/**
 * Login with email and password.
 */
export const OTP_DISABLE_CONFIRM = `
mutation _($passcode: String!) {
    mfa_disable_confirm(input: { passcode: $passcode }) {
        success
    }
}
`;
