export const ALERTS_UPDATE = `
    mutation M(
        $id: Int!, 
        $title: String!,
        $active: Boolean!,
        $description: String
    ) {
        alert_update(
            input: { 
                id: $id,
                alert_title: $title, 
                active: $active,
                description: $description
            }
        ) {
            id
            hash_id
            title
            description
            created_on
            created_by
            active
            conditions {
                id
                type_id
                comparator_id
                alert_id
                order
                break_or
                value
                template {
                    id
                    title
                }
                field {
                    id
                    title
                    type_id
                    type_name
                }
                object_type {
                    id
                    title
                }
                index_field {
                    id
                    title
                    type_id
                    type_name
                }
                dot_field
            }
            subscribers {
                id
                email
            }
        }
    }
`;
