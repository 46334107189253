import React from 'react';
import { FormattedMessage } from 'react-intl';
import { LOCALIZATION_SET_LOCALE, LOCALIZATION_LOAD_LOCALE } from './types';
import { DataStorage } from '../utilities/storage';

const defaultState = {
    locale: undefined
};

/**
 * Loading state mapper.
 * @param {object} state
 * @param {string} action
 */
export default function(state = defaultState, action) {
    switch (action.type) {
        case LOCALIZATION_SET_LOCALE:
            return { ...state, locale: action.locale };

        case LOCALIZATION_LOAD_LOCALE:
            const messages = {};
            messages[action.locale] = action.messages;
            return { ...state, ...messages };

        default:
            return state;
    }
}

export const loadLocale = locale => async dispatch => {
    console.info('loading locale ' + locale)
    fetch(`${process.env.PUBLIC_URL}/messages-${locale}.json`, { method: 'GET' }).then(async data => {
        const messages = await data.json();
        dispatch({ type: LOCALIZATION_LOAD_LOCALE, locale, messages });
    });
};

export const setLocale = (locale, updatePreference = false) => async dispatch => {
    if (updatePreference) {
        DataStorage.setLocale(locale);
    }

    dispatch({ type: LOCALIZATION_SET_LOCALE, locale });
};

export const getSupportedLocales = () => {
    return [{ locale: 'en', name: <FormattedMessage id="language.en" /> }, { locale: 'fr', name: <FormattedMessage id="language.fr" /> }, { locale: 'pt', name: <FormattedMessage id="language.pt" /> }];
};
