import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import VisibilityList from './containers/list';
import VisibilityPage from './containers/visibilityPage';
import { DefinedPermissions } from '../../components/routes/permissions/permissionResolver';

class Visualize extends React.Component {
    render() {
        return (
            <Switch>
                <Route exact path="/visualize" component={props => <VisibilityList {...props} />} />
                <Route path='/visualize/:id' component={props => <VisibilityPage {...props} parents={[VisibilityList.meta]} />} />
            </Switch>
        );
    }
}

Visualize.meta = {
    title: <FormattedMessage id="visualize" />,
    subtitle: <FormattedMessage id="visualizeSubtitle" />,
    routes: ['/visualize'],
    order: 40,
    icon: 'ion-ios-eye-outline',
    menus: { main: true, user: false },
    access: DefinedPermissions.ModelCrud,
    requires: ['model', 'read'],
    onlySubscribed: true,
    proRequired: true,
};

export default Visualize;
