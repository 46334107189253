import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import Tooltip from 'rc-tooltip';
import { FormattedMessage } from 'react-intl';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en';
import pt from 'javascript-time-ago/locale/pt';
TimeAgo.addLocale(pt)
TimeAgo.addDefaultLocale(en)

export const buildPaginationArguments = (page, pageSize, sorting) => {
    const pagingArguments = {
        order_by: sorting.map(s => `${s.id}${s.desc ? ' desc' : ''}`),
        offset: page * pageSize,
        limit: pageSize
    };
    return pagingArguments;
};

export const uuid = (n = 36) => {
    return '00000000-0000-4000-8000-000000000000'.replace(/0/g, () => (0 | (Math.random() * 16)).toString(16)).slice(0, n);
};

export const capitalize = s => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export const getRichTextContent = value => {
    if (!value) return undefined;

    const obj = tryParseJson(value);
    if (!obj) {
        if (value.length > 0) {
            return JSON.parse(
                `{"blocks":[{"key":"${uuid(
                    8
                )}","text":"${value}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`
            );
        }
    }

    return obj;
};

export const tryParseJson = value => {
    try {
        return JSON.parse(value);
    } catch (error) {
        return false;
    }
};

export const validateToken = tokenData => {
    if (!tokenData) return;

    const { token, data } = tokenData;
    if (!token || !data) return;

    const { expires } = data;
    if (moment(expires) <= moment()) return;

    return tokenData;
};

export const isAboutToExpire = tokenData => {
    if (!tokenData || !tokenData.data) return;
    const { expires } = tokenData.data;
    return moment(expires) <= moment().add(5, 'minutes');
};

export const compareFieldArrays = (f1, f2) => {
    if (!f1 && !f2) return true;
    if ((!f1 && !!f2) || (!!f1 && !f2)) return false;
    if (f1.length !== f2.length) return false;

    let areSame = true;
    for (const field of f1) {
        const match = f2.find(f => f.id === field.id);
        if (match === undefined) {
            areSame = false;
            break;
        }
        if (field.cargoCategory === undefined) field.cargoCategory = null;
        if (match.cargoCategory === undefined) match.cargoCategory = null;
        const fieldReduced = { ...field, fieldChildren: undefined, onSelect: undefined, selected: undefined, fullField: undefined };
        const matchReduced = { ...match, fieldChildren: undefined, onSelect: undefined, selected: undefined, fullField: undefined };
        const frJson = JSON.stringify(fieldReduced);
        const mJson = JSON.stringify(matchReduced);
        if (frJson !== mJson) {
            areSame = false;
            break;
        }
    }
    return areSame;
};

export const compareDocumentTemplates = (t1, t2) => {
    if (!t1 || !t2) return true;
    if ((!t1 && !!t2) || (!!t1 && !t2)) return false;

    const headers = !!t1.header && !!t2.header ? JSON.stringify(withoutProperty(t1.header, 'id')) === JSON.stringify(withoutProperty(t2.header, 'id')) : true;
    const bodies = !!t1.body && t2.body ? JSON.stringify(withoutProperty(t1.body, 'id')) === JSON.stringify(withoutProperty(t2.body, 'id')) : true;
    const footers = !!t1.footer && t2.body ? JSON.stringify(withoutProperty(t1.footer, 'id')) === JSON.stringify(withoutProperty(t2.footer, 'id')) : true;

    const areSame = headers && bodies && footers && t1.page_orientation === t2.page_orientation && t1.paper_size === t2.paper_size && t1.file_data === t2.file_data;
    return areSame;
};

const withoutProperty = (arr, property) => {
    const newArr = [];
    arr.forEach(element => {
        delete element[property];
        newArr.push(element);
    });
    return newArr;
};

export const getWidthAndOffset = (value, alignment) => {
    switch (value) {
        case 1:
        case 2:
        case 3:
        case 4:
            return { width: 4, offset: alignment === 'left' ? 0 : alignment === 'center' ? 4 : 8 };

        case 5:
        case 6:
            return { width: 6, offset: alignment === 'left' ? 0 : alignment === 'center' ? 3 : 6 };

        case 7:
        case 8:
        case 9:
        case 10:
            return { width: 8, offset: alignment === 'left' ? 0 : alignment === 'center' ? 2 : 4 };

        default:
        case 11:
        case 12:
            return { width: 12, offset: 0 };
    }
};

export const copyToClipboard = text => {
    var dummy = document.createElement('textarea');
    dummy.setAttribute('contenteditable', 'true');
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
};

export const parsePermissions = data => {
    if (!data) return [];
    const parsed = JSON.parse(data);
    if (!parsed) return [];

    const grouped = _.groupBy(parsed, 'entity');
    const adjusted = {};
    for (const key in grouped) {
        if (grouped.hasOwnProperty(key)) {
            adjusted[key] = {};
            grouped[key]
                .map(p => p.verb)
                .forEach(v => {
                    adjusted[key][v] = true;
                });
        }
    }
    return adjusted;
};

export const arrayEquals = function (array, otherArray) {
    // if the other array is a falsy value, return
    if (!array)
        return false;

    // compare lengths - can save a lot of time 
    if (otherArray.length !== array.length)
        return false;

    for (let i = 0, l=otherArray.length; i < l; i++) {
        // Check if we have nested arrays
        if (otherArray[i] instanceof Array && array[i] instanceof Array) {
            // recurse into the nested arrays
            if (!arrayEquals(otherArray[i], array[i]))
                return false;       
        }           
        else if (otherArray[i] !== array[i]) { 
            // Warning - two different object instances will never be equal: {x:20} != {x:20}
            return false;   
        }           
    }       
    return true;
};

export const debounce = function(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

export const tooltipWrapper = (showTooltip, overlay, children, placement = null, overlayClassName = null) => {
    return showTooltip ? 
        <Tooltip placement={placement !== null ? placement : 'bottom'} trigger={['hover']} overlay={overlay} children={children} overlayClassName={overlayClassName !== null ? overlayClassName : ''} />
         :
        children
};

export const onlyUnique = function (value, index, self) { 
    return self.indexOf(value) === index;
}

export const translateMatchStrength = (strength) => {
    if (strength === null || strength === undefined) {
        return <FormattedMessage id='n/a'/>;
    } else if (strength > 0.999) {
        return <FormattedMessage id='perfect'/>;
    } else if (strength > 0.5) {
        return <FormattedMessage id='great'/>;
    } else if (strength > 0.4) {
        return <FormattedMessage id='good'/>;
    } else if (strength > 0.35) {
        return <FormattedMessage id='ok'/>;
    } else {
        return <FormattedMessage id='poor'/>;
    }
}

export const formatDateFriendly = (date, locale = 'en') => {
    if (date === null || date === undefined) {
        return <FormattedMessage id='never'/>;
    } else {
        const timeAgo = new TimeAgo(locale)
        const localDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()))
        return timeAgo.format(localDate);
    }
}

export const getExcelFieldReferences = (template) => {
    let refs = [];
    // iterate through each cell in each sheet, and parse the text value for a field reference
    try {
        for(const sheetName in template.sheets) {
            const sheet = template.sheets[sheetName];
            for (const row in sheet.data.dataTable) {
                for (const col in sheet.data.dataTable[row]) {
                    const cell = sheet.data.dataTable[row][col];
                    const { value } = cell;
                    refs = refs.concat(parseStringForRefs(value).filter(r => !refs.find(e => r.literal === e.literal)));
                }
            }
        }
    } catch (sheetParseErr) {
        // probably a brand new spreadsheet
        console.warn(sheetParseErr);
    }
    return refs;
}

export const getWordFieldReferences = (template) => {
    let refs = [];
    // iterate through each cell in each sheet, and parse the text value for a field reference
    for(const section of template.sections) {
        for (const block of section.blocks) {
            if (block.inlines) {
                // Simple text block
                let inlineText = "";
                for (const inline of block.inlines) {
                    inlineText += inline.text;
                }
                refs = refs.concat(parseStringForRefs(inlineText).filter(r => !refs.find(e => r.literal === e.literal)));
            } else if (block.rows) {
                // Table
                for (const row of block.rows) {
                    if (!row.cells) {
                        continue;
                    }
                    for (const cell of row.cells) {
                        for (const cellBlock of cell.blocks) {
                            if (!cellBlock.inlines) {
                                continue;
                            }
                            let inlineText = "";
                            for (const inline of cellBlock.inlines) {
                                inlineText += inline.text;
                            }
                            refs = refs.concat(parseStringForRefs(inlineText).filter(r => !refs.find(e => r.literal === e.literal)));
                        }
                    }
                }
            }
        }
        // check headers and footers, if any
        if (section.headersFooters) {
            if (section.headersFooters.header && section.headersFooters.header.blocks) {
                for (const block of section.headersFooters.header.blocks) {
                    if (block.inlines) {
                        // Simple text block
                        let inlineText = "";
                        for (const inline of block.inlines) {
                            inlineText += inline.text;
                        }
                        refs = refs.concat(parseStringForRefs(inlineText).filter(r => !refs.find(e => r.literal === e.literal)));
                    } else if (block.rows) {
                        // Table
                        for (const row of block.rows) {
                            if (!row.cells) {
                                continue;
                            }
                            for (const cell of row.cells) {
                                for (const cellBlock of cell.blocks) {
                                    if (!cellBlock.inlines) {
                                        continue;
                                    }
                                    let inlineText = "";
                                    for (const inline of cellBlock.inlines) {
                                        inlineText += inline.text;
                                    }
                                    refs = refs.concat(parseStringForRefs(inlineText).filter(r => !refs.find(e => r.literal === e.literal)));
                                }
                            }
                        }
                    }
                }
            }
            if (section.headersFooters.footer && section.headersFooters.footer.blocks) {
                for (const block of section.headersFooters.footer.blocks) {
                    if (block.inlines) {
                        // Simple text block
                        let inlineText = "";
                        for (const inline of block.inlines) {
                            inlineText += inline.text;
                        }
                        refs = refs.concat(parseStringForRefs(inlineText).filter(r => !refs.find(e => r.literal === e.literal)));
                    } else if (block.rows) {
                        // Table
                        for (const row of block.rows) {
                            if (!row.cells) {
                                continue;
                            }
                            for (const cell of row.cells) {
                                for (const cellBlock of cell.blocks) {
                                    if (!cellBlock.inlines) {
                                        continue;
                                    }
                                    let inlineText = "";
                                    for (const inline of cellBlock.inlines) {
                                        inlineText += inline.text;
                                    }
                                    refs = refs.concat(parseStringForRefs(inlineText).filter(r => !refs.find(e => r.literal === e.literal)));
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return refs;
}

export const parseStringForRefs = (str) => {
    // search the string globally in case there are multiple matches
    const globalTokenExpr = /{{[^{}\[\]]*\[\$(model|index|lot)\.[cfl\-\.\d]+\]}}/g;
    // capture the label and the actual token from eatch match found
    const tokenCaptureExpr = /{{([^{}\[\]]*)\[\$((?:model|index|lot)\.[cfl\-\.\d]+)\]}}/;
    let refs = [];
    const matches = new String(str).match(globalTokenExpr);
    if (matches) {
        for (const match of matches) {
            const captures = match.match(tokenCaptureExpr);
            // Don't allow repeat entries
            if (!refs.find(r => r.literal === captures[0])) {
                refs.push({
                    literal: captures[0],
                    label: captures[1],
                    token: captures[2],
                    type: captures[2].split('.')[0]
                })
            }
        }
    }
    return refs;
}

export const addMetadataToFieldReferences = (refs, data) => {
    const fixedRefs = [];
    for (const ref of refs) {
        const refLiteral = data.ref_group_references.find(g => g.field_reference_literal === ref.literal);
        if (refLiteral) {
            ref.group = refLiteral;
        } else {
            ref.group = null;
        }
        if (ref.type === 'index') {
            ref.unavailableTableStatuses = [];
            const indexParts = ref.token.split('.');
            ref.field = data.indices.find(i => i.id === indexParts[1]);
            // add initial search criteria for each field
            const initialIndexFilters = {
                model_ids: [],
                criteria: JSON.stringify([{ id: uuid(8), text_values: [''], break_or: false, is_not: false, index_field_id: ref.field.id, comparator_id: 1 }]),
                status_ids: [1,2,3,4,5],
            };
            ref.event_criteria = initialIndexFilters;

            fixedRefs[refs.indexOf(ref)] = ref;
        } else if (ref.type === 'model') {
            const modelParts = ref.token.split('.');
            let initialStatusIds = [1,2,3,5];
            if (modelParts.length === 4) {
                // This is referencing a dot field within an event
                let dotField = data.fields.find(f => f.id === modelParts[modelParts.length - 1]);
                let eventField = data.fields.find(f => f.id === modelParts[modelParts.length - 2].replace('f-', ''));
                ref.field = dotField;
                // add initial search criteria for each field
                const initialModelFilters = {
                    model_ids: [eventField.model_id],
                    criteria: "[]",
                    status_ids: initialStatusIds,
                };
                ref.event_criteria = initialModelFilters;

                fixedRefs[refs.indexOf(ref)] = ref;
            } else {
                // The last portion (split by periods) is either the lot field or model field id
                let fieldId = modelParts[modelParts.length - 1];
                if (fieldId.indexOf('l') > -1) {
                    fieldId = modelParts[modelParts.length - 2];
                    ref.unavailableTableStatuses = ['Draft', 'Failed'];
                    initialStatusIds = [2,3,5];
                } else {
                    ref.unavailableTableStatuses = [];
                }
                let field = data.fields.find(f => f.id === fieldId);
                ref.field = field;
                // add initial search criteria for each field
                const initialModelFilters = {
                    model_ids: [ref.field.model_id],
                    criteria: "[]",
                    status_ids: initialStatusIds,
                };
                ref.event_criteria = initialModelFilters;

                fixedRefs[refs.indexOf(ref)] = ref;
            }
        } else if (ref.type === 'lot') {
            ref.unavailableTableStatuses = [];
            const lotParts = ref.token.split('.');
            ref.field = data.models.find(m => Number(m.id) === Number(lotParts[1]));
            if (lotParts.length === 3) {
                const lotInfoType = lotParts[2];
                if (lotInfoType.includes('l')) {
                    if (lotInfoType === "1") {
                        ref.lot_info_type = "Quantity";
                    } else {
                        ref.lot_info_type = "DoT Unit";
                    }
                } else if (lotInfoType.includes('f')) {
                    const fieldId = lotInfoType.replace('f', '');
                    const dotField = data.fields.find(f => f.id === fieldId);
                    if (dotField) {
                        ref.field.type_name = dotField.type_name;
                    }
                }
            }
            const initialLotFilters = {
                cargo_categories: [lotParts[1]],
                status_ids: [2,3],
            };
            ref.lot_criteria = initialLotFilters;
            fixedRefs[refs.indexOf(ref)] = ref;
        }
    }
    return fixedRefs;
}

export const invertColor = (hex, bw) => {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    var r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
    if (bw) {
        // http://stackoverflow.com/a/3943023/112731
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186
            ? '#000000'
            : '#FFFFFF';
    }
    // invert color components
    r = (255 - r).toString(16);
    g = (255 - g).toString(16);
    b = (255 - b).toString(16);
    // pad each with zeros and return
    return '#' + padZero(r) + padZero(g) + padZero(b);
}
    
export const padZero = (str, len) => {
    len = len || 2;
    var zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
}