import React from 'react';
import ReactModal from 'react-modal';
import { FormattedMessage } from 'react-intl';
import { Button, Popover, Menu, MenuItem, Icon, MenuDivider } from '@blueprintjs/core';
import SimpleBar from 'simplebar-react';

class DocumentsDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { open, onClose, documents = [], deleteDocument, onItemClick } = this.props;

        return (
            <ReactModal isOpen={open} onRequestClose={onClose} className="modal-block dialog" overlayClassName="modal-overlay gray">
                <div className="wd-800-force" role="document">
                    <div className="modal-content bd-0">
                        <div className="modal-header pd-x-20">
                            <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                                <FormattedMessage id="page.transactions.manage.documents" />
                            </h6>
                        </div>

                        <div className="modal-body pd-0 widget-2 transaction-documents-list">
                            <SimpleBar>
                                {documents.length > 0 ? (
                                    documents.map((d, i) => (
                                        <div key={i} className="transaction-documents-list-item">
                                            <div className="col transaction-documents-list-item-title">{d.name}</div>
                                            <div className="col-1 transaction-documents-list-item-menu">
                                                <Popover
                                                    // usePortal={false}
                                                    position="bottom-left"
                                                    content={
                                                        <Menu>
                                                            <MenuItem
                                                                icon="eye-open"
                                                                text={<FormattedMessage id="common.view" />}
                                                                onClick={() => onItemClick(d)}
                                                            />
                                                            <MenuDivider />
                                                            <MenuItem
                                                                icon="cross"
                                                                text={<FormattedMessage id="common.delete" />}
                                                                onClick={() => deleteDocument(d.id)}
                                                            />
                                                        </Menu>
                                                    }
                                                >
                                                    <Button minimal={true} small={true} icon={<Icon iconSize={12} icon="menu" />} />
                                                </Popover>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="col transaction-documents-list-item-no-items tx-center">No documents available.</div>
                                )}
                            </SimpleBar>
                        </div>
                        <div className="modal-footer justify-content-center">
                            <button type="button" className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium" onClick={onClose}>
                                <FormattedMessage id="common.ok" />
                            </button>
                        </div>
                    </div>
                </div>
            </ReactModal>
        );
    }
}

export default DocumentsDialog;
