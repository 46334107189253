import React from 'react';
import { RowBreak, FieldDescription } from './common';
import { Fields } from '../../../../components/fieldsControlled';
import Tooltip from 'rc-tooltip';
import { getTextMaxLength } from '../../../../utilities/constants';

class TextField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { 
            id, 
            width, 
            breakRow, 
            title, 
            disabled, 
            prependEls = [], 
            onChangeExt, 
            tooltipOverlay, 
            appendEls = [], 
            seleniumId, 
            setFieldValue, 
            initialValue, 
            wrapperClassName, 
            wrapperStyle 
        } = this.props;

        const widthClass = width ? `-${width}` : '';
        const fieldEl = (
            <div className="field-text">
                <Fields.Input 
                    type="text" 
                    placeholder={title} 
                    name={id} 
                    showError={false} 
                    showIsRequired={this.props.showIsRequired}
                    disabled={disabled} 
                    onChangeExt={onChangeExt}
                    seleniumId={seleniumId}
                    maxLength={getTextMaxLength()}
                    setFieldValue={setFieldValue}
                    // value={value}
                    initialValue={initialValue}
                />
                {[...appendEls]}
                <FieldDescription 
                    {...this.props} 
                    seleniumId={seleniumId}
                />
            </div>
        );
        return (
            <React.Fragment>
                <div className={wrapperClassName ? wrapperClassName : `pd-b-15 col${widthClass}`} style={wrapperStyle ? wrapperStyle : { display: 'inline-block' }}>
                    {[...prependEls]}
                    {
                        tooltipOverlay ?
                        <Tooltip
                            key={id}
                            placement={'top'}
                            trigger={['hover']}
                            overlay={tooltipOverlay}
                            children={ fieldEl }
                        />
                        :
                        fieldEl
                    }
                </div>
                {breakRow && <RowBreak width={width} />}
            </React.Fragment>
        );
    }
}

export default TextField;
