import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'pages/models/models.scss';
import 'components/fields/fields.scss';
import { DocumentEditorContainerComponent, Toolbar } from '@syncfusion/ej2-react-documenteditor';
import LoadingOverlay from 'react-loading-overlay';

DocumentEditorContainerComponent.Inject(Toolbar);

class WordDocumentViewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            previewData: "",
            doc_file_data: null,
            fieldRefModalRenderCount: 0,
            userSelection: '',
        };
    }

    componentDidMount() {
        const { templateFileData } = this.props;
        this.setState({ doc_file_data: templateFileData}, () => {
            if (this.documenteditor){
                this.documenteditor.documentEditor.open(this.state.doc_file_data);
            }
        })
    }

    render() {

        return (
            <div style={{height: '100%'}}>
                <DocumentEditorContainerComponent
                    id="container" 
                    height={'100%'}
                    ref={(scope) => { this.documenteditor = scope; }} 
                    isReadOnly={true}
                    //this is what really makes the document readonly
                    restrictEditing={true}
                    enableToolbar={true}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WordDocumentViewer);
