import React from 'react';
import { FormattedMessage } from 'react-intl';
import Login from './forms/login';
import MFA from './forms/mfa';
import Forgot from './forms/forgot';
import SetPassword from './forms/setPassword';
import logo from './../../assets/logo-green.svg';

import './account.scss';

class Account extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    renderForm = () => {
        const { form } = this.props;
        switch (form) {
            case 'forgot':
                return <Forgot {...this.props} />;

            case 'reset':
                return <Login {...this.props} />;

            case 'set-password':
                return <SetPassword {...this.props} />;

            case 'mfa':
                return <MFA {...this.props} />;

            case 'login':
            default:
                return <Login {...this.props} />;
        }
    };

    render() {
        return (
            <div className="d-flex align-items-center justify-content-center bg-br-primary ht-100v">
                <div className="login-wrapper wd-300 wd-xs-350 pd-25 pd-xs-40 bg-white rounded shadow-base">
                    <div className="signin-logo tx-center tx-28 tx-bold tx-inverse">
                        <img src={logo} alt="Dorae Inc" className="logo" />
                    </div>
                    <div className="tx-center mg-b-30 account-subtitle">
                        <FormattedMessage id="page.account.heading" />
                    </div>
                    {this.renderForm()}
                </div>
            </div>
        );
    }
}

Account.meta = {
    routes: ['/login', '/forgot', '/reset/', '/mfa'],
    menus: { main: false, user: false }
};

export default Account;
