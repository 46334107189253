import React from 'react';
import { getFieldIcon, FieldTypes } from '../../../../models/field';
import Tooltip from 'rc-tooltip';
import { FormattedMessage } from 'react-intl';
import FadeIn from 'react-fade-in';
import './../../models.scss';
import {Collapse} from 'react-collapse';

class FieldListItem extends React.Component {
    constructor(props){
        super(props);
        this.state = {
        };
    }
    invertColor = (hex, bw) => {
        if (hex.indexOf('#') === 0) {
            hex = hex.slice(1);
        }
        // convert 3-digit hex to 6-digits.
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        if (hex.length !== 6) {
            throw new Error('Invalid HEX color.');
        }
        var r = parseInt(hex.slice(0, 2), 16),
            g = parseInt(hex.slice(2, 4), 16),
            b = parseInt(hex.slice(4, 6), 16);
        if (bw) {
            // http://stackoverflow.com/a/3943023/112731
            return (r * 0.299 + g * 0.587 + b * 0.114) > 186
                ? '#000000'
                : '#FFFFFF';
        }
        // invert color components
        r = (255 - r).toString(16);
        g = (255 - g).toString(16);
        b = (255 - b).toString(16);
        // pad each with zeros and return
        return '#' + this.padZero(r) + this.padZero(g) + this.padZero(b);
    }
    
    padZero = (str, len) => {
        len = len || 2;
        var zeros = new Array(len).join('0');
        return (zeros + str).slice(-len);
    }

    dotField = (id, expanded, child) => {
        const { title, type, selected, order, onSelect, lastChild, disabled, color } = child;
        return (
            <div
                key={'field-item-' + id + '-' + child.id}
                id={'field-item-' + id + '-' + child.id}
                className={`dot-field ${lastChild ? 'last-child' : ''}`}
            >
                <div
                    id={'field-item-' + id + '-' + child.id + '-padded'}
                    className={`field-row no-select ${selected ? ' selected-field' : ''} field-row-${type.toLowerCase()} ${expanded ? '' : 'dot-collapsed'} f-index-${order}`}
                    style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "clip", backgroundColor: !!color ? (selected ? color + 'C0' : color) : undefined, borderColor: (selected && color) ? color : null }} //borderColor: (selected && color) ? color : null, border: (selected && color) ? '2px solid ' + color : null, boxShadow: selected ? `0px 0px 10px 5px ${color ? color : '#888888'}` : undefined
                    onClick={() => onSelect(child)}
                >
                    <div className={`${disabled ? 'disabled' : ''}`}>
                        { (type !== FieldTypes.FIELD_SECTION && type !== FieldTypes.FIELD_END_SECTION) && <i className="material-icons-outlined" style={{color: (!selected && color) ? this.invertColor(color, true) : null}}>{getFieldIcon(type)}</i>}
                        <span style={{color: color ? this.invertColor(color, true) : null}}>{title}</span>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { item, dragHandleProps } = this.props;
        if (!item) return null;

        const { id, title, type, selected, order, onSelect, isDotField, lastChild, fieldChildren, disabled, color, expanded, toggleExpanded = () => null, onAnimationCompleted = () => null, map_disabled_reason } = item;
        const dragProps = isDotField ? {} : dragHandleProps;

        const customOrder = (a, b) => {
            if (a.special_id){
                return 1;  
            }

            if ( a.order < b.order ){
              return -1;
            }
            if ( a.order > b.order ){
              return 1;
            }
            return 0;
          }
        return (
            <div
                key={'field-item-' + id}
                id={'field-item-' + id}
                {...dragProps}
            >
                <Tooltip placement={'right'} trigger={['hover']} overlay={<span>{disabled === true && map_disabled_reason !== undefined ? map_disabled_reason : title}</span>} mouseEnterDelay={disabled === true && map_disabled_reason !== undefined ? 1 : 2} >

                    <div
                        className={`line-target-${id} field-row no-select ${(selected && !color) ? ' selected-field' : (color ? '' : '')} field-row-${type.toLowerCase()} ${expanded ? '' : 'dot-collapsed'} f-index-${order} ${isDotField ? 'dot-field' : ''} ${lastChild ? 'last-child' : ''} ${disabled ? 'disabled' : ''}`}
                        style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "clip", backgroundColor: !!color ? (selected ? color + 'A0' : color) : undefined, borderColor: (selected && color) ? color : null }} //borderColor: (selected && color) ? color : null, border: (selected && color) ? '2px solid ' + color : null
                        onClick={() => onSelect(item)}
                        dotexpanded={expanded ? 'true' : undefined}
                    >
                        <div style={ (!selected && !!color) ? { backgroundColor: color } : {}}>
                            { (type !== FieldTypes.FIELD_SECTION  && type !== FieldTypes.FIELD_END_SECTION )&& <i className="material-icons-outlined" style={{color: color ? this.invertColor(color, true) : null}}>{getFieldIcon(type)}</i>}
                                    <span style={{color: color ? this.invertColor(color, true) : null}}>{title}</span>
                            {
                                (type === FieldTypes.FIELD_CARGO) && (
                                    // if field has children then show expand arrow
                                    <div style={{position: 'absolute', right: 0, top: '11px', backgroundColor: selected ? '#CCD1D8' : (color ? color : 'white')}}>
                                        <Tooltip
                                            placement={'top'}
                                            trigger={['hover']}
                                            overlay={<span>{expanded ? <FormattedMessage id="common.collapse" /> : <FormattedMessage id="common.expand" />}</span>}
                                        >
                                            <i className="material-icons no-select" style={{ opacity: 1}} onClick={e => { e.preventDefault(); toggleExpanded(id);}}>{expanded ? 'arrow_drop_up' : 'arrow_drop_down'}</i>
                                        </Tooltip>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </Tooltip>
                <Collapse 
                    isOpened={expanded}
                    onRest={onAnimationCompleted}
                >
                {
                    // when dot field with children fields
                    fieldChildren.sort(customOrder).map((child, index) => {                        
                        if(child.map_disabled_reason !== undefined) {
                            return (
                                <Tooltip key={index} placement={'right'} trigger={['hover']} overlay={<span>{child.map_disabled_reason}</span>} mouseEnterDelay={2}>
                                    {this.dotField(id, expanded, child)}
                                </Tooltip>
                            )
                        }
                        else {
                            return (
                                this.dotField(id, expanded, child)
                            )
                        }                        
                    })
                }
                </Collapse>
            </div>
        );
    }
}

export default FieldListItem;
