import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import moment from 'moment';
import { Popover, Menu, MenuItem, Button, Icon, MenuDivider } from '@blueprintjs/core';
import ReactModal from 'react-modal';
import SimpleBar from 'simplebar-react';
import Page from '../../../components/page';
import { copyToClipboard } from '../../../utilities/common';
import { Notifications } from '../../../components/notifications';
import InviteLinkCreate from '../components/forms/inviteLinkCreate';
import InviteResponseReview from '../components/forms/inviteResponseReview';
import { getCompanies, getInviteLinks, approveInvite, rejectInvite, getInviteResponses } from '../../../reducers/companies';
import { deleteLink } from '../../../reducers/links';

class CompanyInvites extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: { open: false, data: undefined }
        };
    }

    componentDidMount() {
        const { getInviteResponses, getInviteLinks } = this.props;
        getInviteResponses({ limit: 1000 });
        getInviteLinks();
    }

    setModalVisibility = (open, data) => {
        this.setState({ ...this.state, modal: { open, data: open ? data : undefined } });
    };

    renderActions = () => {
        return (
            <div className="btn-group pd-t-10">
                <span className="btn btn-outline-info" onClick={() => this.setModalVisibility(true)}>
                    <FormattedMessage id="page.companies.invites.createLink" />
                </span>
            </div>
        );
    };

    renderInviteLinkRow = ({ id, title, name, description, created_on }, i) => {
        const { getInviteLinks, deleteLink } = this.props;
        return (
            <div key={i} className="invite-row">
                <div className="row mg-x-0">
                    <div className="col-9 invite-row-title">{title}</div>
                    <div className="col-3 invite-row-actions tx-right">
                        <Popover
                            content={
                                <Menu>
                                    <MenuItem
                                        icon="duplicate"
                                        text={<FormattedMessage id="page.companies.invites.copyLink" />}
                                        onClick={() => {
                                            copyToClipboard(`${window.location.origin}/l/${name}`);
                                            Notifications.success(<FormattedMessage id="page.companies.invites.linkCopied" />);
                                        }}
                                    />
                                    <MenuDivider />
                                    <MenuItem
                                        icon="cross"
                                        text={<FormattedMessage id="common.delete" />}
                                        onClick={() => {
                                            deleteLink(id, () => getInviteLinks());
                                            Notifications.success(<FormattedMessage id="page.companies.invites.linkDeleted" />);
                                        }}
                                    />
                                </Menu>
                            }
                        >
                            <Button minimal={true} small={true} icon={<Icon iconSize={12} icon="menu" />} />
                        </Popover>
                    </div>
                    {!!description && <div className="col-12 invite-row-description mg-t-10">{description}</div>}
                    <div className="col-12 invite-row-description mg-t-10">
                        <FormattedHTMLMessage
                            id="common.createdOnDate"
                            values={{
                                date: moment(created_on)
                                    .add(-i, 'days')
                                    .format('MM/DD/YYYY')
                            }}
                            tagName="span"
                        />
                    </div>
                </div>
            </div>
        );
    };

    renderAnsweredLinkRow = (answer, i) => {
        const { company_name } = answer;
        const { approveInvite, rejectInvite, getInviteResponses } = this.props;
        return (
            <div key={i} className="invite-row">
                <div className="row mg-x-0">
                    <div className="col-9 answered-invite-row-title">
                        <span>{company_name}</span>
                    </div>
                    <div className="col-3 invite-row-actions tx-right">
                        <Popover
                            content={
                                <Menu>
                                    <MenuItem
                                        icon="eye-open"
                                        text={<FormattedMessage id="common.view" />}
                                        onClick={() => this.setModalVisibility(true, answer)}
                                    />
                                    <MenuDivider />
                                    <MenuItem
                                        icon="tick"
                                        text={<FormattedMessage id="common.approve" />}
                                        onClick={() =>
                                            approveInvite(answer.id, () => {
                                                Notifications.success(<FormattedMessage id="page.companies.invites.inviteApproved" />);
                                                getInviteResponses({ limit: 1000 });
                                            })
                                        }
                                    />
                                    <MenuItem
                                        icon="cross"
                                        text={<FormattedMessage id="common.reject" />}
                                        onClick={() =>
                                            rejectInvite(answer.id, () => {
                                                Notifications.success(<FormattedMessage id="page.companies.invites.inviteRejected" />);
                                                getInviteResponses({ limit: 1000 });
                                            })
                                        }
                                    />
                                </Menu>
                            }
                        >
                            <Button minimal={true} small={true} icon={<Icon iconSize={12} icon="menu" />} />
                        </Popover>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { modal } = this.state;
        const { parents, links, answers } = this.props;
        if (!links || !answers) return null;
        return (
            <React.Fragment>
                <Page {...CompanyInvites.meta} parents={parents} actions={this.renderActions()}>
                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            <h5 className="mg-b-15 invites-section-title">
                                <FormattedMessage id="page.companies.invites.createdLinks" />
                            </h5>
                            <div className="invites-box">
                                <SimpleBar>{links.map((link, i) => this.renderInviteLinkRow(link, i))}</SimpleBar>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <h5 className="mg-b-15 invites-section-title">
                                <FormattedMessage id="page.companies.invites.answeredLinks" />
                            </h5>
                            <div className="invites-box">
                                <SimpleBar>{answers.map((answer, i) => this.renderAnsweredLinkRow(answer, i))}</SimpleBar>
                            </div>
                        </div>
                    </div>
                </Page>
                <ReactModal
                    isOpen={modal.open}
                    onRequestClose={() => this.setModalVisibility(false)}
                    className="modal-block dialog"
                    overlayClassName="modal-overlay gray"
                >
                    {!!modal.data ? (
                        <InviteResponseReview answer={modal.data} onCancel={() => this.setModalVisibility(false)} />
                    ) : (
                        <InviteLinkCreate onCancel={() => this.setModalVisibility(false)} />
                    )}
                </ReactModal>
            </React.Fragment>
        );
    }
}

CompanyInvites.meta = {
    title: <FormattedMessage id="page.companies.invites.title" />,
    subtitle: <FormattedMessage id="page.companies.invites.subtitle" />,
    routes: ['/companies/'],
    icon: 'ion-ios-world-outline',
    menus: { main: false, user: false },
    order: 0
};

const mapStateToProps = state => ({
    links: state.companies.links,
    answers: state.companies.inviteResponses
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getInviteLinks,
            deleteLink,
            getCompanies,
            getInviteResponses,
            approveInvite,
            rejectInvite
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanyInvites);
