export const IDENTITY_UPLOAD_AVATAR = `
    mutation M {
        user_upload_avatar {
            id
            status_id
            status_name
            created_on
            modified_on
            email
            auth_type
            last_login_on
            login_attempts
            reset_password
            name
            avatar_id
            lang_id
            lang
            country_id
            country_code2
            country_code3
            state
            city
            zip
            address_1
            address_2
            phone
            extra_info

            mfa_required
        }
    }
`;
