import React from 'react';
import Select, { components } from 'react-select';
import { RowBreak, FieldDescription } from './common';
import { FieldTypes } from '../../../../models/field';
import { injectIntl, FormattedMessage } from 'react-intl';
import { FixedSizeList as List } from "react-window";
import Tooltip from 'rc-tooltip';

const height = 35;

class MenuList extends React.Component {
  render() {
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;

    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  }
}

class SelectField extends React.PureComponent {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.state = {
            options: []
        }
    }

    parseOptions() {
        const { fieldValues, value, type } = this.props;
        let options = [];
        if (type === 'CARGOUNIT') {
            const cargoUnitGroups = [...new Set((fieldValues || []).map(o => o.unit_type))]
            options = cargoUnitGroups.map(o => { 
                const groupOptions = fieldValues.filter(f => f.unit_type === o).map(f => ({ value: f.id, label: <FormattedMessage id={f.display_name} /> }))
                return { label: <FormattedMessage id={o} />, options: groupOptions };
            });
        } else if (type === FieldTypes.FIELD_CURRENCY) {
            options = (fieldValues || []);
        } else  {
            const safeValues = fieldValues || [];
            const fieldsAreOrdered = !safeValues.every(f => f.order_by === 0);
            if (fieldsAreOrdered) {
                safeValues.sort((f1, f2) => {
                    if (f1.order_by < f2.order_by) {
                        return -1;
                    } else if (f1.order_by > f2.order_by) {
                        return 1;
                    }
                    return 0;
                })
            } else {
                safeValues.sort((f1, f2) => {
                    if (f1.id < f2.id) {
                        return -1;
                    } else if (f1.id > f2.id) {
                        return 1;
                    }
                    return 0;
                })
            }
            options = safeValues.filter(o => o && o.hasOwnProperty('value') && !o.is_hidden).map(o => ({ value: o.value, label: o.label ? o.label : o.value }));
        }
        return options;
    }

    componentDidMount() {
        this.setState({ options: this.parseOptions() });
        
    }

    handleChange = selectedValue => {
        const { id, type } = this.props;
        if (this.props.onChange) {
            if (type === 'CARGOUNIT') {
                this.props.onChange(0, selectedValue);
                return;
            }
            const value = !!selectedValue ? (Array.isArray(selectedValue) ? selectedValue.map(s => s.value) : selectedValue.value) : '';
            this.props.onChange(id, value);
        }
    };

    handleBlur = () => {
        const { id, onBlur = () => null } = this.props;
        onBlur(id, true);
    };

    getOptions = () => {
        const { value, type } = this.props;
        let { options } = this.state;
        if (options.length === 0 || type === 'CARGOUNIT') {
            options = this.parseOptions();
        }
        if (type === 'CARGOUNIT' || type === 'Currency') {
            return options;
        }
        let selectedValues = !!value ? (type === FieldTypes.FIELD_MULTI_SELECT ? value : [value]) : [];
        if (!!selectedValues && typeof selectedValues === 'object' && !Array.isArray(value)) {
            selectedValues = Object.values(selectedValues)
        }
        const missingValues = (!!selectedValues && !!selectedValues.filter) ? selectedValues.filter(s => options.find(o => o.value === s) === undefined) : [];

        return missingValues.length === 0 ? options : [...missingValues.map(m => ({ value: m, label: m })), ...options];
    };

    getSelectedValues = () => {
        const { value, type } = this.props;
        if (type === 'CARGOUNIT') {
            return value;
        }
        let selectedValue = value;
        if (!!selectedValue && typeof selectedValue === 'object' && !Array.isArray(selectedValue)) {
            selectedValue = Object.values(selectedValue)
        }
        const options = this.getOptions();
        return type === FieldTypes.FIELD_MULTI_SELECT ? options.filter(o => (selectedValue || []).includes(o.value)) : options.find(o => o.value === selectedValue);
    };

    render() {
        const { 
            id, 
            title, 
            multi, 
            breakRow, 
            width, 
            disabled, 
            isClearable = false, 
            showIsRequired = false, 
            prependEls = [], 
            appendEls = [],
            extraClassName = '',
            tooltipOverlay,
            intl: { formatMessage },
            seleniumId,
            useFormattedMessageFilter,
            backgroundColor,
        } = this.props;
        const widthClass = width ? `-${width}` : '';
        const customStyles = {
            control: base => ({
                ...base,
                boxShadow: 'none',
                background: backgroundColor? backgroundColor:``                
            }),
            multiValueLabel: (provided, state) => ({
                ...provided,
                maxWidth: '10em'
            }),
            menu: (provided, state) => ({
                ...provided,
                zIndex: '20'
            }),
            singleValue: (provided, state) => {
                const opacity = 1;
                const transition = 'opacity 300ms';
                const color = '#495057';
            
                return { ...provided, opacity, transition, color };
            },
            menuList: (provided, state) => ({
                ...provided,
                maxHeight: '200px'
            }),
        };

        const options = this.getOptions();
        let value = this.getSelectedValues();
        const showLabel = multi ? !!value && value.length > 0 : !!value;

        if (value === undefined) {
            value = '';
        }

        let fieldValueLength = 0;
        if (value && value.label) {
            if (typeof value.label === 'string') {
                fieldValueLength = value.label.length;
            } else if (value.label.props && value.label.props.id) {
                fieldValueLength = value.label.props.id.length;
            }
        }

        const singleValueEl = props => (!value || (value && value.label && fieldValueLength > 5)) ?
            <Tooltip trigger={['hover']} placement='left' overlay={<span>{value ? value.label : formatMessage({ id: `noValue` })}</span>} mouseEnterDelay={1.5}>
                <div>
                    <components.SingleValue {...props} className={'se-select-value-' + seleniumId} />
                </div>
            </Tooltip>
            :
            <components.SingleValue {...props} className={'se-select-value-' + seleniumId} />

        const extraProps = {};
        if (useFormattedMessageFilter) {
            extraProps.filterOption = (
                candidate,
                input
              ) => {
                    const { label, value, data } = candidate;
                    let textLabel = '';
                    if (label && label.props && label.props.id) {
                        try {
                            textLabel = formatMessage({ id: label.props.id });
                        } catch {
                            console.error('Invalid formatted message for select option label:', label);
                        }
                    }
                    return textLabel.toLowerCase().includes(input.toLowerCase());
              };
        }

        const fieldEl = (
            <div style={{width: '100%', height: '100%'}}>
                <Select
                    className={`select-input-field se-id-${seleniumId}`}
                    placeholder={title}
                    id={id}
                    options={options}
                    isMulti={multi}
                    inputId={'se-select-input-' + seleniumId}
                    components={{ 
                        IndicatorSeparator: () => null, 
                        DropdownIndicator: props =>  <components.DropdownIndicator {...props} className={'se-select-dropdown-' + seleniumId} />,
                        ClearIndicator: props =>  <components.ClearIndicator {...props} className={'se-select-clear-' + seleniumId} />,
                        SingleValue: singleValueEl,
                        MultiValue: props => <components.MultiValue {...props} className={'se-select-value-' + seleniumId} />,
                        Menu: props => <components.Menu {...props} className={'se-select-menu-' + seleniumId} />,
                        Option: props => <components.Option {...props} className={'se-select-menu-option-' + seleniumId} />
                    }}
                    theme={theme => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary25: '#eeeeee',
                            primary: '#00336B'
                        }
                    })}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    value={value}
                    styles={customStyles}
                    isDisabled={disabled}
                    isClearable={isClearable}
                    menuPlacement={this.props.menuPlacement || 'auto'}
                    { ...extraProps }
                />
            </div>
        )

        return (
            <React.Fragment>
                <div className={`pd-b-15 col${widthClass} ${extraClassName}`} style={{ display: 'inline-block' }}>
                    {[...prependEls]}
                    <div className="field-select ">
                        {
                            tooltipOverlay ?
                            <Tooltip
                                key={id}
                                placement={'top'}
                                trigger={['hover']}
                                overlay={tooltipOverlay}
                                children={ fieldEl }
                            />
                            :
                            fieldEl
                        }
                        {showLabel ? 
                            <Tooltip key='ph-tooltip' trigger={['hover']} overlay={<span>{title}</span>} mouseEnterDelay={2}>
                                <div className="input-placeholder">
                                    {title}
                                </div> 
                            </Tooltip>
                            : 
                            ( showIsRequired ? <div className="validation-message required-placeholder"><FormattedMessage id="validation.required" /></div> : <React.Fragment/> )
                        }
                        <FieldDescription 
                            {...this.props} 
                            seleniumId={seleniumId}
                        />
                        {[...appendEls]}
                    </div>
                </div>
                {breakRow && <RowBreak width={width} />}
            </React.Fragment>
        );
    }
}

export default injectIntl(SelectField);
