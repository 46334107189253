export const MODELS_UPDATE = `
    mutation M($model_id: Int!, $status_id: Int!, $title: String!, $description: HtmlString, $split_conditions: String, $extra_info: JsonString) {
        model_update(
            model_id: $model_id
            input: { status_id: $status_id, title: $title, description: $description, split_conditions: $split_conditions, extra_info: $extra_info }
        ) {
            id
            hash_id
            company_id
            status_id
            status_name
            created_on
            title
            description
            is_used
            split_conditions
            extra_info
            model_type
            model_type_name
        }
    }
`;
