import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Formik, Form } from 'formik';
import { Notifications } from '../../../../components/notifications';
import { Elements, CardElement, injectStripe } from 'react-stripe-elements';
import { setPaymentSource, listSources } from '../../../../reducers/payment';
import { getProfile } from '../../../../reducers/identity';
import { Fields } from '../../../../components/fields';
import LoadingOverlay from 'react-loading-overlay';
import PulseLoader from 'react-spinners/PulseLoader'

class _CardForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmitting: false,
            sources: []
        };
    }

    componentDidMount() {
        const { company, listSources } = this.props;
        listSources(company.id, (sources) => {
            this.setState({sources: sources});
        })
    }

    createOptions = (fontSize = '14px') => {
        return {
            style: {
                base: {
                    fontSize,
                    color: '#495057',
                    fontFamily:
                        '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
                    '::placeholder': {
                        color: '#868e96'
                    }
                },
                invalid: {
                    color: '#9e2146'
                }
            }
        };
    };

    handleSubmit = values => {
        const { stripe, company, setPaymentSource, onCancel, getProfile, managedSubscription, onSuccess = null } = this.props;
        const { owner: { name, email, phone } = { name: '', email: '', phone: '' } } = values;
        if (!stripe || !company) {
            return null;
        }
        if (phone === '') {
            Notifications.error(<FormattedMessage id="page.settings.setup.emptyPhone" />);
            return null;
        }
        this.setState({isSubmitting: true});
            

        stripe
            .createSource({ type: 'card', owner: { name, email, phone } })
            .then(response => {
                if (!!response.error) {
                    Notifications.error(response.error.message);
                    this.setState({isSubmitting: false});
                    return;
                }
                if (!!response.source) {
                    setPaymentSource(response.source.id, () => {
                        this.setState({isSubmitting: false});
                        if (!!managedSubscription && typeof managedSubscription === 'function') {
                            managedSubscription();
                        } else {
                            getProfile();
                            if (!!onSuccess && typeof onSuccess === 'function') {
                                onSuccess();
                            } else {
                                Notifications.success(<FormattedMessage id="page.settings.setup.creditCard.success" />);
                                onCancel();
                            }
                        }
                    }, () => {
                        this.setState({isSubmitting: false});
                    });
                }
            })
            .catch(() => {
                this.setState({isSubmitting: false});
                Notifications.error(<FormattedMessage id="page.settings.setup.creditCard.error" />);
            });
    };

    render() {
        const { user, onCancel = () => null } = this.props;

        return (
            <Formik
                onSubmit={this.handleSubmit}
                initialValues={{ owner: { name: user.name || '', email: user.email || '', phone: user.phone || '' } }}
                render={() => (
                    <Form>
                        <LoadingOverlay
                            active={this.state.isSubmitting}
                            text={<FormattedMessage id='common.processing'/>}
                            spinner={<PulseLoader color='white'/>}
                        >
                            <div className="modal-body pd-0 widget-2 company-info-form">
                                <div className="pd-x-10 pd-y-5">
                                    <div className="col-12 pd-t-20" />
                                    {
                                        this.state.sources.filter(s => s.is_default).length > 0 && 
                                            <div className="form-group col-12">
                                                <h6>
                                                    <FormattedMessage id="owner.currentPaymentSource" 
                                                        values={ {
                                                            brand: this.state.sources.filter(s => s.is_default)[0].brand, 
                                                            last4: this.state.sources.filter(s => s.is_default)[0].last4
                                                        } } />
                                                </h6>
                                                <hr/>
                                            </div>
                                    }
                                    <div className="form-group col-12">
                                        <Fields.Input name="owner.name" />
                                    </div>
                                    <div className="form-group col-12">
                                        <Fields.Input name="owner.email" />
                                    </div>
                                    <div className="form-group col-12">
                                        <Fields.Phone name="owner.phone" />
                                    </div>
                                    <div className="form-group col-12">
                                        <div className="cc-input-container">
                                            <CardElement {...this.createOptions()} />
                                        </div>
                                    </div>
                                    <div className="col-12 pd-t-10" />
                                </div>
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button type="submit" className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium">
                                    <FormattedMessage id="common.submit" />
                                </button>
                                <button type="button" className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium" onClick={onCancel}>
                                    <FormattedMessage id="common.cancel" />
                                </button>
                            </div>
                        </LoadingOverlay>
                    </Form>
                )}
            />
        );
    }
}
const CardForm = injectStripe(_CardForm);

class BillingInfo extends React.Component {
    render() {
        const { user, company, onCancel, setPaymentSource, getProfile, managedSubscription, listSources, onSuccess=null } = this.props;
        return (
            <div className="modal-dialog wd-500-force" role="document">
                <div className="modal-content bd-0">
                    <div className="modal-header pd-x-20">
                        <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                            <FormattedMessage id="page.settings.setup.creditCard" />
                        </h6>
                    </div>
                    <div className="modal-body pd-0 widget-2 company-info-form">
                        <Elements>
                            <CardForm
                                onCancel={onCancel}
                                user={user}
                                company={company}
                                setPaymentSource={setPaymentSource}
                                listSources={listSources}
                                managedSubscription={managedSubscription}
                                getProfile={getProfile}
                                onSuccess={onSuccess}
                            />
                        </Elements>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.identity.user,
    company: state.identity.company
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setPaymentSource,
            listSources,
            getProfile
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    {
        pure: false
    }
)(BillingInfo);
