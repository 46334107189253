import { createBrowserHistory } from 'history';

// function preserveQueryParameters(history, preserve, location) {
//     const currentQuery = queryString.parse(history.location.search);
//     if (currentQuery) {
//         const preservedQuery = {};
//         for (let p of preserve) {
//             const v = currentQuery[p];
//             if (v) {
//                 preservedQuery[p] = v;
//             }
//         }
//         if (location.search) {
//             Object.assign(preservedQuery, queryString.parse(location.search));
//         }
//         location.search = queryString.stringify(preservedQuery);
//     }
//     return location;
// }

function createLocationDescriptorObject(location, state) {
    if (typeof location === 'string') {
        const locationParts = location.split('?');
        const urlWithoutQuery = locationParts[0];
        let queryString = '';
        if (locationParts.length == 2) {
            queryString = `?${locationParts[1]}`;
        }
        return [{ pathname: urlWithoutQuery, search: queryString }, state];
    } else {
        return [location, state];
    }
}

function createPreserveQueryHistory(createHistory) {
    return (options) => {
        const history = createHistory(options);
        const oldPush = history.push;
        history.push = (path, state) => {
            oldPush.apply(history, createLocationDescriptorObject(path, state));
        };
        return history;
    };
}

export const history = createPreserveQueryHistory(createBrowserHistory)();

// export const history = (pathWithQuery) => {
//     const historyHandler = createBrowserHistory();
//     const location = window.location;
//     const urlWithoutQuery = `${location.protocol}//${location.host}${location.pathname}`;
//     const queryString = new URLSearchParams(window.location.search).toString();

// }
