export const TRANSACTION_FIELD_REVISE = `
    mutation M(
        $field_id: Long!
    ) {
        transaction_field_revise(
            input: {
                transaction_field_id: $field_id
            }
        ) {
            id
            transaction_id
            field_id
            value
            company_id: model_company_id
            order_by: field_order_by
            lot_id
            mapped_lot_id
            quantity
            cargo_unit_id
            in_revision
            input_lots {
                lot_id
                quantity
            }
            output_lots {
                lot_type
                quantity
                cargo_unit_id
            }
            use_mapped_value
            use_mapped_quantity_value
            use_mapped_unit_value
            restore_value
            restore_quantity_value
            restore_unit_value
            created_dot_all_consumed
            revised_remainder_quantity
            allow_user_transform
            use_user_transform
        }
    }
`;
