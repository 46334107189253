import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    getFieldTypes,
    setFields,
    setModelPropertiesVisibility,
    persistSingleFieldUpdate,
    persistBatchFieldUpdates,
    cleanUpFields,
    persistDeleteField,
    createField,
    setFieldMapping,
    clearFieldMapping,
    createFieldValue,
    updateField,
} from 'reducers/fields';

import { FormattedMessage, FormattedHTMLMessage, injectIntl} from 'react-intl';
import SimpleBar from 'simplebar-react';
import DraggableList from 'react-draggable-list';
import Tooltip from 'rc-tooltip';
import ReactModal from 'react-modal';
import FieldDefinition, { FieldTypes } from 'models/field';
import FieldProperties from './properties';
import FieldListItem from './listItem';
import { Popover, Position } from '@blueprintjs/core';
import { Formik, Form } from 'formik';
import { Fields } from 'components/fields';
import distinctColors from 'distinct-colors'
import { Notifications } from 'components/notifications';
import { loadModel } from 'reducers/models';
import Xarrow from "react-xarrows";
import { getGroupedSections, inheritMappedFieldProperties, compatibleDateFormats } from 'pages/events/eventUtils'

import { uuid } from '../../../../utilities/common';

class FieldNew extends React.Component {
    contentRef;

    constructor(props) {
        super(props);
        const { fields } = props;
        this.state = {
            searchString: '',
            modal: { open: false, type: undefined },
            selectedField: fields && fields.length > 0 ? fields[0].id : undefined,
            fieldMappingMode: false,
            renderCount: 0,
            arrowRenderCount: 0,
            dotFieldExpandedStates: [],
            propertiesRenderCount: 0,
            isAddingField: false,
            isCloningField: false,
            isChangingField: false,
            isMovingField: false,            
            selectedMultipleFieldsToDelete:[],
            isAddingFieldForMultiselect: false,
            mostRecentlySelected:0,
            isAddingMultiFields: 0,
            pinField: 0,
            commandKeyActive: false,
        };

        this.contentRef = React.createRef();
    }
    
    handlekeyDown = (event) => {
        const { isAddingMultiFields } = this.state;
        
        if( (isAddingMultiFields===0) && (event.key === 'Control' || event.key === 'Shift') ){
            this.setState({ commandKeyActive: true });            
            if ( event.key === 'Control' ){
                this.setState({ isAddingMultiFields: 1 });
            } else {
                this.setState({ isAddingMultiFields: 2 });
            }
        }
    }

    handlekeyUp = (event) => {
        this.setState({ commandKeyActive: false, isAddingFieldForMultiselect: false, mostRecentlySelected: 0, isAddingMultiFields: 0 , pinField:0 });
    }

    handleMouseClickOnProperties = (event) => {
        this.resetSelectedFields();
    }

    handleOnMouseClickOnFields = (event) => {
        const domNode = ReactDOM.findDOMNode(this.contentRef.current);
        if (domNode && domNode.contains(event.target)) {
            const { commandKeyActive } = this.state;
            if( !commandKeyActive  ) {
                this.resetSelectedFields();
            }
        }
    }

    resetSelectedFields = () => {
        this.clearSelectedFieldToDelete();
        this.setState({ commandKeyActive: false, selectedMultipleFieldsToDelete: [], isAddingFieldForMultiselect: false, mostRecentlySelected: 0, isAddingMultiFields: 0 , pinField:0 });
    }

    componentDidMount() {
        const { fieldTypes, getFieldTypes } = this.props;
        if (!fieldTypes) {
            getFieldTypes();
        }

        if (window.addEventListener) {
            window.removeEventListener("keydown", this.handlekeyDown);
            window.addEventListener('keydown', this.handlekeyDown);
            window.removeEventListener("keyup", this.handlekeyUp);
            window.addEventListener('keyup', this.handlekeyUp);            
            document.removeEventListener("click", this.handleOnMouseClickOnFields);
            document.addEventListener("click", this.handleOnMouseClickOnFields);
        } else {            
            window.detachEvent("onkeydown", this.handlekeyDown);
            window.attachEvent("onkeydown", this.handlekeyDown);
            window.detachEvent("onkeyup", this.handlekeyUp);
            window.attachEvent("onkeyup", this.handlekeyUp);
            document.detachEvent("onclick", this.handleOnMouseClickOnFields);
            document.attachEvent('onclick', this.handleOnMouseClickOnFields);
        }
    }

    handleEndSection = async (fieldId, updateOrder, addOrRemove, isCargo) =>{
        const { model, fields, setFields, fieldTypes,persistDeleteField, createField, reloadFieldMappings = () => null } = this.props;
        const { isChangingField } = this.state;
        if (isChangingField) return;
        this.setState({ isChangingField: true });

        if (isCargo){            
            //the dot fields cannot be inside a section
            const fieldToChange=  fields.find(f => f.id === fieldId);
            const startSection =  fields.find(f => f.id !== fieldToChange.id && f.order > fieldToChange.order && f.type === FieldTypes.FIELD_SECTION);
            const endSection =  fields.find(f => f.id !== fieldToChange.id && f.order > fieldToChange.order && f.type === FieldTypes.FIELD_END_SECTION);

            //if outside or bettween sections, the field it's not need to be moved
            if((!startSection && !endSection) || ( ( startSection && (startSection.order > fieldToChange.order)) && ( startSection && (endSection.order > startSection.order)) )){
                this.setState({ isChangingField: false });
                return;
            }

            //the field goes to the first position after the end of section
            const start = [...fields.filter(f => f.order <= endSection.order).filter(f => f.id !== fieldId).map((f, i) => ({ ...f, order: i + 1 }))];
            const end = [...fields.filter(f => f.order > endSection.order)];
            const order= start.length + 1;

            await new Promise(resolve => {
                const fieldsToManage= [... start, fieldToChange, ...end].map((f, i) => ({ ...f, order: i + 1 }));
                updateOrder(order);
                setFields(
                    fieldsToManage,
                    () => {
                        this.setState({ isChangingField: false })
                        resolve();
                    },
                    true
                );
            });

        } else if (addOrRemove){
            const fieldToChange =  fields.find(f => f.id === fieldId);
            const endSection =  fields.find(f => f.id !== fieldToChange.id && f.order > fieldToChange.order && f.type === FieldTypes.FIELD_END_SECTION);

            let insertField= false;
            if (endSection){
                const reverseList = [... fields].reverse();
                const startSection =  reverseList.find(f => f.id !== fieldToChange.id && f.order < endSection.order && f.type === FieldTypes.FIELD_SECTION);
                if ((startSection && endSection ) && ( ( startSection.order < endSection.order) && ( fieldToChange.order > startSection.order && fieldToChange.order < endSection.order) )){
                    insertField= true;
                }
            }

            if(insertField){
                const start = [...fields.filter(f => f.order <= endSection.order).filter(f => f.id !== fieldId).map((f, i) => ({ ...f, order: i + 1 }))];
                const end = [...fields.filter(f => f.order > endSection.order)];
                const order = start.length + 1; 

                const newEndSectionField = new FieldDefinition({ order: order, title:FieldTypes.FIELD_END_SECTION, type_id: fieldTypes.find(f => f.type_name === FieldTypes.FIELD_END_SECTION).id });
                await new Promise(resolve => {
                    createField({ ...newEndSectionField, model_id: model.id }, result => {
                        const f = FieldDefinition.fromDB(result);
                        const fieldsToManage= [... start,fieldToChange,f,...end].map((f, i) => ({ ...f, order: i + 1 }));
                        updateOrder(order);
                        setFields(
                            fieldsToManage,
                            () => {
                                this.setState({ isChangingField: false })
                                resolve();
                            },
                            true
                        );
                    });
                });
            } else {
                const orderIndex = fieldToChange.order;
                const start = [...fields.filter(f => f.order <= orderIndex)];
                const end = [...fields.filter(f => f.order > orderIndex)];
                
                const newEndSectionField = new FieldDefinition({ order: orderIndex + 1, title:FieldTypes.FIELD_END_SECTION , type_id: fieldTypes.find(f => f.type_name === FieldTypes.FIELD_END_SECTION).id });
                await new Promise(resolve => {
                    createField({ ...newEndSectionField, model_id: model.id }, result => {
                        const f = FieldDefinition.fromDB(result);
                        const fieldsToManage= [... start,f,...end].map((f, i) => ({ ...f, order: i + 1 }));
                        //updateOrder(order);
                        setFields(
                            fieldsToManage,
                            () => {
                                this.setState({ isChangingField: false })
                                resolve();
                            },
                            true
                        );
                    });
                })
            }

        } else {
            const refSectionField=  fields.find(f => f.id === fieldId);
            const foundEndSectionfield =  refSectionField && fields.find(f => f.id !== refSectionField.id && f.order > refSectionField.order && f.type === FieldTypes.FIELD_END_SECTION );

            if (foundEndSectionfield){
                const selectedField  =  foundEndSectionfield.id;
                if (!selectedField) {
                    this.setState({ isChangingField: false });
                    return;
                }
            
                this.setState({ ...this.state}, () => {
                    persistDeleteField((fields.find(f => f.id === selectedField) || {}).id, () => reloadFieldMappings());
                    setFields(
                        (fields || [])
                            .filter(f => f.id !== selectedField)
                            .map(f => {
                                if (f.map_dash_field === selectedField) {
                                    f.map_dash_field = null;
                                    f.map_dot_field = null;
                                    f.map_special_dot_field = null;
                                    f.compound_id = null;
                                }
                                return f;
                            }),
                            () => {
                                this.setState({ isChangingField: false })
                            },
                        true
                    );
                }); 
            }
        }
    };

    clearSelectedFieldToDelete(){
        document.querySelectorAll("[id^='field-item-']").forEach(e => {
            e.style.backgroundColor='';
        });

        document.querySelectorAll("*[class^='line-target-']").forEach(e => {
            e.style.backgroundColor='';
        });
    }
    
    drawSelectedFieldToDelete(){
        const { selectedMultipleFieldsToDelete, mostRecentlySelected, selectedField } = this.state;

        if ( selectedMultipleFieldsToDelete && selectedMultipleFieldsToDelete.length > 0 ){
            this.clearSelectedFieldToDelete();
            
            selectedMultipleFieldsToDelete.map( it => {                
                var sel= "[id='" + "field-item-" + it +"']";
                let color="";

                var item= document.querySelector(sel);
                if ( item ) {

                    if ( mostRecentlySelected === it) {
                        color="#001b3a22";
                    } else {
                        if( it !== selectedField) {
                            color="#001b3a10";
                        }
                    }

                    item.style.backgroundColor=color;
                    var item2= item.querySelector(".line-target-" + it);
                    if( item2){
                        if( it !== selectedField) {
                            item2.style.backgroundColor=color;
                        }
                    }
                }
            });
        }
    }

    componentDidUpdate(prevProps) {
        const modelVisibilityChanged = prevProps.modelVisible !== this.props.modelVisible;
        if (modelVisibilityChanged && this.props.modelVisible) {
            this.setState({ selectedField: undefined });
        }
        
        this.drawSelectedFieldToDelete();
    }

    componentWillUnmount() {
        const { cleanUpFields } = this.props;
        cleanUpFields();

        if (window.addEventListener) {
            // For standards-compliant web browsers
            window.removeEventListener("keydown", this.handlekeyDown);
            window.removeEventListener("Keyup", this.handlekeyUp);
            document.removeEventListener("click", this.handleOnMouseClickOnFields);            
        } else {
            window.detachEvent("onkeydown", this.handlekeyDown);
            window.detachEvent("onkeyup", this.handlekeyUp);            
            document.detachEvent("onclick", this.handleOnMouseClickOnFields);
        }
    }

    setSearchString = searchString => {
        this.setState({ searchString: searchString.toLowerCase(), selectedField: undefined });
    };

    setModalVisibility(visible, type) {
        this.setState({ modal: { open: visible }, type: visible ? type : undefined });
    }

    toggleDotFieldExpanded = id => {
        this.setState(prevState => {
            let allStates = prevState.dotFieldExpandedStates;
            const currentState = allStates.find(s => s.id === id);
            if (!currentState) {
                allStates.push({
                    id: id,
                    expanded: true,
                });
            } else {
                allStates = allStates.filter(s => s.id !== id);
                allStates.push({
                    id: id,
                    expanded: !currentState.expanded,
                });
            }
            return { dotFieldExpandedStates: allStates };
        })
    }

    addSelectedMultipleFieldsToDelete = id => {
        this.setState(prevState => {
            let fields = prevState.selectedMultipleFieldsToDelete;
            const exist = fields.find(s => s === id);
            if (!exist) {
                fields.push(id);
                this.setState({ selectedMultipleFieldsToDelete: fields });
            } else {
                var newvales= prevState.selectedMultipleFieldsToDelete.filter((prevItem) => prevItem !== id);
                const last = newvales ? ( newvales.length > 0 ? newvales[newvales.length-1] : undefined) : undefined;
                if ( !newvales || (newvales && newvales.length ===0 ) ){
                    this.clearSelectedFieldToDelete();
                }
                this.setState({ selectedMultipleFieldsToDelete: newvales, selectedField: last });
            }
        })
    }

    selectField = async targetField => {
        const id = targetField ? targetField.id : null;
        const { fields, setModelPropertiesVisibility, setFieldMapping, model, setFields, reloadFieldMappings = () => null } = this.props;
        if (this.state.fieldMappingMode) {
            if (targetField.disabled) return;
            
            let owningFieldId = this.state.selectedField;
            let owningDotFieldId = null;
            let owningSpecialDotFieldId = null;
            if (this.state.selectedDashFieldId) {
                owningFieldId = this.state.selectedDashFieldId;
                owningDotFieldId = this.state.selectedField;
                if (this.state.selectedField.endsWith('.l1') || this.state.selectedField.endsWith('.l2')) {
                    owningSpecialDotFieldId = this.state.selectedField.endsWith('.l1') ? 1 : 2;
                    owningDotFieldId = null;
                }
            }

            let mapDashFieldId = targetField.id;
            let mapDotFieldId = null;
            let mapSpecialDotFieldId = null;
            let compoundId = targetField.id;
            if (targetField.isDotField) {
                mapDashFieldId = targetField.dash_field_id;
                if (targetField.id.endsWith('.l1') || targetField.id.endsWith('.l2')) {
                    mapSpecialDotFieldId = targetField.id.endsWith('.l1') ? 1 : 2;
                    compoundId = `f-${targetField.dash_field_id}.${targetField.id}`;
                } else {
                    mapDotFieldId = targetField.id;
                    compoundId = `cc-${targetField.fullField.model_id}.f-${targetField.dash_field_id}.${targetField.id}`;

                }
            }
            
            // set field mapping
            this.setState({ fieldMappingMode: false });
            setFieldMapping(
                {
                    dash_template_id: model.id,
                    owning_dash_field_id: owningFieldId,
                    owning_dot_field_id: owningDotFieldId,
                    owning_special_dot_field_id: owningSpecialDotFieldId,
                    map_dash_field_id: mapDashFieldId,
                    map_dot_field_id: mapDotFieldId,
                    map_special_dot_field: mapSpecialDotFieldId,
                    compound_id: compoundId
                },
                () => {
                    reloadFieldMappings();
                    setFields(
                        (fields || []).map(f => {
                            if (f.id === this.state.selectedField) {
                                f.map_dash_field = mapDashFieldId;
                                f.map_dot_field = mapDotFieldId;
                                f.map_special_dot_field = mapSpecialDotFieldId;
                                f.compound_id = compoundId;
                            }
                            return f;
                        }), 
                        () => null, 
                        true
                    );
                    Notifications.success(<FormattedMessage id="fieldMappingSuccessfullySet" />)
                }
            );
        } else {
            await setModelPropertiesVisibility(false);
            
            const { isAddingMultiFields, isAddingFieldForMultiselect, selectedField, selectedMultipleFieldsToDelete, pinField } = this.state;

            const fieldId = fields && fields.length > 0 ? fields[0].id : undefined;
            this.setState({ selectedField: id || fieldId, selectedDashFieldId: targetField ? targetField.dash_field_id : null });

            const newid= id || fieldId;

            if( !isAddingFieldForMultiselect && ( isAddingMultiFields !==0 )){
                if ( isAddingMultiFields === 1 ){
                    let addme= true;
                    if ( selectedMultipleFieldsToDelete.length === 0 ){
                        if( selectedField ){
                            this.addSelectedMultipleFieldsToDelete(selectedField);
                            if( selectedField === newid ){
                                addme= false;
                            }
                        } else {                            
                            this.addSelectedMultipleFieldsToDelete(newid);
                            addme= false;
                        }
                    }

                    if( addme ){
                        this.addSelectedMultipleFieldsToDelete(newid);
                    }                    

                } else if ( isAddingMultiFields === 2 ){
                    let tempPin= pinField;
                    let normalFlow= true;

                    if( pinField === 0){
                        if( selectedField ){
                            this.setState({ pinField: `${selectedField}` });
                            tempPin= selectedField;
                        } else {
                            normalFlow= false;
                            this.setState({ selectedMultipleFieldsToDelete: [] });
                            this.addSelectedMultipleFieldsToDelete(newid);
                        }
                    }

                    if( normalFlow ){

                        this.setState({ selectedMultipleFieldsToDelete: [] });

                        const prev= fields.find(f => f.id=== tempPin);
                        const curr= fields.find(f => f.id=== newid);

                        if ( prev.order < curr.order ){
                            let selFields= fields.filter(f => f.order >= prev.order && f.order <= curr.order);
                            selFields.map( f => {
                                this.addSelectedMultipleFieldsToDelete(f.id);
                            });
                        } else {                        
                            let selFields= fields.filter(f => f.order >= curr.order && f.order <= prev.order);
                            selFields.map( f => {
                                this.addSelectedMultipleFieldsToDelete(f.id);
                            });
                        }
                    }
                }

                this.setState({ mostRecentlySelected: `${newid}` });
                this.drawSelectedFieldToDelete();
            }

            this.setState({ isAddingFieldForMultiselect: false });
        }
    };

    updateOrder = list => {
        const { setFields } = this.props;
        const orderedList = list.filter(f => !f.isDotField).map((item, index) => ({ ...item, order: index + 1 }));

        setFields(orderedList);
        this.setState(prevState => ({ propertiesRenderCount: prevState.propertiesRenderCount + 1 }))
        
    };

    addField = () => {
        const { selectedField, isAddingField } = this.state;
        const { model, fields, setFields, fieldTypes, persistSingleFieldUpdate, createField } = this.props;
        if (isAddingField) return;
        this.setState({ isAddingField: true });
        this.setState({ isAddingFieldForMultiselect: true });
        if (selectedField && fields.find(f => f.id === selectedField)) {
            const orderIndex = fields.find(f => f.id === selectedField).order;
            const start = [...fields.filter(f => f.order <= orderIndex)];
            const end = [...fields.filter(f => f.order > orderIndex)];
            const newField = new FieldDefinition({ order: orderIndex + 1, type_id: fieldTypes.find(f => f.type_name === FieldTypes.FIELD_TEXT).id });
            createField({ ...newField, model_id: model.id }, result => {
                const f = FieldDefinition.fromDB(result);
                const newFields = [...start, f, ...end].map((f, i) => ({ ...f, order: i + 1 }));
                setFields(newFields, () => {
                    this.selectField(f)
                    this.setState({ isAddingField: false });
                });
            });
        } else {
            const order = fields ? fields.length + 1 : 1;
            const newOrderedField = new FieldDefinition({ order, type_id: fieldTypes.find(f => f.type_name === FieldTypes.FIELD_TEXT).id });
            createField({ ...newOrderedField, model_id: model.id }, result => {
                const f = FieldDefinition.fromDB(result);
                setFields(
                    [...(fields || []), f],
                    () => {
                        persistSingleFieldUpdate(model.id, f);
                        this.selectField(f);
                        this.setState({ isAddingField: false });
                    },
                    true
                );
            });
        }
    };
    
    removeSelectedFields = () => {
        const { fields, setFields, persistDeleteField, persistBatchFieldUpdates, reloadFieldMappings = () => null, model } = this.props;
        const { selectedMultipleFieldsToDelete, isAddingMultiFields } = this.state;

        if ( (!selectedMultipleFieldsToDelete || selectedMultipleFieldsToDelete.length === 0 ) && (isAddingMultiFields === 0) ) return;

        let updatedFields= [];
        let currentFields = fields;
        let endSectionToRemove=[];

        selectedMultipleFieldsToDelete.map( removeFieldId => {
            let newFields= currentFields;
            const refSectionField=  fields.find(f => f.id === removeFieldId);

            if ( refSectionField && refSectionField.type === FieldTypes.FIELD_END_SECTION ){
                endSectionToRemove.push(refSectionField.id);
                return true; //continue
            }

            if ( refSectionField && refSectionField.type === FieldTypes.FIELD_SECTION ){
                const foundEndSectionfield =  refSectionField && fields.find(f => f.id !== refSectionField.id && f.order > refSectionField.order && f.type === FieldTypes.FIELD_END_SECTION);
                if (foundEndSectionfield){
                    persistDeleteField((fields.find(f => f.id === foundEndSectionfield.id) || {}).id, () => reloadFieldMappings());
                    newFields = (newFields || []).filter(f => f.id !== foundEndSectionfield.id);
                    currentFields = newFields;
                }
            }             
            
            persistDeleteField((fields.find(f => f.id === removeFieldId) || {}).id, () => reloadFieldMappings());
            currentFields = (currentFields || []).filter(f => f.id !== removeFieldId);            
        });

        //endSectionToRemove
        let selectedMultipleFieldsToDeleteTemp= (selectedMultipleFieldsToDelete || []).filter(id => {
            return !endSectionToRemove.includes(id);
        });

        // remove all fields that are marked for deletion
        currentFields = (currentFields || []).filter(f => {
            return !selectedMultipleFieldsToDeleteTemp.includes(f.id);
        });
        
        selectedMultipleFieldsToDeleteTemp.map( removeFieldId => {
            updatedFields = (currentFields || [])
                .filter(f => f.id !== removeFieldId  )
                .map(f => {
                    if (f.map_dash_field === removeFieldId) {
                        f.map_dash_field = null;
                        f.map_dot_field = null;
                        f.map_special_dot_field = null;
                        f.compound_id = null;
                    }
                    return f;
            }).map((item, index) => ({ ...item, order: index + 1 }));
        });

        setFields(
            updatedFields,
            () => {
                if (updatedFields.length > 0){
                    persistBatchFieldUpdates(model.id, updatedFields)
                }        
            },
            true
        );
        
        this.setState({ commandKeyActive: false, selectedField: undefined, selectedMultipleFieldsToDelete: [], isAddingFieldForMultiselect: false, mostRecentlySelected: 0, isAddingMultiFields: 0 , pinField:0 });
        this.clearSelectedFieldToDelete();
        
    };

    removeField = () => {
        const { fields, setFields, persistDeleteField, persistBatchFieldUpdates, reloadFieldMappings = () => null, model } = this.props;
        const { selectedField, selectedMultipleFieldsToDelete } = this.state;
        if (!selectedField) return;

        if ( selectedMultipleFieldsToDelete && selectedMultipleFieldsToDelete.length > 0 ){
            this.removeSelectedFields();
            return ;
        }

        let newFields= fields;
        const refSectionField=  fields.find(f => f.id === selectedField);
        if ( refSectionField.type === FieldTypes.FIELD_SECTION){
            const foundEndSectionfield =  refSectionField && fields.find(f => f.id !== refSectionField.id && f.order > refSectionField.order && f.type === FieldTypes.FIELD_END_SECTION);
            if (foundEndSectionfield){
                persistDeleteField((fields.find(f => f.id === foundEndSectionfield.id) || {}).id, () => reloadFieldMappings());
                newFields = (fields || []).filter(f => f.id !== foundEndSectionfield.id);
            }
        } 
        
        this.setState({ ...this.state, selectedField: undefined }, () => {
            persistDeleteField((fields.find(f => f.id === selectedField) || {}).id, () => reloadFieldMappings());
            const updatedFields = (newFields || [])
                .filter(f => f.id !== selectedField  )
                .map(f => {
                    if (f.map_dash_field === selectedField) {
                        f.map_dash_field = null;
                        f.map_dot_field = null;
                        f.map_special_dot_field = null;
                        f.compound_id = null;
                    }
                    return f;
                }).map((item, index) => ({ ...item, order: index + 1 }));
                setFields(
                    updatedFields,
                    () => {
                        if (updatedFields.length > 0){
                            persistBatchFieldUpdates(model.id, updatedFields)
                        }
                    },
                    true
                );
        });
    };
    
    cloneField = () => {
        const { selectedField, isCloningField } = this.state;
        const { model, fields, setFields, fieldTypes, persistSingleFieldUpdate, createField , createFieldValue, updateField} = this.props;
        if (isCloningField) return;
        if (!selectedField) return;

        this.setState({ isCloningField: true });

        if (selectedField && fields.find(f => f.id === selectedField)) {
            const orderIndex = fields.find(f => f.id === selectedField).order;
            const start = [...fields.filter(f => f.order <= orderIndex)];
            const end = [...fields.filter(f => f.order > orderIndex)];
            const newField = new FieldDefinition({ order: orderIndex + 1, type_id: fieldTypes.find(f => f.type_name === FieldTypes.FIELD_SECTION).id });
            createField({ ...newField, model_id: model.id }, result => {

                const originalField= fields.find(f => f.id === selectedField);
                const db_f = FieldDefinition.fromDB(result);

                //create a copy, to manipulate the values
                const ftemp = JSON.parse(JSON.stringify(originalField));

                //merge fields from the newly created db field
                var re = /\(\*\)$/;
                ftemp.title= ftemp.title.replace(re, "")+ " (*)";
                
                ftemp.id= db_f.id;
                ftemp.order= db_f.order;
                ftemp.index_field_id= null;
                
                const f= JSON.parse(JSON.stringify(ftemp));

                if(ftemp.fieldValues && ftemp.fieldValues.length > 0){
                    //remove the old field values, so to be able to create new fields and associate them
                    f.fieldValues=[];
                    ftemp.fieldValues.forEach((fieldValue) =>{
                        const v = {id: uuid(), value: fieldValue.value, order: fieldValue.order_by,isHidden:false};
                        createFieldValue(f, { ...v, order_by: fieldValue.order_by, is_hidden: v.isHidden }, newValue => {
                            updateField({ ...f, fieldValues: [...(f.fieldValues || []), newValue] }, () => {
                                f.fieldValues.push(newValue);
                            });
                        });
                    });
                }

                const newFields = [...start, f, ...end].map((f, i) => ({ ...f, order: i + 1 }));
                setFields(newFields, () => {
                    this.selectField(f)
                    this.setState({ isCloningField: false });
                });
            });
        }
    }
    
    renderSearch = () => {
        const { searchString } = this.state;
        return (
            <Formik
                enableReinitialize={true}
                initialValues={{ searchString }}
                onSubmit={({ searchString }) => this.setSearchString(searchString)}
                render={({ handleChange, submitForm, setFieldValue }) => {
                    return (
                        <Form
                            noValidate
                            onChange={async e => {
                                await handleChange(e);
                                submitForm();
                            }}
                        >
                            <div className="fields-search">
                                <div className="row">
                                    <div className={`col pd-r-0`}>
                                        <Fields.Input type="text" name="searchString" />
                                    </div>
                                    <div className="col-4 pd-l-10">
                                        <button
                                            disabled={searchString === ''}
                                            type="button"
                                            className="btn btn-primary btn-block"
                                            onClick={async () => {
                                                if (searchString === '') return;
                                                await setFieldValue('searchString', '');
                                                this.setSearchString('');
                                            }}
                                        >
                                            <FormattedMessage id="common.clear" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            />
        );
    };

    getFieldsList = () => {
        const { fields, cargoCategories = [], showFieldMaps, model, templateFieldMappings = [], intl: { formatMessage } } = this.props;
        if (!fields) return [];
        const { selectedField, selectedDashFieldId, searchString } = this.state;

        const isFieldExpanded = id => {
            const { dotFieldExpandedStates } = this.state;
            const thisState = dotFieldExpandedStates.find(s => s.id === id);
            return thisState && thisState.expanded;
        }

        const fieldsWithMethods = fields
            .filter(f => f.title.toLowerCase().indexOf(searchString) > -1)
            .map(f => ({ ...f, selected: selectedField === f.id, onSelect: this.selectField, expanded: isFieldExpanded(f.id), toggleExpanded: this.toggleDotFieldExpanded }));
        const fieldsWithDotFields = [];
        let currentSelectedField = fields.find(f => f.id === selectedField);
        if (!currentSelectedField) {
            const dotFieldCargoCategory = (cargoCategories || []).find(cc => cc.model_field_list.find(f => f.id === selectedField));
            if (dotFieldCargoCategory) {
                currentSelectedField = dotFieldCargoCategory.model_field_list.find(f => f.id === selectedField);
                if (currentSelectedField) {
                    currentSelectedField = FieldDefinition.fromDB(currentSelectedField)
                    currentSelectedField.isDotField = true;
                    currentSelectedField.selectedDashFieldId = selectedDashFieldId;
                }
            } else if (selectedField && (selectedField.endsWith('.l1') || selectedField.endsWith('.l2'))) {
                const parentFieldId = selectedField.split('.')[0];
                if (selectedField.endsWith('.l1')) {
                    const quantityMapping = templateFieldMappings.find(fm => fm.owning_dash_field_id === parentFieldId && fm.owning_special_dot_field_id === 1) || {};
                    const { map_dash_field = null, map_dot_field = null, map_special_dot_field = null, map_compound_id = null } = quantityMapping;
                    currentSelectedField = new FieldDefinition({
                        id: selectedField, 
                        special_id: 1,
                        title: formatMessage({ id: 'quantity' }),
                        type: FieldTypes.FIELD_DOT_QUANTITY, 
                        map_dash_field,
                        map_dot_field,
                        map_special_dot_field,
                        map_compound_id,
                    });
                } else {
                    const unitMapping = templateFieldMappings.find(fm => fm.owning_dash_field_id === parentFieldId && fm.owning_special_dot_field_id === 2) || {};
                    const { map_dash_field = null, map_dot_field = null, map_special_dot_field = null, map_compound_id = null } = unitMapping;
                    currentSelectedField = new FieldDefinition({ 
                        id: selectedField, 
                        special_id: 2,
                        title: formatMessage({ id: 'common.cargoUnitName' }),
                        type: FieldTypes.FIELD_DOT_UNIT, 
                        map_dash_field,
                        map_dot_field,
                        map_special_dot_field,
                        map_compound_id,
                    });
                }
            }
        }
        let fieldColors = [];
        if (showFieldMaps) {
            const palette = distinctColors({count: templateFieldMappings.length});
            for (const mappedField of templateFieldMappings) {
                const existingFieldColor = fieldColors.find(f => f.dash_field_id === mappedField.owning_dash_field_id && ((!!f.dot_field_id && f.dot_field_id === mappedField.owning_dot_field_id) || (!!f.special_dot_field_id && f.special_dot_field_id=== mappedField.owning_special_dot_field_id)));
                if (existingFieldColor) {
                    // check the target of this map already has a color assigned - the owning field of this mapping may be colored from being the target of another map
                    const existingColoredTargetFieldColor =  fieldColors.find(f => f.dash_field_id === mappedField.map_dash_field_id && ((!!f.dot_field_id && f.dot_field_id === mappedField.map_dot_field_id) || (!!f.special_dot_field_id && f.special_dot_field_id === mappedField.map_special_dot_field)));
                    if (!existingColoredTargetFieldColor) {
                        fieldColors.push({ dash_field_id: mappedField.map_dash_field_id, dot_field_id: mappedField.map_dot_field_id, special_dot_field_id: mappedField.map_special_dot_field, color: existingFieldColor.color });
                    } else if (existingColoredTargetFieldColor.color !== existingFieldColor.color) {
                        // change the color of this field to match the target
                        fieldColors = fieldColors.filter(f => !(f.dash_field_id === mappedField.owning_dash_field_id && ((!!f.dot_field_id && f.dot_field_id === mappedField.owning_dot_field_id) || (!!f.special_dot_field_id && f.special_dot_field_id === mappedField.owning_special_dot_field_id))));
                        fieldColors.push({ dash_field_id: mappedField.owning_dash_field_id, dot_field_id: mappedField.owning_dot_field_id, special_dot_field_id: mappedField.owning_special_dot_field_id, color: existingColoredTargetFieldColor.color });
                        // if there's a field mapping to this one, ensure it also matches the new color
                        const fieldMappingToCurrent = templateFieldMappings.find(f => f.map_dash_field_id === mappedField.owning_dash_field_id && ((!!f.map_dot_field_id && f.map_dot_field_id === mappedField.owning_dot_field_id) || (!!f.map_special_dot_field && f.map_special_dot_field === mappedField.owning_special_dot_field_id)));
                        if (fieldMappingToCurrent) {
                            const sourceFieldColor = fieldColors.find(f => f.dash_field_id === fieldMappingToCurrent.owning_dash_field_id && ((!!f.dot_field_id && f.dot_field_id === fieldMappingToCurrent.owning_dot_field_id) || (!!f.special_dot_field_id && f.special_dot_field_id === fieldMappingToCurrent.owning_special_dot_field_id)));
                            if (sourceFieldColor) {
                                fieldColors = fieldColors.filter(f => !(f.dash_field_id === fieldMappingToCurrent.owning_dash_field_id && ((!!f.dot_field_id && f.dot_field_id === fieldMappingToCurrent.owning_dot_field_id) || (!!f.special_dot_field_id && f.special_dot_field_id === fieldMappingToCurrent.owning_special_dot_field_id))));
                            }
                            fieldColors.push({dash_field_id: fieldMappingToCurrent.owning_dash_field_id, dot_field_id: fieldMappingToCurrent.owning_dot_field_id, special_dot_field_id: fieldMappingToCurrent.owning_special_dot_field_id, color: existingColoredTargetFieldColor.color})
                        }
                    }
                    continue;
                }
                // if the field's target already has a color assigned, adopt that color
                const existingTargetFieldColor =  fieldColors.find(f => f.dash_field_id === mappedField.map_dash_field_id && ((!!f.dot_field_id && f.dot_field_id === mappedField.map_dot_field_id) || (!!f.special_dot_field_id && f.special_dot_field_id === mappedField.map_special_dot_field)));
                if (!!existingTargetFieldColor) {
                    fieldColors.push({ dash_field_id: mappedField.owning_dash_field_id, dot_field_id: mappedField.owning_dot_field_id, special_dot_field_id: mappedField.owning_special_dot_field_id, color: existingTargetFieldColor.color });
                    continue;
                }
                // if this field hasn't been assigned a color yet, pick the first available unused color and assign it to the field AND its target
                const unusedColors = palette.filter(p => !fieldColors.find(fc => fc.color === p.hex()));
                if (unusedColors.length > 0) {
                    fieldColors.push({ dash_field_id: mappedField.owning_dash_field_id, dot_field_id: mappedField.owning_dot_field_id, special_dot_field_id: mappedField.owning_special_dot_field_id, color: unusedColors[0].hex() });
                    fieldColors.push({ dash_field_id: mappedField.map_dash_field_id, dot_field_id: mappedField.map_dot_field_id, special_dot_field_id: mappedField.map_special_dot_field, color: unusedColors[0].hex() });
                }
            }
        }

        for (const field of fieldsWithMethods) {
            field.hasMapToOrFrom = !!(templateFieldMappings.find(fm => fm.map_dash_field_id === field.id || fm.owning_dash_field_id === field.id));
            field.fieldChildren = [];
            const fieldColor = fieldColors.find(f => String(f.dash_field_id) === String(field.id) && !f.dot_field_id && !f.special_dot_field_id);
            if (fieldColor) {
                field.color = fieldColor.color;
            }
            if (field.type === FieldTypes.FIELD_CARGO) {
                const targetDot = cargoCategories.find(cc => cc.id === field.cargoCategory);
                if (!targetDot) {
                    fieldsWithDotFields.push(field);
                    continue;
                }
                const dotFields = targetDot.model_field_list.map(f => FieldDefinition.fromDB(f));
                dotFields.push(new FieldDefinition({ id: field.id + '.l1', special_id: 1, title: <FormattedMessage id='quantity'/>, type: FieldTypes.FIELD_DOT_QUANTITY,  }));
                dotFields.push(new FieldDefinition({ id: field.id + '.l2', special_id: 2, title: <FormattedMessage id='common.cargoUnitName'/>, type: FieldTypes.FIELD_DOT_UNIT,  }));
                for (const dotField of dotFields) {
                    // note that while the dash fields will already have field mapping info populated, this is not computed
                    // on the backend for dot fields specifically because there can be multiple instances of the same dot template
                    // referenced within a dash template. Instead, we populate this info from the templateFieldMappings array
                    const dotFieldColor = fieldColors.find(f => String(f.dash_field_id) === String(field.id) && ((!!f.dot_field_id && f.dot_field_id === dotField.id) || (!!f.special_dot_field_id && f.special_dot_field_id === dotField.special_id)));
                    if (dotFieldColor) {
                        dotField.color = dotFieldColor.color;
                    }
                    dotField.selected = selectedField === dotField.id && selectedDashFieldId === field.id;
                    dotField.onSelect = this.selectField;
                    dotField.isDotField = true;
                    dotField.dash_field_id = field.id;
                    dotField.isParentExpanded = field.expanded;
                    dotField.hasMapToOrFrom = !!templateFieldMappings.find(fm => 
                        (fm.map_dash_field_id === field.id 
                            && (
                                (!!fm.map_dot_field_id && fm.map_dot_field_id === dotField.id)
                                || 
                                (!!fm.map_special_dot_field && fm.map_special_dot_field === dotField.special_id)
                            )
                        ) 
                        || 
                        (fm.owning_dash_field_id === field.id 
                            && (
                                (!!fm.owning_dot_field_id && fm.owning_dot_field_id === dotField.id)
                                || 
                                (!!fm.owning_special_dot_field_id && fm.owning_special_dot_field_id === dotField.special_id)
                            )
                        )
                    );
                    if (dotField.id === dotFields[dotFields.length - 1].id) {
                        dotField.lastChild = true;
                    }
                    const dotFieldMap = templateFieldMappings.find(fm => fm.owning_dash_field_id === field.id && ((!!fm.owning_dot_field_id && fm.owning_dot_field_id === dotField.id) || (!!fm.owning_special_dot_field_id && fm.owning_special_dot_field_id === dotField.special_id)));
                    if (dotFieldMap) {
                        dotField.hasMapToOrFrom = true;
                        dotField.map_dash_field = dotFieldMap.map_dash_field_id;
                        dotField.map_dot_field = dotFieldMap.map_dot_field_id;
                        dotField.map_special_dot_field = dotFieldMap.map_special_dot_field;
                        dotField.map_compound_id = dotFieldMap.compound_id;
                    }
                    if (this.state.fieldMappingMode) {
                        // the field is disabled if the fields are not the same type, it's already mapped to, and the field can not map to itself.
                        // for embedded dot fields, we have to check both the field ID and the parent field ID (selectedDashFieldId)
                        dotField.disabled = (
                            !currentSelectedField 
                            || dotField.type !== currentSelectedField.type 
                            || (dotField.id === currentSelectedField.id && selectedDashFieldId === field.id)
                            || field.cargoReferenceType !== 2 // if not output - disable field
                            || (dotField.hasMapToOrFrom && !dotField.map_dash_field)
                        );
                        // new - add field incompatibility text
                        // Add tool tip to fields type 'DOT FIELDS' - fields inside of dot field
                        if(dotField.disabled && dotField.isDotField === true) {
                            if(field.cargoReferenceType !== 2) { // if not output
                                dotField.map_disabled_reason = <FormattedMessage id={"fieldMappingDotRefTypeNotOutputTooltip"} /> 
                            }
                            else if (dotField.type !== currentSelectedField.type) {
                                dotField.map_disabled_reason = <FormattedMessage id={"fieldMapCompatibilityTooltip"} values={{origin: currentSelectedField.type, destination: dotField.type}} /> 
                            }                           
                        }
                        // Date formats
                        if (!!currentSelectedField && dotField.type === currentSelectedField.type && dotField.type === 'Date' && currentSelectedField.type === 'Date'){
                            this.checkCompatibleDateFormatsOnMapping(currentSelectedField, dotField);
                        }
                    }
                    field.fieldChildren.push(dotField);
                }
            }
            fieldsWithDotFields.push(field);
        }
        if (this.state.fieldMappingMode) {
            return fieldsWithDotFields.map( f => {
                // the field is disabled if the fields are not the same type, it's already mapped to, and the field can not map to itself.
                if (
                    !currentSelectedField 
                    || f.type !== currentSelectedField.type 
                    || f.id === currentSelectedField.id
                    || (f.hasMapToOrFrom && !f.map_dash_field)
                    ){
                    f.disabled = true;
                    // new - add field incompatibility text
                    // Add tool tip to fields NOT type 'DOT FIELDS'
                    if(f.disabled === true && f.type !== currentSelectedField.type && f.isDotField === undefined) {
                        if(f.type === FieldTypes.FIELD_CARGO) {
                            f.map_disabled_reason = <FormattedMessage id={"fieldMappingNotPossibleTooltip"} />
                        }
                        else {
                            f.map_disabled_reason = <FormattedMessage id={"fieldMapCompatibilityTooltip"} values={{origin: currentSelectedField.type, destination: f.type}}/>
                        }
                    }
                }
                // Date formats
                if (!!currentSelectedField && f.type === currentSelectedField.type && f.type === 'Date' && currentSelectedField.type === 'Date'){
                    this.checkCompatibleDateFormatsOnMapping(currentSelectedField, f);
                }

                return f;
            })
        }
        return fieldsWithDotFields;
    }

    checkCompatibleDateFormatsOnMapping = (currentSelectedDateField, dateField) => {
        var compatibleDF = compatibleDateFormats[currentSelectedDateField.dateFormat];
        if (compatibleDF.filter(frmt => frmt === dateField.dateFormat).length === 0) {
            dateField.disabled = true;
            dateField.map_disabled_reason = <FormattedMessage id={"fieldMappingIncompatibleDateFormatsTooltip"} />
        }
    }

    findFieldInListRecursively = (findCriteria, list) => {
        let targetField = list.find(findCriteria);
        if (!targetField) {
            // check if any dot fields (children of dot type dash fields) meet the criteria
            const targetFieldParent = list.find(f => f.type === FieldTypes.FIELD_CARGO && f.fieldChildren.find(findCriteria));
            if (targetFieldParent) {
                targetField = targetFieldParent.fieldChildren.find(findCriteria);
            }
        }
        return targetField;
    }

    
    render() {
        const { 
            selectedField, 
            modal, 
            searchString, 
            fieldMappingMode, 
            selectedDashFieldId,
            selectedMultipleFieldsToDelete } = this.state;

        const {
            fieldTypes,
            fields,
            model: { status_name },
            readOnly,
            intl,
        } = this.props;

        if (!fieldTypes) return null;
        const selectedFieldExists = selectedField && fields.filter(f => f.id === selectedField).length > 0;
        const modelIsEditable = status_name === 'Draft' && !readOnly;

        const modifiers = {
            arrow: { enabled: true },
            keepTogether: { enabled: true },
            preventOverflow: { enabled: false },
        };
        
        let fieldsList = this.getFieldsList().sort((a, b) => a.order > b.order ? 1 : -1);

        let title= null;
        for (const field of fieldsList){            
            if ( field.type === FieldTypes.FIELD_SECTION ){
                title= field.title;
            } else if ( field.type === FieldTypes.FIELD_END_SECTION ){
                if (title){                    
                    field.title= intl.formatMessage({ id: 'EndSection' }) + ' (' + title + ')';
                    title= null;
                }
            }
        }

        const currentSelectedField = this.findFieldInListRecursively(f => f.selected, fieldsList);
        const isSection= (currentSelectedField) && ( (currentSelectedField.type === FieldTypes.FIELD_SECTION));
        const isEndSection= (currentSelectedField) && ( (currentSelectedField.type === FieldTypes.FIELD_END_SECTION));
        const isMultiOperation = (selectedMultipleFieldsToDelete &&  selectedMultipleFieldsToDelete.length > 0);

        let line = null;
        let arrows = [];
        let selectedMappedField = this.findFieldInListRecursively(f => f.color && f.selected, fieldsList);
        if (selectedMappedField) {
            if (selectedMappedField.map_dash_field) {
                let arrowTo = selectedMappedField.map_dash_field;
                // If an arrow is pointing to/from a dot field, but the dot-type dash field isn't expanded, set the arrow to point to/from the collapsed parent dash field
                if (selectedMappedField.map_dot_field) {
                    const targetDotField = this.findFieldInListRecursively(f => f.id === selectedMappedField.map_dot_field && f.dash_field_id === selectedMappedField.map_dash_field, fieldsList);
                    if (targetDotField && targetDotField.isParentExpanded) {
                        arrowTo += `-${selectedMappedField.map_dot_field}`;
                    }
                } else if (selectedMappedField.map_special_dot_field) {
                    const targetSpecialDotField = this.findFieldInListRecursively(f => f.special_id === selectedMappedField.map_special_dot_field && f.dash_field_id === selectedMappedField.dash_field_id, fieldsList);
                    if (targetSpecialDotField && targetSpecialDotField.isParentExpanded) {
                        arrowTo += `-${selectedMappedField.map_dash_field}.l${selectedMappedField.map_special_dot_field}`;
                    }
                }
                let arrowFrom = selectedMappedField.id;
                if (selectedMappedField.isDotField) {
                    if (selectedMappedField.isParentExpanded) {
                        // if ()
                        arrowFrom = `${selectedMappedField.dash_field_id}-${selectedMappedField.id}`;
                        arrows.push({ from: `${selectedMappedField.dash_field_id}-${selectedMappedField.id}-padded`, to: `${selectedMappedField.dash_field_id}-${selectedMappedField.id}`, color: selectedMappedField.color, lineOnly: true });
                    } else {
                        arrowFrom = selectedMappedField.dash_field_id;
                    }
                }
                arrows.push({ from: arrowFrom, to: arrowTo, color: selectedMappedField.color });
            }
            let fieldMappingToCurrent = null;
            if (selectedMappedField.isDotField) {
                fieldMappingToCurrent = this.findFieldInListRecursively(f => f.map_dash_field === selectedMappedField.dash_field_id  && ((!!f.map_dot_field && f.map_dot_field === selectedMappedField.id) || (!!f.map_special_dot_field && f.map_special_dot_field === selectedMappedField.special_id)), fieldsList);
            } else {
                fieldMappingToCurrent = this.findFieldInListRecursively(f => f.map_dash_field === selectedMappedField.id, fieldsList);
            }
            if (fieldMappingToCurrent) {
                let arrowTo = fieldMappingToCurrent.map_dash_field;
                if (fieldMappingToCurrent.map_dot_field) {
                    if (selectedMappedField.isParentExpanded) {
                        arrowTo += `-${fieldMappingToCurrent.map_dot_field}`;
                    }
                } else if (fieldMappingToCurrent.map_special_dot_field) {
                    if (selectedMappedField.isParentExpanded) {
                        arrowTo += `-${fieldMappingToCurrent.map_dash_field}.l${fieldMappingToCurrent.map_special_dot_field}`;
                    }
                }
                let arrowFrom = fieldMappingToCurrent.id;
                if (fieldMappingToCurrent.isDotField) {
                    if (fieldMappingToCurrent.isParentExpanded) {
                        arrowFrom = `${fieldMappingToCurrent.dash_field_id}-${fieldMappingToCurrent.id}`;
                        arrows.push({ from: `${fieldMappingToCurrent.dash_field_id}-${fieldMappingToCurrent.id}-padded`, to: `${fieldMappingToCurrent.dash_field_id}-${fieldMappingToCurrent.id}`, color: selectedMappedField.color, lineOnly: true });
                    } else {
                        arrowFrom = fieldMappingToCurrent.dash_field_id;
                    }
                }
                arrows.push({ from: arrowFrom, to: arrowTo, color: selectedMappedField.color });
            }
        }

        if (selectedMappedField) {
            line = (
                <>
                {
                    arrows.map(ar => {
                        // construct the html node IDs for the fields pointing to one another
                        let startId = 'field-item-' + ar.from;
                        let endId = 'field-item-' + ar.to;
                        const endOfListId = 'end-of-field-list';
                        const startOfListId = 'start-of-field-list';
                        const startEl = document.getElementById(startId);
                        if (!startEl) {
                            return null;
                        }
                        const startElRect = startEl.getBoundingClientRect();
                        const endEl = document.getElementById(endId);
                        if (!endEl) {
                            return null;
                        }
                        const endElRect = endEl.getBoundingClientRect();
                        const startListRect = document.getElementById(startOfListId).getBoundingClientRect();
                        // if either field is above the top of the viewport or below the bottom, point the arrow instead
                        // to the appropriate bound instead of the element itself (which is off-screen)
                        let showHead = true;
                        if (startElRect.bottom >= (window.innerHeight || document.documentElement.clientHeight)) {
                            startId = endOfListId;
                        } else if (startElRect.top < startListRect.top) {
                            startId = startOfListId;
                        }
                        if (endElRect.bottom >= (window.innerHeight || document.documentElement.clientHeight)) {
                            if (startId === endOfListId) {
                                return null;
                            }
                            endId = endOfListId;
                            showHead = false;
                        } else if (endElRect.top < startListRect.top) {
                            if (startId === startOfListId) {
                                return null;
                            }
                            endId = startOfListId;
                            showHead = false;
                        }
                        let headSize = 5;
                        if (ar.lineOnly) {
                            headSize = 0;
                        } else if (!showHead) {
                            headSize= 3;
                        }
                        return (
                            <Xarrow
                                key={'line-' + ar.from + '-' + ar.to + '-' + this.state.arrowRenderCount}
                                start={startId}
                                end={endId}
                                color={ar.color}
                                startAnchor={{ position: 'left', offset: { rightness: -5 } }}
                                endAnchor='left'
                                path='grid'
                                curveness={2}
                                dashness={{ strokeLen: 10, nonStrokeLen: 5, animation: 0.5 }}
                                headSize={headSize}
                                advanced={{ passProps: { arrowBody: {}, arrowHead: {}, SVGcanvas: { className: 'arrow-svg-canvas' } } }}
                            />
                        )
                    })
                }
                </>
            );
        }
        
        const moveAttempt = (movedItem, oldIndex, newIndex, newList, fieldsList, updateOrder) => {
            if (!modelIsEditable) return;

            const { isMovingField, isChangingField } = this.state;

            if (isChangingField) return;

            if (isMovingField) return;
            this.setState({ isMovingField: true });

            if (movedItem.type === FieldTypes.FIELD_CARGO){
                let doit= false;
                const order= newIndex + 1;
                const reverseList = [... fieldsList].reverse();
                
                const startSection= reverseList.find(i => i.order <= order && i.type === FieldTypes.FIELD_SECTION );
                if(startSection){
                    const endSection= fieldsList.find(i => i.order > startSection.order && i.type === FieldTypes.FIELD_END_SECTION );
                    if ((newIndex > oldIndex) && (order >= startSection.order && order < endSection.order)){
                        doit= false;
                    } else if ((newIndex < oldIndex) && (order > startSection.order && order <= endSection.order)){
                        doit= false;
                    }
                    else {
                        doit= true;
                    }
                } else {
                    doit= true;
                }

                if (doit) {
                    updateOrder(newList);
                } else {
                    updateOrder(fieldsList);
                }

            } else if (movedItem.type === FieldTypes.FIELD_END_SECTION){
                const reverseList = [... fieldsList].reverse();
                const order= fieldsList[oldIndex].order;
                let doit= false;

                const firstSection= reverseList.find(i => i.order < order && i.type === FieldTypes.FIELD_SECTION );
                if(firstSection && firstSection.order <= newIndex){
                    doit= true;
                }

                const lastSection= fieldsList.find(i => i.order > order && i.type === FieldTypes.FIELD_SECTION );
                if (lastSection && ((newIndex +1) >= lastSection.order) ){
                    doit= false;
                }

                const newOrder= (newIndex +1);
                const doteDown= fieldsList.find(i => i.order > order && i.type === FieldTypes.FIELD_CARGO );
                if( doteDown && ( newOrder >= doteDown.order)) {
                    doit= false;    
                }

                if (doit) {
                    updateOrder(newList);
                } else {
                    updateOrder(fieldsList);
                }

            } else if ( movedItem.type == FieldTypes.FIELD_SECTION){
                let doit= false;
                let order= fieldsList[oldIndex].order;

                const closeEndSection= fieldsList.find(i => i.order > order && i.type === FieldTypes.FIELD_END_SECTION);
                if(closeEndSection && ( (newIndex + 1) < closeEndSection.order ) ){
                    doit= true;
                }

                const reverseList = [... fieldsList].reverse();
                const beforeEndSection= reverseList.find(i => i.order < order && i.type === FieldTypes.FIELD_END_SECTION);
                if (beforeEndSection && ((newIndex +1) <= beforeEndSection.order)){
                    doit= false;
                }

                const newOrder= (newIndex +1);
                const doteUp= reverseList.find(i => i.order <= order && i.type === FieldTypes.FIELD_CARGO );
                if(doteUp && ( newOrder <= doteUp.order)){
                    doit= false;
                }
                if (doit) {
                    updateOrder(newList);
                } else {
                    updateOrder(fieldsList);
                }

            } else {
                updateOrder(newList);
            }

            this.setState({ isMovingField: false });
        };

        return (
            <React.Fragment>
                <div className="col-md-6 col-lg-3 col-xl-2 pd-l-0 pd-r-5">
                    <div className="fields-list widget-2 mg-b-20 overflow-y-hidden">
                        {line}
                        {/* <div id='arrow-div' style={{height: 'calc(100vh - 180px)', overflow: 'hidden'}}> */}
                            <div className="card overflow-hidden">
                                <div className="card-header">
                                    <h6 className="card-title" style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                                        <FormattedMessage id="models.fields.formFields" /> {fields ? `(${fields.length})` : null}
                                    </h6>
                                    <div className="btn-group field-list-buttons">
                                        <Popover content={this.renderSearch()} position={Position.LEFT_BOTTOM} modifiers={modifiers} >
                                            <span className={`btn ${searchString !== '' ? 'search-active' : ''}`}>
                                                <Tooltip placement={'top'} trigger={['hover']} overlay={<FormattedMessage id="models.fields.searchFields" />}>                                        
                                                    <i className={`material-icons dorae_fieldSearch ${this.state.searchString !== '' ? 'dorae_fieldSearchActive': ''}`}>{`${this.state.searchString !== '' ? 'manage_search' : 'search'}`}</i>
                                                </Tooltip>
                                            </span>
                                        </Popover>
                                        <span
                                              className={`btn ${(selectedField && selectedFieldExists && modelIsEditable && !fieldMappingMode && !this.state.isCloningField && !isEndSection && !isSection && !isMultiOperation) ? '' : 'disabled'}`}
                                              onClick={() => ((selectedField && selectedFieldExists && modelIsEditable && !fieldMappingMode && !isEndSection && !isSection && !isMultiOperation) ? this.cloneField() : null)}
                                        >
                                            <Tooltip placement={'top'} trigger={[`${ (isEndSection || isSection)? '':'hover' }`]} overlay={<FormattedMessage id="models.fields.cloneField" />}>
                                                <i className="material-icons"><span className="material-icons">content_copy</span></i>
                                            </Tooltip>
                                        </span>
                                        <span
                                            className={`btn ${(selectedField && selectedFieldExists && modelIsEditable && !fieldMappingMode && !isEndSection) ? '' : 'disabled'}`}
                                            onClick={() => ((selectedField && selectedFieldExists && modelIsEditable && !fieldMappingMode && !isEndSection) ? this.setModalVisibility(true) : null)}
                                        >
                                            <Tooltip placement={'top'} trigger={[`${ isEndSection? '':'hover' }`] } 
                                                overlay={ isMultiOperation ? <FormattedMessage id="models.fields.removeFields" /> : <FormattedMessage id="models.fields.removeField" /> }>
                                                <i className="material-icons">remove</i>
                                            </Tooltip>

                                        </span>
                                        <span className={`btn ${(modelIsEditable && !fieldMappingMode && !this.state.isAddingField && !isMultiOperation) ? '' : 'disabled'}`} onClick={() => ((modelIsEditable&& !fieldMappingMode&& !isMultiOperation) ? this.addField() : null)}>
                                            <Tooltip placement={'top'} trigger={['hover']} overlay={<FormattedMessage id="models.fields.addField" />}>
                                                <i className="material-icons">add</i>
                                            </Tooltip>
                                        </span>
                                    </div>
                                </div>
                                <div ref={this.contentRef} className="card-body pd-0 bd-color-gray-lighter">
                                    <div className="row no-gutters">
                                        <div className="col-12">
                                            <div 
                                                id='start-of-field-list' 
                                                style={{height: '1px', width: '100%'}}
                                            />
                                            <SimpleBar
                                                key={'dorae-simple-bar-'}
                                                onScroll={() => {
                                                    this.setState(prevState => ({ arrowRenderCount: prevState.arrowRenderCount + 1 }))
                                                }}
                                            >
                                                {fields && fields.length > 0 ? (
                                                    <div className="draggable-area dorae_fieldList" ref={el => (this.draggable = el)}>
                                                        {
                                                            this.state.fieldMappingMode ? 
                                                            <Tooltip placement='right' overlayClassName={'dorae_fieldsMappingToolTip'} visible={true} overlay={<FormattedMessage id='chooseFieldToMap' />} >
                                                                <div>
                                                                    <DraggableList
                                                                        itemKey="id"
                                                                        template={FieldListItem}
                                                                        padding={0}
                                                                        springConfig={{ stiffness: 300, damping: 50, className: 'xyz' }}
                                                                        list={fieldsList}
                                                                        onMoveEnd= { (newList, movedItem, oldIndex, newIndex) => moveAttempt(movedItem, oldIndex, newIndex, newList,fieldsList,this.updateOrder)}
                                                                        container={() => this.draggable}
                                                                    />
                                                                </div>
                                                            </Tooltip>
                                                            :
                                                            <DraggableList
                                                                itemKey="id"
                                                                template={FieldListItem}
                                                                padding={0}
                                                                springConfig={{ stiffness: 300, damping: 50, className: 'xyz' }}
                                                                list={fieldsList}
                                                                onMoveEnd= { (newList, movedItem, oldIndex, newIndex) => moveAttempt(movedItem, oldIndex, newIndex, newList,fieldsList,this.updateOrder)}
                                                                container={() => this.draggable}
                                                            />
                                                        }
                                                    </div>
                                                ) : (
                                                    <div className="pd-20 tx-center tx-12">
                                                        <FormattedMessage id="models.fields.noFields" />
                                                    </div>
                                                )}
                                            </SimpleBar>
                                            <div 
                                                id='end-of-field-list' 
                                                style={{height: '1px', width: '100%'}}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        {/* </div> */}
                    </div>
                </div>
                <FieldProperties
                    key={'field-properties-' + `-${this.state.propertiesRenderCount}`}
                    id={selectedField} 
                    dashFieldId={selectedDashFieldId}
                    selectField={this.selectField} 
                    currentSelectedField={currentSelectedField}
                    modelTypeId={this.props.modelTypeId} 
                    addCargoCategory={this.props.addCargoCategory}
                    onCargoTypeModalClose={(typeId) => {
                        if (this.props.reloadCargoType) {
                            this.props.reloadCargoType(typeId);
                        }
                    }}
                    handleEndSection={this.handleEndSection}
                    cargoCategories={this.props.cargoCategories}
                    readOnly={readOnly}
                    onFieldMapModeSet={(val) => this.setState({ fieldMappingMode: val })}
                    fieldMappingMode={this.state.fieldMappingMode}
                    onComplete={() => {
                        const { setFields, reloadFieldMappings = () => null } = this.props;
                        setFields(
                            (fields || [])
                                .map(f => {
                                    if (f.id === this.state.selectedField) {
                                        f.map_dash_field = null;
                                        f.map_dot_field = null;
                                        f.map_special_dot_field = null;
                                        f.compound_id = null;
                                    }
                                    return f;
                                }), 
                            () => reloadFieldMappings(),
                            true
                        );
                    }}
                    onMouseClick={this.handleMouseClickOnProperties}
                />
                {modal.open && (
                    <ReactModal
                        isOpen={modal.open}
                        onRequestClose={() => this.setModalVisibility(false)}
                        className="modal-block dialog"
                        overlayClassName="modal-overlay gray"
                    >
                        <div className="modal-dialog modal-sm" role="document">
                            <div className="modal-content bd-0">
                                <div className="modal-header pd-x-20">
                                    <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">Notice</h6>
                                </div>
                                <div className="modal-body pd-20">
                                    <p className="mg-b-5">
                                        { isMultiOperation ? 
                                            <FormattedHTMLMessage
                                                tagName="span" id="models.fields.confirmDeleteMultiple" />
                                            :
                                            <FormattedHTMLMessage
                                                tagName="span"
                                                id="models.fields.confirmDelete"
                                                values={{ id: (fields.find(f => f.id === selectedField) || {}).title }}
                                            />
                                        }
                                    </p>
                                </div>
                                <div className="modal-footer justify-content-center">
                                    <button
                                        type="button"
                                        className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                        onClick={() => {
                                            this.removeField();
                                            this.setModalVisibility(false);
                                        }}
                                    >
                                        <FormattedMessage id="common.yes" />
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                        onClick={() => {
                                            this.setModalVisibility(false);
                                            this.resetSelectedFields();
                                        }}
                                    >
                                        <FormattedMessage id="common.cancel" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ReactModal>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        fieldTypes: state.fields.types,
        model: state.fields.model,
        fields: state.fields.items,
        modelVisible: state.fields.modelVisible
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getFieldTypes,
            setFields,
            createField,
            setModelPropertiesVisibility,
            persistSingleFieldUpdate,
            persistBatchFieldUpdates,
            persistDeleteField,
            cleanUpFields,
            setFieldMapping,
            clearFieldMapping,
            loadModel,
            createFieldValue,
            updateField,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(FieldNew));
