/**
 * Login with email and password.
 */
export const OTP_ENABLE = `
    mutation _ {
        mfa_enable {
            success
        }
    }
`;
