export const VISIT_ENTITY_GRAPH = `

    type EntityData {
        entity_type	: String!
        entity_hash_id	: Id
        entity_id      : Long
    }

    mutation M (
        $entities: [EntityData!]
    )  
    {
        entity_graph_visit(
            input: {
                entities: $entities
            }
        )
        {
            visit_time
        }
    }
`;