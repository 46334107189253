import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Notifications } from 'components/notifications';
import Field, { FieldTypes } from 'models/field';
import 'pages/models/models.scss';
import { getComparators } from 'reducers/fieldVisibility';
import LoadingOverlay from 'react-loading-overlay';
import { arrayEquals } from 'utilities/common';
import ReactModal from 'react-modal';
import SimpleBar from 'simplebar-react';
import moment from 'moment';
import { filterTransactions } from 'reducers/transactions';
import { 
    getTransaction, 
    updateTransaction, 
    createTransaction, 
    getTransactionRelatedData, 
    getTransactionDeleteSideEffects, 
    transactionFieldRevise,
    getTransactionFieldSideEffectsDetails,
} from 'reducers/transaction';
import { loadModel } from 'reducers/models';
import InputLotModal from 'pages/events/components/inputLotModal';
import { listCargoUnits } from 'reducers/cargoUnits';
import { evaluateConditions } from 'pages/models/fields/components/visibilityConditions/conditionParser';
import { debounce } from 'utilities/common';
import { Prompt } from 'react-router';
import { getGroupedSections, inheritMappedFieldProperties } from 'pages/events/eventUtils'
import DotFieldPreview from 'pages/events/components/dotFieldPreview'
import Event from 'pages/events/components/event'
import ConfirmModal from 'components/modals/confirm'
import { formProps } from '__test_mocks/entities';

const SideEffectRelationTypes = {
    DOT_INPUT: 1,
    DOT_TRANSFORM: 2,
    REMAINDER: 3,
};

export class EventContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lotModalOpen: false,
            selectedLot: {},
            selectedLotField: {},
            columnVisibility: undefined,
            eventLots: [],
            transactionUpdated: false,
            transactionIsUpdating: false,
            cargoTypesExpanded: [],
            localLoading: true,
            finalSaveModalOpen: false,
            transaction: this.props.transaction,
            cargoFieldLots: {},
            dotFieldCreateNew: {},
            cargoTypes: [],
            allowReinitialize: true,
            cargoTypesShowPrefilledFields: [],
            showSaveComplete: false,
            eventCargoCategories: [],
            fieldMappings: [],
            touchedFieldIds: [],
            fieldMappingUsages: [],
            fieldsMappingToLockedDots: [],
            values: {},
            userTransformUsages: [],
        };
        Yup.addMethod(Yup.date, 'format', function (formats, parseStrict) {
            return this.transform(function (value, originalValue) {
              value = moment(originalValue, formats, parseStrict);
              return value.isValid() ? value.toDate() : new Date('');
            });
          });
    }

    componentDidMount() {
        this.setState({ localLoading: true, isLoading: true }, async () => {
            const initialVals = this.getInitialValues();
            this.setState({ values: initialVals, initialValues: initialVals });
            const {
                comparators, 
                getComparators,
                transaction,
                listCargoUnits,
                getTransactionRelatedData,
                getTransactionDeleteSideEffects,
                setPastVersions = () => null,
            } = this.props;
            const cargoFieldLots = {};
            const fieldMappingUsages = {};
            const userTransformUsages = {};
            for (const field of transaction.transaction_field_list) {
                cargoFieldLots[field.field_id] = {};
                if (transaction.status_id === 7 && field.in_revision && field.created_dot_all_consumed) {
                    cargoFieldLots[field.field_id]['quantity'] = field.revised_remainder_quantity;
                } else {
                    cargoFieldLots[field.field_id]['quantity'] = field.quantity;
                }
                fieldMappingUsages[field.field_id] = field.use_mapped_value;
                userTransformUsages[field.field_id] = field.use_user_transform;
            }
            this.setState({ cargoFieldLots });
            getTransactionDeleteSideEffects(transaction.id, (effects) => this.setState({ canNotUnwind: effects.has_effects_to_reverse && !effects.can_reverse_effects}))
            await new Promise(resolve => {
                getTransactionRelatedData(transaction.id, data => {
                    setPastVersions(data.past_versions);
                    const locked_fields = this.getFieldsMappingToLockedDotFields({ transaction, field_mappings: data.field_mappings, cargo_types: data.cargo_types });
                    for (const templateDotField of transaction.model.model_field_list.map(f => Field.fromDB(f)).filter(f => f.type === FieldTypes.FIELD_CARGO)) {
                        const dotField = transaction.transaction_field_list.find(f => f.field_id === templateDotField.id);
                        if (!dotField) continue;
                        const value = JSON.parse(dotField.value);
                        const dotTypeHashId = value[Object.keys(value)[0]];
                        const dotType = (data.cargo_types || []).find(dt => dt.id === dotTypeHashId);
                        if (!dotType) continue;
                        if (!fieldMappingUsages[dotTypeHashId]) {
                            fieldMappingUsages[dotTypeHashId] = {}
                        }
                        for (const dotTypeField of dotType.transaction_field_list) {
                            fieldMappingUsages[dotTypeHashId][dotTypeField.field_id] = dotTypeField.use_mapped_value;
                        }

                        fieldMappingUsages[templateDotField.id + '.l1'] = dotField.use_mapped_quantity_value;
                        fieldMappingUsages[templateDotField.id + '.l2'] = dotField.use_mapped_unit_value;
                        
                        if (!userTransformUsages[dotTypeHashId]) {
                            userTransformUsages[dotTypeHashId] = {}
                        }
                        for (const dotTypeField of dotType.transaction_field_list) {
                            userTransformUsages[dotTypeHashId][dotTypeField.field_id] = dotTypeField.use_user_transform;
                        }
                    }
                    this.setState({ 
                        eventCargoCategories: data.cargo_categories, 
                        cargoTypes: data.cargo_types, 
                        eventLots: data.lots, 
                        fieldMappings: data.field_mappings, 
                        fieldMappingUsages,
                        userTransformUsages,
                        fieldsMappingToLockedDots: locked_fields,
                    }, resolve)
                })
            }).then(_ => {
                const initialVals = this.getInitialValues();
                this.setState({ values: initialVals, initialValues: initialVals, localLoading: false, isLoading: false });
                if (this.props.onLoadComplete) {
                    this.props.onLoadComplete();
                }
            });
            
            if (!comparators) getComparators();

            if (!this.props.cargoUnits || this.props.cargoUnits.length === 0) listCargoUnits();
            
            window.addEventListener('beforeunload', (event) => {
                if (this.state.transactionIsUpdating) {
                    event.preventDefault();
                    event.returnValue = 'You have unfinished changes!';
                    this.setState({ showSaveComplete: true });
                }
            });
        });
    }

    setFieldValue = debounce(
        (name, val) => {
            this.setState(prevState => {
                const prevValues = prevState.values;
                const strName = new String(name);
                if (!!strName) {
                    if (strName.includes('.')) {
                        const nameParts = strName.split('.');
                        if (nameParts.length == 2) {
                            const dotId = nameParts[0];
                            const fieldId = nameParts[1];
                            let dotFields = prevValues[dotId];
                            if (!dotFields) {
                                dotFields = [];
                            }
                            dotFields[fieldId] = val;
                            prevValues[dotId] = dotFields;
                        }
                    } else {
                        prevValues[strName] = val;
                    }
                }
                return prevValues;
            }, this.submitForm);
        },
        500
    );

    submitForm = () => {
        this.updateTransactionDebounced(this.state.values);
    }

    async componentWillUnmount() {
        await this.submitTransaction();
        clearInterval(this.updateInterval);
    }

    getFieldsMappingToLockedDotFields = (args = null) => {
        const { transaction, field_mappings, cargo_types = [], fieldMappingUsages = null } = args;
        const locked_fields = [];
        // if the dash is in revision but this dot type field is not, disable any fields mapping to the dot type field
        for (const templateDotField of transaction.model.model_field_list.map(f => Field.fromDB(f)).filter(f => f.type === FieldTypes.FIELD_CARGO)) {
            const dotField = transaction.transaction_field_list.find(f => f.field_id === templateDotField.id);
            if (transaction.status_id === 7 && !dotField.in_revision) {
                const checked_fields = [];
                const fieldMappingsToDotField = field_mappings.filter(fm => fm.map_dash_field_id === templateDotField.id && fm.owning_dash_field_id !== templateDotField.id);
                for (const mapping of fieldMappingsToDotField) {
                    const targetFieldValue = JSON.parse(dotField.value);
                    const targetDotTypeHashId = targetFieldValue[Object.keys(targetFieldValue)[0]];
                    const targetDotType = cargo_types.find(dt => dt.id === targetDotTypeHashId);
                    if (!targetDotType) continue;
                    if (mapping.map_special_dot_field) {
                        checked_fields.push({ dash_field: mapping.owning_dash_field_id, special_dot_field: mapping.owning_special_dot_field_id });
                    } else {
                        const targetField = targetDotType.transaction_field_list.find(f => f.field_id === mapping.map_dot_field_id);
                        if (!targetField.use_mapped_value) {
                            continue;
                        }
                        checked_fields.push({ dash_field: mapping.owning_dash_field_id, dot_field: mapping.owning_dot_field_id });
                    }
                    const sourceDashField = transaction.transaction_field_list.find(f => f.field_id === mapping.owning_dash_field_id);
                    let sourceDotField = null;
                    const sourceDashFieldUseMapping = fieldMappingUsages ? fieldMappingUsages[sourceDashField.field_id] : sourceDashField.use_mapped_value;
                    if (mapping.owning_dot_field_id) {
                        const sourceFieldValue = JSON.parse(sourceDashField.value);
                        const sourceDotTypeHashId = sourceFieldValue[Object.keys(sourceFieldValue)[0]];
                        const sourceDotType = cargo_types.find(dt => dt.id === sourceDotTypeHashId);
                        if (!sourceDotType) continue;
                        sourceDotField = sourceDotType.transaction_field_list.find(f => f.field_id === mapping.owning_dot_field_id);
                        const sourceDotFieldUseMapping = (fieldMappingUsages && fieldMappingUsages[sourceDashField.field_id]) ? fieldMappingUsages[sourceDashField.field_id][sourceDotField.field_id] : sourceDotField.use_mapped_value;
                        if (!sourceDotFieldUseMapping) {
                            locked_fields.push({ dash_field: sourceDashField.field_id, dot_field: sourceDotField.field_id });
                            continue;
                        }
                    } else if (mapping.owning_special_dot_field_id) {
                        const sourceSpecialDotFieldUseMapping = (fieldMappingUsages && fieldMappingUsages[sourceDashField.field_id]) ? fieldMappingUsages[sourceDashField.field_id]['l' + mapping.owning_special_dot_field_id] : null;
                        if (sourceSpecialDotFieldUseMapping === false) {
                            locked_fields.push({ dash_field: sourceDashField.field_id, special_dot_field: mapping.owning_special_dot_field_id })
                            continue;
                        }
                    } else if (!sourceDashFieldUseMapping) {
                        locked_fields.push({ dash_field: sourceDashField.field_id, dot_field: null });
                        continue;
                    }
                    // follow chains of mapping fields upstream until we find the non-mapped source, which needs to be locked
                    let mappingToSource = field_mappings.find(fm => fm.map_dash_field_id === sourceDashField.field_id && (!sourceDotField || fm.map_dot_field_id === sourceDotField.field_id) && fm.map_special_dot_field === mapping.owning_special_dot_field_id);
                    if (!mappingToSource) {
                        // this field is the top of its chain - lock it up
                        locked_fields.push({ dash_field: sourceDashField.field_id, dot_field: sourceDotField ? sourceDotField.field_id : null, special_dot_field: mapping.owning_special_dot_field_id });
                        continue;
                    }
                    let previousMapping = mappingToSource;
                    while (mappingToSource) {
                        if (checked_fields.find(cf => cf.dash_field === mappingToSource.owning_dash_field_id && cf.dot_field === mappingToSource.owning_dot_field_id && cf.special_dot_field === mappingToSource.owning_special_dot_field_id)) {
                            break;
                        } else {
                            checked_fields.push({ dash_field: mappingToSource.owning_dash_field_id, dot_field: mappingToSource.owning_dot_field_id, special_dot_field: mappingToSource.owning_special_dot_field_id });
                        }
                        const ancestorDashField = transaction.transaction_field_list.find(f => f.field_id === mapping.owning_dash_field_id);
                        let ancestorDotField = null;
                        const ancestorDashFieldUseMapping = fieldMappingUsages ? fieldMappingUsages[ancestorDashField.field_id] : ancestorDashField.use_mapped_value;
                        if (mappingToSource.owning_dot_field_id) {
                            const ancestorFieldValue = JSON.parse(ancestorDashField.value);
                            const ancestorDotTypeHashId = ancestorFieldValue[Object.keys(ancestorFieldValue)[0]];
                            const ancestorDotType = cargo_types.find(dt => dt.id === ancestorDotTypeHashId);
                            if (!ancestorDotType) break;
                            ancestorDotField = ancestorDotType.transaction_field_list.find(f => f.field_id === mappingToSource.owning_dot_field_id);
                            let ancestorDotFieldUseMapping = true;
                            if (fieldMappingUsages && fieldMappingUsages[ancestorDashField.field_id]) {
                                ancestorDotFieldUseMapping = fieldMappingUsages[ancestorDashField.field_id][sourceDotField.field_id];
                            } else if (ancestorDotField) {
                                ancestorDotFieldUseMapping = ancestorDotField.use_mapped_value;
                            }
                            if (!ancestorDotFieldUseMapping) {
                                locked_fields.push({ dash_field: ancestorDashField.field_id, dot_field: ancestorDotField.field_id });
                                break;
                            }
                        } else if (mappingToSource.owning_special_dot_field_id) {
                            const ancestorSpecialDotFieldUseMapping = (fieldMappingUsages && fieldMappingUsages[ancestorDashField.field_id]) ? fieldMappingUsages[ancestorDashField.field_id]['l' + mapping.owning_special_dot_field_id] : null;
                            if (ancestorSpecialDotFieldUseMapping === false) {
                                locked_fields.push({ dash_field: ancestorDashField.field_id, special_dot_field: mapping.owning_special_dot_field_id })
                                continue;
                            }
                        } else if (!ancestorDashFieldUseMapping) {
                            locked_fields.push({ dash_field: ancestorDashField.field_id, dot_field: null });
                            break;
                        }
                        previousMapping = mappingToSource;
                        mappingToSource = field_mappings.find(fm => fm.map_dash_field_id === ancestorDashField.field_id && (!ancestorDotField || fm.map_dot_field_id === ancestorDotField.field_id) && fm.map_special_dot_field === previousMapping.owning_special_dot_field_id);
                    }
                    locked_fields.push({ dash_field: previousMapping.owning_dash_field_id, dot_field: previousMapping.owning_dot_field_id });
                }
            }
        }
        return locked_fields;
    }

    getInitialValues = () => {
        const {
            transaction: {
                title,
                description,
                transaction_field_list,
                model: { model_field_list }
            }
        } = this.props;
        const { fieldMappings } = this.state;
        const dotTemplates = (this.props.cargoCategories || []).concat(this.state.eventCargoCategories);
        const fields = (model_field_list || []).map(f => Field.fromDB(f));
        if (!fields) return {};

        const initialValues = {
            title: title || '',
            description: description || ''
        };
        fields.forEach((field) => {
            const { id, type, defaultValue, dateFormat = 'DD-MMM-YYYY' } = inheritMappedFieldProperties(Object.assign({}, field), fieldMappings, fields, dotTemplates);
            if (type !== FieldTypes.FIELD_SECTION) {
                const fieldValue = (transaction_field_list || []).find(v => v.field_id === id);
                if (fieldValue !== undefined && !!fieldValue.value) {
                    if (fieldValue.value.startsWith('{')) {
                        const valueObject = JSON.parse(fieldValue.value);
                        const valueProperty = valueObject[Object.keys(valueObject)[0]];
                        if (type === FieldTypes.FIELD_DATE) {
                            if (!valueProperty  || valueProperty === 'Invalid date') {
                                initialValues[id] = '';
                            } else {
                                const dateValue = moment(valueProperty, dateFormat, true).format(dateFormat);
                                initialValues[id] = dateValue === 'Invalid date' ? valueProperty : dateValue;
                            }
                        } else {
                            initialValues[id] = valueProperty;
                        }
                    } else {
                        initialValues[id] = fieldValue.value;
                    }
                } else if (defaultValue !== undefined) {
                    if (type === FieldTypes.FIELD_DATE) {
                        initialValues[id] = moment(defaultValue, dateFormat, true).format(dateFormat);
                    } else {
                        initialValues[id] = defaultValue;
                    }
                } else if (type === FieldTypes.FIELD_MULTI_TEXT) {
                    initialValues[id] = [''];
                } else {
                    initialValues[id] = '';
                }
            }
        });
        // in addition to the root transaction, grab initial values for all embedded cargo types
        const allCargoTypes = (this.props.cargoTypes || []).concat(this.state.cargoTypes);
        if (allCargoTypes) {
            allCargoTypes
                .filter(ct => {
                    // Ensure the cargoType actually belongs to this transaction
                    for (const tf of transaction_field_list) {
                        if (tf.value.startsWith('{')) {
                            const value = JSON.parse(tf.value);
                            if (value[Object.keys(value)[0]] === ct.id) {
                                return true;
                            }
                        } else {
                            if (tf.value === ct.id) {
                                return true;
                            }
                        }
                    }
                    return false;
                })
                .forEach(ct => {
                    // Index these values by the ID of the cargo type to 'namespace' them with formik
                    const cargoCategory = (this.props.cargoCategories || []).concat(this.state.eventCargoCategories).find(cc => cc.id === ct.model_id);
                    if (cargoCategory) {
                        const dashField = transaction_field_list.find(tf => {
                            if (tf.value.startsWith('{')) {
                                const value = JSON.parse(tf.value);
                                if (value[Object.keys(value)[0]] === ct.id) {
                                    return true;
                                }
                            } else {
                                if (tf.value === ct.id) {
                                    return true;
                                }
                            }
                        })
                        initialValues[ct.id] = [];
                        const ccFields = (cargoCategory.model_field_list || []).map(f => Field.fromDB(f));
                        ccFields.forEach((dtField) => {
                            const fieldClone = Object.assign({}, dtField);
                            fieldClone.isDotField = true;
                            fieldClone.dash_field_id = dashField.field_id;
                            const { id, type, defaultValue, dateFormat = 'DD-MMM-YYYY' } = inheritMappedFieldProperties(fieldClone, fieldMappings, fields, dotTemplates);
                            if (type !== FieldTypes.FIELD_SECTION) {
                                const ccFieldValue = (ct.transaction_field_list || []).find(v => v.field_id === id);
                
                                if (ccFieldValue !== undefined && !!ccFieldValue.value) {
                                    if (ccFieldValue.value.startsWith('{')) {
                                        const valueObject = JSON.parse(ccFieldValue.value);
                                        const valueProperty =valueObject[Object.keys(valueObject)[0]];
                                        if (type === FieldTypes.FIELD_DATE) {
                                            if (!valueProperty || valueProperty === 'Invalid date') {
                                                initialValues[ct.id][id] = '';
                                            } else {
                                                const dateValue = moment(valueProperty, dateFormat, true).format(dateFormat);
                                                initialValues[ct.id][id] = dateValue === 'Invalid date' ? valueProperty : dateValue;
                                            }
                                        } else {
                                            initialValues[ct.id][id] = valueProperty;
                                        }
                                    } else {
                                        initialValues[ct.id][id] = ccFieldValue.value;
                                    }
                                } else if (defaultValue !== undefined) {
                                    if (type === FieldTypes.FIELD_DATE) {
                                        initialValues[ct.id][id] = moment(defaultValue, dateFormat, true).format(dateFormat);
                                    } else {
                                        initialValues[ct.id][id] = defaultValue;
                                    }
                                } else if (type === FieldTypes.FIELD_MULTI_TEXT) {
                                    initialValues[ct.id][id] = [''];
                                } else {
                                    initialValues[ct.id][id] = '';
                                }
                            }
                        });
                    }
                });
        }
        return initialValues;
    };

    getValidationSchema = () => {
        const { transaction } = this.props;
        const cargoTypes = (this.props.cargoTypes || []).concat(this.state.cargoTypes)
        const cargoCategories = (this.props.cargoCategories || []).concat(this.state.eventCargoCategories);
        const { model } = transaction;
        const fields = model.model_field_list;
        const txFields = transaction.transaction_field_list;

        let requiredFieldsPresent = false;
        let invalidDateFieldsPresent = false;
        const validationSchema = {};

        const requiredFields = fields.filter(f => f.is_required);

        //--------------------------------------------------------------------------
        // PROCESS REQUIRED DASH FIELDS - set validation schema for each DASH field
        //--------------------------------------------------------------------------
        if (requiredFields.length > 0) {
            requiredFieldsPresent = true;
            requiredFields.forEach(f => {
                let yupObj = Yup.mixed();
                if (f.type_name === 'Number') {
                    yupObj = Yup.string().test(
                        'localeIndependentNumber',
                        '${path} is not a valid number',
                        (value) => {
                            const sanitizedValue = value.replaceAll(',', '').replaceAll('.', '');
                            const parsedValue = parseFloat(sanitizedValue);
                            return parsedValue !== NaN;
                        }
                    );
                }
                else if (f.type_name === 'Text' || f.type_name === 'Select') yupObj = Yup.string().min(1);
                else if (f.type_name === 'Date') {
                    const formFieldValues = this.state.values;
                    if (formFieldValues[f.id] !== undefined) {
                        const dateValue = formFieldValues[f.id];
                        if(dateValue) {
                            let extraInfo = null;
                            try {
                                extraInfo = JSON.parse(f.extra_info);
                            } catch(error) { 
                                console.warn('unable to parse field extra info', extraInfo);
                            }

                            if(!moment(dateValue, extraInfo.dateFormat, true).isValid()) {
                                yupObj = Yup.date().format(extraInfo.dateFormat || 'DD-MMM-YYYY', true).label(<FormattedMessage id="validation.date.invalidFormat" />);                         
                            }
                        }
                    }
                }
                validationSchema[f.id] = yupObj.required(<FormattedMessage id="validation.required" />);
            });
        }

        //------------------------------------------------------------
        // PROCESS UNREQUIRED DASH DATE FIELDS - set validation schema
        //------------------------------------------------------------
        const unrequiredDashFields = fields.filter(f => f.is_required === false);
        if (unrequiredDashFields.length > 0) {
            const dateFields = unrequiredDashFields.filter(f => f.type_name === 'Date');
            dateFields.forEach(f => {
                let yupObj1 = Yup.mixed();                
                const formFieldValues = this.state.values;
                if (formFieldValues[f.id] !== undefined) {
                    const dateValue = formFieldValues[f.id];
                    if(dateValue) {
                        let extraInfo = null;
                        try {
                            extraInfo = JSON.parse(f.extra_info);
                        } catch(error) { 
                            console.warn('unable to parse field extra info', extraInfo);
                        }
                        if(!moment(dateValue, extraInfo.dateFormat, true).isValid()) {
                            invalidDateFieldsPresent = true;
                            yupObj1 = Yup.date().format(extraInfo.dateFormat || 'DD-MMM-YYYY', true).label(<FormattedMessage id="validation.date.invalidFormat" />);                          
                            validationSchema[f.id] = yupObj1.required();
                        } 
                    }
                }
            });
        }

        const cargoFields = fields.filter(f => f.type_name === 'Cargo');
        //-------------------
        // PROCESS DOT FIELDS
        //-------------------
        cargoFields.forEach(cargoField => {
            var txField = txFields.find(txf => txf.field_id === cargoField.id);
            if (txField) {
                // If the user has chosen a lot for this cargo field, do not validate the fields
                if (txField.lot_id || (txField.input_lots && txField.input_lots.length > 0)) return;
                const fieldLots = this.state.cargoFieldLots;
                if (fieldLots && fieldLots[cargoField.id] && fieldLots[cargoField.id]['lot']) return;
            }
            const templateCargoType = cargoTypes.find(ct => ct.id === cargoField.cargo_type);
            const cargoCategory = cargoCategories.find(cc => cc.id === cargoField.cargoCategory);
            const cargoCategoryFields = cargoCategory === undefined ? [] : cargoCategory.model_field_list;
            const cargoTypeFields = [];
            
            let incompletedCargoFields = []
            if (templateCargoType === undefined && cargoCategory === undefined) {
                // ambiguous cargo, check if it has a cargo type
                try {
                    const fieldVal = JSON.parse(txField.value);
                    if (fieldVal.cargo_value && fieldVal.cargo_value !== '') {
                        const ambigFieldCargoType = cargoTypes.find(ct => ct.id === fieldVal.cargo_value);
                        if (ambigFieldCargoType) {
                            const ambigFieldCargoCategory = cargoCategories.find(cc => cc.id === ambigFieldCargoType.model_id);
                            const requiredCargoFields = (ambigFieldCargoCategory.model_field_list || [])
                                .filter(f => f.is_required);
                            if (requiredCargoFields.length > 0) {
                                requiredFieldsPresent = true;
                                let embeddedSchemaArray = [];
                                requiredCargoFields.forEach(f => {
                                    let yupObj = Yup.mixed();
                                    if (f.type_name === 'Number') {
                                        yupObj = Yup.string().test(
                                            'localeIndependentNumber',
                                            '${path} is not a valid number',
                                            (value) => {
                                                const sanitizedValue = value.replaceAll(',', '').replaceAll('.', '');
                                                const parsedValue = parseFloat(sanitizedValue);
                                                return parsedValue !== NaN;
                                            }
                                        );
                                    }
                                    else if (f.type_name === 'Text' || f.type_name === 'Select') yupObj = Yup.string().min(1);
                                    else if (f.type_name === 'Date') {
                                        let extraInfo = null;
                                        try {
                                            extraInfo = JSON.parse(f.extra_info);
                                        } catch(error) { 
                                            console.warn('unable to parse field extra info', extraInfo);
                                        }
                                        yupObj = Yup.date().format(extraInfo.dateFormat || 'DD-MMM-YYYY', true);
                                    }

                                    embeddedSchemaArray[f.id] = yupObj.required(<FormattedMessage id="validation.required" />);
                                });
                                const asObject = Object.assign({}, embeddedSchemaArray);
                                validationSchema[ambigFieldCargoType.id] = Yup.object().shape(asObject);
                            }
                        }
                    }
                } catch(err) {
                    console.warn('checking for ambiguous cargo yielded:', err);
                }
                // We don't need to process further for ambiguous cargo fields
                return;
            } else if (templateCargoType) {
                templateCargoType.transaction_field_list.forEach(field => {
                    cargoTypeFields.push(field);
                });
                // We hide any fields that had values hard coded within the event template
                incompletedCargoFields = cargoCategoryFields.filter(unformattedFieldToShow => {
                    const fieldToShow = Field.fromDB(unformattedFieldToShow);
                    const fieldInCargoType = cargoTypeFields.find(f => f.field_id === fieldToShow.id);
                    if (fieldInCargoType === undefined) {
                        return true;
                    }
                    const fieldValueObj = JSON.parse(fieldInCargoType.value);
                    const valuePropertyName = fieldToShow.type.toLowerCase() + '_value';
                    if (fieldValueObj.hasOwnProperty(valuePropertyName)) {
                        const fieldValue = fieldValueObj[valuePropertyName];
                        if (fieldValue === null || fieldValue === undefined || fieldValue === "" || arrayEquals(fieldValue, [""])) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                    return true;
                });
            } else {
                // No fields were filled in the Event Template: show all fields
                incompletedCargoFields = cargoCategoryFields;
            }
            const fieldIdsNotPredefined = incompletedCargoFields.map(f => f.id);
            // find the transaction field so we can grab the cargo type
            const transactionField = transaction.transaction_field_list.find(tf => tf.field_id === cargoField.id);
            if (transactionField && transactionField.value && transactionField.value.startsWith('{')) {
                const value = JSON.parse(transactionField.value);
                // The cargo type of the tx field will be used for namespacing, in case the cargo category etc is used more than once
                const txCargoTypeId = value[Object.keys(value)[0]];
                const cargoCategory = (this.props.cargoCategories || []).concat(this.state.eventCargoCategories).find(cc => cc.id === cargoField.cargoCategory);
                
                if (cargoCategory) {
                    //-----------------------------------------------------------------------
                    // PROCESS REQUIRED DOT FIELDS - set validation schema for each DoT field
                    //-----------------------------------------------------------------------
                    const requiredCargoFields = (cargoCategory.model_field_list || [])
                        .filter(f => f.is_required && fieldIdsNotPredefined.includes(f.id));
                    if (requiredCargoFields.length > 0) {
                        requiredFieldsPresent = true;
                        let embeddedSchemaArray = [];
                        requiredCargoFields.forEach(f => {
                            let yupObj = Yup.mixed();
                            if (f.type_name === 'Number') {
                                yupObj = Yup.string().test(
                                    'localeIndependentNumber',
                                    '${path} is not a valid number',
                                    (value) => {
                                        const sanitizedValue = value.replaceAll(',', '').replaceAll('.', '');
                                        const parsedValue = parseFloat(sanitizedValue);
                                        return parsedValue !== NaN;
                                    }
                                );
                            }
                            else if (f.type_name === 'Text' || f.type_name === 'Select') yupObj = Yup.string().min(1);
                            else if (f.type_name === 'Date') {
                                cargoTypes.forEach(ct => {
                                    const dateField = ct.transaction_field_list.find(tx => tx.field_id === f.id);
                                    if(!!dateField) {                                    
                                        const value = JSON.parse(dateField.value);
                                        const dateValue = value[Object.keys(value)[0]];
                                        if(dateValue) {
                                            let extraInfo = null;
                                            try {
                                                extraInfo = JSON.parse(f.extra_info);
                                            } catch(error) { 
                                                console.warn('unable to parse field extra info', extraInfo);
                                            }
                                            if(!moment(dateValue, extraInfo.dateFormat, true).isValid()) {
                                                yupObj = Yup.date().format(extraInfo.dateFormat || 'DD-MMM-YYYY', true).label(<FormattedMessage id="validation.date.invalidFormat" />);                                            
                                            }
                                        }
                                    }
                                });
                            }
                            embeddedSchemaArray[f.id] = yupObj.required(<FormattedMessage id="validation.required" />);
                        });
                        const asObject = Object.assign({}, embeddedSchemaArray);
                        validationSchema[txCargoTypeId] = Yup.object().shape(asObject);
                    }

                    //-----------------------------------------------------------
                    // PROCESS UNREQUIRED DOT DATE FIELDS - set validation schema 
                    //-----------------------------------------------------------
                    const unrequiredDotFields = (cargoCategory.model_field_list || []).filter(f => f.is_required === false && fieldIdsNotPredefined.includes(f.id));                   
                    if (unrequiredDotFields.length > 0) {
                        const dotDateFields = unrequiredDotFields.filter(f => f.type_name === 'Date');
                        dotDateFields.forEach(cf => {
                            let yupObj2 = Yup.mixed();
                            cargoTypes.forEach(ct => {
                                const dateField = ct.transaction_field_list.find(tx => tx.field_id === cf.id);
                                if(!!dateField) {
                                    
                                    const value = JSON.parse(dateField.value);
                                    const dateValue = value[Object.keys(value)[0]];
                                    if(dateValue) {

                                        let extraInfo = null;
                                        try {
                                            extraInfo = JSON.parse(cf.extra_info);
                                        } catch(error) { 
                                            console.warn('unable to parse field extra info', extraInfo);
                                        }

                                        if(!moment(dateValue, extraInfo.dateFormat, true).isValid()) {
                                            invalidDateFieldsPresent = true;
                                            yupObj2 = Yup.date().format(extraInfo.dateFormat || 'DD-MMM-YYYY', true).label(<FormattedMessage id="validation.date.invalidFormat" />);
                                            let embeddedSchema = [];
                                            embeddedSchema[cf.id] = yupObj2.required();
                                            const asObject = Object.assign({}, embeddedSchema);
                                            validationSchema[txCargoTypeId] = Yup.object().shape(asObject);
                                            
                                        }
                                    }
                                }
                            });                            
                        });
                    }

                }
            }
        })
        return requiredFieldsPresent || invalidDateFieldsPresent ? Yup.object().shape(validationSchema) : undefined;
    };

    updateFn = debounce((callback) => {
        if (this.props.onSavingSet) {
            this.props.onSavingSet(true);
        }
        this.submitTransaction().then(callback);
    }, 500);

    updateTransactionDebounced = (values = null, callback = () => null) => {
        const { transaction } = this.props;
        const { fieldMappings, fieldMappingUsages } = this.state;
        const { model: { model_field_list } } = transaction;
        const fields = (model_field_list || []).map(f => Field.fromDB(f));
        const dotTemplates = (this.props.cargoCategories || []).concat(this.state.eventCargoCategories);
        const dotTemplateFieldArrays = dotTemplates.map(dt => dt.model_field_list || []);
        const dotTemplateFields = [].concat.apply([], dotTemplateFieldArrays).map(f => Field.fromDB(f));
        let sourceField;
        let destinationField;

        if (values != null) {
            for (const fieldMap of fieldMappings) {
                const sourceDashTemplateField = transaction.model.model_field_list.find(f => fieldMap.owning_dash_field_id === f.id)
                let sourceDotTypeHashId;
                let mappedFieldId;
                if (fieldMap.owning_dot_field_id || fieldMap.owning_special_dot_field_id) {
                    sourceField = Object.assign({}, dotTemplateFields.find(f => f.id === fieldMap.owning_dot_field_id));
                    sourceField.isDotField = true;
                    sourceField.dash_field_id = sourceDashTemplateField.id;
                    sourceField = inheritMappedFieldProperties(sourceField, fieldMappings, fields, dotTemplates);
                    sourceDotTypeHashId = values[sourceDashTemplateField.id];
                    mappedFieldId = fieldMap.owning_dot_field_id;
                } else {
                    sourceField = Object.assign({}, fields.find(f => f.id === fieldMap.owning_dash_field_id))
                    sourceField = inheritMappedFieldProperties(sourceField, fieldMappings, fields, dotTemplates);
                    mappedFieldId = fieldMap.owning_dash_field_id;
                }
                // if the parent field mapping to this one is, itself, mapped to, use the original source
                if (sourceField.sourceMapField) {
                    if (sourceField.sourceMapNestedFieldId) {
                        sourceDotTypeHashId = values[sourceField.sourceMapNestedFieldId];
                    } else {
                        sourceDotTypeHashId = null;
                    }
                    mappedFieldId = sourceField.sourceMapField.id;
                    sourceField = Object.assign({}, sourceField.sourceMapField);
                }
                let fetchValue;
                if (sourceDotTypeHashId) {
                    fetchValue = values[sourceDotTypeHashId][mappedFieldId];
                } else {
                    fetchValue = values[mappedFieldId];
                }


                // now that we have the source value, we'll assign it to the mapped field unless the user has edited the field
                const destinationDashTemplateField = transaction.model.model_field_list.find(f => fieldMap.map_dash_field_id === f.id);
                if (fieldMap.map_dot_field_id || fieldMap.map_special_dot_field) {
                    // If the dash is in revision, make sure that we only map to dot fields that are also in revision
                    if (transaction.status_id === 7) {
                        const targetCargoField = transaction.transaction_field_list.find(tf => tf.field_id === destinationDashTemplateField.id);
                        if (!targetCargoField.in_revision) {
                            continue;
                        }
                    }
                    const destinationDotTypeHashId = values[destinationDashTemplateField.id];
                    if (fieldMappingUsages[destinationDotTypeHashId] && fieldMappingUsages[destinationDotTypeHashId][fieldMap.map_dot_field_id] === false) {
                        continue;
                    }
                    destinationField = Object.assign({}, dotTemplateFields.find(f => f.id === fieldMap.map_dot_field_id));
                    destinationField.isDotField = true;
                    destinationField.dash_field_id = destinationDashTemplateField.id;
                    destinationField = inheritMappedFieldProperties(destinationField, fieldMappings, fields, dotTemplates);
                    let formattedValue = fetchValue;
                    if (sourceField && sourceField.type === FieldTypes.FIELD_DATE && destinationField && destinationField.type === FieldTypes.FIELD_DATE) {
                        if (!fetchValue || fetchValue === 'Invalid date') {
                            formattedValue = '';
                        } else {
                            const sourceFormat = sourceField.dateFormat || 'DD-MMM-YYYY';
                            const destinationFormat = destinationField.dateFormat || 'DD-MMM-YYYY';
                            formattedValue = moment(fetchValue, sourceFormat, true).format(destinationFormat);
                        }
                    }
                    if (destinationDotTypeHashId) {
                        values[destinationDotTypeHashId][fieldMap.map_dot_field_id] = formattedValue;
                    }
                } else {
                    if (fieldMappingUsages && fieldMappingUsages[fieldMap.map_dash_field_id] === false) {
                        continue;
                    }
                    destinationField = Object.assign({}, fields.find(f => f.id === fieldMap.map_dash_field_id));
                    destinationField = inheritMappedFieldProperties(destinationField, fieldMappings, fields, dotTemplates);
                    let formattedValue = fetchValue;
                    if (sourceField && sourceField.type === FieldTypes.FIELD_DATE && destinationField && destinationField.type === FieldTypes.FIELD_DATE) {
                        if (!fetchValue || fetchValue === 'Invalid date') {
                            formattedValue = '';
                        } else {
                            const sourceFormat = sourceField.dateFormat || 'DD-MMM-YYYY';
                            const destinationFormat = destinationField.dateFormat || 'DD-MMM-YYYY';
                            formattedValue = moment(fetchValue, sourceFormat, true).format(destinationFormat);
                        }
                    }
                    values[fieldMap.map_dash_field_id] = formattedValue;
                }
            }
            this.setState({ values: values, transactionUpdated: true, transactionIsUpdating: true, allowReinitialize: false });
        } else {
            this.setState({ transactionUpdated: true, transactionIsUpdating: true, allowReinitialize: false });
        }
        return this.updateFn(callback);
    }

    submitTransaction = () => {
        return new Promise(resolve => {
            this.setState({transactionUpdated: false}, async () => {
                
                const {
                    cargoFieldLots,
                    fieldMappingUsages,
                    cargoTypes,
                    userTransformUsages,
                } = this.state;
        
                const {
                    transaction,
                    updateTransaction,
                    getTransaction,
                    comparators,
                    getTransactionRelatedData,
                    fieldUserTransformState,
                } = this.props;
        
                const {
                    id,
                    status_id,
                    model: { model_field_list },
                    transaction_field_list,
                } = transaction;
        
                const fields = (model_field_list || []).map(f => Field.fromDB(f));
                const updates = [];
                const values = this.state.values;

                const cargoTypePromises = [];
                for (const key of Object.keys(values)) {
                    if (!['title', 'description'].includes(key) && values[key] !== undefined) {
                        // First we'll check if this value is referring to an embedded cargo type
                        const allCargoTypes = (this.props.cargoTypes || []).concat(this.state.cargoTypes);
                        const cargoTypeReference = allCargoTypes ? allCargoTypes.find(ct => ct.id === key) : undefined;
                        if (cargoTypeReference) {
                            const cargoField = transaction_field_list.find(f => {
                                const valueParsed = JSON.parse(f.value);
                                return valueParsed["cargo_value"] === key;
                            });
                            // when the dash is in revision, skip over and dot fields that are not in revision
                            if (transaction.status_id === 7 /*in revison*/ &&  !cargoField.in_revision) {
                                continue;
                            }
                            const cargoCategory = (this.props.cargoCategories || []).concat(this.state.eventCargoCategories).find(cc => cargoTypeReference.model.id === cc.id);
                            const cargoTypeUpdates = [];
                            if (cargoCategory) {
                                const ccFields = (cargoCategory.model_field_list || []).map(f => Field.fromDB(f));
                                let cargoFieldValues = values[key];
                                if (!(cargoFieldValues instanceof Array)) {
                                    try {
                                        const entries = Object.entries(cargoFieldValues);
                                        const valArray = [];
                                        for(const entry of entries) {
                                            valArray[entry[0]] = entry[1];
                                        }
                                        cargoFieldValues = valArray;
                                    } catch (err) {
                                        console.warn('Form contains invalid nested value:', cargoFieldValues, err);
                                    }
                                }
                                for (let index = 0; index < cargoFieldValues.length; index++) {
                                    let ctFieldValueRaw = cargoFieldValues[index];
                                    if (ctFieldValueRaw === undefined) {
                                        continue;
                                    }
                                    const ccField = ccFields.find(f => f.id === index || f.id === String(index));
                                    if (ccField) {
                                        const ctFieldValue = {};
                                        ctFieldValue[`${ccField.type.toLowerCase()}_value`] = ctFieldValueRaw;
                                        const cargoFieldVisible = evaluateConditions(ccField, cargoFieldValues, ccFields, comparators);
                                        let fieldUsesMappedValue = true;
                                        if (fieldMappingUsages[key]) {
                                            fieldUsesMappedValue = fieldMappingUsages[key][index];
                                            if (fieldUsesMappedValue === undefined) fieldUsesMappedValue = true;
                                        }
                                        let fieldUsesUserTransformValue = true;
                                        if (userTransformUsages[key]) {
                                            fieldUsesUserTransformValue = userTransformUsages[key][index];
                                            if (fieldUsesUserTransformValue === undefined) fieldUsesUserTransformValue = true;
                                        }
                                        const dotTypeField = cargoTypeReference.transaction_field_list.find(f => f.field_id === ccField.id);
                                        let restoreValue = JSON.stringify(ctFieldValue);
                                        if (fieldUsesMappedValue) {
                                            restoreValue = dotTypeField ?  dotTypeField.restore_value : '';
                                        }
                                        const ctFieldValueData = { 
                                            field_id: Number.parseInt(ccField.id), 
                                            is_visible: cargoFieldVisible, 
                                            value: JSON.stringify(ctFieldValue),
                                            restore_value: restoreValue,
                                            use_mapped_value: fieldUsesMappedValue,
                                            use_user_transform: fieldUsesUserTransformValue,
                                        };
                                        const updateAction = { action: 'set', data: ctFieldValueData };
                                        cargoTypeUpdates.push(updateAction);
                                    }
                                }
                                if (cargoTypeUpdates.length > 0) {
                                    cargoTypePromises.push(new Promise((resolve) => {
                                        const cargoTypeDetails = {
                                            transaction_id: cargoTypeReference.id,
                                            title: cargoTypeReference.title,
                                            description: cargoTypeReference.description,
                                            status_id: cargoTypeReference.status_id,
                                            fields: JSON.stringify({ updates: cargoTypeUpdates })
                                        };
                                
                                        updateTransaction(cargoTypeDetails, () => resolve(), (err) => {
                                            resolve()
                                        });
                                    }));
                                } else {
                                    console.info('no updates for cargo type ' + key);
                                }
                            } else {
                                console.warn('Warning: Cargo category for cargo type not found!', cargoTypeReference);
                            }
                        } else {
                            const field = fields.find(f => f.id === key);
                            const dashField = transaction.transaction_field_list.find(f => f.field_id === key);
                            if (field) {
                                const fieldValue = {};
                                fieldValue[`${field.type.toLowerCase()}_value`] = values[key];
                                const data = { field_id: Number.parseInt(field.id), value: JSON.stringify(fieldValue) };
                                const fieldVisible = evaluateConditions(field, values, fields, comparators);
                                data.is_visible = fieldVisible;
                                const txField = transaction.transaction_field_list.find(f => f.field_id === field.id);
                                if (cargoFieldLots[key]) {
                                    if (transaction.status_id === 7 && txField.in_revision && txField.created_dot_all_consumed) {
                                        data.quantity = (txField ? txField.quantity : null);
                                        data.revised_remainder_quantity = (cargoFieldLots[key]['quantity'] !== undefined) ? cargoFieldLots[key]['quantity'] : (txField ? txField.revised_remainder_quantity : null);
                                    } else {
                                        data.quantity = (cargoFieldLots[key]['quantity'] !== undefined) ? cargoFieldLots[key]['quantity'] : (txField ? txField.quantity : null);
                                        data.revised_remainder_quantity = (txField ? txField.revised_remainder_quantity : null);
                                    }
                                    data.lot_id = (cargoFieldLots[key]['lot'] !== undefined) ? cargoFieldLots[key]['lot'] : (txField ? txField.lot_id : null);
                                    // data.quantity = (cargoFieldLots[key]['quantity'] !== undefined) ? cargoFieldLots[key]['quantity'] : (txField ? txField.quantity : null);
                                    data.cargo_unit_id = (cargoFieldLots[key]['cargoUnit'] !== undefined) ? cargoFieldLots[key]['cargoUnit'] : (txField ? txField.cargo_unit_id : null);
                                    data.mapped_lot_id = (cargoFieldLots[key]['mappedLot'] !== undefined) ? cargoFieldLots[key]['mappedLot'] : (txField ? txField.mapped_lot_id : null);
                                } else {
                                    data.lot_id = (txField ? txField.lot_id : null);
                                    data.quantity = (txField ? txField.quantity : null);
                                    data.cargo_unit_id = (txField ? txField.cargo_unit_id : null);
                                    data.mapped_lot_id = (txField ? txField.mapped_lot_id : null);
                                }
                                const fieldUsesMappedValue = (fieldMappingUsages[key] !== undefined) ? fieldMappingUsages[key] : true;
                                data.use_mapped_value = fieldUsesMappedValue;
                                const fieldUsesMappedQuantityValue = (fieldMappingUsages[key + '.l1'] !== undefined) ? fieldMappingUsages[key + '.l1'] : true;
                                data.use_mapped_quantity_value = fieldUsesMappedQuantityValue;
                                const fieldUsesMappedUnitValue = (fieldMappingUsages[key + '.l2'] !== undefined) ? fieldMappingUsages[key + '.l2'] : true;
                                data.use_mapped_unit_value = fieldUsesMappedUnitValue;
                                let restoreValue = JSON.stringify(fieldValue);
                                if (fieldUsesMappedValue) {
                                    restoreValue = dashField ? dashField.restore_value : '';
                                }
                                data.restore_value = restoreValue;
                                data.restore_unit_value = data.cargo_unit_id;
                                data.restore_quantity_value = data.quantity;
                                data.allow_user_transform = dashField ? dashField.allow_user_transform : false;
                                data.use_user_transform = dashField ? dashField.use_user_transform : true;
                                if (!!fieldUserTransformState) {
                                    const fieldAllowUserTransform = fieldUserTransformState[field.id];
                                    data.allow_user_transform = !!fieldAllowUserTransform;
                                }
                                if (!!userTransformUsages) {
                                    const fieldEnableUserTransform = userTransformUsages[field.id];
                                    data.use_user_transform = !(fieldEnableUserTransform === false);
                                }
                                const dataComposed = { action: 'set', data };
                                updates.push(dataComposed);
                            }
                        }
                    }
                }
                Promise.all(cargoTypePromises).then(() => {
                    getTransactionRelatedData(transaction.id, data => {
                        this.setState({ eventCargoCategories: data.cargo_categories, cargoTypes: data.cargo_types, eventLots: data.lots, fieldMappings: data.field_mappings }, () => {
                            const onSuccess = this.props.onTransactionUpdate === undefined ?  () => {} : this.props.onTransactionUpdate;
                            const onFinalize = () => {
                                if (this.props.onSavingSet) {
                                    this.props.onSavingSet(false);
                                }
                                this.setState({transactionIsUpdating: this.state.transactionUpdated})
                                onSuccess();
                            }
                            // If the only fields changed belong to embedded cargo types, we don't need to update the root transaction
                            if (updates.length > 0 || !(values.title === transaction.title) || !(values.description === transaction.description)) {
                                const transactionDetails = {
                                    transaction_id: id,
                                    title: values.title,
                                    description: values.description,
                                    status_id,
                                    fields: (updates.length > 0) ? JSON.stringify({ updates }) : null
                                };
                        
                                // don't dispatch a fetch of the transaction if the user made changes during our last update
                                updateTransaction(transactionDetails, onFinalize);
                            } else {
                                onFinalize()
                            }
                            if (this.state.showSaveComplete) {
                                Notifications.success(<FormattedMessage id='autosavecomplete' />);
                                this.setState({ showSaveComplete: false });
                            }
                            resolve();
                        })
                    })
                })
            });
        })
    }

    finalSave = () => {
        this.setState({finalSaveLoading: true});
        // wait for possible debounced values to take effect
        setTimeout(async () => {
            const { transaction, updateTransaction, getTransaction, getTransactionRelatedData } = this.props;
            
            const values = this.state.values;
    
            const transactionDetails = {
                transaction_id: transaction.id,
                title: values.title,
                description: values.description,
                status_id: 2,   // set to 'pending' status - backend will update to 'committed' once blockchain is handled
                fields: null
            };
    
            updateTransaction(transactionDetails, () => {
                this.setState({finalSaveLoading: false, finalSaveModalOpen: false});
                Notifications.success(<FormattedMessage id='successfullysavedevent' />);
                getTransactionRelatedData(transaction.id, data => {
                    this.setState({ eventCargoCategories: data.cargo_categories, cargoTypes: data.cargo_types, eventLots: data.lots, fieldMappings: data.field_mappings })
                })
                getTransaction(transaction.id); // refresh the transaction, which should now be in pending/completed state
            }, () => {
                this.setState({finalSaveLoading: false, finalSaveModalOpen: false});
            });
        }, 500)
    }

    validateRequiredFields = (values) => {
        // check for any unspecified cargo fields
        const { 
            transaction, 
            comparators, 
        } = this.props;
        const { model } = transaction;
        const fields = model.model_field_list;
        const groupedFields = getGroupedSections(fields);
        for (const f of groupedFields) {
            if (f.type === FieldTypes.FIELD_CARGO) {
                // If the field is hidden, we won't validate it
                const showField = evaluateConditions(f, values, fields, comparators);
                if (!showField) {
                    continue;
                }
                const transactionField = transaction.transaction_field_list.find(tf => tf.field_id === f.id);
                const transactionFieldPopulated = transactionField && transactionField.value && transactionField.value.startsWith('{');
                // If theres any cargo fields where the user has not selected a cargo, this should fail validation
                if (transactionFieldPopulated) {
                    const value = JSON.parse(transactionField.value);
                    const txCargoTypeId = value[Object.keys(value)[0]];
                    if (!txCargoTypeId || txCargoTypeId === '' && !f.cargoCategory) {
                        return Promise.reject(false);
                    }
                } else if (!f.cargoCategory) {
                    return Promise.reject(false);
                }
            }
        }

        const schema = this.getValidationSchema();
        if (!schema) {
            // there are no required fields - anything goes
            return Promise.resolve(true);
        }
        const sanitizedValues = values;
        const { cargoTypes } = this.state;
        for (const prop in sanitizedValues) {
            if (Array.isArray(sanitizedValues[prop])) {
                // A cargo field could have an array value - map it to an object and sanitize the children
                if (isNaN(prop) || cargoTypes.find(ct => ct.id === prop)) {
                    const arrayToObjValues = Object.assign({}, sanitizedValues[prop]);
                    for (const objProp in arrayToObjValues) {
                        if (Array.isArray(arrayToObjValues[objProp])) {
                            if (arrayToObjValues[objProp].length === 0 || (arrayToObjValues[objProp].length === 1 && !arrayToObjValues[objProp][0])) {
                                arrayToObjValues[objProp] = null;
                            } else {
                                arrayToObjValues[objProp] = Object.assign({}, arrayToObjValues[objProp]);
                            }
                        } else if (moment.isMoment(arrayToObjValues[objProp]) || arrayToObjValues[objProp] instanceof Date) {
                            continue;
                        } else if (!!arrayToObjValues[objProp] && typeof arrayToObjValues[objProp] === 'object') {
                            if (!Object.values(arrayToObjValues[objProp]).some(v => v !== null && v !== undefined && v !== '')) {
                                arrayToObjValues[objProp] = null;
                            }
                        } else if (arrayToObjValues[objProp] === '') {
                            arrayToObjValues[objProp] = null;
                        }
                    }
                    sanitizedValues[prop] = arrayToObjValues;
                // otherwise, check that the array isn't 'empty'
                } else {
                    if (sanitizedValues[prop].length === 0 || (sanitizedValues[prop].length === 1 && !sanitizedValues[prop][0])) {
                        sanitizedValues[prop] = null;
                    }
                }
            // If it's not at array, check if it's an object without any values (i.e. multitext)
            } else if (!!sanitizedValues[prop] && typeof sanitizedValues[prop] === 'object') {
                // cargo fields could also be objects
                if (isNaN(prop) || cargoTypes.find(ct => ct.id === prop)) {
                    const objValues = sanitizedValues[prop];
                    for (const objProp in objValues) {
                        if (Array.isArray(objValues[objProp])) {
                            if (objValues[objProp].length === 0 || (objValues[objProp].length === 1 && !objValues[objProp][0])) {
                                objValues[objProp] = null;
                            } else {
                                objValues[objProp] = Object.assign({}, objValues[objProp]);
                            }
                        } else if (moment.isMoment(objValues[objProp]) || objValues[objProp] instanceof Date) {
                            continue;
                        } else if (!!objValues[objProp] && typeof objValues[objProp] === 'object') {
                            if (!Object.values(objValues[objProp]).some(v => v !== null && v !== undefined && v !== '')) {
                                objValues[objProp] = null;
                            }
                        } else if (objValues[objProp] === '') {
                            objValues[objProp] = null;
                        }
                    }
                    sanitizedValues[prop] = objValues;
                // We could have a date object
                } else if (moment.isMoment(sanitizedValues[prop]) || sanitizedValues[prop] instanceof Date) {
                    continue;
                // Make sure it's not an 'empty array' object
                } else if (!Object.values(sanitizedValues[prop]).some(v => v !== null && v !== undefined && v !== '')) {
                    sanitizedValues[prop] = null;
                }
            } else if (sanitizedValues[prop] === '') {
                sanitizedValues[prop] = null;
            }
        }
        return schema.validate(sanitizedValues);
    }

    onLotSelected = (lotId) => {
        const { getTransaction, transaction } = this.props;
        const { cargoFieldLots, inputLotFieldId } = this.state;
        if (cargoFieldLots[inputLotFieldId] === undefined) {
            cargoFieldLots[inputLotFieldId] = {};
        }
        cargoFieldLots[inputLotFieldId]['lot'] = lotId;
        if (this.props.onSavingSet) {
            this.props.onSavingSet(true);
        }
        this.setState({cargoFieldLots, transactionUpdated: true, transactionIsUpdating: true});
        this.updateTransactionDebounced(null, () => getTransaction(transaction.id));
    }

    onLotsSelected = (formProps) => {
        return async (inputLotsData) => {
            const {
                transaction, 
                getTransaction,
            } = this.props;

            this.setState({ updatingFields: true }, () => {
                getTransaction(transaction.id, () => {
                    this.setState({ updatingFields: false })
                })
            });

            const setDotQuantityState = (inputLotFieldId, quantity, cb = () => null) => {
                this.setState(
                    prevState => {
                        const { cargoFieldLots,  } = prevState;
                        if (cargoFieldLots[inputLotFieldId] === undefined) {
                            cargoFieldLots[inputLotFieldId] = {};
                        }
                        cargoFieldLots[inputLotFieldId]['quantity'] = quantity;
                        if (this.props.onSavingSet) {
                            this.props.onSavingSet(true);
                        }
                        return {cargoFieldLots, transactionUpdated: true, transactionIsUpdating: true};
                    }, 
                    cb
                );
            }

            const setDotUnitState = (inputLotFieldId, value, cb = () => null) => {
                const { cargoFieldLots, } = this.state;
                if (cargoFieldLots[inputLotFieldId] === undefined) {
                    cargoFieldLots[inputLotFieldId] = {};
                }
                cargoFieldLots[inputLotFieldId]['cargoUnit'] = value;
                if (this.props.onSavingSet) {
                    this.props.onSavingSet(true);
                }
                this.setState({cargoFieldLots, transactionUpdated: true, transactionIsUpdating: true}, cb);
            }
    
            this.updateTransactionDebounced(null, async () => {
                const { eventLots, cargoTypes } = this.state;
                const targetTransactionField = transaction.transaction_field_list.find(f => f.id === this.state.targetTransactionField);
                if (!targetTransactionField) return;

                const targetHashId = formProps.values[targetTransactionField.field_id];
                if (!targetHashId) return;

                const newValues = Object.assign({}, formProps.values);

                const { fieldMappingUsages, fieldMappings } = this.state;
                const specialFieldMaps = fieldMappings
                    .filter(mapping => mapping.owning_dash_field_id === targetTransactionField.field_id && !!mapping.owning_special_dot_field_id)
                    .filter(mapping => {
                        const mapUsageId = `${mapping.owning_dash_field_id}.l${mapping.owning_special_dot_field_id}`;
                        const mappingUsage = fieldMappingUsages[mapUsageId];
                        return !!mappingUsage;
                    });                

                if (inputLotsData.length === 0) {
                    await Promise.all(newValues[targetHashId].map(async (_, index) => {
                        await formProps.setFieldValue(`${targetHashId}.${index}`, '');
                    }));
                    newValues[targetHashId] = newValues[targetHashId].map((val) => {
                        if (val !== undefined) {
                            return '';
                        }
                        return val;
                    });

                    await Promise.all(
                        specialFieldMaps.map(mapping => {
                            if (mapping.map_special_dot_field === 1) {
                                return new Promise(resolve => setDotQuantityState(mapping.map_dash_field_id, null, resolve));
                            } else {
                                return new Promise(resolve => setDotUnitState(mapping.map_dash_field_id, null, resolve));
                            }
                        })
                    )
                } else {
                    const targetLot = eventLots.find(l => l.id === inputLotsData[0].lot_id);
                    await new Promise(resolve => {
                        this.setState(prevState => {
                            const { cargoFieldLots } = prevState;
                            if (cargoFieldLots[targetTransactionField.field_id] === undefined) {
                                cargoFieldLots[targetTransactionField.field_id] = {};
                            }
                            cargoFieldLots[targetTransactionField.field_id]['mappedLot'] = targetLot.id;
                            return { cargoFieldLots };
                        }, resolve)
                    });
                    if (!targetLot) return;
                    const lotType = cargoTypes.find(ct => ct.id === targetLot.cargo_type_hash_id)
                    if (!lotType) return;
                    
                    for (const field of lotType.transaction_field_list) {
                        if (field.value.startsWith('{')) {
                            const valueObject = JSON.parse(field.value);
                            const valueProperty = valueObject[Object.keys(valueObject)[0]];
                            newValues[targetHashId][field.field_id] = valueProperty;
                            await formProps.setFieldValue(`${targetHashId}.${field.field_id}`, valueProperty);
                        }
                    }

                    const totalQuantity = inputLotsData.map(data => Number(data.quantity)).reduce((a, b) => a + b, 0);

                    await Promise.all(
                        specialFieldMaps.map(mapping => {
                            if (mapping.map_special_dot_field === 1) {
                                return new Promise(resolve => setDotQuantityState(mapping.map_dash_field_id, totalQuantity, resolve));
                            } else {
                                return new Promise(resolve => setDotUnitState(mapping.map_dash_field_id, inputLotsData[0].unit_id, resolve));
                            }
                        })
                    )
                }
                formProps.submitForm();
                this.updateTransactionDebounced(newValues,);
            });
        }
    }

    onQuantitySelected = (quantity, cb = () => null) => {
        this.setState(prevState => {
            const { cargoFieldLots, inputLotFieldId, fieldMappings, fieldMappingUsages } = prevState;
            if (cargoFieldLots[inputLotFieldId] === undefined) {
                cargoFieldLots[inputLotFieldId] = {};
            }
            cargoFieldLots[inputLotFieldId]['quantity'] = quantity;
            if (this.props.onSavingSet) {
                this.props.onSavingSet(true);
            }
            
            let quantityMapping = fieldMappings.find(fm => fm.owning_dash_field_id === inputLotFieldId && fm.owning_special_dot_field_id === 1);
            while (!!quantityMapping) {
                let quantityMappingUsage = false;
                quantityMappingUsage = fieldMappingUsages[quantityMapping.map_dash_field_id + '.l1'];
                if (quantityMappingUsage === undefined) {
                    quantityMappingUsage = true;
                }
                if (quantityMappingUsage) {
                    if (cargoFieldLots[quantityMapping.map_dash_field_id] === undefined) {
                        cargoFieldLots[quantityMapping.map_dash_field_id] = {};
                    }
                    cargoFieldLots[quantityMapping.map_dash_field_id]['quantity'] = quantity;
                } else {
                    break;
                }
                const previousQuantityMapping = quantityMapping;
                quantityMapping = fieldMappings.find(fm => fm.owning_dash_field_id === previousQuantityMapping.map_dash_field_id && fm.owning_special_dot_field_id === 1);
            }

            return {cargoFieldLots, transactionUpdated: true, transactionIsUpdating: true};
        }, () => {
            this.updateTransactionDebounced();
            cb();
        });
        
    }

    onCargoUnitSelected = (valueObj, cb = () => null) => {
        if (valueObj === undefined) {
            return;
        }
        const { cargoFieldLots, inputLotFieldId, fieldMappings, fieldMappingUsages } = this.state;
        if (cargoFieldLots[inputLotFieldId] === undefined) {
            cargoFieldLots[inputLotFieldId] = {};
        }
        cargoFieldLots[inputLotFieldId]['cargoUnit'] = valueObj.value;
        if (this.props.onSavingSet) {
            this.props.onSavingSet(true);
        }

        let unitMapping = fieldMappings.find(fm => fm.owning_dash_field_id === inputLotFieldId && fm.owning_special_dot_field_id === 2);
        while (!!unitMapping) {
            let unitMappingUsage = false;
            unitMappingUsage = fieldMappingUsages[unitMapping.map_dash_field_id + '.l2'];
            if (unitMappingUsage === undefined) {
                unitMappingUsage = true;
            }
            if (unitMappingUsage) {
                if (cargoFieldLots[unitMapping.map_dash_field_id] === undefined) {
                    cargoFieldLots[unitMapping.map_dash_field_id] = {};
                }
                cargoFieldLots[unitMapping.map_dash_field_id]['cargoUnit'] = valueObj.value;
            } else {
                break;
            }
            const previousUnitMapping = unitMapping;
            unitMapping = fieldMappings.find(fm => fm.owning_dash_field_id === previousUnitMapping.map_dash_field_id && fm.owning_special_dot_field_id === 2);
        }

        this.setState({cargoFieldLots, transactionUpdated: true, transactionIsUpdating: true}, () => {
            this.updateTransactionDebounced();
            cb();
        });
    }

    initializeInputLotState = (fieldId, cargoTypeId) => {
        return () => {
            const { 
                transaction, 
            } = this.props;
            
            const txField = transaction.transaction_field_list.find(tf => tf.field_id === fieldId);
            let fieldQuantity = 0;
            if (txField) {
                if (transaction.status_id === 7 && txField.in_revision && txField.created_dot_all_consumed) {
                    fieldQuantity = txField.revised_remainder_quantity;
                } else {
                    fieldQuantity = txField.quantity;
                }
            }
            let specifiedLot = null;
            if (this.state.cargoFieldLots[fieldId] && this.state.cargoFieldLots[fieldId].hasOwnProperty('lot')) {
                specifiedLot = this.state.cargoFieldLots[fieldId]['lot'];
            }
            let specifiedQuantity = null;
            if (this.state.cargoFieldLots[fieldId] && this.state.cargoFieldLots[fieldId].hasOwnProperty('quantity')) {
                specifiedQuantity = this.state.cargoFieldLots[fieldId]['quantity'];
            }
            let specifiedUnit = '';
            if (this.state.cargoFieldLots[fieldId] && this.state.cargoFieldLots[fieldId].hasOwnProperty('cargoUnit')) {
                specifiedUnit = this.state.cargoFieldLots[fieldId]['cargoUnit'];
            }
            this.setState(prevstate => {
                const allCargoTypes = (this.props.cargoTypes || []).concat(prevstate.cargoTypes);
                return {
                    inputLotCargoType: allCargoTypes.find(ct => ct.id === cargoTypeId), 
                    targetTransactionField: txField.id,
                    lotModalOpen: true,
                    initialTargetLot: (txField && txField.lot_id) ? txField.lot_id : specifiedLot,
                    initialTargetQuantity: (specifiedQuantity != null) ? specifiedQuantity : fieldQuantity,
                    initialTargetCargoUnit: (txField && txField.cargo_unit_id) ? txField.cargo_unit_id : specifiedUnit,
                    inputLotFieldId: fieldId,
                }
            });
        }
    }

    onUseMappedValueToggle = (formProps, id, hashId = null) => {
        let useMappedValue;
        let { fieldMappingUsages } = this.state;
        if (hashId) {
            if (fieldMappingUsages[hashId] === undefined) {
                fieldMappingUsages[hashId] = {};
                fieldMappingUsages[hashId][id] = false;
                useMappedValue = false;
            } else {
                const oldValue = fieldMappingUsages[hashId][id];
                const newValue = (oldValue === undefined) ? false : !oldValue
                fieldMappingUsages[hashId][id] = newValue;
                useMappedValue = newValue;
            }
        } else {
            if (fieldMappingUsages[id] === undefined) {
                fieldMappingUsages[id] = false;
                useMappedValue = false;
            } else {
                fieldMappingUsages[id] = !fieldMappingUsages[id];
                useMappedValue = fieldMappingUsages[id];
            }
        }
        const allDotTypes = (this.props.cargoTypes || []).concat(this.state.cargoTypes);
        const updatedValues = Object.assign({}, formProps.values);
        if (!useMappedValue) {
            // field mapping was disabled - restore the last manually set value, if any
            if (hashId) {
                const dotType = allDotTypes.find(dt => dt.id === hashId);
                const field = dotType ? dotType.transaction_field_list.find(f => f.field_id === id) : null;
                if (field) {
                    // if field value is an object (or array, more likely) clone it instead of directly assigning value
                    let restoreValue = (typeof updatedValues[hashId][id] === 'object') ? Object.assign({}, updatedValues[hashId][id]) : updatedValues[hashId][id];
                    if (field.restore_value !== null && field.restore_value !== undefined) {
                        const valueObject = JSON.parse(field.restore_value);
                        restoreValue = valueObject[Object.keys(valueObject)[0]];
                    }
                    formProps.setFieldValue(`${hashId}.${id}`, restoreValue);
                    if (!updatedValues[hashId]) {
                        updatedValues[hashId] = {};
                    }
                    updatedValues[hashId][id] = restoreValue;
                }
            } else {
                const { transaction: { transaction_field_list }} = this.props;
                const field = transaction_field_list.find(f => f.field_id === id);
                if (field) {
                    // if field value is an object (or array, more likely) clone it instead of directly assigning value
                    let restoreValue = (typeof updatedValues[id] === 'object') ? Object.assign({}, updatedValues[id]) : updatedValues[id];
                    if (field.restore_value !== null && field.restore_value !== undefined) {
                        const valueObject = JSON.parse(field.restore_value);
                        restoreValue = valueObject[Object.keys(valueObject)[0]];
                    }
                    formProps.setFieldValue(id, restoreValue);
                    updatedValues[id] = restoreValue;
                }
            }
        }
        const { transaction } = this.props;
        const updatedLockedFields = this.getFieldsMappingToLockedDotFields({ transaction, cargo_types: allDotTypes, field_mappings: this.state.fieldMappings, fieldMappingUsages })
        this.setState({ values: updatedValues, fieldMappingUsages, fieldsMappingToLockedDots: updatedLockedFields }, async () => {
            await formProps.submitForm();
            this.updateTransactionDebounced(updatedValues)
        })
    }

    onUseUserTransformValueToggle = (formProps, id, hashId = null) => {
        let useUserTransformValue;
        let { userTransformUsages, eventLots, cargoFieldLots } = this.state;
        const { transaction } = this.props;
        if (hashId) {
            if (userTransformUsages[hashId] === undefined) {
                userTransformUsages[hashId] = {};
                userTransformUsages[hashId][id] = false;
                useUserTransformValue = false;
            } else {
                const oldValue = userTransformUsages[hashId][id];
                const newValue = (oldValue === undefined) ? false : !oldValue
                userTransformUsages[hashId][id] = newValue;
                useUserTransformValue = newValue;
            }
        } else {
            if (userTransformUsages[id] === undefined) {
                userTransformUsages[id] = false;
                useUserTransformValue = false;
            } else {
                userTransformUsages[id] = !userTransformUsages[id];
                useUserTransformValue = userTransformUsages[id];
            }
        }
        const allDotTypes = (this.props.cargoTypes || []).concat(this.state.cargoTypes);
        const updatedValues = Object.assign({}, formProps.values);
        if (!useUserTransformValue) {
            // user defined transform value was disabled - restore the value from the selected DoT Type
            if (hashId) {
                const dotTypeFieldInDashID = Object.keys(updatedValues).find(key => updatedValues[key] === hashId);
                if (dotTypeFieldInDashID) {
                    const dotTypeFieldInDash = transaction.transaction_field_list.find(f => f.field_id === dotTypeFieldInDashID);
                    let dot;
                    if (dotTypeFieldInDash) {
                        if (dotTypeFieldInDash.mapped_lot_id) {
                            dot = eventLots.find(d => d.id === dotTypeFieldInDash.mapped_lot_id);
                        } else if (dotTypeFieldInDash.input_lots && dotTypeFieldInDash.input_lots.length > 0) {
                            dot = eventLots.find(d => d.id === dotTypeFieldInDash.input_lots[0].lot_id);
                        }
                    } 
                    const dotType = dot ? allDotTypes.find(dt => dt.id === dot.cargo_type_hash_id) : null;
                    const field = dotType ? dotType.transaction_field_list.find(f => f.field_id === id) : null;
                    if (field) {
                        // parse the value from the selected DoT Type and assign it to the field
                        if (field.value.startsWith('{')) {
                            const valueObject = JSON.parse(field.value);
                            const restoreValue = valueObject[Object.keys(valueObject)[0]];
                            formProps.setFieldValue(`${hashId}.${id}`, restoreValue);
                            if (!updatedValues[hashId]) {
                                updatedValues[hashId] = {};
                            }
                            updatedValues[hashId][id] = restoreValue;
                        } else {
                            if (!updatedValues[hashId]) {
                                updatedValues[hashId] = {};
                            }
                            updatedValues[hashId][id] = '';
                        }
                    } else {
                        if (!updatedValues[hashId]) {
                            updatedValues[hashId] = {};
                        }
                        updatedValues[hashId][id] = '';
                    }
                }
            } else {
                const { transaction: { transaction_field_list }} = this.props;
                const field = transaction_field_list.find(f => f.field_id === id);
                if (field) {
                    // if field value is an object (or array, more likely) clone it instead of directly assigning value
                    let restoreValue = (typeof updatedValues[id] === 'object') ? Object.assign({}, updatedValues[id]) : updatedValues[id];
                    formProps.setFieldValue(id, restoreValue);
                    updatedValues[id] = restoreValue;
                }
            }
        }
        this.setState({ values: updatedValues, userTransformUsages }, async () => {
            await formProps.submitForm();
            this.updateTransactionDebounced(updatedValues)
        })
    }

    getCargoFieldPreview = (f, transaction, cargoTypes, cargoCategories, formProps, comparators, fields, eventCargoCategories, readOnly = false, extendedProps) => {
        const { loadingDotFields, cargoFieldLots } = this.state;

        const setTargetDotTemplate = args => {
            const { fieldId, selectedValue } = args;
            this.setState(prevState => {
                const targetFields = (prevState.cargoCategoryModalTargetFields || []);
                targetFields[fieldId] = selectedValue;
                return { cargoCategoryModalTargetFields: targetFields };
            });
        };

        const onDotFieldTemplateSelect = args => {
            const { fieldId } = args;
            this.setState(prevState => ({
                cargoCategoryModalCurrentTargetField: fieldId,
                cargoCategoryModalOpen: true
            }));
        }

        const onDotFieldQuantitySelected = (args, cb = () => null) => {
            const { fieldId, quantity } = args;
            this.setState({ inputLotFieldId: fieldId }, () => {
                this.onQuantitySelected(quantity, cb);
            })
        }

        const onDotFieldUnitSelected = (args, cb = () => null) => {
            const { fieldId, value } = args;
            this.setState({ inputLotFieldId: fieldId }, () => {
                this.onCargoUnitSelected(value, cb);
            })
        }

        let newDotQuantity = null;
        if (cargoFieldLots && cargoFieldLots && cargoFieldLots[f.id] && cargoFieldLots[f.id]['quantity']) {
            newDotQuantity = cargoFieldLots[f.id]['quantity']
        }

        let targetDotUnit = null;
        if (cargoFieldLots && cargoFieldLots && cargoFieldLots[f.id] && cargoFieldLots[f.id]['cargoUnit']) {
            targetDotUnit = cargoFieldLots[f.id]['cargoUnit']
        }

        return (
            <DotFieldPreview
                key={'dot-field-' + f.id}
                templateField={f}
                event={transaction}
                dotTypes={cargoTypes}
                dotTemplates={cargoCategories}
                formProps={formProps}
                comparators={comparators}
                fields={fields}
                eventDotTemplates={eventCargoCategories}
                cargoCategoryModalTargetFields={this.state.cargoCategoryModalTargetFields}
                loading={loadingDotFields && loadingDotFields[f.id]}
                initializeInputLotState={this.initializeInputLotState}
                setTargetDotTemplate={setTargetDotTemplate}
                onDotFieldTemplateSelect={onDotFieldTemplateSelect}
                fieldDots={this.state.cargoFieldLots[f.id] || null}
                transactionIsUpdating={this.state.transactionIsUpdating}
                dotsList={this.state.eventLots}
                onDotFieldQuantitySelected={onDotFieldQuantitySelected}
                onDotFieldUnitSelected={onDotFieldUnitSelected}
                readOnly={readOnly}
                onReviseClicked={id => {
                    const { getTransactionFieldSideEffectsDetails } = this.props;
                    this.setState({fieldReviseConfirmModalOpen: true, reviseFieldTarget: id, reviseFieldTargetObject: f, loadingFieldSideEffects: true, fieldSideEffectDetails: null });
                    getTransactionFieldSideEffectsDetails(id, details => {
                        this.setState({ fieldSideEffectDetails: details, loadingFieldSideEffects: false })
                    })
                }}
                fieldMappings={this.state.fieldMappings}
                // onChangeExt={(id, value) => {
                //     this.setState(prevState => {
                //         const touchedFieldIds = prevState.touchedFieldIds;
                //         if (!touchedFieldIds.find(f => f.id === id)) {
                //             touchedFieldIds.push({ id: id });
                //         }
                //         return { touchedFieldIds };
                //     })
                // }}
                onInputDotTypeMapped={async (fieldId, dotType, dotId) => {
                    const newValues = Object.assign({}, formProps.values);
                    const targetHashId = formProps.values[fieldId];
                    this.setState(prevState => {
                        const { cargoFieldLots } = prevState;
                        if (cargoFieldLots[fieldId] === undefined) {
                            cargoFieldLots[fieldId] = {};
                        }
                        cargoFieldLots[fieldId]['mappedLot'] = dotId;
                        return { cargoFieldLots };
                    })
                    if (targetHashId) {
                        if (dotType) {
                            for (const field of dotType.transaction_field_list) {
                                if (field.value.startsWith('{')) {
                                    const valueObject = JSON.parse(field.value);
                                    const valueProperty = valueObject[Object.keys(valueObject)[0]];
                                    newValues[targetHashId][field.field_id] = valueProperty;
                                    await formProps.setFieldValue(`${targetHashId}.${field.field_id}`, valueProperty);
                                }
                            }
                            formProps.submitForm()
                        }
                        this.updateTransactionDebounced(newValues)
                    }
                }}
                newDotQuantity={newDotQuantity}
                targetDotUnit={targetDotUnit}
                fieldMappingUsages={this.state.fieldMappingUsages}
                onUseMappedValueToggle={(id, hashId = null) => this.onUseMappedValueToggle(formProps, id, hashId)}
                fieldsMappingToLockedDots={this.state.fieldsMappingToLockedDots}
                userTransformUsages={this.state.userTransformUsages}
                onUseUserTransformValueToggle={(id, hashId = null) => this.onUseUserTransformValueToggle(formProps, id, hashId)}
                extendedProps={extendedProps}
            />
        )
    }

    debouncedOnChange = debounce((e, formProps) => {
        formProps.submitForm();
    }, 500);

    selectDotTemplateModal = (formProps) => {
        return (
            <ReactModal
                isOpen={this.state.cargoCategoryModalOpen}
                onRequestClose={() => this.setState({ cargoCategoryModalOpen: false })}
                className="modal-block dialog"
                overlayClassName="modal-overlay gray"
            >
                <LoadingOverlay
                    active={this.state.cargoCategoryModalLoading}
                    spinner
                    text={<FormattedMessage id='common.loading'/>}
                >
                    <div className="wd-500" role="document">
                        <div className="modal-content bd-0">
                            <div className="modal-header pd-x-20">
                                <FormattedMessage id="common.confirmCargoCategory" />
                            </div>
                            <SimpleBar> 
                                <div className="modal-body">
                                    <FormattedMessage id="page.transactions.confirmCargoCategorySelectPrompt" values={{
                                        categoryTitle: ( this.state.cargoCategoryModalTargetFields && 
                                                        this.state.cargoCategoryModalCurrentTargetField && 
                                                        this.state.cargoCategoryModalTargetFields[this.state.cargoCategoryModalCurrentTargetField] ) 
                                                        ?
                                                        this.state.cargoCategoryModalTargetFields[this.state.cargoCategoryModalCurrentTargetField].label  :  ''
                                    }} />
                                </div>
                            </SimpleBar>
                            <div className="modal-footer justify-content-center">
                                <div className="row d-flex justify-content-center align-items-center">
                                    <div className={"col-6"}>
                                        <button
                                            type="button"
                                            className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                            onClick={() => {
                                                this.setState({ cargoCategoryModalLoading: true });
                                                const {
                                                    transaction,
                                                    updateTransaction,
                                                    getTransaction,
                                                    createTransaction,
                                                    loadModel
                                                } = this.props;

                                                // load the full cargo category (including fields) that was selected
                                                const targetFieldId = this.state.cargoCategoryModalCurrentTargetField;
                                                const cargoCategoryId = this.state.cargoCategoryModalTargetFields[targetFieldId].value;
                                                    
                                                // Create a cargo type from the selected cargo category
                                                createTransaction(cargoCategoryId, 'cargo type', (cargoType) => {
                                                    // Now update the field in the root transaction to point to the cargo type    
                                                    const ctFieldValue = {};
                                                    ctFieldValue['cargo_value'] = cargoType.id;
                                                    const ctFieldValueData = { field_id: Number.parseInt(targetFieldId), value: JSON.stringify(ctFieldValue) };
                                                    const updateAction = { action: 'set', data: ctFieldValueData };
                                                    // Update the value with formik as well
                                                    formProps.setFieldValue(ctFieldValueData.field_id, cargoType.id);

                                                    const modifiedTransaction = {
                                                        transaction_id: transaction.id,
                                                        title: transaction.title,
                                                        description: transaction.description,
                                                        status_id: transaction.status_id,
                                                        fields: JSON.stringify({ updates: [ updateAction ] })
                                                    };
                                                    this.setState(prevState => {
                                                        const cargoTypes = prevState.cargoTypes;
                                                        cargoTypes.push(cargoType);
                                                        return { cargoTypes: cargoTypes };
                                                    }, () => {                                                                                
                                                        updateTransaction(modifiedTransaction, () => {
                                                            loadModel(cargoCategoryId, (cargoCategory) => {
                                                                getTransaction(transaction.id, () => {
                                                                    this.setState(prevState => {
                                                                        const cargoCategories = prevState.eventCargoCategories;
                                                                        cargoCategories.push(cargoCategory);
                                                                        return { 
                                                                            eventCargoCategories: cargoCategories,
                                                                            cargoCategoryModalLoading: false,
                                                                            cargoCategoryModalOpen: false,
                                                                        };
                                                                    });
                                                                });
                                                            });
                                                        }, () => {
                                                            this.setState({ cargoCategoryModalLoading: false, cargoCategoryModalOpen: false })
                                                        });
                                                    })

                                                });
                                            }}
                                        >
                                            <FormattedMessage id="common.ok" />
                                        </button>
                                    </div>
                                    <div className={"col-6"}>
                                        <button
                                            type="button"
                                            className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                            onClick={() => this.setState({ cargoCategoryModalOpen: false })}
                                        >
                                            <FormattedMessage id="common.cancel" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </LoadingOverlay>
            </ReactModal>
        )
    }

    onFinalSaveClicked = (formProps) => {
        this.validateRequiredFields(formProps.values)
            .then(() => {
                if (this.props.onSavingSet) {
                    this.props.onSavingSet(true);
                }
                this.updateTransactionDebounced(formProps.values, () => {
                    let missingQuantity=false;
                    for (const field of this.state.transaction.transaction_field_list) {            
                        const outputDot = this.state.transaction.model.model_field_list.find(f => field.field_id === f.id
                                && f.cargo_reference_type === 2);
                        if( outputDot ){
                            if ( !/[1-9]+[0-9]{0,}/.test(this.state.cargoFieldLots[field.field_id]['quantity']) ){
                                missingQuantity=true;
                                break;
                            }
                        }
                    }
                    this.setState({ finalSaveModalOpen: true, isMissingQuantity: missingQuantity })
                });
            })
            .catch((errors) => {
                console.warn(errors)
                Notifications.error(!!errors.params.label ? errors.params.label : <FormattedMessage id="common.requiredFieldsMissing"/>);
                return errors;
            });
    }

    onReviseClicked = () => {
        this.setState({ localLoading: true, isLoading: true }, async () => {
            const {
                transaction,
                updateTransaction,
                getTransaction,
                getTransactionRelatedData,
            } = this.props;
            const updateDetails = {
                transaction_id: transaction.id,
                title: transaction.title,
                description: transaction.description,
                status_id: 7,
            };
            transaction.status_id = 7;
            updateTransaction(updateDetails, () => {
                getTransaction(transaction.id, () => {
                    this.props.reload();
                })
            })
        });
    }

    finalSaveModal = () => {
        return (
            <ReactModal
                isOpen={this.state.finalSaveModalOpen}
                onRequestClose={() => this.setState({ finalSaveModalOpen: false })}
                className="modal-block dialog"
                overlayClassName="modal-overlay gray"
            >
                <LoadingOverlay
                    active={this.state.finalSaveLoading}
                    spinner
                    text={<FormattedMessage id='common.loading'/>}
                >
                    <div role="document">
                        <div className="modal-content  bd-0">
                            <div className="modal-header pd-x-20">
                                <FormattedMessage id="common.confirmFinalSave" />
                            </div>
                            {/* style={{ height: '600px' }} */}
                            <SimpleBar> 
                                <div className="modal-body">                                    
                                    { this.state.isMissingQuantity ? <FormattedMessage id="page.transactions.finalSavePromptMissingQuantity" /> : <FormattedMessage id="page.transactions.finalSavePrompt" /> }
                                </div>
                            </SimpleBar>
                            <div className="modal-footer justify-content-center">
                                <div className="row d-flex justify-content-center align-items-center">
                                    <div className={"col-6"}>
                                        <button
                                            id='se-save-final-modal-button'
                                            type="button"
                                            className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                            onClick={this.finalSave}
                                            disabled={this.state.transactionIsUpdating}
                                        >
                                            { this.state.transactionIsUpdating ? <FormattedMessage id="common.loading" /> : <FormattedMessage id="common.saveFinal" /> }
                                        </button>
                                    </div>
                                    <div className={"col-6"}>
                                        <button
                                            type="button"
                                            className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                            onClick={() => this.setState({ finalSaveModalOpen: false })}
                                        >
                                            <FormattedMessage id="common.cancel" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </LoadingOverlay>
            </ReactModal>
        )
    }

    eventPlaceholder = () => (
        <LoadingOverlay
            active={true}
            spinner
            text={<FormattedMessage id='common.loading'/>}
        >
            <div className="widget-2" style={{height: '20vw'}}>
                <div className="row">
                    <div className="col-12 pd-0">
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
        </LoadingOverlay>
    )

    render() {
        const { 
            transaction, 
            company, 
            comparators, 
            isLoading, 
            hideHeading = false, 
            hideTitleAndDescription = false, 
            cargoCategories,
            intl: { formatMessage },
            readOnly = false,
            FieldPreviewEl,
            fieldPreviewProps,
            extendedProps,
        } = this.props;
        const allCargoTypes = (this.props.cargoTypes || []).concat(this.state.cargoTypes);
        const { updatingFields, loadingFieldSideEffects, fieldSideEffectDetails } = this.state;
        if (!comparators) return null;

        if (this.state.isLoading) {
            return this.eventPlaceholder();
        }

        const formProps = {
            values: this.state.values, 
            initialValues: this.state.initialValues,
            setFieldValue: this.setFieldValue, 
            submitForm: this.submitForm,
        };

        const { reviseFieldTargetObject } = this.state;

        let sideEffectsSummary;
        let reviseEnabled;
        if (loadingFieldSideEffects) {
            sideEffectsSummary = (
                <div style={{height: '12em'}}>
                    <FormattedMessage id='determiningDotsSideEffects'/>
                </div>
            );
        } else if (fieldSideEffectDetails) {
            if (reviseFieldTargetObject.cargoReferenceType === 2) {
                reviseEnabled = true;
                const dotEffects = fieldSideEffectDetails.side_effects.filter(se => se.type === 'dot_type');
                const dashEffectsIds = fieldSideEffectDetails.side_effects
                    .filter(se => se.type === 'dash_field_dot_type' || se.type === 'dash')
                    .map(se =>  se.type === 'dash' ? se.affectedEntity.id : se.dotField.transaction_id);
                const uniqueDashEffectIds = [...new Set(dashEffectsIds)];
                if (fieldSideEffectDetails.side_effects.length > 0) {
                    sideEffectsSummary = (
                        <div>
                            <p><FormattedMessage id='dotsAndDashesAffectedByRevisingMessage'/></p>
                            <h6>
                                {
                                    (dotEffects.length > 0) && 
                                    <>
                                        <FormattedMessage id='dot(s):' />&nbsp;
                                        {
                                            dotEffects.map((dse, index) => {
                                                return dse.affectedDot.hash_id + (index === dotEffects.length - 1 ? '' : ', ');
                                            })
                                        }
                                    </>
                                }
                            </h6>
                            <h6>
                                {
                                    (uniqueDashEffectIds.length > 0) && 
                                    <>
                                        <FormattedMessage id='dash(es):' />&nbsp;
                                        {
                                            uniqueDashEffectIds.map((dse, index) => {
                                                return dse + (index === uniqueDashEffectIds.length - 1 ? '' : ', ');
                                            })
                                        }
                                    </>
                                }
                            </h6>
                            <br/>
                            <p style={{color: 'red'}}><FormattedMessage id='dotFieldWithSideEffectsReviseConfirmationMessage'/></p>
                            <p><FormattedMessage id='proceedToRevise' /></p>
                        </div>
                    );
                } else {
                    sideEffectsSummary = <FormattedMessage id='dotFieldReviseConfirmationMessage'/>;
                }
            } else {
                if (fieldSideEffectDetails.dots_in_use.length > 0) {
                    reviseEnabled = false
                    const dotIdsInUse = fieldSideEffectDetails.dots_in_use.map(d => d.dot.hash_id);
                    let dotIdsWithSideEffects = [];
                    if (fieldSideEffectDetails.side_effects.length > 0) {
                        dotIdsWithSideEffects = fieldSideEffectDetails.side_effects.map(d => d.dot.hash_id);
                    }
                    const uniqueDotIds = new Set(dotIdsInUse.concat(dotIdsWithSideEffects));
                    sideEffectsSummary = (
                        <div>
                            <p><FormattedMessage id='dotFieldSideEffectsCanNotBeRevised'/></p>
                            <h6>
                                {
                                    [...uniqueDotIds].map((id, index) => {
                                        return id + (index === uniqueDotIds.size - 1 ? '' : ', ');
                                    })
                                }
                            </h6>
                            <br/>
                            <p><FormattedMessage id='toReviseYouNeedToUnwind'/></p>
                        </div>
                    )
                } else {
                    reviseEnabled = true;
                    sideEffectsSummary = <FormattedMessage id='dotFieldReviseConfirmationMessage'/>;
                }
            }
        }


        return (
            <div className="transaction-render">
                <Prompt
                    when={this.state.transactionIsUpdating}
                    message={formatMessage({id:'common.unsavedChangesPrompt'})}
                />
                <LoadingOverlay
                    active={isLoading || updatingFields}
                    spinner
                    text={<FormattedMessage id='common.loading'/>}
                >
                    <>
                        <div className="widget-2">
                            <div className="row">
                                <div className="col-12 pd-0">
                                        <Event
                                            key={'event-' + (this.state.localLoading ? 'loaded' : 'loading')}
                                            transaction={transaction}
                                            company={company}
                                            comparators={comparators}
                                            hideHeading={hideHeading}
                                            hideTitleAndDescription={hideTitleAndDescription}
                                            cargoCategories={cargoCategories}
                                            formProps={formProps}
                                            getCargoFieldPreview={this.getCargoFieldPreview}
                                            cargoTypes={allCargoTypes}
                                            eventCargoCategories={this.state.eventCargoCategories}
                                            transactionIsUpdating={this.state.transactionIsUpdating}
                                            onFinalSaveClicked={this.onFinalSaveClicked}
                                            showSaveButton={this.props.showSaveButton}
                                            showCloseButton={this.props.showCloseButton}
                                            primaryButton={this.props.primaryButton}
                                            onCancel={this.props.onCancel}
                                            readOnly={readOnly}
                                            showQuantityInfo={this.props.showQuantityInfo}
                                            dot={this.props.dot}
                                            onReviseClicked={this.onReviseClicked}
                                            fieldMappings={this.state.fieldMappings}
                                            // onChangeExt={this.onChangeExt}
                                            fieldMappingUsages={this.state.fieldMappingUsages}
                                            onUseMappedValueToggle={(id, hashId = null) => this.onUseMappedValueToggle(formProps, id, hashId = null)}
                                            fieldsMappingToLockedDots={this.state.fieldsMappingToLockedDots}
                                            FieldPreviewEl={FieldPreviewEl}
                                            fieldPreviewProps={fieldPreviewProps}
                                            extendedProps={extendedProps}
                                        />
                                        { this.selectDotTemplateModal(formProps) }
                                    {/* </Form> */}
                                </div>
                            </div>
                        </div>
                        <InputLotModal 
                            key={this.state.targetTransactionField + '-' + this.state.lotModalOpen}
                            targetCargoType={this.state.inputLotCargoType}
                            targetTransactionField={this.state.targetTransactionField} 
                            transaction={transaction}
                            modalVisibility={this.state.lotModalOpen} 
                            onClose={() => this.setState({ lotModalOpen: false })}
                            onMultipleLotsSelected={this.onLotsSelected(formProps)} 
                            onLotSelected={this.onLotSelected} 
                            onQuantitySelected={this.onQuantitySelected}
                            onCargoUnitSelected={this.onCargoUnitSelected}
                            initialTargetLot={this.state.initialTargetLot}
                            initialTargetQuantity={this.state.initialTargetQuantity}
                            initialTargetCargoUnit={this.state.initialTargetCargoUnit}
                            transactionIsUpdating={this.state.transactionIsUpdating}
                            hideCreateNewDot={true}
                        />
                    </>
                    <ConfirmModal
                        id='confirm-field-revise'
                        open={this.state.fieldReviseConfirmModalOpen}
                        setOpen={(o) => this.setState({ fieldReviseConfirmModalOpen: o })}
                        hideConfirm={!reviseEnabled}
                        onConfirm={() => {
                            const { transactionFieldRevise, getTransaction } = this.props;
                            const { reviseFieldTarget } = this.state;
                            transactionFieldRevise({ field_id: reviseFieldTarget }, () => {
                                getTransaction(transaction.id, () => {
                                    this.props.reload();
                                })
                            });
                        }}
                        title={<FormattedMessage id='common.confirmVerb' values={{ verb: 'Revise'}} />}
                        body={
                            <LoadingOverlay
                                active={this.state.loadingFieldSideEffects}
                                spinner
                                text={<FormattedMessage id='common.loading'/>}
                            >
                                { sideEffectsSummary }
                            </LoadingOverlay>
                        }
                    />
                    
                    { this.finalSaveModal() }
                </LoadingOverlay>
            </div>
        );
    }
}

EventContainer.defaultProps = {
    cargoUnits: [],
};

const mapStateToProps = state => {
    return {
        comparators: state.fieldVisibility.comparators,
        cargoUnits: state.cargoUnits.list,
    };
};
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            updateTransaction,
            getComparators,
            filterTransactions,
            loadModel,
            getTransaction,
            createTransaction,
            listCargoUnits,
            getTransactionRelatedData,
            getTransactionDeleteSideEffects,
            transactionFieldRevise,
            getTransactionFieldSideEffectsDetails,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(EventContainer));
