import React from 'react';
import { injectIntl } from 'react-intl';
import { Field, ErrorMessage } from 'formik';
import { Range } from 'rc-slider';

import 'rc-slider/assets/index.css';
import './fields.scss';
import Tooltip from 'rc-tooltip';
import { FormattedMessage } from 'react-intl';

class RangeField extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isChanging: false };
        this.setChangingStatus = this.setChangingStatus.bind(this);
    }

    setChangingStatus = changing => this.setState({ ...this.state, isChanging: changing });

    render() {
        const { isChanging } = this.state;
        const {
            intl: { formatMessage },
            name,
            min,
            max,
            step,
            marks,
            submitOnChange,
            valueDisplay,
            disabled = false
        } = this.props;

        const renderValue =
            valueDisplay ||
            (value => {
                return value;
            });

        return (
            <div className={`slider-${isChanging ? 'active' : 'inactive'}`}>
                <Field
                    {...this.props}
                    render={({ form, field }) => {
                        return (
                            <React.Fragment>
                                <label className="d-flex justify-content-between align-items-center">
                                    <span>
                                    {formatMessage({ id: `${name}.placeholder` })} 
                                    <Tooltip placement={'top'} trigger={['hover']} 
                                    overlay={
                                        <React.Fragment>
                                            <p><FormattedMessage id="widthOffset.moreInformation1" /></p>
                                            <p><FormattedMessage id="widthOffset.moreInformation2" /></p>
                                            <p><FormattedMessage id="widthOffset.moreInformation3" /></p>
                                            <p><FormattedMessage id="widthOffset.moreInformation4" /></p>
                                        </React.Fragment>
                                    }>
                                        <i className="material-icons-outlined" style={{fontSize: "14px", marginLeft: "0.5em"}}>info</i>
                                    </Tooltip>
                                    </span>
                                    <span>{renderValue(field.value)}</span>
                                </label>
                                <Range
                                    disabled={disabled}
                                    min={min || 0}
                                    max={max || 100}
                                    value={field.value}
                                    allowCross={false}
                                    onChange={async v => {
                                        this.setChangingStatus(true);
                                        await form.setFieldValue(name, v);
                                        if (!!submitOnChange) {
                                            form.submitForm();
                                        }
                                    }}
                                    onAfterChange={() => {
                                        this.setChangingStatus(false);
                                        form.handleBlur();
                                    }}
                                    step={step}
                                    marks={marks}
                                />
                            </React.Fragment>
                        );
                    }}
                />
                <ErrorMessage name={name} component="div" className="validation-message" />
            </div>
        );
    }
}

export default injectIntl(RangeField);
