import React from 'react';
import { FormattedMessage } from 'react-intl';
import { call, hasErrors } from '../configuration/api';
import { Notifications } from '../components/notifications';
import { LOTS_LIST, LOTS_LIST_MATCHES, LOTS_LIST_LIGHT } from './types';
import Queries from './queries';

const defaultState = {
    listMatches: [],
    list: [],
    listWithoutFields: [],
    total: 0,
    totalWithoutFields: 0,
    matchesTotal: 0,
    statuses: undefined
};

/**
 * Loading state mapper.
 * @param {object} state
 * @param {string} action
 */
export default function(state = defaultState, action) {
    switch (action.type) {
        case LOTS_LIST:
            const list = action.list.some(d => !!d.cargo_type.index_fields_data)
                ? action.list.map(d => ({ ...d, cargo_type: { ...d.cargo_type, index_fields_data: undefined, ...JSON.parse(d.cargo_type.index_fields_data) }}))
                : action.list;
            return { ...state, list: list, total: Number(action.total) };
        case LOTS_LIST_LIGHT:
            return { ...state, listWithoutFields: action.list, totalWithoutFields: Number(action.total) };
        case LOTS_LIST_MATCHES:
            return { ...state, listMatches: action.listMatches, matchesTotal: Number(action.matchesTotal) };
        
        default:
            return state;
    }
}

export const listMatchingLots = (variables, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Lots.ListMatches, variables });
    if (hasErrors(response)) {
        onError(response);
        return;
    }

    const { data } = response;
    if (!data || !data.lot_list_matches) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    // Map tag (index) field data as if it is a native field
    const listMatchesFixed = data.lot_list_matches.data.some(d => !!d.cargo_type.index_fields_data)
    ? data.lot_list_matches.data.map(d => ({ ...d, cargo_type: { ...d.cargo_type, index_fields_data: undefined, ...JSON.parse(d.cargo_type.index_fields_data) }}))
    : data.lot_list_matches.data;

    data.lot_list_matches.data = listMatchesFixed;

    dispatch({ type: LOTS_LIST_MATCHES, listMatches: data.lot_list_matches.data, matchesTotal: data.lot_list_matches.total });
    onComplete(data.lot_list_matches);
};

export const listLots = (variables, onComplete = () => null, onError = () => null) => async dispatch => {
    let response;
    if (variables.excludeFields) {
        response = await call({ query: Queries.Lots.ListLight, variables });
    } else {
        response = await call({ query: Queries.Lots.List, variables });
    }
    if (hasErrors(response)) {
        onError(response);
        return;
    }

    const { data } = response;
    if (!data || !data.lot_list) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    if (variables.excludeFields) {
        dispatch({ type: LOTS_LIST_LIGHT, list: data.lot_list.data, total: data.lot_list.total });
    } else {
        dispatch({ type: LOTS_LIST, list: data.lot_list.data, total: data.lot_list.total });
    }
    onComplete(data.lot_list);
};
