import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { call, hasErrors } from '../configuration/api';
import { Notifications } from '../components/notifications';
import { COMPANIES_GET_LIST, COMPANIES_GET_STATUSES, COMPANIES_GET_INVITE_LINKS, COMPANIES_GET_INVITE_RESPONSES, COMPANIES_GET_INVOICES } from './types';
import Queries from './queries';

const defaultState = {
    list: undefined,
    total: 0,
    statuses: undefined,
    links: undefined,
    inviteResponses: undefined,
    invoices: undefined,
    totalInvoices: 0,
};

/**
 * Companies state mapper.
 * @param {object} state
 * @param {string} action
 */
export default function(state = defaultState, action) {
    switch (action.type) {
        case COMPANIES_GET_LIST:
            return { ...state, list: action.payload.data, total: Number.parseInt(action.payload.total) };

        case COMPANIES_GET_STATUSES:
            return { ...state, statuses: action.payload };

        case COMPANIES_GET_INVITE_LINKS:
            return { ...state, links: action.payload };

        case COMPANIES_GET_INVITE_RESPONSES:
            return { ...state, inviteResponses: action.payload };

        case COMPANIES_GET_INVOICES:
            return { ...state, invoices: action.payload.data, totalInvoices: action.payload.total };

        default:
            return state;
    }
}

export const getCompanies = (variables, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Companies.List, variables: { status_ids: [2, 3, 4, 5, 6, 7], ...variables } });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.company_list) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    dispatch({ type: COMPANIES_GET_LIST, payload: data.company_list });
    onComplete(data.company_list);
};

export const setupCompany = (variables, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Companies.Create, variables });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.company_create_user_invite) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.company_create_user_invite);
};

export const updateCompany = (variables, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Companies.Update, variables });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.company_update) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.company_update);
};

export const getStatuses = (onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Companies.GetStatuses });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.company_statuses) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    dispatch({ type: COMPANIES_GET_STATUSES, payload: data.company_statuses });
    onComplete(data.company_statuses);
};

export const createInviteLink = (title, description, onComplete = () => null) => async dispatch => {
    const response = await call({
        query: Queries.Companies.CreateInvite,
        variables: {
            title,
            description,
            type_name: 'company_invitation',
            max_uses: 1,
            exp_on: moment()
                .add(6, 'months')
                .format(),
            data: "{json_value:1, json_text: 'company_invite'}"
        }
    });
    if (hasErrors(response)) {
        return;
    }

    const { data } = response;
    if (!data || !data.link_create_ex) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.link_create_ex);
};

export const storeInvitationResponse = (invitation, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Companies.StoreInviteData, variables: { ...invitation } });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.company_create_prescreen) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.company_create_prescreen);
};

export const getInviteLinks = (onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Companies.GetInviteLinks, variables: { type_ids: [4] } });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.link_list) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    const links = data.link_list.filter(l => l.is_valid === true);

    dispatch({ type: COMPANIES_GET_INVITE_LINKS, payload: links });
    onComplete(links);
};

export const getInviteResponses = (variables, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Companies.GetInviteResponses, variables: { status_ids: [1, 2, 3, 4, 5, 6, 7], ...variables } });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.company_list_prescreen || !data.company_list_prescreen.data) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    const result = data.company_list_prescreen.data.map(d => ({
        ...d,
        temp_user: undefined,
        new_user_first_name: (d.temp_user.name || '').split(' ')[0] || '',
        new_user_last_name: (d.temp_user.name || '').split(' ')[1] || '',
        new_user_email: d.temp_user.email || '',
        new_user_phone: d.temp_user.phone || '',
        new_user_country_code: d.country_code2
    }));

    dispatch({ type: COMPANIES_GET_INVITE_RESPONSES, payload: result });
    onComplete(result);
};

export const approveInvite = (company_id, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Companies.ApproveInvite, variables: { company_id } });
    if (hasErrors(response)) {
        return;
    }

    const { data } = response;
    if (!data || !data.company_approve) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.company_approve);
};

export const rejectInvite = (company_id, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Companies.RejectInvite, variables: { company_id } });
    if (hasErrors(response)) {
        return;
    }

    const { data } = response;
    if (!data || !data.company_reject) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.company_reject);
};

export const updateInvite = (variables, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Companies.UpdateInvite, variables });
    if (hasErrors(response)) {
        return;
    }

    const { data } = response;
    if (!data || !data.company_update_prescreen) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.company_update_prescreen);
};

export const deleteCompany = (variables, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Companies.Delete, variables });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.company_delete) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.company_delete);
};

export const getCompanyInvoices = (variables, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Companies.GetInvoices, variables });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.company_list_invoices) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    dispatch({ type: COMPANIES_GET_INVOICES, payload: data.company_list_invoices });
    onComplete(data.company_list_invoices);
};

export const setCompanyBillingEmail = (variables, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Companies.SetBillingEmail, variables });
    if (hasErrors(response)) {
        onError(response);
        return;
    }

    const { data } = response;
    if (!data || !data.company_set_billing_email) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.company_set_billing_email);
};

