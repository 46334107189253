import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import FileSaver from 'file-saver';
import { call, hasErrors, upload } from '../configuration/api';
import Queries from './queries';
import { Notifications } from '../components/notifications';
import { 
    ALERTS_LIST, 
    ALERTS_GET, 
    ALERTS_CREATE, 
    ALERTS_UPDATE, 
    ALERTS_DELETE, 
    ALERT_CONDITIONS_LIST,
    ALERT_CONDITIONS_GET,
    ALERT_CONDITIONS_CREATE,
    ALERT_CONDITIONS_UPDATE,
    ALERT_CONDITIONS_DELETE,
} from './types';

const defaultState = {
    alertsList: [],
    alertsTotal: 0,
    alert: {},
    conditionsList: [],
    conditionsTotal: 0,
    condition: {},
};

/**
 * Loading state mapper.
 * @param {object} state
 * @param {string} action
 */
export default (state = defaultState, action) => {
    let total, data;
    switch (action.type) {
        case ALERTS_LIST:
            ({ total, data } = action.payload)
            return { ...state, alertsList: data, alertsTotal: Number.parseInt(total) };

        case ALERTS_GET:
            return { ...state, alert: action.data };

        case ALERTS_CREATE:
            return { ...state, alert: action.data };

        case ALERTS_UPDATE:
            return { ...state, alert: action.data };

        case ALERTS_DELETE:
            return { ...state, alert: action.data };

        case ALERT_CONDITIONS_LIST:
            ({ total, data } = action.payload)
            return { ...state, conditionsList: data, conditionsTotal: Number.parseInt(total) };

        case ALERT_CONDITIONS_GET:
            return { ...state, condition: action.data };

        case ALERT_CONDITIONS_CREATE:
            return { ...state, condition: action.data };

        case ALERT_CONDITIONS_UPDATE:
            return { ...state, condition: action.data };

        case ALERT_CONDITIONS_DELETE:
            return { ...state, condition: action.data };

        default:
            return state;
    }
}

export const CONDITION_TYPES = {
    event: 1,
    index: 2,
    dot: 3,
}

export const listAlerts = (variables, onComplete = () => null, onError = () => null, doDispatch = true) => async dispatch => {
    const response = await call({ query: Queries.Alerts.List, variables });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.alert_list) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }
    if (doDispatch) dispatch({ type: ALERTS_LIST, payload: data.alert_list });

    onComplete(data.alert_list);
};

export const getAlert = (alert_id, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Alerts.Get, variables: alert_id });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.alert) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }

    dispatch({ type: ALERTS_GET, data: data.alert });
    onComplete(data.alert);
};

export const createAlert = (alert, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Alerts.Create, variables: alert });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.alert_create) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }

    dispatch({ type: ALERTS_CREATE, data: data.alert_create });
    onComplete(data.alert_create);
};

export const updateAlert = (alert, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Alerts.Update, variables: alert });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.alert_update) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }

    dispatch({ type: ALERTS_UPDATE, data: data.alert_update });
    onComplete(data.alert_update);
};

export const deleteAlert = (alert, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Alerts.Delete, variables: alert });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.alert_delete) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }

    dispatch({ type: ALERTS_DELETE, data: data.alert_delete });
    onComplete(data.alert_delete);
};

export const modifyAlertSubscriber = (input, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Alerts.ModifySubscriber, variables: input });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.alert_modify_subscriber) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }

    // dispatch({ type: ALERTS_DELETE, payload: data.alert_modify_subscriber });
    onComplete(data.alert_modify_subscriber);
};

export const unsubscribeAlert = (input, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Alerts.Unsubscribe, variables: input });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.alert_unsubscribe) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }

    // dispatch({ type: ALERTS_DELETE, payload: data.alert_unsubscribe });
    onComplete(data.alert_unsubscribe);
};

export const listConditions = (variables, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Alerts.ListCondition, variables: variables });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.alert_condition_list) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }

    dispatch({ type: ALERT_CONDITIONS_LIST, payload: data.alert_condition_list });
    onComplete(data.alert_condition_list);
};

export const getCondition = (variables, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Alerts.GetCondition, variables: variables });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.alert_condition) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }

    dispatch({ type: ALERT_CONDITIONS_GET, payload: data.alert_condition });
    onComplete(data.alert_condition);
};

export const createCondition = (variables, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Alerts.CreateCondition, variables: variables });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.alert_condition_create) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }

    dispatch({ type: ALERT_CONDITIONS_CREATE, payload: data.alert_condition_create });
    onComplete(data.alert_condition_create);
};

export const updateCondition = (variables, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Alerts.UpdateCondition, variables: variables });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.alert_condition_update) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }

    dispatch({ type: ALERT_CONDITIONS_UPDATE, payload: data.alert_condition_update });
    onComplete(data.alert_condition_update);
};

export const deleteCondition = (variables, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Alerts.DeleteCondition, variables: variables });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.alert_condition_delete) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }

    dispatch({ type: ALERT_CONDITIONS_DELETE, payload: data.alert_condition_delete });
    onComplete(data.alert_condition_delete);
};

export const setConditionReference = (variables, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Alerts.SetReference, variables: variables });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.alert_condition_set_reference) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }

    dispatch({ type: ALERT_CONDITIONS_UPDATE, payload: data.alert_condition_set_reference });
    onComplete(data.alert_condition_set_reference);
};
