import { DataStorage } from './storage';
import { store } from '../configuration/store';
import { IDENTITY_AUTHENTICATE } from '../reducers/types';
import { history } from '../configuration/history';
import { logout as logoutWithDispatch } from 'reducers/identity'

const checkIfImpersonate = () => {
    const url = window.location.pathname;
    const parts = url.split('/impersonate/');
    if (parts && parts.length < 2) return undefined;

    const id = parts[1];
    const tokenString = DataStorage.local.getItem(id);
    const token = JSON.parse(tokenString);
    DataStorage.setToken(token);
    DataStorage.local.removeItem(id);
    history.push('/dashboard' + window.location.search);
};

export const initializeIdentity = () => {
    checkIfImpersonate();
    const token = DataStorage.getToken();
    if (token) {
        store.dispatch({ type: IDENTITY_AUTHENTICATE, payload: { token } });
    }
};

export const logout = () => {
    logoutWithDispatch();
    setTimeout(() => history.push('/dashboard' + window.location.search), 100);
};
