/**
 * Get user details.
 */
export const PROFILE = `
    query details {
        user: user_current {
            id
            status_id
            status_name
            created_on
            modified_on
            email
            auth_type
            last_login_on
            login_attempts
            reset_password
            name
            avatar_id
            lang_id
            lang
            country_id
            country_code2
            country_code3
            state
            city
            zip
            address_1
            address_2
            phone
            extra_info
            
            mfa_required

            avatar {
                id

                thumb(width: 200, height: 200, fit: false) {
                    bucket
                    key
                    size
                    mime
                    last_modified
                    url
                }
            }

            entity_permissions
            permission_list {
                id
                is_admin_level
                code
                title
            }

            company {
                id
                status_id
                status_name
                created_on
                modified_on
                code
                company_name
                lang_id
                lang
                country_id
                country_code2
                country_code3
                custom_domain
                description
                website
                email
                signature
                city
                address_1
                address_2
                state
                zip
                phone1
                phone2
                fax
                branding_config

                is_system

                stripe_customer_id
                is_paid
                payment_source
                subscription_id
                subscription_status
                subscription_type
                last_payment_check
                subscription_seats
                plan_id

                default_prefs
                extra_info

                currency_id
                currency_name

                is_library_admin

                logo {
                    id

                    thumb(width: 189, height: 60, fit: true) {
                        bucket
                        key
                        size
                        mime
                        last_modified
                        url
                    }
                }
            }
        }
    }
`;
