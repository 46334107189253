export const FIELDS_DELETE = `
    mutation M($field_id: Long!) {
        model_field_delete(field_id: $field_id) {
            id
            model_id
            order
            type_id
            type_name
            status_id
            status_name
            index_field_id
            input_id
            visibility_check
            title
            description
            is_required
            validation
            width
            force_break
            extra_info
            company_id: model_company_id
        }
    }
`;
