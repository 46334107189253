import React from 'react';
import { call, hasErrors } from '../configuration/api';
import Queries from './queries';
import { FormattedMessage } from 'react-intl';
import { Notifications } from '../components/notifications';

const SET_SELECTED_LIBRARY = 'SET_SELECTED_LIBRARY';

const defaultState = {
    selectedLibrary: null,
};

/**
 * Document state mapper.
 * @param {object} state
 * @param {string} action
 */
export default function(state = defaultState, action) {
    switch (action.type) {
        case SET_SELECTED_LIBRARY:
            return { ...state, selectedLibrary: action.payload };
        default:
            return state;
    }
}

export const setSelectedLibrary = (library, onComplete = () => null) => async dispatch => {
    await dispatch({ type: SET_SELECTED_LIBRARY, payload: library });
    return onComplete();
}

export const getEntityGroup = (input, onComplete = () => null, onError = () => null) => async dispatch => {
    const includeCount = input && input.includeCount;
    delete input.includeCount;
    let response;
    if (includeCount) {
        response = await call({ query: Queries.EntityGroup.GetWithCount, variables: input });
    } else {
        response = await call({ query: Queries.EntityGroup.Get, variables: input });
    }
    if (hasErrors(response)) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.entity_group) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }
    onComplete(data.entity_group);
};

export const createEntityGroup = (input, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.EntityGroup.Create, variables: input });
    if (hasErrors(response)) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.entity_group_create) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }
    onComplete(data.entity_group_create);
};

export const listEntityGroups = (input, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.EntityGroup.List, variables: input });
    if (hasErrors(response)) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.entity_group_list) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }
    onComplete(data.entity_group_list);
};

export const updateEntityGroup = (input, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.EntityGroup.Update, variables: input });
    if (hasErrors(response)) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.entity_group_update) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }
    onComplete(data.entity_group_update);
};

export const importEntityGroup = (input, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.EntityGroup.Import, variables: input });
    if (hasErrors(response)) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.entity_group_import) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }
    onComplete(data.entity_group_import);
};

export const cloneEntityGroup = (input, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.EntityGroup.Clone, variables: input });
    if (hasErrors(response)) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.entity_group_clone) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }
    onComplete(data.entity_group_clone);
};

export const getEntityGroupIssues = (input, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.EntityGroup.GetIssues, variables: input });
    if (hasErrors(response)) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.entity_group_issues) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }
    onComplete(data.entity_group_issues);
};

export const deleteEntityGroup = (input, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.EntityGroup.Delete, variables: input });
    if (hasErrors(response)) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.entity_group_delete) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }
    onComplete(data.entity_group_delete);
};