import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import EdiText from 'react-editext'
import IconSelectorModal from '../components/iconSelector';
import LibraryTemplateSelector from '../components/libraryTemplateSelector';
import EntityModal from '../components/entityModal';
import { IconPicker, IconPickerItem } from 'react-fa-icon-picker'
import LibraryCarousel from '../components/libraryCarousel';
import TextSelectorModal from '../components/libraryTextModal';
import ConfirmModal from 'components/modals/confirm'
import SelectControl from 'components/fields/uncontrolledComponents/selectControl';
import { createEntityGroup, setSelectedLibrary, updateEntityGroup, getEntityGroupIssues, deleteEntityGroup } from 'reducers/entityGroup';
import { Notifications } from 'components/notifications';
import { history } from 'configuration/history.js';
import Page from 'components/page';
import { getMedia } from 'reducers/media';
import FontIconPicker from '@fonticonpicker/react-fonticonpicker';
import { icons } from '../components/icons';
import carouselIcon from '../images/carousel-icon.png';
import tableIcon from '../images/table-icon.png';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css';

import './createDashboard.scss'


const validLibraryStatuses = [{label: 'Draft', value: 1}, {label: 'Active', value: 2}, {label: 'Deleted', value: 3}, {label: 'Delete Permanently', value: 4}];
const validLibraryLocales = [{label: 'EN', value: 'en'}, {label: 'PT', value: 'pt'}];

function LibraryCreateDashboard({ createEntityGroup, updateEntityGroup, selectedLibrary, parents, setSelectedLibrary, getMedia, getEntityGroupIssues, deleteEntityGroup, intl: { formatMessage } }) {
    let selectedLibraryJson;
    if (!!selectedLibrary) {
        selectedLibraryJson = JSON.stringify(selectedLibrary);
    }
    const initialTitle = formatMessage({ id: 'libraryInitialTitle' });
    const initialDescription = formatMessage({ id: 'libraryInitialDescription' });
    const initialDetailedDescription = formatMessage({ id: 'libraryDetailedDescription' });
    const initialIcon = '';
    const initialStatus = validLibraryStatuses[0];
    const initialLocale = validLibraryLocales[0];
    const initialTable = {
        rows: [
            { 
                header: true,
                columns: [
                    {
                        icons: [],
                        text: {
                            runs: [
                            ]
                        },
                    },
                ]
            },
        ]
    };
    const initialLibraryTemplates = { 
        selectedDashes: [], 
        selectedDots: [], 
        selectedDocuments: [],
    };

    const [libraryTitle, setLibraryTitle] = useState(initialTitle);
    const [libraryDescription, setLibraryDescription] = useState(initialDescription);
    const [libraryDetailedDescription, setLibraryDetailedDescription] = useState(initialDetailedDescription);
    const [primaryIconSelectorOpen, setPrimaryIconSelectorOpen] = useState(false);
    const [primaryIcon, setPrimaryIcon] = useState(initialIcon);
    const [templateSelectorOpen, setTemplateSelectorOpen] = useState(false);
    const [libraryTemplates, setLibraryTemplates] = useState(initialLibraryTemplates);
    const [libraryTags, setLibraryTags] = useState([]);
    const [libraryStatus, setLibraryStatus] = useState(initialStatus);
    const [libraryTable, setLibraryTable] = useState(initialTable);
    const [targetRow, setTargetRow] = useState(-1);
    const [targetColumn, setTargetColumn] = useState(-1);
    const [cellIconSelectorOpen, setCellIconSelectorOpen] = useState(false);
    const [cellTextSelectorOpen, setCellTextSelectorOpen] = useState(false);
    const [isTableView, setIsTableView] = useState(true);
    const [modalEntity, setModalEntity] = useState();
    const [entityModalOpen, setEntityModalOpen] = useState(false);
    const [entityModalType, setEntityModalType] = useState('');
    const [entityModalTitle, setEntityModalTitle] = useState('');
    const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
    const [deleteMode, setDeleteMode] = useState('');
    const [libraryIsActive, setLibraryIsActive] = useState(false);
    const [libraryLocale, setLibraryLocale] = useState(initialLocale)
    const [templateIcons, setTemplateIcons] = useState([]);
    const [templateDescriptions, setTemplateDescriptions] = useState([]);
    const [dashesWithMissingDots, setDashesWithMissingDots] = useState([]);
    const [documentsWithMissingReferences, setDocumentsWithMissingReferences] = useState([]);
    const [confirmDeleteLibraryOpen, setconfirmDeleteLibraryOpen] = useState(false);

    useEffect(() => {
        if (!!selectedLibraryJson) {
            const selectedLibrary = JSON.parse(selectedLibraryJson);
            if (!!selectedLibrary.label) {
                setLibraryTitle(selectedLibrary.label);
            }
            if (!!selectedLibrary.description) {
                setLibraryDescription(selectedLibrary.description);
            }
            if (!!selectedLibrary.detailed_description) {
                setLibraryDetailedDescription(selectedLibrary.detailed_description);
            }
            if (!!selectedLibrary.icon) {
                setPrimaryIcon(selectedLibrary.icon);
            }
            if (!!selectedLibrary.status_id) {
                let status = validLibraryStatuses.find(s => s.value === selectedLibrary.status_id);
                setLibraryStatus(status);
                if (status.value === 2) {
                    setLibraryIsActive(true);
                } else {
                    setLibraryIsActive(false);
                }
            }
            let parsedMetadata;
            if (!!selectedLibrary.metadata) {
                parsedMetadata = JSON.parse(selectedLibrary.metadata);
                setLibraryTable(parsedMetadata);
            }
            if (!!parsedMetadata.templateIcons) {
                setTemplateIcons(parsedMetadata.templateIcons);
            }
            if (!!parsedMetadata.templateDescriptions) {
                setTemplateDescriptions(parsedMetadata.templateDescriptions);
            }
            setLibraryTemplates({
                selectedDashes: selectedLibrary.templates.filter(t => t.model_type_name === 'event template'),
                selectedDots: selectedLibrary.templates.filter(t => t.model_type_name === 'cargo category'),
                selectedDocuments: selectedLibrary.document_templates,
            });
            if (!!selectedLibrary.locale) {
                let libraryLocale = validLibraryLocales.find(s => s.value === selectedLibrary.locale);
                setLibraryLocale(libraryLocale);
            }
            setLibraryTags(selectedLibrary.tags.map(tag => tag.id));
            if( selectedLibrary.status_id !== 4 ){
                getEntityGroupIssues({ id: selectedLibrary.id }, issues => {
                    setDashesWithMissingDots(issues.dashes_with_missing_dots);
                    setDocumentsWithMissingReferences(issues.documents_with_missing_references);
                    if (issues.dashes_with_missing_dots.length > 0 || issues.documents_with_missing_references.length > 0) {
                        const draftStatus = validLibraryStatuses.find(s => s.value === 1);
                        setLibraryStatus(draftStatus);
                        setLibraryIsActive(false);
                    }
                });
            }
        }
    }, [selectedLibraryJson])
    

    function getIcon(name, small = false) {
        return <i className={name} style={{fontSize: 48, color: '#AAA'}} />
    }

    function renderRow(row) {
        return <div className={ row.header ? 'collection-row-header' : 'collection-row'}>
            {
                row.columns.map(column => {
                    let columnComponents = [];
                    if (column.icons) {
                        const iconSize = !!column.smallIcons ? 5 : 5;
                        const iconsContainer = <div className='collection-chart-icons'>
                            {
                                column.icons.map(icon => getIcon(icon, !!column.smallIcons))
                            }
                        </div>;
                        columnComponents.push(iconsContainer);
                    }
                    if (column.text) {
                        let className = '';
                        if (column.clickable) {
                            className += 'clickable ';
                        }
                        if (column.bold) {
                            className += 'collection-column-bold ';
                        } else {
                            className += 'collection-column-regular ';
                        }
                        if (column.blue) {
                            className += 'collection-column-blue ';
                        } else {
                            className += 'collection-column-grey ';
                        }
                        const textEls = <p className={className} >
                            {
                                column.text.runs.map(run => {
                                    if (!!run.linkType) {
                                        let clickHandler = () => null;

                                        if (run.linkType === 'dash') {
                                            clickHandler = () => {
                                                setModalEntity(run.entity);
                                                setEntityModalOpen(true);
                                                setEntityModalType('dash');
                                                setEntityModalTitle(run.entity.title);
                                            }
                                        } else if (run.linkType === 'dot') {
                                            clickHandler = () => {
                                                setModalEntity(run.entity);
                                                setEntityModalOpen(true);
                                                setEntityModalType('dot');
                                                setEntityModalTitle(run.entity.title);
                                            }
                                        } else if (run.linkType === 'dash-template') {
                                            clickHandler = () => {
                                                setModalEntity(run.entity);
                                                setEntityModalOpen(true);
                                                setEntityModalType(run.linkType);
                                                setEntityModalTitle(run.entity.title);
                                            }
                                        } else if (run.linkType === 'dot-template') {
                                            clickHandler = () => {
                                                setModalEntity(run.entity);
                                                setEntityModalOpen(true);
                                                setEntityModalType(run.linkType);
                                                setEntityModalTitle(run.entity.title);
                                            }
                                        } else if (run.linkType === 'document') {
                                            clickHandler = (ev) => {
                                                const docObj = JSON.parse(run.entity);

                                                getMedia(docObj.id, result => {
                                                    setModalEntity(result);
                                                    setEntityModalType('document');
                                                    setEntityModalTitle(run.entity.title);
                                                    setEntityModalOpen(true);
                                                })
                                            }
                                        } else if (run.linkType === 'document-template') {
                                            clickHandler = (ev) => {
                                                ev.stopPropagation(); 
                                                ev.preventDefault();
                                                setModalEntity(run.entity);
                                                setEntityModalType(run.linkType);
                                                setEntityModalTitle(run.entity.title);
                                                setEntityModalOpen(true);
                                            }
                                        }
                                        return (
                                            <span 
                                                className='collection-column-blue' 
                                                onClick={clickHandler}
                                                style={{cursor: 'pointer'}}
                                            >
                                                {run.text} &nbsp;
                                            </span>
                                        )
                                    } else {
                                        return run.text + ' ';
                                    }
                                })
                            }
                        </p>
                        columnComponents.push(textEls);
                    }
                    return <div className='collection-chart-column'> 
                        { columnComponents } 
                        {/* <div className='collection-chart-row-separator' /> */}
                    </div>;
                })
            }
        </div>
    }

    const addRow = () => {
        let rows = libraryTable.rows.slice();
        let columnCount = 0;
        if (rows.length > 0) {
            const firstRow = rows[0];
            columnCount = firstRow.columns.length;
        }
        const newRow = {
            columns: [
            ]
        };
        for (let i = 0; i < columnCount; i++) {
            newRow.columns.push({ icons: [], text: { runs: [] }});
        }
        rows.push(newRow);
        setLibraryTable({ rows });
    }

    const deleteRow = (index) => {
        let rows = libraryTable.rows.slice();
        rows.splice(index, 1);
        setLibraryTable({ rows });
    }

    const addColumn = () => {
        let rows = libraryTable.rows.slice(0);
        for (let i = 0; i < rows.length; i++) {
            rows[i].columns.push({ icons: [], text: { runs: [] } });
        }
        setLibraryTable({ rows });
    }

    const deleteColumn = (index) => {
        let rows = libraryTable.rows.slice(0);
        for (let i = 0; i < rows.length; i++) {
            rows[i].columns.splice(index, 1);
        }
        setLibraryTable({ rows });
    }

    const setCellIcons = (row, column, iconsUnfiltered) => {
        const icons = iconsUnfiltered.filter(i => !!i);
        let rows = libraryTable.rows.splice(0);
        const targetRow = rows[row];
        const targetCell = targetRow.columns[column];
        targetCell.icons = icons;
        targetRow.columns[column] = targetCell;
        rows[row] = targetRow;
        setLibraryTable({ rows });
    }

    const isBackContent = (row, column) => {
        const targetCell = libraryTable.rows[row].columns[column];
        return !!targetCell && !!targetCell.cardContent
    }

    const toggleBackContent = (row, column) => {
        let rows = libraryTable.rows.splice(0);
        const targetRow = rows[row];
        const targetCell = targetRow.columns[column];
        targetCell.cardContent = !targetCell.cardContent;
        targetRow.columns[column] = targetCell;
        rows[row] = targetRow;
        setLibraryTable({ rows });
    }

    const rowDeleteButtonStyle = {
        height: '20px',
        lineHeight: '20px',
        width: '20px',
        fontSize: '1em',
        fontWeight: 'bold',
        borderRadius: '50%',
        backgroundColor: 'red',
        color: 'white',
        textAlign: 'center',
        cursor: 'pointer',
        position: 'absolute',
        left: '3px',
        marginTop: '15px',
    };

    const colDeleteButtonStyle = {
        height: '20px',
        lineHeight: '20px',
        width: '20px',
        fontSize: '1em',
        fontWeight: 'bold',
        borderRadius: '50%',
        backgroundColor: 'red',
        color: 'white',
        textAlign: 'center',
        cursor: 'pointer',
        position: 'absolute',
        top: '50px',
        marginLeft: '55px',
    };

    const libraryHasIssues = dashesWithMissingDots.length > 0 || documentsWithMissingReferences.length > 0;
    const getReferenceType = (referenceType) => {
        switch (referenceType) {
            case 'dot-field':
                return formatMessage({ id: 'dotFieldReference' });
            case 'dot-field-in-dash':
                return formatMessage({ id: 'dotFieldInDashReference' });
            case 'dash-field':
                return formatMessage({ id: 'dashFieldReference' });
            case 'tag':
            default:
                return formatMessage({ id: 'tagFieldReference' });
        }
    }
    const getEntityType = (referenceType) => {
        switch (referenceType) {
            case 'dot-field':
                return formatMessage({ id: 'common.modelType.cargoCategory' });
            case 'dot-field-in-dash':
                return formatMessage({ id: 'common.modelType.eventTemplate' });
            case 'dash-field':
                return formatMessage({ id: 'common.modelType.eventTemplate' });
            case 'tag':
            default:
                return formatMessage({ id: 'index' });
        }
    }
    
    return (
        <>
            <IconSelectorModal 
                isOpen={primaryIconSelectorOpen} 
                setOpen={setPrimaryIconSelectorOpen} 
                onConfirm={val => setPrimaryIcon(val)} 
                currentSelectedIcons={[primaryIcon]} 
                isSingle={true} 
            />
            <IconSelectorModal 
                key={`icon-selector-${targetRow}-${targetColumn}`}
                isOpen={cellIconSelectorOpen} 
                setOpen={setCellIconSelectorOpen} 
                onConfirm={val => setCellIcons(targetRow, targetColumn, val)} 
                currentSelectedIcons={
                    (
                        !!libraryTable && !!libraryTable.rows 
                        && !!libraryTable.rows[targetRow] 
                        && !!libraryTable.rows[targetRow].columns
                        && !!libraryTable.rows[targetRow].columns[targetColumn]
                        && !!libraryTable.rows[targetRow].columns[targetColumn].icons
                    ) 
                    ?
                    libraryTable.rows[targetRow].columns[targetColumn].icons
                    :
                    []
                } 
                isSingle={false} 
            />
            <TextSelectorModal
                key={`text-selector-${targetRow}-${targetColumn}`}
                isOpen={cellTextSelectorOpen} 
                setOpen={setCellTextSelectorOpen} 
                libraryTemplates={libraryTemplates}
                currentText={
                    (
                        !!libraryTable && !!libraryTable.rows 
                        && !!libraryTable.rows[targetRow] 
                        && !!libraryTable.rows[targetRow].columns
                        && !!libraryTable.rows[targetRow].columns[targetColumn]
                        && !!libraryTable.rows[targetRow].columns[targetColumn].text
                    ) 
                    ?
                    libraryTable.rows[targetRow].columns[targetColumn].text
                    :
                    ''
                }
                onConfirm={(text) => {
                    const rows = libraryTable.rows.splice(0);
                    const modifiedRow = rows[targetRow];
                    let modifiedCol = modifiedRow.columns[targetColumn];
                    modifiedCol = { ...modifiedCol, ...text };
                    modifiedRow.columns[targetColumn] = modifiedCol;
                    rows[targetRow] = modifiedRow;
                    setLibraryTable({ rows });
                }}
            />
            {
                !!modalEntity
                &&
                <EntityModal
                    open={entityModalOpen}
                    setOpen={(open) => setEntityModalOpen(open) }
                    entityType={entityModalType}
                    entity={modalEntity}
                    allEntities={libraryTemplates}
                />
            }
            <LibraryTemplateSelector 
                isOpen={templateSelectorOpen} 
                setOpen={setTemplateSelectorOpen} 
                onConfirm={val => {
                    setLibraryTemplates(val);
                    let tagIds = [];
                    val.selectedDashes.forEach(dashTemplate => {
                        const templateTagIds = dashTemplate.model_field_list
                            .filter(field => !!field.index_field_id)
                            .map(field => field.index_field_id);
                        tagIds = tagIds.concat(templateTagIds);
                    });
                    val.selectedDots.forEach(dotTemplate => {
                        const templateTagIds = dotTemplate.model_field_list
                            .filter(field => !!field.index_field_id)
                            .map(field => field.index_field_id);
                        tagIds = tagIds.concat(templateTagIds);
                    });
                    setLibraryTags(tagIds);
                }} 
                currentSelectedDashes={libraryTemplates.selectedDashes}
                currentSelectedDots={libraryTemplates.selectedDots}
                currentSelectedDocuments={libraryTemplates.selectedDocuments}
            />
            <ConfirmModal
                id='confirm-delete-row-col'
                open={confirmDeleteModalOpen}
                setOpen={(o) => setConfirmDeleteModalOpen(o)}
                onConfirm={() => {
                    deleteMode === 'row' ? deleteRow(targetRow) : deleteColumn(targetColumn);
                }}
                title={<FormattedMessage id='common.confirmVerb' values={{ verb: 'Delete'}} />}
                body={deleteMode === 'row' ? <FormattedHTMLMessage tagName='div' id='deleteLibraryRow' values={{ value: targetRow + 1 }}/> 
                                           : <FormattedHTMLMessage tagName='div' id='deleteLibraryColumn' values={{ value: targetColumn + 1 }}/> }
            />
            <ConfirmModal
                id='confirm-delete-row-col'
                open={confirmDeleteLibraryOpen}
                setOpen={(o) => setconfirmDeleteLibraryOpen(o)}
                onConfirm={() => {
                    deleteEntityGroup({ 
                        id: selectedLibrary.id,
                    }, result => {
                        selectedLibrary.status_id= 4;
                        history.push('/library');
                    });
                }}
                title={<FormattedMessage id='common.confirmVerb' values={{ verb: 'Delete'}} />}
                body={<FormattedHTMLMessage  tagName='div' id='deleteLibrary' values={{ name: selectedLibrary ? selectedLibrary.label :'' }} />}
            />
            <Page
                {...{ ...LibraryCreateDashboard.meta, title: !!selectedLibrary ? <FormattedMessage id='editLibrary' /> : 'Create Library' }}
                parents={parents}
            >
                <div className="row pd-b-5">
                    {
                        libraryIsActive && <h2 style={{color: 'red'}}> <FormattedMessage id='libraryWarning' /> </h2>
                    }
                </div>
                <div className="row pd-b-5 pd-t-15">
                    <div className='col-12'>
                        <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                            <FormattedMessage id='libraryLocale' />
                        </h6>
                    </div>
                </div>
                <div className="row pd-b-15">
                    <SelectControl
                        name='library-status'
                        options={validLibraryLocales}
                        submitOnChange={true}
                        onChange={(valueObj) => {
                            setLibraryLocale(valueObj);
                        }}
                        value={libraryLocale}
                        isClearable={false}
                        showLabel={true}
                        width={4}
                    />
                </div>
                <div className="row pd-b-5">
                    <div className='col-12'>
                        <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                            <FormattedMessage id='common.title' />
                        </h6>
                    </div>
                </div>
                <div className="row pd-b-15">
                    <div className='col-xs-3 col-lg-4 col-xl-5'>
                        { libraryIsActive ? 
                            <p>{libraryTitle}</p>
                            :
                            <EdiText
                                type='text'
                                value={libraryTitle}
                                onSave={val => setLibraryTitle(val)}
                                showButtonsOnHover={false}
                            />
                        }
                        
                    </div>
                </div>
                <div className="row pd-b-5">
                    <div className='col-12'>
                        <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                            <FormattedMessage id='model.properties.description' />
                        </h6>
                    </div>
                </div>
                <div className="row pd-b-15">
                    <div className='col-xs-3 col-lg-4 col-xl-5'>
                        { libraryIsActive ? 
                            <p>{libraryDescription}</p>
                            :
                            <EdiText
                                type='text'
                                value={libraryDescription}
                                onSave={val => setLibraryDescription(val)}
                                showButtonsOnHover={false}
                                canEdit={!libraryIsActive}
                            />
                        }
                        
                    </div>
                </div>
                <div className="row pd-b-5">
                    <div className='col-12'>
                        <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                            <FormattedMessage id='detailedDescription' />
                        </h6>
                    </div>
                </div>
                <div className="row pd-b-15">
                    <div className='col-xs-3 col-lg-4 col-xl-5'>
                        { libraryIsActive ? 
                            <p>{libraryDetailedDescription}</p>
                            :
                            <EdiText
                                type='text'
                                value={libraryDetailedDescription}
                                onSave={val => setLibraryDetailedDescription(val)}
                                showButtonsOnHover={false}
                                canEdit={!libraryIsActive}
                            />
                        }
                        
                    </div>
                </div>
                <div className="row pd-b-5">
                    <div className='col-12'>
                        <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                            <FormattedMessage id='libraryIcon' />
                        </h6>
                    </div>
                </div>
                <div className="row pd-b-15">
                    <div className='col-12'>
                        { (primaryIcon.length === 0) ? 
                            <p><FormattedMessage id='noIconSelected' /></p>
                            :
                            <i className={primaryIcon} style={{fontSize: 48}} />
                        }
                        <div 
                            className={libraryIsActive ? 'disabled' : ''} 
                            style={libraryIsActive ? { pointerEvents: 'none' } : {}}
                        >
                            <FontIconPicker
                                icons={icons}
                                value={primaryIcon}
                                // onChange={(val) => console.log(val)}
                                onChange={v => setPrimaryIcon(v)}
                                isMulti={false}
                                iconsPerPage={40}
                                disabled={!libraryIsActive}
                            />
                        </div>
                    </div>
                </div>
                <div className="row pd-b-5">
                    <div className='col-12'>
                        <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                            <FormattedMessage id='libraryTemplates' />
                        </h6>
                    </div>
                </div>
                <div className="row pd-b-15">
                    <div className='col-12'>
                        <div>
                            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                                <span className='tx-bold'><FormattedMessage id='objectTemplates'/>:&nbsp;</span> 
                                { libraryTemplates.selectedDots.length > 0 ? 
                                    libraryTemplates.selectedDots.map(tmp => (<div style={{display: 'flex', flexDirection: 'row'}}>
                                        {tmp.title}
                                        &nbsp;
                                        <div 
                                            className={libraryIsActive ? 'disabled' : ''} 
                                            style={libraryIsActive ? { pointerEvents: 'none' } : {}}
                                        >
                                            <FontIconPicker
                                                icons={icons}
                                                value={templateIcons.find(ti => ti.id === tmp.id)?.icon}
                                                onChange={(v) => {
                                                    const otherTemplateIcons = templateIcons.filter(ti => ti.id !== tmp.id);
                                                    setTemplateIcons(otherTemplateIcons.concat([{ id: tmp.id, icon: v}]));
                                                }}
                                                isMulti={false}
                                                iconsPerPage={40}
                                                disabled={!libraryIsActive}
                                            />
                                        </div>
                                        &nbsp;
                                        &nbsp;
                                    </div>))
                                    : 
                                    <FormattedMessage id='noTemplatesSelected'/> 
                                }
                            </div>
                            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                                <span className='tx-bold'><FormattedMessage id='eventTemplates'/>:&nbsp;</span> 
                                { libraryTemplates.selectedDashes.length > 0 ? 
                                    libraryTemplates.selectedDashes.map(tmp => (<div style={{display: 'flex', flexDirection: 'row'}}>
                                        {tmp.title}
                                        &nbsp;
                                        <div 
                                            className={libraryIsActive ? 'disabled' : ''} 
                                            style={libraryIsActive ? { pointerEvents: 'none' } : {}}
                                        >
                                            <FontIconPicker
                                                icons={icons}
                                                value={templateIcons.find(ti => ti.id === tmp.id)?.icon}
                                                onChange={(v) => {
                                                    const otherTemplateIcons = templateIcons.filter(ti => ti.id !== tmp.id);
                                                    setTemplateIcons(otherTemplateIcons.concat([{ id: tmp.id, icon: v}]));
                                                }}
                                                isMulti={false}
                                                iconsPerPage={40}
                                                disabled={!libraryIsActive}
                                            />
                                        </div>
                                        &nbsp;
                                        &nbsp;
                                    </div>))
                                    : 
                                    <FormattedMessage id='noTemplatesSelected'/> 
                                }
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <span className='tx-bold'><FormattedMessage id='page.models.invoices.templates'/>:&nbsp;</span> 
                                { libraryTemplates.selectedDocuments.length > 0 ? 
                                    libraryTemplates.selectedDocuments.map(tmp => (
                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                            {tmp.title}
                                            &nbsp;
                                            &nbsp;
                                            &nbsp;
                                            <EdiText
                                                type='text'
                                                value={templateDescriptions.find(td => td.id === tmp.id)?.description || 'Description'}
                                                onSave={val => {
                                                    const otherTemplateDescriptions = templateDescriptions.filter(td => td.id !== tmp.id);
                                                    setTemplateDescriptions(otherTemplateDescriptions.concat([{ id: tmp.id, description: val }]));
                                                }}
                                                showButtonsOnHover={false}
                                                canEdit={!libraryIsActive}
                                            />
                                        </div>
                                    ))
                                    : 
                                    <FormattedMessage id='noTemplatesSelected'/> 
                                }
                            </div>
                        </div>
                        <button 
                            className={'btn btn-outline-info pd-x-20' + (libraryIsActive ? ' disabled' : '')}
                            onClick={() => !libraryIsActive && setTemplateSelectorOpen(true)}
                            disabled={libraryIsActive}
                        >
                                <FormattedMessage id='selectTemplates'/>
                        </button>
                    </div>
                </div>
                <div className="row pd-b-5">
                    <div className='col-12'>
                        <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                            <FormattedMessage id='graphAndCarousel' />
                        </h6>
                    </div>
                </div>
                <div className="row pd-b-15">
                    <div className='col-12' style={{ overflowX: 'scroll' }}>
                        <button className={'btn btn-outline-info pd-x-20 mg-b-25' + (libraryIsActive ? ' disabled' : '')} onClick={libraryIsActive ? null : addRow}><FormattedMessage id='addRow'/></button>
                        <button className={'btn btn-outline-info pd-x-20 mg-b-25 mg-x-5' + (libraryIsActive ? ' disabled' : '')} onClick={libraryIsActive ? null : addColumn}><FormattedMessage id='addColumn'/></button>
                        <table className='library-table-builder'>
                            <tbody>
                                {
                                    libraryTable.rows.map((row, rowIndex) => {
                                        return (
                                            <tr>
                                                {
                                                    <div 
                                                        style={rowDeleteButtonStyle}
                                                        onClick={() => {
                                                            if (libraryIsActive) return;
                                                            if (libraryTable.rows.length > 1) {
                                                                setTargetRow(rowIndex);
                                                                setDeleteMode('row');
                                                                setConfirmDeleteModalOpen(true);
                                                            }
                                                        }}
                                                    >
                                                        ✕
                                                    </div>
                                                }
                                                {
                                                    row.columns.map((col, colIndex) => {
                                                        const editorButtons = <>
                                                            <button 
                                                                className={'btn btn-outline-info pd-x-20' + (libraryIsActive ? ' disabled' : '')}
                                                                onClick={() => {
                                                                    if (libraryIsActive) return;
                                                                    setTargetRow(rowIndex);
                                                                    setTargetColumn(colIndex);
                                                                    setCellIconSelectorOpen(true);
                                                                }}
                                                            >
                                                                <FormattedMessage id='selectIcons'/>
                                                            </button>
                                                            <button 
                                                                className={'btn btn-outline-info pd-x-20'  + (libraryIsActive ? ' disabled' : '')}
                                                                onClick={() => {
                                                                    if (libraryIsActive) return;
                                                                    setTargetRow(rowIndex);
                                                                    setTargetColumn(colIndex);
                                                                    setCellTextSelectorOpen(true);
                                                                }}
                                                            >
                                                                <FormattedMessage id='setText'/>
                                                            </button>
                                                            <br/>
                                                            <br/>
                                                            <label>
                                                                Include on back of card: &nbsp;
                                                                <input 
                                                                    type="checkbox" 
                                                                    id='select-user-chkbox'
                                                                    checked={isBackContent(rowIndex, colIndex)}  
                                                                    onChange={() => toggleBackContent(rowIndex, colIndex)}
                                                                    disabled={libraryIsActive || rowIndex === 0}
                                                                />
                                                            </label>
                                                        </>
                                                        if (rowIndex === 0) {
                                                            return (
                                                                <th>
                                                                    { editorButtons }
                                                                    {
                                                                        <div 
                                                                            style={colDeleteButtonStyle}
                                                                            onClick={() => {
                                                                                if (libraryIsActive) return;
                                                                                if (libraryTable.rows[0].columns.length > 1) {
                                                                                    setTargetColumn(colIndex);
                                                                                    setDeleteMode('col');
                                                                                    setConfirmDeleteModalOpen(true);
                                                                                }
                                                                            }}
                                                                        >
                                                                            ✕
                                                                        </div>
                                                                    }
                                                                </th>
                                                            );
                                                        } else {
                                                            return <td>{editorButtons}</td>
                                                        }
                                                    })
                                                }
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row pd-b-5">
                    <div className='col-12'>
                        <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                            <FormattedMessage id='common.preview' />
                        </h6>
                    </div>
                </div>
                <div className="pd-b-25">
                    { 
                        isTableView ?
                        <div className='collection-root'>
                            <div className="row pd-b-5">
                                <div className="library-icon-btn" onClick={() => setIsTableView(!isTableView)}>
                                    <img src={isTableView ? carouselIcon : tableIcon} />
                                </div>
                            </div>
                            <div className='collection-chart'>
                            {
                                libraryTable.rows.map(row => renderRow(row)) 
                            }
                            </div>
                        </div>
                        :
                        <div style={{width: '100%'}} >
                            <div className="row pd-b-5">
                                <div className="library-icon-btn" onClick={() => setIsTableView(!isTableView)}>
                                    <img src={isTableView ? carouselIcon : tableIcon} />
                                </div>
                            </div>
                            <LibraryCarousel 
                                key={JSON.stringify(libraryTable)}
                                collection={{ chart: { rows: libraryTable.rows }}} 
                                libraryTemplates={libraryTemplates}
                            />
                        </div>
                    }
                </div>
                <div className="row pd-b-5 pd-t-15">
                    <div className='col-12'>
                        <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">                            
                            <FormattedMessage id='libraryStatus' />
                        </h6>
                    </div>
                </div>
                <div className="row pd-b-15">
                    <SelectControl
                        name='library-status'
                        options={validLibraryStatuses}
                        submitOnChange={true}
                        onChange={(valueObj) => {
                            setLibraryStatus(valueObj);
                        }}
                        disabled={libraryHasIssues}
                        value={libraryStatus}
                        isClearable={false}
                        showLabel={true}
                        width={4}
                    />
                </div>
                <div className="row pd-b-5">
                    <div className='col-12'>
                        {
                            libraryHasIssues && (
                                <div className="library-issues-list">
                                    <h2><FormattedMessage id="libraryIssues" /></h2>
                                    <ul>
                                        {dashesWithMissingDots.map(d => (
                                            <li key={d}>
                                                <FormattedHTMLMessage id='dashWithMissingDotIssue' tagName="p" values={{ title: d.dash_title, id: d.dash_id, dotTitle: d.dot_title, dotId: d.dot_id}} />
                                            </li>
                                        ))}
                                        {documentsWithMissingReferences.map(d => (
                                            <li key={d}>
                                                <FormattedHTMLMessage id='documentWithMissingReferenceIssue' tagName="p" values={{ title: d.document_title, id: d.document_id, referenceType: getReferenceType(d.reference_type), entityType: getEntityType(d.reference_type), entityTitle: d.reference_title, entityId: d.reference_id }} />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className="row pd-b-5">
                    <div className='col-12'>
                        <div 
                            className='btn btn-outline-info'
                            onClick={() => {
                                libraryTable.templateIcons = templateIcons;
                                libraryTable.templateDescriptions = templateDescriptions;
                                if (!!selectedLibrary) {
                                    if ( libraryStatus.value === 4 ){
                                	    setconfirmDeleteLibraryOpen(true);
                                        return;
                                    }
                                    updateEntityGroup({
                                        id: selectedLibrary.id,
                                        label: libraryTitle,
                                        description: libraryDescription,
                                        detailed_description: libraryDetailedDescription,
                                        icon: primaryIcon,
                                        metadata: JSON.stringify(libraryTable),
                                        entities: [
                                            ...libraryTemplates.selectedDashes.map(d => ({ entity_type: 'dash-template', entity_id: d.id })),
                                            ...libraryTemplates.selectedDots.map(d => ({ entity_type: 'dot-template', entity_id: d.id })),
                                            ...libraryTemplates.selectedDocuments.map(d => ({ entity_type: 'document-template', entity_id: d.id })),
                                            ...libraryTags.map(t => ({ entity_type: 'tag', entity_id: t })),
                                        ],
                                        status: libraryStatus.value,
                                        locale: libraryLocale.value,
                                    }, result => {                                        
                                        Notifications.success(formatMessage({ id: 'libraryUpdateSuccess' }));                                        
                                        setSelectedLibrary(result);
                                    })
                                } else {
                                    createEntityGroup({
                                        label: libraryTitle,
                                        description: libraryDescription,
                                        detailed_description: libraryDetailedDescription,
                                        icon: primaryIcon,
                                        metadata: JSON.stringify(libraryTable),
                                        entities: [
                                            ...libraryTemplates.selectedDashes.map(d => ({ entity_type: 'dash-template', entity_id: d.id })),
                                            ...libraryTemplates.selectedDots.map(d => ({ entity_type: 'dot-template', entity_id: d.id })),
                                            ...libraryTemplates.selectedDocuments.map(d => ({ entity_type: 'document-template', entity_id: d.id })),
                                            ...libraryTags.map(t => ({ entity_type: 'tag', entity_id: t })),
                                        ],
                                        status: libraryStatus.value,
                                        locale: libraryLocale.value,
                                        is_public: true,
                                    }, result => {
                                        Notifications.success(formatMessage({ id: 'librarySaveSuccess' }));
                                        setSelectedLibrary(result, () => history.push(`/library/edit/${result.id}`));
                                    })
                                }
                            }}
                        >
                            <span style={{ margin: '50px'}}><FormattedMessage id='common.save'/></span>
                        </div>
                    </div>
                </div>
            </Page>
        </>
    );
}

LibraryCreateDashboard.meta = {
    showTitleBlock: true,
    title: <FormattedMessage id="page.models.new.title" />,
    subtitle: <br/>,
    routes: ['/library/create', '/library/edit/:id'],
    icon: 'ion-clipboard',
    menus: { main: false, user: false },
    order: 0,
    requires: ['model', 'read', 'update']
};

const mapStateToProps = state => ({
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            createEntityGroup,
            setSelectedLibrary,
            updateEntityGroup,
            getMedia,
            getEntityGroupIssues,
            deleteEntityGroup,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(LibraryCreateDashboard))
