import React from 'react';
import { FormattedMessage } from 'react-intl';
import { call, hasErrors } from '../configuration/api';
import Queries from './queries';
import { Notifications } from '../components/notifications';
import {
    FIELDS_GET_TYPES,
    FIELDS_CONFIGURATION_SET_FIELDS,
    FIELDS_CONFIGURATION_UPDATE_FIELD,
    FIELDS_CONFIGURATION_BATCH_UPDATE_FIELDS,
    FIELDS_GET_MODEL_PROPERTIES,
    FIELDS_IMPORT,
    FIELDS_UPDATE_MODEL,
    FIELDS_SET_MODEL_VISIBILITY,
    MODEL_PERSISTING,
    FIELDS_CLEANUP
} from './types';
import Field from '../models/field';
import { compareFieldArrays } from '../utilities/common';

const defaultState = {
    loading: 0,
    fieldsChanged: false,
    modelChanged: false,
    model: undefined,
    modelVisible: false,
    types: undefined,
    items: undefined
};

/**
 * Loading state mapper.
 * @param {object} state
 * @param {string} action
 */
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case MODEL_PERSISTING:
            return { ...state, loading: state.loading + (action.data ? 1 : -1) };

        case FIELDS_GET_TYPES:
            const fieldTypes = (action.payload || []).filter(t => t.type_name !== 'Button');
            return { ...state, types: fieldTypes };

        case FIELDS_CONFIGURATION_SET_FIELDS:
            return { ...state, items: action.fields, fieldsChanged: !action.persisted };

        case FIELDS_CONFIGURATION_BATCH_UPDATE_FIELDS:
            if (action.persisted) {
                if (compareFieldArrays(state.items, action.fields)) {
                    return { ...state, fieldsChanged: false };
                }
                return state;
            }
            return { ...state, items: action.fields, fieldsChanged: true };

        case FIELDS_CONFIGURATION_UPDATE_FIELD:
            if (action.added) {
                return {
                    ...state,
                    items: state.items.map(item => (item.id === action.field.id ? { ...item, id: action.field.id } : item))
                };
            }
            return {
                ...state,
                items: state.items.map(item => (item.id === action.field.id ? action.field : item)),
                fieldsChanged: true
            };

        case FIELDS_GET_MODEL_PROPERTIES:
            const modelProperties = { ...action.data, model_field_list: undefined };
            const modelFields = action.data.model_field_list ? action.data.model_field_list.map(f => Field.fromDB(f)) : undefined;
            return { ...state, model: modelProperties, items: modelFields };

        case FIELDS_IMPORT:
            return { ...state };

        case FIELDS_SET_MODEL_VISIBILITY:
            return { ...state, modelVisible: action.data };

        case FIELDS_UPDATE_MODEL:
            if (action.persisted) {
                if (JSON.stringify(state.model) === JSON.stringify(action.model)) {
                    return { ...state, modelChanged: !action.persisted };
                }
                return state;
            }

            return { ...state, model: action.model, modelChanged: true };

        case FIELDS_CLEANUP:
            return { ...defaultState, types: state.types };

        default:
            return state;
    }
}

export const cleanUpFields = () => async dispatch => {
    dispatch({ type: FIELDS_CLEANUP });
};

export const getFieldTypes = (onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Fields.Types });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.model_field_type_list) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    dispatch({ type: FIELDS_GET_TYPES, payload: data.model_field_type_list });
    onComplete();
};

export const setFields = (fields, onComplete = () => null, persisted = false) => async dispatch => {
    dispatch({ type: FIELDS_CONFIGURATION_SET_FIELDS, fields: fields || [], persisted });
    onComplete();
};

export const updateField = (field, onComplete = () => null) => async dispatch => {
    dispatch({ type: FIELDS_CONFIGURATION_UPDATE_FIELD, field, persisted: false });
    onComplete(field);
};

export const getFields = (modelId, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Fields.List, variables: { model_id: modelId } });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.model_field_list) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete();
};

export const getField = (fieldId, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Fields.Get, variables: { field_id: fieldId } });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.model_field) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.model_field);
};

export const createField = (variables, onComplete = () => null) => async dispatch => {
    if (!variables.is_static_unit) {
        variables.is_static_unit = false;
    }
    const response = await call({ query: Queries.Fields.Create, variables });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.model_field_create) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.model_field_create);
};

export const importFields = data => async dispatch => {
    dispatch({ type: FIELDS_IMPORT, data });
};

export const setModelPropertiesVisibility = visibility => async dispatch => {
    dispatch({ type: FIELDS_SET_MODEL_VISIBILITY, data: visibility });
};

export const updateModel = (model, onComplete = () => null) => async dispatch => {
    dispatch({ type: FIELDS_UPDATE_MODEL, model, persisted: false });
    onComplete(model);
};

export const persistSingleFieldUpdate = (modelId, field, onComplete = () => null) => async dispatch => {
    dispatch({ type: MODEL_PERSISTING, data: true });
    if (field.is_static_unit === undefined) {
        field.is_static_unit = false;
    }
    const updatedField = { action: !!field.id ? 'update' : 'add', data: { ...Field.toDB(field) } };
    const response = await call({
        query: Queries.Fields.UpdateAll,
        variables: { model_id: modelId, field_updates: JSON.stringify({ updates: [updatedField] }) }
    });
    dispatch({ type: MODEL_PERSISTING, data: false });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.model_field_batch_update) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    if (updatedField.action === 'add') {
        const newField = Field.fromDB(data.model_field_batch_update[0]);
        dispatch({ type: FIELDS_CONFIGURATION_UPDATE_FIELD, field: newField, added: true });
    }
    onComplete();
};

export const persistBatchFieldUpdates = (modelId, fields, onComplete = () => null) => async dispatch => {
    dispatch({ type: MODEL_PERSISTING, data: true });
    const updates = fields.map(f => ({ action: 'update', data: { ...Field.toDB(f) } }));
    const response = await call({ query: Queries.Fields.UpdateAll, variables: { model_id: modelId, field_updates: JSON.stringify({ updates }) } });
    dispatch({ type: MODEL_PERSISTING, data: false });

    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.model_field_batch_update) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    dispatch({ type: FIELDS_CONFIGURATION_BATCH_UPDATE_FIELDS, fields: data.model_field_batch_update.map(f => Field.fromDB(f)), persisted: true });
    onComplete();
};

export const persistDeleteField = (fieldId, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Fields.Delete, variables: { field_id: fieldId } });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.model_field_delete) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete();
};

export const createFieldValue = (field, value, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.FieldValues.Create, variables: { field_id: field.id, ...value } });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.model_field_value_create) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.model_field_value_create);
};

export const deleteFieldValue = (id, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.FieldValues.Delete, variables: { value_id: id } });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.model_field_value_delete) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.model_field_value_create);
};

export const setFieldMapping = (input, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Fields.SetMapping, variables: { ...input } });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.model_field_set_mapping) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.model_field_set_mapping);
};

export const clearFieldMapping = (input, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Fields.ClearMapping, variables: { ...input } });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.model_field_clear_mapping) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.model_field_clear_mapping);
};

export const listMappings = (input, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Fields.ListMappings, variables: { ...input } });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.model_field_list_mappings) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.model_field_list_mappings);
};
