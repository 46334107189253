import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import Settings from 'pages/settings';
import EventTemplates from 'pages/event-templates';
import CargoCategories from 'pages/cargo-categories';
import Lots from 'pages/lots';
import Transactions from 'pages/events';
import Documents from 'pages/documentTemplates';
import { history } from 'configuration/history';
import FaqSearch from '../components/faqSearch';
import { listEntityGroups, setSelectedLibrary, cloneEntityGroup } from 'reducers/entityGroup';
import { IconPickerItem } from 'react-fa-icon-picker'
import { SelectField } from 'pages/models/fields/definitions';
import '@fortawesome/fontawesome-free/css/all.css';
import LoadingOverlay from 'react-loading-overlay';

const CollectionBox = (props) => {
    const { library, showAdminControls = false, setSelectedLibrary, cloneEntityGroup, } = props;
    const {label, description, id, icon, disabled = false, status_id } = library;
    let statusLabel = ' (Draft)';
    if (status_id === 2) {
        statusLabel = ' (ACTIVE)';
    } else if (status_id === 3) {
        statusLabel = ' (Deleted)';
    }
    return (
        <div className={"library-box" + (disabled ? ' disabled' : '')}>
            { 
                showAdminControls
                &&
                <>
                    <button 
                        className="library-box-edit-btn btn btn-outline-info"
                        onClick={() => setSelectedLibrary(null, () => history.push(`/library/edit/${id}`))}
                    >
                        Edit Library
                    </button>
                    <button 
                        className="library-box-edit-btn btn btn-outline-info"
                        onClick={() => {
                            cloneEntityGroup({ id: library.id }, result => {
                                setSelectedLibrary(result, () => history.push(`/library/edit/${result.id}`))
                            })
                        }}
                    >
                        Clone Library
                    </button>
                </>
            }
            <div className="library-box-inner" onClick={() => setSelectedLibrary(null, () => history.push('/library/' + id + window.location.search))}>
                <div className="library-box-title">
                    <h3>{label + (showAdminControls ? statusLabel : '')}</h3>
                </div>
                <div className="library-box-icon-container">
                    <span className={`library-box-icon ${disabled ? 'disabled' : ''}`}>
                        {
                            !!icon
                            ?
                            <i className={icon} style={{fontSize: 96, color: "#001B3A"}} />
                            :
                            <br />
                        }
                    </span>
                </div>
                <h2 className="library-box-description">{description}</h2>
            </div>
        </div>
    );
};

class LibraryDiscoverDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            libraries: [],
            statusFilter: 'all',
            localeFilter: 'all',
        };
    }

    componentDidMount() {
        const { listEntityGroups, locale } = this.props;

        const listInput = {
            locale
        };

        listEntityGroups(listInput, results => {
            this.setState({ libraries: results, loading: false });
        })
    }

    render() {
        const { user, isCompanyAdmin, company, setSelectedLibrary, cloneEntityGroup } = this.props;

        const { is_library_admin } = company;

        const statusFilters = ['all', 'draft', 'active', 'deleted']
        const localeFilters = ['all', 'en', 'pt']
        const collections = this.state.libraries;
        const statusFilter = this.state.statusFilter;
        const localeFilter = this.state.localeFilter;

        return (
            <div className='libraries-container'>
                <div className='libraries-header'>
                    <div className='faq-search-container'>
                        <h3><FormattedMessage id='librariesHeader1'/></h3>
                        <h3><FormattedMessage id='librariesHeader2'/></h3>
                        <br/>
                        <br/>
                        <h4><FormattedMessage id='librariesSearchLabel'/></h4>
                        <div className='search-wrapper'>
                            <FaqSearch 
                                hidePlaceholder={true}
                                className={'faq-search'}
                            />
                        </div>
                    </div>
                    <div className='faq-open-container'>
                        <div 
                            className='icon-container'
                            onClick={() => history.push('/library/faq' + window.location.search)}
                        >
                            <i className='icon ion-ios-book faq-open-icon'/>
                            <h4><FormattedMessage id='browseTopics'/></h4>
                        </div>
                    </div>
                </div>
                <div className='libraries-body'>
                    { 
                        is_library_admin
                        && 
                        <>
                            <button 
                                className='btn btn-outline-info mg-b-15' 
                                onClick={() => history.push('/library/create')}
                            >
                                <FormattedMessage id='createLibrary' />
                            </button>
                            <SelectField
                                name='statusFilter'
                                fieldValues={statusFilters.map(f => ({ value: f, label: f }))}
                                value={statusFilter}
                                title={<FormattedMessage id='statusFilter' />}
                                multi={false}
                                breakrow={false}
                                width={2}
                                error={false}
                                touched={false}
                                onBlur={() => null}
                                onChange={(_, value) => {
                                    this.setState({ statusFilter: value });
                                }}
                            />
                            <SelectField
                                name='localeFilter'
                                fieldValues={localeFilters.map(f => ({ value: f, label: f }))}
                                value={localeFilter}
                                title={<FormattedMessage id='localeFilter' />}
                                multi={false}
                                breakrow={false}
                                width={2}
                                error={false}
                                touched={false}
                                onBlur={() => null}
                                onChange={(_, value) => {
                                    this.setState({ localeFilter: value });
                                }}
                            />
                        </>
                    }
                    <h4><FormattedMessage id='exampleTemplates'/></h4>
                    <div className='subtitle'>
                        <FormattedMessage id='librariesBodySubtitle'/>
                    </div>
                    <LoadingOverlay
                        active={this.state.loading}
                        spinner
                        text={<FormattedMessage id='common.loading'/>}
                    >
                        <div className='links-container'>
                            {
                                collections
                                .filter(c => 
                                    is_library_admin || c.status_id === 2
                                )
                                .filter(c => 
                                    statusFilter === 'all'
                                    || (statusFilter === 'draft' && c.status_id === 1)
                                    || (statusFilter === 'active' && c.status_id === 2)
                                    || (statusFilter === 'deleted' && c.status_id === 3)
                                )
                                .filter(c => 
                                    localeFilter === 'all'
                                    || (localeFilter === c.locale)
                                )
                                .map(c => 
                                    <CollectionBox 
                                        key={c.id}
                                        library={c}
                                        setSelectedLibrary={setSelectedLibrary}
                                        showAdminControls={is_library_admin}
                                        cloneEntityGroup={cloneEntityGroup}
                                    />
                                )
                            }
                            {
                                collections.length < 3 && (
                                    <div className={'library-box disabled'}>
                                        <div className="library-box-inner">
                                            <div className="library-box-title">
                                                <h3><FormattedMessage id='comingSoon' /></h3>
                                            </div>
                                            <div className="library-box-icon-container">
                                                <span className={`library-box-icon disabled`}>
                                                    {
                                                        <i className={'far fa-clipboard'} style={{fontSize: 96, color: "#001B3A"}} />
                                                    }
                                                </span>
                                            </div>
                                            <h2 className="library-box-description"><FormattedMessage id='comingSoonDescription' /></h2>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </LoadingOverlay>
                </div>
            </div>
        );
    }
}



const mapStateToProps = state => ({
    locale: state.localization.locale
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            listEntityGroups,
            setSelectedLibrary,
            cloneEntityGroup,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LibraryDiscoverDashboard);
