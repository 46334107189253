import React from 'react';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import DotTemplatesTable from 'pages/cargo-categories/components/table';
import LoadingOverlay from 'react-loading-overlay';
import { createTransaction } from 'reducers/transaction';
import { history } from 'configuration/history';

class SelectDotTemplateModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalCloseRequested: false,
        }
    }

    overrideMenuEl = () => {
        const { selectedDotTemplates, toggleDotTemplateSelected } = this.props;
        return (d) => (
            <div className="actions-wrapper">
                <input 
                    type="checkbox" 
                    checked={!!selectedDotTemplates.find(t => t.id === d.id)}   
                    onChange={e => {
                        e.stopPropagation();
                        toggleDotTemplateSelected(d);
                    }}
                />
            </div>
        )
    }

    render() {
        const { modalOpen = false, setModalOpen = () => null, confirmSelectedTemplates, toggleDotTemplateSelected } = this.props;

        return (
            <ReactModal
                isOpen={modalOpen}
                onRequestClose={() => setModalOpen(false)}
                className="modal-block dialog"
                overlayClassName="modal-overlay gray"
                style={{content: {width: '70%', maxHeight: '70%', overflowY: 'auto', overflowX: 'hidden'}}}
            >
                <div className="modal-dialog-centered" role="document">
                    <div className="modal-content bd-0">
                        <div className="modal-header pd-x-20">
                            <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                                <FormattedMessage id="common.chooseCargoCategory" />
                            </h6>
                            <div>
                                <button
                                    type="button"
                                    className="btn btn-outline-info font-weight-bold ml-2"
                                    onClick={() => setModalOpen(false)}
                                >
                                    {'X'}
                                </button>
                            </div>
                        </div>
                        <LoadingOverlay
                            active={this.state.isLoading}
                            spinner
                            text={<FormattedMessage id='common.loading'/>}
                        >
                            <div className="modal-body br-section-wrapper">
                                <DotTemplatesTable
                                    overrideMenuEl={this.overrideMenuEl()}
                                    overrideIdOnClick={d => toggleDotTemplateSelected(d)}
                                    overrideTitleOnClick={d => toggleDotTemplateSelected(d)}
                                />
                            </div>
                        </LoadingOverlay>
                        <div className="modal-footer justify-content-center">
                            <button
                                type="button"
                                className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium confirm-template-filters-btn"
                                disabled={this.state.isLoading}
                                onClick={() => confirmSelectedTemplates()}
                            >
                                <FormattedMessage id="common.confirm" />
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium cancel-template-filters-btn"
                                onClick={() => setModalOpen(false)}
                            >
                                <FormattedMessage id="cancel"  />
                            </button>
                        </div>
                    </div>
                </div>
            </ReactModal>
        );
        
    }
}

const mapStateToProps = state => ({
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            createTransaction,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectDotTemplateModal);