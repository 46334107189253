import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import ReactModal from 'react-modal';
import 'pages/models/models.scss';
import { getModels } from 'reducers/models';
import Table from 'components/table';
import moment from 'moment';
import FadeIn from 'react-fade-in';
import ModelFieldPicker from 'pages/models/components/modelFieldPicker';
import { importFields } from 'reducers/fields';
import { loadModel } from 'reducers/models';
import { getIndices } from 'reducers/indices';
import Tooltip from 'rc-tooltip';
import { SelectField } from 'pages/models/fields/definitions';
import { 
    CONDITION_TYPES,
 } from 'reducers/alerts';
import CargoTypeModal from 'pages/lots/components/cargoTypeModal';
import { createTransaction, getTransaction } from 'reducers/transaction';
import LoadingOverlay from 'react-loading-overlay';

class ConditionFieldPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            targetEventTemplateLoading: false,
            dotFieldType: 'Quantity',
        };
    }

    componentDidMount() {
        const { conditionType, condition, getModels, loadModel, getTransaction } = this.props
        if (condition) {
            if (conditionType === CONDITION_TYPES.event) {
                this.setState({ targetEventTemplate: condition.template ? condition.template.id : null, fieldReferenceId: condition.field ? condition.field.id : null, targetEventTemplateLoading: true });
                if (condition.template) {
                    loadModel(condition.template.id, () => {
                        this.setState({ targetEventTemplateLoading: false });
                    });
                } else {
                    this.setState({ targetEventTemplateLoading: false });
                }
            } else if (conditionType === CONDITION_TYPES.index) {
                this.setState({ targetIndexField: condition.index_field ? condition.index_field.id : null });
            } else if (conditionType === CONDITION_TYPES.dot) {
                this.setState({ targetCargoCategory: condition.template ? condition.template.id : null, dotFieldType: condition.dot_field ? condition.dot_field : 'Quantity'});
                if (condition.object_type) {
                    getTransaction(condition.object_type.id, (cargoType) => {
                        this.setState({ cargoType: cargoType, cargoTypeModalLoading: false });
                    });
                }
            }
        }
    }

    setModalVisibility = open => { 
        if (!open) {
            this.setState({
                targetEventTemplate: null,
                targetIndexField: null,
                fieldReferenceId: null,
                fieldType: null
            })
        }
        return this.props.setModalVisibility ? this.props.setModalVisibility(open) : null;
    } 

    setFieldType = (fieldType) => this.setState({ 
        fieldType: fieldType,
        fieldLabel: null
    });

    setTargetEventTemplate = (e) => {
        const { loadModel } = this.props;
        this.setState({targetEventTemplate: e.currentTarget.value, targetEventTemplateLoading: true}, () => {
            loadModel(this.state.targetEventTemplate, () => {
                this.setState({ targetEventTemplateLoading: false });
            });
        });
    }

    getEventTemplateColumns = () => {
        return [
            {
                isDefault: true,
                headerClassName: 'd-sm-block col-2 d-none',
                className: 'd-xs-block col-2 tx-center',
                resizable: false,
                sortable: false,
                width: 34,
                id: 'actions',
                accessor: d => (
                    <div className="actions-wrapper">
                        <div className="form-check">
                            <input
                                type="radio"
                                name="selected-event-template"
                                value={d.id}
                                checked={Number(this.state.targetEventTemplate) === d.id}
                                className="form-check-input"
                                onChange={(e) => {
                                    this.setTargetEventTemplate(e);
                                }}
                            />
                        </div>
                    </div>
                )
            },
            {
                headerClassName: 'd-sm-block col d-none',
                className: 'd-sm-block col d-none tx-center id-column',
                Header: <FormattedMessage id="common.id" />,
                width: 150,
                id: 'id',
                accessor: d => <a href='#' onClick={e => { e.stopPropagation(); e.preventDefault(); this.setTargetEventTemplate({ currentTarget: { value: d.id} }); }}>{d.hash_id}</a>
            },
            {
                headerClassName: 'd-sm-block d-none',
                className: 'd-xs-block',
                Header: <FormattedMessage id="common.title" />,
                id: 'title',
                accessor: d => <a href='#' onClick={e => { e.stopPropagation(); e.preventDefault(); this.setTargetEventTemplate({ currentTarget: { value: d.id} }); }}>{(d.title === null || d.title === '') ? '[Untitled]' : d.title}</a>
            },
            {
                headerClassName: 'd-block col-2',
                className: 'd-block col-2',
                Header: <FormattedMessage id="common.createdBy" />,
                id: 'created_by_name',
                accessor: d => d.created_by_name
            },
            {
                headerClassName: 'd-block col-2',
                className: 'd-block col-2',
                Header: <FormattedMessage id="common.createdOn" />,
                id: 'created_on',
                accessor: d => moment(d.created_on).format('DD-MM-YYYY')
            }
        ];
    };

    eventTemplateSelector = () => {
        const { models, totalModels } = this.props;
        let model = {};
        let showFieldPicker = false;
        if (this.state.targetEventTemplate && models) {
            model = models.find(m => Number(m.id) === Number(this.state.targetEventTemplate));
            showFieldPicker = true;
        }
        return (
            <React.Fragment>
                <FadeIn>
                    <hr/>
                    <div className="col-12 pd-x-20">
                        <FormattedMessage className='pd-x-20' id="common.chooseEventTemplate" />
                    </div>
                    <div id="event-template-picker" className="row pd-15">
                        <div className="col-12">
                            <Table
                                columns={this.getEventTemplateColumns()}
                                data={models}
                                total={totalModels}
                                // queryBuilder={setFilters => <QueryBuilder onFiltersSet={setFilters} />}
                                dataLoader={(options, onComplete) => this.loadEventTemplateData(options, onComplete)}
                            />
                        </div>
                    </div>
                </FadeIn>
                {
                    showFieldPicker && (
                        <FadeIn>
                            <ModelFieldPicker 
                                model={model}
                                modelId={this.state.targetEventTemplate} 
                                modelTypeId={1} 
                                setFieldReferenceId={(id) => { this.setState({ fieldReferenceId: id })}}
                                fieldReferenceId={this.state.fieldReferenceId}
                                isLoading={this.state.targetEventTemplateLoading}
                                cargoCategories={this.props.cargoCategories}
                            />
                            <div className="modal-footer justify-content-center">
                                <button
                                    type="button"
                                    className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                    disabled={!this.state.fieldReferenceId}
                                    onClick={() => {
                                        this.props.onFieldSelect({ type: CONDITION_TYPES.event, id: this.state.fieldReferenceId });
                                        this.setModalVisibility(false)
                                    }}
                                >
                                    <FormattedMessage id="common.selectField" />
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                    onClick={() => this.setModalVisibility(false)}
                                >
                                    <FormattedMessage id="common.cancel"  />
                                </button>
                            </div>
                        </FadeIn>
                    )
                }
            </React.Fragment>
        );
    }

    loadIndexData = (options, onComplete) => {
        const { getIndices } = this.props;
        getIndices({ status_ids: [2], ...options, model_type: null }, onComplete);
    };

    setTargetIndexField = (e) => {
        this.setState({targetIndexField: e.currentTarget.value});
    }
    
    getIndexColumns() {
        return [
            {
                isDefault: true,
                headerClassName: 'd-sm-block col-2 d-none',
                className: 'd-xs-block col-2 tx-center',
                sortable: false,
                resizable: false,
                width: 34,
                id: 'index-selected',
                accessor: d => (
                    <div className="actions-wrapper">
                        <div className="form-check">
                            <input
                                type="radio"
                                name="selected-index-field"
                                value={d.id}
                                checked={Number(this.state.targetIndexField) === Number(d.id)}
                                className="form-check-input"
                                onChange={(e) => {
                                    this.setTargetIndexField(e);
                                }}
                            />
                        </div>
                    </div>
                )
            },
            {
                headerClassName: 'd-sm-block col d-none',
                className: 'd-sm-block col d-none tx-center id-column',
                Header: <FormattedMessage id="common.id" />,
                width: 50,
                id: 'id',
                accessor: d => (
                    <div>
                        { d.id }
                    </div>
                )
            },
            {
                headerClassName: 'd-sm-block d-none',
                className: 'd-xs-block',
                Header: <FormattedMessage id="common.title" />,
                id: 'title',
                accessor:  d => { 
                    return (d.description && d.description.length > 0) ?
                        <div>
                            <Tooltip placement={'top'} trigger={['hover']} overlay={d.description}>
                                <span>
                                    { d.title }
                                </span>
                            </Tooltip>
                        </div>
                        :
                        <div>
                            { d.title }
                        </div>
                }
            },
            {
                headerClassName: 'd-sm-block col-sm-1 d-none',
                className: 'd-sm-block col-sm-1 d-none',
                Header: <FormattedMessage id="common.references" />,
                width: 150,
                id: 'model_type',
                accessor: d => d.model_type === 1 ? <FormattedMessage id="common.eventTemplate" /> : <FormattedMessage id="common.cargoCategory" />
            },
            {
                headerClassName: 'd-sm-block col-sm-1 d-none',
                className: 'd-sm-block col-sm-1 tx-center d-none',
                Header: <FormattedMessage id="common.type" />,
                id: 'type_name',
                accessor: d => d.type_name
            },
            {
                headerClassName: 'd-sm-block d-none col-1',
                className: 'd-xs-block col-1 tx-center',
                Header: <FormattedMessage id="common.isInUse" />,
                sortable: false,
                id: 'is_used',
                accessor: d => (!!d.is_used ? <FormattedMessage id="common.yes" /> : <FormattedMessage id="common.no" />)
            },
            {
                headerClassName: 'd-block col-2',
                className: 'd-block col-2',
                Header: <FormattedMessage id="common.createdOn" />,
                id: 'created_on',
                accessor: d => moment(d.created_on).format('DD-MM-YYYY')
            }
        ];
    }

    setTargetCargoCategory = (e) => {
        this.setState({ targetCargoCategory: e.currentTarget.value, cargoType: null });
    }

    getCargoCategoryColumns() {
        return [
            {
                isDefault: true,
                headerClassName: 'd-sm-block col-2 d-none',
                className: 'd-xs-block col-2 tx-center',
                sortable: false,
                resizable: false,
                width: 34,
                id: 'cargo-selected',
                accessor: d => (
                    <div className="actions-wrapper">
                        <div className="form-check">
                            <input
                                type="radio"
                                name="selected-index-field"
                                value={d.id}
                                checked={Number(this.state.targetCargoCategory) === Number(d.id)}
                                className="form-check-input"
                                onChange={(e) => {
                                    this.setTargetCargoCategory(e);
                                }}
                            />
                        </div>
                    </div>
                )
            },
            {
                headerClassName: 'd-sm-block col d-none',
                className: 'd-sm-block col d-none tx-center id-column',
                Header: <FormattedMessage id="common.id" />,
                width: 150,
                id: 'id',
                accessor: d => <a href='#' onClick={e => { e.stopPropagation(); e.preventDefault(); this.setTargetCargoCategory({ currentTarget: { value: d.id} }); }}>{d.hash_id}</a>
            },
            {
                headerClassName: 'd-sm-block d-none',
                className: 'd-xs-block',
                Header: <FormattedMessage id="common.title" />,
                id: 'title',
                accessor: d => <a href='#' onClick={e => { e.stopPropagation(); e.preventDefault(); this.setTargetCargoCategory({ currentTarget: { value: d.id} }); }}>{(d.title === null || d.title === '') ? '[Untitled]' : d.title}</a>
            },
            {
                headerClassName: 'd-block col-2',
                className: 'd-block col-2',
                Header: <FormattedMessage id="common.createdBy" />,
                id: 'created_by_name',
                accessor: d => d.created_by_name
            },
            {
                headerClassName: 'd-block col-2',
                className: 'd-block col-2',
                Header: <FormattedMessage id="common.createdOn" />,
                id: 'created_on',
                accessor: d => moment(d.created_on).format('DD-MM-YYYY')
            }
        ];
    };

    indexSelector = () => {
        const { indices, totalIndices } = this.props;
        return (
            <React.Fragment>
                <FadeIn>
                    <hr/>
                    <div className="col-12 pd-x-20">
                        <FormattedMessage className='pd-x-20' id="common.chooseIndexField" />
                    </div>
                    <div id="event-template-picker" className="row pd-15">
                        <div className="col-12">
                        <Table
                            columns={this.getIndexColumns()}
                            data={indices}
                            total={totalIndices}
                            dataLoader={(options, onComplete) => this.loadIndexData(options, onComplete)}
                        />
                        </div>
                    </div>
                    <div className="modal-footer justify-content-center">
                        <button
                            type="button"
                            className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                            disabled={!this.state.targetIndexField}
                            onClick={() => {
                                this.props.onFieldSelect({type: CONDITION_TYPES.index, id: this.state.targetIndexField });
                                this.setModalVisibility(false)
                            }}
                        >
                            <FormattedMessage id="common.selectField" />
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                            onClick={() => this.setModalVisibility(false)}
                        >
                            <FormattedMessage id="common.cancel"  />
                        </button>
                    </div>
                </FadeIn>
            </React.Fragment>
        );
    }

    loadCargoCategoryData = (options, onComplete) => {
        const { getModels } = this.props;
        getModels({ status_ids: [2], model_type: 2, ...options }, onComplete);
    };

    lotSelector = () => {
        const { models, totalModels, getTransaction } = this.props;
        return (
            <React.Fragment>
                <FadeIn>
                    <hr/>
                    <div className="col-12 pd-x-20">
                        <FormattedMessage className='pd-x-20' id="common.chooseCargoCategory" />
                    </div>
                    <div id="cargo-category-picker" className="row pd-15">
                        <div className="col-12">
                        <Table
                            columns={this.getCargoCategoryColumns()}
                            data={models}
                            total={totalModels}
                            dataLoader={(options, onComplete) => this.loadCargoCategoryData(options, onComplete)}
                        />
                        </div>
                    </div>
                    <div id="lot-info-selector" className="row col-12">
                        <div className='col-4 offset-4'>
                            <div id="lot-info-wrapper" className="row">
                                <SelectField
                                    name="lot-info-type"
                                    width={12}
                                    required={true}
                                    title={<FormattedMessage id='common.lotField' />}
                                    value={this.state.dotFieldType}
                                    fieldValues={[{value: 'Quantity', label: <FormattedMessage id='quantity' />}, {value: 'DoT Unit', label: <FormattedMessage id='dotUnit' />}]}
                                    onChange={(_, value) => {
                                        this.setState({ dotFieldType: value });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div id="lot-cargo-type" className="row col-12">
                        <div className='col-4 offset-4 pd-y-20'>
                            <LoadingOverlay
                                active={this.state.cargoTypeModalLoading}
                                text={<FormattedMessage id='common.loading'/>}
                            >
                                <div id="lot-info-wrapper" className="row">
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-block tx-11 pd-y-12 pd-x-25 tx-mont tx-medium"
                                        disabled={!this.state.targetCargoCategory}
                                        onClick={() => {
                                            if (this.state.cargoType) {
                                                this.setState({ cargoTypeModalOpen: true })
                                            } else {
                                                this.setState({ cargoTypeModalLoading: true })
                                                this.props.createTransaction(this.state.targetCargoCategory, 'cargo type', (cargoType) => {
                                                    this.props.getTransaction(cargoType.id, (cargoType) => {
                                                        this.setState({ cargoType: cargoType, cargoTypeModalOpen: true, cargoTypeModalLoading: false });
                                                    });
                                                });
                                            }
                                        }}
                                    >
                                        <FormattedMessage id="filterDotsByFieldValuesUpper" />
                                    </button>
                                </div>
                            </LoadingOverlay>
                        </div>
                    </div>
                    <div className="modal-footer justify-content-center">
                        <button
                            type="button"
                            className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                            disabled={!this.state.targetCargoCategory}
                            onClick={() => {
                                this.props.onFieldSelect({type: CONDITION_TYPES.dot, id: this.state.targetCargoCategory, dotTarget: this.state.dotFieldType, dotType: this.state.cargoType ? this.state.cargoType.id : null });
                                this.setModalVisibility(false)
                            }}
                        >
                            <FormattedMessage id="common.selectField" />
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                            onClick={() => this.setModalVisibility(false)}
                        >
                            <FormattedMessage id="common.cancel"  />
                        </button>
                    </div>
                </FadeIn>
                <CargoTypeModal
                    modalOpen={this.state.cargoTypeModalOpen}
                    setModalOpen={(open) => {
                        this.setState({ cargoTypeModalOpen: open })
                        if (!open) {
                            this.setState({ cargoTypeModalLoading: true }, () => {
                                getTransaction(this.state.cargoType.id, updatedCargoType => {
                                    // make sure cargo type wasn't cleared during loading
                                    if (this.state.cargoType) {
                                        this.setState({ cargoType: updatedCargoType, cargoTypeModalLoading: false });
                                    } else {
                                        this.setState({ cargoTypeModalLoading: false });
                                    }
                                });
                            })
                        }
                    }}
                    cargoType={this.state.cargoType}
                />
            </React.Fragment>
        );
    }

    fieldSelector = () => {
        const { conditionType } = this.props
        if (conditionType === CONDITION_TYPES.event) {
            return (
                <React.Fragment>
                    {this.eventTemplateSelector()}
                </React.Fragment>
            )
        } else if (conditionType === CONDITION_TYPES.index) {
            return (
                <React.Fragment>
                    {this.indexSelector()}
                </React.Fragment>
            )
        } else if (conditionType === CONDITION_TYPES.dot) {
            return (
                <React.Fragment>
                    {this.lotSelector()}
                </React.Fragment>
            )
        }
    }

    loadEventTemplateData = (options, onComplete) => {
        const { getModels } = this.props;
        getModels({ status_ids: [2], model_type: 1, ...options }, onComplete);
    };

    render() {
        const { showModal } = this.props;

        return (
            <ReactModal
                isOpen={showModal}
                onRequestClose={() => this.setModalVisibility(false)}
                className="modal-block dialog"
                overlayClassName="modal-overlay gray"
                style={{content: {width: '70%', maxHeight: '90%', overflowY: 'auto', overflowX: 'hidden'}}}
            >
                <div role="document">
                    <div className="modal-content bd-0">
                        <div className="modal-header pd-x-20">
                            <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                                <FormattedMessage id="selectField" />
                            </h6>
                        </div>
                        <div className="modal-body pd-x-20">
                            { this.fieldSelector() }
                        </div>
                    </div>
                </div>
            </ReactModal>
        );
    }
}

const mapStateToProps = state => ({
    models: state.models.list,
    totalModels: state.models.total,
    authenticated: state.identity.authenticated,
    location: state.router.location,
    indices: state.indices.list,
    totalIndices: state.indices.total
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getModels,
            loadModel,
            importFields,
            getIndices,
            createTransaction,
            getTransaction,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(ConditionFieldPicker));
