import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import Page from 'components/page';
import ReactModal from 'react-modal';
import { Formik, Form, Field } from 'formik';
import { Fields } from 'components/fields'
import { createEntityGroup, updateEntityGroup, listEntityGroups } from 'reducers/entityGroup';
import { Notifications } from 'components/notifications';
import { history } from 'configuration/history.js';
import LoadingOverlay from 'react-loading-overlay';
import { Card, Collapse } from "@blueprintjs/core";
import Tooltip from 'rc-tooltip';

function CollectionCard({
    id,
    label,
    dot_template_count,
    dash_template_count,
    document_template_count,
}){
    // shorten any contiguous strings greater than 40 characters
    let labelShortened = label
        .split(/(\s+)/)
        .map(word => word.length > 40 ? word.substring(0,40) + '…' : word)
        .join('');

    // if the whole label is long, shorten it down to 300 characters max
    if (labelShortened.length > 250) {
        labelShortened =  labelShortened.substring(0, 250);
        // end with an ellipsis, if it doesn't already happen to be ending with one
        let appendCharacter = '…'
        if (labelShortened.charAt(249) === appendCharacter) {
            appendCharacter = '';
        }
        labelShortened = labelShortened + appendCharacter;
    }

    // if the label has been shortened, add a tooltip on hover to show the original label
    let labelEl = <span>{labelShortened}</span>;
    if (labelShortened.length !== label.length) {
        labelEl = (
            <Tooltip trigger={['hover']} placement='bottom' overlay={<span>{label}</span>} mouseEnterDelay={1.5}>
                <div>
                    {labelShortened}
                </div>
            </Tooltip>
        );
    }
    return (
        <Card 
            className='collection-card'
            interactive={true}
            onClick={() =>  history.push(`/collections/${id}`)}
        >
            <div className='tx-inverse tx-bold collection-title'>
                {labelEl}
            </div>
            <div className='collection-template-info'>
                <div className='collection-template-info-item'>
                    <FormattedMessage id="dotTemplateCount" values={{ count: dot_template_count }} />
                </div>
                <div className='collection-template-info-item'>
                    <FormattedMessage id="dashTemplateCount" values={{ count: dash_template_count }} />
                </div>
                <div className='collection-template-info-item'>
                    <FormattedMessage id="documentTemplateCount" values={{ count: document_template_count }} />
                </div>
            </div>
        </Card>
    )
}

function Collections({
    createEntityGroup,
    listEntityGroups,
    intl,
}){

    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [newCollectionName, setNewCollectionName] = useState('');
    const [collections, setCollections] = useState([]);
    const [loading, setLoading] = useState(true);

    const renderActions = () => {
        return (
            <div className="btn-group pd-t-10">
                <div 
                    className="btn btn-outline-info" 
                    onClick={() => { 
                        setCreateModalOpen(true);
                        setNewCollectionName('') 
                    }}
                >
                    <FormattedMessage id="newCollection" />
                </div>
            </div>
        );
    };

    useEffect(() => {
        if (typeof listEntityGroups === 'function') {
            listEntityGroups({ list_public: false }, results => {
                setCollections(results);
                setLoading(false);
            });
        }
    }, [listEntityGroups])


    return (
        <Page
            {...Collections.meta}
            subtitle={<FormattedMessage id="collectionsSubtitle" />}
            actions={renderActions()}
            panel={false}
        >
            <LoadingOverlay
                active={loading}
                spinner
                text={<FormattedMessage id='common.loading'/>}
            >
                <div className='collections-list-container' >
                    {
                        collections.length === 0 ?
                            <div className="br-section-wrapper">
                                <span className='tx-bold tx-inverse'>
                                    <FormattedMessage id="noCollectionsMessage" />
                                </span>
                            </div>
                            :
                            collections.map(c => <CollectionCard {...c} />)
                    }
                </div>
            </LoadingOverlay>
            <ReactModal
                isOpen={createModalOpen}
                onRequestClose={() => setCreateModalOpen(false)}
                className="modal-block dialog"
                overlayClassName="modal-overlay gray"
            >
                <div className="modal-dialog rich-text-button-dialog" role="document">
                    <div className="modal-content bd-0">
                        <div className="modal-header pd-x-20">
                            <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold"><FormattedMessage id="newCollection" /></h6>
                        </div>
                        <div className="modal-body">
                            <Formik
                                onSubmit={values => setNewCollectionName(values.newCollectionName)}
                                render={formProps => {
                                    const { formatMessage } = intl;
                                    return (
                                        <Form 
                                            noValidate
                                            onChange={async e => {
                                                await formProps.handleChange(e);
                                                formProps.submitForm();
                                            }}
                                        >
                                            <div className="form-group pd-x-20 pd-t-20">
                                                <Fields.Input type="text" name="newCollectionName" className="form-control" showRequired={true} placeholder={formatMessage({id: 'collectionName'})} />
                                            </div>
                                        </Form>
                                    )
                                }}
                            />
                        </div>
                        <div className="modal-footer justify-content-center">
                            <button
                                type="button"
                                className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                onClick={() => {
                                    
                                    createEntityGroup({
                                        label: newCollectionName,
                                        status: 2,
                                        is_public: false,
                                    }, result => {
                                        Notifications.success(<FormattedMessage id='successfullyCreatedCollection' />);
                                        history.push(`/collections/${result.id}`);
                                    })
                                }}
                            >
                                <FormattedMessage id="createCollection" />
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                onClick={() => {
                                    setCreateModalOpen(false);
                                }}
                            >
                                <FormattedMessage id="common.cancel" />
                            </button>
                        </div>
                    </div>
                </div>
            </ReactModal>
        </Page>
    );
}

Collections.meta = {
    title: <FormattedMessage id="collections" defaultMessage="Collections" />,
    routes: ['/collections'],
    icon: 'ion-ios-folder-outline',
    menus: { main: true, user: false },
    hideBreadcrumbs: true,
    order: 11,
    onlySubscribed: true
};

const mapStateToProps = state => ({
    user: state.identity.user,
    company: state.identity.company,
    permissions: state.identity.permissions
});
const mapDispatchToProps = dispatch => bindActionCreators({
    createEntityGroup,
    listEntityGroups,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(Collections));
