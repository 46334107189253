export const ALERTS_DELETE = `
    mutation M($alert_id: Int!) {
        alert_delete(alert_id: $alert_id) {
            id
            hash_id
            title
            description
            created_on
            created_by
            active
            conditions {
                id
                type_id: typeid
                comparator_id: comparatorid
                value: value
                order: order
                break_or: breakor
            }
            subscribers {
                id
                email
            }
        }
    }
`;
