import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import Page from '../../../components/page';
import { importFields, setModelPropertiesVisibility } from '../../../reducers/fields';
import { setMenuVisibility } from '../../../reducers/menus';
import FieldNew from '../../models/fields/components/new';
import ModelPreview from '../../models/fields/components/preview';
import { loadModel } from '../../../reducers/models';
import { getProfile } from '../../../reducers/identity';
import { canInteract } from '../../../components/routes/permissions/permissionResolver';
import { history } from '../../../configuration/history';
import { requestEntityLock, releaseEntityLock } from 'reducers/entityLock';

import '../models.scss';

class ModelNew extends React.Component {
    constructor(props) {
        super(props);
        this.lockIntervalId = 0;
    }

    validatePermissions = () => {
        const { entityPermissions } = this.props;
        if (!canInteract(entityPermissions, ['model', 'create', 'update'])) {
            history.replace('/');
        }
    };

    componentDidMount() {
        const { match, loadModel, company, getProfile, requestEntityLock } = this.props;
        if (!company) {
            getProfile(this.validatePermissions);
        } else {
            this.validatePermissions();
        }

        if (match && match.params && match.params.id) {
            requestEntityLock(
                {
                    entity_type: 'model',
                    entity_id: match.params.id
                }
            );
            loadModel(match.params.id, (model) => {
                this.lockIntervalId = setInterval(
                    () => {
                        if (model.status_id === 1) {
                            const { lockedEntity } = this.props;
                            if (this.props.user.id !== lockedEntity.user_id) {
                                loadModel(match.params.id);
                            }
                            requestEntityLock({ entity_type: 'model', entity_id: match.params.id })
                        }
                    },
                    10 * 1000
                );
            });
        }
    }

    componentWillUnmount() {
        const { releaseEntityLock, match } = this.props;
        clearInterval(this.lockIntervalId);
        releaseEntityLock({
            entity_type: 'model',
            entity_id: match.params.id
        });
    }

    render() {
        const { 
            parents, 
            model, 
            match, 
            company, 
            lockedEntity, 
            user ,
            intl: { formatMessage }, 
        } = this.props;

        if (!match || !match.params || !match.params.id) return null;
        if (!company) return null;
        if (!model) return null;

        let eventReadLocked = false;
        let readLockedMessage = () => null;
        if (lockedEntity.user_id && lockedEntity.user_id !== user.id && model.status_id === 1) {
            eventReadLocked = true;
            readLockedMessage = () => (
                <div style={{
                    backgroundColor: '#fff',
                    padding: '15px 20px',
                    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.21)',
                    margin: '10px'
                }}>
                    <h6 className="mg-b-0 tx-inverse tx-bold">
                        <FormattedHTMLMessage id='lockedEntityMessage' tagName="span" values={{ entity: formatMessage({ id: 'dotTemplate' }), name: lockedEntity.user_name, email: lockedEntity.user_email }} />
                    </h6>
                </div>
            )
        }

        return (
            <Page 
                {...{ ...ModelNew.meta, title: `${model.title}${model.is_used ? ' (in use)' : ''}` }} 
                parents={parents} 
                panel={false}
                extraHeaderEls={readLockedMessage()}
            >
                <div className="row pd-x-15">
                    <FieldNew 
                        modelTypeId={2} 
                        readOnly={eventReadLocked}
                    />
                    <div className="col-md-12 col-lg-6 col-xl-8 pd-l-5 pd-r-0">
                        <ModelPreview model={model} modelId={match.params.id} modelTypeId={2} />
                    </div>
                </div>
            </Page>
        );
    }
}

ModelNew.meta = {
    showTitleBlock: false,
    title: <FormattedMessage id="page.cargoCategories.new.title" />,
    subtitle: <FormattedMessage id="page.cargoCategories.new.subtitle" />,
    routes: ['/objects/object-templates/'],
    icon: 'ion-clipboard',
    menus: { main: false, user: false },
    order: 0,
    requires: ['model', 'read', 'update']
};

const mapStateToProps = state => ({
    entityPermissions: state.identity.entityPermissions,
    company: state.identity.company,
    model: state.fields.model,
    fieldTypes: state.fields.types,
    lockedEntity: state.entityLock.lockedEntity,
    user: state.identity.user,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getProfile,
            setMenuVisibility,
            loadModel,
            importFields,
            setModelPropertiesVisibility,
            requestEntityLock,
            releaseEntityLock,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(ModelNew));
