import React from 'react';
import { injectIntl, FormattedMessage} from 'react-intl';
import { Field, ErrorMessage  } from 'formik';
import CreatableSelect from 'react-select/lib/Creatable';
import { components } from 'react-select';
import { getTextMaxLength } from '../../utilities/constants';

import './fields.scss';

class MultiTextField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: [],
            inputValue: ''
        };
    }

    componentDidMount() {
        const { value } = this.props;
        const valueOptions = [];
        if (!value) return;
        for (const val of value) {
            valueOptions.push(this.createOption(val));
        }
        this.setState({ value: valueOptions });
    }

    createOption = label => ({ label, value: label });

    handleChange = async (value, form = null) => {
        const { name, submitOnChange, disabled = false } = this.props;
        if (!!disabled) return;
        this.setState({ value });
        if (form) {
            const parsedValue = !!value ? (Array.isArray(value) ? value.map(v => v.value) : value.value) : '';
            await form.setFieldValue(name, parsedValue);
            if (!!submitOnChange) {
                form.submitForm();
            }
        }
    };
    handleInputChange = inputValue => {
        const { disabled = false } = this.props;
        if (!!disabled) return;
        if(inputValue.length <= getTextMaxLength()) {
            this.setState({ inputValue });
        }
    };
    handleKeyDown = (event, form) => {
        const { name, submitOnChange, disabled = false } = this.props;
        if (!!disabled) return;

        const { inputValue, value } = this.state;
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                this.setState({ inputValue: '', value: [...value, this.createOption(inputValue)] }, async () => {
                    const v = this.state.value;
                    const value = !!v ? (Array.isArray(v) ? v.map(s => s.value) : v.value) : '';
                    await form.setFieldValue(name, value);
                    if (!!submitOnChange) {
                        form.submitForm();
                    }
                });
                event.preventDefault();
                break;

            default:
                break;
        }
    };

    render() {
        const {
            name,
            intl: { formatMessage },
            disabled
        } = this.props;
        const { inputValue, value } = this.state;

        const customStyles = {
            control: base => ({
                ...base,
                boxShadow: 'none'
            })
        };

        return (
            <React.Fragment>
                <Field
                    {...this.props}
                    render={({ form }) => {
                        return (
                            <CreatableSelect
                                placeholder={formatMessage({ id: `${name}.placeholder` })}
                                components={{
                                    IndicatorSeparator: () => null, 
                                    DropdownIndicator: null,
                                    MultiValue: props => <components.MultiValue {...props} className={'se-select-value-' + name} />,
                                    Menu: props => <components.Menu {...props} className={'se-select-menu-' + name} />,
                                    Option: props => <components.Option {...props} className={'se-select-menu-option-' + name} />
                                }}
                                inputId={'se-select-input-' + name}
                                isDisabled={disabled}
                                inputValue={inputValue}
                                isClearable
                                isMulti
                                menuIsOpen={false}
                                onChange={v => this.handleChange(v, form)}
                                onInputChange={this.handleInputChange}
                                onKeyDown={e => this.handleKeyDown(e, form)}
                                value={value}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#eeeeee',
                                        primary: '#00336B'
                                    }
                                })}
                                styles={customStyles}                             
                            />
                        );
                    }}
                />
                <ErrorMessage name={name} component="div" className="validation-message" />
                {this.props.showIsRequired && (value === null || value === undefined || value === '') && <div className="validation-message"><FormattedMessage id="validation.required" /></div>}
            </React.Fragment>
        );
    }
}

export default injectIntl(MultiTextField);
