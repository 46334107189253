export const PAPER_SIZES = [
    { name: 'A3', maxWidth: '297mm' },
    { name: 'A4', maxWidth: '210mm' },
    { name: 'A5', maxWidth: '148mm' },
    { name: 'B4', maxWidth: '257mm' },
    { name: 'B5', maxWidth: '182mm' },
    { name: 'Executive', maxWidth: '7.25in' },
    { name: 'Folio', maxWidth: '8.5in' },
    { name: 'Ledger', maxWidth: '17in' },
    { name: 'Legal', maxWidth: '8.5in' },
    { name: 'Letter', maxWidth: '8.5in' },
    { name: 'EnvelopeDL', maxWidth: '110mm' },
    { name: 'Quarto', maxWidth: '215mm' },
    { name: 'Statement', maxWidth: '5.5in' },
    { name: 'Tabloid', maxWidth: '11in' },
    { name: 'Paper10x14', maxWidth: '10in' },
    { name: 'Paper11x17', maxWidth: '11in' }
];

export const PAGE_ORIENTATIONS = [
    'Portrait',
    'Landscape'
];
