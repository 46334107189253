import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Field, ErrorMessage } from 'formik';
import CreatableSelectControl from './uncontrolledComponents/creatableSelectControl';
import Tooltip from 'rc-tooltip';

import './fields.scss';

class CreatableSelectField extends React.Component {
    render() {
        const {
            intl: { formatMessage },
            multi,
            options,
            name,
            value,
            placeholder,
            submitOnChange,
            disabled = false,
            extraOnChange,
            onCreateOption = () => null,
            isClearable = false,
        } = this.props;

        return (
            <React.Fragment>
                <Field
                    {...this.props}
                    render={({ form }) => {
                        const showLabel = multi ? !!value && value.length > 0 : !!value;
                        const fieldValue = multi
                            ? options.filter(o => {
                                  const valueArray = Array.isArray(value) ? value : [];
                                  return valueArray.includes(o.value);
                              })
                            : options.find(o => o.value === value);

                        return (
                            <React.Fragment>
                                <div style={{ position: 'relative' }}>
                                    <CreatableSelectControl
                                        placeholder={placeholder || formatMessage({ id: `${name}.placeholder` })}
                                        name={name}
                                        options={options}
                                        isDisabled={disabled}
                                        onChange={async selectedValue => {
                                            if (!!disabled) return;
                                            if (extraOnChange) {
                                                extraOnChange(form);
                                            }
                                            const value = !!selectedValue
                                                ? Array.isArray(selectedValue)
                                                    ? selectedValue.map(s => s.value)
                                                    : selectedValue.value
                                                : '';
                                            await form.setFieldValue(name, value);
                                            if (!!submitOnChange) {
                                                form.submitForm();
                                            }
                                        }}
                                        onCreateOption={onCreateOption}
                                        onBlur={form.handleBlur}
                                        value={fieldValue}
                                        isMulti={multi}
                                        isClearable={isClearable}
                                    />
                                    {!!showLabel && 
                                        <Tooltip trigger={['hover']} overlay={<span>{placeholder || formatMessage({ id: `${name}.placeholder` })}</span>} mouseEnterDelay={2}>
                                            <div className="select-input-placeholder">{placeholder || formatMessage({ id: `${name}.placeholder` })}</div>
                                        </Tooltip>
                                    }
                                </div>
                                {this.props.showIsRequired && !showLabel && <div className="validation-message"><FormattedMessage id="validation.required" /></div>}
                            </React.Fragment>
                        );
                    }}
                />
                <ErrorMessage name={name} component="div" className="validation-message" />
            </React.Fragment>
        );
    }
}

export default injectIntl(CreatableSelectField);
