export const MODELS_LIST = `
    query($status_ids: [Int!], $created_from: DateTime, $created_to: DateTime, $search: String, $order_by: [OrderBy], $offset: Int, $limit: Int, $model_type: Int) {
        model_list(
            input: { status_ids: $status_ids, created_from: $created_from, created_to: $created_to, search: $search, model_type: $model_type }
            paging: { order_by: $order_by, offset: $offset, limit: $limit }
        ) {
            total
            data {
                id
                hash_id
                company_id
                status_id
                status_name
                created_on
                title
                description
                is_used
                split_conditions
                extra_info
                created_by_name
                model_type
                model_type_name
                is_locked
                contains_inaccessible_dots

                model_field_list {
                    id
                    model_id
                    order
                    type_id
                    type_name
                    status_id
                    status_name
                    index_field_id
                    input_id
                    visibility_check
                    title
                    description
                    is_required
                    validation
                    width
                    force_break
                    extra_info
                    company_id: model_company_id
                    cargoCategory: cargo_category
                    cargo_type
                    transform_cargo_type
                    cargo_reference_type
                    is_static_unit
                    default_unit
                }
            }
        }
    }
`;
