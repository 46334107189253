export const RESPOND_TO_SHARE_REQUEST = `
    mutation M (
        $link_name	: String!
        $accepted     : Boolean!
    )  
    {
        external_sharing_respond_to_invite(
            input: {
                link_name   : $link_name      
                accepted    : $accepted
            }
        )
        {
            id              
            entity_type       
            entity_id     
            target_email      
            include_transactions
        }
    }
`;