export const FIELDS_LIST = `
    query($model_id: Int!, $status_ids: [Int!], $order_by: [OrderBy], $offset: Int, $limit: Int) {
        model_field_list(input: { model_id: $model_id, status_ids: $status_ids }, paging: { order_by: $order_by, offset: $offset, limit: $limit }) {
            id
            model_id
            order
            type_id
            type_name
            status_id
            status_name
            index_field_id
            input_id
            visibility_check
            title
            description
            is_required
            validation
            width
            force_break
            extra_info
            company_id: model_company_id
            cargoCategory: cargo_category
            cargo_type
            transform_cargo_type
            cargo_reference_type
            is_static_unit
            default_unit

            map_dash_field
            map_dot_field
            map_special_dot_field
            map_compound_id

            index_field {
                id
                order_by
                type_id
                type_name
                status_id
                status_name
                title
                description
                extra_info
            }

            values: model_field_value_list(show_hidden: false) {
                id
                company_id
                field_id
                order_by
                is_hidden
                value
            }

            visibility_condition_list {
                id
                order_by
                break_or
                is_not
                field_id
                comparator_id
                comparator_name
                comparands
                extra_info
            }
        }
    }
`;
