import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import CompaniesList from './containers/list';
import CompanyDetails from './containers/details';
import CompanyInvites from './containers/invites';
import CompanyRoles from './containers/roles';
import { DefinedPermissions } from '../../components/routes/permissions/permissionResolver';

import './companies.scss';

class Companies extends React.Component {
    render() {
        return (
            <Switch>
                <Route exact path="/companies" component={props => <CompaniesList {...props} />} />
                <Route exact path="/companies/invites" component={props => <CompanyInvites {...props} parents={[Companies.meta]} />} />
                <Route exact path="/companies/:id" component={props => <CompanyDetails {...props} parents={[Companies.meta]} />} />
                <Route path="/companies/:id/roles" component={props => <CompanyRoles {...props} parents={[Companies.meta]} />} />
            </Switch>
        );
    }
}

Companies.meta = {
    title: <FormattedMessage id="page.companies.title" />,
    subtitle: <FormattedMessage id="page.companies.subtitle" />,
    routes: ['/companies'],
    icon: 'ion-ios-world-outline',
    menus: { main: true, user: false },
    order: 10,
    access: DefinedPermissions.SuperUser,
    requires: ['company', 'read', 'update']
};

export default Companies;
