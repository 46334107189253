import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Tooltip from 'rc-tooltip';
import ReactModal from 'react-modal';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Page from '../../../components/page';
import Table from '../../../components/table';
import { getCompanies, getStatuses } from '../../../reducers/companies';
import CompanySetup from '../components/forms/companySetup';
import CompanyDeleteConfirm from '../components/forms/companyDeleteConfirm'
import { history } from '../../../configuration/history';
import { Button, Popover, Menu, MenuItem, MenuDivider, Icon } from '@blueprintjs/core';
import { setCompaniesTableOptions } from 'reducers/sessionSettings';

class CompaniesList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: { open: false, data: undefined },
            deleteModal: { open: false, data: undefined }
        };
    }

    componentDidMount() {
        const { statuses, getStatuses } = this.props;
        if (!statuses) getStatuses();
    }

    setModalVisibility = (open, data) => {
        this.setState({ ...this.state, modal: { open, data: open ? data : undefined } });
    };

    setDeleteModalVisibility = (open, data) => {
        this.setState({ ...this.state, deleteModal: { open, data: open ? data : undefined } });
    };

    loadData = (options, onComplete) => {
        const { getCompanies } = this.props;
        return getCompanies({ status_ids: [2, 3, 4, 5, 6], ...options }, onComplete);
    };

    getColumns = () => {
        return [
            {
                headerClassName: 'd-sm-block col-2 d-none',
                className: 'd-xs-block col-2',
                sortable: false,
                resizable: false,
                width: 34,
                id: 'actions-menu',
                accessor: d => (
                    <div className="actions-wrapper">
                        <Popover
                            content={
                                <Menu>
                                    <MenuItem icon="share" text={<FormattedMessage id="common.open" />} onClick={() => history.push(`/companies/${d.id}` + window.location.search)} />
                                    <MenuDivider />
                                    <MenuItem
                                        icon="cross"
                                        text={<FormattedMessage id="common.delete" />}
                                        onClick={() => this.setDeleteModalVisibility(true, d)}
                                    />
                                </Menu>
                            }
                        >
                            <Button minimal={true} small={true} icon={<Icon iconSize={12} icon="menu" />} />
                        </Popover>
                    </div>
                )
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block text-center',
                Header: <FormattedMessage id="common.id" />,
                width: 50,
                id: 'id',
                accessor: d => d.id
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block',
                Header: <FormattedMessage id="common.name" />,
                id: 'company_name',
                accessor: d => <Link to={{ pathname: `/companies/${d.id}`, search: window.location.search }}>{d.company_name}</Link>
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block text-center',
                Header: '',
                id: 'actions',
                sortable: false,
                accessor: d => (
                    <React.Fragment>
                        <div className="cell-actions float-right">
                            <Tooltip placement={'top'} trigger={['hover']} overlay={<FormattedMessage id="common.edit" />}>
                                <i
                                    className="material-icons-outlined table-action-icon"
                                    style={{ borderLeft: 'none' }}
                                    onClick={() => this.setModalVisibility(true, d)}
                                >
                                    edit
                                </i>
                            </Tooltip>
                        </div>
                    </React.Fragment>
                )
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block text-center',
                Header: <FormattedMessage id="common.status" />,
                id: 'status_name',
                width: 100,
                accessor: d => <FormattedMessage id={d.status_name} defaultMessage={d.status_name} />
            }
        ];
    };

    renderActions = () => {
        return (
            <div className="btn-group pd-t-10">
                <span className="btn btn-outline-info" onClick={() => this.setModalVisibility(true)}>
                    <FormattedMessage id="page.companies.actions.setup" />
                </span>
                <span className="btn btn-outline-info" onClick={() => history.push('/companies/invites' + window.location.search)}>
                    <FormattedMessage id="page.companies.invites" />
                </span>
            </div>
        );
    };

    render() {
        const { modal, deleteModal } = this.state;
        const { companies = [], total = 0, statuses } = this.props;
        if (!statuses) return null;

        return (
            <React.Fragment>
                <Page {...CompaniesList.meta} actions={this.renderActions()}>
                    <Table
                        columns={this.getColumns()}
                        data={companies}
                        total={total}
                        dataLoader={(options, onComplete) => this.loadData(options, onComplete)}
                        optionsSetter={this.props.setCompaniesTableOptions}
                        initialOptions={this.props.companiesTableOptions}
                    />
                </Page>
                <ReactModal
                    isOpen={modal.open}
                    onRequestClose={() => this.setModalVisibility(false)}
                    className="modal-block dialog"
                    overlayClassName="modal-overlay gray"
                >
                    <CompanySetup onCancel={() => this.setModalVisibility(false)} statuses={statuses} company={modal.data} />
                </ReactModal>
                <ReactModal
                    isOpen={deleteModal.open}
                    onRequestClose={() => this.setDeleteModalVisibility(false)}
                    onCancel={() => this.setDeleteModalVisibility(false)}
                    className="modal-block dialog"
                    overlayClassName="modal-overlay gray"
                >
                    <CompanyDeleteConfirm onCancel={() => this.setDeleteModalVisibility(false)} statuses={statuses} company={deleteModal.data} />
                </ReactModal>
            </React.Fragment>
        );
    }
}

CompaniesList.meta = {
    title: <FormattedMessage id="page.companies.title" />,
    subtitle: <FormattedMessage id="page.companies.subtitle" />,
    routes: ['/companies'],
    icon: 'ion-ios-world-outline',
    menus: { main: true, user: false },
    order: 10
};

const mapStateToProps = state => ({
    companies: state.companies.list,
    total: state.companies.total,
    statuses: state.companies.statuses,
    companiesTableOptions: state.sessionSettings.companiesTableOptions,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getCompanies,
            getStatuses,
            setCompaniesTableOptions,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompaniesList);
