export const DOCUMENT_TEMPLATE_CREATE = `
    mutation M(
        $model_id: Int
        $title: String
        $description: String
        $header: [DocumentElementCreateRequest]
        $body: [DocumentElementCreateRequest]
        $footer: [DocumentElementCreateRequest]
        $file_data : String
        $server_file_data : String
        $document_type : String
    ) {
        document_template_create(input: { model_id: $model_id, title: $title, description: $description, header: $header, body: $body, footer: $footer, file_data: $file_data, server_file_data: $server_file_data, document_type: $document_type }) {
            id
            hash_id
            model_id
            title
            description
            paper_size
            page_orientation
            header {
                ... on TextElement {
                    ref_id
                    title
                    content
                    bold
                    italic
                    underline
                    text_alignment
                    width_offset
                    width
                    offset
                    type
                    break_row
                    order
                }
                ... on ImageElement {
                    ref_id
                    title
                    url
                    width_offset
                    width
                    offset
                    type
                    s3_bucket
                    s3_object
                    break_row
                    order
                }
                ... on RichTextElement {
                    ref_id
                    title
                    json
                    text_alignment
                    width_offset
                    width
                    offset
                    type
                    break_row
                    order
                }
            }
            body {
                ... on TextElement {
                    ref_id
                    title
                    content
                    bold
                    italic
                    underline
                    text_alignment
                    width_offset
                    width
                    offset
                    type
                    break_row
                    order
                }
                ... on ImageElement {
                    ref_id
                    title
                    url
                    width_offset
                    width
                    offset
                    type
                    s3_bucket
                    s3_object
                    break_row
                    order
                }
                ... on RichTextElement {
                    ref_id
                    title
                    json
                    text_alignment
                    width_offset
                    width
                    offset
                    type
                    break_row
                    order
                }
            }
            footer {
                ... on TextElement {
                    ref_id
                    title
                    content
                    bold
                    italic
                    underline
                    text_alignment
                    width_offset
                    width
                    offset
                    type
                    break_row
                    order
                }
                ... on ImageElement {
                    ref_id
                    title
                    url
                    width_offset
                    width
                    offset
                    type
                    s3_bucket
                    s3_object
                    break_row
                    order
                }
                ... on RichTextElement {
                    ref_id
                    title
                    json
                    text_alignment
                    width_offset
                    width
                    offset
                    type
                    break_row
                    order
                }
            }
            version
    		file_data
    		server_file_data
    		document_type
            metadata
        }
    }
`;
