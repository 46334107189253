import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw } from 'draft-js';
import { getRichTextContent } from '../../../utilities/common';

class TransactionHeading extends React.Component {
    getCompanyLogo = () => {
        const {
            company: { company_name, logo }
        } = this.props;

        return logo && logo.thumb && logo.thumb.url ? (
            <img src={logo.thumb.url} style={{ maxHeight: '40px', maxWidth: '190px', margin: '0 auto' }} alt={company_name} />
        ) : null;
    };

    render() {
        const { id, model, company } = this.props;
        if (!id || !model || !company) return null;

        const { title, description } = model;
        const { company_name } = company;

        let descriptionMarkup = '';
        if (!description.startsWith('{"blocks":[')) {
            descriptionMarkup = description;
        } else {
            const descriptionObject = getRichTextContent(description);
            if (!descriptionObject) {
                return null;
            }
            descriptionMarkup = stateToHTML(convertFromRaw(descriptionObject));
        }

        return (
            <div className="model-properties-preview col-12">
                <div className="row">
                    <div className="col-12 pd-15 tx-center branding-line">
                        <div>{this.getCompanyLogo() || <span className="company-name">{company_name}</span>}</div>
                    </div>
                </div>
                <div className="row mg-0 pd-t-20 pd-x-60">
                    <h4 className="model-properties-preview-title">{title}</h4>
                    <div className="col-12 pd-x-0" dangerouslySetInnerHTML={{ __html: descriptionMarkup }} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    company: state.identity.company
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TransactionHeading);
