import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactModal from 'react-modal';
import _ from 'lodash';
import Page from '../../../components/page';
import PricingPlanBox from '../components/payment/planBox';
import { getPlans, getProducts, subscribe } from '../../../reducers/payment';
import { Notifications } from '../../../components/notifications';
import { getProfile } from '../../../reducers/identity';
import { history } from '../../../configuration/history';
import PurchasePlan from './../components/payment/purchasePlan';
import doraeLogo from 'assets/logo-green.svg';

class CompanyPaymentPlans extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subscriptionType: 'annually',
            modal: { open: false, data: undefined }
        };
    }

    componentDidMount() {
        const { plans, getPlans, products, getProducts } = this.props;
        if (!plans) getPlans();
        if (!products) getProducts();
    }

    getInterval = () => (this.state.subscriptionType === 'annually' ? 'year' : 'month');

    getPrice = (interval, productId, groupedPlans) => {
        if (!interval || !productId || !groupedPlans) return undefined;

        const plan = (groupedPlans[productId] || []).find(p => p.interval === interval);
        if (!plan) return undefined;
        return plan.price_cents / 100;
    };

    setModalVisibility = (open, data) => this.setState({ ...this.state, modal: { open, data: !!open ? data : undefined } });

    setSubscriptionType = subscriptionType => this.setState({ ...this.state, subscriptionType });

    subscribe = (plan, { seats }) => {
        const { subscribe, getProfile } = this.props;
        subscribe(plan.id, seats, () => {
            getProfile();
            Notifications.success(<FormattedMessage id="page.settings.setup.sub.done" />);
            this.setModalVisibility(false);
            history.push('/settings' + window.location.search);
        });
    };

    render() {
        const { subscriptionType, modal } = this.state;
        const { parents, company, plans, products, intl: { formatMessage }, } = this.props;
        if (!company || !plans || plans.length === 0 || !products || products.length === 0) return null;

        // console.log(company, products, plans)

        const standardProductId = (products.find(p => p.metadata.category === 'standard') || {}).id;
        const proProductId = (products.find(p => p.metadata.category === 'pro') || {}).id;
        const plansForCurrency = plans.filter(p => p.currency.toUpperCase() === company.currency_name.toUpperCase());
        const groupedPlans = _.groupBy(plansForCurrency, 'product_id');

        return (
            <React.Fragment>
                <Page {...CompanyPaymentPlans.meta} parents={parents}>
                    <div className="row">
                        <div className="col-12 mg-b-30 tx-center">
                            <div className="plan-purchase-title">
                                <FormattedMessage id="page.companies.payment.choosePlan" />
                            </div>
                            <div>
                                <FormattedMessage id="page.companies.payment.choosePlan.sub" />
                            </div>
                        </div>
                        <div className="col-sm-4 col-xs-12 offset-sm-4 mg-b-60 btn-group">
                            <span
                                className={`col-6 btn btn-outline-primary ${subscriptionType === 'annually' ? 'active' : ''}`}
                                onClick={() => this.setSubscriptionType('annually')}
                            >
                                <FormattedMessage id="common.annually" />
                            </span>
                            <span
                                className={`col-6 btn btn-outline-primary ${subscriptionType === 'monthly' ? 'active' : ''}`}
                                onClick={() => this.setSubscriptionType('monthly')}
                            >
                                <FormattedMessage id="common.monthly" />
                            </span>
                        </div>
                    </div>

                    <div className="row payment-plans-row">
                        <PricingPlanBox
                            type={subscriptionType}
                            onChoosePlan={() => this.setModalVisibility(true, (groupedPlans[standardProductId] || []).find(p => p.interval === this.getInterval()))}
                            plan="standard"
                            price={{
                                monthly: this.getPrice('month', standardProductId, groupedPlans),
                                annually: this.getPrice('year', standardProductId, groupedPlans)
                            }}
                            title={
                                <span className="dorae_subscription-row">
                                    <img src={doraeLogo} alt="Dorae" className="dorae_plan-logo" /> <strong>Standard</strong>
                                </span>
                            }
                            subtitle={formatMessage({id: 'standardDescription'})}
                            features={[formatMessage({id: 'basicSupport'}), formatMessage({id: 'dataCaptureAndDocs'}), formatMessage({id: 'unlimitedSearch'})]}
                            currency={company.currency_name}
                            seleniumClass={'standard-subscribe'}
                        />
                        <PricingPlanBox
                            type={subscriptionType}
                            plan="pro"
                            onChoosePlan={() => this.setModalVisibility(true, (groupedPlans[proProductId] || []).find(p => p.interval === this.getInterval()))}
                            price={{
                                monthly: this.getPrice('month', proProductId, groupedPlans),
                                annually: this.getPrice('year', proProductId, groupedPlans)
                            }}
                            title={
                                <span className="dorae_subscription-row">
                                    <img src={doraeLogo} alt="Dorae" className="dorae_plan-logo" /> <strong>Pro</strong>
                                </span>
                            }
                            subtitle={formatMessage({id: 'proDescription'})}
                            features={[formatMessage({id: 'doraeStandard'}), formatMessage({id: 'liveSupport'}), formatMessage({id: 'visibilityFunctions'})]}
                            currency={company.currency_name}
                            seleniumClass={'pro-subscribe'}
                        />
                        <PricingPlanBox
                            type={subscriptionType}
                            plan="enterprise"
                            price={{ available: false }}
                            title={
                                <span className="dorae_subscription-row">
                                    <img src={doraeLogo} alt="Dorae" className="dorae_plan-logo" /> <strong>Enterprise</strong>
                                </span>
                            }
                            subtitle={formatMessage({id: 'enterpriseDescription'})}
                            features={[
                                'Dorae Pro',
                                'Multi-user interaction features',
                                'Bespoke system integrations',
                                'Designated account and customer success teams'
                            ]}
                            features={[
                                formatMessage({id: 'doraePro'}), 
                                formatMessage({id: 'bespokeIntegrations'}),
                                formatMessage({id: 'designatedAccountTeams'}), 
                            ]}

                            last={true}
                            currency={company.currency_name}
                            seleniumClass={'enterprise-subscribe'}
                        />
                    </div>
                </Page>
                <ReactModal
                    isOpen={modal.open}
                    onRequestClose={() => this.setModalVisibility(false)}
                    className="modal-block dialog"
                    overlayClassName="modal-overlay gray modal-overlay-account"
                >
                    <PurchasePlan
                        price={modal.data ? modal.data.price_cents : 1}
                        plan={modal.data}
                        type={subscriptionType}
                        onSubscribe={values => this.subscribe(modal.data, values)}
                        onCancel={() => this.setModalVisibility(false)}
                    />
                </ReactModal>
            </React.Fragment>
        );
    }
}

CompanyPaymentPlans.meta = {
    title: <FormattedMessage id="page.companies.paymentPlans.title" />,
    subtitle: <FormattedMessage id="page.companies.paymentPlans.subtitle" />,
    showTitleBlock: false,
    routes: ['/settings/billing/plans'],
    icon: 'ion-ios-world-outline',
    menus: { main: false, user: false },
    order: 0
};

const mapStateToProps = state => {
    return {
        company: state.identity.company,
        plans: state.payment.plans,
        products: state.payment.products
    };
};
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getProfile,
            getPlans,
            getProducts,
            subscribe
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(CompanyPaymentPlans));
