import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Field, ErrorMessage } from 'formik';
import NumberFormat from 'react-number-format';
import { normalizeNumber } from 'pages/models/fields/definitions/common';

import './fields.scss';
import Tooltip from 'rc-tooltip';

String.prototype.replaceAll = function(search, replacement) {
    var target = this;
    return target.split(search).join(replacement);
};

function getDecimalPlaces(n) {
    // Make sure it is a number and use the builtin number -> string.
    var s = "" + (+n);
    // Pull out the fraction and the exponent.
    var match = /(?:\.(\d+))?(?:[eE]([+\-]?\d+))?$/.exec(s);
    // NaN or Infinity or integer.
    // We arbitrarily decide that Infinity is integral.
    if (!match) { return 0; }
    // Count the number of digits in the fraction and subtract the
    // exponent to simulate moving the decimal point left by exponent places.
    // 1.234e+2 has 1 fraction digit and '234'.length -  2 == 1
    // 1.234e-2 has 5 fraction digit and '234'.length - -2 == 5
    return Math.max(
        0,  // lower limit.
        (match[1] == '0' ? 0 : (match[1] || '').length)  // fraction length
        - (match[2] || 0));  // exponent
  }

class InputNumericField extends React.Component {
    state = {
      value: '',
      lastDecimalSeparator: '',
      lastDigitGroupSeparator: ''
    };

    render() {
        const {
            intl: { formatMessage },
            name,
            placeholder,
            extras,
            disabled = false,
            fieldValue,
            numberFormat = "DotSeparator",
            groupThousands = false,
            stepField = 1,
        } = this.props;
        const { pre, post } = extras || {};
        const decimalSeparator = (numberFormat === "DotSeparator") ? "." : ",";
        const digitGroupSeparator = !groupThousands ? "" : ( (numberFormat === "DotSeparator") ? "," : ".");
        const decimalPlaces = getDecimalPlaces(stepField);


        return (
            <React.Fragment>
                <div className="input-field">
                    {!!pre && <div className="input-extra-pre">{pre}</div>}
                    <Field
                        {...this.props}
                        render={({ field }) => {
                            return (
                                <React.Fragment>
                                    { !!field.value && 
                                        <Tooltip trigger={['hover']} overlay={<span>{placeholder || formatMessage({ id: `${name}.placeholder` })}</span>} mouseEnterDelay={2}>
                                            <div className="input-placeholder">{placeholder || formatMessage({ id: `${name}.placeholder` })}</div>
                                        </Tooltip>
                                    }
                                    <NumberFormat
                                        name={name}
                                        // isNumericString={true}
                                        thousandSeparator={digitGroupSeparator}
                                        decimalSeparator={decimalSeparator}
                                        decimalScale={decimalPlaces}
                                        value={fieldValue}
                                        className={`form-control ${!!post ? 'with-post-extra' : ''}`}
                                        disabled={disabled}
                                        placeholder={placeholder || formatMessage({ id: `${name}.placeholder`, defaultMessage: 'New Field' })}
                                        onChange={this.props.manualOnChange}
                                    />
                                    {this.props.showIsRequired && (field.value === null || field.value === undefined || field.value === '') && <div className="validation-message required-placeholder"><FormattedMessage id="validation.required" /></div>}
                                </React.Fragment>
                            );
                        }}
                    />
                    {!!post && <div className="input-extra-post">{post}</div>}
                </div>
                {<ErrorMessage name={name} component="div" className="validation-message" />}
            </React.Fragment>
        );
    }
}

export default injectIntl(InputNumericField);
