export const LINKS_CLICK = `
    mutation M($link_name: String!) {
        link_click(link_name: $link_name) {
            id
            type_id
            type_name
            name
            created_on
            exp_on
            data
            clicks
            uses
            max_uses
            is_valid

            link_token
            link_url
        }
    }
`;
