import { LOADING_START, LOADING_COMPLETE } from './types';

const defaultState = {
    loading: false,
    message: undefined
};

/**
 * Loading state mapper.
 * @param {object} state
 * @param {string} action
 */
export default function(state = defaultState, action) {
    switch (action.type) {
        case LOADING_START:
            return { ...state, loading: true, message: action.message || 'Loading' };

        case LOADING_COMPLETE:
            return { ...state, loading: false, message: undefined };

        default:
            return state;
    }
}

export const setLoading = message => async dispatch => dispatch({ type: LOADING_START, message });
export const unsetLoading = () => async dispatch => dispatch({ type: LOADING_COMPLETE });
