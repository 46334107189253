import React from 'react';
import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import Page from '../../../components/page';
import { getDocumentTemplate } from 'reducers/document';
import { getDocumentReferenceData } from 'reducers/documents';
import LoadingOverlay from 'react-loading-overlay';
import SimpleBar from 'simplebar-react';
import FieldReferenceListItem from '../components/fieldReferenceListItem'
import { getIndices } from 'reducers/indices'
import { getField } from 'reducers/fields'
import FieldReferenceInfo from '../components/fieldReferenceInfo';
import FieldReferenceEventSelector from '../components/fieldReferenceEventSelector';
import FieldReferenceLotSelector from '../components/fieldReferenceLotSelector';
import { filterTransactions } from 'reducers/transactions';
import { getModels } from 'reducers/models'
import FadeIn from 'react-fade-in';
import ReactModal from 'react-modal';
import { createTransactionDocument } from 'reducers/transaction'
import { createDocumentSelection, listDocumentSelections } from 'reducers/document'
import { Notifications } from 'components/notifications';
import { uuid } from 'utilities/common';
import ExcelDocument from '../components/excelDocument';
import ConfirmModal from 'components/modals/confirm';
import { Fields } from 'components/fields'
import { Formik, Form } from 'formik';
import FieldReferenceGroupInfo from '../components/fieldReferenceGroupInfo';

import 'pages/models/models.scss';
import { formProps } from '__test_mocks/entities';
import { getExcelFieldReferences, getWordFieldReferences, addMetadataToFieldReferences } from 'utilities/common'

class DocumentBuilder extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            builderLoading: false,
            fieldReferences: [],
            selectedReference: null,
            showExcel: false,
            contentHeight: 500,
            unavailableTableStatuses: [],
            savedDocumentSelections: [],
        };
        this.contentRef = React.createRef();
    }

    //#region methods
    measureElement = element => {
        const DOMNode = ReactDOM.findDOMNode(element);
        return window.innerHeight - DOMNode.offsetTop - 100
    }

    addMetadataToReferences = async (refs) => {
        const { getDocumentReferenceData, template } = this.props;
        let fixedRefs = [];
        await new Promise(resolve => {
            getDocumentReferenceData({ field_references: refs, document_template_id: template.id }, (data) => {
                fixedRefs = addMetadataToFieldReferences(refs, data);
                resolve();
            })
        })
        return fixedRefs;
    }

    clearFieldRef = (ref) => {
        this.setState(prevState => { 
            const refs = prevState.fieldReferences.map(r => {
                if (r.literal === ref.literal) {
                    r.completed = false;
                    r.events = [];
                }
                return r;
            });
            return {
                fieldReferences: refs,
            }
        });
        this.setSelectedFieldRef(ref);
    }

    setSelectedFieldRef = (ref) => {
        const { filterTransactions, listLots } = this.props;
        const updateSelectedFieldRefState = () => {
            this.setState(prevState => { 
                let refs = prevState.fieldReferences.map(r => {
                    r.selected = r.literal === ref.literal;
                    return r;
                });
                return {
                    fieldReferences: refs,
                    selectedReference: ref,
                    unavailableTableStatuses: ref.unavailableTableStatuses,
                    selectionSaved: false,
                }
            });
        }
        // filter transactions based on the index field or cargo category of the selected reference
        const options = { order_by: ['created_on'], offset: 0, limit: 20, ignore: undefined, criteria: '' };
        let targetEntity;
        if (ref.type === 'index') {
            let criteria = [];
            if (options.criteria !== undefined && options.criteria.length > 0) {
                criteria = JSON.parse(options.criteria);
            }
            criteria.push( { id: uuid(8), text_values: [''], break_or: false, is_not: false, index_field_id: ref.field.id, comparator_id: 1 } );
            options.criteria = JSON.stringify(criteria);
            targetEntity = 'transactions'
        } else if (ref.type === 'model') {
            let model_ids = [];
            if (options.model_ids && options.model_ids.length > 0) {
                model_ids = options.model_ids;
            }
            model_ids = model_ids.concat(ref.event_criteria.model_ids);
            options.model_ids = model_ids;
            targetEntity = 'transactions'
        } else if (ref.type === 'lot') {
            targetEntity = 'lots'
        } else {
            console.error('Invalid field reference: ' + ref);
        }
        if (targetEntity === 'transactions') {
            filterTransactions(
                {
                    result_index_field_ids: [],
                    status_ids: [1, 2, 3, 4, 5],
                    transaction_type: 1,
                    ...options
                },
                updateSelectedFieldRefState
            );
        } else if (targetEntity === 'lots') {
            updateSelectedFieldRefState();
        }
    }

    setFieldRefEvents = (eventIds) => {
        this.setState(prevState => { 
            let refs = prevState.fieldReferences.map(r => {
                if (r.literal === prevState.selectedReference.literal || (r.group && prevState.selectedReference.group && r.group.name && r.group.name === prevState.selectedReference.group.name) ) {
                    r.events = eventIds;
                    r.all = false;
                    r.completed = true;
                }
                r.selected = false;
                return r;
            });
            return {
                fieldReferences: refs,
                selectedReference: null 
            }
        });
    }

    setFieldRefAllEvents = () => {
        this.setState(prevState => { 
            let refs = prevState.fieldReferences.map(r => {
                if (r.literal === prevState.selectedReference.literal || (r.group && prevState.selectedReference.group && r.group.name && r.group.name === prevState.selectedReference.group.name) ) {
                    r.all = true;
                    r.completed = true;
                }
                r.selected = false;
                return r;
            });
            return { 
                fieldReferences: refs,
                selectedReference: null 
            }
        });
    }

    setFieldRefSearchCriteria = (criteria, type) => {
        this.setState(prevState => { 
            let refs = prevState.fieldReferences.map(r => {
                if (r.literal === prevState.selectedReference.literal || (r.group && prevState.selectedReference.group && r.group.name && r.group.name === prevState.selectedReference.group.name) ) {
                    if (type === 'event') {
                        r.event_criteria = criteria;
                    } else {
                        r.lot_criteria = criteria
                    }
                }
                return r;
            });
            return {
                fieldReferences: refs,
            }
        });
    }

    setFieldRefLotInfoType = (lotInfoType) => {
        this.setState(prevState => { 
            const currentRef = prevState.selectedReference;
            let refs = prevState.fieldReferences.map(r => {
                if (r.literal === prevState.selectedReference.literal) {
                    r.lot_info_type = lotInfoType;
                }
                return r;
            });
            currentRef.lot_info_type = lotInfoType;
            return {
                fieldReferences: refs,
                selectedReference: currentRef 
            }
        });
    }

    fieldReferenceInfoEl = () => {
        const { selectedReference, fieldReferences } = this.state;

        if (fieldReferences.length === 0) {
            return (
                <div className="pd-20 tx-center tx-12">
                    <FormattedMessage id="common.templateContainsNoFieldReferences" />
                </div>
            );
        } else if (!selectedReference) {
            return (
                <div className="pd-20 tx-center tx-12">
                    <FormattedMessage id="common.selectFieldReferenceForInfo" />
                </div>
            );
        } else if (selectedReference.group && selectedReference.group.name) {
            return (
                <FieldReferenceGroupInfo group={selectedReference.group} refs={fieldReferences} />
            );
        } else {
            return (
                <FieldReferenceInfo fieldRef={this.state.selectedReference} />
            );
        }
    }

    generateDocument = (format) => {
        const { template, createTransactionDocument } = this.props;
        createTransactionDocument(null, template.id, format, 'idc', JSON.stringify(this.state.fieldReferences), response => {
            if (format === 'xlsx') {
                if (response && response.field_references_with_values) {
                    this.setState({showExcel: true, fixedFieldRefs: response.field_references_with_values, templateFileData: template.file_data, templateMetadata: template.metadata });
                }
            } else {
                if (response && !!response.url) {
                    Notifications.success(<FormattedMessage id="page.transactions.create.document.done" />);
                    window.open(response.url, '_blank');
                }
            }
        })
    }

    entityChooserElement = () => {
        const { selectedReference, fieldReferences } = this.state;
        const { template } = this.props;

        if (fieldReferences.length === 0) {
            if (template.document_type === 'word') {
                return (
                    <div className='row'>
                        <div className='col-3 offset-3 pd-y-15'>
                            {/* <FadeIn> */}
                                <button
                                    type="button"
                                    className="btn btn-primary btn-block tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                    onClick={() => this.generateDocument('docx')}
                                >
                                    <FormattedMessage id="common.Word" />
                                </button>
                            {/* </FadeIn> */}
                        </div>
                        <div className='col-3 pd-y-15'>
                            {/* <FadeIn> */}
                                <button
                                    type="button"
                                    className="btn btn-primary btn-block tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                    onClick={() => this.generateDocument('pdf')}
                                >
                                    <FormattedMessage id="common.Pdf" />
                                </button>
                            {/* </FadeIn> */}
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className='row'>
                        <div className='col-4 offset-4 pd-y-15'>
                            <button
                                type="button"
                                className="btn btn-primary btn-block tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                onClick={() => this.generateDocument('xlsx')} 
                            >
                                <FormattedMessage id="common.Excel" />
                            </button>
                        </div>
                    </div>
                );
            }
        } else if (fieldReferences.every(f => f.completed)) {
            const { selectionSaved } = this.state;
            let actionButtons;
            let size;
            if (template.document_type === 'word') {
                size = 3;
                actionButtons = (
                    <React.Fragment>
                        <div className='col-3 pd-y-15'>
                            <button
                                type="button"
                                className="btn btn-primary btn-block tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                onClick={() => this.setState({ saveSelectionModalOpen: true })}
                                disabled={selectionSaved}
                            >
                                { selectionSaved ? <FormattedMessage id="selectionSaved" /> : <FormattedMessage id="saveSelection" /> }
                            </button>
                        </div>
                        <div className='col-3 pd-y-15'>
                            <button
                                type="button"
                                className="btn btn-primary btn-block tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                onClick={() => this.generateDocument('docx')}
                            >
                                <FormattedMessage id="common.Word" />
                            </button>
                        </div>
                        <div className='col-3 pd-y-15'>
                            <button
                                type="button"
                                className="btn btn-primary btn-block tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                onClick={() => this.generateDocument('pdf')}
                            >
                                <FormattedMessage id="common.Pdf" />
                            </button>
                        </div>
                    </React.Fragment>
                );
            } else {
                size = 4;
                actionButtons = (
                    <React.Fragment>
                        <div className='col-4 pd-y-15'>
                            <button
                                type="button"
                                className="btn btn-primary btn-block tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                onClick={() => this.setState({ saveSelectionModalOpen: true })}
                                disabled={selectionSaved}
                            >
                                { selectionSaved ? <FormattedMessage id="selectionSaved" /> : <FormattedMessage id="saveSelection" /> }
                            </button>
                        </div>
                        <div className='col-4 pd-y-15'>
                            <button
                                type="button"
                                className="btn btn-primary btn-block tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                onClick={() => this.generateDocument('xlsx')} 
                            >
                                <FormattedMessage id="common.Excel" />
                            </button>
                        </div>
                    </React.Fragment>
                );
            }
            return (
                <div className='row pd-x-15'>
                    {actionButtons}
                    <div className={'col-' + size + ' pd-y-15'}>
                        <button
                            type="button"
                            className="btn btn-secondary btn-block tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                            onClick={() => this.setState({ confirmClearModalOpen: true })} 
                        >
                            <FormattedMessage id="common.clearAll" />
                        </button>
                    </div>
                    <ReactModal
                        isOpen={this.state.confirmClearModalOpen}
                        onRequestClose={() => this.setState({ confirmClearModalOpen: false })}
                        className="modal-block dialog"
                        overlayClassName="modal-overlay gray"
                    >
                        <div className="modal-dialog modal-lg wd-450" role="document">
                            <div className="modal-content bd-0">
                                <div className="modal-header pd-x-20">
                                    <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                                        <FormattedMessage id="common.confirm" />
                                    </h6>
                                </div>
                                <div className="modal-body pd-20">
                                    <FormattedMessage id="common.confirmClearAllFieldReferences" />
                                </div>
                                <div className="modal-footer justify-content-center">
                                    <button
                                        type="button"
                                        className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                        onClick={() => {
                                            this.setState(prevState => { 
                                                let refs = prevState.fieldReferences.map(r => {
                                                    r.events = null;
                                                    r.completed = false;
                                                    r.selected = false;
                                                    return r;
                                                });
                                                return {
                                                    fieldReferences: refs,
                                                    selectedReference: null,
                                                    confirmClearModalOpen: false,
                                                    selectionSaved: false,
                                                }
                                            });
                                        }}
                                    >
                                        <FormattedMessage id="common.yes" />
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                        onClick={() => this.setState({ confirmClearModalOpen: false })}
                                    >
                                        <FormattedMessage id="common.cancel"  />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ReactModal>
                </div>
            )
        } else if (!selectedReference) {
            let elements = (
                <div className="pd-20 tx-center tx-12">
                    <FormattedMessage id="common.selectFieldReferenceForEventsWithOneCompleted" />
                </div>
            );
            if (fieldReferences.some(f => f.completed)) {
                const { selectionSaved } = this.state;
                let saveButtons;
                let offsetSize;
                if (template.document_type === 'word') {
                    offsetSize = 0;
                    saveButtons = (
                        <>
                            <div className='col-4 pd-y-15'>
                                {/* <FadeIn> */}
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-block tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                        onClick={() => this.generateDocument('docx')}
                                    >
                                        <FormattedMessage id="common.Word" />
                                    </button>
                                {/* </FadeIn> */}
                            </div>
                            <div className='col-4 pd-y-15'>
                                {/* <FadeIn> */}
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-block tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                        onClick={() => this.generateDocument('pdf')}
                                    >
                                        <FormattedMessage id="common.Pdf" />
                                    </button>
                                {/* </FadeIn> */}
                            </div>
                        </>
                    );
                } else {
                    offsetSize = 2;
                    saveButtons = (
                        <>
                            <div className='col-4 pd-y-15'>
                                <button
                                    type="button"
                                    className="btn btn-primary btn-block tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                    onClick={() => this.generateDocument('xlsx')} 
                                >
                                    <FormattedMessage id="common.Excel" />
                                </button>
                            </div>
                        </>
                    );
                }
                elements = (
                    <>
                        {elements}
                        <div className='row pd-x-15'>
                            <div className={'col-4 offset-' + offsetSize + ' pd-y-15'}>
                                <button
                                    type="button"
                                    className="btn btn-primary btn-block tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                    onClick={() => this.setState({ saveSelectionModalOpen: true })}
                                    disabled={selectionSaved}
                                >
                                    { selectionSaved ? <FormattedMessage id="selectionSaved" /> : <FormattedMessage id="saveSelection" /> }
                                </button>
                            </div>
                            {saveButtons}
                        </div>
                    </>
                )
            } else {
                elements = (
                    <div className="pd-20 tx-center tx-12">
                        <FormattedMessage id="common.selectFieldReferenceForEvents" />
                    </div>
                );
            }
            return elements;
        } else {
            if (selectedReference.type === 'lot') {
                return (
                    <FieldReferenceLotSelector 
                        key={selectedReference.literal}
                        fieldRef={selectedReference} 
                        template={template}
                        onFieldEventsConfirmed={(fieldEvents) => this.setFieldRefEvents(fieldEvents)}
                        onFieldLotsConfirmedAll={this.setFieldRefAllEvents}
                        onFieldSearchCriteriaSet={(criteria) => this.setFieldRefSearchCriteria(criteria, 'lot')}
                        onLotInfoTypeChanged={(lotInfoType) => this.setFieldRefLotInfoType(lotInfoType)}
                    />
                )
            } else {
                return (
                    <FieldReferenceEventSelector 
                        key={selectedReference.literal}
                        fieldRef={selectedReference} 
                        template={template}
                        onFieldEventsConfirmed={(fieldEvents) => this.setFieldRefEvents(fieldEvents)}
                        onFieldEventsConfirmedAll={this.setFieldRefAllEvents}
                        onFieldSearchCriteriaSet={(criteria) => this.setFieldRefSearchCriteria(criteria, 'event')}
                        unavailableStatuses={this.state.unavailableTableStatuses}
                    />
                )
            }
        }
    }

    saveSelectionModal = () => {
        return (
            <>
            <ConfirmModal
                open={this.state.saveSelectionModalOpen}
                setOpen={(o) => this.setState({ saveSelectionModalOpen: o })}
                loading={this.state.saveSelectionModalLoading}
                onConfirm={() => {
                    const { savedSelectionName, savedDocumentSelections } = this.state;
                    const { createDocumentSelection, template } = this.props;
                    if (!savedSelectionName) {
                        Notifications.error(<FormattedMessage id='selectionNeedsName' />)
                        return;
                    }
                    this.setState({ saveSelectionModalLoading: true }, () => {
                        if (savedDocumentSelections.find(ds => ds.name.trim() === savedSelectionName.trim())) {
                            this.setState({ overwriteSavedSelectionModal: true });
                        } else {
                            const { fieldReferences } = this.state;
                            const referencesInGroups = fieldReferences.filter(ref => ref.group && ref.group.name);
                            const standaloneReferences = fieldReferences.filter(ref => !referencesInGroups.find(inGroup => inGroup.literal === ref.literal));
                            const uniqueGroups = [...new Set(referencesInGroups.map(ref => ref.group.name))]
                            const groupsWithCriteria = [];
                            for (const group of uniqueGroups) {
                                const firstMatch = referencesInGroups.find(ref => ref.group.name === group);
                                const groupWithCriteria = { name: group, isGroup: true, all: firstMatch.all, events: firstMatch.events || undefined };
                                if (firstMatch.event_criteria) {
                                    groupWithCriteria.event_criteria = firstMatch.event_criteria;
                                } else if (firstMatch.lot_criteria) {
                                    groupWithCriteria.lot_criteria = firstMatch.lot_criteria;
                                }
                                groupsWithCriteria.push(groupWithCriteria);
                            }
                            const selectionData = [...groupsWithCriteria, ...standaloneReferences];
                            createDocumentSelection({ document_template_id: template.id, name: savedSelectionName, field_reference_events: JSON.stringify(selectionData) }, () => {
                                Notifications.success(<FormattedMessage id='selectionSaved' />);
                                this.setState({ selectionSaved: true, saveSelectionModalLoading: false, saveSelectionModalOpen: false });
                            }, () => {
                                this.setState({ selectionSaved: false, saveSelectionModalLoading: false, saveSelectionModalOpen: false });
                            })
                        }
                    })

                }}
                confirmLabel={<FormattedMessage id='common.save' />}
                stayOpenOnConfirm={true}
                title={<FormattedMessage id='saveSelection' />}
                body={(
                    <Formik
                        initialValues={{ name: '' }}
                        onSubmit={values => this.setState({ savedSelectionName: values.name })}
                        render={formProps => (
                            <Form 
                                noValidate
                                onChange={async e => {
                                    await formProps.handleChange(e);
                                    formProps.submitForm();
                                }}
                            >
                                <div className="form-group">
                                    <Fields.Input type="text" name="name" className="form-control" showRequired={true} seleniumId="documentSaveSelection" />
                                </div>
                            </Form>
                        )}
                    />
                )}
            />
            <ConfirmModal
                open={this.state.overwriteSavedSelectionModal}
                setOpen={(o) => this.setState({ overwriteSavedSelectionModal: o })}
                onConfirm={() => {
                    const { savedSelectionName } = this.state;
                    const { createDocumentSelection, template } = this.props;
                    createDocumentSelection({ document_template_id: template.id, name: savedSelectionName, field_reference_events: JSON.stringify(this.state.fieldReferences) }, () => {
                        Notifications.success(<FormattedMessage id='selectionSaved' />);
                        this.setState({ selectionSaved: true, saveSelectionModalLoading: false, saveSelectionModalOpen: false });
                    }, () => {
                        this.setState({ selectionSaved: false, saveSelectionModalLoading: false, saveSelectionModalOpen: false });
                    });
                }}
                onCancel={() => this.setState({ saveSelectionModalLoading: false })}
                confirmLabel={<FormattedMessage id='common.confirm' />}
                stayOpenOnConfirm={false}
                title={<FormattedMessage id='common.confirm' />}
                body={<FormattedMessage id='overwriteSavedSelectionConfirm' />}
            />
            </>
        )
    }
    //#endregion

    //#region lifecycle
    async componentDidMount() {
        const { 
            match, 
            getDocumentTemplate,
            documentSelections = null,
            listDocumentSelections,
        } = this.props;


        const urlParams = new URLSearchParams(window.location.search);
        const fixDocumentHeight = urlParams.get('fixDocumentHeight');
        if (fixDocumentHeight !== 'true') {
            window.addEventListener('resize', () => this.contentRef.current && this.setState({ contentHeight: this.measureElement(this.contentRef.current) }) );
        }
        const manualResize = () => {
            const el = document;
            const event = document.createEvent('HTMLEvents');
            event.initEvent('resize', true, false);
            el.dispatchEvent(event);
        }
        setTimeout(manualResize, 1000);
        setTimeout(manualResize, 3000);
        setTimeout(manualResize, 6000);

        if (match && match.params && match.params.id) {
            this.setState({ builderLoading: true }, async () => {
                listDocumentSelections({ document_template_ids: [match.params.id] }, docSelections => {
                    this.setState({ savedDocumentSelections: docSelections });
                })
                getDocumentTemplate(match.params.id, async (template) => {
                    const parsedTemplate = JSON.parse(template.file_data);
                    let refsWithFieldData = [];
                    let saveSelectionDefault = false;
                    let fieldReferences = []
                    if (template.document_type === 'excel') {
                        fieldReferences = getExcelFieldReferences(parsedTemplate);
                    } else {
                        fieldReferences = getWordFieldReferences(parsedTemplate);
                    }
                    refsWithFieldData = await this.addMetadataToReferences(fieldReferences);
                    if (documentSelections != null && documentSelections.field_reference_events && documentSelections.field_reference_events.length > 0) {
                        saveSelectionDefault = true;
                        const savedRefs = JSON.parse(documentSelections.field_reference_events);
                        const savedGroups = savedRefs.filter(ref => ref.isGroup);
                        const oldAndNewRefs = [];
                        for (const ref of refsWithFieldData) {
                            const saved = savedRefs.find(sr => !sr.isGroup && sr.literal === ref.literal);
                            if (saved) {
                                oldAndNewRefs.push(saved);
                            } else {
                                if (ref.group && ref.group.name) {
                                    const matchingGroup = savedGroups.find(g => g.name === ref.group.name);
                                    if (matchingGroup) {
                                        ref.all = matchingGroup.all;
                                        ref.completed = true;
                                        if (matchingGroup.event_criteria) {
                                            ref.event_criteria = matchingGroup.event_criteria;
                                            ref.events = matchingGroup.events;
                                        } else if (matchingGroup.lot_criteria) {
                                            ref.lot_criteria = matchingGroup.lot_criteria;
                                            ref.events = matchingGroup.events;
                                        }
                                    }
                                }
                                oldAndNewRefs.push(ref);
                            }
                        }
                        refsWithFieldData = oldAndNewRefs;
                    }
                    const refsWithSelectMethod = refsWithFieldData.map(r => { r.onSelect = this.setSelectedFieldRef; r.onFieldCleared = this.clearFieldRef; return r; })
                    this.setState({ builderLoading : false, fieldReferences: refsWithSelectMethod, selectionSaved: saveSelectionDefault });
                });
            })
        }
    }
    //#endregion

    render() {
        const { parents, match, template } = this.props;
        if (!match || !match.params || !match.params.id || !template) return null;
        const { fieldReferences, selectedReference } = this.state;

        let titleElement = <FormattedMessage id="common.chooseEvents" />;
        if (fieldReferences.length === 0 || fieldReferences.every(f => f.completed)) {
            titleElement = <FormattedMessage id="common.generateDocument" />
        } else if (selectedReference && selectedReference.type === 'lot') {
            titleElement = <FormattedMessage id="common.chooseLots" />
        }

        let fieldsInGroups = [];
        let fieldsNotInGroups = [];
        if (fieldReferences && fieldReferences.length > 0) {
            fieldsInGroups = fieldReferences.filter(r => !!r.group && !!r.group.name);
            fieldsNotInGroups = fieldReferences.filter(r => !(!!r.group && !!r.group.name));
        }
        let groups = [];
        for (const currentField of fieldsInGroups) {
            if (groups.filter(g => g.name === currentField.group.name).length === 0) {
                const allFieldsInCurrentGroup = fieldsInGroups.filter(f => f.group.name === currentField.group.name);
                groups.push({ name: currentField.group.name, fields: allFieldsInCurrentGroup });
            }
        }

        let referenceList;
        if (fieldsInGroups.length === 0 && fieldsNotInGroups.length === 0) {
            referenceList = (
                <div className="pd-20 tx-center tx-12">
                    <FormattedMessage id="common.noFieldReferences" />
                </div>
            );
        } else {
            referenceList = (
                <div className="draggable-area" ref={el => (this.draggable = el)}>
                    { groups.map((r, i) => {
                        r.index = i;
                        const firstMatch = r.fields[0];
                        return <FieldReferenceListItem key={i} item={({ ...firstMatch, label: r.name })} />
                    })}
                    { fieldsNotInGroups.map((r, i) => {
                        r.index = i + groups.length;
                        return <FieldReferenceListItem key={i + groups.length} item={r} />
                    })}
                </div>
            )
        }
        
        return (
            <div ref={this.contentRef}>
                <Page {...{ ...DocumentBuilder.meta, title: template.title }} parents={parents} panel={false} noPadding={this.state.showExcel}>
                    { this.state.showExcel ?
                        <ExcelDocument 
                            fixedFieldRefs={this.state.fixedFieldRefs} 
                            templateFileData={this.state.templateFileData}
                            templateMetadata={this.state.templateMetadata}
                            manualHeight={this.state.contentHeight}
                        />
                        :
                        <LoadingOverlay
                            active={this.state.builderLoading}
                            spinner
                            text={<FormattedMessage id='common.loading'/>}
                        >
                            <div className="row pd-x-15">
                                <div className="col-4 col-lg-3 col-xl-2 pd-l-0 pd-r-5">
                                    <div className="fields-list widget-2 mg-b-20">
                                        <div className="card overflow-hidden">
                                            <div className="card-header" style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipses"}}>
                                                <h6 className="card-title">
                                                    <FormattedMessage id="common.fieldReferences" /> {fieldReferences ? `(${fieldReferences.length})` : null}
                                                </h6>
                                            </div>
                                            <div className="card-body pd-0 bd-color-gray-lighter">
                                                <div className="row no-gutters">
                                                    <div className="col-12">
                                                        <SimpleBar>
                                                            { referenceList }
                                                        </SimpleBar>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="hidden-lg-down col-xl-2 pd-x-5">
                                    <div className="fields-list widget-2 mg-b-20">
                                        <div className="card overflow-hidden">
                                            <div className="card-header" style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipses"}}>
                                                <h6 className="card-title">
                                                    <FormattedMessage id="common.referenceInfo" />
                                                </h6>
                                            </div>
                                            <div className="card-body pd-0 bd-color-gray-lighter">
                                                <div className="row no-gutters">
                                                    <div className="col-12">
                                                        <SimpleBar>
                                                            { this.fieldReferenceInfoEl() }
                                                        </SimpleBar>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-8 col-lg-9 col-xl-8 pd-l-5 pd-r-0">
                                    <div className="fields-preview widget-2 mg-b-20">
                                        <div className="card">
                                            <div className="card-header">
                                                <h6 className="card-title">
                                                    { titleElement }
                                                </h6>
                                            </div>
                                            <div className="card-body pd-0 bd-color-gray-lighter">
                                                <div className="row no-gutters">
                                                    <div className="col-12" style={{overflowX: 'hidden'}}>
                                                        {/* <div style={{overflowX: 'hidden', overflowY: 'auto', height: '100%'}}>
                                                            { this.entityChooserElement() }
                                                        </div> */}
                                                        <div 
                                                            style={{
                                                                position: 'relative',
                                                                flexDirection: 'column',
                                                                flexWrap: 'wrap',
                                                                justifyContent: 'flex-start',
                                                                alignContent: 'flex-start',
                                                                alignItems: 'flex-start'
                                                            }}
                                                        >
                                                            <div 
                                                                style={{height: 'calc(100vh - 180px)', overflowX: 'hidden', overflowY: 'auto'}}
                                                            >
                                                                { this.entityChooserElement() }
                                                            </div>
                                                        </div>
                                                        
                                                        {/* <SimpleBar style={{overflowX: 'hidden', overflowY: 'auto'}}>
                                                            { this.entityChooserElement() }
                                                        </SimpleBar> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </LoadingOverlay>
                    }
                    { this.saveSelectionModal() }
                </Page>
            </div>
        );
    }
}

DocumentBuilder.meta = {
    showTitleBlock: false,
    title: <FormattedMessage id="page.cargoCategories.new.title" />,
    subtitle: <FormattedMessage id="page.cargoCategories.new.subtitle" />,
    routes: ['/builder'],
    icon: 'ion-clipboard',
    menus: { main: false, user: false },
    order: 0,
    requires: ['model', 'read', 'update']
};

const mapStateToProps = state => ({
    entityPermissions: state.identity.entityPermissions,
    company: state.identity.company,
    model: state.fields.model,
    fieldTypes: state.fields.types,
    template: state.document.instance,
    indices: state.indices.list,
    models: state.models.list,
    documentSelections: state.document.documentSelections,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getDocumentTemplate,
            getIndices,
            getField,
            createTransactionDocument,
            filterTransactions,
            getModels,
            createDocumentSelection,
            getDocumentReferenceData,
            listDocumentSelections,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DocumentBuilder);
