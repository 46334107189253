import React from 'react';
import { FormattedMessage } from 'react-intl';
import { call, hasErrors } from '../configuration/api';
import Queries from './queries';
import { Notifications } from '../components/notifications';
import { USERS_GET_LIST, USERS_GET_ROLES, USERS_GET_PERMISSIONS } from './types';

const defaultState = {
    list: undefined,
    total: 0,
    roles: undefined,
    permissions: undefined
};

/**
 * Loading state mapper.
 * @param {object} state
 * @param {string} action
 */
export default function(state = defaultState, action) {
    switch (action.type) {
        case USERS_GET_LIST:
            const { total, data } = action.payload;
            return { ...state, list: data, total: Number.parseInt(total) };

        case USERS_GET_ROLES:
            return { ...state, roles: action.payload };

        case USERS_GET_PERMISSIONS:
            return { ...state, permissions: action.payload };

        default:
            return state;
    }
}

export const getUsers = (variables, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Users.List, variables });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.user_list) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    dispatch({ type: USERS_GET_LIST, payload: data.user_list });
    onComplete();
};

export const getUserRoles = (variables, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Users.GetRoles, variables });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.role_list) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    dispatch({ type: USERS_GET_ROLES, payload: data.role_list });
    onComplete(data.role_list);
};

export const inviteUser = (variables, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Users.Invite, variables });
    if (hasErrors(response)) {
        return;
    }

    const { data } = response;
    if (!data || !data.user_invite) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.user_invite);
};

export const getPermissions = (onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Users.GetPermissions });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.permission_list) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    dispatch({ type: USERS_GET_PERMISSIONS, payload: data.permission_list });
    onComplete(data.permission_list);
};

export const createUserRole = (variables, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Users.CreateRole, variables });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.role_create) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.role_create);
};

export const updateUserRole = (variables, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Users.UpdateRole, variables });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.role_update) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.role_update);
};

export const updateUser = (variables, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Users.Update, variables });
    if (hasErrors(response)) {
        onError(response.error);
        return;
    }

    const { data } = response;
    if (!data || !data.user_update) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.role_update);
};

export const assignPermissionsToRole = (variables, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Users.SetRolePermissions, variables });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.role_set_permission_list) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.role_set_permission_list);
};

export const deleteUser = (id, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Users.Delete, variables: { user_id: id } });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.user_delete) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete();
};

export const reenableUser = (id, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Users.Reenable, variables: { user_id: id } });
    if (hasErrors(response)) {
        onError(response.user_reenable);
        return;
    }

    const { data } = response;
    if (!data || !data.user_reenable) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete();
};
