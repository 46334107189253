import React from 'react';
import { FormattedMessage } from 'react-intl';
import thumb from '../../../assets/user.svg';
import { history } from '../../../configuration/history';

import './user.scss';

class UserMenuComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { user, links = [], logout } = this.props;
        if (!user) return null;

        const { name, avatar } = user;
        const url = avatar && avatar.thumb && avatar.thumb.url ? avatar.thumb.url : thumb;

        return (
            <nav>
                <div className="dropdown">
                    <span className="nav-link nav-link-profile" data-toggle="dropdown">
                        <span className="logged-name hidden-md-down">{name}</span>
                        <img src={url} className="wd-32 rounded-circle" alt={name} />
                        <span className="square-10 bg-success" />
                    </span>
                    <div className="dropdown-menu dropdown-menu-header wd-200">
                        <ul className="list-unstyled user-profile-nav">
                            {links.map(({ title, icon, routes }, i) => (
                                <li key={i}>
                                    <span className="user-menu-link" onClick={() => history.push(routes[0] + window.location.search)}>
                                        <i className={`icon ${icon}`} /> {title}
                                    </span>
                                </li>
                            ))}
                            <li>
                                <span className="user-menu-link" onClick={logout}>
                                    <i className="icon ion-power" /> <FormattedMessage id="logout" />
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
}

export default UserMenuComponent;
