import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import '../fields.scss';
import Tooltip from 'rc-tooltip';

class YesNoNull extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    getBoolean = value => {
        switch (value) {
            case true:
            case 'true':
            case 1:
            case '1':
            case 'on':
            case 'yes':
                return true;
            case null:
            case undefined:
            case '':
                return null;
            default:
                return false;
        }
    };

    render() {
        const {
            name,
            intl: { formatMessage },
            placeholder,
            disabled = false,
            value,
            onChange = () => null,
            tooltipOverlay,
            seleniumId,
        } = this.props;

        const parsedValue = this.getBoolean(value);

        const fieldEl = (
            <div className={`switch-toggle switch-3 switch-candy se-id-${seleniumId}`} style={{width: '8em'}}>
                <input id={name + "-on"} name={name + '-radio-group'} type="radio" disabled={disabled} checked={(parsedValue === true) ? 'checked' : ''} readOnly className={`se-id-${seleniumId}-yes`} />
                <label htmlFor={name + "-on"} className={disabled ? 'disabled' : ''} onClick={() => onChange(true)}><FormattedMessage id='common.yes'/></label>

                <input id={name + "-na"} name={name + '-radio-group'} type="radio" disabled={disabled} checked={(parsedValue === null) ? 'checked' : ''} readOnly className={`se-id-${seleniumId}-null`} />
                <label htmlFor={name + "-na"} className={disabled ? 'disabled' : ''} onClick={() => onChange('')}><FormattedMessage id='common.n/a'/></label>

                <input id={name + "-off"} name={name + '-radio-group'} type="radio" disabled={disabled} checked={(parsedValue === false) ? 'checked' : ''} readOnly className={`se-id-${seleniumId}-no`} />
                <label htmlFor={name + "-off"} className={disabled ? 'disabled' : ''} onClick={() => onChange(false)}><FormattedMessage id='common.no'/></label>

                <a href='/#'><span style={{display: 'none'}}>hidden link</span></a>
            </div>
        );
        
        const fieldPlaceholder = (showIsRequired, placeholder, value) =>{            
            if (showIsRequired  && (value === null || value === undefined || value === '')){
                return <div>
                <div className="boolean-placeholder validation-message" style={{marginLeft:'8px',marginBottom: '5px', marginTop: '10px'} } > <FormattedMessage id="validation.required" /></div>
                <div className="boolean-placeholder" style={{marginLeft:'8px',marginBottom:'5px', marginTop: '25px'} }> { placeholder || formatMessage({ id: `${name}.placeholder`, defaultMessage: 'New Field' }) } </div>
                </div>
            }
            else {                
                return <div className="boolean-placeholder" style={{marginLeft:'8px',marginBottom:'5px',marginTop:'9px'}}> { placeholder || formatMessage({ id: `${name}.placeholder`, defaultMessage: 'New Field' }) } </div>
            }            
        }

        return (
            <React.Fragment>
                <div className="boolean-wrapper" style={{marginTop: (this.props.showIsRequired  && (value === null || value === undefined || value === ''))? '1.8vw' : '0.7vw'  }}>
                    <Tooltip trigger={['hover']} overlay={<span>{placeholder || formatMessage({ id: `${name}.placeholder`, defaultMessage: 'New Field' })}</span>} mouseEnterDelay={2}>
                        { fieldPlaceholder(this.props.showIsRequired,placeholder,value) }
                    </Tooltip>
                    {
                        tooltipOverlay ?
                        <Tooltip
                            key={name}
                            placement={'top'}
                            trigger={['hover']}
                            overlay={tooltipOverlay}
                            children={ fieldEl }
                        />
                        :
                        fieldEl
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default injectIntl(YesNoNull);
