import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import List from './containers/collectionsList'
import CollectionContainer from './containers/collectionContainer'
import './collections.scss';

class CollectionsPages extends React.Component {
    render() {
        return (
            <Switch>
                <Route exact path="/collections" component={props => <List {...props} />} />
                <Route path="/collections/:id" component={props => <CollectionContainer {...props} parents={[List.meta]} />} />
                {/* <Route exact path="/models/indices" component={props => <ModelsIndices {...props} parents={[Models.meta]} />} /> */}
                {/* <Route exact path="/models/:modelId/document-templates" component={props => <DocumentConfigurationList {...props} parents={[Models.meta]} />} /> */}
                {/* <Route exact path="/models/:modelId/document-templates/:id" component={props => <DocumentConfiguration {...props} parents={[Models.meta]} />} /> */}
                {/* <Route path="/models/:id" component={props => <ModelNew {...props} parents={[Models.meta]} />} /> */}
            </Switch>
        );
    }
}

CollectionsPages.meta = {
    title: <FormattedMessage id="collections" defaultMessage="Collections" />,
    routes: ['/collections'],
    icon: 'ion-ios-folder-outline',
    menus: { main: true, user: false },
    order: 11,
    onlySubscribed: true
};

const mapStateToProps = state => ({
    user: state.identity.user,
    company: state.identity.company,
    permissions: state.identity.permissions
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CollectionsPages);
