import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import ReactModal from 'react-modal';
import Page from '../../../components/page';
import Table from '../../../components/table';
import { history } from '../../../configuration/history';
import { getUserRoles, getUsers } from '../../../reducers/users';
import thumb from '../../../assets/user.svg';
import UserInvite from './../components/forms/userInvite';
import { canAccessPage, DefinedPermissions } from '../../../components/routes/permissions/permissionResolver';

class CompanyUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: { open: false }
        };
    }

    componentDidMount() {
        const { company, roles, getUserRoles } = this.props;
        if (!roles) getUserRoles({ company_id: company.id });
    }

    setModalVisibility = open => {
        this.setState({ ...this.state, modal: { open } });
    };

    loadData = (options, onComplete) => {
        const { getUsers, company } = this.props;
        return getUsers({ company_ids: [company.id], status_ids: [1, 2, 3, 4, 5], ...options }, onComplete);
    };

    getColumns = () => {
        return [
            {
                headerClassName: 'd-sm-block d-none',
                Header: <FormattedMessage id="common.name" />,
                id: 'name',
                accessor: d => d.name,
                Cell: row => {
                    const { original, value } = row;
                    const avatar = original.avatar && original.avatar.thumb && original.avatar.thumb.url ? original.avatar.thumb.url : thumb;
                    return (
                        <div>
                            <span className="d-sm-block d-none">{value}</span>
                            <div className="d-sm-none d-xs-block mobile-cell">
                                <div className="row align-items-center">
                                    <div className="col-auto text-center pl-4 pr-0">
                                        <img src={avatar} className="wd-40 rounded-circle" alt={original.name} />
                                    </div>
                                    <div className="col-9">
                                        <div className="font-weight-bold ellipsis">{value}</div>
                                        <div className="ellipsis">{original.email}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }
            },
            {
                headerClassName: 'd-sm-block d-none',
                className: 'd-sm-block d-none',
                Header: <FormattedMessage id="common.email" />,
                id: 'email',
                accessor: d => d.email
            }
        ];
    };

    renderActions = () => {
        const { permissions } = this.props;
        const isSuperAdmin = canAccessPage(permissions, { access: { score: DefinedPermissions.SuperUser.score } });

        return (
            <div className="btn-group pd-t-10">
                <span className="btn btn-outline-info" onClick={() => this.setModalVisibility(true)}>
                    <FormattedMessage id="page.companies.details.actions.invite.user" />
                </span>
                {!!isSuperAdmin && (
                    <span className="btn btn-outline-info" onClick={() => history.push(`/settings/roles` + window.location.search)}>
                        <FormattedMessage id="page.companies.details.actions.setup.roles" />
                    </span>
                )}
            </div>
        );
    };

    render() {
        const { modal } = this.state;
        const { company, users = [], total = 0, roles, parents } = this.props;
        if (!company) return null;

        return (
            <React.Fragment>
                <Page {...CompanyUsers.meta} parents={parents} actions={this.renderActions()}>
                    <Table columns={this.getColumns()} data={users} total={total} dataLoader={(options, onComplete) => this.loadData(options, onComplete)} />
                </Page>
                <ReactModal
                    isOpen={modal.open}
                    onRequestClose={() => this.setModalVisibility(false)}
                    className="modal-block dialog"
                    overlayClassName="modal-overlay gray"
                >
                    <UserInvite companyId={company.id} roles={roles} onCancel={() => this.setModalVisibility(false)} />
                </ReactModal>
            </React.Fragment>
        );
    }
}

CompanyUsers.meta = {
    title: <FormattedMessage id="page.settings.users.title" />,
    subtitle: <FormattedMessage id="page.settings.users.subtitle" />,
    routes: ['/settings'],
    icon: 'ion-ios-briefcase-outline',
    menus: { main: false, user: false },
    order: 20
};

const mapStateToProps = state => {
    return {
        company: state.identity.company,
        roles: state.users.roles,
        users: state.users.list,
        total: state.users.total,
        permissions: state.identity.permissions
    };
};
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getUserRoles,
            getUsers
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanyUsers);
