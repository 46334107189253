export const LINKS_DELETE = `
    mutation M($link_id: Int!) {
        link_delete(link_id: $link_id) {
            id
            type_id
            type_name
            name
            created_on
            exp_on
            data
            clicks
            uses
            max_uses
            is_valid
        }
    }
`;
