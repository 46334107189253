import React from 'react';
import { call, hasErrors } from '../configuration/api';
import Queries from './queries';
import { Notifications } from '../components/notifications';
import { FormattedMessage } from 'react-intl';
import { NoFragmentCyclesRule } from 'graphql';

const defaultState = {
};

/**
 * Loading state mapper.
 * @param {object} state
 * @param {string} action
 */
export default function(state = defaultState, action) {
    switch (action.type) {

        default:
            return state;
    }
}

export const searchFaq = (variables, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Faq.Search, variables });
    if (hasErrors(response, false, false)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.faq_search) {
        onError();
        return;
    }

    onComplete(data.faq_search);
};
