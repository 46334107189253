export const DOCUMENT_TEMPLATE_CREATE_FIELD_REF_GROUP = `
    mutation M(
        $document_template_id: Long!
        $name: String!
    ) {
        document_template_create_ref_group(
            input: { 
                document_template_id: $document_template_id 
                name: $name 
            }
        ) {
            document_template_id
            name
        }
    }
`;
