import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ReactModal from 'react-modal';
import Table from './../../../components/table';
import { getProfile } from '../../../reducers/identity';
import { getIndices, createIndex, getIndexTypes, updateIndex, deleteIndex, getIndexStatuses } from '../../../reducers/indices';
import { Fields } from '../../../components/fields';
import { Button, Popover, Menu, MenuItem, MenuDivider, Icon } from '@blueprintjs/core';
import QueryBuilder from './queryBuilder';
import moment from 'moment';
import Tooltip from 'rc-tooltip';
import { setEventIndicesTableOptions, setCargoIndicesTableOptions } from 'reducers/sessionSettings';
import ConfirmModal from 'components/modals/confirm';

class ModelsIndicesTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deleteTargetMultiIds: [],
            tableRenderCount: 1,
        }
    }

    componentDidMount() {
        const { company, getProfile, indexTypes, getIndexTypes, statuses, getIndexStatuses } = this.props;
        if (!company) getProfile();
        if (!indexTypes) getIndexTypes();
        if (!statuses) getIndexStatuses();  
    }

    componentWillReceiveProps(props) {
        if (this.props.isDeletingMulti !== props.isDeletingMulti) {
            this.setState({ deleteTargetMultiIds: [] });
        }
    }

    loadData = (options, onComplete) => {
        const { getIndices, modelTypeId, setEventIndicesTableOptions = () => null, eventIndicesTableOptions, setCargoIndicesTableOptions = () => null, cargoIndicesTableOptions } = this.props;
        getIndices({ status_ids: [1, 2], ...options, model_type: modelTypeId }, (results) => {
            if (results === undefined) {
                onComplete([]);
                return;
            }
            
            if (results.total > 0 && results.data.length === 0) {
                // navigate to previous page and force table to re-render
                let newOptions;
                if (modelTypeId === 1) {
                    newOptions = Object.assign({}, eventIndicesTableOptions);
                    newOptions.page = newOptions.page - 1;
                    setEventIndicesTableOptions(newOptions).then(() => {
                        this.setState({ tableRenderCount: this.state.tableRenderCount + 1 });
                    })
                } else {
                    newOptions = Object.assign({}, cargoIndicesTableOptions);
                    newOptions.page = newOptions.page - 1;
                    setCargoIndicesTableOptions(newOptions).then(() => {
                        this.setState({ tableRenderCount: this.state.tableRenderCount + 1 });
                    })
                }
            } else {
                onComplete(results);
            }
        });
    };

    eventAllSelectorEl = () => {
        const { indices } = this.props;
        const { deleteTargetMultiIds } = this.state;
        const allEventsSelected = deleteTargetMultiIds.length === indices.length;
        if (this.props.isDeletingMulti) { // prop from parent component
            return ( 
                <div className="actions-wrapper" style={{height: '100%'}}>
                    <input 
                        type="checkbox" 
                        checked={allEventsSelected}  
                        onChange={() => this.setState(prevState => {
                            let allSelectedIds = prevState.deleteTargetMultiIds;
                            if (allSelectedIds.length === indices.length) {
                                return { deleteTargetMultiIds: [] };
                            } else {
                                return { deleteTargetMultiIds: indices.map(t => t.id) };
                            }
                        }, () => {this.props.setTagIdsToDelete(this.state.deleteTargetMultiIds)})} // new update parent state
                    />
                </div>
            );
        } else {
            return null;
        }
    }

    toggleDeleteTarget = (id) => {
        this.setState(prevState => {
            const targetIds = prevState.deleteTargetMultiIds;
            const index = targetIds.indexOf(id);
            if (index === -1) {
                targetIds.push(id);
            } else {
                targetIds.splice(index, 1);
            }
            return { deleteTargetMultiIds: targetIds };
        }, () => {this.props.setTagIdsToDelete(this.state.deleteTargetMultiIds)}) //new update parent state
    } 

    getColumns() {
        return [
            {
                headerClassName: 'd-sm-block col-2 d-none',
                Header: this.eventAllSelectorEl(),
                className: 'd-xs-block text-center col-2',
                sortable: false,
                resizable: false,
                width: 34,                                
                id: 'actions',
                accessor: d => {

                    return this.props.isDeletingMulti 
                    ?
                        <div className="actions-wrapper">
                            <input 
                                type="checkbox" 
                                checked={this.state.deleteTargetMultiIds && this.state.deleteTargetMultiIds.indexOf(d.id) > -1}                                  
                                onChange={() => this.toggleDeleteTarget(d.id)}                                
                            />
                        </div>
                    :
                        <div className="actions-wrapper">
                            <Popover
                                content={
                                    <Menu>
                                        <MenuItem                                            
                                            icon="edit"
                                            text={<FormattedMessage id="common.edit" />}
                                            onClick={() => {                                                
                                                this.props.setModalVisibility(true, d, 'edit');
                                            }}
                                        />
                                        <MenuItem
                                            icon="duplicate"
                                            text={<FormattedMessage id="common.clone" />}
                                            onClick={() => this.props.setModalVisibility(true, d, 'clone')}
                                        />
                                        <MenuDivider />
                                        <MenuItem
                                            icon="cross"
                                            text={<FormattedMessage id="common.delete" />}
                                            onClick={() => this.props.setModalVisibility(true, d, 'delete')}
                                        />
                                    </Menu>
                                }
                            >
                                <Button minimal={true} small={true} icon={<Icon iconSize={12} icon="menu" />} />
                            </Popover>
                        </div>
                }
            },
            {
                headerClassName: 'd-sm-block col d-none',
                className: 'd-sm-block col d-none tx-center id-column',
                Header: <FormattedMessage id="common.id" />,
                width: 100,
                id: 'id',
                accessor: d => (                                            
                        <a onClick={e => { e.stopPropagation(); e.preventDefault(); this.props.setModalVisibility(true, d, 'edit');}} href='#'>
                            { d.hash_id } 
                        </a>
                )
            },
            {
                headerClassName: 'd-sm-block d-none',
                className: 'd-xs-block',
                Header: <FormattedMessage id="common.title" />,
                id: 'title',
                accessor:  d => { 
                    return (d.description && d.description.length > 0) ?
                        <Tooltip placement={'top'} trigger={['hover']} overlay={d.description}>
                            { 
                                <a onClick={e => { e.stopPropagation(); e.preventDefault(); this.props.setModalVisibility(true, d, 'edit');}} href='#'>
                                    { d.title }
                                </a>
                            }
                        </Tooltip>
                        :
                        (                           
                            <a onClick={e => { e.stopPropagation(); e.preventDefault(); this.props.setModalVisibility(true, d, 'edit');}} href='#'>
                                { d.title }
                            </a>
                        )
                }
            },
            {
                headerClassName: 'd-sm-block col-sm-1 d-none',
                className: 'd-sm-block col-sm-1 tx-center d-none',
                Header: <FormattedMessage id="common.type" />,
                id: 'type_name',
                accessor: d => <FormattedMessage id={d.type_name} defaultMessage={(d.type_name === 'Cargo' ? 'DoT' : d.type_name)} />
            },
            {
                headerClassName: 'd-sm-block d-none col-1',
                className: 'd-xs-block col-1 tx-center',
                Header: <FormattedMessage id="common.status" />,
                id: 'status_id',
                accessor: d => <FormattedMessage id={d.status_name} defaultMessage={d.status_name} />
            },
            {
                headerClassName: 'd-sm-block d-none col-1',
                className: 'd-xs-block col-1 tx-center',
                Header: <FormattedMessage id="common.isInUse" />,
                sortable: false,
                id: 'is_used',
                accessor: d => (!!d.is_used ? <FormattedMessage id="common.yes" /> : <FormattedMessage id="common.no" />)
            },
            {
                headerClassName: 'd-block col-2',
                className: 'd-block col-2',
                Header: <FormattedMessage id="common.createdOn" />,
                id: 'created_on',
                accessor: d => moment(d.created_on).format('DD-MM-YYYY')
            }
        ];
    }

    render() {
        const {  company, indices, getIndices, total, createIndex, updateIndex, statuses, modal, modelTypeId } = this.props;
        const indexTypesList = this.props.indexTypes;
        if (!company || !indexTypesList || !statuses) return null;
        const omittedTypes = ['none', 'button', 'section'];
        if (modelTypeId === 2) { // cargo category 
            omittedTypes.push('cargo');
        }
        let newStatuses= statuses.filter(i => i.id !== 1);
        const indexTypes = indexTypesList.filter(i => !omittedTypes.includes(i.type_name.toLowerCase()));
        let initialValues = {};
        let isUsed= false;
        if (modal.open && ['add', 'edit', 'clone'].includes(modal.type)) {
            if (modal.type === 'edit') {
                initialValues = {
                    ...modal.data,
                    description: modal.data.description || '',
                    type: (indexTypes.find(i => i.id === modal.data.type_id) || {}).type_name,
                    status_id: (modal.data.status_id === 1 ? 2 : modal.data.status_id)
                };
                isUsed = initialValues.is_used;
            } else if (modal.type === 'add') {
                newStatuses= statuses.filter(i => i.id === 2);
                initialValues = { title: '', description: '', type: '', status_id: 2 }
            } else {        
                //not sure if it's preferable to set the status active even if it is archived or deleted ...
                //initialValues = { title: '', description: '', type: (indexTypes.find(i => i.id === modal.data.type_id) || {}).type_name, status_id: (modal.data.status_id === 1 ? 2 : modal.data.status_id) }
                initialValues = { title: '', description: '', type: (indexTypes.find(i => i.id === modal.data.type_id) || {}).type_name, status_id: 2 }
            }
        }

        return (
            <React.Fragment>
                {
                    modelTypeId === 1 ? // 1 = event templates / Dash Tags, 2 = cargo categories / DoT Tags                           
                        <Table
                            key={'tb-' + this.state.tableRenderCount}
                            columns={this.getColumns()}
                            queryBuilder={setFilters => <QueryBuilder onFiltersSet={setFilters} />}
                            data={indices}
                            total={total}
                            dataLoader={(options, onComplete) => {
                                this.loadData(options, onComplete);
                                this.setState({ options: options });
                            }}
                            optionsSetter={this.props.setEventIndicesTableOptions}
                            initialOptions={this.props.eventIndicesTableOptions}
                        />
                        :
                        <Table
                            key={'tb-' + this.state.tableRenderCount}
                            columns={this.getColumns()}
                            queryBuilder={setFilters => <QueryBuilder onFiltersSet={setFilters} />}
                            data={indices}
                            total={total}
                            dataLoader={(options, onComplete) => {
                                this.loadData(options, onComplete);
                                this.setState({ options: options });
                            }}
                            optionsSetter={this.props.setCargoIndicesTableOptions}
                            initialOptions={this.props.cargoIndicesTableOptions}
                        />
                }
                {modal.open && ['add', 'edit', 'clone'].includes(modal.type) && (
                    <ReactModal
                        isOpen={modal.open}
                        onRequestClose={() => this.props.setModalVisibility(false)}
                        className="modal-block dialog"
                        overlayClassName="modal-overlay gray"
                    >
                        <Formik
                            enableReinitialize={true}
                            initialValues={initialValues}
                            validationSchema={Yup.object().shape({
                                title: Yup.string().required(<FormattedMessage id="validation.required" />),
                                type: Yup.mixed().required(<FormattedMessage id="validation.required" />),
                                status_id: Yup.mixed().required(<FormattedMessage id="validation.required"/>)
                            })}
                            onSubmit={({ id, title, description, type, status_id }) => {
                                const action = modal.type === 'edit' ? updateIndex : createIndex;
                                const variables =
                                    modal.type === 'edit'
                                        ? { field_id: id, status_id, title, description, model_type: modelTypeId, type_id: (indexTypes.find(i => i.type_name === type) || {}).id }
                                        : { title, status_id, description, model_type: modelTypeId, type_id: (indexTypes.find(i => i.type_name === type) || {}).id };

                                action(variables, () => {
                                    this.loadData(this.state.options, () => null);
                                    this.props.setModalVisibility(false);
                                });
                            }}
                            render={({ values }) => {
                                return (
                                    <Form noValidate>
                                        <div className="modal-dialog wd-300" role="document">
                                            <div className="modal-content bd-0">
                                                <div className="modal-header pd-x-20">
                                                    <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                                                        <FormattedMessage id="models.configure.indices.new" />
                                                    </h6>
                                                </div>
                                                <div className="modal-body pd-20">
                                                    <div className="form-group">
                                                        <Fields.Input type="text" name="title" className="form-control" />
                                                    </div>
                                                    <div className="form-group">
                                                        <Fields.Select
                                                            name="type"
                                                            options={indexTypes.filter(i => i.type_name !== 'Cargo').map(({ type_name }) => ({ value: type_name, label: <FormattedMessage id={type_name} /> }))}
                                                            className="form-control"
                                                            value={values.type}
                                                            disabled={isUsed}                                                            
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <Fields.Select
                                                            name="status_id"
                                                            options={newStatuses.map(({ id, status_name }) => ({ value: id, label: <FormattedMessage id={status_name} /> }))}
                                                            className="form-control"
                                                            value={values.status_id}
                                                            disabled={isUsed}                                                            
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <Fields.Input type="text" name="description" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="modal-footer justify-content-center">
                                                    <button type="submit" className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium">
                                                        <FormattedMessage id="common.save" />
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                                        onClick={() => this.props.setModalVisibility(false)}
                                                    >
                                                        <FormattedMessage id="common.cancel" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                );
                            }}
                        />
                    </ReactModal>
                )}
                {modal.open && modal.type === 'delete' && (
                    <ReactModal
                        isOpen={modal.open}
                        onRequestClose={() => this.props.setModalVisibility(false)}
                        className="modal-block dialog"
                        overlayClassName="modal-overlay gray"
                    >
                        <div className="modal-dialog modal-sm" role="document">
                            <div className="modal-content bd-0">
                                <div className="modal-header pd-x-20">
                                    <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                                        <FormattedMessage id="common.confirm" />
                                    </h6>
                                </div>
                                <div className="modal-body pd-20">
                                    <p className="mg-b-5">
                                        <FormattedHTMLMessage
                                            tagName="span"
                                            id="page.models.configure.indices.confirmDelete"
                                            values={{ title: modal.data.title }}
                                        />
                                    </p>
                                </div>
                                <div className="modal-footer justify-content-center">
                                    <button
                                        type="button"
                                        className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                        onClick={() =>
                                            updateIndex({ ...modal.data, field_id: modal.data.id, status_id: 4 }, () => {
                                                this.loadData(this.state.options, () => null);
                                                this.props.setModalVisibility(false);
                                            })
                                        }
                                    >
                                        <FormattedMessage id="common.yes" />
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                        onClick={() => this.props.setModalVisibility(false)}
                                    >
                                        <FormattedMessage id="common.cancel" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ReactModal>
                )}

                <ConfirmModal
                    open={this.props.confirmMultiModalOpen}
                    setOpen={(o) => this.props.setConfirmMultiModalOpen(o) }
                    onConfirm={() => {
                        // delete selected tags
                        const promises = [];
                        for (const tagId of this.state.deleteTargetMultiIds) {
                            promises.push(new Promise(resolve => {
                                const tag = indices.find(t => t.id === tagId);
                                updateIndex({ ...tag, field_id: tag.id, status_id: 4 }, resolve)
                            }));
                        }
                        Promise.all(promises).then(() => {
                            this.loadData(this.state.options, () => null);
                            this.props.setTagIdsToDelete([]);
                            this.setState({ deleteTargetMultiIds: [] });
                            this.props.setConfirmMultiModalOpen(false);
                        });
                    }}
                    title={<FormattedMessage id='common.confirmVerb' values={{ verb: <FormattedMessage id='deleteSelected' /> }} />}
                    body={<FormattedMessage id='confirmDeleteMultipleTags' />}
                />

            </React.Fragment>
        );
    }
}

ModelsIndicesTable.defaultProps = {
    indices: [],
    total: 0
};

const mapStateToProps = state => ({
    company: state.identity.company,
    indexTypes: state.indices.types,
    indices: state.indices.list,
    statuses: state.indices.statuses,
    total: state.indices.total,
    eventIndicesTableOptions: state.sessionSettings.eventIndicesTableOptions,
    cargoIndicesTableOptions: state.sessionSettings.cargoIndicesTableOptions,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getProfile,
            getIndexTypes,
            getIndexStatuses,
            getIndices,
            createIndex,
            updateIndex,
            deleteIndex,
            setEventIndicesTableOptions,
            setCargoIndicesTableOptions,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ModelsIndicesTable);
