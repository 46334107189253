export const CARGO_UNITS_LIST = `
	query _ {
		cargo_unit_list {
			id
			short_name
			name
			display_name
			description
			unit_type
			is_public
			is_default
		}
  	}`