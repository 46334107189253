/**
 * Login with email and password.
 */
export const OTP_REQUEST_DISABLE = `
    mutation _ {
        mfa_disable_request {
            success
        }
    }
`;
