export const DOCUMENT_TEMPLATE_CLONE = `
    mutation M(
        $document_template_id: Long
    ) {
        document_template_clone (input: { document_template_id: $document_template_id }) {
            id
            hash_id
            model_id
            title
            description
            paper_size
            page_orientation
            header {
                ... on TextElement {
                    ref_id
                    title
                    content
                    bold
                    italic
                    underline
                    text_alignment
                    width_offset
                    width
                    offset
                    type
                    break_row
                    order
                }
                ... on ImageElement {
                    ref_id
                    title
                    url
                    width_offset
                    width
                    offset
                    type
                    s3_bucket
                    s3_object
                    break_row
                    order
                }
                ... on RichTextElement {
                    ref_id
                    title
                    json
                    text_alignment
                    width_offset
                    width
                    offset
                    type
                    break_row
                    order
                }
            }
            body {
                ... on TextElement {
                    ref_id
                    title
                    content
                    bold
                    italic
                    underline
                    text_alignment
                    width_offset
                    width
                    offset
                    type
                    break_row
                    order
                }
                ... on ImageElement {
                    ref_id
                    title
                    url
                    width_offset
                    width
                    offset
                    type
                    s3_bucket
                    s3_object
                    break_row
                    order
                }
                ... on RichTextElement {
                    ref_id
                    title
                    json
                    text_alignment
                    width_offset
                    width
                    offset
                    type
                    break_row
                    order
                }
            }
            footer {
                ... on TextElement {
                    ref_id
                    title
                    content
                    bold
                    italic
                    underline
                    text_alignment
                    width_offset
                    width
                    offset
                    type
                    break_row
                    order
                }
                ... on ImageElement {
                    ref_id
                    title
                    url
                    width_offset
                    width
                    offset
                    type
                    s3_bucket
                    s3_object
                    break_row
                    order
                }
                ... on RichTextElement {
                    ref_id
                    title
                    json
                    text_alignment
                    width_offset
                    width
                    offset
                    type
                    break_row
                    order
                }
            }
            version
    		file_data
    		server_file_data
    		document_type
            metadata
        }
    }
`;
