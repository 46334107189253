export const GET_ENTITY_GROUP_ISSUES = `
    query M ( 
        $id: Long
    )  {
        entity_group_issues (
            input: {
                id: $id
            }
        ) {
            dashes_with_missing_dots {
                dash_title
                dash_id
                dot_title
                dot_id
            }
            documents_with_missing_references {
                document_title
                document_id
                reference_type
                reference_id
                reference_title
            }
        }
    }
`;