import React from 'react';
import { FormattedMessage } from 'react-intl';
import { call, hasErrors } from '../configuration/api';
import { Notifications } from '../components/notifications';
import { TRANSACTIONS_GET_SINGLE, LOADING_START, LOADING_COMPLETE, TRANSACTIONS_PERSISTING } from './types';
import Queries from './queries';

const defaultState = {
    details: undefined,
    loading: 0
};

/**
 * Loading state mapper.
 * @param {object} state
 * @param {string} action
 */
export default function(state = defaultState, action) {
    switch (action.type) {
        case TRANSACTIONS_GET_SINGLE:
            return { ...state, details: action.payload };
        case TRANSACTIONS_PERSISTING:
            return { ...state, loading: state.loading + (action.data ? 1 : -1) };

        default:
            return state;
    }
}

export const getTransaction = (id, onComplete = () => null, doDispatch = true) => async dispatch => {
    const response = await call({ query: Queries.Transactions.Get, variables: { transaction_id: id } });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.transaction) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }
    if (doDispatch) {
        dispatch({ type: TRANSACTIONS_GET_SINGLE, payload: data.transaction });
    }
    onComplete(data.transaction);
};

export const getTransactionRelatedData = (id, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Transactions.GetRelatedData, variables: { transaction_id: id } });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.transaction_related_data) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }
    onComplete(data.transaction_related_data);
};

export const createTransaction = (modelId, transaction_type, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Transactions.Create, variables: { model_id: modelId, status_id: 2, transaction_type_name: transaction_type } });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.transaction_create) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.transaction_create);
};

export const updateTransaction = (variables, onComplete = () => null, onError = () => null) => async dispatch => {
    dispatch({ type: TRANSACTIONS_PERSISTING, data: true });
    const response = await call({ query: Queries.Transactions.Update, variables });
    dispatch({ type: TRANSACTIONS_PERSISTING, data: false });

    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.transaction_update) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.transaction_update);
};

export const createTransactionInvoice = (transaction_id, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Transactions.CreateInvoice, variables: { transaction_id } });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.invoice_create) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.invoice_create);
};

export const getTransactionInvoices = (transaction_id, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Transactions.GetInvoices, variables: { transaction_id } });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.invoice_list) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.invoice_list.data);
};

export const createTransactionDocument = (transaction_id, document_template_id, save_format, name, field_reference_events, onComplete = () => null, onError = () => null) => async dispatch => {
    dispatch({ type: LOADING_START, message: <FormattedMessage id="page.transactions.create.document.loading" /> });
    const response = await call({ query: Queries.Transactions.CreateDocument, variables: { transaction_id, document_template_id, save_format, name, field_reference_events } });
    setTimeout(() => dispatch({ type: LOADING_COMPLETE }), 500); // add artificial delay to end of loading UI so user can actually see

    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.document_create) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.document_create);
};

export const getTransactionDocuments = (transaction_ids, document_template_ids, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Transactions.GetDocuments, variables: { transaction_ids, document_template_ids } });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.document_list) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.document_list.data);
};

export const deleteTransactionDocument = (document_id, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Transactions.DeleteDocument, variables: { document_id } });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.document_delete) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.document_delete);
};

export const setTransactionFieldLots = (variables, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Transactions.SetFieldLots, variables });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.transaction_field_set_lots) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }

    onComplete(data.transaction_field_set_lots);
};

export const transactionFieldRevise = (variables, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Transactions.FieldRevise, variables });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.transaction_field_revise) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }

    onComplete(data.transaction_field_revise);
};

export const getTransactionDeleteSideEffects = (id, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Transactions.GetDeleteSideEffects, variables: { transaction_id: id } });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.transaction_get_delete_side_effects) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }
    onComplete(data.transaction_get_delete_side_effects);
};

export const getTransactionFieldSideEffectsDetails = (id, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Transactions.GetSideEffectsDetails, variables: { transaction_field_id: id } });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.transaction_field_get_side_effects_details) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }
    onComplete(data.transaction_field_get_side_effects_details);
};

