import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Fields } from '../../../../components/fields';
import { Notifications } from '../../../../components/notifications';
import { setupCompany, getCompanies, updateCompany } from '../../../../reducers/companies';
import countries from './../../../../assets/countries.json';
import { getProfile } from '../../../../reducers/identity';

class CompanyInfo extends React.Component {
    adjustValues = values => {
        const newValues = {};
        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                const property = values[key];
                if (property === undefined || property === '') {
                    newValues[key] = null;
                } else {
                    newValues[key] = property;
                }
            }
        }
        return newValues;
    };

    handleSubmit = formValues => {
        const values = this.adjustValues(formValues);
        const { onCancel, getProfile, updateCompany } = this.props;
        updateCompany({ ...values, id: undefined, company_id: values.id }, () => {
            Notifications.success(<FormattedMessage id="page.companies.company.updated" />);
            getProfile();
            onCancel();
        });
    };

    render() {
        const { company, onCancel } = this.props;
        return (
            <div className="modal-dialog wd-500-force" role="document">
                <div className="modal-content bd-0">
                    <div className="modal-header pd-x-20">
                        <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                            <FormattedMessage id="page.settings.companyDetails" />
                        </h6>
                    </div>
                    <Formik
                        initialValues={{
                            status_id: 2,
                            company_name: '',
                            description: '',
                            address_1: '',
                            address_2: '',
                            lang_id: 1,
                            website: '',
                            email: '',
                            country_id: '',
                            city: '',
                            state: '',
                            zip: '',
                            new_user_first_name: '',
                            new_user_last_name: '',
                            new_user_email: '',
                            new_user_phone: '',
                            ...company
                        }}
                        validationSchema={Yup.object().shape({
                            company_name: Yup.string().required(<FormattedMessage id="validation.required" />),
                            new_user_first_name: !!company ? undefined : Yup.string().required(<FormattedMessage id="validation.required" />),
                            new_user_last_name: !!company ? undefined : Yup.string().required(<FormattedMessage id="validation.required" />),
                            new_user_email: !!company
                                ? undefined
                                : Yup.string()
                                      .email(<FormattedMessage id="validation.invalid" />)
                                      .required(<FormattedMessage id="validation.required" />),
                            country_id: Yup.string().required(<FormattedMessage id="validation.required" />)
                        })}
                        onSubmit={this.handleSubmit}
                        render={({ values }) => (
                            <Form>
                                <React.Fragment>
                                    <div className="modal-body pd-0 widget-2 company-info-form">
                                        <div className="pd-x-10 pd-y-5">
                                            <div className="col-12 pd-t-20" />
                                            <div className="form-group col-12">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <Fields.Input name="company_name" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group col-12">
                                                <div className="row">
                                                    <div className="col-6 pd-r-0">
                                                        <Fields.Input name="website" />
                                                    </div>
                                                    <div className="col-6">
                                                        <Fields.Select
                                                            name="country_id"
                                                            value={values.country_id || ''}
                                                            options={countries.map(c => ({ value: c.id, label: `(${c.code}) ${c.title}` }))}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group col-12">
                                                <div className="row">
                                                    <div className="col-6 pd-r-0">
                                                        <Fields.Input name="address_1" />
                                                    </div>
                                                    <div className="col-6">
                                                        <Fields.Input name="address_2" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group col-12">
                                                <div className="row">
                                                    <div className="col-4 pd-r-0">
                                                        <Fields.Input name="city" />
                                                    </div>
                                                    <div className="col-4 pd-r-0">
                                                        <Fields.Input name="state" />
                                                    </div>
                                                    <div className="col-4">
                                                        <Fields.Input name="zip" />
                                                    </div>
                                                </div>
                                            </div>
                                            {!company && (
                                                <React.Fragment>
                                                    <div className="form-group col-12 pd-t-15">
                                                        <h6 className="tx-10 mg-b-0 tx-uppercase">
                                                            <FormattedMessage id="page.companies.actions.setup.adminUser" />
                                                        </h6>
                                                    </div>
                                                    <div className="form-group col-12">
                                                        <div className="row">
                                                            <div className="col-6 pd-r-0">
                                                                <Fields.Input name="new_user_first_name" />
                                                            </div>
                                                            <div className="col-6">
                                                                <Fields.Input name="new_user_last_name" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-12">
                                                        <Fields.Input name="new_user_email" />
                                                    </div>
                                                    <div className="form-group col-12">
                                                        <Fields.Phone name="new_user_phone" />
                                                    </div>
                                                </React.Fragment>
                                            )}
                                            <div className="col-12 pd-t-10" />
                                        </div>
                                    </div>
                                    <div className="modal-footer justify-content-center">
                                        <button type="submit" className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium">
                                            <FormattedMessage id="common.submit" />
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                            onClick={onCancel}
                                        >
                                            <FormattedMessage id="common.cancel" />
                                        </button>
                                    </div>
                                </React.Fragment>
                            </Form>
                        )}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getCompanies,
            setupCompany,
            updateCompany,
            getProfile
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanyInfo);
