export const IDENTITY_CHANGE_PASSWORD = `
    mutation M($current_password: String!, $new_password: String!, $reset_password: Boolean) {
        user_set_password(input: { current_password: $current_password, password: $new_password, reset_password: $reset_password }) {
            token {
                token
                data {
                    expires
                    expires_in
                    user_id
                    company_id
                    signin_user_id
                    signin_company_id
                }
            }

            user {
                id
                status_id
                status_name
                created_on
                modified_on
                email
                auth_type
                last_login_on
                login_attempts
                reset_password
                name

                requires_mfa
            }
        }
    }
`;
