export const INDICES_CREATE = `
    mutation M($order_by: Int, $type_id: Int!, $status_id: Int, $title: String!, $description: HtmlString, $extra_info: JsonString, $model_type: Int!) {
        index_field_create(
            input: { order_by: $order_by, type_id: $type_id, status_id: $status_id, title: $title, description: $description, extra_info: $extra_info, model_type: $model_type }
        ) {
            id
            hash_id
            order_by
            type_id
            type_name
            status_id
            status_name
            title
            description
            extra_info
            is_used
            model_type
            model_type_name
        }
    }
`;
