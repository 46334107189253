export const TRANSACTIONS_GET = `
    query _($transaction_id: Id!) {
        transaction(transaction_id: $transaction_id) {
            id
            status_id
            status_name
            model_id
            company_id
            user_id
            created_on
            source_cargo_id
            result_cargo_id
            title
            description
            notes
            bc_transaction_id
            extra_info
    		
    		transaction_type
    		transaction_type_name

            model {
                id
                title
                company_id
                description
                
                model_field_list {
                    id
                    model_id
                    order
                    type_id
                    type_name
                    status_id
                    status_name
                    index_field_id
                    input_id
                    visibility_check
                    title
                    description
                    is_required
                    validation
                    width
                    force_break
                    extra_info
                    company_id: model_company_id
                    cargoCategory: cargo_category
                    cargo_type
                    cargo_reference_type
                    transform_cargo_type
                    is_static_unit
                    default_unit

                    map_dash_field
                    map_dot_field
                    map_special_dot_field
                    map_compound_id
    
                    model_field_value_list {
                        id
                        company_id
                        field_id
                        order_by
                        is_hidden
                        value
                    }
    
                    index_field {
                        id
                        order_by
                        type_id
                        type_name
                        status_id
                        status_name
                        title
                        description
                        extra_info
                    }
                }
            }

            history {
                id
                created_on
                accessed_on
                processed_on
                locked_on
                locked_by
                company_id
                user_id
                status_id
                status_name
                type_id
                type_name
                operation_id
                operation_name
                object_id
                bc_batch_id
                bc_block_id
                bc_transaction_id
            }

            transaction_field_list {
                id
                transaction_id
                field_id
                value
                company_id: model_company_id
                order_by: field_order_by
                lot_id
                mapped_lot_id
                quantity
                cargo_unit_id
                in_revision
                can_unwind
                input_lots {
                    lot_id
                    quantity
                }
                output_lots {
                    lot_type
                    quantity
                    cargo_unit_id
                }
                use_mapped_value
                use_mapped_quantity_value
                use_mapped_unit_value
                restore_value
                restore_unit_value
                restore_quantity_value
                created_dot_all_consumed
                revised_remainder_quantity
                allow_user_transform
                use_user_transform
            }
        }
    }
`;
