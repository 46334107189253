import React from 'react';
import { RowBreak, FieldDescription } from './common';
import { Fields } from '../../../../components/fieldsControlled';

class YesNoNullField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { id, width, breakRow, title, submitOnChange, disabled, prependEls = [], onChangeExt, tooltipOverlay, appendEls = [], seleniumId, setFieldValue, value, initialValue, rerenderOnChange } = this.props;
        const widthClass = width ? `-${width}` : '';

        return (
            <React.Fragment>
                <div className={`pd-b-15 col${widthClass}`} style={{ display: 'inline-block' }}>
                    {[...prependEls]}
                    <Fields.YesNoNull 
                        placeholder={title} 
                        submitOnChange={submitOnChange} 
                        name={id} 
                        disabled={disabled}
                        onChangeExt={onChangeExt}
                        tooltipOverlay={tooltipOverlay}
                        seleniumId={seleniumId}
                        setFieldValue={setFieldValue}
                        initialValue={initialValue}
                        rerenderOnChange={rerenderOnChange}
                        showIsRequired={this.props.showIsRequired}
                    />
                    <span style={{ marginLeft: '1em'}}>
                        {[...appendEls]}
                    </span>
                    <FieldDescription 
                        {...this.props} 
                        seleniumId={seleniumId}
                        display='inline-block'
                    />
                </div>
                {breakRow && <RowBreak width={width} />}
            </React.Fragment>
        );
    }
}

export default YesNoNullField;
