import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';
import { login, resetPassword, inviteSetPassword } from 'reducers/identity';
import { Fields } from 'components/fields';
import { Notifications } from 'components/notifications';
import { history } from 'configuration/history';

class SetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: undefined
        };
    }

    setError = error => this.setState({ ...this.state, error });

    handleSubmit = async ({ password }, { setSubmitting }) => {
        const { resetPassword, inviteSetPassword, location } = this.props;
        const { linkData, invite = false } = location.state || {};
        const { link_token, name } = linkData || {};

        const onSuccess = () => {
            Notifications.success(<FormattedMessage id="page.account.reset.password.changed" />);
            history.push('/login' + window.location.search);
        };
        const onError = errorResponse => {
            this.setError(errorResponse);
            setSubmitting(false);
        };

        const action = !!invite
            ? () => inviteSetPassword({ link_code: name, password, token_from_link: link_token }, onSuccess, onError)
            : () => resetPassword({ password, token_from_link: link_token }, onSuccess, onError);

        this.setState({ ...this.state, error: undefined }, action);
    };

    render() {
        return (
            <Formik
                initialValues={{ password: '', password_retype: '' }}
                validationSchema={Yup.object().shape({
                    password: Yup.string()
                        .matches(/^[^\r\n<>@#$%&\^\*{{}}/\+=~\\`]*$/, <FormattedMessage id="invalidCharactersPassword" />)
                        .matches(/[^a-zA-Z\s]+/, <FormattedMessage id="passwordMustContainNumber" />)
                        .min(8, <FormattedMessage id="validation.length.min" values={{ length: 8 }} />)
                        .required(<FormattedMessage id="validation.required" />),
                    password_retype: Yup.string()
                        .oneOf([Yup.ref('password'), null], 'Passwords must match.')
                        .required(<FormattedMessage id="validation.required" />)
                })}
                onSubmit={this.handleSubmit}
                render={({ isSubmitting }) => (
                    <Form>
                        <div className="form-group">
                            <FormattedMessage id="page.account.reset.title" tagName="span" />
                        </div>
                        <div className="form-group">
                            <Fields.Input type="password" name="password" className="form-control" showError={true} />
                        </div>
                        <div className="form-group">
                            <Fields.Input type="password" name="password_retype" className="form-control" showError={true} />
                        </div>
                        <button type="submit" className="btn btn-primary btn-block mg-t-25" disabled={isSubmitting}>
                            <FormattedMessage id="common.submit" />
                        </button>
                    </Form>
                )}
            />
        );
    }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            login,
            resetPassword,
            inviteSetPassword
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SetPassword);
