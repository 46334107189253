export const MODELS_IMPORT = `
    mutation M($model_id: Int, $title: String, $json_data: JsonString) {
        model_import(model_id: $model_id, input: { title: $title, json_data: $json_data }) {
            id
            hash_id
            company_id
            status_id
            status_name
            created_on
            title
            description
            is_used
            split_conditions
            extra_info
            model_type
            model_type_name
        }
    }
`;
