import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Notifications } from '../../../../components/notifications';
import { deleteCompany, getCompanies } from '../../../../reducers/companies';

class CompanyDeleteConfirm extends React.Component {

    render() {
        const { company, getCompanies, deleteCompany, onCancel = () => null } = this.props;
        return (
            <div className="modal-dialog wd-500-force" role="document">
                <div className="modal-content bd-0">
                    <div className="modal-header pd-x-20">
                        <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                            <FormattedMessage id="page.companies.actions.delete" />
                        </h6>
                    </div>
                    <div className="modal-body pd-20">
                        <p className="mg-b-5">
                            <FormattedHTMLMessage
                                tagName="span"
                                id="page.companies.actions.delete.confirm"
                                values={{ title: company ? company.company_name : null }}
                            />
                        </p>
                    </div>
                    <div className="modal-footer justify-content-center">
                        <button
                            type="button"
                            className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                            onClick={() => {
                                deleteCompany({ company_id: company.id }, () => {
                                        Notifications.success(<FormattedMessage id="page.companies.company.deleted" />);
                                        getCompanies();
                                    },
                                    (err) => {
                                        Notifications.error(err);
                                    }
                                );
                                onCancel();
                            }}
                        >
                            <FormattedMessage id="common.yes" />
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                            onClick={onCancel}
                        >
                            <FormattedMessage id="common.cancel"  />
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            deleteCompany,
            getCompanies
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanyDeleteConfirm);
