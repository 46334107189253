export const CREATE_DOCUMENT = `
    mutation M($transaction_id: Id, $document_template_id: Long!, $save_format: String! $name: String!, $field_reference_events: JsonString!) {
        document_create(input: { transaction_id: $transaction_id, document_template_id: $document_template_id, save_format: $save_format, name: $name, field_reference_events: $field_reference_events }) {
            id
            name
            url
            s3_bucket
            s3_object
            field_references_with_values
        }
    }
`;
