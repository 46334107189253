import React from 'react';

class StyleButton extends React.Component {
    constructor() {
        super();
        this.onToggle = e => {
            e.preventDefault();
            this.props.onToggle(this.props.style);
        };
    }
    render() {
        let className = 'RichEditor-styleButton';
        if (this.props.active) {
            className += ' RichEditor-activeButton';
        }
        return (
            <span className={className} onMouseDown={this.onToggle}>
                {this.props.label}
            </span>
        );
    }
}

const BLOCK_TYPES = [
    { label: 'H1', style: 'header-one' },
    { label: 'H2', style: 'header-two' },
    { label: 'H3', style: 'header-three' },
    { label: 'H4', style: 'header-four' },
    { label: 'Blockquote', style: 'blockquote' },
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' },
    { label: 'Code Block', style: 'code-block' }
];

var INLINE_STYLES = [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' },
    { label: 'Monospace', style: 'CODE' }
];

export const getStyleButtons = (editorState, toggleBlockType, toggleInlineStyle, form, hiddenControls = null, extraControls = null) => {
    const selection = editorState.getSelection();
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();
    const currentStyle = editorState.getCurrentInlineStyle();

    return (
        <div className="RichEditor-controls">
            <div>
                { BLOCK_TYPES.map(type => (
                    (hiddenControls && hiddenControls.includes(type.label)) ? 
                    <div key={type.style}/>
                    :
                    <StyleButton
                        key={type.style}
                        active={type.style === blockType}
                        label={type.label}
                        onToggle={b => toggleBlockType(b, form)}
                        style={type.style}
                    />
                ))}
                <div />
                { extraControls ? extraControls : <div key={'no-extra-controls'}/> }
                { INLINE_STYLES.map(type => (
                    (hiddenControls && hiddenControls.includes(type.label)) ? 
                        <div key={type.style}/> 
                        :
                        <StyleButton
                            key={type.style}
                            active={currentStyle.has(type.style)}
                            label={type.label}
                            onToggle={i => toggleInlineStyle(i, form)}
                            style={type.style}
                        />
                ))}
            </div>
        </div>
    );
};
