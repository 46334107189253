export const ERROR_REPORT_CREATE = `
mutation M(
    $error_data: String!
) {
    error_reporting_create(
        input: {
            error_data: $error_data
        }
    ) {
        success
    }
}`;