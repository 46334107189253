import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { getModels } from 'reducers/models';
import Select from 'react-select';
import ReactModal from 'react-modal';
import { listLots } from 'reducers/lots';

import 'pages/models/models.scss';
import FadeIn from 'react-fade-in';
import { tooltipWrapper } from 'utilities/common';
import LotsTable from 'pages/lots/components/lotsTable';
import { loadModel } from 'reducers/models';

class FieldReferenceLotSelector extends React.Component {
    constructor(props) {
        super(props);
        const {template, fieldRef} = props;
        // default to multiple if available
        const isMultiAvailable = template.document_type === 'excel' 
        || (fieldRef.token.endsWith('.l1'))

        this.state = {
            columnVisibility: undefined,
            isMulti: isMultiAvailable,
            selectedLotIds: [],
            initialFieldRefModels: [],
        };
    }

    componentDidMount() {
        const { fieldRef, loadModel } = this.props;
        if (fieldRef.type === 'lot') {
            const modelPromises = [];
            fieldRef.lot_criteria.cargo_categories.forEach(id => {
                modelPromises.push(new Promise((resolve, reject) => loadModel(id, resolve, reject)));
            })
            Promise.all(modelPromises).then(models => {
                this.setState(prevState => {
                    return { initialFieldRefModels: prevState.initialFieldRefModels.concat(models) }
                })
            })
        }
    }

    isMultiAvailable = () => {
        const {template, fieldRef} = this.props;
        return template.document_type === 'excel' 
        || (fieldRef.type === 'lot' && fieldRef.field.type_name === 'Number')
        || (fieldRef.token.endsWith('.l1'))
    }

    loadData = (options, onComplete) => {
        const { listLots, fieldRef } = this.props;
        
        return listLots(
            {
                cargo_categories: [fieldRef.field.id],
                ...options
            },
            onComplete
        );
    };

    setColumns = selectedColumns => {
        this.setState({ columnVisibility: selectedColumns });
    };

    getPrependColumns = () => {
        const columns = [
            {
                isDefault: true,
                headerClassName: 'd-sm-block col-2 d-none',
                className: 'd-sm-block text-center col-2',
                sortable: false,
                resizable: false,
                Header: () => this.eventAllSelectorEl(),
                width: 34,
                id: 'actions',
                accessor: d => (this.eventSelectorEl(d.id))
            },
        ];

        return columns;
    };

    setLotIdSelected = (id) => {
        this.setState(prevState => {
            let allSelectedIds = prevState.selectedLotIds;
            if (allSelectedIds.includes(id)) {
                allSelectedIds.splice(allSelectedIds.indexOf(id), 1);
            } else {
                allSelectedIds.push(id);
            }
            return { selectedLotIds: allSelectedIds };
        })
    }

    selectLotIdMultiOrSingle = (id) => {
        const { isMulti } = this.state;
        if (isMulti) {
            this.setLotIdSelected(id);
        } else {
            this.setState({ selectedLotIds: [id] })
        }
    }

    eventAllSelectorEl = () => {
        const { lots } = this.props;
        const { isMulti } = this.state;
        const selectedIds = this.state.selectedLotIds;
        const allLotsSelected = selectedIds.length === lots.length;
        if (this.isMultiAvailable() && isMulti) {
            return ( 
                <div className="actions-wrapper" style={{height: '100%'}}>
                    <input 
                        type="checkbox" 
                        checked={allLotsSelected}  
                        onChange={() => this.setState(prevState => {
                            let allSelectedIds = prevState.selectedLotIds;
                            if (allSelectedIds.length === lots.length) {
                                return { selectedLotIds: [] };
                            } else {
                                return { selectedLotIds: lots.map(t => t.id) };
                            }
                        })}
                    />
                </div>
            );
        } else {
            return null;
        }
    }
    
    eventSelectorEl = (id) => {
        const { isMulti } = this.state;
        const selectedIds = this.state.selectedLotIds;
        const isLotSelected = selectedIds && selectedIds.includes(id);
        if (isMulti) {
            return ( 
                <div className="actions-wrapper">
                    <input 
                        type="checkbox" 
                        checked={isLotSelected}  
                        onChange={() => this.setLotIdSelected(id)}
                    />
                </div>
            );
        } else {
            return ( 
                <div className="actions-wrapper">
                    <div className="form-check">
                        <input
                            type="radio"
                            name="event-select"
                            checked={isLotSelected}
                            className="form-check-input"
                            onChange={() => this.setState({ selectedLotIds: [id] })}
                        />
                    </div>
                </div>
            );
        }
    }

    render() {
        const { lots, total, template, fieldRef, onFieldSearchCriteriaSet = () => null } = this.props;
        const { isMulti } = this.state;

        if (!lots) return null;

        const fieldModels = [];
        if (fieldRef.type === 'lot') {
            if (this.state.initialFieldRefModels && this.state.initialFieldRefModels.length > 0) {
                this.state.initialFieldRefModels.forEach(model => fieldModels.push(model));
            } else {
                fieldModels.push(fieldRef.field);
            }
        }
        const isMultiAvailable = this.isMultiAvailable();

        return (
            <div className="form-group row" style={{overflowX: 'hidden'}}>
                <div className='col-12'>
                    <div className='pd-x-15'>
                        {
                            tooltipWrapper(
                                !isMultiAvailable,
                                <FormattedMessage id="common.multiOnlyAvailableMsg"/>,
                                <div id="multi-picker" className={'row '}>
                                    <div className="col-6 pd-x-20">
                                        <span  style={{float:'left'}}>
                                            <FormattedMessage className='pd-x-20' id="common.singleOrMultipleLots" />
                                        </span>
                                        {
                                            tooltipWrapper(
                                                isMultiAvailable,
                                                template.document_type === 'excel' ? <FormattedMessage id="common.multiExcelMsg" /> : <FormattedMessage id="common.multiWordMsgLots" />,
                                                <span style={{float:'left', marginLeft: '10px'}}>
                                                    <i className="material-icons-outlined">info</i>
                                                </span>
                                            )
                                        }
                                    </div>
                                    <div className="col-3 pd-x-20">
                                        <label>
                                            <input
                                                type="radio"
                                                name="is-multi"
                                                checked={!this.state.isMulti}
                                                className="form-check-input"
                                                onChange={() => this.setState({ isMulti: false, selectedLotIds: [] })}
                                                disabled={!isMultiAvailable}
                                            />
                                            <FormattedMessage className='pd-x-20' id="common.single" />
                                        </label>
                                    </div>
                                    <div className="col-3 pd-x-20">
                                        <label>
                                            <input
                                                type="radio"
                                                name="is-multi"
                                                checked={this.state.isMulti}
                                                className="form-check-input"
                                                onChange={() => {
                                                    this.setState({ isMulti: true, selectedLotIds: [] });
                                                }}
                                                disabled={!isMultiAvailable}
                                            />
                                            <FormattedMessage className='pd-x-20' id="common.multiple" />
                                        </label>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className='col-12'>
                    <hr/>
                </div>
                <div className='col-12'>
                    <div className='pd-x-15 pd-y-15'>
                        <LotsTable 
                            key={fieldRef.literal}
                            prependColumns={this.getPrependColumns()}
                            initialCargoCategories={[fieldRef.field.id]}
                            onIdSelected={(id) => this.selectLotIdMultiOrSingle(id)}
                            onFiltersSet={(filters) => {
                                onFieldSearchCriteriaSet(filters);
                            }}
                            persistState={false}
                            externalModels={fieldModels}
                        />
                    </div>
                </div>
                {
                    this.state.isMulti &&
                        <div className='col-10 offset-1 pd-y-20'>
                            <FadeIn>
                                <Select
                                    placeholder={<FormattedMessage id='common.selectedLots'/>}
                                    value={this.state.selectedLotIds.map(id => ({label: lots.find(l => l.id === id).hash_id, value: id}))}
                                    options={this.state.selectedLotIds.map(id => ({label: lots.find(l => l.id === id).hash_id, value: id}))}
                                    isMulti={true}
                                    isClearable={true}
                                    isSearchable={false}
                                    onChange={(vals) => this.setState({ selectedLotIds: vals.map(v => v.value)})}
                                    components={{ 
                                        IndicatorSeparator: () => null, 
                                        DropdownIndicator: () => null, 
                                        Menu: () => null,
                                    }}
                                    theme={theme => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#eeeeee',
                                            primary: '#00336B'
                                        }
                                    })}
                                />
                            </FadeIn>
                        </div>
                }
                <div className='row col-12'>
                    <div className='col-xl-4 offset-xl-4 col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-xs-10 offset-xs-1'>
                        <button
                            type="button"
                            className="btn btn-primary btn-block tx-11 tx-uppercase pd-y-12 tx-mont tx-medium"
                            onClick={() => {
                                if (this.state.selectedLotIds.length === 0) {
                                    this.setState({ confirmModalOpen: true })
                                } else if (this.props.onFieldEventsConfirmed) {
                                    const { selectedLotIds } = this.state;
                                    this.props.onFieldEventsConfirmed(selectedLotIds);
                                }
                            }}
                        >
                            <FormattedMessage id="common.useSelectedLotsForField" />
                        </button>
                    </div>
                </div>
                {
                    this.state.isMulti &&
                    <div className='row col-12'>
                        <div className='col-xl-4 offset-xl-4 col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-xs-10 offset-xs-1 pd-y-10'>
                            <button
                                type="button"
                                className="btn btn-primary btn-block tx-11 tx-uppercase pd-y-12 tx-mont tx-medium"
                                onClick={() => {
                                    if (this.props.onFieldLotsConfirmedAll) {
                                        this.props.onFieldLotsConfirmedAll();
                                    }
                                }} 
                            >
                                <FormattedMessage id="common.useAllLotsForField" values={{count: total}} />
                            </button>
                        </div>
                    </div>
                }
                {
                    !this.state.isMulti && this.state.selectedLotIds.length > 0 &&
                        <div className='row col-12'>
                            <div className='col-xl-4 offset-xl-4 col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-xs-10 offset-xs-1 pd-y-10'>
                                <FadeIn>
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-block tx-11 tx-uppercase pd-y-12 tx-mont tx-medium"
                                        onClick={() => this.setState({ selectedLotIds: [] })} 
                                    >
                                        <FormattedMessage id="common.clearSelectedLot" />
                                    </button>
                                </FadeIn>
                            </div>
                        </div>
                }
                <ReactModal
                    isOpen={this.state.confirmModalOpen}
                    onRequestClose={() => this.setState({ confirmModalOpen: false })}
                    className="modal-block dialog"
                    overlayClassName="modal-overlay gray"
                >
                    <div className="modal-dialog modal-lg wd-450" role="document">
                        <div className="modal-content bd-0">
                            <div className="modal-header pd-x-20">
                                <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                                    <FormattedMessage id="common.confirmFieldReferenceEvents" />
                                </h6>
                            </div>
                            <div className="modal-body pd-20">
                                <FormattedMessage id="common.confirmFieldReferenceLotsMsgNone" />
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button
                                    type="button"
                                    className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                    onClick={() => {
                                        if (this.props.onFieldEventsConfirmed) {
                                            const { selectedLotIds } = this.state;
                                            this.props.onFieldEventsConfirmed(selectedLotIds);
                                        }
                                        this.setState({ confirmModalOpen: false })
                                    }}
                                >
                                    <FormattedMessage id="common.yes" />
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                    onClick={() => this.setState({ confirmModalOpen: false })}
                                >
                                    <FormattedMessage id="common.cancel"  />
                                </button>
                            </div>
                        </div>
                    </div>
                </ReactModal>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    models: state.models.list,
    lots: state.lots.list,
    total: state.lots.total,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getModels,
            listLots,
            loadModel,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FieldReferenceLotSelector);
