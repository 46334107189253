import React from 'react';
import { call, hasErrors } from '../configuration/api';
import Queries from './queries';
import { FormattedMessage } from 'react-intl';
import { Document, DocumentSections } from './../models/document';
import { Notifications } from '../components/notifications';
import { DOCUMENT_GET, DOCUMENT_UPDATED, DOCUMENT_SELECT_ITEM, DOCUMENT_SET_TEMPLATE, DOCUMENT_SET_ITEMS, DOCUMENT_TEMPLATE_CLONE } from './types';
import { compareDocumentTemplates } from '../utilities/common';

const SET_DOCUMENT_SELECTION = 'SET_DOCUMENT_SELECTION';

const defaultState = {
    serverVersion: undefined,
    instance: undefined,
    templateChanged: false,
    selected: { section: DocumentSections.HEADER, item: undefined },
    documentSelections: null,
};

/**
 * Document state mapper.
 * @param {object} state
 * @param {string} action
 */
export default function(state = defaultState, action) {
    switch (action.type) {
        case DOCUMENT_GET:
            const dgTemplate = new Document(action.data);
            return { ...state, instance: dgTemplate, serverVersion: state.serverVersion || dgTemplate, templateChanged: false };

        case DOCUMENT_UPDATED:
            const duTemplate = new Document(action.data);
            return { ...state, serverVersion: duTemplate, templateChanged: !compareDocumentTemplates(duTemplate, state.serverVersion) };

        case DOCUMENT_SELECT_ITEM:
            return { ...state, selected: { section: action.section, item: action.item } };

        case DOCUMENT_SET_TEMPLATE:
            let docProps = {title: action.title, description: action.description, paper_size: action.paper_size, page_orientation: action.page_orientation};
            return { ...state, instance: { ...state.instance, title: action.title, description: action.description, paper_size: action.paper_size, page_orientation: action.page_orientation }, templateChanged: !compareDocumentTemplates(docProps, state.serverVersion) };

        case DOCUMENT_SET_ITEMS:
            const siInstance = { ...state.instance };
            siInstance[action.section] = action.items;
            return { ...state, instance: siInstance, templateChanged: true };

        case SET_DOCUMENT_SELECTION:
            return { ...state, documentSelections: action.data };

        default:
            return state;
    }
}

export const selectItem = (section, item, onComplete = () => null) => async dispatch => {
    dispatch({ type: DOCUMENT_SELECT_ITEM, section, item });
    onComplete();
};

export const setTemplate = (title, description, paper_size, page_orientation, onComplete = () => null) => async dispatch => {
    dispatch({ type: DOCUMENT_SET_TEMPLATE, title, description, paper_size, page_orientation });
    onComplete();
};

export const setItems = (section, items, onComplete = () => null) => async dispatch => {
    dispatch({ type: DOCUMENT_SET_ITEMS, section, items });
    onComplete();
};

const DocumentCache='DocumentCache';

const putCacheData = (item, data) => {
    if ('caches' in window) {
        caches.open(DocumentCache).then(cache => {
            cache.put(new Request(item), new Response(data));
        })
    }
}

const getCache =  async (id) => {
    let result= null;
    if ('caches' in window) {
        const cacheStorage = await caches.open(DocumentCache);
        const cachedResponse = await cacheStorage.match(`documents?id=${id}`);
        if(cachedResponse){
            result= await cachedResponse.json();
        }
    }
    return result;
}

const updateChecksum = async (id, cache_document) => {
    const response = await call({ query: Queries.Documents.CheckSum, variables: { document_template_id: id } });
    const { data } = response;

    const checksum= data.document_template_checksum.checksum;
    
    var temp_doc, type;        
    if ( cache_document.document_type === 'excel' ){
        type= 'excel';
        temp_doc= Buffer.from(JSON.stringify(cache_document,'utf-8')).toString('base64');
    } else {        
        type= 'word';
        temp_doc= Buffer.from(JSON.stringify(cache_document),'ascii').toString('base64');
    }

    const dataCache = JSON.stringify({
            id: id,
            checksum: checksum,
            type: type,
            cache_document: temp_doc
    });

    putCacheData(`documents?id=${id}`, dataCache);
}

const verifyChecksum = async (id) =>{
    const response = await call({ query: Queries.Documents.CheckSum, variables: { document_template_id: id } });
    const { data } = response;

    const checksum= data.document_template_checksum.checksum;

    const cachedData = await getCache(id);    
    if ( cachedData && cachedData.cache_document && cachedData.checksum && checksum === cachedData.checksum ){
        return {
            loadfromserver: false, checksum: checksum,
            cache_document: JSON.parse(Buffer.from(cachedData.cache_document,'base64').toString('utf-8'))
        };
    }
    else {
        return { loadfromserver: true, checksum: checksum, cache_document: null };
    }
}

export const getDocumentTemplate = (id, onComplete = () => null) => async dispatch => {
    const newData = await verifyChecksum(id);

    if ( newData.loadfromserver === true ){
        const response = await call({ query: Queries.Documents.Get, variables: { document_template_id: id } });
        if (hasErrors(response)) {
            onComplete();
            return;
        }

        const { data } = response;
        if (!data || !data.document_template) {
            Notifications.error(<FormattedMessage id="error.default" />);
            return;
        }
        
        await updateChecksum(id, data.document_template);

        const file_data_temp= data.document_template.file_data;        
        if ( data.document_template.document_type === 'word' ){
            data.document_template.file_data= JSON.parse(Buffer.from(JSON.stringify(file_data_temp),'ascii').toString('utf-8'));
        }

        dispatch({ type: DOCUMENT_GET, data: data.document_template });
        onComplete(data.document_template);
    } else {

        if (!newData || !newData.cache_document) {
            Notifications.error(<FormattedMessage id="error.default" />);
            return;
        }

        dispatch({ type: DOCUMENT_GET, data: newData.cache_document });
        onComplete(newData.cache_document);
    }
};

export const createDocumentTemplate = (model_id, document_type, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Documents.Create, variables: { ...new Document({ model_id, document_type }) } });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.document_template_create) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    dispatch({ type: DOCUMENT_GET, data: data.document_template_create });
    onComplete(data.document_template_create);
};

export const cloneDocumentTemplate = (document_template_id, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Documents.Clone, variables: { document_template_id } });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.document_template_clone) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }

    dispatch({ type: DOCUMENT_GET, data: data.document_template_clone });
    onComplete(data.document_template_clone);
};

export const getDocumentSample = (template_id, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Documents.CreateSample, variables: { template_id: template_id } });
    if (hasErrors(response)) {
        onError(response);
        return;
    }

    const { data } = response;
    if (!data || !data.document_create_sample) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }
    onComplete(data.document_create_sample);
};

export const updateDocumentTemplate = (template, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Documents.Update, variables: template });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.document_template_update) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    await updateChecksum(data.document_template_update.id, data.document_template_update);

    dispatch({ type: DOCUMENT_UPDATED, data: data.document_template_update });
    onComplete(data.document_template_update);
};

export const createDocumentSelection = (input, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Documents.CreateSelection, variables: { ...input } });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.document_selection_create) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.document_selection_create);
};

export const listDocumentSelections = (input, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Documents.ListSelections, variables: { ...input } });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.document_selection_list) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.document_selection_list);
};

export const setDocumentSelection = (input, onComplete = () => null) => async dispatch => {
    dispatch({ type: SET_DOCUMENT_SELECTION, data: input });
    onComplete();
}