import React from 'react';
import { FormattedMessage } from 'react-intl';
import SimpleBar from 'simplebar-react';
import './terms.scss';

class Terms extends React.Component {
    render() {
        const { onCancel } = this.props;
        return (
            <div className="modal-dialog wd-1000" role="document">
                <div className="modal-content  bd-0">
                    <div className="modal-header pd-x-20">
                        <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">CUSTOMER TERMS</h6>
                    </div>
                    <SimpleBar style={{ height: '350px' }}>
                        <div className="modal-body">
                            <p>
                                THESE CUSTOMER TERMS (“AGREEMENT”) FORM A BINDING CONTRACT BETWEEN YOU (THE ENTITY OR INDIVIDUAL AGREEING TO THIS AGREEMENT,
                                “YOU” OR “CUSTOMER”) AND DORAE (AS DEFINED BELOW), EFFECTIVE AS OF THE DATE THE FIRST REPRESENTATIVE OF CUSTOMER CREATES A DORAE
                                SUBSCRIPTION THROUGH THE ONLINE INTERFACE OR OTHERWISE (“EFFECTIVE DATE”). YOU WILL BE REQUIRED TO INDICATE YOUR AGREEMENT TO
                                THIS AGREEMENT IN ORDER TO ACCESS THE SERVICES, REGISTER ANY DORAE SOFTWARE, AND ACCESS THE DORAE WEB APPLICATION FOR THE
                                SERVICES.  BY CLICKING ON THE "I AGREE" BUTTON OR OTHER BUTTON OR MECHANISM DESIGNED TO ACKNOWLEDGE AGREEMENT TO THE TERMS OF AN
                                ELECTRONIC COPY OF THIS AGREEMENT, OR ACCESSING THE DORAE WEB APPLICATION, DOWNLOADING OR INSTALLING ANY DORAE SOFTWARE, OR
                                USING ANY MEDIA THAT CONTAINS ANY DORAE SOFTWARE, YOU ARE AGREEING: (1) THAT YOU HAVE READ AND UNDERSTAND THE TERMS AND
                                CONDITIONS OF THIS AGREEMENT; (2) TO BE LEGALLY RESPONSIBLE FOR COMPLIANCE WITH EACH AND EVERY TERM AND CONDITION OF THIS
                                AGREEMENT; (3) THAT YOU ARE DULY AUTHORIZED TO BIND YOUR COMPANY LEGALLY TO THIS AGREEMENT; AND (4) THAT THIS AGREEMENT IS THE
                                COMPLETE AND EXCLUSIVE STATEMENT OF THE AGREEMENT BETWEEN THE PARTIES RELATING TO THE SUBJECT MATTER HEREOF, AND SUPERSEDES ALL
                                PROPOSALS OR PRIOR OR CONTEMPORANEOUS AGREEMENTS, ORAL OR WRITTEN, INCLUDING, BUT NOT LIMITED TO, ANY TERMS CONTAINED IN
                                CUSTOMER’S PURCHASE ORDER. YOU AGREE THAT THIS AGREEMENT IS EQUIVALENT TO ANY WRITTEN NEGOTIATED AGREEMENT SIGNED BY YOU.
                            </p>
                            <p className="tx-12 pd-b-5 tx-uppercase">
                                The applicable Dorae entity for purposes of this Agreement ("Dorae") shall be (a) for customers organized in the United States
                                of America, Dorae Inc., a Delaware corporation, (b) for customers organized in the United Kingdom or within the European Union,
                                Dorae Ltd., a U.K. limited company and (c) for all other customers, Dorae Inc., a Cayman Islands exempt company.
                            </p>

                            <div>
                                <h6 className="tx-14 mg-t-3 tx-uppercase tx-inverse tx-bold">1. Services</h6>
                                <ol>
                                    <li className="tx-12 mg-b-2 sub-1">
                                        Provision of Services. Customer and End Users may access and use the Services during the Initial Subscription Term and
                                        any renewal Subscription Terms, as applicable, in accordance with this Agreement and the Documentation.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        Data Exchange. The Services allow Customer to import data from and export data to Third-Party Services. By using the
                                        Services, Customer authorizes Dorae to transfer, store, and process Customer Data from End Users and Customer’s
                                        Third-Party Services accounts on its behalf. By using the Services, Customer also authorizes Dorae to export Customer
                                        Data to Customer’s Third-Party Services accounts on its behalf. If Customer uses a Third-Party Service, Dorae will not
                                        be responsible for any act or omission of the Third-Party Service, including the Third-Party Service’s access to, use,
                                        provision, modification, or deletion of Customer Data. Dorae does not warrant or support any Third-Party Service.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        Security & Privacy. Dorae implements and maintains an industry-standard information security program with
                                        administrative, physical, and technical safeguards designed to protect the integrity of Customer Data. Customer agrees
                                        that Dorae may engage data sub-processors with at least the same level protections as provided by Dorae hereunder.
                                        Customer agrees that Dorae and its sub-processors may transfer, store, and process Customer Data in locations other than
                                        Customer’s country to the extent needed to provide the Services. All such transfers shall comply with the provisions
                                        outlined herein.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        Software. Some Services may allow Customer and End Users to download Dorae software, which may be updated automatically.
                                        Dorae grants Customer a limited, non-exclusive right during the Term to use such software only to access the Services.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        Changes. Dorae may, at its discretion, change or update the Services from time to time. Dorae will notify Customer in
                                        advance of planned changes to the Services that materially reduce their functionality.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        Implementation Services. The parties may enter into a SOW describing configuration, training, or other implementation
                                        services to be provided by Dorae or its contractors to Customer. Dorae will be responsible for the provision of services
                                        specified in the SOW, and Customer will be responsible for the provision of any payment and Customer-provided assistance
                                        specified in the SOW.
                                    </li>
                                </ol>
                            </div>
                            <div>
                                <h6 className="tx-14 mg-b-3 tx-uppercase tx-inverse tx-bold">2. Evaluations</h6>
                                <ol>
                                    <li className="tx-12 mg-b-2">
                                        Evaluation Offerings. Customer’s use of Evaluation Offerings will be governed by this Agreement, as modified by this
                                        subsection. Customer may access and use the Evaluation Offerings for the sole purpose of determining whether to
                                        subscribe for a commercial version of the Evaluation Offerings and subject to any time period and usage limits set forth
                                        on the applicable Dorae invoice. If no time period is agreed upon prior to the commencement of any Evaluation Offerings,
                                        Customer’s use of the Evaluation Offerings will be subject to a time limit of thirty (30) days and any other use and/or
                                        other limitations as may be imposed by Dorae.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        Evaluation Disclaimer. NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS AGREEMENT, DORAE DISCLAIMS ALL WARRANTIES,
                                        EXPRESS OR IMPLIED, FOR EVALUATION OFFERINGS. ALL EVALUATION OFFERINGS ARE PROVIDED TO CUSTOMER ON AN “AS IS” BASIS AT
                                        CUSTOMER’S OWN RISK AND DORAE WILL HAVE NO LIABILITY ASSOCIATED WITH THE EVALUATION OFFERINGS.
                                    </li>
                                </ol>
                            </div>
                            <div>
                                <h6 className="tx-14 mg-b-3 tx-uppercase tx-inverse tx-bold">3. CUSTOMER USE OF SERVICES</h6>
                                <ol>
                                    <li className="tx-12 mg-b-2">
                                        Subscriptions. Except as set forth in the applicable Dorae invoice, Services are made available as subscriptions and
                                        additional End User subscriptions may be added during the Subscription Term at the then-applicable price of that
                                        subscription type, pro-rated for the remainder of the then-current Subscription Term.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        Customer Responsibilities. Customer and End Users may only use the Services in accordance with this Agreement. Customer
                                        will be responsible for any use of the Services through its account, including all use of the Services by Customer’s End
                                        Users. Customer is responsible for designating an Administrator for its Services account, maintaining updated
                                        Administrator contact information, and managing access to the Administrator account. Customer is responsible for
                                        maintaining secure access by End Users to the Services. Each End User must have a subscription and Customer may not
                                        permit sharing of End User accounts and passwords.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        Compliance. Customer will obtain any consents and comply with any laws necessary to allow the operation of the Services
                                        and to permit Dorae to process, store, and transfer Customer Data from End Users and Customer’s Third-Party Services
                                        accounts.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        Usage Limits. Use of the Services may be subject to usage limits as set forth in the subscription descriptions or the
                                        Documentation.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        Restrictions. Customer will not (and will not allow any third party to): (a) reverse engineer, decompile, disassemble,
                                        or otherwise attempt to discover the source code or underlying ideas or algorithms of the Services, (b) modify or create
                                        derivative works based on the Services, (c) copy, rent, lease, distribute, time-share the Services or otherwise use the
                                        Services for the benefit of a third party, (d) remove or alter proprietary notices from the Service, (e) use the Service
                                        to create any other product or service, (f) breach or circumvent any security or authentication measures of the Service,
                                        (g) interfere with or disrupt any part of the Services, (h) submit viruses, worms, Trojan horses, corrupted files, or
                                        other destructive content to the Services, or (i) use a robot or other automated means to access the Services.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        {' '}
                                        Suspension. Dorae may suspend Customer’s or End User’s right to use the Services if (a) Customer or End User’s use of
                                        the Services poses a security risk to or may adversely affect the Services, Dorae’s systems and infrastructure, or a
                                        third party, (b) Customer or End User’s use of the Services could subject Dorae to liability, or (c) Customer is in
                                        material breach of this Agreement. Dorae will use commercially reasonable efforts to provide prompt notice of any
                                        impending suspension unless immediate suspension is necessary to comply with legal process, regulation, order or prevent
                                        material imminent harm to the Services or any third party, in which case Dorae will notify Customer to the extent
                                        allowed by applicable law of such suspension as soon as reasonably practicable thereafter. Dorae will provide Customer a
                                        commercially reasonable opportunity to cure any issue prior to suspending Customer or End User’s right to use the
                                        Services, and in any case, any suspension will be narrowly tailored to mitigate the issue.
                                    </li>
                                </ol>
                            </div>
                            <div>
                                <h6 className="tx-14 mg-b-3 tx-uppercase tx-inverse tx-bold">4. INTELLECTUAL PROPERTY RIGHTS & DATA</h6>
                                <ol>
                                    <li className="tx-12 mg-b-2">
                                        Reservation of Rights. Except for the rights granted in Section 1.1, Dorae and its licensors own and reserve all right,
                                        title, and interest in and to the Dorae Technology.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        Customer License and Enhancement Data. Notwithstanding any other provision of this Agreement, (a) Customer grants Dorae
                                        and its contractors a limited, non-exclusive right to use, copy, create derivative works based on, and display your
                                        Customer Data as reasonably necessary for Dorae to provide, maintain, and improve the Dorae Technology; (b) Dorae may
                                        use Customer’s and its End Users’ Services usage history, statistics and telemetry (“Enhancement Data”) for Dorae’s
                                        internal analytical purposes related to its provision of Services, including to improve and enhance the Dorae
                                        Technology; and (c) Dorae may make information derived from its analysis of Enhancement Data publicly available on an
                                        aggregated and de-indentified basis, provided that such information does not contain any personally-identifiable
                                        Customer Data. For the sake of clarity, aggregated and de-indentified data is not Confidential Information of Customer.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        Suggestions. Customer agrees that with respect to any Suggestions, Dorae may, in its discretion and for any purpose, (a)
                                        use, modify, and incorporate into Dorae’s products and services and (b) license or sublicense the Suggestions without
                                        obligation to Customer.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        Customer List. Unless Customer opts out, Dorae may use Customer’s name and logo to identify Customer as a Dorae customer
                                        on Dorae’s website and in other marketing materials so long as Customer’s name and logo do not appear with greater
                                        prominence than Dorae’s other customers. To opt out, Customer shall send an email from its Administrator's email address
                                        to sales@dorae.com with "Customer List Opt Out" in the subject line.
                                    </li>
                                </ol>
                            </div>
                            <div>
                                <h6 className="tx-14 mg-b-3 tx-uppercase tx-inverse tx-bold">5. FEES & PAYMENT </h6>
                                <ol>
                                    <li className="tx-12 mg-b-2">
                                        Fees. Customer will pay Dorae the fees for the Services set forth in the applicable Dorae invoice. All payments are due
                                        in U.S. dollars unless otherwise indicated on the applicable Dorae invoice. Customer is responsible for providing
                                        complete and accurate billing and contact information to Dorae and updating Dorae of any changes. All fees paid are
                                        non-refundable and not subject to set-off.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        Invoicing & Payment. Unless otherwise set forth in the applicable Dorae invoice or any SOW, all fees will be invoiced in
                                        advance. All invoices issued under this Agreement are due and payable according to the payment terms in the Dorae
                                        invoice, if applicable. If Customer provides Dorae with credit card information for payment, Customer acknowledges that
                                        an invoice may not be sent and Customer authorizes Dorae to charge the credit card for the Services listed in the
                                        applicable Dorae invoice for the Subscription Term or for additional subscriptions added for the Subscription Term. If
                                        the Customer requires the use of a purchase order or purchase order number, the Customer (a) must provide the purchase
                                        order number at the time of purchase and (b) agrees that any terms and conditions on a Customer purchase order will not
                                        apply to this Agreement and are void.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        Late Payments. Past due amounts are subject to a finance charge of 1% per month (or the highest rate permitted by law)
                                        from the payment due date until paid in full. Customer will be responsible for all reasonable expenses (including
                                        attorneys’ fees) incurred by Dorae in collecting past due amounts. Dorae may suspend or terminate the Services for
                                        material breach for fees that are past due.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        Taxes. Customer is responsible for all taxes, except those directly relating to Dorae’s net income, gross receipts, or
                                        capital stock. Dorae will invoice Customer for sales or value added tax when required to do so and Customer will pay the
                                        tax unless Customer provides Dorae with a valid tax exemption certificate authorized by the appropriate taxing
                                        authority.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        Future Functionality. Customer agrees that it has not relied on the availability of any future functionality of the
                                        Service or any other future product or service in executing this Agreement. Customer acknowledges that information
                                        provided by Dorae regarding future functionality should not be relied upon to make a purchase decision.
                                    </li>
                                </ol>
                            </div>
                            <div>
                                <h6 className="tx-14 mg-b-3 tx-uppercase tx-inverse tx-bold">6. TERM & TERMINATION</h6>
                                <ol>
                                    <li className="tx-12 mg-b-2">
                                        Agreement Term. The Agreement will remain in effect for the Term. Upon the end of the Initial Subscription Term or
                                        renewal Subscription Term, as applicable, this Agreement shall renew at the then-current fee structure for successive
                                        renewal Subscription Terms of the same length unless either party terminates this Agreement in accordance with its terms
                                        or Customer cancels its subscription for Services. Customer may cancel its subscription for Services at any time through
                                        the online interface, whereupon the then-current Subscription Term of Customer will not be subject to auto-renewal.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        Termination. Either party may terminate this Agreement if (a) the other party is in material breach (including for a
                                        material breach of the SLA or material non-performance of work pursuant to any SOW) of the Agreement and fails to cure
                                        that breach within 30 days after receipt of written notice, or (b) the other party ceases its business operations or
                                        becomes subject to insolvency proceedings. Dorae may also terminate the Agreement (a) for non-payment of fees, (b) if it
                                        has the right to suspend the Services under Section 2.6 or (c) in order to comply with legal process, regulation, order
                                        or prevent material imminent harm to the Services or any third party.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        Effect of Termination. If this Agreement expires or is terminated, then (a) the rights granted by one party to the other
                                        will cease immediately except as otherwise set forth in this Section and (b) the following Sections will survive: 3
                                        (Intellectual Property Rights), 4 (Fees & Payment), 5.3 (Effect of Termination), 6 (Confidentiality), 7 (Disclaimers), 8
                                        (Indemnity), 9 (Limitation of Liability), 10 (Disputes), 11 (General Provisions), 12 (Definitions).
                                    </li>
                                </ol>
                            </div>
                            <div>
                                <h6 className="tx-14 mg-b-3 tx-uppercase tx-inverse tx-bold">7. CONFIDENTIALITY</h6>
                                <ol>
                                    <li className="tx-12 mg-b-2">
                                        Definition of Confidential Information. “Confidential Information” means any business or technical information disclosed
                                        by a party (“Disclosing Party”) to the other party (“Receiving Party”) under this Agreement that is marked as
                                        confidential or would normally be considered confidential to a reasonable person under the circumstances. The
                                        Confidential Information of Dorae includes the Dorae Technology and any Dorae invoices. The Confidential Information of
                                        Customer includes the Customer Data. Confidential Information will not include any information that (a) is or becomes
                                        public through no fault of the Receiving Party, (b) was known to the Receiving Party prior to receipt, (c) is obtained
                                        by the Receiving Party from a third party not under an obligation of confidentiality, or (d) is independently developed
                                        by Receiving Party without use of the Disclosing Party’s Confidential Information.{' '}
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        Obligations. The Receiving Party will use reasonable care to (a) not use the Disclosing Party’s Confidential Information
                                        for any purpose outside of the scope of this Agreement and (b) except as authorized or directed by the Customer to
                                        provide Confidential Information to a Third-Party Service, limit access to the Disclosing Party’s Confidential
                                        Information to its and its Affiliates’ employees and contractors who need access to the Confidential Information for any
                                        purpose not outside of the scope of this Agreement and who have agreed to confidentiality provisions with the Receiving
                                        Party containing similar protections to the protections for Confidential Information in this Agreement.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        Compelled Disclosure. Each party may disclose the other party’s Confidential Information when required by law or
                                        regulation so long as the Receiving Party gives the Disclosing Party prior notice of the compelled disclosure, to the
                                        extent permitted, and reasonable assistance at the Disclosing Party’s cost to contest or limit the disclosure. If the
                                        Receiving Party is compelled to disclose the Confidential Information, the Receiving Party will disclose the minimum
                                        extent of Confidential Information necessary
                                    </li>
                                </ol>
                            </div>
                            <div>
                                <h6 className="tx-14 mg-b-3 tx-uppercase tx-inverse tx-bold">8. WARRANTIES AND DISCLAIMERS</h6>
                                <ol>
                                    <li className="tx-12 mg-b-2">
                                        Customer Data. Customer warrants that it owns all right, title and interest, or possesses sufficient license rights, in
                                        and to the Customer Data as may be necessary to permit the use contemplated under this Agreement.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        Dorae Warranties. Dorae warrants that (a) the Services will be provided in a professional and competent manner in
                                        accordance with industry standards, (b) the Services provided hereunder are and will be in compliance with all
                                        applicable federal, state and local laws and government rules and regulations, and (c) it has taken commercially
                                        reasonable measures (including the use of then-current industry standard security tools) designed to ensure that the
                                        Services will not cause any Illicit Code to be installed or introduced on Customer’s computer, telecommunication or
                                        other information systems. Notwithstanding the foregoing, any such code included within the Services for the sole
                                        purpose of allowing Vendor to (A) perform license validation and enforcement or (B) access the Services remotely in
                                        order to perform its support and maintenance obligations shall not be considered Illicit Code. In the event Dorae is not
                                        able to cure any breach of such warranty within 30 days of written notice thereof from Customer, Customer will at its
                                        option have the right to (i) terminate this Agreement and receive a refund of any fees Customer has prepaid for the
                                        Service that it has not received as of the date of the warranty claim or (ii) elect as Customer’s sole remedy to
                                        continue the time Dorae has to cure the defect and receive a reduction in fees or a credit to be agreed upon by both
                                        parties for each month thereafter that the defect remains uncured.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        General. EXCEPT AS SET FORTH IN SECTION 7.2 ABOVE, TO THE FULLEST EXTENT PERMITTED BY LAW, DORAE AND ITS AFFILIATES,
                                        SUPPLIERS, AND DISTRIBUTORS (A) MAKE NO OTHER WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE
                                        REGARDING THE SERVICES OR ANY OUTPUT FROM THE SERVICES, AND (B) DISCLAIMS ALL OTHER WARRANTIES, INCLUDING ANY IMPLIED OR
                                        EXPRESS WARRANTIES (I) OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT OR (II) THAT THE
                                        OUTPUT OF THE SERVICES IS ACCURATE OR RELIABLE. THE SERVICES AND ANY OUTPUT FROM THE SERVICES ARE PROVIDED “AS IS” AND
                                        “AS AVAILABLE.”
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        Beta Services and Evaluation Offerings. Despite anything to the contrary in this Agreement, Customer acknowledges that
                                        (a) Customer has the sole discretion whether to use any Beta Services or Evaluation Offerings, (b) Beta Services and
                                        Evaluation Offerings may not be supported and may be changed at any time, including in a manner that reduces
                                        functionality, (c) Beta Services may not be available or reliable, (d) Beta Services and Evaluation Offerings may not be
                                        subject to the same security or audits as the Services, and (e) Dorae provides Beta Services and Evaluation Offerings
                                        “as-is” and will have no liability arising out of or in connection with Beta Services or Evaluation Offerings.{' '}
                                    </li>
                                </ol>
                            </div>
                            <div>
                                <h6 className="tx-14 mg-b-3 tx-uppercase tx-inverse tx-bold">9. INDEMNIFICATION</h6>
                                <ol>
                                    <li className="tx-12 mg-b-2">
                                        By Customer. Customer will defend Dorae and its Affiliates from and against all third-party claims, lawsuits, and
                                        demands arising out of an allegation regarding (a) Customer Data, or (b) or use of the Services by Customer or End Users
                                        in violation of this Agreement, and will indemnify Dorae and its Affiliates for all reasonable attorney’s fees incurred
                                        and damages and other costs awarded in a final judgment or amounts paid in a settlement consented to as further set
                                        forth in Section 8.3.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        By Dorae. Dorae will defend Customer from and against all third-party claims, lawsuits, and demands arising out of an
                                        allegation that Dorae’s technology used to provide the Services infringes or misappropriates any U.S. patent, copyright,
                                        or trade secret of such third party, and will indemnify Customer for all reasonable attorney’s fees incurred and damages
                                        and other costs awarded in a final judgment or amounts paid in a settlement consented to as further set forth in Section
                                        8.3. In no event will Dorae have obligations or liability under this Section arising from: (a) use of the Services in a
                                        modified form or in combination with materials or technology not furnished by Dorae, and (b) any content, information,
                                        or data provided by Customer, End Users, or other third parties. For any claim covered by this Section, Dorae will, at
                                        its election, either (i) procure the rights to use the portion of the Services alleged to be infringing, (ii) replace
                                        the alleged infringing portion of the Services with a non-infringing alternative, or (iii) terminate the allegedly
                                        infringing portion of the Services or this Agreement and provide Customer with a pro-rata refund of any pre-paid, unused
                                        fees.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        General. The party seeking indemnification will promptly notify the other party of the claim and cooperate with the
                                        party in defending the claim. The indemnifying party will have full control and authority over the defense, except that:
                                        (a) any settlement requiring the party seeking indemnification to admit liability or to pay any money will require that
                                        party’s prior written consent, such consent not to be unreasonably withheld or delayed, and (b) the other party may join
                                        in the defense with its own counsel at its own expense. THE INDEMNITIES ARE A PARTY’S ONLY REMEDY UNDER THIS AGREEMENT
                                        FOR VIOLATION BY THE OTHER PARTY OF A THIRD PARTY’S INTELLECTUAL PROPERTY RIGHTS.
                                    </li>
                                </ol>
                            </div>
                            <div>
                                <h6 className="tx-14 mg-b-3 tx-uppercase tx-inverse tx-bold">10. LIMITATION OF LIABILITY</h6>
                                <ol>
                                    <li className="tx-12 mg-b-2">
                                        Limitation on Indirect Liability. TO THE FULLEST EXTENT PERMITTED BY LAW, NEITHER PARTY NOR ITS AFFILIATES, SUPPLIERS,
                                        AND DISTRIBUTORS WILL BE LIABLE TO THE OTHER PARTY UNDER THIS AGREEMENT FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL,
                                        PUNITIVE, EXEMPLARY, OR CONSEQUENTIAL DAMAGES OR (B) ANY LOSS OF USE, DATA, BUSINESS, OR PROFITS, OR SERVICE
                                        INTERRUPTION, OR THE COST OF SUBSTITUTE SERVICES, REGARDLESS OF THE LEGAL THEORY AND REGARDLESS OF WHETHER THE PARTY HAS
                                        BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGES, EVEN IF A LIMITED REMEDY SET FORTH IS FOUND TO HAVE FAILED OF ITS
                                        ESSENTIAL PURPOSE.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        Customer Responsibilities. Customer is responsible for all login credentials, including usernames and passwords, for its
                                        Administrator and all End Users. Dorae shall not be responsible for any damages, losses or liability to Customer, End
                                        Users, or anyone else, if such information is not kept confidential by Customer or its End Users, or if such information
                                        is correctly provided by an unauthorized third party logging into and accessing the Services.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        Liability Cap. NEITHER PARTY’S NOR ITS AFFILIATES’, SUPPLIERS’, AND DISTRIBUTORS’ AGGREGATE LIABILITY UNDER THIS
                                        AGREEMENT AND ARISING OUT OF OR IN CONNECTION WITH CUSTOMER’S USE OF THE SERVICES AND OUTPUT OF THE SERVICES WILL EXCEED
                                        THE AMOUNT CUSTOMER HAS PAID OR IS PAYABLE FOR CUSTOMER’S USE OF THE SERVICES IN THE TWELVE (12) MONTH PERIOD PRIOR TO
                                        THE EVENT GIVING RISE TO THE LIABILITY.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        Excluded Claims. Sections 9.1 and 9.3 do not apply with respect to claims arising under Section 2 (Customer Use of
                                        Services) and Section 4 (Fees & Payment).
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        Application and Reliance. The limitations under this “Limitation of Liability” section apply with respect to all legal
                                        theories, whether in contract, tort or otherwise, and to the extent permitted by law. The provisions of this “Limitation
                                        of Liability” section allocate the risks under this Agreement between the parties, and the parties have relied on these
                                        limitations in determining whether to enter into this Agreement and the pricing for the Services.
                                    </li>
                                </ol>
                            </div>
                            <div>
                                <h6 className="tx-14 mg-b-3 tx-uppercase tx-inverse tx-bold">11. DISPUTES</h6>
                                <ol>
                                    <li className="tx-12 mg-b-2">
                                        Informal Resolution. Dorae wants to address Customer’s concerns and requests that Customer bring issues that may lead to
                                        a dispute to our attention immediately. Before filing a claim, Dorae and Customer agree to try to resolve the dispute by
                                        providing notice to the other party and having the appropriate executives attempt to resolve the dispute through
                                        communication and discussion. After 15 days, either party may bring a formal proceeding.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        Arbitration Agreement. Dorae and Customer agree to resolve any claims relating to this Agreement or the Services through
                                        final and binding arbitration, except as further set forth below. Any dispute will be administered by JAMS under its
                                        Streamlined Arbitration Rules & Procedures by one arbitrator. The arbitration will take place in London, England in the
                                        English language. Either party may bring a lawsuit in court for claims for injunctive relief to stop unauthorized use or
                                        abuse of the Services or claims of intellectual property infringement without first engaging in the informal resolution
                                        process described above. Dorae and Customer consent to the exclusive jurisdiction and venue of the courts located in
                                        London, England.
                                    </li>
                                </ol>
                            </div>
                            <div>
                                <h6 className="tx-14 mg-b-3 tx-uppercase tx-inverse tx-bold">12. GENERAL PROVISIONS</h6>
                                <ol>
                                    <li className="tx-12 mg-b-2">
                                        Entire Agreement. All attachments to the Agreement, SOWs, and invoices agreed by the parties are hereby incorporated
                                        into the Agreement by reference. This Agreement constitutes the entire agreement between Customer and Dorae with respect
                                        to the subject matter of this Agreement and supersedes any prior or contemporaneous agreements whether written or oral,
                                        including any non-disclosure agreements. If there is a conflict between the documents that make up this Agreement, the
                                        documents will control in the following order: Dorae invoice, the SOW, the Agreement, and the Documentation.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        Updating of Agreement. As Dorae's business evolves, it may change this Agreement and the other components of the
                                        Agreement (except any invoices and SOWs). If Dorae makes a material change to the Agreement, it will provide Customer
                                        with reasonable notice prior to the change taking effect by emailing the email address associated with Customer’s
                                        Administrator. Customer can review the most current version of the Agreement at any time by visiting www.dorae.com. The
                                        materially revised Agreement will become effective on the date set forth in Dorae’s notice, and all other changes will
                                        become effective upon posting of the change. If Customer (or any End User) accesses or uses the Services after the
                                        effective date, that use will constitute Customer’s acceptance of any revised Agreement.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        Governing Law. This Agreement will be governed by laws of England and Wales except for its conflicts of laws principles.
                                        For the avoidance of doubt, the United Nations Convention on the International Sale of Goods shall not apply.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        Notices. Notices must be sent by first class mail or overnight courier and are deemed given when received. Notices to
                                        Customer may also be sent to the applicable Administrator account email address and are deemed given when sent. Notices
                                        to Dorae must be sent to Dorae, Attn: Legal Department, 67 Grosvenor Street, London, W1K 3JN, United Kingdom.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        Assignment. Neither party may assign any of its rights or obligations under this Agreement, whether by operation of law
                                        or otherwise, without the other party’s prior written consent, not to be unreasonably withheld; except, however, either
                                        party may assign this Agreement in its entirety, without the other party’s consent, to its Affiliate or in connection
                                        with a merger, acquisition, corporate reorganization, or sale of all or substantially all of its assets. Subject to the
                                        restrictions in this section, this Agreement will be binding upon and inure to the benefit of the parties and their
                                        respective successors and assigns. Any other attempt to transfer or assign is void. If a party is acquired by, sells
                                        substantially all of its assets to, or undergoes a change of control in favor of a direct competitor of the other party,
                                        then the other party may terminate this Agreement upon written notice. In the event of a termination by Dorae, Dorae
                                        will offer a pro-rata refund of prepaid fees for the Services.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        Relationship of the Parties. The parties are independent contractors. This Agreement does not create a partnership,
                                        joint venture, or agency relationship between the parties.
                                    </li>
                                    <li className="tx-12 mg-b-2">Third-Party Beneficiaries. There are no third-party beneficiaries under this Agreement.</li>
                                    <li className="tx-12 mg-b-2">
                                        Construction. Any ambiguity in the Agreement will be interpreted equitably without regard to which party drafted the
                                        Agreement. “Including” and “include” will be construed to mean “including without limitation.”
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        Force Majeure. Neither party will be liable for inadequate performance to the extent caused by a condition (for example,
                                        natural disaster, act of war or terrorism, labor disputes, governmental action, utilities failures, and Internet
                                        disturbance) that was beyond its reasonable control.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        Waiver. No failure or delay by either party in exercising a right under this Agreement will constitute a waiver of that
                                        right. A waiver of a default is not a waiver of any subsequent default.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        Severability. If a court of competent jurisdiction finds any term of this Agreement to be unenforceable, the
                                        unenforceable term will be modified to reflect the parties’ intention and only to the extent necessary to make the term
                                        enforceable. The remaining provisions of the Agreement will remain in effect.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        Export Compliance. The Services may be subject to export restriction laws and regulations in the U.S. and any other
                                        applicable jurisdiction and Customer is responsible for compliance regarding Customer’s and its End Users’ use of the
                                        Services. Customer will not permit End Users to use the Services from a U.S. embargoed country.
                                    </li>
                                </ol>
                            </div>
                            <div>
                                <h6 className="tx-14 mg-b-3 tx-uppercase tx-inverse tx-bold">13. DEFINITIONS</h6>
                                <ol>
                                    <li className="tx-12 mg-b-2">
                                        “Administrator” means a Customer-designated End User who administers the Services account and has access to permissions
                                        and other sensitive settings.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        “Affiliate” means any entity that controls, is controlled by, or is under common control with a party, where “control”
                                        means the ability to direct the management and policies of an entity.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        “Beta Services” means products or features available to Customer on a trial, beta, early access, or similar basis.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        “Customer Data” means data and information submitted to the Services by Customer or its End Users, including data and
                                        information submitted to the Services from Third-Party Services authorized by Customer.
                                    </li>
                                    <li className="tx-12 mg-b-2">“Documentation” means any Dorae-provided written documentation and policies.</li>
                                    <li className="tx-12 mg-b-2">
                                        “Dorae Technology” means the Services, Documentation, and Dorae’s trademarks, logos, and other brand features,
                                        including, without limitation, any object or other software code, interoperability and functionality features, design
                                        attributes (e.g., the “look and feel” and user interface of the Services), workflows and screen layouts associated with
                                        the Services.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        “Effective Date” means the earlier of the effective date or subscription start date set forth on the applicable Dorae
                                        invoice.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        “End Users” means users of Customer’s Services account. End Users may include Customer’s and its Affiliate’s employees,
                                        agents, and consultants.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        “Evaluation Offerings” means Services provided by Dorae to Customer for a limited period on a free or discounted trial
                                        basis.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        “Illicit Code” means any viruses, worms, time bombs, Trojan horses or other harmful, malicious or destructive code.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        “Initial Subscription Term” means the term for the applicable Services starting on the subscription start date set forth
                                        on the applicable Dorae invoice and continuing for the period set forth in the applicable Dorae invoice.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        “Services” means the products and services ordered by Customer and reflected in the applicable Dorae invoice or
                                        otherwise provided by Dorae and used by Customer under this Agreement.
                                    </li>
                                    <li className="tx-12 mg-b-2">“SOW” means statement of work.</li>
                                    <li className="tx-12 mg-b-2">
                                        {' '}
                                        “Subscription Term” means the Initial Subscription Term or any renewal terms for the Services.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        “Suggestions” means feedback, comments, or suggestions that Customer or End Users provide to Dorae or its contractors.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        “Term” means the term of the Agreement, which will begin on the Effective Date and continue until the earlier of (a) the
                                        last Subscription Term has expired or (b) until the Agreement is terminated.
                                    </li>
                                    <li className="tx-12 mg-b-2">
                                        “Third-Party Services” means products or services offered by a third party that interoperate with the Services for which
                                        Customer has an account and has connected the account with the Services.
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </SimpleBar>
                    <div className="modal-footer justify-content-center">
                        <button type="button" onClick={onCancel} className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium">
                            <FormattedMessage id="common.ok" />
                        </button>
                        <button
                            type="button"
                            onClick={() => window.open('https://dev-dorae-media.s3.eu-west-2.amazonaws.com/Dorae+Customer+Terms.pdf', '_blank')}
                            className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                        >
                            <FormattedMessage id="common.download" />
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default Terms;
