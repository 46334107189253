import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getProfile } from '../../../../../reducers/identity';
import { loadModel } from '../../../../../reducers/models';
import { setMenuVisibility } from '../../../../../reducers/menus';
import { getDocumentTemplate, updateDocumentTemplate, getDocumentSample } from '../../../../../reducers/document';
import '../../../models.scss';

import '../../../../../components/fields/fields.scss';
import { SpreadsheetComponent } from '@syncfusion/ej2-react-spreadsheet';
import FieldsReferencesMenu from './fieldsReferencesMenu';
import EdiText from 'react-editext'
import { DocumentEditorContainerComponent, Toolbar } from '@syncfusion/ej2-react-documenteditor';
DocumentEditorContainerComponent.Inject(Toolbar);

class ExcelDocumentTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            previewData: "",
            loadingPreview: false,
            contentChanged: false,
        };

        this.persistChanges = this.persistChanges.bind(this);
    }

    componentDidMount() {
        const { match, loadModel, getDocumentTemplate } = this.props;
        loadModel(match.params.modelId);
        getDocumentTemplate(match.params.id, (dt) => {
            this.setState({templateTitle: dt.title})
        });
        

        this.updateInterval = setInterval(() => {
            this.persistChanges();
        }, 5000);
       
        setTimeout(() => {
            let dropButtons = document.getElementsByClassName('e-drop-icon');
            dropButtons.forEach((el) => {
                if (el.title === 'Collapse Toolbar') {
                    el.click();
                    setTimeout(() => el.click(), 50);
                }
            })
        }, 1000);
    }

    onDataBound() {
    }

    async componentWillUnmount() {
        await this.persistChanges();
        clearInterval(this.updateInterval);
    }

    persistChanges = async () => {
        const { template, updateDocumentTemplate, getDocumentTemplate } = this.props;

        let spreadsheetJson = this.getStringifiedWorkbook();

        if (spreadsheetJson !== template.file_data || template.title !== this.state.templateTitle) {
            template.file_data = spreadsheetJson;
            template.title = this.state.templateTitle;
            updateDocumentTemplate(template, () => {
                getDocumentTemplate(template.id, () => {
                    this.setState({contentChanged: false});
                });
            });
        }
    };

    onSaveTitle = (val) => {
        this.setState({templateTitle: val, contentChanged: true});
    }

    onFieldSelect(field) {
        let proxy = this;
        proxy.spreadsheet.updateCell({value: '{{' + field.id + '}}'});
    }

    getCircularReplacer = () => {
        const seen = new WeakSet();
        return (key, value) => {
          if (typeof value === "object" && value !== null) {
            if (seen.has(value)) {
              return;
            }
            if (key === 'targetElement' || key === '_reactInternalFiber' || key === 'element' || key === 'parentObj' || key === 'controlParent') {
                return;
            }
            seen.add(value);
          }
          return value;
        };
      };

    getStringifyObject(value, skipProp = []) {
        return JSON.stringify(value, (key, value) => {
            if (skipProp.indexOf(key) > -1) {
                return undefined;
            }
            else {
                if (value && typeof value === 'object' && value.hasOwnProperty('properties')) {
                    return value.properties;
                }
                else if (value !== null) {
                    return value;
                }
                else {
                    return undefined;
                }
            }
        });
    }

    getStringifiedWorkbook() {
        let jsonStr = this.getStringifyObject(this.spreadsheet, ['sheets', '_isScalar', 'observers', 'closed', 'isStopped', 'hasError',
            '__isAsync', 'beforeCellFormat', 'beforeCellRender', 'beforeDataBound', 'beforeOpen', 'beforeSave', 'beforeSelect',
            'beforeSort', 'cellEdit', 'cellEditing', 'cellSave', 'contextMenuItemSelect', 'contextMenuBeforeClose',
            'contextMenuBeforeOpen', 'created', 'dataBound', 'fileItemSelect', 'fileMenuBeforeClose', 'fileMenuBeforeOpen', 'openFailure',
            'saveComplete', 'sortComplete', 'select']);
        let basicSettings = JSON.parse(jsonStr);
        basicSettings.sheets = this.getSpreadsheets();
        return JSON.stringify(basicSettings);
    }

    getSpreadsheets() {
        
        let reducedSheets = this.spreadsheet.sheets.map(s => { 
            return { 
                id: s.id, 
                rows: s.rows.map(r => {
                        if (r.properties === undefined)
                            return r;
                        if (r.properties.cells === undefined)
                            return r.properties;
                        
                        let row = {...r.properties};
                        row.cells = r.properties.cells.map(c => {
                                if (c.properties === undefined) {
                                    return c;
                                }
                                let cell = {};
                                if (c.properties.hasOwnProperty('formula')){
                                    cell.formula = c.properties.formula;
                                }
                                if (c.properties.hasOwnProperty('value')){
                                    cell.value = c.properties.value;
                                }
                                return cell;
                            });
                        return row;
                    }), 
                columns: s.columns, 
                rangeSettings: s.rangeSettings, 
                index: s.index, 
                name: s.name, 
                rowCount: s.rowCount, 
                colCount: s.colCount, 
                selectedRange: s.selectedRange, 
                activeCell: s.activeCell, 
                topLeftCell: s.topLeftCell, 
                showHeaders: s.showHeaders, 
                showGridLines: s.showGridLines }})

        // remove empty entries
        reducedSheets = reducedSheets.map(s => {
            return JSON.parse(JSON.stringify(s, this.getCircularReplacer()), (key, value) => {
                //Remove empty properties
                if ((Array.isArray(value) || typeof value === 'string') && !value.length) {
                    return undefined;
                }
                return value;
            });
        })
        return reducedSheets;
    }

    render() {
        const { model, template, fields } = this.props;
        if (!model || !template || !fields) return null;
        let workbook = null;
        if (template.file_data && template.file_data.length > 0) {
            workbook = JSON.parse(template.file_data);
        }

        return (
            <React.Fragment>
                <EdiText
                    type="text"
                    value={this.state.templateTitle}
                    onSave={this.onSaveTitle}
                    showButtonsOnHover
                />
                <FieldsReferencesMenu name="content" onFieldSelect={this.onFieldSelect.bind(this)} manualClassName='btn btn-outline-info' sendFullField={true}/>
                {workbook ? 
                    <SpreadsheetComponent id="dorae-spreadsheet" 
                        ref={(ssObj) => { this.spreadsheet = ssObj }} 
                        dataBound={this.onDataBound.bind(this)} 
                        sheets={workbook.sheets.map(s => {
                            return JSON.parse(JSON.stringify(s), (key, value) => {
                                //Remove empty properties
                                if ((Array.isArray(value) || typeof value === 'string') && !value.length) {
                                    return undefined;
                                }
                                if (Array.isArray(value)) {
                                    return value.filter(e => e !== null);
                                }
                                return value;
                            });
                        })}
                        allowOpen={true}
                        allowSave={true}
                        style={{ 'height': this.props.manualHeight }}  
                        // contextMenuBeforeOpen={(args) => {
                        //     if (args.parentItem === null) {
                        //         let menuItems = [
                        //             {
                        //                 text: "Add Event Field Reference",
                        //                 items: [
                        //                     {
                        //                         text: "Seller"
                        //                     },
                        //                     {
                        //                         text: "Buyer"
                        //                     },
                        //                     {
                        //                         text: "Premium"
                        //                     },
                        //                 ]
                        //             }
                        //         ];
                        //         this.spreadsheet.addContextMenuItems(menuItems, 'Sort');
                        //     }
                        // }}
                        // contextMenuItemSelect={(args) => {
                        //     console.log(args);
                        // }}
                    /> 
                    :
                    <SpreadsheetComponent id="dorae-spreadsheet" 
                        ref={(ssObj) => { this.spreadsheet = ssObj }} 
                        dataBound={this.onDataBound.bind(this)} 
                        allowOpen={true}
                        allowSave={true}
                        style={{ 'height': this.props.manualHeight }}  
                        // contextMenuBeforeOpen={(args) => {
                        //     if (args.parentItem === null) {
                        //         let menuItems = [
                        //             {
                        //                 text: "Add Event Field Reference",
                        //                 items: [
                        //                     {
                        //                         text: "Seller"
                        //                     },
                        //                     {
                        //                         text: "Buyer"
                        //                     },
                        //                     {
                        //                         text: "Premium"
                        //                     },
                        //                 ]
                        //             }
                        //         ];
                        //         this.spreadsheet.addContextMenuItems(menuItems, 'Sort');
                        //     }
                        // }}
                        // contextMenuItemSelect={(args) => {
                        //     console.log(args);
                        // }}
                    /> 
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    company: state.identity.company,
    model: state.fields.model,
    fields: state.fields.items,
    fieldTypes: state.fields.types,
    template: state.document.instance,
    templateChanged: state.document.templateChanged
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getProfile,
            loadModel,
            getDocumentTemplate,
            getDocumentSample,
            setMenuVisibility,
            updateDocumentTemplate
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExcelDocumentTemplate);

// const testData = '[{"id":1,"rows":[{"cells":[],"height":20},{"height":20},{"height":20},{"cells":[{},{},{},{"formula":"","value":"875"}],"height":20},{"cells":[null,null,null,null,null,{"formula":"","value":"asdf"}],"height":20},{"cells":[]}],"columns":[],"rangeSettings":[],"index":0,"name":"Sheet1","rowCount":100,"colCount":100,"selectedRange":"F6:F6","activeCell":"F6","topLeftCell":"A1","showHeaders":true,"showGridLines":true}]';
//const testData = '[{"id":1,"rows":[{"properties":{"cells":[],"height":20},"changedProperties":{},"childChangedProperties":{},"oldProperties":{},"propName":"rows","isParentArray":true},{"properties":{"height":20},"changedProperties":{},"childChangedProperties":{},"oldProperties":{},"propName":"rows","isParentArray":true},{"properties":{"height":20},"changedProperties":{},"childChangedProperties":{},"oldProperties":{},"propName":"rows","isParentArray":true},{"properties":{"cells":[{"properties":{},"changedProperties":{},"childChangedProperties":{},"oldProperties":{},"propName":"cells","isParentArray":true},{"properties":{},"changedProperties":{},"childChangedProperties":{},"oldProperties":{},"propName":"cells","isParentArray":true},{"properties":{},"changedProperties":{},"childChangedProperties":{},"oldProperties":{},"propName":"cells","isParentArray":true},{"properties":{"formula":"","value":"875"},"changedProperties":{},"childChangedProperties":{},"oldProperties":{},"propName":"cells","isParentArray":true}],"height":20},"changedProperties":{},"childChangedProperties":{},"oldProperties":{},"propName":"rows","isParentArray":true},{"properties":{"cells":[null,null,null,null,null,{"formula":"","value":"asdf"}],"height":20},"changedProperties":{},"childChangedProperties":{},"oldProperties":{},"propName":"rows","isParentArray":true},null,{"cells":[]}],"columns":[],"rangeSettings":[],"index":0,"name":"Sheet1","rowCount":100,"colCount":100,"selectedRange":"F7:F7","activeCell":"F7","topLeftCell":"A1","showHeaders":true,"showGridLines":true}]';