export const DOCUMENT_TEMPLATE_GET_REF_DATA = `
    type DocumentFieldReference {
        literal: String!
        label: String!
        token: String!
        type: String!
    }

    query($field_references: [DocumentFieldReference!], $document_template_id: Long!) {
        document_template_ref_data(input: { field_references: $field_references, document_template_id: $document_template_id }) {
            fields {
                id
                model_id
                order
                type_id
                type_name
                status_id
                status_name
                index_field_id
                input_id
                visibility_check
                title
                description
                is_required
                validation
                width
                force_break
                extra_info
                company_id: model_company_id
                cargoCategory: cargo_category
                cargo_type
                transform_cargo_type
                cargo_reference_type

                model
                {
                    id
                    company_id
                    status_id
                    status_name
                    created_on
                    title
                    description
                    is_used
                    split_conditions
                    extra_info
                    
                    model_type_name
                    contains_inaccessible_dots
                }
            }

            models {
                id
                title
            }

            indices {
                id
                hash_id
                order_by
                type_id
                type_name
                status_id
                status_name
                title
                description
                extra_info
                is_used
                model_type
                model_type_name
                created_on
            }

            ref_groups {
                document_template_id
                name
            }

            ref_group_references {
                document_template_id
                name
                field_reference_literal
                compatible_field_ids
                compatible_model_ids
            }
        }
    }
`;
