import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import ReactModal from 'react-modal';
import 'pages/models/models.scss';
import SimpleBar from 'simplebar-react';
import { getModels } from 'reducers/models';
import Table from 'components/table';
import QueryBuilder from 'pages/event-templates/components/queryBuilder';
import { Link } from 'react-router-dom';
import moment from 'moment';
import FadeIn from 'react-fade-in';
import { importFields, setModelPropertiesVisibility } from 'reducers/fields';
import { loadModel } from 'reducers/models';
import { getTransaction } from 'reducers/transaction';
import { getIndices } from 'reducers/indices';
import Tooltip from 'rc-tooltip';
import { tooltipWrapper } from 'utilities/common'
import { SelectField, TextField } from 'pages/models/fields/definitions';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import LoadingOverlay from 'react-loading-overlay';
import { Notifications } from 'components/notifications';
import { createFieldReferenceGroup, deleteFieldReferenceGroup } from 'reducers/documents';
import Select, { components } from 'react-select';

class FieldGroupPickerModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groups: [],
            view: 'edit',
            groupsToDelete: [],
        };
    }

    setModalVisibility = open => { 
        return this.props.setModalVisibility ? this.props.setModalVisibility(open) : null;
    }

    fieldReferenceGroupSelector = () => {
        const {
            intl: { formatMessage },
            fieldRefGroups,
        } = this.props;
        const { fieldLabel, targetIndexField, fieldReferenceId, fieldType, targetCargoCategory } = this.state;
        const disabled = !fieldLabel;
        const disabledMessage = <FormattedMessage id='youMustEnterLabelFirst' />;
        const addNewButton =  
            <div key='new-unit-btn'>
                {
                    tooltipWrapper(
                        true,
                        <FormattedMessage id='addNewReferenceGroup' />,
                        <button 
                            type="button" 
                            className="btn btn-primary" 
                            style={{width:'3em', height:'3em', marginLeft: '5px'}} 
                            onClick={() => this.setState({ addGroupModalOpen: true })} 
                        > 
                            + 
                        </button>
                    )
                }
            </div>
        return tooltipWrapper(
            disabled,
            disabledMessage,
            <div className="col-6 offset-3">
                <SelectField
                    type='field-ref-group'
                    id='field-ref-group-select'
                    name='fieldReferenceGroup'
                    key={fieldRefGroups.length}
                    fieldValues={fieldRefGroups.map(g => ({ value: g.name, label: g.name }))}
                    value={''}
                    title={<FormattedMessage id='fieldReferenceGroup' />}
                    multi={false}
                    breakrow={false}
                    width={12}
                    error={false}
                    touched={false}
                    disabled={disabled}
                    onBlur={() => null}
                    onChange={(_, value) => {
                        this.setState({ groupName: value });
                    }}
                    appendEls={[addNewButton]}
                    menuPlacement={this.props.menuPlacement || 'auto'}
                />
            </div>
        );
    }

    handleSubmit = async (values, { setSubmitting }) => {
        const { createFieldReferenceGroup, template } = this.props;
        const docTemplateId = template.id;
        const cleanupState = (error) => {
            setSubmitting(false)
            this.setState({ addGroupModalLoading: false, addGroupModalOpen: false });
            if (!error) {
                this.props.onGroupAdded({
                    name: values.name,
                    document_template_id: docTemplateId
                })
            }
        }
        this.setState({ error: undefined, addGroupModalLoading: true }, () =>
            createFieldReferenceGroup(
                {
                    name: values.name,
                    document_template_id: docTemplateId
                },
                () => cleanupState(false),
                () => cleanupState(true)
            )
        );
    }

    isGroupOptionDisabled = (ref, group) => {
        const { groups } = this.state;
        const { fieldRefGroupRefs } = this.props;

        // if there are no refs in the group, we can add
        const refsInCurrentGroup = groups.filter(g => g.group === group.name);
        if (refsInCurrentGroup.length === 0) {
            return false;
        }
        // the group can not contain references of a different type
        const refInGroupWithDifferentType = refsInCurrentGroup.find(gr => (gr.literal.indexOf('[$' + ref.type) < 0))
        if (refInGroupWithDifferentType) {
            return true;
        }
        // if there are any references in the group which don't contain the field in question in their field compatibility list, reject
        const refsWithMetadataInCurrentGroup = fieldRefGroupRefs.filter(frgr => !!refsInCurrentGroup.find(r => r.literal === frgr.field_reference_literal));
        const refInGroupWithoutFieldInCompatibilityList = refsWithMetadataInCurrentGroup
            .find(groupRef => 
                (!groupRef.compatible_field_ids || !groupRef.compatible_field_ids.find(id => String(id) === String(ref.field.id)))
                && (!groupRef.compatible_model_ids || !groupRef.compatible_model_ids.find(id => String(id) === String(ref.field.id))));
        return refInGroupWithoutFieldInCompatibilityList !== undefined;
    }

    fieldRefWithGroup = (ref) => {
        const { fieldRefGroups } = this.props;
        const { groups } = this.state;
        const currentGroup = groups.find(gr => gr.literal === ref.literal);
        const currentGroupName = currentGroup ? currentGroup.group : '';
        const addNewButton =  
            <div key='new-unit-btn'>
                {
                    tooltipWrapper(
                        true,
                        <FormattedMessage id='addNewReferenceGroup' />,
                        <button 
                            type="button" 
                            className="btn btn-primary" 
                            style={{width:'3em', height:'3em', marginLeft: '5px'}} 
                            onClick={() => this.setState({ addGroupModalOpen: true })} 
                        > 
                            + 
                        </button>
                    )
                }
            </div>

        const customStyles = {
            control: base => ({
                ...base,
                boxShadow: 'none'
            }),
            multiValueLabel: (provided, state) => ({
                ...provided,
                maxWidth: '10em'
            }),
            menu: (provided, state) => ({
                ...provided,
                zIndex: '20'
            }),
            singleValue: (provided, state) => {
                const opacity = 1;
                const transition = 'opacity 300ms';
                const color = '#495057';
            
                return { ...provided, opacity, transition, color };
            },
            menuList: (provided, state) => ({
                ...provided,
                maxHeight: '200px'
            }),
        };

        const Option = props => {
            return (
                props.isDisabled ?
                    <Tooltip
                        placement={'left'}
                        trigger={['hover']}
                        overlay={<FormattedMessage id="refGroupNotCompatible" />}
                    >
                        <div>
                            <components.Option {...props} />
                        </div>
                    </Tooltip>
                    :
                    <components.Option {...props} />
            );
          };

        const options = fieldRefGroups.map(g => ({ value: g.name, label: g.name, isDisabled: this.isGroupOptionDisabled(ref, g) }))
        const selectedValue = options.find(o => o.value === currentGroupName);

        return (
            <div className='row' key={ref.literal}>
                <div className='col-6'>
                    <h6>{ref.label}</h6>
                </div>
                <div className={`pd-b-15 col-6`} style={{ display: 'inline-block' }}>
                    <div className="field-select ">
                        <Select
                            id='field-ref-group-select'
                            className="select-input-field"
                            placeholder={<FormattedMessage id='fieldReferenceGroup' />}
                            options={options}
                            isMulti={false}
                            components={{ IndicatorSeparator: () => null, Option }}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary25: '#eeeeee',
                                    primary: '#00336B'
                                }
                            })}
                            onChange={selectedValue => {
                                const value = selectedValue ? selectedValue.value : null;
                                this.setState(prevState => {
                                    const groups = prevState.groups;
                                    let target = groups.find(g => g.literal === ref.literal);
                                    if (target) {
                                        target.group = value;
                                    } else {
                                        target = { group: value, literal: ref.literal };
                                    }
                                    const newGroups = groups.filter(g => g.literal !== ref.literal);
                                    newGroups.push(target);
                                    return { groups: newGroups };
                                });
                            }}
                            value={selectedValue}
                            styles={customStyles}
                            isDisabled={false}
                            isClearable={true}
                            menuPlacement={'auto'}
                        />
                        {!!selectedValue &&
                            <Tooltip trigger={['hover']} overlay={<span><FormattedMessage id='fieldReferenceGroup' /></span>} mouseEnterDelay={2}>
                                <div className="input-placeholder">
                                    <FormattedMessage id='fieldReferenceGroup' />
                                </div> 
                            </Tooltip>
                        }
                        {addNewButton}
                    </div>
                </div>
            </div>
        );
    }

    groupDelete = group => {
        const groupIsSelected = () => { 
            const { groupsToDelete } = this.state;
            const isChecked = (groupsToDelete.indexOf(group.name) > -1);
            return isChecked;
        };
        const setGroupSelected = () => this.setState(prevState => {
            let prevGroups = prevState.groupsToDelete;
            if (prevGroups.indexOf(group.name) > -1) {
                prevGroups = prevGroups.filter(g => g !== group.name);
            } else {
                prevGroups.push(group.name);
            }
            return { groupsToDelete: prevGroups };
        })
        return (
            <div className='col-4' key={group.name}>
                <div className={`checkbox-field withPadding pd-b-5`} onClick={(e) => e.stopPropagation()}>
                    <label className="ckbox">
                        <input type="checkbox" checked={groupIsSelected()} onChange={() => { setGroupSelected()}}/>
                        <span className={`ckbox-label-text${groupIsSelected() ? ' checked' : ''}`} onClick={(e) => e.stopPropagation()}>{ group.name }</span>
                    </label>
                </div>
            </div>
        )
    }

    componentDidMount = () => {
        const { fieldRefGroupRefs } = this.props;
        this.setState({ groups: fieldRefGroupRefs.map(frg => ({ group: frg.name, literal: frg.field_reference_literal })) });
    }   

    render() {
        const { showModal, intl: { formatMessage }, fieldRefs = [], onFieldRefGroupsSet, fieldRefGroupRefs, fieldRefGroups } = this.props;
        let modalBody = null;
        if (this.props.view === 'edit') {
            if (fieldRefs.length > 0) {
                modalBody = fieldRefs.map(r => this.fieldRefWithGroup(r));
            } else {
                modalBody = <FormattedMessage id='noFieldRefsInGroups' />
            }
        } else if (this.props.view === 'delete') {
            modalBody = (
                <>
                    <span><FormattedMessage id={(fieldRefGroups.length > 0) ? 'deleteGroupsHeader' : 'noRefGroupsInDoc'}/></span>
                    <div className='row'>
                        { fieldRefGroups.map(r => this.groupDelete(r)) }
                    </div>
                </>
            );
        }

        return (
            <>
                <ReactModal
                    isOpen={showModal}
                    onRequestClose={() => this.setModalVisibility(false)}
                    className="modal-block dialog"
                    overlayClassName="modal-overlay gray"
                    style={{content: {width: '50%', maxHeight: '90%', overflowY: 'auto', overflowX: 'hidden'}}}
                >
                    <LoadingOverlay
                        active={this.state.groupEditorModalLoading}
                        spinner
                        text={<FormattedMessage id='common.loading'/>}
                    >
                        <div role="document">
                            <div className="modal-content bd-0">
                                <div className="modal-header pd-x-20">
                                    <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                                        {
                                            this.props.view === 'edit' ?
                                            <FormattedMessage id="fieldReferenceGroups" />
                                            :
                                            <FormattedMessage id="deleteGroups" />
                                        }
                                    </h6>
                                </div>
                                <div className="modal-body pd-x-20">
                                    { modalBody }
                                </div>
                                <div className="modal-footer justify-content-center">
                                    <button 
                                        type="button" 
                                        className="btn btn-primary btn-block col-3 pd-x-25" 
                                        onClick={() => {
                                            if (this.props.view === 'edit') {
                                                onFieldRefGroupsSet(this.state.groups);
                                                this.setModalVisibility(false);
                                            } else if (this.props.view === 'delete') {
                                                const { deleteFieldReferenceGroup, template, onDeleteConfirm } = this.props;
                                                this.setState({ groupEditorModalLoading: true }, () => {
                                                    const promises = [];
                                                    for (const target of this.state.groupsToDelete) {
                                                        promises.push( new Promise(resolve => {
                                                            deleteFieldReferenceGroup({ document_template_id: template.id, name: target }, resolve);
                                                        }));
                                                    }
                                                    Promise.all(promises).then(() => {
                                                        onDeleteConfirm(() => {
                                                            this.setState({ groups: fieldRefGroupRefs.map(frg => ({ group: frg.name, literal: frg.field_reference_literal })), groupEditorModalLoading: false, view: 'edit' });
                                                            this.setModalVisibility(false);
                                                        })
                                                    })
                                                })
                                            }
                                        }}
                                    >
                                        <FormattedMessage id="common.confirm" />
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-block col-3 pd-x-25"
                                        style={{marginTop: '0'}}
                                        onClick={() => {
                                            if (this.state.view === 'delete') {
                                                this.setState({ groupsToDelete: [], view: 'edit' });
                                            } else {
                                                this.setState({ groups: fieldRefGroupRefs.map(frg => ({ group: frg.name, literal: frg.field_reference_literal })) });
                                                this.setModalVisibility(false);
                                            }
                                        }}
                                    >
                                        <FormattedMessage id="exit" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </LoadingOverlay>
                </ReactModal>

                <ReactModal
                    isOpen={this.state.addGroupModalOpen}
                    onRequestClose={() => this.setState({ addGroupModalOpen: false })}
                    className="modal-block dialog"
                    overlayClassName="modal-overlay gray"
                    style={{content: {width: '50%'}}}
                >
                    <LoadingOverlay
                        active={this.state.addGroupModalLoading}
                        spinner
                        text={<FormattedMessage id='common.loading'/>}
                    >
                        <div className="modal-dialog-centered" role="document">
                            <div className="modal-content bd-0">
                                    <div className="modal-header pd-x-20">
                                        <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                                            <FormattedMessage id="addNewReferenceGroup" />
                                        </h6>
                                    </div>
                                <Formik
                                    initialValues={{ name: '' }}
                                    validationSchema={Yup.object().shape({
                                        name: Yup.string()
                                            .min(1, <FormattedMessage id="validation.length.min" values={{ length: 1 }} />)
                                            .required(<FormattedMessage id="validation.required" />),
                                    })}
                                    onSubmit={this.handleSubmit}
                                    render={({ isSubmitting, submitForm, validateForm, values }) => (
                                        <Form>
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <TextField id="name" title={formatMessage({ id: 'groupName' })} type='text' className="form-control" showIsRequired={true} showError={true} description={formatMessage({ id: "referenceGroupNameHelper" })} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer justify-content-center">
                                                <button 
                                                    type="button" 
                                                    className="btn btn-primary btn-block col-3 pd-x-25" 
                                                    disabled={isSubmitting || !values.name} 
                                                    onClick={async () => {
                                                        const result = await validateForm()
                                                        var errorNames = Object.keys(result);
                                                        if (errorNames.length === 0) {
                                                            submitForm();
                                                        } else {
                                                            Notifications.error(<FormattedMessage id='common.requiredFieldsMissing' />);
                                                        }
                                                    }}
                                                >
                                                    <FormattedMessage id="addNewReferenceGroup" />
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary btn-block col-3 pd-x-25"
                                                    style={{marginTop: '0'}}
                                                    onClick={() => this.setState({ addGroupModalOpen: false })}
                                                >
                                                    <FormattedMessage id="common.close" />
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                />
                            </div>
                        </div>
                    </LoadingOverlay>
                </ReactModal>
            </>
        );
    }
}

const mapStateToProps = state => ({
    models: state.models.list,
    totalModels: state.models.total,
    authenticated: state.identity.authenticated,
    location: state.router.location,
    indices: state.indices.list,
    totalIndices: state.indices.total
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getModels,
            loadModel,
            importFields,
            getTransaction,
            getIndices,
            createFieldReferenceGroup,
            deleteFieldReferenceGroup,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(FieldGroupPickerModal));
