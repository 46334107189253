export const USERS_GET_ROLE_ASSIGNED_PERMISSIONS = `
    query($role_id: Int!) {
        role_permission_list(role_id: $role_id) {
            id
            is_admin_level
            code
            title
        }
    }
`;
