export const FONTS = [
    'Inherit from previous',
    'Arial',
    'Baskerville',
    'Calibri',
    'Cambria',
    'Courier',
    'Garamond',
    'Georgia',
    'Helvetica',
    'Lato',
    'Nunito',
    'Open Sans',
    'Roboto',
    'Times',
    'Trebuchet MS',
    'Verdana',
    'Work Sans'
];

export const FONT_SIZES = [
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    18,
    24,
    36,
    48,
    64,
    72,
    96,
    144,
    288,
];
