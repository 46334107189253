import React from 'react';
import { Redirect } from 'react-router-dom';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import AlertList from './components/list';
import AlertNew from './components/alert';
import Page from '../../components/page';

class Intelligence extends React.Component {
    render() {
        const { user, company, permissions } = this.props;
        if (!user || !company || !permissions) return null;

        return (
            <Switch>
                <Route exact path="/intelligence/alerts" component={props => <AlertList {...props} />} />
                <Route path="/intelligence/alerts/:id" render={props => <AlertNew key={props.match.params.id} {...props} parents={[AlertList.meta]} setRootLoadingState={this.props.setRootLoadingState} />} />
            </Switch>
        );
    }
}

Intelligence.meta = {
    title: <FormattedMessage id="page.intelligence.title" defaultMessage="Dashboard" />,
    routes: ['/intelligence/alerts'],
    icon: 'ion-ios-lightbulb-outline',
    menus: { main: true, user: false },
    order: 50,
    onlySubscribed: true
};

const mapStateToProps = state => ({
    user: state.identity.user,
    company: state.identity.company,
    permissions: state.identity.permissions
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Intelligence);
