import React from 'react';
import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import Page from '../../../../components/page';
import { getProfile } from '../../../../reducers/identity';
import { loadModel } from '../../../../reducers/models';
import DocumentConfigurationList from './list';
import { setMenuVisibility } from '../../../../reducers/menus';
import DocumentConfiguratorItems from './components/items';
import DocumentConfiguratorProperties from './components/properties';
import DocumentConfiguratorPreview from './components/preview';
import { getDocumentTemplate, updateDocumentTemplate, getDocumentSample } from '../../../../reducers/document';
import '../../models.scss';
import '../../../../components/fields/fields.scss';
import { DocumentEditorContainerComponent, Toolbar } from '@syncfusion/ej2-react-documenteditor';
import ExcelDocumentTemplate from './components/excelDocumentTemplate';
import WordDocumentTemplate from './components/wordDocumentTemplate';

DocumentEditorContainerComponent.Inject(Toolbar);

class DocumentConfiguration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            previewData: "",
            loadingPreview: false,
            isExcel: false,
            contentHeight: 500
        };

        this.persistChanges = this.persistChanges.bind(this);
        this.contentRef = React.createRef();
    }

    measureElement = element => {
        const DOMNode = ReactDOM.findDOMNode(element);
        return window.innerHeight - DOMNode.offsetTop - 270
    }

    componentDidMount() {
        const { match, loadModel, getDocumentTemplate, getDocumentSample } = this.props;

        window.addEventListener('resize', () => this.contentRef.current && this.setState({ contentHeight: this.measureElement(this.contentRef.current) }) );
        setTimeout(() => {
            var el = document;
            var event = document.createEvent('HTMLEvents');
            event.initEvent('resize', true, false);
            el.dispatchEvent(event);
        }, 1000);

        loadModel(match.params.modelId);
        getDocumentTemplate(match.params.id);
        this.setState({ loadingPreview: true }, () => {
            getDocumentSample(match.params.id, (result) => {
                this.setState({
                    loadingPreview: false,
                    previewData: result.base64data
                });
            })
        })
        

        this.updateInterval = setInterval(() => {
            this.persistChanges();
        }, 5000);
    }

    onDataBound() {
    }

    async componentWillUnmount() {
        await this.persistChanges();
        clearInterval(this.updateInterval);
    }

    persistChanges = async () => {
        const { template, templateChanged, updateDocumentTemplate, getDocumentSample } = this.props;
        if (template.document_type === 'word' || template.document_type === 'excel') {
            this.setState({
                loadingPreview: false
            });
            return null;
        }
        if (templateChanged) {
            this.setState({ loadingPreview: true }, () => {
                updateDocumentTemplate(template, () => {
                    getDocumentSample(template.id, (result) => {
                        this.setState({
                            loadingPreview: false,
                            previewData: result.base64data
                        });
                    });
                });
            });
        }
    };

    addMacro() {
        let proxy = this;
        proxy.documenteditor.documentEditor.editor.insertText('{{Testing}}');
    }

    saveSpreadsheet() {
        // this.spreadsheet.previewData
    }

    render() {
        const { model, template, parents, fields } = this.props;
        if (!model || !template || !fields) return null;
        let documentComponent = <div className="row pd-x-15 documents-configurator">
                <div className="col-2 pd-l-0 pd-r-5">
                    <DocumentConfiguratorItems />
                </div>
                <div className="col-2 pd-x-5">
                    <DocumentConfiguratorProperties />
                </div>
                <div className="col-8 pd-l-5 pd-r-0">
                    <DocumentConfiguratorPreview
                        isLoading={this.state.loadingPreview}
                        imageData={this.state.previewData}
                    />
                </div>
                <div>
                    
                </div>
            </div>;
        if (template.document_type === 'word') {
            documentComponent = <WordDocumentTemplate {...this.props} manualHeight={this.state.contentHeight} />
        } else if (template.document_type === 'excel') {
            documentComponent = <ExcelDocumentTemplate {...this.props} manualHeight={this.state.contentHeight} />;
        }

        return (
            <div ref={this.contentRef}>
                <Page
                    {...{ ...DocumentConfiguration.meta, title: template.title, routes: [`/models/${model.id}/document-templates/${template.id}`] }}
                    parents={[
                        ...parents,
                        { title: model.title, routes: ['/models/'] },
                        { ...DocumentConfigurationList.meta, routes: [`/models/${model.id}/document-templates`] }
                    ]}
                >
                    {documentComponent}
                </Page>
            </div>
        );
    }
}

DocumentConfiguration.meta = {
    showTitleBlock: false,
    panel: false,
    title: <FormattedMessage id="page.models.document.configuration.title" />,
    subtitle: <FormattedMessage id="page.models.document.configuration.subtitle" />,
    routes: ['/models/'],
    icon: 'ion-clipboard',
    menus: { main: false, user: false },
    order: 0
};

const mapStateToProps = state => ({
    company: state.identity.company,
    model: state.fields.model,
    fields: state.fields.items,
    fieldTypes: state.fields.types,
    template: state.document.instance,
    templateChanged: state.document.templateChanged
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getProfile,
            loadModel,
            getDocumentTemplate,
            getDocumentSample,
            setMenuVisibility,
            updateDocumentTemplate
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DocumentConfiguration);
