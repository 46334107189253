import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getLinkTypes, loadLink, useLink } from '../../reducers/links';
import { history } from '../../configuration/history';
import { resolveLinkAction } from './linkRouter';
import { Notifications } from '../../components/notifications';
import { FormattedMessage } from 'react-intl';

class Links extends React.Component {
    componentDidMount() {
        const { match, getLinkTypes, loadLink, authenticated } = this.props;
        const link_name = match && match.params ? match.params.token : undefined;

        getLinkTypes(types =>
            loadLink(
                link_name,
                linkData => {
                    const action = resolveLinkAction(linkData, types, authenticated);
                    if (!action) {
                        Notifications.error(<FormattedMessage id="page.links.invalid.link" />);
                        history.push('/' + window.location.search);
                    } else {
                        action();
                    }
                },
                () => history.push('/' + window.location.search)
            )
        );
    }

    render() {
        const { children } = this.props;
        return <div>{children}</div>;
    }
}

Links.meta = {
    routes: ['/l/'],
    menus: { main: false, user: false },
    order: 0
};

Links.defaultProps = {
    link: undefined
};

const mapStateToProps = state => ({
    authenticated: state.identity.authenticated,
    types: state.links.types
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getLinkTypes,
            loadLink,
            useLink
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Links);
