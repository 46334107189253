import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import ReactModal from 'react-modal';
import Tooltip from 'rc-tooltip';
import Page from '../../../components/page';
import Table from '../../../components/table';
import { getUserRoles, getPermissions } from '../../../reducers/users';
import CreateRole from '../components/forms/createRole';

class CompanyRoles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: { open: false, data: undefined }
        };
    }

    componentDidMount() {
        const { company, roles, getUserRoles, permissions, getPermissions } = this.props;
        if (!roles) getUserRoles({ company_id: company.id, order_by: ['id'] });
        if (!permissions) getPermissions();
    }

    setModalVisibility = (open, data) => {
        this.setState({ ...this.state, modal: { open, data: !!open ? data : undefined } });
    };

    loadData = (options, onComplete) => {
        const { getUserRoles, match } = this.props;
        return getUserRoles({ company_id: match.params.id, ...options }, onComplete);
    };

    getColumns = () => {
        return [
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block text-center',
                Header: <FormattedMessage id="common.id" />,
                width: 100,
                resizable: false,
                id: 'id',
                accessor: d => d.id
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block',
                Header: <FormattedMessage id="common.title" />,
                id: 'title',
                accessor: d => d.title
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block text-center',
                Header: '',
                id: 'actions',
                sortable: false,
                resizable: false,
                accessor: d => (
                    <React.Fragment>
                        <div className="cell-actions float-right">
                            <Tooltip placement={'top'} trigger={['hover']} overlay={<FormattedMessage id="common.edit" />}>
                                <i
                                    className="material-icons-outlined table-action-icon"
                                    style={{ borderLeft: 'none' }}
                                    onClick={() => this.setModalVisibility(true, d)}
                                >
                                    edit
                                </i>
                            </Tooltip>
                        </div>
                    </React.Fragment>
                )
            },
            {
                headerClassName: 'd-sm-block d-none',
                className: 'd-sm-block d-none',
                Header: <FormattedMessage id="common.isBuiltIn" />,
                width: 100,
                id: 'is_builtin',
                accessor: d => (!!d.is_builtin ? <FormattedMessage id="common.yes" /> : <FormattedMessage id="common.no" />)
            }
        ];
    };

    renderActions = () => {
        return (
            <div className="btn-group pd-t-10">
                <span className="btn btn-outline-info" onClick={() => this.setModalVisibility(true)}>
                    <FormattedMessage id="page.companies.roles.setup.role" />
                </span>
            </div>
        );
    };

    render() {
        const { modal } = this.state;
        const { parents, company, roles = [], total, permissions } = this.props;
        if (!company || !permissions) return null;

        return (
            <React.Fragment>
                <Page {...CompanyRoles.meta} parents={parents} actions={this.renderActions()}>
                    <Table columns={this.getColumns()} data={roles} total={total} dataLoader={(options, onComplete) => this.loadData(options, onComplete)} />
                </Page>
                <ReactModal
                    isOpen={modal.open}
                    onRequestClose={() => this.setModalVisibility(false)}
                    className="modal-block dialog"
                    overlayClassName="modal-overlay gray"
                >
                    <CreateRole companyId={company.id} permissions={permissions} role={modal.data} onCancel={() => this.setModalVisibility(false)} />
                </ReactModal>
            </React.Fragment>
        );
    }
}

CompanyRoles.meta = {
    title: <FormattedMessage id="page.companies.roles.title" />,
    subtitle: <FormattedMessage id="page.companies.roles.subtitle" />,
    routes: ['/settings/roles'],
    icon: 'ion-ios-world-outline',
    menus: { main: false, user: false },
    order: 0
};

const mapStateToProps = state => {
    return {
        company: state.identity.company,
        roles: state.users.roles,
        total: state.users.roles ? state.users.roles.length : 0,
        permissions: state.users.permissions
    };
};
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getUserRoles,
            getPermissions
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanyRoles);
