import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import ReactModal from 'react-modal';
import { Fields } from 'components/fields';
import Dropzone from 'react-dropzone';
import Page from 'components/page';
import { 
    getProfile, 
    uploadAvatar, 
    changeOwnPassword, 
    otpAuthenticate, 
    otpRequestDisable, 
    otpDisableConfirm, 
    otpEnable,
} from 'reducers/identity';
import thumb from 'assets/avatar.svg';
import ConfirmModal from 'components/modals/confirm'

import './profile.scss';
import { logout } from 'reducers/identity';
import { history } from 'configuration/history';
import { Notifications } from 'components/notifications';

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: { open: false, type: undefined }
        };
    }

    setModalVisibility = (visible, type) => this.setState({ ...this.state, modal: { open: visible, type: !!visible ? type : undefined } });

    componentDidMount() {
        const { getProfile, user } = this.props;
        if (!user) {
            getProfile();
        }
    }

    getLocation = () => {
        const { company } = this.props;
        const { country_code2, city, zip, address, address_1, address_2 } = company;
        const location = [];
        if (!!country_code2 && country_code2 !== '') location.push(country_code2);
        if (!!city && city !== '') location.push(city);
        if (!!zip && zip !== '') location.push(zip);
        if (!!address && address !== '') location.push(address);
        if (!!address_1 && address_1 !== '') location.push(address_1);
        if (!!address_2 && address_2 !== '') location.push(address_2);

        return location.join(', ');
    };

    renderChangePassword = () => {
        const { changeOwnPassword } = this.props;
        return (
            <div className="modal-dialog wd-350-force" role="document">
                <div className="modal-content bd-0">
                    <div className="modal-header pd-x-20">
                        <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                            <FormattedMessage id="page.profile.changePassword" />
                        </h6>
                    </div>
                    <Formik
                        initialValues={{ current_password: '', new_password: '', password_retype: '' }}
                        validationSchema={Yup.object().shape({
                            current_password: Yup.string()
                                .matches(/^[^\r\n<>@#$%&\^\*{{}}/\+=~\\`]*$/, <FormattedMessage id="invalidCharactersPassword" />)
                                .min(8, <FormattedMessage id="validation.length.min" values={{ length: 8 }} />)
                                .required(<FormattedMessage id="validation.required" />),
                            new_password: Yup.string()
                                .matches(/^[^\r\n<>@#$%&\^\*{{}}/\+=~\\`]*$/, <FormattedMessage id="invalidCharactersPassword" />)
                                .min(8, <FormattedMessage id="validation.length.min" values={{ length: 8 }} />)
                                .required(<FormattedMessage id="validation.required" />),
                            password_retype: Yup.string()
                                .oneOf([Yup.ref('new_password'), null], 'Passwords must match.')
                                .required(<FormattedMessage id="validation.required" />)
                        })}
                        onSubmit={values => changeOwnPassword(values, () => this.setModalVisibility(false))}
                        render={() => (
                            <Form noValidate>
                                <React.Fragment>
                                    <div className="modal-body pd-5 pd-t-15">
                                        <div className="form-group col-12">
                                            <Fields.Input type="password" name="current_password" className="form-control" showError={true} />
                                        </div>
                                        <div className="form-group col-12">
                                            <Fields.Input type="password" name="new_password" className="form-control" showError={true} />
                                        </div>
                                        <div className="form-group col-12">
                                            <Fields.Input type="password" name="password_retype" className="form-control" showError={true} />
                                        </div>
                                    </div>

                                    <div className="modal-footer justify-content-center">
                                        <button type="submit" className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium">
                                            <FormattedMessage id="common.submit" />
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                            onClick={() => this.setModalVisibility(false)}
                                        >
                                            <FormattedMessage id="common.cancel" />
                                        </button>
                                    </div>
                                </React.Fragment>
                            </Form>
                        )}
                    />
                </div>
            </div>
        );
    };

    handleSubmit = (values) => {
        this.setState({ passcode: values.otp });
    };

    render() {
        const { modal } = this.state;
        const { user, company, getProfile, uploadAvatar, otpRequestDisable, otpDisableConfirm, otpEnable, logout } = this.props;
        if (!user || !company) return null;

        const multiFactorAuthRequired = user.mfa_required;

        const { company_name } = company;
        const { name, avatar } = user;
        const imageUploaded = !!avatar && avatar.thumb && avatar.thumb.url;
        const url = !!imageUploaded ? avatar.thumb.url : thumb;

        return (
            <React.Fragment>
                <Page title={Profile.meta.title} subtitle={Profile.meta.subtitle} showTitle={false} showSubtitle={false}>
                    <div className="profile-page">
                        <div className="row">
                            <div className="col-12" style={{ marginTop: '6vw' }}>
                                <div className={`avatar${!!imageUploaded ? ' image-uploaded' : ''}`} style={{ backgroundImage: `url(${url})` }}>
                                    <Dropzone onDrop={files => uploadAvatar(files, () => getProfile())}>
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()} className="upload-wrapper">
                                                <input {...getInputProps()} />
                                            </div>
                                        )}
                                    </Dropzone>

                                    <div className='profile-icon' >
                                        <Dropzone onDrop={files => uploadAvatar(files, () => getProfile())}>
                                            {({ getRootProps, getInputProps }) => (
                                                <div {...getRootProps()} className="profile-upload-wrapper"> 
                                                    <i className={'icon ion-ios-camera-outline wd-18 rounded-circle'} style={{ fontSize: 40, cursor:`pointer`}}/>
                                                    <input {...getInputProps()} />                                                
                                                </div>
                                            )}
                                        </Dropzone>
                                    </div>
                                </div>
                               
                            </div>
                            <div className="col-12 tx-center">
                                <h4 className="profile-name">{name}</h4>
                                <p>{company_name}</p>
                                <span>{this.getLocation()}</span>
                            </div>
                            <div className="col-12 pd-t-60">
                                <div className="row">
                                    <div className="col-sm-12 col-lg-4 offset-lg-4">
                                        <button
                                            type="button"
                                            className="btn btn-block btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                            onClick={() => this.setModalVisibility(true, 'change-password')}
                                        >
                                            <FormattedMessage id="page.profile.changePassword" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 pd-t-20">
                                <div className="row">
                                    <div className="col-sm-12 col-lg-4 offset-lg-4">
                                        <button
                                            type="button"
                                            className="btn btn-block btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                            onClick={() => this.setState({ confirmMfaToggleModalOpen: true })}
                                        >
                                            <FormattedMessage id={multiFactorAuthRequired ? 'disableMfa' : 'enableMfa'} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Page>
                <ReactModal
                    isOpen={modal.open}
                    onRequestClose={() => this.setModalVisibility(false)}
                    className="modal-block dialog"
                    overlayClassName="modal-overlay gray"
                >
                    {this.renderChangePassword()}
                </ReactModal>
                <ConfirmModal
                    key='confirm-mfa-toggle'
                    open={this.state.confirmMfaToggleModalOpen}
                    setOpen={(o) => this.setState({ confirmMfaToggleModalOpen: o })}
                    loading={this.state.confirmMfaToggleModalLoading}
                    stayOpenOnConfirm={true}
                    onConfirm={() => {
                        if (multiFactorAuthRequired) {
                            otpRequestDisable();
                            this.setState({ confirmMfaToggleModalOpen: false, disableMfaModalOpen: true, confirmMfaToggleModalOpen: false });
                        } else {
                            this.setState({ confirmMfaToggleModalLoading: true }, () => {
                                const onComplete = () => {
                                    Notifications.success(<FormattedMessage id='successfullyEnabledMFA' />)
                                    logout(() => history.push('/login' + window.location.search))
                                }
                                otpEnable(null, onComplete, onComplete);
                            })
                        }
                    }}
                    title={<FormattedMessage id='common.confirmVerb' values={{ verb: multiFactorAuthRequired ? <FormattedMessage id='common.disable' /> : <FormattedMessage id='common.enable' />}} />}
                    body={
                        multiFactorAuthRequired ?
                            <FormattedMessage id={'disableMfaConfirmMessage'} />
                            :
                            <>
                                <p><FormattedMessage id={'enableMfaConfirmMessage'} /></p>
                                <p style={{color: 'red'}}><strong><FormattedMessage id={'enableMfaConfirmMessage2'} /></strong></p>
                            </>
                    }
                />
                <ConfirmModal
                    key='confirm-otp-resend'
                    open={this.state.resendOtpModalOpen}
                    setOpen={(o) => this.setState({ resendOtpModalOpen: o })}
                    onConfirm={() => {
                        otpRequestDisable();
                        this.setState({ disableMfaModalOpen: true });
                    }}
                    onCancel={() => {
                        this.setState({ disableMfaModalOpen: true });
                    }}
                    title={<FormattedMessage id='common.confirmVerb' values={{ verb: 'Resend'}} />}
                    body={<FormattedMessage id='resendPasscodeConfirmMessage'/>}
                    confirmLabel={<FormattedMessage id='send' />}
                />
                <ConfirmModal
                    key={'disable-mfa-modal'}
                    open={this.state.disableMfaModalOpen}
                    setOpen={(o) => this.setState({ disableMfaModalOpen: o })}
                    loading={this.state.disableMfaModalLoading}
                    onConfirm={() => {
                        const { passcode } = this.state;
                        this.setState({ disableMfaModalLoading: true }, () => {
                            const onComplete = () => {
                                getProfile(this.setState({ disableMfaModalLoading: false, disableMfaModalOpen: false, }));
                                Notifications.success(<FormattedMessage id='successfullyDisabledMFA' />);
                            }
                            otpDisableConfirm({ passcode }, onComplete, onComplete);
                        })
                    }}
                    stayOpenOnConfirm={true}
                    title={<FormattedMessage id='authorizeDisable' />}
                    body={(
                        <Formik
                            initialValues={{ otp: '' }}
                            onSubmit={this.handleSubmit}
                            validationSchema={Yup.object().shape({
                                otp: Yup.string()
                                    .length(6, <FormattedMessage id="otpLengthMessage" />)
                                    .matches(/^\d+$/, <FormattedMessage id='otpNumOnly' />)
                                    .required(<FormattedMessage id="validation.required" />)
                            })}
                            render={({ handleChange, submitForm, setFieldValue }) => (
                                <Form 
                                    noValidate 
                                    className="login"
                                    onChange={async e => {
                                        await handleChange(e);
                                        submitForm();
                                    }}
                                >
                                    <p>
                                        <FormattedMessage id={'disableMfaMessage'} />
                                    </p>
                                    <div className="form-group">
                                        <Fields.Input 
                                            name="otp" 
                                            className="form-control" 
                                            showError={true}
                                            type='text'
                                        />
                                    </div>
                                    <a href='#' onClick={e => { e.stopPropagation(); e.preventDefault(); this.setState({ resendOtpModalOpen: true, disableMfaModalOpen: false }); }}><FormattedMessage id='sendNewPasscode'/></a>
                                    <br/>
                                </Form>
                            )}
                        />
                    )}
                    confirmLabel={<FormattedMessage id='disable' />}
                />
            </React.Fragment>
        );
    }
}

Profile.meta = {
    title: <FormattedMessage id="page.profile.title" />,
    subtitle: <FormattedMessage id="page.profile.subtitle" />,
    routes: ['/profile'],
    icon: 'ion-ios-person',
    menus: { main: false, user: true },
    order: 0
};

const mapStateToProps = state => ({
    user: state.identity.user,
    company: state.identity.company
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getProfile,
            uploadAvatar,
            changeOwnPassword,
            otpAuthenticate, 
            otpRequestDisable,
            otpDisableConfirm,
            otpEnable,
            logout,
        },
        dispatch
    );
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Profile);
