/**
 * Request password change.
 */
export const PASSWORD_CHANGE_REQUEST = `
    mutation M($login: String!) {
        reset_password(email: $login) {
            success
            action
            error_code
        }
    }
`;
