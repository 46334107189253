import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Fields } from '../../../../components/fields';
import { previewPayment } from '../../../../reducers/payment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import PulseLoader from 'react-spinners/PulseLoader'

class Subscription extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentPreviewLoading: false,
            seats: 1,
            previewAmount: 0,
            lineItems: []
        };
    }

    componentDidMount(){
        const {
            company,
            isSubscriptionChange
        } = this.props;
        this.setState({seats: company.subscription_id ? company.subscription_seats : 1}, () => {
            if (isSubscriptionChange){
                this.loadPaymentPreview();
            }
        })
    }

    loadPaymentPreview() {
        this.setState({paymentPreviewLoading: true});
        this.props.previewPayment(this.props.plan.id, this.state.seats, (preview) => {
            this.setState({paymentPreviewLoading: false, previewAmount: Number(preview.amount_due).toFixed(2), lineItems: preview.line_items});
        },
        (error) => {
            this.setState({paymentPreviewLoading: false});
        })
    }

    render() {
        const { price, type, onCancel, onSubmit = () => null, initialSeats, isSubscriptionChange, isSubmitting, currency } = this.props;
        const currencySymbol = currency === 'USD' ? '$' : '€';
        const previewPrice = (this.state.previewAmount / 100).toFixed(2);
        return (
            <div className="modal-dialog wd-500-force" role="document">
                <div className="modal-content bd-0">
                    <div className="modal-header pd-x-20">
                        <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                            <FormattedMessage id="page.settings.setup.sub.dialog.title" />
                        </h6>
                    </div>
                    <Formik
                        initialValues={{
                            seats: (initialSeats && initialSeats > 0) ? initialSeats : 1
                        }}
                        validationSchema={Yup.object().shape({
                            seats: Yup.number()
                                .min(1, <FormattedMessage id="validation.min" values={{ min: 1 }} />)
                                .required(<FormattedMessage id="validation.required" />)
                        })}
                        onSubmit={onSubmit}
                        render={({ values }) => (
                            <Form>
                                <LoadingOverlay
                                    active={isSubmitting}
                                    text={<FormattedMessage id='common.processing'/>}
                                    spinner={<PulseLoader color='white'/>}
                                >
                                    <div className="modal-body pd-0 widget-2 company-info-form">
                                        <div className="pd-x-10 pd-y-5">
                                            <div className="col-12 pd-t-20" />
                                            <div className="form-group col-12">
                                                <div className="row">
                                                    <div className="col-8 pd-r-0">
                                                        <div className="sub-details">
                                                            {
                                                                isSubscriptionChange ?
                                                                (
                                                                    
                                                                    <LoadingOverlay
                                                                        active={this.state.paymentPreviewLoading}
                                                                        text={<FormattedMessage id='common.loading'/>}
                                                                    >
                                                                        <span className="sub-price">{`${currencySymbol}${(previewPrice < 0) ? (previewPrice * -1) : previewPrice}`} {(previewPrice < 0 ? <FormattedMessage id='page.settings.setup.credit'/> : <div/>)}</span>
                                                                        {
                                                                            (this.state.lineItems.length > 0) && 
                                                                            <React.Fragment>
                                                                                <div>
                                                                                {
                                                                                    (previewPrice < 0) ? 
                                                                                    <FormattedMessage id='page.settings.setup.creditDescription'/> :
                                                                                    <FormattedMessage id='page.settings.setup.chargeDescription'/>
                                                                                }
                                                                                </div>
                                                                                <div className="sub-info">
                                                                                    {type === 'annually' && <FormattedMessage id="page.settings.setup.total.for.seats.change.annual" values={{ seats: values.seats, totalCost: ((price * values.seats) / 100).toFixed(2) }} />}
                                                                                    {type !== 'annually' && <FormattedMessage id="page.settings.setup.total.for.seats.change.monthly" values={{ seats: values.seats, totalCost: ((price * values.seats) / 100).toFixed(2) }} />}
                                                                                </div>
                                                                            </React.Fragment>
                                                                        }
                                                                        {
                                                                            (this.state.lineItems.length == 0) && 
                                                                            <div className="sub-info">
                                                                                <FormattedMessage id='page.settings.setup.subscriptionUnchanged'/>
                                                                            </div>
                                                                        }
                                                                    </LoadingOverlay>
                                                                ) : (
                                                                    <React.Fragment>
                                                                        <span className="sub-price">{ `${currencySymbol}${((price * values.seats) / 100) / (type === 'annually' ? 12 : 1).toFixed(2)}` }</span>
                                                                        <div className="sub-info">
                                                                            <FormattedMessage id="page.settings.setup.total.for.seats" values={{ seats: values.seats }} />
                                                                        </div>
                                                                        <div className="sub-info">
                                                                            {type === 'annually' && <FormattedMessage id="page.settings.setup.total.for.seats.annual" values={{ seats: values.seats, totalCost: ((price * values.seats) / 100).toFixed(2) }} />}
                                                                        </div>
                                                                    </React.Fragment>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-4">
                                                        <Fields.Input 
                                                            type="number" 
                                                            name="seats" 
                                                            min={1} 
                                                            max={10000}
                                                            onChangeExt={(_, val) => {
                                                                if (val > 0 && val < 10000) {
                                                                    this.setState({seats: val}, () => {
                                                                        if (isSubscriptionChange){
                                                                            this.loadPaymentPreview();
                                                                        }
                                                                    })
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 pd-t-10" />
                                        </div>
                                    </div>
                                    <div className="modal-footer justify-content-center">
                                        <button 
                                            type="submit" 
                                            className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                            disabled={isSubscriptionChange && this.state.lineItems.length == 0}
                                        >
                                            <FormattedMessage id="common.submit" />
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                            onClick={onCancel}
                                        >
                                            <FormattedMessage id="common.cancel" />
                                        </button>
                                    </div>
                                </LoadingOverlay>
                            </Form>
                        )}
                    />
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        company: state.identity.company,
        plans: state.payment.plans,
        products: state.payment.products
    };
};
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            previewPayment
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Subscription);