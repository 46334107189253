export const MODEL_SET_USERS = `
    type ModelUser {
        user_id: Long!
        include_transactions: Boolean!
    }

    mutation M($model_id: Int!, $model_users: [ModelUser!]) {
        model_set_users(input: { model_id: $model_id, model_users: $model_users }) {
            id
            hash_id
            company_id
            status_id
            status_name
            created_on
            title
            description
            is_used
            split_conditions
            extra_info
            model_type
            model_type_name

            model_users {
                id
            }
            
            model_field_list {
                id
                model_id
                order
                type_id
                type_name
                status_id
                status_name
                index_field_id
                input_id
                visibility_check
                title
                description
                is_required
                validation
                width
                force_break
                extra_info
                company_id: model_company_id
                cargoCategory: cargo_category
                cargo_type
                cargo_reference_type
                transform_cargo_type
                is_static_unit
                default_unit

                model_field_value_list {
                    id
                    company_id
                    field_id
                    order_by
                    is_hidden
                    value
                }

                index_field {
                    id
                    order_by
                    type_id
                    type_name
                    status_id
                    status_name
                    title
                    description
                    extra_info
                }
            }
        }
    }
`;
