import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import DotTypeEditor from 'pages/events/containers/eventContainer';
import { FormattedMessage } from 'react-intl';
import { history } from 'configuration/history';
import SelectableFieldPreview from './selectableFieldPreview';

function FieldTransformationsModal(props) {
    const {
        modalOpen = false,
        setModalOpen = () => null,
        cargoType = null,
        hideTitleAndDescription = true,
        readOnly = false,
        modalTitle,
        primaryButton,
        showLink,
        showQuantityInfo,
        dot,
    } = props;


    const [eventSaving, setEventSaving] = useState(false);
    const [fieldUserTransformCheckboxes, setFieldUserTransformCheckboxes] = useState(null);

    useEffect(() => {
        if (!!cargoType && !!cargoType.transaction_field_list) {
            const initialUserTransformCheckboxState = {};
            cargoType.transaction_field_list.forEach(tf => {
                initialUserTransformCheckboxState[tf.field_id] = tf.allow_user_transform;
            })
            setFieldUserTransformCheckboxes(initialUserTransformCheckboxState);
        }
    }, [cargoType])


    const isFieldSelected = id => fieldUserTransformCheckboxes[id];
    const toggleFieldSelected = id => {
        const clonedState = Object.assign({}, fieldUserTransformCheckboxes);
        const previous = fieldUserTransformCheckboxes[id];
        clonedState[id] = !(previous === true);
        setFieldUserTransformCheckboxes(clonedState);
    }

    const handleSaveStateChange = (state) => {
        setEventSaving(state);
    }

    return (
        <ReactModal
            isOpen={modalOpen}
            onRequestClose={() => setModalOpen(false)}
            className="modal-block dialog"
            overlayClassName="modal-overlay gray"
            style={{ content: { width: '70%', maxHeight: '70%', overflowY: 'auto', overflowX: 'hidden' } }}
        >
            <div className="modal-dialog-centered" role="document" onClick={e => e.stopPropagation()}>
                <div className="modal-content bd-0">
                    <div className="modal-header pd-x-20">
                        {
                            eventSaving ?
                                <div style={{ position: 'relative' }}>
                                    <div className="loader" />
                                    <span className="mg-l-5">
                                        <FormattedMessage id="common.saving" />
                                    </span>
                                </div>
                                :
                                <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                                    {modalTitle ? modalTitle : <FormattedMessage id="common.cargoType" />}
                                </h6>
                        }
                        <div>
                            {
                                showLink &&
                                <button
                                    type="button"
                                    className="btn btn-outline-info"
                                    onClick={() => cargoType && cargoType.id && history.push(`/events/${cargoType.id}`)}
                                >
                                    <FormattedMessage id='openPage' />
                                </button>
                            }

                            <button
                                type="button"
                                className="btn btn-outline-info font-weight-bold ml-2"
                                onClick={() => setModalOpen(false)}
                            >
                                {'X'}
                            </button>
                        </div>

                    </div>
                    <div className="modal-body br-section-wrapper">
                        <FormattedMessage id="dotTransformExplanation" />
                        <DotTypeEditor
                            transaction={cargoType}
                            company={{}}
                            hideHeading={true}
                            hideTitleAndDescription={hideTitleAndDescription}
                            showCloseButton={true}
                            primaryButton={primaryButton}
                            onCancel={() => setModalOpen(false)}
                            onSavingSet={handleSaveStateChange}
                            doValidation={false}
                            showQuantityInfo={showQuantityInfo}
                            dot={dot}
                            readOnly={readOnly}
                            FieldPreviewEl={SelectableFieldPreview}
                            fieldPreviewProps={{ onFieldToggleSelect: toggleFieldSelected, isFieldSelected: isFieldSelected }}
                            fieldUserTransformState={fieldUserTransformCheckboxes}
                        />
                    </div>
                </div>
            </div>
        </ReactModal>
    );

}

export default FieldTransformationsModal;