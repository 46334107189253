import Field, { FieldTypes } from 'models/field';

export const compatibleDateFormats = {
    'DD-MMM-YYYY': ['DD-MMM-YYYY', 'YYYY-MM-DD', 'MM/DD/YYYY', 'DD/MM/YYYY', 'YYYY/MM/DD', 'YYYY', 'MM', 'Q', 'DDD', 'DD', 'MM/YYYY', 'YYYY/MM'],
    'YYYY-MM-DD': ['DD-MMM-YYYY', 'YYYY-MM-DD', 'MM/DD/YYYY', 'DD/MM/YYYY', 'YYYY/MM/DD', 'YYYY', 'MM', 'Q', 'DDD', 'DD', 'MM/YYYY', 'YYYY/MM'],
    'MM/DD/YYYY': ['DD-MMM-YYYY', 'YYYY-MM-DD', 'MM/DD/YYYY', 'DD/MM/YYYY', 'YYYY/MM/DD', 'YYYY', 'MM', 'Q', 'DDD', 'DD', 'MM/YYYY', 'YYYY/MM'],
    'DD/MM/YYYY': ['DD-MMM-YYYY', 'YYYY-MM-DD', 'MM/DD/YYYY', 'DD/MM/YYYY', 'YYYY/MM/DD', 'YYYY', 'MM', 'Q', 'DDD', 'DD', 'MM/YYYY', 'YYYY/MM'],
    'YYYY/MM/DD': ['DD-MMM-YYYY', 'YYYY-MM-DD', 'MM/DD/YYYY', 'DD/MM/YYYY', 'YYYY/MM/DD', 'YYYY', 'MM', 'Q', 'DDD', 'DD', 'MM/YYYY', 'YYYY/MM'],
    'YYYY': ['YYYY'],
    'MM': ['MM', 'Q'],
    'Q': ['Q'],
    'DDD': ['MM', 'Q', 'DDD', 'DD'],
    'DD': ['DD'],
    'MM/YYYY': ['YYYY', 'MM', 'Q', 'MM/YYYY', 'YYYY/MM'],
    'YYYY/MM': ['YYYY', 'MM', 'Q', 'MM/YYYY', 'YYYY/MM'],
}

export const getGroupedSections = (allFields, fieldMappings = [], dashTemplateFields = null, dotTemplates = [], fieldsMappingToLockedDots = []) => {
    if (!allFields || allFields.length === 0) return [];

    const fields = allFields.map(f => { 
        const mappedField = Field.fromDB(f);
        if (f.hiddenField) mappedField.hiddenField = true;
        mappedField.isDotField = f.isDotField;
        mappedField.dash_field_id = f.dash_field_id;
        if (fieldsMappingToLockedDots && fieldsMappingToLockedDots.length > 0) {
            mappedField.locked_from_revise = fieldsMappingToLockedDots.find(ld => mappedField.isDotField ? (ld.dash_field === mappedField.dash_field_id && ld.dot_field === mappedField.id) : (ld.dash_field === mappedField.id)) !== undefined;
        }
        return inheritMappedFieldProperties(mappedField, fieldMappings, dashTemplateFields, dotTemplates);
    });
    if (!fields || fields.length === 0) return [];

    fields.sort((a,b) => {
        if (a.order < b.order) return -1;
        else if (a.order === b.order) return 0;
        else return 1;
    });
    const rootFields = [];

    let inSection = false;
    let currentSection = null;
    let currentSectionId = null;
    const sectionChildren = [];

    for (let field of fields) {      
        if (field.type === FieldTypes.FIELD_END_SECTION) {
            if (inSection) {
                sectionChildren[currentSectionId].push(field);
                currentSection.children = sectionChildren[currentSectionId].map(f => f.id);
                rootFields.push(currentSection);
            } else {
                // otherwise it is just a root field
                rootFields.push(field);
            }
            inSection = false;
        }  else if (field.type === FieldTypes.FIELD_SECTION) { 
            if (inSection) {
                // Already in a section - we must complete that one
                currentSection.children = sectionChildren[currentSectionId].map(f => f.id);
                rootFields.push(currentSection);
            } else {
                // we are in one now
                inSection = true;
            }
            currentSection = field;
            currentSectionId = field.id;
            sectionChildren[currentSectionId] = [];
        } else if (field.type === FieldTypes.FIELD_CARGO) {
            if (inSection) {
                // we were already in a section - we must complete that one
                currentSection.children = sectionChildren[currentSectionId].map(f => f.id);
                rootFields.push(currentSection);
                inSection = false;
            }
            // Add the cargo field as if it's a normal field
            rootFields.push(field);
        } else {
            // 'regular' field - if we're in a section, add it to the section children
            if (inSection) {
                sectionChildren[currentSectionId].push(field);
            } else {
                // otherwise it is just a root field
                rootFields.push(field);
            }
        }
    }
    // if the last field is part of a section, we need to finalize that section
    if (inSection) {
        currentSection.children = sectionChildren[currentSectionId].map(f => f.id);
        rootFields.push(currentSection);
    }
    return rootFields;
};

export const inheritMappedFieldProperties = (field, fieldMappings = [], dashTemplateFields = null, dotTemplates = [], ) => {
    if (!field) return field;
    let mappingToCurrentField;
    if (field.isDotField) {
        mappingToCurrentField = fieldMappings.find(fm => fm.map_dash_field_id === field.dash_field_id && fm.map_dot_field_id === field.id);
    } else {
        mappingToCurrentField = fieldMappings.find(fm => fm.map_dash_field_id === field.id);
    }
    field.isMappedField = !!mappingToCurrentField;

    if (!!mappingToCurrentField && (field.use_mapped_value !== false)) {
        if (mappingToCurrentField.owning_dot_field_id) {
            const containingDotTemplate = dotTemplates.find(dt => dt && dt.model_field_list && dt.model_field_list.find(f => f.id === mappingToCurrentField.owning_dot_field_id));
            let templateField = containingDotTemplate ? containingDotTemplate.model_field_list.find(f => f.id === mappingToCurrentField.owning_dot_field_id) : null;
            if (!templateField) {
                return field;
            }
            if (!templateField.hasOwnProperty('visibilityCondition')){
                templateField = Field.fromDB(templateField);
            }
            field.sourceMapField = templateField;
            field.sourceMapNestedFieldId = mappingToCurrentField.owning_dash_field_id;
            let parentMapping = mappingToCurrentField;
            const originalDateFormat = field.dateFormat || 'DD-MMM-YYYY';
            while (true) {
                field.step = templateField.step;
                field.min = templateField.min;
                field.max = templateField.max;
                field.groupThousands = templateField.groupThousands;
                field.numberFormat = templateField.numberFormat;
                const compatibleFormats = compatibleDateFormats[templateField.dateFormat || 'DD-MMM-YYYY'];
                if (compatibleFormats && compatibleFormats.find(f => f === originalDateFormat)) {
                    field.dateFormat = originalDateFormat;
                } else {
                    field.dateFormat = templateField.dateFormat;
                }
                // don't continue inheriting upstream if the parent field is not currently being mapped to
                if (templateField.use_mapped_value === false) {
                    break;
                }
                // otherwise continue upstream
                parentMapping = fieldMappings.find(fm => fm.map_dash_field_id === parentMapping.owning_dash_field_id && fm.map_dot_field_id === parentMapping.owning_dot_field_id);
                if (parentMapping) {
                    if (parentMapping.owning_dot_field_id) {
                        const parentDotTemplate = dotTemplates.find(dt => dt.model_field_list.find(f => f.id === parentMapping.owning_dot_field_id));
                        templateField = parentDotTemplate ? parentDotTemplate.model_field_list.find(f => f.id === parentMapping.owning_dot_field_id) : null;
                        field.sourceMapNestedFieldId = parentMapping.owning_dash_field_id;
                    } else {
                        templateField = dashTemplateFields.find(f => f.id === parentMapping.owning_dash_field_id);
                        field.sourceMapNestedFieldId = null;
                    }
                    field.sourceMapField = templateField;
                } else {
                    break;
                }
            }
        } else {
            let templateField = dashTemplateFields.find(f => f.id === mappingToCurrentField.owning_dash_field_id);
            if (!templateField.hasOwnProperty('visibilityCondition')){
                templateField = Field.fromDB(templateField);
            }
            if (templateField) {
                field.sourceMapField = templateField;
                let parentMapping = mappingToCurrentField;
                const originalDateFormat = field.dateFormat || 'DD-MMM-YYYY';
                while (true) {
                    field.step = templateField.step;
                    field.min = templateField.min;
                    field.max = templateField.max;
                    field.groupThousands = templateField.groupThousands;
                    field.numberFormat = templateField.numberFormat;
                    const compatibleFormats = compatibleDateFormats[templateField.dateFormat || 'DD-MMM-YYYY'];
                    if (compatibleFormats && compatibleFormats.find(f => f === originalDateFormat)) {
                        field.dateFormat = originalDateFormat;
                    } else {
                        field.dateFormat = templateField.dateFormat;
                    }
                    // don't continue inheriting upstream if the parent field is not currently being mapped to
                    if (templateField.use_mapped_value === false) {
                        break;
                    }
                    // otherwise continue upstream
                    parentMapping = fieldMappings.find(fm => fm.map_dash_field_id === parentMapping.owning_dash_field_id && fm.map_dot_field_id === parentMapping.owning_dot_field_id);
                    if (parentMapping) {
                        if (parentMapping.owning_dot_field_id) {
                            const parentDotTemplate = dotTemplates.find(dt => dt.model_field_list.find(f => f.id === parentMapping.owning_dot_field_id));
                            templateField = parentDotTemplate ? parentDotTemplate.model_field_list.find(f => f.id === parentMapping.owning_dot_field_id) : null;
                            field.sourceMapNestedFieldId = parentMapping.owning_dash_field_id;
                        } else {
                            templateField = dashTemplateFields.find(f => f.id === parentMapping.owning_dash_field_id);
                            field.sourceMapNestedFieldId = null;
                        }
                        if (!templateField.hasOwnProperty('visibilityCondition')){
                            templateField = Field.fromDB(templateField);
                        }
                        field.sourceMapField = templateField;
                    } else {
                        break;
                    }
                }
            }
        }
    }
    return field;
};
