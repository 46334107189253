export const USERS_SET_ROLE_PERMISSIONS = `
    mutation M($role_id: Int!, $permission_ids: [Int!]) {
        role_set_permission_list(role_id: $role_id, permission_ids: $permission_ids) {
            id
            is_admin_level
            code
            title
        }
    }
`;
