import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { history } from '../configuration/history';
import identity from './identity';
import users from './users';
import companies from './companies';
import blacklist from './blacklist';
import menus from './menus';
import localization from './localization';
import transactions from './transactions';
import transaction from './transaction';
import loading from './loading';
import media from './media';
import models from './models';
import fields from './fields';
import indices from './indices';
import fieldVisibility from './fieldVisibility';
import document from './document';
import documents from './documents';
import links from './links';
import payment from './payment';
import lots from './lots';
import cargoUnits from './cargoUnits';
import sessionSettings from './sessionSettings'
import alerts from './alerts'
import sharing from './sharing'
import internal from './internal'
import entityLock from './entityLock'
import entityGraph from './entityGraph'
import faq from './faq'
import entityGroup from './entityGroup'

const appReducer = combineReducers({
    router: connectRouter(history),
    identity,
    users,
    companies,
    blacklist,
    transactions,
    transaction,
    loading,
    media,
    models,
    fields,
    indices,
    fieldVisibility,
    localization,
    menus,
    document,
    documents,
    links,
    payment,
    lots,
    cargoUnits,
    sessionSettings,
    alerts,
    sharing,
    internal,
    entityLock,
    entityGraph,
    faq,
    entityGroup,
});

const rootReducer = (state, action) => appReducer(state, action);
export default rootReducer;
