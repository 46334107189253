export const ALERTS_CREATE = `
    mutation M(
        $title: String!,
    ) {
        alert_create(
            input: { 
                alert_title: $title, 
            }
        ) {
            id
            hash_id
            title
            description
            created_on
            created_by
            active
            conditions {
                id
                type_id
                comparator_id
                alert_id
                order
                break_or
            }
            subscribers {
                id
                email
            }
        }
    }
`;
