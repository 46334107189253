import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactModal from 'react-modal';
import AdvancedFilters from './advancedFilters';
import { getComparators, setVisibilityDialog, getFieldValues } from '../../../../../reducers/fieldVisibility';

class VisibilityConditions extends React.Component {
    componentDidMount() {
        const { comparators, getComparators } = this.props;
        if (!comparators) getComparators();
    }

    render() {
        const { field, fields, comparators, dialog, setVisibilityDialog, fieldValues, getFieldValues, onConditionsSet, disabled } = this.props;
        if (!dialog || !field) return null;

        return (
            <ReactModal isOpen={!!dialog} onRequestClose={() => setVisibilityDialog()} className="modal-block dialog" overlayClassName="modal-overlay gray">
                <AdvancedFilters
                    field={field}
                    fields={fields}
                    filters={field.visibilityCondition}
                    comparators={comparators}
                    indexValues={fieldValues}
                    loadIndexValues={getFieldValues}
                    onFiltersSet={f => {
                        onConditionsSet(f);
                        setVisibilityDialog();
                    }}
                    onCancel={() => setVisibilityDialog()}
                    disabled={disabled}
                />
            </ReactModal>
        );
    }
}

const mapStateToProps = state => {
    return {
        fields: state.fields.items,
        model: state.fields.model,
        comparators: state.fieldVisibility.comparators,
        fieldValues: state.fieldVisibility.fieldValues,
        dialog: state.fieldVisibility.dialog
    };
};
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getFieldValues,
            getComparators,
            setVisibilityDialog
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VisibilityConditions);
