import React from 'react';
import { call, hasErrors } from '../configuration/api';
import Queries from './queries';
import { FormattedMessage } from 'react-intl';
import { Notifications } from '../components/notifications';
import { DOCUMENTS_GET_LIST } from './types';

const defaultState = {
    list: undefined,
    total: 0
};

/**
 * Documents state mapper.
 * @param {object} state
 * @param {string} action
 */
export default function(state = defaultState, action) {
    switch (action.type) {
        case DOCUMENTS_GET_LIST:
            return { ...state, list: action.list, total: Number.parseInt(action.total) };

        default:
            return state;
    }
}

export const getDocumentTemplates = (variables, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Documents.List, variables });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.document_template_list) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    dispatch({ type: DOCUMENTS_GET_LIST, list: data.document_template_list.data, total: data.document_template_list.total });
    onComplete(data.document_template_list);
};

export const deleteDocumentTemplate = (id, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Documents.Delete, variables: { template_id: id } });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.document_template_delete) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }

    Notifications.success(<FormattedMessage id="document.deleted" />);
    onComplete(data.document_delete);
};

export const getDocumentReferenceData = (variables, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Documents.GetRefData, variables });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.document_template_ref_data) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.document_template_ref_data);
};

export const createFieldReferenceGroup = (variables, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Documents.CreateFieldRefGroup, variables });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.document_template_create_ref_group) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.document_template_create_ref_group);
};

export const addFieldReferenceToGroup = (variables, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Documents.AddFieldRefToGroup, variables });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.document_template_add_ref_to_group) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.document_template_add_ref_to_group);
};

export const removeFieldReferenceFromGroup = (variables, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Documents.RemoveFieldRefFromGroup, variables });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.document_template_remove_ref_from_group) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.document_template_remove_ref_from_group);
};

export const deleteFieldReferenceGroup = (variables, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Documents.DeleteFieldRefGroup, variables });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.document_template_delete_ref_group) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.document_template_delete_ref_group);
};

export const setFieldGroupReferences = (variables, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Documents.SetFieldGroupReferences, variables });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.document_template_set_group_refs) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.document_template_set_group_refs);
};