import moment from 'moment';
import { FieldComparatorTypes, FieldTypes } from '../../../../../models/field';
import Field from '../../../../../models/field';

export const evaluateConditions = (field, values, fields, comparators) => {
    if (!field) return true;

    const conditions = field.visibilityCondition;
    if (!conditions || conditions.length === 0) return true;

    const groups = [];
    conditions.forEach(condition => {
        if (groups.length === 0) {
            groups.push([]);
        }
        if (!!condition.break_or) {
            groups.push([condition]);
        } else {
            groups[groups.length - 1] = [...groups[groups.length - 1], condition];
        }
    });

    const notEmptyGroups = groups.filter(g => g.length > 0);
    const groupResults = notEmptyGroups.map(g => g.map(c => evaluate(c, values, fields, comparators)).every(c => !!c));
    const result = groupResults.some(r => !!r);
    return result;
};

const evaluate = (condition, values, fields, comparators) => {
    if (!condition) return true;

    const { filterValue, field, comparator } = condition;
    let fieldData = fields.find(f => f.visibility_id === field);
    if (!fieldData) {
        try {
            const fieldsParsed = fields.map(f => Field.fromDB(f));
            fieldData = fieldsParsed.find(f => f.visibility_id === field);
            if (!fieldData) {
                return true;
            }
        } catch {
            return true;
        }
    }

    const action = fieldData.type === FieldTypes.FIELD_DATE ? getDateActions(comparator, comparators) : getAction(comparator, comparators);
    const fieldId = fieldData.id;

    let fieldValue = '';
    if (String(fieldId).indexOf('.') > -1) {
        const idParts = fieldId.split('.');
        if (values[idParts[0]]) {
            fieldValue = values[idParts[0]][idParts[1]];
        } else if (values[idParts[1]] !== undefined) {
            fieldValue = values[idParts[1]];
        }
    } else {
        fieldValue = values[fieldId];
    }

    fieldValue = (fieldValue !== undefined && fieldValue !== null) ? fieldValue : fieldData.defaultValue;
    const result = action(filterValue, fieldValue);
    return result;
};

const getAction = (comparator, comparators) => {
    const key = (comparators.find(c => c.id === comparator) || {}).comparator_name;
    const equals = (filter, value) => {
        if (filter === value || (filter === null && value === '')) return true;
        // try case insensitive
        if (typeof value === 'string' && typeof filter === 'string') {
            if (value.toLowerCase() === filter.toLowerCase()) {
                return true;
            }
        }
        // Not exact match - lets try and parse the value as a number
        const floatVal = parseFloat(typeof value === "string" ? value.replace(/,/g, '') : value);
        const floatFilter = parseFloat(typeof filter === "string" ? filter.replace(/,/g, '') : filter);
        if (isNaN(floatVal) || isNaN(floatFilter)) {
            return false;
        } else {
            return floatVal === floatFilter;
        }
    }
    const isNull = (_, value) => {
        // handle empty arrays, or arrays containing only an empty string for cleared multiselect and multitext fields
        return value === undefined || value === null || value === ''
            || (Array.isArray(value) && (value.length === 0 || (value.length === 1 && value[0] === '')));
    } 
    switch (key) {
        case FieldComparatorTypes.NONE:
            return (_, value) => !isNull(null, value);
        case FieldComparatorTypes.EQUALS:
            return equals;
        case FieldComparatorTypes.NOT_EQUAL:
            return (filter, value) => !equals(filter, value);
        case FieldComparatorTypes.GREATER:
            return (filter, value) => filter < value;
        case FieldComparatorTypes.LESS:
            return (filter, value) => filter > value;
        case FieldComparatorTypes.GREATER_EQUAL:
            return (filter, value) => filter <= value;
        case FieldComparatorTypes.LESS_EQUAL:
            return (filter, value) => filter >= value;
        case FieldComparatorTypes.BETWEEN:
            return ([filter1, filter2], value) => (filter1 <= value) && (value <= filter2);
        case FieldComparatorTypes.CONTAINS:
            return (filter, value) => {
                if (!value) return false;
                if (typeof value === 'string' && typeof filter === 'string') {
                    return value.toLowerCase().includes(filter.toLowerCase());
                }
                try {
                    return value.map(v => v.toLowerCase()).includes(filter.map(f => f.toLowerCase()));
                } catch { }
                try {
                    return value.map(v => v.toLowerCase()).includes(filter.toLowerCase());
                } catch { }
                if (typeof value === 'object') {
                    return Object.values(value).includes(filter);
                }
                return value.includes(filter);
            } 
        case FieldComparatorTypes.DOES_NOT_CONTAIN:
            return (filter, value) => {
                if (!value) return true;
                if (typeof value === 'string' && typeof filter === 'string') {
                    return !value.toLowerCase().includes(filter.toLowerCase());
                }
                try {
                    return !value.map(v => v.toLowerCase()).includes(filter.map(f => f.toLowerCase()));
                } catch { }
                try {
                    return !value.map(v => v.toLowerCase()).includes(filter.toLowerCase());
                } catch { }
                if (typeof value === 'object') {
                    return !Object.values(value).includes(filter);
                }
                return !value.includes(filter);
            } 
        case FieldComparatorTypes.IN:
            return (filter, value) => filter.includes(value);
        case FieldComparatorTypes.LIKE:
            return (filter, value) => {
                if (!value) return false;
                if (typeof value === 'string' && typeof filter === 'string') {
                    return value.toLowerCase().includes(filter.toLowerCase());
                }
                return value.includes(filter);
            }
        case FieldComparatorTypes.IS_NULL:
            return isNull;
        case FieldComparatorTypes.NOT_LIKE:
            return (filter, value) => {
                if (!value) return true;
                if (typeof value === 'string' && typeof filter === 'string') {
                    return !value.toLowerCase().includes(filter.toLowerCase());
                }
                return !value.includes(filter);
            }

        default:
            return () => true;
    }
};

const getDateActions = (comparator, comparators) => {
    const key = (comparators.find(c => c.id === comparator) || {}).comparator_name;

    switch (key) {
        case FieldComparatorTypes.NONE:
            return (_, value) => !(value === undefined || value === null || value === '');
        case FieldComparatorTypes.EQUALS:
            return (filter, value) => moment(filter).isSame(moment(value), 'day');
        case FieldComparatorTypes.NOT_EQUAL:
            return (filter, value) => !moment(filter).isSame(moment(value), 'day');
        case FieldComparatorTypes.GREATER:
            return (filter, value) => moment(value).isAfter(moment(filter), 'day');
        case FieldComparatorTypes.LESS:
            return (filter, value) => moment(value).isBefore(moment(filter), 'day');
        case FieldComparatorTypes.GREATER_EQUAL:
            return (filter, value) => moment(value).isSameOrAfter(moment(filter), 'day');
        case FieldComparatorTypes.LESS_EQUAL:
            return (filter, value) => moment(value).isSameOrBefore(moment(filter), 'day');
        case FieldComparatorTypes.BETWEEN:
            return ([filter1, filter2], value) => moment(value).isBetween(moment(filter1), moment(filter2), 'day', '[]');
        case FieldComparatorTypes.IS_NULL:
            return (_, value) => value === undefined || value === null || value === '';

        default:
            return () => true;
    }
};
