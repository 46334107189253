import React from 'react';
import { FormattedMessage } from 'react-intl';

class PricingPlanBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const {
            plan,
            type = 'annually',
            price: { monthly, annually, available = true } = { available: true },
            title,
            subtitle,
            features = [],
            onChoosePlan = () => null,
            width = 'col-sm col-xs-12',
            last = false,
            currency = 'USD',
            seleniumClass = '',
        } = this.props;

        const currencySymbol = currency === 'USD' ? '$' : '€';

        return (
            <div className={`${width} payment-plan-box ${!!last ? 'last-plan' : ''}`}>
                <div className="payment-plan-box-inner">
                    <h5 className="plan-title tx-century-gothic">{title}</h5>
                    {/* <p className="plan-subtitle">{subtitle}</p> */}

                    {!!available ? (
                        <React.Fragment>
                            {type === 'annually' ? (
                                <div className="pricing-details">
                                    <p className="pricing-details-price">
                                        {`${currencySymbol}${Number(annually)} `}
                                        <span>
                                            <FormattedMessage id="page.companies.payment.plan.box.annualDescription" />
                                        </span>
                                    </p>
                                    <p className="pricing-details-alternate">
                                        <strong>{currencySymbol}{Number(monthly)}</strong> <FormattedMessage id="page.companies.payment.plan.box.monthlyDescription" />
                                    </p>
                                </div>
                            ) : (
                                <div className="pricing-details">
                                    <p className="pricing-details-price">
                                        {`${currencySymbol}${Number(monthly)} `}
                                        <span>
                                            <FormattedMessage id="page.companies.payment.plan.box.monthlyDescription" />
                                        </span>
                                    </p>
                                    <p className="pricing-details-alternate">
                                        <strong>{currencySymbol}{Number(annually)}</strong> <FormattedMessage id="page.companies.payment.plan.box.annualDescription" />
                                    </p>
                                </div>
                            )}
                        </React.Fragment>
                    ) : (
                        <div className="pricing-details">
                            <p className="coming-soon-price">
                                <FormattedMessage id="common.comingSoon" />
                            </p>
                        </div>
                    )}

                    {features.length > 0 && (
                        <div className="plan-features">
                            <span className="top-features-title">
                                <FormattedMessage id="page.companies.payment.plan.box.topFeatures" />
                            </span>
                            <ul>
                                {features.map((f, i) => (
                                    <li key={i}>{f}</li>
                                ))}
                            </ul>
                        </div>
                    )}

                    {!available && (
                        <div className={"purchase-button not-available " + seleniumClass}>
                            <div className="plan-price">
                                <FormattedMessage id="common.comingSoon" />
                            </div>
                        </div>
                    )}
                    {!!available && type === 'annually' && (
                        <div className={"purchase-button " + seleniumClass} onClick={() => onChoosePlan(plan, type)}>
                            <span>
                                <FormattedMessage id="common.subscribe" />
                            </span>
                        </div>
                    )}
                    {!!available && type === 'monthly' && (
                        <div className={"purchase-button " + seleniumClass} onClick={() => onChoosePlan(plan, type)}>
                            <span>
                                <FormattedMessage id="common.subscribe" />
                            </span>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default PricingPlanBox;
