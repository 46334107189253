export const DOCUMENT_TEMPLATE_ADD_FIELD_REF_TO_GROUP = `
    mutation M(
        $document_template_id: Long!
        $name: String!
        $field_reference_literal: String!
        $all_literals: [String!]
    ) {
        document_template_add_ref_to_group(
            input: { 
                document_template_id: $document_template_id 
                name: $name 
                field_reference_literal: $field_reference_literal
                all_literals: $all_literals
            }
        ) {
            document_template_id
            name
            field_reference_literal
        }
    }
`;
