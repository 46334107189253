import React from 'react';
import { Button, Tag } from '@blueprintjs/core';
import { FormattedMessage } from 'react-intl';
import SimpleBar from 'simplebar-react';
import onClickOutside from 'react-onclickoutside';

class FieldsList extends React.Component {
    handleClickOutside = e => {
        const { setVisibility } = this.props;
        setVisibility(false);
    };

    render() {
        const { fields = [], open, setVisibility, formProps, name, currentValue } = this.props;
        if (!open) return null;

        return (
            <div className="field-picker-list">
                <SimpleBar>
                    {fields.map((field, i) => (
                        <div
                            key={i}
                            className="field-picker-list-item"
                            onClick={async () => {
                                await formProps.setFieldValue(name, `${currentValue}{{${field.id}}}`);
                                await formProps.submitForm();
                                setVisibility(false);
                            }}
                        >
                            {field.title}
                            <Tag className="float-right">{field.id}</Tag>
                        </div>
                    ))}
                </SimpleBar>
            </div>
        );
    }
}

const FieldsListClosable = onClickOutside(FieldsList);

class FieldPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
    }

    setVisibility = open => this.setState({ ...this.state, open });

    render() {
        const { open } = this.state;
        const { fields = [], formProps, name, currentValue } = this.props;

        return (
            <div className="field-picker">
                <Button
                    className="tx-left"
                    fill={true}
                    icon={'small-plus'}
                    text={<FormattedMessage id="page.models.document.configuration.properties.chooseFields" />}
                    onClick={() => this.setVisibility(true)}
                />
                <FieldsListClosable
                    open={open}
                    fields={fields}
                    setVisibility={this.setVisibility}
                    formProps={formProps}
                    name={name}
                    currentValue={currentValue}
                />
            </div>
        );
    }
}

export default FieldPicker;
