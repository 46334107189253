import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import LotsList from './containers/list';
import { DefinedPermissions } from '../../components/routes/permissions/permissionResolver';

class Lots extends React.Component {
    render() {
        const {state}= this.props.history.location;
        let collMeta, collItemMeta, parents;

        if( state ){              
            const { title, route} = JSON.parse(state);
            
            collMeta = {
                title: 'collections',
                routes: ['/collections'],
            };
            collItemMeta = {
                title: title,
                routes: [`${route}`],
            };

            parents= [collMeta, collItemMeta];
        }
        
        return (
            <Switch>
                <Route path="/objects" component={props => <LotsList {...props}  parents={parents} />} />
            </Switch>
        );
    }
}

Lots.meta = {
    title: <FormattedMessage id="dots" />,
    subtitle: <FormattedMessage id="page.lots.subtitle" />,
    routes: ['/objects'],
    order: 20,
    icon: 'ion-ios-cart-outline',
    menus: { main: true, user: false },
    access: DefinedPermissions.ModelCrud,
    requires: ['model', 'read'],
    onlySubscribed: true
};

export default Lots;
