import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Popover, Menu, Button, MenuItem, Icon, MenuDivider } from '@blueprintjs/core';
import Page from 'components/page';
import Table from 'components/table';
import { history } from 'configuration/history';
import { getProfile } from 'reducers/identity';
import { loadModel } from 'reducers/models';
import { getDocumentTemplates, deleteDocumentTemplate } from 'reducers/documents';
import { getDocumentTemplate } from 'reducers/document';
import { createDocumentTemplate, cloneDocumentTemplate, listDocumentSelections, setDocumentSelection } from 'reducers/document';
import { setDocumentTemplatesTableOptions } from 'reducers/sessionSettings';
import ConfirmModal from 'components/modals/confirm';

class DocumentTemplateList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deleteTargetMultiIds: [],
            tableRenderCount: 1,
            documentSelections: [],
        };
    }

    loadData = (options, onComplete) => {
        const { match, getDocumentTemplates, setDocumentTemplatesTableOptions = () => null, documentTemplatesTableOptions, listDocumentSelections } = this.props;
        getDocumentTemplates({ ...options }, (results) => {
            if (results.total > 0 && results.data.length === 0) {
                // navigate to previous page and force table to re-render
                const newOptions = Object.assign({}, documentTemplatesTableOptions);
                newOptions.page = newOptions.page - 1;
                setDocumentTemplatesTableOptions(newOptions).then(() => {
                    this.setState({ tableRenderCount: this.state.tableRenderCount + 1 });
                })
            } else {
                const docTemplateIds = results.data.map(dt => dt.id);
                listDocumentSelections({ document_template_ids: docTemplateIds }, docSelections => {
                    this.setState({documentSelections: docSelections, selectionsLoaded: true});
                    onComplete(results);
                })
            }
        });
    };

    toggleDeleteTarget = (id) => {
        this.setState(prevState => {
            const targetIds = prevState.deleteTargetMultiIds;
            const index = targetIds.indexOf(id);
            if (index === -1) {
                targetIds.push(id);
            } else {
                targetIds.splice(index, 1);
            }
            return { deleteTargetMultiIds: targetIds };
        })
    }

    eventAllSelectorEl = () => {
        const { templates = [] } = this.props;
        const { deleteTargetMultiIds, isDeletingMulti } = this.state;
        const allEventsSelected = deleteTargetMultiIds.length === templates.length;
        if (isDeletingMulti) {
            return ( 
                <div className="actions-wrapper" style={{height: '100%'}}>
                    <input 
                        type="checkbox" 
                        checked={allEventsSelected}  
                        onChange={() => this.setState(prevState => {
                            let allSelectedIds = prevState.deleteTargetMultiIds;
                            if (allSelectedIds.length === templates.length) {
                                return { deleteTargetMultiIds: [] };
                            } else {
                                return { deleteTargetMultiIds: templates.map(t => t.id) };
                            }
                        })}
                    />
                </div>
            );
        } else {
            return null;
        }
    }

    getColumns = () => {
        const { 
            deleteDocumentTemplate, 
            getDocumentTemplates, 
            match, 
            getDocumentTemplate, 
            setBuilderLoading = () => null, 
            cloneDocumentTemplate,
            setDocumentSelection,
        } = this.props;

        return [
            {
                headerClassName: 'd-sm-block col-2 d-none',
                Header: this.eventAllSelectorEl(),
                className: 'd-xs-block text-center col-2',
                sortable: false,
                resizable: false,
                width: 34,
                id: 'actions',
                accessor: d => {
                    const docSelections = this.state.documentSelections.filter(ds => ds.document_template_id === d.id);
                    return this.state.isDeletingMulti ?
                    (
                        <div className="actions-wrapper">
                            <input 
                                type="checkbox" 
                                checked={this.state.deleteTargetMultiIds && this.state.deleteTargetMultiIds.indexOf(d.id) > -1}  
                                onChange={() => this.toggleDeleteTarget(d.id)}
                            />
                        </div>
                    )
                    :
                    (
                        <div className="actions-wrapper">
                            <Popover
                                content={
                                    <Menu>
                                        <MenuItem
                                            icon="plus"
                                            text={<FormattedMessage id="common.generateDocument" />}
                                            popoverProps={{ openOnTargetFocus: false }}
                                        >
                                            <MenuItem
                                                text={<FormattedMessage id="new" />}
                                                popoverProps={{ openOnTargetFocus: false }}
                                                onClick={() => {
                                                    setBuilderLoading(true);
                                                    getDocumentTemplate(d.id, () => {
                                                        setBuilderLoading(false);
                                                    });
                                                    setDocumentSelection(null, () => {
                                                        history.push(`/document-templates/${d.id}/builder` + window.location.search);
                                                    })
                                                }}
                                            />
                                            <MenuItem
                                                text={(docSelections.length === 0) ? <FormattedMessage id="saved(none)" /> : <FormattedMessage id="saved" />}
                                                popoverProps={{ openOnTargetFocus: false }}
                                                disabled={docSelections.length === 0}
                                            >
                                                { docSelections.map(ds => (
                                                    <MenuItem
                                                        key={ds.id}
                                                        text={ds.name}
                                                        popoverProps={{ openOnTargetFocus: false }}
                                                        onClick={() => {
                                                            setBuilderLoading(true);
                                                            getDocumentTemplate(d.id, () => {
                                                                setBuilderLoading(false);
                                                            });
                                                            setDocumentSelection(ds, () => {
                                                                history.push(`/document-templates/${d.id}/builder` + window.location.search);
                                                            })
                                                        }}
                                                    />
                                                ))}
                                            </MenuItem>
                                        </MenuItem>
                                        <MenuItem
                                            icon="duplicate"
                                            text={<FormattedMessage id="common.clone" />}
                                            onClick={() => cloneDocumentTemplate( d.id, c => history.push(`/document-templates/${c.id}` + window.location.search))}
                                        />
                                        <MenuDivider />
                                        <MenuItem
                                            icon="cross"
                                            text={<FormattedMessage id="common.delete" />}
                                            onClick={() =>
                                                this.setState({ deleteTargetId: d.id, deleteTargetTitle: d.title || '[Untitled]', confirmModalOpen: true })
                                            }
                                        />
                                    </Menu>
                                }
                            >
                                <Button minimal={true} small={true} icon={<Icon iconSize={12} icon="menu" />} />
                            </Popover>
                        </div>
                    )
                }
            },
            {
                headerClassName: 'd-sm-block col d-none',
                className: 'd-sm-block col d-none tx-center id-column',
                Header: <FormattedMessage id="common.id" />,
                width: 100,
                id: 'id',
                accessor: d => <Link to={{ pathname: `/document-templates/${d.id}`, search: window.location.search }}>{d.hash_id}</Link>
            },
            {
                headerClassName: 'd-sm-block d-none',
                className: 'd-xs-block',
                Header: <FormattedMessage id="common.title" />,
                id: 'title',
                accessor: d => <Link to={{ pathname: `/document-templates/${d.id}`, search: window.location.search }}>{(d.title && d.title.length > 0) ? d.title : '[Untitled]'}</Link>
            },
            {
                headerClassName: 'd-block col-1',
                className: 'd-block col-1 tx-center',
                Header: <FormattedMessage id="common.type" />,
                id: 'document_type',
                accessor: d => d.document_type === 'word' ? <FormattedMessage id='common.Word'/> : <FormattedMessage id='common.Excel'/>
            },
            {
                headerClassName: 'd-block col-2',
                className: 'd-block col-2 tx-center',
                Header: <FormattedMessage id="common.createdOn" />,
                id: 'created_on',
                accessor: d => moment(d.created_on).format('DD-MM-YYYY')
            },
            {
                headerClassName: 'd-block col-2',
                className: 'd-block col-2 tx-center',
                Header: <FormattedMessage id="common.alteredOn" />,
                id: 'updated_at',
                accessor: d => moment(d.updated_at).format('DD-MM-YYYY')
            }
        ];
    };

    deleteMultiButtonGroup = () => {
        if (this.state.isDeletingMulti) {
            const deleteButton = this.state.deleteTargetMultiIds && this.state.deleteTargetMultiIds.length > 0 ?
                (
                    <span 
                        className="btn btn-outline-info" 
                        onClick={() => {
                            this.setState({ confirmMultiModalOpen: true });
                        }
                    }>
                        <FormattedMessage id="deleteSelectedDocumentTemplates" values={{count: this.state.deleteTargetMultiIds.length}} />
                    </span>
                )
                :
                (
                    <span 
                        className="btn btn-outline-info disabled" 
                        disabled={true}
                        onClick={() => null
                    }>
                        <FormattedMessage id="noDocumentTemplatesSelected" />
                    </span>
                )
            return (
                <React.Fragment>
                    { deleteButton }
                    <span 
                        className="btn btn-outline-info" 
                        onClick={() => {
                            this.setState({ isDeletingMulti: false, confirmMultiModalOpen: false, deleteTargetMultiIds: [] });
                        }
                    }>
                        <FormattedMessage id="cancel" />
                    </span>
                </React.Fragment>
            );
        } else {
            return (
                <span 
                    className="btn btn-outline-info" 
                    onClick={() => {
                        this.setState({ isDeletingMulti: true, deleteTargetMultiIds: [] });
                    }
                }>
                    <FormattedMessage id="deleteMultipleDocumentTemplates" />
                </span>
            );
        }
    }

    renderActions = () => {
        const { createDocumentTemplate } = this.props;
        return (
            <div className="btn-group pd-t-10">
                <span className="btn btn-outline-info">
                    <Popover
                        position="bottom-left"
                        captureDismiss
                        content={
                            <Menu>
                                <div tabIndex={0} />
                                <MenuItem 
                                    text={<FormattedMessage id="common.wordDoc" />}
                                    icon="small-plus"
                                    onClick={() => createDocumentTemplate(null, 'word', ({ id }) => history.push(`/document-templates/${id}` + window.location.search))}
                                />
                                <MenuItem 
                                    text={<FormattedMessage id="common.excelDoc" />}
                                    icon="small-plus"
                                    onClick={() => createDocumentTemplate(null, 'excel', ({ id }) => history.push(`/document-templates/${id}` + window.location.search))}
                                />
                            </Menu>
                        }
                    >
                        <FormattedMessage id="page.models.document.configuration.list.createNew" />
                    </Popover>
                </span>
                { this.deleteMultiButtonGroup() }
                {/* <span
                    className="btn btn-outline-info"
                    onClick={() => createDocumentTemplate(model.id, ({ id }) => history.push(`/models/${model.id}/document-templates/${id}` + window.location.search))}
                >
                    <FormattedMessage id="page.models.document.configuration.list.createNew" />
                </span> */}
            </div>
        );
    };

    render() {
        const { parents, templates, total = 0, deleteDocumentTemplate, intl: { formatMessage }, } = this.props;

        return (
            <Page {...DocumentTemplateList.meta} actions={this.renderActions()}>
                <Table 
                    key={'tb-' + this.state.tableRenderCount}
                    columns={this.getColumns()} 
                    data={templates} total={total} 
                    dataLoader={(options, onComplete) => {
                        this.loadData(options, onComplete);
                        this.setState({ options: options });
                    }}
                    optionsSetter={this.props.setDocumentTemplatesTableOptions}
                    initialOptions={this.props.documentTemplatesTableOptions}
                />
                <ConfirmModal
                    open={this.state.confirmModalOpen}
                    setOpen={(o) => this.setState({ confirmModalOpen: o })}
                    onConfirm={() => {
                        const { options = { }, deleteTargetId } = this.state;
                        deleteDocumentTemplate(deleteTargetId, () => {
                            this.loadData(options, () => null);
                        })
                    }}
                    title={<FormattedMessage id='common.confirmVerb' values={{ verb: formatMessage({ id: 'delete' })}} />}
                    body={<FormattedMessage id='confirmDocumentTemplateDelete' values={{ title: this.state.deleteTargetTitle }} />}
                />
                <ConfirmModal
                    open={this.state.confirmMultiModalOpen}
                    setOpen={(o) => this.setState({ confirmMultiModalOpen: o })}
                    onConfirm={() => {
                        const { options = { status_ids: [1, 2], model_type: 1 }, deleteTargetMultiIds } = this.state;
                        const promises = [];
                        for (const templateId of deleteTargetMultiIds) {
                            promises.push(new Promise(resolve => {
                                deleteDocumentTemplate(templateId, resolve)
                            }));
                        }
                        Promise.all(promises).then(() => {
                            this.loadData(options, () => null);
                            this.setState({ isDeletingMulti: false });
                        })

                    }}
                    title={<FormattedMessage id='common.confirmVerb' values={{ verb: formatMessage({ id: 'deleteSelected' }) }} />}
                    body={<FormattedMessage id='confirmDocumentTemplateDeleteMultiple' />}
                />
            </Page>
        );
    }
}

DocumentTemplateList.meta = {
    title: <FormattedMessage id="page.models.document.configuration.list.title" />,
    subtitle: <FormattedMessage id="page.models.document.configuration.list.subtitle" />,
    routes: ['/document-templates/'],
    icon: 'ion-clipboard',
    menus: { main: false, user: false },
    order: 0
};

const mapStateToProps = state => ({
    company: state.identity.company,
    model: state.fields.model,
    templates: state.documents.list,
    total: state.documents.total,
    fieldTypes: state.fields.types,
    documentTemplatesTableOptions: state.sessionSettings.documentTemplatesTableOptions,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getProfile,
            createDocumentTemplate,
            getDocumentTemplates,
            deleteDocumentTemplate,
            getDocumentTemplate,
            setDocumentTemplatesTableOptions,
            cloneDocumentTemplate,
            listDocumentSelections,
            setDocumentSelection,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(DocumentTemplateList));
