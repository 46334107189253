
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import ReactCardFlip from './ReactCardFlip';
import ReactCardFlip from 'react-card-flip';
import { IconPickerItem } from 'react-fa-icon-picker'
import DoraeIcon from 'assets/favicon.ico';
import { getMedia } from 'reducers/media';
import EntityModal from './entityModal';
import './libraryCarousel.scss';
import '@fortawesome/fontawesome-free/css/all.css';


function getIcon(name, index, small = false) {
    return <i key={name} className={name} style={{fontSize: 48, color: "#AAA"}} />
}


    
function carousel(root, startingIndex = 0) {
    var
        figure = root.querySelector('figure'),
        nav = root.querySelector('nav'),
        images = figure.children,
        n = images.length,
        gap = root.dataset.gap || 0,
        bfc = 'bfc' in root.dataset,
        
        theta =  2 * Math.PI / n,
        currImage = startingIndex,
        apothem = 0
    ;
    
    setupCarousel(n, parseFloat(getComputedStyle(images[0]).width));
    window.addEventListener('resize', () => { 
        setupCarousel(n, parseFloat(getComputedStyle(images[0]).width)) 
    });

    function setupCarousel(n, s) {
        apothem = s / (2 * Math.tan(Math.PI / n));
        
        // figure.style.transformOrigin = `50% 50% ${- apothem}px`;
        const figure = root.querySelector('figure')
        figure.style.transform = `translateZ(${apothem}px)`;

        // for (var i = 0; i < n; i++)
        //     images[i].style.padding = `${gap}px`;
        for (let i = 0; i < n; i++) {
            // images[i].style.transformOrigin = `50% 50% ${- apothem}px`;
            images[i].style.transform = `rotateY(${i * theta}rad) translateZ(${apothem}px)`;
        }
        if (bfc)
            for (let i = 0; i < n; i++)
                 images[i].style.backfaceVisibility = 'hidden';
        
        rotateCarousel(currImage);
    }

    function rotateCarousel(imageIndex) {
        figure.style.transform = `translateZ(${- apothem}px) rotateY(${imageIndex * -theta}rad)`;
    }
    
}

function rotateCarousel(root, imageIndex) {
    var
        figure = root.querySelector('figure'),
        images = figure.children,
        n = images.length,
        s = parseFloat(getComputedStyle(images[0]).width),
        theta =  2 * Math.PI / n,
        apothem =  s / (2 * Math.tan(Math.PI / n))
    ;
    figure.style.transform = `translateZ(${- apothem}px) rotateY(${imageIndex * -theta}rad)`;
}

function CarouselCard({ onCardClicked, content, isCurrentCard, libraryTemplates, forceRerender = () => console.info('no rerender handler'), index = 0 }) {
  const [isFlipped, setIsFlipped] = useState(false);
  const [backIndex, setBackIndex] = useState(0);
  const [modalEntity, setModalEntity] = useState();
  const [entityModalOpen, setEntityModalOpen] = useState(false);
  const [entityModalType, setEntityModalType] = useState('');
  const [entityModalTitle, setEntityModalTitle] = useState('');
  let maxBackIndex = 0;
  if (content.back && content.back.cards) {
    maxBackIndex = content.back.cards.length - 1;
  }
  const onClick = () => {
      if (isCurrentCard) {
        if (isFlipped && backIndex < maxBackIndex) {
            setBackIndex(backIndex + 1);
        } else {
            setIsFlipped(!isFlipped);
            setTimeout(() => setBackIndex(0), 10);
        }
      }
      onCardClicked();
  }
  const icons = content.front.icons ? content.front.icons : [];
  let iconEls = null;
  if (icons.length > 0) {
      iconEls = (
        <div className={'collection-chart-icons'} >
            {
                icons.map((icon, index) => getIcon(icon, index))
            }
        </div>
      );
  }
  let cardBack = (
    <div className="card mg-x-15 mg-y-15">
        <div className="card-body library-card-body pd-x-0 pd-y-0">
            <img src={DoraeIcon} className={'dorae-icon'} alt='' />
        </div>
    </div>
  );
  if (content.blank) {
      return <div/>;
  }
  let backIconEls = null;
  if (content.back) {
    if (content.back.cards && content.back.cards.length >= backIndex && content.back.cards[backIndex].icons) {
        backIconEls = (
        <div className={'collection-chart-icons'} >
            {
                content.back.cards[backIndex].icons.map((icon, index) => getIcon(icon, index))
            }
        </div>
        );
    }
    cardBack = (
        <div className="card mg-x-15 mg-y-15">
            <div className="card-body library-card-body pd-x-0 pd-y-0">
                    { backIconEls }
                    <p className="card-content">
                        {
                            content.back.cards 
                            && content.back.cards.map(card => {
                                if (!card.text || !card.text.runs){
                                    return null;
                                }
                                else {
                                    return (
                                        <>
                                            {
                                                card.text.runs.map(run => {
                                                    if (!!run.linkType) {
                                                        let clickHandler = () => null;
                                                        let linkType = run.linkType;
                                                        if (isCurrentCard) {
                                                            if (run.linkType === 'dash') {
                                                                clickHandler = (ev) => {
                                                                    ev.stopPropagation(); 
                                                                    ev.preventDefault();
                                                                    setModalEntity(run.entity);
                                                                    setEntityModalOpen(true);
                                                                    setEntityModalType('dash');
                                                                    setEntityModalTitle(run.entity.title);
                                                                }
                                                            } else if (run.linkType === 'dot') {
                                                                clickHandler = (ev) => {
                                                                    ev.stopPropagation(); 
                                                                    ev.preventDefault();
                                                                    setModalEntity(run.entity);
                                                                    setEntityModalOpen(true);
                                                                    setEntityModalType('dot');
                                                                    setEntityModalTitle(run.entity.title);
                                                                }
                                                            } else if (run.linkType === 'dash-template') {
                                                                clickHandler = (ev) => {
                                                                    ev.stopPropagation(); 
                                                                    ev.preventDefault();
                                                                    setModalEntity(run.entity);
                                                                    setEntityModalOpen(true);
                                                                    setEntityModalType(run.linkType);
                                                                    setEntityModalTitle(run.entity.title);
                                                                }
                                                            } else if (run.linkType === 'dot-template') {
                                                                clickHandler = (ev) => {
                                                                    ev.stopPropagation(); 
                                                                    ev.preventDefault();
                                                                    setModalEntity(run.entity);
                                                                    setEntityModalOpen(true);
                                                                    setEntityModalType(run.linkType);
                                                                    setEntityModalTitle(run.entity.title);
                                                                }
                                                            } else if (run.linkType === 'document') {
                                                                linkType = 'document';
                                                                clickHandler = (ev) => {
                                                                    ev.stopPropagation(); 
                                                                    ev.preventDefault();
                                                                    const docObj = JSON.parse(run.entity);
                    
                                                                    getMedia(docObj.id, result => {
                                                                        setModalEntity(result);
                                                                        setEntityModalType('document');
                                                                        setEntityModalTitle(run.entity.title);
                                                                        setEntityModalOpen(true);
                                                                    })
                                                                }
                                                            } else if (run.linkType === 'document-template') {
                                                                clickHandler = (ev) => {
                                                                    ev.stopPropagation();
                                                                    ev.preventDefault();
                                                                    setModalEntity(run.entity);
                                                                    setEntityModalOpen(true);
                                                                    setEntityModalType(run.linkType);
                                                                    setEntityModalTitle(run.entity.title);
                                                                }
                                                            }
                                                        }
                                                        return (
                                                            <span 
                                                                key={JSON.stringify(run)}
                                                                className={'carousel-card-link link-type-' + linkType}
                                                                onClick={clickHandler}
                                                                style={{cursor: 'pointer'}}
                                                            >
                                                                {run.text}
                                                                &nbsp;
                                                            </span>
                                                        )
                                                    } else {
                                                        return (
                                                            <span key={JSON.stringify(run)}>
                                                                {run.text}&nbsp;
                                                            </span>
                                                        )
                                                    }
                                                })
                                            }
                                        </>
                                    )
                                }
                            })[backIndex]
                        }
                    </p>
                    {
                        maxBackIndex > 0 &&
                        <p className="card-back-index">{`${backIndex + 1}/${maxBackIndex + 1}`}</p>
                    }
            </div>
        </div>
      );
  }
  return (
    <>
        {
            !!modalEntity
            &&
            <EntityModal
                open={entityModalOpen}
                setOpen={(open) => setEntityModalOpen(open) }
                onClose={forceRerender}
                entityType={entityModalType}
                entity={modalEntity}
                allEntities={libraryTemplates}
            />
        }
        <div key={JSON.stringify(content) + `-${index}`} className="carousel-card" onClick={onClick} >
            <ReactCardFlip isFlipped={isFlipped} flipDirection="vertical" cardZIndex={isCurrentCard ? '100' : '50'}>
                <div className="card mg-x-15 mg-y-15">
                    <div className="card-body library-card-body pd-x-0 pd-y-0">
                        { iconEls }
                        <p className="card-content">
                            {
                                content.front.text
                                && content.front.text
                                && content.front.text.runs.map(run => {
                                    if (!!run.linkType) {
                                        let clickHandler = () => null;
                                        let linkType = run.linkType;
                                        if (isCurrentCard) {
                                            if (run.linkType === 'dash') {
                                                clickHandler = (ev) => {
                                                    ev.stopPropagation(); 
                                                    ev.preventDefault();
                                                    setModalEntity(run.entity);
                                                    setEntityModalOpen(true);
                                                    setEntityModalType('dash');
                                                    setEntityModalTitle(run.entity.title);
                                                }
                                            } else if (run.linkType === 'dot') {
                                                clickHandler = (ev) => {
                                                    ev.stopPropagation(); 
                                                    ev.preventDefault();
                                                    setModalEntity(run.entity);
                                                    setEntityModalOpen(true);
                                                    setEntityModalType('dot');
                                                    setEntityModalTitle(run.entity.title);
                                                }
                                            } else if (run.linkType === 'dash-template') {
                                                clickHandler = (ev) => {
                                                    ev.stopPropagation(); 
                                                    ev.preventDefault();
                                                    setModalEntity(run.entity);
                                                    setEntityModalOpen(true);
                                                    setEntityModalType(run.linkType);
                                                    setEntityModalTitle(run.entity.title);
                                                }
                                            } else if (run.linkType === 'dot-template') {
                                                clickHandler = (ev) => {
                                                    ev.stopPropagation(); 
                                                    ev.preventDefault();
                                                    setModalEntity(run.entity);
                                                    setEntityModalOpen(true);
                                                    setEntityModalType(run.linkType);
                                                    setEntityModalTitle(run.entity.title);
                                                }
                                            } else if (run.linkType === 'document') {
                                                linkType = 'document';
                                                clickHandler = (ev) => {
                                                    ev.stopPropagation(); 
                                                    ev.preventDefault();
                                                    const docObj = JSON.parse(run.entity);
    
                                                    getMedia(docObj.id, result => {
                                                        setModalEntity(result);
                                                        setEntityModalType('document');
                                                        setEntityModalTitle(run.entity.title);
                                                        setEntityModalOpen(true);
                                                    })
                                                }
                                            } else if (run.linkType === 'document-template') {
                                                clickHandler = (ev) => {
                                                    ev.stopPropagation();
                                                    ev.preventDefault();
                                                    setModalEntity(run.entity);
                                                    setEntityModalOpen(true);
                                                    setEntityModalType(run.linkType);
                                                    setEntityModalTitle(run.entity.title);
                                                }
                                            }
                                        }
                                        return (
                                            <span 
                                                key={JSON.stringify(run)}
                                                className={'carousel-card-link link-type-' + linkType}
                                                onClick={clickHandler}
                                                style={{cursor: 'pointer'}}
                                            >
                                                {run.text} &nbsp;
                                            </span>
                                        )
                                    } else {
                                        return (
                                            <span key={JSON.stringify(run)}>
                                                {run.text} &nbsp;
                                            </span>
                                        )
                                    }
                                })
                            }
                        </p>
                    </div>
                </div>
                { cardBack }
            </ReactCardFlip>
        </div>
    </>
  )

}

function LibraryCarousel({ collection, libraryTemplates }) {
    const [selectedImage, setSelectedImage] = useState(0);
    let collectionHeaders = collection.chart.rows.find(row => !!row.header);
    if (!collectionHeaders) {
        collectionHeaders = collection.chart.rows[0] || [];
    }
    const cards = collectionHeaders.columns.map((ch, index) => {
        const card = {};
        card.front = {
            text: ch.text,
            icons: ch.icons,
        };
        // let backContent = null;
        let backCards = [];
        collection.chart.rows.forEach(row => {
            row.columns.forEach((col, colIndex) => {
                if (colIndex === index && !!col.cardContent) {
                    let backIcons = (col.icons || []);
                    let backRuns = (col.text.runs || []);
                    if (backIcons.length > 0 || backRuns.length > 0){
                        backCards.push({
                            text: {
                                runs: backRuns,
                            },
                            icons: backIcons,
                        })
                    }
                }
            })
        });
        if (backCards.length > 0) {
            card.back = {
                cards: backCards
            };
        }
        return card;
    });

    const initCarousel = () => {
        const carousels = document.querySelectorAll('.dorae-library-carousel');
        for (var i = 0; i < carousels.length; i++) {
            carousel(carousels[i], selectedImage);
        }
    }

    useEffect(() => {
        initCarousel();
    }, [])


    const prevClicked = () => {
        let nextImageIndex = selectedImage > 0 ? selectedImage - 1 : (cards.length - 1);
        setSelectedImage(nextImageIndex);
        var carousel = document.querySelector('.dorae-library-carousel');
        rotateCarousel(carousel, nextImageIndex);
    };

    const nextClicked = () => {
        let nextImageIndex = selectedImage < (cards.length - 1) ? selectedImage + 1 : 0;
        setSelectedImage(nextImageIndex);
        var carousel = document.querySelector('.dorae-library-carousel');
        rotateCarousel(carousel, nextImageIndex);
    };

    const navDotClicked = (index) => {
        setSelectedImage(index);
        var carousel = document.querySelector('.dorae-library-carousel');
        rotateCarousel(carousel, index);
    }

    let blankCards = [];
    const MINIMUM_POLYGON_SIDES = 7;
    if (cards.length < MINIMUM_POLYGON_SIDES) {
        for (let i=cards.length; i < MINIMUM_POLYGON_SIDES; i++) {
            blankCards.push(<div key={'blank-card-' + i} className='empty-card-space'/>);
        }
    } else {
        blankCards.push(<div className='empty-card-space'/>);
    }

  return (
      <>
        <div className="dorae-library-carousel" data-gap="50">
            <figure>
                {
                    cards.map(
                        (card, index) => {
                            let onCardClicked = () => null;
                            if ( 
                                (
                                    index < selectedImage 
                                    && !( index === 0 && selectedImage === (cards.length - 1) )
                                ) || (
                                    selectedImage === 0 && index === (cards.length - 1)
                                ) 
                            ) {
                                onCardClicked = prevClicked;
                            } else if (index !== selectedImage) {
                                onCardClicked = nextClicked;
                            }
                            const isCurrentCard = index === selectedImage;
                            return (
                                <CarouselCard
                                    key={index} 
                                    onCardClicked={onCardClicked}
                                    forceRerender={() => console.debug('Prevent carousel rerender')}
                                    content={card} 
                                    isCurrentCard={isCurrentCard} 
                                    libraryTemplates={libraryTemplates}
                                    index={index}
                                />
                            )
                        }
                    )
                }
                {
                    blankCards
                }
            </figure>
            <nav>
                <button 
                    className="nav prev" 
                    onClick={prevClicked}
                >
                    ◄
                </button>
                {
                    cards.map((_, index) => (
                        <div 
                            key={`nav-dot-${index}`} 
                            onClick={() => navDotClicked(index)} 
                            className={'dot ' + ((index === selectedImage) ? 'selected' : '')} 
                        />
                    ))
                }
                <button 
                    className="nav next" 
                    onClick={nextClicked}
                >
                    ►
                </button>
            </nav>
        </div>
      </>
  );
}



const mapStateToProps = state => ({
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getMedia,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LibraryCarousel);