import React from 'react';
import { injectIntl } from 'react-intl';
import { Field, ErrorMessage } from 'formik';
import ReactPhoneInput from 'react-phone-input-2';

import 'react-phone-input-2/dist/style.css';
import './fields.scss';

class PhoneField extends React.Component {
    constructor(props) {
        super(props);
        this.state = { country: undefined };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        {
            const language = !!navigator.languages && !!navigator.languages.length ? navigator.languages[0] : navigator.language;
            if (!language) {
                this.setState({ ...this.state, country: 'us' });
                return;
            }
            const country = language.indexOf('-') > -1 ? language.split('-')[1] : language;
            this.setState({ ...this.state, country: (country || 'us').toLowerCase() });
        }
    }

    handleChange = async (value, form) => {
        const { name, submitOnChange = false } = this.props;
        await form.setFieldValue(name, value);
        if (!!submitOnChange) {
            form.submitForm();
        }
    };

    render() {
        const {
            intl: { formatMessage },
            name,
            placeholder,
            showError = true
        } = this.props;
        const { country } = this.state;
        if (!country) return null;

        return (
            <React.Fragment>
                <div className="phone-field">
                    <Field
                        {...this.props}
                        render={({ field, form }) => {
                            return (
                                <React.Fragment>
                                    <div className="input-placeholder">{placeholder || formatMessage({ id: `${name}.placeholder` })}</div>
                                    <ReactPhoneInput
                                        className="form-control"
                                        enableAreaCodes={false}
                                        disableAreaCodes={true}
                                        placeholder={placeholder || formatMessage({ id: `${name}.placeholder` })}
                                        value={field.value}
                                        defaultCountry={country}
                                        onChange={v => this.handleChange(v, form)}
                                    />
                                </React.Fragment>
                            );
                        }}
                    />
                </div>
                {!!showError && <ErrorMessage name={name} component="div" className="validation-message" />}
            </React.Fragment>
        );
    }
}

export default injectIntl(PhoneField);
