import InputField from './input';
import InputNumericField from './inputNumeric';
import SelectField from './selectField';
import CreatableSelectField from './creatableSelectField';
import AsyncSelectField from './asyncSelect';
import BooleanField from './boolean';
import CheckboxField from './checkbox';
import ChecklistField from './checklist';
import DateField from './date';
import SliderField from './slider';
import RangeField from './range';
import RichTextField from './richText';
import RichTextButton from './richTextButton';
import MultiTextField from './multiText';
import UploadField from './upload';
import ImageAlignmentField from './imageAlignment';
import PhoneField from './phone';
import ButtonField from './button';
import YesNoNullField from './yesNoNullField';

export const Fields = {
    Input: InputField,
    InputNumeric: InputNumericField,
    Select: SelectField,
    CreatableSelect: CreatableSelectField,
    AsyncSelect: AsyncSelectField,
    Boolean: BooleanField,
    Checkbox: CheckboxField,
    Checklist: ChecklistField,
    Date: DateField,
    Slider: SliderField,
    Range: RangeField,
    RichText: RichTextField,
    RichTextButton: RichTextButton,
    MultiText: MultiTextField,
    Upload: UploadField,
    ImageAlignment: ImageAlignmentField,
    Phone: PhoneField,
    Button: ButtonField,
    YesNoNull: YesNoNullField,
};
