export const PAYMENT_LIST_PRODUCTS = `
    query {
        stripe_product_list {
            id
            name
            metadata {
                category
            }
        }
    }
`;
