export const PREVIEW_PAYMENT = `
mutation _($payment_plan_id : String!, $seats : Int!)  
{
    company_preview_payment_plan(payment_plan_id: $payment_plan_id, seats: $seats)
    {
        amount_due
        created
        line_items {
            plan {
                id
                nickname
            }
            quantity
            amount
            description
            start
            end
        }
    }
}
`;
