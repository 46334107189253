import React from 'react';
import { FormattedMessage } from 'react-intl';
import Settings from 'pages/settings';
import EventTemplates from 'pages/event-templates';
import CargoCategories from 'pages/cargo-categories';
import Lots from 'pages/lots';
import Transactions from 'pages/events';
import Documents from 'pages/documentTemplates';
import Collections from 'pages/collections';
import { history } from 'configuration/history';

class IntroductionDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    renderPage2 = (title, description) => {
        return (
            <div className="col-12 intro-line">
                <span className="intro-line-title">{title}</span>
                <i className="material-icons">arrow_right</i>
                <span className="intro-line-description">{description}</span>
            </div>
        );
    };

    renderPage = (title, description, route, icon, disabled = false) => {
        return (
            <div className={"col-6 intro-box" + (disabled ? ' disabled' : '')}>
                <div className="intro-box-inner" onClick={() => !disabled && history.push(route + window.location.search)}>
                    <i className={`intro-box-icon icon ${icon} ${disabled ? 'disabled' : ''}`} />
                    <span className="intro-box-title tx-century-gothic">{title}</span>
                    <span className="intro-box-description">{description}</span>
                </div>
            </div>
        );
    };

    render() {
        const { user = {}, isCompanyAdmin, company } = this.props;
        const { is_paid, subscription_type } = company;
        return (
            <div className="">
                <div className="row">
                    <div className="col">
                        <h3 className="pd-t-30 pd-x-5 color-primary">
                            { company.company_name }
                        </h3>
                        <h4 className="pd-x-5 color-primary">
                            <FormattedMessage id="page.dashboard.subtitle" values={{ user: user.name }} />
                        </h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 pd-t-10 pd-b-30 tx-center">
                        {/* <h4 className="pd-t-30 color-primary">
                            <FormattedMessage id="page.dashboard.yourEnvironment" />
                        </h4> */}
                    </div>
                    {!!is_paid && this.renderPage(Lots.meta.title, <FormattedMessage id="lots.pageDescription" />, '/objects', 'ion-ios-cart-outline')}
                    {!!is_paid &&
                        this.renderPage(
                            Transactions.meta.title,
                            <FormattedMessage id="events.pageDescription" />,
                            '/events',
                            'ion-arrow-swap'
                        )}
                    {!!is_paid &&
                        this.renderPage(
                            <FormattedMessage id='page.library.title' />,
                            <FormattedMessage id="library.pageDescription" />,
                            '/library',
                            //'ion-ios-library-outline',
                            'ion-ios-book-outline'
                        )}
                    {!!is_paid && this.renderPage(Collections.meta.title, <FormattedMessage id="collectionsSubtitle" />, '/collections', 'ion-ios-folder-outline')}
                    {!!is_paid &&
                        this.renderPage(
                            <FormattedMessage id='visibility' />,
                            <FormattedMessage id="visibility.pageDescription" />,
                            '/visualize',
                            'ion-ios-eye-outline',
                            subscription_type !== 'pro',
                        )}
                    {!!is_paid &&
                        this.renderPage(
                            Documents.meta.title,
                            <FormattedMessage id="documents.pageDescription" />,
                            '/document-templates',
                            'ion-ios-paper-outline'
                        )}
                    {!!is_paid &&
                        this.renderPage(
                            <FormattedMessage id='intelligence' />,
                            <FormattedMessage id="intelligence.pageDescription" />,
                            '/intelligence/alerts',
                            'ion-ios-lightbulb-outline',
                        )}
                    {!!is_paid &&
                        this.renderPage(
                            <FormattedMessage id='relationships' />,
                            <FormattedMessage id="relationships.pageDescription" />,
                            '/',
                            'ion-ios-people',
                            true
                        )}
                </div>
            </div>
        );
    }
}

export default IntroductionDashboard;
