import React from 'react';
import ReactModal from 'react-modal';
import Transaction from 'pages/events/containers/eventContainer';
import { FormattedMessage } from 'react-intl';
import { history } from 'configuration/history';

class CargoTypeModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            eventSaving: false,
            modalCloseRequested: false,
        }
    }

    handleSaveStateChange = (state) => {
        const { setModalOpen = () => null } = this.props;
        if (!state && this.state.modalCloseRequested) {
            setModalOpen(false);
        }
        this.setState({ eventSaving: state });
    }

    render() {
        const { 
            modalOpen = false, 
            setModalOpen = () => null, 
            cargoType = null, 
            hideTitleAndDescription = true, 
            readOnly = false, 
            modalTitle, 
            primaryButton,
            showLink,
            onClose = () => null,
        } = this.props;

        return (
            <ReactModal
                isOpen={modalOpen}
                onRequestClose={() => {
                    setModalOpen(false);
                    onClose();
                }}
                className="modal-block dialog"
                overlayClassName="modal-overlay gray"
                style={{content: {width: '70%', maxHeight: '70%', overflowY: 'auto', overflowX: 'hidden'}}}
            >
                <div className="modal-dialog-centered" role="document" onClick={e => e.stopPropagation()}>
                    <div className="modal-content bd-0">
                        <div className="modal-header pd-x-20">
                            {
                                this.state.eventSaving ?
                                    <div style={{ position: 'relative' }}>
                                        <div className="loader" />
                                        <span className="mg-l-5">
                                            <FormattedMessage id="common.saving" />
                                        </span>
                                    </div>
                                    :
                                    <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                                        { modalTitle ? modalTitle : <FormattedMessage id="common.cargoType" /> }
                                    </h6>
                            }
                            <div>
                                {
                                showLink &&
                                <button
                                    type="button"
                                    className="btn btn-outline-info"
                                    onClick={() => cargoType && cargoType.id && history.push(`/events/${cargoType.id}`)}
                                >
                                    <FormattedMessage id='openPage' />
                                </button>
                                }
                            
                                <button
                                    type="button"
                                    className="btn btn-outline-info font-weight-bold ml-2"
                                    onClick={() => setModalOpen(false)}
                                >
                                    {'X'}
                                </button>
                            </div>
                                
                        </div>
                        <div className="modal-body br-section-wrapper">
                            <Transaction 
                                transaction={cargoType} 
                                company={{}} 
                                hideHeading={true} 
                                hideTitleAndDescription={hideTitleAndDescription} 
                                showCloseButton={true}
                                primaryButton={primaryButton}
                                onCancel={() => setModalOpen(false)}
                                onSavingSet={this.handleSaveStateChange}
                                doValidation={false} 
                                showQuantityInfo={this.props.showQuantityInfo}
                                dot={this.props.dot}
                                readOnly={readOnly}
                                extendedProps={{ selectFieldBackgroundColor: '#e9ecef', unitFieldBackgroundColor:'#e9ecef'}}
                            />
                        </div>
                    </div>
                </div>
            </ReactModal>
        );
        
    }
}

export default CargoTypeModal;