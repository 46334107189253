import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { injectIntl, FormattedMessage } from 'react-intl';
import LoadingOverlay from 'react-loading-overlay';

export default injectIntl(function DashDeleteModal(props) {
    const { 
        open,
        setOpen,
        loading,
        hasDotSideEffects = false,
        canUnwind = false,
        deleteTargetTitle = '',
        onConfirm = () => null,
        stayOpenOnConfirm = false,
        confirmLabel = null,
        cancelLabel = null,
        intl: { formatMessage },
    } = props;

    const [ unwindDeletedDash, setUnwindDeletedDash ] = useState(false);
    let modalBody = '';
    if (!hasDotSideEffects) {
        modalBody = <FormattedMessage id='confirmEventDelete' values={{ title: deleteTargetTitle }} />
    } else if (canUnwind) {
        modalBody = (
            <React.Fragment>
                <FormattedMessage id='confirmEventDeleteWithUnwind' values={{ title: deleteTargetTitle }} />
                <div className={`checkbox-field withPadding pd-y-10 justify-content-center`}>
                    <div className='float-left pd-x-10' style={{width: '100%'}}>
                        <label className="ckbox">
                            <input type="checkbox" checked={unwindDeletedDash} onChange={(e) => { e.stopPropagation(); setUnwindDeletedDash(!unwindDeletedDash)}}/>
                            <span className={`ckbox-label-text${unwindDeletedDash ? ' checked' : ''}`}><FormattedMessage id="reverseDotEffects" /></span>
                        </label>
                    </div>
                </div>
            </React.Fragment>
        );
    } else {
        modalBody = <FormattedMessage id='confirmEventDeleteCanNotUnwind' values={{ title: deleteTargetTitle }} />
    }

    const closeModal = () => {
        setOpen(false);
        setUnwindDeletedDash(false);
    }

    return (
        <ReactModal
            isOpen={open}
            onRequestClose={closeModal}
            className="modal-block dialog"
            overlayClassName="modal-overlay gray"
            style={{zIndex: '2000'}}
        >
            <LoadingOverlay
                active={loading}
                spinner
                text={<FormattedMessage id='common.loading'/>}
            >
                <div className="modal-dialog modal-lg wd-450" role="document">
                    <div className="modal-content bd-0">
                        <div className="modal-header pd-x-20">
                            <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                                <FormattedMessage id='common.confirmVerb' values={{verb: formatMessage({ id: 'delete' })}}/>
                            </h6>
                        </div>
                        <div className="modal-body pd-20">
                            { modalBody }
                        </div>
                        <div className="modal-footer justify-content-center">
                            <button
                                type="button"
                                className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                onClick={() => {
                                    if (onConfirm) {
                                        onConfirm(hasDotSideEffects && canUnwind && unwindDeletedDash);
                                    }
                                    if (!stayOpenOnConfirm) {
                                        closeModal();
                                    }
                                }}
                            >
                                { confirmLabel ? confirmLabel : <FormattedMessage id="common.yes" /> }
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                onClick={closeModal}
                            >
                                { cancelLabel ? cancelLabel : <FormattedMessage id="common.cancel" /> }
                            </button>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        </ReactModal>
    )
});