import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getProfile } from '../../../../../reducers/identity';
import { loadModel } from '../../../../../reducers/models';
import { setMenuVisibility } from '../../../../../reducers/menus';
import { getDocumentTemplate, updateDocumentTemplate, getDocumentSample } from '../../../../../reducers/document';
import '../../../models.scss';

import '../../../../../components/fields/fields.scss';
import FieldsReferencesMenu from './fieldsReferencesMenu';
import EdiText from 'react-editext'
import { DocumentEditorContainerComponent, Toolbar } from '@syncfusion/ej2-react-documenteditor';
DocumentEditorContainerComponent.Inject(Toolbar);

class WordDocumentTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            previewData: "",
            loadingPreview: false,
            contentChanged: false,
        };

        this.persistChanges = this.persistChanges.bind(this);
    }

    componentDidMount() {
        const { match, loadModel, getDocumentTemplate } = this.props;
        loadModel(match.params.modelId);
        getDocumentTemplate(match.params.id, (dt) => {
            this.setState({templateTitle: dt.title})
            if (this.documenteditor){
                this.documenteditor.documentEditor.open(dt.file_data);
            }
        });
        

        this.updateInterval = setInterval(async () => {
            await this.persistChanges();
        }, 2000);
    }

    async componentWillUnmount() {
        await this.persistChanges();
        window.onbeforeunload = null;
        clearInterval(this.updateInterval);
    }

    persistChanges = async () => {
        const { template, updateDocumentTemplate, getDocumentTemplate } = this.props;
        const documentEditor = this.documenteditor.documentEditor;
        if (this.state.contentChanged) {
            window.onbeforeunload = function() {
                return true;
            };
            try {
                await documentEditor.saveAsBlob('Sfdt').then(async (exportedDocument) => {
                    await documentEditor.saveAsBlob('Docx').then((exportedWordDocument) => {
                        const sfdtReader = new FileReader();
                        sfdtReader.addEventListener('loadend', () => {
                            const base64Text = ";base64,";
                            const base64Sfdt = sfdtReader.result.substring(sfdtReader.result.indexOf(base64Text) + base64Text.length);   
                            const sfdt = atob(base64Sfdt);
                            template.file_data = sfdt;
                            template.title = this.state.templateTitle;
                            const wordReader = new FileReader();
                            wordReader.onloadend = () => {
                                const base64Text = ";base64,";
                                const base64data = wordReader.result.substring(wordReader.result.indexOf(base64Text) + base64Text.length);                
                                template.server_file_data = base64data;
                                updateDocumentTemplate(template, () => {
                                    getDocumentTemplate(template.id, () => {
                                        this.setState({contentChanged: false});
                                        window.onbeforeunload = null;
                                    });
                                });
                            }
                            wordReader.readAsDataURL(exportedWordDocument); 
                        });
                        sfdtReader.readAsDataURL(exportedDocument);
                    });
                });
            }
            catch (err) {
                console.error('Error saving template: ', err);
                window.onbeforeunload = null;
            }
        }
    };

    onSaveTitle = (val) => {
        this.setState({templateTitle: val, contentChanged: true});
    }

    onFieldSelect(field) {
        let proxy = this;
        proxy.documenteditor.documentEditor.editor.insertText('{{' + field.id + '}}');
    }

    render() {
        const { model, template, fields } = this.props;
        if (!model || !template || !fields) return null;

        return (
            <React.Fragment>
                <EdiText
                    type="text"
                    value={this.state.templateTitle}
                    onSave={this.onSaveTitle}
                    showButtonsOnHover
                />
                <FieldsReferencesMenu name="content" onFieldSelect={this.onFieldSelect.bind(this)} manualClassName='btn btn-outline-info' sendFullField={true}/>
                <DocumentEditorContainerComponent 
                    id="container" 
                    style={{ 'height': this.props.manualHeight }}  
                    ref={(scope) => { this.documenteditor = scope; }} 
                    enableToolbar={true}
                    contentChange={() => this.setState({contentChanged: true})}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    company: state.identity.company,
    model: state.fields.model,
    fields: state.fields.items,
    fieldTypes: state.fields.types,
    template: state.document.instance,
    templateChanged: state.document.templateChanged
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getProfile,
            loadModel,
            getDocumentTemplate,
            getDocumentSample,
            setMenuVisibility,
            updateDocumentTemplate
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WordDocumentTemplate);
