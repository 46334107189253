import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Table from 'components/table';
import { listLots } from 'reducers/lots';
import CargoTypeModal from './cargoTypeModal';
import moment from 'moment';
import { setLotsTableOptions, setLotsTableQueryState, setLotsTableQueryValues, setLotsTableColumns } from 'reducers/sessionSettings';
import QueryBuilder from './queryBuilder';
import { getIndices } from 'reducers/indices';
import { getModels } from 'reducers/models';
import { translateMatchStrength, formatDateFriendly } from 'utilities/common';
import FieldValueRenderer from 'pages/events/components/fieldValueRenderer';
import ColumnPicker, { getInitialColumnVisibility } from 'components/table/columnPicker';
import { history } from 'configuration/history';
import { Button, Popover, Menu, MenuItem, MenuDivider, Icon } from '@blueprintjs/core';
import { setGraphEntity } from 'reducers/entityGraph';
import QRCode from 'qrcode'
import JsBarcode from 'jsbarcode';
import { createEntityGraphLink } from 'reducers/entityGraph'
import DashViewModal from 'pages/events/components/dashViewModal';
import ConfirmModal from 'components/modals/confirm'


class LotsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: { open: false, data: undefined },
            deleteModal: { open: false, data: undefined },
            cargoCategories: props.initialCargoCategories || [],
            selectedModels: [],
        };
    }

    async componentDidMount() {
        const { getIndices, useRootState = true, useDotTags = false } = this.props;
        
        this.setState({ columnVisibility: getInitialColumnVisibility(this.getColumns()) })
        const indicesPromise = new Promise(resolve => getIndices(
            { status_ids: [2], model_type: 2 }, 
            r => {
                this.setState({ columnVisibility: getInitialColumnVisibility(this.getColumns(r.data)) }, resolve)
            },
            useDotTags ? 'dot' : null
        ));
        await Promise.all([indicesPromise]);
    }

    setModalVisibility = (open, data) => {
        this.setState({ ...this.state, modal: { open, data: open ? data : undefined } });
    };

    setDeleteModalVisibility = (open, data) => {
        this.setState({ ...this.state, deleteModal: { open, data: open ? data : undefined } });
    };

    loadData = (options, onComplete) => {
        let { columnVisibility, selectedModels } = this.state;
        const { indexFields, dotIndexFields, useDotTags = false, lotsTableColumns, includeLastVisited = false } = this.props;
        if (lotsTableColumns) {
            columnVisibility = lotsTableColumns;
        }
        if (!options.cargo_categories) {
            options.cargo_categories = this.state.cargoCategories
        }
        const { listLots, externalModels = [] } = this.props;
        options.cargo_categories = [...new Set(options.cargo_categories.concat(options.models || []).concat(externalModels.map(m => m.id)))]
        this.setState({ selectedModels: selectedModels.filter(m => options.cargo_categories.includes(m.id)) });
        const tags = useDotTags ? dotIndexFields : indexFields;
        const resultIndices = tags.filter(i => !!columnVisibility && columnVisibility[`field_${i.id}`] === true);
        options.result_index_field_ids = resultIndices.map(i => i.id);
        options.include_last_visualized = includeLastVisited;
        return listLots(options, onComplete);
    };

    setColumns = selectedColumns => {
        const { setLotsTableColumns, persistState = true } = this.props;
        this.setState({ columnVisibility: selectedColumns });
        if (persistState) {
            setLotsTableColumns(selectedColumns);
        }
    };

    getColumns = providedIndices => {
        let { columnVisibility, options = { } } = this.state;
        const { lotsTableColumns, showMenu = false, includeLastVisited = false, hiddenColumns = [], prependColumns = [], locale, useDotTags = false, indexFields, dotIndexFields, createEntityGraphLink, company } = this.props;
        if (lotsTableColumns) {
            columnVisibility = lotsTableColumns;
        }
        const tags = (useDotTags ? dotIndexFields : indexFields ) || providedIndices || [];
        const resultTags = tags.filter(i => !!columnVisibility && columnVisibility[`field_${i.id}`] === true);
        const isPro = (company.subscription_type === 'pro' || company.id === 1);
        const showMatchStrength = (options.criteria && options.criteria !== '[]') || (options.field_criteria && options.field_criteria !== '[]');
        let columns = [
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block text-center',
                Header: <FormattedMessage id="common.id" />,
                width: 100,
                id: 'hash_id',
                accessor: d => (
                    this.props.onIdSelected ?
                    <a onClick={e => { e.stopPropagation(); e.preventDefault(); this.props.onIdSelected(d.id);}} href='#'>
                        { d.hash_id }
                    </a>
                    :
                    <a onClick={e => { e.stopPropagation(); e.preventDefault(); this.setState({ cargoType: d.cargo_type, cargoTypeModalOpen: true, dot: d, modalTitle: d.hash_id });}} href='#'>
                        { d.hash_id }
                    </a>
                    
                )
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block text-center',
                Header: <FormattedMessage id="common.cargoType" />,
                id: 'cargo_type_category_title',
                accessor: d =>  d.cargo_type.model.title
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block text-center',
                Header: <FormattedMessage id="common.cargoUnitName" />,
                id: 'cargo_unit_name',
                accessor: d => d.cargo_unit ? <FormattedMessage id={d.cargo_unit.display_name} /> : ''
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block text-center',
                Header: <FormattedMessage id="common.quantity" />,
                width: 150,
                id: 'quantity',
                accessor: d => d.quantity
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block text-center',
                Header: <FormattedMessage id="created_by_dash_id" />,
                id: 'created_by_dash_id',
                accessor: d => {
                    let content = null;
                    if (d.created_by_dash_id) {
                        let stateUpdate = () => null;
                        if (d.created_by_dash_is_accessible) {
                            stateUpdate = () => this.setState({ dashModalOpen: true, viewingDash: d.created_by_dash_id });
                        } else {
                            stateUpdate = () => this.setState({ externalCompanyName: d.created_by_dash_company, inaccessibleDashModalOpen: true });
                        }
                        content = (
                            <a 
                                href='#'
                                onClick={e => { e.stopPropagation(); e.preventDefault(); stateUpdate(); }}
                            >
                                {d.created_by_dash_id}
                            </a>
                        )
                    }
                    return content;
                }
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block text-center',
                Header: <FormattedMessage id="common.status" />,
                id: 'status_name',
                accessor: d => <FormattedMessage id={d.status_name} defaultMessage={d.status_name} />
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block text-center',
                Header: <FormattedMessage id="claimed_by_dash_id" />,
                id: 'claimed_by_dash_id',
                accessor: d => {
                    let content = null;
                    if (d.claimed_by_dash_id) {
                        let stateUpdate = () => null;
                        if (d.claimed_by_dash_is_accessible) {
                            stateUpdate = () => this.setState({ dashModalOpen: true, viewingDash: d.claimed_by_dash_id });
                        } else {
                            stateUpdate = () => this.setState({ externalCompanyName: d.claimed_by_dash_company, inaccessibleDashModalOpen: true });
                        }
                        content = (
                            <a 
                                href='#'
                                onClick={e => { e.stopPropagation(); e.preventDefault(); stateUpdate(); }}
                            >
                                {d.claimed_by_dash_id}
                            </a>
                        )
                    }
                    return content;
                }
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block text-center',
                Header: <FormattedMessage id="common.alteredOn" />,
                id: 'modified_on',
                accessor: d => moment(d.modified_on || d.created_on).format('DD-MM-YYYY')
            },
            {
                isDefault: false,
                headerClassName: 'd-block',
                className: 'd-block text-center',
                Header: <FormattedMessage id="common.createdOn" />,
                id: 'created_on',
                accessor: d => moment(d.created_on).format('DD-MM-YYYY')
            },
        ];
        if (showMatchStrength) {
            columns.push(
                {
                    isDefault: true,
                    headerClassName: 'd-block',
                    className: 'd-block',
                    Header: <FormattedMessage id="common.matchStrength" />,
                    id: 'match_strength',
                    accessor: d => translateMatchStrength(d.match_strength)
                },
            )
        }
        if (includeLastVisited) {
            columns.push(
                {
                    isDefault: true,
                    headerClassName: 'd-block',
                    className: 'd-block',
                    Header: <FormattedMessage id="lastViewed" />,
                    id: 'last_visualized',
                    accessor: d => formatDateFriendly(d.last_visualized ? new Date(d.last_visualized) : null, locale)
                },
            )
            columns.push(
                {
                    isDefault: true,
                    headerClassName: 'd-block',
                    className: 'd-block',
                    Header: <FormattedMessage id="viewCount" />,
                    id: 'visualized_count',
                    accessor: d => d.visualized_count ? d.visualized_count : '0'
                },
            )
        }

        if (showMenu) {
            const menuColumn = {
                isDefault: true,
                headerClassName: 'd-sm-block col-2 d-none',
                className: 'd-xs-block text-center col-2',
                sortable: false,
                resizable: false,
                width: 34,
                id: 'actions',
                accessor: d => (
                    <div className="actions-wrapper">
                        <Popover
                            content={
                                <Menu>
                                    <MenuItem
                                        icon="eye-open"
                                        text={<FormattedMessage id="openVisualization" />}
                                        disabled={!isPro}
                                        onClick={() => {
                                            const visualizationData = btoa(JSON.stringify({ entities: [{ entityType: 'dot', entityId: d.id }] }));
                                            history.push(`/visualize/${visualizationData}` + window.location.search)
                                        }}
                                    />
                                    <MenuItem
                                        icon="barcode"
                                        text={<FormattedMessage id="generateCode" />}
                                        popoverProps={{ openOnTargetFocus: false }}
                                    >
                                        <MenuItem
                                            text={<FormattedMessage id="qrCode" />}
                                            onClick={() => {
                                                const visualizationData = btoa(JSON.stringify({ entities: [{ entityType: 'dot', entityId: d.id }] }));
                                                const url = window.location.protocol + '//' + window.location.host + `/visualize/${visualizationData}`
                                                QRCode.toDataURL(url)
                                                    .then(dataUrl => {
                                                        var image = new Image();
                                                        image.src = dataUrl;
                                                
                                                        var w = window.open("");
                                                        w.document.write(image.outerHTML);
                                                        w.document.close();
                                                    })
                                                    .catch(err => {
                                                        console.error(err)
                                                    })
                                            }}
                                        />
                                        <MenuItem
                                            text={<FormattedMessage id="barcode" />}
                                            onClick={() => {
                                                document.body.style.cursor='wait';
                                                const visualizationData = { entities: [{ entity_type: 'dot', entity_id: d.id }] };
                                                createEntityGraphLink(
                                                    visualizationData, 
                                                    (visLink) => {
                                                        const url = `${window.location.protocol}//${window.location.host}/l/${visLink.name}`
                                                        JsBarcode('#barcode', url, {
                                                            format: "CODE128"
                                                        });
                                                        const canvas = document.getElementById("barcode");
                                                        const dataUrl = canvas.toDataURL("png");
                                                        var image = new Image();
                                                        image.src = dataUrl;
                                                
                                                        var w = window.open("");
                                                        w.document.write(image.outerHTML);
                                                        w.document.close();
                                                        document.body.style.cursor='default';
                                                    },
                                                    () => {
                                                        document.body.style.cursor='default';
                                                    }
                                                )
                                            }}
                                        />
                                        <canvas id='barcode' hidden={true}  />
                                    </MenuItem>
                                </Menu>
                            }
                        >
                            <Button minimal={true} small={true} icon={<Icon iconSize={12} icon="menu" />} />
                        </Popover>
                    </div>
                )
            };
            columns = [menuColumn, ...columns];
        }

        columns = columns
            .concat(
                ...tags.map(i => ({
                    headerClassName: 'd-sm-block d-none',
                    className: 'd-sm-block d-none',
                    Header: i.title,
                    id: `field_${i.id}`,
                    accessor: d => <FieldValueRenderer row={d.cargo_type} field={i} />
                }))
            )
            .filter(column => !hiddenColumns.find(hc => hc === column.id));
        return columns;
    };

    render() {
        const { lots = [], total = 0, prependColumns = [], appendColumns = [], persistState = true, indexFields, dotIndexFields, useDotTags = false, externalModels = [], useRootState = true, lotsTableColumns, lockModal } = this.props;
        let { columnVisibility } = this.state;
        const tags = (useDotTags ? dotIndexFields : indexFields) || [];
        if (!columnVisibility) return null;
        if (useRootState && lotsTableColumns) {
            columnVisibility = lotsTableColumns;
        }

        const bodyColumns = this.getColumns();
        const columns = prependColumns.concat(bodyColumns).concat(appendColumns);
        let columnsToShow = columns;
        let columnPicker = undefined;
        if (!!columnVisibility) {
            columnsToShow = prependColumns.concat(bodyColumns.filter(c => !!c && !!c.id && columnVisibility[c.id] === true)).concat(appendColumns);
            columnPicker = onPicked => (
                <div className="row">
                    <div className="col">
                        <div className="float-right">
                            <ColumnPicker
                                columns={columns}
                                selectedColumns={columnsToShow.map(c => c.id)}
                                models={this.state.selectedModels}
                                onChange={c => {
                                    this.setColumns(c);
                                    onPicked(c);
                                }}
                            />
                        </div>
                    </div>
                </div>
            );
        }

        const cargoType = this.state.cargoType;
        if (lockModal && cargoType) {
            cargoType.status_id = 3;
        }

        return (
            <React.Fragment>
                <Table
                    columns={columnsToShow}
                    columnPicker={columnPicker}
                    data={lots}
                    total={total}
                    queryBuilder={setFilters => 
                        <QueryBuilder 
                            fields={tags}
                            onModelsSet={models => this.setState({ selectedModels: models })}
                            modelsPlaceholder={<FormattedMessage id='dotTemplates'/>}
                            onFiltersSet={(filters) => {
                                if (this.props.onFiltersSet) {
                                    if (!filters.cargo_categories) {
                                        filters.cargo_categories = this.state.cargoCategories
                                    }
                                    this.props.onFiltersSet(filters)
                                }
                                setFilters(filters);
                            }}
                            stateSetter={persistState ? this.props.setLotsTableQueryState : undefined}
                            valuesSetter={persistState ? this.props.setLotsTableQueryValues : undefined}
                            initialState={persistState ? this.props.lotsTableQueryState : undefined}
                            initialValues={persistState ? this.props.lotsTableQueryValues : undefined}
                            externalModels={externalModels}
                        />
                    }
                    dataLoader={(options, onComplete) => {
                        this.loadData(options, onComplete)
                        this.setState({ options: options });
                    }}
                    optionsSetter={persistState ? this.props.setLotsTableOptions : undefined}
                    initialOptions={useRootState ? this.props.lotsTableOptions : undefined}
                    defaultSort={this.props.defaultSort}
                    defaultSortDesc={this.props.defaultSortDesc}
                />
                <CargoTypeModal
                    modalOpen={this.state.cargoTypeModalOpen}
                    setModalOpen={(open) => this.setState({ cargoTypeModalOpen: open })}
                    cargoType={cargoType}
                    dot={this.state.dot}
                    modalTitle={this.state.modalTitle}
                    readOnly={true}
                />
                <DashViewModal
                    key={this.state.viewingDash ? this.state.viewingDash : 'unassigned-dot-modal'}
                    modalOpen={this.state.dashModalOpen}
                    setModalOpen={(open) => this.setState({ dashModalOpen: open })}
                    dashId={this.state.viewingDash ? this.state.viewingDash : null}
                    modalTitle={this.state.viewingDash ? this.state.viewingDash : ''}
                    showLink={true}
                    showPastVersions={true}
                />
                
                <ConfirmModal
                    id='inaccessible-dash-modal'
                    open={this.state.inaccessibleDashModalOpen}
                    setOpen={(o) => this.setState({ inaccessibleDashModalOpen: o })}
                    onConfirm={() => null}
                    hideConfirm={true}
                    title={<FormattedMessage id='inaccessibleExternalDashTitle' />}
                    body={<FormattedHTMLMessage id='inaccessibleExternalDash' tagName='div' values={{ companyName: this.state.externalCompanyName }} />}
                    cancelLabel={<FormattedMessage id='common.close' />}
                />
            </React.Fragment>
        );
        
    }
}

LotsTable.defaultProps = {
    lots: [],
    total: 0
};

const mapStateToProps = state => ({
    lots: state.lots.list.filter(l => l.status_name !== 'draft'),
    total: state.lots.total,
    statuses: state.lots.statuses,
    indexFields: state.indices.list ? state.indices.list.filter(i => i.status_id === 2) : [],
    dotIndexFields: state.indices.dotList ? state.indices.dotList.filter(i => i.status_id === 2) : [],
    models: state.models.list,
    lotsTableOptions: state.sessionSettings.lotsTableOptions,
    lotsTableQueryState: state.sessionSettings.lotsTableQueryState,
    lotsTableQueryValues: state.sessionSettings.lotsTableQueryValues,
    lotsTableColumns: state.sessionSettings.lotsTableColumns,
    locale: state.localization.locale,
    company: state.identity.company,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            listLots,
            setLotsTableOptions,
            setLotsTableQueryState,
            setLotsTableQueryValues,
            getIndices,
            getModels,
            setLotsTableColumns,
            setGraphEntity,
            createEntityGraphLink,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LotsTable);
