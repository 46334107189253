import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import Page from 'components/page';
import { getEntityGroup, updateEntityGroup } from 'reducers/entityGroup';
import LoadingOverlay from 'react-loading-overlay';
import CollectionTemplateSelector from 'pages/collections/components/collectionTemplateSelector';
import { Notifications } from 'components/notifications';
import { createTransaction } from 'reducers/transaction';
import { setDocumentSelection, listDocumentSelections } from 'reducers/document';
import TemplateCard from 'pages/collections/components/templateCard';
import ConfirmModal from 'components/modals/confirm'
import { history } from 'configuration/history';
import EdiText from 'react-editext'

const DotTemplateModelTypeName = 'cargo category';


function CollectionContainer({
    match,
    parents,
    getEntityGroup,
    updateEntityGroup,
}) {
    const [collection, setCollection] = useState();
    const [loading, setLoading] = useState(true);
    const [templateSelectorOpen, setTemplateSelectorOpen] = useState(false);
    const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
    const [collectionTemplates, setCollectionTemplates] = useState({ 
        selectedDashes: [], 
        selectedDots: [], 
        selectedDocuments: [],
    });
    
    const [prevCollectionTemplates, setPrevCollectionTemplates] = useState({ 
        selectedDashes: [], 
        selectedDots: [], 
        selectedDocuments: [],
    });

    useEffect(() => {
        const collectionId = match.params.id;
        getEntityGroup({ id: collectionId, includeCount: true }, result => {
            setCollection(result);
            const dotTemplates = result.templates.filter(t => t.model_type_name === DotTemplateModelTypeName);
            const dashTemplates = result.templates.filter(t => t.model_type_name !== DotTemplateModelTypeName);
            const documentTemplates = result.document_templates;
            setCollectionTemplates({
                selectedDashes: dashTemplates, 
                selectedDots: dotTemplates, 
                selectedDocuments: documentTemplates,
            })
            setLoading(false);
        })
    }, [match, getEntityGroup]);

    // const noTemplatesYet = templates.length === 0 && document_templates.length === 0;
    const { selectedDashes, selectedDots, selectedDocuments } = collectionTemplates;
    const noTemplatesYet = selectedDashes.length === 0 && selectedDots.length === 0 && selectedDocuments.length === 0;
    const renderActions = () => {
        return (
            <div className="btn-group pd-t-10">
                <button 
                    className="btn btn-outline-info" 
                    onClick={() => { 
                        setTemplateSelectorOpen(true);
                    }}
                >
                    <FormattedMessage id="setTemplates" />
                </button>
                <button 
                    className="btn btn-outline-info" 
                    onClick={() => { 
                        setConfirmDeleteModalOpen(true);
                    }}
                >
                    <FormattedMessage id="deleteCollection" />
                </button>
            </div>
        );
    };
    
    const saveTitle = (title) =>{
            
        collection.label=title;

        updateEntityGroup({
            ...collection,
            entities: [
                ...collectionTemplates.selectedDashes.map(d => ({ entity_type: 'dash-template', entity_id: d.id })),
                ...collectionTemplates.selectedDots.map(d => ({ entity_type: 'dot-template', entity_id: d.id })),
                ...collectionTemplates.selectedDocuments.map(d => ({ entity_type: 'document-template', entity_id: d.id })),
            ],
        }, result => {
            Notifications.success(<FormattedMessage id='updateCollectionSuccess' />);
            getEntityGroup({ id: result.id, includeCount: true }, result => {
                setCollection(result);
                setLoading(false);
            })
        });
    };
    
    return (
        <Page
            {...CollectionContainer.meta}
            title={loading ? <FormattedMessage id='common.loading'/> : collection.label}
            subtitle=
                {
                    loading ? ' ' :
                    (
                        <>
                            <br></br>
                            <div className="row pd-b-5">
                                <div className='col-12'>
                                    <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                                        <FormattedMessage id='common.title' />
                                    </h6>
                                </div>
                            </div>
                            <div className="row pd-b-15">
                                <div className='col-xs-3 col-lg-4 col-xl-5'>
                                {
                                    <EdiText
                                        type='text'
                                        value={ loading? '': collection.label}
                                        onSave={val => saveTitle(val)}
                                        showButtonsOnHover={false} />                             
                                }
                                </div>
                            </div>
                        </>
                    )
                }
            parents={parents}
            actions={renderActions()}
        >
            {
                loading ? 
                <LoadingOverlay
                    active={loading}
                    spinner
                    text={<FormattedMessage id='common.loading'/>}
                >
                    <div style={{ height: '400px'}} />
                </LoadingOverlay>
                :
                (
                    noTemplatesYet ?
                        <span className='tx-bold tx-inverse'>
                            <FormattedMessage id='noTemplatesYet' />
                        </span>
                        :
                        <>
                        <div>
                            <FormattedMessage id='objectTemplates' />
                            {
                                selectedDots.length === 0 ?
                                    <div className='tx-inverse tx-bold pd-10'>
                                        <FormattedMessage id='noDotsInCollection' />
                                    </div>
                                    :
                                    <div className='collections-templates-container'>
                                        { selectedDots.map(d => 
                                            {
                                                d.collectionTitle=collection.label;
                                                d.collectionId= match.params.id;
                                                return <TemplateCard {...d} key={d.hash_id} />
                                            }
                                        )}

                                    </div>
                            }
                        </div>
                        <div>
                            <FormattedMessage id='eventTemplates' />
                            {
                                selectedDashes.length === 0 ?
                                    <div className='tx-inverse tx-bold pd-10'>
                                        <FormattedMessage id='noDashesInCollection' />
                                    </div>
                                    :
                                    <div className='collections-templates-container'>
                                        { selectedDashes.map(d =>
                                            {                                             
                                                d.collectionTitle=collection.label;
                                                d.collectionId= match.params.id;
                                                return <TemplateCard {...d} key={d.hash_id} />
                                            }
                                        )}
                                    </div>
                            }
                        </div>
                        <div>
                            <FormattedMessage id='page.models.invoices.templates' />
                            {
                                selectedDocuments.length === 0 ?
                                    <div className='tx-inverse tx-bold pd-10'>
                                        <FormattedMessage id='noDocumentsInCollection' />
                                    </div>
                                    :
                                    <div className='collections-templates-container'>
                                        { selectedDocuments.map(d => <TemplateCard {...d} key={d.hash_id} />) }
                                    </div>
                            }
                        </div>
                        </>
                )
            }
            <CollectionTemplateSelector 
                isOpen={templateSelectorOpen} 
                setOpen={setTemplateSelectorOpen} 
                onConfirm={val => {
                    setPrevCollectionTemplates(collectionTemplates)
                    setCollectionTemplates(val);
                    let tagIds = [];
                    val.selectedDashes.forEach(dashTemplate => {
                        const templateTagIds = dashTemplate.model_field_list
                            .filter(field => !!field.index_field_id)
                            .map(field => field.index_field_id);
                        tagIds = tagIds.concat(templateTagIds);
                    });
                    val.selectedDots.forEach(dotTemplate => {
                        const templateTagIds = dotTemplate.model_field_list
                            .filter(field => !!field.index_field_id)
                            .map(field => field.index_field_id);
                        tagIds = tagIds.concat(templateTagIds);
                    });
                    updateEntityGroup({
                        ...collection,
                        entities: [
                            ...val.selectedDashes.map(d => ({ entity_type: 'dash-template', entity_id: d.id })),
                            ...val.selectedDots.map(d => ({ entity_type: 'dot-template', entity_id: d.id })),
                            ...val.selectedDocuments.map(d => ({ entity_type: 'document-template', entity_id: d.id })),
                        ],
                    }, result => {
                        Notifications.success(<FormattedMessage id='updateCollectionSuccess' />);
                        // refresh to fetch latest templates
                        getEntityGroup({ id: result.id, includeCount: true }, result => {
                            setCollection(result);
                            const dotTemplates = result.templates.filter(t => t.model_type_name === DotTemplateModelTypeName);
                            const dashTemplates = result.templates.filter(t => t.model_type_name !== DotTemplateModelTypeName);
                            const documentTemplates = result.document_templates;
                            setCollectionTemplates({
                                selectedDashes: dashTemplates, 
                                selectedDots: dotTemplates, 
                                selectedDocuments: documentTemplates,
                            })
                            setLoading(false);
                        })
                    })
                }} 
                currentSelectedDashes={collectionTemplates.selectedDashes}
                currentSelectedDots={collectionTemplates.selectedDots}
                currentSelectedDocuments={collectionTemplates.selectedDocuments}
            />
            <ConfirmModal
                id='confirm-delete-modal'
                open={confirmDeleteModalOpen}
                setOpen={(o) => setConfirmDeleteModalOpen(o)}
                onConfirm={() => {
                    updateEntityGroup({
                        ...collection,
                        entities: [],
                        status: 3,
                    }, result => {
                        Notifications.success(<FormattedMessage id='deleteCollectionSuccess' />);
                        history.push('/collections' + window.location.search)
                    })
                }}
                title={<FormattedMessage id='deleteCollection' />}
                body={<FormattedMessage id='confirmDeleteCollection' />}
            />
        </Page>
    );
}

CollectionContainer.meta = {
    title: <FormattedMessage id="collections" defaultMessage="Collections" />,
    routes: ['/collections/:id'],
    icon: 'ion-ios-folder-outline',
    menus: { main: true, user: false },
    order: 11,
    onlySubscribed: true
};

const mapStateToProps = state => ({
    user: state.identity.user,
    company: state.identity.company,
    permissions: state.identity.permissions
});
const mapDispatchToProps = dispatch => bindActionCreators({
    getEntityGroup,
    updateEntityGroup,
    createTransaction,
    setDocumentSelection,
    listDocumentSelections,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(CollectionContainer));
