import React from 'react';
import { FormattedMessage } from 'react-intl';
import { call, hasErrors } from '../configuration/api';
import { Notifications } from '../components/notifications';
import { FIELD_INDICES_GET_TYPES, FIELD_INDICES_GET_STATUSES, FIELD_INDICES_GET_LIST, FIELD_INDICES_GET_DASH_LIST, FIELD_INDICES_GET_DOT_LIST } from './types';
import Queries from './queries';

const defaultState = {
    statuses: undefined,
    types: undefined,
    list: undefined,
    dotList: undefined,
    dashList: undefined,
    total: 0,
    dotTotal: 0,
    dashTotal: 0,
};

/**
 * Loading state mapper.
 * @param {object} state
 * @param {string} action
 */
export default function(state = defaultState, action) {
    switch (action.type) {
        case FIELD_INDICES_GET_TYPES:
            return { ...state, types: action.payload };

        case FIELD_INDICES_GET_STATUSES:
            return { ...state, statuses: action.payload };

        case FIELD_INDICES_GET_LIST:
            {
                const { total, data } = action.payload;
                return { ...state, list: data, total: Number.parseInt(total) };
            }

        case FIELD_INDICES_GET_DOT_LIST:
            {
                const { total, data } = action.payload;
                return { ...state, dotList: data, dotTotal: Number.parseInt(total) };
            }

        case FIELD_INDICES_GET_DASH_LIST:
            {
                const { total, data } = action.payload;
                return { ...state, dashList: data, dashTotal: Number.parseInt(total) };
            }

        default:
            return state;
    }
}

export const getIndexTypes = (onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.FieldIndices.Types });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.index_field_type_list) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    dispatch({ type: FIELD_INDICES_GET_TYPES, payload: data.index_field_type_list });
    onComplete(data.index_field_type_list);
};

export const getIndexStatuses = (onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.FieldIndices.Statuses });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.index_field_status_list) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    dispatch({ type: FIELD_INDICES_GET_STATUSES, payload: data.index_field_status_list });
    onComplete(data.index_field_status_list);
};

export const getIndices = (variables, onComplete = () => null, storeAsType = null) => async dispatch => {
    const response = await call({ query: Queries.FieldIndices.List, variables });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.index_field_list) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }
    if (storeAsType === null) {
        dispatch({ type: FIELD_INDICES_GET_LIST, payload: data.index_field_list });
    } else if (storeAsType === 'dot') {
        dispatch({ type: FIELD_INDICES_GET_DOT_LIST, payload: data.index_field_list });
    } else if (storeAsType === 'dash') {
        dispatch({ type: FIELD_INDICES_GET_DASH_LIST, payload: data.index_field_list });
    }
    onComplete(data.index_field_list);
};

export const createIndex = (variables, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.FieldIndices.Create, variables });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.index_field_create) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.index_field_create);
};

export const updateIndex = (variables, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.FieldIndices.Update, variables });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.index_field_update) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.index_field_update);
};

export const deleteIndex = (id, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.FieldIndices.Delete, variables: { field_id: id } });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.index_field_delete) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.index_field_delete);
};
