import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Field, ErrorMessage } from 'formik';
import Select, { components } from 'react-select';

import './fields.scss';
import Tooltip from 'rc-tooltip';

class SelectField extends React.Component {
    render() {
        const {
            intl: { formatMessage },
            multi,
            options,
            name,
            value,
            placeholder,
            submitOnChange,
            disabled = false,
            extraOnChange,
            isClearable = false,
            showIsRequired,
            hideDropDown,
        } = this.props;
        const customStyles = {
            control: base => ({
                ...base,
                boxShadow: 'none'
            }),
            multiValueLabel: (provided, state) => ({
                ...provided,
                maxWidth: '10em'
            }),
            menu: (provided, state) => ({
                ...provided,
                zIndex: '20'
            }),
            menuList: (provided, state) => ({
                ...provided,
                maxHeight: '200px'
            }),
            groupHeading: (provided, state) => ({
                ...provided,
                color: 'black',
                fontWeight: 'bold',
                fontSize: '95%'
            }),
        };
        return (            
            <React.Fragment>                
                <Field
                    {...this.props}
                    render={({ form }) => {                        
                        const showLabel = multi ? (!!value && value.length > 0) : !!value;
                        let fieldValue = null;
                        if (multi) {
                            fieldValue = options.filter(o => {
                                const valueArray = Array.isArray(value) ? value : [];
                                return valueArray.includes(o.value);
                            })
                        } else {
                            if (Array.isArray(value)) {
                                fieldValue = options.find(o => value.includes(o.value))                                                                
                            } else {                                                                
                                fieldValue = !!value ? options.find(o => o.value === value) : undefined;
                                
                                try {
                                    // try for select options with Groups
                                    if(fieldValue === undefined) {
                                        fieldValue = options.map(m => m.options).flat().filter(f => f.value === value);                                                                                
                                    }
                                }
                                catch {
                                    fieldValue = undefined;                                    
                                }
                            }
                        }
                        let fieldValueLength = 0;
                        if (fieldValue && fieldValue.label) {
                            if (typeof fieldValue.label === 'string') {
                                fieldValueLength = fieldValue.label.length;                                
                            } else if (fieldValue.label.props && fieldValue.label.props.id) {
                                fieldValueLength = fieldValue.label.props.id.length;                                
                            }
                        }
                        const singleValueEl = props => (!fieldValue || (fieldValue && fieldValue.label && fieldValueLength > 5)) ?
                            <Tooltip trigger={['hover']} placement='left' overlay={<span>{fieldValue ? fieldValue.label : formatMessage({ id: `noValue` })}</span>} mouseEnterDelay={1.5}>
                                <div>
                                    <components.SingleValue {...props} className={'se-select-value-' + name} />
                                </div>
                            </Tooltip>
                            :
                            <components.SingleValue {...props} className={'se-select-value-' + name} />

                        // const wrapper = el => (!multi && ) ? 
                        //     (
                        //     )
                        //     :
                        //     el
                       
                        return (
                            <React.Fragment>
                                <div style={{ position: 'relative' }}>
                                    <Select
                                        placeholder={placeholder || formatMessage({ id: `${name}.placeholder` })}
                                        name={name}
                                        options={options}
                                        isDisabled={disabled}
                                        onChange={async selectedValue => {                                            
                                            if (!!disabled) return;
                                            if (extraOnChange) {
                                                extraOnChange(form, selectedValue);
                                            }
                                            const value = !!selectedValue
                                                ? Array.isArray(selectedValue)
                                                    ? selectedValue.map(s => s.value)
                                                    : selectedValue.value
                                                : '';
                                            await form.setFieldValue(name, value);
                                            if (!!submitOnChange) {
                                                form.submitForm();
                                            }
                                        }}
                                        isSearchable={!hideDropDown}
                                        onBlur={form.handleBlur}
                                        value={fieldValue}
                                        isMulti={multi}
                                        isClearable={isClearable}
                                        inputId={'se-select-input-' + name}
                                        components={{ 
                                            IndicatorSeparator: () => null, 
                                            DropdownIndicator: props =>  hideDropDown ? null : <components.DropdownIndicator {...props} className={'se-select-dropdown-' + name} />,
                                            ClearIndicator: props =>  <components.ClearIndicator {...props} className={'se-select-clear-' + name} />,
                                            SingleValue: singleValueEl,
                                            MultiValue: props => <components.MultiValue {...props} className={'se-select-value-' + name} />,
                                            Menu: props => hideDropDown ? null : <components.Menu {...props} className={'se-select-menu-' + name} />,
                                            Option: props => <components.Option {...props} className={'se-select-menu-option-' + name} />
                                        }}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#eeeeee',
                                                primary: '#00336B'
                                            }
                                        })}
                                        styles={customStyles}
                                        menuPlacement={this.props.menuPlacement || 'auto'}
                                        noOptionsMessage={() => <FormattedMessage id='noOptions' />}
                                    />
                                    {!!showLabel && 
                                        <Tooltip trigger={['hover']} overlay={<span>{placeholder || formatMessage({ id: `${name}.placeholder` })}</span>} mouseEnterDelay={1.5}>
                                            <div className="select-input-placeholder">{placeholder || formatMessage({ id: `${name}.placeholder` })}</div>
                                        </Tooltip>
                                    }
                                </div>
                                {showIsRequired && !showLabel && <div className="validation-message select-input-placeholder" style={{marginLeft: '15px'}}><FormattedMessage id="validation.required" /></div>}
                            </React.Fragment>
                        );
                    }}
                />
                { this.props.showError && <ErrorMessage name={name} component="div" className="validation-message" /> }
            </React.Fragment>
        );
    }
}

export default injectIntl(SelectField);
