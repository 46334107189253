import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray, ErrorMessage } from 'formik';
import { injectIntl } from 'react-intl';
import onClickOutside from 'react-onclickoutside';
import { Fields } from '.';
import SimpleBar from 'simplebar-react';

import './fields.scss';

class ChecklistField extends React.Component {
    constructor(props) {
        super(props);
        this.state = { visible: false };
    }

    handleClickOutside = e => {
        this.setState({ ...this.state, visible: false });
    };

    setVisibility = visible => {
        this.setState({ ...this.state, visible });
    };

    render() {
        const { visible } = this.state;
        const {
            name,
            options,
            intl: { formatMessage },
            placeholder,
            disabled = false
        } = this.props;
        return (
            <div className={`check-list ${visible ? 'visible' : ''}`}>
                <span className="ellipsis access-button no-select" onClick={() => this.setVisibility(!visible)}>
                    {placeholder || formatMessage({ id: `${name}.placeholder` })}
                </span>

                {visible && (
                    <FieldArray
                        name={name}
                        render={({ form }) => {
                            return (
                                <div className="check-list-items">
                                    <SimpleBar>
                                        {options.map(({ value, label }, index) => (
                                            <Fields.Checkbox
                                                disabled={disabled}
                                                key={index}
                                                name={`${name}.${value}`}
                                                checked={form.values && form.values[name] && form.values[name][value] === true}
                                                placeholder={label}
                                            />
                                        ))}
                                    </SimpleBar>
                                </div>
                            );
                        }}
                    />
                )}
                <ErrorMessage name={name} component="div" className="validation-message" />
            </div>
        );
    }
}

ChecklistField.defaultProps = {
    name: undefined,
    options: []
};
ChecklistField.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.array
};

ChecklistField = onClickOutside(ChecklistField);
export default injectIntl(ChecklistField);
