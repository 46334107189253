export const TRANSACTIONS_GET_INVOICES = `
    query($transaction_ids: [Id!], $offset: Int, $limit: Int, $order_by: [OrderBy]) {
        invoice_list(input: { transaction_ids: $transaction_ids }, paging: { order_by: $order_by, offset: $offset, limit: $limit }) {
            total
            data {
                id
                filename
                transaction_id
                url
            }
        }
    }
`;
