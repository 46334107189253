import { validateToken } from './common';

export const DataKeys = {
    TOKEN: `t`,
    EXPIRES_AT: 'e',
    COMPANY_ID: 'c',
    USER_ID: 'u',
    SIGN_IN_USER_ID: 'su',
    LOCALE: 'l'
};

const isStorageAvailable = (storage = localStorage) => {
    const test = 'test';
    try {
        storage.setItem(test, test);
        storage.removeItem(test);
        return true;
    } catch (e) {
        return false;
    }
};

export class DataStorage {
    static isLocalStorageAvailable = isStorageAvailable(localStorage);
    static isSessionStorageAvailable = isStorageAvailable(sessionStorage);

    static session = {
        clear: () => {
            if (!DataStorage.isSessionStorageAvailable) return;
            sessionStorage.clear();
        },
        setItem: (key, value) => {
            if (!DataStorage.isSessionStorageAvailable) return;
            sessionStorage.setItem(key, value);
        },
        removeItem: key => {
            if (!DataStorage.isSessionStorageAvailable) return;
            sessionStorage.removeItem(key);
        },
        getItem: key => {
            if (!DataStorage.isSessionStorageAvailable) return;
            return sessionStorage.getItem(key);
        }
    };

    static local = {
        clear: () => {
            if (!DataStorage.isLocalStorageAvailable) return;
            localStorage.clear();
        },
        setItem: (key, value) => {
            if (!DataStorage.isLocalStorageAvailable) return;
            localStorage.setItem(key, value);
        },
        removeItem: key => {
            if (!DataStorage.isLocalStorageAvailable) return;
            localStorage.removeItem(key);
        },
        getItem: key => {
            if (!DataStorage.isLocalStorageAvailable) return;
            return localStorage.getItem(key);
        }
    };

    static clearAll() {
        const locale = DataStorage.getLocale();
        DataStorage.local.clear();
        DataStorage.session.clear();
        DataStorage.setLocale(locale);
    }

    static readToken(store) {
        let tokenString = store.getItem(DataKeys.TOKEN);
        if (!tokenString) {
            const locale = DataStorage.getLocale();
            store.clear();
            if (locale) {
                DataStorage.setLocale(locale);
            }

            return undefined;
        }

        const token = JSON.parse(tokenString);
        const valid = validateToken(token);
        if (!valid) {
            const locale = DataStorage.getLocale();
            store.clear();
            if (locale) {
                DataStorage.setLocale(locale);
            }

            return undefined;
        }

        return token;
    }

    /**
     * Retrieves token from session and local storage.
     * 1. If current session is impersonation token will be present in session storage
     * 2. otherwise it logic will fallback to getting the token from local storage
     */
    static getToken() {
        const sessionToken = DataStorage.readToken(DataStorage.session);
        const localToken = DataStorage.readToken(DataStorage.local);
        return sessionToken || localToken;
    }

    /**
     * Sets token to local or session storage, for:
     * 1. originally logged in user its stored in local storage
     * 2. any impersonated users its stored in session storage
     *
     * @param {object} tokenData authenticate api response.
     */
    static setToken(tokenData) {
        if (!validateToken(tokenData)) return false;

        const { signin_user_id, user_id } = tokenData.data;
        const storage = signin_user_id === user_id ? DataStorage.local : DataStorage.session;
        storage.setItem(DataKeys.TOKEN, JSON.stringify(tokenData));

        return true;
    }

    static getLocale() {
        return DataStorage.local.getItem(DataKeys.LOCALE);
    }

    static setLocale(locale) {
        return DataStorage.local.setItem(DataKeys.LOCALE, locale);
    }
}
