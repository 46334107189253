import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import LoadingOverlay from 'react-loading-overlay';
import EdiText from 'react-editext'
import IconSelectorModal from '../components/iconSelector';
import LibraryIcon, { ValidIconNames } from 'components/libraryIcons'
import LibraryTemplateSelector from '../components/libraryTemplateSelector';
import { IconPickerItem } from 'react-fa-icon-picker'
import LibraryCarousel from '../components/libraryCarousel';
import TextSelectorModal from '../components/libraryTextModal';
import ConfirmModal from 'components/modals/confirm'
import SelectControl from 'components/fields/uncontrolledComponents/selectControl';
import { getEntityGroup, setSelectedLibrary } from 'reducers/entityGroup';
import { Notifications } from 'components/notifications';
import { history } from 'configuration/history.js';
import Page from 'components/page';

import './createDashboard.scss'


function LibraryFetchWrapper(props) {
    const { selectedLibrary, parents, match, children, getEntityGroup, setSelectedLibrary } = props;
    let selectedLibraryJson;
    if (!!selectedLibrary) {
        selectedLibraryJson = JSON.stringify(selectedLibrary);
    }

    useEffect(() => {
        if (!selectedLibraryJson) {
            getEntityGroup({ id: match.params.id }, result => {                
                (result.document_templates||[]).map(dt => {
                    if( dt.document_type === 'word' ){
                        let file_data_temp= dt.file_data;
                        dt.file_data= JSON.parse(Buffer.from(JSON.stringify(file_data_temp),'ascii').toString('utf-8'));
                    }
                })
                setSelectedLibrary(result)}                
            );
        }
    }, [getEntityGroup, match.params.id, selectedLibraryJson, setSelectedLibrary]);

    if (!selectedLibrary) {
        return (
            <div className="br-pagebody pd-20">
                <div className="br-section-wrapper pd-30">
                    <LoadingOverlay
                        active={true}
                        spinner
                        text={<FormattedMessage id='common.loading'/>}
                    >
                        <div className="widget-2" style={{height: '20vw'}}>
                            <div className="row">
                                <div className="col-12 pd-0">
                                    <p>&nbsp;</p>
                                </div>
                            </div>
                        </div>
                    </LoadingOverlay>
                </div>
            </div>
        );
    }

    const childrenWithProps = React.Children.map(children, child => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { selectedLibrary, parents });
        }
        return child;
    })

    return <>{childrenWithProps}</>
}

const mapStateToProps = state => ({
    selectedLibrary: state.entityGroup.selectedLibrary,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getEntityGroup,
            setSelectedLibrary,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LibraryFetchWrapper)
