import { AUTHENTICATE } from './identity/authenticate';
import { SIGN_IN } from './identity/signIn';
import { PROFILE } from './identity/profile';
import { IMPERSONATE } from './identity/impersonate';
import { PASSWORD_CHANGE_REQUEST } from './identity/requestResetPassword';
import { IDENTITY_RESET_PASSWORD } from './identity/resetPassword';
import { IDENTITY_CHANGE_PASSWORD } from './identity/changePassword';
import { IDENTITY_INVITE_SET_PASSWORD } from './identity/inviteSetPassword';
import { IDENTITY_UPLOAD_AVATAR } from './identity/uploadAvatar';
import { IDENTITY_CHANGE_OWN_PASSWORD } from './identity/changeOwnPassword';
import { OTP_AUTH } from './identity/otpAuthenticate';
import { OTP_RESEND } from './identity/otpResend';
import { OTP_REQUEST_DISABLE } from './identity/otpRequestDisable';
import { OTP_DISABLE_CONFIRM } from './identity/otpDisableConfirm';
import { OTP_ENABLE } from './identity/otpEnable';

import { USERS_LIST } from './users/list';
import { USERS_INVITE } from './users/invite';
import { USERS_LIST_ROLES } from './users/listRoles';
import { USERS_CREATE_ROLE } from './users/createRole';
import { USERS_UPDATE_ROLE } from './users/updateRole';
import { USERS_UPDATE } from './users/update';
import { USERS_LIST_PERMISSIONS } from './users/listPermissions';
import { USERS_SET_ROLE_PERMISSIONS } from './users/setRolePermissions';
import { USERS_GET_ROLE_ASSIGNED_PERMISSIONS } from './users/getRolePermissions';
import { USERS_DELETE } from './users/delete';
import { USERS_REENABLE } from './users/reenable';

import { COMPANIES_LIST } from './companies/list';
import { COMPANIES_GET } from './companies/get';
import { COMPANIES_CREATE } from './companies/create';
import { COMPANIES_UPDATE } from './companies/update';
import { COMPANIES_DELETE } from './companies/delete';
import { COMPANIES_GET_STATUSES } from './companies/statuses';
import { COMPANIES_CREATE_INVITE_LINK } from './companies/createInvite';
import { COMPANIES_STORE_INVITE_DATA } from './companies/storeInviteData';
import { COMPANIES_GET_INVITE_LINKS } from './companies/listInviteLinks';
import { COMPANIES_GET_INVITE_RESPONSES } from './companies/getInviteResponses';
import { COMPANIES_APPROVE_INVITE } from './companies/approveCompanyInvite';
import { COMPANIES_REJECT_INVITE } from './companies/rejectCompanyInvite';
import { COMPANIES_UPDATE_INVITE_DETAILS } from './companies/updateInviteDetails';
import { COMPANIES_GET_INVOICES } from './companies/getInvoices';
import { COMPANIES_SET_BILLING_EMAIL } from './companies/setBillingEmail';

import { BLACKLIST_LIST } from './blacklist/list';
import { BLACKLIST_DELETE } from './blacklist/delete';

import { MODELS_LIST } from './models/list';
import { MODELS_LIST_LIGHT } from './models/listLight';
import { MODELS_GET } from './models/get';
import { MODELS_UPDATE } from './models/update';
import { MODELS_CREATE } from './models/create';
import { MODELS_DELETE } from './models/delete';
import { MODELS_GET_STATUSES } from './models/statuses';
import { MODELS_IMPORT } from './models/import';
import { MODELS_EXPORT } from './models/export';
import { MODELS_CLONE } from './models/clone';
import { MODEL_SET_USERS } from './models/setUsers';

import { FIELDS_TYPES } from './fields/types';
import { FIELDS_LIST } from './fields/list';
import { FIELDS_CREATE } from './fields/create';
import { FIELDS_BATCH_UPDATE } from './fields/batch';
import { FIELDS_DELETE } from './fields/delete';
import { FIELD_GET } from './fields/get';
import { FIELDS_SET_MAPPING } from './fields/setMapping'
import { FIELDS_CLEAR_MAPPING } from './fields/clearMapping'
import { FIELDS_LIST_MAPPINGS } from './fields/listMappings'

import { FIELD_VALUE_CREATE } from './fieldValues/create';
import { FIELD_VALUE_DELETE } from './fieldValues/delete';

import { INDICES_TYPES } from './indices/types';
import { INDICES_LIST } from './indices/list';
import { INDICES_CREATE } from './indices/create';
import { INDICES_UPDATE } from './indices/update';
import { INDICES_DELETE } from './indices/delete';
import { INDICES_STATUSES } from './indices/statuses';

import { FIELD_VISIBILITY_OPERATORS } from './fieldVisibility/operators';
import { FIELD_VISIBILITY_INDEX_VALUES } from './fieldVisibility/indexValues';
import { FIELD_VISIBILITY_FIELD_VALUES } from './fieldVisibility/fieldValues';

import { DOCUMENT_TEMPLATE_GET } from './documentTemplates/get';
import { DOCUMENT_TEMPLATE_LIST } from './documentTemplates/list';
import { DOCUMENT_TEMPLATE_CREATE } from './documentTemplates/create';
import { DOCUMENT_TEMPLATE_UPDATE } from './documentTemplates/update';
import { DOCUMENT_TEMPLATE_DELETE } from './documentTemplates/delete';
import { DOCUMENT_TEMPLATE_CLONE } from './documentTemplates/clone';
import { DOCUMENT_TEMPLATE_GET_REF_DATA } from './documentTemplates/getReferenceData';
import { DOCUMENT_TEMPLATE_CREATE_FIELD_REF_GROUP } from './documentTemplates/createFieldRefGroup';
import { DOCUMENT_TEMPLATE_ADD_FIELD_REF_TO_GROUP } from './documentTemplates/addFieldRefToGroup';
import { DOCUMENT_TEMPLATE_REMOVE_FIELD_REF_FROM_GROUP } from './documentTemplates/removeFieldRefFromGroup';
import { DOCUMENT_TEMPLATE_DELETE_FIELD_REF_GROUP } from './documentTemplates/deleteFieldRefGroup';
import { DOCUMENT_TEMPLATE_SET_GROUP_REFS } from './documentTemplates/setGroupFieldRefs';
import { DOCUMENT_TEMPLATE_GET_CHECKSUM } from './documentTemplates/getChecksum';



import { TRANSACTIONS_LIST } from './transactions/list';
import { TRANSACTIONS_GET } from './transactions/get';
import { TRANSACTIONS_CREATE } from './transactions/create';
import { TRANSACTIONS_UPDATE } from './transactions/update';
import { TRANSACTIONS_SEARCH } from './transactions/search';
import { TRANSACTIONS_SEARCH_VISIBILITY } from './transactions/searchWithVisibilityData';
import { TRANSACTIONS_STATUSES } from './transactions/statuses';
import { TRANSACTIONS_DELETE } from './transactions/delete';
import { TRANSACTIONS_CREATE_INVOICE } from './transactions/createInvoice';
import { TRANSACTIONS_GET_INVOICES } from './transactions/getInvoices';
import { TRANSACTION_FIELD_SET_LOTS } from './transactions/fieldSetLots';
import { TRANSACTION_FIELD_REVISE } from './transactions/fieldRevise';
import { TRANSACTIONS_GET_RELATED_DATA } from './transactions/getRelatedData';
import { TRANSACTIONS_GET_DELETE_SIDE_EFFECTS } from './transactions/getDeleteSideEffects';
import { TRANSACTIONS_CLONE } from './transactions/clone';
import { TRANSACTIONS_GET_SIDE_EFFECTS_DETAILS } from './transactions/getSideEffectsDetails';



import { CREATE_DOCUMENT } from './documents/createDocument';
import { GET_DOCUMENTS } from './documents/getDocuments';
import { DELETE_DOCUMENT } from './documents/deleteDocument';
import { DOCUMENTS_CREATE_SAMPLE } from './documents/createSample';
import { CREATE_DOCUMENT_SELECTION } from './documents/createDocumentSelection'
import { LIST_DOCUMENT_SELECTION } from './documents/listDocumentSelections'

import { LINKS_GET_TYPES } from './links/types';
import { LINKS_CLICK } from './links/click';
import { LINKS_USE } from './links/use';
import { LINKS_DELETE } from './links/delete';

import { MEDIA_UPLOAD } from './media/upload';
import { MEDIA_FIND } from './media/find';
import { MEDIA_GET } from './media/get';
import { MEDIA_DELETE } from './media/delete';

import { PAYMENT_LIST_PRODUCTS } from './payment/listProducts';
import { PAYMENT_LIST_PLANS } from './payment/listPlans';
import { PAYMENT_SET_SOURCE } from './payment/setPaymentSource';
import { PAYMENT_SUBSCRIBE } from './payment/subscribe';
import { PAYMENT_CANCEL_SUBSCRIPTION } from './payment/cancelSubscription';
import { PREVIEW_PAYMENT } from './payment/previewPayment';
import { RETRY_PAYMENT } from './payment/retryPayment';
import { LIST_SOURCES } from './payment/listSources';

import { LOTS_LIST } from './lots/list';
import { LOTS_LIST_LIGHT } from './lots/listLight';
import { LOTS_LIST_MATCHES } from './lots/listMatches';

import { CARGO_UNITS_LIST } from './cargoUnits/list';
import { CARGO_UNITS_CREATE } from './cargoUnits/create';

import { CONDITIONS_CREATE } from './alerts/conditionCreate';
import { CONDITIONS_DELETE } from './alerts/conditionDelete';
import { CONDITIONS_GET } from './alerts/conditionGet';
import { CONDITIONS_LIST } from './alerts/conditionList';
import { CONDITIONS_UPDATE } from './alerts/conditionUpdate';
import { CONDITIONS_SET_REFERENCE } from './alerts/conditionSetReference';
import { ALERTS_CREATE } from './alerts/create';
import { ALERTS_DELETE } from './alerts/delete';
import { ALERTS_GET } from './alerts/get';
import { ALERTS_LIST } from './alerts/list';
import { ALERTS_UPDATE } from './alerts/update';
import { ALERTS_MODIFY_SUBSCRIBER } from './alerts/modifySubscriber';
import { ALERTS_UNSUBSCRIBE } from './alerts/unsubscribe';

import { CREATE_SHARED_ENTITY } from './sharing/createSharedEntity';
import { RESPOND_TO_SHARE_REQUEST } from './sharing/inviteRespond';
import { GET_SHARED_ENTITIES } from './sharing/getSharedEntities';
import { DELETE_SHARED_ENTITY } from './sharing/deleteSharedEntity';
import { FORFEIT_SHARED_ENTITY } from './sharing/forfeitSharedEntity';

import { REQUEST_ENTITY_LOCK } from './entityLocks/requestEntityLock';
import { RELEASE_ENTITY_LOCK } from './entityLocks/releaseEntityLock';

import { ERROR_REPORT_CREATE } from './errorReport/create';

import { GET_ENTITY_GRAPH } from './entityGraph/getEntityGraph';
import { VISIT_ENTITY_GRAPH } from './entityGraph/visitEntityGraph';
import { ENTITY_GRAPH_CREATE_LINK } from './entityGraph/createEntityGraphLink';

import { FAQ_SEARCH } from './faq/search';

import { ENTITY_GROUP_CREATE } from './entityGroup/createEntityGroup';
import { ENTITY_GROUP_LIST } from './entityGroup/listEntityGroups';
import { GET_ENTITY_GROUP } from './entityGroup/getEntityGroup';
import { GET_ENTITY_GROUP_WITH_COUNT } from './entityGroup/getEntityGroupWithInstanceCount';
import { ENTITY_GROUP_UPDATE } from './entityGroup/updateEntityGroup';
import { ENTITY_GROUP_IMPORT } from './entityGroup/importEntityGroup';
import { ENTITY_GROUP_CLONE } from './entityGroup/cloneEntityGroup';
import { GET_ENTITY_GROUP_ISSUES } from './entityGroup/getEntityGroupIssues';
import { ENTITY_GROUP_DELETE } from './entityGroup/deleteEntityGroup';



const Queries = {
    Identity: {
        Login: SIGN_IN,
        Authenticate: AUTHENTICATE,
        Profile: PROFILE,
        Impersonate: IMPERSONATE,
        RequestPasswordChange: PASSWORD_CHANGE_REQUEST,
        ResetPassword: IDENTITY_RESET_PASSWORD,
        ChangePassword: IDENTITY_CHANGE_PASSWORD,
        InviteSetPassword: IDENTITY_INVITE_SET_PASSWORD,
        UploadAvatar: IDENTITY_UPLOAD_AVATAR,
        ChangeOwnPassword: IDENTITY_CHANGE_OWN_PASSWORD,
        OtpAuthenticate: OTP_AUTH,
        OtpResend: OTP_RESEND,
        OtpRequestDisable: OTP_REQUEST_DISABLE,
        OtpDisableConfirm: OTP_DISABLE_CONFIRM,
        OtpEnable: OTP_ENABLE,
    },
    Users: {
        List: USERS_LIST,
        Invite: USERS_INVITE,
        GetRoles: USERS_LIST_ROLES,
        CreateRole: USERS_CREATE_ROLE,
        UpdateRole: USERS_UPDATE_ROLE,
        Update: USERS_UPDATE,
        GetPermissions: USERS_LIST_PERMISSIONS,
        SetRolePermissions: USERS_SET_ROLE_PERMISSIONS,
        GetRoleAssignedPermissions: USERS_GET_ROLE_ASSIGNED_PERMISSIONS,
        Delete: USERS_DELETE,
        Reenable: USERS_REENABLE
    },
    Companies: {
        List: COMPANIES_LIST,
        Get: COMPANIES_GET,
        Create: COMPANIES_CREATE,
        Update: COMPANIES_UPDATE,
        Delete: COMPANIES_DELETE,
        GetStatuses: COMPANIES_GET_STATUSES,
        CreateInvite: COMPANIES_CREATE_INVITE_LINK,
        StoreInviteData: COMPANIES_STORE_INVITE_DATA,
        GetInviteLinks: COMPANIES_GET_INVITE_LINKS,
        GetInviteResponses: COMPANIES_GET_INVITE_RESPONSES,
        ApproveInvite: COMPANIES_APPROVE_INVITE,
        RejectInvite: COMPANIES_REJECT_INVITE,
        UpdateInvite: COMPANIES_UPDATE_INVITE_DETAILS,
        GetInvoices: COMPANIES_GET_INVOICES,
        SetBillingEmail: COMPANIES_SET_BILLING_EMAIL
    },
    Blacklist: {
        List: BLACKLIST_LIST,
        Delete: BLACKLIST_DELETE
    },
    Models: {
        List: MODELS_LIST,
        ListLight: MODELS_LIST_LIGHT,
        Get: MODELS_GET,
        Update: MODELS_UPDATE,
        Create: MODELS_CREATE,
        Delete: MODELS_DELETE,
        GetStatuses: MODELS_GET_STATUSES,
        Import: MODELS_IMPORT,
        Export: MODELS_EXPORT,
        Clone: MODELS_CLONE,
        SetUsers: MODEL_SET_USERS,
    },
    Fields: {
        Types: FIELDS_TYPES,
        List: FIELDS_LIST,
        Create: FIELDS_CREATE,
        UpdateAll: FIELDS_BATCH_UPDATE,
        Delete: FIELDS_DELETE,
        Get: FIELD_GET,
        SetMapping: FIELDS_SET_MAPPING,
        ClearMapping: FIELDS_CLEAR_MAPPING,
        ListMappings: FIELDS_LIST_MAPPINGS,
    },
    FieldValues: {
        Create: FIELD_VALUE_CREATE,
        Delete: FIELD_VALUE_DELETE
    },
    FieldIndices: {
        Types: INDICES_TYPES,
        List: INDICES_LIST,
        Create: INDICES_CREATE,
        Update: INDICES_UPDATE,
        Delete: INDICES_DELETE,
        Statuses: INDICES_STATUSES
    },
    FieldVisibility: {
        Operators: FIELD_VISIBILITY_OPERATORS,
        IndexValues: FIELD_VISIBILITY_INDEX_VALUES,
        FieldValues: FIELD_VISIBILITY_FIELD_VALUES
    },
    Documents: {
        Get: DOCUMENT_TEMPLATE_GET,
        List: DOCUMENT_TEMPLATE_LIST,
        Create: DOCUMENT_TEMPLATE_CREATE,
        CreateSample: DOCUMENTS_CREATE_SAMPLE,
        Update: DOCUMENT_TEMPLATE_UPDATE,
        Delete: DOCUMENT_TEMPLATE_DELETE,
        Clone: DOCUMENT_TEMPLATE_CLONE,
        CreateSelection: CREATE_DOCUMENT_SELECTION,
        ListSelections: LIST_DOCUMENT_SELECTION,
        GetRefData: DOCUMENT_TEMPLATE_GET_REF_DATA,
        CreateFieldRefGroup: DOCUMENT_TEMPLATE_CREATE_FIELD_REF_GROUP,
        AddFieldRefToGroup: DOCUMENT_TEMPLATE_ADD_FIELD_REF_TO_GROUP,
        RemoveFieldRefFromGroup: DOCUMENT_TEMPLATE_REMOVE_FIELD_REF_FROM_GROUP,
        DeleteFieldRefGroup: DOCUMENT_TEMPLATE_DELETE_FIELD_REF_GROUP,
        SetFieldGroupReferences: DOCUMENT_TEMPLATE_SET_GROUP_REFS,
        CheckSum: DOCUMENT_TEMPLATE_GET_CHECKSUM, 
    },
    Transactions: {
        List: TRANSACTIONS_LIST,
        Get: TRANSACTIONS_GET,
        Create: TRANSACTIONS_CREATE,
        Update: TRANSACTIONS_UPDATE,
        Search: TRANSACTIONS_SEARCH,
        SearchWithVisibility: TRANSACTIONS_SEARCH_VISIBILITY,
        Statuses: TRANSACTIONS_STATUSES,
        Delete: TRANSACTIONS_DELETE,
        CreateInvoice: TRANSACTIONS_CREATE_INVOICE,
        GetInvoices: TRANSACTIONS_GET_INVOICES,
        CreateDocument: CREATE_DOCUMENT,
        GetDocuments: GET_DOCUMENTS,
        DeleteDocument: DELETE_DOCUMENT,
        SetFieldLots: TRANSACTION_FIELD_SET_LOTS,
        FieldRevise: TRANSACTION_FIELD_REVISE,
        GetRelatedData: TRANSACTIONS_GET_RELATED_DATA,
        GetDeleteSideEffects: TRANSACTIONS_GET_DELETE_SIDE_EFFECTS,
        Clone: TRANSACTIONS_CLONE,
        GetSideEffectsDetails: TRANSACTIONS_GET_SIDE_EFFECTS_DETAILS,
    },
    Links: {
        Types: LINKS_GET_TYPES,
        Click: LINKS_CLICK,
        Use: LINKS_USE,
        Delete: LINKS_DELETE
    },
    Media: {
        Upload: MEDIA_UPLOAD,
        Find: MEDIA_FIND,
        Get: MEDIA_GET,
        Delete: MEDIA_DELETE
    },
    Payment: {
        ListProducts: PAYMENT_LIST_PRODUCTS,
        ListPlans: PAYMENT_LIST_PLANS,
        SetSource: PAYMENT_SET_SOURCE,
        Subscribe: PAYMENT_SUBSCRIBE,
        CancelSubscription: PAYMENT_CANCEL_SUBSCRIPTION,
        PreviewPayment: PREVIEW_PAYMENT,
        RetryPayment: RETRY_PAYMENT,
        ListSources: LIST_SOURCES,
    },
    Lots: {
        ListMatches: LOTS_LIST_MATCHES,
        List: LOTS_LIST,
        ListLight: LOTS_LIST_LIGHT
    },
    CargoUnits: {
        List: CARGO_UNITS_LIST,
        Create: CARGO_UNITS_CREATE
    },
    Alerts: {
        CreateCondition: CONDITIONS_CREATE,
        DeleteCondition: CONDITIONS_DELETE,
        GetCondition: CONDITIONS_GET,
        ListCondition: CONDITIONS_LIST,
        UpdateCondition: CONDITIONS_UPDATE,
        Create: ALERTS_CREATE,
        Delete: ALERTS_DELETE,
        Get: ALERTS_GET,
        List: ALERTS_LIST,
        Update: ALERTS_UPDATE,
        ModifySubscriber: ALERTS_MODIFY_SUBSCRIBER,
        Unsubscribe: ALERTS_UNSUBSCRIBE,
        SetReference: CONDITIONS_SET_REFERENCE
    },
    Sharing: {
        CreateSharedEntity: CREATE_SHARED_ENTITY,
        RespondToShareInvite: RESPOND_TO_SHARE_REQUEST,
        ListSharedEntities: GET_SHARED_ENTITIES,
        DeleteSharedEntity: DELETE_SHARED_ENTITY,
        ForfeitSharedEntity: FORFEIT_SHARED_ENTITY,
    },
    EntityLocks: {
        RequestEntityLock: REQUEST_ENTITY_LOCK,
        ReleaseEntityLock: RELEASE_ENTITY_LOCK,
    },
    ErrorReport: {
        Create: ERROR_REPORT_CREATE,
    },
    EntityGraph: {
        GetEntityGraph: GET_ENTITY_GRAPH,
        VisitEntityGraph: VISIT_ENTITY_GRAPH,
        CreateLink: ENTITY_GRAPH_CREATE_LINK,
    },
    Faq: {
        Search: FAQ_SEARCH,
    },
    EntityGroup: {
        Create: ENTITY_GROUP_CREATE,
        List: ENTITY_GROUP_LIST,
        Get: GET_ENTITY_GROUP,
        GetWithCount: GET_ENTITY_GROUP_WITH_COUNT,
        Update: ENTITY_GROUP_UPDATE,
        Import: ENTITY_GROUP_IMPORT,
        Clone: ENTITY_GROUP_CLONE,
        GetIssues: GET_ENTITY_GROUP_ISSUES,
        Delete: ENTITY_GROUP_DELETE,
    },
};

export default Queries;
