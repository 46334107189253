export const USERS_LIST_PERMISSIONS = `
    query($include_admin_level: Boolean, $order_by: [OrderBy], $offset: Int, $limit: Int) {
        permission_list(input: { include_admin_level: $include_admin_level }, paging: { order_by: $order_by, offset: $offset, limit: $limit }) {
            id
            is_admin_level
            code
            title
        }
    }
`;
