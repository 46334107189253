export const GET_ENTITY_GRAPH = `

    type EntityData {
        entity_type	: String!
        entity_hash_id	: Id
        entity_id      : Long
    }

    query M (
        $entities: [EntityData!]
    )  
    {
        entity_graph(
            input: {
                entities: $entities
            }
        )
        {
            nodes {
                type
                id
                graph_id
                to
                from
                is_accessible
                owning_company_name
                dash {
                    id
                    status_id
                    status_name
                    model_id
                    company_id
                    user_id
                    created_on
                    modified_on
                    title
                    description
                    extra_info
                    
                    transaction_type
                    transaction_type_name

                    model {
                        id
                        title
                        company_id
                        description
                        contains_inaccessible_dots
                        
                        model_field_list {
                            id
                            model_id
                            order
                            type_id
                            type_name
                            status_id
                            status_name
                            index_field_id
                            input_id
                            visibility_check
                            title
                            description
                            is_required
                            validation
                            width
                            force_break
                            extra_info
                            company_id: model_company_id
                            cargoCategory: cargo_category
                            cargo_type
                            cargo_reference_type
                            is_static_unit
                            default_unit

                            map_dash_field
                            map_dot_field
                            map_special_dot_field
                            map_compound_id
            
                            model_field_value_list {
                                id
                                company_id
                                field_id
                                order_by
                                is_hidden
                                value
                            }
            
                            index_field {
                                id
                                order_by
                                type_id
                                type_name
                                status_id
                                status_name
                                title
                                description
                                extra_info
                            }
                        }
                    }

                    transaction_field_list {
                        id
                        transaction_id
                        field_id
                        value
                        company_id: model_company_id
                        order_by: field_order_by
                        lot_id
                        mapped_lot_id
                        quantity
                        cargo_unit_id
                        in_revision
                        can_unwind
                        use_mapped_value
                        restore_value
                        created_dot_all_consumed
                        revised_remainder_quantity
                        input_lots {
                            lot_id
                            quantity
                        }
                        allow_user_transform
                        use_user_transform
                    }

                    past_versions {
                        id
                        status_id
                        status_name
                        model_id
                        company_id
                        user_id
                        created_on
                        modified_on
                        title
                        description
                        extra_info
                        
                        transaction_type
                        transaction_type_name

                        model {
                            id
                            title
                            company_id
                            description
                            
                            model_field_list {
                                id
                                model_id
                                order
                                type_id
                                type_name
                                status_id
                                status_name
                                index_field_id
                                input_id
                                visibility_check
                                title
                                description
                                is_required
                                validation
                                width
                                force_break
                                extra_info
                                company_id: model_company_id
                                cargoCategory: cargo_category
                                cargo_type
                                cargo_reference_type
                                is_static_unit
                                default_unit

                                map_dash_field
                                map_dot_field
                                map_special_dot_field
                                map_compound_id
                
                                model_field_value_list {
                                    id
                                    company_id
                                    field_id
                                    order_by
                                    is_hidden
                                    value
                                }
                
                                index_field {
                                    id
                                    order_by
                                    type_id
                                    type_name
                                    status_id
                                    status_name
                                    title
                                    description
                                    extra_info
                                }
                            }
                        }

                        transaction_field_list {
                            id
                            transaction_id
                            field_id
                            value
                            company_id: model_company_id
                            order_by: field_order_by
                            lot_id
                            mapped_lot_id
                            quantity
                            cargo_unit_id
                            in_revision
                            can_unwind
                            use_mapped_value
                            restore_value
                            created_dot_all_consumed
                            revised_remainder_quantity
                            input_lots {
                                lot_id
                                quantity
                            }
                            allow_user_transform
                            use_user_transform
                        }
                    }
                }
                dot {
                    id
                    hash_id
                    created_on
                    modified_on
                    quantity
                    original_quantity
                    status_name
                    match_strength
                    claimed_by_dash_id
                    created_by_dash_id
                    created_by_dash_company
                    created_by_dash_is_accessible
                    claimed_by_dash_company
                    claimed_by_dash_is_accessible
                    cargo_unit_id
                    cargo_unit {
                        name
                        display_name
                        description
                    }
                    cargo_unit_name
                    cargo_type_hash_id
                    cargo_type_category_title
                    cargo_type {
                        id
                        status_id
                        status_name
                        model_id
                        company_id
                        user_id
                        created_on
                        source_cargo_id
                        result_cargo_id
                        title
                        description
                        notes
                        bc_transaction_id
                        extra_info
    
                        index_fields_data
                        
                        transaction_type
                        transaction_type_name
    
                        model {
                            id
                            title
                            company_id
                            description
    
                            model_field_list {
                                id
                                model_id
                                order
                                type_id
                                type_name
                                status_id
                                status_name
                                index_field_id
                                input_id
                                visibility_check
                                title
                                description
                                is_required
                                validation
                                width
                                force_break
                                extra_info
                                company_id: model_company_id
                                cargo_type
                                cargo_reference_type
                            }
                        }
    
                        transaction_field_list {
                            id
                            transaction_id
                            field_id
                            value
                            company_id: model_company_id
                            order_by: field_order_by
                            lot_id
                        }
                    }
                    past_versions {
                        id
                        hash_id
                        created_on
                        modified_on
                        quantity
                        original_quantity
                        status_name
                        match_strength
                        claimed_by_dash_id
                        created_by_dash_id
                        created_by_dash_company
                        created_by_dash_is_accessible
                        claimed_by_dash_company
                        claimed_by_dash_is_accessible
                        cargo_unit_id
                        cargo_unit {
                            name
                            display_name
                            description
                        }
                        cargo_unit_name
                        cargo_type_hash_id
                        cargo_type_category_title
                        cargo_type {
                            id
                            status_id
                            status_name
                            model_id
                            company_id
                            user_id
                            created_on
                            source_cargo_id
                            result_cargo_id
                            title
                            description
                            notes
                            bc_transaction_id
                            extra_info
        
                            index_fields_data
                            
                            transaction_type
                            transaction_type_name
        
                            model {
                                id
                                title
                                company_id
                                description
        
                                model_field_list {
                                    id
                                    model_id
                                    order
                                    type_id
                                    type_name
                                    status_id
                                    status_name
                                    index_field_id
                                    input_id
                                    visibility_check
                                    title
                                    description
                                    is_required
                                    validation
                                    width
                                    force_break
                                    extra_info
                                    company_id: model_company_id
                                    cargo_type
                                    cargo_reference_type
                                }
                            }
        
                            transaction_field_list {
                                id
                                transaction_id
                                field_id
                                value
                                company_id: model_company_id
                                order_by: field_order_by
                                lot_id
                            }
                        }
                    }
                }
                dash_field {
                    id
                    model_field {
                        cargo_reference_type
                    }
                }
            }
        }
    }
`;