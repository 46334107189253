import React from 'react';
import { injectIntl } from 'react-intl';
import Slider from 'rc-slider';

import 'rc-slider/assets/index.css';
import 'components/fields/fields.scss';

class SliderControl extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            isChanging: false,
        };
        this.setChangingStatus = this.setChangingStatus.bind(this);
    }

    setChangingStatus = changing => this.setState({ ...this.state, isChanging: changing });

    render() {
        const { isChanging } = this.state;
        const {
            intl: { formatMessage },
            name,
            min,
            max,
            step,
            marks,
            onChange,
            valueDisplay,
            disabled = false,
            value,
        } = this.props;

        const renderValue =
            valueDisplay ||
            (value => {
                return value;
            });

        return (
            <div className={`slider-${isChanging ? 'active' : 'inactive'}`}>
                <label className="d-flex justify-content-between align-items-center">
                    <span>{formatMessage({ id: `${name}.placeholder` })}</span>
                    <span>{renderValue(value)}</span>
                </label>
                <Slider
                    disabled={disabled}
                    min={min || 0}
                    max={max || 100}
                    value={value}
                    onChange={async v => {
                        this.setChangingStatus(true);
                        if (onChange) {
                            onChange(v)
                        }
                    }}
                    onAfterChange={() => {
                        this.setChangingStatus(false);
                    }}
                    step={step}
                    marks={marks}
                />
            </div>
        );
    }
}

export default injectIntl(SliderControl);
