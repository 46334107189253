export const USERS_LIST_ROLES = `
    query($company_id: Int, $search: String, $order_by: [OrderBy], $offset: Int, $limit: Int) {
        role_list(input: { company_id: $company_id, search: $search }, paging: { order_by: $order_by, offset: $offset, limit: $limit }) {
            id
            company_id
            title
            is_builtin
            is_restricted

            granted_permissions: permission_list {
                id
                is_admin_level
                code
                title
            }

            aux {
                name
                value
            }
        }
    }
`;
