import React from 'react';
import { FormattedMessage } from 'react-intl';
import { call, hasErrors } from '../configuration/api';
import Queries from './queries';
import { Notifications } from '../components/notifications';
import { LINKS_GET_TYPES } from './types';

const defaultState = {
    types: undefined
};

/**
 * Links state mapper.
 * @param {object} state
 * @param {string} action
 */
export default function(state = defaultState, action) {
    switch (action.type) {
        case LINKS_GET_TYPES:
            return { ...state, types: action.payload };

        default:
            return state;
    }
}

export const getLinkTypes = (onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Links.Types });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.link_types) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    dispatch({ type: LINKS_GET_TYPES, payload: data.link_types });
    onComplete(data.link_types);
};

export const loadLink = (link_name, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Links.Click, variables: { link_name } });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.link_click) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.link_click);
};

export const useLink = (link_name, link_token, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Links.Use, variables: { link_name, link_token } });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.link_use) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.link_use);
};

export const deleteLink = (link_id, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Links.Delete, variables: { link_id } });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.link_delete) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.link_delete);
};
