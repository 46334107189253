/**
 * Authenticate with token.
 */
export const IMPERSONATE = `
    mutation(
        $user_id: Long # When set to null means a reset to user's original identity
    ) {
        user_switch(user_id: $user_id) {
            token {
                token
                data {
                    expires
                    expires_in
                    user_id
                    company_id
                    signin_user_id
                    signin_company_id
                    requires_mfa
                    mfa_passed
                }
            }

            user {
                id
                status_id
                status_name
                created_on
                email
                last_login_on
                login_attempts
                reset_password
                name
                avatar_id
            }
        }
    }
`;
