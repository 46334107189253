import React from 'react';
import { getFieldIcon, FieldTypes } from 'models/field';
import ReactModal from 'react-modal';
import { FormattedMessage } from 'react-intl';
import Tooltip from 'rc-tooltip';

import 'pages/models/models.scss';

class FieldReferenceListItem extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            confirmModalOpen: false,
        }
    }
    getListItem = () => {
        const { item } = this.props;
        if (!item) return null;

        const { literal, label, type, token, field, index, completed, selected, onSelect = () => null } = item;
        let fieldClass = ''
        let fieldClassPrefix = 'field-ref-list-item';
        if (selected) {
            fieldClass = 'selected-field-ref';
        } else if (completed) {
            fieldClass = 'disabled';
            fieldClassPrefix += '-disabled'
        }
        if (type === 'index' || type === 'model') {
            const fieldIcon = completed ? 
                <button className='btn btn-icon' onClick={() => this.setState({ confirmModalOpen: true })}>
                    <i className="material-icons-outlined" style={{opacity: 1, color: 'black'}}>delete_forever</i>
                </button>
                :
                <i className="material-icons-outlined">{getFieldIcon(field.type_name)}</i>
            const fieldTypeName = field.type_name.toLowerCase();
            return (
                <div
                    className={`${fieldClassPrefix} no-select ${fieldClass} field-row-${fieldTypeName === 'cargo' ? 'text' : fieldTypeName} f-index-${index}`}
                    style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "clip"}}
                    onClick={() => !completed && onSelect(item)}
                >
                    { fieldIcon }
                    <span>{(label && label.length > 0) ? label : '[Untitled]'} {completed && '(Completed)'}</span>
                </div>
            );
        } else if (type === 'lot') {
            const lotIcon = completed ? 
                <button className='btn btn-icon' onClick={() => this.setState({ confirmModalOpen: true })}>
                    <i className="material-icons-outlined" style={{opacity: 1}}>delete_forever</i>
                </button>
                :
                <i className="material-icons-outlined">shopping_cart</i>
            return (
                <div
                    className={`${fieldClassPrefix} no-select ${fieldClass} field-row-text f-index-${index}`}
                    style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "clip"}}
                    onClick={() => !completed && onSelect(item)}
                >
                    { lotIcon }
                    <span>{(label && label.length > 0) ? label : '[Untitled]'} {completed && '(Completed)'}</span>
                </div>
            );
        } else {
            return <div />
        }

    }
    render() {
        return <React.Fragment>
            <Tooltip placement={'right'} trigger={['hover']} overlay={this.props.item ? this.props.item.label : null} mouseEnterDelay={2}>
                { this.getListItem() }
            </Tooltip>
            <ReactModal
                    isOpen={this.state.confirmModalOpen}
                    onRequestClose={() => this.setState({ confirmModalOpen: false })}
                    className="modal-block dialog"
                    overlayClassName="modal-overlay gray"
                >
                    <div className="modal-dialog modal-lg wd-450" role="document">
                        <div className="modal-content bd-0">
                            <div className="modal-header pd-x-20">
                                <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                                    <FormattedMessage id="common.confirmClear" />
                                </h6>
                            </div>
                            <div className="modal-body pd-20">
                                <FormattedMessage id="common.confirmClearFieldReference" />
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button
                                    type="button"
                                    className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                    onClick={() => {
                                        if (this.props.item.onFieldCleared) {
                                            this.props.item.onFieldCleared(this.props.item);
                                        }
                                        this.setState({ confirmModalOpen: false })
                                    }}
                                >
                                    <FormattedMessage id="common.yes" />
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                    onClick={() => this.setState({ confirmModalOpen: false })}
                                >
                                    <FormattedMessage id="common.cancel"  />
                                </button>
                            </div>
                        </div>
                    </div>
                </ReactModal>
        </React.Fragment>
    }
}

export default FieldReferenceListItem;
