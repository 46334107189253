import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Popover, Menu, MenuItem, MenuDivider } from '@blueprintjs/core';

class FieldsReferencesMenu extends React.Component {
    render() {
        const { fields = [], onFieldSelect, manualClassName = null, sendFullField = false } = this.props;

        return (
            <React.Fragment>
                <Popover
                    position="bottom-left"
                    content={
                        <Menu>
                            <div tabIndex={0} />
                            <MenuDivider title={<FormattedMessage id="page.models.document.configuration.properties.chooseFields" />} />
                            {!!fields && fields.length > 0 ? (
                                fields.map(
                                    (t, i) =>
                                        t.type_id === 2 ?
                                        <MenuDivider key={i} title={t.title} />
                                        :
                                        <MenuItem
                                            key={i}
                                            text={t.title + '  {{' + t.id + '}}'}
                                            onClick={() => {
                                                onFieldSelect(sendFullField ? t : t.id);
                                            }}
                                        />
                                )
                            ) : (
                                <MenuItem disabled={true} text={<FormattedMessage id="page.models.document.configuration.properties.noFieldsAvailable" />} />
                            )}
                        </Menu>
                    }
                >
                    <span className={manualClassName || 'RichEditor-styleButton RichEditor-activeButton'}>
                        <FormattedMessage id="common.addMacro" />
                    </span>
                </Popover>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    company: state.identity.company,
    model: state.fields.model,
    fields: state.fields.items,
    fieldTypes: state.fields.types,
    template: state.document.instance
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FieldsReferencesMenu);
