import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Map } from 'immutable';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Fields } from '../../../../components/fields';
import { Notifications } from '../../../../components/notifications';
import { createUserRole, getUserRoles, assignPermissionsToRole, updateUserRole } from '../../../../reducers/users';

class CreateRole extends React.Component {
    handleSubmit = ({ role_id, title, permissionList }) => {
        const { createUserRole, updateUserRole, assignPermissionsToRole, onCancel, getUserRoles, companyId } = this.props;
        const permission_ids = new Map(permissionList)
            .keySeq()
            .toArray()
            .map(k => Number.parseInt(k));

        if (!!role_id) {
            updateUserRole({ role_id, title, company_id: companyId }, () => {
                assignPermissionsToRole({ role_id, permission_ids }, () => {
                    Notifications.success(<FormattedMessage id="page.companies.roles.role.updated" />);
                });
                getUserRoles({ company_id: companyId, status_ids: [1, 2, 3, 4, 5, 6], order_by: ['id'] });
            });
        } else {
            createUserRole({ title, company_id: companyId }, role => {
                assignPermissionsToRole({ role_id: role.id, permission_ids }, () => {
                    Notifications.success(<FormattedMessage id="page.companies.roles.role.created" />);
                });
                getUserRoles({ company_id: companyId, status_ids: [1, 2, 3, 4, 5, 6], order_by: ['id'] });
            });
        }

        onCancel();
    };

    toPermissionValues = ({ granted_permissions = [] }) => {
        const map = (granted_permissions || []).map(g => g.id);
        return map.reduce((k, v) => k.set(v, true), new Map()).toJS();
    };

    render() {
        const { onCancel, permissions, role } = this.props;
        const roleValues = {
            ...role,
            permissionList: !!role ? this.toPermissionValues(role) : {},
            permissions: undefined,
            role_id: !!role ? role.id : undefined
        };
        if (!permissions) return null;

        return (
            <div className="modal-dialog wd-350-force" role="document">
                <div className="modal-content bd-0">
                    <div className="modal-header pd-x-20">
                        <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                            <FormattedMessage id="page.companies.roles.setup.role" />
                        </h6>
                    </div>
                    <Formik
                        initialValues={{ title: '', permissionList: {}, ...roleValues }}
                        validationSchema={Yup.object().shape({
                            title: Yup.string().required(<FormattedMessage id="validation.required" />)
                        })}
                        onSubmit={this.handleSubmit}
                        render={({ values }) => (
                            <Form>
                                <React.Fragment>
                                    <div className="modal-body pd-5 pd-t-15">
                                        <div className="form-group col-12">
                                            <Fields.Input name="title" />
                                        </div>
                                        <div className="col-12 mg-b-5">
                                            <FormattedMessage id="page.companies.roles.assign.permissions" />
                                        </div>
                                        <div className="card mg-x-15 mg-b-15">
                                            <div className="card-body pd-x-0 pd-b-0">
                                                {permissions.map((p, i) => (
                                                    <div key={i} className="form-group col-12">
                                                        <Fields.Checkbox
                                                            name={`permissionList.${p.id}`}
                                                            checked={values.permissionList[p.id] === true}
                                                            placeholder={`${p.title} - ${p.id}`}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer justify-content-center">
                                        <button type="submit" className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium">
                                            <FormattedMessage id="common.submit" />
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                            onClick={onCancel}
                                        >
                                            <FormattedMessage id="common.cancel" />
                                        </button>
                                    </div>
                                </React.Fragment>
                            </Form>
                        )}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            createUserRole,
            updateUserRole,
            getUserRoles,
            assignPermissionsToRole
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateRole);
