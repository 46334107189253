import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Switch, Route } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import CompanyDetails from './containers/details';
import CompanyRoles from './containers/roles';
import CompanyUsers from './containers/users';
import CompanyBillingOverdue from './containers/billingOverdue';
import CompanyBilling from './containers/billing';
import CompanyPaymentPlans from './containers/paymentPlans';
import CompanyInvoices from './containers/invoices';
import CompanyPaymentInfo from './containers/paymentInfo';
import { getProfile } from '../../reducers/identity';
import { DefinedPermissions } from '../../components/routes/permissions/permissionResolver';

import './settings.scss';

class Settings extends React.Component {
    componentDidMount() {
        const { company, getProfile } = this.props;
        if (!company) getProfile();
    }

    render() {
        const { company } = this.props;
        if (!company) return null;

        const { is_paid, subscription_status } = company;

        return (
            <Switch>
                {!!is_paid && <Route exact path="/settings/users" component={props => <CompanyUsers {...props} parents={[Settings.meta]} />} />}
                {!!is_paid && <Route exact path="/settings/roles" component={props => <CompanyRoles {...props} parents={[Settings.meta]} />} />}
                {!!is_paid && <Route exact path="/settings/billing" component={props => <CompanyBilling {...props} parents={[Settings.meta]} />} />}
                <Route exact path="/settings/billing/plans" component={props => <CompanyPaymentPlans {...props} parents={[Settings.meta]} />} />
                <Route
                    exact
                    path="/settings/billing/invoices"
                    component={props => <CompanyInvoices {...props} parents={[Settings.meta, CompanyBilling.meta]} />}
                />
                <Route
                    exact
                    path="/settings/billing/payment-info"
                    component={props => <CompanyPaymentInfo {...props} parents={[Settings.meta, CompanyBilling.meta]} />}
                />
                <Route path="/settings" component={props => ['past_due', 'unpaid', 'incomplete'].indexOf(subscription_status) > -1 ? <CompanyBillingOverdue {...props} parents={[Settings.meta]} /> : <CompanyDetails {...props} />} />
            </Switch>
        );
    }
}

Settings.meta = {
    title: <FormattedMessage id="page.settings.title" />,
    subtitle: <FormattedMessage id="page.settings.subtitle" />,
    routes: ['/settings'],
    icon: 'ion-ios-gear-outline',
    menus: { main: true, user: false },
    order: 5,
    access: DefinedPermissions.CompanyEdit,
    requires: ['settings', 'read', 'update']
};

const mapStateToProps = state => {
    return {
        company: state.identity.company
    };
};
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getProfile
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Settings);
