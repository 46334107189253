import React from 'react';
import { RowBreak, FieldDescription } from './common';
import { Fields } from '../../../../components/fields';

class BooleanField extends React.Component {
    render() {
        const { id, width, breakRow, title, submitOnChange, disabled } = this.props;
        const widthClass = width ? `-${width}` : '';

        return (
            <React.Fragment>
                <div className={`field-boolean pd-b-15 col${widthClass}`}>
                    <Fields.Boolean placeholder={title} submitOnChange={submitOnChange} name={id} disabled={disabled} />
                    <FieldDescription {...this.props} />
                </div>
                {breakRow && <RowBreak width={width} />}
            </React.Fragment>
        );
    }
}

export default BooleanField;
