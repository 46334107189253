import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class Breadcrumbs extends React.Component {

    refineTitle = (title) =>{        
        if ( !title.split ){
            return title;
        }

        // shorten any contiguous strings greater than 40 characters
        let titleShortened = title
            .split(/(\s+)/)
            .map(word => word.length > 15 ? word.substring(0,15) + '…' : word)
            .join('');

        // if the whole label is long, shorten it down to 300 characters max
        if (titleShortened.length > 25) {
            titleShortened =  titleShortened.substring(0, 25);
            // end with an ellipsis, if it doesn't already happen to be ending with one
            let appendCharacter = '…'
            if (titleShortened.charAt(24) === appendCharacter) {
                appendCharacter = '';
            }
            titleShortened = titleShortened + appendCharacter;
        }   
        return titleShortened;
    } 

    getPath = () => {
        const { parents } = this.props;
        if (!parents || parents.length === 0) return null;

        return (
            <React.Fragment>
                {parents.map(({ title, routes }, i) => (
                    <Link key={i} to={routes && routes.length > 0 ? routes[0] : '/dashboard'} className="breadcrumb-item">
                       {this.refineTitle(title)}
                    </Link>
                ))}
            </React.Fragment>
        );
    };

    render() {
        const { title, hideBreadcrumbs, company, actions } = this.props;
        if (hideBreadcrumbs) return null;

        return (
            <div className="br-pageheader pd-y-5 pd-x-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <nav className="breadcrumb pd-0 mg-0 tx-12">
                                <Link to={{ pathname: '/dashboard', search: window.location.search }} className="breadcrumb-item">
                                    {company}
                                </Link>
                                {this.getPath()}
                                <span className="breadcrumb-item active">{this.refineTitle(title)}</span>
                            </nav>
                        </div>
                        {actions && <div className="col-lg-6 col-sm-12">{actions}</div>}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { company } = state.identity;
    return {
        company: company && company.company_name ? company.company_name : 'Dorae Inc.',
        pathname: state.router.location.pathname
    };
};

export default connect(
    mapStateToProps,
    null
)(Breadcrumbs);
