export const ENTITY_GRAPH_CREATE_LINK = `

    type EntityData {
        entity_type	: String!
        entity_hash_id	: Id
        entity_id      : Long
    }

    mutation M (
        $entities: [EntityData!]
    )  
    {
        entity_graph_create_link(
            input: {
                entities: $entities
            }
        )
        {
            id
            type_id
            type_name
            name
            created_on
            exp_on
            data
            clicks
            uses
            max_uses
            is_valid
            title
            description
        }
    }
`;