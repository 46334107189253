import React, {useState, useEffect} from 'react';
import ReactModal from 'react-modal';
import { FormattedMessage } from 'react-intl';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SelectControl from 'components/fields/uncontrolledComponents/selectControl';
import LoadingOverlay from 'react-loading-overlay';
import ClipLoader from 'react-spinners/ClipLoader';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { filterTransactions } from 'reducers/transactions';
import { listLots } from 'reducers/lots'
import { getTransactionDocuments } from 'reducers/transaction';
import {
    AttachmentField,
} from 'pages/models/fields/definitionsControlled';

library.add(faEdit)


export default function TextSelectorModal({ isOpen, setOpen, onConfirm, currentText, libraryTemplates }) {
    const [text, setText] = useState((!!currentText && !!currentText.runs) ? currentText : { runs: [ ] });
    const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);
    const [isEditLinkModalOpen, setIsLinkEditModalOpen] = useState(false);
    const [isAddTextModalOpen, setIsAddTextModalOpen] = useState(false);
    const [isEditTextModalOpen, setIsEditTextModalOpen] = useState(false);
    const [targetRunIndex, setTargetRunIndex] = useState(0);
    const updateLink = (index, newLinkRun) => {
        const currentRuns = text.runs.slice();
        currentRuns[index] = newLinkRun;
        setText({ runs: currentRuns });
    };
    const addLink = (linkRun) => {
        setText({
            runs: [
                ...text.runs,
                {
                    ...linkRun
                }
            ]
        });
    };
    const updateTextRun = (index, newText) => {
        const currentRuns = text.runs.slice();
        currentRuns[index].text = newText;
        setText({ runs: currentRuns });
    };
    const addTextRun = (newText) => {
        setText({ runs: [
            ...text.runs,
            {
                text: newText
            }
        ] });
    }
    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={() => setOpen(false)}
            className="modal-block dialog"
            overlayClassName="modal-overlay gray"
            style={{content: {maxHeight: '70%', overflowY: 'auto', overflowX: 'hidden'}}}
        >
            <LinkModal
                isOpen={isLinkModalOpen}
                setOpen={setIsLinkModalOpen}
                libraryTemplates={libraryTemplates}
                onConfirm={addLink}
            />
            <LinkModal
                key={'edit-run-link-' + targetRunIndex}
                isOpen={isEditLinkModalOpen}
                setOpen={setIsLinkEditModalOpen}
                libraryTemplates={libraryTemplates}
                initialRun={(text.runs && text.runs.length > targetRunIndex) ? text.runs[targetRunIndex] : null}
                onConfirm={val => updateLink(targetRunIndex, val)}
            />
            <TextModal
                isOpen={isAddTextModalOpen}
                setOpen={setIsAddTextModalOpen}
                onConfirm={addTextRun}
            />
            <TextModal
                key={'edit-run-text-' + targetRunIndex}
                isOpen={isEditTextModalOpen}
                setOpen={setIsEditTextModalOpen}
                initialText={(text.runs && text.runs.length > targetRunIndex) ? text.runs[targetRunIndex].text : ''}
                onConfirm={val => updateTextRun(targetRunIndex, val)}
            />
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content bd-0">
                    <div className="modal-header pd-x-20">
                        <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                            <FormattedMessage id='addTextAndLinks' />
                        </h6>
                    </div>
                    <div className="modal-body pd-20">
                        <div className="row">
                            <button className='btn btn-outline-info pd-x-20 mg-b-15' onClick={() => setIsLinkModalOpen(true)}><FormattedMessage id='addLink'/></button>
                            <button className='btn btn-outline-info pd-x-20 mg-b-15 mg-l-15' onClick={() => setIsAddTextModalOpen(true)}><FormattedMessage id='addText'/></button>
                        </div>
                        <div className="row">
                            {
                                text.runs.map((run, index) => {
                                    if (!!run.linkType) {
                                        return <div className="row pd-b-5 pd-x-25" style={{ maxWidth: '100%' }}>
                                            <span 
                                                style={{
                                                    color:'blue', 
                                                    border: '1px solid blue', 
                                                    padding: '0 0.5em 0 0.5em',
                                                    overflowWrap: 'break-word',
                                                    maxWidth: '100%',
                                                }}
                                            >
                                                {run.text} 
                                                &nbsp; 
                                                <span 
                                                    style={{
                                                        fontWeight: 'bold',
                                                        color: 'red',
                                                        textAlign: 'center',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        const currentRuns = text.runs.slice();
                                                        currentRuns.splice(index, 1);
                                                        setText({ runs: currentRuns });
                                                    }}
                                                >
                                                    ✕
                                                </span>
                                                <span 
                                                    style={{
                                                        cursor: 'pointer',
                                                        marginLeft: '5px',
                                                    }}
                                                    onClick={() => {
                                                        setTargetRunIndex(index);
                                                        setIsLinkEditModalOpen(true);
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </span>
                                            </span>
                                        </div>
                                    } else {
                                        return <div className="row pd-b-5 pd-x-25" style={{ maxWidth: '100%' }}>
                                            <span 
                                                style={{
                                                    color:'black', 
                                                    border: '1px solid black', 
                                                    padding: '0 0.5em 0 0.5em',
                                                    overflowWrap: 'break-word',
                                                    maxWidth: '100%',
                                                }}
                                            >
                                                {run.text} 
                                                &nbsp;
                                                <span 
                                                    style={{
                                                        fontWeight: 'bold',
                                                        color: 'red',
                                                        textAlign: 'center',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        const currentRuns = text.runs.slice();
                                                        currentRuns.splice(index, 1);
                                                        setText({ runs: currentRuns });
                                                    }}
                                                >
                                                    ✕
                                                </span>
                                                <span 
                                                    style={{
                                                        cursor: 'pointer',
                                                        marginLeft: '5px',
                                                    }}
                                                    onClick={() => {
                                                        setTargetRunIndex(index);
                                                        setIsEditTextModalOpen(true);
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </span>
                                            </span>
                                        </div>
                                    }
                                })
                            }
                        </div>
                        <div className="row pd-y-15">
                            <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                                Preview:
                            </h6>
                            <div
                                style={{ width: '100%', minHeight: '5em', border: '1px solid black' }}
                            >
                                {
                                    text.runs.map((run, index) => {
                                        if (!!run.linkType) {
                                            return <span style={{color:'blue', cursor: 'pointer', overflowWrap: 'break-word'}}>
                                                {run.text}&nbsp;
                                            </span>
                                        } else {
                                            return <span style={{ overflowWrap: 'break-word' }}>
                                                {run.text}&nbsp;
                                            </span>
                                        }
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer justify-content-center">
                        <button
                            type="button"
                            className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                            onClick={() => {
                                onConfirm({ text });
                                setOpen(false)
                            }}
                        >
                            <FormattedMessage id="common.confirm" />
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                            onClick={() => {
                                setText(currentText);
                                setOpen(false);
                            }}
                        >
                            <FormattedMessage id="common.cancel" />
                        </button>
                    </div>
                </div>
            </div>
        </ReactModal>
    )
}


const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            filterTransactions,
            listLots,
            getTransactionDocuments,
        },
        dispatch
    );
const mapStateToProps = state => ({});

const LinkModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(function ({ 
    isOpen, 
    setOpen, 
    onConfirm, 
    libraryTemplates, 
    filterTransactions, 
    listLots, 
    getTransactionDocuments, 
    initialRun,
}) {
    const [text, setText] = useState('');
    const [entity, setEntity] = useState();
    const [entityType, setEntityType] = useState('');
    const [loadingEntities, setLoadingEntities] = useState(false);
    const [availableEntities, setAvailableEntities] = useState([]);
    const [selectedDocument, setSelectedDocument] = useState();

    useEffect(() => {
        if (!!initialRun) {
            setText(initialRun.text);
            setEntity(initialRun.entity);
            setEntityType(initialRun.linkType);
        }
    }, [initialRun])
    

    const templateOptions = [
        {
            label: 'Dash Templates',
            options: libraryTemplates.selectedDashes.map(d => ({ label: `${d.title} (${d.hash_id})`, value: d }))
        },
        {
            label: 'DoT Templates',
            options: libraryTemplates.selectedDots.map(d => ({ label: `${d.title} (${d.hash_id})`, value: d }))
        },
        {
            label: 'Document Templates',
            options: libraryTemplates.selectedDocuments.map(d => ({ label: `${d.title} (${d.hash_id})`, value: d }))
        },
    ]

    let entityOptions = [];

    if (entityType === 'dash' || entityType === 'dash-template') {
        entityOptions = availableEntities.map(d => ({ label: `${d.title || 'Untitled'} (${d.id})`, value: d }));
    } else if (entityType === 'dot' || entityType === 'dot-template') {
        entityOptions = availableEntities.map(d => ({ label: `${d.hash_id}, Quantity ${d.quantity}`, value: d }));
    } else {
        entityOptions = availableEntities.map(d => ({ label: `${d.s3_object} (${d.id})`, value: d }));
    }

    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={() => setOpen(false)}
            className="modal-block dialog"
            overlayClassName="modal-overlay gray"
            style={{zIndex: '2001'}}
        >
            <div className="modal-dialog modal-lg wd-450" role="document">
                <div className="modal-content bd-0">
                    <div className="modal-header pd-x-20">
                        <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                            <FormattedMessage id='addLink' />
                        </h6>
                    </div>
                    <div className="modal-body pd-20">
                        <div className="row pd-b-20">
                            <label>
                                Enter Text <span style={{color: 'red'}}>(required)</span>
                            </label>
                            <input type="textarea" value={text} style={{ marginLeft: '15px', border: '1px solid black', width: '100%'}} onChange={e => setText(e.target.value)} />
                        </div>
                        <div className="row">
                            <SelectControl
                                name='link-template'
                                options={templateOptions}
                                submitOnChange={true}
                                onChange={async (valueObj) => {
                                    const selectedEntity = valueObj.value;
                                    let promise;
                                    if (!selectedEntity.model_type_name) {
                                        setEntityType('document-template');
                                        promise = new Promise(resolve => {
                                            getTransactionDocuments(
                                                null,
                                                [selectedEntity.id],
                                                (results) => {
                                                    if (results === undefined) {
                                                        resolve([]);
                                                    } else {
                                                        resolve(results);
                                                    }
                                                },
                                            );
                                        });
                                        
                                    } else if (selectedEntity.model_type_name === 'event template') {
                                        setEntityType('dash-template');
                                        promise = new Promise(resolve => {
                                            filterTransactions(
                                                {
                                                    transaction_type: 1,
                                                    model_ids: [selectedEntity.id],
                                                },
                                                (results) => {
                                                    if (results === undefined) {
                                                        resolve([]);
                                                    } else {
                                                        resolve(results);
                                                    }
                                                },
                                                false
                                            );
                                        });
                                    } else {
                                        setEntityType('dot-template');
                                        promise = new Promise(resolve => {
                                            listLots(
                                                {
                                                    cargo_categories: [selectedEntity.id],
                                                },
                                                (results) => {
                                                    if (results === undefined) {
                                                        resolve([]);
                                                    } else {
                                                        resolve(results);
                                                    }
                                                },
                                            );
                                        });
                                    }
                                    setEntity(selectedEntity);
                                    setLoadingEntities(true);
                                    let entities = await promise;
                                    if (!selectedEntity.model_type_name) {
                                        setAvailableEntities(entities);
                                    } else {
                                        setAvailableEntities(entities.data);
                                    }
                                    setLoadingEntities(false);
                                }}
                                isClearable={true}
                                showLabel={false}
                                showIsRequired={true}
                                width={12}
                            />
                        </div>
                        {
                            (!entityType || !entityType.includes('document'))
                            ?
                            <LoadingOverlay
                                active={loadingEntities}
                                spinner={<ClipLoader size={20} color={'white'}/>}
                                style={{
                                    wrapper: {
                                        height: '100%', width: '100%'
                                    }
                                }}
                            >
                                <div className="row">
                                    <SelectControl
                                        name='link-entity'
                                        disabled={!entity || loadingEntities}
                                        options={entityOptions}
                                        submitOnChange={true}
                                        onChange={(valueObj) => {
                                            const selectedEntity = valueObj.value;
                                            if (entityType === 'document-template') {
                                                setEntityType('document');
                                            } else if (entityType === 'dash-template') {
                                                setEntityType('dash');
                                            } else {
                                                setEntityType('dot');
                                            }
                                            setEntity(selectedEntity);
                                        }}
                                        isClearable={true}
                                        showLabel={false}
                                        showIsRequired={false}
                                        width={12}
                                    />
                                </div>
                            </LoadingOverlay>
                            :
                            <AttachmentField
                                value={selectedDocument} 
                                showIsRequired={false} 
                                disabled={false} 
                                tooltipOverlay={null} 
                                appendEls={[]} 
                                setFieldValue={(id, val) => {
                                    setEntityType('document');
                                    setEntity(val);
                                    setSelectedDocument(val);
                                }} 
                                initialValue={selectedDocument}
                            />
                        }
                    </div>
                    <div className="modal-footer justify-content-center">
                        <button
                            type="button"
                            className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                            disabled={!text || !entity}
                            onClick={() => {
                                onConfirm({ text, entity, linkType: entityType });
                                setText('');
                                setEntity(null);
                                setOpen(false)
                            }}
                        >
                            <FormattedMessage id="common.confirm" />
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                            onClick={() => {
                                // setText('');
                                // setEntity(null);
                                setOpen(false);
                            }}
                        >
                            <FormattedMessage id="common.cancel" />
                        </button>
                    </div>
                </div>
            </div>
        </ReactModal>
    )
})



function TextModal({ isOpen, setOpen, onConfirm, initialText = '' }) {
    const [text, setText] = useState(initialText);
    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={() => setOpen(false)}
            className="modal-block dialog"
            overlayClassName="modal-overlay gray"
            style={{zIndex: '2001'}}
        >
            <div className="modal-dialog modal-lg wd-450" role="document">
                <div className="modal-content bd-0">
                    <div className="modal-header pd-x-20">
                        <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                            <FormattedMessage id='addText' />
                        </h6>
                    </div>
                    <div className="modal-body pd-20">
                        <div className="row">
                            <label>
                                Enter Text:
                            </label>
                            <textarea value={text} style={{ marginLeft: '15px', border: '1px solid black', width: '100%', minHeight: '5em'}} onChange={e => setText(e.target.value)} />
                        </div>
                    </div>
                    <div className="modal-footer justify-content-center">
                        <button
                            type="button"
                            className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                            disabled={!text}
                            onClick={() => {
                                onConfirm(text);
                                setText(initialText);
                                setOpen(false)
                            }}
                        >
                            <FormattedMessage id="common.confirm" />
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                            onClick={() => {
                                setText(initialText);
                                setOpen(false);
                            }}
                        >
                            <FormattedMessage id="common.cancel" />
                        </button>
                    </div>
                </div>
            </div>
        </ReactModal>
    )
}