import React from 'react';
import { FormattedMessage } from 'react-intl';
import { findMessage, messages } from './messages';

export const parseErrors = (response, defaultMessage = messages.default_error) => {
    if (!response) {
        return <FormattedMessage {...defaultMessage} />;
    }

    if (response.networkError && response.networkError.result && response.networkError.result.errors) {
        const errors = response.networkError.result.errors;
        if (errors.length) {
            return (
                <React.Fragment>
                    {errors.map((e, i) => (
                        <FormattedMessage key={i} {...findMessage(e.message, defaultMessage)} />
                    ))}
                </React.Fragment>
            );
        }
    }

    if (response.response && response.response.data && response.response.data.errors && response.response.data.errors.length > 0) {
        const [error] = response.response.data.errors;
        const { message } = error || {};
        return message;
    }

    return response.message || <FormattedMessage {...defaultMessage} />;
};
