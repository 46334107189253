import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { setVisibilityDialog } from '../../../../../reducers/fieldVisibility';
import Tooltip from 'rc-tooltip';

class VisibilityDialogButton extends React.Component {
    render() {
        const { field, setVisibilityDialog, disabled = false, count = 0 } = this.props;
        if (!field) return null;

        const tooltipWrapper = (children) => {
            return (field.is_required) ? 
                <Tooltip placement={'right'} trigger={['hover']} overlay={<FormattedMessage id="common.fieldRequiredOrVisibility"/>} children={children} />
                 :
                <div children={children} />
        }
        return tooltipWrapper (
                <button type="button" className={'btn btn-block rich-text-button ' + (disabled ? 'disabled' : '')} onClick={() => (!!disabled ? null : setVisibilityDialog(field))}>
                    <span>
                        <FormattedMessage id="visibilityConditions.placeholder" values={{count}} />
                    </span>
                    <i className="material-icons-outlined" style={{ lineHeight: '16px' }}>
                        visibility
                    </i>
                </button>
            )
    }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setVisibilityDialog
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VisibilityDialogButton);
