export const DOCUMENT_TEMPLATE_SET_GROUP_REFS = `

    type GroupReference {
        field_reference_literal: String!
        name: String
    }

    mutation M(
        $group_references: [GroupReference!]
        $document_template_id: Long!
    ) {
        document_template_set_group_refs(
            input: { 
                group_references: $group_references
                document_template_id: $document_template_id
            }
        ) {
            success
        }
    }
`;
