import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import { injectIntl, FormattedMessage } from 'react-intl';
import ModelPreview from 'pages/models/fields/components/preview';
import FieldDefinition from 'models/field';
import WordDocumentViewer from 'pages/documentTemplates/components/wordDocumentViewer';
import ExcelDocumentViewer from 'pages/documentTemplates/components/excelDocumentViewer';
import { loadModel } from 'reducers/models';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { history } from 'configuration/history';


function EntityModal(props) {
    const { 
        open,
        setOpen,
        entityType,
        entity,
        allEntities,
        intl: { formatMessage },
        onClose = () => null,
        loadModel,
    } = props;

    console.log('modal props', props);

    const [dotTemplates, setDotTemplates] = useState(allEntities ? allEntities.selectedDots : []);

    useEffect(() => {
        if (entityType === 'dash-template') {
            const dotFields = entity.model_field_list.filter(f => f.type_name === 'Cargo' && !!f.cargoCategory);
            const dotTemplatePromises = [];
            dotFields.forEach(f => {
                dotTemplatePromises.push(new Promise(resolve => {
                    loadModel(f.cargoCategory, resolve);
                }))
            })
            const fieldsWithDotTypes = dotFields.filter(f => console.log(f))
            const dotTypePromises = [];

            Promise.all(dotTemplatePromises).then(results => {
                console.log('loaded dot templates', results)
                setDotTemplates(results);
            })
        }
    }, [entity.id, entity.model_field_list, entityType, loadModel])

    let contentStyle ={width: '70%', maxHeight: '90%', overflowY: 'hidden', overflowX: 'hidden'};

    if (entityType === 'document-template') {
        contentStyle.height = '95%';
        contentStyle.maxHeight = '95%';
    }

    return (
        <ReactModal
            isOpen={open}
            onRequestClose={() => {
                setOpen(false)
                onClose();
            }}
            className="modal-block dialog"
            overlayClassName="modal-overlay gray"
            style={{zIndex: '2000', content: { ...contentStyle, minWidth: '800px' }}}
        >
            <>
                {entityType === 'document-template' && entity.document_type === 'excel' &&
                    <div style={{ height: '100%', backgroundColor: '#fff', padding: '5px' }}>
                        <div style={{ padding: '1em', display: 'flex', flexDirection: 'row' }}>
                            <div style={{width: '50%'}}>
                                <h6 className="card-title">
                                    {entity.title}
                                </h6>
                            </div>
                            <div style={{ marginLeft: 'auto' }}>
                                <button
                                    type="button"
                                    className="btn btn-outline-info font-weight-bold ml-2 close-preview-btn"
                                    onClick={() => history.push(`/document-templates/${entity.id}` + window.location.search)}
                                >
                                    <FormattedMessage id='openEditor' />
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-outline-info font-weight-bold ml-2 close-preview-btn"
                                    onClick={() => setOpen(false)}
                                    style={{ width: '3em' }}
                                >
                                    {'X'}
                                </button>
                            </div>
                        </div>
                        <div className='document-preview-overlay'>
                            <ExcelDocumentViewer
                                templateFileData={entity.file_data}
                                templateMetadata={entity.metadata}
                            />
                        </div>
                    </div>
                }
                {entityType === 'document-template' && entity.document_type === 'word' &&
                    <div style={{ height: '100%', backgroundColor: '#fff', padding: '5px' }}>
                        <div style={{ padding: '1em', display: 'flex', flexDirection: 'row' }}>
                            <div style={{width: '50%'}}>
                                <h6 className="card-title">
                                    {entity.title}
                                </h6>
                            </div>
                            <div style={{ marginLeft: 'auto' }}>
                                <button
                                    type="button"
                                    className="btn btn-outline-info font-weight-bold ml-2 close-preview-btn"
                                    onClick={() => history.push(`/document-templates/${entity.id}` + window.location.search)}
                                >
                                    <FormattedMessage id='openEditor' />
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-outline-info font-weight-bold ml-2 close-preview-btn"
                                    onClick={() => setOpen(false)}
                                    style={{ width: '3em' }}
                                >
                                    {'X'}
                                </button>
                            </div>
                        </div>
                        <div style={{ height: 'calc(100% - 5em)' }}>
                            <WordDocumentViewer
                                templateFileData={entity.file_data}
                            />
                        </div>
                    </div>
                }
                {(entityType === 'dash-template' || entityType === 'dot-template') &&
                    <div style={{ backgroundColor: '#fff', padding: '1em 1em 1em 1em' }}>
                        <div style={{ marginBottom: '2em', display: 'flex', flexDirection: 'row' }}>
                            <div style={{width: '50%'}}>
                                <h6 className="card-title">
                                    {entity.title}
                                </h6>
                            </div>
                            <div style={{ marginLeft: 'auto' }}>
                                <button
                                    type="button"
                                    className="btn btn-outline-info font-weight-bold ml-2 close-preview-btn"
                                    onClick={() => {
                                        entityType === 'dash-template' ?
                                            history.push(`/events/event-templates/${entity.id}` + window.location.search)
                                            :
                                            history.push(`/objects/object-templates/${entity.id}` + window.location.search);
                                    }}
                                >
                                    <FormattedMessage id='openEditor' />
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-outline-info font-weight-bold ml-2 close-preview-btn"
                                    onClick={() => setOpen(false)}
                                    style={{ width: '3em' }}
                                >
                                    {'X'}
                                </button>
                            </div>
                        </div>
                        <div className='template-preview-overlay'>
                            <ModelPreview 
                                key={entity.id}
                                model={entity}
                                modelId={entity.id} 
                                modelTypeId={entityType === 'dash-template' ? 1 : 2} 
                                cargoCategories={dotTemplates} 
                                cargoTypes={[]}
                                manualFields={entity.model_field_list.map(f => FieldDefinition.fromDB(f))}
                                hideMapFieldToggle={true}
                                hideHeader={true}
                                hideTitle={true}
                            />
                        </div>
                    </div>
                }
            </>
        </ReactModal>
    )
};


const mapStateToProps = state => ({
});
const mapDispatchToProps = dispatch => bindActionCreators({
    loadModel,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(EntityModal));