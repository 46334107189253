import { MAIN_MENU_TOGGLE_VISIBILITY, MAIN_MENU_SET_VISIBILITY, MAIN_MENU_ADJUST } from './types';

const defaultState = {
    firstLoad: true,
    isMobile: false,
    open: true,
    menuClass: ''
};

const getClass = (isClosing, isMobile) => (isClosing ? 'collapsed-menu' : isMobile ? 'show-left' : '');

/**
 * Loading state mapper.
 * @param {object} state
 * @param {string} action
 */
export default function(state = defaultState, action) {
    switch (action.type) {
        case MAIN_MENU_TOGGLE_VISIBILITY:
            return {
                ...state,
                open: !state.open,
                menuClass: getClass(state.open, state.isMobile)
            };

        case MAIN_MENU_SET_VISIBILITY:
            return { ...state, open: action.visibility };

        case MAIN_MENU_ADJUST:
            const isMobile = action.width <= 640;
            if (isMobile) {
                let open = state.open;
                if (state.firstLoad) {
                    open = false;
                }
                return { ...state, open, firstLoad: false, isMobile,  menuClass: getClass(!open, true) };
            }
            return { ...state, firstLoad: false, isMobile, menuClass: getClass(!state.open, false) };

        default:
            return state;
    }
}

export const toggleMenuVisibility = () => async dispatch => {
    dispatch({ type: MAIN_MENU_TOGGLE_VISIBILITY });
};

export const setMenuVisibility = visibility => async dispatch => {
    dispatch({ type: MAIN_MENU_SET_VISIBILITY, visibility });
};

export const setSize = width => async dispatch => {
    dispatch({ type: MAIN_MENU_ADJUST, width: width });
};
