import React from 'react';
import moment from 'moment';
//import 'moment/locale/pt';
import { Field } from 'formik';
import { RowBreak, FieldDescription } from './common';
import { FormattedMessage } from 'react-intl';
import DateTime from  'react-datetime';
import 'react-datetime/css/react-datetime.css'
import '../../models.scss';
import Tooltip from 'rc-tooltip';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class DateField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.initialValue,
            isOpen: false,
            key: `field-${props.id}`
        };
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.initialValue !== prevProps.initialValue) {
            let key = this.state.key;
            if (!this.state.isOpen) {
                key = `field-${this.props.id}-${this.props.initialValue}`;
            }
            this.setState({ value: this.props.initialValue, key });
        }
    }

    render() {
        const { id, width, breakRow, title, dateFormat, disabled, prependEls = [], onChangeExt = () => null, tooltipOverlay, appendEls = [], seleniumId, locale, setFieldValue } = this.props;
        const widthClass = width ? `-${width}` : '';
        const {value} = this.state;
        const inputProps = {
            placeholder: title,
            style: {
                display: 'inline-block',
                width: '100%'
            }
        }
        let className = 'form-control';
        const stringFormat = dateFormat || 'DD-MMM-YYYY';
        moment.locale(locale);
        const parsedDate = moment.isMoment(value) ? value : moment(value, stringFormat, true);
        let dateValue = value;
        if (parsedDate.isValid()) {
            dateValue = parsedDate.format(stringFormat)
        }
        if (disabled) {
            inputProps.disabled = true;
        } else if (value && !parsedDate.isValid()) {
            className += ' invalid';
        }
        inputProps.className = className;
        inputProps.name = id;

        const fieldEl = (
            <div style={{width: '100%', height: '100%'}}>
                <DateTime
                    key={this.state.key}
                    locale={locale}
                    initialValue={dateValue}
                    dateFormat={stringFormat}
                    onChange={async date => {
                        if (moment.isMoment(date) && date.isValid()) {
                            const txtDate = date.format(stringFormat)
                            await setFieldValue(id, txtDate)
                            onChangeExt(id, txtDate);
                        } else {
                            await setFieldValue(id, date);
                            onChangeExt(id, date);
                        }
                        this.setState({ value: date });
                        // submitForm();
                    }}
                    onOpen={() => this.setState({ isOpen: true })}
                    onClose={() => this.setState({ isOpen: false })}
                    inputProps={inputProps}
                    style={{ display: 'inline-block', width: '100%' }}
                    timeFormat={false}
                    isValidDate={() => true}
                    className={`se-id-${seleniumId} ${stringFormat === 'MM' ? 'dorae_isMonth-Selector' : ''}`}
                    closeOnSelect={true}
                    closeOnTab={true}
                />
                <div style={{ position: 'absolute', right: '0', top: '0', backgroundColor: 'white'}}>
                    {[...appendEls]}
                </div>
            </div>
        );

        return (
            <React.Fragment>
                <div className={`pd-b-15 col${widthClass}`} style={{ display: 'inline-block' }}>
                    {[...prependEls]}
                    <div className={'field-date'}>
                        {
                            tooltipOverlay ?
                            <Tooltip
                                key={id}
                                placement={'top'}
                                trigger={['hover']}
                                overlay={tooltipOverlay}
                                children={ fieldEl }
                            />
                            :
                            fieldEl
                        }
                        {!!dateValue && 
                            <Tooltip trigger={['hover']} overlay={<span>{title}</span>} mouseEnterDelay={2}>
                                <div className="input-placeholder">
                                    {title}
                                </div> 
                            </Tooltip>
                        }
                        {this.props.showIsRequired && !dateValue && <div className="validation-message required-placeholder"><FormattedMessage id="validation.required" /></div>}
                        <FieldDescription 
                            {...this.props} 
                            seleniumId={seleniumId}
                        />
                    </div>
                </div>
                {breakRow && <RowBreak width={width} />}
            </React.Fragment>
        );
    }
}


const mapStateToProps = state => ({
    locale: state.localization.locale
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DateField);