export const USERS_LIST = `
    query($company_ids: [Int!], $status_ids: [Int!], $country_ids: [Int!], $search: String, $order_by: [OrderBy], $offset: Int, $limit: Int, $role_ids: [Int!]) {
        user_list(
            input: { company_ids: $company_ids, status_ids: $status_ids, country_ids: $country_ids, search: $search, role_ids: $role_ids }
            paging: { order_by: $order_by, offset: $offset, limit: $limit }
        ) {
            total
            data {
                id
                hash_id
                status_id
                status_name
                created_on
                modified_on
                email
                auth_type
                last_login_on
                login_attempts
                reset_password
                name
                avatar_id

                role_id

                mfa_required
                
                avatar {
                    id

                    thumb(
                        width: 200
                        height: 200
                        fit: false
                    ) {
                        bucket
                        key
                        size
                        mime
                        last_modified
                        url
                    }
                }

                lang_id
                lang
                country_id
                country_code2
                country_code3
                state
                city
                zip
                address_1
                address_2
                phone
                extra_info

                aux {
                    name
                    value
                }
            }
        }
    }
`;
