export const MODELS_CLONE = `
    mutation M($model_id: Int!, $title: String) {
        model_clone(model_id: $model_id, title: $title) {
            id
            hash_id
            company_id
            status_id
            status_name
            created_on
            title
            description
            is_used
            split_conditions
            extra_info
            model_type
            model_type_name
            contains_inaccessible_dots
        }
    }
`;
