import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { uuid } from '../utilities/common';
import moment from 'moment';

export default class Field {
    id;
    input_id;
    order;
    title;
    description;
    defaultValue;
    type;
    type_id;
    width;
    breakRow;
    visibility_id;
    visibilityCondition;
    options;
    currency;
    step;
    min;
    max;
    fieldValues;
    index_field_id;
    is_required;
    dateFormat;
    fullField;
    groupThousands;
    numberFormat;
    cargoCategory;
    cargoType;
    transformCargoType;
    cargoReferenceType;
    is_static_unit;
    default_unit;
    quantityLabel;
    map_dash_field;
    map_dot_field;
    map_special_dot_field;
    map_compound_id;
    use_mapped_value;
    use_mapped_quantity_value;
    use_mapped_unit_value;
    restore_value;
    restore_quantity_value;
    restore_unit_value;
    map_disabled_reason;
    allow_user_transform;
    use_user_transform;

    constructor(field) {
        const {
            id,
            special_id,
            input_id,
            order,
            title,
            description,
            defaultValue,
            type,
            type_id,
            width,
            breakRow,
            visibility_id = uuid(8),
            visibilityCondition,
            options,
            currency,
            step,
            min = -1000000000000,
            max = 1000000000000,
            fieldValues,
            is_required,
            index_field_id,
            dateFormat,
            groupThousands = false,
            numberFormat = "DotSeparator",
            cargoCategory,
            cargoType,
            transform_cargo_type,
            cargoReferenceType,
            is_static_unit,
            default_unit,
            quantityLabel,
            map_dash_field,
            map_dot_field,
            map_special_dot_field,
            map_compound_id,
            use_mapped_value,
            use_mapped_quantity_value,
            use_mapped_unit_value,
            restore_value,
            restore_quantity_value,
            restore_unit_value,
            map_disabled_reason,
            allow_user_transform,
            use_user_transform,
        } = field || {};

        this.id = id;
        this.special_id = special_id;
        this.input_id = input_id;
        this.order = order || 1;
        this.title = title || 'New Field';
        this.description = description || '';
        this.defaultValue = defaultValue || '';
        this.type = type || FieldTypes.FIELD_SECTION;
        this.type_id = type_id;
        this.width = width || 12;
        this.breakRow = breakRow === true;
        this.visibility_id = visibility_id;
        this.visibilityCondition = visibilityCondition || '';
        this.options = options || '';
        this.currency = currency || '';
        this.step = step || 1;
        this.min = min;
        this.max = max;
        this.fieldValues = fieldValues || [];
        this.index_field_id = index_field_id;
        this.is_required = !!is_required;
        this.fullField = field;
        this.dateFormat = dateFormat || 'DD-MMM-YYYY';
        this.groupThousands = groupThousands;
        this.numberFormat = numberFormat;
        this.cargoCategory = cargoCategory;
        this.cargoType = cargoType;
        this.transformCargoType = transform_cargo_type;
        this.cargoReferenceType = cargoReferenceType;
        this.is_static_unit = is_static_unit;
        this.default_unit = default_unit;
        this.quantityLabel = quantityLabel;
        this.map_dash_field = map_dash_field;
        this.map_dot_field = map_dot_field;
        this.map_special_dot_field = map_special_dot_field;
        this.map_compound_id = map_compound_id;
        this.use_mapped_value = use_mapped_value;
        this.use_mapped_quantity_value = use_mapped_quantity_value;
        this.use_mapped_unit_value = use_mapped_unit_value;
        this.restore_value = restore_value;
        this.restore_quantity_value = restore_quantity_value;
        this.restore_unit_value = restore_unit_value;
        this.map_disabled_reason = map_disabled_reason;
        this.allow_user_transform = allow_user_transform;
        this.use_user_transform = use_user_transform;
    }

    static fromDB(data) {
        if (!data) return undefined;
        let field = new Field({
            ...data,
            type: data.type || data.type_name,
            breakRow: !!data.force_break,
            fieldValues: data.model_field_value_list || data.values,
            visibilityCondition: JSON.parse(data.visibility_check),
            cargoCategory: data.cargoCategory,
            cargoType: data.cargo_type,
            transformCargoType: data.transform_cargo_type,
            cargoReferenceType: data.cargo_reference_type,
            use_mapped_value: data.use_mapped_value,
            use_mapped_quantity_value: data.use_mapped_quantity_value,
            use_mapped_unit_value: data.use_mapped_unit_value,
            restore_value: data.restore_value,
            restore_quantity_value: data.restore_quantity_value,
            restore_unit_value: data.restore_unit_value,
            allow_user_transform: data.allow_user_transform,
            use_user_transform: data.use_user_transform,
            default_unit: data.default_unit,
        });

        const { extra_info } = data;
        if (extra_info) {
            const extraInfo = JSON.parse(extra_info);
            const { defaultValue, currency, dateFormat, step, min, max, visibility_id, groupThousands, numberFormat, quantityLabel } = extraInfo;
            field.defaultValue = defaultValue !== undefined && defaultValue !== null && defaultValue !== '' ? defaultValue : undefined;
            field.currency = !!currency ? currency : undefined;
            field.dateFormat = !!dateFormat ? dateFormat : undefined;
            field.step = step !== undefined && step !== null && step !== '' ? step : 1;
            field.min = min !== undefined && min !== null && min !== '' ? min : 1;
            field.max = max !== undefined && max !== null && max !== '' ? max : 1;
            field.visibility_id = !!visibility_id ? visibility_id : field.visibility_id;
            field.groupThousands = !!groupThousands ? groupThousands : field.groupThousands;
            field.numberFormat = !!numberFormat ? numberFormat : field.numberFormat;
            field.quantityLabel = quantityLabel;
        }

        return field;
    }

    static toDB(field) {
        let data = {
            ...field,
            id: isNaN(field.id) ? undefined : field.id,
            type_name: field.type,
            dbId: undefined,
            force_break: field.breakRow,
            fullField: undefined,
            model_field_value_list: field.fieldValues,
            index_field: undefined,
            visibility_check: JSON.stringify(field.visibilityCondition),
            visibility_id: undefined,
            visibilityCondition: undefined,
            step: undefined,
            min: undefined,
            max: undefined,
            groupThousands: undefined,
            numberFormat: undefined,
            cargoCategory: undefined,
            cargoType: undefined,
            transformCargoType: undefined,
            cargoReferenceType: undefined,
            is_static_unit: false,
            default_unit: undefined,
            quantityLabel: undefined,
            map_dash_field: undefined,
            map_dot_field: undefined,
            map_special_dot_field: undefined,
            map_compound_id: undefined,
            fieldChildren: undefined,
        };

        for (const key in data) {
            if (data.hasOwnProperty(key) && (data[key] === undefined || data[key] === '')) {
                delete data[key];
            }
        }

        const { defaultValue, 
            currency, 
            dateFormat, 
            step,
            min, 
            max, 
            visibility_id, 
            groupThousands, 
            numberFormat, 
            cargoCategory, 
            cargoType, 
            transformCargoType, 
            cargoReferenceType, 
            is_static_unit, 
            default_unit, 
            quantityLabel, 
            map_dash_field,
            map_dot_field,
            map_special_dot_field,
            map_compound_id,
            use_mapped_value,
            use_mapped_quantity_value,
            use_mapped_unit_value,
            restore_value,
            restore_quantity_value,
            restore_unit_value,
            allow_user_transform,
            use_user_transform,
        } = field;

        let extraInfo = { visibility_id };
        if (!!currency) extraInfo = { ...extraInfo, currency };
        if (!!dateFormat) extraInfo = !!extraInfo ? { ...extraInfo, dateFormat } : { dateFormat };
        if (step !== 1) extraInfo = !!extraInfo ? { ...extraInfo, step } : { step };
        if (min !== undefined && min !== null && min !== '') extraInfo = !!extraInfo ? { ...extraInfo, min } : { min };
        if (max !== undefined && max !== null && max !== '') extraInfo = !!extraInfo ? { ...extraInfo, max } : { max };
        if (defaultValue !== undefined && defaultValue !== null && defaultValue !== '') {
            extraInfo = !!extraInfo ? { ...extraInfo, defaultValue } : { defaultValue };
        }
        if (groupThousands !== undefined && groupThousands !== null) {
            extraInfo = !!extraInfo ? { ...extraInfo, groupThousands } : { groupThousands };
        }
        if (numberFormat !== undefined && numberFormat !== null && numberFormat !== '') {
            extraInfo = !!extraInfo ? { ...extraInfo, numberFormat } : { numberFormat };
        }
        if (quantityLabel !== undefined && quantityLabel !== null && quantityLabel !== '') {
            extraInfo.quantityLabel = quantityLabel;
        }

        if (!!extraInfo) {
            data.extra_info = JSON.stringify(extraInfo);
        }

        if (cargoCategory !== undefined && cargoCategory !== null) {
            data.cargo_category = cargoCategory;
        }
        if (cargoType !== undefined && cargoType !== null) {
            data.cargo_type = cargoType;
        }
        if (transformCargoType !== undefined && transformCargoType !== null) {
            data.transform_cargo_type = transformCargoType;
        }
        if (cargoReferenceType !== undefined && cargoReferenceType !== null) {
            data.cargo_reference_type = cargoReferenceType;
        }
        data.is_static_unit = is_static_unit;
        data.default_unit = default_unit;
        data.map_dash_field = map_dash_field;
        data.map_dot_field = map_dot_field;
        data.map_special_dot_field = map_special_dot_field;
        data.map_compound_id = map_compound_id;
        data.use_mapped_value = !(use_mapped_value === false);
        data.use_mapped_quantity_value = !(use_mapped_quantity_value === false);
        data.use_mapped_unit_value = !(use_mapped_unit_value === false);
        data.restore_value = restore_value;
        data.restore_quantity_value = restore_quantity_value;
        data.restore_unit_value = restore_unit_value;
        data.allow_user_transform = allow_user_transform;
        data.use_user_transform = use_user_transform;

        return data;
    }
}

export const FieldTypes = {
    FIELD_NONE: 'None',
    FIELD_SECTION: 'Section',
    FIELD_TEXT: 'Text',
    FIELD_NUMBER: 'Number',
    FIELD_DATE: 'Date',
    FIELD_YES_NO: 'YesNo',
    FIELD_SELECT: 'Select',
    FIELD_MULTI_SELECT: 'MultiSelect',
    FIELD_BUTTON: 'Button',
    FIELD_CURRENCY: 'Currency',
    FIELD_MULTI_TEXT: 'MultiText',
    FIELD_ATTACHMENT: 'Attachment',
    FIELD_CARGO: 'Cargo',
    FIELD_DOT_QUANTITY: 'DotQuantity',
    FIELD_DOT_UNIT: 'DotUnit',
    FIELD_END_SECTION: 'EndSection',
};

export const getFieldIcon = type => {
    switch (type) {
        case FieldTypes.FIELD_SECTION:
            return 'folder';
        case FieldTypes.FIELD_TEXT:
            return 'text_fields';
        case FieldTypes.FIELD_NUMBER:
            return 'exposure';
        case FieldTypes.FIELD_DATE:
            return 'calendar_today';
        case FieldTypes.FIELD_YES_NO:
            return 'toggle_on';
        case FieldTypes.FIELD_SELECT:
            return 'done';
        case FieldTypes.FIELD_MULTI_SELECT:
            return 'done_all';
        case FieldTypes.FIELD_BUTTON:
            return 'library_add';
        case FieldTypes.FIELD_CURRENCY:
            return 'attach_money';
        case FieldTypes.FIELD_MULTI_TEXT:
            return 'format_align_left';
        case FieldTypes.FIELD_ATTACHMENT:
            return 'attach_file';
        case FieldTypes.FIELD_CARGO:
            return 'directions_boat';
        case FieldTypes.FIELD_DOT_QUANTITY:
            return 'exposure';
        case FieldTypes.FIELD_DOT_UNIT:
            return 'done';
        case FieldTypes.FIELD_END_SECTION:
            return 'done';


        case FieldTypes.FIELD_NONE:
        default:
            return 'help';
    }
};

export const getFormattedFieldValue = (type, value, array = true) => {
    let name = 'values';
    switch (type) {
        case FieldTypes.FIELD_TEXT:
            return { text_values: [value] };

        case FieldTypes.FIELD_MULTI_TEXT:
        case FieldTypes.FIELD_SELECT:
        case FieldTypes.FIELD_MULTI_SELECT:
        case FieldTypes.FIELD_CURRENCY:
            name = 'text_values';
            break;
        
        case FieldTypes.FIELD_YES_NO:
        case FieldTypes.FIELD_NUMBER:
            name = 'num_values';
            break;

        case FieldTypes.FIELD_DATE:
            name = 'date_values';
            break;

        default:
            name = 'values';
            break;
    }

    const data = {};
    data[name] = !!array ? [value] : value;
    return data;
};

export const FieldComparatorTypes = {
    NONE: 'None',
    EQUALS: 'Equals',
    GREATER: 'Greater',
    LESS: 'Less',
    GREATER_EQUAL: 'GreaterEqual',
    LESS_EQUAL: 'LessEqual',
    NOT_EQUAL: 'NotEqual',
    BETWEEN: 'Between',
    CONTAINS: 'Contains',
    IN: 'In',
    LIKE: 'Like',
    NOT_LIKE: 'NotLike',
    IS_NULL: 'IsNull',
    DOES_NOT_CONTAIN: 'DoesNotContain'
};

export const getComparatorSymbol = name => {
    switch (name) {
        case FieldComparatorTypes.NONE:
            return <FormattedMessage id="common.any" />;
        case FieldComparatorTypes.EQUALS:
            return '=';
        case FieldComparatorTypes.NOT_EQUAL:
            return <span>&#8800;</span>;
        case FieldComparatorTypes.GREATER:
            return '>';
        case FieldComparatorTypes.LESS:
            return '<';
        case FieldComparatorTypes.GREATER_EQUAL:
            return <span>&ge;</span>;
        case FieldComparatorTypes.LESS_EQUAL:
            return <span>&le;</span>;
        case FieldComparatorTypes.BETWEEN:
            return <FormattedMessage id="between" />;
        case FieldComparatorTypes.CONTAINS:
            return <FormattedMessage id="common.includes" />;
        case FieldComparatorTypes.IN:
            return <FormattedMessage id="common.isOneOf" />;
        case FieldComparatorTypes.LIKE:
            return <FormattedMessage id="common.contains" />;
        case FieldComparatorTypes.IS_NULL:
            return <FormattedMessage id="common.isEmpty" />;
        case FieldComparatorTypes.DOES_NOT_CONTAIN:
            return <FormattedMessage id="common.excludes" />;
        case FieldComparatorTypes.NOT_LIKE:
            return <FormattedMessage id="common.notContains" />;

        default:
            return '';
    }
};

export const getFilterShortText = (field, fieldType, comparator, value) => {
    if (fieldType === 'Date' && value !== null){
        if (Array.isArray(value)){
            value = value.map(d => moment(d).format('YYYY-MM-DD'));
        } else {
            value = moment(value).format('YYYY-MM-DD');
        }
    }
    if (fieldType === 'YesNo') {
        if (value) {
            value = 'Yes'
        } else if (value === null) {
            value = 'N/A'
        } else {
            value = 'No'
        }
    }
    const shortenedField = (field.length > 25) ?  field.slice(0, 22) + '...' : field;
    switch (comparator) {
        case FieldComparatorTypes.NONE:
            return <FormattedHTMLMessage id="filter.hasAnyValue" values={{ field: shortenedField }} tagName="span" />;
        case FieldComparatorTypes.EQUALS:
            return `${shortenedField} = ${value}`;
        case FieldComparatorTypes.NOT_EQUAL:
            return (
                <span>
                    {`${shortenedField} `}
                    <span>&#8800;</span>
                    {` ${value}`}
                </span>
            );
        case FieldComparatorTypes.GREATER:
            return `${shortenedField} > ${value}`;
        case FieldComparatorTypes.LESS:
            return `${shortenedField} < ${value}`;
        case FieldComparatorTypes.GREATER_EQUAL:
            return (
                <span>
                    {`${shortenedField} `}
                    <span>&ge;</span>
                    {` ${value}`}
                </span>
            );
        case FieldComparatorTypes.LESS_EQUAL:
            return (
                <span>
                    {`${shortenedField} `}
                    <span>&le;</span>
                    {` ${value}`}
                </span>
            );
        case FieldComparatorTypes.BETWEEN:
            const range = Array.isArray(value) ? value : [value, 'unknown'];
            return <FormattedHTMLMessage id="filter.between" values={{ field: shortenedField, value1: range[0], value2: range[1] }} tagName="span" />;
        case FieldComparatorTypes.CONTAINS:
            return <FormattedHTMLMessage id="filter.includes" values={{ field: shortenedField, values: Array.isArray(value) ? value.join(', ') : value }} tagName="span" />;
        case FieldComparatorTypes.DOES_NOT_CONTAIN:
            return <FormattedHTMLMessage id="filter.excludes" values={{ field: shortenedField, values: Array.isArray(value) ? value.join(', ') : value }} tagName="span" />;
        case FieldComparatorTypes.IN:
            return <FormattedHTMLMessage id="filter.isOneOf" values={{ field: shortenedField, values: Array.isArray(value) ? value.join(', ') : value }} tagName="span" />;
        case FieldComparatorTypes.LIKE:
            return <FormattedHTMLMessage id="filter.contains" values={{ field: shortenedField, value }} tagName="span" />;
        case FieldComparatorTypes.NOT_LIKE:
            return <FormattedHTMLMessage id="filter.notContains" values={{ field: shortenedField, value }} tagName="span" />;
        case FieldComparatorTypes.IS_NULL:
            return <FormattedHTMLMessage id="filter.isEmpty" values={{ field: shortenedField }} tagName="span" />;

        default:
            return '';
    }
};
