import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import Page from '../../../components/page';
import LotsTable from 'pages/lots/components/lotsTable';
import { history } from 'configuration/history';
import DashesTable from 'pages/events/components/dashesTable';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { formatDateFriendly } from 'utilities/common';
import { tooltipWrapper } from 'utilities/common'


class VisibilityList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'dots',
            selectMultipleDotTargetIds: [],
            selectMultipleDashTargetIds: [],
            isSelectingMultiple: false,
        }
    }

    renderActions = () => {
        const { isSelectingMultiple, selectMultipleDotTargetIds, selectMultipleDashTargetIds } = this.state;
        let buttonMessage = <FormattedMessage id="graphViewMultipleMessage" />;
        let buttonDisabled = false;
        if (isSelectingMultiple) {
            const selectedCount = selectMultipleDotTargetIds.length + selectMultipleDashTargetIds.length;
            if (selectedCount > 0) {
                buttonMessage = <FormattedMessage id="viewMultiple" values={{ count: selectedCount }} />;
            } else {
                buttonDisabled = true;
                buttonMessage = <FormattedMessage id="selectMultipleToView" />;
            }
        }
        const viewMultipleButton = (
            <span 
                className={'btn btn-outline-info' + (buttonDisabled ? ' disabled' : '')}
                onClick={() => {
                    if (buttonDisabled) return;
                    if (isSelectingMultiple) {
                        const entityArgs = { entities: [] };
                        selectMultipleDashTargetIds.forEach(dashId => entityArgs.entities.push({ entityType: 'dash', entityId: dashId }));
                        selectMultipleDotTargetIds.forEach(dashId => entityArgs.entities.push({ entityType: 'dot', entityId: dashId }));
                        const visualizationData = btoa(JSON.stringify(entityArgs));
                        history.push(`/visualize/${visualizationData}` + window.location.search)
                    } else {
                        this.setState(prevState => {
                            return { isSelectingMultiple: !prevState.isSelectingMultiple }
                        });
                    }
                }}
            >
                {  buttonMessage }
            </span>
        )
        let cancelButton = null;
        if (isSelectingMultiple) {
            cancelButton = (
                <span 
                    className="btn btn-outline-info" 
                    onClick={() => {
                        this.setState({ isSelectingMultiple: false, selectMultipleDotTargetIds: [], selectMultipleDashTargetIds: [] });
                    }
                }>
                    <FormattedMessage id="cancel" />
                </span>
            );
        }
        return (
            <div className="btn-group pd-t-10">
                { viewMultipleButton }
                { cancelButton }
            </div>
        );
        
    };


    toggleDashSelectMultipleTarget = id => {
        this.setState(prevState => {
            const targetIds = prevState.selectMultipleDashTargetIds;
            const index = targetIds.indexOf(id);
            if (index === -1) {
                targetIds.push(id);
            } else {
                targetIds.splice(index, 1);
            }
            return { selectMultipleDashTargetIds: targetIds };
        })
    }

    toggleDotSelectMultipleTarget = id => {
        this.setState(prevState => {
            const targetIds = prevState.selectMultipleDotTargetIds;
            const index = targetIds.indexOf(id);
            if (index === -1) {
                targetIds.push(id);
            } else {
                targetIds.splice(index, 1);
            }
            return { selectMultipleDotTargetIds: targetIds };
        })
    }

    // clearMultipleTargets
    clearMultipleDashTargets = ids => {
        this.setState(prevState => {
            const targetIds = prevState.selectMultipleDashTargetIds.filter(id => !ids.includes(id));
            return { selectMultipleDashTargetIds: targetIds };
        })
    }

    addMultipleDashTargets = ids => {
        this.setState(prevState => {
            const newIds = ids.filter(id => !prevState.selectMultipleDashTargetIds.includes(id));
            return { selectMultipleDashTargetIds: prevState.selectMultipleDashTargetIds.concat(newIds) };
        })
    }

    clearMultipleDotTargets = ids => {
        this.setState(prevState => {
            const targetIds = prevState.selectMultipleDotTargetIds.filter(id => !ids.includes(id));
            return { selectMultipleDotTargetIds: targetIds };
        })
    }

    addMultipleDotTargets = ids => {
        this.setState(prevState => {
            const newIds = ids.filter(id => !prevState.selectMultipleDotTargetIds.includes(id));
            return { selectMultipleDotTargetIds: prevState.selectMultipleDotTargetIds.concat(newIds) };
        })
    }

    render() {
        const { isSelectingMultiple, selectMultipleDotTargetIds, selectMultipleDashTargetIds } = this.state;
        const { locale, intl: { formatMessage } } = this.props;

        const dotViewColumn = {
            isDefault: true,
            headerClassName: 'd-sm-block col-2 d-none',
            className: 'd-xs-block text-center col-2',
            sortable: false,
            resizable: false,
            width: 34,
            id: 'view_dots',
            accessor: d => {
                return isSelectingMultiple ?
                (
                    <div className="actions-wrapper">
                        <input 
                            key={'select-dash-checkbox-' + d.id}
                            type="checkbox" 
                            checked={selectMultipleDotTargetIds && selectMultipleDotTargetIds.indexOf(d.id) > -1}  
                            onChange={() => this.toggleDotSelectMultipleTarget(d.id)}
                        />
                    </div>
                )
                :
                (
                    <i 
                        className={`icon ion-ios-eye-outline`} 
                        style={{ fontSize: 22, cursor: 'pointer', color: '#008bcc' }}
                        onClick={() => {
                            const visualizationData = btoa(JSON.stringify({ entities: [{ entityType: 'dot', entityId: d.id }] }));
                            history.push(`/visualize/${visualizationData}` + window.location.search)
                        }}
                    /> 
                )
            }
        };

        const dashViewColumn = {
            isDefault: true,
            headerClassName: 'd-sm-block col-2 d-none',
            className: 'd-xs-block text-center col-2',
            sortable: false,
            resizable: false,
            width: 34,
            id: 'view_dashes',
            accessor: d => {
                return isSelectingMultiple ?
                tooltipWrapper(
                    d.model ? d.model.contains_inaccessible_dots : false,
                    <FormattedMessage id='containsInaccessibleDotsMessage' values={{ entityType: formatMessage({ id: 'dash' }) }} />,
                    <div className="actions-wrapper">
                        <input 
                            key={'select-dash-checkbox-' + d.id}
                            type="checkbox" 
                            checked={selectMultipleDashTargetIds && selectMultipleDashTargetIds.indexOf(d.id) > -1}  
                            onChange={() => this.toggleDashSelectMultipleTarget(d.id)}
                            disabled={d.model ? d.model.contains_inaccessible_dots : false}
                        />
                    </div>
                )
                :
                tooltipWrapper(
                    d.model ? d.model.contains_inaccessible_dots : false,
                    <FormattedMessage id='containsInaccessibleDotsMessage' values={{ entityType: formatMessage({ id: 'dash' }) }} />,
                    <i 
                        className={`icon ion-ios-eye-outline ${d.model ? (d.model.contains_inaccessible_dots && 'disabled') : ''}`} 
                        style={{ fontSize: 22, cursor: !(d.model && d.model.contains_inaccessible_dots) && 'pointer', color: '#008bcc' }}
                        onClick={() => {
                            if (d.model && d.model.contains_inaccessible_dots) {
                                return;
                            }
                            const visualizationData = btoa(JSON.stringify({ entities: [{ entityType: 'dash', entityId: d.id }] }));
                            history.push(`/visualize/${visualizationData}` + window.location.search)
                        }}
                    /> 
                )
            }
        };

        const dashVisualizationColumns = [
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block',
                Header: <FormattedMessage id="lastViewed" />,
                id: 'last_visualized',
                accessor: d => formatDateFriendly(d.last_visualized ? new Date(d.last_visualized) : null, locale)
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block',
                Header: <FormattedMessage id="viewCount" />,
                id: 'visualized_count',
                accessor: d => d.visualized_count ? d.visualized_count : '0'
            },
        ]

        return (
            <Page {...VisibilityList.meta} actions={this.renderActions()}>
                <Tabs forceRenderTabPanel={true}>
                    <TabList>
                        <Tab><h4><FormattedMessage id='viewDots'/></h4></Tab>
                        <Tab><h4><FormattedMessage id='viewDashes'/></h4></Tab>
                    </TabList>
                
                    <TabPanel>
                        <LotsTable 
                            key='vizualization-lots-table' 
                            lockModal={true} 
                            useRootState={false} 
                            persistState={false}
                            includeLastVisited={true} 
                            prependColumns={[dotViewColumn]} 
                            hiddenColumns={['cargo_unit_name', 'created_by_dash_id', 'status_name', 'claimed_by_dash_id', 'modified_on']}
                            toggleSelectMultiTarget={this.toggleDotSelectMultipleTarget}
                            isSelectingMultiple={this.state.isSelectingMultiple}
                            defaultSort={'last_visualized'}
                            defaultSortDesc={true}
                            useDotTags={true}
                        />
                    </TabPanel>
                    <TabPanel>
                        <DashesTable 
                            key={'vizualization-dashes-table'}
                            useRootState={false} 
                            persistState={false}
                            selectMultipleTargetIds={this.state.selectMultipleDashTargetIds}
                            toggleSelectMultiTarget={this.toggleDashSelectMultipleTarget}
                            isSelectingMultiple={this.state.isSelectingMultiple}
                            clearMultipleTargets={this.clearMultipleDashTargets}
                            selectMultipleTargets={this.addMultipleDashTargets}
                            prependColumns={[dashViewColumn]} 
                            appendColumns={dashVisualizationColumns}
                            hiddenColumns={['actions', 'status_name', 'modified_on', 'user_name']}
                            includeLastVisited={true} 
                            defaultSort={'last_visualized'}
                            defaultSortDesc={true}
                            useDashTags={true}
                        />
                    </TabPanel>
                </Tabs>
            </Page>
        );
        
    }
}

VisibilityList.meta = {
    title: <FormattedMessage id="visualize" />,
    subtitle: <FormattedMessage id="visualizeSubtitle" />,
    routes: ['/visualize'],
    icon: 'ion-ios-eye-outline',
    menus: { main: true, user: false },
    order: 10
};

const mapStateToProps = state => ({
    locale: state.localization.locale,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(VisibilityList));