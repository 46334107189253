import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'formik';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { RowBreak, FieldDescription } from './common';
import { SUPPORTED_CURRENCIES } from '../../../../utilities/constants';
import Tooltip from 'rc-tooltip';

class CurrencyField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { id, width, breakRow, title, currency, disabled } = this.props;
        const widthClass = width ? `-${width}` : '';
        return (
            <React.Fragment>
                <div className={`pd-b-15 col${widthClass}`} style={{ display: 'inline-block' }}>
                    <div className="field-currency">
                        <Field
                            name={id}
                            render={({ field }) => {
                                const amount =
                                    field.value === undefined || field.value === null || field.value === '' ? parseFloat('0').toFixed(2) : field.value;

                                return (
                                    <div className="currency-group">
                                        <span className="input-group-addon start">
                                            {
                                                (
                                                    SUPPORTED_CURRENCIES.filter(c => c.AlphabeticCode !== '').find(c => c.AlphabeticCode === currency) ||
                                                    SUPPORTED_CURRENCIES[0]
                                                ).AlphabeticCode
                                            }
                                        </span>
                                        <MaskedInput
                                            className="form-control"
                                            mask={createNumberMask({
                                                prefix: '',
                                                suffix: '',
                                                includeThousandsSeparator: true,
                                                requireDecimal: true,
                                                decimalLimit: 2
                                            })}
                                            {...field}
                                            value={amount}
                                            placeholder={title}
                                            disabled={disabled}
                                        />
                                        <Tooltip trigger={['hover']} overlay={<span>{title}</span>} mouseEnterDelay={2}>
                                            <div className="input-placeholder">
                                                {title}
                                            </div> 
                                        </Tooltip>
                                    </div>
                                );
                            }}
                        />
                        <FieldDescription {...this.props} />
                    </div>
                    {this.props.showIsRequired && String(currency).length === 0 && <div className="validation-message"><FormattedMessage id="validation.required" /></div>}
                </div>
                {breakRow && <RowBreak width={width} />}
            </React.Fragment>
        );
    }
}

export default CurrencyField;
