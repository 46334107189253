import React from 'react';
import { injectIntl } from 'react-intl';
import { Field, ErrorMessage } from 'formik';
import RichTextEditor from './richTextEditor';

import './fields.scss';

class RichTextField extends React.Component {
    constructor(props) {
        super(props);
        this.renderEditor = this.renderEditor.bind(this);
    }

    renderEditor = fieldProps => {
        const {
            name,
            intl: { formatMessage }
        } = this.props;
        return <RichTextEditor {...this.props} placeholder={formatMessage({ id: `${name}.placeholder` })} fieldProps={fieldProps} />;
    };

    render() {
        const { name } = this.props;

        return (
            <React.Fragment>
                <Field {...this.props} render={this.renderEditor} />
                <ErrorMessage name={name} component="div" className="validation-message" />
            </React.Fragment>
        );
    }
}

export default injectIntl(RichTextField);
