export const MEDIA_UPLOAD = `
    mutation M {
        media_upload {
            id
            created_on
            type_id
            type_name
            content_type
            is_ready
            user_file_name

            blob {
                bucket
                key
                size
                mime
                last_modified
                url
            }
        }
    }
`;
