import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Creatable } from 'react-select';

import '../fields.scss';
import Tooltip from 'rc-tooltip';

class CreatableSelectControl extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        }
    }

    handleCreate = async (inputValue) => {
        await new Promise(async resolve => {
            this.setState({ isLoading: true }, async () => {
                const {
                    onChange = () => null,
                    onCreateOption = () => null,
                } = this.props;
                const newOption = await onCreateOption(inputValue);
                if (newOption) {
                    await onChange(this.props.id || null, newOption);
                }
                this.setState({ isLoading: false }, resolve);
            });
        });
    };

    render() {
        const {
            intl: { formatMessage },
            multi,
            options,
            name,
            value,
            placeholder,
            disabled = false,
            onChange = () => null,
            isClearable = false,
            showIsRequired,
            width,
            removePaddingRight,
        } = this.props;

        const { isLoading } = this.state;
        
        const customStyles = {
            control: base => ({
                ...base,
                boxShadow: 'none'
            }),
            multiValueLabel: (provided, state) => ({
                ...provided,
                maxWidth: '10em'
            }),
            menu: (provided, state) => ({
                ...provided,
                zIndex: '20'
            }),
            menuList: (provided, state) => ({
                ...provided,
                maxHeight: '200px'
            }),
        };

        const showLabel = !!value && (this.props.showLabel !== false);

        return (
            <React.Fragment>
                <div className={`pd-b-15 ${removePaddingRight ? 'pd-r-0' : ''} col-${width ? width : 12}`} style={{ display: 'inline-block'}}>
                    <div className="field-select ">
                        <Creatable
                            className="select-input-field"
                            placeholder={placeholder || formatMessage({ id: `${name}.placeholder` })}
                            name={name}
                            options={options}
                            isLoading={isLoading}
                            isDisabled={disabled || isLoading}
                            onCreateOption={this.handleCreate}
                            onChange={onChange}
                            value={value}
                            isMulti={multi}
                            isClearable={isClearable}
                            components={{ IndicatorSeparator: () => null }}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary25: '#eeeeee',
                                    primary: '#00336B'
                                }
                            })}
                            styles={customStyles}
                            menuPlacement={this.props.menuPlacement || 'auto'}
                        />
                        {!!showLabel && 
                            <Tooltip trigger={['hover']} overlay={<span>{placeholder || formatMessage({ id: `${name}.placeholder` })}</span>} mouseEnterDelay={2}>
                                <div className="select-input-placeholder">{placeholder || formatMessage({ id: `${name}.placeholder` })}</div>
                            </Tooltip>
                        }
                        {showIsRequired && !value && <div className="validation-message select-input-placeholder"><FormattedMessage id="validation.required" /></div>}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default injectIntl(CreatableSelectControl);
