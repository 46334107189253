import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Fields } from 'components/fieldsControlled';
import { history } from '../../../configuration/history';
import Field, { FieldTypes } from '../../../models/field';
import FieldPreview from '../../models/fields/components/fieldPreview';
import TransactionHeading from './heading';
import CargoQuantityField from 'pages/events/components/cargoQuantityField';
import CargoUnitField from 'pages/events/components/cargoUnitForm';

import './../../models/models.scss';
import { getGroupedSections } from 'pages/events/eventUtils'
import { tooltipWrapper } from 'utilities/common'
import { getTextMaxLength } from '../../../utilities/constants';

export default function Event(props) {
    const { 
        transaction, 
        company, 
        comparators, 
        hideHeading = false, 
        hideTitleAndDescription = false, 
        cargoCategories,
        formProps,
        getCargoFieldPreview,
        cargoTypes,
        eventCargoCategories,
        transactionIsUpdating,
        onFinalSaveClicked,
        onReviseClicked,
        showSaveButton,
        showCloseButton,
        readOnly = false,
        dot = null,
        fieldMappings = [],
        onChangeExt,
        primaryButton,
        onUseMappedValueToggle,
        fieldMappingUsages,
        fieldsMappingToLockedDots,
        FieldPreviewEl,
        fieldPreviewProps,
        onUseUserTransformValueToggle,
        extendedProps,
    } = props;
    let { showQuantityInfo } = props;
    const { model } = transaction;
    const fields = model.model_field_list.map(f => {
        f.use_mapped_value = fieldMappingUsages[f.id] === undefined ? true : fieldMappingUsages[f.id];
        return f;
    });
    const groupedFields = getGroupedSections(fields, fieldMappings, fields, eventCargoCategories, fieldsMappingToLockedDots);

    let saveButton = showSaveButton ? 
        <div className="col-3" id='save-final-btn'>
            {
                (transaction.status_id === 2 || transaction.status_id === 3) ?
                <button 
                    type="button" 
                    className="btn btn-primary btn-block" 
                    disabled={readOnly} 
                    onClick={() => onReviseClicked(formProps)}
                >
                    <FormattedMessage id="revise" />
                </button>
                :
                <button 
                    type="button" 
                    className="btn btn-primary btn-block" 
                    disabled={(transaction.status_id !== 1 && transaction.status_id !== 7) || transactionIsUpdating || readOnly} 
                    onClick={() => onFinalSaveClicked(formProps)}
                >
                    <FormattedMessage id="common.saveFinal" />
                </button>
            }
        </div>
        :
        null;

    if (primaryButton) {
        saveButton = primaryButton;
    }

    if(!!dot) {  
        showQuantityInfo = true;
    }

    const { setFieldValue } = formProps;

    let unitBackgroundColor='';
    if ( extendedProps ){
        if( readOnly ) {
            const { unitFieldBackgroundColor } = extendedProps;
            unitBackgroundColor= unitFieldBackgroundColor;
        }
    }

    return (
        <div className="field-preview-wrapper hide-multi-br">
            {hideHeading === true ? 
                <div className="model-properties-preview col-12">
                    <div className="row mg-0">
                        <div className="col-12 pd-15 tx-center">
                        </div>
                    </div>
                    <div className="row mg-0 pd-t-20 pd-x-60">
                        <h4 className="model-properties-preview-title">{model.title}</h4>
                    </div>
                </div>
                :
                <TransactionHeading model={model} company={company} id={model.id} />
            }
            <div className="pd-x-60">
                {hideTitleAndDescription === true || 
                    <React.Fragment>
                        <div className={`field-text pd-b-15 col-12`}>                            
                            <Fields.Input 
                                id='transaction-title' 
                                disabled={(transaction.status_id !== 1 && transaction.status_id !== 7) || readOnly} 
                                name="title" 
                                maxLength={getTextMaxLength()} 
                                setFieldValue={setFieldValue}
                                initialValue={transaction.title} 
                            />
                        </div>
                    </React.Fragment>}
                {groupedFields.map(f => {
                    f.onChangeExt = onChangeExt;
                    if (f.type === FieldTypes.FIELD_CARGO) {
                        const decoratedFields = fields.map(df => { 
                            df.onChangeExt = onChangeExt; 
                            return df; 
                        });
                        const el = getCargoFieldPreview(f, transaction, cargoTypes, cargoCategories, formProps, comparators, decoratedFields, eventCargoCategories, readOnly, extendedProps);
                        return el;
                    } else {
                        const sharedProps = {
                            key: f.id,
                            field: f,
                            fields: fields.map(f => {
                                const mappedField = Field.fromDB(f);
                                if (f.hiddenField) mappedField.hiddenField = true;
                                mappedField.onChangeExt = onChangeExt;
                                mappedField.isDotField = f.isDotField;
                                mappedField.dash_field_id = f.dash_field_id;
                                if (fieldsMappingToLockedDots && fieldsMappingToLockedDots.length > 0) {
                                    mappedField.locked_from_revise = fieldsMappingToLockedDots.find(ld => mappedField.isDotField ? (ld.dash_field === mappedField.dash_field_id && ld.dot_field === mappedField.id) : (ld.dash_field === mappedField.id)) !== undefined;
                                }
                                let correspondingMapping;
                                if (f.isDotField) {
                                    correspondingMapping = fieldMappings.find(fm => fm.map_dash_field_id === f.dash_field_id && fm.map_dot_field_id === f.id);
                                } else {
                                    correspondingMapping = fieldMappings.find(fm => fm.map_dash_field_id === f.id);
                                }
                                mappedField.isMappedField = !!correspondingMapping;
                                return mappedField;
                            }),
                            formProps: formProps,
                            comparators: comparators,
                            modelTypeId: 1,
                            showIsRequired: f.is_required,
                            disabled: (transaction.status_id !== 1 && transaction.status_id !== 7) || readOnly,
                            onChangeExt: onChangeExt,
                            onUseMappedValueToggle: onUseMappedValueToggle,
                            fieldMappingUsages: fieldMappingUsages,
                            onUseUserTransformValueToggle: onUseUserTransformValueToggle,
                            extendedProps: extendedProps
                        }
                        if (!!FieldPreviewEl) {
                            return <FieldPreviewEl {...sharedProps} {...fieldPreviewProps} />;
                        } else {
                            return <FieldPreview {...sharedProps} />;
                        }
                    }
                })}
                { 
                    showQuantityInfo && (
                        <div className='row'>
                            <div className={`pd-b-15 col-4 offset-2`}>
                                <CargoQuantityField
                                    disabled={true}
                                    onQuantitySelected={() => null}
                                    initialTargetQuantity={ dot ? dot.original_quantity : '' }
                                />
                            </div>
                            <CargoUnitField 
                                disabled={true}
                                onCargoUnitSelected={() => null}
                                initialTargetCargoUnit={ dot ? dot.cargo_unit_id : '' }
                                width={4}
                                backgroundColor={unitBackgroundColor}
                            />
                        </div>
                    )
                }
            </div>
            <div className="col-12 mg-b-60">
                <div className="row d-flex justify-content-center align-items-center pd-t-15">
                    { saveButton }
                    { showCloseButton && 
                        <div className='col-2'>
                            <button 
                                id='close-tx-btn'
                                type="button" 
                                disabled={transactionIsUpdating} 
                                className="btn btn-secondary btn-block" 
                                onClick={props.onCancel === undefined ?  () => history.push('/events' + window.location.search) : props.onCancel}
                            >
                                <FormattedMessage id="common.close" />
                            </button>
                        </div>
                    }
                    </div>
            </div>
        </div>
    )
}

