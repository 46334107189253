import { getElement } from '@syncfusion/ej2-svg-base';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { DataStorage } from '../../utilities/storage';
import { canAccessPage, canInteract } from './permissions/permissionResolver';

export const AuthenticatedRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        strict={false}
        render={props => {
            const { authenticated, permissions, entityPermissions, token } = rest;

            if (!authenticated) {
                DataStorage.clearAll();
                return <Redirect to={{ pathname: '/login', search: window.location.search }} />;
            } else {
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                const hidefc = urlParams.get('hidefc');
                if (hidefc === 'true') {
                    const hideWidget = () => {
                        const fcWidget = document.getElementById('fc_widget');
                        if (fcWidget) {
                            fcWidget.style.display = 'none';
                        }
                    }
                    setTimeout(hideWidget, 1000);
                    setTimeout(hideWidget, 2000);
                    setTimeout(hideWidget, 5000);
                } else {
                    if (window.fcWidget) {
                        window.fcWidget.setTags(["logged"]); 
                        setTimeout(() => window.fcWidget.setTags(["logged"]), 2000);
                        setTimeout(() => window.fcWidget.setTags(["logged"]), 5000);
                    } else {
                        setTimeout(() => window.fcWidget && window.fcWidget.setTags(["logged"]), 1000);
                        setTimeout(() => window.fcWidget && window.fcWidget.setTags(["logged"]), 2000);
                        setTimeout(() => window.fcWidget && window.fcWidget.setTags(["logged"]), 5000);
                    }
                }
            }

            if (token.data.requires_mfa && !token.data.mfa_passed) {
                return <Redirect to={{ pathname: '/mfa', search: window.location.search }} />;
            }


            if (authenticated && props.location.pathname === '/') {
                return <Redirect to={{ pathname: '/dashboard', state: { from: props.location }, search: window.location.search }} />;
            }

            const canAccess = canAccessPage(permissions, Component.meta);
            if (!canAccess) {
                return <Redirect to={{ pathname: '/dashboard', search: window.location.search }} />;
            }

            if (!!Component.meta && !!Component.meta.requires) {
                const can = canInteract(entityPermissions, Component.meta.requires);
                if (!can) {
                    return <Redirect to={{ pathname: '/dashboard', search: window.location.search }} />;
                }
            }

            return <Component {...props} {...rest} />;
        }}
    />
);

export const AnonymousRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            const { authenticated, exclusively } = rest;
            if (authenticated) {
                if (window.fcWidget) {
                    window.fcWidget.setTags(["logged"]); 
                    setTimeout(() => window.fcWidget.setTags(["logged"]), 2000);
                    setTimeout(() => window.fcWidget.setTags(["logged"]), 5000);
                } else {
                    setTimeout(() => window.fcWidget && window.fcWidget.setTags(["logged"]), 1000);
                    setTimeout(() => window.fcWidget && window.fcWidget.setTags(["logged"]), 2000);
                    setTimeout(() => window.fcWidget && window.fcWidget.setTags(["logged"]), 5000);
                }
            }

            if (exclusively && authenticated) {
                return <Redirect to={{ pathname: '/dashboard', state: { from: props.location }, search: window.location.search }} />;
            }

            return <Component {...props} {...rest} />;
        }}
    />
);
