import React from 'react';

class FieldValueListItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { item, dragHandleProps } = this.props;
        if (!item) return null;

        const { id, value, deleteValue } = item;

        return (
            <div className="existing-value" {...dragHandleProps}>
                <div className="title ellipsis" title={value}>
                    {value}
                </div>
                <button className="btn btn-warning icon-button field-inline float-right" type="button" onClick={() => deleteValue(id)}>
                    <i className="material-icons-outlined">remove</i>
                </button>
            </div>
        );
    }
}

export default FieldValueListItem;
