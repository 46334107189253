import React from 'react';
import { FormattedMessage } from 'react-intl';
import { call, hasErrors } from '../configuration/api';
import { Notifications } from '../components/notifications';
import { FIELD_VISIBILITY_GET_OPERATORS, FIELD_VISIBILITY_GET_INDEX_VALUES, FIELD_VISIBILITY_GET_FIELD_VALUES, FIELD_VISIBILITY_SET_DIALOG } from './types';
import Queries from './queries';

const defaultState = {
    comparators: undefined,
    indexValues: {},
    fieldValues: {},
    dialog: undefined
};

/**
 * Loading state mapper.
 * @param {object} state
 * @param {string} action
 */
export default (state = defaultState, action) => {
    switch (action.type) {
        case FIELD_VISIBILITY_GET_OPERATORS:
            return { ...state, comparators: action.payload };

        case FIELD_VISIBILITY_GET_INDEX_VALUES:
            const indexValues = { ...state.indexValues };
            indexValues[action.field_id] = [...action.payload];
            return { ...state, indexValues };

        case FIELD_VISIBILITY_GET_FIELD_VALUES:
            const fieldValues = { ...state.fieldValues };
            fieldValues[action.field_id] = [...action.payload];
            return { ...state, fieldValues };

        case FIELD_VISIBILITY_SET_DIALOG:
            return { ...state, dialog: action.payload };

        default:
            return state;
    }
}

export const getComparators = (onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.FieldVisibility.Operators });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.comparator_list) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    dispatch({ type: FIELD_VISIBILITY_GET_OPERATORS, payload: data.comparator_list });
    onComplete(data.comparator_list);
};

export const getIndexValues = (index_field_id, search = '', onComplete = () => null, formatter = () => null) => async dispatch => {
    const response = await call({ query: Queries.FieldVisibility.IndexValues, variables: { index_field_id, search, limit: 100 } });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.model_field_value_list_by_index || !data.model_field_value_list_by_index.data) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }
    
    data.model_field_value_list_by_index.data.sort((a, b) => {
        if (a.field_id === b.field_id) {
            return a.order_by - b.order_by;
        } else {
            return a.field_id - b.field_id;
        }
    });

    dispatch({ type: FIELD_VISIBILITY_GET_INDEX_VALUES, payload: data.model_field_value_list_by_index.data, field_id: index_field_id });
    onComplete(data.model_field_value_list_by_index.data);

    if (formatter) {
        return formatter(data.model_field_value_list_by_index.data);
    }
};

export const getFieldValues = (field_id, search = '', onComplete = () => null, formatter = () => null) => async dispatch => {
    const response = await call({ query: Queries.FieldVisibility.FieldValues, variables: { field_id, search, limit: 100 } });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.model_field_value_list || !data.model_field_value_list.data) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    dispatch({ type: FIELD_VISIBILITY_GET_FIELD_VALUES, payload: data.model_field_value_list.data, field_id: field_id });
    onComplete(data.model_field_value_list.data);

    if (formatter) {
        return formatter(data.model_field_value_list.data);
    }
};

export const setVisibilityDialog = field => async dispatch => {
    dispatch({ type: FIELD_VISIBILITY_SET_DIALOG, payload: field });
};
