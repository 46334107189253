export const COMPANIES_GET_INVITE_RESPONSES = `
    query($company_ids: [Int!], $status_ids: [Int!], $country_ids: [Int!], $search: String, $order_by: [OrderBy], $offset: Int, $limit: Int) {
        company_list_prescreen(
            input: { company_ids: $company_ids, status_ids: $status_ids, country_ids: $country_ids, search: $search }
            paging: { order_by: $order_by, offset: $offset, limit: $limit }
        ) {
            total
            data {
                id
                status_id
                status_name
                created_on
                modified_on
                code
                company_name
                lang_id
                lang
                country_id
                country_code2
                country_code3
                custom_domain
                description
                website
                email
                signature
                city
                state
                address_1
                address_2
                zip
                phone1
                phone2
                fax

                branding_config
                default_prefs
                extra_info

                is_system

                stripe_customer_id
                is_paid
                payment_source
                subscription_id
                subscription_status
                subscription_type
                last_payment_check
                subscription_seats
                plan_id

                # Additional fields that may vary from record to record
                aux {
                    name
                    value
                }

                screen_state

                temp_user {
                    email
                    name
                    phone
                    company_id
                }
            }
        }
    }
`;
