export const TRANSACTION_FIELD_SET_LOTS = `
    mutation M(
        $field_id: Long!
        $input: JsonString!
    ) {
        transaction_field_set_lots(
            field_id: $field_id
            input: $input
        ) {
            id
            status_id
            status_name
            model_id
            company_id

            created_on
            user_id
            user_name
            user_email
            modified_on
            modified_by_user_id
            modified_by_user_name
            modified_by_user_email
            source_cargo_id
            result_cargo_id
            title
            description
            notes

            #bc_address
            bc_transaction_id
            extra_info
    		
    		transaction_type
    		transaction_type_name

            history {
                id
                created_on
                accessed_on
                processed_on
                locked_on
                locked_by
                company_id
                user_id
                status_id
                status_name
                type_id
                type_name
                operation_id
                operation_name
                object_id
                bc_batch_id
                bc_block_id
                bc_transaction_id
            }
        }
    }
`;
