import React from 'react';
import ReactModal from 'react-modal';

import './print.scss';

class PrintContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { open, onClose = () => null, children } = this.props;
        if (!open) return null;

        return (
            <React.Fragment>
                <style>{`body #root {display: none;} body .ReactModalPortal {display: block;}`}</style>
                <ReactModal isOpen={open} onRequestClose={onClose} className="modal-block print-modal" overlayClassName="modal-overlay print-modal">
                    {children}
                </ReactModal>
            </React.Fragment>
        );
    }
}

export default PrintContainer;
