import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import Tooltip from 'rc-tooltip';
import SimpleBar from 'simplebar-react';
import DraggableList from 'react-draggable-list';
import DocumentListItem from './documentListItem';
import { DocumentSections, DocumentRichText } from '../../../../../models/document';
import { selectItem, setItems } from '../../../../../reducers/document';

class DocumentConfiguratorItems extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        const { template, model, fields, selectItem } = this.props;
        if (!template || !model || !fields) return null;
        const item = template[DocumentSections.HEADER].length > 0 ? template[DocumentSections.HEADER][0] : undefined;
        if (!!item && !!item.ref_id) {
            selectItem(DocumentSections.HEADER, item.ref_id);
        }
    }

    removeElement = (section, item) => {
        const { template, setItems, selectItem } = this.props;
        selectItem(section, undefined, () => setItems(section, template[section].filter(d => d.ref_id !== item)));
    };

    render() {
        const { is_used = false } = this.state;
        const { template, model, fields, selected, selectItem, setItems } = this.props;
        if (!template || !model || !fields) return null;

        return (
            <div className="widget-2 mg-b-20 doc-t-items">
                <div className="card overflow-hidden">
                    <div className="card-header">
                        <h6 className="card-title">
                            <FormattedMessage id="common.items" /> {template && template[selected.section] ? `(${template[selected.section].length})` : null}
                        </h6>
                        <div className="btn-group field-list-buttons">
                            <span
                                className={`btn ${!!selected.item && !is_used ? '' : 'disabled'}`}
                                onClick={() => (is_used ? null : this.removeElement(selected.section, selected.item))}
                            >
                                <Tooltip placement={'top'} trigger={['hover']} overlay={<FormattedMessage id="models.fields.removeField" />}>
                                    <i className="material-icons">remove</i>
                                </Tooltip>
                            </span>
                            <span
                                className={`btn ${is_used ? 'disabled' : ''}`}
                                onClick={() => {
                                    if (is_used) return;
                                    const newItem = { ...new DocumentRichText(), order: template[selected.section].length };
                                    setItems(selected.section, [...template[selected.section], newItem], () => selectItem(selected.section, newItem.ref_id));
                                }}
                            >
                                <Tooltip placement={'top'} trigger={['hover']} overlay={<FormattedMessage id="models.fields.addField" />}>
                                    <i className="material-icons">add</i>
                                </Tooltip>
                            </span>
                        </div>
                    </div>
                    <div className="card-body pd-0 bd-color-gray-lighter">
                        <div className="row no-gutters">
                            <div className="col-12">
                                <SimpleBar>
                                    {template[selected.section] && template[selected.section].length > 0 ? (
                                        <div className="draggable-area" ref={el => (this.draggable = el)}>
                                            <DraggableList
                                                itemKey="ref_id"
                                                template={DocumentListItem}
                                                padding={0}
                                                springConfig={{ stiffness: 300, damping: 50, className: 'xyz' }}
                                                list={template[selected.section]
                                                    .map(i => ({
                                                        ...i,
                                                        isSelected: () => i.ref_id === selected.item,
                                                        onSelect: () => selectItem(selected.section, i.ref_id)
                                                    }))
                                                    .sort((a, b) => a.order - b.order)}
                                                onMoveEnd={newList =>
                                                    is_used ? null : setItems(selected.section, newList.map((item, index) => ({ ...item, order: index })))
                                                }
                                                container={() => this.draggable}
                                            />
                                        </div>
                                    ) : (
                                        <div className="pd-20 tx-center tx-12">
                                            <FormattedMessage id="page.models.document.configuration.noItems" />
                                        </div>
                                    )}
                                </SimpleBar>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer tx-12 mg-0 pd-0 bg-transparent">
                        <div className="row mg-0">
                            <span
                                className={`col footer-link ${selected.section === DocumentSections.HEADER ? 'active' : ''}`}
                                onClick={() => selectItem(DocumentSections.HEADER)}
                            >
                                <FormattedMessage id="common.header" />
                            </span>
                            <span
                                className={`col footer-link ${selected.section === DocumentSections.BODY ? 'active' : ''}`}
                                onClick={() => selectItem(DocumentSections.BODY)}
                            >
                                <FormattedMessage id="common.body" />
                            </span>
                            <span
                                className={`col footer-link ${selected.section === DocumentSections.FOOTER ? 'active' : ''}`}
                                onClick={() => selectItem(DocumentSections.FOOTER)}
                            >
                                <FormattedMessage id="common.footer" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    company: state.identity.company,
    model: state.fields.model,
    fields: state.fields.items,
    template: state.document.instance,
    selected: state.document.selected
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            selectItem,
            setItems
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DocumentConfiguratorItems);
