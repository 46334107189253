export const TRANSACTIONS_LIST = `
    query(
        $status_ids: [Int!]
        $model_ids: [Int!]
        $source_cargo_ids: [Long!]
        $result_cargo_ids: [Long!]
        $created_from: DateTime
        $created_to: DateTime
        $search: String
        $order_by: [OrderBy]
        $offset: Int
        $limit: Int
        $transaction_type: Int
    ) {
        transaction_list(
            input: {
                status_ids: $status_ids
                model_ids: $model_ids
                source_cargo_ids: $source_cargo_ids
                result_cargo_ids: $result_cargo_ids
                created_from: $created_from
                created_to: $created_to
                search: $search
                transaction_type: $transaction_type
            }
            paging: { order_by: $order_by, offset: $offset, limit: $limit }
        ) {
            total
            data {
                id
                status_id
                status_name
                model_id
                company_id
                user_id
                created_on
                modified_on
                source_cargo_id
                result_cargo_id
                title
                description
                notes
                bc_transaction_id
                extra_info
                
                transaction_type
                transaction_type_name

                model {
                    id
                    title
                    company_id
                    description
                    contains_inaccessible_dots
                }

                history {
                    id
                    created_on
                    accessed_on
                    processed_on
                    locked_on
                    locked_by
                    company_id
                    user_id
                    status_id
                    status_name
                    type_id
                    type_name
                    operation_id
                    operation_name
                    object_id
                    bc_batch_id
                    bc_block_id
                    bc_transaction_id
                }

                transaction_field_list {
                    id
                    transaction_id
                    field_id
                    value
                    company_id: model_company_id
                    order_by: field_order_by
                    lot_id
                    quantity
                    cargo_unit_id
                }
            }
        }
    }
`;
