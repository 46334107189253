export const PAYMENT_LIST_PLANS = `
    query {
        stripe_plan_list {
            id
       		nickname
       		product_id
       		interval
       		price_cents
            currency
        }
    }
`;
