export const FIELD_VISIBILITY_FIELD_VALUES = `
    query($field_id: Long!, $show_hidden: Boolean, $search: String, $order_by: [OrderBy], $offset: Int, $limit: Int) {
        model_field_value_list(
            input: { field_id: $field_id, show_hidden: $show_hidden, search: $search }
            paging: { order_by: $order_by, offset: $offset, limit: $limit }
        ) {
            total
            data {
                id
                company_id
                field_id
                order_by
                is_hidden
                value
                field_model_id
                field_model_title
            }
        }
    }
`;
