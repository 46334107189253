import React from 'react';
import { ErrorMessage, Field } from 'formik';
import { RowBreak, FieldDescription } from './common';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { uploadMedia, getMedia, deleteMedia } from '../../../../reducers/media';
import Dropzone from 'react-dropzone';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Notifications } from '../../../../components/notifications';
import Tooltip from 'rc-tooltip';
import ReactModal from 'react-modal';
import SimpleBar from 'simplebar-react';

class AttachmentField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mediaId: undefined,
            confirmDeleteModalOpen: false,
            attachForm: null,
            attachMedia: null
        };
    }

    handleUpload = (files, form) => {
        const { id, uploadMedia, title, onChangeExt = () => null } = this.props;
        uploadMedia(files, async media => {
            await form.setFieldValue(id, JSON.stringify(media));
            onChangeExt(id, JSON.stringify(media));
            await form.submitForm();
            Notifications.success(<FormattedMessage id="common.fileUploaded" values={{ title }} />);
        });
    };

    handleClick = (media) => {
        const { getMedia } = this.props;
        if (!media.id) {
            return;
        }
        getMedia(media.id, ({ blob: { url } = {} } = {}) => {
            if (!!url) {
                window.open(url, '_blank');
            }
        });
    };

    handleClickDelete = (media, form) =>  {
        this.setState({ 
            attachForm: form,
            attachMedia: media,
            confirmDeleteModalOpen: true
        });
    };
    
    terminateDelete = () => {
        this.setState({ 
            attachForm: null,
            attachMedia: null,
            confirmDeleteModalOpen: false
        });
    }

    executeAttachmentDelete = () => {
        const { id, deleteMedia, onChangeExt = () => null } = this.props;
        const { attachMedia, attachForm } = this.state;
        if (!attachMedia.id) {
            this.terminateDelete();
            return;
        }        
        deleteMedia(attachMedia.id, async ({ user_file_name } = {}) => {
            await attachForm.setFieldValue(id, null);
            onChangeExt(id, null);
            await attachForm.submitForm();
            if (!!user_file_name) {
                this.terminateDelete();
                Notifications.success(<FormattedMessage id="common.attachmentDeleted" values={{ user_file_name }} />);
            }
        }); 
    }

    confirmDeleteModal = () => {
        return (
            <ReactModal
                isOpen={this.state.confirmDeleteModalOpen}
                onRequestClose={() => this.setState({ confirmDeleteModalOpen: false })}
                className="modal-block dialog"
                overlayClassName="modal-overlay gray"
            >
                <div role="document">
                    <div className="modal-content  bd-0">
                        <div className="modal-header pd-x-20">
                            <FormattedMessage id="common.confirmDelete" />
                        </div>
                        <SimpleBar> 
                            <div className="modal-body">
                                <FormattedMessage id="common.confirmAttachmentDelete" values={ !!this.state.attachMedia ? { user_file_name: this.state.attachMedia.user_file_name }: {user_file_name: ''} } />
                            </div>
                        </SimpleBar>
                        <div className="modal-footer justify-content-center">
                            <div className="row d-flex justify-content-center align-items-center">
                                <div className={"col-6"}>
                                    <button
                                        id='se-save-final-modal-button'
                                        type="button"
                                        className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                        onClick={this.executeAttachmentDelete}
                                    >
                                        <FormattedMessage id="common.verb.delete" />
                                    </button>
                                </div>
                                <div className={"col-6"}>
                                    <button
                                        type="button"
                                        className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                        onClick={this.terminateDelete}
                                    >
                                        <FormattedMessage id="common.cancel" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ReactModal>
        )
    }

    render() {
        const { id, width, breakRow, multiple, title, accept, disabled, showError=false, prependEls = [], tooltipOverlay, appendEls = [], seleniumId } = this.props;
        const widthClass = width ? `-${width}` : '';

        return (
            <React.Fragment>
                <div className={`pd-b-15 col${widthClass}`} style={{ display: 'inline-block' }}>
                    {[...prependEls]}
                    <div className="field-text">
                    <Field
                        {...this.props}
                        render={({ form }) => {
                            let value = undefined;
                            if (id.indexOf('.') > -1) {
                                const idParts = id.split('.');
                                const cargoValues = form.values[idParts[0]];
                                if (cargoValues) {
                                    value = cargoValues[idParts[1]];
                                }
                            } else {
                                value = form.values[id];
                            }
                            let isJsonValue = false;
                            let mediaValue = null;
                            if (value) {
                                try {
                                    mediaValue = JSON.parse(value);
                                    if (mediaValue.hasOwnProperty('id')) {
                                        isJsonValue = true;
                                    }
                                } catch {}
                            }
                            let fieldEl;
                            if (isJsonValue) {
                                fieldEl = (
                                    <div className={`attachment-uploaded se-id-${seleniumId}`}>
                                        <div className="row">
                                            <div className="col">
                                                <span className="download-button" style={{textOverflow: 'ellipsis', overflow: 'hidden', display: 'block', whiteSpace: 'nowrap', paddingRight: '5em'}} onClick={() => this.handleClick(mediaValue)}>
                                                    <i className="material-icons-outlined">cloud_done</i>
                                                    <FormattedHTMLMessage id="common.downloadFile" values={{ title, file_name: mediaValue.user_file_name }} tagName={'span'} />
                                                </span>
                                                <Dropzone multiple={false} accept={accept} onDrop={files => this.handleUpload(files, form)} disabled={disabled} className={`se-id-${seleniumId}`}>
                                                    {({ getRootProps, getInputProps }) => (
                                                        <>
                                                            <span {...getRootProps()} className={(appendEls.length > 0 ? 'replace-attachment-with-append ' : 'replace-attachment ') + (disabled ? 'disabled' : '')} disabled={disabled} >
                                                                <FormattedMessage id="common.replace" />
                                                                <input style={{overflow: 'hidden'}} {...getInputProps()} name={'upload_ignore'} />
                                                            </span>
                                                            <div style={{ position: 'absolute', right: '15px', top: '0', backgroundColor: 'white'}}>
                                                                {[...appendEls]}
                                                            </div>
                                                            
                                                        </>
                                                    )}
                                                </Dropzone>                                                
                                                <span className={'delete-attachment ' + (disabled ? 'disabled' : '') + (appendEls.length > 0 ? ' mapping-spacer' : '')} onClick={() => !disabled ? this.handleClickDelete(mediaValue, form) : null}>
                                                    delete
                                                </span>
                                                
                                            </div>
                                        </div>
                                    </div>
                                );
                            } else {
                                fieldEl =  (
                                    <div className={`field-text se-id-${seleniumId}`}>
                                        <Field
                                            {...this.props}
                                            render={({ form }) => {
                                                return (
                                                    <Dropzone multiple={false} accept={accept} onDrop={files => this.handleUpload(files, form)} disabled={disabled}>
                                                        {({ getRootProps, getInputProps }) => (
                                                            <React.Fragment>
                                                                <button {...getRootProps()} type="button" className={"btn rich-text-button " + (disabled ? 'disabled' : '') } style={{textOverflow: 'ellipsis', overflow: 'hidden', display: 'block', width: '100%'}}>
                                                                    <i className="material-icons-outlined">{'upload'}</i>
                                                                    <span style={{color: 'grey', textOverflow: 'ellipsis', overflow: 'hidden', display: 'block'}}>{title}</span>
                                                                    <input {...getInputProps()} name={'upload_ignore'} />
                                                                </button>
                                                            </React.Fragment>
                                                        )}
                                                    </Dropzone>
                                                );
                                            }}
                                        />
                                        {[...appendEls]}
                                        {this.props.showIsRequired && <div className="validation-message required-placeholder"><FormattedMessage id="validation.required" /></div>}
                                    </div>
                                )
                            }
                            return tooltipOverlay ?
                            <Tooltip
                                key={id}
                                placement={'top'}
                                trigger={['hover']}
                                overlay={tooltipOverlay}
                                children={ fieldEl }
                            />
                            :
                            fieldEl
                        }}
                    />
                        <FieldDescription 
                            {...this.props} 
                            seleniumId={seleniumId}
                        />
                    </div>
                    <div>
                        {showError && <ErrorMessage name={id} component="div" className="validation-message" />}
                    </div>
                </div>
                {breakRow && <RowBreak width={width} />}
                {this.confirmDeleteModal()}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            uploadMedia,
            getMedia,
            deleteMedia
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AttachmentField);
