export const TRANSACTIONS_CREATE_INVOICE = `
    mutation M($transaction_id: Id!) {
        invoice_create(input: { transaction_id: $transaction_id }) {
            id
            filename
            transaction_id
            url
        }
    }
`;
