import React from 'react';
import CreatableSelectControl from 'components/fields/uncontrolledComponents/creatableSelectControl';
import { RowBreak, FieldDescription } from './common';
import { FieldTypes } from '../../../../models/field';
import { FormattedMessage } from 'react-intl';

class CreatableSelectField extends React.PureComponent {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.state = {
            options: []
        }
    }

    handleChange = selectedValue => {
        const { id } = this.props;
        if (this.props.onChange) {
            const value = !!selectedValue ? (Array.isArray(selectedValue) ? selectedValue.map(s => s.value) : selectedValue.value) : '';
            this.props.onChange(id, value);
        }
    };

    handleBlur = () => {
        const { id, onBlur = () => null } = this.props;
        onBlur(id, true);
    };

    getOptions = () => {
        const { options } = this.props;
        if (options.some(o => o.hasOwnProperty('value'))) {
            return options;
        }
        return options.map(o => ({ value: o, label: o }));
    };

    getSelectedValues = () => {
        const { value } = this.props;
        if (!value) {
            return null;
        }
        const options = this.getOptions();
        const selectedOption = options.find(o => o.value === value);
        if (selectedOption) {
            return selectedOption;
        }
        return { value: value, label: value };
    };

    render() {
        const { 
            id, 
            title, 
            multi, 
            breakRow, 
            width, 
            disabled, 
            isClearable = false, 
            showIsRequired = false, 
            prependEls = [], 
            appendEls = [],
            onCreateOption = () => null,
            removePaddingRight
        } = this.props;
        const widthClass = width ? `-${width}` : '';
        
        const value = this.getSelectedValues();

        return (
            <React.Fragment>
                <div className={`pd-b-15 col${widthClass}`} style={{ display: 'inline-block' }}>
                    {[...prependEls]}
                    <div className="field-select ">
                        <CreatableSelectControl
                            className="select-input-field"
                            name={id}
                            options={this.getOptions()}
                            isMulti={multi}
                            onChange={this.handleChange}
                            onCreateOption={onCreateOption}
                            onBlur={this.handleBlur}
                            value={value}
                            isDisabled={disabled}
                            showIsRequired={showIsRequired}
                            isClearable={isClearable}
                            menuPlacement={this.props.menuPlacement || 'auto'}
                            removePaddingRight={removePaddingRight}
                        />
                        <FieldDescription {...this.props} />
                        {[...appendEls]}
                    </div>
                </div>
                {breakRow && <RowBreak width={width} />}
            </React.Fragment>
        );
    }
}

export default CreatableSelectField;
