import React from 'react';
import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Page from 'components/page';
import { getProfile } from 'reducers/identity';
import DocumentTemplateList from './list';
import { setMenuVisibility } from 'reducers/menus';
import DocumentConfiguratorItems from '../components/items';
import DocumentConfiguratorProperties from '../components/properties';
import DocumentConfiguratorPreview from '../components/preview';
import { getDocumentTemplate, updateDocumentTemplate, getDocumentSample } from 'reducers/document';
import 'pages/models/models.scss';
import 'components/fields/fields.scss';
import { DocumentEditorContainerComponent, Toolbar } from '@syncfusion/ej2-react-documenteditor';
import ExcelDocumentTemplate from '../components/excelDocumentTemplate';
import WordDocumentTemplate from '../components/wordDocumentTemplate';
import { requestEntityLock, releaseEntityLock } from 'reducers/entityLock';

DocumentEditorContainerComponent.Inject(Toolbar);

class DocumentTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            previewData: "",
            loadingPreview: false,
            isExcel: false,
            contentHeight: 500
        };
        this.persistChanges = this.persistChanges.bind(this);
        this.contentRef = React.createRef();
        this.lockIntervalId = 0;
        this.hadLockLastCheck = false;
    }

    measureElement = element => {
        const DOMNode = ReactDOM.findDOMNode(element);
        return window.innerHeight - DOMNode.offsetTop - 130
    }

    componentDidMount() {
        const { match, getDocumentTemplate, getDocumentSample, requestEntityLock, user } = this.props;
        const urlParams = new URLSearchParams(window.location.search);
        const fixDocumentHeight = urlParams.get('fixDocumentHeight');
        if (fixDocumentHeight !== 'true') {
            window.addEventListener('resize', () => this.contentRef.current && this.setState({ contentHeight: this.measureElement(this.contentRef.current) }) );
        }
        const resizeCallback = () => {
            var el = document;
            var event = document.createEvent('HTMLEvents');
            event.initEvent('resize', true, false);
            el.dispatchEvent(event);
        };
        setTimeout(resizeCallback, 500);
        setTimeout(resizeCallback, 1000);
        setTimeout(resizeCallback, 1500);
        setTimeout(resizeCallback, 3000);
        setTimeout(resizeCallback, 5000);
        requestEntityLock(
            {
                entity_type: 'document',
                entity_id: match.params.id
            },
            (entityLock) => {
                this.hadLockLastCheck = (entityLock.user_id === user.id);
            }
        );

        getDocumentTemplate(match.params.id, () => {
            this.lockIntervalId = setInterval(
                () => {
                    if (this.props.user.id !== this.props.lockedEntity.user_id) {
                        getDocumentTemplate(match.params.id);
                    } else if (!this.hadLockLastCheck) {
                        
                    }
                    requestEntityLock({ entity_type: 'document', entity_id: match.params.id }, newEntityLock => {
                        if (this.props.user.id === newEntityLock.user_id && !this.hadLockLastCheck) {
                            // Document editor container ignores manual height on re-render, so we toggle
                            // the height up one pixel and down one pixel to force it to revert to proper height
                            this.setState(prevState => {
                                return { contentHeight: prevState.contentHeight + 1 }
                            }, () => {
                                var el = document;
                                var event = document.createEvent('HTMLEvents');
                                event.initEvent('resize', true, false);
                                el.dispatchEvent(event);
                                this.setState(prevState => {
                                    return { contentHeight: prevState.contentHeight - 1 }
                                });
                            });
                            this.hadLockLastCheck = true;
                        }
                    })
                },
                10 * 1000
            );
        });
        
        this.updateInterval = setInterval(() => {
            this.persistChanges();
        }, 5000);
    }

    onDataBound() {
    }

    async componentWillUnmount() {
        await this.persistChanges();
        const { releaseEntityLock, match } = this.props;
        clearInterval(this.lockIntervalId);
        releaseEntityLock({
            entity_type: 'document',
            entity_id: match.params.id
        });
        clearInterval(this.updateInterval);
    }

    persistChanges = async () => {
        const { template, templateChanged, updateDocumentTemplate, getDocumentSample } = this.props;
        if (template.document_type === 'word' || template.document_type === 'excel') {
            this.setState({
                loadingPreview: false
            });
            return null;
        }
        if (templateChanged) {
            this.setState({ loadingPreview: true }, () => {
                updateDocumentTemplate(template, () => {
                    getDocumentSample(template.id, (result) => {
                        this.setState({
                            loadingPreview: false,
                            previewData: result.base64data
                        });
                    });
                });
            });
        }
    };

    addMacro() {
        let proxy = this;
        proxy.documenteditor.documentEditor.editor.insertText('{{Testing}}');
    }

    saveSpreadsheet() {
        // this.spreadsheet.previewData
    }

    render() {
        const { template, parents, lockedEntity, user } = this.props;
        if (!template) return null;

        
        let documentReadLocked = false;
        let readLockedMessage = () => null;
        if (lockedEntity.user_id && lockedEntity.user_id !== user.id && lockedEntity.entity_type === 'document' && lockedEntity.entity_id === template.id) {
            documentReadLocked = true;
            readLockedMessage = () => (
                <div style={{
                    backgroundColor: '#fff',
                    padding: '15px 20px',
                    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.21)',
                    margin: '10px'
                }}>
                    <h6 className="mg-b-0 tx-inverse tx-bold">
                        <FormattedHTMLMessage id='lockedEntityMessage' tagName="span" values={{ entity: 'Document Template', name: lockedEntity.user_name, email: lockedEntity.user_email }} />
                    </h6>
                </div>
            )
        }

        let documentComponent = <div className="row pd-x-15 documents-configurator">
                <div className="col-2 pd-l-0 pd-r-5">
                    <DocumentConfiguratorItems />
                </div>
                <div className="col-2 pd-x-5">
                    <DocumentConfiguratorProperties />
                </div>
                <div className="col-8 pd-l-5 pd-r-0">
                    <DocumentConfiguratorPreview
                        isLoading={this.state.loadingPreview}
                        imageData={this.state.previewData}
                    />
                </div>
                <div>
                    
                </div>
            </div>;
        if (template.document_type === 'word') {
            documentComponent = <WordDocumentTemplate key={'word-' + documentReadLocked} {...this.props} manualHeight={this.state.contentHeight} readOnly={documentReadLocked}/>
        } else if (template.document_type === 'excel') {
            documentComponent = <ExcelDocumentTemplate key={'excel-' + documentReadLocked} {...this.props} manualHeight={this.state.contentHeight} readOnly={documentReadLocked} />
        }

        return (
            <div ref={this.contentRef}>
                <Page
                    {...{ ...DocumentTemplate.meta, title: template.title, routes: [`/document-templates/${template.id}`] }}
                    parents={[
                        ...parents,
                        { ...DocumentTemplateList.meta, routes: [`/document-templates`] }
                    ]}
                    noPadding={true}
                    extraHeaderEls={readLockedMessage()}
                >
                    {documentComponent}
                </Page>
            </div>
        );
    }
}

DocumentTemplate.meta = {
    showTitleBlock: false,
    panel: false,
    title: <FormattedMessage id="page.models.document.configuration.list.title" />,
    subtitle: <FormattedMessage id="page.models.document.configuration.subtitle" />,
    routes: ['/document-templates/'],
    icon: 'ion-clipboard',
    menus: { main: false, user: false },
    order: 0
};

const mapStateToProps = state => ({
    company: state.identity.company,
    model: state.fields.model,
    fields: state.fields.items,
    fieldTypes: state.fields.types,
    template: state.document.instance,
    templateChanged: state.document.templateChanged,
    lockedEntity: state.entityLock.lockedEntity,
    user: state.identity.user,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getProfile,
            getDocumentTemplate,
            getDocumentSample,
            setMenuVisibility,
            updateDocumentTemplate,
            requestEntityLock,
            releaseEntityLock,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DocumentTemplate);
