import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Fields } from '../../../../components/fields';
import { Notifications } from '../../../../components/notifications';
import { createInviteLink, getInviteLinks, approveInvite, rejectInvite, getCompanies, getInviteResponses, updateInvite } from '../../../../reducers/companies';
import countries from '../../../../assets/countries.json';
import SimpleBar from 'simplebar-react';

class InviteResponseReview extends React.Component {
    handleSubmit = values => {
        const { answer, getInviteResponses, updateInvite } = this.props;

        updateInvite({ ...values, company_id: answer.id }, () => {
            Notifications.success(<FormattedMessage id="page.companies.invites.inviteUpdated" />);
            getInviteResponses({ limit: 1000 });
        });
    };

    getInitialValues = () => {
        const { answer } = this.props;

        const {
            status_id = 1,
            company_name = '',
            description = '',
            address_1 = '',
            address_2 = '',
            code = '',
            website = '',
            email = '',
            lang_id = 1,
            country_id = '',
            city = '',
            state = '',
            zip = '',
            new_user_first_name = '',
            new_user_last_name = '',
            new_user_email = '',
            new_user_phone = ''
        } = answer || {};

        return {
            status_id,
            company_name,
            description,
            address_1,
            address_2,
            code,
            website,
            email,
            lang_id,
            country_id,
            city,
            state,
            zip,
            new_user_first_name,
            new_user_last_name,
            new_user_email,
            new_user_phone
        };
    };

    render() {
        const { onCancel, answer, approveInvite, rejectInvite, getInviteResponses } = this.props;

        return (
            <div className="modal-dialog wd-100p-force advanced-filters-modal" role="document">
                <div className="modal-content bd-0">
                    <div className="modal-header pd-x-20">
                        <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                            <FormattedMessage id="page.companies.invites.reviewInvite" />
                        </h6>
                    </div>
                    <Formik
                        initialValues={this.getInitialValues()}
                        validationSchema={Yup.object().shape({
                            company_name: Yup.string().required(<FormattedMessage id="validation.required" />),
                            new_user_first_name: Yup.string().required(<FormattedMessage id="validation.required" />),
                            new_user_last_name: Yup.string().required(<FormattedMessage id="validation.required" />),
                            new_user_email: Yup.string().required(<FormattedMessage id="validation.required" />),
                            new_user_phone: Yup.string().required(<FormattedMessage id="validation.required" />)
                        })}
                        onSubmit={this.handleSubmit}
                        render={({ values }) => (
                            <Form>
                                <React.Fragment>
                                    <div className="modal-body pd-0 widget-2 company-invite-form">
                                        <SimpleBar>
                                            <div className="pd-x-10 pd-y-5">
                                                <div className="form-group col-12 pd-t-15">
                                                    <h6 className="tx-10 mg-b-0 tx-uppercase">
                                                        <FormattedMessage id="page.account.forms.presubmission.subscribe.company" />
                                                    </h6>
                                                </div>
                                                <div className="form-group col-12">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <Fields.Input name="company_name" showError={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12">
                                                    <div className="row">
                                                        <div className="col-6 pd-r-0">
                                                            <Fields.Input name="website" />
                                                        </div>
                                                        <div className="col-6">
                                                            <Fields.Select
                                                                name="country_id"
                                                                value={values.country_id || ''}
                                                                options={countries.map(c => ({ value: c.id, label: ` (${c.code}) ${c.title}` }))}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12">
                                                    <div className="row">
                                                        <div className="col-6 pd-r-0">
                                                            <Fields.Input name="address_1" />
                                                        </div>
                                                        <div className="col-6">
                                                            <Fields.Input name="address_2" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12">
                                                    <div className="row">
                                                        <div className="col-4 pd-r-0">
                                                            <Fields.Input name="city" />
                                                        </div>
                                                        <div className="col-4 pd-r-0">
                                                            <Fields.Input name="state" />
                                                        </div>
                                                        <div className="col-4">
                                                            <Fields.Input name="zip" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-group col-12 pd-t-15">
                                                    <h6 className="tx-10 mg-b-0 tx-uppercase">
                                                        <FormattedMessage id="page.account.forms.presubmission.subscribe.user" />
                                                    </h6>
                                                </div>
                                                <div className="form-group col-12">
                                                    <div className="row">
                                                        <div className="col-6 pd-r-0">
                                                            <Fields.Input name="new_user_first_name" showError={true} />
                                                        </div>
                                                        <div className="col-6">
                                                            <Fields.Input name="new_user_last_name" showError={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12">
                                                    <Fields.Input name="new_user_email" showError={true} />
                                                </div>
                                                <div className="form-group col-12">
                                                    <Fields.Phone name="new_user_phone" showError={true} />
                                                </div>
                                            </div>
                                        </SimpleBar>
                                    </div>
                                    <div className="modal-footer justify-content-center">
                                        <button type="submit" className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium">
                                            <FormattedMessage id="common.update" />
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                            onClick={() =>
                                                approveInvite(answer.id, () => {
                                                    Notifications.success(<FormattedMessage id="page.companies.invites.inviteApproved" />);
                                                    getInviteResponses({ limit: 1000 });
                                                    onCancel();
                                                })
                                            }
                                        >
                                            <FormattedMessage id="common.approve" />
                                        </button>

                                        <button
                                            type="button"
                                            className="btn btn-danger tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                            onClick={() =>
                                                rejectInvite(answer.id, () => {
                                                    Notifications.success(<FormattedMessage id="page.companies.invites.inviteRejected" />);
                                                    getInviteResponses({ limit: 1000 });
                                                    onCancel();
                                                })
                                            }
                                        >
                                            <FormattedMessage id="common.reject" />
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                            onClick={onCancel}
                                        >
                                            <FormattedMessage id="common.cancel" />
                                        </button>
                                    </div>
                                </React.Fragment>
                            </Form>
                        )}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getCompanies,
            getInviteResponses,
            createInviteLink,
            getInviteLinks,
            approveInvite,
            rejectInvite,
            updateInvite
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InviteResponseReview);
