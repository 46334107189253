import React from 'react';
import { Field, FieldArray } from 'formik';
import { RowBreak, FieldDescription } from './common';
import { injectIntl, FormattedMessage } from 'react-intl';
import Tooltip from 'rc-tooltip';
import { getTextMaxLength } from '../../../../utilities/constants';

class MultiTextField extends React.Component {
    render() {
        const {
            id,
            width,
            breakRow,
            title,
            intl: { formatMessage },
            disabled,
            showIsRequired,
            prependEls = [],
            onChangeExt = () => null,
            tooltipOverlay,
            appendEls = [],
            seleniumId,
            marginLeft,
        } = this.props;
        const widthClass = width ? `-${width}` : '';
        return (
            <React.Fragment>
                <div className={`field-multiText pd-b-15 col${widthClass}`}>
                    {[...prependEls]}
                    <FieldArray
                        name={id}
                        render={formProps => {
                            const { form, push, remove } = formProps;
                            let fieldValues = [''];
                            if (String(id).indexOf('.') > -1) {
                                const idParts = id.split('.');
                                if (form.values[idParts[0]]) {
                                    if (Array.isArray(form.values[idParts[0]][idParts[1]])) {
                                        fieldValues = form.values[idParts[0]][idParts[1]];
                                    } else if (!!form.values[idParts[0]][idParts[1]] && typeof form.values[idParts[0]][idParts[1]] === 'object') {
                                        fieldValues = Object.values(form.values[idParts[0]][idParts[1]]);
                                    } else {
                                        fieldValues = [''];
                                    }
                                }
                            } else {
                                if (Array.isArray(form.values[id])) {
                                    if (form.values[id].length === 0) {
                                        fieldValues = [''];
                                    } else {
                                        fieldValues = form.values[id];
                                    }
                                } else if (!!form.values[id] && typeof form.values[id]  === 'object') {
                                    fieldValues = Object.values(form.values[id]);
                                } else {
                                    fieldValues = [''];
                                }
                            }
                            const noValuesYet = fieldValues.length === 1 && fieldValues[0] === '';
                            const fieldEl = (
                                <div style={{width: '100%', height: '100%'}}>
                                    {fieldValues.map((v, index) => (
                                        <div key={index} className="d-flex justify-content-between align-items-center mg-b-10"
                                            style={ marginLeft ? { marginLeft:`${marginLeft}`} : {}} >
                                            <Field
                                                name={`${id}.${index}`}
                                                type="text"
                                                placeholder={`${formatMessage({ id: 'common.enter' })} ${title}`}
                                                autoComplete="off"
                                                className={`form-control mg-r-5 se-id-${seleniumId}`}
                                                disabled={disabled}
                                                onKeyDown={() => onChangeExt(id, fieldValues)}
                                                maxLength={getTextMaxLength()}
                                            />
                                            {fieldValues.length - 1 === index ? (
                                                <>
                                                    <button 
                                                        type="button" 
                                                        className={`btn btn-primary se-id-${seleniumId}-add-button`}
                                                        onClick={async () => { 
                                                            fieldValues.push('');
                                                            onChangeExt(id, fieldValues);
                                                            await form.setFieldValue(id, fieldValues);
                                                            await form.submitForm();
                                                        }}
                                                        disabled={disabled}
                                                    >
                                                        <i className="material-icons">add</i>
                                                    </button>
                                                    {[...appendEls]}
                                                </>
                                            ) : (
                                                <button 
                                                    type="button" 
                                                    className={`btn btn-warning se-id-${seleniumId}-remove-button`}
                                                    onClick={async () => { 
                                                        fieldValues.splice(index, 1);
                                                        onChangeExt(id, fieldValues);
                                                        await form.setFieldValue(id, fieldValues);
                                                        await form.submitForm();
                                                    }} 
                                                    disabled={disabled}
                                                >
                                                    <i className="material-icons">remove</i>
                                                </button>
                                            )}
                                            <FieldDescription 
                                                {...this.props} 
                                                seleniumId={seleniumId}
                                            />
                                        </div>
                                    ))}
                                </div>
                            );
                            return (
                                <div>
                                    {
                                        tooltipOverlay ?
                                        <Tooltip
                                            placement={'top'}
                                            trigger={['hover']}
                                            overlay={tooltipOverlay}
                                            children={ fieldEl }
                                        />
                                        :
                                        fieldEl
                                    }
                                    {(noValuesYet && showIsRequired) ? 
                                        <div className="validation-message required-placeholder-multitext"><FormattedMessage id="validation.required" /></div> 
                                        : 
                                        <Tooltip trigger={['hover']} overlay={<span>{title}</span>} mouseEnterDelay={2}>
                                            <div className="input-placeholder-multitext">{title}</div>
                                        </Tooltip>
                                    }
                                </div>
                            );
                        }}
                    />
                </div>
                {breakRow && <RowBreak width={width} />}
            </React.Fragment>
        );
    }
}

export default injectIntl(MultiTextField);
