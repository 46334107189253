import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Settings from 'pages/settings';
import EventTemplates from 'pages/event-templates';
import CargoCategories from 'pages/cargo-categories';
import Lots from 'pages/lots';
import Transactions from 'pages/events';
import Documents from 'pages/documentTemplates';
import { history } from 'configuration/history';
import Page from 'components/page';
import ReactModal from 'react-modal';
import LoadingOverlay from 'react-loading-overlay';
import ModelPreview from 'pages/models/fields/components/preview';
import CargoTypeModal from 'pages/lots/components/cargoTypeModal';
import { 
    getTransaction, 
} from 'reducers/transaction';
import LibraryCarousel from '../components/libraryCarousel';
import { IconPickerItem } from 'react-fa-icon-picker'
import { importEntityGroup } from 'reducers/entityGroup';
import { Notifications } from 'components/notifications';
import ConfirmModal from 'components/modals/confirm'
import EntityModal from '../components/entityModal';
import { getMedia } from 'reducers/media';
import carouselIcon from '../images/carousel-icon.png';
import tableIcon from '../images/table-icon.png';


class LibaryCollection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            collection: {},
            modalState: null,
            entityModalOpen: false,
            isTableView: false,
            importDisabled: false,
        };
    }

    componentDidMount() {
        const { selectedLibrary } = this.props;

        if (!!selectedLibrary.label) {
            this.setState({ libraryTitle: selectedLibrary.label });
        }
        this.setState({
            libraryTemplates: {
                selectedDashes: selectedLibrary.templates.filter(t => t.model_type_name === 'event template'),
                selectedDots: selectedLibrary.templates.filter(t => t.model_type_name === 'cargo category'),
                selectedDocuments: selectedLibrary.document_templates,
            }
        });

        const container = document.querySelector('.br-mainpanel');
        container.scrollIntoView();
    }

    renderActions = () => {
        const { importEntityGroup, selectedLibrary } = this.props;
        return (
            <div className="btn-group pd-t-0">
                <span
                    className={"btn btn-outline-info" + (this.state.importDisabled ? ' disabled' : '')}
                    onClick={() => {
                        if (this.state.importDisabled) return;
                        this.setState({ importDisabled: true });
                        importEntityGroup({ id: selectedLibrary.id }, () => {
                            this.setState({ libraryImportModalOpen: true, importDisabled: false });
                        })
                    }}
                >
                    <FormattedMessage id="importLibrary" />
                </span>
            </div>
        );
    };

    
    

    getIcon(name, small = false) {
        if (name) {
            return <i className={name} key={name} style={{fontSize: 48, color: "#AAA"}} />
        } else {
            return null;
        }
    }

    renderRow(row) {
        const { getMedia } = this.props;
        return <div className={ row.header ? 'collection-row-header' : 'collection-row'}>
            {
                row.columns.map(column => {
                    let columnComponents = [];
                    if (column.icons) {
                        const iconSize = !!column.smallIcons ? 5 : 5;
                        const iconsContainer = <div className='collection-chart-icons'>
                            {
                                column.icons.map(icon => this.getIcon(icon, !!column.smallIcons))
                            }
                        </div>;
                        columnComponents.push(iconsContainer);
                    }
                    if (column.text) {
                        let className = '';
                        if (column.clickable) {
                            className += 'clickable ';
                        }
                        if (column.bold) {
                            className += 'collection-column-bold ';
                        } else {
                            className += 'collection-column-regular ';
                        }
                        if (column.blue) {
                            className += 'collection-column-blue ';
                        } else {
                            className += 'collection-column-grey ';
                        }
                        const textEls = <p className={className} >
                            {
                                column.text.runs.map(run => {
                                    if (!!run.linkType) {
                                        let clickHandler = () => null;

                                        if (run.linkType === 'dash') {
                                            clickHandler = () => {
                                                this.setState({
                                                    modalEntity: run.entity,
                                                    entityModalOpen: true,
                                                    entityModalType: run.linkType,
                                                })
                                            }
                                        } else if (run.linkType === 'dot') {
                                            clickHandler = () => {
                                                this.setState({
                                                    modalEntity: run.entity,
                                                    entityModalOpen: true,
                                                    entityModalType: run.linkType,
                                                })
                                            }
                                        } else if (run.linkType === 'dash-template') {
                                            clickHandler = () => {
                                                this.setState({
                                                    modalEntity: run.entity,
                                                    entityModalOpen: true,
                                                    entityModalType: run.linkType,
                                                })
                                            }
                                        } else if (run.linkType === 'dot-template') {
                                            clickHandler = () => {
                                                this.setState({
                                                    modalEntity: run.entity,
                                                    entityModalOpen: true,
                                                    entityModalType: run.linkType,
                                                })
                                            }
                                        } else if (run.linkType === 'document') {
                                            clickHandler = () => {
                                                const docObj = JSON.parse(run.entity);

                                                getMedia(docObj.id, result => {
                                                    this.setState({
                                                        modalEntity: result,
                                                        entityModalOpen: true,
                                                        entityModalType: 'document',
                                                    })
                                                })
                                            }
                                        } else if (run.linkType === 'document-template') {
                                            clickHandler = () => {
                                                this.setState({
                                                    modalEntity: run.entity,
                                                    entityModalOpen: true,
                                                    entityModalType: run.linkType,
                                                })
                                            }
                                        }

                                        return (
                                            <span 
                                                className='collection-column-blue' 
                                                onClick={clickHandler}
                                                style={{cursor: 'pointer'}}
                                                key={JSON.stringify(run)}
                                            >
                                                {run.text} &nbsp;
                                            </span>
                                        )
                                    } else {
                                        return run.text + ' ';
                                    }
                                })
                            }
                        </p>
                        columnComponents.push(textEls);
                    }
                    return <div className='collection-chart-column'> { columnComponents } </div>;
                })
            }
        </div>
    }

    render() {
        const { user = {}, isCompanyAdmin, company, parents, selectedLibrary } = this.props;

        if (!selectedLibrary) {
            return (
                <Page
                    {...{ ...LibaryCollection.meta, title: `${'Loading...'}` }}
                    parents={parents}
                    actions={this.renderActions()}
                >
                    <div className="">
                        <div className="row">
                            Loading...
                        </div>
                    </div>
                </Page>
            );
        }

        const libraryTable = JSON.parse(selectedLibrary.metadata);
        const templateIcons = libraryTable.templateIcons || [];
        const templateDescriptions = libraryTable.templateDescriptions || [];
        const { modalEntity, entityModalOpen, entityModalType, libraryTemplates } = this.state;

        let libraryDashTemplateTitles = 'None';
        const dashTemplates = selectedLibrary.templates.filter(t => t.model_type_name === 'event template');
        if (dashTemplates.length > 0){
            libraryDashTemplateTitles = '';
            for (let index = 0; index < dashTemplates.length; index++){
                if (index > 0) libraryDashTemplateTitles += ', ';
                libraryDashTemplateTitles += dashTemplates[index].title;
            }
        }
        let libraryDotTemplateTitles = 'None';
        const dotTemplates = selectedLibrary.templates.filter(t => t.model_type_name === 'cargo category');
        if (dotTemplates.length > 0){
            libraryDotTemplateTitles = '';
            for (let index = 0; index < dotTemplates.length; index++){
                if (index > 0) libraryDotTemplateTitles += ', ';
                libraryDotTemplateTitles += dotTemplates[index].title;
            }
        }
        let libraryDocumentTemplateTitles = 'None';
        const documentTemplates = selectedLibrary.document_templates;
        if (documentTemplates.length > 0){
            libraryDocumentTemplateTitles = '';
            for (let index = 0; index < documentTemplates.length; index++){
                if (index > 0) libraryDocumentTemplateTitles += ', ';
                libraryDocumentTemplateTitles += documentTemplates[index].title;
            }
        }
        let tagNames = 'None';
        const tags = selectedLibrary.tags || [];
        if (tags.length > 0){
            tagNames = '';
            for (let index = 0; index < tags.length; index++){
                if (index > 0) tagNames += ', ';
                tagNames += tags[index].title;
            }
        }

        const clickHandler = (entity, entityType) => {
            return () => {
                this.setState({
                    modalEntity: entity,
                    entityModalOpen: true,
                    entityModalType: entityType,
                })
            }
        }

        return (
            <Page
                {...{ ...LibaryCollection.meta, title: `${selectedLibrary.label || 'Loading...'}` }}
                panel={false}
                parents={parents}
                actions={this.renderActions()}
            >
                <div className="br-section-wrapper"> 
                    <div className='collection-root'>
                        <div className='row'>
                            <p className='collection-description'>
                                {!! selectedLibrary.detailed_description ? selectedLibrary.detailed_description : selectedLibrary.description}
                            </p>
                        </div>
                        <div className="library-icon-btn" onClick={() => this.setState({ isTableView: !this.state.isTableView })}>
                            <img src={this.state.isTableView ? carouselIcon : tableIcon} />
                        </div>
                        <div className="collection-chart">
                            {
                                this.state.isTableView ?
                                libraryTable.rows.map(row => this.renderRow(row)) 
                                :
                                <LibraryCarousel 
                                    key={JSON.stringify(libraryTable)}
                                    collection={{ chart: { rows: libraryTable.rows }}} 
                                    libraryTemplates={libraryTemplates}
                                />
                            }
                        </div>
                        <div className="library-contents">
                            <div className="contents-container">
                            <h3><FormattedMessage id="objectTemplates" /></h3>
                                <div className="templates-container dot-templates">
                                    {
                                        dotTemplates.map(dt => (
                                            <div className="template-box" onClick={clickHandler(dt, 'dot-template')}>
                                                { templateIcons.find(ti => ti.id === dt.id) ?
                                                    this.getIcon(templateIcons.find(ti => ti.id === dt.id).icon)
                                                    :
                                                    null
                                                }
                                                <h5>{dt.title}</h5>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="contents-container">
                                <h3><FormattedMessage id="eventTemplates" /></h3>
                                <div className="templates-container dash-templates">
                                    {
                                        dashTemplates.map(dt => (
                                            <div className="template-box" onClick={clickHandler(dt, 'dash-template')}>
                                                { templateIcons.find(ti => ti.id === dt.id) ?
                                                    this.getIcon(templateIcons.find(ti => ti.id === dt.id).icon)
                                                    :
                                                    null
                                                }
                                                <h5>{dt.title}</h5>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="contents-container">
                                <h3><FormattedMessage id="documentsAndTags" /></h3>
                                <div className="templates-container doc-templates">
                                    {
                                        documentTemplates.map(dt => (
                                            <div className="template-box" onClick={clickHandler(dt, 'document-template')}>
                                                <h5>{dt.title}</h5>
                                                { templateDescriptions.find(ti => ti.id === dt.id) ?
                                                    <div className="description">
                                                        { templateDescriptions.find(ti => ti.id === dt.id).description }
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                        ))
                                    }
                                    {
                                        tags.filter(tag => tag.model_type === 1).length > 0 &&
                                        <div className="template-box">
                                            <h4><strong><FormattedMessage id="dashTags" /></strong></h4>
                                                                                        
                                            <textarea class="tag-title" rows="5" readonly spellcheck="false" style={{ border: 'none', outline: 'none', resize: 'none'}} value={
                                                tags.filter(tag => tag.model_type === 1).map(tag => ( 
                                                     tag.title
                                                )).join('\n')
                                            }/>
                                        </div>
                                    }
                                    {                                        
                                        tags.filter(tag => tag.model_type === 2).length > 0 &&
                                        <div className="template-box">
                                            <h4><strong><FormattedMessage id="dotTags" /></strong></h4>
                                                                                        
                                            <textarea class="tag-title" rows="5" readonly spellcheck="false" style={{ border: 'none', outline: 'none', resize: 'none'}} value={
                                                tags.filter(tag => tag.model_type === 2).map(tag => ( 
                                                     tag.title 
                                                )).join('\n')
                                            }/>                                            
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ConfirmModal
                    id='library-import-modal'
                    open={this.state.libraryImportModalOpen}
                    setOpen={(o) => this.setState({ libraryImportModalOpen: o })}
                    onConfirm={() => null}
                    hideConfirm={true}
                    title={<FormattedMessage id='successfullyImportedLibrary' />}
                    body={
                        <>
                            <FormattedHTMLMessage tagName='div' id='importLibrarySummary' values={{ name: selectedLibrary.label }} />
                            <br />
                            <br />
                            <div>
                                <span style={{fontWeight: 'bold'}}><FormattedMessage id='eventTemplates' />: </span>
                                {libraryDashTemplateTitles}
                            </div>
                            <div>
                                <span style={{fontWeight: 'bold'}}><FormattedMessage id='objectTemplates' />: </span>
                                {libraryDotTemplateTitles}
                            </div>
                            <div>
                                <span style={{fontWeight: 'bold'}}><FormattedMessage id='page.models.invoices.templates' />: </span>
                                {libraryDocumentTemplateTitles}
                            </div>
                            <div>
                                <span style={{fontWeight: 'bold'}}><FormattedMessage id='page.models.configure.indices.title' />: </span>
                                {tagNames}
                            </div>
                        </>
                    }
                    cancelLabel={<FormattedMessage id='common.close' />}
                />
                {
                    !!modalEntity
                    &&
                    <EntityModal
                        open={entityModalOpen}
                        setOpen={(open) => this.setState({ entityModalOpen: open }) }
                        entityType={entityModalType}
                        entity={modalEntity}
                        allEntities={libraryTemplates}
                    />
                }
            </Page>
        )
    }
}

LibaryCollection.meta = {
    showTitleBlock: true,
    title: <FormattedMessage id="page.models.new.title" />,
    subtitle: <br/>,
    routes: ['/library/'],
    icon: 'ion-clipboard',
    menus: { main: false, user: false },
    order: 0,
    requires: ['model', 'read', 'update']
};

const mapStateToProps = state => ({
    // entityPermissions: state.identity.entityPermissions,
    // company: state.identity.company,
    // model: state.fields.model,
    // fieldTypes: state.fields.types
    selectedLibrary: state.entityGroup.selectedLibrary,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getTransaction,
            importEntityGroup,
            getMedia,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LibaryCollection);