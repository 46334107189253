import { LinkTypes } from '../../models/link';
import { history } from '../../configuration/history';

export const resolveLinkAction = (linkData, types, authenticated) => {
    if (!linkData || !types) return false;

    const { type_name, is_valid } = linkData;
    if (!is_valid) return false;

    switch (type_name) {
        case LinkTypes.ACTIVATION:
            return false;

        case LinkTypes.SET_PASSWORD:
            return () => (!!authenticated ? history.push('/' + window.location.search) : history.push('/set-password' + window.location.search, { linkData }));

        case LinkTypes.MEMBER_INVITATION:
            return () => (!!authenticated ? history.push('/' + window.location.search) : history.push('/set-password' + window.location.search, { linkData, invite: true }));

        case LinkTypes.COMPANY_INVITATION:
            return () => (!!authenticated ? history.push('/' + window.location.search) : history.push('/presubmission' + window.location.search, { linkData }));

        case LinkTypes.SHARED_ENTITY_INVITATION:
            return () => history.push('/accept-shared-invite' + window.location.search, { linkData });

        case LinkTypes.VISIBILITY_INSTANCE:
            return () => {
                const entityData = JSON.parse(linkData.data);
                const composedData = { entities: [] };
                entityData.entities.forEach(entity => {
                    composedData.entities.push({
                        entityType: entity.entity_type,
                        entityId: entity.entity_type === 'dash' ? entity.entity_hash_id : entity.entity_id
                    });
                })
                const encodedData = btoa(JSON.stringify(composedData));
                history.push(`/visualize/${encodedData}` + window.location.search)
            }

        default:
            return false;
    }
};
