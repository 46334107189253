export const COMPANIES_UPDATE = `
    mutation M(
        $company_id: Int!
        $status_id: Int
        $code: String
        $company_name: String
        $lang_id: Int
        $country_id: Int
        $custom_domain: String
        $description: HtmlString
        $website: String
        $email: String
        $signature: HtmlString
        $city: String
        $state: String
        $address_1: String
        $address_2: String
        $zip: String
        $phone1: String
        $phone2: String
        $fax: String
        $branding_config: JsonString
        $default_prefs: JsonString
        $extra_info: JsonString
        $currency_id: Long
    ) {
        company_update(
            company_id: $company_id
            input: {
                status_id: $status_id
                code: $code
                company_name: $company_name
                lang_id: $lang_id
                country_id: $country_id
                custom_domain: $custom_domain
                description: $description
                website: $website
                email: $email
                signature: $signature
                city: $city
                state: $state
                address_1: $address_1
                address_2: $address_2
                zip: $zip
                phone1: $phone1
                phone2: $phone2
                fax: $fax
                branding_config: $branding_config
                default_prefs: $default_prefs
                extra_info: $extra_info
                currency_id: $currency_id
            }
        ) {
            id
            status_id
            status_name
            created_on
            modified_on
            code
            company_name
            lang_id
            lang
            country_id
            country_code2
            country_code3
            custom_domain
            description
            website
            email
            signature
            city
            state
            address_1
            address_2
            zip
            phone1
            phone2
            fax

            branding_config
            default_prefs
            extra_info

            is_system

            stripe_customer_id
            is_paid
            payment_source
            subscription_id
            subscription_status
            subscription_type
            last_payment_check
            subscription_seats
            plan_id
        }
    }
`;
