import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { history } from './history';
import rootReducer from './../reducers';

const initialState = {};
const enhancers = [];
const middleware = [thunk, routerMiddleware(history)];

if (process.env.NODE_ENV === 'development') {
    if (typeof window === 'object') {
        const { __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ } = window;
        if (__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && typeof __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function') {
            enhancers.push(__REDUX_DEVTOOLS_EXTENSION_COMPOSE__());
        }
    }
}

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
);
export const store = createStore(rootReducer, initialState, composedEnhancers);
