import { uuid } from '../utilities/common';

export class Document {
    id;
    model_id;
    title;
    description;
    paper_size;
    page_orientation;
    header;
    body;
    footer;
    document_type;
    file_data;
    server_file_data;
    metadata;

    constructor(document = {}) {
        const { id = uuid(8), model_id, title = 'New document template', description = '', header = [], body = [], footer = [], page_orientation = null, paper_size = null, document_type = 'legacy', file_data = null, server_file_data = null, metadata = null } = document;
        this.id = id;
        this.model_id = model_id;
        this.title = title;
        this.paper_size = paper_size;
        this.page_orientation = page_orientation;
        this.description = description;
        this.header = header;
        this.body = body;
        this.footer = footer;
        this.document_type = document_type;
        this.file_data = file_data;
        this.server_file_data = server_file_data;
        this.metadata = metadata;
    }
}

export const DocumentItemTypes = {
    TEXT: 'text',
    RICHTEXT: 'richtext',
    IMAGE: 'image'
};

export const DocumentSections = {
    HEADER: 'header',
    BODY: 'body',
    FOOTER: 'footer'
};

export class DocumentText {
    ref_id;
    type;
    title;
    content;
    break_row;
    text_alignment;
    bold;
    italic;
    underline;
    width;
    offset;
    order;

    constructor(element = {}) {
        const {
            ref_id = uuid(8),
            type,
            title = 'New element',
            content = 'element...',
            break_row = false,
            text_alignment = 'left',
            font = null,
            font_size = null,
            bold = false,
            italic = false,
            underline = false,
            width = 12,
            offset = 0,
            order = 0,
            
        } = element;

        this.ref_id = ref_id;
        this.type = type || DocumentItemTypes.TEXT;
        this.title = title;
        this.content = content;
        this.break_row = break_row;
        this.text_alignment = text_alignment;
        this.font = font;
        this.font_size = font_size;
        this.bold = bold;
        this.italic = italic;
        this.underline = underline;
        this.width = width;
        this.offset = offset;
        this.order = order;
    }
}

export class DocumentRichText {
    ref_id;
    type;
    title;
    json;
    break_row;
    text_alignment;
    width;
    offset;
    order;

    constructor(element = {}) {
        const {
            ref_id = uuid(8),
            type,
            title = 'New element',
            json = `{"blocks":[{"key":"${ref_id}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
            break_row = false,
            text_alignment = 'left',
            font = null,
            font_size = null,
            width = 12,
            offset = 0,
            order = 0
        } = element;

        this.ref_id = ref_id;
        this.type = type || DocumentItemTypes.RICHTEXT;
        this.title = title;
        this.json = json;
        this.break_row = break_row;
        this.text_alignment = text_alignment;
        this.font = font;
        this.font_size = font_size;
        this.width = width;
        this.offset = offset;
        this.order = order;
    }
}

export class DocumentImage {
    ref_id;
    type;
    title;
    url;
    break_row;
    width;
    offset;
    s3_bucket;
    s3_object;
    order;

    constructor(element = {}) {
        const { ref_id = uuid(8), type, title = 'New element', url, break_row = false, width = 12, offset = 0, s3_bucket, s3_object, order = 0 } = element;

        this.ref_id = ref_id;
        this.type = type || DocumentItemTypes.IMAGE;
        this.title = title;
        this.url = url;
        this.break_row = break_row;
        this.width = width;
        this.offset = offset;
        this.s3_bucket = s3_bucket;
        this.s3_object = s3_object;
        this.order = order;
    }
}
