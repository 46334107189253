import 'pages/models/models.scss';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { listCargoUnits, createCargoUnit } from 'reducers/cargoUnits';
import { SelectField, TextField, CreatableSelectField } from 'pages/models/fields/definitions';
import { Fields } from 'components/fields';
import ReactModal from 'react-modal'
import { injectIntl, FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import Tooltip from 'rc-tooltip';
import { Notifications } from 'components/notifications';
import LoadingOverlay from 'react-loading-overlay';
import { tooltipWrapper } from 'utilities/common'


class CargoUnitField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addUnitModalOpen: false,
            userCreatedCargoUnitType: null,
            selectedCargoUnitType: null,
        };
    }

    componentDidMount() {
        this.props.listCargoUnits(null, () => {
        });
    }

    handleSubmit = async (values, { setSubmitting }) => {
        const { createCargoUnit, listCargoUnits } = this.props;
        const cleanupState = () => {
            setSubmitting(false)
            this.closeModal();
            this.setState({ creatingCargoUnit: false });
        }
        this.setState({ error: undefined, creatingCargoUnit: true }, () =>
            createCargoUnit(
                values,
                () => listCargoUnits(null, cleanupState, cleanupState),
                cleanupState
            )
        );
    }

    createCargoUnitCategory = (newCargoUnitCategory) => {
        return new Promise(resolve => 
            this.setState({ 
                    userCreatedCargoUnitType: newCargoUnitCategory,
                    selectedCargoUnitType: newCargoUnitCategory
                }, 
                () => resolve(newCargoUnitCategory)
            )
        );
    }

    closeModal = () => {
        this.setState({ 
            addUnitModalOpen: false,
            userCreatedCargoUnitType: null,
            selectedCargoUnitType: null,
        });
    }

    render() {        
        const {
            intl: { formatMessage },
            prependEls = [],
            seleniumId,
            appendEl,
            tooltipOverlay,
            disabled,
            backgroundColor,
        } = this.props;

        const { userCreatedCargoUnitType } = this.state;

        const inputCargoUnit = (this.state.inputCargoUnit === undefined || disabled) ? 
                                    this.props.cargoUnits
                                        .map(cu => ({value: cu.id, label: <FormattedMessage id={cu.display_name} defaultMessage={cu.display_name} />}))
                                        .find(o => o.value === this.props.initialTargetCargoUnit) 
                                    : 
                                    this.state.inputCargoUnit;
        const unitTypes = (this.props.cargoUnits || []).map(cu => cu.unit_type);
        const unitTypeOptions = [...new Set(unitTypes)].map(o => ({label: formatMessage({ id: o }), value: o}));
        if (userCreatedCargoUnitType) unitTypeOptions.push({ label: userCreatedCargoUnitType, value: userCreatedCargoUnitType });
        const addNewButton =  
            <div key='new-unit-btn'>
                {
                    tooltipWrapper(
                        true,
                        <FormattedMessage id='addNewDotUnit' />,
                        <button 
                            type="button" 
                            className="btn btn-primary" 
                            style={{width:'3em', height:'3em', marginLeft: '5px'}} 
                            onClick={() => this.setState({ addUnitModalOpen: true })} 
                            disabled={this.props.disabled}
                        > 
                            + 
                        </button>
                    )
                }
            </div>

        const appendEls = [addNewButton];
        if (appendEl) {
            appendEls.push(appendEl);
        }
        
        return (
            <React.Fragment>
                <SelectField
                    type='CARGOUNIT'
                    id='input-cargo'
                    name={this.props.name}
                    fieldValues={this.props.cargoUnits}
                    value={inputCargoUnit || ''}
                    title={this.props.overridePlaceholder || <FormattedMessage id='common.cargoUnitName' />}
                    multi={false}
                    breakrow={false}
                    width={this.props.width}
                    error={false}
                    touched={false}
                    disabled={disabled}
                    onBlur={() => null}
                    onChange={(_, value) => {
                        this.setState({ inputCargoUnit: value }, () => {
                            if (this.props.onCargoUnitSelected) this.props.onCargoUnitSelected(value);
                        });
                    }}
                    prependEls={prependEls}
                    appendEls={appendEls}
                    extraClassName={'pd-l-0'}
                    menuPlacement={this.props.menuPlacement || 'auto'}
                    seleniumId={`${seleniumId}.unit`}
                    tooltipOverlay={tooltipOverlay}
                    useFormattedMessageFilter={true}
                    backgroundColor={backgroundColor}
                />
                <ReactModal
                    id='cargo-unit-modal'
                    isOpen={this.state.addUnitModalOpen}
                    onRequestClose={this.closeModal}
                    className="modal-block dialog"
                    overlayClassName="modal-overlay gray"
                    style={{content: {width: '70%'}}}
                >
                    <LoadingOverlay
                        active={this.state.creatingCargoUnit}
                        spinner
                        text={<FormattedMessage id='common.loading'/>}
                    >
                        <div className="modal-dialog-centered" role="document">
                            <div className="modal-content bd-0">
                                    <div className="modal-header pd-x-20">
                                        <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                                            <FormattedMessage id="createCargoUnit" />
                                        </h6>
                                    </div>
                                <Formik
                                    initialValues={{ name: '', shortName: '' }}
                                    validationSchema={Yup.object().shape({
                                        name: Yup.string()
                                            .min(1, <FormattedMessage id="validation.length.min" values={{ length: 1 }} />)
                                            .required(<FormattedMessage id="validation.required" />),
                                        shortName: Yup.string()
                                            .min(1, <FormattedMessage id="validation.length.min" values={{ length: 1 }} />)
                                            .required(<FormattedMessage id="validation.required" />),
                                        cargoUnitType: Yup.string()
                                            .min(1, <FormattedMessage id="validation.length.min" values={{ length: 1 }} />)
                                            .required(<FormattedMessage id="validation.required" />),
                                    })}
                                    onSubmit={this.handleSubmit}
                                    render={({ isSubmitting, errors, submitForm, validateForm, setFieldValue }) => (
                                        <Form>
                                            <div className="modal-body">
                                                <div className="row pd-r-20">                                                   
                                                    <div className="col-6">
                                                        <TextField id="shortName" title={formatMessage({ id: 'customUnitLabel' })} type='text' className="form-control" showIsRequired={true} showError={true} description={formatMessage({ id: "cargoUnitNameHelper" })} />
                                                    </div>
                                                    <div className="col-6">
                                                        <CreatableSelectField  
                                                            className={`form-control`}
                                                            id={'cargoUnitType'}
                                                            options={unitTypeOptions}
                                                            showIsRequired={true} 
                                                            showError={true}  
                                                            onCreateOption={this.createCargoUnitCategory}
                                                            value={this.state.selectedCargoUnitType}
                                                            onChange={(id, selectedOption) => {
                                                                setFieldValue(id, selectedOption)
                                                                return new Promise(resolve =>  this.setState({ selectedCargoUnitType: selectedOption }, resolve))
                                                            }}
                                                            isClearable={true}
                                                            showLabel={true}
                                                            description={formatMessage({ id: "cargoUnitTypeHelper" })}
                                                            type="select"
                                                            removePaddingRight={true}
                                                            seleniumName={'unittype'}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <TextField id="name" title={formatMessage({ id: 'documentaryName' })} type='text' className="form-control" showIsRequired={true} showError={true} description={formatMessage({ id: "cargoUnitShortNameHelper" })} />
                                                    </div>                                                  
                                                </div>
                                            </div>
                                            <div className="modal-footer justify-content-center">
                                                    <button 
                                                        type="button" 
                                                        className="btn btn-primary btn-block col-3 pd-x-25" 
                                                        disabled={isSubmitting} 
                                                        onClick={async () => {
                                                            const result = await validateForm()
                                                            var errorNames = Object.keys(result);
                                                            if (errorNames.length === 0) {
                                                                await submitForm();
                                                            } else {
                                                                Notifications.error(<FormattedMessage id='common.requiredFieldsMissing' />);
                                                            }
                                                        }}
                                                    >
                                                        <FormattedMessage id="createCargoUnit" />
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary btn-block col-3 pd-x-25"
                                                        style={{marginTop: '0'}}
                                                        onClick={this.closeModal}
                                                    >
                                                        <FormattedMessage id="common.close" />
                                                    </button>
                                            </div>
                                        </Form>
                                    )}
                                />
                            </div>
                        </div>
                    </LoadingOverlay>
                </ReactModal>
            </React.Fragment>
        );
    }
}

CargoUnitField.defaultProps = {
    cargoUnits: [],
};

const mapStateToProps = state => {
    return {
        cargoUnits: state.cargoUnits.list,
    };
};
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            listCargoUnits,
            createCargoUnit,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(CargoUnitField));
