import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import ReactModal from 'react-modal';
import 'pages/models/models.scss';
import SimpleBar from 'simplebar-react';
import { getModels } from 'reducers/models';
import Table from 'components/table';
import QueryBuilder from 'pages/event-templates/components/queryBuilder';
import { Link } from 'react-router-dom';
import moment from 'moment';
import FadeIn from 'react-fade-in';
import ModelFieldPicker from 'pages/models/components/modelFieldPicker';
import { importFields, setModelPropertiesVisibility } from 'reducers/fields';
import { loadModel } from 'reducers/models';
import { getTransaction } from 'reducers/transaction';
import { getIndices } from 'reducers/indices';
import Tooltip from 'rc-tooltip';
import { tooltipWrapper } from 'utilities/common'
import { SelectField, TextField } from 'pages/models/fields/definitions';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import LoadingOverlay from 'react-loading-overlay';
import { Notifications } from 'components/notifications';
import { createFieldReferenceGroup } from 'reducers/documents';
import Select, { components } from 'react-select';

const MODEL_FIELD = 'model';
const INDEX_FIELD = 'index';
const LOT_FIELD = 'lot';

class FieldPickerModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            targetEventTemplateLoading: false,
            lotInfoType: 'Quantity',
        };
    }

    setModalVisibility = open => { 
        if (!open) {
            this.setState({
                targetEventTemplate: null,
                targetIndexField: null,
                targetCargoCategory: null,
                fieldReferenceId: null,
                fieldType: null
            })
        }
        return this.props.setModalVisibility ? this.props.setModalVisibility(open) : null;
    } 

    setFieldType = (fieldType) => this.setState({ 
        fieldType: fieldType,
        fieldLabel: null
    });

    fieldTypePicker = () => (
        <div id="field-type-picker" className={'row ' + (this.state.fieldType && 'disabled')}>
            <div className="col-3 pd-x-20">
                <FormattedMessage className='pd-x-20' id="common.chooseFieldType" />
            </div>
            <div className="col-3 pd-x-20">
                <label>
                    <input
                        type="radio"
                        name="field-type"
                        value={MODEL_FIELD}
                        checked={this.state.fieldType === MODEL_FIELD}
                        className="form-check-input"
                        onChange={() => this.setFieldType(MODEL_FIELD)}
                        disabled={this.state.fieldType}
                    />
                    <FormattedMessage className='pd-x-20' id="common.eventField" />
                </label>
            </div>
            <div className="col-3 pd-x-20">
                <label>
                    <input
                        type="radio"
                        name="field-type"
                        value={INDEX_FIELD}
                        checked={this.state.fieldType === INDEX_FIELD}
                        className="form-check-input"
                        onChange={() => this.setFieldType(INDEX_FIELD)}
                        disabled={this.state.fieldType}
                    />
                    <FormattedMessage className='pd-x-20' id="common.indexField" />
                </label>
            </div>
            <div className="col-3 pd-x-20">
                <label>
                    <input
                        type="radio"
                        name="field-type"
                        value={LOT_FIELD}
                        checked={this.state.fieldType === LOT_FIELD}
                        className="form-check-input"
                        onChange={() => this.setFieldType(LOT_FIELD)}
                        disabled={this.state.fieldType}
                    />
                    <FormattedMessage className='pd-x-20' id="common.lotField" />
                </label>
            </div>
        </div>
    )

    setTargetEventTemplate = (e) => {
        const { loadModel } = this.props;
        this.setState({targetEventTemplate: e.currentTarget.value, targetEventTemplateLoading: true, currentGroupName: null}, () => {
            loadModel(this.state.targetEventTemplate, () => {
                this.setState({ targetEventTemplateLoading: false });
            });
        });
    }

    getEventTemplateColumns = () => {
        const { intl: { formatMessage }} = this.props;
        return [
            {
                isDefault: true,
                headerClassName: 'd-sm-block col-2 d-none',
                className: 'd-xs-block col-2 tx-center',
                resizable: false,
                sortable: false,
                width: 34,
                id: 'actions',
                accessor: d => tooltipWrapper(
                    d.contains_inaccessible_dots,
                    <FormattedMessage id='containsInaccessibleDotsMessage' values={{ entityType: formatMessage({ id: 'common.model' })}} />,
                    <div className="actions-wrapper">
                        <div className="form-check">
                            <input
                                type="radio"
                                name="selected-event-template"
                                value={d.id}
                                checked={Number(this.state.targetEventTemplate) === d.id}
                                className="form-check-input"
                                onChange={(e) => {
                                    this.setTargetEventTemplate(e);
                                }}
                                disabled={d.contains_inaccessible_dots}
                            />
                        </div>
                    </div>
                )
            },
            {
                headerClassName: 'd-sm-block col d-none',
                className: 'd-sm-block col d-none tx-center id-column',
                Header: <FormattedMessage id="common.id" />,
                width: 150,
                id: 'id',
                accessor: d => tooltipWrapper(
                    d.contains_inaccessible_dots,
                    <FormattedMessage id='containsInaccessibleDotsMessage' values={{ entityType: formatMessage({ id: 'common.model' })}} />,
                    d.contains_inaccessible_dots ?
                    <span>{d.hash_id}</span>
                    :
                    <a href='#' onClick={e => { e.stopPropagation(); e.preventDefault(); this.setTargetEventTemplate({ currentTarget: { value: d.id} }); }}>{d.hash_id}</a>
                )
            },
            {
                headerClassName: 'd-sm-block d-none',
                className: 'd-xs-block',
                Header: <FormattedMessage id="common.title" />,
                id: 'title',
                accessor: d => tooltipWrapper(
                    d.contains_inaccessible_dots,
                    <FormattedMessage id='containsInaccessibleDotsMessage' values={{ entityType: formatMessage({ id: 'common.model' })}} />,
                    d.contains_inaccessible_dots ?
                    <span>{(d.title === null || d.title === '') ? '[Untitled]' : d.title}</span>
                    :
                    <a href='#' onClick={e => { e.stopPropagation(); e.preventDefault(); this.setTargetEventTemplate({ currentTarget: { value: d.id} }); }}>{(d.title === null || d.title === '') ? '[Untitled]' : d.title}</a>
                )
            },
            {
                headerClassName: 'd-block col-2',
                className: 'd-block col-2',
                Header: <FormattedMessage id="common.createdBy" />,
                id: 'created_by_name',
                accessor: d => d.created_by_name
            },
            {
                headerClassName: 'd-block col-2',
                className: 'd-block col-2',
                Header: <FormattedMessage id="common.createdOn" />,
                id: 'created_on',
                accessor: d => moment(d.created_on).format('DD-MM-YYYY')
            }
        ];
    };

    eventTemplateSelector = () => {
        const { models, totalModels } = this.props;
        let model = {};
        let showFieldPicker = false;
        if (this.state.targetEventTemplate) {
            model = models.find(m => Number(m.id) === Number(this.state.targetEventTemplate));
            showFieldPicker = true;
        }
        return (
            <React.Fragment>
                <FadeIn>
                    <hr/>
                    <div className="col-12 pd-x-20">
                        <FormattedMessage className='pd-x-20' id="common.chooseEventTemplate" />
                    </div>
                    <div id="event-template-picker" className="row pd-15">
                        <div className="col-12">
                            <Table
                                columns={this.getEventTemplateColumns()}
                                data={models}
                                total={totalModels}
                                // queryBuilder={setFilters => <QueryBuilder onFiltersSet={setFilters} />}
                                dataLoader={(options, onComplete) => this.loadEventTemplateData(options, onComplete)}
                            />
                        </div>
                    </div>
                </FadeIn>
                {
                    showFieldPicker && (
                        <FadeIn>
                            <ModelFieldPicker 
                                model={model}
                                modelId={this.state.targetEventTemplate} 
                                modelTypeId={1} 
                                setFieldReferenceId={(id) => { this.setState({ fieldReferenceId: id })}}
                                fieldReferenceId={this.state.fieldReferenceId}
                                isLoading={this.state.targetEventTemplateLoading}
                                cargoCategories={this.props.cargoCategories}
                            />
                            <div id="field-label" className="row pd-15">
                                { this.fieldLabel() }
                            </div>
                            <div className='row pd-b-15 pd-x-15'>
                                { this.fieldReferenceGroupSelector({ type: MODEL_FIELD, id: this.state.fieldReferenceId }) }
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button
                                    type="button"
                                    className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                    disabled={!this.state.fieldLabel}
                                    onClick={() => {
                                        this.props.onFieldSelect({ type: MODEL_FIELD, id: this.state.fieldReferenceId, title: this.state.fieldLabel, group: this.state.groupName });
                                        if (!this.props.stayOpenOnSelect) {
                                            this.setModalVisibility(false)
                                        }
                                    }}
                                >
                                    <FormattedMessage id="common.selectField" />
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                    onClick={() => this.setModalVisibility(false)}
                                >
                                    <FormattedMessage id="common.cancel"  />
                                </button>
                            </div>
                        </FadeIn>
                    )
                }
            </React.Fragment>
        );
    }

    loadIndexData = (options, onComplete) => {
        const { getIndices } = this.props;
        getIndices({ status_ids: [2], ...options, model_type: null }, onComplete);
    };

    setTargetIndexField = (e) => {
        this.setState({targetIndexField: e.currentTarget.value, currentGroupName: null});
    }
    
    getIndexColumns() {
        return [
            {
                isDefault: true,
                headerClassName: 'd-sm-block col-2 d-none',
                className: 'd-xs-block col-2 tx-center',
                sortable: false,
                resizable: false,
                width: 34,
                id: 'index-selected',
                accessor: d => (
                    <div className="actions-wrapper">
                        <div className="form-check">
                            <input
                                type="radio"
                                name="selected-index-field"
                                value={d.id}
                                checked={Number(this.state.targetIndexField) === Number(d.id)}
                                className="form-check-input"
                                onChange={(e) => {
                                    this.setTargetIndexField(e);
                                }}
                            />
                        </div>
                    </div>
                )
            },
            {
                headerClassName: 'd-sm-block col d-none',
                className: 'd-sm-block col d-none tx-center id-column',
                Header: <FormattedMessage id="common.id" />,
                width: 50,
                id: 'id',
                accessor: d => (
                    <div>
                        { d.id }
                    </div>
                )
            },
            {
                headerClassName: 'd-sm-block d-none',
                className: 'd-xs-block',
                Header: <FormattedMessage id="common.title" />,
                id: 'title',
                accessor:  d => { 
                    return (d.description && d.description.length > 0) ?
                        <div>
                            <Tooltip placement={'top'} trigger={['hover']} overlay={d.description}>
                                <span>
                                    { d.title }
                                </span>
                            </Tooltip>
                        </div>
                        :
                        <div>
                            { d.title }
                        </div>
                }
            },
            {
                headerClassName: 'd-sm-block col-sm-1 d-none',
                className: 'd-sm-block col-sm-1 d-none',
                Header: <FormattedMessage id="common.references" />,
                width: 150,
                id: 'model_type',
                accessor: d => d.model_type === 1 ? <FormattedMessage id="common.eventTemplate" /> : <FormattedMessage id="common.cargoCategory" />
            },
            {
                headerClassName: 'd-sm-block col-sm-1 d-none',
                className: 'd-sm-block col-sm-1 tx-center d-none',
                Header: <FormattedMessage id="common.type" />,
                id: 'type_name',
                accessor: d => d.type_name
            },
            {
                headerClassName: 'd-sm-block d-none col-1',
                className: 'd-xs-block col-1 tx-center',
                Header: <FormattedMessage id="common.isInUse" />,
                sortable: false,
                id: 'is_used',
                accessor: d => (!!d.is_used ? <FormattedMessage id="common.yes" /> : <FormattedMessage id="common.no" />)
            },
            {
                headerClassName: 'd-block col-2',
                className: 'd-block col-2',
                Header: <FormattedMessage id="common.createdOn" />,
                id: 'created_on',
                accessor: d => moment(d.created_on).format('DD-MM-YYYY')
            }
        ];
    }

    setTargetCargoCategory = (e) => {
        const { loadModel } = this.props;
        this.setState({targetCargoCategory: e.currentTarget.value, targetCargoCategoryLoading: true, currentGroupName: null}, () => {
            loadModel(this.state.targetCargoCategory, () => {
                this.setState({ targetCargoCategoryLoading: false });
            });
        });
    }

    getCargoCategoryColumns() {
        return [
            {
                isDefault: true,
                headerClassName: 'd-sm-block col-2 d-none',
                className: 'd-xs-block col-2 tx-center',
                sortable: false,
                resizable: false,
                width: 34,
                id: 'cargo-selected',
                accessor: d => (
                    <div className="actions-wrapper">
                        <div className="form-check">
                            <input
                                type="radio"
                                name="selected-index-field"
                                value={d.id}
                                checked={Number(this.state.targetCargoCategory) === Number(d.id)}
                                className="form-check-input"
                                onChange={(e) => {
                                    this.setTargetCargoCategory(e);
                                }}
                            />
                        </div>
                    </div>
                )
            },
            {
                headerClassName: 'd-sm-block col d-none',
                className: 'd-sm-block col d-none tx-center id-column',
                Header: <FormattedMessage id="common.id" />,
                width: 150,
                id: 'id',
                accessor: d => <a href='#' onClick={e => { e.stopPropagation(); e.preventDefault(); this.setTargetCargoCategory({ currentTarget: { value: d.id} }); }}>{d.hash_id}</a>
            },
            {
                headerClassName: 'd-sm-block d-none',
                className: 'd-xs-block',
                Header: <FormattedMessage id="common.title" />,
                id: 'title',
                accessor: d => <a href='#' onClick={e => { e.stopPropagation(); e.preventDefault(); this.setTargetCargoCategory({ currentTarget: { value: d.id} }); }}>{(d.title === null || d.title === '') ? '[Untitled]' : d.title}</a>
            },
            {
                headerClassName: 'd-block col-2',
                className: 'd-block col-2',
                Header: <FormattedMessage id="common.createdBy" />,
                id: 'created_by_name',
                accessor: d => d.created_by_name
            },
            {
                headerClassName: 'd-block col-2',
                className: 'd-block col-2',
                Header: <FormattedMessage id="common.createdOn" />,
                id: 'created_on',
                accessor: d => moment(d.created_on).format('DD-MM-YYYY')
            }
        ];
    };

    fieldLabel = () => {
        const {
            intl: { formatMessage },
        } = this.props;
        const { fieldLabel, targetIndexField, fieldReferenceId, fieldType, targetCargoCategory } = this.state;
        const disabled = (fieldType === MODEL_FIELD && !fieldReferenceId) 
                        || (fieldType === INDEX_FIELD && !targetIndexField)
                        || (fieldType === LOT_FIELD && !fieldReferenceId)
        let disabledMessage;
        switch(fieldType) {
            case MODEL_FIELD:
                disabledMessage = <FormattedMessage id='chooseFieldFirst' />;
                break;
            case INDEX_FIELD:
                disabledMessage = <FormattedMessage id='chooseIndexFirst' />;
                break;
            case LOT_FIELD:
                disabledMessage = <FormattedMessage id='chooseFieldFirst' />;
                break;
            default:
                disabledMessage = null;
        }
        return (
            <div className="col-6 offset-3">
                { tooltipWrapper(
                    disabled,
                    disabledMessage,
                    <div className="input-field">
                        {!!fieldLabel && 
                            <Tooltip trigger={['hover']} overlay={<span>{formatMessage({ id: 'fieldReferenceLabel.placeholder' })}</span>} mouseEnterDelay={2}>
                                <div className="input-placeholder">
                                    {formatMessage({ id: 'fieldReferenceLabel.placeholder' })}
                                </div> 
                            </Tooltip>
                        }
                        <input
                            type={'text'}
                            disabled={disabled}
                            onChange={e => {
                                e.preventDefault();
                                this.setState({ fieldLabel: e.target.value })
                            }}
                            className={'form-control'}
                            autoComplete="off"
                            placeholder={formatMessage({ id: 'fieldReferenceLabel.placeholder', defaultMessage: 'Field Reference Label' })}
                        />
                        { !this.state.fieldLabel && <div className="validation-message required-placeholder"><FormattedMessage id="validation.required" /></div> }
                    </div>
                ) }
            </div>
        )
    }

    isGroupOptionDisabled = (ref, group) => {
        const { fieldRefGroupRefs } = this.props;

        // if there are no refs in the group, we can add
        const refsInCurrentGroup = fieldRefGroupRefs.filter(g => g.name === group.name);
        if (refsInCurrentGroup.length === 0) {
            return false;
        }
        // the group can not contain references of a different type
        const refInGroupWithDifferentType = refsInCurrentGroup.find(gr => gr.field_reference_literal.indexOf('[$' + ref.type) < 0);
        if (refInGroupWithDifferentType) {
            return true;
        }
        
        // check for field compatibility among the references in the group
        let targetFieldId;
        let targetmodelId = -1;
        if (this.state.fieldType === MODEL_FIELD) {
            targetFieldId = this.state.fieldReferenceId;
            if (this.state.fieldReferenceId) {
                if (this.state.fieldReferenceId.indexOf('cc-') > -1) {
                    const parts = this.state.fieldReferenceId.split('.');
                    targetFieldId = parts[parts.length - 1];
                } else if (this.state.fieldReferenceId.indexOf('.l') > -1) {
                    const parts = this.state.fieldReferenceId.split('.');
                    targetFieldId = parts[0];
                }
            }
        } else if (this.state.fieldType === INDEX_FIELD) {
            targetFieldId = this.state.targetIndexField;
        } else if (this.state.fieldType === LOT_FIELD) {
            targetFieldId = this.state.fieldReferenceId;
            targetmodelId = this.state.targetCargoCategory;
        }


        const refInGroupWithoutFieldInCompatibilityList = refsInCurrentGroup
            .find(groupRef => 
                (!groupRef.compatible_field_ids || !groupRef.compatible_field_ids.find(id => id === targetFieldId))
                && (!groupRef.compatible_model_ids || !groupRef.compatible_model_ids.find(id => id === targetmodelId)));
        return refInGroupWithoutFieldInCompatibilityList !== undefined;
    }

    fieldReferenceGroupSelector = (ref) => {
        const {
            intl: { formatMessage },
            fieldRefGroups,
        } = this.props;
        const { fieldLabel, targetIndexField, fieldReferenceId, fieldType, targetCargoCategory } = this.state;
        const disabled = !fieldLabel;
        const disabledMessage = <FormattedMessage id='youMustEnterLabelFirst' />;
        const seleniumName = 'referencegroup';
        const addNewButton =  
            <div key='new-unit-btn'>
                {
                    tooltipWrapper(
                        true,
                        <FormattedMessage id='addNewReferenceGroup' />,
                        <button 
                            type="button" 
                            className="btn btn-primary" 
                            style={{width:'3em', height:'3em', marginLeft: '5px'}} 
                            onClick={() => this.setState({ addGroupModalOpen: true })} 
                        > 
                            + 
                        </button>
                    )
                }
            </div>

        const customStyles = {
            control: base => ({
                ...base,
                boxShadow: 'none'
            }),
            multiValueLabel: (provided, state) => ({
                ...provided,
                maxWidth: '10em'
            }),
            menu: (provided, state) => ({
                ...provided,
                zIndex: '20'
            }),
            singleValue: (provided, state) => {
                const opacity = 1;
                const transition = 'opacity 300ms';
                const color = '#495057';
            
                return { ...provided, opacity, transition, color };
            },
            menuList: (provided, state) => ({
                ...provided,
                maxHeight: '200px'
            }),
        };

        const Option = props => {
            return (
                props.isDisabled ?
                    <Tooltip
                        placement={'left'}
                        trigger={['hover']}
                        overlay={<FormattedMessage id="refGroupNotCompatible" />}
                    >
                        <div>
                            <components.Option {...props} />
                        </div>
                    </Tooltip>
                    :
                    <components.Option {...props} className={'se-select-menu-option-' + seleniumName} />
            );
        };

        const options = fieldRefGroups.map(g => ({ value: g.name, label: g.name, isDisabled: this.isGroupOptionDisabled(ref, g) }))
        const selectedValue = options.find(o => o.value === this.state.currentGroupName);

        return tooltipWrapper(
            disabled,
            disabledMessage,
            <div className={`col-6 offset-3`} style={{ display: 'inline-block' }}>
                <div className="field-select">
                    <Select
                        id='field-ref-group-select'
                        className="select-input-field"
                        placeholder={<FormattedMessage id='fieldReferenceGroup' />}
                        options={options}
                        isMulti={false}
                        components={{ IndicatorSeparator: () => null, 
                            Option,
                            DropdownIndicator: props => <components.DropdownIndicator {...props} className={'se-select-dropdown-' + seleniumName} /> }}
                        theme={theme => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: '#eeeeee',
                                primary: '#00336B'
                            }
                        })}
                        onChange={selectedValue => {
                            const value = selectedValue ? selectedValue.value : null;
                            this.setState({ groupName: value });
                        }}
                        value={selectedValue}
                        styles={customStyles}
                        isDisabled={disabled}
                        isClearable={true}
                        menuPlacement={'auto'}
                    />
                    {!!selectedValue &&
                        <Tooltip trigger={['hover']} overlay={<span><FormattedMessage id='fieldReferenceGroup' /></span>} mouseEnterDelay={2}>
                            <div className="input-placeholder">
                                <FormattedMessage id='fieldReferenceGroup' />
                            </div> 
                        </Tooltip>
                    }
                    {addNewButton}
                </div>
            </div>
        );
    }

    indexSelector = () => {
        const { indices, totalIndices } = this.props;
        return (
            <React.Fragment>
                <FadeIn>
                    <hr/>
                    <div className="col-12 pd-x-20">
                        <FormattedMessage className='pd-x-20' id="common.chooseIndexField" />
                    </div>
                    <div id="event-template-picker" className="row pd-15">
                        <div className="col-12">
                        <Table
                            columns={this.getIndexColumns()}
                            data={indices}
                            total={totalIndices}
                            dataLoader={(options, onComplete) => this.loadIndexData(options, onComplete)}
                        />
                        </div>
                    </div>
                    <div id="field-label" className="row pd-15">
                        { this.fieldLabel() }
                    </div>
                    <div className='row pd-b-15 pd-x-15'>
                        { this.fieldReferenceGroupSelector({ type: INDEX_FIELD, id: this.state.targetIndexField}) }
                    </div>
                    <div className="modal-footer justify-content-center">
                        <button
                            type="button"
                            className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                            disabled={!this.state.fieldLabel}
                            onClick={() => {
                                this.props.onFieldSelect({ type: INDEX_FIELD, id: this.state.targetIndexField, title: this.state.fieldLabel, group: this.state.groupName });
                                if (!this.props.stayOpenOnSelect) {
                                    this.setModalVisibility(false)
                                }
                            }}
                        >
                            <FormattedMessage id="common.selectField" />
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                            onClick={() => this.setModalVisibility(false)}
                        >
                            <FormattedMessage id="common.cancel"  />
                        </button>
                    </div>
                </FadeIn>
            </React.Fragment>
        );
    }

    loadCargoCategoryData = (options, onComplete) => {
        const { getModels } = this.props;
        getModels({ status_ids: [2], model_type: 2, ...options }, onComplete);
    };

    lotSelector = () => {
        const { models, totalModels } = this.props;
        let model = {};
        let showFieldPicker = false;
        if (this.state.targetCargoCategory) {
            model = models.find(m => Number(m.id) === Number(this.state.targetCargoCategory));
            showFieldPicker = true;
        }
        return (
            <React.Fragment>
                <FadeIn>
                    <hr/>
                    <div className="col-12 pd-x-20">
                        <FormattedMessage className='pd-x-20' id="common.chooseCargoCategory" />
                    </div>
                    <div id="cargo-category-picker" className="row pd-15">
                        <div className="col-12">
                        <Table
                            columns={this.getCargoCategoryColumns()}
                            data={models}
                            total={totalModels}
                            dataLoader={(options, onComplete) => this.loadCargoCategoryData(options, onComplete)}
                        />
                        </div>
                    </div>
                    {
                        showFieldPicker && (
                            <FadeIn>
                                <ModelFieldPicker 
                                    model={model}
                                    modelId={this.state.targetCargoCategory} 
                                    modelTypeId={2} 
                                    setFieldReferenceId={(id) => { this.setState({ fieldReferenceId: id })}}
                                    fieldReferenceId={this.state.fieldReferenceId}
                                    isLoading={this.state.targetCargoCategoryLoading}
                                    cargoCategories={this.props.cargoCategories}
                                />
                                <div id="field-label" className="row pd-15">
                                    { this.fieldLabel() }
                                </div>
                                <div className='row pd-b-15 pd-x-15'>
                                    { this.fieldReferenceGroupSelector({ type: LOT_FIELD, id: this.state.targetCargoCategory, lotTarget: this.state.fieldReferenceId }) }
                                </div>
                                <div className="modal-footer justify-content-center">
                                    <button
                                        type="button"
                                        className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                        disabled={!this.state.fieldLabel || !this.state.targetCargoCategory}
                                        onClick={() => {
                                            this.props.onFieldSelect({ type: LOT_FIELD, id: this.state.targetCargoCategory, title: this.state.fieldLabel, lotTarget: this.state.fieldReferenceId, group: this.state.groupName });
                                            if (!this.props.stayOpenOnSelect) {
                                                this.setModalVisibility(false)
                                            }
                                        }}
                                    >
                                        <FormattedMessage id="common.selectField" />
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                        onClick={() => this.setModalVisibility(false)}
                                    >
                                        <FormattedMessage id="common.cancel"  />
                                    </button>
                                </div>
                            </FadeIn>
                        )
                    }
                </FadeIn>
            </React.Fragment>
        );
    }

    fieldSelector = () => {
        if (this.state.fieldType === MODEL_FIELD) {
            return (
                <React.Fragment>
                    {this.eventTemplateSelector()}
                </React.Fragment>
            )
        } else if (this.state.fieldType === INDEX_FIELD) {
            return (
                <React.Fragment>
                    {this.indexSelector()}
                </React.Fragment>
            )
        } else if (this.state.fieldType === LOT_FIELD) {
            return (
                <React.Fragment>
                    {this.lotSelector()}
                </React.Fragment>
            )
        }
    }

    loadEventTemplateData = (options, onComplete) => {
        const { getModels } = this.props;
        getModels({ status_ids: [2], model_type: 1, ...options }, onComplete);
    };

    handleSubmit = async (values, { setSubmitting }) => {
        const { createFieldReferenceGroup, docTemplateId } = this.props;
        const cleanupState = (error) => {
            setSubmitting(false)
            this.setState({ addGroupModalLoading: false, addGroupModalOpen: false });
            if (!error) {
                this.props.onGroupAdded({
                    name: values.name,
                    document_template_id: docTemplateId
                })
            }
        }
        this.setState({ error: undefined, addGroupModalLoading: true }, () =>
            createFieldReferenceGroup(
                {
                    name: values.name,
                    document_template_id: docTemplateId
                },
                () => cleanupState(false),
                () => cleanupState(true)
            )
        );
    }

    render() {
        const { showModal, intl: { formatMessage }, } = this.props;

        return (
            <>
                <ReactModal
                    isOpen={showModal}
                    onRequestClose={() => this.setModalVisibility(false)}
                    className="modal-block dialog"
                    overlayClassName="modal-overlay gray"
                    style={{content: {width: '70%', maxHeight: '90%', overflowY: 'auto', overflowX: 'hidden'}}}
                >
                    <div role="document">
                        <div className="modal-content bd-0">
                            <div className="modal-header pd-x-20">
                                <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                                    <FormattedMessage id="common.addFieldReference" />
                                </h6>
                            </div>
                            <div className="modal-body pd-x-20">
                                { this.fieldTypePicker() }
                                { this.fieldSelector() }
                            </div>
                        </div>
                    </div>
                </ReactModal>

                <ReactModal
                    id='cargo-unit-modal'
                    isOpen={this.state.addGroupModalOpen}
                    onRequestClose={() => this.setState({ addGroupModalOpen: false })}
                    className="modal-block dialog"
                    overlayClassName="modal-overlay gray"
                    style={{content: {width: '50%'}}}
                >
                    <LoadingOverlay
                        active={this.state.addGroupModalLoading}
                        spinner
                        text={<FormattedMessage id='common.loading'/>}
                    >
                        <div className="modal-dialog-centered" role="document">
                            <div className="modal-content bd-0">
                                    <div className="modal-header pd-x-20">
                                        <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                                            <FormattedMessage id="addNewReferenceGroup" />
                                        </h6>
                                    </div>
                                <Formik
                                    initialValues={{ name: '' }}
                                    validationSchema={Yup.object().shape({
                                        name: Yup.string()
                                            .min(1, <FormattedMessage id="validation.length.min" values={{ length: 1 }} />)
                                            .required(<FormattedMessage id="validation.required" />),
                                    })}
                                    onSubmit={this.handleSubmit}
                                    render={({ isSubmitting, submitForm, validateForm, values }) => (
                                        <Form>
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <TextField id="name" title={formatMessage({ id: 'groupName' })} type='text' className="form-control" showIsRequired={true} showError={true} description={formatMessage({ id: "referenceGroupNameHelper" })} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer justify-content-center">
                                                <button 
                                                    type="button" 
                                                    className="btn btn-primary btn-block col-3 pd-x-25" 
                                                    disabled={isSubmitting || !values.name} 
                                                    onClick={async () => {
                                                        const result = await validateForm()
                                                        var errorNames = Object.keys(result);
                                                        if (errorNames.length === 0) {
                                                            submitForm();
                                                        } else {
                                                            Notifications.error(<FormattedMessage id='common.requiredFieldsMissing' />);
                                                        }
                                                    }}
                                                >
                                                    <FormattedMessage id="addNewReferenceGroup" />
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary btn-block col-3 pd-x-25"
                                                    style={{marginTop: '0'}}
                                                    onClick={() => this.setState({ addGroupModalOpen: false })}
                                                >
                                                    <FormattedMessage id="common.close" />
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                />
                            </div>
                        </div>
                    </LoadingOverlay>
                </ReactModal>
            </>
        );
    }
}

const mapStateToProps = state => ({
    models: state.models.list,
    totalModels: state.models.total,
    authenticated: state.identity.authenticated,
    location: state.router.location,
    indices: state.indices.list,
    totalIndices: state.indices.total
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getModels,
            loadModel,
            importFields,
            getTransaction,
            getIndices,
            createFieldReferenceGroup,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(FieldPickerModal));
