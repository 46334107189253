import React from 'react';
import Presubmission from './presubmission';

class Subscribe extends React.Component {
    render() {
        return (
            <div className="d-flex align-items-center justify-content-center bg-br-primary ht-100v">
                <Presubmission {...this.props} />
            </div>
        );
    }
}

Subscribe.meta = {
    route: ['/presubmission'],
    menus: { main: false, user: false }
};

export default Subscribe;
