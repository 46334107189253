import React from 'react';
import { Formik, Form } from 'formik';
import SimpleBar from 'simplebar-react';
import { Fields } from 'components/fields';
import { uuid } from 'utilities/common';
import { FieldTypes, FieldComparatorTypes, getComparatorSymbol } from 'models/field';
import { injectIntl, FormattedMessage } from 'react-intl';
import { getCurrencyOptions } from 'utilities/constants';
import Tooltip from 'rc-tooltip';

class AdvancedFilters extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: props.filters || []
        };
    }

    handleSubmit = values => {
        if (!values.field) return;
        if (values.comparator === 8 && !Array.isArray(values.filterValue)){
            values.filterValue = [];
        }
        let previousEntry = this.state.list.find(l => l.id === values.id);
        let list = [];
        if (!!previousEntry){
            list = [...this.state.list.map(l => (l.id === values.id ? { ...l, ...values } : l))]
        } else {
            list = [...this.state.list, values];
        }
        this.setState({ ...this.state, list });
    };

    createFilterInstance = () => {
        return { id: uuid(8), filterValue: '', break_or: false, is_not: false };
    };

    removeFilter = id => {
        const { list } = this.state;
        this.setState({ ...this.state, list: [...list.filter(l => l.id !== id)] });
    };

    addNewFilter = (break_or = false) => {
        const { list } = this.state;
        this.setState({ ...this.state, list: [...list, { id: uuid(8), break_or }] });
    };

    getComparators = values => {
        const { fields, comparators, loadIndexValues, disabled } = this.props;
        const { field, comparator } = values;
        if (!field) return [];

        
        const { NONE, EQUALS, GREATER, LESS, GREATER_EQUAL, LESS_EQUAL, NOT_EQUAL, BETWEEN, CONTAINS, DOES_NOT_CONTAIN, IN, LIKE, NOT_LIKE, IS_NULL } = FieldComparatorTypes;
        
        const indexField = fields.filter(f => f.type !== FieldTypes.FIELD_SECTION && f.type !== FieldTypes.FIELD_CARGO).find(f => f.visibility_id === field) || {};
        let comparatorInputs = [];
        switch (indexField.type) {
            case FieldTypes.FIELD_TEXT:
                const textInput = (
                    <div className="col-4 pd-l-0" key={`${field}-${indexField.type}-${comparator}`}>
                        <Fields.Input name="filterValue" type="text" value={values.filterValue} disabled={disabled} />
                    </div>
                );
                comparatorInputs = [
                    { comparator: NONE, input: null },
                    { comparator: IS_NULL, input: null },
                    { comparator: EQUALS, input: textInput },
                    { comparator: NOT_EQUAL, input: textInput },
                    { comparator: LIKE, input: textInput },
                    { comparator: NOT_LIKE, input: textInput }
                ];
                break;

            case FieldTypes.FIELD_NUMBER:
                const numberInput = (
                    <div className="col-4 pd-l-0" key={`${field}-${indexField.type}-${comparator}`}>
                        <Fields.Input name="filterValue" type="number" value={values.filterValue} disabled={disabled} />
                    </div>
                );
                comparatorInputs = [
                    { comparator: NONE, input: null },
                    { comparator: IS_NULL, input: null },
                    { comparator: EQUALS, input: numberInput },
                    { comparator: NOT_EQUAL, input: numberInput },
                    { comparator: GREATER, input: numberInput },
                    { comparator: GREATER_EQUAL, input: numberInput },
                    { comparator: LESS_EQUAL, input: numberInput },
                    { comparator: LESS, input: numberInput },
                    {
                        comparator: BETWEEN,
                        input: (
                            <div className="col-5 pd-l-0" key={`${field}-${indexField.type}-${comparator}`}>
                                <div className="row">
                                    <div className="col-5 pd-r-0">
                                        <Fields.Input
                                            name="filterValue[0]"
                                            type="number"
                                            value={
                                                values.filterValue && Array.isArray(values.filterValue) && values.filterValue.length > 0
                                                    ? values.filterValue[0]
                                                    : null
                                            }
                                            disabled={disabled}
                                        />
                                    </div>
                                    <div className="col-2 pd-x-0">
                                        <div className="field-inline-separator">
                                            <FormattedMessage id="common.and" />
                                        </div>
                                    </div>
                                    <div className="col-5 pd-l-0">
                                        <Fields.Input
                                            name="filterValue[1]"
                                            type="number"
                                            value={
                                                values.filterValue && Array.isArray(values.filterValue) && values.filterValue.length > 1
                                                    ? values.filterValue[1]
                                                    : null
                                            }
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    }
                ];
                break;
            case FieldTypes.FIELD_CURRENCY:
                const currencyInput = (
                    <div className="col-4 pd-l-0" key={`${indexField.type_name}-${comparator}`}>
                        <Fields.Select name="filterValue" options={getCurrencyOptions()} submitOnChange={true} value={values.filterValue} disabled={disabled} />
                    </div>
                );
                comparatorInputs = [
                    { comparator: EQUALS, input: currencyInput },
                    { comparator: NOT_EQUAL, input: currencyInput },
                ];
                break;

            case FieldTypes.FIELD_YES_NO:
                const booleanInput = (
                    <div className="col-4 pd-l-0" key={`${field}-${indexField.type}-${comparator}`}>
                        <Fields.Select
                            name="filterValue"
                            value={values.filterValue}
                            submitOnChange={true}
                            options={[
                                { value: true, label: <FormattedMessage id="common.yes" /> },
                                { value: false, label: <FormattedMessage id="common.no" /> },
                                { value: null, label: <FormattedMessage id="common.n/a" /> },
                            ]}
                            disabled={disabled}
                        />
                    </div>
                );

                comparatorInputs = [
                    { comparator: EQUALS, input: booleanInput },
                ];
                break;

            case FieldTypes.FIELD_DATE:
                const dateInput = (
                    <div className="col-4 pd-l-0" key={`${field}-${indexField.type}-${comparator}`}>
                        <Fields.Date submitOnChange={true} dateFormat={indexField.dateFormat} name="filterValue" value={values.filterValue} disabled={disabled} />
                    </div>
                );

                comparatorInputs = [
                    { comparator: NONE, input: null },
                    { comparator: IS_NULL, input: null },
                    { comparator: EQUALS, input: dateInput },
                    { comparator: NOT_EQUAL, input: dateInput },
                    { comparator: GREATER, input: dateInput },
                    { comparator: GREATER_EQUAL, input: dateInput },
                    { comparator: LESS_EQUAL, input: dateInput },
                    { comparator: LESS, input: dateInput },
                    {
                        comparator: BETWEEN,
                        input: (
                            <div className="col-5 pd-l-0" key={`${field}-${indexField.type}-${comparator}`}>
                                <div className="row">
                                    <div className="col-5 pd-r-0">
                                        <Fields.Date
                                            submitOnChange={true}
                                            dateFormat={indexField.dateFormat}
                                            name="filterValue[0]"
                                            value={ (values.filterValue && Array.isArray(values.filterValue)) ? values.filterValue[0] : ''}
                                            disabled={disabled}
                                        />
                                    </div>
                                    <div className="col-2 pd-x-0">
                                        <div className="field-inline-separator">
                                            <FormattedMessage id="common.and" />
                                        </div>
                                    </div>
                                    <div className="col-5 pd-l-0">
                                        <Fields.Date
                                            submitOnChange={true}
                                            dateFormat={indexField.dateFormat}
                                            name="filterValue[1]"
                                            value={ (values.filterValue && Array.isArray(values.filterValue)) ? values.filterValue[1] : ''}
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    }
                ];
                break;

            case FieldTypes.FIELD_SELECT:
                const selectInput = multi => (
                    <div className="col-4 pd-l-0" key={`${field}-${indexField.type}-${comparator}`}>
                        <Fields.AsyncSelect
                            name="filterValue"
                            value={values.filterValue}
                            submitOnChange={true}
                            multi={multi}
                            options={
                                indexField && indexField.fieldValues
                                    ? indexField.fieldValues.map(i => ({ value: i.value, label: i.value }))
                                    : undefined
                            }
                            optionsLoader={s => loadIndexValues(indexField.id, s, () => null, r => r.map(i => ({ value: i.value, label: i.value })))}
                            disabled={disabled}
                        />
                    </div>
                );
                comparatorInputs = [
                    { comparator: NONE, input: null },
                    { comparator: IS_NULL, input: null },
                    { comparator: EQUALS, input: selectInput(false) },
                    { comparator: NOT_EQUAL, input: selectInput(false) },
                    { comparator: IN, input: selectInput(true) }
                ];
                break;

            case FieldTypes.FIELD_MULTI_SELECT:
                const multiSelectInput = multi => (
                    <div className="col-4 pd-l-0" key={`${field}-${indexField.type}-${comparator}`}>
                        <Fields.AsyncSelect
                            name="filterValue"
                            value={values.filterValue}
                            submitOnChange={true}
                            multi={multi}
                            options={
                                indexField && indexField.fieldValues
                                    ? indexField.fieldValues.map(i => ({ value: i.value, label: i.value }))
                                    : undefined
                            }
                            optionsLoader={s => loadIndexValues(indexField.id, s, () => null, r => r.map(i => ({ value: i.value, label: i.value })))}
                            disabled={disabled}
                        />
                    </div>
                );
                comparatorInputs = [
                    { comparator: NONE, input: null },
                    { comparator: IS_NULL, input: null },
                    { comparator: CONTAINS, input: multiSelectInput(false) },
                    { comparator: DOES_NOT_CONTAIN, input: multiSelectInput(false) }
                ];
                break;

            case FieldTypes.FIELD_MULTI_TEXT:
                comparatorInputs = [
                    { comparator: NONE, input: null },
                    { comparator: IS_NULL, input: null },
                    {
                        comparator: CONTAINS,
                        input: (
                            <div className="col-4 pd-l-0" key={`${field}-${indexField.type}-${comparator}`}>
                                <Fields.Input name="filterValue" type="text" value={values.filterValue} disabled={disabled} />
                            </div>
                        )
                    },
                    {
                        comparator: DOES_NOT_CONTAIN,
                        input: (
                            <div className="col-4 pd-l-0" key={`${field}-${indexField.type}-${comparator}`}>
                                <Fields.Input name="filterValue" type="text" value={values.filterValue} disabled={disabled} />
                            </div>
                        )
                    }
                ];
                break;

            case FieldTypes.FIELD_ATTACHMENT:
                comparatorInputs = [
                    { comparator: NONE, input: null },
                    { comparator: IS_NULL, input: null },
                ];
                break;
                
            default:
                break;
        }

        return comparatorInputs.map(ci => ({
            ...ci,
            comparator: { value: (comparators.find(c => c.comparator_name === ci.comparator) || {}).id, label: getComparatorSymbol(ci.comparator) }
        }));
    };

    renderForm(filterItem) {
        const { list } = this.state;
        const { fields, field, disabled, intl: { formatMessage } } = this.props;
        return (
            <div className="filter-form">
                <Formik
                    enableReinitialize={true}
                    initialValues={!!filterItem ? { ...filterItem } : { ...this.createFilterInstance() }}
                    onSubmit={(values, form) => this.handleSubmit(values, form)}
                    render={({ values, handleChange, submitForm }) => {
                        const comparatorInputs = this.getComparators(values);
                        return (
                            <Form
                                noValidate
                                onChange={async e => {
                                    await handleChange(e);
                                    submitForm();
                                }}
                            >
                                <React.Fragment>
                                    <div className="row">
                                        <div className="col">
                                            <Fields.Select
                                                name="field"
                                                submitOnChange={true}
                                                disabled={!!values.field || disabled}
                                                value={values.field}
                                                options={fields
                                                    .filter(f => 
                                                        f.type !== FieldTypes.FIELD_SECTION && 
                                                        f.type !== FieldTypes.FIELD_CARGO && 
                                                        f.id !== field.id 
                                                        // condition to when field selected is a Section and but the user should not have the option to choose fields that are within the section
                                                        && (field.type === FieldTypes.FIELD_SECTION && !!field.children && !!field.children.find(c => c === f.id) ? false : true)
                                                        )
                                                    .map(f => ({ value: f.visibility_id, label: f.title }))}
                                            />
                                        </div>
                                        {values.field && (
                                            <Tooltip trigger={['hover']} placement='top' overlay={<span>{ formatMessage({ id: `comparator.placeholder` })}</span>}>
                                                <div className="col-2 pd-l-0">
                                                    <Fields.Select
                                                        key={values.field}
                                                        name="comparator"
                                                        submitOnChange={true}
                                                        disabled={disabled}
                                                        value={values.comparator}
                                                        options={comparatorInputs.map(c => c.comparator)}
                                                    />
                                                </div>
                                            </Tooltip>
                                        )}
                                        {values.field &&
                                            values.comparator &&
                                            (comparatorInputs.find(c => c.comparator.value === values.comparator) || {}).input}
                                        { ( list.length > 0 ) && (
                                            <div className="col-1 pd-l-0">
                                                <button
                                                    className={'btn btn-primary icon-button field-inline float-right ' + (disabled && 'disabled')}
                                                    type="button"
                                                    onClick={() => !disabled &&this.removeFilter(values.id)}
                                                    disabled={disabled}
                                                >
                                                    <i className="material-icons-outlined">remove</i>
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </React.Fragment>
                            </Form>
                        );
                    }}
                />
            </div>
        );
    }

    render() {
        const { list } = this.state;
        const { onFiltersSet, onCancel, disabled } = this.props;

        const filters = () => {
            if (list.length > 0 && list.filter(f => f.field).length > 0) {
                const filtersList = (
                    <div className="advanced-filters-inner">
                        {list.map((filterItem, index) => (
                            <React.Fragment key={filterItem.id}>
                                {!!filterItem.break_or && index != 0 && (
                                    <div className="filter-separator tx-center mg-y-25">
                                        <span>OR</span>
                                    </div>
                                )}
                                {this.renderForm(filterItem)}
                            </React.Fragment>
                        ))}
                        <div className="row mg-t-15">
                            <div className="col">
                                <button className={'btn btn-primary icon-button field-inline mg-r-15 new-filter-and ' + (disabled && 'disabled')} type="button" onClick={() => !disabled && this.addNewFilter(false)}>
                                    <i className="material-icons">add</i>
                                    <span>
                                        <FormattedMessage id="page.transactions.newFilter.type" values={{ type: <FormattedMessage id='AND' /> }} />
                                    </span>
                                </button>
                                <button className={'btn btn-primary icon-button field-inline mg-r-15 new-filter-or ' + (disabled && 'disabled')} type="button" onClick={() => !disabled && this.addNewFilter(true)}>
                                    <i className="material-icons">add</i>
                                    <span>
                                        <FormattedMessage id="page.transactions.newFilter.type" values={{ type: <FormattedMessage id='OR' /> }} />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                );
                if (list.length >=10) {
                    return <SimpleBar> { filtersList } </SimpleBar>;
                } else {
                    return filtersList;
                }
            } else {
                return this.renderForm()
            }
        }
         

        return (
            <div className="modal-dialog advanced-filters-modal" role="document">
                <div className="modal-content bd-0">
                    <div className="modal-header pd-x-20">
                        <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                            <FormattedMessage id="models.configuration.setup.visibility.conditions" />
                        </h6>
                    </div>
                    <div className="modal-body pd-20">
                        <div className="advanced-filters-container">
                            { filters() }
                        </div>
                    </div>
                    <div className="modal-footer justify-content-center">
                        <button
                            type="button"
                            className={'btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium ' + (disabled && 'disabled')}
                            onClick={() => !disabled && onFiltersSet(list)}
                        >
                            <FormattedMessage id="common.apply" />
                        </button>
                        <button type="button" className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium" onClick={onCancel}>
                            <FormattedMessage id="common.cancel" />
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default injectIntl(AdvancedFilters);
