import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Dropzone from 'react-dropzone';
import { history } from 'configuration/history';
import Page from 'components/page';
import { getModels, createModel, importModel, exportModel, cloneModel, deleteModel, persistModelChanges } from 'reducers/models';
import { getProfile } from 'reducers/identity';
import { createTransaction } from 'reducers/transaction';
import Model from 'models/model';
import { getIndices } from 'reducers/indices';
import { setEventTemplatesTableOptions, setEventsTemplatesTableQueryValues, setEventsTemplatesTableQueryState } from 'reducers/sessionSettings';
import ConfirmModal from 'components/modals/confirm';
import '../models.scss';
import EventTemplatesTable from './table';
import { forfeitSharedEntity } from 'reducers/sharing';

class ModelList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deleteTargetMultiIds: [],
            tableRenderCount: 1,
        }
    }

    async componentDidMount() {
        const { company, getProfile, getIndices } = this.props;
        getIndices();
        if (!company) {
            getProfile();
        }
    }

    toggleDeleteTarget = (id) => {
        this.setState(prevState => {
            const targetIds = prevState.deleteTargetMultiIds;
            const index = targetIds.indexOf(id);
            if (index === -1) {
                targetIds.push(id);
            } else {
                targetIds.splice(index, 1);
            }
            return { deleteTargetMultiIds: targetIds };
        })
    }

    eventAllSelectorEl = () => {
        const { models, company } = this.props;
        const { deleteTargetMultiIds, isDeletingMulti } = this.state;
        const deletableModels = models.filter(m => !(m.contains_inaccessible_dots || m.company_id !== company.id));
        const allEventsSelected = deleteTargetMultiIds.length === deletableModels.length;
        if (isDeletingMulti) {
            return ( 
                <div className="actions-wrapper" style={{height: '100%'}}>
                    <input 
                        type="checkbox" 
                        checked={allEventsSelected}  
                        onChange={() => this.setState(prevState => {
                            let allSelectedIds = prevState.deleteTargetMultiIds;
                            if (allSelectedIds.length === deletableModels.length) {
                                return { deleteTargetMultiIds: [] };
                            } else {
                                return { deleteTargetMultiIds: deletableModels.map(t => t.id) };
                            }
                        })}
                    />
                </div>
            );
        } else {
            return null;
        }
    }

    overrideMenuEl = () => {
        const { company } = this.props;
        return this.state.isDeletingMulti ?
            (d) => (
                <div className="actions-wrapper">
                    <input 
                        type="checkbox" 
                        checked={this.state.deleteTargetMultiIds && this.state.deleteTargetMultiIds.indexOf(d.id) > -1}  
                        onChange={() => this.toggleDeleteTarget(d.id)}
                        disabled={d.contains_inaccessible_dots || d.company_id !== company.id}
                    />
                </div>
            )
            :
            undefined;
    }

    deleteMultiButtonGroup = () => {
        if (this.state.isDeletingMulti) {
            const deleteButton = this.state.deleteTargetMultiIds && this.state.deleteTargetMultiIds.length > 0 ?
                (
                    <span 
                        className="btn btn-outline-info" 
                        onClick={() => {
                            this.setState({ confirmMultiModalOpen: true });
                        }
                    }>
                        <FormattedMessage id="deleteSelectedEventTemplates" values={{count: this.state.deleteTargetMultiIds.length}} />
                    </span>
                )
                :
                (
                    <span 
                        className="btn btn-outline-info disabled" 
                        disabled={true}
                        onClick={() => null
                    }>
                        <FormattedMessage id="noEventTemplatesSelected" />
                    </span>
                )
            return (
                <React.Fragment>
                    { deleteButton }
                    <span 
                        className="btn btn-outline-info" 
                        onClick={() => {
                            this.setState({ isDeletingMulti: false, confirmMultiModalOpen: false, deleteTargetMultiIds: [] });
                        }
                    }>
                        <FormattedMessage id="cancel" />
                    </span>
                </React.Fragment>
            );
        } else {
            return (
                <span 
                    className="btn btn-outline-info" 
                    onClick={() => {
                        this.setState({ isDeletingMulti: true, deleteTargetMultiIds: [] });
                    }
                }>
                    <FormattedMessage id="deleteMultipleEventTemplates" />
                </span>
            );
        }
    }

    renderActions = () => {
        const { createModel, company, importModel, getModels } = this.props;
        return (
            <div className="btn-group pd-t-10">
                <span 
                    className="btn btn-outline-info" 
                    onClick={() => {
                        let model = Model.forCompany(company.id);
                        model.title = 'New Dash Template'
                        model.model_type_name = 'event template';
                        createModel(model, m => history.push(`/events/event-templates/${m.id}` + window.location.search))
                    }
                }>
                    <FormattedMessage id="page.models.button.new" />
                </span>
                <span className="btn btn-outline-info">
                    <Dropzone
                        accept={'.dorml'}
                        onDrop={files =>
                            importModel(null, files, m => {
                                getModels({ model_type: 1 });
                                if (m.model_type_name === 'event template') {
                                    history.push(`/events/event-templates/${m.id}` + window.location.search);
                                } else {
                                    history.push(`/objects/object-templates/${m.id}` + window.location.search);
                                }
                            })
                        }
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()} className="upload-wrapper">
                                <FormattedMessage id="common.import" />
                                <input {...getInputProps()} />
                            </div>
                        )}
                    </Dropzone>
                </span>
                <span className="btn btn-outline-info" onClick={() => history.push(`/events/event-templates/indices` + window.location.search)}>
                    <FormattedMessage id="models.configure.indices" />
                </span>
                { this.deleteMultiButtonGroup() }
            </div>
        );
    };

    render() {
        const { models, company, persistModelChanges, forfeitSharedEntity, intl: { formatMessage }, } = this.props;
        if (!company) return null;
        const { deleteTargetMultiIds } = this.state;
        const targetedModels = models.filter(m => deleteTargetMultiIds && deleteTargetMultiIds.includes(m.id));
        let multiDeleteWillUnshare = false;
        if (targetedModels.some(m => m.company_id !== company.id)) {
            multiDeleteWillUnshare = true;
        }

        return (
            <Page {...ModelList.meta} actions={this.renderActions()} parents={this.props.parents}>
                <EventTemplatesTable
                    overrideMenuEl={this.overrideMenuEl()}
                    overrideMenuHeader={this.eventAllSelectorEl()}
                    externalRenderCount={this.state.tableRenderCount}
                />
                <ConfirmModal
                    open={this.state.confirmMultiModalOpen}
                    setOpen={(o) => this.setState({ confirmMultiModalOpen: o })}
                    onConfirm={() => {
                        const { deleteTargetMultiIds } = this.state;
                        const promises = [];
                        for (const templateId of deleteTargetMultiIds) {
                            promises.push(new Promise(resolve => {
                                const targetModel = models.find(m => m.id === templateId);
                                if (targetModel.company_id === company.id) {
                                    persistModelChanges({ ...targetModel, title: targetModel.title ? targetModel.title : "", status_id: 4 }, resolve)
                                } else {
                                    forfeitSharedEntity({ entity_type: 'user_model', id: templateId }, resolve)
                                }
                            }));
                        }
                        Promise.all(promises).then(() => {
                            // re-render the table
                            this.setState( prevState => {
                                return { isDeletingMulti: false, tableRenderCount: prevState.tableRenderCount + 1 };
                            });
                        })

                    }}
                    title={<FormattedMessage id='common.confirmVerb' values={{ verb: multiDeleteWillUnshare ? formatMessage({ id: 'deleteUnshareSelected' }) : formatMessage({ id: 'deleteSelected' })}} />}
                    body={multiDeleteWillUnshare ? <FormattedHTMLMessage tagName='div' id='confirmEventTemplateDeleteAndUnshareMultiple' /> : <FormattedMessage id='confirmEventTemplateDeleteMultiple' />}
                />
            </Page>
        );
    }
}

ModelList.meta = {
    title: <FormattedMessage id="page.models.title" />,
    subtitle: <FormattedMessage id="page.models.subtitle" />,
    routes: ['/models'],
    icon: 'ion-clipboard',
    menus: { main: true, user: false },
    order: 30
};

ModelList.defaultProps = {
    models: [],
    total: 0
};

const mapStateToProps = state => ({
    models: state.models.eventTemplateList,
    company: state.identity.company,
    total: state.models.eventTemplateTotal,
    eventTemplatesTableOptions: state.sessionSettings.eventTemplatesTableOptions,
    eventTemplatesTableQueryValues: state.sessionSettings.eventTemplatesTableQueryValues,
    eventTemplatesTableQueryState: state.sessionSettings.eventTemplatesTableQueryState,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getProfile,
            getModels,
            createModel,
            createTransaction,
            persistModelChanges,
            importModel,
            exportModel,
            cloneModel,
            deleteModel,
            getIndices,
            setEventTemplatesTableOptions,
            setEventsTemplatesTableQueryValues,
            setEventsTemplatesTableQueryState,
            forfeitSharedEntity,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(ModelList));
