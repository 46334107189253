export const CONDITIONS_CREATE = `
    mutation M(
        $type_id: Int!, 
        $comparator_id: String,
        $value: String,
        $alert_id: Int!,
        $order: Int!,
        $break_or: Boolean!
    ) {
        alert_condition_create(
            input: { 
                type_id: $type_id, 
                comparator_id: $comparator_id,
                value: $value,
                alert_id: $alert_id,
                order: $order,
                break_or: $break_or,
            }
        ) {
            id
            type_id
            comparator_id
            alert_id
            order
            break_or
        }
    }
`;
