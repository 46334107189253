export const FAQ_SEARCH = `
	query _(
		$locale: String!
		$query: String!
	) {
		faq_search(
			input: {
				locale: $locale
				query: $query
			}
		) {
			id
		}
  	}`