/**
 * Authenticate with token.
 */
export const AUTHENTICATE = `
    query authenticate {
        signin_token {
            token {
                token
                data {
                    expires
                    expires_in
                    user_id
                    company_id
                    signin_user_id
                    signin_company_id
                    requires_mfa
                    mfa_passed
                }
            }

            user {
                id
                status_id
                status_name
                created_on
                modified_on
                email
                auth_type
                last_login_on
                login_attempts
                reset_password
                name
                avatar_id
                lang_id
                lang
                country_id
                country_code2
                country_code3
                state
                city
                zip
                address_1
                address_2
                phone
                extra_info

                mfa_required

                avatar {
                    id

                    thumb(
                        width: 200
                        height: 200
                        fit: false
                    ) {
                        bucket
                        key
                        size
                        mime
                        last_modified
                        url
                    }
                }

                entity_permissions
                permission_list {
                    id
                    is_admin_level
                    code
                    title
                }
            }
        }
    }
`;
