import React from 'react';
import { Field } from 'formik';

class ButtonField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { width, title, outline = false, secondary = false, onClick = () => null, value, name, disabled } = this.props;
        const widthClass = width ? `-${width}` : '-12';
        let buttonClass = secondary ? 'btn-secondary' : 'btn-primary';
        if (outline) {
            buttonClass = secondary ? 'btn-outline-secondary' : 'btn-outline-info';
        }
        return (
            <Field
                {...this.props}
                render={({ form }) => {
                    return (
                        <div className={`field-button pd-b-15 col${widthClass}`}>
                            <button type="button" className={'btn ' + buttonClass + ' btn-block ellipsis ' + (disabled && 'disabled')} onClick={() => disabled ? null : onClick(form, value, name)}>
                                {title}
                            </button>
                        </div>
                    );
                }}
            />
        );
    }
}

export default ButtonField;
