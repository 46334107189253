import React from 'react';
import { injectIntl } from 'react-intl';
import { Field, ErrorMessage } from 'formik';
import Switch from 'rc-switch';

import './fields.scss';

class BooleanField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    getBoolean = value => {
        switch (value) {
            case true:
            case 'true':
            case 1:
            case '1':
            case 'on':
            case 'yes':
                return true;
            default:
                return false;
        }
    };

    render() {
        const {
            name,
            intl: { formatMessage },
            placeholder,
            submitOnChange,
            disabled = false
        } = this.props;

        return (
            <React.Fragment>
                <div className="boolean-wrapper">
                    <div className="boolean-field">
                        <Field
                            placeholder={placeholder || formatMessage({ id: `${name}.placeholder` })}
                            name={name}
                            render={fieldProps => {
                                const {
                                    field: { value },
                                    form: { setFieldValue, submitForm }
                                } = fieldProps;
                                return (
                                    <Switch
                                        onChange={async () => {
                                            if (!!disabled) return;
                                            await setFieldValue(name, !this.getBoolean(value));
                                            if (!!submitOnChange) {
                                                submitForm();
                                            }
                                        }}
                                        checked={this.getBoolean(value)}
                                        disabled={disabled}
                                    />
                                );
                            }}
                        />
                    </div>
                    <div className="boolean-placeholder">{placeholder || formatMessage({ id: `${name}.placeholder`, defaultMessage: 'New Field' })}</div>
                </div>
                <ErrorMessage name={name} component="div" className="validation-message" />
            </React.Fragment>
        );
    }
}

export default injectIntl(BooleanField);
