import React, { Component, createRef } from 'react'
import dagreD3, { GraphLabel } from 'dagre-d3'
import * as d3 from 'd3'
import './dagreStyles.css';

class DagreGraph extends Component {
	svg = createRef()
	innerG = createRef()
	flashTimer;

	static defaultProps = {
		zoomable: false,
		fitBoundaries: false,
		className: 'dagre-d3-react',
	}
	componentDidMount() {
		this._drawChart()
	}
	componentDidUpdate() {
		this._drawChart()
	}

	_getNodeData(id) {
		return this.props.nodes.find((node) => node.id === id)
	}

	_getEdgeData(data) {
		return {
			from: data.v,
			to: data.w,
			fromNode: this._getNodeData(data.v),
			toNode: this._getNodeData(data.w),
		};
	}

	_drawChart = () => {
		const {
			nodes,
			links,
			zoomable,
			fitBoundaries,
			config,
			animate,
			shape,
			onNodeIdClick,
			onNodeButtonClick,
			onRelationshipClick,
			onRelationshipHover,
			onRelationshipHoverEnd,
			onHiddenDotHover,
			onHiddenDotHoverEnd,
			onZoom,
			previousTransform,
			maxDefaultScale,
			minDefaultScale,
			maxScale,
			minScale,
			hideFlash,
		} = this.props
		let g = new dagreD3.graphlib.Graph({compound: true}).setGraph(config || {})

		nodes.forEach((node) => {
			g.setNode(node.id, {
				label: node.label,
				// class: node.class || 'node',
				labelType: node.labelType || 'string',
				...node.config,
			});
			if (node.parent) {
				g.setParent(node.id, node.parent);
			}
		})

		g.nodes().forEach(function(v) {
			var node = g.node(v);
			// Round the corners of the nodes
			node.rx = node.ry = 10;
		});

		if (shape) {
			g.nodes().forEach((v) => (g.node(v).shape = shape))
		}

		links.forEach((link) =>
			g.setEdge(link.source, link.target, { label: link.label || '', class: link.class || 'edge', curve: d3.curveBasis, ...link.config })
			// g.setEdge(link.source, link.target, { label: link.label || '', class: link.class || 'edge', ...link.config })
		)

		let render = new dagreD3.render()
		let svg = d3.select(this.svg.current)
		let inner = d3.select(this.innerG.current)

		let zoom = d3.zoom().on('zoom', () => {
			let transform = d3.event.transform;
			inner.attr('transform', transform)
			if (onZoom) {
				onZoom(transform)
			}
		});
		if (maxScale && minScale) {
			zoom = zoom.scaleExtent([minScale, maxScale]);
		}

		if (zoomable) {
			svg.call(zoom)
		}
		if (animate) {
			g.graph().transition = function transition(selection) {
				return selection.transition().duration(animate || 1000)
			}
		}
		const horizontalShift = '-30';
		const verticalShift = '-30';
		render(inner, g)
		svg.selectAll("g.label")
			.attr("transform", `translate(${horizontalShift},${verticalShift})`);
		svg.selectAll("g.label g")
			.attr("transform", `translate(${horizontalShift},${verticalShift})`);
		svg.selectAll("g.edgeLabel g.label")
			.attr("transform", `translate(0,0)`);

		if (fitBoundaries && !previousTransform) {
			svg.selectAll("g.label")
				.attr("transform", `translate(${horizontalShift},${verticalShift})`);
			svg.selectAll("g.label g")
				.attr("transform", `translate(${horizontalShift},${verticalShift})`);
			svg.selectAll("g.edgeLabel g.label")
				.attr("transform", `translate(0,0)`);
			let bounds = inner.node().getBBox()
			const parent = inner.node().parentElement || inner.node().parentNode
			const fullWidth = (parent.clientWidth || parent.parentNode.clientWidth) - 0
			const fullHeight = (parent.clientHeight || parent.parentNode.clientHeight) - 0
			const width = bounds.width
			const height = bounds.height
			const midX = bounds.x + width / 2
			const midY = bounds.y + height / 2

			if (width === 0 || height === 0) return // nothing to fit

			let scale = 0.7 / Math.max(width / fullWidth, height / fullHeight)

			if (scale > maxDefaultScale) {
				scale = maxDefaultScale
			} else if (scale < minDefaultScale) {
				scale = minDefaultScale
			}

			const translate = [fullWidth / 2 - scale * midX, fullHeight / 2 - scale * midY]
			const transform = d3.zoomIdentity.translate(translate[0], translate[1]).scale(scale)

			svg
				.transition()
				.duration(animate || 0) // milliseconds
				.call(zoom.transform, transform)
		}

		// add node changed attribute to any node whose version changed since last render
		const allNodes = svg.selectAll('g.node');
		const self = this;
		allNodes.each(function(node) {
			let gNode = d3.select(this);
			gNode.attr('dorae-node-changed', false);
		})
		allNodes.each(function(node) {
			let gNode = d3.select(this);
			let _original = self._getNodeData(node)
			const nodeData =  _original.dorae.node;
			if (!nodeData.invisible && nodeData.currentVersion && nodeData.previousVersion && nodeData.currentVersion !== nodeData.previousVersion) {
				// set it to false and then quickly set it to true so the animation
				// will render when the version changes multiple times sequentially
				const flashEntity = entity => {
					entity.attr('dorae-node-changed', false);
					if (!hideFlash) {
						setTimeout(() => entity.attr('dorae-node-changed', true), 10)
						// reset to false to avoid repeated flashing that seems to occasionally occur
						if (this.flashTimer) {
							clearTimeout(this.flashTimer);
						}
						this.flashTimer = setTimeout(() => entity.attr('dorae-node-changed', false), 1510)
						entity.attr('dorae-node-version', nodeData.currentVersion);
					}
				}
				// if the current node is a child of a cluster (dashes are clusters)
				// flash the parent of the node
				if (_original.parent && _original.parent.includes('dash-')) {
					const allClusters = svg.selectAll('g.cluster');
					allClusters.each(function(cluster){
						if (cluster === _original.parent) {
							let gCluster = d3.select(this);
							flashEntity(gCluster);
						}
					})
				}
				flashEntity(gNode);
			}
		})

		if (onNodeIdClick) {
			const allNodes = svg.selectAll('g.node');
			const self = this;
			allNodes.each(function(node) {
				let gNode = d3.select(this);
				let _node = g.node(node);
				let _original = self._getNodeData(node)
				gNode.select('.clickable').on('click', () => {
					onNodeIdClick({ d3node: _node, original: _original })
				})
			})
		}
		if (onNodeButtonClick) {
			const allNodes = svg.selectAll('g.node');
			const self = this;
			allNodes.each(function(node) {
				let gNode = d3.select(this);
				let _node = g.node(node);
				let _original = self._getNodeData(node)
				gNode.select('button').on('click', () => {
					onNodeButtonClick({ d3node: _node, original: _original })
				})
			})
		}
		if (onHiddenDotHover) {
			const self = this;
			svg.selectAll('g.node circle').on('mouseenter', (id) => {
				if (id.includes('dot-')) {
					const dotNode = g.node(id);
					const dotNodeData = self._getNodeData(id);
					onHiddenDotHover({ d3node: dotNode, original: dotNodeData });
				}
			})
		}
		if (onHiddenDotHoverEnd) {
			svg.selectAll('g.node circle').on('mouseout', () => onHiddenDotHoverEnd())
		}
		// add definition for drop shadow
		const defs = d3.select('svg').selectAll('defs');
		const filter = defs.append('filter')
			.attr('id', 'drop_shadow')
			.attr('height', '100%');
		filter.append("feGaussianBlur")
			.attr("in", "SourceAlpha")
			.attr("stdDeviation", 2)
			.attr("result", "blur");

		// svg.selectAll("g.nodes g.label")
		// 	.attr("transform", "translate(0,-30)");
		
		if (onRelationshipClick) {
			svg.selectAll('g.edgeLabel, g.edgePath').on('click', (id) => {
				let _source = g.node(id.v)
				let _original_source = this._getNodeData(id.v)

				let _target = g.node(id.w)
				let _original_target = this._getNodeData(id.w)
				onRelationshipClick({
					d3source: _source,
					source: _original_source,
					d3target: _target,
					target: _original_target,
				})
			})
		}

		if (onRelationshipHover) {
			const allEdges = svg.selectAll('g.edgeLabel, g.edgePath');
			const self = this;
			allEdges.each(function(edge) {
				let d3Edge = d3.select(this);
				let gEdge = g.edge(edge);
				let edgeData = self._getEdgeData(edge)
				d3Edge.on('mouseenter', () => {
					onRelationshipHover(edgeData, gEdge)
				})
			})
		}

		if (onRelationshipHoverEnd) {
			const allEdges = svg.selectAll('g.edgeLabel, g.edgePath');
			const self = this;
			allEdges.each(function(edge) {
				let d3Edge = d3.select(this);
				let gEdge = g.edge(edge);
				let edgeData = self._getEdgeData(edge)
				d3Edge.on('mouseleave', () => {
					onRelationshipHoverEnd(edgeData, gEdge)
				})
			})
		}
	}

	render() {
		const {
			dropShadows = [],
			highlightColor,
		} = this.props;
		
		const dropShadowFilters = []
		dropShadows.forEach(ds => {
			dropShadowFilters.push(
				<filter id={'dropshadow-' + ds.id} key={'dropshadow-' + ds.id} height="150%">
					<feDropShadow 
						dx="0" 
						dy="0" 
						stdDeviation="3"
						floodColor={ds.color}
						floodOpacity="0.9"
						opacity="1"
					/>
				</filter>
			)
			dropShadowFilters.push(
				<filter id={'edgedropshadow' + ds.id} key={'edgedropshadow' + ds.id} height="150%" filterUnits="userSpaceOnUse">
					<feDropShadow 
						dx="0" 
						dy="0" 
						stdDeviation="3"
						floodColor={ds.color}
						floodOpacity="0.9"
						opacity="1"
					/>
				</filter>
			)
		})
		const dropShadow = (
			<feDropShadow 
				dx="0" 
				dy="5" 
				stdDeviation="8"
				floodColor={highlightColor}
				floodOpacity="1"
				opacity="1"
				// height="2000px"
				// width="200%"
			/>
		)
		const miniDropShadow = (
			<feDropShadow 
				dx="0" 
				dy="0" 
				stdDeviation="7"
				floodColor={highlightColor}
				floodOpacity="1"
				opacity="1"
				// height="2000px"
				// width="200%"
			/>
		)
		return (
			<svg width={this.props.width} height={this.props.height} ref={this.svg} className={this.props.className || ''}>
				<g ref={this.innerG} />
				<defs>
					{ dropShadowFilters }
					<filter id="dropshadow" height="150%">
						{ dropShadow }
						{/* <feGaussianBlur in="SourceAlpha" stdDeviation="2"/>
						<feOffset dx="0" dy="0" result="offsetblur"/>
						<feComponentTransfer>
							<feFuncA type="linear" slope="1"/> 
						</feComponentTransfer>
						<feMerge> 
							<feMergeNode/> 
							<feMergeNode in="SourceGraphic"/>
						</feMerge> */}
					</filter>
					<filter id="minidropshadow" height="300%" width="300%" x="-100%" y="-100%">
						{ miniDropShadow }
						{ miniDropShadow }
					</filter>
					<filter id="edgedropshadow" height="150%" filterUnits="userSpaceOnUse">
						{ dropShadow }
					</filter>
				</defs>
			</svg>
		)
	}
}

export default DagreGraph