export const MEDIA_GET = `
    query _($media_id: Long!) {
        media(media_id: $media_id) {
            id
            created_on
            type_id
            type_name
            content_type
            is_ready
            user_file_name

            blob {
                bucket
                key
                size
                mime
                last_modified
                url
            }
        }
    }
`;
