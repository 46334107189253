import React from 'react';
import { FormattedMessage } from 'react-intl';
import { call, hasErrors } from '../configuration/api';
import Queries from './queries';
import { Notifications } from '../components/notifications';
import { PAYMENT_GET_PRODUCTS, PAYMENT_GET_PLANS } from './types';

const defaultState = {
    products: undefined,
    plans: undefined
};

/**
 * Payment state mapper.
 * @param {object} state
 * @param {string} action
 */
export default function(state = defaultState, action) {
    switch (action.type) {
        case PAYMENT_GET_PRODUCTS:
            return { ...state, products: action.payload };

        case PAYMENT_GET_PLANS:
            return { ...state, plans: action.payload };

        default:
            return state;
    }
}

export const getProducts = (onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Payment.ListProducts });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.stripe_product_list) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    dispatch({ type: PAYMENT_GET_PRODUCTS, payload: data.stripe_product_list });
    onComplete(data.stripe_product_list);
};

export const getPlans = (onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Payment.ListPlans });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.stripe_plan_list) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    dispatch({ type: PAYMENT_GET_PLANS, payload: data.stripe_plan_list });
    onComplete(data.stripe_plan_list);
};

export const setPaymentSource = (id, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Payment.SetSource, variables: { payment_source_id: id } });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.company_set_payment_source) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.company_set_payment_source);
};

export const subscribe = (payment_plan_id, seats, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Payment.Subscribe, variables: { payment_plan_id, seats } });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.company_set_payment_plan) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.company_set_payment_plan);
};

export const cancelSubscription = (onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Payment.CancelSubscription });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete();
};



export const previewPayment = (payment_plan_id, seats, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Payment.PreviewPayment, variables: { payment_plan_id, seats } });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.company_preview_payment_plan) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.company_preview_payment_plan);
};

export const retryPayment = (onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Payment.RetryPayment });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.company_retry_payment) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.company_retry_payment);
};

export const listSources = (company_id, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Payment.ListSources, variables: { company_id } });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.company_get_payment_sources) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.company_get_payment_sources);
};
