export const INDICES_UPDATE = `
    mutation M($field_id: Long!, $order_by: Int, $type_id: Int!, $status_id: Int, $title: String!, $description: HtmlString, $extra_info: JsonString) {
        index_field_update(
            field_id: $field_id
            input: { order_by: $order_by, type_id: $type_id, status_id: $status_id, title: $title, description: $description, extra_info: $extra_info }
        ) {
            id
            hash_id
            order_by
            type_id
            type_name
            status_id
            status_name
            title
            description
            extra_info
            is_used
        }
    }
`;
