import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import ReactModal from 'react-modal';
import { listLots } from 'reducers/lots';
import Table from 'components/table';
import { getModels } from 'reducers/models';
import moment from 'moment';
import { getDocumentTemplates } from 'reducers/documents';
import { loadModel } from 'reducers/models';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'pages/models/models.scss';

function LibraryTemplateSelector({ 
    isOpen,
    setOpen,
    currentSelectedDashes = [], 
    currentSelectedDots = [], 
    currentSelectedDocuments = [], 
    getModels,
    getDocumentTemplates,
    onConfirm = () => null,
}) {
    const [activeTab, setActiveTab] = useState('dots');
    const [selectedDashes, setSelectedDashes] = useState(currentSelectedDashes);
    const [selectedDots, setSelectedDots] = useState(currentSelectedDots);
    const [selectedDocuments, setSelectedDocuments] = useState(currentSelectedDocuments);
    const [dotTemplates, setDotTemplates] = useState([]);
    const [totalDotTemplates, setTotalDotTemplates] = useState(0);
    const [dashTemplates, setDashTemplates] = useState([]);
    const [totalDashTemplates, setTotalDashTemplates] = useState(0);
    const [documentTemplates, setDocumentTemplates] = useState([]);
    const [totalDocumentTemplates, setTotalDocumentTemplates] = useState(0);

    useEffect(() => {
        setSelectedDashes(currentSelectedDashes);
        setSelectedDots(currentSelectedDots);
        setSelectedDocuments(currentSelectedDocuments);
    }, [currentSelectedDashes, currentSelectedDots, currentSelectedDocuments])

    const toggleSelectedEntity = (target, list, setList) => {
        const existingIndex = list.findIndex(entity => entity.id === target.id);
        if (existingIndex > -1) {
            // item is in the list - remove it
            const arrayBeforeTarget = list.slice(0, existingIndex);
            const arrayAfterTarget = (existingIndex === list.length - 1) ? [] : list.slice(existingIndex + 1);
            setList(arrayBeforeTarget.concat(arrayAfterTarget));
        } else {
            // item NOT in list - add it
            const newValues = list.slice(0);
            newValues.push(target);
            setList(newValues);
        }
    }

    const loadDotTemplates = (options, onComplete) => {
        getModels(
            { model_type: 2, ...options }, 
            results => {
                setDotTemplates(results.data);
                setTotalDotTemplates(results.total);
                onComplete(results);
            }
        );
    };

    const loadDashTemplates = (options, onComplete) => {
        getModels(
            { model_type: 1, ...options }, 
            results => {
                setDashTemplates(results.data);
                setTotalDashTemplates(results.total);
                onComplete(results);
            }
        );
    };

    const loadDocumentTemplates = (options, onComplete) => {
        getDocumentTemplates(
            { ...options }, 
            results => {
                setDocumentTemplates(results.data);
                setTotalDocumentTemplates(results.total);
                onComplete(results);
            }
        );
    };

    const dashTemplateColumns = [
        {
            isDefault: true,
            headerClassName: 'd-sm-block col-2 d-none',
            className: 'd-xs-block col-2 tx-center',
            resizable: false,
            sortable: false,
            width: 34,
            id: 'actions',
            accessor: d => (
                <div className="actions-wrapper">
                    <input 
                        key={'select-dash-checkbox-' + d.id}
                        type="checkbox" 
                        checked={selectedDashes.findIndex(entity => entity.id === d.id) > -1}  
                        onChange={() => toggleSelectedEntity(d, selectedDashes, setSelectedDashes)}
                    />
                </div>
            )
        },
        {
            headerClassName: 'd-sm-block col d-none',
            className: 'd-sm-block col d-none tx-center id-column',
            Header: <FormattedMessage id="common.id" />,
            width: 150,
            id: 'id',
            accessor: d => <a href='#' onClick={e => { e.stopPropagation(); e.preventDefault(); toggleSelectedEntity(d, selectedDashes, setSelectedDashes); }}>{d.hash_id}</a>
        },
        {
            headerClassName: 'd-sm-block d-none',
            className: 'd-xs-block',
            Header: <FormattedMessage id="common.title" />,
            id: 'title',
            accessor: d => <a href='#' onClick={e => { e.stopPropagation(); e.preventDefault(); toggleSelectedEntity(d, selectedDashes, setSelectedDashes); }}>{(d.title === null || d.title === '') ? '[Untitled]' : d.title}</a>
        },
        {
            headerClassName: 'd-block col-2',
            className: 'd-block col-2',
            Header: <FormattedMessage id="common.createdBy" />,
            id: 'created_by_name',
            accessor: d => d.created_by_name
        },
        {
            headerClassName: 'd-block col-2',
            className: 'd-block col-2',
            Header: <FormattedMessage id="common.createdOn" />,
            id: 'created_on',
            accessor: d => moment(d.created_on).format('DD-MM-YYYY')
        }
    ];

    const dotTemplateColumns = [
        {
            isDefault: true,
            headerClassName: 'd-sm-block col-2 d-none',
            className: 'd-xs-block col-2 tx-center',
            resizable: false,
            sortable: false,
            width: 34,
            id: 'actions',
            accessor: d => (
                <div className="actions-wrapper">
                    <input 
                        key={'select-dot-checkbox-' + d.id}
                        type="checkbox" 
                        checked={selectedDots.findIndex(entity => entity.id === d.id) > -1}  
                        onChange={() => toggleSelectedEntity(d, selectedDots, setSelectedDots)}
                    />
                </div>
            )
        },
        {
            headerClassName: 'd-sm-block col d-none',
            className: 'd-sm-block col d-none tx-center id-column',
            Header: <FormattedMessage id="common.id" />,
            width: 150,
            id: 'id',
            accessor: d => <a href='#' onClick={e => { e.stopPropagation(); e.preventDefault(); toggleSelectedEntity(d, selectedDots, setSelectedDots); }}>{d.hash_id}</a>
        },
        {
            headerClassName: 'd-sm-block d-none',
            className: 'd-xs-block',
            Header: <FormattedMessage id="common.title" />,
            id: 'title',
            accessor: d => <a href='#' onClick={e => { e.stopPropagation(); e.preventDefault(); toggleSelectedEntity(d, selectedDots, setSelectedDots); }}>{(d.title === null || d.title === '') ? '[Untitled]' : d.title}</a>
        },
        {
            headerClassName: 'd-block col-2',
            className: 'd-block col-2',
            Header: <FormattedMessage id="common.createdBy" />,
            id: 'created_by_name',
            accessor: d => d.created_by_name
        },
        {
            headerClassName: 'd-block col-2',
            className: 'd-block col-2',
            Header: <FormattedMessage id="common.createdOn" />,
            id: 'created_on',
            accessor: d => moment(d.created_on).format('DD-MM-YYYY')
        }
    ];

    const documentTemplateColumns = [
        {
            isDefault: true,
            headerClassName: 'd-sm-block col-2 d-none',
            className: 'd-xs-block col-2 tx-center',
            resizable: false,
            sortable: false,
            width: 34,
            id: 'actions',
            accessor: d => (
                <div className="actions-wrapper">
                    <input 
                        key={'select-document-checkbox-' + d.id}
                        type="checkbox" 
                        checked={selectedDocuments.findIndex(entity => entity.id === d.id) > -1}  
                        onChange={() => toggleSelectedEntity(d, selectedDocuments, setSelectedDocuments)}
                    />
                </div>
            )
        },
        {
            headerClassName: 'd-sm-block col d-none',
            className: 'd-sm-block col d-none tx-center id-column',
            Header: <FormattedMessage id="common.id" />,
            width: 100,
            id: 'id',
            accessor: d => <a href='#' onClick={e => { e.stopPropagation(); e.preventDefault(); toggleSelectedEntity(d, selectedDocuments, setSelectedDocuments); }}>{d.hash_id}</a>
        },
        {
            headerClassName: 'd-sm-block d-none',
            className: 'd-xs-block',
            Header: <FormattedMessage id="common.title" />,
            id: 'title',
            accessor: d => <a href='#' onClick={e => { e.stopPropagation(); e.preventDefault(); toggleSelectedEntity(d, selectedDocuments, setSelectedDocuments); }}>{(d.title === null || d.title === '') ? '[Untitled]' : d.title}</a>
        },
        {
            headerClassName: 'd-block col-1',
            className: 'd-block col-1 tx-center',
            Header: <FormattedMessage id="common.type" />,
            id: 'document_type',
            accessor: d => d.document_type === 'word' ? <FormattedMessage id='common.Word'/> : <FormattedMessage id='common.Excel'/>
        },
        {
            headerClassName: 'd-block col-2',
            className: 'd-block col-2 tx-center',
            Header: <FormattedMessage id="common.createdOn" />,
            id: 'created_on',
            accessor: d => moment(d.created_on).format('DD-MM-YYYY')
        },
        {
            headerClassName: 'd-block col-2',
            className: 'd-block col-2 tx-center',
            Header: <FormattedMessage id="common.alteredOn" />,
            id: 'updated_at',
            accessor: d => moment(d.updated_at).format('DD-MM-YYYY')
        }
    ]

    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={() => setOpen(false)}
            className="modal-block dialog"
            overlayClassName="modal-overlay gray"
            style={{content: {width: '85%', maxHeight: '90%', overflowY: 'auto', overflowX: 'hidden'}}}
        >
            
            <div role="document">
                <div className="modal-content bd-0">
                    <div className="modal-header pd-x-20">
                        <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                            <FormattedMessage id="selectTemplates" />
                        </h6>
                    </div>
                    <div className="modal-body pd-x-20">
                        <Tabs forceRenderTabPanel={true}>
                            <TabList>
                                <Tab><h4><FormattedMessage id='selectDotTemplates'/></h4></Tab>
                                <Tab><h4><FormattedMessage id='selectDashTemplates'/></h4></Tab>
                                <Tab><h4><FormattedMessage id='selectDocumentTemplates'/></h4></Tab>
                            </TabList>
                        
                            <TabPanel>
                                <Table
                                    columns={dotTemplateColumns}
                                    data={dotTemplates}
                                    total={totalDotTemplates}
                                    // queryBuilder={setFilters => <QueryBuilder onFiltersSet={setFilters} />}
                                    dataLoader={loadDotTemplates}
                                />
                            </TabPanel>
                            <TabPanel>
                                <Table
                                    columns={dashTemplateColumns}
                                    data={dashTemplates}
                                    total={totalDashTemplates}
                                    // queryBuilder={setFilters => <QueryBuilder onFiltersSet={setFilters} />}
                                    dataLoader={loadDashTemplates}
                                />
                            </TabPanel>
                            <TabPanel>
                                <Table 
                                    columns={documentTemplateColumns} 
                                    data={documentTemplates} 
                                    total={totalDocumentTemplates} 
                                    dataLoader={loadDocumentTemplates}
                                />
                            </TabPanel>
                        </Tabs>
                    </div>
                    <div className="modal-footer justify-content-center">
                        <button
                            type="button"
                            className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                            onClick={() => {
                                onConfirm({ selectedDashes, selectedDots, selectedDocuments });
                                setOpen(false);
                            }}
                        >
                            <FormattedMessage id="common.confirm" />
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                            onClick={() => {
                                setSelectedDots(currentSelectedDots);
                                setSelectedDashes(currentSelectedDashes);
                                setSelectedDocuments(currentSelectedDocuments);
                                setOpen(false);
                            }}
                        >
                            <FormattedMessage id="common.cancel" />
                        </button>
                    </div>
                </div>
            </div>
        </ReactModal>
    );
        
}

const mapStateToProps = state => ({
    models: state.models.list,
    lots: state.lots.list,
    dashes: state.lots.list,
    total: state.lots.total,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getModels,
            listLots,
            loadModel,
            getDocumentTemplates,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LibraryTemplateSelector);
