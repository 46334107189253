export const LOTS_LIST = `
    query(
		$cargo_categories : [Long]
        $order_by: [OrderBy]
        $offset: Int
        $limit: Int
        $status_ids: [Int!]
        $model_ids: [Int!]
        $created_from: DateTime
		$created_to: DateTime
		$criteria: JsonString
		$field_criteria: JsonString
		$result_index_field_ids: [Long!]
		$include_last_visualized: Boolean
	) {
		lot_list (
			input: {
				cargo_categories: $cargo_categories
                status_ids: $status_ids
                created_from: $created_from
				created_to: $created_to
				criteria: $criteria
				field_criteria: $field_criteria
				result_index_field_ids: $result_index_field_ids
				include_last_visualized: $include_last_visualized
            }
            paging: { order_by: $order_by, offset: $offset, limit: $limit }
		) {
			total
			data {		
				id
				hash_id
				created_on
				modified_on
				quantity
				original_quantity
				status_name
				match_strength
				claimed_by_dash_id
				created_by_dash_id
				created_by_dash_company
				created_by_dash_is_accessible
				claimed_by_dash_company
				claimed_by_dash_is_accessible
				cargo_unit_id
				cargo_unit {
					name
					display_name
					description
				}
				cargo_unit_name
				cargo_type_hash_id
				cargo_type_category_title

				last_visualized
				visualized_count

				cargo_type {
					id
					status_id
					status_name
					model_id
					company_id
					user_id
					created_on
					source_cargo_id
					result_cargo_id
					title
					description
					notes
					bc_transaction_id
					extra_info

					index_fields_data
					
					transaction_type
					transaction_type_name

					model {
						id
						title
						company_id
						description

						model_field_list {
							id
							model_id
							order
							type_id
							type_name
							status_id
							status_name
							index_field_id
							input_id
							visibility_check
							title
							description
							is_required
							validation
							width
							force_break
							extra_info
							company_id: model_company_id
							cargo_type
							cargo_reference_type
							transform_cargo_type
						}
					}

					transaction_field_list {
						id
						transaction_id
						field_id
						value
						company_id: model_company_id
						order_by: field_order_by
						lot_id
					}
				}
			}
        }
    }
`;
