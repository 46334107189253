import React from 'react';
import { FormattedMessage } from 'react-intl';
import { call, hasErrors } from '../configuration/api';
import { Notifications } from '../components/notifications';
import { TRANSACTIONS_GET_LIST, TRANSACTIONS_GET_STATUSES } from './types';
import Queries from './queries';

const defaultState = {
    list: undefined,
    total: 0,
    statuses: undefined
};

/**
 * Loading state mapper.
 * @param {object} state
 * @param {string} action
 */
export default function(state = defaultState, action) {
    switch (action.type) {
        case TRANSACTIONS_GET_LIST:
            const { total, data } = action.payload;
            const list = data.some(d => !!d.index_fields_data)
                ? data.map(d => ({ ...d, index_fields_data: undefined, ...JSON.parse(d.index_fields_data) }))
                : data;
            return { ...state, list, total: !!total ? Number.parseInt(total) : data.length };

        case TRANSACTIONS_GET_STATUSES:
            return { ...state, statuses: action.payload };

        default:
            return state;
    }
}

export const getTransactions = (variables, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Transactions.List, variables });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.transaction_list) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    dispatch({ type: TRANSACTIONS_GET_LIST, payload: data.transaction_list });
    onComplete(data.transaction_list);
};

export const getStatuses = (onComplete = () => null) => async dispatch => {
    const transactionStatuses = [
        {id: 1, status_name: "Draft"},
        {id: 2, status_name: "Pending"},
        {id: 3, status_name: "Completed"},
        {id: 4, status_name: "Failed"},
        {id: 5, status_name: "Archived"},
        {id: 6, status_name: "Deleted"},
        {id: 7, status_name: "In Revision"},
        {id: 8, status_name: "Past Version"},
    ]

    dispatch({ type: TRANSACTIONS_GET_STATUSES, payload: transactionStatuses });
    onComplete(transactionStatuses);
};

export const filterTransactions = (variables, onComplete = () => null, includeVisibility = false) => async dispatch => {
    const query = includeVisibility ? Queries.Transactions.SearchWithVisibility : Queries.Transactions.Search;
    const response = await call({ query, variables });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.transaction_search) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    dispatch({ type: TRANSACTIONS_GET_LIST, payload: data.transaction_search });
    onComplete(data.transaction_search);
};

export const deleteTransaction = (variables, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Transactions.Delete, variables });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.transaction_delete) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.transaction_delete);
};

export const cloneTransaction = (variables, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Transactions.Clone, variables });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.transaction_clone) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.transaction_clone);
};

