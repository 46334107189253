import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Tab, Tabs } from '@blueprintjs/core';
import Subscription from './../forms/subscription';
import BillingInfo from './../forms/billingInfo';
import { getProfile } from '../../../../reducers/identity';
import { getPlans, getProducts, subscribe } from 'reducers/payment';
import { history } from '../../../../configuration/history';
import { Notifications } from '../../../../components/notifications';

class PurchasePlan extends React.Component {
    constructor(props) {
        super(props);
        this.state = { tab: '0', seats: 1, isSubmitting: false };
    }

    handleTabChange = tab => {
        this.setState({ tab });

    }

    setSeats = (seats, callback) => this.setState({ ...this.state, seats }, callback);

    purchaseSubscription = seats => {
        const { plan, subscribe, onCancel, getProfile } = this.props;
        this.setState({isSubmitting: true}, () => {
            subscribe(plan.id, seats, () => {
                getProfile(() => {
                    Notifications.success(<FormattedMessage id="page.settings.setup.sub.done" />);
                    this.setState({isSubmitting: false});
                    onCancel();
                    history.push('/settings' + window.location.search);
                });
            });
        });
    };

    render() {
        const { tab } = this.state;
        const { company, price, type, onCancel, plan } = this.props;
        // const isSubscriptionChange = !!company.subscription_id && company.subscription_status !== 'canceled' ? true : false;
        const isSubscriptionChange = true;

        return (
            <Tabs className="purchase-plan-tabs" onChange={this.handleTabChange} selectedTabId={tab}>
                <Tab
                    id="0"
                    title="Seats"
                    panel={
                        <Subscription
                            price={price}
                            type={type}
                            initialSeats={company.subscription_seats}
                            plan={plan}
                            isSubscriptionChange={isSubscriptionChange}
                            isSubmitting={this.state.isSubmitting}
                            onCancel={onCancel}
                            onSubmit={values => {
                                const { seats } = values;
                                this.setSeats(seats, () => {
                                    if (!!company.payment_source) {
                                        this.purchaseSubscription(seats);
                                    } else {
                                        this.handleTabChange('1');
                                    }
                                });
                            }}
                            currency={company.currency_name}
                        />
                    }
                />
                <Tab
                    id="1"
                    title="Credit Card"
                    panel={<BillingInfo onCancel={onCancel} managedSubscription={() => this.purchaseSubscription(this.state.seats)} />}
                />
            </Tabs>
        );
    }
}

const mapStateToProps = state => {
    return {
        company: state.identity.company,
        plans: state.payment.plans,
        products: state.payment.products
    };
};
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getProfile,
            getPlans,
            getProducts,
            subscribe
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PurchasePlan);
