import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import SimpleBar from 'simplebar-react';
import ReactModal from 'react-modal';
import Tooltip from 'rc-tooltip';
import { DocumentSections, DocumentItemTypes } from 'models/document';
import ImageRenderer from './imageRenderer';
import { RowBreak } from 'pages/models/fields/definitions/common';
import { convertFromRaw } from 'draft-js';
import { getRichTextContent } from 'utilities/common';
import { stateToHTML } from 'draft-js-export-html';
import { PAPER_SIZES } from 'utilities/paperSizes';


class DocumentConfiguratorPreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullscreen: false
        };
    }

    setFullscreen = fullscreen => {
        this.setState({ ...this.state, fullscreen });
    };

    noElementsPresent = () => {
        const { template } = this.props;
        let nonePresent = true;
        nonePresent &= !template[DocumentSections.HEADER] || template[DocumentSections.HEADER].length === 0;
        nonePresent &= !template[DocumentSections.BODY] || template[DocumentSections.BODY].length === 0;
        nonePresent &= !template[DocumentSections.FOOTER] || template[DocumentSections.FOOTER].length === 0;
        return nonePresent;
    };

    renderSections = () => {
        const { template } = this.props;
        const noHeader = !template[DocumentSections.HEADER] || template[DocumentSections.HEADER].length === 0;
        const noBody = !template[DocumentSections.BODY] || template[DocumentSections.BODY].length === 0;
        const noFooter = !template[DocumentSections.FOOTER] || template[DocumentSections.FOOTER].length === 0;

        return (
            <React.Fragment>
                {!noHeader && (
                    <div className="doc-t-preview-section doc-t-header">
                        <div className="row mg-0">
                            <div className="col pd-y-30">
                                {template[DocumentSections.HEADER].sort((a, b) => a.order - b.order).map(e => this.renderElement(e))}
                            </div>
                        </div>
                    </div>
                )}
                {!noBody && (
                    <div className="doc-t-preview-section doc-t-body">
                        <div className="row mg-0">
                            <div className="col pd-y-30">
                                {template[DocumentSections.BODY].sort((a, b) => a.order - b.order).map(e => this.renderElement(e))}
                            </div>
                        </div>
                    </div>
                )}
                {!noFooter && (
                    <div className="doc-t-preview-section doc-t-footer">
                        <div className="row mg-0">
                            <div className="col pd-y-30">
                                {template[DocumentSections.FOOTER].sort((a, b) => a.order - b.order).map(e => this.renderElement(e))}
                            </div>
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    };

    renderElement = ({ ref_id, title, type, content, json, url, break_row, text_alignment, bold, italic, underline, width_offset, s3_bucket, s3_object }) => {
        if (width_offset == null){
            width_offset = [0, 12];
        }
        const offset = width_offset[0];
        const width = width_offset[1] - offset;
        const classes = `col-${width} offset-${offset} element-container`;

        if (type === DocumentItemTypes.TEXT) {
            const textClasses = `${classes} tx-${text_alignment} font-weight-${bold ? 'bold' : 'normal'} ${italic ? 'font-italic' : ''} ${
                underline ? 'font-underline' : ''
            }`;
            return (
                <React.Fragment key={ref_id}>
                    <div className={textClasses}>
                        <span>{content}</span>
                    </div>
                    {break_row && width + offset < 12 && <RowBreak width={width + offset} />}
                </React.Fragment>
            );
        }
        if (type === DocumentItemTypes.RICHTEXT) {
            const textClasses = `${classes} tx-${text_alignment}`;
            const richTextObj = getRichTextContent(json);
            if (!richTextObj) {
                return null;
            }
            const richTextMarkup = stateToHTML(convertFromRaw(richTextObj));
            return (
                <React.Fragment key={ref_id}>
                    <div className={textClasses}>
                        <span dangerouslySetInnerHTML={{ __html: richTextMarkup}}/>
                    </div>
                    {break_row && width + offset < 12 && <RowBreak width={width + offset} />}
                </React.Fragment>
            );
        }

        if (type === DocumentItemTypes.IMAGE) {
            const imageClasses = `${classes} image-container`;
            return (
                <React.Fragment key={ref_id}>
                    <div className={imageClasses}>
                        <ImageRenderer title={title} url={url} s3_bucket={s3_bucket} s3_object={s3_object} />
                    </div>
                    {break_row && width + offset < 12 && <RowBreak width={width + offset} />}
                </React.Fragment>
            );
        }
    };

    renderPreviewBlock() {
        const { fullscreen } = this.state;
        const { model, fields, imageData, isLoading, template } = this.props;
        if (!model || !fields) return null;
        let base64src = "data:image/jpeg;base64, " + imageData;

        const button = (
            <Tooltip
                placement={'top'}
                trigger={['hover']}
                overlay={<span>{fullscreen ? <FormattedMessage id="common.exitFullScreen" /> : <FormattedMessage id="common.fullScreen" />}</span>}
            >
                <span className="btn" onClick={() => this.setFullscreen(!fullscreen)}>
                    <i className="material-icons">{fullscreen ? 'fullscreen_exit' : 'fullscreen'}</i>
                </span>
            </Tooltip>
        );

        return (
            <div className="widget-2 mg-b-20 doc-t-preview">
                <div className="card overflow-hidden">
                    <div className="card-header">
                        <h6 className="card-title">
                            <FormattedMessage id="common.preview" />
                        </h6>
                        <div className="btn-group field-list-buttons">{button}</div>
                    </div>
                    <div className="card-body pd-0 bd-color-gray-lighter">
                        <div className="row no-gutters">
                            <div className="col-12">
                                <SimpleBar>
                                    {
                                        isLoading ? (
                                            <div className="loading-indicator">
                                                <div className="loader" />
                                                <span className="mg-l-5">Loading ...</span>
                                            </div>
                                        ) :
                                        (
                                            <div className="shadow border" style={{margin: 'auto', maxWidth:  template.paper_size ? PAPER_SIZES.filter(ps => ps.name === template.paper_size)[0].maxWidth :  PAPER_SIZES.filter(ps => ps.name === 'A4')[0].maxWidth}}>
                                                <img src={base64src} className="preview-image img-fluid" alt="preview" />
                                            </div>
                                        )
                                    }
                                    
                                </SimpleBar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { fullscreen } = this.state;

        return (
            <React.Fragment>
                {!fullscreen && this.renderPreviewBlock()}
                <ReactModal
                    isOpen={fullscreen}
                    onRequestClose={() => this.setFullscreen(false)}
                    className="modal-block full model-preview-modal"
                    overlayClassName="modal-overlay full"
                >
                    {fullscreen && this.renderPreviewBlock()}
                </ReactModal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    company: state.identity.company,
    model: state.fields.model,
    fields: state.fields.items,
    fieldTypes: state.fields.types,
    template: state.document.instance
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DocumentConfiguratorPreview);
