import React from 'react';
import { call, hasErrors } from '../configuration/api';
import Queries from './queries';
import { FormattedMessage } from 'react-intl';
import { Notifications } from '../components/notifications';

const GET_ENTITY_GRAPH = 'GET_ENTITY_GRAPH';
const SET_GRAPH_ENTITY = 'SET_GRAPH_ENTITY';

const defaultState = {
    entityGraph: {},
    entityType: null,
    entityId: null,
};

/**
 * Document state mapper.
 * @param {object} state
 * @param {string} action
 */
export default function(state = defaultState, action) {
    switch (action.type) {
        case GET_ENTITY_GRAPH:
            return { ...state, nodes: action.data.nodes };

        case SET_GRAPH_ENTITY:
            return { ...state, entityType: action.entityType, entityId: action.entityId }

        default:
            return state;
    }
}

export const getEntityGraph = (input, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.EntityGraph.GetEntityGraph, variables: input });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.entity_graph) {
        onError();
        return;
    }
    dispatch({ type: GET_ENTITY_GRAPH, data: data.entity_graph })
    onComplete(data.entity_graph);
};

export const visitEntityGraph = (input, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.EntityGraph.VisitEntityGraph, variables: input });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.entity_graph_visit) {
        onError();
        return;
    }
    onComplete(data.entity_graph_visit);
};

export const setGraphEntity = (input, onComplete = () => null) => async dispatch => {
    dispatch({ type: SET_GRAPH_ENTITY, entityType: input.entityType, entityId: input.entityId })
    onComplete();
}

export const createEntityGraphLink = (input, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.EntityGraph.CreateLink, variables: input });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.entity_graph_create_link) {
        onError();
        return;
    }
    onComplete(data.entity_graph_create_link);
};