import { Map } from 'immutable';
import { canInteract } from '../components/routes/permissions/permissionResolver';
import Account from './account';
import Companies from './companies';
import Settings from './settings';
import Dashboard from './dashboard';
import CargoCategories from './cargo-categories';
import EventTemplates from './event-templates';
import Profile from './profile';
import Transactions from './events';
import Links from './links';
import Impersonate from './account/impersonate';
import Subscribe from './account/forms/presubmission';
import Lots from './lots';
import DocumentTemplatesPage from './documentTemplates';
import Intelligence from './intelligence';
import Sharing from './sharing';
import Visualize from './visualization';
import LibraryPages from './library'
import CollectionsPages from './collections';

export { Account, Companies, Settings, Dashboard, CargoCategories, EventTemplates, Profile, Subscribe, Transactions, Links, Impersonate, Lots, DocumentTemplatesPage, Intelligence, Sharing, Visualize, LibraryPages, CollectionsPages,};

const listPages = () => {
    return Map(require('./')).filter(p => p.meta && p.meta.routes);
};
export const getPages = (menuType, permissions, entityPermissions, company) => {
    const checkSubIfRequired = (meta, company) => (!!meta.onlySubscribed ? !!company.is_paid : true);
    const pages = listPages()
        .filter(p => p.meta.menus && p.meta.menus[menuType] && canInteract(entityPermissions, p.meta.requires) && checkSubIfRequired(p.meta, company))
        .map(p => p.meta);

    return pages
        ? pages
              .valueSeq()
              .toArray()
              .sort((a, b) => a.order - b.order)
        : [];
};

export const getCurrentPage = path => {
    const page = listPages().find(p => p.meta.routes.includes(path));
    return page ? page.meta : {};
};
