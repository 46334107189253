export const TRANSACTIONS_GET_RELATED_DATA = `
    query _($transaction_id: Id!) {
        transaction_related_data(input: { transaction_id: $transaction_id }) {
            cargo_types {
                id
                status_id
                status_name
                model_id
                company_id
                user_id
                created_on
                source_cargo_id
                result_cargo_id
                title
                description
                notes
                bc_transaction_id
                extra_info
                
                transaction_type
                transaction_type_name
    
                model {
                    id
                    title
                    company_id
                    description
                    
                    model_field_list {
                        id
                        model_id
                        order
                        type_id
                        type_name
                        status_id
                        status_name
                        index_field_id
                        input_id
                        visibility_check
                        title
                        description
                        is_required
                        validation
                        width
                        force_break
                        extra_info
                        company_id: model_company_id
                        cargoCategory: cargo_category
                        cargo_type
                        cargo_reference_type
                        transform_cargo_type
                        is_static_unit
                        default_unit
        
                        model_field_value_list {
                            id
                            company_id
                            field_id
                            order_by
                            is_hidden
                            value
                        }
        
                        index_field {
                            id
                            order_by
                            type_id
                            type_name
                            status_id
                            status_name
                            title
                            description
                            extra_info
                        }
                    }
                }
    
                transaction_field_list {
                    id
                    transaction_id
                    field_id
                    value
                    company_id: model_company_id
                    order_by: field_order_by
                    lot_id
                    quantity
                    cargo_unit_id
                    input_lots {
                        lot_id
                        quantity
                    }
                    output_lots {
                        lot_type
                        quantity
                        cargo_unit_id
                    }
                    use_mapped_value
                    use_mapped_quantity_value
                    use_mapped_unit_value
                    restore_value
                    restore_quantity_value
                    restore_unit_value
                    all_dots_consumed
                    allow_user_transform
                    use_user_transform
                }
            }

            cargo_categories {
                id
                hash_id
                company_id
                status_id
                status_name
                created_on
                title
                description
                is_used
                split_conditions
                extra_info
                model_type
                model_type_name

                model_users {
                    id
                    include_transactions
                }
                
                model_field_list {
                    id
                    model_id
                    order
                    type_id
                    type_name
                    status_id
                    status_name
                    index_field_id
                    input_id
                    visibility_check
                    title
                    description
                    is_required
                    validation
                    width
                    force_break
                    extra_info
                    company_id: model_company_id
                    cargoCategory: cargo_category
                    cargo_type
                    transform_cargo_type
                    cargo_reference_type
                    is_static_unit
                    default_unit

                    model_field_value_list {
                        id
                        company_id
                        field_id
                        order_by
                        is_hidden
                        value
                    }

                    index_field {
                        id
                        order_by
                        type_id
                        type_name
                        status_id
                        status_name
                        title
                        description
                        extra_info
                    }
                }
            }

            lots {
                id
				hash_id
				created_on
				modified_on
                quantity
                original_quantity
                claimed_by_dash_id
				created_by_dash_id
                created_by_dash_company
                created_by_dash_is_accessible
                claimed_by_dash_company
                claimed_by_dash_is_accessible
				status_name
				cargo_unit_id
				cargo_unit {
					name
					display_name
					description
				}
				cargo_unit_name
				cargo_type_hash_id
				cargo_type_category_title
				cargo_type {
					id
					status_id
					status_name
					model_id
					company_id
					user_id
					created_on
					source_cargo_id
					result_cargo_id
					title
					description
					notes
					bc_transaction_id
					extra_info
					
					transaction_type
					transaction_type_name
				}
            }

            past_versions {
                id
                status_id
                status_name
                model_id
                company_id
                user_id
                created_on
                source_cargo_id
                result_cargo_id
                title
                description
                notes
                bc_transaction_id
                extra_info
                
                transaction_type
                transaction_type_name
    
                model {
                    id
                    title
                    company_id
                    description
                    
                    model_field_list {
                        id
                        model_id
                        order
                        type_id
                        type_name
                        status_id
                        status_name
                        index_field_id
                        input_id
                        visibility_check
                        title
                        description
                        is_required
                        validation
                        width
                        force_break
                        extra_info
                        company_id: model_company_id
                        cargoCategory: cargo_category
                        cargo_type
                        cargo_reference_type
                        transform_cargo_type
                        is_static_unit
                        default_unit
        
                        model_field_value_list {
                            id
                            company_id
                            field_id
                            order_by
                            is_hidden
                            value
                        }
        
                        index_field {
                            id
                            order_by
                            type_id
                            type_name
                            status_id
                            status_name
                            title
                            description
                            extra_info
                        }
                    }
                }
    
                transaction_field_list {
                    id
                    transaction_id
                    field_id
                    value
                    company_id: model_company_id
                    order_by: field_order_by
                    lot_id
                    quantity
                    cargo_unit_id
                    input_lots {
                        lot_id
                        quantity
                    }
                    output_lots {
                        lot_type
                        quantity
                        cargo_unit_id
                    }
                    use_mapped_value
                    use_mapped_quantity_value
                    use_mapped_unit_value
                    restore_value
                    restore_quantity_value
                    restore_unit_value
                    all_dots_consumed
                    allow_user_transform
                    use_user_transform
                }
            }

            field_mappings {
                owning_dash_field_id
                owning_dot_field_id
                owning_special_dot_field_id
                map_dash_field_id
                map_dot_field_id
                map_special_dot_field
                compound_id
            }
        }
    }
`;
