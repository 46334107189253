import React from 'react';
import { FormattedMessage } from 'react-intl';
import { call, hasErrors, upload } from '../configuration/api';
import { DataStorage } from '../utilities/storage';
import { validateToken, isAboutToExpire } from '../utilities/common';
import Queries from './queries';
import { Notifications } from '../components/notifications';
import { IDENTITY_AUTHENTICATE, IDENTITY_LOGOUT, IDENTITY_PROFILE } from './types';

/**
 * Check token and refresh it.
 */
export const authenticate = (onComplete = () => null) => async dispatch => {
    const tokenData = DataStorage.getToken();
    if (!tokenData || !validateToken(tokenData)) {
        dispatch({ type: IDENTITY_LOGOUT });
        return;
    }

    if (isAboutToExpire(tokenData)) {
        const response = await call({ query: Queries.Identity.Authenticate });
        if (hasErrors(response)) {
            onComplete();
            return;
        }

        if (!DataStorage.setToken(response.data)) return;
        dispatch({ type: IDENTITY_AUTHENTICATE, payload: tokenData });
        onComplete(response.data);
    }
};

/**
 * Clear tokens from storage.
 */
export const logout = () => async dispatch => {
    DataStorage.clearAll();
    dispatch({ type: IDENTITY_LOGOUT });
};

/**
 * Authenticate with email and password.
 * @param {*} values object containing email and password.
 */
export const login = (values, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Identity.Login, variables: { ...values } }, false);
    if (hasErrors(response, true, false)) {
        onError(response);
        return;
    }

    const { data } = response;
    if (!data || !data.signin) {
        return;
    }

    dispatch({ type: IDENTITY_AUTHENTICATE, payload: data.signin });
    onComplete(data.signin);
};

/**
 * Get profile for current user.
 */
export const getProfile = (onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Identity.Profile });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.user) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    dispatch({ type: IDENTITY_PROFILE, payload: data.user });
    onComplete(data.user);
};

/**
 * Impersonate user.
 */
export const impersonate = (id, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Identity.Impersonate, variables: { user_id: id } });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.user_switch) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    DataStorage.local.setItem(id, JSON.stringify(data.user_switch.token));
    onComplete(data.user_switch);
};

/**
 * Request password change.
 */
export const requestPasswordChange = (email, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Identity.RequestPasswordChange, variables: { login: email } });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.reset_password) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.reset_password);
};

/**
 * Change password.
 */
export const changePassword = ({ current_password = undefined, password, reset_password = false }, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Identity.ChangePassword, variables: { current_password, password, reset_password } });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.user_set_password) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.user_set_password);
};

/**
 * Reset password.
 */
export const resetPassword = ({ password, token_from_link }, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Identity.ResetPassword, variables: { password, token_from_link } });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.link_set_password) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.link_set_password);
};

/**
 * Reset password.
 */
export const inviteSetPassword = ({ link_code, password, token_from_link }, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Identity.InviteSetPassword, variables: { link_code, password, token_from_link } });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.link_user_invite) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.link_user_invite);
};

export const uploadAvatar = (files, onComplete = () => null, onError = () => null) => async dispatch => {
    if (!files || files.length < 1) return;
    const file = files[0];
    const formData = new FormData();
    formData.append('json', JSON.stringify({ query: Queries.Identity.UploadAvatar }));
    formData.append('file', file, file.name);

    const response = await upload(formData);
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.user_upload_avatar) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }

    onComplete(data.user_upload_avatar);
};
