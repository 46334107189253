import React from 'react';
import { injectIntl } from 'react-intl';
import { Field, ErrorMessage } from 'formik';
import Select from 'react-select';
import { getWidthAndOffset, capitalize } from '../../utilities/common';

import './fields.scss';

class ImageAlignmentField extends React.Component {
    handleChange = async (alignment, form) => {
        const { width, offset } = getWidthAndOffset(this.props.width, alignment);

        if (!['left', 'center', 'right'].includes(alignment)) return;

        await form.setFieldValue('alignment_ignore', alignment);
        await form.setFieldValue('width', width);
        await form.setFieldValue('offset', offset);
        await form.setFieldValue('break_row', true);
        form.submitForm();
    };

    render() {
        const {
            intl: { formatMessage },
            multi = false,
            value,
            placeholder,
            disabled = false
        } = this.props;
        const customStyles = {
            control: base => ({
                ...base,
                boxShadow: 'none'
            })
        };

        const name = 'alignment_ignore';
        const options = ['left', 'center', 'right'].map(t => ({ value: t, label: capitalize(t) }));

        return (
            <React.Fragment>
                <Field
                    {...this.props}
                    render={({ form }) => {
                        const showLabel = multi ? !!value && value.length > 0 : !!value;
                        const fieldValue = multi
                            ? options.filter(o => {
                                  const valueArray = Array.isArray(value) ? value : [];
                                  return valueArray.includes(o.value);
                              })
                            : options.find(o => o.value === value);

                        return (
                            <div style={{ position: 'relative' }}>
                                <Select
                                    placeholder={formatMessage({ id: `${name}.placeholder` })}
                                    name={name}
                                    options={options}
                                    onChange={selectedValue => {
                                        if (!!disabled) return;
                                        const value = !!selectedValue
                                            ? Array.isArray(selectedValue)
                                                ? selectedValue.map(s => s.value)
                                                : selectedValue.value
                                            : '';

                                        this.handleChange(value, form);
                                    }}
                                    onBlur={form.handleBlur}
                                    value={fieldValue}
                                    isMulti={multi}
                                    components={{ IndicatorSeparator: () => null }}
                                    theme={theme => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#eeeeee',
                                            primary: '#00336B'
                                        }
                                    })}
                                    styles={customStyles}
                                />
                                {!!showLabel && <div className="select-input-placeholder">{placeholder || formatMessage({ id: `${name}.placeholder` })}</div>}
                            </div>
                        );
                    }}
                />
                <ErrorMessage name={name} component="div" className="validation-message" />
            </React.Fragment>
        );
    }
}

export default injectIntl(ImageAlignmentField);
