import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import DocumentTemplatesList from './containers/list';
import DocumentTemplate from './containers/documentTemplate'
import DocumentBuilder from './containers/documentBuilder'
import { DefinedPermissions } from '../../components/routes/permissions/permissionResolver';

class DocumentTemplatesPage extends React.Component {
    render() {
        return (
            <Switch>
                <Route exact path="/document-templates" render={props => <DocumentTemplatesList {...props} setRootLoadingState={this.props.setRootLoadingState} />} />
                <Route exact path="/document-templates/:id" render={props => <DocumentTemplate {...props} parents={[]} setRootLoadingState={this.props.setRootLoadingState} />} />
                <Route path="/document-templates/:id/builder" render={props => <DocumentBuilder {...props} parents={[DocumentTemplate.meta]} setRootLoadingState={this.props.setRootLoadingState} />} />
            </Switch>
        );
    }
}

DocumentTemplatesPage.meta = {
    title: <FormattedMessage id="page.documentTemplates.title" />,
    subtitle: <FormattedMessage id="page.documentTemplates.subtitle" />,
    routes: ['/document-templates'],
    order: 50,
    icon: 'ion-ios-paper-outline',
    menus: { main: true, user: false },
    access: DefinedPermissions.ModelCrud,
    requires: ['model', 'read'],
    onlySubscribed: true
};

export default DocumentTemplatesPage;
