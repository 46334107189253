import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import SimpleBar from 'simplebar-react';
import ReactModal from 'react-modal';
import { Formik, Form } from 'formik';
import Tooltip from 'rc-tooltip';
import { Fields } from '../../../../components/fields';
import { uuid } from '../../../../utilities/common';
import FieldDefinition, { FieldTypes } from '../../../../models/field';
import FieldPreview from './fieldPreview';
import ModelProperties from '../definitions/modelProperties';
import { getComparators } from '../../../../reducers/fieldVisibility';
import PrintContainer from '../../../../components/print';
import { arrayEquals } from '../../../../utilities/common'
import { evaluateConditions } from './visibilityConditions/conditionParser';
import { RowBreak } from '../definitions/common'
import moment from 'moment';


import './../../models.scss';

class ModelPreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullscreen: false,
            print: false,
            showFieldMaps: false,
        };
    }

    componentDidMount() {
        const { comparators, getComparators } = this.props;
        if (!comparators) getComparators();
    }

    setFullscreen = fullscreen => {
        this.setState({ ...this.state, fullscreen });
    };

    setPrint = print => {
        this.setState({ ...this.state, print });
    };

    getInitialValues = () => {
        const { fields, manualFields } = this.props;
        if (!fields && !manualFields) return {};

        let fieldsToUse = !!manualFields ? manualFields : fields;

        const initialValues = {};
        fieldsToUse.forEach(({ id, type, defaultValue, dateFormat }) => {
            if (defaultValue !== undefined) {
                if (type === FieldTypes.FIELD_DATE) {
                    if (!defaultValue  || defaultValue === 'Invalid date') {
                        initialValues[id] = '';
                    } else {
                        initialValues[id] = moment(defaultValue, dateFormat).format(dateFormat);
                    }
                } else {
                    initialValues[id] = defaultValue;
                }
            } else if (type === FieldTypes.FIELD_MULTI_TEXT) {
                initialValues[id] = [''];
            } else {
                initialValues[id] = '';
            }
        });
        // Add default values for cargo fields
        if (this.props.cargoCategories) {
            this.props.cargoCategories
                .filter(cc => {
                    // Only populate values for cargo categories referenced by this template
                    for (const field of fieldsToUse) {
                        if (field.cargoCategory === cc.id) {
                            return true;
                        }
                    }
                    return false;
                })
                .forEach(cc => {
                    fieldsToUse.filter(f => f.cargoCategory === cc.id).forEach(referringField => {
                        const prefix = referringField ? 'cc' + referringField.id + '-' : 'cc';
                        initialValues[prefix + cc.id] = [];
                        const ccFields = (cc.model_field_list || []).map(f => FieldDefinition.fromDB(f));
                        ccFields.forEach(({ id, type, defaultValue, dateFormat }) => {
                            if (type !== FieldTypes.FIELD_SECTION) {
                                let ccFieldValue = undefined;
                                if (this.props.cargoTypes && referringField.cargoType) {
                                    const cargoType = this.props.cargoTypes.find(ct => ct.id === referringField.cargoType);
                                    if (cargoType) {
                                        ccFieldValue = (cargoType.transaction_field_list || []).find(v => v.field_id === id);
                                    }
                                }

                                if (ccFieldValue !== undefined && !!ccFieldValue.value) {
                                    if (ccFieldValue.value.startsWith('{')) {
                                        const valueObject = JSON.parse(ccFieldValue.value);
                                        const valueProperty =valueObject[Object.keys(valueObject)[0]];
                                        if (type === FieldTypes.FIELD_DATE) {
                                            if (!valueProperty  || valueProperty === 'Invalid date') {
                                                initialValues[prefix + cc.id][id] = '';
                                            } else {
                                                initialValues[prefix + cc.id][id] = moment(valueProperty, dateFormat).format(dateFormat);
                                            }
                                        } else {
                                            initialValues[prefix + cc.id][id] = valueProperty;
                                        }
                                    } else {
                                        initialValues[prefix + cc.id][id] = ccFieldValue.value;
                                    }
                                } else if (defaultValue !== undefined) {
                                    if (type === FieldTypes.FIELD_DATE) {
                                        initialValues[prefix + cc.id][id] = moment(defaultValue, dateFormat).format(dateFormat);
                                    } else {
                                        initialValues[prefix + cc.id][id] = defaultValue;
                                    }
                                } else if (type === FieldTypes.FIELD_MULTI_TEXT) {
                                    initialValues[prefix + cc.id][id] = [''];
                                } else {
                                    initialValues[prefix + cc.id][id] = '';
                                }
                            }
                        });
                    })
                });
        }
        return initialValues;
    };

    getValidationSchema = () => {
        const { fields = [], manualFields } = this.props;
        const fieldsToUse = !!manualFields ? manualFields : fields;
        const requiredFields = fieldsToUse.filter(f => f.is_required);
        if (requiredFields.length > 0) {
            const validationSchema = {};
            requiredFields.forEach(f => {
                validationSchema[f.id] = Yup.mixed().required(<FormattedMessage id="validation.required" />);
            });

            return Yup.object().shape(validationSchema);
        }

        return undefined;
    };

    getGroupedSections = (specifiedFields = null) => {
        const { fields, manualFields } = this.props;

        if (specifiedFields === null) {
            specifiedFields = !!manualFields ? manualFields : fields;
        }

        if (!specifiedFields || specifiedFields.length === 0) return [];

        specifiedFields.sort((a,b) => {
            if (a.order < b.order) return -1;
            else if (a.order === b.order) return 0;
            else return 1;
        });
        const rootFields = [];

        let inSection = false;
        let currentSection = null;
        let currentSectionId = null;
        const sectionChildren = [];

        for (let field of specifiedFields) {
            if (field.type === FieldTypes.FIELD_END_SECTION) {
                if (inSection) {
                    sectionChildren[currentSectionId].push(field);
                    currentSection.children = sectionChildren[currentSectionId].map(f => f.id);
                    rootFields.push(currentSection);
                } else {
                    // otherwise it is just a root field
                    rootFields.push(field);
                }
                inSection = false;
            } else if (field.type === FieldTypes.FIELD_SECTION) {
                if (inSection) {
                    // Already in a section - we must complete that one
                    currentSection.children = sectionChildren[currentSectionId].map(f => f.id);
                    rootFields.push(currentSection);
                } else {
                    // we are in one now
                    inSection = true;
                }
                currentSection = field;
                currentSectionId = field.id;
                sectionChildren[currentSectionId] = [];
            } else if (field.type === FieldTypes.FIELD_CARGO) {
                if (inSection) {
                    // we were already in a section - we must complete that one
                    currentSection.children = sectionChildren[currentSectionId].map(f => f.id);
                    rootFields.push(currentSection);
                    inSection = false;
                }
                // Add the cargo field as if it's a normal field
                rootFields.push(field);
            } else {
                // 'regular' field - if we're in a section, add it to the section children
                if (inSection) {
                    sectionChildren[currentSectionId].push(field);
                } else {
                    // otherwise it is just a root field
                    rootFields.push(field);
                }
            }
        }
        // if the last field is part of a section, we need to finalize that section
        if (inSection) {
            currentSection.children = sectionChildren[currentSectionId].map(f => f.id);
            rootFields.push(currentSection);
        }
        return rootFields;
    };

    renderPreviewBlock = () => {
        const { fullscreen } = this.state;
        const { fields, manualFields, modelId, comparators, modelTypeId, cargoCategories = [], cargoTypes = [], hideMapFieldToggle = false, hideHeader = false, hideTitle = false } = this.props;
        if (!comparators) return null;

        const fieldsToUse = !!manualFields ? manualFields : fields;

        const groupedFields = this.getGroupedSections();

        const fullScreenButton = (
            <Tooltip
                placement={'top'}
                trigger={['hover']}
                overlay={<span>{fullscreen ? <FormattedMessage id="common.exitFullScreen" /> : <FormattedMessage id="common.fullScreen" />}</span>}
            >
                <span className="btn" onClick={() => this.setFullscreen(!fullscreen)}>
                    <i className="material-icons">{fullscreen ? 'fullscreen_exit' : 'fullscreen'}</i>
                </span>
            </Tooltip>
        );
        const printButton = (
            <Tooltip placement={'top'} trigger={['hover']} overlay={<FormattedMessage id="common.print" />}>
                <span
                    className="btn"
                    onClick={async () => {
                        await this.setPrint(true);
                        await window.print();
                        this.setPrint(false);
                    }}
                >
                    <i className="material-icons-outlined">print</i>
                </span>
            </Tooltip>
        );

        return (
            <div className={this.props.overrideClass ? this.props.overrideClass : "fields-preview widget-2 mg-b-20 hide-multi-br"}>
                <div className="card">
                    { !hideHeader &&
                        <div className="card-header">
                            <h6 className="card-title">
                                <FormattedMessage id="common.preview" />
                            </h6>
                            {
                                modelTypeId === 1 && !hideMapFieldToggle && (
                                    <label className="ckbox se-show-mapped-fields-checkbox" style={{width: '14em'}}>
                                        <input type="checkbox" checked={this.state.showFieldMaps} onChange={(e) => { e.stopPropagation(); this.setState(prevState => ({ showFieldMaps: !prevState.showFieldMaps }), () => this.props.onShowFieldMaps(this.state.showFieldMaps))}}/>
                                        <span className={`ckbox-label-text${this.state.showFieldMaps ? ' checked' : ''}`}><FormattedMessage id="showFieldMaps" /></span>
                                    </label>
                                )
                            }
                            <div className="btn-group field-list-buttons">
                                {printButton}
                                {fullScreenButton}
                            </div>
                        </div>
                    }
                    <div className="card-body pd-0 bd-color-gray-lighter">
                        <div className="row no-gutters">
                            <div className="col-12">
                                <SimpleBar>
                                    <Formik
                                        key={fieldsToUse ? JSON.stringify(fieldsToUse.map(f => FieldDefinition.toDB(f))) : fieldsToUse}
                                        enableReinitialize={false}
                                        initialValues={this.getInitialValues()}
                                        validationSchema={this.getValidationSchema()}
                                        onSubmit={() => null}
                                        render={formProps => (
                                            <Form noValidate>
                                                <div className="field-preview-wrapper">
                                                    {
                                                        !hideTitle && <>
                                                            <ModelProperties id={modelId} />
                                                            <div className={`field-text pd-b-15 col-12`}>
                                                                <Fields.Input name="title" />
                                                            </div>
                                                        </>
                                                    }
                                                    {groupedFields.map(f => {
                                                        if (f.type === FieldTypes.FIELD_CARGO) {
                                                            // Test if this field is visible here, since we will not pass external fields to the FieldPreview
                                                            const { values } = formProps;

                                                            const result = evaluateConditions(f, values, fieldsToUse, comparators);
                                                            if (!result) {
                                                                return <RowBreak width={12} />;
                                                            }
                                                            const cargoCategory = cargoCategories ? cargoCategories.find(cc => cc.id === f.cargoCategory) : undefined;
                                                            const cargoCategoryFields = cargoCategory === undefined ? [] : cargoCategory.model_field_list.map(f => FieldDefinition.fromDB(f));
                                                            const cargoTypeFields = [];
                                                            cargoTypes.filter(ct => ct.id === f.cargoType).forEach(ct => {
                                                                ct.transaction_field_list.forEach(field => {
                                                                    cargoTypeFields.push(field);
                                                                })
                                                            })
                                                            const formattedCargoFields = cargoCategoryFields.map(ccf => Object.assign({}, ccf, { id: 'cc' + f.id + '-' + f.cargoCategory + '.' + ccf.id }));
                                                            const incompletedCargoFields = cargoCategoryFields.filter(fieldToShow => {
                                                                const fieldInCargoType = cargoTypeFields.find(f => f.field_id === fieldToShow.id);
                                                                if (fieldInCargoType === undefined) {
                                                                    return true;
                                                                }
                                                                const fieldValueObj = JSON.parse(fieldInCargoType.value);
                                                                const valuePropertyName = fieldToShow.type.toLowerCase() + '_value';
                                                                if (fieldValueObj.hasOwnProperty(valuePropertyName)) {
                                                                    const fieldValue = fieldValueObj[valuePropertyName];
                                                                    if (fieldValue === null || fieldValue === undefined || fieldValue === "" || arrayEquals(fieldValue, [""])) {
                                                                        return true;
                                                                    } else {
                                                                        return false;
                                                                    }
                                                                }
                                                                return true;
                                                            }).map(ccf => 'cc' + f.id + '-' + f.cargoCategory + '.' + ccf.id);
                                                            const completedCargoFieldIds = formattedCargoFields.filter(f => !incompletedCargoFields.includes(f.id)).map(f => f.id);
                                                            // Present the Cargo field as a section containing the Cargo Category fields with prepopulated fields disabled
                                                            const cargoCategorySection = new FieldDefinition(f);
                                                            cargoCategorySection.title = f.title + (cargoCategory === undefined ? '' : ' (' + cargoCategory.title + ')');
                                                            cargoCategorySection.type = FieldTypes.FIELD_CARGO;
                                                            const cargoChildren = this.getGroupedSections(formattedCargoFields);
                                                            cargoCategorySection.children = cargoChildren.map(f => f.id);
                                                            
                                                            return <FieldPreview 
                                                                    key={f.id} 
                                                                    field={cargoCategorySection} 
                                                                    fields={formattedCargoFields} 
                                                                    formProps={formProps} 
                                                                    comparators={comparators} 
                                                                    modelTypeId={2} 
                                                                    prefilledFieldIds={completedCargoFieldIds}
                                                                    showPrefilledFields={true}
                                                                    rerenderOnChange={true}
                                                                />
                                                        } else {
                                                            return <FieldPreview 
                                                                    key={f.id + `-${f.type}`} 
                                                                    field={f} 
                                                                    fields={fieldsToUse} 
                                                                    formProps={formProps} 
                                                                    comparators={comparators} 
                                                                    modelTypeId={modelTypeId} 
                                                                    showIsRequired={f.is_required}
                                                                    rerenderOnChange={true}
                                                                />
                                                        }
                                                    })}
                                                    <div className="col-12">
                                                        <div className="row d-flex justify-content-center align-items-center pd-y-15 mg-b-15">
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    />
                                </SimpleBar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { fullscreen, print } = this.state;
        const { fields, modelId, comparators, modelTypeId } = this.props;
        if (!comparators) return null;

        const groupedFields = this.getGroupedSections();

        return (
            <React.Fragment>
                {!fullscreen && this.renderPreviewBlock()}
                <ReactModal
                    isOpen={fullscreen}
                    onRequestClose={() => this.setFullscreen(false)}
                    className="modal-block full model-preview-modal"
                    overlayClassName="modal-overlay full"
                >
                    {fullscreen && this.renderPreviewBlock()}
                </ReactModal>
                <PrintContainer open={print} onClose={() => this.setPrint(false)}>
                    <Formik
                        key={uuid()}
                        enableReinitialize={true}
                        initialValues={this.getInitialValues()}
                        validationSchema={this.getValidationSchema()}
                        onSubmit={() => null}
                        render={formProps => (
                            <Form noValidate>
                                <div className="field-preview-wrapper">
                                    <ModelProperties id={modelId} />
                                    <div className={`field-text pd-b-15 col-12`}>
                                        <Fields.Input name="title" />
                                    </div>
                                    {groupedFields.map(f => (
                                        <FieldPreview key={f.id} field={f} fields={fields} formProps={formProps} comparators={comparators} modelTypeId={modelTypeId} />
                                    ))}
                                    <div className="col-12">
                                        <div className="row d-flex justify-content-center align-items-center pd-y-15 mg-b-15">
                                            <div className="col-sm-6 col-md-3">
                                                <button type="submit" className="btn btn-primary btn-block">
                                                    <FormattedMessage id="common.save" />
                                                </button>
                                            </div>
                                            <div className="col-sm-6 col-md-3">
                                                <button type="submit" className="btn btn-light btn-block">
                                                    <FormattedMessage id="common.cancel" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    />
                </PrintContainer>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        fields: state.fields.items,
        comparators: state.fieldVisibility.comparators
    };
};
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getComparators
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ModelPreview);
