import React from 'react';
import { FormattedMessage } from 'react-intl';
import { call, hasErrors } from '../configuration/api';
import Queries from './queries';
import { Notifications } from '../components/notifications';

const SET_PRE_LOGOUT_ACTION = 'SET_PRE_LOGOUT_ACTION';

const defaultState = {
    preLogoutAction: (cb) => cb()
};

/**
 * Links state mapper.
 * @param {object} state
 * @param {string} action
 */
export default function(state = defaultState, action) {
    switch (action.type) {
        case SET_PRE_LOGOUT_ACTION:
            return { ...state, preLogoutAction: action.payload };

        default:
            return state;
    }
}

export const setPreLogoutAction = (preLogoutAction = () => null) => async dispatch => {
    dispatch({ type: SET_PRE_LOGOUT_ACTION, payload: preLogoutAction });
};
