import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Formik, Form } from 'formik';
import FieldDefinition, { FieldTypes } from 'models/field';
import { getComparators } from 'reducers/fieldVisibility';
import PickerFieldPreview from './pickerFieldPreview';
import LoadingOverlay from 'react-loading-overlay';
import CargoQuantityField from 'pages/events/components/cargoQuantityField';
import CargoUnitField from 'pages/events/components/cargoUnitForm';


import 'pages/models/models.scss';

class ModelFieldPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullscreen: false,
            print: false
        };
    }

    getGroupedSections = (specifiedFields = null) => {
        const { fields } = this.props;

        if (specifiedFields === null) {
            specifiedFields = fields;
        }

        if (!specifiedFields || specifiedFields.length === 0) return [];

        specifiedFields.sort((a,b) => {
            if (a.order < b.order) return -1;
            else if (a.order === b.order) return 0;
            else return 1;
        });
        const rootFields = [];

        let inSection = false;
        let currentSection = null;
        let currentSectionId = null;
        const sectionChildren = [];
 
        for (let field of specifiedFields) {
            if (field.type === FieldTypes.FIELD_END_SECTION) {
                if (inSection) {
                    sectionChildren[currentSectionId].push(field);
                    currentSection.children = sectionChildren[currentSectionId].map(f => f.id);
                    rootFields.push(currentSection);
                } else {
                    // otherwise it is just a root field
                    rootFields.push(field);
                }
                inSection = false;
            } else if (field.type === FieldTypes.FIELD_SECTION) {
                if (inSection) {
                    // Already in a section - we must complete that one
                    currentSection.children = sectionChildren[currentSectionId].map(f => f.id);
                    rootFields.push(currentSection);
                } else {
                    // we are in one now
                    inSection = true;
                }
                currentSection = field;
                currentSectionId = field.id;
                sectionChildren[currentSectionId] = [];
            } else if (field.type === FieldTypes.FIELD_CARGO) {
                if (inSection) {
                    // we were already in a section - we must complete that one
                    currentSection.children = sectionChildren[currentSectionId].map(f => f.id);
                    rootFields.push(currentSection);
                    inSection = false;
                }
                // Add the cargo field as if it's a normal field
                rootFields.push(field);
            } else {
                // 'regular' field - if we're in a section, add it to the section children
                if (inSection) {
                    sectionChildren[currentSectionId].push(field);
                } else {
                    // otherwise it is just a root field
                    rootFields.push(field);
                }
            }
        }
        // if the last field is part of a section, we need to finalize that section
        if (inSection) {
            currentSection.children = sectionChildren[currentSectionId].map(f => f.id);
            rootFields.push(currentSection);
        }
        return rootFields;
    };

    elSelector = (id) => (
        <input
            key={id + '-picker'}
            type="radio"
            name="field-reference-id"
            value={id}
            checked={this.props.fieldReferenceId === id}
            className="field-reference-picker"
            onChange={() => this.props.setFieldReferenceId(id)}
        />
    )

    render() {
        const { fields, modelId, comparators, modelTypeId, cargoCategories = [] } = this.props;
        const groupedFields = this.getGroupedSections();

        return (
            <React.Fragment>
                <hr/>
                <div className="col-12 pd-x-20">
                    <FormattedMessage className='pd-x-20' id="common.chooseField" />
                </div>
                <br/>
                <div className="fields-preview widget-2 mg-b-20">
                    <div className="card">
                        <div className="card-body pd-15 bd-color-gray-lighter">
                            <div className="row no-gutters">
                                <div className="col-12">
                                    <LoadingOverlay
                                        active={this.props.isLoading}
                                        spinner
                                        text={<FormattedMessage id='common.loading'/>}
                                    >
                                        <Formik
                                            enableReinitialize={false} 
                                            render={formProps => (
                                                <Form noValidate>
                                                    {groupedFields.map(f => {
                                                        f.isPreview = true;
                                                        if (f.type === FieldTypes.FIELD_CARGO) {
                                                            const cargoCategory = cargoCategories ? cargoCategories.find(cc => cc.id === f.cargoCategory) : undefined;
                                                            const cargoCategoryFields = cargoCategory === undefined ? [] : cargoCategory.model_field_list.map(f => FieldDefinition.fromDB(f));
                                                            
                                                            const cargoFieldsToShow = cargoCategoryFields.map(ccf => { ccf.id = 'cc-' + f.cargoCategory + '.f-' + f.id + '.' + ccf.id; return ccf; });
                                                            // Present the Cargo field as a section containing the Cargo Category fields not populated by the cargo type
                                                            const cargoCategorySection = new FieldDefinition(f);
                                                            cargoCategorySection.title = f.title + (cargoCategory === undefined ? '' : ' (' + cargoCategory.title + ')');
                                                            cargoCategorySection.type = FieldTypes.FIELD_CARGO;
                                                            const cargoChildren = this.getGroupedSections(cargoFieldsToShow);
                                                            cargoCategorySection.children = cargoChildren.map(f => f.id);
                                                            return <PickerFieldPreview key={f.id} field={cargoCategorySection} fields={cargoCategoryFields} fieldReferenceId={this.props.fieldReferenceId} setFieldReferenceId={this.props.setFieldReferenceId} comparators={comparators} modelTypeId={2}/>
                                                        } else {
                                                            return <PickerFieldPreview key={f.id} field={f} fields={fields} comparators={comparators} fieldReferenceId={this.props.fieldReferenceId} setFieldReferenceId={this.props.setFieldReferenceId} modelTypeId={modelTypeId} showIsRequired={f.is_required} /> 
                                                        }
                                                    })}
                                                    {
                                                        (modelTypeId === 2) &&
                                                        <div className='row'>
                                                            <div className={`pd-b-15 col-4 offset-2`}>
                                                                { this.elSelector('l1') }
                                                                <CargoQuantityField
                                                                    disabled={true}
                                                                    initialTargetQuantity={0}
                                                                />
                                                            </div>
                                                            <CargoUnitField 
                                                                prependEls={[this.elSelector('l2')]}
                                                                disabled={true}
                                                                initialTargetCargoUnit={null}
                                                                width={4}
                                                            />
                                                        </div>
                                                    }
                                                </Form>
                                            )}
                                        />
                                    </LoadingOverlay>
                                    <div className="col-12">
                                        <div className="row d-flex justify-content-center align-items-center pd-y-15 mg-b-15">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        fields: state.fields.items,
        comparators: state.fieldVisibility.comparators
    };
};
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getComparators,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ModelFieldPicker);
