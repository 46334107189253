import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Field, ErrorMessage } from 'formik';
import Select from 'react-select/lib/Async';
import { components } from 'react-select';
import Tooltip from 'rc-tooltip';

import './fields.scss';

class AsyncSelectField extends React.Component {
    loadOptions = inputValue => {
        const { optionsLoader } = this.props;
        return optionsLoader(inputValue);
    };

    handleInputChange = (newValue) => {
        this.loadOptions(newValue);
        return newValue;
    };

    render() {
        const {
            intl: { formatMessage },
            multi,
            name,
            options,
            submitOnChange,
            disabled = false,
            useFormattedLabel = false,
            getLabelFromOptions = false,
            isClearable = false,
            extraOnChange = () => null,
        } = this.props;
        const customStyles = {
            control: base => ({
                ...base,
                boxShadow: 'none'
            }),
            multiValueLabel: (provided, state) => ({
                ...provided,
                maxWidth: '10em'
            }),
            menu: (provided, state) => ({
                ...provided,
                zIndex: '20'
            }),
            menuList: (provided, state) => ({
                ...provided,
                maxHeight: '200px'
            }),
        };

        const seleniumName = name === 'default_unit' ? 'default.unit' : name;

        return (
            <React.Fragment>
                <Field
                    {...this.props}
                    render={({ form, field }) => {
                        let fieldValue = undefined;
                        if (multi && field.value && Array.isArray(field.value)) {
                            fieldValue = field.value.map(v => ({ value: v, label: useFormattedLabel ? (Array.isArray(v) ? <FormattedMessage id={v[0]} /> : <FormattedMessage id={v} /> ): v }))
                        } else if (!multi && field.value) {
                            let label = field.value;
                            if (useFormattedLabel) {
                                if (Array.isArray(field.value)) {
                                    label = field.value[0];
                                } else {
                                    label = field.value;
                                }
                                label = !!label ? <FormattedMessage id={label} /> : '';
                            } else if (getLabelFromOptions) {
                                if (options.length > 0) {
                                    const first = options[0];
                                    const optionsAreGrouped = !!first.options;
                                    if (optionsAreGrouped) {
                                        for (let index = 0; index < options.length; index++) {
                                            const selectedOption = options[index].options.find(o => o.value === field.value);
                                            if (selectedOption) {
                                                label = selectedOption.label;
                                                break;
                                            }
                                        }
                                    } else {
                                        const selectedOption = options.find(o => o.value === field.value);
                                        if (selectedOption) {
                                            label = selectedOption.label;
                                        }
                                    }
                                }
                            }
                            fieldValue = { value: field.value, label: label }
                        }
                        let fieldValueLength = 0;
                        if (fieldValue && fieldValue.label) {
                            if (typeof fieldValue.label === 'string') {
                                fieldValueLength = fieldValue.label.length;
                            } else if (fieldValue.label.props && fieldValue.label.props.id) {
                                fieldValueLength = fieldValue.label.props.id.length;
                            }
                        }
                        const singleValueEl = props => (!fieldValue || (fieldValue && fieldValue.label && fieldValueLength > 5)) ?
                            <Tooltip trigger={['hover']} placement='left' overlay={<span>{fieldValue ? fieldValue.label : formatMessage({ id: `noValue` })}</span>} mouseEnterDelay={1.5}>
                                <div>
                                    <components.SingleValue {...props} className={'se-select-value-' + seleniumName} />
                                </div>
                            </Tooltip>
                            :
                            <components.SingleValue {...props} className={'se-select-value-' + seleniumName} />

                        return (
                            <Select
                                defaultOptions={!!options ? options : true}
                                loadOptions={this.loadOptions}
                                placeholder={formatMessage({ id: `${name}.placeholder` })}
                                name={name}
                                onChange={async selectedValue => {
                                    if (!!disabled) return;
                                    const value = !!selectedValue ? (Array.isArray(selectedValue) ? selectedValue.map(s => s.value) : selectedValue.value) : '';
                                    await form.setFieldValue(name, value);
                                    if (!!submitOnChange) {
                                        form.submitForm();
                                    }
                                    if (extraOnChange) extraOnChange(selectedValue);
                                }}
                                value={fieldValue}
                                onBlur={form.handleBlur}
                                isMulti={multi}
                                components={{ 
                                    IndicatorSeparator: () => null, 
                                    DropdownIndicator: props =>  <components.DropdownIndicator {...props} className={'se-select-dropdown-' + seleniumName} />,
                                    ClearIndicator: props =>  <components.ClearIndicator {...props} className={'se-select-clear-' + seleniumName} />,
                                    SingleValue: singleValueEl,
                                    MultiValue: props => <components.MultiValue {...props} className={'se-select-value-' + seleniumName} />,
                                    Menu: props => <components.Menu {...props} className={'se-select-menu-' + seleniumName} />,
                                    Option: props => <components.Option {...props} className={'se-select-menu-option-' + seleniumName} />
                                }}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#eeeeee',
                                        primary: '#00336B'
                                    }
                                })}
                                onInputChange={this.handleInputChange}
                                styles={customStyles}
                                isDisabled={this.props.disabled}
                                isClearable={isClearable}
                                menuPlacement={this.props.menuPlacement || 'auto'}
                            />
                        );
                    }}
                />
                <ErrorMessage name={name} component="div" className="validation-message" />
                {this.props.showIsRequired && <div className="validation-message"><FormattedMessage id="validation.required" /></div>}
            </React.Fragment>
        );
    }
}

export default injectIntl(AsyncSelectField);
