import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { FieldDescription } from 'pages/models/fields/definitions/common';
import Tooltip from 'rc-tooltip';

import './fields.scss';

class InputField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.initialValue
        };
    }
    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.initialValue !== prevProps.initialValue) {
          this.setState({ value: this.props.initialValue });
        }
    }
    render() {
        const {
            intl: { formatMessage },
            name,
            type,
            min = -1 * Number.MAX_VALUE,
            max = Number.MAX_VALUE,
            placeholder,
            extras,
            disabled = false,
            onChangeExt = _ => {return null},
            showError,
            step,
            seleniumId,
            maxLength,
            // value,
            setFieldValue,
        } = this.props;
        const { pre, post } = extras || {};
        if (!setFieldValue) return null;
        const { value } = this.state;
        return (
            <React.Fragment>
                <div className="input-field">
                    {!!pre && <div className="input-extra-pre">{pre}</div>}
                    { !!value && 
                        <Tooltip trigger={['hover']} overlay={<span>{placeholder || formatMessage({ id: `${name}.placeholder` })}</span>} mouseEnterDelay={2}>
                            <div className="input-placeholder">{placeholder || formatMessage({ id: `${name}.placeholder` })}</div>
                        </Tooltip>
                    }
                    <input
                        name={name}
                        type={type}
                        min={min}
                        max={max}
                        step={step}
                        disabled={disabled}
                        onChange={e => {
                            e.preventDefault();
                            let val = e.target.valueAsNumber === Number.MAX_VALUE || 
                                        e.target.valueAsNumber === -1 * Number.MAX_VALUE ? 0 : e.target.value;
                            setFieldValue(name, val);
                            this.setState({ value: val });
                            onChangeExt(name, e.target.value);
                        }}
                        className={`form-control ${!!post ? 'with-post-extra' : ''} se-id-${seleniumId}`}
                        placeholder={placeholder || formatMessage({ id: `${name}.placeholder`, defaultMessage: 'New Field' })}
                        maxLength={maxLength}
                        value={value}
                    />
                    {this.props.showIsRequired && (value === null || value === undefined || value === '') && <div className="validation-message required-placeholder"><FormattedMessage id="validation.required" /></div>}
                    <FieldDescription {...this.props} />
                </div>
                {!!post && <div className="input-extra-post">{post}</div>}
                {/* {showError && <ErrorMessage name={name} component="div" className="validation-message" />} */}
            </React.Fragment>
        );
    }
}

export default injectIntl(InputField);
