export const FIELDS_CLEAR_MAPPING = `
    mutation M(
        $dash_template_id: Long!
        $owning_dash_field_id: Long!
        $owning_dot_field_id: Long
        $owning_special_dot_field_id: Int
        $map_dash_field_id: Long!
        $map_dot_field_id: Long
        $map_special_dot_field: Int
    ) {
        model_field_clear_mapping(
            input: {
                dash_template_id: $dash_template_id
                owning_field_id: $owning_dash_field_id
                owning_dot_field_id: $owning_dot_field_id
                owning_special_dot_field_id: $owning_special_dot_field_id
                map_dash_field_id: $map_dash_field_id
                map_dot_field_id: $map_dot_field_id
                map_special_dot_field: $map_special_dot_field
            }
        ) {
            id
            model_id
            order
            type_id
            type_name
            status_id
            status_name
            index_field_id
            input_id
            visibility_check
            title
            description
            is_required
            validation
            width
            force_break
            extra_info
            company_id: model_company_id

            map_dash_field
            map_dot_field
            map_special_dot_field
            map_compound_id

            index_field {
                id
                order_by
                type_id
                type_name
                status_id
                status_name
                title
                description
                extra_info
            }
        }
    }
`;
