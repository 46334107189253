export const DOCUMENT_TEMPLATE_LIST = `
    query($document_template_ids: [Long!], $model_ids: [Int], $offset: Int, $limit: Int, $order_by: [OrderBy]) {
        document_template_list(
            input: { document_template_ids: $document_template_ids, model_ids: $model_ids }
            paging: { order_by: $order_by, offset: $offset, limit: $limit }
        ) {
            total
            data {
                id
                hash_id
                model_id
                title
                description
                version
                document_type
                created_on
                updated_at
            }
        }
    }
`;
