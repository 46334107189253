import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import ReactResizeDetector from 'react-resize-detector';
import { toggleMenuVisibility, setSize } from '../../../reducers/menus';

import './main.scss';

class MainMenu extends React.Component {
    onMenuClick = () => {
        const { isMobile, toggleMenuVisibility } = this.props;
        if (!isMobile) return;
        toggleMenuVisibility();
    };

    render() {
        const { authenticated, pathname, setSize, links = [], company } = this.props;
        if (!authenticated || pathname.indexOf('shared-invite') > -1) return null;

        const { subscription_type } = company;
        const isPro = (subscription_type === 'pro') || company.id === 1;

        const isActive = routes => routes.some(r => pathname.startsWith(r));
        const workflowLinks = links.filter(l => l.title.props.id !== 'page.settings.title');
        const settingsLink = links.filter(l => l.title.props.id === 'page.settings.title');
        return (
            <div className="br-sideleft overflow-hidden" onClick={this.onMenuClick}>
                <label className="sidebar-label pd-x-15 mg-t-20">
                    <FormattedMessage id="common.navigation" />
                </label>
                <div className="br-sideleft-menu">
                    {
                        workflowLinks.map(({ title, icon, routes, proRequired }, i) => (
                            <Link to={{pathname: routes[0], search: window.location.search }} className={`br-menu-link ${isActive(routes) ? 'active' : ''} ${(!isPro && proRequired) ? 'disabled' : ''}`} key={i} style={(!isPro && proRequired) ? { pointerEvents: 'none'} : {}}>
                                <div className="br-menu-item">
                                    <i className={`menu-item-icon icon ${icon} tx-22`} />
                                    <span className="menu-item-label">{title}</span>
                                </div>
                            </Link>
                        ))
                    }
                </div>
                <ReactResizeDetector querySelector={'body'} handleWidth onResize={setSize} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isMobile: state.menus.isMobile,
        authenticated: state.identity.authenticated,
        company: state.identity.company,
        pathname: state.router.location.pathname
    };
};
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setSize,
            toggleMenuVisibility
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MainMenu);
