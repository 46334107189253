import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import Page from 'components/page';
import Table from 'components/table';
import { getCompanyInvoices, setCompanyBillingEmail } from 'reducers/companies';
import { setInvoicesTableOptions } from 'reducers/sessionSettings';
import LoadingOverlay from 'react-loading-overlay';
import ReactModal from 'react-modal';
import { Fields } from 'components/fields';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Notifications } from 'components/notifications';

class CompanyInvoices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    loadData = (options, onComplete) => {
        const { getCompanyInvoices } = this.props;
        return getCompanyInvoices(options, onComplete);
    };

    getColumns = () => {
        const { company } = this.props;
        const currencySymbol = (company.currency_name === 'USD' ? '$' : '€')
        return [
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block text-center id-column',
                Header: <FormattedMessage id="common.id" />,
                width: 100,
                resizable: false,
                id: 'id',
                accessor: d => <a href={`${d.invoice_pdf}`}>{d.id}</a>
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block text-center',
                Header: <FormattedMessage id="common.plan" />,
                id: 'plan_id',
                accessor: d => d.line_items[0].plan.nickname
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block text-center',
                Header: <FormattedMessage id="common.seats" />,
                id: 'quantity',
                sortable: false,
                accessor: d => d.line_items[0].quantity
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block text-center',
                Header: <FormattedMessage id="common.amountPaid" />,
                id: 'amount_paid',
                accessor: d => currencySymbol + Number(d.amount_paid / 100).toFixed(2)
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block text-center',
                Header: <FormattedMessage id="common.created" />,
                id: 'created',
                accessor: d => new Date(d.created).toLocaleDateString()
            },
        ];
    };

    renderActions = () => {
        return (
            <div className="btn-group pd-t-10">
                <span className="btn btn-outline-info" onClick={() => this.setState({ updatingBillingEmailModalOpen: true })}>
                    <FormattedMessage id="updateBillingEmail" />
                </span>
            </div>
        );
    };

    updateBillingEmail = async () => { 
        const { setCompanyBillingEmail } = this.props;
        const schema = Yup.object().shape({
            email: Yup.string().email().required()
        });
        const valid = await schema.isValid({ email: this.state.billingEmail });
        if (valid) {
            this.setState({ updatingBillingEmail: true }, () => {
                setCompanyBillingEmail({ email: this.state.billingEmail }, () => {
                    this.setState({ updatingBillingEmail: false, updatingBillingEmailModalOpen: false, billingEmail: '' });
                    Notifications.success(<FormattedMessage id='successfullyUpdatedBillingEmail'/>)
                }, () => {
                    this.setState({ updatingBillingEmail: false, updatingBillingEmailModalOpen: false, billingEmail: '' });
                })
            });
        } else {
            Notifications.error(<FormattedMessage id="invalidEmail" />);
        }
    }

    render() {
        const { parents, company, invoices = [], total = 0 } = this.props;
        if (!company) return null;

        return (
            <Page {...CompanyInvoices.meta} parents={parents} actions={this.renderActions()}>
                <Table 
                    columns={this.getColumns()} 
                    data={invoices} total={total} 
                    dataLoader={(options, onComplete) => this.loadData(options, onComplete)} 
                    optionsSetter={this.props.setInvoicesTableOptions}
                    initialOptions={this.props.invoicesTableOptions}
                />
                <ReactModal
                        isOpen={this.state.updatingBillingEmailModalOpen}
                        onRequestClose={() => this.setState({ updatingBillingEmailModalOpen: false, billingEmail: '' })}
                        className="modal-block dialog"
                        overlayClassName="modal-overlay gray"
                    >
                        <LoadingOverlay
                            active={this.state.updatingBillingEmail}
                            spinner
                            text={<FormattedMessage id='common.loading'/>}
                        >
                            <div role="document">
                                <div className="modal-content  bd-0">
                                    <div className="modal-header pd-x-20">
                                        <FormattedMessage id="updateBillingEmail" />
                                    </div>
                                    <div className="modal-body">
                                        <div className="input-field">
                                            <Formik
                                                onSubmit={values => this.setState({ billingEmail: values.email })}
                                                render={({ handleChange, submitForm }) => {
                                                    return (
                                                        <Form
                                                            noValidate
                                                            onChange={async e => {
                                                                await handleChange(e);
                                                                submitForm();
                                                            }}
                                                        >
                                                            <Fields.Input
                                                                name='email'
                                                            />
                                                        </Form>
                                                    )
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="modal-footer justify-content-center">
                                        <div className="row d-flex justify-content-center align-items-center">
                                            <div className={"col-6"}>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                                    onClick={this.updateBillingEmail}
                                                    disabled={this.state.updatingBillingEmail}
                                                >
                                                    <FormattedMessage id="submit" />
                                                </button>
                                            </div>
                                            <div className={"col-6"}>
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                                    onClick={() => this.setState({ updatingBillingEmailModalOpen: false, billingEmail: '' })}
                                                >
                                                    <FormattedMessage id="common.cancel" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </LoadingOverlay>
                    </ReactModal>
            </Page>
        );
    }
}

CompanyInvoices.meta = {
    title: <FormattedMessage id="page.companies.billing.invoices.title" />,
    subtitle: <FormattedMessage id="page.companies.billing.invoices.subtitle" />,
    routes: ['/settings/billing/invoices'],
    icon: 'ion-ios-world-outline',
    menus: { main: false, user: false },
    order: 0
};

CompanyInvoices.defaultProps = {
    invoices: [],
    total: 0
};

const mapStateToProps = state => {
    return {
        company: state.identity.company,
        invoices: state.companies.invoices,
        total: state.companies.totalInvoices,
        invoicesTableOptions: state.sessionSettings.invoicesTableOptions,
    };
};
const mapDispatchToProps = dispatch => bindActionCreators({
    getCompanyInvoices,
    setInvoicesTableOptions,
    setCompanyBillingEmail,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanyInvoices);
