import React from 'react';
import { injectIntl } from 'react-intl';
import { Field, ErrorMessage } from 'formik';
import Dropzone from 'react-dropzone';

import './fields.scss';

class UploadField extends React.Component {
    handleUpload = (files, form) => {
        const { action, name, submitOnChange = false } = this.props;
        action(files, async media => {
            const {
                blob: { bucket, key, url }
            } = media;
            await form.setFieldValue(name, url);
            await form.setFieldValue('s3_bucket', bucket);
            await form.setFieldValue('s3_object', key);
            if (!!submitOnChange) {
                form.submitForm();
            }
        });
    };

    render() {
        const {
            intl: { formatMessage },
            name,
            showError = true,
            multiple = false,
            // maxSize,
            accept = '*/*'
        } = this.props;

        return (
            <React.Fragment>
                <div className="input-field">
                    <Field
                        {...this.props}
                        render={({ form }) => {
                            return (
                                <Dropzone
                                    multiple={multiple}
                                    accept={accept}
                                    // maxSize={maxSize}
                                    onDrop={files => this.handleUpload(files, form)}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <React.Fragment>
                                            <button {...getRootProps()} type="button" className="btn btn-block rich-text-button">
                                                <span>{formatMessage({ id: 'common.upload' })}</span>
                                                <i className="material-icons-outlined">upload</i>
                                                <input {...getInputProps()} name={'upload_ignore'} />
                                            </button>
                                        </React.Fragment>
                                    )}
                                </Dropzone>
                            );
                        }}
                    />
                </div>
                {!!showError && <ErrorMessage name={name} component="div" className="validation-message" />}
            </React.Fragment>
        );
    }
}

export default injectIntl(UploadField);
