export const MODELS_EXPORT = `
    query M($model_id: Int!) {
        model_export(model_id: $model_id) {
            id
            hash_id
            company_id
            status_id
            status_name
            created_on
            title
            description
            is_used
            split_conditions
            extra_info
            model_type
            model_type_name
        }
    }
`;
