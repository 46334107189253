import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import ModelList from './components/list';
import ModelNew from './components/new';
import ModelsIndices from './components/indices';
import { DefinedPermissions } from '../../components/routes/permissions/permissionResolver';
import DocumentConfigurationList from '../models/components/documents/list';
import DocumentConfiguration from '../models/components/documents/single';
import Transactions from 'pages/events/index';

class EventTemplates extends React.Component {
    render() {
        return (
            <Switch>
                <Route exact path="/events/event-templates" component={props => <ModelList {...props} parents={[Transactions.meta]} />} />
                <Route exact path="/events/event-templates/indices" component={props => <ModelsIndices {...props} parents={[Transactions.meta, EventTemplates.meta]} />} />
                {/* <Route exact path="/events/event-templates/:modelId/document-templates" component={props => <DocumentConfigurationList {...props} parents={[Transactions.meta, EventTemplates.meta]} />} /> */}
                {/* <Route exact path="/events/event-templates/:modelId/document-templates/:id" component={props => <DocumentConfiguration {...props} parents={[Transactions.meta, EventTemplates.meta]} />} /> */}
                <Route path="/events/event-templates/:id" component={props => <ModelNew key={props.match.params.id} {...props} parents={[Transactions.meta, EventTemplates.meta]} />} />
            </Switch>
        );
    }
}

EventTemplates.meta = {
    title: <FormattedMessage id="page.eventTemplates.title" />,
    subtitle: <FormattedMessage id="page.eventTemplates.subtitle" />,
    routes: ['/events/event-templates'],
    icon: 'ion-clipboard',
    order: 32,
    access: DefinedPermissions.ModelCrud,
    requires: ['model', 'read'],
    onlySubscribed: true
};

export default EventTemplates;
