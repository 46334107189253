import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Switch } from 'react-router-dom';
import { AuthenticatedRoute, AnonymousRoute } from '../components/routes';
import { Account, Companies, Settings, Dashboard, CargoCategories, EventTemplates, Profile, Subscribe, Transactions, Links, Impersonate, Lots, DocumentTemplatesPage, Intelligence, Sharing, Visualize, LibraryPages, CollectionsPages } from 'pages';
import Header from '../components/header';
import Footer from '../components/footer';
import Localized from '../components/localized';
import { NotificationsContainer } from '../components/notifications';
import { logout, getProfile } from '../reducers/identity';
import IdleTimer from 'react-idle-timer'
import { history } from 'configuration/history.js';
import { createErrorReport } from 'reducers/errorReport';
import { DataStorage } from 'utilities/storage';

class Routes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showHeaderLoading: false,
        };
        this.idleTimer = null;
        this.logoutIdleTimer = null;
        this.onAction = this._onAction.bind(this);
        this.onActive = this._onActive.bind(this);
        this.onIdle = this._onIdle.bind(this);
        this.logoutUser = this._logoutUser.bind(this);
    }

    componentDidMount() {
        const { authenticated, permissions, pathname, getProfile } = this.props;
        if (!!authenticated && !permissions && !pathname.includes('/mfa')) getProfile();
    }
 
    _onAction(e) {
    //   console.log('user did something', e)
    }
   
    _onActive(e) {
    //   console.log('user is active', e)
    //   console.log('time remaining', this.idleTimer.getRemainingTime())
    }
   
    _onIdle(e) {
      const { preLogoutAction, logout } = this.props;
      if (preLogoutAction !== undefined) {
          preLogoutAction(() => {
            history.push('/' + window.location.search)
          })
      }
    }

    _logoutUser(e) {
        const { logout } = this.props;
        logout();
    }

    render() {
        const { menuClass, authenticated, permissions, entityPermissions, pathname, token } = this.props;

        if (pathname.includes('/mfa')) {
            return (
                <Localized>
                    <section className={menuClass}>
                        <IdleTimer
                            ref={ref => { this.idleTimer = ref }}
                            element={document}
                            onActive={this.onActive}
                            onIdle={this.onIdle}
                            onAction={this.onAction}
                            debounce={250}
                            timeout={1000 * 60 * 30} />
                        <IdleTimer
                            ref={ref => { this.logoutIdleTimer = ref }}
                            element={document}
                            onIdle={this.logoutUser}
                            debounce={1000}
                            timeout={1000 * 60 * 60} />
                        <Header rootLoadingState={this.state.showHeaderLoading} />
                        <div className='overflow-y-hidden'>
                            <Switch>
                                <AnonymousRoute exact path="/mfa" component={Account} form={'mfa'} {...data} />
                            </Switch>
                            <Footer />
                        </div>
                        <NotificationsContainer />
                    </section>
                </Localized>
            )
        }
        if (!!authenticated && !permissions) return null;

        const data = { authenticated, permissions, entityPermissions, token };
        const setRootLoadingState = (state) => this.setState({showHeaderLoading: state});


        return (
            <Localized>
                <section className={menuClass}>
                    <IdleTimer
                        ref={ref => { this.idleTimer = ref }}
                        element={document}
                        onActive={this.onActive}
                        onIdle={this.onIdle}
                        onAction={this.onAction}
                        debounce={250}
                        timeout={1000 * 60 * 30} />
                    <IdleTimer
                        ref={ref => { this.logoutIdleTimer = ref }}
                        element={document}
                        onIdle={this.logoutUser}
                        debounce={1000}
                        timeout={1000 * 60 * 60} />
                    <Header rootLoadingState={this.state.showHeaderLoading} />
                    <div className={(authenticated && pathname.indexOf('shared-invite') === -1 && !pathname.includes('/mfa')) ? 'br-mainpanel' : ''}>
                        <Switch>
                            <AuthenticatedRoute exact path="/dashboard" component={Dashboard} {...data} />
                            <AuthenticatedRoute exact path="/profile" component={Profile} {...data} />
                            {/* <AuthenticatedRoute path="/models" component={Models} {...data} /> */}
                            <AuthenticatedRoute path="/document-templates" component={DocumentTemplatesPage} setRootLoadingState={setRootLoadingState} {...data} />
                            <AuthenticatedRoute path="/objects/object-templates/" component={CargoCategories} {...data} />
                            <AuthenticatedRoute path="/objects" component={Lots} {...data} />
                            <AuthenticatedRoute path="/events/event-templates" component={EventTemplates} {...data} />
                            <AuthenticatedRoute path="/events" component={Transactions} {...data} />
                            <AuthenticatedRoute path="/companies" component={Companies} {...data} />
                            <AuthenticatedRoute path="/settings" component={Settings} {...data} />
                            <AuthenticatedRoute path="/impersonate" component={Impersonate} {...data} />
                            <AuthenticatedRoute path="/intelligence/alerts" component={Intelligence} setRootLoadingState={setRootLoadingState} {...data} />
                            <AuthenticatedRoute exact path="/" component={Dashboard} {...data} />
                            <AuthenticatedRoute path="/visualize" component={Visualize} {...data} />
                            <AuthenticatedRoute path='/library' component={LibraryPages} {...data} />
                            <AuthenticatedRoute path='/collections' component={CollectionsPages} {...data} />

                            <AnonymousRoute exclusively path="/login" component={Account} form={'login'} {...data} />
                            <AnonymousRoute exact path="/mfa" component={Account} form={'mfa'} {...data} />
                            <AnonymousRoute exclusively path="/forgot" component={Account} form={'forgot'} {...data} />
                            <AnonymousRoute exclusively path="/set-password" component={Account} form={'set-password'} {...data} />
                            <AnonymousRoute exclusively path="/reset/:token" component={Account} form={'reset'} {...data} />
                            <AnonymousRoute exclusively path="/presubmission" component={Subscribe} form={'presubmission'} {...data} />
                            <AnonymousRoute exact path="/accept-shared-invite" component={Sharing} {...data} />

                            <AnonymousRoute exact path="/l/:token" component={Links} {...data} />
                        </Switch>
                        <Footer />
                    </div>
                    <NotificationsContainer />
                </section>
            </Localized>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticated: state.identity.authenticated,
        permissions: state.identity.permissions,
        entityPermissions: state.identity.entityPermissions,
        menuClass: state.menus.menuClass,
        pathname: state.router.location.pathname,
        preLogoutAction: state.internal.preLogoutAction,
        token: state.identity.token,
    };
};
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getProfile,
            logout,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Routes);
