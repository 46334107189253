import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import moment from 'moment';
import { Button, Popover, Menu, MenuItem, MenuDivider, Icon } from '@blueprintjs/core';
import { history } from 'configuration/history';
import Table from 'components/table';
import { getAlert, listAlerts, deleteAlert } from 'reducers/alerts';
import { getProfile } from 'reducers/identity';
import QueryBuilder from './queryBuilder';
import { getIndices } from 'reducers/indices';
import { setAlertsTableOptions } from 'reducers/sessionSettings';
import ConfirmModal from 'components/modals/confirm';
import 'pages/models/models.scss';
import { tooltipWrapper } from 'utilities/common'

class AlertsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deleteTargetMultiIds: [],
            tableRenderCount: 1,
            options: props.alertsTableOptions,
        }
    }

    async componentDidMount() {
        const { company, getProfile } = this.props;
        if (!company) {
            getProfile();
        }
    }

    getColumns = () => {
        return [
            {
                headerClassName: 'd-sm-block col-2 d-none',
                Header: this.props.overrideMenuHeader ? this.props.overrideMenuHeader : null,
                className: 'd-xs-block text-center col-2',
                sortable: false,
                resizable: false,
                width: 34,
                id: 'actions',
                accessor: d => {
                    return this.props.overrideMenuEl ?
                    this.props.overrideMenuEl(d)
                    :
                    (
                        <div className="actions-wrapper">
                            <Popover
                                content={
                                    <Menu>
                                        <MenuItem icon="share" text={<FormattedMessage id="common.open" />} onClick={() => history.push(`/intelligence/alerts/${d.id}` + window.location.search)} />
                                        <MenuDivider />
                                        {/* <MenuItem
                                            disabled={d.status_id !== 2}
                                            icon="add-to-artifact"
                                            text={<FormattedMessage id="page.models.new.transaction" />}
                                            onClick={() =>
                                                d.status_id !== 2 ? null : createTransaction(d.id, 'transaction', transaction => history.push(`/events/${transaction.id}` + window.location.search))
                                            }
                                        />
                                        <MenuDivider />
                                        <MenuItem icon="export" text={<FormattedMessage id="common.export" />} onClick={() => exportModel(d)} />
                                        <MenuItem
                                            icon="duplicate"
                                            text={<FormattedMessage id="common.clone" />}
                                            onClick={() => cloneModel(d, c => history.push(`/events/event-templates/${c.id}` + window.location.search))}
                                        />
                                        <MenuDivider /> */}
                                        <MenuItem
                                            icon="cross"
                                            text={<FormattedMessage id="common.delete" />}
                                            onClick={() =>
                                                this.setState({ deleteTargetId: d.id, deleteTargetTitle: d.title || '[Untitled]', confirmModalOpen: true })
                                            }
                                        />
                                    </Menu>
                                }
                            >
                                <Button minimal={true} small={true} icon={<Icon iconSize={12} icon="menu" />} />
                            </Popover>
                        </div>
                    )
                }
            },
            {
                headerClassName: 'd-sm-block col d-none',
                className: 'd-sm-block col d-none tx-center id-column',
                Header: <FormattedMessage id="common.id" />,
                width: 100,
                id: 'Id',
                accessor: d => <Link to={{ pathname: `/intelligence/alerts/${d.id}`, search: window.location.search }}>{d.hash_id}</Link>
            },
            {
                headerClassName: 'd-sm-block d-none',
                className: 'd-xs-block',
                Header: <FormattedMessage id="common.title" />,
                id: 'Title',
                accessor: d => { return tooltipWrapper(
                    d.description,
                    d.description,
                    <Link to={{ pathname: `/intelligence/alerts/${d.id}`, search: window.location.search }}>{(d.title === null || d.title === '') ? '[Untitled]' : d.title}</Link>
                ) }
            },
            {
                headerClassName: 'd-block col-2',
                className: 'd-block col-2',
                Header: <FormattedMessage id="common.createdOn" />,
                id: 'CreatedOn',
                accessor: d => moment(d.created_on).format('DD-MM-YYYY')
            },
            {
                headerClassName: 'd-sm-block d-none col-1',
                className: 'd-xs-block col-1 tx-center',
                Header: <FormattedMessage id="common.status" />,
                id: 'Active',
                accessor: d => d.active ? 'Active' : 'Inactive'
            },
        ];
    };

    loadData = (options, onComplete) => {
        const { listAlerts, setAlertsTableOptions = () => null, alertsTableOptions, externalOptions = {} } = this.props;
        this.setState({ options: options });
        listAlerts({...options}, (results) => {
            if (results === undefined) {
                onComplete([]);
                return;
            }
            if (results.total > 0 && results.data.length === 0) {
                // navigate to previous page and force table to re-render
                const newOptions = Object.assign({}, alertsTableOptions);
                newOptions.page = newOptions.page - 1;
                setAlertsTableOptions(newOptions).then(() => {
                    this.setState({ tableRenderCount: this.state.tableRenderCount + 1 });
                })
            } else {
                onComplete(results);
            }
        });
    };

    render() {
        const { 
            alerts, 
            total, 
            company, 
            externalRenderCount = 0 , 
            deleteAlert, intl: { formatMessage }, 
        } = this.props;
        if (!company) return null;
        return (
            <React.Fragment>
                <Table
                    key={'tb-' + (this.state.tableRenderCount + externalRenderCount)}
                    columns={this.getColumns()}
                    data={alerts}
                    total={total}
                    // queryBuilder={setFilters => 
                    //     <QueryBuilder 
                    //         onFiltersSet={setFilters} 
                    //         stateSetter={this.props.setEventsTemplatesTableQueryState}
                    //         valuesSetter={this.props.setEventsTemplatesTableQueryValues}
                    //         initialState={this.props.eventTemplatesTableQueryState}
                    //         initialValues={this.props.eventTemplatesTableQueryValues}
                    //         statusSelectDisabled={this.props.statusSelectDisabled}
                    //         externalStatuses={this.props.externalStatuses}
                    //     />
                    // }
                    dataLoader={(options, onComplete) => {
                        this.loadData(options, onComplete);
                        this.setState({ options: options });
                    }}
                    optionsSetter={this.props.setAlertsTableOptions}
                    initialOptions={this.props.alertsTableOptions}
                />
                <ConfirmModal
                    open={this.state.confirmModalOpen}
                    setOpen={(o) => this.setState({ confirmModalOpen: o })}
                    onConfirm={() => {
                        const { deleteTargetId, options } = this.state;
                        deleteAlert({ alert_id: deleteTargetId }, () => this.loadData({ ...options }, () => null))
                    }}
                    title={<FormattedMessage id='common.confirmVerb' values={{ verb: formatMessage({ id: 'Delete' })}} />}
                    body={<FormattedMessage id='confirmAlertDelete' values={{ title: this.state.deleteTargetTitle }} />}
                />
            </React.Fragment>
        );
    }
}

AlertsTable.defaultProps = {
    models: [],
    total: 0
};

const mapStateToProps = state => ({
    alerts: state.alerts.alertsList,
    company: state.identity.company,
    total: state.alerts.alertsTotal,
    alertsTableOptions: state.sessionSettings.alertsTableOptions,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getProfile,
            listAlerts,
            deleteAlert,
            setAlertsTableOptions,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(AlertsTable));
