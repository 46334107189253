import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Field, ErrorMessage } from 'formik';
import { FieldDescription } from 'pages/models/fields/definitions/common';
import Tooltip from 'rc-tooltip';

import './fields.scss';

class InputField extends React.Component {
    render() {
        const {
            intl: { formatMessage },
            name,
            type,
            min = -1 * Number.MAX_VALUE,
            max = Number.MAX_VALUE,
            placeholder,
            extras,
            disabled = false,
            onChangeExt = _ => {return null},
            showError,
            step,
            seleniumId,
            maxLength
        } = this.props;
        const { pre, post } = extras || {};
        return (
            <React.Fragment>
                    <Field
                        {...this.props}
                        render={({ field, form }) => {
                            return (
                                <React.Fragment>
                                    <div className="input-field">
                                        {!!pre && <div className="input-extra-pre">{pre}</div>}
                                        { !!field.value && 
                                            <Tooltip trigger={['hover']} overlay={<span>{placeholder || formatMessage({ id: `${name}.placeholder` })}</span>} mouseEnterDelay={2}>
                                                <div className="input-placeholder">{placeholder || formatMessage({ id: `${name}.placeholder` })}</div>
                                            </Tooltip>
                                        }
                                        <input
                                            {...field}
                                            name={name}
                                            type={type}
                                            min={min}
                                            max={max}
                                            step={step}
                                            disabled={disabled}
                                            onChange={e => {
                                                e.preventDefault();
                                                let val = e.target.valueAsNumber === Number.MAX_VALUE || 
                                                            e.target.valueAsNumber === -1 * Number.MAX_VALUE ? 0 : e.target.value;
                                                form.setFieldValue(name, val);
                                                onChangeExt(name, e.target.value);
                                            }}
                                            className={`form-control ${!!post ? 'with-post-extra' : ''} se-id-${seleniumId}`}
                                            placeholder={placeholder || formatMessage({ id: `${name}.placeholder`, defaultMessage: 'New Field' })}
                                            maxLength={maxLength}
                                        />
                                        {this.props.showIsRequired && (field.value === null || field.value === undefined || field.value === '') && <div className="validation-message required-placeholder"><FormattedMessage id="validation.required" /></div>}
                                        <FieldDescription {...this.props} />
                                    </div>
                                    {!!post && <div className="input-extra-post">{post}</div>}
                                </React.Fragment>
                            );
                        }}
                    />
                {showError && <ErrorMessage name={name} component="div" className="validation-message" />}
            </React.Fragment>
        );
    }
}

export default injectIntl(InputField);
