import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import ReactModal from 'react-modal';
import 'pages/models/models.scss';
import Table from 'components/table';
import { listSharedEntities, createSharedEntity, deleteSharedEntity } from 'reducers/sharing';
import LoadingOverlay from 'react-loading-overlay';
import Tooltip from 'rc-tooltip';
import ConfirmModal from 'components/modals/confirm'
import { Formik, Form, Field } from 'formik';
import { Notifications } from 'components/notifications';
import { Fields } from 'components/fields'
import * as Yup from 'yup';

class ExternalSharingModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sharedUsersTableRenderCount: 0,
        };
    }

    confirmDeleteSharedEntity = sharedEntity => {
        this.setState({ selectedSharedEntityToDelete: sharedEntity.id, confirmDeleteModalOpen: true, deleteTargetEmail: sharedEntity.target_email });
    }

    getColumns = () => {
        const { modelType } = this.props;
        return [
            {
                isDefault: true,
                headerClassName: 'd-sm-block col-2 d-none',
                className: 'd-xs-block text-center col-2',
                sortable: false,
                resizable: false,
                width: 34,
                id: 'actions',
                accessor: d => (
                    <Tooltip
                        placement={'right'}
                        trigger={['hover']}
                        overlay={<FormattedMessage id="stopSharingWithUser" />}
                    >
                        <button className='btn btn-icon' onClick={() => this.confirmDeleteSharedEntity(d)}>
                            <i className="material-icons-outlined" style={{opacity: 0.8, color: 'gray'}}>delete_forever</i>
                        </button>
                    </Tooltip>
                )
            },
            {
                headerClassName: 'd-sm-block d-none',
                className: 'd-xs-block text-center',
                Header: <FormattedMessage id="userEmail" />,
                sortable: false,
                id: 'target_email',
                accessor: d => d.target_email,
            },
            {
                headerClassName: 'd-sm-block d-none',
                className: 'd-xs-block text-center',
                Header: (modelType === 'event template') ? <FormattedMessage id="includeEvents" /> : <FormattedMessage id="includeDots" />,
                sortable: false,
                id: 'include_transactions',
                accessor: d => d.include_transactions ? <FormattedMessage id="common.yes" /> : <FormattedMessage id="common.no" />
            },
            {
                headerClassName: 'd-sm-block d-none',
                className: 'd-xs-block text-center',
                Header: <FormattedMessage id="inviteAccepted" />,
                sortable: false,
                id: 'accepted',
                accessor: d => {
                    if (d.accepted === null) {
                        return <FormattedMessage id="noResponse" />
                    } else if (d.accepted) {
                        return <FormattedMessage id="common.yes" />
                    } else {
                        return <FormattedMessage id="common.no" />
                    }
                }
            },
        ];
    };

    loadData = (options, onComplete) => {
        const { modelId, listSharedEntities } = this.props;
        return listSharedEntities({ entity_type: "model", entity_id: modelId }, onComplete, onComplete);
    };

    render() {
        const { showModal, sharedEntities, titleId, modelType, createSharedEntity, deleteSharedEntity, sharingWillCascade } = this.props;
        return (
            <React.Fragment>
                <ReactModal
                    isOpen={showModal}
                    onRequestClose={() => this.props.setModalVisibility(false)}
                    className="modal-block dialog"
                    overlayClassName="modal-overlay gray"
                    style={{content: {width: '70%', maxHeight: '90%', overflowY: 'auto', overflowX: 'hidden'}}}
                >
                    <LoadingOverlay
                        active={this.props.loading}
                        spinner
                        text={<FormattedMessage id='common.loading'/>}
                    >
                        <div role="document">
                            <div className="modal-content bd-0">
                                <div className="modal-header pd-x-20">
                                    <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                                        <FormattedMessage id={titleId} />
                                    </h6>
                                </div>
                                <div className="modal-body pd-x-20">
                                    <Table
                                        key={'shared-users-' + this.state.sharedUsersTableRenderCount}
                                        columns={this.getColumns()}
                                        data={sharedEntities}
                                        total={sharedEntities.length}
                                        dataLoader={(options, onComplete) => this.loadData(options, onComplete)}
                                    />
                                </div>
                                <div className="modal-footer justify-content-center">
                                    <button
                                        type="button"
                                        className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                        onClick={() => this.setState({ createShareInviteModalOpen: true }) }
                                    >
                                        <FormattedMessage id="addUser" />
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                        onClick={() => this.props.setModalVisibility(false)}
                                    >
                                        <FormattedMessage id="common.close"  />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </LoadingOverlay>
                </ReactModal>
                <ConfirmModal
                    id='confirm-delete-modal'
                    open={this.state.confirmDeleteModalOpen}
                    setOpen={(o) => this.setState({ confirmDeleteModalOpen: o })}
                    onConfirm={() => {
                        const {  selectedSharedEntityToDelete } = this.state;
                        deleteSharedEntity({ entity_type: 'user_model', id: selectedSharedEntityToDelete }, () =>
                            this.loadData({}, () => null)
                        );
                    }}
                    title={<FormattedMessage id='common.confirmVerb' values={{ verb: 'Unshare'}} />}
                    body={<FormattedHTMLMessage tagName='div'  id={(modelType === 'event template') ? 'confirmSharedEntityDeleteCascade' : 'confirmSharedEntityDelete'} values={{ email: this.state.deleteTargetEmail }} />}
                />
                <ConfirmModal
                    key={'share-invite-modal-' + this.state.createShareInviteModalOpen}
                    id='share-invite-modal'
                    open={this.state.createShareInviteModalOpen}
                    setOpen={(o) => this.setState({ createShareInviteModalOpen: o })}
                    loading={this.state.createShareInviteModalLoading}
                    onConfirm={async () => {
                        const { target_email, include_transactions } = this.state;
                        const schema = Yup.object().shape({
                            email: Yup.string().email().required()
                        });
                        const valid = await schema.isValid({ email: target_email });
                        if (!valid) {
                            Notifications.error(<FormattedMessage id='invalidEmail' />)
                            return;
                        }
                        this.setState({ createShareInviteModalLoading: true }, () => {
                            createSharedEntity({ entity_type: 'model', entity_id: this.props.modelId, target_email: target_email, include_transactions: include_transactions}, () => {
                                this.setState({ createShareInviteModalLoading: false, createShareInviteModalOpen: false, sharedUsersTableRenderCount: this.state.sharedUsersTableRenderCount + 1, include_transactions: false, });
                            }, () => {
                                this.setState({ createShareInviteModalLoading: false });
                            })
                        })

                    }}
                    confirmLabel={<FormattedMessage id='invite' />}
                    stayOpenOnConfirm={true}
                    title={<FormattedMessage id='inviteExternalUser' />}
                    body={(
                        <Formik
                            onSubmit={values => this.setState({ target_email: values.target_email, include_transactions: values.include_transactions })}
                            render={formProps => {
                                const { intl: { formatMessage } } = this.props;
                                return (
                                    <Form 
                                        noValidate
                                        onChange={async e => {
                                            await formProps.handleChange(e);
                                            formProps.submitForm();
                                        }}
                                    >
                                        <div className="form-group">
                                            { sharingWillCascade && <p><FormattedMessage id='sharingWillCascade'/></p>}
                                            <Fields.Input type="email" name="target_email" className="form-control" showRequired={true} placeholder={formatMessage({id: 'targetUsersEmail'})} />
                                            <div className={'checkbox-field pd-b-5'}>
                                                <label className="ckbox">
                                                    <Field type="checkbox" name='include_transactions' checked={this.state.include_transactions} />
                                                    <span className={`ckbox-label-text${this.state.include_transactions ? ' checked' : ''}`}>{(modelType === 'event template') ? <FormattedMessage id="includeEvents" /> : <FormattedMessage id="includeDots" />}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </Form>
                                )
                            }}
                        />
                    )}
                />
            </React.Fragment>
        );
    }

}

const mapStateToProps = state => ({
    sharedEntities: state.sharing.sharedEntitiesList,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            listSharedEntities,
            createSharedEntity,
            deleteSharedEntity,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(ExternalSharingModal));
