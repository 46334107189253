import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { otpAuthenticate, otpResend, getProfile } from '../../../reducers/identity';
import { Fields } from '../../../components/fields';
import { history } from 'configuration/history';
import ConfirmModal from 'components/modals/confirm'

class MFA extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    handleSubmit = async (values, { setFieldError, setSubmitting }) => {
        const { otpAuthenticate, getProfile } = this.props;
        this.setState({ ...this.state, error: undefined }, () =>
            otpAuthenticate(
                { passcode: values.otp },
                () => {
                    getProfile();
                    setSubmitting(false);
                    history.push('/' + window.location.search);
                },
                errorResponse => {
                    const message = errorResponse && errorResponse.error ? errorResponse.error : 'error.default';
                    setFieldError('otp', message);
                    setSubmitting(false);
                }
            )
        );
    };

    render() {
        return (
            <>
                <Formik
                    initialValues={{ otp: '' }}
                    validationSchema={Yup.object().shape({
                        otp: Yup.string()
                            .length(6, <FormattedMessage id="otpLengthMessage" />)
                            .matches(/^\d+$/, <FormattedMessage id='otpNumOnly' />)
                            .required(<FormattedMessage id="validation.required" />)
                    })}
                    onSubmit={this.handleSubmit}
                    render={({ isSubmitting, errors, setFieldValue }) => (
                        <Form noValidate className="login">
                            <p>
                                <FormattedMessage id={'mfaMessage'} />
                            </p>
                            <div className="form-group">
                                <Fields.Input 
                                    name="otp" 
                                    className="form-control" 
                                    showError={true}
                                    type='text'
                                />
                            </div>
                            <a href='#' onClick={e => { e.stopPropagation(); e.preventDefault(); this.setState({ confirmModalOpen: true }); }}><FormattedMessage id='sendNewPasscode'/></a>
                            <br/>
                            <br/>
                            <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting}>
                                <FormattedMessage id="submit" />
                            </button>
                        </Form>
                    )}
                />
                <ConfirmModal
                    id='confirm-otp-resend'
                    open={this.state.confirmModalOpen}
                    setOpen={(o) => this.setState({ confirmModalOpen: o })}
                    onConfirm={() => {
                        const { otpResend } = this.props;
                        otpResend();
                    }}
                    title={<FormattedMessage id='common.confirmVerb' values={{ verb: 'Resend'}} />}
                    body={<FormattedMessage id='resendPasscodeConfirmMessage'/>}
                    confirmLabel={<FormattedMessage id='send' />}
                />
            </>
        );
    }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            otpAuthenticate,
            getProfile,
            otpResend,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MFA);
