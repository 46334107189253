export const USERS_UPDATE_ROLE = `
    mutation M($role_id: Int!, $title: String!) {
        role_update(role_id: $role_id, input: { title: $title }) {
            id
            company_id
            title
            is_builtin
            is_restricted

            aux {
                name
                value
            }
        }
    }
`;
