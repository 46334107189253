import React from 'react';
import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Page from '../../../components/page';
import { history } from 'configuration/history';
import { listLots } from 'reducers/lots'
import DagreGraph from '../dagre/dagreGraph'
import { 
    getTransaction, 
    getTransactionRelatedData, 
    getTransactionDeleteSideEffects, 
    transactionFieldRevise,
    getTransactionFieldSideEffectsDetails,
} from 'reducers/transaction';
import CargoTypeModal from 'pages/lots/components/cargoTypeModal';
import distinctColors from 'distinct-colors'
import Select, { components } from 'react-select';
import { invertColor } from 'utilities/common'
import { DotGraphNode, DashGraphNode, FieldGraphNode, GraphEdge, Path, getPath, getEdges } from '../dagre/graphNodes';
import { getEntityGraph, visitEntityGraph } from 'reducers/entityGraph'
import LoadingOverlay from 'react-loading-overlay';
import SliderControl from 'components/fields/uncontrolledComponents/sliderControl';
import { debounce } from 'utilities/common';
import MouseTooltip from 'react-sticky-mouse-tooltip';
import Tooltip from 'rc-tooltip';
import ConfirmModal from 'components/modals/confirm'
import './visibility.css';

class PathVisualization extends React.Component {
  contentRef;
  targetEntitesRef;
  flashLimitTimer;
  constructor(props) {
    super(props);
    const now = new Date();
    const today = new Date( now.getTime() + (now.getTimezoneOffset() * 60000));
    this.state = {
      contentHeight: 500,
      contentWidth: 500,
      resized: false,
      nodes: [],
      targetNodes: [],
      targetNodesFixedOrder: [],
      entity: {},
      pathLoading: true,
      today: today,
      viewDate: today,
      viewDateIndex: 0,
      createdDates: [today],
      hoveredEdge: null,
      visualizationZoom: null,
      isMouseTooltipVisible: false,
      showLegend: true,
    }
    this.contentRef = React.createRef();
    this.targetEntitesRef = React.createRef();
  }

  addSecondsToDate(seconds, date) {
    return new Date(date.getTime() + seconds);
  }

  async componentDidMount(){
    const { match, getEntityGraph, visitEntityGraph } = this.props;
    const encodedData = match.params.id;
    const graphObject = JSON.parse(atob(encodedData));
    const entityGraphArgs = { entities: [] };
    graphObject.entities.forEach(entity => {
      const { entityType, entityId } = entity;
      const entityArgs = { entity_type: entityType };
      if (entityType === 'dash') {
        entityArgs.entity_hash_id = entityId;
      } else {
        entityArgs.entity_id = entityId;
      }
      entityGraphArgs.entities.push(entityArgs);
    })
    let targetNodes = [];
    graphObject.entities.forEach(entity => {
      targetNodes.push(entity);
    })

    window.addEventListener('resize', () => {
      const size = this.measureElementWithOffset(this.contentRef.current);
      if (this.contentRef.current && (size.height !== this.state.contentHeight || size.width !== this.state.contentWidth)) {
        let topOffset = 0;
        if (this.targetEntitesRef.current) {
          const selectSize = this.measureElement(this.targetEntitesRef.current);
          topOffset = selectSize.height;
        }
        const MIN_HEIGHT = 380;
        if (this.state.contentHeight === 500 && this.state.contentWidth === 500) {
          let fixedHeight = (size.height - topOffset);
          if (fixedHeight < MIN_HEIGHT) {
            fixedHeight = MIN_HEIGHT;
          }
          this.setState({ contentHeight: fixedHeight, contentWidth: size.width });
        }
        if (!this.state.pathLoading) {
          let fixedHeight = (size.height - topOffset);
          if (fixedHeight < MIN_HEIGHT) {
            fixedHeight = MIN_HEIGHT;
          }
          this.setState({ contentHeight: fixedHeight, contentWidth: size.width, resized: true });
        }
      }
    });
    const manualResize = () => {
        const el = document;
        const event = document.createEvent('HTMLEvents');
        event.initEvent('resize', true, false);
        el.dispatchEvent(event);
    }
    const checkLoadFinished = setInterval(() => {
      if (this.state.resized) {
        clearInterval(checkLoadFinished);
      } else {
        manualResize();
      }
    }, 100)

    // tell backend that this is an explicit visit, then request the data for the visualization
    visitEntityGraph(entityGraphArgs);
    getEntityGraph(entityGraphArgs, results => {
      let nodes = results.nodes;
      
      let createdDates = [];
      nodes.forEach(node => {
        if (node.type === 'dash') {
          const afterCreatedTime = this.addSecondsToDate(1, new Date(node.dash.created_on));
          const existing = createdDates.find(cd => {
            const diff = cd.getTime() - afterCreatedTime.getTime();
            return ((diff < 1000 && diff >= 0) || (diff > -1000 && diff < 0));
          })
          if (!existing) {
            createdDates.push(afterCreatedTime);
          }
          node.dash.past_versions
            .map(pv => pv.modified_on)
            .forEach(versionDate => {
              const afterVersionDate = this.addSecondsToDate(1, new Date(versionDate));
              const existing = createdDates.find(cd => {
                const diff = cd.getTime() - afterVersionDate.getTime();
                return ((diff < 1000 && diff >= 0) || (diff > -1000 && diff < 0));
              })
              if (!existing) {
                createdDates.push(afterVersionDate)
              }
            })
        } else if (node.type === 'dot') {
          const dateToUse = (node.from.find(from => from.includes('dot')) === undefined) ? node.dot.created_on : node.dot.modified_on;
          const afterCreatedOrModifiedTime = this.addSecondsToDate(1, new Date(dateToUse));
          const existing = createdDates.find(cd => {
            const diff = cd.getTime() - afterCreatedOrModifiedTime.getTime();
            return ((diff < 1000 && diff >= 0) || (diff > -1000 && diff < 0));
          })
          if (!existing) {
            createdDates.push(afterCreatedOrModifiedTime);
          }
          node.dot.past_versions
            .map(pv => pv.modified_on)
            .forEach(versionDate => {
              const afterVersionDate = this.addSecondsToDate(1, new Date(versionDate));
              const existing = createdDates.find(cd => {
                const diff = cd.getTime() - afterVersionDate.getTime();
                return ((diff < 1000 && diff >= 0) || (diff > -1000 && diff < 0));
              })
              if (!existing) {
                createdDates.push(afterVersionDate)
              }
            })
        }
      })
      createdDates.sort((a,b) => a-b);
      createdDates[createdDates.length - 1] = this.state.today;
      this.setState({ nodes: nodes, targetNodes: targetNodes, targetNodesFixedOrder: targetNodes, pathLoading: false, createdDates, viewDateIndex: createdDates.length - 1 });
    });
  }

  measureElementWithOffset = element => {
    const { menuClass, isMobile } = this.props;
    const DOMNode = ReactDOM.findDOMNode(element);
    let menuOffset = 220;
    if (isMobile) {
      menuOffset = 30;
    } else if (menuClass === 'collapsed-menu') {
      menuOffset = 90;
    }
    if (DOMNode) {
      return {
        height: window.innerHeight - DOMNode.offsetTop - 210,
        width: window.innerWidth - DOMNode.offsetLeft - menuOffset
      };
    } else {
      return {
        height: 500,
        width: 500,
      }
    }
  }

  measureElement = element => {
    const DOMNode = ReactDOM.findDOMNode(element);
    if (DOMNode) {
      return {
        height: DOMNode.offsetHeight,
        width: DOMNode.offsetWidth
      };
    } else {
      return {
        height: 140,
        width: 500,
      }
    }
  }

  getNodeOptionLabel = (node) => {
    const entityType = node.entityType === 'dash' ? 'Dash' : 'DoT';
    let id = node.entity.id;
    if (node.entity.hash_id) {
      id = node.entity.hash_id;
    }
    const { intl } = this.props;
    const pathOf = intl.formatMessage({id: 'visualization.pathOf'});
    return `${pathOf} ${entityType} ${id}`;
  }

  onViewDateChange = (dateIndex) => {
    this.setState(prevState => ({ viewDateIndex: dateIndex, previousDateIndex: prevState.dateIndex, viewDate: prevState.createdDates[dateIndex], previousViewDate: prevState.viewDate, hideFlash: false }))
    if (this.flashLimitTimer) {
      clearTimeout(this.flashLimitTimer);
    }
    this.flashLimitTimer = setTimeout(() => this.setState({ hideFlash: true }), 20);
  }

  debouncedPersistZoom = debounce((zoom) => this.setState(prevState => ({ visualizationZoom: zoom, previousViewDate: prevState.viewDate })), 100);

  legendElement = () => (
    <div className="shadow-base dorae-visibility-key" hidden={!this.state.showLegend} style={{maxWidth: '12em'}}>
      <div className="card">
        <div className="card-body" style={{ padding: '0.5em 1.25em 0.5em 1.25em' }}>
          <div className="row">
            <div className="col-3" style={{ padding: '0 0 0 7px' }}>
              <svg style={{height: '36px', width: '36px'}}>
                <polygon points="18,0 0,18 18,36 36,18" style={{fill: 'green'}}></polygon>
              </svg>
            </div>
            <div className="col-9 my-auto">
              <h6 className="tx-14 mg-b-0 tx-inverse tx-bold" style={{fontSize: '0.85em'}}>
                <FormattedMessage id='dotOutput'/>
              </h6>
            </div>
          </div>
          <hr style={{ marginTop: '0.5em', marginBottom: '0.5em' }} />
          <div className="row">
            <div className="col-3" style={{ padding: '0 0 0 7px' }}>
              <svg style={{height: '36px', width: '36px'}}>
                <polygon points="18,0 0,18 18,36 36,18" style={{fill: 'blue'}}></polygon>
              </svg>
            </div>
            <div className="col-9 my-auto">
              <h6 className="tx-14 mg-b-0 tx-inverse tx-bold" style={{fontSize: '0.85em'}}>
                <FormattedMessage id='dotTransform'/>
              </h6>
            </div>
          </div>
          <hr style={{ marginTop: '0.5em', marginBottom: '0.5em' }} />
          <div className="row">
            <div className="col-3" style={{ padding: '0 0 0 7px' }}>
              <svg style={{height: '36px', width: '36px'}}>
                <polygon points="18,0 0,18 18,36 36,18" style={{fill: 'red'}}></polygon>
              </svg>
            </div>
            <div className="col-9 my-auto">
              <h6 className="tx-14 mg-b-0 tx-inverse tx-bold" style={{fontSize: '0.85em'}}>
                <FormattedMessage id='dotInput'/>
              </h6>
            </div>
          </div>
          <hr style={{ marginTop: '0.5em', marginBottom: '0.5em' }} />
          <div className="row">
            <div className="col-3" style={{ padding: '0 0 0 7px' }}>
              <svg style={{height: '36px', width: '36px'}}>
                <circle cx="18" cy="18" r="12" style={{fill: '#a38828'}}/>
              </svg>
            </div>
            <div className="col-9 my-auto">
              <h6 className="tx-14 mg-b-0 tx-inverse tx-bold" style={{fontSize: '0.85em'}}>
                <FormattedMessage id='hiddenDot'/>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  render() {
    const { intl: { formatMessage } } = this.props;
    const { nodes, targetNodes, targetNodesFixedOrder, createdDates, viewDateIndex, viewDate, previousViewDate } = this.state;
    const nodeObjects = [];
    const targetNodeObjects = [];
    const targetNodeObjectsFixedOrder = [];
    // convert data from backend to objects with necessary data accessible
    nodes.forEach(originalNode => {
      const node = Object.assign({}, originalNode);
      node.is_accessible = originalNode.is_accessible;
      node.owning_company_name = originalNode.owning_company_name;
      if (node.type === 'dash') {
        const dashFields = node.dash.transaction_field_list;
        const fieldNodes = nodes
          .filter(fieldNode => 
            fieldNode.type === 'field' 
            && dashFields.find(dashField => dashField.id === fieldNode.id)
            && (fieldNode.to.length > 0 || fieldNode.from.length > 0)
          )
          .map(originalFieldNode => {
            const fieldNode = Object.assign({}, originalFieldNode);
            const dotRefType = fieldNode.dash_field.model_field.cargo_reference_type;
            if (dotRefType === 2) {
              fieldNode.type = 'output';
            } else if (dotRefType === 3) {
              fieldNode.type = 'transform';
            } else {
              fieldNode.type = 'input';
            }
            fieldNode.id = fieldNode.graph_id;
            nodeObjects.push(new FieldGraphNode(fieldNode));
            return fieldNode;
          })
        node.fields = fieldNodes;
        node.hash_id = node.dash.id;
        node.id = node.graph_id;
        node.version_dates = node.dash.past_versions.map(pv => pv.modified_on);
        // determine based on the current view date which previous version of a dash
        // should be displayed, if any. Additionally, determine if the current viewed
        // version differs from the previous so we can 'flash' the node in the UI
        let currentVersion = null;
        let previousVersion = null;
        let pastVersionActive = false;
        let previousPastVersionActive = false;
        let activeDash = node.dash;
        node.created_on = node.dash.created_on;
        node.modified_on = node.dash.modified_on;
        node.dash.past_versions.sort((a, b) => new Date(a.modified_on) - new Date(b.modified_on))
        node.dash.past_versions.forEach((pastVersion, index) => {
          if (!pastVersionActive) {
            currentVersion = index + 1;
          }
          if (!previousPastVersionActive) {
            previousVersion = index + 1;
          }
          if ((viewDate - new Date(pastVersion.modified_on)) <= 0 && !pastVersionActive) {
            activeDash = pastVersion;
            currentVersion = index + 1;
            pastVersionActive = true;
          }
          if ((previousViewDate - new Date(pastVersion.modified_on)) <= 0 && !previousPastVersionActive) {
            previousVersion = index + 1;
            previousPastVersionActive = true;
          }
        });
        if (!pastVersionActive && currentVersion) {
          currentVersion++;
        }
        if (!previousPastVersionActive && previousVersion) {
          previousVersion++;
        }
        node.dash = activeDash;
        node.currentVersion = currentVersion;
        node.previousVersion = previousVersion;
        node.title = node.dash.title;

        nodeObjects.push(new DashGraphNode(node));

      } else if (node.type === 'dot') {
        node.id = node.graph_id;
        node.hash_id = node.dot.hash_id;
        node.hidden = node.from.find(fromNode => fromNode.indexOf('dot') > -1) ? true : false;
        node.created_on = node.dot.created_on;
        node.modified_on = node.dot.modified_on;
        node.version_dates = node.dot.past_versions.map(pv => pv.modified_on);
        // determine based on the current view date which previous version of a dot
        // should be displayed, if any. Additionally, determine if the current viewed
        // version differs from the previous so we can 'flash' the node in the UI
        let currentVersion = null;
        let previousVersion = null;
        let pastVersionActive = false;
        let previousPastVersionActive = false;
        let activeDot = node.dot;
        node.dot.past_versions.forEach((pastVersion, index) => {
          pastVersion.isPastVersion = true;
          if (!pastVersionActive) {
            currentVersion = index + 1;
          }
          if (!previousPastVersionActive) {
            previousVersion = index + 1;
          }
          if ((viewDate - new Date(pastVersion.modified_on)) <= 0 && !pastVersionActive) {
            activeDot = pastVersion;
            currentVersion = index + 1;
            pastVersionActive = true;
          }
          if ((previousViewDate - new Date(pastVersion.modified_on)) <= 0 && !previousPastVersionActive) {
            previousVersion = index + 1;
            previousPastVersionActive = true;
          }
        });
        if (!pastVersionActive && currentVersion) {
          currentVersion++;
        }
        if (!previousPastVersionActive && previousVersion) {
          previousVersion++;
        }
        node.dot = activeDot;
        node.currentVersion = currentVersion;
        node.previousVersion = previousVersion;
        node.quantity = (node.dot.status_name === 'claimed' ? '0' : node.dot.quantity);
        node.originalQuantity = node.dot.original_quantity;
        node.title = node.dot.cargo_type.model.title;

        nodeObjects.push(new DotGraphNode(node));

      }
    })
    targetNodes.forEach(entity => {
      const { entityType, entityId } = entity;
      const targetNode = nodeObjects.find(node => node.entityType === entityType && (entityType === 'dash' ? node.hash_id === entityId : node.id === `dot-${entityId}`));
      targetNodeObjects.push(targetNode);
    })
    targetNodesFixedOrder.forEach(entity => {
      const { entityType, entityId } = entity;
      const targetNode = nodeObjects.find(node => node.entityType === entityType && (entityType === 'dash' ? node.hash_id === entityId : node.id === `dot-${entityId}`));
      targetNodeObjectsFixedOrder.push(targetNode)
    })

    let dagreNodes = [], dagreLinks = [], nodesWithVisibility = [];

    let highlightColor = '';

    if (nodeObjects.length > 0)  {
      // collect paths for each target node
      const paths = [];
      // set base number of colors to the nearest increment of 10 so that colors aren't
      // immediately changing with first few path selections
      const numberOfColors = Math.ceil(targetNodeObjectsFixedOrder.length / 10) * 10;
      const palette = distinctColors({
        count: numberOfColors,
        chromaMin: 40,
        chromaMax: 70,
        lightMin: 15,
        lightMax: 85,
      });
      // use sorted list when fetching colors so adding/changing selected
      // paths doesn't automatically shift existing paths colors.
      // copy the array so that the nodes are not sorted in place
      targetNodeObjects.forEach((targetNode, index) => {
        const sortedIndex = targetNodeObjectsFixedOrder.findIndex(node => node.id === targetNode.id);
        const pathColor = palette[sortedIndex].hex();
        targetNode.color = pathColor;
        targetNode.originalColor = pathColor;
        targetNode.isTargeted = true;
        if (index === targetNodeObjects.length - 1) {
          targetNode.highlight = true;
          targetNode.selected = true;
          highlightColor = palette[sortedIndex].hex();
        } else {
          targetNode.highlight = false;
          targetNode.selected = false;
        }
        paths.push(new Path(targetNode, getPath(targetNode, nodeObjects)));
      })

      // don't show any nodes not contained in a path 
      nodesWithVisibility = nodeObjects
        .map(node => {
          node.invisible = !paths.find(path => 
            path.allNodes.find(pathNode => {
              if (pathNode.id === node.id) {
                return true;
              } else if (node.entityType === 'field' && pathNode.entityType === 'dash') {
                return pathNode.entity.fields.find(field => field.id === node.id);
              }
              return false;
            })
          );
          if (node.entityType !== 'field') {
            const visibleDate = node.hidden ? new Date(node.modified_on) : new Date(node.created_on);
            if ((viewDate.getTime() - visibleDate.getTime()) <= 0) {
              node.invisible = true;
            }
          }
          return node;
        });
      nodesWithVisibility = nodesWithVisibility
        .map(node => {
          // do a second pass to hide dots whose originating dash is hidden
          if (node.entityType === 'dot' && node.from.length > 0) {
            const fromNodeId = node.from[0];
            if (fromNodeId.includes('field')) {
              const containingDashNode = nodesWithVisibility
                .find(n => n.entityType === 'dash' && n.fields.find(fieldNode => fieldNode.graph_id === fromNodeId));
              if (containingDashNode && containingDashNode.invisible) {
                node.invisible = true;
              }
            }
          }
          return node;
        })

      // we can eliminate field nodes for processing because
      // dash nodes contain the logic for adding their fields
      nodesWithVisibility
        .filter(node => node.entityType !== 'field')
        .forEach(node => {
          dagreNodes = dagreNodes.concat(node.toDagreNodes());
          //------------------------
          // screen objects language
          //------------------------ 
          dagreNodes.forEach( dn => {
            const { intl } = this.props;
            if(dn.label){
              dn.label = dn.label.replace(/Highlight Path/g, intl.formatMessage({id: 'visualization.highlightPath'}));
              dn.label = dn.label.replace(/Add Path/g, intl.formatMessage({id: 'visualization.addpath'}));
              dn.label = dn.label.replace(/Title:/g, intl.formatMessage({id: 'visualization.title'}));
              dn.label = dn.label.replace(/Template:/g, intl.formatMessage({id: 'visualization.template'}));
              dn.label = dn.label.replace(/Available:/g, intl.formatMessage({id: 'visualization.available'}));
            }
          })
          //------------------------
          // End language
          //------------------------
        });
      
      dagreLinks = getEdges(nodesWithVisibility, this.state.hoveredEdge);

    }
    
    const customStyles = {
      multiValue: (provided, state) => {
        return {
          ...provided,
          backgroundColor: state.data.value.originalColor || state.data.value.color,
        }
      },
      multiValueLabel: (provided, state) => {
        return {
          ...provided,
          color: `${invertColor(state.data.value.originalColor || state.data.value.color, true)}`,
          fontWeight: '900',
          fontSize: 'medium',
        }
      },
      multiValueRemove: (provided, state) => {
        return {
          ...provided,
          backgroundColor: state.data.value.originalColor || state.data.value.color,
          color: `${invertColor(state.data.value.originalColor || state.data.value.color, true)}`,
        }
      },
      valueContainer: (provided, state) => {
        return {
          ...provided,
          maxHeight: 'calc(60px + 3em)',
          overflowY: 'scroll',
        }
      }
    };

    // for each created on date, build a 'mark' for the slider
    const dateCount = createdDates.length;
    const dateMarks = {};
    createdDates.forEach((date, index) => {
      dateMarks[index] = date.toLocaleDateString()
    })

    // construct multiselect options from the nodes the user has selected
    const targetNodeMultiselectValues = targetNodes.map(entity => {
      const { entityType, entityId } = entity;
      const targetNode = nodeObjects.find(node => node.entityType === entityType && (entityType === 'dash' ? node.hash_id === entityId : node.id === `dot-${entityId}`));
      return {label: this.getNodeOptionLabel(targetNode), value: targetNode}
    });

    const modalTitle = <>
      {(this.state.entityModalType === 'dash') ? <FormattedMessage id='event'/> : <FormattedMessage id='dot'/>} {this.state.modalTitle}
    </>

    const MAX_DEFAULT_SCALE = 1.5;
    const MIN_DEFAULT_SCALE = 0.5;
    const MAX_SCALE = 5;
    const MIN_SCALE = 0.2;

    let tooltipDotId = '', tooltipDotQuantity = '', tooltipDotTemplate = '';
    if (this.state.hoveredMiniDot) {
      const miniDot = this.state.hoveredMiniDot.original.dorae.node.dot;
      tooltipDotId = miniDot.hash_id;
      tooltipDotQuantity = miniDot.originalQuantity;
      tooltipDotTemplate = miniDot.title;
    }

    const clickNext = () => {
      const { viewDateIndex, createdDates } = this.state;
      if (viewDateIndex < (createdDates.length - 1)) {
        this.onViewDateChange(viewDateIndex + 1);
      }
    }

    const clickPrevious = () => {
      const { viewDateIndex } = this.state;
      if (viewDateIndex > 0) {
        this.onViewDateChange(viewDateIndex - 1);
      }
    }

    
    const timelineSlider = (
      <div>
        <div className={'timeline-previous round no-select' + (viewDateIndex === 0 ? ' disabled' : '')} onClick={clickPrevious}> &#8249; </div>
        <div className={'timeline-next round no-select' + (viewDateIndex === (createdDates.length - 1) ? ' disabled' : '')} onClick={clickNext}> &#8250; </div>
        <SliderControl
          disabled={this.state.pathLoading || createdDates.length === 1}
          min={0}
          max={dateCount - 1}
          initialValue={dateCount - 1}
          step={1}
          marks={dateMarks}
          valueDisplay={value => `${createdDates[value].toLocaleDateString()} ${createdDates[value].toLocaleTimeString()}`}
          onChange={this.onViewDateChange}
          name="visibilityDateSlider"
          value={this.state.viewDateIndex}
        />
      </div>
    )

    const timelineSliderWrapped = (createdDates.length === 1) ?
      <Tooltip trigger={['hover']} placement='top' overlay={<span>{formatMessage({ id: `noActivityMessage` })}</span>}>
          {timelineSlider}
      </Tooltip>
      :
      (timelineSlider)

    return (
      <div ref={this.contentRef}>
        <LoadingOverlay
            active={this.state.pathLoading}
            spinner
            text={<FormattedMessage id='common.loading'/>}
        >
          <CargoTypeModal
              modalOpen={this.state.entityModalOpen}
              setModalOpen={(open) => this.setState({ entityModalOpen: open })}
              cargoType={this.state.entity}
              showQuantityInfo={false}
              dot={null}
              hideTitleAndDescription={this.state.entityModalType === 'dot'}
              readOnly={true}
              modalTitle={modalTitle}
              showLink={this.state.entityModalType !== 'dot'}
          />
          <ConfirmModal
              id='inaccessible-dash-modal'
              open={this.state.inaccessibleEntityModalOpen}
              setOpen={(o) => this.setState({ inaccessibleEntityModalOpen: o })}
              onConfirm={() => null}
              hideConfirm={true}
              title={<FormattedMessage id='inaccessibleExternalDashTitle' />}
              body={<FormattedHTMLMessage id='inaccessibleExternalEntity' tagName='div' values={{ companyName: this.state.externalCompanyName, entityType: this.state.entityType }} />}
              cancelLabel={<FormattedMessage id='common.close' />}
          />
          <ConfirmModal
              id='inaccessible-dots-in-dash-modal'
              open={this.state.nestedInaccessibleEntityModalOpen}
              setOpen={(o) => this.setState({ nestedInaccessibleEntityModalOpen: o })}
              onConfirm={() => null}
              hideConfirm={true}
              title={<FormattedMessage id='inaccessibleExternalDashTitle' />}
              body={<FormattedMessage id='containsInaccessibleDotsMessageInModal' values={{ entityType: this.state.entityType }} />}
              cancelLabel={<FormattedMessage id='common.close' />}
          />
          <h5><FormattedMessage id='targetEntities'/></h5>
          <div
            ref={this.targetEntitesRef}
          >
            <Select
              placeholder={<FormattedMessage id='targetEntities'/>}
              value={targetNodeMultiselectValues}
              options={targetNodeMultiselectValues}
              isMulti={true}
              isClearable={false}
              isSearchable={false}
              onChange={(vals) => {
                if (vals.length > 0) {
                  this.setState({ 
                    targetNodes: vals.map(v => {
                      const node = v.value;
                      return {
                        entityId: (node.entityType === 'dash' ? node.hash_id : node.id.replace('dot-', '')),
                        entityType: node.entityType
                      }
                    })
                  })
                }
              }}
              components={{ 
                  IndicatorSeparator: () => null, 
                  DropdownIndicator: () => null, 
                  Menu: () => null,
                  MultiValueLabel: props => (
                    <span 
                      onClick={() => {
                        this.setState(prevState => {
                          let { targetNodes } = prevState;
                          let targetNodeObjects = [];
                          targetNodes.forEach(entity => {
                            const { entityType, entityId } = entity;
                            const targetNode = nodeObjects.find(node => node.entityType === entityType && (entityType === 'dash' ? node.hash_id === entityId : node.id === `dot-${entityId}`));
                            targetNodeObjects.push(targetNode);
                          })
                          const nodeClicked = props.data.value;
                          targetNodeObjects = targetNodeObjects.filter(node => node.id !== nodeClicked.id);
                          targetNodeObjects.push(nodeClicked);
        
                          targetNodes = targetNodeObjects.map(node => ({
                            entityId: (node.entityType === 'dash' ? node.hash_id : node.id.replace('dot-', '')),
                            entityType: node.entityType
                          }))
        
                          return { targetNodes };
                        })
                      }} 
                    >
                      <components.MultiValueLabel {...props} />
                    </span>
                  ),
                  ValueContainer: props => {
                    const children = props.children;
                    const options = children[0];
                    const input = children[1];
                    if (!Array.isArray(options)) {
                      return <components.ValueContainer {...props} />
                    }
                    const optionsFixed = options.map(c => ({...c, key: JSON.stringify(c.props.data)}))
                    const childrenFixed = [optionsFixed, input];
                    return <components.ValueContainer {...props} children={childrenFixed} />
                  },
              }}
              theme={theme => ({
                  ...theme,
                  colors: {
                      ...theme.colors,
                      primary25: '#eeeeee',
                      primary: '#00336B'
                  }
              })}
              styles={customStyles}
            />
          </div>
          <DagreGraph
              nodes={dagreNodes}
              links={dagreLinks}
              config={{
                  rankDir: 'LR',
                  // ranker options: network-simplex, tight-tree, or longest-path
                  ranker: 'network-simplex'
              }}
              width={this.state.contentWidth}
              height={this.state.contentHeight}
              // animate={!!this.state.visualizationZoom || 1000}
              fitBoundaries
              zoomable={true}
              onNodeIdClick={e => {
                const node = e.original.dorae.node;
                if (node.is_accessible) {
                  if (node.entityType === 'dash') {
                    if (node.dash.dash.model.contains_inaccessible_dots) {
                      this.setState({ nestedInaccessibleEntityModalOpen: true, entityType: formatMessage({ id: node.entityType }) })
                    } else {
                      this.setState({ entityModalOpen: true, entity: node.dash.dash, modalTitle: node.dash.hash_id, entityModalType: node.entityType })
                    }
                  } else {
                    this.setState({ entityModalOpen: true, entity: node.dot.dot.cargo_type, modalTitle: node.dot.hash_id, entityModalType: node.entityType })
                  }
                } else {
                    this.setState({ inaccessibleEntityModalOpen: true, externalCompanyName: node.owning_company_name, entityType: formatMessage({ id: node.entityType }) })
                }
              }}
              onNodeButtonClick={e => {
                this.setState(prevState => {
                  let { targetNodes, targetNodesFixedOrder } = prevState;
                  let targetNodeObjects = [];
                  let targetNodeObjectsFixedOrder = [];
                  targetNodes.forEach(entity => {
                    const { entityType, entityId } = entity;
                    const targetNode = nodeObjects.find(node => node.entityType === entityType && (entityType === 'dash' ? node.hash_id === entityId : node.id === `dot-${entityId}`));
                    targetNodeObjects.push(targetNode);
                  })
                  targetNodesFixedOrder.forEach(entity => {
                    const { entityType, entityId } = entity;
                    const targetNode = nodeObjects.find(node => node.entityType === entityType && (entityType === 'dash' ? node.hash_id === entityId : node.id === `dot-${entityId}`));
                    targetNodeObjectsFixedOrder.push(targetNode)
                  })
                  // items will not be removed or reordered from this list so we can use it for 
                  // accessing color indices
                  const nodeClicked = e.original.dorae.node;
                  if (!targetNodeObjectsFixedOrder.find(node => node.id === nodeClicked.id)) {
                    targetNodeObjectsFixedOrder.push(nodeClicked);
                  }
                  // the standard list will move re-selected nodes to the end, so they are always
                  // the right-most items in the UI for convenience.
                  targetNodeObjects = targetNodeObjects.filter(node => node.id !== nodeClicked.id);
                  targetNodeObjects.push(nodeClicked);

                  targetNodes = targetNodeObjects.map(node => ({
                    entityId: (node.entityType === 'dash' ? node.hash_id : node.id.replace('dot-', '')),
                    entityType: node.entityType
                  }))
                  targetNodesFixedOrder = targetNodeObjectsFixedOrder.map(node => ({
                    entityId: (node.entityType === 'dash' ? node.hash_id : node.id.replace('dot-', '')),
                    entityType: node.entityType
                  }))

                  return { targetNodes, targetNodesFixedOrder };
                })
              }}
              // onRelationshipClick={e => console.log(e)}
              onRelationshipHover={(e, f) => {
                let edgeHoverTimeout = this.state.edgeHoverTimeout;
                if (edgeHoverTimeout) {
                  clearTimeout(edgeHoverTimeout);
                }
                edgeHoverTimeout = setTimeout(() => {
                  this.setState({ hoveredEdge: null })
                }, 2000)
                this.setState({ hoveredEdge: e, edgeHoverTimeout })
              }}
              onRelationshipHoverEnd={(e, f) => this.setState({ hoveredEdge: null })}
              onHiddenDotHover={(dot) => {
                if (dot.original.dorae.node.invisible) {
                  return;
                }
                if (!this.state.isMouseTooltipVisible && !this.state.pauseMiniDotHover) {
                  this.setState({ isMouseTooltipVisible: true, hoveredMiniDot: dot })
                  // this.debouncedShowMiniDotTooltip(dot)
                  let miniDotPauseTimeout = this.state.miniDotPauseTimeout;
                  if (miniDotPauseTimeout) {
                    clearTimeout(miniDotPauseTimeout);
                  }
                  miniDotPauseTimeout = setTimeout(() => {
                    this.setState({ isMouseTooltipVisible: false })
                  }, 2000)
                  this.setState({ miniDotPauseTimeout });
                }
              }}
              onHiddenDotHoverEnd={() => {
                let miniDotHoverPauseTimeout = this.state.miniDotHoverPauseTimeout;
                this.setState({ isMouseTooltipVisible: false, pauseMiniDotHover: true });
                if (miniDotHoverPauseTimeout) {
                  clearTimeout(miniDotHoverPauseTimeout);
                }
                miniDotHoverPauseTimeout = setTimeout(() => {
                  this.setState({ pauseMiniDotHover: false })
                }, 500)
                this.setState({ miniDotHoverPauseTimeout });
              }}
              highlightColor={highlightColor}
              onZoom={zoom => this.debouncedPersistZoom(zoom)}
              previousTransform={this.state.visualizationZoom}
              maxDefaultScale={MAX_DEFAULT_SCALE}
              minDefaultScale={MIN_DEFAULT_SCALE}
              maxScale={MAX_SCALE}
              minScale={MIN_SCALE}
              hideFlash={this.state.hideFlash}
          />
          <div 
            className="row" 
            style={{
              display: (this.state.pathLoading || nodesWithVisibility.filter(n => n.entityType !== 'field' && !n.invisible).length > 0) ? 'none' : 'flex',
              position: 'absolute',
              bottom: '100px',
              left: '0',
              right: '0'
            }}
          >
            <div className="col-6 offset-3 text-center">
              <h6 className="tx-14 mg-b-0 tx-inverse tx-bold" >
                <FormattedMessage id='noNodesToDisplay' />
              </h6>
              <br/>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              {!this.state.pathLoading && this.legendElement()}
              <div className='btn btn-outline-info' onClick={() => this.setState(prevState => ({ showLegend: !prevState.showLegend }))}>
                <FormattedMessage id={this.state.showLegend ? 'hideKey' : 'showKey'}/>
              </div>
            </div>
            <div className="col-6">
              {timelineSliderWrapped}
            </div>
          </div>
        </LoadingOverlay>
        <MouseTooltip
          visible={this.state.isMouseTooltipVisible}
          offsetX={20}
          offsetY={20}
        >
          <div className="shadow-base">
            <div className="card">
              <div className="card-body">
                <h6 className="tx-14 mg-b-0 tx-inverse tx-bold" >
                  <FormattedMessage id='dot' />: {tooltipDotId}
                </h6>
                <FormattedMessage id='quantity' />: {tooltipDotQuantity}
                <br/>
                <FormattedMessage id='common.template' />: {tooltipDotTemplate}
              </div>
            </div>
          </div>
        </MouseTooltip>
      </div>
    );
      
  }
}

PathVisualization.meta = {
  showTitleBlock: false,
  title: <FormattedMessage id="page.lots.title" />,
  subtitle: <FormattedMessage id="page.lots.subtitle" />,
  routes: ['/visualize'],
  icon: 'ion-ios-world-outline',
  menus: { main: true, user: false },
  order: 10
};

const mapStateToProps = state => {
  return {
    menuClass: state.menus.menuClass,
    isMobile: state.menus.isMobile,
  };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getTransaction,
            getTransactionRelatedData,
            getTransactionDeleteSideEffects,
            transactionFieldRevise,
            getTransactionFieldSideEffectsDetails,
            getEntityGraph,
            visitEntityGraph,
        },
        dispatch
    );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(PathVisualization));