import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import Page from '../../../components/page';

class CompanyPaymentInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    renderActions = () => {
        return null;
    };

    render() {
        const { parents, company } = this.props;
        if (!company) return null;

        return (
            <Page {...CompanyPaymentInfo.meta} parents={parents} actions={this.renderActions()}>
                <div className="row">
                    <div className="col-12">
                        <span>payment info ...</span>
                    </div>
                </div>
            </Page>
        );
    }
}

CompanyPaymentInfo.meta = {
    title: <FormattedMessage id="page.companies.billing.paymentInfo.title" />,
    subtitle: <FormattedMessage id="page.companies.billing.paymentInfo.subtitle" />,
    routes: ['/settings/billing/payment-info'],
    icon: 'ion-ios-world-outline',
    menus: { main: false, user: false },
    order: 0
};

const mapStateToProps = state => {
    return {
        company: state.identity.company
    };
};
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanyPaymentInfo);
