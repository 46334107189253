import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import ReactModal from 'react-modal';
import { Button, Popover, Menu, MenuItem, Icon } from '@blueprintjs/core';
import Page from '../../../components/page';
import { history } from '../../../configuration/history';
import { getUserRoles, getUsers } from '../../../reducers/users';
import { getModels } from '../../../reducers/models';
import countries from './../../../assets/countries.json';
import CompanyInfo from '../components/forms/companyInfo';
import { getPlans, getProducts } from '../../../reducers/payment';
import BillingInfo from '../components/forms/billingInfo';
import doraeLogo from 'assets/logo-green.svg';

class CompanyDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = { modal: { open: false, type: undefined } };
    }

    setModalVisibility = (visibility, type) => this.setState({ ...this.state, modal: { open: visibility, type: !!visibility ? type : undefined } });

    componentDidMount() {
        const { company, roles, getUserRoles, getUsers, getModels, plans, getPlans, products, getProducts } = this.props;

        const { is_paid } = company;
        if (!!is_paid) {
            if (!roles) getUserRoles({ company_id: company.id });
            getUsers({ company_ids: [company.id], status_ids: [1, 2, 3, 4, 5] });
            getModels();

            if (!plans) getPlans();
            if (!products) getProducts();
        }
    }

    renderCard = (title, value, classes = 'col-sm-6 col-lg-3') => {
        return (
            <div className={classes}>
                <div className="card">
                    <div className="card-header">
                        <h6 className="card-title">{title}</h6>
                    </div>
                    <div className="card-body">
                        <span className="color-primary">{value}</span>
                    </div>
                </div>
            </div>
        );
    };

    renderCards = () => {
        const { company } = this.props;
        const { is_paid, subscription_seats = '', subscription_type } = company;

        if (!!is_paid) {
            return (
                <React.Fragment>
                    {this.renderCard(<FormattedMessage id='subscriptionType' />, <span className="st-box-company-name dorae_subscription-row"><img src={doraeLogo} alt="Dorae" className="dorae_subscription-logo" /> {subscription_type === 'pro' ? 'Pro' : 'Standard'}</span>, 'col-6')}
                    {this.renderCard(<FormattedMessage id='totalLicenses' />, subscription_seats, 'col-6')}
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <div className="col-9 unpaid-info-box">
                    <div className="card">
                        <div className="card-header">
                            <FormattedMessage id="page.settings.setup.sub.choosePlan" />
                        </div>
                        <div className="card-body">
                            <FormattedMessage id="page.settings.setup.sub.choosePlanInfo" />
                        </div>
                    </div>
                </div>
                <div className="col-3 unpaid-button tx-right">
                    <button className="col" onClick={() => history.push('/settings/billing/plans' + window.location.search)}>
                        <FormattedMessage id="page.settings.setup.sub.goToChoosePlan" />
                    </button>
                </div>
            </React.Fragment>
        );
    };

    renderInfoLine = (title, value, width = 'col') => {
        return (
            <div className={`${width} mg-b-8`}>
                <div className="tx-10 tx-uppercase tx-mont tx-medium tx-spacing-1 mg-b-2">{title}</div>
                <span className="tx-info">{value}</span>
            </div>
        );
    };

    renderActions = () => {
        const {
            company: { is_paid }
        } = this.props;

        if (!is_paid) return null;

        return (
            <div className="btn-group pd-t-10">
                <span className="btn btn-outline-info" onClick={() => history.push(`/settings/billing` + window.location.search)}>
                    <FormattedMessage id="page.companies.billing.manageLicensesAndUsers" />
                </span>
            </div>
        );
    };

    render() {
        const { modal } = this.state;
        const { user, company } = this.props;
        if (!user || !company) return null;

        const { payment_source } = company;
        const isFreeTrial = company.id !== 1 && (payment_source === null || payment_source === undefined || payment_source === '');

        return (
            <React.Fragment>
                <Page {...CompanyDetails.meta} actions={this.renderActions()} panel={false}>
                    <div className="row">
                        <div className="col">
                            <div className="row no-gutters widget-1 shadow-base">{this.renderCards()}</div>
                        </div>
                    </div>
                    <br/>
                    <div className="shadow-base">
                        <div className="card">
                            <div className='widget-1'>
                                <div className="card-header row">
                                    <h6 className="col-10 card-title"><FormattedMessage id='companyDetails'/></h6>
                                    <div className="col-2 company-details-edit tx-right">
                                        <Popover
                                            position="left"
                                            content={
                                                <Menu>
                                                    <MenuItem
                                                        icon="edit"
                                                        text={<FormattedMessage id="common.edit" />}
                                                        onClick={() => this.setModalVisibility(true, 'company-info')}
                                                    />
                                                    {   !isFreeTrial && 
                                                        <MenuItem
                                                            icon="credit-card"
                                                            text={<FormattedMessage id="page.settings.setup.creditCard" />}
                                                            onClick={() => this.setModalVisibility(true, 'billing-info')}
                                                        />
                                                    }
                                                </Menu>
                                            }
                                        >
                                            <Button minimal={true} small={true} icon={<Icon iconSize={12} icon="menu" />} />
                                        </Popover>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-10">
                                        <h4 className="color-primary">{company.company_name}</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    {this.renderInfoLine(
                                        <FormattedMessage id="website.placeholder" />,
                                        !!company.website ? (
                                            <span className="clickable" onClick={() => window.open(company.website, '_blank')}>
                                                {company.website}
                                            </span>
                                        ) : (
                                            ''
                                        ),
                                        'col-4'
                                    )}
                                    {this.renderInfoLine(
                                        <FormattedMessage id="country_id.placeholder" />,
                                        (countries.find(c => c.code === company.country_code2) || { title: '' }).title,
                                        'col-4'
                                    )}
                                </div>
                                <div className="row">
                                    {this.renderInfoLine(<FormattedMessage id="address_1.placeholder" />, company.address_1 || '', 'col-4')}
                                    {this.renderInfoLine(<FormattedMessage id="address_2.placeholder" />, company.address_2 || '', 'col-4')}
                                </div>
                                <div className="row">
                                    {this.renderInfoLine(<FormattedMessage id="common.city" />, company.city || '')}
                                    {this.renderInfoLine(<FormattedMessage id="state.placeholder" />, company.state || '')}
                                    {this.renderInfoLine(<FormattedMessage id="zip.placeholder" />, company.zip || '')}
                                </div>
                                <div className="row">
                                    <div className="col pd-15 pd-t-20 settings-summary-group-title">
                                        <FormattedMessage id="page.account.forms.presubmission.subscribe.user.admin" />
                                    </div>
                                </div>
                                <div className="row">
                                    {this.renderInfoLine(<FormattedMessage id="first_name.placeholder" />, user.name.split(' ')[0], 'col-4')}
                                    {this.renderInfoLine(<FormattedMessage id="last_name.placeholder" />, user.name.replace(user.name.split(' ')[0], ''), 'col-4')}
                                </div>
                                <div className="row">{this.renderInfoLine(<FormattedMessage id="email.placeholder" />, user.email || '', 'col')}</div>
                                <div className="row">{this.renderInfoLine(<FormattedMessage id="phone1.placeholder" />, user.phone || '', 'col')}</div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="br-pagebody pd-x-0">
                        <div className="br-section-wrapper pd-30">
                            
                        </div>
                    </div> */}
                </Page>
                <ReactModal
                    isOpen={modal.open}
                    onRequestClose={() => this.setModalVisibility(false)}
                    className="modal-block dialog"
                    overlayClassName="modal-overlay gray"
                >
                    {modal.type === 'company-info' ? (
                        <CompanyInfo onCancel={() => this.setModalVisibility(false)} company={company} />
                    ) : (
                        <BillingInfo onCancel={() => this.setModalVisibility(false)} company={company} />
                    )}
                </ReactModal>
            </React.Fragment>
        );
    }
}

CompanyDetails.meta = {
    title: <FormattedMessage id="page.settings.title" />,
    subtitle: <FormattedMessage id="page.settings.subtitle" />,
    routes: ['/settings'],
    icon: 'ion-ios-briefcase-outline',
    menus: { main: false, user: false },
    order: 20
};

const mapStateToProps = state => {
    return {
        user: state.identity.user,
        company: state.identity.company,
        roles: state.users.roles,
        users: state.users.list,
        total: state.users.total,
        permissions: state.identity.permissions,
        totalTransactions: state.transactions.total,
        totalModels: state.models.total,
        plans: state.payment.plans,
        products: state.payment.products
    };
};
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getUserRoles,
            getUsers,
            getModels,
            getPlans,
            getProducts
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanyDetails);
