import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getProfile } from 'reducers/identity';
import { loadModel } from 'reducers/models';
import { setMenuVisibility } from 'reducers/menus';
import { getDocumentTemplate, updateDocumentTemplate, getDocumentSample } from 'reducers/document';
import { FormattedMessage } from 'react-intl';
import 'pages/models/models.scss';
import 'components/fields/fields.scss';
import Iframe from 'react-iframe';
import LoadingOverlay from 'react-loading-overlay';
import EdiText from 'react-editext'
import FieldPickerModal from 'pages/documentTemplates/components/fieldPickerModal';
import { getModels } from 'reducers/models';
import { Notifications } from 'components/notifications';
import FieldGroupPickerModal from './fieldGroupPickerModal';
import { addFieldReferenceToGroup, removeFieldReferenceFromGroup, setFieldGroupReferences, getDocumentReferenceData } from 'reducers/documents';
import { getExcelFieldReferences, addMetadataToFieldReferences } from 'utilities/common';
import { Popover, Menu, MenuItem, MenuDivider } from '@blueprintjs/core';
import ConfirmModal from 'components/modals/confirm';
import FieldRefCopyPasteModal from 'components/modals/fieldReferenceCopyPasteModal';
//import { isEqual } from 'lodash';

const DefaultDocument = { file_data: `{"version":"14.0.3","customList":[],"sheets":{"Sheet1":{"name":"Sheet1","isSelected":true,"theme":"Office","data":{"defaultDataNode":{"style":{"themeFont":"Body"}},"dataTable":{}},"rowHeaderData":{"defaultDataNode":{"style":{"themeFont":"Body"}}},"colHeaderData":{"defaultDataNode":{"style":{"themeFont":"Body"}}},"leftCellIndex":0,"topCellIndex":0,"selections":{"0":{"row":0,"rowCount":1,"col":0,"colCount":1},"length":1},"rowOutlines":{"items":[]},"columnOutlines":{"items":[]},"cellStates":{},"outlineColumnOptions":{},"autoMergeRangeInfos":[],"printInfo":{"showBorder":false,"useMax":false,"paperSize":{"width":826.7716535433073,"height":1169.2913385826773,"kind":9}},"index":0}}}` };

class ExcelDocumentTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            previewData: "",
            loadingPreview: true,
            contentChanged: false,
            fieldModalVisible: false,
            cargoCategories: [],
            fauxSaving: false,
            fieldRefGroups: [],
            fieldRefGroupRefs: [],
            fieldRefs: [],
            groupModalRenderCount: 0,
            doc_file_data: null,
            fieldRefModalRenderCount: 0,
            readyToRender: true
        };
        this.waitForResponse = null;
    }

    fauxSave = () => {
        const { setRootLoadingState } = this.props;
        setRootLoadingState(true);
        this.setState({readyToRender: false, fauxSaving: true}, () => {
            this.sendIframeMessage('initsave');
        })
    }

    processMessage = (evt) => {
        const { template, updateDocumentTemplate, getDocumentTemplate, setRootLoadingState, setFieldGroupReferences, getDocumentReferenceData, hideFieldReferenceCopyPastedWarning } = this.props;
        // only handle local messages (i.e. coming from the iframe)
        if (evt.origin === window.location.origin) {
            const { type, data } = evt.data;
            if (type) {
                if (type === 'save') {
                    const promises = [];
                    console.log("message save");
                    setRootLoadingState(true);
                    promises.push(new Promise(resolve => this.setState({readyToRender: false, contentChanged: true}, () => {
                        template.title = this.state.templateTitle;
                        const { excel, json, metadata } = data;
                        template.file_data = json;
                        promises.push(new Promise(resolve => this.setState({readyToRender: false, doc_file_data: json }, resolve())));
                        template.server_file_data = excel;
                        const parsedData = JSON.parse(metadata);
                        template.gc_show_print_lines = parsedData.showPrintLines;
                        template.gc_paper_size = parsedData.paperSize;
                        template.culture = parsedData.doraeCulture;
                        updateDocumentTemplate(template, () => {
                            if (this.state.fauxSaving) {
                                Notifications.success(<FormattedMessage id="templatesavedsuccessfully" />)
                            }
                            promises.push(new Promise(resolve => this.setState({readyToRender: false, contentChanged: false, fauxSaving: false}, resolve())));
                            getDocumentTemplate(template.id, () => {
                                setTimeout(() => {
                                    setRootLoadingState(false);
                                }, 700)
                            });

                            const parsedTemplate = JSON.parse(this.state.doc_file_data);
                            const fieldReferences = getExcelFieldReferences(parsedTemplate);
                            const potentiallyAlteredRefs = this.state.fieldRefGroupRefs.filter(sRef => {
                                return !fieldReferences.find(fRef => fRef.literal === sRef.field_reference_literal);
                            });
                            const reloadRefs = () => {
                                getDocumentReferenceData({ field_references: fieldReferences, document_template_id: template.id }, (dt) => {
                                    const fieldReferencesWithData = addMetadataToFieldReferences(fieldReferences, dt)
                                    promises.push(new Promise(resolve => this.setState({readyToRender: false,  fieldRefGroups: dt.ref_groups, fieldRefGroupRefs: dt.ref_group_references, fieldRefs: fieldReferencesWithData, fieldGroupModalVisible: false, fieldRefGroupsLoading: false }, resolve())));
                                });     
                            };
                            if (potentiallyAlteredRefs.length > 0) {
                                // initialize the array with all the group references that were not altered
                                const group_references = this.state.fieldRefGroupRefs
                                    .filter(sRef => {
                                        return fieldReferences.find(fRef => fRef.literal === sRef.field_reference_literal);
                                    })
                                    .map(unalteredRef => ({
                                        name: unalteredRef.name,
                                        field_reference_literal: unalteredRef.field_reference_literal,
                                    }));

                                // make sure any altered field references are unambiguously identifiable as the one previously in a group
                                for (const alteredRef of potentiallyAlteredRefs) {
                                    const matchingRefs = fieldReferences.filter(fRef => alteredRef.field_reference_literal.indexOf(`${fRef.token}`) > -1);
                                    // If we find one and only one field ref with a matching token, we'll assume the user modified 
                                    // the label and move the updated field reference back into the group it belonged to
                                    if (matchingRefs.length === 1) {
                                        const updatedRef = matchingRefs[0];
                                        group_references.push({
                                            name: alteredRef.name,
                                            field_reference_literal: updatedRef.literal,
                                        });
                                    }
                                }
                                // update field reference groups and then refresh
                                setFieldGroupReferences({ group_references, document_template_id: template.id }, reloadRefs, reloadRefs);
                            } else {
                                reloadRefs();
                            }

                        }, () => {
                            promises.push(new Promise(resolve => this.setState({readyToRender: true,  fauxSaving: false }, resolve())));
                            setRootLoadingState(false);
                        });
                    }, resolve())));
                    Promise.all(promises);
                } else if (type === 'loadcomplete') {
                    console.log("message loadcomplete");
                    if (template.metadata) {
                        const parsedMeta = JSON.parse(template.metadata);
                        if (parsedMeta.showPrintLines !== undefined && parsedMeta.paperSize !== undefined) {
                            const data = {
                                showPrintLines: parsedMeta.showPrintLines,
                                paperSize: parsedMeta.paperSize,
                                doraeCulture: parsedMeta.culture,
                            };
                            this.sendIframeMessage('loadmetadata', JSON.stringify(data));
                        }
                    }
                    setTimeout(() => {
                        this.setState({readyToRender: true,  loadingPreview: false });
                    }, 200)
                } else if (type === 'ack') {
                    console.log("message ack");
                    this.setState({readyToRender: false,  receivedIframeAck: true })
                } else if (type === 'fieldrefcopied') {
                    if (!hideFieldReferenceCopyPastedWarning) {
                        console.log("message fieldrefcopied");
                        this.setState({readyToRender: false,  showFieldRefCopyPasteModal: true });
                    }
                    // excelHideFieldReferenceCopyPastedWarning
                }
            }
        }
    }

    checkIframeLoaded = (dt) => {
        // Get a handle to the iframe element
        var iframe = document.getElementById('excel-iframe');
        if (iframe) {
            var iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
            
            if (iframeDoc.readyState  === 'complete') {
                this.setState({readyToRender: false, receivedIframeAck: false}, () => {
                    this.waitForResponse = setInterval(() => {
                        if (!this.state.receivedIframeAck) {
                            console.info('No response from excel iframe - pinging again')
                            this.sendIframeMessage('sig', 'sig');
                        } else {
                            this.setState({readyToRender: true,  receivedIframeAck: true })
                            clearInterval(this.waitForResponse);
                            this.waitForResponse = null;
                            this.sendIframeMessage('loadssjson', dt.file_data);
                        }
                    }, 300);
                });
                return;
            }
        }
    
        // If we are here, it is not loaded. Set things up so we check   the status again in 100 milliseconds
        window.setTimeout(() => {this.checkIframeLoaded(dt)}, 100);
    }

    componentDidMount() {
        const { match, getDocumentTemplate, getDocumentReferenceData, getModels } = this.props;
        const promises = [];

        if (window.addEventListener) {
            // For standards-compliant web browsers
            window.removeEventListener("message", this.processMessage);
            window.addEventListener("message", this.processMessage, false);
        }
        else {
            window.detachEvent("onmessage", this.processMessage);
            window.attachEvent("onmessage", this.processMessage);
        }
        
        window.addEventListener('beforeunload', (event) => {
            if (this.state.contentChanged ) {
                event.preventDefault();
                event.returnValue = 'You have unfinished changes!';
            }
        });
        getDocumentTemplate(match.params.id, (dt) => {
            //this.setState({templateTitle: dt.title})
            promises.push(new Promise(resolve => this.setState({readyToRender: false, templateTitle: dt.title}, resolve())));
            if (dt.server_file_data) {
                this.checkIframeLoaded(dt); 
            } else {
                this.checkIframeLoaded(DefaultDocument);
                // this.setState({ loadingPreview: false });
            }
            const parsedTemplate = JSON.parse(dt.file_data);
            let fieldReferences = getExcelFieldReferences(parsedTemplate);
            getDocumentReferenceData({ field_references: fieldReferences, document_template_id: match.params.id }, (withRefData) => {
                const fieldReferencesWithData = addMetadataToFieldReferences(fieldReferences, withRefData);
                promises.push(new Promise(resolve => this.setState({readyToRender: false, fieldRefGroups: withRefData.ref_groups, fieldRefGroupRefs: withRefData.ref_group_references, fieldRefs: fieldReferencesWithData, doc_file_data: dt.file_data }, resolve)));
            });  
        });
        getModels({model_type: 2}, (cargoCategories) => {
            promises.push(new Promise(resolve => this.setState({readyToRender: false, cargoCategories: cargoCategories.data}, resolve)));
        }, false)
        Promise.all(promises);
    }

    componentWillUnmount() {
        if (window.addEventListener) {
            // For standards-compliant web browsers
            window.removeEventListener("message", this.processMessage);
        }
        else {
            window.detachEvent("onmessage", this.processMessage);
        }
    }

    onSaveTitle = (val) => {
        console.log("onSaveTitle");
        const { template, updateDocumentTemplate, getDocumentTemplate } = this.props;
        this.setState({readyToRender: true,  templateTitle: val, contentChanged: true }, () => {
            template.title = val;
            updateDocumentTemplate(template, () => {
                getDocumentTemplate(template.id, () => {
                    this.setState({readyToRender: true, contentChanged: false});
                });
            });
        });
    }

    sendIframeMessage(type, data) {
        const msg = { type, data };
        const iframe = document.getElementById("excel-iframe");
        if (iframe) {
            var iframeWin = iframe.contentWindow;
            iframeWin.postMessage(msg, window.location.origin);
        } else {
            console.error('iframe not loaded!')
        }
    }

    onFieldSelect(field, ignoreDuplicates = false) {
        console.log("onFieldSelect");
        const { addFieldReferenceToGroup, match } = this.props;

        let lotTargetText = '';
        if (field.lotTarget) {
            if (field.lotTarget === 'Quantity') {
                lotTargetText = '.1';
            } else if (field.lotTarget === 'DoT Unit') {
                lotTargetText = '.2';
            } else {
                if (field.lotTarget.indexOf('l') > -1) {
                    lotTargetText = '.' + field.lotTarget;
                } else {
                    lotTargetText = '.f' + field.lotTarget;
                }
            }
        }
        const refLiteral = `{{${field.title} [$${field.type}.${field.id}${lotTargetText}]}}`;

        if (!ignoreDuplicates) {
            const { fieldRefs } = this.state;
            const exactFieldRefMatches = fieldRefs.filter(ref => ref.literal === refLiteral);
            if (exactFieldRefMatches.length > 0) {
                this.setState({readyToRender: true,  fieldReferenceToConfirm: field, confirmFieldRefModalOpen: true, confirmFieldRefModalMessage: <FormattedMessage id='duplicateFieldRefWarning' /> });
                return;
            }
            const fieldRefsWithMatchingLabels = fieldRefs.filter(ref => ref.label.trim() === field.title.trim());
            if (fieldRefsWithMatchingLabels.length > 0) {
                this.setState({readyToRender: true,  fieldReferenceToConfirm: field, confirmFieldRefModalOpen: true, confirmFieldRefModalMessage: <FormattedMessage id='duplicateFieldRefLabelWarning' /> });
                return;
            }
        }

        this.setState(prevState => ({readyToRender: true,  fieldModalVisible: false, fieldRefModalRenderCount: prevState.fieldRefModalRenderCount + 1 }));

        if (field.group) {
            this.setState(prevState => {
                const fieldRefGroupRefs = prevState.fieldRefGroupRefs;
                fieldRefGroupRefs.push({ document_template_id: match.params.id, name: field.group, field_reference_literal: refLiteral, compatible_field_ids: [], compatible_model_ids: [] });
                return { fieldRefGroupRefs }
            })
            addFieldReferenceToGroup({
                document_template_id: match.params.id,
                name: field.group,
                field_reference_literal: refLiteral,
                all_literals: this.state.fieldRefs.map(r => r.literal)
            });
        }
        this.sendIframeMessage('insert', refLiteral);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (false) {//! isEqual(this.props, nextProps)) {
            console.log("prev props: " + JSON.stringify(this.props, 3, null))
            console.log("next props: " + JSON.stringify(nextProps,  3, null))
        }
        return nextState.readyToRender;
    }

    render() {
        const { readOnly, match, template } = this.props;
        const { fieldRefGroupsLoading } = this.state;

        const mainElement = (
            <LoadingOverlay
                active={this.state.loadingPreview}
                spinner
                text={<FormattedMessage id='common.loading'/>}
            >
                <div
                    style={{ 
                        opacity: this.state.loadingPreview ? '0' : '1',
                        width: '100%',
                        minHeight: '500px',
                    }}
                >
                    <Iframe 
                        url={process.env.PUBLIC_URL + '/CustomToolbars/index.html'} 
                        id='excel-iframe'
                        width='100%'
                        height={this.props.manualHeight ? this.props.manualHeight : 500}
                        loading='eager'
                    />
                </div>
            </LoadingOverlay>
        )
        let renderElement = mainElement;
        if (readOnly) {
            renderElement = (
                <LoadingOverlay
                    active={true}
                >
                    { mainElement }
                </LoadingOverlay>
            )
        }
        return (
            <React.Fragment>
                <FieldPickerModal 
                    key={'field-ref-picker-' + this.state.fieldRefModalRenderCount}
                    showModal={this.state.fieldModalVisible}
                    setModalVisibility={(val) => this.setState({ fieldModalVisible: val})}
                    cargoCategories={this.state.cargoCategories} 
                    onFieldSelect={fieldInfo => this.onFieldSelect(fieldInfo)}
                    docTemplateId={match.params.id}
                    fieldRefGroups={this.state.fieldRefGroups}
                    fieldRefGroupRefs={this.state.fieldRefGroupRefs}
                    onGroupAdded={(newGroup) => this.setState(prevState => {
                        const groups = prevState.fieldRefGroups;
                        groups.push(newGroup);
                        return { fieldRefGroups: groups };
                    })}
                    stayOpenOnSelect={true}
                />
                <FieldGroupPickerModal 
                    key={JSON.stringify(this.state.fieldRefs) + this.state.groupModalRenderCount}
                    showModal={this.state.fieldGroupModalVisible}
                    setModalVisibility={(val) => this.setState({ fieldGroupModalVisible: val})}
                    template={template}
                    fieldRefs={this.state.fieldRefs}
                    fieldRefGroups={this.state.fieldRefGroups}
                    fieldRefGroupRefs={this.state.fieldRefGroupRefs}
                    onFieldRefGroupsSet={groups => {
                        const { template, setFieldGroupReferences, getDocumentReferenceData } = this.props;
                        const group_references = [];
                        const parsedTemplate = JSON.parse(this.state.doc_file_data);
                        const fieldReferences = getExcelFieldReferences(parsedTemplate);
                        for (const groupData of groups) {
                            group_references.push({
                                name: groupData.group,
                                field_reference_literal: groupData.literal,
                            });
                        }
                        const reloadRefs = () => {
                            getDocumentReferenceData({ field_references: fieldReferences, document_template_id: match.params.id }, (dt) => {
                                const fieldReferencesWithData = addMetadataToFieldReferences(fieldReferences, dt)
                                this.setState({readyToRender: false, fieldRefGroups: dt.ref_groups, fieldRefGroupRefs: dt.ref_group_references, fieldRefs: fieldReferencesWithData, fieldGroupModalVisible: false, fieldRefGroupsLoading: false });
                            });     
                        };
                        setFieldGroupReferences({ group_references, document_template_id: template.id }, reloadRefs, reloadRefs);
                    }}
                    onDeleteConfirm={(callback) => {
                        const { getDocumentReferenceData } = this.props;

                        const parsedTemplate = JSON.parse(this.state.doc_file_data);
                        const fieldReferences = getExcelFieldReferences(parsedTemplate);
                        getDocumentReferenceData({ field_references: fieldReferences, document_template_id: match.params.id }, (dt) => {
                            this.setState({readyToRender: true,  fieldRefGroups: dt.ref_groups, fieldRefGroupRefs: dt.ref_group_references, groupModalRenderCount: this.state.groupModalRenderCount + 1 }, callback);
                        });  
                    }}
                    onGroupAdded={(newGroup) => this.setState(prevState => {
                        const groups = prevState.fieldRefGroups;
                        groups.push(newGroup);
                        return { fieldRefGroups: groups };
                    })}
                    view={this.state.refGroupView}
                />
                <ConfirmModal
                    key={'field-ref-confirm-' + this.state.fieldRefModalRenderCount}
                    open={this.state.confirmFieldRefModalOpen}
                    setOpen={(o) => this.setState({readyToRender: true,  confirmFieldRefModalOpen: o })}
                    onConfirm={() => {
                        this.onFieldSelect(this.state.fieldReferenceToConfirm, true);
                        this.setState(prevState => ({ fieldModalVisible: false, fieldRefModalRenderCount: prevState.fieldRefModalRenderCount + 1 }));
                    }}
                    title={<FormattedMessage id='confirmFieldReference' />}
                    body={this.state.confirmFieldRefModalMessage}
                />
                <FieldRefCopyPasteModal
                    open={this.state.showFieldRefCopyPasteModal}
                    setOpen={(o) => this.setState({readyToRender: true, showFieldRefCopyPasteModal: o })}
                />
                <div className='row'>
                    <div className='col-xs-3 col-lg-4 col-xl-5'>
                        { readOnly ? 
                            <p>{this.state.templateTitle}</p>
                            :
                            <EdiText
                                type='text'
                                value={this.state.templateTitle}
                                onSave={this.onSaveTitle}
                                showButtonsOnHover={false}
                            />
                        }
                        
                    </div>
                    <div className='col-xs-9 col-lg-8 col-xl-7'>
                        <div className='float-right'>
                            <button
                                type='button'
                                className='btn btn-outline-info tx-11 tx-uppercase pd-y-12 pd-x-25 mg-x-25 tx-mont tx-medium'
                                onClick={this.fauxSave}
                                disabled={this.state.fauxSaving || readOnly}
                            >
                                <FormattedMessage id='common.save' />
                            </button>
                            <button
                                type='button'
                                className='btn btn-outline-info tx-11 tx-uppercase pd-y-12 pd-x-25 mg-r-25 tx-mont tx-medium'
                                onClick={() => {
                                    this.setState({readyToRender: false, insertFieldRefLoading: true }, () => {
                                        const { match, getDocumentReferenceData } = this.props;
                                        const parsedTemplate = JSON.parse(this.state.doc_file_data);
                                        const fieldReferences = getExcelFieldReferences(parsedTemplate);
                                        getDocumentReferenceData({ field_references: fieldReferences, document_template_id: match.params.id }, (dt) => {
                                            const fieldReferencesWithData = addMetadataToFieldReferences(fieldReferences, dt)
                                            this.setState({ fieldRefGroups: dt.ref_groups, fieldRefGroupRefs: dt.ref_group_references, fieldRefs: fieldReferencesWithData, fieldModalVisible: true, insertFieldRefLoading: false });
                                        });
                                    });
                                }}
                                disabled={readOnly}
                            >
                            { this.state.insertFieldRefLoading ?
                                <FormattedMessage id='common.loading' /> 
                                :
                                <FormattedMessage id='common.insertFieldReference' />
                            }
                            </button>
                            <Popover
                                content={
                                    <Menu>
                                        <MenuItem
                                            icon="edit"
                                            text={fieldRefGroupsLoading ? <FormattedMessage id='common.loading' /> : <FormattedMessage id="common.edit" />}
                                            disabled={fieldRefGroupsLoading}
                                            onClick={() =>
                                                !fieldRefGroupsLoading && this.setState({readyToRender:true, fieldGroupModalVisible: true, refGroupView: 'edit' })
                                            }
                                        />
                                        <MenuDivider />
                                        <MenuItem
                                            icon="cross"
                                            text={fieldRefGroupsLoading ? <FormattedMessage id='common.loading' /> : <FormattedMessage id="common.delete" />}
                                            disabled={fieldRefGroupsLoading}
                                            onClick={() =>
                                                !fieldRefGroupsLoading && this.setState({readyToRender:true,  fieldGroupModalVisible: true, refGroupView: 'delete' })
                                            }
                                        />
                                    </Menu>
                                }
                            >
                                <button
                                    type='button'
                                    className='btn btn-outline-info tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium'
                                    onClick={() => {
                                        this.setState({readyToRender: true, fieldRefGroupsLoading: true }, () => {
                                            const { match, getDocumentReferenceData } = this.props;
                                            const parsedTemplate = JSON.parse(this.state.doc_file_data);
                                            const fieldReferences = getExcelFieldReferences(parsedTemplate);
                                            getDocumentReferenceData({ field_references: fieldReferences, document_template_id: match.params.id }, (dt) => {
                                                const fieldReferencesWithData = addMetadataToFieldReferences(fieldReferences, dt)
                                                this.setState({ fieldRefGroups: dt.ref_groups, fieldRefGroupRefs: dt.ref_group_references, fieldRefs: fieldReferencesWithData, fieldRefGroupsLoading: false });
                                            });
                                        });
                                    }}
                                    disabled={readOnly || this.state.fieldRefGroupsLoading}
                                >
                                    <FormattedMessage id='fieldReferenceGroups' /> 
                                </button>
                            </Popover>
                        </div>
                    </div>
                </div>
                { renderElement }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    company: state.identity.company,
    model: state.fields.model,
    fields: state.fields.items,
    fieldTypes: state.fields.types,
    template: state.document.instance,
    templateChanged: state.document.templateChanged,
    hideFieldReferenceCopyPastedWarning:  state.sessionSettings.excelHideFieldReferenceCopyPastedWarning,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getProfile,
            loadModel,
            getDocumentTemplate,
            getDocumentSample,
            setMenuVisibility,
            updateDocumentTemplate,
            getModels,
            addFieldReferenceToGroup,
            removeFieldReferenceFromGroup,
            setFieldGroupReferences,
            getDocumentReferenceData,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExcelDocumentTemplate);