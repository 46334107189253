import React from 'react';
import ReactModal from 'react-modal';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setExcelHideFieldReferenceCopyPasteWarning } from 'reducers/sessionSettings';

class FieldRefCopyPasteModal extends React.Component {
    constructor(props) {
        super(props);
    }
    render = () => {
        const { setExcelHideFieldReferenceCopyPasteWarning } = this.props;
        return (
            <ReactModal
                isOpen={this.props.open}
                onRequestClose={() => this.props.setOpen(false)}
                className="modal-block dialog"
                overlayClassName="modal-overlay gray"
                style={{zIndex: '2000'}}
            >
                <div className="modal-dialog modal-lg wd-450" role="document">
                    <div className="modal-content bd-0">
                        <div className="modal-header pd-x-20">
                            <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                                <FormattedMessage id='warning' />
                            </h6>
                        </div>
                        <div className="modal-body pd-20">
                            <FormattedMessage id='copyPasteFieldRefWarning' />
                        </div>
                        <div className="modal-footer justify-content-center">
                            <button
                                type="button"
                                className="btn btn-primary pd-y-12 pd-x-25 tx-mont tx-medium"
                                onClick={() => {
                                    this.props.setOpen(false)
                                }}
                            >
                                <FormattedMessage id="common.close" />
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary pd-y-12 pd-x-25 tx-mont tx-medium"
                                onClick={() => {
                                    setExcelHideFieldReferenceCopyPasteWarning(true);
                                    this.props.setOpen(false)
                                }}
                            >
                                <FormattedMessage id="dontShowAgain" />
                            </button>
                        </div>
                    </div>
                </div>
            </ReactModal>
        )
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setExcelHideFieldReferenceCopyPasteWarning,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FieldRefCopyPasteModal);