import React from 'react';
import ReactModal from 'react-modal';
import { FormattedMessage } from 'react-intl';
import LoadingOverlay from 'react-loading-overlay';

export default class ConfirmModal extends React.Component {
    render = () => (
        <ReactModal
            isOpen={this.props.open}
            onRequestClose={() => this.props.setOpen(false)}
            className="modal-block dialog"
            overlayClassName="modal-overlay gray"
            style={{zIndex: '2000'}}
        >
            <LoadingOverlay
                active={this.props.loading}
                spinner
                text={<FormattedMessage id='common.loading'/>}
            >
                <div className="modal-dialog modal-lg wd-450" role="document">
                    <div className="modal-content bd-0">
                        <div className="modal-header pd-x-20">
                            <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                                {this.props.title}
                            </h6>
                        </div>
                        <div className="modal-body pd-20">
                            {this.props.body}
                        </div>
                        <div className="modal-footer justify-content-center">
                            {
                                !this.props.hideConfirm &&
                                <button
                                    type="button"
                                    className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                    onClick={() => {
                                        if (this.props.onConfirm) {
                                            this.props.onConfirm();
                                        }
                                        if (!this.props.stayOpenOnConfirm) {
                                            this.props.setOpen(false)
                                        }
                                    }}
                                >
                                    { this.props.confirmLabel ? this.props.confirmLabel : <FormattedMessage id="common.yes" /> }
                                </button>
                            }
                            <button
                                type="button"
                                className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                onClick={() => {
                                    if (this.props.onCancel) {
                                        this.props.onCancel();
                                    }
                                    this.props.setOpen(false)
                                }}
                            >
                                { this.props.cancelLabel ? this.props.cancelLabel : <FormattedMessage id="common.cancel" /> }
                            </button>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        </ReactModal>
    )
}