import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import Page from 'components/page';

const FaqEl = ({question, answer}) => {
    const [show, setShow] = useState(false);
    return (
        <React.Fragment>
            <a href='#' onClick={e => { e.stopPropagation(); e.preventDefault(); setShow(!show)}}><h4>{question}</h4></a>
            <p style={{display: show ? 'block' : 'none'}}>{answer}</p>
        </React.Fragment>
    )
}

const FaqSection = ({title, elements}) => {
    const [show, setShow] = useState(false);
    return (
        <div style={{paddingBottom: '0.5em'}}>
            <a href='#' onClick={e => { e.stopPropagation(); e.preventDefault(); setShow(!show)}}><h3>{title}</h3></a>
            <div style={{display: show ? 'block' : 'none', marginLeft: '1em'}}>
                {
                    elements.map(el => <FaqEl {...el} />)
                }
            </div>
        </div>
    )
}

class FAQ extends React.Component {

    

    render() {
        return (
            <Page
                {...FAQ.meta}
                subtitle={<FormattedMessage id="frequentlyAskedQuestions"/>}
                panel={false}
            >
                <FaqSection 
                    title={<FormattedMessage id='faq.group.1' />} 
                    elements={[
                        { question:<FormattedMessage id='faq.q1.1' />, answer:<FormattedMessage id='faq.a1.1' /> },
                        { question:<FormattedMessage id='faq.q1.2' />, answer:<FormattedMessage id='faq.a1.2' /> },
                        { question:<FormattedMessage id='faq.q1.3' />, answer:<FormattedMessage id='faq.a1.3' /> },
                        { question:<FormattedMessage id='faq.q1.4' />, answer:<FormattedMessage id='faq.a1.4' /> },
                        { question:<FormattedMessage id='faq.q1.5' />, answer:<FormattedMessage id='faq.a1.5' /> },
                        { question:<FormattedMessage id='faq.q1.6' />, answer:<FormattedMessage id='faq.a1.6' /> },
                        { question:<FormattedMessage id='faq.q1.7' />, answer:<FormattedMessage id='faq.a1.7' /> },
                        { 
                            question:<FormattedMessage id='faq.q1.8' />, 
                            answer: (
                                <>
                                    <span><FormattedMessage id='faq.a1.8.1' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a1.8.2' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a1.8.3' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a1.8.4' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a1.8.5' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a1.8.6' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a1.8.7' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a1.8.8' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a1.8.9' /></span>
                                </>
                            )
                        },
                        { 
                            question:<FormattedMessage id='faq.q1.9' />, 
                            answer: (
                                <>
                                    <span><FormattedMessage id='faq.a1.9.1' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a1.9.2' /></span>
                                </>
                            )
                        },
                        { 
                            question:<FormattedMessage id='faq.q1.10' />,
                            answer: (
                                <>
                                    <span><FormattedMessage id='faq.a1.10.1' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a1.10.2' /></span>
                                </>
                            )
                        },
                    ]}
                />
                <FaqSection 
                    title={<FormattedMessage id='faq.group.2' />} 
                    elements={[
                        
                        { 
                            question:<FormattedMessage id='faq.q2.1' />,
                            answer: (
                                <>
                                    <span><FormattedMessage id='faq.a2.1.1' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a2.1.2' /></span>
                                </>
                            )
                        },
                        { 
                            question:<FormattedMessage id='faq.q2.2' />,
                            answer: (
                                <>
                                    <span><FormattedMessage id='faq.a2.2.1' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a2.2.2' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a2.2.3' /></span>
                                </>
                            )
                        },
                        { question:<FormattedMessage id='faq.q2.3' />, answer:<FormattedMessage id='faq.a2.3' /> },
                        { 
                            question:<FormattedMessage id='faq.q2.4' />,
                            answer: (
                                <>
                                    <span><FormattedMessage id='faq.a2.4.1' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a2.4.2' /></span>
                                </>
                            )
                        },
                        { question:<FormattedMessage id='faq.q2.5' />, answer:<FormattedMessage id='faq.a2.5' /> },
                        { 
                            question:<FormattedMessage id='faq.q2.6' />,
                            answer: (
                                <>
                                    <span><FormattedMessage id='faq.a2.6.1' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a2.6.2' /></span>
                                </>
                            )
                        },
                        { question:<FormattedMessage id='faq.q2.7' />, answer:<FormattedMessage id='faq.a2.7' /> },
                        { question:<FormattedMessage id='faq.q2.8' />, answer:<FormattedMessage id='faq.a2.8' /> },
                        { question:<FormattedMessage id='faq.q2.9' />, answer:<FormattedMessage id='faq.a2.9' /> },
                        { 
                            question:<FormattedMessage id='faq.q2.10' />,
                            answer: (
                                <>
                                    <span><FormattedMessage id='faq.a2.10.1' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a2.10.2' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a2.10.3' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a2.10.4' /></span>
                                </>
                            )
                        },
                        { 
                            question:<FormattedMessage id='faq.q2.11' />,
                            answer: (
                                <>
                                    <span><FormattedMessage id='faq.a2.11.1' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a2.11.2' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a2.11.3' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a2.11.4' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a2.11.5' /></span>
                                </>
                            )
                        },
                        { 
                            question:<FormattedMessage id='faq.q2.12' />,
                            answer: (
                                <>
                                    <span><FormattedMessage id='faq.a2.12.1' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a2.12.2' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a2.12.3' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a2.12.4' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a2.12.5' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a2.12.6' /></span>
                                </>
                            )
                        },
                        { 
                            question:<FormattedMessage id='faq.q2.13' />,
                            answer: (
                                <>
                                    <span><FormattedMessage id='faq.a2.13.1' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a2.13.2' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a2.13.3' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a2.13.4' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a2.13.5' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a2.13.6' /></span>
                                </>
                            )
                        },
                    ]}
                />
                <FaqSection 
                    title={<FormattedMessage id='faq.group.3' />} 
                    elements={[
                        { question:<FormattedMessage id='faq.q3.1' />, answer:<FormattedMessage id='faq.a3.1' /> },
                        { 
                            question:<FormattedMessage id='faq.q3.2' />,
                            answer: (
                                <>
                                    <span><FormattedMessage id='faq.a3.2.1' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a3.2.2' /></span>
                                </>
                            )
                        },
                        { question:<FormattedMessage id='faq.q3.3' />, answer:<FormattedMessage id='faq.a3.3' /> },
                        { 
                            question:<FormattedMessage id='faq.q3.4' />,
                            answer: (
                                <>
                                    <span><FormattedMessage id='faq.a3.4.1' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a3.4.2' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a3.4.3' /></span>
                                </>
                            )
                        },
                        { 
                            question:<FormattedMessage id='faq.q3.5' />,
                            answer: (
                                <>
                                    <span><FormattedMessage id='faq.a3.5.1' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a3.5.2' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a3.5.3' /></span>
                                </>
                            )
                        },
                        { 
                            question:<FormattedMessage id='faq.q3.6' />,
                            answer: (
                                <>
                                    <span><FormattedMessage id='faq.a3.6.1' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a3.6.2' /></span>
                                </>
                            )
                        },
                    ]}
                />
                <FaqSection 
                    title={<FormattedMessage id='faq.group.4' />} 
                    elements={[
                        { question:<FormattedMessage id='faq.q4.1' />, answer:<FormattedMessage id='faq.a4.1' /> },
                        { 
                            question:<FormattedMessage id='faq.q4.2' />,
                            answer: (
                                <>
                                    <span><FormattedMessage id='faq.a4.2.1' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a4.2.2' /></span>
                                </>
                            )
                        },
                        { 
                            question:<FormattedMessage id='faq.q4.3' />,
                            answer: (
                                <>
                                    <span><FormattedMessage id='faq.a4.3.1' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a4.3.2' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a4.3.3' /></span>
                                </>
                            )
                        },
                        { question:<FormattedMessage id='faq.q4.4' />, answer:<FormattedMessage id='faq.a4.4' /> },
                        { 
                            question:<FormattedMessage id='faq.q4.5' />,
                            answer: (
                                <>
                                    <span><FormattedMessage id='faq.a4.5.1' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a4.5.2' /></span>
                                </>
                            )
                        },
                    ]}
                />
                <FaqSection 
                    title={<FormattedMessage id='faq.group.5' />} 
                    elements={[
                        { question:<FormattedMessage id='faq.q5.1' />, answer:<FormattedMessage id='faq.a5.1' /> },
                        { question:<FormattedMessage id='faq.q5.2' />, answer:<FormattedMessage id='faq.a5.2' /> },
                        { question:<FormattedMessage id='faq.q5.3' />, answer:<FormattedMessage id='faq.a5.3' /> },
                        { question:<FormattedMessage id='faq.q5.4' />, answer:<FormattedMessage id='faq.a5.4' /> },
                    ]}
                />
                <FaqSection 
                    title={<FormattedMessage id='faq.group.6' />} 
                    elements={[
                        { question:<FormattedMessage id='faq.q6.1' />, answer:<FormattedMessage id='faq.a6.1' /> },
                        { question:<FormattedMessage id='faq.q6.2' />, answer:<FormattedMessage id='faq.a6.2' /> },
                    ]}
                />
                <FaqSection 
                    title={<FormattedMessage id='faq.group.7' />} 
                    elements={[
                        { 
                            question:<FormattedMessage id='faq.q7.1' />,
                            answer: (
                                <>
                                    <span><FormattedMessage id='faq.a7.1.1' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a7.1.2' /></span>
                                </>
                            )
                        },
                        { 
                            question:<FormattedMessage id='faq.q7.2' />,
                            answer: (
                                <>
                                    <span><FormattedMessage id='faq.a7.2.1' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a7.2.2' /></span>
                                </>
                            )
                        },
                        { 
                            question:<FormattedMessage id='faq.q7.3' />,
                            answer: (
                                <>
                                    <span><FormattedMessage id='faq.a7.3.1' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a7.3.2' /></span>
                                </>
                            )
                        },
                        { 
                            question:<FormattedMessage id='faq.q7.4' />,
                            answer: (
                                <>
                                    <span><FormattedMessage id='faq.a7.4.1' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a7.4.2' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a7.4.3' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a7.4.4' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a7.4.5' /></span>
                                </>
                            )
                        },
                    ]}
                />
                <FaqSection 
                    title={<FormattedMessage id='faq.group.8' />} 
                    elements={[
                        { question:<FormattedMessage id='faq.q8.1' />, answer:<FormattedMessage id='faq.a8.1' /> },
                        { question:<FormattedMessage id='faq.q8.2' />, answer:<FormattedMessage id='faq.a8.2' /> },
                        { 
                            question:<FormattedMessage id='faq.q8.3' />,
                            answer: (
                                <>
                                    <span><FormattedMessage id='faq.a8.3.1' /></span><br/><br/>
                                    <span><FormattedMessage id='faq.a8.3.2' /></span>
                                </>
                            )
                        },
                    ]}
                />
            </Page>
            
        );
    }
}

FAQ.meta = {
    title: <FormattedMessage id="faq" defaultMessage="FAQ" />,
    routes: ['/faq'],
    icon: 'ion-ios-help-outline',
    menus: { main: true, user: false },
    hideBreadcrumbs: true,
    order: 100,
    onlySubscribed: true
};

const mapStateToProps = state => ({
    user: state.identity.user,
    company: state.identity.company,
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FAQ);
