export const COMPANIES_GET_INVOICES = `
    query(
        $cargo_categories : [Long]
        $order_by: [OrderBy]
        $offset: Int
        $limit: Int
    ) {
        company_list_invoices(
            paging: { order_by: $order_by, offset: $offset, limit: $limit }
		) {
            total
            data {
                id
                amount_paid
                created
                invoice_pdf
                plan_id
                line_items {
                    plan {
                        id
                        nickname
                    }
                    quantity
                }
            }
        }
    }
`;
