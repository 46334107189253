import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import Page from 'components/page';
import ReactModal from 'react-modal';
import { Formik, Form, Field } from 'formik';
import { Fields } from 'components/fields'
import { getEntityGroup, updateEntityGroup } from 'reducers/entityGroup';
import LoadingOverlay from 'react-loading-overlay';
import LibraryTemplateSelector from 'pages/library/components/libraryTemplateSelector';
import { Card, Collapse } from "@blueprintjs/core";
import { Notifications } from 'components/notifications';
import { createTransaction } from 'reducers/transaction';
import { history } from 'configuration/history';
import { Popover, Menu, Button, MenuItem, Icon } from '@blueprintjs/core';
import { setDocumentSelection, listDocumentSelections } from 'reducers/document';
import { 
    setEventsTableOptions, 
    setEventsTableQueryValues, 
    setEventsTableQueryState,
    setLotsTableOptions,
    setLotsTableQueryValues,
    setLotsTableQueryState,
} from 'reducers/sessionSettings';
import EntityModal from 'pages/collections/components/entityModal';

function TemplateCard({
    id,
    hash_id,
    title,
    instance_count,
    model_type_name,
    model_field_list,
    status_id,
    createTransaction,
    setDocumentSelection,
    listDocumentSelections,
    setEventsTableOptions,
    setEventsTableQueryValues, 
    setEventsTableQueryState,
    eventsTableQueryState,
    setLotsTableOptions,
    setLotsTableQueryValues,
    setLotsTableQueryState,
    lotsTableQueryState, 
    collectionTitle,
    collectionId,
    ...rest
}){
    const [loading, setLoading] = useState(false);
    const [entityModalOpen, setEntityModalOpen] = useState(false);
    const [docSelections, setDocSelections] = useState([]);

    useEffect(() => {
        listDocumentSelections({ document_template_ids: [id] }, selections => {
            setDocSelections(selections);
        })
    }, [id, listDocumentSelections])

    let extraContent;
    let entityType = 'document-template';
    if (model_type_name === 'event template') {
        entityType = 'dash-template';
        extraContent = (
            <>
                { instance_count !== undefined && 
                    <div className='collection-template-info-item'>
                        <FormattedMessage id="dashCount" values={{ count: instance_count }} />
                    </div>
                }
                <div className='collection-card-buttons mg-t-10'>
                    <button 
                        type="button"
                        className='btn btn-outline-info'
                        onClick={() => {
                            setEntityModalOpen(true);
                            // history.push(`/events/event-templates/${id}` + window.location.search);
                        }}
                    >
                        <FormattedMessage id="viewDashTemplate" />
                    </button>
                    <button 
                        type="button"
                        className='btn btn-outline-info mg-l-10'
                        disabled={loading}
                        onClick={() => {
                            setEventsTableOptions({
                                filters: {
                                    criteria: "[]",
                                    field_criteria: "[]",
                                    model_ids: [id],
                                    status_ids: [1,3,7],
                                },
                                page: 0,
                                pageSize: 10,
                                sorted: [],
                            });
                            setEventsTableQueryState({
                                fields: [],
                                modal: { open: false },
                                ...eventsTableQueryState,
                                filters: {
                                    model_ids: [id],
                                    status_ids: [1,3,7],
                                },
                                modelValues: [id],
                                selectedModels: [{
                                    id,
                                    hash_id,
                                    title,
                                    model_type_name,
                                    status_id,
                                    model_field_list,
                                    ...rest
                                }],
                                advancedFilters: [],
                                fieldFilters: [],
                            });
                            setEventsTableQueryValues({
                                models: [id],
                                status: [1,3,7],
                            });
                            history.push(`/events` + window.location.search, `{ "title": "${collectionTitle}" , "route": "/collections/${collectionId}"}`)
                        }}
                    >
                        <FormattedMessage id="viewDashes" />
                    </button>
                    <button 
                        type="button"
                        className='btn btn-outline-info mg-l-10'
                        disabled={status_id !== 2 || loading}
                        onClick={() => {
                            setLoading(true)
                            createTransaction(id, 'transaction', transaction => history.push(`/events/${transaction.id}` + window.location.search))
                        }}
                    >
                        <FormattedMessage id="createEvent" />
                    </button>
                </div>
            </>
        );
    } else if (model_type_name === 'cargo category') {
        entityType = 'dot-template';
        extraContent = (
            <>
                { instance_count !== undefined && 
                    <div className='collection-template-info-item'>
                        <FormattedMessage id="dotCount" values={{ count: instance_count }} />
                    </div>
                }
                <div className='collection-card-buttons mg-t-10'>
                    <button 
                        type="button"
                        className='btn btn-outline-info'
                        onClick={() => {
                            setEntityModalOpen(true);
                            // history.push(`/objects/object-templates/${id}` + window.location.search);
                        }}
                    >
                        <FormattedMessage id="viewDotTemplate" />
                    </button>
                    <button 
                        type="button"
                        className='btn btn-outline-info mg-l-10'
                        disabled={loading}
                        onClick={() => {
                            setLotsTableOptions({
                                filters: {
                                    cargo_categories: [id],
                                    status_ids: [2,3],
                                    criteria: '[]',
                                    field_criteria: '[]',
                                },
                                page: 0,
                                pageSize: 10,
                                sorted: [],
                            });
                            setLotsTableQueryState({
                                fields: [],
                                modal: { open: false },
                                ...eventsTableQueryState,
                                filters: {
                                    cargo_categories: [id],
                                    status_ids: [2,3],
                                },
                                modelValues: [id],
                                selectedModels: [{
                                    id,
                                    hash_id,
                                    title,
                                    model_type_name,
                                    status_id,
                                    model_field_list,
                                    ...rest
                                }],
                                advancedFilters: [],
                                fieldFilters: [],
                            });
                            setLotsTableQueryValues({
                                models: [id],
                                status: [2,3],
                            });
                            history.push(`/objects` + window.location.search, `{ "title": "${collectionTitle}" , "route": "/collections/${collectionId}"}`)
                        }}
                    >
                        <FormattedMessage id="viewDots" />
                    </button>
                </div>
            </>
        );
    } else {
        extraContent = (
            <>
                <div className='collection-card-buttons'>
                    <button 
                        type="button"
                        className='btn btn-outline-info'
                        onClick={() => {
                            setEntityModalOpen(true);
                            // history.push(`/document-templates/${id}` + window.location.search);
                        }}
                    >
                        <FormattedMessage id="viewDocumentTemplate" />
                    </button>
                    <Popover
                        content={
                            <Menu>
                                <MenuItem
                                    text={<FormattedMessage id="new" />}
                                    popoverProps={{ openOnTargetFocus: false }}
                                    onClick={() => {
                                        setDocumentSelection(null, () => {
                                            history.push(`/document-templates/${id}/builder` + window.location.search);
                                        })
                                    }}
                                />
                                <MenuItem
                                    text={(docSelections.length === 0) ? <FormattedMessage id="saved(none)" /> : <FormattedMessage id="saved" />}
                                    popoverProps={{ openOnTargetFocus: false }}
                                    disabled={docSelections.length === 0}
                                >
                                    { docSelections.map(ds => (
                                        <MenuItem
                                            key={ds.id}
                                            text={ds.name}
                                            popoverProps={{ openOnTargetFocus: false }}
                                            onClick={() => {
                                                setDocumentSelection(ds, () => {
                                                    history.push(`/document-templates/${id}/builder` + window.location.search);
                                                })
                                            }}
                                        />
                                    ))}
                                </MenuItem>
                            </Menu>
                        }
                    >
                        <button 
                            type="button"
                            className='btn btn-outline-info mg-l-10'
                            disabled={loading}
                            // onClick={() => history.push(`/document-templates/${id}/builder` + window.location.search)}
                        >
                            <FormattedMessage id="common.generateDocument" />
                        </button>
                    </Popover>
                </div>
            </>
        );
    }
    return (
        <Card 
            className='collection-card'
            onClick={() => null}
        >
            <span className='tx-inverse tx-bold'>
                {title} ({hash_id})
            </span>
            <div className='collection-template-info'>
                { extraContent }
            </div>
            <EntityModal
                key={id}
                open={entityModalOpen}
                setOpen={(open) => setEntityModalOpen(open) }
                entityType={entityType}
                entity={{
                    id,
                    hash_id,
                    title,
                    instance_count,
                    model_type_name,
                    model_field_list,
                    status_id,
                    ...rest
                }}
                allEntities={[]}
            />
        </Card>
    )
}



const mapStateToProps = state => ({
    user: state.identity.user,
    company: state.identity.company,
    permissions: state.identity.permissions,
    eventsTableQueryState: state.sessionSettings.eventsTableQueryState,
    lotsTableQueryState: state.sessionSettings.lotsTableQueryState,
});
const mapDispatchToProps = dispatch => bindActionCreators({
    getEntityGroup,
    updateEntityGroup,
    createTransaction,
    setDocumentSelection,
    listDocumentSelections,
    setEventsTableOptions,
    setEventsTableQueryValues, 
    setEventsTableQueryState,
    setLotsTableOptions,
    setLotsTableQueryValues,
    setLotsTableQueryState,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(TemplateCard));