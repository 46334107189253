import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Popover, Menu, MenuItem, MenuDivider, Icon } from '@blueprintjs/core';
import moment from 'moment';
import { filterTransactions, deleteTransaction, cloneTransaction } from 'reducers/transactions';
import Table from 'components/table';
import Page from 'components/page';
import { getIndices } from 'reducers/indices';
import QueryBuilder from '../components/queryBuilder';
import { getModels, getModelsLight } from 'reducers/models';
import FieldValueRenderer from '../components/fieldValueRenderer';
import ColumnPicker, { getInitialColumnVisibility } from 'components/table/columnPicker';
import { history } from 'configuration/history';
import { setEventsTableOptions, setEventsTableQueryValues, setEventsTableQueryState, setEventsTableColumns } from 'reducers/sessionSettings';
import ConfirmModal from 'components/modals/confirm'
import { updateTransaction, getTransactionDeleteSideEffects } from 'reducers/transaction';
import CreateEventModal from '../components/createEventModal';
import { translateMatchStrength } from 'utilities/common';
import DashDeleteModal from 'components/modals/dashDeleteModal';
import MultiDashDeleteModal from 'components/modals/multiDashDeleteModal';
import { setGraphEntity } from 'reducers/entityGraph';
import DashViewModal from 'pages/events/components/dashViewModal';
import QRCode from 'qrcode';
import JsBarcode from 'jsbarcode';
import { createEntityGraphLink } from 'reducers/entityGraph'
import { tooltipWrapper } from 'utilities/common'


class TransactionsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columnVisibility: undefined,
            deleteTargetMultiIds: [],
            tableRenderCount: 1,
            targetDashHasDotSideEffects: false,
            targetDashCanUnwind: false,
            referencedDotTemplates: [],
        };
    }

    componentDidMount() {
        const { getIndices, getModels, getModelsLight } = this.props;
        this.setState({ columnVisibility: getInitialColumnVisibility(this.getColumns()) });
        getIndices({ status_ids: [2] }, r => this.setState({ columnVisibility: getInitialColumnVisibility(this.getColumns(r.data)) }));
    }

    loadData = (options, onComplete) => {
        let { columnVisibility } = this.state;
        const { filterTransactions, indexFields = [], eventsTableOptions, setEventsTableOptions = () => console.warn('No method for setting table options'), eventsTableColumns } = this.props;
        if (eventsTableColumns != null) {
            columnVisibility = eventsTableColumns;
        }
        const resultIndices = indexFields.filter(i => !!columnVisibility && columnVisibility[`field_${i.id}`] === true);
        return filterTransactions(
            {
                result_index_field_ids: resultIndices.map(i => i.id),
                status_ids: [1, 3, 7],
                transaction_type: 1,
                ...options
            },
            (results) => {
                if (results === undefined) {
                    onComplete([]);
                    return;
                }
                if (results.total > 0 && results.data.length === 0) {
                    // navigate to previous page and force table to re-render
                    const newOptions = Object.assign({}, eventsTableOptions);
                    newOptions.page = newOptions.page - 1;
                    setEventsTableOptions(newOptions).then(() => {
                        this.setState({ tableRenderCount: this.state.tableRenderCount + 1 });
                    })
                } else {
                    onComplete(results);
                }
            }
        );
    };

    setColumns = selectedColumns => {
        this.setState({ columnVisibility: selectedColumns });
        this.props.setEventsTableColumns(selectedColumns);
    };

    toggleDeleteTarget = (id) => {
        this.setState(prevState => {
            const targetIds = prevState.deleteTargetMultiIds;
            const index = targetIds.indexOf(id);
            if (index === -1) {
                targetIds.push(id);
            } else {
                targetIds.splice(index, 1);
            }
            return { deleteTargetMultiIds: targetIds };
        })
    }

    eventAllSelectorEl = () => {
        const { transactions } = this.props;
        const { deleteTargetMultiIds, isDeletingMulti } = this.state;
        const allEventsSelected = deleteTargetMultiIds.length === transactions.length;
        if (isDeletingMulti) {
            return ( 
                <div className="actions-wrapper" style={{height: '100%'}}>
                    <input 
                        type="checkbox" 
                        checked={allEventsSelected}  
                        onChange={() => this.setState(prevState => {
                            let allSelectedIds = prevState.deleteTargetMultiIds;
                            if (allSelectedIds.length === transactions.length) {
                                return { deleteTargetMultiIds: [] };
                            } else {
                                return { deleteTargetMultiIds: transactions.map(t => t.id) };
                            }
                        })}
                    />
                </div>
            );
        } else {
            return null;
        }
    }

    getColumns = providedIndices => {
        const { eventsTableColumns, cloneTransaction, getTransactionDeleteSideEffects, setGraphEntity, createEntityGraphLink, company, intl: { formatMessage } } = this.props;
        let { columnVisibility, options = { status_ids: [1, 2, 3, 4, 5] } } = this.state;
        if (eventsTableColumns != null) {
            columnVisibility = eventsTableColumns;
        }
        const isPro = (company.subscription_type === 'pro' || company.id === 1);
        const indexFields = this.props.indexFields || providedIndices || [];
        const resultIndices = indexFields.filter(i => !!columnVisibility && columnVisibility[`field_${i.id}`] === true);
        const showMatchStrength = (options.criteria && options.criteria !== '[]') || (options.field_criteria && options.field_criteria !== '[]');

        const columns = [
            {
                isDefault: true,
                headerClassName: 'd-sm-block col-2 d-none',
                Header: this.eventAllSelectorEl(),
                className: 'd-xs-block text-center col-2',
                sortable: false,
                resizable: false,
                width: 34,
                id: 'actions',
                accessor: d => {
                    return this.state.isDeletingMulti ?
                    tooltipWrapper(
                        d.model ? d.model.contains_inaccessible_dots : false,
                        <FormattedMessage id='containsInaccessibleDotsMessage' values={{ entityType: formatMessage({ id: 'dash' }) }} />,
                        <div className="actions-wrapper">
                            <input 
                                type="checkbox" 
                                checked={this.state.deleteTargetMultiIds && this.state.deleteTargetMultiIds.indexOf(d.id) > -1}  
                                onChange={() => this.toggleDeleteTarget(d.id)}
                                disabled={d.model ? d.model.contains_inaccessible_dots : false}
                            />
                        </div>
                    )
                    :
                    tooltipWrapper(
                        d.model ? d.model.contains_inaccessible_dots : false,
                        <FormattedMessage id='containsInaccessibleDotsMessage' values={{ entityType: formatMessage({ id: 'dash' }) }} />,
                        <div className={'actions-wrapper' + ((d.model && d.model.contains_inaccessible_dots) ? ' disabled' : '')}>
                            <Popover
                                disabled={d.model ? d.model.contains_inaccessible_dots : false}
                                content={
                                    <Menu>
                                        <MenuItem
                                            icon="edit"
                                            text={<FormattedMessage id="common.edit" />}
                                            onClick={() => history.push(`/events/${d.id}` + window.location.search)}
                                        />
                                        <MenuItem
                                            icon="eye-open"
                                            text={<FormattedMessage id="openVisualization" />}
                                            disabled={!isPro}
                                            onClick={() => {
                                                const visualizationData = btoa(JSON.stringify({ entities: [{ entityType: 'dash', entityId: d.id }] }));
                                                history.push(`/visualize/${visualizationData}` + window.location.search)
                                            }}
                                        />
                                        <MenuItem
                                            icon="duplicate"
                                            text={<FormattedMessage id="common.clone" />}
                                            onClick={() => {
                                                cloneTransaction({ transaction_id: d.id }, data => {
                                                    history.push(`/events/${data.id}` + window.location.search);
                                                });
                                            }}
                                        />
                                        <MenuItem
                                            icon="barcode"
                                            text={<FormattedMessage id="generateCode" />}
                                            popoverProps={{ openOnTargetFocus: false }}
                                        >
                                            <MenuItem
                                                text={<FormattedMessage id="qrCode" />}
                                                onClick={() => {
                                                    const visualizationData = btoa(JSON.stringify({ entities: [{ entityType: 'dash', entityId: d.id }] }));
                                                    const url = window.location.protocol + '//' + window.location.host + `/visualize/${visualizationData}`
                                                    QRCode.toDataURL(url)
                                                        .then(dataUrl => {
                                                            var image = new Image();
                                                            image.src = dataUrl;
                                                    
                                                            var w = window.open("");
                                                            w.document.write(image.outerHTML);
                                                            w.document.close();
                                                        })
                                                        .catch(err => {
                                                            console.error(err)
                                                        })
                                                }}
                                            />
                                            <MenuItem
                                                text={<FormattedMessage id="barcode" />}
                                                onClick={() => {
                                                    document.body.style.cursor='wait';
                                                    const visualizationData = { entities: [{ entity_type: 'dash', entity_hash_id: d.id }] };
                                                    createEntityGraphLink(
                                                        visualizationData, 
                                                        (visLink) => {
                                                            const url = `${window.location.protocol}//${window.location.host}/l/${visLink.name}`
                                                            JsBarcode('#barcode', url, {
                                                                format: "CODE128"
                                                            });
                                                            const canvas = document.getElementById("barcode");
                                                            const dataUrl = canvas.toDataURL("png");
                                                            var image = new Image();
                                                            image.src = dataUrl;
                                                    
                                                            var w = window.open("");
                                                            w.document.write(image.outerHTML);
                                                            w.document.close();
                                                            document.body.style.cursor='default';
                                                        },
                                                        () => {
                                                            document.body.style.cursor='default';
                                                        }
                                                    )
                                                }}
                                            />
                                            <canvas id='barcode' hidden={true}  />
                                        </MenuItem>
                                        <MenuDivider />
                                        <MenuItem
                                            icon="compressed"
                                            text={<FormattedMessage id="common.archive" />}
                                            disabled={d.status_id === 5 || d.status_id === 6}
                                            onClick={() =>
                                                this.setState({ targetArchiveTransaction: {...d}, archiveTargetTitle: d.title || '[Untitled]', archiveConfirmModalOpen: true })
                                            }
                                        />
                                        <MenuItem
                                            icon="undo"                                            
                                            text={<FormattedMessage id="common.unarchive" />}
                                            disabled={ d.status_id !== 5}
                                            onClick={() =>
                                                this.setState({ targetUndoArchiveTransaction: {...d}, undoArchiveTargetTitle: d.title || '[Untitled]', undoArchiveConfirmModalOpen: true })
                                            }
                                        />
                                        <MenuDivider />
                                        <MenuItem
                                            icon="cross"
                                            text={<FormattedMessage id="common.delete" />}
                                            disabled={d.status_id === 6}
                                            onClick={() => {
                                                this.setState({ deleteTargetId: d.id, deleteTargetTitle: d.title || '[Untitled]', confirmModalOpen: true, deleteModalLoading: true });
                                                getTransactionDeleteSideEffects(d.id, sideEffectsData => {
                                                    this.setState({
                                                        targetDashHasDotSideEffects: sideEffectsData.has_effects_to_reverse,
                                                        targetDashCanUnwind: sideEffectsData.can_reverse_effects,
                                                        deleteModalLoading: false,
                                                    });
                                                })
                                            }}
                                        />
                                    </Menu>
                                }
                            >
                                <Button minimal={true} small={true} icon={<Icon iconSize={12} icon="menu" />} />
                            </Popover>
                        </div>
                    )
                }
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block text-center id-column',
                Header: <FormattedMessage id="common.id" />,
                width: 100,
                id: 'hash_id',
                accessor: d => tooltipWrapper(
                    d.model ? d.model.contains_inaccessible_dots : false,
                    <FormattedMessage id='containsInaccessibleDotsMessage' values={{ entityType: formatMessage({ id: 'dash' }) }} />,
                    (d.model && d.model.contains_inaccessible_dots) 
                    ?
                    <span>{d.id}</span>
                    :
                    <a href='#' onClick={e => { e.stopPropagation(); e.preventDefault(); this.setState({ dashModalOpen: true, viewingDash: d }); }}>{d.id}</a>
                )
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block',
                Header: <FormattedMessage id="common.title" />,
                id: 'title',
                accessor: d => d.title
            },          
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block text-center',
                Header: <FormattedMessage id="common.status" />,
                id: 'status_name',
                width: 100,
                accessor: d => <FormattedMessage id={d.status_name} defaultMessage={d.status_name} />
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block',
                Header: <FormattedMessage id="common.model" />,
                id: 'model_title',
                accessor: d => d.model_title
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block',
                Header: <FormattedMessage id="common.createdBy" />,
                id: 'user_name',
                accessor: d => d.user_name
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block text-center',
                Header: <FormattedMessage id="common.alteredOn" />,
                id: 'modified_on',
                accessor: d => moment(d.modified_on || d.created_on).format('DD-MM-YYYY')
            },
            {
                isDefault: false,
                headerClassName: 'd-block',
                className: 'd-block text-center',
                Header: <FormattedMessage id="common.createdOn" />,
                id: 'created_on',
                accessor: d => moment(d.created_on).format('DD-MM-YYYY')
            },
            ...indexFields.map(i => ({
                headerClassName: 'd-sm-block d-none',
                className: 'd-sm-block d-none',
                Header: i.title,
                id: `field_${i.id}`,
                accessor: d => <FieldValueRenderer row={d} field={i} />
            }))
        ];
        if (showMatchStrength) {
            columns.push(
                {
                    isDefault: true,
                    headerClassName: 'd-block',
                    className: 'd-block',
                    Header: <FormattedMessage id="common.matchStrength" />,
                    id: 'match_strength',
                    accessor: d => translateMatchStrength(d.match_strength)
                },
            )
        }

        return columns;
    };

    renderActions = () => {
        const createButton = (
            <span 
                className="btn btn-outline-info" 
                onClick={() => {
                    this.setState({ createEventModalOpen: true });
                }
            }>
                <FormattedMessage id="createEvent" />
            </span>
        )
        const eventTemplatesButton = (
            <span 
                className="btn btn-outline-info" 
                onClick={() => {
                    history.push('/events/event-templates/' + window.location.search)
                }
            }>
                <FormattedMessage id="eventTemplates" />
            </span>
        )
        if (this.state.isDeletingMulti) {
            const deleteButton = this.state.deleteTargetMultiIds && this.state.deleteTargetMultiIds.length > 0 ?
                (
                    <span 
                        className="btn btn-outline-info" 
                        onClick={() => {
                            const { getTransactionDeleteSideEffects } = this.props;
                            this.setState({
                                confirmMultiModalOpen: true, 
                                multiDeleteModalLoading: true, 
                                targetDashesHaveDotSideEffects: false, 
                                targetDashesCanUnwind: null,
                                targetDashesWithSideEffects: [],
                                targetDashesWhichCanUnwind: [],
                            }, () => {
                                const sideEffectPromises = this.state.deleteTargetMultiIds.map(id => new Promise(resolve => {
                                    getTransactionDeleteSideEffects(id, sideEffectsData => {
                                        this.setState(prevState => {
                                            let targetDashesWithSideEffects = prevState.targetDashesWithSideEffects;
                                            let targetDashesWhichCanUnwind = prevState.targetDashesWhichCanUnwind;
                                            let targetDashesHaveDotSideEffects = prevState.targetDashesHaveDotSideEffects;
                                            let targetDashesCanUnwind = prevState.targetDashesCanUnwind;
    
                                            if (sideEffectsData.has_effects_to_reverse) {
                                                targetDashesWithSideEffects = targetDashesWithSideEffects.concat(id);
                                                targetDashesHaveDotSideEffects = true;
                                            }
    
                                            if (sideEffectsData.can_reverse_effects) {
                                                targetDashesWhichCanUnwind = targetDashesWhichCanUnwind.concat(id);
                                                if (targetDashesCanUnwind === null) {
                                                    targetDashesCanUnwind = true;
                                                }
                                            } else if (sideEffectsData.has_effects_to_reverse) {
                                                targetDashesCanUnwind = false;
                                            }
    
                                            return {
                                                targetDashesWithSideEffects,
                                                targetDashesHaveDotSideEffects,
                                                targetDashesWhichCanUnwind,
                                                targetDashesCanUnwind,
                                            }
                                        }, resolve);
                                    });
                                }));
                                Promise.all(sideEffectPromises).then(() => {
                                    this.setState({ multiDeleteModalLoading: false });
                                });
                            });
                        }
                    }>
                        <FormattedMessage id="deleteSelectedEvents" values={{count: this.state.deleteTargetMultiIds.length}} />
                    </span>
                )
                :
                (
                    <span 
                        className="btn btn-outline-info disabled" 
                        disabled={true}
                        onClick={() => null
                    }>
                        <FormattedMessage id="noEventsSelected" />
                    </span>
                )
            return (
                <div className="btn-group pd-t-10">
                    { eventTemplatesButton }
                    { createButton }
                    { deleteButton }
                    <span 
                        className="btn btn-outline-info" 
                        onClick={() => {
                            this.setState({ isDeletingMulti: false, confirmMultiModalOpen: false, deleteTargetMultiIds: [] });
                        }
                    }>
                        <FormattedMessage id="cancel" />
                    </span>
                </div>
            );
        } else {
            return (
                <div className="btn-group pd-t-10">
                    { eventTemplatesButton }
                    { createButton }
                    <span 
                        className="btn btn-outline-info" 
                        onClick={() => {
                            this.setState({ isDeletingMulti: true, deleteTargetMultiIds: [] });
                        }
                    }>
                        <FormattedMessage id="deleteMultipleEvents" />
                    </span>
                </div>
            );
        }
        
    };

    render() {
        const { intl: { formatMessage }, transactions, total, indexFields = [], models = [], deleteTransaction, updateTransaction, eventsTableColumns } = this.props;
        let { columnVisibility } = this.state;
        if (!columnVisibility) return null;

        if (eventsTableColumns != null) {
            columnVisibility = eventsTableColumns;
        }

        const columns = this.getColumns();
        let columnsToShow = columns.filter(c => !!c && !!c.id && (columnVisibility[c.id] === true || c.id === 'match_strength'))
        const columnPicker = onPicked => (
            <div className="row">
                <div className="col">
                    <div className="float-right">
                        <ColumnPicker
                            columns={columns}
                            selectedColumns={columnsToShow.map(c => c.id)}
                            models={this.state.selectedModels}
                            onChange={c => {
                                this.setColumns(c);
                                onPicked(c);
                            }}
                        />
                    </div>
                </div>
            </div>
        );

        return (
            <Page {...TransactionsList.meta} parents={this.props.parents} actions={this.renderActions()}>
                <Table
                    key={'tb-' + this.state.tableRenderCount}
                    columns={columnsToShow}
                    columnPicker={columnPicker}
                    data={transactions}
                    total={total || transactions.length}
                    queryBuilder={setFilters => 
                        <QueryBuilder 
                            fields={indexFields} 
                            models={this.state.referencedDotTemplates}
                            onModelsSet={models => this.setState({ selectedModels: models })}
                            onDotTemplatesSet={models => this.setState({ referencedDotTemplates: models })}
                            onFiltersSet={setFilters}
                            formattedFiltersSetter={this.props.setEventsTableFilters}
                            stateSetter={this.props.setEventsTableQueryState}
                            valuesSetter={this.props.setEventsTableQueryValues}
                            initialState={this.props.eventsTableQueryState}
                            initialValues={this.props.eventsTableQueryValues}
                            modelType={'event template'}
                        />
                    }
                    dataLoader={(options, onComplete) => {
                        this.loadData(options, onComplete);
                        this.setState({ options: options });
                    }}
                    optionsSetter={this.props.setEventsTableOptions}
                    initialOptions={this.props.eventsTableOptions}
                />
                <DashDeleteModal
                    loading={this.state.deleteModalLoading}
                    open={this.state.confirmModalOpen}
                    setOpen={(o) => this.setState({ confirmModalOpen: o })}
                    onConfirm={(unwind_dots) => {
                        const { options = { status_ids: [1, 2, 3, 4, 5] }, deleteTargetId } = this.state;
                        deleteTransaction({transaction_id: deleteTargetId, unwind_dots}, () =>
                            this.loadData(options, () => null)
                        )
                    }}
                    deleteTargetTitle={this.state.deleteTargetTitle}
                    hasDotSideEffects ={this.state.targetDashHasDotSideEffects}
                    canUnwind={this.state.targetDashCanUnwind}
                />
                <MultiDashDeleteModal
                    loading={this.state.multiDeleteModalLoading}
                    open={this.state.confirmMultiModalOpen}
                    setOpen={(o) => this.setState({ confirmMultiModalOpen: o })}
                    onConfirm={(unwind_dots) => {
                        const { options = { status_ids: [1, 2, 3, 4, 5]}, deleteTargetMultiIds } = this.state;
                        const promises = deleteTargetMultiIds.map(id => new Promise(resolve => {
                            deleteTransaction({transaction_id: id, unwind_dots}, resolve)
                        }));
                        Promise.all(promises).then(() => {
                            this.loadData(options, () => null);
                            this.setState({ isDeletingMulti: false });
                        })
                    }}
                    hasDotSideEffects ={this.state.targetDashesHaveDotSideEffects}
                    canUnwind={this.state.targetDashesCanUnwind}
                    targetDashesWithSideEffects={this.state.targetDashesWithSideEffects}
                    targetDashesWhichCanUnwind={this.state.targetDashesWhichCanUnwind}
                />
                <ConfirmModal
                    open={this.state.archiveConfirmModalOpen}
                    setOpen={(o) => this.setState({ archiveConfirmModalOpen: o })}
                    onConfirm={() => {
                        const { options = { status_ids: [1, 2, 3, 4, 5] }, targetArchiveTransaction } = this.state;
                        updateTransaction({ ...targetArchiveTransaction, transaction_id: targetArchiveTransaction.id, status_id: 5}, () =>
                            this.loadData(options, () => null)
                        )
                    }}                               
                    title={<FormattedMessage id='common.confirmVerb' values={{ verb: formatMessage({ id: 'common.archive' }) }} />}
                    body={<FormattedMessage id='confirmEventArchive' values={{ title: this.state.archiveTargetTitle }} />}
                />

                <ConfirmModal
                    open={this.state.undoArchiveConfirmModalOpen}
                    setOpen={(o) => this.setState({ undoArchiveConfirmModalOpen: o })}
                    onConfirm={() => {
                        const { options = { status_ids: [1, 2, 3, 4, 5] }, targetUndoArchiveTransaction } = this.state;
                        updateTransaction({ ...targetUndoArchiveTransaction, transaction_id: targetUndoArchiveTransaction.id, status_id: 1}, () =>
                            this.loadData(options, () => null)
                        )
                    }}                    
                    title={<FormattedMessage id='common.confirmVerb' values={{ verb: formatMessage({ id: 'common.unarchive' }) }} />}                    
                    body={<FormattedMessage id='confirmEventUndoArchive' values={{ title: this.state.undoArchiveTargetTitle }} />}
                />

                <CreateEventModal 
                    modalOpen={this.state.createEventModalOpen}
                    setModalOpen={state => this.setState({ createEventModalOpen: state })}
                />
                <DashViewModal
                    key={this.state.viewingDash ? this.state.viewingDash.id : 'unassigned-dot-modal'}
                    modalOpen={this.state.dashModalOpen}
                    setModalOpen={(open) => this.setState({ dashModalOpen: open })}
                    dashId={this.state.viewingDash ? this.state.viewingDash.id : null}
                    modalTitle={this.state.viewingDash ? this.state.viewingDash.id : ''}
                    showLink={true}
                    showPastVersions={true}
                />
            </Page>
        );
    }
}

TransactionsList.meta = {
    title: <FormattedMessage id="page.transactions.title" />,
    subtitle: <FormattedMessage id="page.transactions.subtitle" />,
    routes: ['/events'],
    icon: 'ion-arrow-swap',
    menus: { main: true, user: false },
    order: 40
};

TransactionsList.defaultProps = {
    transactions: [],
    total: 0
};

const mapStateToProps = state => ({
    transactions: state.transactions.list,
    total: state.transactions.total,
    indexFields: state.indices.list ? state.indices.list.filter(i => i.status_id === 2) : undefined,
    models: state.models.list,
    eventsTableOptions: state.sessionSettings.eventsTableOptions,
    eventsTableQueryState: state.sessionSettings.eventsTableQueryState,
    eventsTableQueryValues: state.sessionSettings.eventsTableQueryValues,
    eventsTableColumns: state.sessionSettings.eventsTableColumns,
    company: state.identity.company,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getModels,
            getModelsLight,
            filterTransactions,
            getIndices,
            deleteTransaction,
            setEventsTableOptions,
            setEventsTableQueryValues,
            setEventsTableQueryState,
            updateTransaction,
            getTransactionDeleteSideEffects,
            setEventsTableColumns,
            cloneTransaction,
            setGraphEntity,
            createEntityGraphLink,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(TransactionsList));
