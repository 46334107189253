import React from 'react';
import { DocumentItemTypes } from 'models/document';

import 'pages/models/models.scss';

class DocumentListItem extends React.Component {
    render() {
        const { item, dragHandleProps } = this.props;
        if (!item) return null;

        const { id, title, type, isSelected, order, onSelect } = item;
        return (
            <div
                className={`field-row col-12 no-select ${isSelected() ? ' selected-field' : ''} field-row-${type.toLowerCase()} f-index-${order}`}
                style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "clip"}}
                {...dragHandleProps}
                onClick={() => onSelect(id)}
            >
                {type === DocumentItemTypes.TEXT && <i className="material-icons-outlined">text_fields</i>}
                {type === DocumentItemTypes.RICHTEXT && <i className="material-icons-outlined">text_fields</i>}
                {type === DocumentItemTypes.IMAGE && <i className="material-icons-outlined">photo</i>}
                <span>{title}</span>
            </div>
        );
    }
}

export default DocumentListItem;
