export const USERS_UPDATE = `
mutation M(
    $user_id        : Long!
    $status_id      : Int
    $email          : String!
    $name           : String!
    $avatar_id      : Long
    $lang_id        : Int
    $country_id     : Int
    $state          : String
    $city           : String
    $zip            : String
    $address_1      : String
    $address_2      : String
    $phone          : String
    $role_id		: Int
)  
{
    user_update(
        user_id: $user_id
        input: {
                status_id      : $status_id 
                email          : $email    
                name           : $name  
                avatar_id      : $avatar_id 
                lang_id        : $lang_id   
                country_id     : $country_id
                state          : $state     
                city           : $city      
                zip            : $zip       
                address_1      : $address_1 
                address_2      : $address_2 
                phone          : $phone
                role_id		   : $role_id
        }
    )
    {      
        id
        hash_id
        status_id
        status_name
        created_on
        modified_on
        email
        auth_type
        last_login_on
        login_attempts
        reset_password
        name
        avatar_id
        lang_id
        lang
        country_id
        country_code2
        country_code3
        state
        city
        zip
        address_1
        address_2
        phone
        extra_info

        mfa_required
    }
}
`;
