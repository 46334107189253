export const MAIN_MENU_SET_SIZE = 'MAIN_MENU_SET_SIZE';
export const MAIN_MENU_TOGGLE_VISIBILITY = 'MAIN_MENU_TOGGLE_VISIBILITY';
export const MAIN_MENU_SET_VISIBILITY = 'MAIN_MENU_SET_VISIBILITY';
export const MAIN_MENU_ADJUST = 'MAIN_MENU_ADJUST';

export const LOCALIZATION_SET_LOCALE = 'LOCALIZATION_SET_LOCALE';
export const LOCALIZATION_LOAD_LOCALE = 'LOCALIZATION_LOAD_LOCALE';

export const LOADING_START = 'LOADING_START';
export const LOADING_COMPLETE = 'LOADING_COMPLETE';

export const IDENTITY_AUTHENTICATE = 'IDENTITY_AUTHENTICATE';
export const IDENTITY_PROFILE = 'IDENTITY_PROFILE';
export const IDENTITY_LOGOUT = 'IDENTITY_LOGOUT';

export const USERS_GET_LIST = 'USERS_GET_LIST';
export const USERS_GET_ROLES = 'USERS_GET_ROLES';
export const USERS_GET_PERMISSIONS = 'USERS_GET_PERMISSIONS';

export const BLACKLIST_GET_LIST = 'BLACKLIST_GET_LIST';
export const BLACKLIST_DELETE = 'BLACKLIST_DELETE';

export const MODEL_PERSISTING = 'MODEL_PERSISTING';

export const MODELS_GET_LIST = 'MODELS_GET_LIST';
export const MODELS_CREATE = 'MODELS_CREATE';
export const MODELS_GET_STATUSES = 'MODELS_GET_STATUSES';
export const CARGO_CATEGORY_LIST = 'CARGO_CATEGORY_LIST';
export const EVENT_TEMPLATE_LIST = 'EVENT_TEMPLATE_LIST';

export const FIELDS_GET_TYPES = 'FIELDS_GET_TYPES';
export const FIELDS_GET_MODEL_PROPERTIES = 'FIELDS_GET_MODEL_PROPERTIES';
export const FIELDS_CONFIGURATION_SET_FIELDS = 'FIELDS_CONFIGURATION_SET_FIELDS';
export const FIELDS_CONFIGURATION_UPDATE_FIELD = 'FIELDS_CONFIGURATION_UPDATE_FIELD';
export const FIELDS_CONFIGURATION_BATCH_UPDATE_FIELDS = 'FIELDS_CONFIGURATION_BATCH_UPDATE_FIELDS';
export const FIELDS_IMPORT = 'FIELDS_IMPORT';
export const FIELDS_SET_MODEL_VISIBILITY = 'FIELDS_SET_MODEL_VISIBILITY';
export const FIELDS_UPDATE_MODEL = 'FIELDS_UPDATE_MODEL';
export const FIELDS_CLEANUP = 'FIELDS_CLEANUP';

export const FIELD_INDICES_GET_TYPES = 'FIELD_INDICES_GET_TYPES';
export const FIELD_INDICES_GET_STATUSES = 'FIELD_INDICES_GET_STATUSES';
export const FIELD_INDICES_GET_LIST = 'FIELD_INDICES_GET_LIST';
export const FIELD_INDICES_GET_DASH_LIST = 'FIELD_INDICES_GET_DASH_LIST';
export const FIELD_INDICES_GET_DOT_LIST = 'FIELD_INDICES_GET_DOT_LIST';

export const FIELD_VISIBILITY_GET_OPERATORS = 'FIELD_VISIBILITY_GET_OPERATORS';
export const FIELD_VISIBILITY_GET_INDEX_VALUES = 'FIELD_VISIBILITY_GET_INDEX_VALUES';
export const FIELD_VISIBILITY_GET_FIELD_VALUES = 'FIELD_VISIBILITY_GET_FIELD_VALUES';
export const FIELD_VISIBILITY_SET_DIALOG = 'FIELD_VISIBILITY_SET_DIALOG';

export const TRANSACTIONS_GET_LIST = 'TRANSACTIONS_GET_LIST';
export const TRANSACTIONS_GET_SINGLE = 'TRANSACTIONS_GET_SINGLE';
export const TRANSACTIONS_GET_STATUSES = 'TRANSACTIONS_GET_STATUSES';

export const TRANSACTIONS_PERSISTING = 'TRANSACTIONS_PERSISTING';


export const COMPANIES_GET_LIST = 'COMPANIES_GET_LIST';
export const COMPANIES_GET_STATUSES = 'COMPANIES_GET_STATUSES';
export const COMPANIES_GET_INVITE_LINKS = 'COMPANIES_GET_INVITE_LINKS';
export const COMPANIES_GET_INVITE_RESPONSES = 'COMPANIES_GET_INVITE_RESPONSES';
export const COMPANIES_GET_INVOICES = 'COMPANIES_GET_INVOICES';

export const LINKS_GET_TYPES = 'LINKS_GET_TYPES';

export const DOCUMENTS_GET_LIST = 'DOCUMENTS_GET_LIST';

export const DOCUMENT_GET = 'DOCUMENT_GET';
export const DOCUMENT_UPDATED = 'DOCUMENT_UPDATED';
export const DOCUMENT_SET_TEMPLATE = 'DOCUMENT_SET_TEMPLATE';
export const DOCUMENT_SET_ITEMS = 'DOCUMENT_SET_ITEMS';
export const DOCUMENT_CREATE = 'DOCUMENT_CREATE';
export const DOCUMENT_SELECT_ITEM = 'DOCUMENT_SELECT_ITEM';
export const DOCUMENT_TEMPLATE_CLONE = 'DOCUMENT_TEMPLATE_CLONE';


export const PAYMENT_GET_PLANS = 'PAYMENT_GET_PLANS';
export const PAYMENT_GET_PRODUCTS = 'PAYMENT_GET_PRODUCTS';

export const LOTS_LIST = 'LOTS_LIST';
export const LOTS_LIST_MATCHES = 'LOTS_LIST_MATCHES';
export const LOTS_LIST_LIGHT = 'LOTS_LIST_LIGHT';

export const CARGO_UNITS_LIST = 'CARGO_UNITS_LIST';

export const SET_EVENTS_TABLE_OPTIONS= 'SET_EVENTS_TABLE_OPTIONS';
export const SET_EVENT_TEMPLATES_TABLE_OPTIONS= 'SET_EVENT_TEMPLATE_TABLE_OPTIONS';
export const SET_LOTS_TABLE_OPTIONS= 'SET_LOTS_TABLE_OPTIONS';
export const SET_CARGO_CATEGORIES_TABLE_OPTIONS= 'SET_CARGO_CATEGORIES_TABLE_OPTIONS';
export const SET_DOCUMENTS_TABLE_OPTIONS= 'SET_DOCUMENTS_TABLE_OPTIONS';
export const SET_DOCUMENT_TEMPLATES_TABLE_OPTIONS= 'SET_DOCUMENT_TEMPLATES_TABLE_OPTIONS';
export const SET_COMPANIES_TABLE_OPTIONS= 'SET_COMPANIES_TABLE_OPTIONS';
export const SET_INVOICES_TABLE_OPTIONS= 'SET_INVOICES_TABLE_OPTIONS';
export const SET_EVENT_INDICES_TABLE_OPTIONS= 'SET_EVENT_INDICES_TABLE_OPTIONS';
export const SET_CARGO_INDICES_TABLE_OPTIONS= 'SET_CARGO_INDICES_TABLE_OPTIONS';
export const SET_EVENTS_TABLE_FILTERS= 'SET_EVENTS_TABLE_FILTERS';
export const SET_EVENTS_TABLE_ADV_FILTERS= 'SET_EVENTS_TABLE_ADV_FILTERS';
export const SET_EVENT_TEMPLATES_TABLE_QUERY_VALUES= 'SET_EVENT_TEMPLATES_TABLE_QUERY_VALUES';
export const SET_EVENT_TEMPLATES_TABLE_QUERY_STATE= 'SET_EVENT_TEMPLATES_TABLE_QUERY_STATE';
export const SET_CARGO_CATEGORIES_TABLE_QUERY_VALUES= 'SET_CARGO_CATEGORIES_TABLE_QUERY_VALUES';
export const SET_CARGO_CATEGORIES_TABLE_QUERY_STATE= 'SET_CARGO_CATEGORIES_TABLE_QUERY_STATE';
export const SET_LOTS_TABLE_QUERY_VALUES= 'SET_LOTS_TABLE_QUERY_VALUES';
export const SET_LOTS_TABLE_QUERY_STATE= 'SET_LOTS_TABLE_QUERY_STATE';
export const SET_ALERTS_TABLE_OPTIONS= 'SET_ALERTS_TABLE_OPTIONS';
export const SET_EVENTS_TABLE_COLUMNS= 'SET_EVENTS_TABLE_COLUMNS';
export const SET_LOTS_TABLE_COLUMNS= 'SET_LOTS_TABLE_COLUMNS';
export const SET_INPUT_LOT_TABLE_COLUMNS= 'SET_INPUT_LOT_TABLE_COLUMNS';

export const ALERTS_LIST = 'ALERTS_LIST';
export const ALERTS_GET = 'ALERTS_GET';
export const ALERTS_CREATE = 'ALERTS_CREATE';
export const ALERTS_UPDATE = 'ALERTS_UPDATE';
export const ALERTS_DELETE = 'ALERTS_DELETE';
export const ALERTS_MODIFY_SUBSCRIBER = 'ALERTS_MODIFY_SUBSCRIBER';

export const ALERT_CONDITIONS_LIST = 'ALERT_CONDITIONS_LIST';
export const ALERT_CONDITIONS_GET = 'ALERT_CONDITIONS_GET';
export const ALERT_CONDITIONS_CREATE = 'ALERT_CONDITIONS_CREATE';
export const ALERT_CONDITIONS_UPDATE = 'ALERT_CONDITIONS_UPDATE';
export const ALERT_CONDITIONS_DELETE = 'ALERT_CONDITIONS_DELETE';

export const EXCEL_HIDE_FR_COPYPASTE_WARNING = 'EXCEL_HIDE_FR_COPYPASTE_WARNING';