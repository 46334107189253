import React from 'react';
import moment from 'moment';
import { Line, Bar } from 'react-chartjs-2';

class StaticDashboardContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    renderLeftBox = (title, content = [], color = 'bg-teal') => {
        return (
            <div className={`dash-box ${color} rounded overflow-hidden`}>
                <p className="tx-11 tx-spacing-1 tx-mont tx-medium tx-uppercase tx-white-8 mg-b-15">{title}</p>
                {content.map((c, i) => (
                    <div key={i} className="tx-11 tx-roboto tx-white-6 mg-b-5">
                        {c.includes(':') ? (
                            <React.Fragment>
                                <span>{c.split(':')[0]}:</span>
                                <span className="mg-l-5 tx-white">{c.split(':')[1]}</span>
                            </React.Fragment>
                        ) : (
                            <div className="mg-b-10">{c}</div>
                        )}
                    </div>
                ))}
            </div>
        );
    };

    renderBarChart = () => {
        const d = Array.apply(null, { length: 7 }).map(Number.call, Number);
        const labels = d
            .map(i =>
                moment()
                    .add(i * -1, 'days')
                    .format('MMMM D')
            )
            .reverse();

        const data = {
            labels,
            datasets: [
                {
                    label: 'Copper',
                    data: [22, 20, 27, 21, 23, 26, 25],
                    fill: true,
                    backgroundColor: '#00336Bcc',
                    borderColor: '#00336B'
                },
                {
                    label: 'Cobalt',
                    data: [12, 49, 23, 32, 52, 44, 31],
                    fill: true,
                    backgroundColor: '#0866c6cc',
                    borderColor: '#0866c6'
                },
                {
                    label: 'Other',
                    data: [24, 19, 23, 20, 32, 34, 45],
                    fill: true,
                    backgroundColor: '#9094a9cc',
                    borderColor: '#9094a9'
                }
            ]
        };
        return (
            <React.Fragment>
                <p className="mg-b-15">
                    <span className="tx-11 tx-spacing-1 tx-mont tx-medium tx-uppercase tx-black-8 mg-r-5">Top Materials</span>
                    <span className="tx-11 tx-spacing-1 tx-mont tx-medium tx-uppercase tx-gray-400">(last week)</span>
                </p>
                <div style={{ height: '200px' }}>
                    <Bar
                        data={data}
                        options={{
                            maintainAspectRatio: false,
                            legend: { position: 'bottom' },
                            scales: {
                                yAxes: [
                                    {
                                        display: true,
                                        gridLines: {
                                            borderDash: [2, 4],
                                            color: '#ccc'
                                        },

                                        ticks: {
                                            stepSize: 20
                                        }
                                    }
                                ],
                                xAxes: [
                                    {
                                        display: true,
                                        gridLines: {
                                            borderDash: [2, 4],
                                            color: '#ccc'
                                        }
                                    }
                                ]
                            }
                        }}
                    />
                </div>
            </React.Fragment>
        );
    };

    renderLineChart = () => {
        const d = Array.apply(null, { length: 7 }).map(Number.call, Number);
        const labels = d
            .map(i =>
                moment()
                    .add(i * -1, 'days')
                    .format('MMMM D')
            )
            .reverse();

        const data = {
            labels,
            datasets: [
                {
                    label: 'Daily Transactions',
                    data: [22, 19, 27, 23, 12, 24, 17, 25, 23],
                    fill: false,
                    borderColor: '#00336B'
                }
            ]
        };
        return (
            <React.Fragment>
                <p className="mg-b-15">
                    <span className="tx-11 tx-spacing-1 tx-mont tx-medium tx-uppercase tx-black-8 mg-r-5">Transactions</span>
                    <span className="tx-11 tx-spacing-1 tx-mont tx-medium tx-uppercase tx-gray-400">(last week)</span>
                </p>
                <div style={{ height: '200px' }}>
                    <Line
                        data={data}
                        options={{
                            maintainAspectRatio: false,
                            legend: { position: 'bottom' },
                            scales: {
                                yAxes: [
                                    {
                                        display: true,
                                        gridLines: {
                                            borderDash: [2, 4],
                                            color: '#ccc'
                                        },

                                        ticks: {
                                            stepSize: 20
                                        }
                                    }
                                ],
                                xAxes: [
                                    {
                                        display: true,
                                        gridLines: {
                                            borderDash: [2, 4],
                                            color: '#ccc'
                                        }
                                    }
                                ]
                            }
                        }}
                    />
                </div>
            </React.Fragment>
        );
    };

    render() {
        return (
            <div className="dashboard-wrapper">
                <div className="dash-left">
                    {this.renderLeftBox("This Month's Volumes", [
                        'Total Value: $100,004,962',
                        '',
                        'Top Material: Copper',
                        'Volume: 20,116mt',
                        '',
                        'Top Counterparts: Jiangxi Copper Company',
                        'Volume: 9,873mt'
                    ])}

                    {this.renderLeftBox(
                        "Today's Transactions",
                        ['Committed: 24 (87% of historical average)', 'Newly Created: 15 (107% of historical average)'],
                        'bg-primary'
                    )}

                    {this.renderLeftBox("Today's Traffic", ['Logins: 29', '', 'Most Active: Karan Pereira', 'Transactions: 17'], 'bg-br-primary')}
                </div>

                <div className="dash-right">
                    <div className="card bd-0">{this.renderBarChart()}</div>
                    <div className="card bd-0">{this.renderLineChart()}</div>
                </div>
            </div>
        );
    }
}

export default StaticDashboardContent;
