import React from 'react';
import { FormattedMessage } from 'react-intl';
import { call, hasErrors } from '../configuration/api';
import { Notifications } from '../components/notifications';
import { CARGO_UNITS_LIST } from './types';
import Queries from './queries';

const defaultState = {
    list: [],
    total: 0,
};

/**
 * Loading state mapper.
 * @param {object} state
 * @param {string} action
 */
export default function(state = defaultState, action) {
    switch (action.type) {
        case CARGO_UNITS_LIST:
            return { ...state, list: action.list, total: action.list.length };
        
        default:
            return state;
    }
}

export const listCargoUnits = (variables, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.CargoUnits.List });
    if (hasErrors(response)) {
        onError(response);
        return;
    }

    const { data } = response;
    if (!data || !data.cargo_unit_list) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    dispatch({ type: CARGO_UNITS_LIST, list: data.cargo_unit_list });
    onComplete(data.cargo_unit_list);
};

export const createCargoUnit = (variables, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.CargoUnits.Create, variables });
    if (hasErrors(response)) {
        onError(response);
        return;
    }

    const { data } = response;
    if (!data || !data.cargo_unit_create) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.cargo_unit_create);
};
