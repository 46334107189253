import React from 'react';
import Tooltip from 'rc-tooltip';
import { Formik, Form } from 'formik';
import { FormattedMessage } from 'react-intl';
import { FieldTypes } from '../../../../models/field';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw } from 'draft-js';
import { getRichTextContent, uuid } from '../../../../utilities/common';
import { getCurrencyOptions, getTextMaxLength } from '../../../../utilities/constants';
import { Fields } from '../../../../components/fields';
import YesNoNullField from './yesNoNull';

export const RowBreak = ({ width }) => {
    return width === 12 ? null : <br />;
};

export const FieldDescription = ({ type, description, display = null, seleniumId = null }) => {
    if (!type || !description || description === '') return null;
    const isSection = type === FieldTypes.FIELD_SECTION;

    let descriptionMarkup = '';
    if (!description.startsWith('{"blocks":[')) {
        descriptionMarkup = description;
    } else {
        const descriptionObject = getRichTextContent(description);
        if (!descriptionObject || descriptionObject.blocks.filter(b => b.text.length > 0).length == 0) {
            return null;
        }
        descriptionMarkup = <div className="field-description-overlay" dangerouslySetInnerHTML={{ __html: stateToHTML(convertFromRaw(descriptionObject)) }} />;
    }

    return (
        <Tooltip placement={'right'} trigger={['hover', 'click']} overlayClassName="field-description-tooltip" overlay={descriptionMarkup} overlayStyle={{zIndex: 2080}}>
            <span className={`field-description ${isSection ? 'section' : ''} se-description-${seleniumId}`} style={display ? {display: display} : null}>
                <i className="material-icons-outlined">info</i>
            </span>
        </Tooltip>
    );
};

export const ValuesSetupForm = props => {
    const { onSubmit, fieldValue } = props;
    let defaultValues = { value: '', order: 0, isHidden: false };
    return (
        <Formik
            enableReinitialize={false}
            initialValues={{ id: uuid(), ...(fieldValue || { ...defaultValues }) }}
            onSubmit={onSubmit}
            render={({ values, resetForm }) => {
                return (
                    <Form noValidate>
                        <div className="existing-value">
                            <div className="field-value-input">
                                <Fields.Input type="text" name="value" maxLength={getTextMaxLength()} />
                            </div>
                            <button
                                className="btn btn-primary icon-button field-inline float-right"
                                type="button"
                                onClick={async () => {
                                    if (values.value === undefined || values.value === null || values.value === '') return;
                                    await onSubmit(values);
                                    resetForm({ ...defaultValues });
                                }}
                            >
                                <i className="material-icons-outlined">add</i>
                            </button>
                        </div>
                    </Form>
                );
            }}
        />
    );
};

export const DateFormatLegend = (
    <table className="date-format-legend">
        <thead>
            <tr>
                <th>
                    <FormattedMessage id="common.format" />
                </th>
                <th>
                    <FormattedMessage id="common.example" />
                </th>
                <th>
                    <FormattedMessage id="common.description" />
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                    <code>YYYY</code>
                </td>
                <td>
                    <code>2014</code>
                </td>
                <td>
                    <FormattedMessage id="models.configuration.dateFormat.2or4digitYear" />
                </td>
            </tr>
            <tr>
                <td>
                    <code>YY</code>
                </td>
                <td>
                    <code>14</code>
                </td>
                <td>
                    <FormattedMessage id="models.configuration.dateFormat.2digitYear" />
                </td>
            </tr>
            <tr>
                <td>
                    <code>Q</code>
                </td>
                <td>
                    <code>1..4</code>
                </td>
                <td>
                    <FormattedMessage id="models.configuration.dateFormat.QYear" />
                </td>
            </tr>
            <tr>
                <td>
                    <code>M MM</code>
                </td>
                <td>
                    <code>1..12</code>
                </td>
                <td>
                    <FormattedMessage id="models.configuration.dateFormat.monthNumber" />
                </td>
            </tr>
            <tr>
                <td>
                    <code>D DD</code>
                </td>
                <td>
                    <code>1..31</code>
                </td>
                <td>
                    <FormattedMessage id="models.configuration.dateFormat.dayOfMonth" />
                </td>
            </tr>
            <tr>
                <td>
                    <code>DDD DDDD</code>
                </td>
                <td>
                    <code>1..365</code>
                </td>
                <td>
                    <FormattedMessage id="models.configuration.dateFormat.dayOfYear" />
                </td>
            </tr>
        </tbody>
    </table>
);

export const normalizeNumber = (values, formattedNumber) => {
    let numberWithoutSeparators = String(formattedNumber);
    if (!!numberWithoutSeparators) {
        const decimalSeparator = (values.numberFormat === "DotSeparator") ? "." : ",";
        if (values.groupThousands) {
            const digitGroupSeparator = (values.numberFormat === "DotSeparator") ? "," : ".";
            numberWithoutSeparators = numberWithoutSeparators.replaceAll(digitGroupSeparator, '');
        }
        return numberWithoutSeparators.replaceAll(decimalSeparator, '.');
    } else {
        return formattedNumber;
    }
}

export const getDefaultValuesField = (values, disabled) => {
    switch (values.type) {
        case FieldTypes.FIELD_NUMBER:
            return (
                <div className="form-group col-12">
                    <Fields.InputNumeric disabled={disabled} submitOnChange={true} stepField={normalizeNumber(values, values.step)} groupThousands={values.groupThousands} numberFormat={values.numberFormat} fieldValue={values.defaultValue} name="defaultValue" />
                </div>
            );
        case FieldTypes.FIELD_TEXT:
            return (
                <div className="form-group col-12">
                    <Fields.Input type="text" name="defaultValue" disabled={disabled} maxLength={getTextMaxLength()} />
                </div>
            );
        case FieldTypes.FIELD_CURRENCY:
            return (
                <div className="form-group col-12">
                    <Fields.Select 
                        name="defaultValue" 
                        options={getCurrencyOptions()} 
                        submitOnChange={true} 
                        value={values.defaultValue} 
                        disabled={disabled} 
                        isClearable={true}
                        />
                </div>
            );

        case FieldTypes.FIELD_DATE:
            return (
                <div className="form-group col-12">
                    <Fields.Date 
                        submitOnChange={true} 
                        dateFormat={values.dateFormat} 
                        name="defaultValue" 
                        disabled={disabled} 
                        key={values.dateFormat}
                    />
                </div>
            );

        case FieldTypes.FIELD_YES_NO:
            return (
                <div className="form-group col-12">
                    <Fields.YesNoNull submitOnChange={true} name="defaultValue" disabled={disabled} seleniumId={'defaultValue'} />
                </div>
            );

        case FieldTypes.FIELD_SELECT:
        case FieldTypes.FIELD_MULTI_SELECT:
            return (
                <div className="form-group col-12">
                    <Fields.Select
                        name="defaultValue"
                        value={values.defaultValue}
                        submitOnChange={true}
                        isClearable={true}
                        multi={values.type === FieldTypes.FIELD_MULTI_SELECT}
                        options={values && values.fieldValues ? [...values.fieldValues.filter(v => v && v.hasOwnProperty('value')).map(i => ({ value: i.value, label: i.value }))] : []}
                        disabled={disabled}
                    />
                </div>
            );
        
        case FieldTypes.FIELD_MULTI_TEXT:
            return (
                <div className="form-group col-12">
                    <Fields.MultiText
                        name="defaultValue"
                        value={values.defaultValue}
                        submitOnChange={true}
                        disabled={disabled}
                    />
                </div>
            )

        default:
            return null;
    }
};
