export const COMPANIES_CREATE_INVITE_LINK = `
    mutation M($type_name: String!, $data: JsonString, $exp_on: DateTime, $max_uses: Int, $title: String, $description: String) {
        link_create_ex(input: { type_name: $type_name, data: $data, exp_on: $exp_on, max_uses: $max_uses, title: $title, description: $description }) {
            id
            type_id
            type_name
            name
            created_on
            exp_on
            data
            clicks
            uses
            max_uses
            is_valid
            title
            description
        }
    }
`;
