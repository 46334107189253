import '../../models/models.scss';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import Tooltip from 'rc-tooltip';

class cargoQuantityField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            exactMatchesOnly: false,
            lotMatchesExact: [],
            lotMatches: [],
            loadingLots: false,
        };
    }

    componentDidMount() {
    }

    setQuantity = e => {
        const val = e.currentTarget ? e.currentTarget.value : 0;
        this.setState({ inputQuantity: val }, () => {
            if (this.props.onQuantitySelected) this.props.onQuantitySelected(val);
        });
    }

    setQuantityNumeric = num => {
        const numIfPositive = (num < 0) ? 0 : num;
        this.setState({ inputQuantity: numIfPositive }, () => {
            if (this.props.onQuantitySelected) this.props.onQuantitySelected(numIfPositive);
        });
    }

    render() {
        const { disabled, appendEl, customLabel, intl: { formatMessage }, tooltipOverlay, seleniumId } = this.props;

        const inputQuantity = (this.state.inputQuantity === undefined || isNaN(this.state.inputQuantity) || disabled) ? this.props.initialTargetQuantity : this.state.inputQuantity;

        const fieldEl = (
            <div className="number-group">
                <span className={'input-group-addon start no-select ' + (disabled ? 'disabled' : '')} onClick={() => !disabled && this.setQuantityNumeric( isNaN(inputQuantity) ? 0 : (Number(inputQuantity) - 1) )}>
                    -
                </span>
                <MaskedInput
                    className={`form-control cargo-quantity se-id-${seleniumId}.quantity`}
                    autoComplete="off"
                    mask={createNumberMask({
                        allowDecimal: true,
                        decimalSymbol: '.',
                        prefix: '',
                        suffix: '',
                        allowNegative: false,
                        includeThousandsSeparator: false,
                        decimalLimit: 3
                    })}
                    id='input-quantity'
                    defaultValue={0}
                    min={0}
                    max={10000000000}
                    value={inputQuantity}
                    onChange={this.setQuantity}
                    disabled={disabled}
                    placeholder={customLabel ? customLabel : formatMessage({ id:"common.quantity" })}
                />
                <span className={'input-group-addon end no-select ' + (disabled ? 'disabled' : '')} onClick={() => !disabled && this.setQuantityNumeric( isNaN(inputQuantity) ? 1 : (Number(inputQuantity) + 1) )}>
                    +
                </span>
                { !(inputQuantity === undefined || inputQuantity === null || isNaN(inputQuantity) || inputQuantity === '') &&<div className="input-placeholder">{ customLabel ? customLabel : <FormattedMessage id="common.quantity" /> }</div> }
            </div>
        );

        return (
            <div className={'field-number'}>
                {
                    tooltipOverlay ?
                    <Tooltip
                        placement={'top'}
                        trigger={['hover']}
                        overlay={tooltipOverlay}
                        children={ fieldEl }
                    />
                    :
                    fieldEl
                }
                {appendEl}
            </div>
        );
    }
}

cargoQuantityField.defaultProps = {
};

const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(cargoQuantityField));
