import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import ReactModal from 'react-modal';
import { Field, ErrorMessage } from 'formik';
import RichTextEditor from './richTextEditor';

import './fields.scss';

class RichTextButtonField extends React.Component {
    constructor(props) {
        super(props);
        this.state = { modal: { open: false } };
    }

    setModalVisibility = visibility => {
        const { disabled = false } = this.props;
        this.setState({ ...this.state, modal: { open: !!disabled ? false : visibility } });
    };

    render() {
        const {
            name,
            intl: { formatMessage },
            dialogTitle,
            disabled = false,
        } = this.props;
        const { modal } = this.state;

        return (
            <React.Fragment>
                <button type="button" className={'btn btn-block rich-text-button' + (disabled ? ' disabled' : '')} onClick={() => this.setModalVisibility(true)}>
                    <span>{formatMessage({ id: `${name}.placeholder` })}</span>
                    <i className="material-icons-outlined">edit</i>
                </button>

                {modal.open && (
                    <ReactModal
                        isOpen={modal.open}
                        onRequestClose={() => this.setModalVisibility(false)}
                        className="modal-block dialog"
                        overlayClassName="modal-overlay gray"
                    >
                        <Field {...this.props}>
                         {(fieldProps) => (
                            <div className="modal-dialog rich-text-button-dialog" role="document">
                                <div className="modal-content bd-0">
                                    <div className="modal-header pd-x-20">
                                        <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">{dialogTitle}</h6>
                                    </div>
                                    <div className="modal-body">
                                        <div className="form-control-wrapper">
                                            <RichTextEditor {...this.props} placeholder={formatMessage({ id: `${name}.placeholder` })} fieldProps={fieldProps} />
                                            <ErrorMessage name={name} component="div" className="validation-message" />
                                        </div>
                                    </div>
                                    <div className="modal-footer justify-content-center">
                                        <button
                                            type="button"
                                            className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                            onClick={() => {
                                                this.setModalVisibility(false);
                                                if (!this.props.submitOnChange) {
                                                    fieldProps.form.submitForm();
                                                }
                                            }}
                                        >
                                            <FormattedMessage id="common.ok" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                         )}
                        </Field>
                    </ReactModal>
                )}
            </React.Fragment>
        );
    }
}

export default injectIntl(RichTextButtonField);
