import React, {useState} from 'react';
import ReactModal from 'react-modal';
import { FormattedMessage } from 'react-intl';
import FontIconPicker from '@fonticonpicker/react-fonticonpicker';
import { icons } from './icons';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css';

export default function IconSelectorModal({ isOpen, setOpen, onConfirm, currentSelectedIcons, isSingle = false }) {
    const [selectedIcons, setSelectedIcons] = useState(currentSelectedIcons);
    const [currentSelectedIcon, setCurrentSelectedIcon] = useState( (isSingle && currentSelectedIcons.length > 0) ? currentSelectedIcons[0] : '' );
    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={() => setOpen(false)}
            className="modal-block dialog"
            overlayClassName="modal-overlay gray"
            style={{zIndex: '2000', content: { width: '650px', height: '90%', overflowY: 'auto', overflowX: 'visible' }}}
        >
            <div className="modal-dialog modal-lg wd-450" role="document">
                <div className="modal-content bd-0">
                    <div className="modal-header pd-x-20">
                        <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                            <FormattedMessage id='selectIcon' />
                        </h6>
                    </div>
                    <div className="modal-body pd-20">
                        <div className="row">
                            <span>Click to choose Icon{!isSingle && 's'} </span> &nbsp;&nbsp;
                            {/* <IconPicker value={currentSelectedIcon} onChange={(v) => {
                                if (isSingle) {
                                    setCurrentSelectedIcon(v)
                                } else {
                                    setSelectedIcons(selectedIcons.concat([v]));
                                }
                            }} /> */}
                            <FontIconPicker
                                icons={icons}
                                value={isSingle ? currentSelectedIcon : selectedIcons}
                                // onChange={(val) => console.log(val)}
                                onChange={(v) => {
                                    if (isSingle) {
                                        setCurrentSelectedIcon(v)
                                    } else {
                                        setSelectedIcons(v);
                                    }
                                }}
                                isMulti={!isSingle}
                                iconsPerPage={20}
                            />
                        </div>
                        {
                            !isSingle 
                            &&
                            <div className="row">
                                {
                                    selectedIcons.map((name, index) => !name ? null : (
                                        <div key={`${name}-${index}`} className="col-4">
                                            <i className={name} style={{fontSize: 48, color: "#AAA"}} />

                                            {/* <IconPickerItem icon={name} size={48} color="#AAA"  /> */}
                                            <div 
                                                style={{
                                                    height: '20px',
                                                    lineHeight: '20px',
                                                    width: '20px',
                                                    fontSize: '1em',
                                                    fontWeight: 'bold',
                                                    borderRadius: '50%',
                                                    backgroundColor: 'red',
                                                    color: 'white',
                                                    textAlign: 'center',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    const currentIcons = selectedIcons.slice();
                                                    currentIcons.splice(index, 1);
                                                    setSelectedIcons(currentIcons);
                                                }}
                                            >
                                                ✕
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        }
                    </div>
                    <div className="modal-footer justify-content-center">
                        <button
                            type="button"
                            className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                            disabled={!selectedIcons}
                            onClick={() => {
                                if (isSingle) {
                                    onConfirm(currentSelectedIcon)
                                } else {
                                    onConfirm(selectedIcons);
                                }
                                setOpen(false)
                            }}
                        >
                            <FormattedMessage id="common.confirm" />
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                            onClick={() => {
                                if (isSingle) {
                                    if (currentSelectedIcons.length > 0) {
                                        setCurrentSelectedIcon(currentSelectedIcons[0]);
                                    } else {
                                        setCurrentSelectedIcon('');
                                    }
                                } else {
                                    setSelectedIcons(currentSelectedIcons);
                                }
                                setOpen(false);
                            }}
                        >
                            <FormattedMessage id="common.cancel" />
                        </button>
                    </div>
                </div>
            </div>
        </ReactModal>
    )
}