import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import Select from 'react-select/lib/Async';
// import Select, { components } from 'react-select';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import fuzzysort from 'fuzzysort';
import 'components/fields/fields.scss';
import Tooltip from 'rc-tooltip';
import ReactModal from 'react-modal';
import lunr from 'lunr';
import { searchFaq } from 'reducers/faq'

class FaqSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: '',
            savedInputValue: '',
            options: [],
            faqMessages: [],
            index: null,
        };
    }

    componentDidMount() {
        const { translations } = this.props;
        let faqMessages = [];
        for (var key in translations) {
            if (translations.hasOwnProperty(key) && (key.includes('faq.q') || key.includes('faq.a'))) {
                const valueLower = translations[key].toLowerCase();
                faqMessages.push({ key, value: translations[key], valueLower, prepared: fuzzysort.prepare(valueLower) });
            }
        }
        this.setState({ faqMessages });

        /* // grab pre-built index of FAQ so we don't have to generate at runtime
           // this will make more sense if the FAQ becomes sufficiently large
           // just need to JSON.stringify(index) and save to public/faqSearchIndex.json
            fetch(
                `${process.env.PUBLIC_URL}/faqSearchIndex.json`,
                {
                    method: 'GET',
                    headers : { 
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            )
            .then(response => response.json())
            .then(index => this.setState({ index }));
        */
         // build index for lunr search:
        const index = lunr(function() {
            this.ref('key');
            this.field('valueLower');

            faqMessages.forEach(faq => {
                this.add(faq);
            })
        });
        this.setState({ index });
    }

    render() {
        const {
            intl: { formatMessage },
            name,
            disabled = false,
            className = null,
        } = this.props;
        
        const customStyles = {
            control: base => ({
                ...base,
                boxShadow: 'none'
            }),
            multiValueLabel: (provided, state) => ({
                ...provided,
                maxWidth: '10em'
            }),
            menu: (provided, state) => ({
                ...provided,
                zIndex: '20'
            }),
            menuList: (provided, state) => ({
                ...provided,
                maxHeight: '200px'
            }),
            
        };

        const optionsMessage = () => { return this.state.inputValue.length > 0 ? formatMessage({ id: 'librariesSearchNoResults'}) : formatMessage({ id: 'librariesSearchTypeMessage'}) }
        return (
            <div className={className} style={{ display: 'inline-block' }}>
                <div className="field-select ">
                    <Select
                        className="select-input-field"
                        placeholder={<i className="material-icons faq-search-icon ">search</i>}
                        name={name}
                        defaultOptions={this.state.inputValue.trim() ? this.state.options : []}
                        loadOptions={(inputValue) => new Promise(resolve => {
                            setTimeout(async () => {
                                let options = [];
                                if (this.state.inputValue) {
                                    // try to query the backend, which uses Typesense to search the FAQ
                                    const { locale, searchFaq, translations } = this.props;
                                    await new Promise(resolve => {
                                        searchFaq(
                                            { locale, query: this.state.inputValue.toLowerCase() }, 
                                            results => {
                                                const resultQuestions = results.map(res => {
                                                    const value = translations[res.id];
                                                    return {
                                                        key: res.id,
                                                        value
                                                    };
                                                })
    
                                                const formattedResults = resultQuestions
                                                    .map(q => {
                                                        return {
                                                            label: q.value,
                                                            value: q.key
                                                        }
                                                    });
                                                formattedResults.forEach(q => {
                                                    let match = options.find(o => o.value === q.value);
                                                    if (match === undefined) {
                                                        options.push(q);
                                                    }
                                                })
                                                resolve();
                                            },
                                            () => {
                                                // if we fail to fetch results from the backend, calculate rudimentary results in the frontend
                                                const inputWords = this.state.inputValue
                                                    .split(/\s+/)
                                                    .filter(str => str.length > 0);
                                                let resultQuestions = [];
            
                                                // if there is only 1 or 2 words, we use fuzzysort as it is extremely effective with a small number of search terms
                                                if (inputWords.length < 3) {
                                                    // add the whole string without spaces to try and match context
                                                    const results = fuzzysort.go(this.state.inputValue, this.state.faqMessages, {
                                                        key:'prepared',
                                                        threshold: -1000,
                                                        limit: 50,
                                                    });
                                                    // present only the questions - transpose answers to their corresponding questions
                                                    resultQuestions = results.map(result => {
                                                        if (result.obj.key.includes('faq.q')) {
                                                            return result.obj;
                                                        } else {
                                                            const keyParts = result.obj.key.split('.');
                                                            if (keyParts.length > 2) {
                                                                const correspondingQuestionId = keyParts[0] + '.' + keyParts[1].replace('a', 'q') + '.' + keyParts[2];
                                                                return this.state.faqMessages.find(question => question.key === correspondingQuestionId);
                                                            }
                                                            return undefined;
                                                        }
                                                    })
                                                } else {
                                                    const lastWord = inputWords[inputWords.length - 1];
                                                    const lastWordFuzz = lastWord.length > 6 ? '~2' : ( '~' + String(8 - lastWord.length) );
                                                    // the '+' character marks each word as required, while the trailing '~' and number indicate the number of transformations
                                                    // to allow for the search term. For the last word, we dynamically compute a greater number of transformations in the event the user hasn't
                                                    // finished typing the word
                                                    const searchText = '+' + inputWords.slice(0, inputWords.length - 1).join('~2 +') + '~2 +' + lastWord + lastWordFuzz;
                                                    const results = this.state.index.search(searchText);
                                                    
                                                    // present only the questions - transpose answers to their corresponding questions
                                                    resultQuestions = results.map(result => {
                                                        if (result.ref.includes('faq.q')) {
                                                            const faqEntry = this.state.faqMessages.find(question => question.key === result.ref);
                                                            return faqEntry;
                                                        } else {
                                                            const keyParts = result.ref.split('.');
                                                            if (keyParts.length > 2) {
                                                                const correspondingQuestionId = keyParts[0] + '.' + keyParts[1].replace('a', 'q') + '.' + keyParts[2];
                                                                return this.state.faqMessages.find(question => question.key === correspondingQuestionId);
                                                            }
                                                            return undefined;
                                                        }
                                                    });
            
                                                }
                                                const formattedResults = resultQuestions
                                                    .map(q => {
                                                        return {
                                                            label: q.value,
                                                            value: q.key
                                                        }
                                                    });
                                                formattedResults.forEach(q => {
                                                    let match = options.find(o => o.value === q.value);
                                                    if (match === undefined) {
                                                        options.push(q);
                                                    }
                                                })
                                                resolve();
                                            }
                                        )
                                    })
                                }

                                // remove numbers from start of each option 
                                options.forEach(opt => {
                                    // some labels have different dashes after number: '-' or '–'
                                    let start = opt.label.indexOf('-') !== -1 ? opt.label.indexOf('-') : opt.label.indexOf('–') !== -1 ? opt.label.indexOf('–') : -1;
                                    opt.label = opt.label.substring(start + 1 , opt.label.length).trim();
                                });
                                
                                this.setState({ options: options.slice(0, 50) });
                                resolve(options.slice(0, 50));

                            }, 50);
                        })}
                        isDisabled={disabled}
                        inputValue={this.state.inputValue}
                        onInputChange={(val, action) => {
                            if (action.action === "input-change") {
                                this.setState({ inputValue: val });
                            }
                        }}
                        components={{ 
                            IndicatorSeparator: () => null,
                            DropdownIndicator: () => null,
                        }}
                        theme={theme => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: '#eeeeee',
                                primary: '#00336B'
                            }
                        })}
                        noOptionsMessage={optionsMessage}
                        styles={customStyles}
                        menuPlacement={this.props.menuPlacement || 'auto'}
                        value=''
                        onChange={selectedValue => {
                            const answerPrefix = selectedValue.value.replace('faq.q', 'faq.a');
                            const answers = this.state.faqMessages.filter(faq => faq.key.includes(answerPrefix));
                            this.setState({
                                modalOpen: true,
                                faqTitle: selectedValue.label,
                                faqBody: (
                                    <div>
                                        { answers.map((a, index) => (<span key={index}><FormattedMessage id={a.key} /><br/></span>)) }
                                    </div>
                                )
                                
                            });
                        }}
                        controlShouldRenderValue={false}
                        filterOption={() => true}
                        
                    />
                    <ReactModal
                        isOpen={this.state.modalOpen}
                        onRequestClose={() => this.setState({ modalOpen: false })}
                        className="modal-block dialog"
                        overlayClassName="modal-overlay gray"
                    >
                        <div className="modal-dialog modal-lg wd-450" role="document">
                            <div className="modal-content bd-0">
                                <div className="modal-header pd-x-20">
                                    <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                                        {this.state.faqTitle}
                                    </h6>
                                </div>
                                <div 
                                    className="modal-body pd-20"
                                    style={{maxHeight: '400px', overflowY: 'auto', overflowX: 'hidden'}}
                                >
                                    {this.state.faqBody}
                                </div>
                                <div className="modal-footer justify-content-center">
                                    <button
                                        type="button"
                                        className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                        onClick={() => this.setState({ modalOpen: false }) }
                                    >
                                        <FormattedMessage id="common.close" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ReactModal>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    const locale = state.localization.locale;
    return {
        allMessages: state.localization.messages,
        translations: state.localization[locale],
        locale,
    };
};
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            searchFaq
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(FaqSearch));
