import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import Page from 'components/page';
import LibraryDiscoverDashboard from './discover';
import { DefinedPermissions, canAccessPage } from 'components/routes/permissions/permissionResolver';

import '../dashboard.scss';

class LibraryMainPage extends React.Component {
    render() {
        const { user, company, permissions } = this.props;
        if (!user || !company || !permissions) return null;
        const isCompanyAdmin = canAccessPage(permissions, { access: { score: DefinedPermissions.CompanyRoot.score } });

        return (
            <Page
                {...LibraryMainPage.meta}
                // showTitleBlock={!!company.is_system}
                panel={false}
            >
                {/* {!!company.is_system ? <div /> : <LibraryDiscoverDashboard user={user} company={company} isCompanyAdmin={isCompanyAdmin} />} */}
                <LibraryDiscoverDashboard user={user} company={company} isCompanyAdmin={isCompanyAdmin} />
            </Page>
        );
    }
}

LibraryMainPage.meta = {
    title: <FormattedMessage id="page.library.title" defaultMessage="Library" />,
    subtitle: <FormattedMessage id="librarySubtitle" />,
    routes: ['/library'],
    icon: 'ion-ios-book',
    menus: { main: true, user: false },
    hideBreadcrumbs: true,
    order: 0,
    onlySubscribed: true
};

const mapStateToProps = state => ({
    user: state.identity.user,
    company: state.identity.company,
    permissions: state.identity.permissions
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LibraryMainPage);
