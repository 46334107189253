import React from 'react';
import { FormattedMessage } from 'react-intl';
import { call, hasErrors, upload } from '../configuration/api';
import { Notifications } from '../components/notifications';
import Queries from './queries';

const defaultState = {};

/**
 * Loading state mapper.
 * @param {object} state
 * @param {string} action
 */
export default (state = defaultState, action) => {
    switch (action.type) {
        default:
            return state;
    }
}

export const findMedia = (s3_object, s3_bucket = 'dev-dorae-media', onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Media.Find, variables: { s3_bucket, s3_object } });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.media_find) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.media_find);
};

export const uploadMedia = (files, onComplete = () => null, onError = () => null) => async dispatch => {
    if (!files || files.length < 1) return;
    const file = files[0];
    
    if (file.size > 20000000) {
        Notifications.error(<FormattedMessage id="error.fileTooLarge" />);
        onError();
        return;
    }
    
    const formData = new FormData();
    formData.append('json', JSON.stringify({ query: Queries.Media.Upload }));
    formData.append('file', file, file.name);

    const response = await upload(formData);
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.media_upload) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }

    onComplete(data.media_upload);
};

export const getMedia = (id, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Media.Get, variables: { media_id: id } });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.media) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.media);
};

// NEW
export const deleteMedia = (id, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Media.Delete, variables: { media_id: id } });
    if (hasErrors(response)) {
        onComplete();
        return;
    }
    
    const { data } = response;
    if (!data || !data.media_delete) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.media_delete);
};
