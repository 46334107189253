import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import FileSaver from 'file-saver';
import { call, hasErrors, upload } from '../configuration/api';
import Queries from './queries';
import { Notifications } from '../components/notifications';
import { 
    MODELS_GET_LIST, 
    FIELDS_GET_MODEL_PROPERTIES, 
    MODEL_PERSISTING, 
    FIELDS_UPDATE_MODEL, 
    MODELS_CREATE, 
    MODELS_GET_STATUSES,
    CARGO_CATEGORY_LIST, 
    EVENT_TEMPLATE_LIST,
} from './types';

const defaultState = {
    cargoCategoryList: [],
    cargoCategoryTotal: 0,
    eventTemplateList: [],
    eventTemplateTotal: 0,
    list: undefined,
    total: 0,
    statuses: undefined,
    modelIsSaving: false,
};

/**
 * Loading state mapper.
 * @param {object} state
 * @param {string} action
 */
export default function(state = defaultState, action) {
    let total, data;
    switch (action.type) {
        case MODELS_GET_LIST:
            ({ total, data } = action.payload)
            return { ...state, list: data, total: Number.parseInt(total) };

        case EVENT_TEMPLATE_LIST:
            ({ total, data } = action.payload)
            return { ...state, eventTemplateList: data, eventTemplateTotal: Number.parseInt(total) };

        case CARGO_CATEGORY_LIST:
            ({ total, data } = action.payload)
            return { ...state, cargoCategoryList: data, cargoCategoryTotal: Number.parseInt(total) };

        case MODELS_GET_STATUSES:
            return { ...state, statuses: action.data };

        case MODEL_PERSISTING:
            return { ...state, modelIsSaving: action.data}

        default:
            return state;
    }
}

export const getModels = (variables, onComplete = () => null, doDispatch = true) => async dispatch => {
    const response = await call({ query: Queries.Models.List, variables });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.model_list) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }
    if (doDispatch) {
        if (variables && variables.model_type) {
            if (variables.model_type === 1) {
                dispatch({ type: EVENT_TEMPLATE_LIST, payload: data.model_list });
            } else {
                dispatch({ type: CARGO_CATEGORY_LIST, payload: data.model_list });
            }
        }
        dispatch({ type: MODELS_GET_LIST, payload: data.model_list });
    }
    onComplete(data.model_list);
};

export const getModelsLight = (variables, onComplete = () => null, doDispatch = true) => async dispatch => {
    const response = await call({ query: Queries.Models.ListLight, variables });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.model_list) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }
    if (doDispatch) {
        if (variables && variables.model_type) {
            if (variables.model_type === 1) {
                dispatch({ type: EVENT_TEMPLATE_LIST, payload: data.model_list });
            } else {
                dispatch({ type: CARGO_CATEGORY_LIST, payload: data.model_list });
            }
        }
        dispatch({ type: MODELS_GET_LIST, payload: data.model_list });
    }
    onComplete(data.model_list);
}

export const createModel = (model, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Models.Create, variables: model });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.model_create) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }

    dispatch({ type: MODELS_CREATE, payload: data.model_create });
    dispatch({ type: FIELDS_GET_MODEL_PROPERTIES, data: data.model_create });
    onComplete(data.model_create);
};

export const deleteModel = (id, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Models.Delete, variables: { model_id: id } });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.model_delete) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }

    Notifications.success(<FormattedMessage id="model.deleted" />);
    onComplete(data.model_delete);
};

export const loadModel = (id, onComplete = () => null, onError = () => null, doDispatch = true) => async dispatch => {
    const response = await call({ query: Queries.Models.Get, variables: { model_id: id } });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.model) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }
    if (doDispatch) {
        dispatch({ type: FIELDS_GET_MODEL_PROPERTIES, data: data.model });
    }
    onComplete(data.model);
};

export const persistModelChanges = (model, onComplete = () => null, onError = () => null) => async dispatch => {
    dispatch({ type: MODEL_PERSISTING, data: true });
    const response = await call({ query: Queries.Models.Update, variables: { model_id: model.id, ...model } });
    dispatch({ type: MODEL_PERSISTING, data: false });

    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.model_update) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }

    dispatch({ type: FIELDS_UPDATE_MODEL, model, persisted: true });
    onComplete(data.model_update);
};

export const getModelStatuses = (onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Models.GetStatuses });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.model_status_list) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }

    dispatch({ type: MODELS_GET_STATUSES, data: data.model_status_list });
    onComplete(data.model_status_list);
};

export const importModel = (details, files, onComplete = () => null, onError = () => null) => async dispatch => {
    if (!files || files.length < 1) return;
    const file = files[0];
    const formData = new FormData();
    formData.append('json', JSON.stringify({ query: Queries.Models.Import, variables: { ...(details || {}) } }));
    formData.append('file', file, file.name);

    const response = await upload(formData);
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.model_import) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }

    if (data.model_import.model_type_name === 'event template') {
        Notifications.success(<FormattedHTMLMessage id="eventTemplate.imported" values={{ title: data.model_import.title || data.model_import.id }} tagName="span" />);
    } else {
        Notifications.success(<FormattedHTMLMessage id="cargoCategory.imported" values={{ title: data.model_import.title || data.model_import.id }} tagName="span" />);
    }
    onComplete(data.model_import);
};

export const exportModel = (model, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Models.Export, variables: { model_id: model.id } });
    if (!response) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    var blob = new Blob([JSON.stringify(response)], { type: 'text/plain;charset=utf-8' });
    FileSaver.saveAs(blob, `${model.title || model.id}.dorml`);
    onComplete(response);
};

export const cloneModel = (model, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Models.Clone, variables: { model_id: model.id } });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.model_clone) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }
    Notifications.success(<FormattedHTMLMessage id={(model.model_type_name === 'cargo category') ? 'cargoCategory.cloned' : 'eventTemplate.cloned'} values={{ title: data.model_clone.title || data.model_clone.id }} tagName="span" />);
    onComplete(data.model_clone);
};

export const setModelUsers = (variables, onComplete = () => null, onError = () => null, doDispatch = true) => async dispatch => {
    const response = await call({ query: Queries.Models.SetUsers, variables });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.model_set_users) {
        Notifications.error(<FormattedMessage id="error.default" />);
        onError();
        return;
    }
    if (doDispatch) {
        dispatch({ type: FIELDS_GET_MODEL_PROPERTIES, data: data.model_set_users });
    }
    onComplete(data.model);
};
