import React from 'react';
import 'pages/models/models.scss';
import { FormattedMessage } from 'react-intl';

class FieldReferenceInfo extends React.Component {
    render() {
        const { fieldRef } = this.props;
        if (!fieldRef) return null;

        const { literal, label, type, token, field, index } = fieldRef;
        const fieldCategoryIsModel = type === 'model';
        let category;
        if (type === 'model') {
            category = <FormattedMessage id='dashTemplate' />;
        } else if (type === 'index') {
            category = <FormattedMessage id='index' />;
        } else {
            category = <FormattedMessage id='dot' />;
        }
        let otherFields, header;
        if (type === 'model') {
            const tokenParts = token.split('.');
            const fieldId = tokenParts[tokenParts.length - 1];
            if (fieldId.indexOf('l') > -1) {
                header = (
                    <React.Fragment>
                        <span>
                            <strong><FormattedMessage id='label' />: </strong> {label}
                        </span>
                        <br/>
                        <span>
                            <strong><FormattedMessage id='category' />: </strong> {category}
                        </span>
                        <br/>
                        <span>
                            <strong><FormattedMessage id='type' />: </strong> {fieldId === 'l1' ? <FormattedMessage id='dotQuantity' /> : <FormattedMessage id='dotUnitName' /> }
                        </span>
                    </React.Fragment>
                );
                otherFields = (
                    <React.Fragment>
                        <span>
                            <strong><FormattedMessage id='fieldTitle' />: </strong> {field.title}
                        </span>
                        <br/>
                        <span>
                            <strong><FormattedMessage id='dashTemplate' />: </strong> {field.model.title}
                        </span>
                    </React.Fragment>
                );
            } else {
                header = (
                    <React.Fragment>
                        <span>
                            <strong><FormattedMessage id='label' />: </strong> {label}
                        </span>
                        <br/>
                        <span>
                            <strong><FormattedMessage id='category' />: </strong> {category}
                        </span>
                        <br/>
                        <span>
                            <strong><FormattedMessage id='type' />: </strong> <FormattedMessage id={field.type_name} />
                        </span>
                    </React.Fragment>
                );
                if (field && field.model) {
                    otherFields = field.model.model_type_name === 'event template' ?
                        <React.Fragment>
                            <span>
                                <strong><FormattedMessage id='fieldTitle' />: </strong> {field.title}
                            </span>
                            <br/>
                            <span>
                                <strong><FormattedMessage id='dashTemplate' />: </strong> {field.model.title}
                            </span>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <span>
                                <strong><FormattedMessage id='fieldTitle' />: </strong> {field.title}
                            </span>
                            <br/>
                            <span>
                                <strong><FormattedMessage id='dotTemplate' />: </strong> {field.model.title}
                            </span>
                        </React.Fragment>;
                }
            }
        } else if (type === 'index') {
            header = (
                <React.Fragment>
                    <span>
                        <strong><FormattedMessage id='label' />: </strong> {label}
                    </span>
                    <br/>
                    <span>
                        <strong><FormattedMessage id='category' />: </strong> {category}
                    </span>
                    <br/>
                    <span>
                        <strong><FormattedMessage id='type' />: </strong> <FormattedMessage id={field.type_name} />
                    </span>
                </React.Fragment>
            );
            otherFields = <React.Fragment>
                <span>
                    <strong><FormattedMessage id='tagTitle' />: </strong> {field.title}
                </span>
                <br/>
                <span>
                    <strong><FormattedMessage id='tagTarget' />: </strong> {field.model_type === 1 ? <FormattedMessage id='dashTemplate' /> : <FormattedMessage id='dotTemplate' />}
                </span>
            </React.Fragment>
        } else {
            header = (
                <React.Fragment>
                    <span>
                        <strong><FormattedMessage id='label' />: </strong> {label}
                    </span>
                    <br/>
                    <span>
                        <strong><FormattedMessage id='category' />: </strong> {category}
                    </span>
                </React.Fragment>
            );
            otherFields = <React.Fragment>
                <span>
                    <strong><FormattedMessage id='dotTemplateTitle' />: </strong> { field ? field.title : '' }
                </span>
            </React.Fragment>
        }

        return (
            <React.Fragment key={literal}>
                <div className="form-group col-12 pd-15">
                    {header}
                    <br/>
                    {otherFields}
                </div>
            </React.Fragment>
        );
    }
}

export default FieldReferenceInfo;
