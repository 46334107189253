import { Map } from 'immutable';

export const DefinedPermissions = {
    // admin company level
    SuperUser: { name: 'ROOT', score: 100000 },
    CompanySwitch: { name: 'COMPANY_VIEW', score: 20000 },
    Impersonate: { name: 'IMPERSONATE', score: 20000 },

    // standard company level
    CompanyRoot: { name: 'COMPANY_ROOT', score: 10000 },
    RoleCrud: { name: 'ROLE_CRUD', score: 2000 },
    UserCrud: { name: 'USER_CRUD', score: 2000 },
    CompanyEdit: { name: 'COMPANY_EDIT', score: 2000 },
    CargoCrud: { name: 'CARGO_CRUD', score: 2000 },
    ModelCrud: { name: 'MODEL_CRUD', score: 2000 },
    TransactionCrud: { name: 'TX_CRUD', score: 2000 }
};

export const canAccessPage = (permissions, pageMeta) => {
    if (!pageMeta) return true;

    // no access parameters set (free for all)
    const { access } = pageMeta;
    if (!access) return true;

    // access parameters set, but no score required (free for all)
    const { score, name } = access;
    if (!score) return true;

    // no permissions assigned
    if (!permissions || permissions.length === 0) return false;

    // user has exact permission
    if (!!permissions.find(p => p.code === name)) return true;

    // user has permissions of higher rank so required permission is implied.
    const definedPermissionsMap = Map(DefinedPermissions);
    const maxScore = Math.max(...permissions.map(p => (definedPermissionsMap.find(dp => dp.name === p.code) || { score: -1 }).score));
    if (score <= maxScore) return true;

    return false;
};

export const canInteract = (permissions = {}, entityAction = []) => {
    if (!entityAction || entityAction.length < 2) return true;

    if (entityAction.length === 2) {
        const entity = entityAction[0].toUpperCase();
        const action = entityAction[1].toUpperCase();

        return !!permissions[entity] && !!permissions[entity][action];
    }

    const entity = entityAction[0].toUpperCase();
    const actions = entityAction.slice(1, entityAction.length).map(a => a.toUpperCase());
    const actionResult = actions.every(action => !!permissions[entity] && !!permissions[entity][action]);
    return !!permissions[entity] && !!actionResult;
};
