import React from 'react';
import { Fields } from '../../../../components/fields';
import { FormattedMessage } from 'react-intl';
import SimpleBar from 'simplebar-react';
import ReactModal from 'react-modal';
import { Formik, Form } from 'formik';
import { uuid } from '../../../../utilities/common';
import { FieldTypes } from '../../../../models/field';
import FieldPreview from '../components/fieldPreview';
import ModelProperties from '../definitions/modelProperties';
import PrintContainer from '../../../../components/print';


class CargoField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getGroupedSections = () => {
        const { fields } = this.props;
        if (!fields || fields.length === 0) return [];

        const sections = fields.filter(f => f.type === FieldTypes.FIELD_SECTION);
        if (sections.length === 0) return fields;

        const nonSections = fields.filter(f => f.type !== FieldTypes.FIELD_SECTION);
        if (nonSections.length === 0) return fields;

        const heading = [...nonSections.filter(f => f.order <= sections[0].order)];

        const grouped = sections.map((section, index) => {
            let afterSection = nonSections.filter(f => f.order > section.order);
            if (sections.length > index + 1) {
                afterSection = afterSection.filter(f => f.order <= sections[index + 1].order);
            }
            return { ...section, children: afterSection.map(f => f.id) };
        });

        return [...heading, ...grouped];
    };

    renderPreviewBlock = () => {
        const { fields, modelId, comparators, modelTypeId } = this.props;
        if (!comparators) return null;

        const groupedFields = this.getGroupedSections();

        return (
            <div className="fields-preview widget-2 mg-b-20">
                <div className="card">
                    <div className="card-header">
                        <h6 className="card-title">
                            <FormattedMessage id="common.preview" />
                        </h6>
                    </div>
                    <div className="card-body pd-0 bd-color-gray-lighter">
                        <div className="row no-gutters">
                            <div className="col-12">
                                <SimpleBar>
                                    <Formik
                                        key={uuid()}
                                        enableReinitialize={true}
                                        initialValues={this.getInitialValues()}
                                        validationSchema={this.getValidationSchema()}
                                        onSubmit={() => null}
                                        render={formProps => (
                                            <Form noValidate>
                                                <div className="field-preview-wrapper">
                                                    <ModelProperties id={modelId} />
                                                    <div className={`field-text pd-b-15 col-12`}>
                                                        <Fields.Input name="title" />
                                                    </div>
                                                    <div className={`field-text pd-b-30 col-12`}>
                                                        <Fields.Input name="description" />
                                                    </div>
                                                    {groupedFields.map(f => (
                                                        <FieldPreview key={f.id} field={f} fields={fields} formProps={formProps} comparators={comparators} modelTypeId={modelTypeId} />
                                                    ))}
                                                    <div className="col-12">
                                                        <div className="row d-flex justify-content-center align-items-center pd-y-15 mg-b-15">
                                                            <div className="col-sm-6 col-md-3">
                                                                <button type="submit" className="btn btn-primary btn-block" disabled={true}>
                                                                    <FormattedMessage id="common.save" />
                                                                </button>
                                                            </div>
                                                            <div className="col-sm-6 col-md-3">
                                                                <button type="submit" className="btn btn-light btn-block" disabled={true}>
                                                                    <FormattedMessage id="common.cancel" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    />
                                </SimpleBar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    
    render() {
        const { fullscreen, print } = this.state;
        const { fields, modelId, comparators, modelTypeId } = this.props;
        if (!comparators) return null;

        const groupedFields = this.getGroupedSections();

        return (
            <React.Fragment>
                {!fullscreen && this.renderPreviewBlock()}
                <ReactModal
                    isOpen={fullscreen}
                    onRequestClose={() => this.setFullscreen(false)}
                    className="modal-block full model-preview-modal"
                    overlayClassName="modal-overlay full"
                >
                    {fullscreen && this.renderPreviewBlock()}
                </ReactModal>
                <PrintContainer open={print} onClose={() => this.setPrint(false)}>
                    <Formik
                        key={uuid()}
                        enableReinitialize={true}
                        initialValues={this.getInitialValues()}
                        validationSchema={this.getValidationSchema()}
                        onSubmit={() => null}
                        render={formProps => (
                            <Form noValidate>
                                <div className="field-preview-wrapper">
                                    <ModelProperties id={modelId} />
                                    <div className={`field-text pd-b-15 col-12`}>
                                        <Fields.Input name="title" />
                                    </div>
                                    <div className={`field-text pd-b-30 col-12`}>
                                        <Fields.Input name="description" />
                                    </div>
                                    {groupedFields.map(f => (
                                        <FieldPreview key={f.id} field={f} fields={fields} formProps={formProps} comparators={comparators} modelTypeId={modelTypeId} />
                                    ))}
                                    <div className="col-12">
                                        <div className="row d-flex justify-content-center align-items-center pd-y-15 mg-b-15">
                                            <div className="col-sm-6 col-md-3">
                                                <button type="submit" className="btn btn-primary btn-block">
                                                    <FormattedMessage id="common.save" />
                                                </button>
                                            </div>
                                            <div className="col-sm-6 col-md-3">
                                                <button type="submit" className="btn btn-light btn-block">
                                                    <FormattedMessage id="common.cancel" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    />
                </PrintContainer>
            </React.Fragment>
        );
    }
}

export default CargoField;
