import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import ReactModal from 'react-modal';
import Terms from '../../pages/account/forms/presubmission/terms';
import { Popover, Menu, MenuItem, } from '@blueprintjs/core';
import { setLocale } from 'reducers/localization';
import ReactCountryFlag from "react-country-flag"

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: { open: false }
        };
    }

    setModalVisibility = open => this.setState({ ...this.state, modal: { open } });

    shouldHideFooter = () => {
        const { authenticated, location } = this.props;
        if (!authenticated || location.pathname.indexOf('shared-invite') > -1 || location.pathname === '/mfa' || location.pathname.startsWith('/visualize/')) return true;
        if (location && location.pathname && location.pathname.toLowerCase().startsWith('/models/')) return true;

        return false;
    };

    render() {
        const { setLocale } = this.props;
        const { modal } = this.state;
        if (this.shouldHideFooter()) return null;

        return (
            <React.Fragment>
                <footer className="br-footer">
                    <div className="footer-left">
                        <span>
                            <FormattedMessage id="footer.copyrights" values={{ year: moment().year() }} />
                        </span>
                        <span className="pd-x-15">|</span>
                        <span className="clickable" onClick={() => this.setModalVisibility(true)}>
                            <FormattedMessage id="common.termsAndConditions" />
                        </span>
                        <span className="pd-x-15">|</span>
                        <Popover
                            // usePortal={false}
                            position="top"
                            content={
                                <Menu>
                                    <MenuItem
                                        text={
                                            <div>
                                                <ReactCountryFlag countryCode="US" svg />
                                                &nbsp;
                                                &nbsp;
                                                English
                                            </div>
                                        }
                                        onClick={() => setLocale('en', true)}
                                    />
                                    <MenuItem
                                        text={
                                            <div>
                                                <ReactCountryFlag countryCode="PT" svg />
                                                &nbsp;
                                                &nbsp;
                                                Português
                                            </div>
                                        }
                                        onClick={() => setLocale('pt', true)}
                                    />
                                </Menu>
                            }
                        >
                            <span className="clickable">
                                &#127757; &nbsp;
                                <FormattedMessage id="language.current" />
                            </span>
                        </Popover>
                    </div>
                </footer>
                <ReactModal
                    isOpen={modal.open}
                    onRequestClose={() => this.setModalVisibility(false)}
                    className="modal-block dialog"
                    overlayClassName="modal-overlay gray modal-overlay-account"
                >
                    <Terms onCancel={() => this.setModalVisibility(false)} />
                </ReactModal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    authenticated: state.identity.authenticated,
    location: state.router.location
});
const mapDispatchToProps = dispatch => bindActionCreators({
    setLocale,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Footer);
