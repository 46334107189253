import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class Loading extends React.Component {
    render() {
        const { loading, message } = this.props;
        if (!loading) return null;

        return (
            <div className="loading-indicator">
                <div className="loader" />
                <span className="mg-l-5">{message}</span>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading.loading,
        message: state.loading.message
    };
};
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Loading);
