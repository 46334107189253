import React from 'react';
import { FormattedMessage } from 'react-intl';
import { call, hasErrors } from '../configuration/api';
import Queries from './queries';
import { Notifications } from '../components/notifications';

export const createErrorReport = (input, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.ErrorReport.Create, variables: { error_data: input } });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.error_reporting_create) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    onComplete(data.error_reporting_create);
};