export const GET_DOCUMENTS = `
    query($transaction_ids: [Id!], $document_template_ids: [Long!]) {
        document_list(input: { transaction_ids: $transaction_ids, document_template_ids: $document_template_ids }) {
            total
            data {
                id
                name
                url
                s3_bucket
	    		s3_object
            }
        }
    }
`;
