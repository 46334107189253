export const FIELD_VALUE_DELETE = `
    mutation M($value_id: Long!) {
        model_field_value_delete(value_id: $value_id) {
            id
            company_id
            field_id
            order_by
            is_hidden
            value
        }
    }
`;
