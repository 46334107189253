import React from 'react';
import { call, hasErrors } from '../configuration/api';
import Queries from './queries';
import { FormattedMessage } from 'react-intl';
import { Notifications } from '../components/notifications';

const LIST_SHARED_ENTITIES = 'LIST_SHARED_ENTITIES';

const defaultState = {
    sharedEntitiesList: [],
};

/**
 * Document state mapper.
 * @param {object} state
 * @param {string} action
 */
export default function(state = defaultState, action) {
    switch (action.type) {
        case LIST_SHARED_ENTITIES:
            return { ...state, sharedEntitiesList: action.data };

        default:
            return state;
    }
}

export const respondToSharingInvite = (input, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Sharing.RespondToShareInvite, variables: input });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.external_sharing_respond_to_invite) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }
    onComplete(data.external_sharing_respond_to_invite);
};

export const listSharedEntities = (input, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Sharing.ListSharedEntities, variables: input });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.external_sharing_list) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }
    dispatch({ type: LIST_SHARED_ENTITIES, data: data.external_sharing_list });
    onComplete(data.external_sharing_list);
};

export const createSharedEntity = (input, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Sharing.CreateSharedEntity, variables: input });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.external_sharing_create) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }
    onComplete(data.external_sharing_create);
};

export const deleteSharedEntity = (input, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Sharing.DeleteSharedEntity, variables: input });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.external_sharing_delete) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }
    onComplete(data.external_sharing_delete);
};

export const forfeitSharedEntity = (input, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.Sharing.ForfeitSharedEntity, variables: input });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.external_sharing_forfeit) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }
    onComplete(data.external_sharing_forfeit);
};