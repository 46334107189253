import React from 'react';
import YesNoNull from './uncontrolledComponents/yesNoNull'

class YesNoNullField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.initialValue
        };
    }
    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.initialValue !== prevProps.initialValue) {
          this.setState({ value: this.props.initialValue });
        }
    }
    render() {
        const {
            name,
            placeholder,
            submitOnChange,
            disabled = false,
            onChangeExt = () => null,
            tooltipOverlay,
            seleniumId,
            setFieldValue,
            rerenderOnChange,
        } = this.props;

        if (!setFieldValue) return null;
        const { value } = this.state;

        return (             
                <YesNoNull 
                    placeholder={placeholder} 
                    submitOnChange={submitOnChange} 
                    name={name} 
                    disabled={disabled} 
                    value={value}
                    onChange={async value => {
                        if (disabled) return;
                        await setFieldValue(name, value);
                        onChangeExt(name, value)
                        if (rerenderOnChange) {
                            this.setState({ value });
                        }
                    }}
                    tooltipOverlay={tooltipOverlay}                    
                    seleniumId={seleniumId}
                    showIsRequired={this.props.showIsRequired}                
                />                            
        );
    }
}

export default YesNoNullField;
