export const GET_SHARED_ENTITIES = `
    query (
        $entity_type	: String!
        $entity_id      : Long!
    )  
    {
        external_sharing_list(
            input: {
                entity_type             : $entity_type      
                entity_id		        : $entity_id           
            }
        )
        {
            id              
            entity_type       
            entity_id     
            target_email      
            include_transactions
            accepted
        }
    }
`;