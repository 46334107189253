export const CONDITIONS_DELETE = `
    mutation M($condition_id: Int!) {
        alert_condition_delete(condition_id: $condition_id) {
            id
            type_id
            comparator_id
            alert_id
            order
            break_or
            template {
                id
                title
            }
            field {
                id
                title
            }
            object_type {
                id
                title
            }
            index_field {
                id
                title
            }
            dot_field
        }
    }
`;
