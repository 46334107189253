export const MEDIA_DELETE = `
mutation M($media_id: Long!) {
    media_delete(media_id: $media_id) {
            id
            created_on
            type_id
            type_name
            content_type
            is_ready
            user_file_name
        }
    }
`;
