import React from 'react';
import { call, hasErrors } from '../configuration/api';
import Queries from './queries';
import { FormattedMessage } from 'react-intl';
import { Notifications } from '../components/notifications';

const REQUEST_ENTITY_LOCK = 'REQUEST_ENTITY_LOCK';

const defaultState = {
    lockedEntity: {},
};

/**
 * Document state mapper.
 * @param {object} state
 * @param {string} action
 */
export default function(state = defaultState, action) {
    switch (action.type) {
        case REQUEST_ENTITY_LOCK:
            const entityLock = action.data;
            if (
                state.lockedEntity.entity_hash_id === entityLock.entity_hash_id
                && state.lockedEntity.entity_id === entityLock.entity_id
                && state.lockedEntity.entity_type === entityLock.entity_type
                && state.lockedEntity.user_email === entityLock.user_email
                && state.lockedEntity.user_id === entityLock.user_id
                && state.lockedEntity.user_name === entityLock.user_name
            ) {
                return state;
            }
            return { ...state, lockedEntity: action.data };

        default:
            return state;
    }
}

export const requestEntityLock = (input, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.EntityLocks.RequestEntityLock, variables: input });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.entity_lock_request_lock) {
        console.error('Error requesting entity lock!', data);
        return;
    }
    dispatch({ type: REQUEST_ENTITY_LOCK, data: data.entity_lock_request_lock })
    onComplete(data.entity_lock_request_lock);
};

export const releaseEntityLock = (input, onComplete = () => null, onError = () => null) => async dispatch => {
    const response = await call({ query: Queries.EntityLocks.ReleaseEntityLock, variables: input });
    if (hasErrors(response)) {
        onError();
        return;
    }

    const { data } = response;
    if (!data || !data.entity_lock_release_lock) {
        console.error('Error releasing entity lock!', data);
        return;
    }
    dispatch({ type: REQUEST_ENTITY_LOCK, data: {} })
    onComplete(data.entity_lock_release_lock);
};