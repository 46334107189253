import React from 'react';
import { Formik, Form } from 'formik';
import SimpleBar from 'simplebar-react';
import { Fields } from '../../../../components/fields';
import { uuid } from '../../../../utilities/common';
import { FieldTypes, FieldComparatorTypes, getComparatorSymbol } from '../../../../models/field';
import { injectIntl, FormattedMessage } from 'react-intl';
import { getCurrencyOptions } from '../../../../utilities/constants';
import { getComparators, setVisibilityDialog, getFieldValues } from 'reducers/fieldVisibility';
import Select from 'react-select';
import Tooltip from 'rc-tooltip';

class FieldFilters extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: props.filters || []
        };
    }

    handleSubmit = (values, form) => {
        if (!values.field) return;
        if (values.comparator === 8){
            values.filterValue = values.filterValueArray || [];
        }
        let previousEntry = this.state.list.find(l => l.id === values.id);
        let list = [];
        if (!!previousEntry){
            list = [...this.state.list.map(l => (l.id === values.id ? { ...l, ...values } : l))]
        } else {
            list = [...this.state.list, values];
        }

        this.setState({ list });
    };

    createFilterInstance = () => {
        return { id: uuid(8), filterValue: '', break_or: false, is_not: false };
    };

    removeFilter = id => {
        const { list } = this.state;
        this.setState({ list: [...list.filter(l => l.id !== id)] });
    };

    addNewFilter = (break_or = false) => {
        const { list } = this.state;
        this.setState({ list: [...list, { id: uuid(8), break_or }] });
    };

    getComparators = (values, showSelectOnTop = false) => {
        const { comparators, indexValues, loadIndexValues } = this.props;
        const { field, comparator } = values;
        if (!field) return [];
        
        const allFields = this.getAvailableFields();

        const {
            NONE,
            EQUALS,
            GREATER,
            LESS,
            GREATER_EQUAL,
            LESS_EQUAL,
            NOT_EQUAL,
            BETWEEN,
            CONTAINS,
            IN,
            LIKE,
            IS_NULL,
            DOES_NOT_CONTAIN,
            NOT_LIKE
        } = FieldComparatorTypes;

        const indexField = allFields.find(f => f.id === field) || {};
        let comparatorInputs = [];
        switch (indexField.type_name) {
            case FieldTypes.FIELD_TEXT:
                const textInput = (
                    <div className="col-4 pd-l-0" key={`${indexField.type_name}-${comparator}`}>
                        <Fields.Input name="filterValue" type="text" value={values.filterValue} />
                    </div>
                );
                comparatorInputs = [
                    { comparator: NONE, input: null },
                    { comparator: IS_NULL, input: null },
                    { comparator: EQUALS, input: textInput },
                    { comparator: NOT_EQUAL, input: textInput },
                    { comparator: LIKE, input: textInput },
                    { comparator: NOT_LIKE, input: textInput }
                ];
                break;

            case FieldTypes.FIELD_NUMBER:
                const numberInput = (
                    <div className="col-4 pd-l-0" key={`${indexField.type_name}-${comparator}`}>
                        <Fields.Input name="filterValue" type="number" value={values.filterValue} />
                    </div>
                );
                comparatorInputs = [
                    { comparator: NONE, input: null },
                    { comparator: IS_NULL, input: null },
                    { comparator: EQUALS, input: numberInput },
                    { comparator: NOT_EQUAL, input: numberInput },
                    { comparator: GREATER, input: numberInput },
                    { comparator: GREATER_EQUAL, input: numberInput },
                    { comparator: LESS_EQUAL, input: numberInput },
                    { comparator: LESS, input: numberInput },
                    {
                        comparator: BETWEEN,
                        input: (
                            <div className="col-5 pd-l-0" key={`${indexField.type_name}-${comparator}`}>
                                <div className="row">
                                    <div className="col-5 pd-r-0">
                                        <Fields.Input
                                            name="filterValueArray[0]"
                                            type="number"
                                            value={
                                                values.filterValueArray && Array.isArray(values.filterValueArray) && values.filterValueArray.length > 1
                                                    ? values.filterValueArray[0]
                                                    : ''
                                            }
                                        />
                                    </div>
                                    <div className="col-2 pd-x-0">
                                        <div className="field-inline-separator">
                                            <FormattedMessage id="common.and" />
                                        </div>
                                    </div>
                                    <div className="col-5 pd-l-0">
                                        <Fields.Input
                                            name="filterValueArray[1]"
                                            type="number"
                                            value={
                                                values.filterValueArray && Array.isArray(values.filterValueArray) && values.filterValueArray.length > 1
                                                    ? values.filterValueArray[1]
                                                    : ''
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    }
                ];
                break;
            case FieldTypes.FIELD_CURRENCY:
                const currencyInput = (
                    <div className="col-4 pd-l-0" key={`${indexField.type_name}-${comparator}`}>
                        <Fields.Select 
                            name="filterValue" 
                            options={getCurrencyOptions()} 
                            submitOnChange={true} 
                            value={values.filterValue} 
                            menuPlacement={showSelectOnTop ? 'top' : 'auto'}
                        />
                    </div>
                );
                comparatorInputs = [
                    { comparator: EQUALS, input: currencyInput },
                    { comparator: NOT_EQUAL, input: currencyInput },
                ];
                break;

            case FieldTypes.FIELD_YES_NO:
                const booleanInput = (
                    <div className="col-4 pd-l-0" key={`${indexField.type_name}-${comparator}`}>
                        <Fields.Select
                            name="filterValue"
                            value={values.filterValue}
                            submitOnChange={true}
                            options={[
                                { value: 1, label: <FormattedMessage id="common.yes" /> }, 
                                { value: 0, label: <FormattedMessage id="common.no" /> },
                                { value: null, label: <FormattedMessage id="common.n/a" /> },
                            ]}
                            menuPlacement={showSelectOnTop ? 'top' : 'auto'}
                        />
                    </div>
                );
                comparatorInputs = [
                    { comparator: EQUALS, input: booleanInput }
                ];
                break;

            case FieldTypes.FIELD_DATE:
                const dateInput = (
                    <div className="col-4 pd-l-0" key={`${indexField.type_name}-${comparator}`}>
                        <Fields.Date submitOnChange={true} name="filterValue" value={values.filterValue} />
                    </div>
                );
                comparatorInputs = [
                    { comparator: NONE, input: null },
                    { comparator: IS_NULL, input: null },
                    { comparator: EQUALS, input: dateInput },
                    { comparator: NOT_EQUAL, input: dateInput },
                    { comparator: GREATER, input: dateInput },
                    { comparator: GREATER_EQUAL, input: dateInput },
                    { comparator: LESS_EQUAL, input: dateInput },
                    { comparator: LESS, input: dateInput },
                    {
                        comparator: BETWEEN,
                        input: (
                            <div className="col-5 pd-l-0" key={`${indexField.type_name}-${comparator}`}>
                                <div className="row">
                                    <div className="col-5 pd-r-0">
                                        <Fields.Date 
                                            submitOnChange={true} 
                                            name="filterValueArray[0]" 
                                            value={ 
                                                (values.filterValueArray && Array.isArray(values.filterValueArray) && values.filterValueArray.length > 1) ? 
                                                values.filterValueArray[0]
                                                : 
                                                ''
                                            }
                                        />
                                    </div>
                                    <div className="col-2 pd-x-0">
                                        <div className="field-inline-separator">
                                            <FormattedMessage id="common.and" />
                                        </div>
                                    </div>
                                    <div className="col-5 pd-l-0">
                                        <Fields.Date 
                                            submitOnChange={true} 
                                            name="filterValueArray[1]" 
                                            value={ 
                                                (values.filterValueArray && Array.isArray(values.filterValueArray) && values.filterValueArray.length > 1) ? 
                                                values.filterValueArray[1]
                                                : 
                                                ''
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    }
                ];
                break;

            case FieldTypes.FIELD_SELECT:
                const selectInput = multi => (
                    <div className="col-4 pd-l-0" key={`${indexField.type_name}-${comparator}`}>
                        <Fields.AsyncSelect
                            name="filterValue"
                            value={values.filterValue}
                            submitOnChange={true}
                            multi={multi}
                            options={indexValues && indexValues[field] ? indexValues[field].map(i => ({ value: i.value, label: i.value })) : undefined}
                            optionsLoader={s => loadIndexValues(field, s, () => null, r => r.map(i => ({ value: i.value, label: i.value })))}
                            menuPlacement={showSelectOnTop ? 'top' : 'auto'}
                        />
                    </div>
                );
                comparatorInputs = [
                    { comparator: NONE, input: null },
                    { comparator: IS_NULL, input: null },
                    { comparator: EQUALS, input: selectInput(false) },
                    { comparator: NOT_EQUAL, input: selectInput(false) },
                    { comparator: IN, input: selectInput(true) }
                ];
                break;

            case FieldTypes.FIELD_MULTI_SELECT:
                const multiSelectInput = multi => (
                    <div className="col-4 pd-l-0" key={`${indexField.type_name}-${comparator}`}>
                        <Fields.AsyncSelect
                            name="filterValue"
                            value={values.filterValue}
                            submitOnChange={true}
                            multi={multi}
                            options={indexValues && indexValues[field] ? indexValues[field].map(i => ({ value: i.value, label: i.value })) : undefined}
                            optionsLoader={s => loadIndexValues(field, s, () => null, r => r.map(i => ({ value: i.value, label: i.value })))}
                            menuPlacement={showSelectOnTop ? 'top' : 'auto'}
                        />
                    </div>
                );
                comparatorInputs = [
                    { comparator: NONE, input: null },
                    { comparator: IS_NULL, input: null },
                    { comparator: CONTAINS, input: multiSelectInput(false) },
                    { comparator: DOES_NOT_CONTAIN, input: multiSelectInput(false) }
                ];
                break;

            case FieldTypes.FIELD_MULTI_TEXT:
                    const multiTextInput = (
                        <div className="col-4 pd-l-0" key={`${indexField.type_name}-${comparator}`}>
                            <Fields.Input name="filterValue" type="text" value={values.filterValue} />
                        </div>
                    );
                comparatorInputs = [
                    { comparator: NONE, input: null },
                    { comparator: IS_NULL, input: null },
                    {
                        comparator: CONTAINS,
                        input: multiTextInput
                    },
                    {
                        comparator: DOES_NOT_CONTAIN,
                        input: multiTextInput
                    }
                ];
                break;

            case FieldTypes.FIELD_ATTACHMENT:
                comparatorInputs = [
                    { comparator: NONE, input: null },
                    { comparator: IS_NULL, input: null },
                ];
                break;

            case FieldTypes.FIELD_CARGO:
                comparatorInputs = [
                    { comparator: NONE, input: null },
                    { comparator: IS_NULL, input: null },
                ];
                break;

            default:
                break;
        }

        return comparatorInputs.map(ci => ({
            ...ci,
            comparator: { value: (comparators.find(c => c.comparator_name === ci.comparator) || {}).id, label: getComparatorSymbol(ci.comparator) }
        }));
    };

    getAvailableFields = (isGroupedFieldFilter = false) => {
        const { fields, selectedModelIds, availableModels, allModels = [] } = this.props;

        let fieldsInSelectedModels = fields;

        if (selectedModelIds && selectedModelIds.length > 0) {
            const selectedModels = availableModels.filter(m => selectedModelIds.includes(m.id));
            fieldsInSelectedModels = selectedModels.map(m => m.model_field_list).flat().filter(f => f.type_name !== FieldTypes.FIELD_SECTION && f.type_name !== FieldTypes.FIELD_CARGO);
            let dotFieldsInDash = selectedModels.map(m => m.model_field_list).flat().filter(f => f.type_name === FieldTypes.FIELD_CARGO);
            if (this.state.filterModels && this.state.filterModels.length > 0) {
                const filteredModelIds = this.state.filterModels.map(m => m.value);
                fieldsInSelectedModels = fieldsInSelectedModels.filter(f => filteredModelIds.includes(f.model_id));
                dotFieldsInDash = selectedModels.filter(m => filteredModelIds.includes(m.id)).map(m => m.model_field_list).flat().filter(f => f.type_name === FieldTypes.FIELD_CARGO);
            }
            let referencedDotTypes = allModels.filter(m => dotFieldsInDash.some(f => f.cargoCategory === m.id));
            
            // remove duplicate templates
            const unique = [];
            const map = new Map();
            for (const item of referencedDotTypes) {
                if(!map.has(item.id)){
                    map.set(item.id, true);
                    unique.push(item);
                }
            }
            referencedDotTypes = unique;

            // remove Sections from field list
            const containedDotFields = referencedDotTypes.map(m => m.model_field_list).flat().filter(f => f.type_name !== FieldTypes.FIELD_SECTION);

            fieldsInSelectedModels = fieldsInSelectedModels.concat(containedDotFields);

            // Group fields by template
            if (isGroupedFieldFilter) {
                let fieldListGroupedByTemplate = [];
                for (let i = 0; i < selectedModels.length; i++) {
                    let item = {label: selectedModels[i].title, options: [] };
                    // when in Dashes - obtain dash fields / when in Dots - obtain dot fields
                    item.options = fieldsInSelectedModels.filter(f => f.model_id === selectedModels[i].id).sort((f1, f2) => f1.order - f2.order).map(f => ({ value: f.id, label: f.title + ' (' + selectedModels[i].title + ')' }));
                    // when in Dashes - obtain Dot Template Ids in dash / when in Dots 'dotTemplateIdsInDash' is length = 0
                    const dotTemplateIdsInDash = selectedModels[i].model_field_list.filter(f => f.type_name === FieldTypes.FIELD_CARGO).map(dt => dt.cargoCategory)
                    // when in Dashes - obtain dot fields in Dot Template Ids / when in Dots 'dotTemplateIdsInDash' is length = 0
                    for (let k = 0; k < dotTemplateIdsInDash.length; k++) {
                        const dotFields = containedDotFields.filter(f => f.model_id === dotTemplateIdsInDash[k]).sort((f1, f2) => f1.order - f2.order).map(f => ({ value: f.id, label: f.title + ' (' + selectedModels[i].title + ')' }));
                        item.options = item.options.concat(dotFields);
                    }

                    if(item.options.length > 0) {
                        fieldListGroupedByTemplate.push(item);
                    }
                }
                fieldsInSelectedModels = fieldListGroupedByTemplate;
            }
        }

        return fieldsInSelectedModels;
    }

    renderForm(filterItem) {
        const { list } = this.state;
        const { intl: { formatMessage } } = this.props;
        const filterIndex = list.indexOf(filterItem);
        const showSelectOnTop = filterIndex > 3;

        const fieldsInSelectedModels = this.getAvailableFields(true);        

        return (
            <div className="filter-form">
                <Formik
                    enableReinitialize={true}
                    initialValues={!!filterItem ? { ...filterItem } : { ...this.createFilterInstance() }}
                    onSubmit={(values, form) => this.handleSubmit(values, form)}
                    render={({ values, handleChange, submitForm }) => {
                        const comparatorInputs = this.getComparators(values, showSelectOnTop);
                        return (
                            <Form
                                noValidate
                                onChange={async e => {
                                    await handleChange(e);
                                    e.stopPropagation();
                                    submitForm();
                                }}
                            >
                                <React.Fragment>
                                    <div className="row">
                                        <div className="col">
                                             
                                            <Fields.Select
                                                name="field"
                                                submitOnChange={true}
                                                disabled={!!values.field}
                                                value={values.field}
                                                options={fieldsInSelectedModels}
                                                menuPlacement={showSelectOnTop ? 'top' : 'auto'}
                                            />
                                            
                                        </div>
                                        {values.field && (
                                            <Tooltip trigger={['hover']} placement='top' overlay={<span>{ formatMessage({ id: `comparator.placeholder` })}</span>}>
                                                <div className="col-2 pd-l-0">
                                                    <Fields.Select
                                                        key={values.field}
                                                        name="comparator"
                                                        submitOnChange={true}
                                                        value={values.comparator}
                                                        options={comparatorInputs.map(c => c.comparator)}
                                                        menuPlacement={showSelectOnTop ? 'top' : 'auto'}
                                                    />
                                                </div>
                                            </Tooltip>

                                        )}
                                        {values.field &&
                                            values.comparator &&
                                            (comparatorInputs.find(c => c.comparator.value === values.comparator) || {}).input}
                                        {list.length > 0 && (
                                            <div className="col-1 pd-l-0">
                                                <button
                                                    className="btn btn-primary icon-button field-inline float-right"
                                                    type="button"
                                                    onClick={() => this.removeFilter(values.id)}
                                                >
                                                    <i className="material-icons-outlined">remove</i>
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </React.Fragment>
                            </Form>
                        );
                    }}
                />
            </div>
        );
    }

    filterModelChooser = () => {
        const { selectedModelIds, availableModels, } = this.props;
        let selectedModels = [];
        if (selectedModelIds && selectedModelIds.length > 0) {
            selectedModels = availableModels.filter(m => selectedModelIds.includes(m.id));
        }
        const modelOptions = selectedModels.map(m => ({ label: m.title, value: m.id }));
        let filterModels = modelOptions;
        if (this.state.filterModels) {
            filterModels = this.state.filterModels;
        }
        return (
            <div style={{padding: '15px'}}>
                {(filterModels.length > 0) && <div className="input-placeholder"><FormattedMessage id='templateFields'/></div>}
                <Select
                    placeholder={<FormattedMessage id='templateFields'/>}
                    value={filterModels}
                    options={modelOptions}
                    isMulti={true}
                    isClearable={true}
                    onChange={(vals) => this.setState({ filterModels: vals })}
                    theme={theme => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary25: '#eeeeee',
                            primary: '#00336B'
                        }
                    })}
                />
            </div>
        )
    }

    render() {
        const { list } = this.state;
        const { onFiltersSet, onCancel, availableModels, selectedModelIds } = this.props;
        const selectedModels = availableModels.filter(m => selectedModelIds.find(id => m.id === id));
        const modelsWithMatchingDotFields = selectedModels.filter(m => {
            const dotFields = m.model_field_list.filter(f => f.type_name === FieldTypes.FIELD_CARGO);
            const matchingDotFields = dotFields.filter(f => m.model_field_list.find(otherField => otherField.id !== f.id && otherField.cargoCategory === f.cargoCategory));
            return matchingDotFields.length > 0;
        });
        const fieldsInSelectedModels = this.getAvailableFields();
        const selectedFields = fieldsInSelectedModels.filter(f => list.find(filter => filter.field === f.id));
        const dotFieldsWithMatchesInList = selectedFields.filter(selectedField => {
            return selectedModels.some(m => {
                const dotFields = m.model_field_list.filter(f => f.type_name === FieldTypes.FIELD_CARGO);
                const matchingDotFields = dotFields.filter(f => m.model_field_list.find(otherField => otherField.id !== f.id && otherField.cargoCategory === f.cargoCategory));
                return matchingDotFields.some(df => df.cargoCategory === selectedField.model_id);
            });
        })

        const filters = () => {
            if (list.length > 0 && list.filter(f => f.field).length > 0) {
                const filtersList = (
                    <div className="advanced-filters-inner">
                        {list.map((filterItem, index) => (
                            <React.Fragment key={filterItem.id}>
                                {!!filterItem.break_or && index != 0 && (
                                    <div className="filter-separator tx-center mg-y-25">
                                        <span>OR</span>
                                    </div>
                                )}
                                {this.renderForm(filterItem)}
                            </React.Fragment>
                        ))}
                        <div className="row mg-t-15">
                            <div className="col">
                                <button className="btn btn-primary icon-button field-inline mg-r-15 new-filter-and" type="button" onClick={() => this.addNewFilter(false)}>
                                    <i className="material-icons">add</i>
                                    <span>
                                        <FormattedMessage id="page.transactions.newFilter.type" values={{ type: <FormattedMessage id='AND' /> }} />
                                    </span>
                                </button>
                                <button className="btn btn-primary icon-button field-inline mg-r-15 new-filter-or" type="button" onClick={() => this.addNewFilter(true)}>
                                    <i className="material-icons">add</i>
                                    <span>
                                        <FormattedMessage id="page.transactions.newFilter.type" values={{ type: <FormattedMessage id='OR' /> }} />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                );
                const itemsBreakOr = list.filter(f => f.break_or).length;
                const itemsNotBreakOr = list.filter(f => !f.break_or).length;
                if (((2 * itemsBreakOr) + itemsNotBreakOr) >= 10) {
                    return <SimpleBar> { filtersList } </SimpleBar>;
                } else {
                    return filtersList;
                }
            } else {
                return this.renderForm()
            }
        }

        return (
            <div className="modal-dialog advanced-filters-modal" role="document">
                <div className="modal-content bd-0">
                    <div className="modal-header pd-x-20">
                        <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                            <FormattedMessage id="setupFieldFilters" />
                        </h6>
                    </div>
                    <div className="modal-body pd-20">
                        <div className="advanced-filters-container">
                            { this.filterModelChooser() }
                            { 
                                dotFieldsWithMatchesInList.length > 0 
                                &&
                                <h6 className='mg-x-15' style={{color: 'red'}}>
                                    {
                                        selectedModels.length == 1 ?
                                        <FormattedMessage id='dotFieldFilterMultiMatchSingleInstance' />
                                        :
                                        <FormattedMessage id='dotFieldFilterMultiMatchMultiInstance' />
                                    }
                                </h6>
                            }
                            { filters() }
                        </div>
                    </div>
                    <div className="modal-footer justify-content-center">
                        <button
                            type="button"
                            className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                            onClick={() => onFiltersSet(list)}
                        >
                            <FormattedMessage id="common.apply" />
                        </button>
                        <button type="button" className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium" onClick={onCancel}>
                            <FormattedMessage id="common.cancel" />
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default injectIntl(FieldFilters);
