export const CONDITIONS_SET_REFERENCE = `
    mutation M(
        $condition_id: Int!, 
        $data: JsonString!
    ) {
        alert_condition_set_reference(
            input: { 
                condition_id: $condition_id,
                reference_data: $data
            }
        ) {
            id
            type_id
            comparator_id
            alert_id
            order
            break_or
            template {
                id
                title
            }
            field {
                id
                title
                type_id
                type_name
            }
            object_type {
                id
                title
            }
            index_field {
                id
                title
                type_id
                type_name
            }
            dot_field
        }
    }
`;
