import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Field, ErrorMessage } from 'formik';
import moment from 'moment';
//import 'moment/locale/pt';
import DateTime from  'react-datetime';
import 'react-datetime/css/react-datetime.css'
import './fields.scss';
import Tooltip from 'rc-tooltip';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class DateField extends React.Component {
    render() {
        const {
            intl: { formatMessage },
            name,
            submitOnChange,
            placeholder,
            dateFormat,
            disabled = false,
            locale,
        } = this.props;

        return (
            <div className="date-field">
                <Field
                    autoComplete="off"
                    {...this.props}
                    render={fieldProps => {
                        const {
                            field,
                            form: { setFieldValue, submitForm }
                        } = fieldProps;
                        const {value} = field;
                        delete field.value;
                        const inputProps = {
                            ...field,
                            placeholder: placeholder || formatMessage({ id: `${name}.placeholder` }),
                            style: {
                                display: 'inline-block',
                                width: '100%'
                            },
                            autoComplete: 'off',
                        }
                        let className = 'form-control';
                        moment.locale(locale);
                        const parsedDate = moment.isMoment(value) ? value : moment(value, dateFormat || 'DD-MMM-YYYY');
                        let dateValue = '';
                        if (parsedDate.isValid()) {
                            dateValue = parsedDate.format(dateFormat || 'DD-MMM-YYYY')
                        }
                        if (disabled) {
                            inputProps.disabled = true;
                        } else if (value && !moment(value, dateFormat || 'DD-MMM-YYYY', true).isValid()) {
                            className += ' invalid';
                        }
                        inputProps.className = className;
                        return (
                            <React.Fragment>
                                <DateTime
                                    locale={locale}
                                    initialValue={dateValue}
                                    dateFormat={dateFormat || 'DD-MMM-YYYY'}
                                    onChange={async date => {
                                        if (moment.isMoment(date) && date.isValid()) {
                                            const txtDate = date.format(dateFormat || 'DD-MMM-YYYY')
                                            await setFieldValue(name, txtDate)
                                            if (submitOnChange) {
                                                submitForm();
                                            } 
                                        } else {
                                            await setFieldValue(name, date);
                                            if (date === '' && submitOnChange) {
                                                submitForm();
                                            }
                                        }
                                    }}
                                    inputProps={inputProps}
                                    style={{ display: 'inline-block', width: '100%' }}
                                    timeFormat={false}
                                    closeOnSelect={true}
                                    closeOnTab={true}
                                />
                                { !!dateValue && 
                                    <Tooltip trigger={['hover']} overlay={<span>{placeholder || formatMessage({ id: `${name}.placeholder` })}</span>} mouseEnterDelay={2}>
                                        <div className="input-placeholder">{placeholder || formatMessage({ id: `${name}.placeholder` })}</div>
                                    </Tooltip>
                                }
                                {this.props.showIsRequired && !dateValue && <div className="validation-message required-placeholder"><FormattedMessage id="validation.required" /></div>}
                            </React.Fragment>
                        );
                    }}
                />
                <ErrorMessage name={name} component="div" className="validation-message" />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    locale: state.localization.locale
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(DateField));
