import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import ReactModal from 'react-modal';
import Tooltip from 'rc-tooltip';
import Page from '../../../components/page';
import Table from '../../../components/table';
import thumb from '../../../assets/user.svg';
import { getCompanies } from '../../../reducers/companies';
import { getUsers, getUserRoles } from '../../../reducers/users';
import { impersonate, getProfile } from '../../../reducers/identity';
import UserInvite from '../components/forms/userInvite';
import { history } from '../../../configuration/history';

class CompanyDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: { open: false }
        };
    }

    componentDidMount() {
        const { companies, getCompanies, roles, getUserRoles, match } = this.props;
        if (!companies) getCompanies({ status_ids: [1, 2, 3, 4, 5, 6] });
        if (!roles) getUserRoles({ company_id: match.params.id });
    }

    setModalVisibility = open => {
        this.setState({ ...this.state, modal: { open } });
    };

    loadData = (options, onComplete) => {
        const { getUsers, match } = this.props;
        return getUsers({ company_ids: match && match.params ? [match.params.id] : undefined, status_ids: [1, 2, 3, 4, 5], ...options }, onComplete);
    };

    getUserRole = (role_id) => {
        const { roles } = this.props;
        const role = (roles || []).find(r => role_id === r.id);
        if (!role) return 'Unavailable';
        return role.title;
    }

    getColumns = () => {
        const { impersonate, getProfile } = this.props;
        return [
            {
                headerClassName: 'd-sm-block d-none',
                Header: <FormattedMessage id="common.name" />,
                id: 'name',
                accessor: d => d.name,
                Cell: row => {
                    const { original, value } = row;
                    const avatar = original.avatar && original.avatar.thumb && original.avatar.thumb.url ? original.avatar.thumb.url : thumb;
                    return (
                        <div>
                            <span className="d-sm-block d-none">{value}</span>
                            <div className="d-sm-none d-xs-block mobile-cell">
                                <div className="row align-items-center">
                                    <div className="col-auto text-center pl-4 pr-0">
                                        <img src={avatar} className="wd-40 rounded-circle" alt={original.name} />
                                    </div>
                                    <div className="col-9">
                                        <div className="font-weight-bold ellipsis">{value}</div>
                                        <div className="ellipsis">{original.email}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }
            },
            {
                headerClassName: 'd-sm-block d-none',
                className: 'd-sm-block d-none',
                Header: <FormattedMessage id="common.email" />,
                id: 'email',
                accessor: d => d.email
            },
            {
                headerClassName: 'd-sm-block d-none',
                className: 'd-sm-block d-none',
                Header: <FormattedMessage id="role.placeholder" />,
                id: 'role_id',
                accessor: d => this.getUserRole(d.role_id)
            },
            {
                headerClassName: 'd-sm-block col-2 d-none',
                className: 'd-xs-block col-2',
                sortable: false,
                id: 'actions',
                accessor: d => (
                    <div className="cell-actions float-right">
                        <Tooltip placement={'top'} trigger={['hover']} overlay={<FormattedMessage id="common.impersonate" />}>
                            <i
                                className="material-icons-outlined table-action-icon"
                                onClick={() =>
                                    impersonate(d.id, async () => {
                                        const tab = await window.open(`/impersonate/${d.id}`, '_blank');
                                        await tab.focus();
                                        getProfile();
                                    })
                                }
                            >
                                transfer_within_a_station
                            </i>
                        </Tooltip>
                    </div>
                )
            }
        ];
    };

    renderActions = () => {
        const { match } = this.props;
        return (
            <div className="btn-group pd-t-10">
                {/* <span className="btn btn-outline-info" onClick={() => this.setModalVisibility(true)}>
                    <FormattedMessage id="page.companies.details.actions.invite.user" />
                </span> */}
            </div>
        );
    };

    render() {
        const { modal } = this.state;
        const { parents, company, users = [], total = 0, roles } = this.props;
        if (!company) return null;

        return (
            <React.Fragment>
                <Page
                    {...{ ...CompanyDetails.meta, title: company.company_name, subtitle: company.description }}
                    parents={parents}
                    actions={this.renderActions()}
                >
                    <Table columns={this.getColumns()} data={users} total={total} dataLoader={(options, onComplete) => this.loadData(options, onComplete)} />
                </Page>
                <ReactModal
                    isOpen={modal.open}
                    onRequestClose={() => this.setModalVisibility(false)}
                    className="modal-block dialog"
                    overlayClassName="modal-overlay gray"
                >
                    <UserInvite companyId={company.id} roles={roles} onCancel={() => this.setModalVisibility(false)} />
                </ReactModal>
            </React.Fragment>
        );
    }
}

CompanyDetails.meta = {
    routes: ['/companies/'],
    icon: 'ion-ios-world-outline',
    menus: { main: false, user: false },
    order: 0
};

const mapStateToProps = (state, props) => {
    const companyId = props.match && props.match.params ? props.match.params.id : undefined;
    const companies = state.companies.list;
    const company = !!companyId && !!companies ? companies.find(c => c.id === Number.parseInt(companyId)) : undefined;
    return {
        companies,
        company,
        users: state.users.list,
        total: state.users.total,
        roles: state.users.roles
    };
};
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getProfile,
            getCompanies,
            getUsers,
            getUserRoles,
            impersonate
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanyDetails);
