import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import Page from '../../../components/page';
import PathVisualization from './pathVisualization';

class VisibilityPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
    this.contentRef = React.createRef();
  }

  render() {

    return (
      <Page {...VisibilityPage.meta} ref={this.contentRef} noPadding={true}>
          <PathVisualization contentRef={this.contentRef} {...this.props}/>
      </Page>
    );
      
  }
}

VisibilityPage.meta = {
  showTitleBlock: false,
  title: <FormattedMessage id="page.lots.title" />,
  subtitle: <FormattedMessage id="page.lots.subtitle" />,
  routes: ['/visualize'],
  icon: 'ion-ios-world-outline',
  menus: { main: true, user: false },
  order: 10
};

const mapStateToProps = state => {
  return {
    menuClass: state.menus.menuClass,
    isMobile: state.menus.isMobile,
  };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
        },
        dispatch
    );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(VisibilityPage));