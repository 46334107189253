import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import TransactionsList from './containers/list';
import EventPage from './containers/single';
import { DefinedPermissions } from '../../components/routes/permissions/permissionResolver';

import './transactions.scss';

class Transactions extends React.Component {
    render() {
        const {state}= this.props.history.location;
        let collMeta, collItemMeta, parents;

        if( state ){              
            const { title, route} = JSON.parse(state);
            
            collMeta = {
                title: 'collections',
                routes: ['/collections'],
            };
            collItemMeta = {
                title: title,
                routes: [`${route}`],
            };

            parents= [collMeta, collItemMeta];
        }
        
        return (
            <Switch>
                <Route exact path="/events" component={props => <TransactionsList {...props} parents={parents}/>} />
                <Route path="/events/:id" component={props => <EventPage {...props} parents={[Transactions.meta]} />} />
            </Switch>
        );
    }
}

Transactions.meta = {
    title: <FormattedMessage id="page.transactions.title" />,
    subtitle: <FormattedMessage id="page.transactions.subtitle" />,
    routes: ['/events'],
    icon: 'ion-arrow-swap',
    menus: { main: true, user: false },
    order: 30,
    access: DefinedPermissions.TransactionCrud,
    requires: ['transaction', 'read', 'update'],
    onlySubscribed: true
};

export default Transactions;
