export const DOCUMENT_TEMPLATE_DELETE = `
    mutation M(
        $template_id: Long!
    ) {
        document_template_delete(
            template_id: $template_id
        ) {
            id
            hash_id
            title
            status
        }
    }
`;
