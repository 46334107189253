export const INDICES_DELETE = `
    mutation M($field_id: Long!) {
        index_field_delete(field_id: $field_id) {
            id
            order_by
            type_id
            type_name
            status_id
            status_name
            title
            description
            extra_info
            is_used
        }
    }
`;
