export const FIELDS_BATCH_UPDATE = `
    mutation M($model_id: Int!, $field_updates: JsonString!) {
        model_field_batch_update(model_id: $model_id, field_updates: $field_updates) {
            id
            model_id
            order
            type_id
            type_name
            status_id
            status_name
            index_field_id
            input_id
            visibility_check
            title
            description
            is_required
            validation
            width
            force_break
            extra_info
            company_id: model_company_id
            cargo_category
            cargo_type
            transform_cargo_type
            cargo_reference_type
            is_static_unit
            default_unit


            values: model_field_value_list(show_hidden: false) {
                id
                company_id
                field_id
                order_by
                is_hidden
                value
            }

            index_field {
                id
                order_by
                type_id
                type_name
                status_id
                status_name
                title
                description
                extra_info
            }

            visibility_condition_list {
                id
                order_by
                break_or
                is_not
                field_id
                comparator_id
                comparator_name
                comparands
                extra_info
            }
        }
    }
`;
