export const ALERTS_LIST = `
    query($order_by: [OrderBy], $offset: Int, $limit: Int) {
        alert_list (
            paging: { order_by: $order_by, offset: $offset, limit: $limit }
        ) {
            total
            data {
                id
                hash_id
                title
                description
                created_on
                created_by
                active
                conditions {
                    id
                }
            }
        }
    }
`;
