import { uuid } from '../utilities/common';

export default class Model {
    id;
    company_id;
    status_id;
    status_name;
    created_on;
    title;
    description;
    is_used;
    split_conditions;
    extra_info;

    constructor(model) {
        const { id, company_id, status_id, status_name, created_on, title, description, is_used, split_conditions, extra_info } = model || {};

        this.id = id;
        this.company_id = company_id;
        this.status_id = status_id !== undefined ? status_id : 1;
        this.status_name = status_name || 'Draft';
        this.created_on = created_on;
        this.title = title || 'New Dash Template';
        this.description =
            description ||
            `{"blocks":[{"key":"${uuid(
                8
            )}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`;
        this.is_used = !!is_used;
        this.split_conditions = split_conditions;
        this.extra_info = extra_info;
    }

    static forCompany = ({ id }) => {
        return new Model({ company_id: id });
    };
}
