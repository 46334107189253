import React from 'react';
import { Formik, Form } from 'formik';
import { Fields } from '../fields';

class ColumnPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleSubmit = ({ columns }) => {
        const { onChange } = this.props;
        onChange(columns);
    };

    render() {
        const { columns, models = [], selectedColumns = [] } = this.props;
        let filteredColumns = columns.filter(c => c.id !== 'actions' && c.id !== 'quantity_consumed' && c.id !== 'view_dots' && c.id !== 'view_dashes');
        if (models.length > 0) {
            filteredColumns = filteredColumns.filter(c => {
                if (!c.id || !c.id.includes('field_')) {
                    return true;
                }
                return models.some(m => m.model_field_list.some(mf => Number(mf.index_field_id) === Number(c.id.replace('field_', ''))))
            })
        }
        const options = filteredColumns.map(c => ({ value: c.id, label: c.Header }));

        return (
            <div className="column-picker">
                <Formik
                    enableReinitialize={false}
                    initialValues={{ columns: getColumnVisibility(columns, selectedColumns) }}
                    onSubmit={this.handleSubmit}
                    render={({ handleChange, submitForm }) => {
                        return (
                            <Form
                                noValidate
                                onChange={async e => {
                                    await handleChange(e);
                                    submitForm();
                                }}
                            >
                                <Fields.Checklist options={options} name="columns" placeholder={<i className="material-icons">view_column</i>} />
                            </Form>
                        );
                    }}
                />
            </div>
        );
    }
}

export default ColumnPicker;

export const getInitialColumnVisibility = (columns, selectedColumns = []) => {
    const result = {};
    columns.forEach(column => {
        result[column.id] = (!!column.isDefault && !column.isHidden) || selectedColumns.includes(column.id);
    });

    return result;
};

const getColumnVisibility = (columns, selectedColumns = []) => {
    const result = {};
    columns.forEach(column => {
        result[column.id] = selectedColumns.includes(column.id);
    });

    return result;
};
