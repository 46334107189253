export const FIELDS_LIST_MAPPINGS = `
    query(
        $dash_template_id: Long!
    ) {
        model_field_list_mappings(
            input: {
                dash_template_id: $dash_template_id
            }
        ) {
            owning_dash_field_id
            owning_dot_field_id
            owning_special_dot_field_id
            map_dash_field_id
            map_dot_field_id
            map_special_dot_field
            compound_id
        }
    }
`;
