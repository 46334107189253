import { 
    SET_EVENTS_TABLE_OPTIONS, 
    SET_EVENT_TEMPLATES_TABLE_OPTIONS, 
    SET_EVENT_TEMPLATES_TABLE_QUERY_VALUES,
    SET_EVENT_TEMPLATES_TABLE_QUERY_STATE,
    SET_LOTS_TABLE_OPTIONS, 
    SET_LOTS_TABLE_QUERY_VALUES,
    SET_LOTS_TABLE_QUERY_STATE,
    SET_CARGO_CATEGORIES_TABLE_OPTIONS, 
    SET_CARGO_CATEGORIES_TABLE_QUERY_VALUES,
    SET_CARGO_CATEGORIES_TABLE_QUERY_STATE,
    SET_DOCUMENTS_TABLE_OPTIONS,
    SET_DOCUMENT_TEMPLATES_TABLE_OPTIONS,
    SET_COMPANIES_TABLE_OPTIONS,
    SET_INVOICES_TABLE_OPTIONS,
    SET_EVENT_INDICES_TABLE_OPTIONS,
    SET_CARGO_INDICES_TABLE_OPTIONS,
    SET_EVENTS_TABLE_FILTERS,
    SET_EVENTS_TABLE_ADV_FILTERS,
    SET_ALERTS_TABLE_OPTIONS,
    SET_EVENTS_TABLE_COLUMNS,
    SET_LOTS_TABLE_COLUMNS,
    SET_INPUT_LOT_TABLE_COLUMNS,
    EXCEL_HIDE_FR_COPYPASTE_WARNING,
} from './types';

const defaultState = {
    eventsTableOptions: null,
    eventsTableQueryValues: null,
    eventsTableQueryState: null,
    eventTemplatesTableOptions: null,
    lotsTableOptions: null,
    cargoCategoriesTableOptions: null,
    documentsTableOptions: null,
    documentTemplatesTableOptions: null,
    companiesTableOptions: null,
    invoicesTableOptions: null,
    eventIndicesTableOptions: null,
    cargoIndicesTableOptions: null,
    alertsTableOptions: null,
    eventsTableColumns: null,
    lotsTableColumns: null,
    inputLotTableColumns: null,
    excelHideFieldReferenceCopyPastedWarning: false,
};

/**
 * Loading state mapper.
 * @param {object} state
 * @param {string} action
 */
export default function(state = defaultState, action) {
    switch (action.type) {
        case SET_EVENTS_TABLE_OPTIONS:
            return { ...state, eventsTableOptions: action.payload };
        case SET_EVENTS_TABLE_FILTERS:
            return { ...state, eventsTableQueryValues: action.payload };
        case SET_EVENTS_TABLE_ADV_FILTERS:
            return { ...state, eventsTableQueryState: action.payload };
        case SET_EVENT_TEMPLATES_TABLE_OPTIONS:
            return { ...state, eventTemplatesTableOptions: action.payload };
        case SET_EVENT_TEMPLATES_TABLE_QUERY_VALUES:
            return { ...state, eventTemplatesTableQueryValues: action.payload };
        case SET_EVENT_TEMPLATES_TABLE_QUERY_STATE:
            return { ...state, eventTemplatesTableQueryState: action.payload };
        case SET_LOTS_TABLE_OPTIONS:
            return { ...state, lotsTableOptions: action.payload };
        case SET_CARGO_CATEGORIES_TABLE_OPTIONS:
            return { ...state, cargoCategoriesTableOptions: action.payload };
        case SET_CARGO_CATEGORIES_TABLE_QUERY_VALUES:
            return { ...state, cargoCategoriesTableQueryValues: action.payload };
        case SET_CARGO_CATEGORIES_TABLE_QUERY_STATE:
            return { ...state, cargoCategoriesTableQueryState: action.payload };
        case SET_DOCUMENTS_TABLE_OPTIONS:
            return { ...state, documentsTableOptions: action.payload };
        case SET_DOCUMENT_TEMPLATES_TABLE_OPTIONS:
            return { ...state, documentTemplatesTableOptions: action.payload };
        case SET_COMPANIES_TABLE_OPTIONS:
            return { ...state, companiesTableOptions: action.payload };
        case SET_INVOICES_TABLE_OPTIONS:
            return { ...state, invoicesTableOptions: action.payload };
        case SET_EVENT_INDICES_TABLE_OPTIONS:
            return { ...state, eventIndicesTableOptions: action.payload };
        case SET_CARGO_INDICES_TABLE_OPTIONS:
            return { ...state, cargoIndicesTableOptions: action.payload };
        case SET_LOTS_TABLE_QUERY_VALUES:
            return { ...state, lotsTableQueryValues: action.payload };
        case SET_LOTS_TABLE_QUERY_STATE:
            return { ...state, lotsTableQueryState: action.payload };
        case SET_ALERTS_TABLE_OPTIONS:
            return { ...state, alertsTableOptions: action.payload };
        case SET_EVENTS_TABLE_COLUMNS:
            return { ...state, eventsTableColumns: action.payload };
        case SET_LOTS_TABLE_COLUMNS:
            return { ...state, lotsTableColumns: action.payload };
        case SET_INPUT_LOT_TABLE_COLUMNS:
            return { ...state, inputLotTableColumns: action.payload };
        case EXCEL_HIDE_FR_COPYPASTE_WARNING:
            return { ...state, excelHideFieldReferenceCopyPastedWarning: action.payload };
        default:
            return state;
    }
}

export const setEventsTableOptions = (options) => async dispatch => {
    dispatch({ type: SET_EVENTS_TABLE_OPTIONS, payload: options });
};

export const setEventsTableColumns = (options) => async dispatch => {
    dispatch({ type: SET_EVENTS_TABLE_COLUMNS, payload: options });
};

export const setLotsTableColumns = (options) => async dispatch => {
    dispatch({ type: SET_LOTS_TABLE_COLUMNS, payload: options });
};

export const setInputLotTableColumns = (options) => async dispatch => {
    dispatch({ type: SET_INPUT_LOT_TABLE_COLUMNS, payload: options });
};

export const setEventsTableQueryValues = (options) => async dispatch => {
    dispatch({ type: SET_EVENTS_TABLE_FILTERS, payload: options });
};

export const setEventsTableQueryState = (options) => async dispatch => {
    dispatch({ type: SET_EVENTS_TABLE_ADV_FILTERS, payload: options });
};

export const setEventTemplatesTableOptions = (options) => async dispatch => {
    dispatch({ type: SET_EVENT_TEMPLATES_TABLE_OPTIONS, payload: options });
};

export const setEventsTemplatesTableQueryValues = (options) => async dispatch => {
    dispatch({ type: SET_EVENT_TEMPLATES_TABLE_QUERY_VALUES, payload: options });
};

export const setEventsTemplatesTableQueryState = (options) => async dispatch => {
    dispatch({ type: SET_EVENT_TEMPLATES_TABLE_QUERY_STATE, payload: options });
};

export const setLotsTableOptions = (options) => async dispatch => {
    dispatch({ type: SET_LOTS_TABLE_OPTIONS, payload: options });
};

export const setLotsTableQueryValues = (options) => async dispatch => {
    dispatch({ type: SET_LOTS_TABLE_QUERY_VALUES, payload: options });
};

export const setLotsTableQueryState = (options) => async dispatch => {
    dispatch({ type: SET_LOTS_TABLE_QUERY_STATE, payload: options });
};

export const setCargoCategoriesTableOptions = (options) => async dispatch => {
    dispatch({ type: SET_CARGO_CATEGORIES_TABLE_OPTIONS, payload: options });
};

export const setCargoCategoriesTableQueryValues = (options) => async dispatch => {
    dispatch({ type: SET_CARGO_CATEGORIES_TABLE_QUERY_VALUES, payload: options });
};

export const setCargoCategoriesTableQueryState = (options) => async dispatch => {
    dispatch({ type: SET_CARGO_CATEGORIES_TABLE_QUERY_STATE, payload: options });
};

export const setDocumentsTableOptions = (options) => async dispatch => {
    dispatch({ type: SET_DOCUMENTS_TABLE_OPTIONS, payload: options });
};

export const setDocumentTemplatesTableOptions = (options) => async dispatch => {
    dispatch({ type: SET_DOCUMENT_TEMPLATES_TABLE_OPTIONS, payload: options });
};

export const setCompaniesTableOptions = (options) => async dispatch => {
    dispatch({ type: SET_COMPANIES_TABLE_OPTIONS, payload: options });
};

export const setInvoicesTableOptions = (options) => async dispatch => {
    dispatch({ type: SET_INVOICES_TABLE_OPTIONS, payload: options });
};

export const setEventIndicesTableOptions = (options) => async dispatch => {
    dispatch({ type: SET_EVENT_INDICES_TABLE_OPTIONS, payload: options });
};

export const setCargoIndicesTableOptions = (options) => async dispatch => {
    dispatch({ type: SET_CARGO_INDICES_TABLE_OPTIONS, payload: options });
};

export const setAlertsTableOptions = (options) => async dispatch => {
    dispatch({ type: SET_ALERTS_TABLE_OPTIONS, payload: options });
};

export const setExcelHideFieldReferenceCopyPasteWarning = (options) => async dispatch => {
    dispatch({ type: EXCEL_HIDE_FR_COPYPASTE_WARNING, payload: options });
};
