export const ALERTS_MODIFY_SUBSCRIBER = `
    mutation M(
        $alert_id: Int!
        $user_id: Long!,
        $add: Boolean!
    ) {
        alert_modify_subscriber (
            input: { 
                alert_id: $alert_id, 
                user_id: $user_id, 
                add: $add, 
            }
        ) {
            id
            hash_id
            title
            description
            created_on
            created_by
            active
            conditions {
                id
                type_id
                comparator_id
                alert_id
                order
                break_or
                value
                template {
                    id
                    title
                }
                field {
                    id
                    title
                    type_id
                    type_name
                }
                object_type {
                    id
                    title
                }
                index_field {
                    id
                    title
                    type_id
                    type_name
                }
                dot_field
            }
            subscribers {
                id
                email
            }
        }
    }
`;
