import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getProfile } from 'reducers/identity';
import { loadModel } from 'reducers/models';
import { setMenuVisibility } from 'reducers/menus';
import { getDocumentTemplate, updateDocumentTemplate, getDocumentSample } from 'reducers/document';
import { FormattedMessage } from 'react-intl';
import 'pages/models/models.scss';
import 'components/fields/fields.scss';
import Iframe from 'react-iframe';
import LoadingOverlay from 'react-loading-overlay';
import { getModels } from 'reducers/models';
import { Notifications } from 'components/notifications';

class ExcelDocumentViewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingPreview: true,
        };
        this.waitForResponse = null;
    }

    processMessage = (evt) => {
        const { templateMetadata } = this.props;
        // only handle local messages (i.e. coming from the iframe)
        if (evt.origin === window.location.origin) {
            const { type, data } = evt.data;
            if (!type) return;
            if (type === 'loadcomplete') {
                if (templateMetadata) {
                    const parsedMeta = JSON.parse(templateMetadata);
                    if (parsedMeta.showPrintLines !== undefined && parsedMeta.paperSize !== undefined) {
                        const data = {
                            showPrintLines: parsedMeta.showPrintLines,
                            paperSize: parsedMeta.paperSize,
                            doraeCulture: parsedMeta.culture,
                        };
                        this.sendIframeMessage('loadmetadata', JSON.stringify(data));
                    }
                }
                setTimeout(() => {
                    this.setState({ loadingPreview: false });
                }, 200)
            } else if (type === 'ack') {
                this.setState({ receivedIframeAck: true })
            }
        }
    }

    checkIframeLoaded = (templateFileData) => {
        // Get a handle to the iframe element
        var iframe = document.getElementById('excel-iframe');
        if (iframe) {
            var iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
        
            // Check if loading is complete
            if (  iframeDoc.readyState  == 'complete' ) {
                this.setState({receivedIframeAck: false}, () => {
                    this.waitForResponse = setInterval(() => {
                        if (!this.state.receivedIframeAck) {
                            console.info('No response from excel iframe - pinging again')
                            this.sendIframeMessage('sig', 'sig');
                        } else {
                            this.setState({ receivedIframeAck: true })
                            clearInterval(this.waitForResponse);
                            this.waitForResponse = null;
                            this.sendIframeMessage('loadssjson', templateFileData);
                            this.sendIframeMessage('readonly', null);
                        }
                    }, 300);
                });
                return;
            }
        }
    
        // If we are here, it is not loaded. Set things up so we check   the status again in 100 milliseconds
        window.setTimeout(() => {this.checkIframeLoaded(templateFileData)}, 100);
    }

    componentDidMount() {
        const { templateFileData } = this.props;
        
        if (window.addEventListener) {
            // For standards-compliant web browsers
            window.removeEventListener("message", this.processMessage);
            window.addEventListener("message", this.processMessage, false);
        }
        else {
            window.detachEvent("onmessage", this.processMessage);
            window.attachEvent("onmessage", this.processMessage);
        }
        this.checkIframeLoaded(templateFileData);
    }

    componentWillUnmount() {
        if (window.addEventListener) {
            // For standards-compliant web browsers
            window.removeEventListener("message", this.processMessage);
        }
        else {
            window.detachEvent("onmessage", this.processMessage);
        }
    }

    sendIframeMessage(type, data) {
        const msg = { type, data };
        const iframe = document.getElementById("excel-iframe");
        if (iframe) {
            var iframeWin = iframe.contentWindow;
            iframeWin.postMessage(msg, window.location.origin);
        } else {
            console.error('iframe not loaded!')
        }
    }

    render() {
        return (
            <div style={{height: '100%'}}>
                {
                    this.state.loadingPreview && 
                    <LoadingOverlay
                        active={this.state.loadingPreview}
                        spinner
                        text={<FormattedMessage id='common.loading'/>}
                    >
                        <div
                            style={{ 
                                height: '80vh',
                                width: '100%',
                            }}
                        />
                    </LoadingOverlay>
                }
                <div
                    style={{ 
                        opacity: this.state.loadingPreview ? '0' : '1',
                        width: '100%',
                        height: '100%',
                        // minHeight: '500px',
                    }}
                >
                    <Iframe 
                        url={process.env.PUBLIC_URL + '/CustomToolbars/index.html'} 
                        id='excel-iframe'
                        width='100%'
                        height='100%'
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    company: state.identity.company,
    model: state.fields.model,
    fields: state.fields.items,
    fieldTypes: state.fields.types,
    template: state.document.instance,
    templateChanged: state.document.templateChanged
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getProfile,
            loadModel,
            getDocumentTemplate,
            getDocumentSample,
            setMenuVisibility,
            updateDocumentTemplate,
            getModels,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExcelDocumentViewer);