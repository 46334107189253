import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Popover, Menu, MenuItem, MenuDivider, Icon } from '@blueprintjs/core';
import moment from 'moment';
import { filterTransactions, deleteTransaction, cloneTransaction } from 'reducers/transactions';
import Table from 'components/table';
import Page from 'components/page';
import { getIndices } from 'reducers/indices';
import QueryBuilder from '../components/queryBuilder';
import { getModels, getModelsLight } from 'reducers/models';
import FieldValueRenderer from '../components/fieldValueRenderer';
import ColumnPicker, { getInitialColumnVisibility } from 'components/table/columnPicker';
import { history } from 'configuration/history';
import { setEventsTableOptions, setEventsTableQueryValues, setEventsTableQueryState, setEventsTableColumns } from 'reducers/sessionSettings';
import ConfirmModal from 'components/modals/confirm'
import { updateTransaction, getTransactionDeleteSideEffects } from 'reducers/transaction';
import CreateEventModal from '../components/createEventModal';
import { translateMatchStrength } from 'utilities/common';
import DashDeleteModal from 'components/modals/dashDeleteModal';
import MultiDashDeleteModal from 'components/modals/multiDashDeleteModal';
import { setGraphEntity } from 'reducers/entityGraph';
import DashViewModal from 'pages/events/components/dashViewModal';
import { tooltipWrapper } from 'utilities/common'


class DashesTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columnVisibility: undefined,
            tableRenderCount: 1,
            targetDashHasDotSideEffects: false,
            targetDashCanUnwind: false,
            selectedModels:  [],
            referencedDotTemplates: [],
        };
    }

    componentDidMount() {
        const { getIndices, useDashTags = false } = this.props;

        this.setState({ columnVisibility: getInitialColumnVisibility(this.getColumns()) });
        getIndices(
            { status_ids: [2], model_type: 1 }, 
            r => this.setState({ columnVisibility: getInitialColumnVisibility(this.getColumns(r.data)) }),
            useDashTags ? 'dash' : null
        );
    }

    loadData = (options, onComplete) => {
        let { columnVisibility } = this.state;
        const { filterTransactions, indexFields, dashIndexFields, useDashTags = false, eventsTableOptions, setEventsTableOptions = () => console.warn('No method for setting table options'), eventsTableColumns, includeLastVisited, persistState = true, useRootState = true } = this.props;
        if (eventsTableColumns != null && useRootState) {
            columnVisibility = eventsTableColumns;
        }
        const tags = (useDashTags ? dashIndexFields : indexFields) || [];
        const resultIndices = tags.filter(i => !!columnVisibility && columnVisibility[`field_${i.id}`] === true);
        if (includeLastVisited) {
            options.include_last_visualized = true;
        }
        return filterTransactions(
            {
                result_index_field_ids: resultIndices.map(i => i.id),
                status_ids: [1, 3, 7],
                transaction_type: 1,
                ...options
            },
            (results) => {
                if (results === undefined) {
                    onComplete([]);
                    return;
                }
                if (results.total > 0 && results.data.length === 0) {
                    // navigate to previous page and force table to re-render
                    const newOptions = Object.assign({}, eventsTableOptions);
                    newOptions.page = newOptions.page - 1;
                    if (persistState) {
                        setEventsTableOptions(newOptions).then(() => {
                            this.setState({ tableRenderCount: this.state.tableRenderCount + 1 });
                        })
                    } else {
                        this.setState({ tableRenderCount: this.state.tableRenderCount + 1 });
                    }
                } else {
                    onComplete(results);
                }
            },
            includeLastVisited
        );
    };

    setColumns = selectedColumns => {
        const { setEventsTableColumns, persistState = true } = this.props;
        this.setState({ columnVisibility: selectedColumns });
        if (persistState) {
            setEventsTableColumns(selectedColumns);
        }
    };

    toggleSelectMultiTarget = (id) => {
        this.props.toggleSelectMultiTarget(id);
    }

    eventAllSelectorEl = () => {
        const { transactions, selectMultipleTargetIds = [], selectMultipleTargets = () => null, clearMultipleTargets = () => null, isSelectingMultiple = false } = this.props;
        const allEventsSelected = transactions.map(t => t.id).filter(id => !selectMultipleTargetIds.includes(id)).length === 0;
        if (isSelectingMultiple) {
            return ( 
                <div className="actions-wrapper" style={{height: '100%'}}>
                    <input 
                        type="checkbox" 
                        checked={allEventsSelected}  
                        onChange={() => {
                            const { transactions, selectMultipleTargetIds = [], selectMultipleTargets = () => null, clearMultipleTargets = () => null, isSelectingMultiple = false } = this.props;
                            const allEventsSelected = transactions.map(t => t.id).filter(id => !selectMultipleTargetIds.includes(id)).length === 0;
                            if (allEventsSelected) {
                                clearMultipleTargets(transactions.map(t => t.id));
                            } else {
                                selectMultipleTargets(transactions.map(t => t.id).filter(id => !selectMultipleTargetIds.includes(id)));
                            }
                        }}
                    />
                </div>
            );
        } else {
            return null;
        }
    }

    getColumns = providedIndices => {
        const { eventsTableColumns, prependColumns = [], appendColumns = [], hiddenColumns = [], useDashTags, dashIndexFields, indexFields, useRootState = true, company, intl: { formatMessage } } = this.props;
        let { columnVisibility, options = { status_ids: [1, 2, 3, 4, 5] } } = this.state;
        if (useRootState && eventsTableColumns != null) {
            columnVisibility = eventsTableColumns;
        }
        const tags = (useDashTags ? dashIndexFields : indexFields) || providedIndices || [];
        const resultIndices = tags.filter(i => !!columnVisibility && columnVisibility[`field_${i.id}`] === true);
        const showMatchStrength = (options.criteria && options.criteria !== '[]') || (options.field_criteria && options.field_criteria !== '[]');
        const isPro = (company.subscription_type === 'pro' || company.id === 1);
        let columns = [
            {
                isDefault: true,
                headerClassName: 'd-sm-block col-2 d-none',
                Header: this.eventAllSelectorEl(),
                className: 'd-xs-block text-center col-2',
                sortable: false,
                resizable: false,
                width: 34,
                id: 'actions',
                accessor: d => {
                    const { cloneTransaction, getTransactionDeleteSideEffects, isSelectingMultiple = false, selectMultipleTargetIds } = this.props;
                    return isSelectingMultiple ?
                    (
                        <div className="actions-wrapper">
                            <input 
                                key={'select-dash-checkbox-' + d.id}
                                type="checkbox" 
                                checked={selectMultipleTargetIds && selectMultipleTargetIds.indexOf(d.id) > -1}  
                                onChange={() => this.toggleSelectMultiTarget(d.id)}
                            />
                        </div>
                    )
                    :
                    (
                        <div className="actions-wrapper">
                            <Popover
                                content={
                                    <Menu>
                                        <MenuItem
                                            icon="edit"
                                            text={<FormattedMessage id="common.edit" />}
                                            onClick={() => history.push(`/events/${d.id}` + window.location.search)}
                                        />
                                        <MenuItem
                                            icon="eye-open"
                                            disabled={!isPro}
                                            text={<FormattedMessage id="openVisualization" />}
                                            onClick={() => {
                                                const visualizationData = btoa(JSON.stringify({ entities: [{ entityType: 'dash', entityId: d.id }] }));
                                                history.push(`/visualize/${visualizationData}` + window.location.search)
                                            }}
                                        />
                                        <MenuItem
                                            icon="duplicate"
                                            text={<FormattedMessage id="common.clone" />}
                                            onClick={() => {
                                                cloneTransaction({ transaction_id: d.id }, data => {
                                                    history.push(`/events/${data.id}` + window.location.search);
                                                });
                                            }}
                                        />
                                        <MenuDivider />
                                        <MenuItem
                                            icon="compressed"
                                            text={<FormattedMessage id="common.archive" />}
                                            disabled={d.status_id === 5 || d.status_id === 6}
                                            onClick={() =>
                                                this.setState({ targetArchiveTransaction: {...d}, archiveTargetTitle: d.title || '[Untitled]', archiveConfirmModalOpen: true })
                                            }
                                        />
                                        <MenuItem
                                            icon="cross"
                                            text={<FormattedMessage id="common.delete" />}
                                            disabled={d.status_id === 6}
                                            onClick={() => {
                                                this.setState({ deleteTargetId: d.id, deleteTargetTitle: d.title || '[Untitled]', confirmModalOpen: true, deleteModalLoading: true });
                                                getTransactionDeleteSideEffects(d.id, sideEffectsData => {
                                                    this.setState({
                                                        targetDashHasDotSideEffects: sideEffectsData.has_effects_to_reverse,
                                                        targetDashCanUnwind: sideEffectsData.can_reverse_effects,
                                                        deleteModalLoading: false,
                                                    });
                                                })
                                            }}
                                        />
                                    </Menu>
                                }
                            >
                                <Button minimal={true} small={true} icon={<Icon iconSize={12} icon="menu" />} />
                            </Popover>
                        </div>
                    )
                }
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block text-center id-column',
                Header: <FormattedMessage id="common.id" />,
                width: 100,
                id: 'hash_id',
                accessor: d => tooltipWrapper(
                    d.model ? d.model.contains_inaccessible_dots : false,
                    <FormattedMessage id='containsInaccessibleDotsMessage' values={{ entityType: formatMessage({ id: 'dash' }) }} />,
                    (d.model && d.model.contains_inaccessible_dots) 
                    ?
                    <span>{d.id}</span>
                    :
                    <a href='#' onClick={e => { e.stopPropagation(); e.preventDefault(); this.setState({ dashModalOpen: true, viewingDash: d }); }}>{d.id}</a>
                )
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block',
                Header: <FormattedMessage id="common.title" />,
                id: 'title',
                accessor: d => d.title
            },
            {
                isDefault: false,
                headerClassName: 'd-block',
                className: 'd-block',
                Header: <FormattedMessage id="common.description" />,
                id: 'description',
                sortable: false,
                accessor: d => d.description
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block text-center',
                Header: <FormattedMessage id="common.status" />,
                id: 'status_name',
                width: 100,
                accessor: d => <FormattedMessage id={d.status_name} defaultMessage={d.status_name} />
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block',
                Header: <FormattedMessage id="common.model" />,
                id: 'model_title',
                accessor: d => d.model_title
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block',
                Header: <FormattedMessage id="common.createdBy" />,
                id: 'user_name',
                accessor: d => d.user_name
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block text-center',
                Header: <FormattedMessage id="common.alteredOn" />,
                id: 'modified_on',
                accessor: d => moment(d.modified_on || d.created_on).format('DD-MM-YYYY')
            },
            {
                isDefault: false,
                headerClassName: 'd-block',
                className: 'd-block text-center',
                Header: <FormattedMessage id="common.createdOn" />,
                id: 'created_on',
                accessor: d => moment(d.created_on).format('DD-MM-YYYY')
            },
        ];
        if (showMatchStrength) {
            columns.push(
                {
                    isDefault: true,
                    headerClassName: 'd-block',
                    className: 'd-block',
                    Header: <FormattedMessage id="common.matchStrength" />,
                    id: 'match_strength',
                    accessor: d => translateMatchStrength(d.match_strength)
                },
            )
        }

        columns = prependColumns.
            concat(columns.filter(col => !hiddenColumns.find(hc => hc === col.id)))
            .concat(appendColumns)
            .concat(
                ...tags.map(i => ({
                    headerClassName: 'd-sm-block d-none',
                    className: 'd-sm-block d-none',
                    Header: i.title,
                    id: `field_${i.id}`,
                    accessor: d => <FieldValueRenderer row={d} field={i} />
                }))
            );
        return columns;
    };

    render() {
        const { transactions, total, indexFields, dashIndexFields, useDashTags, eventsTableColumns, persistState = true, useRootState = true } = this.props;
        let { columnVisibility } = this.state;
        const tags = (useDashTags ? dashIndexFields : indexFields) || [];
        if (!columnVisibility) return null;

        if (useRootState && eventsTableColumns != null) {
            columnVisibility = eventsTableColumns;
        }

        const columns = this.getColumns();
        let columnsToShow = columns.filter(c => !!c && !!c.id && (columnVisibility[c.id] === true || c.id === 'match_strength'))
        const columnPicker = onPicked => (
            <div className="row">
                <div className="col">
                    <div className="float-right">
                        <ColumnPicker
                            columns={columns}
                            selectedColumns={columnsToShow.map(c => c.id)}
                            models={this.state.selectedModels}
                            onChange={c => {
                                this.setColumns(c);
                                onPicked(c);
                            }}
                        />
                    </div>
                </div>
            </div>
        );
        return (
            <>
                <Table
                    key={'dash-table-' + this.state.tableRenderCount}
                    columns={columnsToShow}
                    columnPicker={columnPicker}
                    data={transactions}
                    total={total || transactions.length}
                    queryBuilder={setFilters => 
                        <QueryBuilder 
                            fields={tags} 
                            models={this.state.referencedDotTemplates}
                            onModelsSet={models => this.setState({ selectedModels: models })}
                            onDotTemplatesSet={models => this.setState({ referencedDotTemplates: models })}
                            onFiltersSet={setFilters}
                            formattedFiltersSetter={this.props.setEventsTableFilters}
                            stateSetter={persistState ? this.props.setEventsTableQueryState : undefined}
                            valuesSetter={persistState ? this.props.setEventsTableQueryValues : undefined}
                            initialState={persistState ? this.props.eventsTableQueryState : undefined}
                            initialValues={persistState ? this.props.eventsTableQueryValues : undefined}
                            modelType={'event template'}
                        />
                    }
                    dataLoader={(options, onComplete) => {
                        this.loadData(options, onComplete);
                        this.setState({ options: options });
                    }}
                    optionsSetter={persistState ? this.props.setEventsTableOptions : undefined}
                    initialOptions={useRootState ? this.props.eventsTableOptions : undefined}
                    defaultSort={this.props.defaultSort}
                    defaultSortDesc={this.props.defaultSortDesc}
                />
                <DashViewModal
                    key={this.state.viewingDash ? this.state.viewingDash.id : 'unassigned-dot-modal'}
                    modalOpen={this.state.dashModalOpen}
                    setModalOpen={(open) => this.setState({ dashModalOpen: open })}
                    dashId={this.state.viewingDash ? this.state.viewingDash.id : null}
                    modalTitle={this.state.viewingDash ? this.state.viewingDash.id : ''}
                    showLink={true}
                    showPastVersions={true}
                />
            </>
        );
    }
}

DashesTable.meta = {
    title: <FormattedMessage id="page.transactions.title" />,
    subtitle: <FormattedMessage id="page.transactions.subtitle" />,
    routes: ['/events'],
    icon: 'ion-arrow-swap',
    menus: { main: true, user: false },
    order: 40
};

DashesTable.defaultProps = {
    transactions: [],
    total: 0
};

const mapStateToProps = state => ({
    transactions: state.transactions.list,
    total: state.transactions.total,
    indexFields: state.indices.list ? state.indices.list.filter(i => i.status_id === 2) : undefined,
    dashIndexFields: state.indices.dashList ? state.indices.dashList.filter(i => i.status_id === 2) : undefined,
    eventsTableOptions: state.sessionSettings.eventsTableOptions,
    eventsTableQueryState: state.sessionSettings.eventsTableQueryState,
    eventsTableQueryValues: state.sessionSettings.eventsTableQueryValues,
    eventsTableColumns: state.sessionSettings.eventsTableColumns,
    company: state.identity.company,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getModels,
            getModelsLight,
            filterTransactions,
            getIndices,
            deleteTransaction,
            setEventsTableOptions,
            setEventsTableQueryValues,
            setEventsTableQueryState,
            updateTransaction,
            getTransactionDeleteSideEffects,
            setEventsTableColumns,
            cloneTransaction,
            setGraphEntity,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(DashesTable));
