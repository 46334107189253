import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { findMedia } from '../../../../../reducers/media';

class ImageRenderer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newUrl: undefined
        };
    }

    setMediaUrl = ({ blob: { url } } = { blob: {} }) => {
        this.setState({ ...this.state, newUrl: url });
    };
    componentDidUpdate(prevProps) {
        const { s3_object, s3_bucket, findMedia } = this.props;

        const bucketPresent = !!s3_bucket && prevProps.s3_bucket !== s3_bucket;
        const objectPresent = !!s3_object && prevProps.s3_object !== s3_object;

        if (bucketPresent && objectPresent) {
            findMedia(s3_object, s3_bucket, media => this.setMediaUrl(media));
        }
    }

    componentDidMount() {
        const { s3_object, s3_bucket, findMedia } = this.props;
        if (!!s3_object && !!s3_bucket) {
            findMedia(s3_object, s3_bucket, media => this.setMediaUrl(media));
        }
    }

    render() {
        const { s3_object, s3_bucket, url, title = '' } = this.props;
        const { newUrl } = this.state;

        if (!!s3_object && !!s3_bucket) {
            return !!newUrl ? <img src={newUrl} alt={title} /> : null;
        }

        if (!!url) {
            return <img src={url} alt={title} />;
        }

        return <i className="material-icons-outlined">photo</i>;
    }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            findMedia
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ImageRenderer);
