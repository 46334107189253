/**
 * Login with email and password.
 */
export const OTP_RESEND = `
    mutation _ {
        mfa_resend {
            success
        }
    }
`;
