import '../../models/models.scss';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingOverlay from 'react-loading-overlay';
import ReactModal from 'react-modal';
import SimpleBar from 'simplebar-react';
import Table from 'components/table';
import { listMatchingLots, listLots } from 'reducers/lots';
import CargoUnitField from './cargoUnitForm';
import CargoQuantityField from './cargoQuantityField';
import CargoTypeModal from 'pages/lots/components/cargoTypeModal';
import Tooltip from 'rc-tooltip';
import moment from 'moment';
import { tooltipWrapper } from 'utilities/common'
import { listCargoUnits } from 'reducers/cargoUnits';
import { setTransactionFieldLots } from 'reducers/transaction';
import 'pages/models/models.scss';
import { Notifications } from 'components/notifications';
import QueryBuilder from './inputLotQueryBuilder';
import { getIndices } from 'reducers/indices';
import { getModels } from 'reducers/models';
import FieldValueRenderer from 'pages/events/components/fieldValueRenderer';
import ColumnPicker, { getInitialColumnVisibility } from 'components/table/columnPicker';
import { setInputLotTableColumns } from 'reducers/sessionSettings';

class InputLotModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayPage: 1,
            selectedLots: [],
            exactMatchesOnly: false,
            lotMatchesExact: [],
            lotMatchesExactTotal: 0,
            lotMatches: [],
            lotMatchesTotal: 0,
            newLotSelected: false,
            selectedCargoTypes: [],
            targetQuantity: props.initialTargetQuantity,
            targetCargoUnit: props.initialTargetCargoUnit,
            remainderLots: [],
            inputLotQuantities: [],
            renderCount: 0,
            otherSelectedInputDotsInDash: []
        };
    }

    async componentDidMount() {
        const { listCargoUnits, targetTransactionField, transaction } = this.props;
        if (!this.props.cargoUnits) listCargoUnits()
        const txField = transaction.transaction_field_list.find(f => f.id === targetTransactionField);
        if (txField) {
            if (txField.input_lots && txField.input_lots.length > 0) {
                this.setState({ loading: true }, () => {
                    const inputLots = txField.input_lots.map(l => l.lot_id);
                    const inputLotQuantities = [];
                    for (const inputLotData of txField.input_lots) {
                        inputLotQuantities[inputLotData.lot_id] = inputLotData.quantity;
                    }
                    const outputLots = txField.output_lots ? txField.output_lots.map(l => ({ dotType: l.lot_type, dotUnit: l.cargo_unit_id, quantity: l.quantity })) : [];
                    this.setState( { 
                        newLotSelected: false, 
                        loading: true, 
                        selectedLots: inputLots, 
                        remainderLots: outputLots, 
                        inputLotQuantities,
                    }, () => {
                        this.loadData({}, (lots) => this.setState({loading: false, selectedCargoTypes: txField.input_lots.map(li => lots.data.find(l => l.id === li.lot_id)).filter(l => l !== undefined).map(l => l.cargo_type_hash_id)}));
                    });
                })
            } else {
                this.setState( { 
                    newLotSelected: !!this.props.initialTargetQuantity && this.props.initialTargetQuantity > 0,
                });
            }
        }
        const { getIndices, getModels } = this.props;
        const indicesPromise = new Promise(resolve => getIndices({ status_ids: [2], model_type: 2 }, r => this.setState({ columnVisibility: getInitialColumnVisibility(this.getColumns(r.data)) }, resolve)));
        const modelsPromise = new Promise(resolve => getModels(
            {model_type: 2}, 
            results => {
                this.setState({ dotTypes: results.data });
                resolve();
            },
            false
        ));
        let otherSelectedInputDotsInDash = [];
        transaction
            .transaction_field_list
            .filter(f => f.id !== targetTransactionField && f.input_lots && f.input_lots.length > 0)
            .forEach(f => {
                otherSelectedInputDotsInDash = otherSelectedInputDotsInDash.concat(f.input_lots);
            })
        this.setState({ otherSelectedInputDotsInDash });
        await Promise.all([indicesPromise, modelsPromise]);
    }

    loadData = (options, onComplete) => {
        let { columnVisibility } = this.state;
        const { targetCargoType, listLots, indexFields, inputLotTableColumns, targetTransactionField } = this.props;
        if (inputLotTableColumns != null) {
            columnVisibility = inputLotTableColumns;
        }
        const resultIndices = indexFields.filter(i => !!columnVisibility && columnVisibility[`field_${i.id}`] === true);
        options.result_index_field_ids = resultIndices.map(i => i.id);
        if (targetCargoType && targetCargoType.id) {
            listLots({
                ...options,
                cargo_categories: [targetCargoType.model_id],
                status_ids: [2]
            }, (lots) => {
                const lotMatches = lots.data.some(d => !!d.cargo_type.index_fields_data)
                ? lots.data.map(d => ({ ...d, cargo_type: { ...d.cargo_type, index_fields_data: undefined, ...JSON.parse(d.cargo_type.index_fields_data) }}))
                : lots.data;
                this.setState({ lotMatches: lotMatches, lotMatchesTotal: lots.total }, () => onComplete(lots));
            })
            // listMatchingLots({ cargo_type_id: targetCargoType.id, exact_matches: true, dash_field_id: targetTransactionField, ...options }, (lots) => {
            //     this.setState({ lotMatchesExact: lots.data, lotMatchesExactTotal: lots.total })
            //     listMatchingLots({cargo_type_id: targetCargoType.id, exact_matches: false, dash_field_id: targetTransactionField, ...options}, (lots) => {
            //         this.setState({ lotMatches: lots.data, lotMatchesTotal: lots.total }, onComplete)
            //     })
            // })
        } else {
            onComplete([]);
        }
    };

    setColumns = selectedColumns => {
        this.setState({ columnVisibility: selectedColumns });
        this.props.setInputLotTableColumns(selectedColumns);
    };

    toggleSelectedLot = (lotId, lotQuantity) => {
        this.setState( prevState => {
            const selectedLots = prevState.selectedLots;
            const dotQuantities = prevState.inputLotQuantities;
            const lots = prevState.lotMatches;
            const lot = lots.find(l => l.id === lotId);
            const selectedCargoTypes = prevState.selectedCargoTypes;
            const index = selectedLots.indexOf(lotId);
            if (index === -1) {
                selectedLots.push(lotId);
                selectedCargoTypes.push(lot.cargo_type_hash_id);
                if (dotQuantities[lotId] === undefined) {
                    dotQuantities[lotId] = lotQuantity;
                }
            } else {
                selectedLots.splice(index, 1);
                selectedCargoTypes.splice(selectedCargoTypes.indexOf(lot.cargo_type_hash_id), 1);
            }
            return { 
                selectedLots, 
                transactionIsUpdating: true, 
                selectedCargoTypes, 
                inputLotQuantities: dotQuantities, 
            };
        });
    } 

    getColumns = providedIndices => {
        const { intl: { formatMessage }, transaction } = this.props;
        const { otherSelectedInputDotsInDash } = this.state;
        const indexFields = this.props.indexFields || providedIndices || [];
        const columns = [
            {
                isDefault: true,
                headerClassName: 'd-sm-block col-2 d-none',
                className: 'd-xs-block col-2',
                sortable: false,
                resizable: false,
                width: 34,
                id: 'actions',
                accessor: d => {
                    let quantityUsedByOtherFields = 0;
                    const otherFieldReferencesToCurrentDot = otherSelectedInputDotsInDash.filter(ref => ref.lot_id === d.id);
                    if (otherFieldReferencesToCurrentDot.length > 0) {
                        quantityUsedByOtherFields = otherFieldReferencesToCurrentDot.reduce((sum, currentVal) => Number(sum) + Number(currentVal.quantity), 0);
                    }
                    const disabled = this.state.newLotSelected || d.used_by_other_field || d.created_by_dash_id === transaction.id || quantityUsedByOtherFields >= d.quantity;
                    let tooltipMessage = <FormattedMessage id='youMustSelectChooseExistingDot' />;
                    if (d.used_by_other_field) {
                        tooltipMessage = <FormattedMessage id='dotReferencedByFieldInDash' />;
                    } else if (d.created_by_dash_id === transaction.id) {
                        tooltipMessage = <FormattedMessage id='dashCanNotSelfReference' />;
                    } else if (quantityUsedByOtherFields >= d.quantity) {
                        tooltipMessage = <FormattedMessage id='dotWillBeConsumedByOtherFieldsInDash' />;
                    }
                    return tooltipWrapper(
                        disabled,
                        tooltipMessage,
                        <div className="actions-wrapper">
                            <input 
                                type="checkbox" 
                                checked={!this.state.newLotSelected && this.state.selectedLots && this.state.selectedLots.indexOf(d.id) > -1}  
                                onChange={() => this.toggleSelectedLot(d.id, d.quantity)}
                                disabled={disabled}
                            />
                        </div>,
                        'right'
                    )
                }
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block text-center',
                Header: <FormattedMessage id="common.id" />,
                width: 100,
                id: 'id',
                accessor: d => (
                    <a onClick={e => { e.stopPropagation(); e.preventDefault(); this.setState({ cargoType: d.cargo_type, cargoTypeModalOpen: true })}} href='#'>
                        { `${d.hash_id}`  }
                    </a>
                )
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block text-center',
                Header: <FormattedMessage id="common.cargoType" />,
                id: 'cargo_type_id',
                accessor: d => `${d.cargo_type.model.title} (${d.cargo_type.id})` 
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block text-center',
                Header: <FormattedMessage id="common.cargoUnitName" />,
                id: 'cargo_unit_name',
                accessor: d => d.cargo_unit ? <FormattedMessage id={d.cargo_unit.display_name} /> : ''
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block text-center',
                Header: <FormattedMessage id="common.quantity" />,
                width: 150,
                id: 'quantity',
                accessor: d => d.quantity
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block text-center',
                Header: <FormattedMessage id="common.status" />,
                id: 'status_name',
                accessor: d => <FormattedMessage id={d.status_name} defaultMessage={d.status_name} />
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block text-center',
                Header: <FormattedMessage id="common.alteredOn" />,
                id: 'modified_on',
                accessor: d => moment(d.modified_on || d.created_on).format('DD-MM-YYYY')
            },
            {
                isDefault: false,
                headerClassName: 'd-block',
                className: 'd-block text-center',
                Header: <FormattedMessage id="common.createdOn" />,
                id: 'created_on',
                accessor: d => moment(d.created_on).format('DD-MM-YYYY')
            },
            ...indexFields.map(i => ({
                headerClassName: 'd-sm-block d-none',
                className: 'd-sm-block d-none',
                Header: i.title,
                id: `field_${i.id}`,
                accessor: d => <FieldValueRenderer row={d.cargo_type} field={i} />
            }))
        ];

        columns.push({
            isDefault: true,
            headerClassName: 'd-block',
            className: 'd-block text-center',
            sortable: false,
            Header: <span>
                    <FormattedMessage id="quantityConsumed" />
                </span>,
            id: 'quantity_consumed',
            accessor: d => {
                const selectedLots = this.state.selectedLots;
                const isLotSelected = (selectedLots.indexOf(d.id) > -1);
                let maximumValue = d.quantity;
                let quantityUsedByOtherFields = 0;
                const otherFieldReferencesToCurrentDot = otherSelectedInputDotsInDash.filter(ref => ref.lot_id === d.id);
                if (otherFieldReferencesToCurrentDot.length > 0) {
                    quantityUsedByOtherFields = otherFieldReferencesToCurrentDot.reduce((sum, currentVal) => Number(sum) + Number(currentVal.quantity), 0);
                }
                maximumValue = maximumValue - quantityUsedByOtherFields;
                const defaultVal = this.state.inputLotQuantities[d.id] || maximumValue;
                return (
                    <input
                        key={'input-dot-' + d.id + '-quantity-' + this.state.renderCount}
                        type='number'
                        onChange={e => {
                            e.persist();
                            this.setState(prevState => {
                                const dotQuantities = prevState.inputLotQuantities;
                                dotQuantities[d.id] = e.target.value;
                                return { inputLotQuantities: dotQuantities }
                            })
                        }}
                        placeholder={formatMessage({ id: 'quantity' })}
                        defaultValue={defaultVal}
                        disabled={!isLotSelected || this.state.newLotSelected}
                        max={maximumValue}
                        min={0}
                        style={{width: '90%'}}
                    />
                )
            }
        })

        return columns;
    };

    confirmInputLots = () => {
        const lots = this.state.exactMatchesOnly ? this.state.lotMatchesExact : this.state.lotMatches;
        const { otherSelectedInputDotsInDash, selectedLots } = this.state;
        const inputLotsData = [];
        this.props.onQuantitySelected('');
        for (const lotId of selectedLots) {
            const targetLot = lots.find(l => l.id === lotId);
            if (targetLot === undefined) {
                console.error('DoT not found!', lotId, selectedLots);
                continue;
            }
            const quant = this.state.inputLotQuantities[lotId];

            let maximumValue = targetLot.quantity;
            let quantityUsedByOtherFields = 0;
            const otherFieldReferencesToCurrentDot = otherSelectedInputDotsInDash.filter(ref => ref.lot_id === lotId);
            if (otherFieldReferencesToCurrentDot.length > 0) {
                quantityUsedByOtherFields = otherFieldReferencesToCurrentDot.reduce((sum, currentVal) => Number(sum) + Number(currentVal.quantity), 0);
            }
            maximumValue = maximumValue - quantityUsedByOtherFields;

            if (quant === null || quant === undefined) {
                Notifications.error(<FormattedMessage id='inputDotQuantityNotSpecified'/>);
                return;
            } else if (quant > maximumValue) {
                Notifications.error(<FormattedMessage id='dotConsumedMustBeLessThanQuantity'/>);
                return;
            }
            inputLotsData.push({ lot_id: lotId, quantity: quant, unit_id: targetLot.cargo_unit_id });
        }
        this.setState({ loading: true }, () => {
            this.props.setTransactionFieldLots(
                { 
                    field_id: this.props.targetTransactionField, 
                    input: JSON.stringify({
                        input_lots: inputLotsData, 
                        output_lots: [],
                    })
                },
                () => { this.setState({ loading: false }, () => { this.props.onMultipleLotsSelected(inputLotsData); this.props.onClose() }) },
                () => this.setState({ loading: false })
            );
        })
    }

    inputLotPage = () => {
        const { hideCreateNewDot, targetCargoType, indexFields, prependColumns = [], appendColumns = [], inputLotTableColumns } = this.props;
        const { dotTypes = [] } = this.state;
        let { columnVisibility } = this.state;

        if (inputLotTableColumns != null) {
            columnVisibility = inputLotTableColumns;
        }

        const columns = prependColumns.concat(this.getColumns()).concat(appendColumns);
        let columnsToShow = columns.filter(c => !!c && !!c.id);
        let columnPicker = undefined;
        if (!!columnVisibility) {
            // only show columns related to the target cargo type
            columnsToShow = columns.filter(c => !!c && !!c.id && columnVisibility[c.id] === true)
            .filter(c => {
                if (!c.id.includes('field_')) {
                    return true;
                }
                if (targetCargoType) {
                    return dotTypes.filter(m => m.id === targetCargoType.model_id).some(m => m.model_field_list.some(mf => Number(mf.index_field_id) === Number(c.id.replace('field_', ''))))
                } else {
                    return true;
                }
            });
            columnPicker = onPicked => (
                <div className="row">
                    <div className="col">
                        <div className="float-right">
                            <ColumnPicker
                                columns={columns}
                                models={dotTypes.filter(m => m.id === targetCargoType.model_id)}
                                selectedColumns={columnsToShow.map(c => c.id)}
                                onChange={c => {
                                    this.setColumns(c);
                                    onPicked(c);
                                }}
                            />
                        </div>
                    </div>
                </div>
            );
        }
        const lots = this.state.exactMatchesOnly ? this.state.lotMatchesExact : this.state.lotMatches;
        const total = this.state.exactMatchesOnly ? this.state.lotMatchesExactTotal : this.state.lotMatchesTotal;

        return (
            <div className="modal-content  bd-0">
                <div className="modal-header pd-x-20">
                    <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                        <FormattedMessage id="common.inputLot" />
                    </h6>
                    <div>
                        <button
                            type="button"
                            className="btn btn-outline-info font-weight-bold ml-2"
                            onClick={() => { this.setState({ renderCount: this.state.renderCount + 1 }); this.props.onClose(); }}
                        >
                            {'X'}
                        </button>
                    </div>
                            
                </div>
                <SimpleBar> 
                    <div className="modal-body">
                        <div className="row">
                            <div className='col-5'>
                                {
                                    !hideCreateNewDot &&
                                    <div className="form-check">
                                        <input
                                            type="radio"
                                            name="input-lot"
                                            value={false}
                                            checked={!this.state.newLotSelected}
                                            className="form-check-input se-existing-lot-radio"
                                            onChange={_ => { 
                                                this.setState({ newLotSelected: false, targetQuantity: '' }); 
                                            }}
                                        />
                                        <FormattedMessage id="page.eventTemplates.chooseExistingLot" />
                                    </div>
                                }
                            </div>
                            <div className='col-2'/>
                            {/* <div className={`checkbox-field withPadding pd-b-5 col-5 justify-content-end`}>
                                <div className='float-right pd-x-25'>
                                    <label className="ckbox">
                                        <input type="checkbox" checked={this.state.exactMatchesOnly} onChange={(e) => { e.stopPropagation(); this.setState({ exactMatchesOnly: !this.state.exactMatchesOnly })}}/>
                                        <span className={`ckbox-label-text${this.state.exactMatchesOnly ? ' checked' : ''}`}><FormattedMessage id="common.exactMatches" /></span>
                                    </label>
                                </div>
                            </div> */}
                        </div>
                        <Table
                            key={'lots-table'}
                            columns={columnsToShow}
                            columnPicker={columnPicker}
                            data={lots}
                            total={Number(total)}
                            dataLoader={(options, onComplete) => this.loadData(options, onComplete)}
                            queryBuilder={setFilters => 
                                <QueryBuilder 
                                    fields={indexFields} 
                                    models={dotTypes} 
                                    modelsPlaceholder={<FormattedMessage id='dotTemplates'/>}
                                    onFiltersSet={(filters) => {
                                        if (this.props.onFiltersSet) {
                                            if (!filters.cargo_categories) {
                                                filters.cargo_categories = this.state.cargoCategories
                                            }
                                            this.props.onFiltersSet(filters)
                                        }
                                        setFilters(filters);
                                    }}
                                    externalModels={[targetCargoType.model_id]}
                                />
                            }
                        />
                        {
                            !hideCreateNewDot &&
                            <>
                                <div className="separator-with-text">
                                    <FormattedMessage id="page.eventTemplates.or" />
                                </div>
                                <div className="form-check">
                                    <div className="row">
                                        <div className="col-4">
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="input-lot"
                                                    value={true}
                                                    checked={this.state.newLotSelected}
                                                    className="form-check-input se-create-lot-radio"
                                                    onChange={_ => this.setState({ newLotSelected: true })}
                                                />
                                                <FormattedMessage className='pd-b-15' id="page.eventTemplates.createNewLot" />
                                            </label>
                                        </div>
                                        <div className={`pd-b-15 col-4`}>
                                            <CargoQuantityField
                                                key={'new-lot-quantity-' + this.state.newLotSelected}
                                                disabled={!this.state.newLotSelected}
                                                onQuantitySelected={val => this.setState({ targetQuantity: val })}
                                                initialTargetQuantity={this.state.targetQuantity}
                                            />
                                        </div>
                                        <CargoUnitField 
                                            disabled={!this.state.newLotSelected}
                                            onCargoUnitSelected={val => this.setState({ targetCargoUnit: val })}
                                            initialTargetCargoUnit={this.props.initialTargetCargoUnit}
                                            menuPlacement={'top'}
                                            width={4}
                                        />
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </SimpleBar>
                <div className="modal-footer justify-content-center">
                    {
                        this.state.newLotSelected ?
                            <button
                                type="button"
                                className="btn btn-primary col-4 pd-y-12 pd-x-25"
                                onClick={() => {
                                    this.props.setTransactionFieldLots(
                                        { field_id: this.props.targetTransactionField, input: JSON.stringify({ input_lots: [], output_lots: []})},
                                        () => { this.setState({ loading: false }, () => { this.props.onMultipleLotsSelected(); this.props.onClose() }) },
                                        () => this.setState({ loading: false })
                                    );
                                    this.props.onLotSelected('');
                                    this.props.onQuantitySelected(this.state.targetQuantity);
                                    this.props.onCargoUnitSelected(this.state.targetCargoUnit);
                                    this.props.onClose();
                                }}
                            >
                                <FormattedMessage id="confirm" />
                            </button>
                            :
                            <button
                                type="button"
                                className={'btn btn-primary col-4 pd-y-12 pd-x-25'}
                                onClick={() => {
                                    this.confirmInputLots();
                                }}
                            >
                                <FormattedMessage id="confirm" />
                            </button>
                    }
                    <button
                        type="button"
                        className="btn btn-secondary col-4 pd-y-12 pd-x-25"
                        onClick={() => { this.setState({ renderCount: this.state.renderCount + 1 }); this.props.onClose(); }}
                    >
                        <FormattedMessage id="common.close" />
                    </button>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div style={this.state.cargoTypeModalOpen ? {display: 'none'} : {}}>
                <ReactModal
                    isOpen={this.props.modalVisibility}
                    onRequestClose={this.props.onClose}
                    className="modal-block dialog"
                    overlayClassName="modal-overlay gray"
                    style={{content: {width: '85%', maxHeight: '90%', overflowY: 'auto', overflowX: 'hidden'}}}
                >
                    <div role="document">
                        <LoadingOverlay
                            active={this.props.transactionIsUpdating || this.state.loading}
                            spinner
                            text={<FormattedMessage id='common.loading'/>}
                        >
                            { this.inputLotPage() }
                        </LoadingOverlay>
                    </div>
                </ReactModal>
                <CargoTypeModal
                    modalOpen={this.state.cargoTypeModalOpen}
                    setModalOpen={(open) => this.setState({ cargoTypeModalOpen: open })}
                    cargoType={this.state.cargoType}
                />
            </div>
        );
    }
}

InputLotModal.defaultProps = {
    cargoUnits: [],
};

const mapStateToProps = state => {
    return {
        lots: state.lots.list.filter(l => l.status_name !== 'draft'),
        cargoUnits: state.cargoUnits.list,
        indexFields: state.indices.list ? state.indices.list.filter(i => i.status_id === 2) : [],
        inputLotTableColumns: state.sessionSettings.inputLotTableColumns,
    };
};
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            listMatchingLots,
            listLots,
            listCargoUnits,
            setTransactionFieldLots,
            getIndices,
            getModels,
            setInputLotTableColumns,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(InputLotModal));
