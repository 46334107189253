export const DOCUMENT_TEMPLATE_GET = `
    query($document_template_id: Long!) {
        document_template(document_template_id: $document_template_id) {
            id
            hash_id
            model_id
            title
            description
            paper_size
            page_orientation
            header {
                ... on TextElement {
                    ref_id
                    title
                    content
                    bold
                    italic
                    underline
                    text_alignment
                    width_offset
                    width
                    offset
                    type
                    break_row
                    order
                    margin_top
                    margin_bottom
                }
                ... on ImageElement {
                    ref_id
                    title
                    url
                    width_offset
                    width
                    offset
                    type
                    s3_bucket
                    s3_object
                    break_row
                    order
                    margin_top
                    margin_bottom
                }
                ... on RichTextElement {
                    ref_id
                    title
                    json
                    text_alignment
                    font
                    font_size
                    width_offset
                    width
                    offset
                    type
                    break_row
                    order
                    margin_top
                    margin_bottom
                }
            }
            body {
                ... on TextElement {
                    ref_id
                    title
                    content
                    bold
                    italic
                    underline
                    text_alignment
                    width_offset
                    width
                    offset
                    type
                    break_row
                    order
                    margin_top
                    margin_bottom
                }
                ... on ImageElement {
                    ref_id
                    title
                    url
                    width_offset
                    width
                    offset
                    type
                    s3_bucket
                    s3_object
                    break_row
                    order
                    margin_top
                    margin_bottom
                }
                ... on RichTextElement {
                    ref_id
                    title
                    json
                    text_alignment
                    font
                    font_size
                    width_offset
                    width
                    offset
                    type
                    break_row
                    order
                    margin_top
                    margin_bottom
                }
            }
            footer {
                ... on TextElement {
                    ref_id
                    title
                    content
                    bold
                    italic
                    underline
                    text_alignment
                    width_offset
                    width
                    offset
                    type
                    break_row
                    order
                    margin_top
                    margin_bottom
                }
                ... on ImageElement {
                    ref_id
                    title
                    url
                    width_offset
                    width
                    offset
                    type
                    s3_bucket
                    s3_object
                    break_row
                    order
                    margin_top
                    margin_bottom
                }
                ... on RichTextElement {
                    ref_id
                    title
                    json
                    text_alignment
                    font
                    font_size
                    width_offset
                    width
                    offset
                    type
                    break_row
                    order
                    margin_top
                    margin_bottom
                }
            }
            version
            file_data
            server_file_data
            document_type
            
            metadata

            ref_groups {
                document_template_id
                name
            }

            ref_group_references {
                document_template_id
                name
                field_reference_literal
            }
        }
    }
`;
