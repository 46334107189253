import React from 'react';
import { FormattedMessage } from 'react-intl';
import { call, hasErrors } from '../configuration/api';
import Queries from './queries';
import { Notifications } from '../components/notifications';
import { BLACKLIST_GET_LIST, BLACKLIST_DELETE } from './types';

const defaultState = {
    list: undefined,
    total: 0
};

/**
 * Blacklist state mapper.
 * @param {object} state
 * @param {string} action
 */
export default function(state = defaultState, action) {
    switch (action.type) {
        case BLACKLIST_GET_LIST:
            const { total, data } = action.payload;
            return { ...state, list: data, total: Number.parseInt(total) };

        case BLACKLIST_DELETE:
            return { ...state, list: (state.list || []).filter(d => d.id !== action.payload), total: state.total - 1 };

        default:
            return state;
    }
}

export const getBlacklist = (variables, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Blacklist.List, variables });
    if (hasErrors(response)) {
        onComplete();
        return;
    }

    const { data } = response;
    if (!data || !data.blacklist_entry_list) {
        Notifications.error(<FormattedMessage id="error.default" />);
        return;
    }

    dispatch({ type: BLACKLIST_GET_LIST, payload: data.blacklist_entry_list });
    onComplete();
};

export const deleteBlacklistRecord = (blacklist_id, onComplete = () => null) => async dispatch => {
    const response = await call({ query: Queries.Blacklist.Delete, variables: { blacklist_id } });
    if (!hasErrors(response)) {
        dispatch({ type: BLACKLIST_DELETE, payload: blacklist_id });
    }
    onComplete();
};
