import React from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { RowBreak, FieldDescription } from './common';
import { FormattedMessage } from 'react-intl';
// import '../../../../components/fields/fields.scss';
import '../../models.scss';
import Tooltip from 'rc-tooltip';

String.prototype.replaceAll = function(search, replacement) {
    var target = this;
    return target.split(search).join(replacement);
};

class NumberField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.initialValue
        };
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.initialValue !== prevProps.initialValue) {
          this.setState({ value: this.props.initialValue });
        }
    }

    getDecimalPlaces(n) {
        // Make sure it is a number and use the builtin number -> string.
        var s = "" + (+n);
        // Pull out the fraction and the exponent.
        var match = /(?:\.(\d+))?(?:[eE]([+\-]?\d+))?$/.exec(s);
        // NaN or Infinity or integer.
        // We arbitrarily decide that Infinity is integral.
        if (!match) { return 0; }
        // Count the number of digits in the fraction and subtract the
        // exponent to simulate moving the decimal point left by exponent places.
        // 1.234e+2 has 1 fraction digit and '234'.length -  2 == 1
        // 1.234e-2 has 5 fraction digit and '234'.length - -2 == 5
        return Math.max(
            0,  // lower limit.
            (match[1] == '0' ? 0 : (match[1] || '').length)  // fraction length
            - (match[2] || 0));  // exponent
      }

    changeValue = (id, value, change, setFieldValue, digitGroupSeparator, decimalSeparator, precision) => {
        const { min = -Number.MAX_VALUE, max = Number.MAX_VALUE, submitOnChange, onChangeExt = () => null } = this.props;
        const unformattedMinValue = min ? String(min).replaceAll(digitGroupSeparator, '').replaceAll(decimalSeparator, '.') : null;
        const minValue = isNaN(Number.parseFloat(unformattedMinValue)) ? -Number.MAX_VALUE : Number.parseFloat(unformattedMinValue);
        const unformattedMaxValue = max ? String(max).replaceAll(digitGroupSeparator, '').replaceAll(decimalSeparator, '.') : null;
        const maxValue = isNaN(Number.parseFloat(unformattedMaxValue)) ? Number.MAX_VALUE : Number.parseFloat(unformattedMaxValue);
        const unformattedChange = String(change).replaceAll(digitGroupSeparator, '').replaceAll(decimalSeparator, '.');
        const unformattedPrecision = String(precision).replaceAll(digitGroupSeparator, '').replaceAll(decimalSeparator, '.');


        const fieldValue = isNaN(Number.parseFloat(value)) ? 0 : Number.parseFloat(value);
        const changedValue = isNaN(Number.parseFloat(unformattedChange)) ? 0 : Number.parseFloat(unformattedChange);

        const places = unformattedPrecision ? this.getDecimalPlaces(unformattedPrecision) : 2;

        let result = Number(fieldValue + changedValue).toFixed(places);
        if (change > 0 && result >= maxValue) {
            result = maxValue;
        } else if (change < 0 && result <= minValue) {
            result = minValue;
        }
        // onChangeExt(id, result);

        result = String(result).replace('.', decimalSeparator);
        setFieldValue(id, result);
        this.setState({ value: result });
        // if (submitOnChange) {
        //     form.submitForm();
        // }
    };

    render() {
        const { 
            id, 
            width, 
            breakRow, 
            title, 
            defaultValue: dv, 
            step, 
            min = -Number.MAX_VALUE, 
            max = Number.MAX_VALUE,
            groupThousands = false,
            numberFormat = "DotSeparator",
            disabled,
            prependEls = [],
            isPreview = false,
            onChangeExt = () => null,
            tooltipOverlay,
            appendEls = [],
            seleniumId,
            setFieldValue,
        } = this.props;

        const { value } = this.state;

        const widthClass = width ? `-${width}` : '';
        const decimalSeparator = (numberFormat === "DotSeparator") ? "." : ",";
        const digitGroupSeparator = (numberFormat === "DotSeparator") ? ',' : '.';

        const unformattedDefaultValue = dv ? String(dv).replaceAll(digitGroupSeparator, '').replaceAll(decimalSeparator, '.') : null;
        let defaultValue = isNaN(Number.parseFloat(unformattedDefaultValue)) ? Number.parseFloat('0') : Number.parseFloat(unformattedDefaultValue);
        const unformattedValue = value ? String(value).replaceAll(digitGroupSeparator, '').replaceAll(decimalSeparator, '.') : null;
        const fieldValue = isNaN(Number.parseFloat(unformattedValue)) ? Number.parseFloat('0') : Number.parseFloat(unformattedValue);

        const unformattedMinValue = min ? String(min).replaceAll(digitGroupSeparator, '').replaceAll(decimalSeparator, '.') : null;
        const unformattedMaxValue = max ? String(max).replaceAll(digitGroupSeparator, '').replaceAll(decimalSeparator, '.') : null;
        const minValue = isNaN(Number.parseFloat(unformattedMinValue)) ? -Number.MAX_VALUE : Number.parseFloat(unformattedMinValue);
        const maxValue = isNaN(Number.parseFloat(unformattedMaxValue)) ? Number.MAX_VALUE : Number.parseFloat(unformattedMaxValue);
        
        const unformattedStep = step ? String(step).replaceAll(digitGroupSeparator, '').replaceAll(decimalSeparator, '.') : null;

        if (isPreview) {
            defaultValue = '';
        }

        const fieldEl = (
            <div className="number-group">
                <span className={'input-group-addon start no-select ' + (disabled ? 'disabled' : '')} onClick={() => !disabled && this.changeValue(id, fieldValue, '-' + step, setFieldValue, digitGroupSeparator, decimalSeparator, step)}>
                    -
                </span>
                <MaskedInput
                    className={`form-control se-id-${seleniumId}`}
                    autoComplete="off"
                    mask={createNumberMask({
                        allowDecimal: true,
                        decimalSymbol: decimalSeparator,
                        prefix: '',
                        suffix: '',
                        allowNegative: true,
                        includeThousandsSeparator: groupThousands,
                        thousandsSeparatorSymbol: digitGroupSeparator,
                        decimalLimit: unformattedStep ? this.getDecimalPlaces(unformattedStep) : 2
                    })}
                    id={id}
                    name={id}
                    placeholder={title}
                    defaultValue={defaultValue}
                    min={minValue}
                    max={maxValue}
                    onBlur={() => {
                        if (fieldValue > maxValue) {
                            this.changeValue(id, fieldValue, 1, setFieldValue, digitGroupSeparator, decimalSeparator, step);
                        } else if (fieldValue < minValue){
                            this.changeValue(id, fieldValue, -1, setFieldValue, digitGroupSeparator, decimalSeparator, step);
                        }
                    }}
                    onChange={e => {
                        const newValue = e.currentTarget.value;
                        setFieldValue(id, newValue);
                        this.setState({ value: newValue });
                    }}
                    value={this.state.value}
                    // onKeyDown={() => onChangeExt(id, fieldValue)}
                    disabled={disabled}
                />
                <span className={`input-group-addon ${appendEls.length > 0 ? 'end-with-append-el' : 'end'} no-select ${disabled ? 'disabled' : ''}`} onClick={() => !disabled && this.changeValue(id, fieldValue, step, setFieldValue, digitGroupSeparator, decimalSeparator, step)}>
                    +
                </span>
                {value !== undefined && value !== null && value !== '' && (
                    <Tooltip trigger={['hover']} overlay={<span>{title}</span>} mouseEnterDelay={2}>
                        <div className="input-placeholder">
                            {title}
                        </div> 
                    </Tooltip>
                )}
                {[...appendEls]}
                {this.props.showIsRequired && (value === undefined || value === null|| value === '') && <div className="validation-message required-placeholder"><FormattedMessage id="validation.required" /></div>}
            </div>
        );

        return (
            <React.Fragment>
                <div className={`pd-b-15 col${widthClass}`} style={{display: 'inline-block'}}>
                    {[...prependEls]}

                    <div className='field-number'>
                        {
                            tooltipOverlay ?
                            <Tooltip
                                placement={'top'}
                                trigger={['hover']}
                                overlay={tooltipOverlay}
                                children={ fieldEl }
                            />
                            :
                            fieldEl
                        }
                        <FieldDescription 
                            {...this.props} 
                            seleniumId={seleniumId}
                        />
                    </div>
                </div>
                {breakRow && <RowBreak width={width} />}
            </React.Fragment>
        );
    }
}

export default NumberField;
