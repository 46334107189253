import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { UserMenu, MainMenu, LanguageMenu } from '../menus';
import { toggleMenuVisibility } from '../../reducers/menus';
import defaultLogo from './../../assets/logo-green.svg';
import { getPages } from '../../pages';
import Loading from '../loading';
import { Link } from 'react-router-dom';
import { getProfile } from 'reducers/identity';
import { canAccessPage, canInteract } from 'components/routes/permissions/permissionResolver';
import Settings from 'pages/settings';
import { tooltipWrapper } from 'utilities/common'
import { Popover, Menu, MenuItem, } from '@blueprintjs/core';
import ReactResizeDetector from 'react-resize-detector';

import './header.scss';
import 'components/menus/user/user.scss';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            popoverOpen: false,
            windowSize: 0,
        };
      }

    componentDidMount() {
        const { authenticated, permissions, getProfile, location: { pathname } } = this.props;
        if (!!authenticated && !permissions && pathname !== '/mfa') getProfile();
    }

    getLogo = () => {
        const { company } = this.props;
        if (!company) return null;
        const { company_name, logo } = company;

        const action = () => (window.open('https://dorae.com', '_blank'));
        return (
            <div className="br-logo no-select" onClick={action}>
                {logo && logo.thumb && logo.thumb.url ? (
                    <img src={logo.thumb.url} style={{ maxHeight: '40px', maxWidth: '190px', margin: '0 auto' }} alt={company_name} />
                ) : (
                    <img src={defaultLogo} style={{ height: '35px', margin: '-6px auto 0 auto' }} alt={company_name} />
                )}
            </div>
        );
    };

    showLoadingIndicator = () => {
        const {
            location: { pathname },
            modelLoading,
            transactionLoading,
            templateChanged,
            rootLoadingState
        } = this.props;

        if (rootLoadingState) {
            return true;
        }
        if ((pathname.indexOf('/event-templates/') > -1 || pathname.indexOf('/object-templates/') > -1) && modelLoading > 0) {
            return true;
        }
        if ((pathname.indexOf('/event-templates/') > -1 || pathname.indexOf('/object-templates/') > -1) && pathname.indexOf('/document-templates/') > -1 && templateChanged) {
            return true;
        }
        if ((pathname.indexOf('transactions') > -1 || pathname.indexOf('events') > -1) && transactionLoading > 0) {
            return true;
        }

        return false;
    };

    render() {
        const { authenticated, toggleMenuVisibility, permissions, entityPermissions, company, showLanguageMenu = false, location: { pathname } } = this.props;
        if (!authenticated || pathname.indexOf('shared-invite') > -1 || pathname === '/mfa') return null;
        const settingsAvailable = canAccessPage(permissions, Settings.meta) && canInteract(entityPermissions, Settings.meta.requires);
        
        const links = getPages('main', permissions, entityPermissions, company);
        const workflowLinks = links.filter(l => l.title.props.id !== 'page.settings.title');
        const isActive = routes => routes.some(r => pathname.startsWith(r));

        const onMenuClick = () => {
            this.setState(prevState => ({ popoverOpen: !prevState.popoverOpen }));
        }

        const setSize = () => this.setState({ windowSize: window.innerWidth });
        const menuHasPopover = window.innerWidth > 639 && window.innerWidth < 992;
        const navigationMenu = menuHasPopover ?
            <Popover
                key={this.state.windowSize}
                position="bottom"
                isOpen={this.state.popoverOpen}
                content={
                    <Menu>
                        {
                            workflowLinks.map(({ title, icon, routes }, i) => (
                                <MenuItem
                                    key={icon}
                                    text={
                                        <Link to={{pathname: routes[0], search: window.location.search }} className={`br-menu-link ${isActive(routes) ? 'active' : ''}`} key={icon}>
                                            <div className="br-menu-item">
                                                <i className={`menu-item-icon icon ${icon} tx-22`} />
                                                <span className="menu-item-label">{title}</span>
                                            </div>
                                        </Link>
                                    }
                                    onClick={() => this.setState({ popoverOpen: false })}
                                />
                            ))
                        }
                    </Menu>
                }
            >
                <div className="navicon-left">
                    <div className="nav-icon" onClick={onMenuClick}>
                        <i className="icon ion-navicon-round" />
                    </div>
                </div>
            </Popover>
            :
            <div className="navicon-left" key={this.state.windowSize}>
                <div className="nav-icon" onClick={toggleMenuVisibility}>
                    <i className="icon ion-navicon-round" />
                </div>
            </div>
        
        const urlParams = new URLSearchParams(window.location.search);
        const hideNavBar = urlParams.get('hideNavBar');
        let navBar = (
            <div className="br-header">
                <div className="br-header-left">
                    { navigationMenu }
                    {/* {(window.location.hostname.indexOf('app') === -1) && <h1 className={'pd-x-25 pd-t-10'} style={{color: 'red'}}>DORAE DEVELOPMENT ENVIRONMENT</h1>} */}
                    {/* --- Hidden search box for now  --- */}
                    {/* <div className="input-group hidden-xs-down wd-170 transition">
                        <input id="searchbox" type="text" className="searchbox form-control" placeholder="Search" />
                        <span className="input-group-btn">
                            <button className="btn btn-secondary" type="button">
                                <i className="icon ion-search" />
                            </button>
                        </span>
                    </div> */}
                    {this.showLoadingIndicator() && (
                        <div className="hidden-xs-down transition">
                            <div className="loading-indicator">
                                <div className="loader" />
                                <span className="mg-l-5">
                                    <FormattedMessage id="common.saving" />
                                </span>
                            </div>
                        </div>
                    )}
                    <Loading />
                </div>
                <div className="br-header-right">
                    {
                        tooltipWrapper(
                            true,
                            <FormattedMessage id={settingsAvailable ? 'settingsLabelForLoggedAdmins' : 'settingsOnlyAvailableForAdmin'} />,
                            <Link to={{pathname: '/settings', search: window.location.search }} className={'dropdown' + (!settingsAvailable ? ' disabled' : '')}  onClick={e => settingsAvailable || e.preventDefault()}>
                                <nav>
                                    <span className="nav-link nav-link-profile" style={{display: 'flex', alignItems: 'center'}}>
                                        <span className="logged-name hidden-md-down"> <FormattedMessage id='settings'/> </span>
                                        <i className={`icon ion-ios-gear-outline wd-18 rounded-circle`} style={{fontSize: 22}}/>
                                    </span>
                                </nav>
                            </Link>,
                            null,
                            'dorae_settings-tooltip'
                        )
                    }
                    
                    <UserMenu />
                    <div className="menu-separator" />
                    {!!showLanguageMenu && <LanguageMenu />}
                </div>
            </div>
        )
        if (hideNavBar === 'true') {
            navBar = null;
        }

        return (
            <React.Fragment>
                {this.getLogo()}
                <MainMenu links={links} />
                { navBar }
                <ReactResizeDetector querySelector={'body'} handleWidth onResize={setSize} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        location: state.router.location,
        modelLoading: state.models.modelIsSaving,
        transactionLoading: state.transaction.loading,
        templateChanged: state.document.templateChanged,
        authenticated: state.identity.authenticated,
        permissions: state.identity.permissions,
        entityPermissions: state.identity.entityPermissions,
        company: state.identity.company,
    };
};
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            toggleMenuVisibility,
            getProfile,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);
