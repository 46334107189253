import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import ReactModal from 'react-modal';
import 'pages/models/models.scss';
import Table from 'components/table';
import { getUsers, getUserRoles } from 'reducers/users';
import thumb from 'assets/user.svg';
import LoadingOverlay from 'react-loading-overlay';

class SubscribersModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedUsers: []
        };
    }

    componentDidMount = () => {
        const { company, roles, getUserRoles } = this.props;
        if (this.props.subscribers) {
            this.setState({ selectedUsers: this.props.subscribers.map(s => s.id) });
        }
        if (!roles) getUserRoles({ company_id: company.id });
    }

    toggleUserSelected = (id) => {
        this.setState(prevState => {
            let allSelectedIds = prevState.selectedUsers;
            if (allSelectedIds.includes(id)) {
                allSelectedIds.splice(allSelectedIds.indexOf(id), 1);
            } else {
                allSelectedIds.push(id);
            }
            return { selectedUsers: allSelectedIds };
        });
    }

    userSelectorEl = (id) => {
        const { disabledIds = [], externalIds = [] } = this.props;
        const selectedIds = this.state.selectedUsers;
        const isUserSelected = (selectedIds && selectedIds.includes(id)) || (externalIds && externalIds.includes(id));
        return ( 
            <div className="actions-wrapper">
                <input 
                    type="checkbox" 
                    checked={isUserSelected}  
                    onChange={() => this.toggleUserSelected(id)}
                    disabled={disabledIds.indexOf(id) > -1}
                />
            </div>
        );
    }

    userAllSelectorEl = () => {
        const { users = [], disabledIds = [], externalIds = [] } = this.props;
        const { selectedUsers } = this.state;
        const allSelectedConfigurable = selectedUsers.filter(u => disabledIds.indexOf(u) === -1);
        const allAvailableConfigurable = users.filter(u => disabledIds.indexOf(u.id) === -1);
        const allUsersSelected = allSelectedConfigurable.length === allAvailableConfigurable.length;
        return ( 
            <div className="actions-wrapper" style={{height: '100%'}}>
                <input 
                    type="checkbox" 
                    checked={allUsersSelected}  
                    onChange={() => this.setState(prevState => {
                        let allSelectedIds = prevState.selectedUsers;
                        const allSelectedConfigurable = allSelectedIds.filter(u => disabledIds.indexOf(u) === -1);
                        const allAvailableConfigurable = users.filter(u => disabledIds.indexOf(u.id) === -1);
                        if (allSelectedConfigurable.length === allAvailableConfigurable.length) {
                            return { selectedUsers: externalIds };
                        } else {
                            return { selectedUsers: allAvailableConfigurable.map(u => u.id) };
                        }
                    })}
                />
            </div>
        );
    }

    getUserRole = (role_id) => {
        const { roles } = this.props;
        const role = roles.find(r => role_id === r.id);
        if (!role) return 'Unavailable';
        return role.title;
    }

    getColumns = () => {
        const { disabledIds = [] } = this.props;
        return [
            {
                isDefault: true,
                headerClassName: 'd-sm-block col-2 d-none',
                className: 'd-xs-block text-center col-2',
                sortable: false,
                resizable: false,
                Header: () => this.userAllSelectorEl(),
                width: 34,
                id: 'actions',
                accessor: d => (this.userSelectorEl(d.id))
            },
            {
                headerClassName: 'd-sm-block d-none',
                Header: <FormattedMessage id="common.name" />,
                id: 'name',
                accessor: d => d.name,
                Cell: row => {
                    const { original, value } = row;
                    const avatar = original.avatar && original.avatar.thumb && original.avatar.thumb.url ? original.avatar.thumb.url : thumb;
                    return (
                        <div>
                            <span className="d-sm-block d-none">{value}</span>
                            <div className="d-sm-none d-xs-block mobile-cell">
                                <div className="row align-items-center">
                                    <div className="col-auto text-center pl-4 pr-0">
                                        <img src={avatar} className="wd-40 rounded-circle" alt={original.name} />
                                    </div>
                                    <div className="col-9">
                                        <div className="font-weight-bold ellipsis">{value}</div>
                                        <div className="ellipsis">{original.email}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }
            },
            {
                headerClassName: 'd-sm-block d-none',
                className: 'd-sm-block d-none',
                Header: <FormattedMessage id="common.email" />,
                id: 'email',
                accessor: d => d.email
            },
            {
                headerClassName: 'd-sm-block d-none',
                className: 'd-sm-block d-none',
                Header: <FormattedMessage id="role.placeholder" />,
                id: 'role_id',
                accessor: d => this.getUserRole(d.role_id)
            },
            {
                headerClassName: 'd-sm-block d-none',
                className: 'd-sm-block d-none',
                Header: <FormattedMessage id="common.status" />,
                id: 'status_id',
                accessor: d => <FormattedMessage id={d.status_name} defaultMessage={d.status_name} />
            }
        ];
    };

    loadData = (options, onComplete) => {
        const { getUsers, company, externalOptions = null } = this.props;
        let users = getUsers({ company_ids: [company.id], status_ids: [2], ...options, ...externalOptions }, onComplete);
        return users;
    };

    render() {
        const { showModal, users, total, titleId = 'alertSubscribers' } = this.props;

        return (
            <ReactModal
                isOpen={showModal}
                onRequestClose={() => this.props.setModalVisibility(false)}
                className="modal-block dialog"
                overlayClassName="modal-overlay gray"
                style={{content: {width: '70%', maxHeight: '90%', overflowY: 'auto', overflowX: 'hidden'}}}
            >
                <LoadingOverlay
                    active={this.props.loading}
                    spinner
                    text={<FormattedMessage id='common.loading'/>}
                >
                    <div role="document">
                        <div className="modal-content bd-0">
                            <div className="modal-header pd-x-20">
                                <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                                    <FormattedMessage id={titleId} />
                                </h6>
                            </div>
                            <div className="modal-body pd-x-20">
                                <Table
                                    columns={this.getColumns()}
                                    data={users}
                                    total={total}
                                    dataLoader={(options, onComplete) => this.loadData(options, onComplete)}
                                />
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button
                                    type="button"
                                    className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                    onClick={() => {
                                        this.props.onConfirm(this.state.selectedUsers);
                                    }}
                                >
                                    <FormattedMessage id="common.confirm" />
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                    onClick={() => this.props.setModalVisibility(false)}
                                >
                                    <FormattedMessage id="common.cancel"  />
                                </button>
                            </div>
                        </div>
                    </div>
                </LoadingOverlay>
            </ReactModal>
        );
    }

}

const mapStateToProps = state => ({
    company: state.identity.company,
    users: state.users.list,
    total: state.users.total,
    roles: state.users.roles,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getUsers,
            getUserRoles,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubscribersModal);