import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import logo from 'assets/logo-green.svg';
import ConfirmModal from 'components/modals/confirm';
import { history } from 'configuration/history';
import { respondToSharingInvite } from 'reducers/sharing';
import { Notifications } from 'components/notifications';

class SharedEntityInvitation extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            inviteAccepted: false,
            inviteResponseConfirmModalOpen: false,
            inviteResponseCompleted: false,
        }
    }

    inviteResponseForm = () => {
        const { location } = this.props;
        const { linkData } = location.state || {};
        const sharedEntityMeta = JSON.parse(linkData.data);
        return (
            <React.Fragment>
                <p>
                    <FormattedHTMLMessage id="sharedUserEntity" 
                        values={{
                            username: sharedEntityMeta.inviting_user,
                            companyname: sharedEntityMeta.inviting_company_name,
                        }} 
                        tagName="span"
                    />
                </p>
                <p>
                    <FormattedHTMLMessage id="singleUserSharedEntityMessage" 
                        values={{
                            entity: sharedEntityMeta.entity_name,
                            entitytitle: sharedEntityMeta.entity_title,
                            companyname: sharedEntityMeta.inviting_company_name,
                        }} 
                        tagName="span"
                    />
                </p>
                <p>
                    <FormattedHTMLMessage id="sharedEntityWarning"
                        values={{
                            entity: sharedEntityMeta.entity_name,
                            companyname: sharedEntityMeta.inviting_company_name,
                        }} 
                        tagName="span"
                    />
                </p>
                <br/>
                <div className="row">
                    <div className='col-3 offset-3'>
                        <button
                            type="button"
                            className="btn btn-block btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                            onClick={() => {
                                this.setState({ inviteAccepted: true, inviteResponseConfirmModalOpen: true })
                            }}
                        >
                            <FormattedMessage id="accept" />
                        </button>
                    </div>
                    <div className='col-3'>
                        <button
                            type="button"
                            className="btn btn-block btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                            onClick={() => {
                                this.setState({ inviteAccepted: false, inviteResponseConfirmModalOpen: true })
                            }}
                        >
                            <FormattedMessage id="reject" />
                        </button>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    inviteResponseCompletedMessage = () => {
        const { authenticated } = this.props;
        if (this.state.inviteAccepted) {
            return authenticated ?
                <React.Fragment>
                    <p>
                        <FormattedMessage id="sharedInviteAcceptedMessageAuthenticated" />
                    </p>
                    <br/>
                    <div className="row">
                        <div className='col-6 offset-3'>
                            <button
                                type="button"
                                className="btn btn-block btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                onClick={() => history.push(`/login` + window.location.search)}
                            >
                                <FormattedMessage id="returnToDashboard" />
                            </button>
                        </div>
                    </div>
                </React.Fragment>
                :
                <React.Fragment>
                    <p>
                        <FormattedMessage id="sharedInviteAcceptedMessage" />
                    </p>
                    <br/>
                    <div className="row">
                        <div className='col-6 offset-3'>
                            <button
                                type="button"
                                className="btn btn-block btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                onClick={() => history.push(`/login` + window.location.search)}
                            >
                                <FormattedMessage id="login" />
                            </button>
                        </div>
                    </div>
                </React.Fragment>
        } else {
            return authenticated ?
                <React.Fragment>
                    <p>
                        <FormattedMessage id="sharedInviteRejectedMessageAuthenticated" />
                    </p>
                    <br/>
                    <div className="row">
                        <div className='col-6 offset-3'>
                            <button
                                type="button"
                                className="btn btn-block btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                onClick={() => history.push(`/login` + window.location.search)}
                            >
                                <FormattedMessage id="returnToDashboard" />
                            </button>
                        </div>
                    </div>
                </React.Fragment>
                :
                <React.Fragment>
                    <p>
                        <FormattedMessage id="sharedInviteRejectedMessage" />
                    </p>
                    <br/>
                    <div className="row">
                        <div className='col-6 offset-3'>
                            <button
                                type="button"
                                className="btn btn-block btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                onClick={() => history.push(`/login` + window.location.search)}
                            >
                                <FormattedMessage id="login" />
                            </button>
                        </div>
                    </div>
                </React.Fragment>
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="d-flex align-items-center justify-content-center bg-br-primary ht-100v">
                    <div className="login-wrapper wd-650 wd-xs-650 pd-25 pd-xs-40 bg-white rounded shadow-base">
                        <div className="signin-logo tx-center tx-28 tx-bold tx-inverse">
                            <img src={logo} alt="Dorae Inc" className="logo" />
                        </div>
                        <div className="tx-center mg-b-30 account-subtitle">
                            <FormattedMessage id="collaboration" />
                        </div>
                        {
                            this.state.inviteResponseCompleted ?
                            this.inviteResponseCompletedMessage()
                            :
                            this.inviteResponseForm()
                        }
                    </div>
                </div>
                <ConfirmModal
                    open={this.state.inviteResponseConfirmModalOpen}
                    setOpen={(o) => this.setState({ inviteResponseConfirmModalOpen: o })}
                    loading={this.state.inviteResponseConfirmModalLoading}
                    stayOpenOnConfirm={true}
                    onConfirm={() => {
                        const { location, respondToSharingInvite } = this.props;
                        const { linkData } = location.state || {};
                        this.setState({ inviteResponseConfirmModalLoading: true }, () => {
                            respondToSharingInvite(
                                { link_name: linkData.name, accepted: this.state.inviteAccepted }, 
                                () => {
                                    this.setState({ inviteResponseConfirmModalLoading: false, inviteResponseConfirmModalOpen: false, inviteResponseCompleted: true })
                                },
                                () => {
                                    this.setState({ inviteResponseConfirmModalLoading: false, inviteResponseConfirmModalOpen: false });
                                    // Notifications.error(<FormattedMessage id='sharingResponseErrorMessage' />)
                                }
                            )
                        })
                    }}
                    confirmLabel={this.state.inviteAccepted ? <FormattedMessage id="accept" /> : <FormattedMessage id="reject" />}
                    title={<FormattedMessage id='common.confirm' />}
                    body={(
                        <FormattedMessage id='sharedEntityConfirmMessage' values={{response: this.state.inviteAccepted ? 'accept' : 'reject' }} />
                    )}
                />
            </React.Fragment>
        );
    }
}

SharedEntityInvitation.meta = {
    routes: ['/accept-shared-invite'],
    menus: { main: false, user: false }
};

const mapStateToProps = state => ({
    authenticated: state.identity.authenticated,
});
const mapDispatchToProps = dispatch => bindActionCreators({
    respondToSharingInvite,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SharedEntityInvitation);
