import React from 'react';
import { FormattedMessage } from 'react-intl';
import Tooltip from 'rc-tooltip';
import { FieldDescription } from './common';
import '../../models.scss';
import CargoQuantityField from '../../../events/components/cargoQuantityField'
import CargoUnitField from '../../../events/components/cargoUnitForm'

class SectionField extends React.Component {
    constructor(props) {
        super(props);
        this.state = { expanded: true };
    }

    setExpanded = expanded => this.setState({ ...this.state, expanded });

    render() {
        const { expanded } = this.state;
        const { title, children, renderChild, isCargo = false, extra = null, seleniumId, default_unit, is_static_unit, readOnly, quantityLabel } = this.props;

        const completedFieldElement = 
            <div className={`field-text pd-b-15 col-12`}>
                <FormattedMessage id="common.noDotFieldSelected" />
            </div>;

        return (
            <React.Fragment>
                <div className={`field-section col-12 pd-b-15 ${expanded ? 'expanded' : 'collapsed'} se-id-${seleniumId}`}>
                    <div className="card primary">
                        <div className="card-header bg-transparent no-select" onClick={() => this.setExpanded(!expanded)}>
                            <div className="title-wrapper">
                                <Tooltip trigger={['hover']} overlay={<span>{title}</span>} mouseEnterDelay={2}>
                                    <h6 className="card-title tx-uppercase tx-12 mg-b-0 ellipsis section-title">{title}</h6>
                                </Tooltip>
                                <FieldDescription 
                                    {...this.props} 
                                    seleniumId={seleniumId}
                                />
                            </div>

                            <Tooltip
                                placement={'top'}
                                trigger={['hover']}
                                overlay={<span>{expanded ? <FormattedMessage id="common.collapse" /> : <FormattedMessage id="common.expand" />}</span>}
                            >
                                <i className="material-icons no-select">{expanded ? 'arrow_drop_up' : 'arrow_drop_down'}</i>
                            </Tooltip>
                        </div>
                        <div className="card-body">
                            <div className="section-body">
                                {(isCargo && children && children.length === 0) ? completedFieldElement : (children && children.map(c => <React.Fragment key={c}>{renderChild(c)}</React.Fragment>))}
                                {extra}
                                {(isCargo && children && children.length !== 0)? (
                                    <div className="row">
                                        <div className={`pd-b-15 col-4 offset-2`}>
                                            <CargoQuantityField
                                                disabled={readOnly}
                                                onQuantitySelected={_ => null}
                                                initialTargetQuantity={0}
                                                customLabel={quantityLabel}
                                            />
                                        </div>
                                        <CargoUnitField
                                            disabled={false}
                                            onCargoUnitSelected={_ => null}
                                            width={4}
                                            initialTargetCargoUnit={default_unit}
                                            disabled={readOnly || is_static_unit }
                                        />
                                    </div>
                                ):''}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default SectionField;
