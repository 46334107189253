import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Formik, Form } from 'formik';
import { Fields } from 'components/fields';
import { getModelStatuses } from 'reducers/models';
import { getComparators, getIndexValues, getFieldValues } from 'reducers/fieldVisibility';
import TagFilters from 'pages/events/components/queryBuilder/tagFilters';
import { FormattedMessage } from 'react-intl';
import ReactModal from 'react-modal';
import { getFormattedFieldValue } from 'models/field';
import FilterPreview from 'pages/events/components/queryBuilder/filterPreview';
import FieldFilters from 'pages/events/components/queryBuilder/fieldFilters';

class QueryBuilder extends React.Component {
    constructor(props) {
        super(props);
        this.state = props.initialState ? props.initialState : {
            filters: { status_ids: [2, 3] },
            advancedFilters: [],
            fieldFilters: [],
            modelValues: [],
            modal: { open: false },
            includeDeletedTemplates: false,
            includeArchivedTemplates: false,
        };
    }

    componentDidMount() {
        const { comparators, getComparators, statuses, getModelStatuses } = this.props;
        if (!comparators) getComparators();
        if (!statuses) getModelStatuses();
    }

    removeAdvancedFilter = id => {
        const { advancedFilters } = this.state;

        this.setState({ advancedFilters: [...advancedFilters.filter(l => l.id !== id)] }, () => {
            this.setFilters(undefined, this.state.advancedFilters, this.state.fieldFilters, undefined);
        });
    };

    removeFieldFilter = id => {
        const { fieldFilters } = this.state;

        this.setState({ fieldFilters: [...fieldFilters.filter(l => l.id !== id)] }, () => {
            this.setFilters(undefined, this.state.advancedFilters, this.state.fieldFilters, undefined);
        });
    }

    setFilters(filters, advancedFilters, fieldFilters, modalVisible = undefined) {
        const { onFiltersSet, fields, externalFilters = [], stateSetter = () => null, models } = this.props;
        const filteredModels = models
        .filter(m => 
            (this.state.includeDeletedTemplates || m.status_id !== 4)
            && (this.state.includeArchivedTemplates || m.status_id !== 3)
        );
        const modelFields = filteredModels.map(m => m.model_field_list).flat();

        let assignedAdvancedFilters = advancedFilters || this.state.advancedFilters;
        assignedAdvancedFilters = assignedAdvancedFilters.filter(f => f.hasOwnProperty('field') && f.hasOwnProperty('comparator') && externalFilters.find(ef => ef.id && ef.id === f.id) === undefined);
        let assignedFieldFilters = fieldFilters || this.state.fieldFilters;
        assignedFieldFilters = assignedFieldFilters.filter(f => f.hasOwnProperty('field') && f.hasOwnProperty('comparator'));
        
        this.setState({ 
                filters: filters || this.state.filters,
                advancedFilters: assignedAdvancedFilters,
                fieldFilters: assignedFieldFilters,
                showFieldFilterModal: modalVisible !== undefined ? !!modalVisible : this.state.showFieldFilterModal,
                modal: modalVisible !== undefined ? { open: !!modalVisible } : { ...this.state.modal },
            },
            () => {
                const transformedFilters = {
                    ...this.state.filters,
                    criteria: JSON.stringify(
                        this.state.advancedFilters.map(fd => {
                            let f = { ...fd };
                            if (f.comparator === 13) {
                                f.comparator = 9;
                                f.is_not = true;
                            }

                            if (f.comparator === 12) {
                                f.filterValue = null;
                            }

                            return {
                                ...f,
                                index_field_id: f.field,
                                comparator_id: f.comparator,
                                ...getFormattedFieldValue((fields.find(i => i.id === f.field) || {}).type_name, f.filterValue, !Array.isArray(f.filterValue)),
                                comparator: undefined,
                                id: undefined,
                                field: undefined,
                                filterValue: undefined
                            };
                        })
                    ),
                    field_criteria: JSON.stringify(
                        this.state.fieldFilters.map(fd => {
                            let f = { ...fd };
                            if (f.comparator === 13) {
                                f.comparator = 9;
                                f.is_not = true;
                            }

                            if (f.comparator === 12) {
                                f.filterValue = null;
                            }

                            return {
                                ...f,
                                field_id: f.field,
                                comparator_id: f.comparator,
                                ...getFormattedFieldValue((modelFields.find(i => i.id === f.field) || {}).type_name, f.filterValue, !Array.isArray(f.filterValue)),
                                comparator: undefined,
                                id: undefined,
                                field: undefined,
                                filterValue: undefined
                            };
                        })
                    )
                };
                stateSetter(this.state);
                onFiltersSet(transformedFilters);
            }
        );
    }

    formatFilters = (values, setFieldValue) => {
        let updatedValues = { ...values };
        let momentFrom, momentTo;
        if (!!values.createdFrom && !!values.to) {
            momentFrom = moment(values.createdFrom, 'DD-MMM-YYYY', true);
            momentTo = moment(values.to, 'DD-MMM-YYYY', true);
            let bothValid = true;
            if (!momentFrom.isValid()) {
                updatedValues.createdFrom = null;
                bothValid = false;
            } 
            if (!momentTo.isValid()) {
                updatedValues.to = null;
                bothValid = false;
            } 
            if (bothValid && momentFrom > momentTo) {
                let newTo = momentFrom.add(1, 'days').toDate();
                setFieldValue('to', newTo);
                updatedValues.to = newTo;
            }
        } else  {
            if (!!values.createdFrom) {
                momentFrom = moment(values.createdFrom, 'DD-MMM-YYYY', true);
                if (!momentFrom.isValid()) {
                    updatedValues.createdFrom = null;
                } 
            }
            if (!!values.to) {
                momentTo = moment(values.to, 'DD-MMM-YYYY', true);
                if (!momentTo.isValid()) {
                    updatedValues.to = null;
                } 
            }
        }
        if (updatedValues.createdFrom === '') {
            updatedValues.createdFrom = null;
        }
        if (updatedValues.to === '') {
            updatedValues.to = null;
        }
        const { models, status, createdFrom, to } = updatedValues;
        const filters = {
            status_ids: status,
            cargo_categories: models,
            created_from: createdFrom,
            created_to: to
        };

        return filters;
    };

    setModalVisibility = open => {
        this.setState({ ...this.state, modal: { open } });
    };

    handleSubmit = (values, form) => {
        const { valuesSetter = () => null } = this.props;
        valuesSetter(values);
        this.setState({ modelValues: values.models || [] });
        const filters = this.formatFilters(values, form.setFieldValue);
        this.setFilters(filters);
    };

    renderForm = () => {
        const { filters, advancedFilters, modal, fieldFilters } = this.state;
        const { 
            fields, 
            models, 
            comparators,
            indexValues, 
            getIndexValues, 
            unavailableStatuses = [], 
            statusSelectorDisabled = false,
            targetEventTemplate,
            eventTemplateSelectorDisabled = false,
            externalFilters = [],
            externalModels = [],
            modelsPlaceholder = null,
            getFieldValues,
            fieldValues,
        } = this.props;

        const statuses = [
            { id: 2, status_name: 'unclaimed' }, 
            { id: 3, status_name: 'claimed' },
        ];

        const allTagFilters = externalFilters.concat(advancedFilters);

        const filteredModels = models
        .filter(m => 
            (this.state.includeDeletedTemplates || m.status_id !== 4)
            && (this.state.includeArchivedTemplates || m.status_id !== 3)
        );

        return (
            <React.Fragment>
                <Formik
                    enableReinitialize={true}
                    initialValues={this.props.initialValues || { status: filters.status_ids }}
                    onSubmit={(values, form) => this.handleSubmit(values, form)}
                    render={({ values, handleChange, submitForm }) => {
                        return (
                            <Form
                                noValidate
                                onChange={async e => {
                                    await handleChange(e);
                                    submitForm();
                                }}
                            >
                                <div className="row">
                                    <div className="col-4">
                                        <Fields.Date name="createdFrom" submitOnChange={true} />
                                    </div>
                                    <div className="col-4">
                                        <Fields.Date name="to" submitOnChange={true} />
                                    </div>
                                    <div className="col-4">
                                        {
                                            externalModels.concat(values.models || []).length > 0 ?
                                                <button
                                                    className="btn btn-primary col icon-button field-inline"
                                                    type="button"
                                                    onClick={() => this.setState({ showFieldFilterModal: true })}
                                                >
                                                    <i className="material-icons-outlined">filter_list</i>
                                                    <span>
                                                        <FormattedMessage id="fieldFiltersWithCount" values={{ count: fieldFilters.length }} />
                                                    </span>
                                                </button>
                                                :
                                                <button
                                                    className="btn btn-primary col icon-button field-inline"
                                                    type="button"
                                                    onClick={() => this.setModalVisibility(true)}
                                                >
                                                    <i className="material-icons-outlined">local_offer</i>
                                                    <span>
                                                        <FormattedMessage id="page.transactions.filters" values={{ count: allTagFilters.length }} />
                                                    </span>
                                                </button>
                                        }
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                />
                <ReactModal
                    isOpen={modal.open}
                    onRequestClose={() => this.setModalVisibility(false)}
                    className="modal-block dialog"
                    overlayClassName="modal-overlay gray"
                >
                    <TagFilters
                        fields={fields}
                        filters={allTagFilters}
                        availableModels={filteredModels}
                        selectedModelIds={externalModels.concat(this.state.modelValues)}
                        comparators={comparators}
                        indexValues={indexValues}
                        loadIndexValues={getIndexValues}
                        onFiltersSet={f => this.setFilters(undefined, f, fieldFilters, false)}
                        onCancel={() => this.setModalVisibility(false)}
                    />
                </ReactModal>
                <ReactModal
                    isOpen={this.state.showFieldFilterModal}
                    onRequestClose={() => this.setState({ showFieldFilterModal: false })}
                    className="modal-block dialog"
                    overlayClassName="modal-overlay gray"
                >
                    <FieldFilters
                        fields={filteredModels.map(m => m.model_field_list).flat()}
                        filters={fieldFilters}
                        comparators={comparators}
                        indexValues={fieldValues}
                        loadIndexValues={getFieldValues}
                        availableModels={filteredModels}
                        selectedModelIds={externalModels.concat(this.state.modelValues)}
                        onFiltersSet={f => this.setFilters(undefined, allTagFilters, f, false)}
                        onCancel={() => this.setState({ showFieldFilterModal: false })}
                    />
                </ReactModal>
            </React.Fragment>
        );
    };

    render() {
        const { 
            fields, 
            statuses, 
            comparators,
            externalFilters = [],
            models,
        } = this.props;
        const { advancedFilters, fieldFilters } = this.state;
        const allFilters = externalFilters.concat(advancedFilters);
        if (!comparators || !statuses) return null;

        const modelFields = models
            .filter(m => 
                (this.state.includeDeletedTemplates || m.status_id !== 4)
                && (this.state.includeArchivedTemplates || m.status_id !== 3)
            )
            .map(m => m.model_field_list)
            .flat();

        return (
            <div className="row">
                <div className="col-12 mg-b-15">{this.renderForm()}</div>
                <FilterPreview filters={allFilters} fieldFilters={fieldFilters} comparators={comparators} fields={fields} modelFields={modelFields} onRemove={this.removeAdvancedFilter} onRemoveFieldFilter={this.removeFieldFilter} />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    comparators: state.fieldVisibility.comparators,
    statuses: state.models.statuses,
    indexValues: state.fieldVisibility.indexValues,
    fieldValues: state.fieldVisibility.fieldValues,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getModelStatuses,
            getComparators,
            getIndexValues,
            getFieldValues,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(QueryBuilder);
