export const BLACKLIST_LIST = `
    query($user_ids: [Int!], $created_from: DateTime, $created_to: DateTime, $order_by: [OrderBy], $offset: Int, $limit: Int) {
        blacklist_entry_list(
            input: { user_ids: $user_ids, created_from: $created_from, created_to: $created_to }
            paging: { order_by: $order_by, offset: $offset, limit: $limit }
        ) {
            total
            data {
                id
                created_on
                exp_on
                user_id
                added_by_user_id
                company_id

                # Associations
                user {
                    id
                    email
                }

                user_added {
                    id
                    email
                }
            }
        }
    }
`;
