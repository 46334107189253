import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { history } from 'configuration/history';
import Page from 'components/page';
import { createAlert, deleteAlert } from 'reducers/alerts'
import { getProfile } from 'reducers/identity';
import ConfirmModal from 'components/modals/confirm';
import 'pages/models/models.scss';
import AlertsTable from './table';

class AlertList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deleteTargetMultiIds: [],
            tableRenderCount: 1,
        }
    }

    async componentDidMount() {
        const { company, getProfile } = this.props;
        if (!company) {
            getProfile();
        }
    }

    toggleDeleteTarget = (id) => {
        this.setState(prevState => {
            const targetIds = prevState.deleteTargetMultiIds;
            const index = targetIds.indexOf(id);
            if (index === -1) {
                targetIds.push(id);
            } else {
                targetIds.splice(index, 1);
            }
            return { deleteTargetMultiIds: targetIds };
        })
    }

    eventAllSelectorEl = () => {
        const { alerts } = this.props;
        const { deleteTargetMultiIds, isDeletingMulti } = this.state;
        const allEventsSelected = deleteTargetMultiIds.length === alerts.length;
        if (isDeletingMulti) {
            return ( 
                <div className="actions-wrapper" style={{height: '100%'}}>
                    <input 
                        type="checkbox" 
                        checked={allEventsSelected}  
                        onChange={() => this.setState(prevState => {
                            let allSelectedIds = prevState.deleteTargetMultiIds;
                            if (allSelectedIds.length === alerts.length) {
                                return { deleteTargetMultiIds: [] };
                            } else {
                                return { deleteTargetMultiIds: alerts.map(t => t.id) };
                            }
                        })}
                    />
                </div>
            );
        } else {
            return null;
        }
    }

    overrideMenuEl = () => {
        return this.state.isDeletingMulti ?
            (d) => (
                <div className="actions-wrapper">
                    <input 
                        type="checkbox" 
                        checked={this.state.deleteTargetMultiIds && this.state.deleteTargetMultiIds.indexOf(d.id) > -1}  
                        onChange={() => this.toggleDeleteTarget(d.id)}
                    />
                </div>
            )
            :
            undefined;
    }

    deleteMultiButtonGroup = () => {
        if (this.state.isDeletingMulti) {
            const deleteButton = this.state.deleteTargetMultiIds && this.state.deleteTargetMultiIds.length > 0 ?
                (
                    <span 
                        className="btn btn-outline-info" 
                        onClick={() => {
                            this.setState({ confirmMultiModalOpen: true });
                        }
                    }>
                        <FormattedMessage id="deleteSelectedAlerts" values={{count: this.state.deleteTargetMultiIds.length}} />
                    </span>
                )
                :
                (
                    <span 
                        className="btn btn-outline-info disabled" 
                        disabled={true}
                        onClick={() => null
                    }>
                        <FormattedMessage id="noAlertsSelected" />
                    </span>
                )
            return (
                <React.Fragment>
                    { deleteButton }
                    <span 
                        className="btn btn-outline-info" 
                        onClick={() => {
                            this.setState({ isDeletingMulti: false, confirmMultiModalOpen: false, deleteTargetMultiIds: [] });
                        }
                    }>
                        <FormattedMessage id="cancel" />
                    </span>
                </React.Fragment>
            );
        } else {
            return (
                <span 
                    className="btn btn-outline-info" 
                    onClick={() => {
                        this.setState({ isDeletingMulti: true, deleteTargetMultiIds: [] });
                    }
                }>
                    <FormattedMessage id="deleteAlerts" />
                </span>
            );
        }
    }

    renderActions = () => {
        const { company, createAlert } = this.props;
        return (
            <div className="btn-group pd-t-10">
                <span 
                    className="btn btn-outline-info" 
                    onClick={() => {
                        createAlert({ title: 'New Alert' }, a => history.push(`/intelligence/alerts/${a.id}` + window.location.search))
                    }
                }>
                    <FormattedMessage id="newAlert" />
                </span>
                { this.deleteMultiButtonGroup() }
            </div>
        );
    };

    render() {
        const { company, deleteAlert, intl: { formatMessage }, } = this.props;
        if (!company) return null;
        return (
            <Page {...AlertList.meta} actions={this.renderActions()} parents={this.props.parents}>
                <AlertsTable
                    overrideMenuEl={this.overrideMenuEl()}
                    overrideMenuHeader={this.eventAllSelectorEl()}
                    externalRenderCount={this.state.tableRenderCount}
                />
                <ConfirmModal
                    open={this.state.confirmMultiModalOpen}
                    setOpen={(o) => this.setState({ confirmMultiModalOpen: o })}
                    onConfirm={() => {
                        const { deleteTargetMultiIds } = this.state;
                        const promises = [];
                        for (const alertId of deleteTargetMultiIds) {
                            promises.push(new Promise(resolve => {
                                deleteAlert({ alert_id: alertId }, resolve, resolve);
                            }));
                        }
                        Promise.all(promises).then(() => {
                            // re-render the table
                            this.setState( prevState => {
                                return { isDeletingMulti: false, tableRenderCount: prevState.tableRenderCount + 1 };
                            });
                        })

                    }}
                    title={<FormattedMessage id='common.confirmVerb' values={{ verb:  formatMessage({ id: 'deleteSelected' })}} />}
                    body={<FormattedMessage id='confirmAlertDeleteMultiple' />}
                    
                />
            </Page>
        );
    }
}

AlertList.meta = {
    title: <FormattedMessage id="alerts" />,
    subtitle: <FormattedMessage id="manageAlerts" />,
    routes: ['/intelligence/alerts'],
    icon: 'ion-clipboard',
    menus: { main: true, user: false },
    order: 30
};

AlertList.defaultProps = {
    models: [],
    total: 0
};

const mapStateToProps = state => ({
    alerts: state.alerts.alertsList,
    company: state.identity.company,
    total: state.alerts.alertsTotal,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getProfile,
            createAlert,
            deleteAlert,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(AlertList));
