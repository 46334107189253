import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Button, Popover, Menu, MenuItem, MenuDivider, Icon } from '@blueprintjs/core';
import moment from 'moment';
import { filterTransactions, deleteTransaction } from 'reducers/transactions';
import Table from 'components/table';
import { getIndices } from 'reducers/indices';
import QueryBuilder from 'pages/events/components/queryBuilder';
import { loadModel, getModels } from 'reducers/models';
import FieldValueRenderer from 'pages/events/components/fieldValueRenderer';
import ColumnPicker, { getInitialColumnVisibility } from 'components/table/columnPicker';
import { history } from 'configuration/history';
import Tooltip from 'rc-tooltip';
import Select from 'react-select';
import ReactModal from 'react-modal';

import 'pages/models/models.scss';
import FadeIn from 'react-fade-in';
import { uuid, onlyUnique } from 'utilities/common';
import { getFormattedFieldValue } from 'models/field';

class FieldReferenceEventSelector extends React.Component {
    constructor(props) {
        super(props);
        const {template, fieldRef} = props;
        // default to multiple if available
        const isMultiAvailable = template.document_type === 'excel' 
            || (fieldRef.type === 'index' && fieldRef.field.type_name === 'Number')
            || (fieldRef.token.indexOf('l1') > -1)

        this.state = {
            columnVisibility: [],
            isMulti: isMultiAvailable,
            selectedEventIds: [],
            selectedModels: [],
            initialFieldRefModels: [],
        };
        // omit 'failed' - we never want to search these for docs
        this.statuses = [
            { id: 1, name: 'Draft' },
            { id: 2, name: 'Pending' },
            { id: 3, name: 'Completed' },
            { id: 4, name: 'Archived' },
            { id: 6, name: 'Deleted' },
        ];            
    }

    componentDidMount() {
        const { getIndices, loadModel, getModels, template, fieldRef } = this.props;
        this.setState({ columnVisibility: getInitialColumnVisibility(this.getColumns()) })
        getIndices({ status_ids: [2] }, r => this.setState({ columnVisibility: getInitialColumnVisibility(this.getColumns(r.data)) }));
        getModels({})
        if (fieldRef.type === 'model') {
            const modelPromises = [];
            fieldRef.event_criteria.model_ids.forEach(id => {
                modelPromises.push(new Promise((resolve, reject) => loadModel(id, resolve, reject)));
            })
            Promise.all(modelPromises).then(models => {
                this.setState(prevState => {
                    return { initialFieldRefModels: prevState.initialFieldRefModels.concat(models) }
                })
            })
        }
    }

    isMultiAvailable = () => {
        const {template, fieldRef} = this.props;
        return template.document_type === 'excel' 
        || (fieldRef.type === 'index' && fieldRef.field.type_name === 'Number')
        || (fieldRef.type === 'model' && fieldRef.field.type_name === 'Number')
        || (fieldRef.token.indexOf('l1') > -1)
    }


    loadData = (options, onComplete) => {
        const { columnVisibility } = this.state;
        const { filterTransactions, indexFields = [], fieldRef, models = [] } = this.props;

        if (fieldRef.type === 'index') {
            let criteria = [];
            if (options.criteria !== undefined && options.criteria.length > 0) {
                criteria = JSON.parse(options.criteria);
            } else {
                criteria.push( { id: uuid(8), text_values: [''], break_or: false, is_not: false, index_field_id: fieldRef.field.id, comparator_id: 1 } );
            }
            options.criteria = JSON.stringify(criteria);
        } else {
            let model_ids = [];
            if (options.model_ids !== undefined && options.model_ids.length > 0) {
                model_ids = options.model_ids;
            }
            model_ids = model_ids.concat(fieldRef.event_criteria.model_ids);
            options.model_ids = model_ids;
        }
        if (options.model_ids) {
            this.setState({ selectedModels: (models.concat(this.state.initialFieldRefModels)).filter(m => options.model_ids.includes(m.id)) });
        } else {
            this.setState({ selectedModels: [] });
        }

        const availableOptions = this.statuses.filter(s => (this.props.unavailableStatuses || []).indexOf(s.name) < 0).map(s => s.id);
        if (!options.status_ids) options.status_ids = [1,3,7];
        options.status_ids = options.status_ids.filter(s => availableOptions.indexOf(s) > -1);
        
        const resultIndices = indexFields.filter(i => !!columnVisibility && columnVisibility[`field_${i.id}`] === true);
        return filterTransactions(
            {
                result_index_field_ids: resultIndices.map(i => i.id),
                transaction_type: 1,
                ...options
            },
            onComplete
        );
    };

    setColumns = selectedColumns => {
        this.setState({ ...this.state, columnVisibility: selectedColumns });
    };

    getColumns = providedIndices => {
        const indexFields = this.props.indexFields || providedIndices || [];

        const columns = [
            {
                isDefault: true,
                headerClassName: 'd-sm-block col-2 d-none',
                className: 'd-xs-block text-center col-2',
                sortable: false,
                resizable: false,
                Header: () => this.eventAllSelectorEl(),
                width: 34,
                id: 'actions',
                accessor: d => (this.eventSelectorEl(d.id))
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block text-center id-column',
                Header: <FormattedMessage id="common.id" />,
                width: 100,
                id: 'id',
                accessor: d => <a href='#' onClick={e => { e.stopPropagation(); e.preventDefault(); this.selectEventIdMultiOrSingle(d.id); }}>{d.id}</a>
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block',
                Header: <FormattedMessage id="common.title" />,
                id: 'title',
                accessor: d => d.title
            },
            {
                isDefault: false,
                headerClassName: 'd-block',
                className: 'd-block',
                Header: <FormattedMessage id="common.description" />,
                id: 'description',
                sortable: false,
                accessor: d => d.description
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block text-center',
                Header: <FormattedMessage id="common.status" />,
                id: 'status_name',
                width: 100,
                accessor: d => <FormattedMessage id={d.status_name} defaultMessage={d.status_name} />
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block',
                Header: <FormattedMessage id="common.model" />,
                id: 'model_id',
                accessor: d => d.model.title
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block',
                Header: <FormattedMessage id="common.createdBy" />,
                id: 'user_name',
                accessor: d => d.user_name
            },
            {
                isDefault: true,
                headerClassName: 'd-block',
                className: 'd-block text-center',
                Header: <FormattedMessage id="common.alteredOn" />,
                id: 'modified_on',
                accessor: d => moment(d.modified_on || d.created_on).format('DD-MM-YYYY')
            },
            {
                isDefault: false,
                headerClassName: 'd-block',
                className: 'd-block text-center',
                Header: <FormattedMessage id="common.createdOn" />,
                id: 'created_on',
                accessor: d => moment(d.created_on).format('DD-MM-YYYY')
            },
            ...indexFields.map(i => ({
                headerClassName: 'd-sm-block d-none',
                className: 'd-sm-block d-none',
                Header: i.title,
                id: `field_${i.id}`,
                accessor: d => <FieldValueRenderer row={d} field={i} />
            }))
        ];

        return columns;
    };

    tooltipWrapper = (showTooltip, overlay, children) => {
        return showTooltip ? 
            <Tooltip placement={'bottom'} trigger={['hover']} overlay={overlay} children={children} />
             :
            <div children={children} />
    }

    setEventIdSelected = (id) => {
        this.setState(prevState => {
            let allSelectedIds = prevState.selectedEventIds;
            if (allSelectedIds.includes(id)) {
                allSelectedIds.splice(allSelectedIds.indexOf(id), 1);
            } else {
                allSelectedIds.push(id);
            }
            return { selectedEventIds: allSelectedIds };
        })
    }

    selectEventIdMultiOrSingle = (id) => {
        const { isMulti } = this.state;
        if (this.isMultiAvailable() && isMulti) {
            this.setEventIdSelected(id);
        } else {
            this.setState({ selectedEventIds: [id] })
        }
    }
    
    eventSelectorEl = (id) => {
        const { isMulti } = this.state;
        const selectedIds = this.state.selectedEventIds;
        const isEventSelected = selectedIds && selectedIds.includes(id);
        if (this.isMultiAvailable() && isMulti) {
            return ( 
                <div className="actions-wrapper">
                    <input 
                        type="checkbox" 
                        checked={isEventSelected}  
                        onChange={() => this.setEventIdSelected(id)}
                    />
                </div>
            );
        } else {
            return ( 
                <div className="actions-wrapper">
                    <div className="form-check">
                        <input
                            type="radio"
                            name="event-select"
                            checked={isEventSelected}
                            className="form-check-input"
                            onChange={() => this.setState({ selectedEventIds: [id] })}
                        />
                    </div>
                </div>
            );
        }
    }

    eventAllSelectorEl = () => {
        const { transactions } = this.props;
        const { isMulti } = this.state;
        const selectedIds = this.state.selectedEventIds;
        const allEventsSelected = selectedIds.length === transactions.length;
        if (this.isMultiAvailable() && isMulti) {
            return ( 
                <div className="actions-wrapper" style={{height: '100%'}}>
                    <input 
                        type="checkbox" 
                        checked={allEventsSelected}  
                        onChange={() => this.setState(prevState => {
                            let allSelectedIds = prevState.selectedEventIds;
                            if (allSelectedIds.length === transactions.length) {
                                return { selectedEventIds: [] };
                            } else {
                                return { selectedEventIds: transactions.map(t => t.id) };
                            }
                        })}
                    />
                </div>
            );
        } else {
            return null;
        }
    }

    render() {
        const { transactions, total, indexFields = [], models = [], fieldRef, template, onFieldSearchCriteriaSet = () => null } = this.props;
        const { columnVisibility } = this.state;
        if (!columnVisibility) return null;
        const isMultiAvailable = this.isMultiAvailable();

        const fieldFilters = [];
        let fieldModels = [];
        if (fieldRef.type === 'index') {
            fieldFilters.push( { id: uuid(8), filterValue: '', break_or: false, is_not: false, field: fieldRef.field.id, comparator: 1 } );
        } else if (fieldRef.type === 'model') {
            fieldModels = fieldModels.concat(fieldRef.event_criteria.model_ids);
        }
        const columns = this.getColumns();
        let columnsToShow = columns.filter(c => !!c && !!c.id && columnVisibility[c.id] === true);
        const columnPicker = onPicked => (
            <div className="row">
                <div className="col">
                    <div className="float-right">
                        <ColumnPicker
                            columns={columns}
                            models={models.filter(m => fieldModels.includes(m.id) || this.state.selectedModels.filter(sm => m.id === sm.id).length > 0)}
                            selectedColumns={columnsToShow.map(c => c.id)}
                            onChange={c => {
                                this.setColumns(c);
                                onPicked(c);
                            }}
                        />
                    </div>
                </div>
            </div>
        );
        return (
            <div className="form-group row">
                <div className='col-12'>
                    <div className='pd-x-15'>
                        {
                            this.tooltipWrapper(
                                !isMultiAvailable,
                                <FormattedMessage id="common.multiOnlyAvailableMsg"/>,
                                <div id="multi-picker" className={'row ' + (!isMultiAvailable && 'disabled')}>
                                    <div className="col-6 pd-x-20">
                                        <span  style={{float:'left'}}>
                                            <FormattedMessage className='pd-x-20' id="common.singleOrMultipleEvents" />
                                        </span>
                                        {
                                            this.tooltipWrapper(
                                                isMultiAvailable,
                                                template.document_type === 'excel' ? <FormattedMessage id="common.multiExcelMsg" /> : <FormattedMessage id="common.multiWordMsg" />,
                                                <span style={{float:'left', marginLeft: '10px'}}>
                                                    <i className="material-icons-outlined">info</i>
                                                </span>
                                            )
                                        }
                                    </div>
                                    <div className="col-3 pd-x-20">
                                        <label>
                                            <input
                                                type="radio"
                                                name="is-multi"
                                                checked={!this.state.isMulti || !isMultiAvailable}
                                                className="form-check-input"
                                                onChange={() => this.setState({ isMulti: false, selectedEventIds: [] })}
                                                disabled={!isMultiAvailable}
                                            />
                                            <FormattedMessage className='pd-x-20' id="common.single" />
                                        </label>
                                    </div>
                                    <div className="col-3 pd-x-20">
                                        <label>
                                            <input
                                                type="radio"
                                                name="is-multi"
                                                checked={this.state.isMulti && isMultiAvailable}
                                                className="form-check-input"
                                                onChange={() => this.setState({ isMulti: true, selectedEventIds: [] })}
                                                disabled={!isMultiAvailable}
                                            />
                                            <FormattedMessage className='pd-x-20' id="common.multiple" />
                                        </label>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className='col-12'>
                    <hr/>
                </div>
                <div className='col-12'>
                    <div className='pd-x-15 pd-y-15'>
                        <Table
                            key={fieldRef.literal}
                            columns={columnsToShow}
                            columnPicker={columnPicker}
                            data={transactions}
                            total={total || transactions.length}
                            queryBuilder={setFilters => 
                                <QueryBuilder 
                                    fields={indexFields} 
                                    models={models} 
                                    onFiltersSet={(filters) => {
                                        const allFilters = Object.assign({}, filters);
                                        if (!allFilters.model_ids) {
                                            allFilters.model_ids = [];
                                        }
                                        if (!allFilters.criteria) {
                                            allFilters.criteria = "[]";
                                        }
                                        allFilters.model_ids = allFilters.model_ids.concat((this.state.initialFieldRefModels || []).map(m => m.id));
                                        allFilters.model_ids = allFilters.model_ids.filter(onlyUnique)
                                        let parsedCriteria = JSON.parse(allFilters.criteria);
                                        const fixedFilters = (fieldFilters || []).map(fd => {
                                            let f = { ...fd };
                                            if (f.comparator === 13) {
                                                f.comparator = 9;
                                                f.is_not = true;
                                            }
                
                                            if (f.comparator === 12) {
                                                f.filterValue = null;
                                            }
                
                                            return {
                                                ...f,
                                                index_field_id: f.field,
                                                comparator_id: f.comparator,
                                                ...getFormattedFieldValue((indexFields.find(i => i.id === f.field) || {}).type_name, f.filterValue, !Array.isArray(f.filterValue)),
                                                comparator: undefined,
                                                id: undefined,
                                                field: undefined,
                                                filterValue: undefined
                                            };
                                        })
                                        parsedCriteria = parsedCriteria.concat(fixedFilters);
                                        allFilters.criteria = JSON.stringify(parsedCriteria);
                                        onFieldSearchCriteriaSet(allFilters);
                                        setFilters(filters);
                                    }} 
                                    externalFilters={fieldFilters}
                                    externalModels={this.state.initialFieldRefModels}
                                    modelsPlaceholder={<FormattedMessage id='eventsAndCargoes.placeholder'/>}
                                    unavailableStatuses={this.props.unavailableStatuses || []}
                                    // statusSelectorDisabled={true}
                                />}
                            dataLoader={(options, onComplete) => this.loadData(options, onComplete)}
                        />
                    </div>
                </div>
                {
                    this.state.isMulti && isMultiAvailable &&
                        <div className='col-10 offset-1 pd-y-20'>
                            <FadeIn>
                                <Select
                                    placeholder={<FormattedMessage id='common.selectedEvents'/>}
                                    value={this.state.selectedEventIds.map(id => ({label: id, value: id}))}
                                    options={this.state.selectedEventIds.map(id => ({label: id, value: id}))}
                                    isMulti={true}
                                    isClearable={true}
                                    isSearchable={false}
                                    // isDisabled={true}
                                    onChange={(vals) => this.setState({ selectedEventIds: vals.map(v => v.value)})}
                                    components={{ 
                                        IndicatorSeparator: () => null, 
                                        DropdownIndicator: () => null, 
                                        Menu: () => null,
                                        // MultiValueRemove: () => null,
                                    }}
                                    theme={theme => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#eeeeee',
                                            primary: '#00336B'
                                        }
                                    })}
                                />
                            </FadeIn>
                        </div>
                }
                <div className='row col-12'>
                    <div className='col-xl-4 offset-xl-4 col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-xs-10 offset-xs-1 pd-y-20'>
                        <button
                            type="button"
                            className="btn btn-primary btn-block tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                            onClick={() => {
                                if (this.state.selectedEventIds.length === 0) {
                                    this.setState({ confirmModalOpen: true })
                                } else if (this.props.onFieldEventsConfirmed) {
                                    const { selectedEventIds } = this.state;
                                    this.props.onFieldEventsConfirmed(selectedEventIds);
                                }
                            }} 
                        >
                            <FormattedMessage id="common.useSelectedEventsForField" />
                        </button>
                    </div>
                </div>
                {
                    this.state.isMulti &&
                    <div className='row col-12'>
                        <div className='col-xl-4 offset-xl-4 col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-xs-10 offset-xs-1 pd-y-10'>
                            <button
                                type="button"
                                className="btn btn-secondary btn-block tx-11 tx-uppercase pd-y-12 tx-mont tx-medium"
                                onClick={() => {
                                    if (this.props.onFieldEventsConfirmedAll) {
                                        this.props.onFieldEventsConfirmedAll();
                                    }
                                }} 
                            >
                                <FormattedMessage id="common.useAllEventsForField" values={{count: total}} />
                            </button>
                        </div>
                    </div>
                }
                {
                    !this.state.isMulti && this.state.selectedEventIds.length > 0 &&
                        <div className='row col-12'>
                            <div className='col-xl-4 offset-xl-4 col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-xs-10 offset-xs-1'>
                                <FadeIn>
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-block tx-11 tx-uppercase pd-y-12 tx-mont tx-medium"
                                        onClick={() => this.setState({ selectedEventIds: [] })} 
                                    >
                                        <FormattedMessage id="common.clearSelectedEvent" />
                                    </button>
                                </FadeIn>
                            </div>
                        </div>
                }
                <ReactModal
                    isOpen={this.state.confirmModalOpen}
                    onRequestClose={() => this.setState({ confirmModalOpen: false })}
                    className="modal-block dialog"
                    overlayClassName="modal-overlay gray"
                >
                    <div className="modal-dialog modal-lg wd-450" role="document">
                        <div className="modal-content bd-0">
                            <div className="modal-header pd-x-20">
                                <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                                    <FormattedMessage id="common.confirmFieldReferenceEvents" />
                                </h6>
                            </div>
                            <div className="modal-body pd-20">
                                <FormattedMessage id="common.confirmFieldReferenceEventsMsgNone" />
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button
                                    type="button"
                                    className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                    onClick={() => {
                                        if (this.props.onFieldEventsConfirmed) {
                                            const { selectedEventIds } = this.state;
                                            this.props.onFieldEventsConfirmed(selectedEventIds);
                                        }
                                        this.setState({ confirmModalOpen: false })
                                    }}
                                >
                                    <FormattedMessage id="common.yes" />
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                    onClick={() => this.setState({ confirmModalOpen: false })}
                                >
                                    <FormattedMessage id="common.cancel"  />
                                </button>
                            </div>
                        </div>
                    </div>
                </ReactModal>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    transactions: state.transactions.list,
    total: state.transactions.total,
    indexFields: state.indices.list ? state.indices.list.filter(i => i.status_id === 2) : undefined,
    models: state.models.list
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            loadModel,
            getModels,
            filterTransactions,
            getIndices,
            deleteTransaction
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FieldReferenceEventSelector);
