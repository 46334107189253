import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Fields } from '../../../components/fields';
import { requestPasswordChange } from '../../../reducers/identity';

class Forgot extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            submitted: false,
            isSubmitting: false,
         };
    }

    setSubmitted = submitted => this.setState({ submitted });

    handleSubmit = ({ email }) => {
        const { requestPasswordChange } = this.props;
        this.setState({ isSubmitting: true }, () => {
            requestPasswordChange(email, () => {
                this.setSubmitted(true);
            }, () => {
                this.setState({ isSubmitting: false });
            });
        })
    };

    render() {
        const { submitted } = this.state;
        if (!!submitted) {
            return (
                <React.Fragment>
                    <div className="form-group">
                        <FormattedMessage id="page.account.reset.submitted.request" tagName="span" />
                    </div>
                    <div className="form-group">
                        <Link to={{ pathname: '/login', search: window.location.search }} className="tx-12 d-block mg-t-10">
                            <FormattedMessage id="page.account.login.link" />
                        </Link>
                    </div>
                </React.Fragment>
            );
        }

        return (
            <Formik
                initialValues={{ email: '' }}
                validationSchema={Yup.object().shape({
                    email: Yup.string()
                        .email(<FormattedMessage id="validation.invalid" />)
                        .required(<FormattedMessage id="validation.required" />)
                })}
                onSubmit={this.handleSubmit}
                render={() => (
                    <Form noValidate>
                        <div className="form-group">
                            <Fields.Input type="email" name="email" className="form-control" showError={true} />
                        </div>
                        <div className="form-group">
                            <Link to={{ pathname: '/login', search: window.location.search }} className="tx-12 d-block mg-t-10">
                                <FormattedMessage id="page.account.login.link" />
                            </Link>
                        </div>
                        <button type="submit" className="btn btn-primary btn-block" disabled={this.state.isSubmitting}>
                            <FormattedMessage id="submit" />
                        </button>
                    </Form>
                )}
            />
        );
    }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            requestPasswordChange
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Forgot);
