import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import moment from 'moment';
//import 'moment/locale/pt';
import DateTime from  'react-datetime';
import 'react-datetime/css/react-datetime.css'
import './fields.scss';
import Tooltip from 'rc-tooltip';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class DateField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.initialValue
        };
    }
    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.initialValue !== prevProps.initialValue) {
          this.setState({ value: this.props.initialValue });
        }
    }
    render() {
        const {
            intl: { formatMessage },
            name,
            submitOnChange,
            placeholder,
            dateFormat,
            disabled = false,
            locale,
            setFieldValue,
        } = this.props;

        if (!setFieldValue) return null;
        const {value} = this.state;
        const inputProps = {
            placeholder: placeholder || formatMessage({ id: `${name}.placeholder` }),
            style: {
                display: 'inline-block',
                width: '100%'
            },
            autoComplete: 'off',
        }
        let className = 'form-control';
        moment.locale(locale);
        const parsedDate = moment.isMoment(value) ? value : moment(value, dateFormat || 'DD-MMM-YYYY');
        let dateValue = '';
        if (parsedDate.isValid()) {
            dateValue = parsedDate.format(dateFormat || 'DD-MMM-YYYY')
        }
        if (disabled) {
            inputProps.disabled = true;
        } else if (value && !moment(value, dateFormat || 'DD-MMM-YYYY', true).isValid()) {
            className += ' invalid';
        }
        inputProps.className = className;

        return (
            <div className="date-field">
                <DateTime
                    locale={locale}
                    initialValue={dateValue}
                    dateFormat={dateFormat || 'DD-MMM-YYYY'}
                    onChange={date => {
                        if (moment.isMoment(date) && date.isValid()) {
                            const txtDate = date.format(dateFormat || 'DD-MMM-YYYY')
                            setFieldValue(name, txtDate)
                            // if (submitOnChange) {
                            //     submitForm();
                            // } 
                        } else {
                            setFieldValue(name, date);
                            // if (date === '' && submitOnChange) {
                            //     submitForm();
                            // }
                        }
                    }}
                    inputProps={inputProps}
                    style={{ display: 'inline-block', width: '100%' }}
                    timeFormat={false}
                    closeOnSelect={true}
                    closeOnTab={true}
                />
                { !!dateValue && 
                    <Tooltip trigger={['hover']} overlay={<span>{placeholder || formatMessage({ id: `${name}.placeholder` })}</span>} mouseEnterDelay={2}>
                        <div className="input-placeholder">{placeholder || formatMessage({ id: `${name}.placeholder` })}</div>
                    </Tooltip>
                }
                {this.props.showIsRequired && !dateValue && <div className="validation-message required-placeholder"><FormattedMessage id="validation.required" /></div>}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    locale: state.localization.locale
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(DateField));
