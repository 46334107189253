import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { FieldTypes } from '../../../models/field';

class FieldValueRenderer extends React.Component {
    formatValue = () => {
        const { row, field } = this.props;
        const { type_name } = field;
        const value = row[`field_${field.id}`];
        if (value === undefined) return '';

        switch (type_name) {
            case FieldTypes.FIELD_DATE:
                const date = moment(value);
                return date.isValid() ? date.format('DD-MM-YYYY') : '';

            case FieldTypes.FIELD_YES_NO:
                return value === true ? <FormattedMessage id="common.yes" /> : value === false ? <FormattedMessage id="common.no" /> : <FormattedMessage id='common.n/a' />;

            case FieldTypes.FIELD_MULTI_SELECT:
            case FieldTypes.FIELD_MULTI_TEXT:
                return Array.isArray(value) ? value.join(', ') : value;

            case FieldTypes.FIELD_CURRENCY:
                return value;

            case FieldTypes.FIELD_ATTACHMENT:
                try {
                    const parsedValue = JSON.parse(value);
                    return parsedValue.user_file_name;
                } catch {
                    return '';
                }

            case FieldTypes.FIELD_TEXT:
            case FieldTypes.FIELD_NUMBER:
            case FieldTypes.FIELD_SELECT:
            default:
                return value;
        }
    };

    render() {
        return this.formatValue();
    }
}

export default FieldValueRenderer;
