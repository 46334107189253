import React from 'react';
import { getFilterShortText } from '../../../../models/field';

class FilterPreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    renderFilter = (filter, isFieldFilter = false) => {
        const { comparators, fields, modelFields, onRemove, onRemoveFieldFilter = () => null, fieldFilters } = this.props;
        const { id, field, comparator, filterValue } = filter;

        // If Dash Template was removed by user during filtering then existing field filters must be removed
        // verify if fieldFilters id exists in modelFields
        let filtersToDelete = [];
        for (let i = 0; i < fieldFilters.length; i++) {
            let fieldNotPresentInModelFields = modelFields.find(f => f.id === fieldFilters[i].field);
            if(!fieldNotPresentInModelFields) {
                filtersToDelete.push(fieldFilters[i]);
            }
        }
        if (filtersToDelete.length > 0) {
            // remove unused filters from fieldFilters
            for( var k = 0; k < filtersToDelete.length; k++) { 
                const indexToDelete = fieldFilters.indexOf(filtersToDelete[k]);
                if (indexToDelete > -1) {
                    fieldFilters.splice(indexToDelete, 1);
                }
            }
        }

        let fieldName;
        let fieldType;
        if (isFieldFilter) {
            // fields
            fieldName = (modelFields.find(f => f.id === field) || {}).title || `Id: ${field}`;
            fieldType = (modelFields.find(f => f.id === field) || {}).type_name;
        } else {
            // tags
            fieldName = (fields.find(f => f.id === field) || {}).title || `Id: ${field}`;
            fieldType = (fields.find(f => f.id === field) || {}).type_name;
        }
        const comparatorName = (comparators.find(c => c.id === comparator) || {}).comparator_name;

        return (
            <div key={id} className="filter-chip">
                <span className="filter-text">
                    { isFieldFilter ? 
                        <i className="material-icons-outlined" style={{padding: 0, marginRight: '5px', border: 'none'}}>filter_list</i>
                        :
                        <i className="material-icons-outlined" style={{padding: 0, marginRight: '5px', border: 'none'}}>local_offer</i>
                    }
                    {getFilterShortText(fieldName, fieldType, comparatorName, filterValue)}
                </span>
                <i className="material-icons" onClick={() => isFieldFilter ? onRemoveFieldFilter(id) : onRemove(id)}>
                    close
                </i>
            </div>
        );
    };

    render() {
        const { filters, fieldFilters } = this.props;
        let filtersSafe = [];
        if (!!filters && filters.length > 0) {
            filtersSafe = filters;
        }
        let fieldFiltersSafe = [];
        if (!!fieldFilters && fieldFilters.length > 0) {
            fieldFiltersSafe = fieldFilters;
        }

        return <div className="col-12 mg-b-5">
            {filtersSafe.map(f => this.renderFilter(f, false))}
            {fieldFiltersSafe.map(f => this.renderFilter(f, true))}
        </div>;
    }
}

export default FilterPreview;
