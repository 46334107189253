import React from 'react';
import { injectIntl } from 'react-intl';
import { Field, ErrorMessage } from 'formik';

import './fields.scss';

class CheckboxField extends React.Component {
    render() {
        const {
            intl: { formatMessage },
            placeholder,
            name,
            checked,
            withPadding,
            disabled = false,
            extras,
        } = this.props;

        const fieldProps = { ...this.props };
        delete fieldProps['withPadding'];

        return (
            <div className={`checkbox-field${!!withPadding ? ' withPadding' : ''} pd-b-5`}>
                <label className="ckbox">
                    <Field type="checkbox" {...fieldProps} checked={checked} disabled={disabled} />
                    <span className={`ckbox-label-text${checked ? ' checked' : ''}`}>{placeholder || formatMessage({ id: `${name}.placeholder` })}</span>
                    <ErrorMessage name={name} component="div" className="validation-message" />
                    &nbsp;
                    {
                        extras ? extras.post : null
                    }
                </label>
            </div>
        );
    }
}

export default injectIntl(CheckboxField);
