import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Fields } from '../../../../components/fields';
import { Notifications } from '../../../../components/notifications';
import { inviteUser, getUsers } from '../../../../reducers/users';

class UserInvite extends React.Component {
    handleSubmit = ({ first_name, last_name, email, role }) => {
        const { inviteUser, onCancel, companyId, getUsers } = this.props;
        inviteUser({ first_name, last_name, email, role_id: role }, () => {
            Notifications.success(<FormattedMessage id="page.companies.details.user.invited" />);
            getUsers({ company_ids: [companyId], status_ids: [1, 2, 3, 4, 5] });
            onCancel();
        });
    };

    render() {
        const { onCancel, roles } = this.props;
        if (!roles) return null;

        return (
            <div className="modal-dialog wd-100p-force advanced-filters-modal" role="document">
                <div className="modal-content bd-0">
                    <div className="modal-header pd-x-20">
                        <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                            <FormattedMessage id="page.companies.details.actions.invite.user" />
                        </h6>
                    </div>
                    <Formik
                        initialValues={{ first_name: '', last_name: '', email: '', country_code: '', phone: '', role: '' }}
                        validationSchema={Yup.object().shape({
                            first_name: Yup.string().required(<FormattedMessage id="validation.required" />),
                            last_name: Yup.string().required(<FormattedMessage id="validation.required" />),
                            email: Yup.string()
                                .email(<FormattedMessage id="validation.invalid" />)
                                .required(<FormattedMessage id="validation.required" />),
                            role: Yup.number().required(<FormattedMessage id="validation.required" />)
                        })}
                        onSubmit={this.handleSubmit}
                        render={() => (
                            <Form>
                                <React.Fragment>
                                    <div className="modal-body pd-5 pd-t-15">
                                        <div className="form-group col-12">
                                            <div className="row">
                                                <div className="col-6 pd-r-0">
                                                    <Fields.Input name="first_name" showIsRequired={true} />
                                                </div>
                                                <div className="col-6">
                                                    <Fields.Input name="last_name" showIsRequired={true} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group col-12">
                                            <Fields.Input name="email" showIsRequired={true} />
                                        </div>
                                        <div className="form-group col-12">
                                            <Fields.Select name="role" options={roles.map(r => ({ value: r.id, label: r.title }))} showIsRequired={true} />
                                        </div>
                                    </div>
                                    <div className="modal-footer justify-content-center">
                                        <button type="submit" className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium">
                                            <FormattedMessage id="common.submit" />
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                            onClick={onCancel}
                                        >
                                            <FormattedMessage id="common.cancel" />
                                        </button>
                                    </div>
                                </React.Fragment>
                            </Form>
                        )}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            inviteUser,
            getUsers
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserInvite);
