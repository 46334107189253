import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Formik, Form } from 'formik';
import { Fields } from '../../../components/fields';
import { getModelStatuses } from '../../../reducers/models';
import { FormattedMessage } from 'react-intl';

class QueryBuilder extends React.Component {
    constructor(props) {
        super(props);
        this.state = props.initialState ? props.initialState : {
            filters: { status_ids: [1, 2] }
        };
    }

    componentDidMount() {
        const { statuses, getModelStatuses } = this.props;
        if (!statuses) getModelStatuses();
    }

    setFilters(filters) {
        const { onFiltersSet } = this.props;
        this.setState({ ...this.state, filters: filters || this.state.filters }, () => {
            onFiltersSet({ ...this.state.filters });
            this.props.stateSetter(this.state);
        });
    }

    formatFilters = (values, setFieldValue) => {
        let updatedValues = { ...values };
        let momentFrom, momentTo;
        if (!!values.createdFrom && !!values.to) {
            momentFrom = moment(values.createdFrom, 'DD-MMM-YYYY', true);
            momentTo = moment(values.to, 'DD-MMM-YYYY', true);
            let bothValid = true;
            if (!momentFrom.isValid()) {
                updatedValues.createdFrom = null;
                bothValid = false;
            } 
            if (!momentTo.isValid()) {
                updatedValues.to = null;
                bothValid = false;
            } 
            if (bothValid && momentFrom >= momentTo) {
                let newTo = momentFrom.add(1, 'days').toDate();
                setFieldValue('to', newTo);
                updatedValues.to = newTo;
            }
        } else  {
            if (!!values.createdFrom) {
                momentFrom = moment(values.createdFrom, 'DD-MMM-YYYY', true);
                if (!momentFrom.isValid()) {
                    updatedValues.createdFrom = null;
                } 
            }
            if (!!values.to) {
                momentTo = moment(values.to, 'DD-MMM-YYYY', true);
                if (!momentTo.isValid()) {
                    updatedValues.to = null;
                } 
            }
        }
        if (updatedValues.createdFrom === '') {
            updatedValues.createdFrom = null;
        }
        if (updatedValues.to === '') {
            updatedValues.to = null;
        }
        const { status, createdFrom, to } = updatedValues;
        const filters = {
            status_ids: status,
            created_from: createdFrom,
            created_to: to
        };

        return filters;
    };

    handleSubmit = (values, form) => {
        const { valuesSetter = () => null } = this.props;
        valuesSetter(values);
        const filters = this.formatFilters(values, form.setFieldValue);
        this.setFilters(filters);
    };

    renderForm = () => {
        const { filters } = this.state;
        const { statuses, externalStatuses = null } = this.props;

        return (
            <Formik
                enableReinitialize={true}
                initialValues={this.props.initialValues || { status: filters.status_ids }}
                onSubmit={(values, form) => this.handleSubmit(values, form)}
                render={({ values, handleChange, submitForm }) => {
                    return (
                        <Form
                            noValidate
                            onChange={async e => {
                                await handleChange(e);
                                submitForm();
                            }}
                        >
                            <div className="row mg-b-15">
                                <div className="col-8" onChange={e => { e.preventDefault(); e.stopPropagation(); }}>
                                    <Fields.Select
                                        name="status"
                                        submitOnChange={true}
                                        multi={true}
                                        value={values.status}
                                        options={ externalStatuses ?
                                            externalStatuses.map(s => ({ value: s.id, label: <FormattedMessage id={s.status_name} /> }))
                                            :
                                            statuses.map(s => ({ value: s.id, label: <FormattedMessage id={s.status_name} /> }))}
                                        disabled={this.props.statusSelectDisabled}
                                        useFormattedMessageFilter={true}
                                    />
                                </div>
                                <div className="col-2 pd-l-0">
                                    <Fields.Date name="createdFrom" submitOnChange={true} />
                                </div>
                                <div className="col-2 pd-l-0">
                                    <Fields.Date name="to" submitOnChange={true} />
                                </div>
                            </div>
                        </Form>
                    );
                }}
            />
        );
    };

    render() {
        const { statuses } = this.props;
        if (!statuses) return null;

        return (
            <div className="row">
                <div className="col-12 mg-b-15">{this.renderForm()}</div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    comparators: state.fieldVisibility.comparators,
    statuses: state.models.statuses
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getModelStatuses
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(QueryBuilder);
