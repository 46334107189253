export const MEDIA_FIND = `
    query _($s3_bucket: String!, $s3_object: String!) {
        media_find(s3_bucket: $s3_bucket, s3_object: $s3_object) {
            id
            created_on
            type_id
            type_name
            content_type
            is_ready
            user_file_name

            blob {
                bucket
                key
                size
                mime
                last_modified
                url
            }
        }
    }
`;
