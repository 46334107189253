import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactTable from 'react-table';
import axios from 'axios';
import TablePagination from './pagination';
import { buildPaginationArguments } from '../../utilities/common';

import 'react-table/react-table.css';
import './table.scss';

class Table extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            filters: this.props.initialOptions ? (this.props.initialOptions.filters || []) : [],
        };
        this.loadData = this.loadData.bind(this);
        this.setFilters = this.setFilters.bind(this);
    }

    componentWillUnmount() {
        axios.CancelToken.source().cancel();
    }

    updateState = tableState => {
        const { total } = this.props;
        const { pageSize } = tableState;
        const pages = total > 0 ? Math.ceil(total / pageSize) : undefined;
        this.setState({ pages, loading: false });
    };

    runDefaultDataLoader = tableState => {
        const { dataLoader, optionsSetter = () => null, defaultSort, defaultSortDesc} = this.props;
        const filters = this.state.filters;
        // const { filters } = this.state;
        const { page, pageSize, sorted } = tableState;
        const args = buildPaginationArguments(page, pageSize, sorted && sorted.length > 0 ? sorted : [{ id: defaultSort ? defaultSort : 'created_on', desc: defaultSortDesc !== undefined ? defaultSortDesc : true }]);
        optionsSetter({ page, pageSize, sorted, filters });
        dataLoader({ ...args, ...filters, ignore: undefined }, () => this.updateState(tableState));
    };

    loadData = tableState => {
        this.setState({ loading: true }, () => this.runDefaultDataLoader(tableState));
    };

    setFilters = filters => {
        this.setState({ filters });
    };

    setColumns = columns => {
        this.setState({ filters: { ...this.state.filters, ignore: columns } });
    };

    render() {
        const { columns, data, total, columnPicker, queryBuilder, initialOptions } = this.props;
        // const { order_by, offset, limit } = initialOptions;
        const seleniumDecoratedColumns = columns.map(c => {
            return {
                ...c,
                headerClassName: c.headerClassName + ' se-table-column-header-' + c.id,
                className: c.className + ' se-table-column-' + c.id,
            };
        });
        const { pages, loading } = this.state;
        const filters = this.state.filters;
        let page = null, pageSize = null, sorted = null;
        if (initialOptions) {
            ({ page, pageSize, sorted } = initialOptions)
            return (
                <React.Fragment>
                    {!!queryBuilder ? queryBuilder(f => this.setFilters(f)) : null}
                    {!!columnPicker ? columnPicker(this.setColumns) : null}
                    <ReactTable
                        filtered={filters}
                        columns={seleniumDecoratedColumns}
                        data={data}
                        resolveData={data => (filters ? [...data] : [...data])}
                        manual
                        onFetchData={this.loadData}
                        pages={pages}
                        loading={loading}
                        minRows={3}
                        className="-highlight -striped"
                        PaginationComponent={props => <TablePagination {...props} totalRecords={total} />}
                        defaultPage={page}
                        defaultPageSize={pageSize}
                        defaultSorted={sorted}
                    />
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    {!!queryBuilder ? queryBuilder(f => this.setFilters(f)) : null}
                    {!!columnPicker ? columnPicker(this.setColumns) : null}
                    <ReactTable
                        filtered={filters}
                        columns={seleniumDecoratedColumns}
                        data={data}
                        resolveData={data => (filters ? [...data] : [...data])}
                        manual
                        onFetchData={this.loadData}
                        pages={pages}
                        loading={loading}
                        minRows={3}
                        className="-highlight -striped"
                        defaultPageSize={10}
                        PaginationComponent={props => <TablePagination {...props} reducedPagination={this.props.reducedPagination} totalRecords={total} />}
                    />
                </React.Fragment>
            );
        }
    }
}

Table.defaultProps = {
    dataLoader: undefined,
    columns: undefined,
    columnPicker: undefined,
    queryBuilder: undefined,
    data: [],
    total: 0
};

Table.propTypes = {
    dataLoader: PropTypes.func.isRequired,
    columns: PropTypes.array.isRequired,
    columnPicker: PropTypes.any,
    queryBuilder: PropTypes.any,
    data: PropTypes.array,
    total: PropTypes.number
};

const mapStateToProps = state => ({
    eventsTableQueries: state.sessionSettings.eventsTableQueries,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Table);
