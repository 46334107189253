import React from 'react';
import ReactModal from 'react-modal';
import Transaction from 'pages/events/containers/eventContainer';
import { FormattedMessage } from 'react-intl';
import { getTransaction } from 'reducers/transaction';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { history } from 'configuration/history';
import CargoTypeModal from 'pages/lots/components/cargoTypeModal';
import { Popover, Menu, MenuItem, } from '@blueprintjs/core';
import moment from 'moment';

class DashViewModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            pastVersions: [],
        }
    }

    componentDidMount() {
        const { dashId, getTransaction } = this.props;
        if (dashId) {
            getTransaction(dashId, () => this.setState({ loading: false }));
        }
    }

    render() {
        const { 
            modalOpen = false, 
            setModalOpen = () => null, 
            modalTitle, 
            primaryButton, 
            transaction,
            showLink,
            showPastVersions,
            onClose = () => null,
        } = this.props;

        const { pastVersions } = this.state;

        return (
            <ReactModal
                isOpen={modalOpen}
                onRequestClose={() => {
                    setModalOpen(false);
                    onClose();
                }}
                className="modal-block dialog"
                overlayClassName="modal-overlay gray"
                style={{content: {width: '70%', maxHeight: '70%', overflowY: 'auto', overflowX: 'hidden'}}}
            >
                <div className="modal-dialog-centered" role="document" onClick={e => e.stopPropagation()}>
                    <div className="modal-content bd-0">
                        <div className="modal-header pd-x-20">
                            <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                                { modalTitle }
                            </h6>
                            <div>
                                {
                                    showPastVersions && pastVersions.length > 0 &&
                                    <button
                                        type='button'
                                        className="btn btn-outline-info" 
                                        style={{padding: 0}}
                                    >
                                        <Popover
                                            position="bottom-left"
                                            captureDismiss
                                            targetTagName="div"
                                            wrapperTagName="div"
                                            content={
                                                <Menu>
                                                    <div tabIndex={0} />
                                                    {
                                                        this.state.pastVersions
                                                            .sort((first, second) => {
                                                                const firstMom = moment(first.created_on);
                                                                const secondMom = moment(second.created_on);
                                                                if (firstMom.isBefore(secondMom)) {
                                                                    return 1;
                                                                } else if (firstMom.isAfter(secondMom)) {
                                                                    return -1;
                                                                } else {
                                                                    return 0;
                                                                }
                                                            })
                                                            .map((pv, index) => (
                                                                <MenuItem 
                                                                    key={pv.id}
                                                                    text={<FormattedMessage id="revisedOn" values={{date: moment(pv.created_on).format('DD-MM-YYYY HH:MM'), version: (this.state.pastVersions.length - index)}} />}
                                                                    onClick={() => this.setState({ selectedPastVersion: pv, selectedPastVersionDate: moment(pv.created_on).format('DD-MM-YYYY HH:MM'), pastVersionModalOpen: true })}
                                                                />
                                                            )
                                                        )
                                                    }
                                                    
                                                </Menu>
                                            }
                                        >
                                            <div style={{ padding: '0.65rem 0.75rem', width: '100%', height: '100%' }}>
                                                <FormattedMessage id="pastVersions" />
                                            </div>
                                        </Popover>
                                    </button>
                                }
                                {
                                    showLink &&
                                        <button
                                            type="button"
                                            className="btn btn-outline-info ml-2"
                                            onClick={() => transaction && transaction.id && history.push(`/events/${transaction.id}`)}
                                        >
                                            <FormattedMessage id='openPage' />
                                        </button>
                                }

                                <button
                                    type="button"
                                    className="btn btn-outline-info font-weight-bold ml-2"
                                    onClick={() => setModalOpen(false)}
                                >
                                    {'X'}
                                </button>
                            </div>
                        </div>
                        <div className="modal-body br-section-wrapper">
                            {
                                this.state.loading ?
                                    <LoadingOverlay
                                        active={true}
                                        spinner
                                        text={<FormattedMessage id='common.loading'/>}
                                    >
                                        <div className="widget-2" style={{height: '20vw'}}>
                                            <div className="row">
                                                <div className="col-12 pd-0">
                                                    <p>&nbsp;</p>
                                                </div>
                                            </div>
                                        </div>
                                    </LoadingOverlay>
                                    :
                                    <Transaction 
                                        transaction={transaction} 
                                        company={{}}
                                        hideHeading={true} 
                                        hideTitleAndDescription={false} 
                                        showCloseButton={true}
                                        primaryButton={primaryButton}
                                        onCancel={() => setModalOpen(false)}
                                        doValidation={false} 
                                        showQuantityInfo={false}
                                        readOnly={true}
                                        setPastVersions={pastVersions => this.setState({ pastVersions })}
                                        extendedProps={{ selectFieldBackgroundColor: '#e9ecef', unitFieldBackgroundColor:'#e9ecef'}}
                                    />

                            }
                        </div>
                    </div>
                </div>
                <CargoTypeModal
                    modalOpen={this.state.pastVersionModalOpen}
                    setModalOpen={(open) => this.setState({ pastVersionModalOpen: open })}
                    cargoType={this.state.selectedPastVersion}
                    showQuantityInfo={false}
                    dot={null}
                    hideTitleAndDescription={false}
                    readOnly={true}
                    modalTitle={<FormattedMessage id='pastVersionRevisedOn' values={{ date: this.state.selectedPastVersionDate }}/>}
                />
            </ReactModal>
        );
        
    }
}

const mapStateToProps = state => {
    return {
        transaction: state.transaction.details,
    };
};
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getTransaction,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DashViewModal);