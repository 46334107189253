import { invertColor } from 'utilities/common'

export class Node {
    constructor(entityType, entity, id) {
      this.entityType = entityType;
      this.entity = entity;
      this.id = id;
    }
}

export class Path {
  constructor(selectedNode, allNodes) {
    this.selectedNode = selectedNode;
    this.allNodes = allNodes;
  }
}

export class DotGraphNode extends Node {
    constructor(dot) {
        const { id, hash_id, color, title, hidden = false, to, from, invisible, originalQuantity, quantity, created_on, modified_on, version_dates, currentVersion, isTargeted, previousVersion, is_accessible, owning_company_name } = dot;
        super('dot', dot, id);
        this.id = id;
        this.hash_id = hash_id;
        this.color = color;
        this.title = title;
        this.hidden = hidden;
        this.invisible = invisible;
        this.to = to;
        this.from = from;
        this.dot = dot;
        this.originalQuantity = originalQuantity;
        this.quantity = quantity;
        this.created_on = created_on;
        this.modified_on = modified_on;
        this.version_dates = version_dates;
        this.currentVersion = currentVersion;
        this.previousVersion = previousVersion;
        this.isTargeted = isTargeted;
        const { status_name, isPastVersion } = dot.dot;
        this.isDeleted = (status_name !== 'unclaimed' && status_name !== 'claimed' && !isPastVersion);
        this.is_accessible = is_accessible;
        this.owning_company_name = owning_company_name;
    }
    toDagreNodes() {
        let dotNode = null;
        if (this.hidden) {
            let opacity = 1;
            if (this.invisible) {
                opacity = 0;
            } else if (!this.highlight) {
                opacity = 0.5;
            }
            dotNode = {
                id: this.id,
                label: '',
                labelType: "string",
                config: {
                    style: `fill: ${this.color}; opacity: ${opacity}; ${this.selected ? 'filter: url(#minidropshadow);' : 'filter: none;'}`,
                    shape: 'circle',
                    width: 1,
                    height: 1,
                },
                dorae: {
                    node: this
                }
            }
        } else {
            let opacity = 1;
            if (this.invisible) {
                opacity = 0;
            } else if (!this.highlight) {
                opacity = 0.5;
            }
            let fillColor = 'white';
            if (this.selected) {
                fillColor = this.color + '66';
            } else if (!(this.quantity && this.quantity > 0)) {
                fillColor = '#dedede';
            }
            let labelColor = this.color;
            if (this.selected) {
                labelColor = invertColor(this.color, true);
            }
            dotNode = {
                id: this.id,
                label: this.invisible ? '' : GetNodeLabel('DoT', this.hash_id, '', this.title, labelColor, '1', this.originalQuantity, this.quantity, this.selected, this.currentVersion, this.isTargeted, this.isDeleted),
                labelType: "html",
                config: {
                    // style: 'fill: #3796cd',
                    style: `fill: ${fillColor}; border: thin; stroke: ${this.color ? this.color : 'black'}; opacity: ${opacity}; ${this.highlight ? 'filter: url(#dropshadow);' : 'filter: none;'}`,
                    shape: 'rect',
                    rx: 25,
                    ry: 25,
                    width: 160,
                    height: 160,
                },
                dorae: {
                    node: this
                }
            }
        }
        return [dotNode]
    }
}

export class DashGraphNode extends Node {
    constructor(dash) {
        const { id, hash_id, title, fields, created_on, modified_on, version_dates, currentVersion, previousVersion, status_id, is_accessible, owning_company_name } = dash;
        super('dash', dash, id);
        this.id = id;
        this.hash_id = hash_id;
        this.title = title || 'Untitled';
        this.fields = fields;
        if (dash.color) {
            this.color = dash.color;
        }
        this.dash = dash;
        this.invisible = dash.invisible;
        this.template = dash.dash.model.title || 'Untitled';
        this.created_on = created_on;
        this.modified_on = modified_on;
        this.version_dates = version_dates;
        this.currentVersion = currentVersion;
        this.previousVersion = previousVersion;
        this.isDeleted = dash.dash.status_id === 6;
        this.is_accessible = is_accessible;
        this.owning_company_name = owning_company_name;
    }
    toDagreNodes() {
        const fieldNodes = []
        let opacity = 1;
        if (this.invisible) {
            opacity = 0;
        } else if (!this.highlight) {
            opacity = 0.6;
        }
        let fillColor = 'white';
        if (this.selected) {
            fillColor = this.color + '66';
        }
        if (this.fields) {
            this.fields.forEach(field => {
                let color;
                if (field.type === 'output') {
                    color = 'green';
                } else if (field.type === 'input') {
                    color = 'red';
                } else if (field.type === 'transform') {
                    color = 'blue';
                }
                if (color) {
                    const fieldNode = {
                        id: field.id,
                        label: '',
                        labelType: "string",
                        config: {
                            style: `fill: ${color}; opacity: ${opacity};`,
                            shape: 'diamond',
                            width: 5,
                            height: 5,
                        },
                        parent: this.id,
                        dorae: {
                            node: this
                        },
                    }
                    if (this.class) {
                        fieldNode.class = this.class;
                    }
                    fieldNodes.push(fieldNode)
                }
            });
        }
        const dashNode = {
            id: this.id,
            label: '',
            labelType: "string",
            config: {
                style: `fill: ${fillColor}; border: thin; stroke: ${this.color ? this.color : 'black'}; opacity: ${opacity}; ${this.highlight ? 'filter: url(#dropshadow);' : 'filter: none;'}`,
                shape: 'rect',
                clusterLabelPos: 'top',
                rx: 25,
                ry: 25,
                width: 150,
            },
            dorae: {
                node: this
            },
        };
        if (this.class) {
            dashNode.class = this.class;
        }
        const labelNode = GetLabelNode(this.id, 'Dash', this.hash_id, this.title, this);
        return [dashNode, labelNode].concat(fieldNodes)
    }
}

export class FieldGraphNode extends Node {
    constructor(field) {
        const { id } = field;
        super('field', field, id);
        this.invisible = false;
    }
}

export class GraphEdge {
    constructor(from, to, label = '', invisible = false, color = 'black', highlight = true, showLabel = false) {
        this.from = from;
        this.to = to;
        this.label = label;
        this.invisible = invisible;
        this.color = color;
        this.highlight = highlight;
        this.showLabel = showLabel;
    }
    toDagre() {
        let opacity = '1';
        let headOpacity = '1';
        if (this.invisible) {
            opacity = '0';
            headOpacity = '0';
        } else if (!this.highlight) {
            opacity = '0.5'
        }
        return {
            source: this.from,
            target: this.to,
            label: this.label,
            config: {
                arrowheadStyle: `opacity: ${headOpacity}; stroke: ${this.color}; fill: ${this.color}; stroke-width: 1.5px`,
                style: `opacity: ${opacity}; stroke: ${this.color};`,
                labelStyle: `color: ${this.showLabel ? 'black' : 'white'}`,
            }
        }
    }
}

export function getUpstreamNodes(node, nodes) {
    const dashes = nodes.filter(n => n.entityType === 'dash');
    const upstreamNodes = [];
    let nodesToVisit = [node];
    const visitedNodeIds = [node.id];
    while (nodesToVisit.length > 0) {
        const currentNode = nodesToVisit.pop();
        switch (currentNode.entityType) {
            case 'dash':
                if (!upstreamNodes.find(n => n.id === currentNode.id)) {
                    upstreamNodes.push(currentNode);
                }
                currentNode.entity.fields.forEach(field => {
                    field.from.forEach(fromId => {
                        const upstreamNode = nodes.find(n => n.id === fromId);
                        if (upstreamNode) {
                            if (upstreamNode.entityType !== 'field' && !upstreamNodes.find(n => n.id === fromId)) {
                                upstreamNodes.push(upstreamNode);
                            }
                            if (!visitedNodeIds.find(id => id === upstreamNode.id)) {
                                nodesToVisit.push(upstreamNode);
                                visitedNodeIds.push(upstreamNode.id);
                            }
                        }
                    });
                });
                break;
            case 'field':
                const containingDash = dashes.find(dash => dash.fields && dash.fields.find(field => field.id === currentNode.id));
                if (containingDash) {
                    if (!upstreamNodes.find(n => n.id === containingDash.id)) {
                        upstreamNodes.push(containingDash);
                    }
                    if (!visitedNodeIds.find(id => id === containingDash.id)) {
                        nodesToVisit.push(containingDash);
                        visitedNodeIds.push(containingDash.id);
                    }
                }
                break;
            case 'dot':
                if (!upstreamNodes.find(n => n.id === currentNode.id)) {
                    upstreamNodes.push(currentNode);
                }
                const dot = currentNode.entity;
                dot.from.forEach(fromId => {
                    const upstreamNode = nodes.find(n => n.id === fromId);
                    if (upstreamNode) {
                        if (upstreamNode.entityType !== 'field' && !upstreamNodes.find(n => n.id === fromId)) {
                            upstreamNodes.push(upstreamNode);
                        }
                        if (!visitedNodeIds.find(id => id === upstreamNode.id)) {
                            nodesToVisit.push(upstreamNode);
                            visitedNodeIds.push(upstreamNode.id);
                        }
                    }
                });
                break;
            default:
                throw new Error('Invalid node entity type', currentNode);
        }
    }
    // return all except for the node passed to the method
    return upstreamNodes.filter(n => n.id !== node.id);
}

export function getDownstreamNodes(node, nodes) {
    const dashes = nodes.filter(n => n.entityType === 'dash');
    const downstreamNodes = [];
    let nodesToVisit = [node];
    const visitedNodeIds = [node.id];
    while (nodesToVisit.length > 0) {
        const currentNode = nodesToVisit.pop();
        switch (currentNode.entityType) {
            case 'dash':
                if (!downstreamNodes.find(n => n.id === currentNode.id)) {
                    downstreamNodes.push(currentNode);
                }
                currentNode.entity.fields.forEach(field => {
                    field.to.forEach(fromId => {
                        const downstreamNode = nodes.find(n => n.id === fromId);
                        if (downstreamNode) {
                            if (downstreamNode.entityType !== 'field' && !downstreamNodes.find(n => n.id === fromId)) {
                                downstreamNodes.push(downstreamNode);
                            }
                            if (!visitedNodeIds.find(id => id === downstreamNode.id)) {
                                nodesToVisit.push(downstreamNode);
                                visitedNodeIds.push(downstreamNode.id);
                            }
                        }
                    });
                });
                break;
            case 'field':
                const containingDash = dashes.find(dash => dash.fields && dash.fields.find(field => field.id === currentNode.id));
                if (containingDash) {
                    if (!downstreamNodes.find(n => n.id === containingDash.id)) {
                        downstreamNodes.push(containingDash);
                    }
                    nodesToVisit.push(containingDash);
                    if (!visitedNodeIds.find(id => id === containingDash.id)) {
                        nodesToVisit.push(containingDash);
                        visitedNodeIds.push(containingDash.id);
                    }
                }
                break;
            case 'dot':
                if (!downstreamNodes.find(n => n.id === currentNode.id)) {
                    downstreamNodes.push(currentNode);
                }
                const dot = currentNode.entity;
                dot.to.forEach(fromId => {
                    const downstreamNode = nodes.find(n => n.id === fromId);
                    if (downstreamNode) {
                        if (downstreamNode.entityType !== 'field' && !downstreamNodes.find(n => n.id === fromId)) {
                            downstreamNodes.push(downstreamNode);
                        }
                        if (!visitedNodeIds.find(id => id === downstreamNode.id)) {
                            nodesToVisit.push(downstreamNode);
                            visitedNodeIds.push(downstreamNode.id);
                        }
                    }
                });
                break;
            default:
                throw new Error('Invalid node entity type', currentNode);
        }
    }
    // return all except for the node passed to the method
    return downstreamNodes.filter(n => n.id !== node.id);
}

export function getPath(node, nodes) {
    const upstream = getUpstreamNodes(node, nodes).map(n => {
        n.color = node.color;
        n.highlight = node.highlight;
        n.selected = false;
        return n;
    });
    const downstream = getDownstreamNodes(node, nodes).map(n => {
        n.color = node.color;
        n.highlight = node.highlight;
        n.selected = false;
        return n;
    });
    return downstream.concat([node]).concat(upstream);
}

export function getEdges(nodesWithVisibility, selectedEdge = null) {
    const edges = [];
    // hide edges if the 'from' node or the 'to' node is invisible
    nodesWithVisibility.forEach(node => {
        if (node.entityType === 'dash') {
            node.entity.fields.forEach(field => {
                field.to.forEach(destination => {
                    const targetNode = nodesWithVisibility.find(node => node.id === destination);
                    const label = targetNode.entity.dot.original_quantity
                    let showLabel = false;
                    if (!(node.invisible || targetNode.invisible) && selectedEdge && selectedEdge.to === destination && selectedEdge.from === field.graph_id) {
                        showLabel = true;
                    }
                    edges.push(new GraphEdge(field.id, destination, label, node.invisible || targetNode.invisible, node.color, node.highlight, showLabel).toDagre());
                });
            })
        } else if (node.entityType === 'dot') {
            node.entity.to.forEach(destination => {
                const targetNode = nodesWithVisibility.find(node => node.id === destination);
                let isEdgeInvisible = node.invisible || targetNode.invisible;
                let edgeColor = targetNode.color;
                let edgeHighlight = targetNode.highlight;
                let showLabel = false;
                if (!isEdgeInvisible && selectedEdge && selectedEdge.to === destination && selectedEdge.from === node.id) {
                    showLabel = true;
                }
                let label = '';
                // make sure the parent dash of a field isn't invisible
                if (targetNode.entityType === 'field') {
                    const parentDash = nodesWithVisibility.find(parentNode => parentNode.entityType === 'dash' && parentNode.fields.find(field => field.id === targetNode.id));
                    isEdgeInvisible = isEdgeInvisible || parentDash.invisible;
                    if (node.highlight) {
                        edgeColor = parentDash.color;
                        edgeHighlight = parentDash.highlight;
                    } else {
                        edgeColor = node.color;
                        edgeHighlight = node.highlight;
                    }
                    label = node.entity.dot.quantity;
                } else {
                    label = targetNode.entity.dot.quantity;
                }
                edges.push(new GraphEdge(node.id, destination, label, isEdgeInvisible, edgeColor, edgeHighlight, showLabel).toDagre());
            })
        }
    });
    return edges;
}

function GetNodeLabel(type, id, title, template, color = 'black', opacity = '1', originalQuantity = null, quantity = null, selected = false, currentVersion = null, isTargeted = false, isDeleted = false) {
    const trimmedTitle = (title && title.length > 12) ? title.substring(0,11) + '…' : title;
    const trimmedTemplate = (template && template.length > 12) ? template.substring(0,11) + '…' : template;
    let buttonText = 'Add Path';
    const buttonDisabled = selected === true;
    let buttonOpacity = opacity;
    if (opacity === '1' && buttonDisabled) {
        buttonOpacity = '0.3';
    }
    if (isTargeted) {
        buttonText = 'Highlight Path';
    }
    if (!!originalQuantity) {
        let fontColor;
        if (selected || (quantity && quantity > 0)) {
            fontColor = color;
        } else {
            fontColor = '#5e5e5e';
        }
        const quantityString = `${quantity}/${originalQuantity}`
        const trimmedQuantityStr = (quantityString.length > 9) ?quantityString.substring(0,8) + '…' : quantityString;
        let thirdLine = isDeleted ? '<br/><span style="color: red">DELETED</span>' : `<br>Available: ${trimmedQuantityStr}`;
        return `<div style="overflow: visible; width: 150px; height: 90px; color: ${fontColor}; opacity: ${opacity}" class="no-select">`
            + `<button class='btn-outline-info ${buttonDisabled ? 'disabled-btn' : ''}' ${buttonDisabled ? 'disabled' : ''} style="color: ${fontColor};"><i class="material-icons-outlined" style="margin-top: 4px; font-size: 14px;">visibility</i> ${buttonText}</button><br/><br/>`
            + `<p>${type}: <b class='clickable'><u>${id} ${currentVersion ? 'V' + currentVersion : ''}</u></b>`
            + `${thirdLine} <br/>Template: ${trimmedTemplate}</p></div>`
    }
    return `<div style="overflow: visible; width: 150px; height: 90px; color: ${color}; opacity: ${opacity}" class="no-select">`
        + `<button class='btn-outline-info ${buttonDisabled ? 'disabled-btn' : ''}' ${buttonDisabled ? 'disabled' : ''} style="color: ${color};"><i class="material-icons-outlined" style="margin-top: 4px; font-size: 14px;">visibility</i> ${buttonText}</button><br/><br/>`
        + `<p>${type}: <b class='clickable'><u>${id} ${currentVersion ? 'V' + currentVersion : ''}</u></b><br>`
        + `Title: ${trimmedTitle}<br>Template: ${trimmedTemplate}</p></div>`
        + (isDeleted ? '<br/><span style="color: red">DELETED</span>' : '')
}


function GetLabelNode(id, type, hash_id, title, node) {
  if (node) {
    let opacity = 1;
    if (node.invisible) {
      opacity = 0;
    } else if (!node.highlight) {
      opacity = 0.5;
    }
    let fillColor = 'white';
    if (node.selected) {
      fillColor = node.color + '99';
    }
    let labelColor = node.color;
    if (node.selected) {
      labelColor = invertColor(node.color, true);
    }
    return {
      id: `${id}-label`,
      label: node.invisible ? '' : GetNodeLabel(type, hash_id, title, node.template, labelColor, '1', null, null, node.selected, node.currentVersion, node.isTargeted, node.isDeleted),
      labelType: "html",
      config: {
        // style: 'fill: #3796cd',
        style: `fill: none; border: 0px; 'opacity: ${opacity};`,
        shape: 'rect',
        width: 150,
        height: 90,
        class: 'nodeLabel'
      },
      parent: id,
      dorae: {
        node: node
      },
    }
    
  }
  return {
    id: `${id}-label`,
    label: GetNodeLabel(type, hash_id, title),
    labelType: "html",
    config: {
      // style: 'fill: #3796cd',
      style: `fill: white; border: 0px;`,
      shape: 'rect',
      width: 150,
      height: 50,
      class: 'nodeLabel'
    },
    parent: id
  }
}