import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import Page from 'components/page';
import { importFields, setModelPropertiesVisibility } from 'reducers/fields';
import { getModels } from 'reducers/models';
import { setMenuVisibility } from 'reducers/menus';
import FieldNew from '../../models/fields/components/new';
import ModelPreview from '../../models/fields/components/preview';
import { loadModel } from 'reducers/models';
import { listMappings } from 'reducers/fields'
import { getTransaction } from 'reducers/transaction';
import { getProfile } from 'reducers/identity';
import { canInteract } from 'components/routes/permissions/permissionResolver';
import { history } from 'configuration/history';
import LoadingOverlay from 'react-loading-overlay';
import { requestEntityLock, releaseEntityLock } from 'reducers/entityLock';

import '../models.scss';

class ModelNew extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cargoCategories: [],
            cargoTypes: [],
            model: {},
            loadingEventTemplate: true,
            showFieldMaps: false,
            template_field_mappings: [],
        };
        this.lockIntervalId = 0;
    }

    validatePermissions = () => {
        const { entityPermissions } = this.props;
        if (!canInteract(entityPermissions, ['model', 'create', 'update'])) {
            history.replace('/');
        }
    };

    async componentDidMount() {
        const { match, loadModel, company, getProfile, getModels, getTransaction, requestEntityLock, listMappings } = this.props;
        if (!company) {
            getProfile(this.validatePermissions);
        } else {
            this.validatePermissions();
        }

        if (match && match.params && match.params.id) {
            loadModel(match.params.id, async (model) => {
                this.lockIntervalId = setInterval(
                    () => {
                        if (model.status_id === 1) {
                            const { lockedEntity } = this.props;
                            if (this.props.user.id !== lockedEntity.user_id) {
                                loadModel(match.params.id);
                            }
                            requestEntityLock({ entity_type: 'model', entity_id: match.params.id })
                        }
                    },
                    10 * 1000
                );
                const allCargoCategoriesPromise = new Promise(resolve => getModels({model_type: 2}, (cargoCategories) => {
                    this.setState({cargoCategories: cargoCategories.data, model: model}, resolve);
                }));
                const cargoTypeFields = 
                    model.model_field_list
                        .filter(f => f.cargo_type !== null && f.cargo_type !== undefined);
                const promises = [];
                for (const cargoField of cargoTypeFields) {
                    promises.push(new Promise(resolve => {
                        getTransaction(cargoField.cargo_type, (cargoType) => {
                            resolve({ type: 'type', cargoType: cargoType });
                        });
                    }));
                }
                
                const cargoFields = 
                    model.model_field_list
                        .filter(f => f.cargoCategory !== null && f.cargoCategory !== undefined);
                for (const cargoField of cargoFields) {
                    promises.push(
                        new Promise(resolve => {
                            loadModel(cargoField.cargoCategory, (cargoCategory) => {
                                resolve({ type: 'category', cargoCategory: cargoCategory });
                            }, () => null, false)
                        })
                    );
                }
                promises.push(new Promise(resolve => {
                    requestEntityLock(
                        {
                            entity_type: 'model',
                            entity_id: match.params.id
                        },
                        resolve
                    );
                }))
                await new Promise((resolve, reject) => listMappings({ dash_template_id: match.params.id }, resolve, reject))
                    .then(
                        results => this.setState({ template_field_mappings: results }),
                        error => console.error('error retrieving template field mappings:', error)
                    );
                await allCargoCategoriesPromise;
                const referencedCargoes = await Promise.all(promises);
                this.setState(state => {
                    let cargoCategories = state.cargoCategories;
                    let cargoTypes = state.cargoTypes;
                    referencedCargoes.forEach(cargo => {
                        if (cargo && cargo.type) {
                            if (cargo.type === 'type') {
                                cargoTypes = cargoTypes.concat(cargo.cargoType)
                            } else {
                                const cargoCategory = cargo.cargoCategory;
                                cargoCategories = cargoCategories
                                    .filter(cc => cc.id !== cargoCategory.id)
                                    .concat(cargoCategory);
                            }
                        }
                    });
                    return { cargoCategories, cargoTypes, loadingEventTemplate: false };
                });
            });
        }
    }

    componentWillUnmount() {
        const { releaseEntityLock, match } = this.props;
        clearInterval(this.lockIntervalId);
        releaseEntityLock({
            entity_type: 'model',
            entity_id: match.params.id
        });
    }

    render() {
        const { 
            parents, 
            model, 
            match, 
            company, 
            getTransaction, 
            lockedEntity, 
            user, 
            listMappings, 
            intl: { formatMessage }, 
        } = this.props;
        
        if (!match || !match.params || !match.params.id) return null;
        if (!company) return null;
        if (!model) return null;

        let eventReadLocked = false;
        let readLockedMessage = () => null;
        if (lockedEntity.user_id && lockedEntity.user_id !== user.id && model.status_id === 1) {
            eventReadLocked = true;
            readLockedMessage = () => (
                <div style={{
                    backgroundColor: '#fff',
                    padding: '15px 20px',
                    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.21)',
                    margin: '10px'
                }}>
                    <h6 className="mg-b-0 tx-inverse tx-bold">
                        <FormattedHTMLMessage id='lockedEntityMessage' tagName="span" values={{ entity: formatMessage({ id: 'dashTemplate'}), name: lockedEntity.user_name, email: lockedEntity.user_email }} />
                    </h6>
                </div>
            )
        }

        return (
            <Page 
                {...{ ...ModelNew.meta, title: `${model.title}${model.is_used ? ' (in use)' : ''}` }} 
                parents={parents} 
                panel={false}
                extraHeaderEls={readLockedMessage()}
            >
                <LoadingOverlay
                    active={this.state.loadingEventTemplate}
                    spinner
                    text={<FormattedMessage id='common.loading'/>}
                >
                    <div className="row pd-x-15">
                        <FieldNew 
                            key={match.params.id}
                            modelTypeId={1} 
                            addCargoCategory={(cargoCategory) => {
                                this.setState(state => {
                                    const cargoCategories = state.cargoCategories
                                        .filter(cc => cc.id !== cargoCategory.id)
                                        .concat(cargoCategory)
                                    return { cargoCategories };
                                });
                            }}
                            reloadCargoType={id => {
                                this.setState({ loadingEventTemplate: true }, () => {
                                    getTransaction(id, (cargoType) => {
                                        this.setState(prevState => {
                                            let cargoTypes = prevState.cargoTypes;
                                            if (cargoTypes.find(c => c.id === cargoType.id)) {
                                                cargoTypes = cargoTypes.map(c => c.id === cargoType.id ? cargoType : c);
                                            } else {
                                                cargoTypes.push(cargoType);
                                            }
                                            return { cargoTypes, loadingEventTemplate: false };
                                        })
                                    });
                                })
                            }}
                            cargoCategories={this.state.cargoCategories}
                            readOnly={eventReadLocked}
                            showFieldMaps={this.state.showFieldMaps}
                            templateFieldMappings={this.state.template_field_mappings}
                            reloadFieldMappings={() => listMappings({ dash_template_id: match.params.id }, results => this.setState({ template_field_mappings: results }), error => console.error('error retrieving template field mappings:', error))}
                        />
                        <div className="col-md-12 col-lg-6 col-xl-8 pd-l-5 pd-r-0">
                            <ModelPreview 
                                key={match.params.id + '-' + !this.state.loadingEventTemplate}
                                model={model}
                                modelId={match.params.id} 
                                modelTypeId={1} 
                                cargoCategories={this.state.cargoCategories} 
                                cargoTypes={this.state.cargoTypes} 
                                onShowFieldMaps={val => {
                                    this.setState({ showFieldMaps: val });
                                }}
                            />
                        </div>
                    </div>
                </LoadingOverlay>
            </Page>
        );
    }
}

ModelNew.meta = {
    showTitleBlock: false,
    title: <FormattedMessage id="page.models.new.title" />,
    subtitle: <FormattedMessage id="page.models.new.subtitle" />,
    routes: ['/models/'],
    icon: 'ion-clipboard',
    menus: { main: false, user: false },
    order: 0,
    requires: ['model', 'read', 'update']
};

const mapStateToProps = state => ({
    entityPermissions: state.identity.entityPermissions,
    company: state.identity.company,
    model: state.fields.model,
    fieldTypes: state.fields.types,
    lockedEntity: state.entityLock.lockedEntity,
    user: state.identity.user,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getProfile,
            setMenuVisibility,
            loadModel,
            importFields,
            setModelPropertiesVisibility,
            getModels,
            getTransaction,
            requestEntityLock,
            releaseEntityLock,
            listMappings,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(ModelNew));
