import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Popover, Menu, Button, MenuItem, Icon } from '@blueprintjs/core';
import Page from '../../../../components/page';
import Table from '../../../../components/table';
import { history } from '../../../../configuration/history';
import { getProfile } from '../../../../reducers/identity';
import { loadModel } from '../../../../reducers/models';
import { getDocumentTemplates, deleteDocumentTemplate } from '../../../../reducers/documents';
import { createDocumentTemplate } from '../../../../reducers/document';
import Tooltip from 'rc-tooltip';

class DocumentConfigurationList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        const { match, loadModel } = this.props;
        loadModel(match.params.modelId);
    }

    loadData = (options, onComplete) => {
        const { match, getDocumentTemplates } = this.props;
        getDocumentTemplates({ ...options, model_ids: [match.params.modelId] }, onComplete);
    };

    getColumns = () => {
        const { model, deleteDocumentTemplate, getDocumentTemplates, match } = this.props;
        return [
            {
                headerClassName: 'd-sm-block col-2 d-none',
                className: 'd-xs-block col-2',
                sortable: false,
                resizable: false,
                width: 34,
                id: 'actions',
                accessor: d => (
                    <div className="actions-wrapper">
                        <Popover
                            content={
                                <Menu>
                                    <MenuItem
                                        icon="cross"
                                        text={<FormattedMessage id="common.delete" />}
                                        onClick={async() => {
                                            deleteDocumentTemplate(d.id, (dt) => {
                                                getDocumentTemplates({model_ids: [match.params.modelId]});
                                            })
                                        }}
                                    />
                                </Menu>
                            }
                        >
                            <Button minimal={true} small={true} icon={<Icon iconSize={12} icon="menu" />} />
                        </Popover>
                    </div>
                )
            },
            {
                headerClassName: 'd-sm-block col d-none',
                className: 'd-sm-block col d-none tx-center id-column',
                Header: <FormattedMessage id="common.id" />,
                width: 50,
                id: 'id',
                accessor: d => d.id
            },
            {
                headerClassName: 'd-sm-block d-none',
                className: 'd-xs-block',
                Header: <FormattedMessage id="common.title" />,
                id: 'title',
                accessor: d => <Link to={{ pathname: `/models/${model.id}/document-templates/${d.id}`, search: window.location.search }}>{d.title}</Link>
            },
            {
                headerClassName: 'd-block col-2',
                className: 'd-block col-2',
                Header: <FormattedMessage id="common.createdOn" />,
                id: 'created_on',
                accessor: d => moment(d.created_on).format('DD-MM-YYYY')
            }
        ];
    };

    renderActions = () => {
        const { model, createDocumentTemplate } = this.props;
        return (
            <div className="btn-group pd-t-10">
                <Popover
                    position="bottom-left"
                    captureDismiss
                    content={
                        <Menu>
                            <div tabIndex={0} />
                            <MenuItem 
                                text={<FormattedMessage id="common.wordDoc" />}
                                icon="small-plus"
                                onClick={() => createDocumentTemplate(model.id, 'word', ({ id }) => history.push(`/models/${model.id}/document-templates/${id}` + window.location.search))}
                            />
                            <Tooltip
                                position="bottom"
                                content={
                                    <FormattedMessage id="common.comingSoon" />
                                }
                                hoverOpenDelay={300}
                            >
                                <MenuItem 
                                    text={<FormattedMessage id="common.excelDoc" />}
                                    disabled={true}
                                    icon="small-plus"
                                    onClick={() => createDocumentTemplate(model.id, 'excel', ({ id }) => history.push(`/models/${model.id}/document-templates/${id}` + window.location.search))}
                                />
                            </Tooltip>
                        </Menu>
                    }
                >
                    <span className="btn btn-outline-info">
                        <FormattedMessage id="page.models.document.configuration.list.createNew" />
                    </span>
                </Popover>
                {/* <span
                    className="btn btn-outline-info"
                    onClick={() => createDocumentTemplate(model.id, ({ id }) => history.push(`/models/${model.id}/document-templates/${id}` + window.location.search))}
                >
                    <FormattedMessage id="page.models.document.configuration.list.createNew" />
                </span> */}
            </div>
        );
    };

    render() {
        const { model, parents, templates, total = 0 } = this.props;
        if (!model) return null;

        return (
            <Page {...DocumentConfigurationList.meta} parents={[...parents, { title: model.title, routes: ['/models'] }]} actions={this.renderActions()}>
                <Table columns={this.getColumns()} data={templates} total={total} dataLoader={(options, onComplete) => this.loadData(options, onComplete)} />
            </Page>
        );
    }
}

DocumentConfigurationList.meta = {
    title: <FormattedMessage id="page.models.document.configuration.list.title" />,
    subtitle: <FormattedMessage id="page.models.document.configuration.list.subtitle" />,
    routes: ['/models/'],
    icon: 'ion-clipboard',
    menus: { main: false, user: false },
    order: 0
};

const mapStateToProps = state => ({
    company: state.identity.company,
    model: state.fields.model,
    templates: state.documents.list,
    total: state.documents.total,
    fieldTypes: state.fields.types
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getProfile,
            loadModel,
            createDocumentTemplate,
            getDocumentTemplates,
            deleteDocumentTemplate
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DocumentConfigurationList);
