import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import Tooltip from 'rc-tooltip';
import SimpleBar from 'simplebar-react';
import { Formik, Form } from 'formik';
import ReactModal from 'react-modal';
import DraggableList from 'react-draggable-list';
import FieldDefinition, { FieldTypes } from 'models/field';
import { Fields } from 'components/fields';
import {
    getFieldTypes,
    updateField,
    updateModel,
    persistSingleFieldUpdate,
    persistBatchFieldUpdates,
    setModelPropertiesVisibility,
    createFieldValue,
    deleteFieldValue,
    setFieldMapping,
    clearFieldMapping,
} from 'reducers/fields';
import { createTransaction, getTransaction } from 'reducers/transaction';
import { persistModelChanges, getModelStatuses, getModels, loadModel, setModelUsers } from 'reducers/models';
import { getCurrencyOptions } from 'utilities/constants';
import FieldValueListItem from './valueListItem';
import { ValuesSetupForm, DateFormatLegend, getDefaultValuesField, normalizeNumber } from '../definitions/common';
import { getIndices } from 'reducers/indices';
import VisibilityDialogButton from './visibilityConditions/visibilityDialogButton';
import VisibilityConditions from './visibilityConditions';
import { Field } from 'formik';
import { Notifications } from 'components/notifications';
import LoadingOverlay from 'react-loading-overlay';
import CargoTypeModal from 'pages/lots/components/cargoTypeModal';
import { debounce } from 'utilities/common';
import InternalSharingModal from 'components/modals/internalSharingModal';
import { getProfile } from 'reducers/identity.actions';
import ConfirmModal from 'components/modals/confirm';
import { Popover, Menu, MenuItem, } from '@blueprintjs/core';
import ExternalSharingModal from 'components/modals/externalSharingModal';
import CargoUnitField from 'pages/events/components/cargoUnitForm';
import { listCargoUnits, createCargoUnit } from 'reducers/cargoUnits';
import { tooltipWrapper } from 'utilities/common'
import * as Yup from 'yup';
import { SelectField, TextField, CreatableSelectField } from 'pages/models/fields/definitions';
import FieldTransformationsModal from './fieldTransformationsModal';
import moment from 'moment';

class FieldProperties extends React.Component {
    contentRef;
    
    constructor(props) {
        super(props);
        this.state = {
            modal: { open: false, type: undefined },
            minMaxError: false,
            cargoCategories:[],
            cargoTypeModalOpen: false,
            statusModalOpen: false,
            statusModalLoading: false,
            shareModalCount: 0,
            showShareModal: false,
            fieldPropsRenderCount: 0,
            addUnitModalOpen: false,
            userCreatedCargoUnitType: null,
            selectedCargoUnitType: null,
        };

        this.persistChanges = this.persistChanges.bind(this);
        this.contentRef = React.createRef();
    }

    //---------------------------------------
    // ADD UNITS MODAL ----------------------
    closeUnitsModal = () => {
        this.setState({ 
            addUnitModalOpen: false
        });
    };
    handleSubmitUnit = async (values, { setSubmitting }) => {
        const { createCargoUnit, listCargoUnits } = this.props;
        const cleanupState = () => {
            setSubmitting(false)
            this.closeUnitsModal();
            this.setState({ creatingCargoUnit: false });
        }
        this.setState({ error: undefined, creatingCargoUnit: true }, () =>
            createCargoUnit(
                values,
                () => listCargoUnits(null, cleanupState, cleanupState),
                cleanupState
            )
        );
    };
    createCargoUnitCategory = (newCargoUnitCategory) => {
        return new Promise(resolve => 
            this.setState({ 
                    userCreatedCargoUnitType: newCargoUnitCategory,
                    selectedCargoUnitType: newCargoUnitCategory
                }, 
                () => resolve(newCargoUnitCategory)
            )
        );
    };
    // END - ADD UNITS MODAL------------------
    //----------------------------------------


    setModalVisibility = (visible, type) => {
        this.setState({ ...this.state, modal: { open: visible }, type: visible ? type : undefined });
    };

    updateValueOrder = list => {
        const { field, updateField } = this.props;
        const orderedList = list.map((item, index) => ({ ...item, order_by: index + 1 }));
        updateField({ ...field, fieldValues: orderedList, deleteValue: undefined }, () => {
            this.setState(prevState => ({ fieldPropsRenderCount: prevState.fieldPropsRenderCount + 1 }));
        });
    };

    renderDialog = () => {
        const { field, updateField, deleteFieldValue, createFieldValue } = this.props;
        const { modal } = this.state;
        if (!modal.open) return null;

        const scrollableNodeRef = React.createRef();

        const getDefaultValue =  (defaultValue, deletedValue) => {
            if ( defaultValue && Array.isArray(defaultValue) ){
                return defaultValue.filter(value => value !== deletedValue[0].value);
            } else if( defaultValue == deletedValue[0].value ){
                return null;
            }
            return defaultValue;
        }

        const defineOptionsDialog = (
            <div className="modal-dialog modal-lg wd-450" role="document">
                <div className="modal-content bd-0">
                    <div className="modal-header pd-x-20">
                        <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                            <FormattedMessage id="models.fields.defineValues" />
                        </h6>
                    </div>
                    <div className="modal-body pd-20">
                        {field.fieldValues && field.fieldValues.length > 0 && (
                            <div className="field-values-definition-list">
                                <SimpleBar id='select-options' scrollableNodeProps={{ ref: scrollableNodeRef }}>
                                    <div className="draggable-area" ref={el => (this.draggable = el)}>
                                        <DraggableList
                                            itemKey="id"
                                            template={FieldValueListItem}
                                            padding={0}
                                            springConfig={{ stiffness: 300, damping: 50, className: 'xyz' }}
                                            list={field.fieldValues
                                                .map((f, i) => ({
                                                    ...f,
                                                    order_by: i,
                                                    deleteValue: id =>
                                                        deleteFieldValue(id, () =>
                                                            updateField({
                                                                ...field,
                                                                fieldValues: field.fieldValues.filter(v => v.id !== id),
                                                                deleteValue: undefined,
                                                                defaultValue: getDefaultValue(field.defaultValue, field.fieldValues.filter(v => v.id === id))
                                                            }, () => {
                                                                this.setState(prevState => ({ fieldPropsRenderCount: prevState.fieldPropsRenderCount + 1 }));
                                                            })
                                                        )
                                                }))
                                                .sort((a, b) => a.order - b.order)}
                                            onMoveEnd={newList => this.updateValueOrder(newList)}
                                            container={() => this.draggable}
                                        />
                                    </div>
                                </SimpleBar>
                            </div>
                        )}
                        <ValuesSetupForm
                            onSubmit={v => {
                                if (v.value === '' || v.value === null || v.value === undefined) return;
                                if (field.fieldValues && field.fieldValues.find(fv => fv.value === v.value)) {
                                    Notifications.error(<FormattedMessage id='common.repeatField'/>)
                                } else {
                                    createFieldValue(field, { ...v, order_by: (field.fieldValues || []).length + 1, is_hidden: v.isHidden }, newValue => {
                                        updateField({ ...field, fieldValues: [...(field.fieldValues || []), newValue] }, () => {
                                            const container = document.querySelector('#select-options .simplebar-content-wrapper');
                                            container.scrollTo({ top: container.scrollHeight, behavior: "smooth" });
                                            this.setState(prevState => ({ fieldPropsRenderCount: prevState.fieldPropsRenderCount + 1 }));
                                        });
                                    })
                                }
                            }}
                            onCancel={() => this.setModalVisibility(false)}
                        />
                    </div>
                    <div className="modal-footer justify-content-center">
                        <button
                            type="button"
                            className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                            onClick={() => this.setModalVisibility(false)}
                        >
                            <FormattedMessage id="common.ok" />
                        </button>
                    </div>
                </div>
            </div>
        );

        return (
            <ReactModal
                isOpen={modal.open}
                onRequestClose={() => this.setModalVisibility(false)}
                className="modal-block dialog"
                overlayClassName="modal-overlay gray"
            >
                {defineOptionsDialog}
            </ReactModal>
        );
    };

    StatusButton = (title, value, verb, reactivatePrompt = false, readOnly = false) => {
        return (
            <Fields.Button
                name='status_id'
                disabled={readOnly}
                value={value}
                outline={true}
                title={title}
                onClick={(form, value, name) => this.setState({statusForm: form, statusValue: value, statusVerb: verb, statusName: name, statusModalOpen: true, reactivatePrompt: reactivatePrompt})}
            />
        );
    };

    typeSpecificProperties = {
        type: (typeOptions, values, disabled = false, modelTypeId = 1) => {
            if (modelTypeId === 2) {
                typeOptions = typeOptions.filter(t => t.value !== 'Cargo');
            }
            //typeOptions = typeOptions.filter(t => t.value !== FieldTypes.FIELD_END_SECTION);
            typeOptions = [...typeOptions.filter(f => f.value !== FieldTypes.FIELD_SECTION),typeOptions.find(f => f.value === FieldTypes.FIELD_SECTION)].filter(t => t.value !== FieldTypes.FIELD_END_SECTION);
            
            const { currentSelectedField, handleEndSection = (fieldId, addOrRemove) => null } = this.props;
            if (currentSelectedField && currentSelectedField.hasMapToOrFrom && !disabled) {
                return (
                    <Tooltip placement={'bottom'} trigger={['hover']} overlay={<><FormattedMessage id="fieldCurrentlyMappedMustClear1"/><br/><FormattedMessage id="fieldCurrentlyMappedMustClear2"/></>}>
                        <div className="form-group col-12">
                            <Fields.Select
                                name="type"
                                options={typeOptions}
                                submitOnChange={true}
                                value={values.type}
                                disabled={true} 
                                extraOnChange={async (form, selectedValue) => {
                                    const updateOrder = (newOrder) => {
                                        form.setFieldValue('order', newOrder);
                                    }
                                    if( form.values.type === FieldTypes.FIELD_SECTION ){
                                        await handleEndSection(form.values.id, updateOrder, false, false);
                                    } else if (selectedValue.value === FieldTypes.FIELD_SECTION){
                                        await handleEndSection(form.values.id, updateOrder, true, false);
                                    } else if( selectedValue.value === FieldTypes.FIELD_CARGO ){
                                        await handleEndSection(form.values.id, updateOrder, false, true);
                                    }
                                    form.setFieldValue('defaultValue', null); form.setFieldValue('index_field_id', null)
                                }}
                            />
                        </div>
                    </Tooltip>
                )
            } else {
                return (
                    <div className="form-group col-12">
                        <Fields.Select 
                            name="type" 
                            options={typeOptions}
                            submitOnChange={true}
                            value={values.type}
                            disabled={disabled}
                            extraOnChange={async (form, selectedValue) => {
                                const updateOrder = (newOrder) => {
                                    form.setFieldValue('order', newOrder);
                                }       
                                if( form.values.type === FieldTypes.FIELD_SECTION ){
                                    await handleEndSection(form.values.id, updateOrder, false, false);
                                } else if (selectedValue.value === FieldTypes.FIELD_SECTION){
                                    await handleEndSection(form.values.id, updateOrder, true, false);
                                } else if( selectedValue.value === FieldTypes.FIELD_CARGO ){
                                    await handleEndSection(form.values.id, updateOrder, false, true);
                                }
                                form.setFieldValue('defaultValue', null); form.setFieldValue('index_field_id', null)}
                            }
                        />
                    </div>
                );
            }
        },
        title: (disabled = false) => (
            <div className="form-group col-12">
                <Fields.Input type="text" name="title" disabled={disabled} />
            </div>
        ),
        description: (disabled = false) => (
            <div className="form-group col-12">
                <Fields.RichTextButton
                    submitOnChange={false}
                    hiddenControls={['H1', 'H2', 'H3', 'H4', 'Blockquote', 'UL', 'OL', 'Code Block', 'Monospace']}
                    name="description"
                    dialogTitle={<FormattedMessage id="model.properties.description" />}
                    disabled={disabled}
                />
            </div>
        ),
        visibilityCondition: (values, disabled = false) => {
            const { currentSelectedField } = this.props;
            let visibilityConditions = [];
            if (currentSelectedField && currentSelectedField.visibilityCondition) {
                visibilityConditions = currentSelectedField.visibilityCondition;
            }
            return (
                <div className="form-group col-12">
                    { values.is_required ?
                        <Tooltip placement={'right'} trigger={['hover']} overlay={<FormattedMessage id="common.fieldRequiredOrVisibility"/>}>
                            <div>
                                <VisibilityDialogButton field={values} count={visibilityConditions.length} disabled={true} />
                            </div>
                        </Tooltip>
                        :
                        <VisibilityDialogButton field={values} count={visibilityConditions.length} disabled={false} />
                    }
                </div>
            )
        },
        step: (disabled = false, values) => (
            <div className="form-group col-12">
                <Fields.InputNumeric 
                    disabled={disabled} 
                    groupThousands={values.groupThousands}
                    numberFormat={values.numberFormat}
                    fieldValue={values.step}
                    submitOnChange={true}
                    name="step" 
                    stepField="0.000000001"
                    extras={{
                        post: (
                            <Tooltip placement={'right'} trigger={['hover']} overlay={<FormattedMessage id='precisionTooltip' />}>
                                <i className="material-icons-outlined">info</i>
                            </Tooltip>
                        )
                    }}
                />
            </div>
        ),
        min: (disabled = false, values) => (
            <div className="form-group col-12">
                <Fields.InputNumeric disabled={disabled} stepField={normalizeNumber(values, values.step)} submitOnChange={true} groupThousands={values.groupThousands} numberFormat={values.numberFormat} fieldValue={values.min} name="min" />
                {!!this.state.minMaxError ? (<p className="text-danger">Min must be less than max</p>) : null}
            </div>
        ),
        max: (disabled = false, values) => (
            <div className="form-group col-12">
                <Fields.InputNumeric disabled={disabled} stepField={normalizeNumber(values, values.step)} submitOnChange={true} groupThousands={values.groupThousands} numberFormat={values.numberFormat} fieldValue={values.max} name="max" />
                {!!this.state.minMaxError ? (<p className="text-danger">Min must be less than max</p>) : null}
            </div>
        ),
        width: (disabled = false) => (
            <div className="form-group col-12">
                <Fields.Slider
                    disabled={disabled}
                    min={0}
                    max={12}
                    step={null}
                    marks={{ 3: '25%', 4: '33%', 6: '50%', 8: '67%', 9: '75%', 12: '100%' }}
                    valueDisplay={value => `${Math.round((value * 100) / 12)}%`}
                    submitOnChange={true}
                    name="width"
                />
            </div>
        ),
        breakRow: (values, disabled = false) => {
            const breakRowTooltip = (
                <Tooltip 
                    placement={'right'}
                    trigger={['hover']}
                    overlay={<FormattedMessage id='common.breakRow' />}
                >
                    <i className="material-icons-outlined" style={{ fontSize: '18px' }}>info</i>
                </Tooltip>
            )
            return(
                <div className="form-group col-12">
                   <Fields.Checkbox 
                        name="breakRow"
                        checked={values.breakRow === true}
                        disabled={disabled} 
                        extras={{ post: breakRowTooltip }} />
                </div>
            )            
        },

        isRequired: (values, disabled = false) => {
            const { currentSelectedField } = this.props;
            let visibilityConditions = [];
            if (currentSelectedField && currentSelectedField.visibilityCondition) {
                visibilityConditions = currentSelectedField.visibilityCondition;
            }
            return (
                <div className="form-group col-12">
                    { (visibilityConditions !== '' && visibilityConditions.length !== 0) ?
                        <Tooltip placement={'right'} trigger={['hover']} overlay={<FormattedMessage id="common.fieldRequiredOrVisibility"/>}>
                            <div>
                                <Fields.Checkbox name="is_required" checked={values.is_required === true} disabled={true} />
                            </div>
                        </Tooltip>
                        :
                        <Fields.Checkbox name="is_required" checked={values.is_required === true} disabled={disabled} />
                    }
                </div>
            )
        },
        defaultValue: (values, disabled = false) => getDefaultValuesField(values, disabled),
        options: (disabled) => (
            <div className="form-group col-12">
                <button type="button" className="btn btn-block rich-text-button" onClick={() => this.setModalVisibility(true)} disabled={disabled}>
                    <FormattedMessage id="options.placeholder" tagName="span" />
                    <i className="material-icons-outlined">format_list_bulleted</i>
                </button>
            </div>
        ),
        currency: (values, disabled = false) => (
            <div className="form-group col-12">
                <Fields.Select name="currency" options={getCurrencyOptions()} submitOnChange={true} value={values.currency} disabled={disabled} />
            </div>
        ),
        status: (options, values, modelIsEditable, model, readOnly = false) => {
            const modelTypeName = model.model_type_name;
            const {
                intl: { formatMessage },
                cargoCategories,
            } = this.props;

            let hasValidFields = false;
            let dotFieldsOk= false;            
            let allFields=0;

            const hasInvalidDateFields= model.model_field_list.filter(f => f.type === FieldTypes.FIELD_DATE).some( dt => {
                if( dt.defaultValue && !moment(dt.defaultValue, dt.dateFormat || 'DD-MMM-YYYY', true).isValid()){
                    return true;
                }
            });

            let dashReferencesDotsInDraft = false;
            try {
                const dotFields = model.model_field_list.filter(f => f.type === FieldTypes.FIELD_CARGO && !!f.cargoCategory);
                if((model.model_field_list && model.model_field_list.length > 0)){
                    const allDotFields = model.model_field_list.filter(f => f.type === FieldTypes.FIELD_CARGO).length;                    
                    allFields = model.model_field_list.filter(f => f.type !== FieldTypes.FIELD_SECTION && f.type !== FieldTypes.FIELD_END_SECTION).length;
                    dotFieldsOk = (dotFields.length === allDotFields);

                    if (dotFieldsOk){
                        hasValidFields= true;
                    }
                }

                const dotTemplateIds = dotFields.map(f => f.cargoCategory);
                const referencedDotTemplates = cargoCategories.filter(dt => dotTemplateIds.includes(dt.id));
                dashReferencesDotsInDraft = referencedDotTemplates.find(dt => dt.status_id === 1);

            } catch (err) {
                console.warn('Error checking Dash Template for referenced DoT Templates in draft:', err);
            }

            let saveButton = this.StatusButton(formatMessage({id: 'common.saveFinal'}), 2, formatMessage({id: 'common.verb.complete' }), false, readOnly || dashReferencesDotsInDraft || hasInvalidDateFields || !hasValidFields );
            const archiveButton = this.StatusButton(formatMessage({id: 'common.archive'}), 3, formatMessage({id: 'common.verb.archive' }), false, readOnly);
            const deleteButton = this.StatusButton(formatMessage({id: 'common.delete'}), 4, formatMessage({id: 'common.verb.delete' }), false, readOnly);
            const reactivateButton = this.StatusButton(formatMessage({id: 'common.reactivate'}), 2, formatMessage({id: 'common.verb.reactivate' }), true, readOnly);
            
            if (dashReferencesDotsInDraft) {
                saveButton = (
                    <Tooltip placement={'right'} trigger={['hover']} overlay={<><FormattedMessage id="templateReferencesDraftDots1"/><br/><FormattedMessage id="templateReferencesDraftDots2"/></>}>
                        <div>
                            {saveButton}
                        </div>
                    </Tooltip>
                )
            }

            if (!hasValidFields){
                saveButton = (
                    <Tooltip placement={'right'} trigger={['hover']} overlay={                        
                        !dotFieldsOk  ? <FormattedMessage id="missing.conditions2"/> :''
                    }>
                        <div>
                            {saveButton}
                        </div>
                    </Tooltip>
                )
            }

            let statusButton;
            if (values.status_id === 1) {
                // draft --> save final (active)
                statusButton =  (
                    <React.Fragment>
                        <Fields.Button
                            disabled={true}
                            outline={true}
                            title={<FormattedMessage id='common.savedAsDraft'/>}
                        />
                        {saveButton}
                    </React.Fragment>
                )
            } else if (values.status_id === 2) {
                // active --> delete or archive
                statusButton = (
                    <React.Fragment>
                        <Fields.Button
                            disabled={true}
                            outline={true}
                            title={<FormattedMessage id='common.savedFinal'/>}
                        />
                        {archiveButton}
                        {deleteButton}
                    </React.Fragment>
                );
            } else if (values.status_id === 3) {
                // archived --> active
                statusButton =  (
                    <React.Fragment>
                        <Fields.Button
                            disabled={true}
                            outline={true}
                            title={<FormattedMessage id='common.archived'/>}
                        />
                        {reactivateButton}
                    </React.Fragment>
                )
            } else {
                // deleted --> immutable
                statusButton = (
                    <Fields.Button
                        disabled={true}
                        outline={true}
                        secondary={true}
                        title={<FormattedMessage id='common.deleted'/>}
                    />
                );
            }

            const statusVerb = this.state.statusVerb;
            const updatedModelName = (modelTypeName === 'event template') ? <FormattedMessage id='dashTemplate' /> : <FormattedMessage id='dotTemplate' />;
            let statusMessage = (
                <React.Fragment>
                    <FormattedMessage id="page.models.configure.confirmStatusChange" values={{ verb: statusVerb, modelType: updatedModelName }} />
                </React.Fragment>
            )
            if (statusVerb === 'complete' || statusVerb === 'delete') {
                const overrideVerb = (statusVerb === 'complete') ? <FormattedMessage id='finalSave' /> : <FormattedMessage id='permanentlyDelete' />;
                statusMessage = (
                    <React.Fragment>
                        <FormattedMessage id="page.models.configure.confirmStatusChange" values={{ verb: overrideVerb, modelType: updatedModelName }} />
                    </React.Fragment>
                )
            }


            return (
                <React.Fragment>
                    <div className="form-group col-12">
                        {statusButton}
                    </div>
                    
                    <ReactModal
                        isOpen={this.state.statusModalOpen}
                        onRequestClose={() => this.setState({ statusModalOpen: false })}
                        className="modal-block dialog"
                        overlayClassName="modal-overlay gray"
                    >
                        <LoadingOverlay
                            active={this.state.statusModalLoading}
                            spinner
                            text={<FormattedMessage id='common.loading'/>}
                        >
                            <div className="modal-dialog modal-lg wd-450" role="document">
                                <div className="modal-content bd-0">
                                    <div className="modal-header pd-x-20">
                                        <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                                            <FormattedMessage id="common.confirmVerb" values={{verb: this.state.statusVerb}} />
                                        </h6>
                                    </div>
                                    <div className="modal-body pd-20">
                                        {this.state.reactivatePrompt ?
                                            <FormattedMessage id="page.models.configure.confirmReactivate" values={{ modelType: (modelTypeName === 'event template') ? <FormattedMessage id='dashTemplate' /> : <FormattedMessage id='dotTemplate' /> }} />
                                            :
                                            (statusMessage)
                                        }
                                    </div>
                                    <div className="modal-footer justify-content-center">
                                        <button
                                            type="button"
                                            className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                            onClick={async () => {
                                                const { statusForm, statusName, statusValue } = this.state;
                                                this.setState({ isStatusChanging: true });
                                                if (statusForm) {
                                                    await statusForm.setFieldValue(statusName, statusValue);
                                                    statusForm.submitForm();
                                                }
                                            }}
                                        >
                                            <FormattedMessage id="common.yes" />
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                            onClick={() => this.setState({ statusModalOpen: false })}
                                        >
                                            <FormattedMessage id="common.cancel"  />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </LoadingOverlay>
                    </ReactModal>
                </React.Fragment>
        )},
        indices: (options = [], values, disabled = false) => (
            <div className="form-group col-12">
                <Fields.Select
                    name="index_field_id"
                    options={options}
                    submitOnChange={true}
                    value={values.index_field_id}
                    isClearable={true}
                    disabled={disabled}
                    menuPlacement={'top'}
                />
            </div>
        ),
        dateFormat: (options = [], values, disabled = false) => {
            let dateFormatControl = (active = false) => {
                return(
                    <div className="form-group col-12 date-format-property">
                        <Fields.Select
                            name="dateFormat"
                            options={options}
                            submitOnChange={true}
                            value={values.dateFormat || 'DD-MMM-YYYY'}
                            disabled={active}
                            extraOnChange={(form, value) => { 
                                if(form.values.dateFormat === value.value) {
                                    return;
                                }
                                form.setFieldValue('defaultValue', undefined);
                            }}
                        />
                    </div>
                )
            }
            const { currentSelectedField } = this.props;
            // if field is mapped disable date format dropdown control
            if ((currentSelectedField && currentSelectedField.hasMapToOrFrom) || values.isDotField) {
                return(
                    <Tooltip placement={'bottom'} trigger={['hover']} overlay={<><FormattedMessage id="fieldCurrentlyMappedMustClear1"/><br/><FormattedMessage id="fieldCurrentlyMappedMustClear3"/></>}>
                        {dateFormatControl(true)}
                    </Tooltip>
                )
            }
            else {
                return (dateFormatControl())
            }
        },
        groupThousands: (values, disabled = false) => (
            <div className="form-group col-12">
                <Fields.Checkbox name="groupThousands" checked={values.groupThousands === true} disabled={disabled} />
            </div>
        ),
        numberFormat: (options = [], values, disabled = false) => (
            <div className="form-group col-12">
                <Fields.Select
                    name="numberFormat"
                    options={options}
                    submitOnChange={true}
                    extraOnChange={(form, value) => {
                        if(form.values.numberFormat === value.value) {
                            return;
                        }
                        // we need to swap commas and periods
                        const swapCommasAndPeriods = (numberValue) => {
                            const strVal = String(numberValue);
                            const valWithTempCommaReplacement = strVal.replaceAll(',', '!');
                            const valWithFixedPeriods = valWithTempCommaReplacement.replaceAll('.', ',');
                            const fixedVal = valWithFixedPeriods.replaceAll('!', '.');
                            return fixedVal;
                        }
                        if (form.values.defaultValue) {
                            form.setFieldValue('defaultValue', swapCommasAndPeriods(form.values.defaultValue))
                        }
                        if (form.values.step) {
                            form.setFieldValue('step', swapCommasAndPeriods(form.values.step))
                        }
                        if (form.values.min) {
                            form.setFieldValue('min', swapCommasAndPeriods(form.values.min))
                        }
                        if (form.values.max) {
                            form.setFieldValue('max', swapCommasAndPeriods(form.values.max))
                        }

                    }}
                    value={values.numberFormat ? values.numberFormat : "DotSeparator"}
                    disabled={disabled}
                />
            </div>
        ),
        cargoCategories: (options = [], values, disabled = false) => (
            <div className="form-group col-12">
                <Fields.Select
                    name="cargoCategory"
                    options={options}
                    submitOnChange={true}
                    extraOnChange={(form, value) => {
                        form && form.setFieldValue('cargoType', null);
                        form && form.setFieldValue('transformCargoType', null);
                        if (value) {
                            this.props.loadModel(value.value, (cargoCategory) => {
                                this.props.addCargoCategory(cargoCategory);
                            }, () => null, false);
                        }
                    }}
                    value={values.cargoCategory}
                    disabled={disabled}
                    isClearable={true}
                />
            </div>
        ),
        cargoType: (values, disabled = false) => (
            <div className="form-group col-12">
                <Field
                    {...this.props}
                    render={({ form }) => {
                        const cargoTypeDisabled = ((disabled && !values.cargoType) || !values.cargoCategory || !values.cargoReferenceType || this.props.readOnly);
                        const dotFieldNotOutput = (!!values.cargoReferenceType && values.cargoReferenceType !== 2 /* output */);
                        const tooltipWrapper = (children) => {
                            return ((cargoTypeDisabled || dotFieldNotOutput) && !disabled) ? 
                                <Tooltip placement={'right'} trigger={['hover']} overlay={<FormattedMessage id={dotFieldNotOutput ? 'onlyPopulateOutputTypes' : 'common.populateCargoCategoryAndReference'} />} children={children} />
                                 :
                                <div children={children} />
                        }
                        return (
                            <React.Fragment>
                                {
                                    tooltipWrapper(
                                        <div 
                                            className={'btn btn-outline-info col-12 ' + ((cargoTypeDisabled || dotFieldNotOutput) ? 'disabled' : '')}
                                            disabled={cargoTypeDisabled || dotFieldNotOutput}
                                            onClick={() => {
                                                if (cargoTypeDisabled || dotFieldNotOutput) return null;
                                                let cargoTypeId = values.cargoType;
                                                if (cargoTypeId === null || cargoTypeId === undefined) {
                                                    this.props.createTransaction(values.cargoCategory, 'cargo type', (cargoType) => {
                                                        form.setFieldValue('cargoType', cargoType.id);
                                                        form.submitForm();
                                                        this.props.getTransaction(cargoType.id, (cargoType) => {
                                                            this.setState({cargoType: cargoType}, () => {
                                                                this.setState({cargoTypeModalOpen: true});
                                                            });
                                                        });
                                                    });
                                                } else {
                                                    this.props.getTransaction(cargoTypeId, (cargoType) => {
                                                        this.setState({cargoType: cargoType}, () => {
                                                            this.setState({cargoTypeModalOpen: true});
                                                        });
                                                    });
                                                }
                                            }}
                                        >
                                            {(disabled && !cargoTypeDisabled) ? <FormattedMessage id="common.viewPopulatedCargoFields"/> : <FormattedMessage id="common.populateCargoFields"/>}
                                        </div>
                                    )
                                }
                                <CargoTypeModal
                                    modalOpen={this.state.cargoTypeModalOpen}
                                    setModalOpen={(open) => {
                                        this.setState({ cargoTypeModalOpen: open });
                                        if (!open && this.props.onCargoTypeModalClose) {
                                            this.props.onCargoTypeModalClose(this.state.cargoType.id);
                                        }
                                    }}
                                    cargoType={this.state.cargoType}
                                />
                            </React.Fragment>
                        );
                    }}
                />
            </div>
        ),
        transformCargoType: (values, disabled = false) => (
            <div className="form-group col-12">
                <Field
                    {...this.props}
                    render={({ form }) => {
                        const cargoTypeDisabled = ((disabled && !values.transformCargoType) || !values.cargoCategory || !values.cargoReferenceType || this.props.readOnly);
                        const tooltipWrapper = (children) => {
                            return (cargoTypeDisabled && !disabled) ? 
                                <Tooltip placement={'right'} trigger={['hover']} overlay={<FormattedMessage id="common.populateCargoCategoryAndReference"/>} children={children} />
                                 :
                                <div children={children} />
                        }
                        return (
                            <React.Fragment>
                                {
                                    tooltipWrapper(
                                        <div 
                                            className={'btn btn-outline-info col-12 ' + (cargoTypeDisabled ? 'disabled' : '')}
                                            disabled={cargoTypeDisabled}
                                            onClick={() => {
                                                if (cargoTypeDisabled) return null;
                                                let cargoTypeId = values.transformCargoType;
                                                if (cargoTypeId === null || cargoTypeId === undefined) {
                                                    this.props.createTransaction(values.cargoCategory, 'cargo type', (cargoType) => {
                                                        form.setFieldValue('transformCargoType', cargoType.id);
                                                        form.submitForm();
                                                        this.props.getTransaction(cargoType.id, (cargoType) => {
                                                            this.setState({transformCargoType: cargoType}, () => {
                                                                this.setState({transformCargoTypeModalOpen: true});
                                                            });
                                                        });
                                                    });
                                                } else {
                                                    this.props.getTransaction(cargoTypeId, (cargoType) => {
                                                        this.setState({transformCargoType: cargoType}, () => {
                                                            this.setState({transformCargoTypeModalOpen: true});
                                                        });
                                                    });
                                                }
                                            }}
                                        >
                                            {(disabled && !cargoTypeDisabled) ? <FormattedMessage id="viewFieldTransformations"/> : <FormattedMessage id="setFieldTransformations"/>}
                                        </div>
                                    )
                                }
                                <FieldTransformationsModal
                                    modalOpen={this.state.transformCargoTypeModalOpen}
                                    setModalOpen={(open) => {
                                        this.setState({ transformCargoTypeModalOpen: open });
                                        if (!open && this.props.onCargoTypeModalClose) {
                                            this.props.onCargoTypeModalClose(this.state.transformCargoType.id);
                                        }
                                    }}
                                    cargoType={this.state.transformCargoType}
                                />
                            </React.Fragment>
                        );
                    }}
                />
            </div>
        ),
        cargoReferenceType: (options = [], values, disabled = false) => { 
            const noCargoCategory = (values.cargoCategory === null || values.cargoCategory === undefined || values.cargoCategory === '');
            const referenceTooltipWrapper = (children) => {
                return (noCargoCategory && !disabled) ? 
                    <Tooltip placement={'right'} trigger={['hover']} overlay={<FormattedMessage id="common.populateCargoCategory"/>} children={children} />
                     :
                    <div children={children} />
            }
            return (
                <div className="form-group col-12">
                    {
                        referenceTooltipWrapper(
                            <div>
                                <Fields.Select
                                    name="cargoReferenceType"
                                    options={options}
                                    submitOnChange={true}
                                    value={ noCargoCategory ? 1 : values.cargoReferenceType}
                                    disabled={disabled || noCargoCategory}/>
                            </div>
                        )
                    }
                    
                </div>
            )
        },
        defaultUnit: ( values, disabled = false) => { 

            const {intl: { formatMessage },} = this.props;
            const unitTypes = (this.props.cargoUnits || []).map(cu => cu.unit_type);
            const unitTypeOptions = [...new Set(unitTypes)].map(o => ({label: formatMessage({ id: o }), value: o}));

            // const options = this.props.cargoUnits
            //     ? this.props.cargoUnits.map(i => ({ value: i.id, label: <FormattedMessage id={i.display_name} /> }))
            //     : undefined

            const cargoUnitGroups = [...new Set((this.props.cargoUnits || []).map(o => o.unit_type))]
            const options = this.props.cargoUnits ? cargoUnitGroups.map(o => {
                const groupOptions = this.props.cargoUnits.filter(f => f.unit_type === o).map(f => ({ value: f.id, label: <FormattedMessage id={f.display_name} /> }))
                return { label: <FormattedMessage id={o} />, options: groupOptions };
            }) : undefined;

            let value;
            for (let i = 0; i < options.length; i++) {
                value = options[i].options.find(it => it.value === values.default_unit)
                if (value) {
                    break;
                }
            }

            let flatOpt=[];
            for (let i = 0; i < options.length; i++) {
                for (let j = 0; j < options[i].options.length; j++) {
                    flatOpt.push(options[i].options[j]);
                }
            }

            return (
                <>
                <div className="form-group col-12">
                    <div className="row">
                        <div className="col-9 pr-0">
                        
                            <Fields.AsyncSelect
                                name="default_unit"
                                value={value}
                                submitOnChange={true}
                                multi={false}
                                options={options}
                                optionsLoader={(search) => new Promise((resolve) => {
                                    /*
                                    let result;
                                    for (let i = 0; i < options.length; i++) {
                                        result = options[i].options.filter(f => formatMessage({ id: f.label.props.id }).toLowerCase().includes(search.toLowerCase()))
                                    }
                                    if (result) resolve(result);
                                    */

                                    let result = flatOpt.filter(f => f.label.props.id.toLowerCase().includes(search.toLowerCase()))
                                    if (result) resolve(result);

                                })}
                                disabled={disabled}
                                getLabelFromOptions={true}
                                isClearable={true}
                                extraOnChange={val => {
                                    if (val === null) {
                                        values.isStaticUnit = false;
                                    }
                                }}
                                menuPlacement='top'
                            />
                        </div>
                        <div className="col-3 pl-2">
                            <div key='new-unit-btn'>
                                {
                                    tooltipWrapper(
                                        true,
                                        <FormattedMessage id='addNewDotUnit' />,
                                        <button 
                                            disabled={disabled}
                                            type="button" 
                                            className="btn btn-primary" 
                                            style={{width:'3em', height:'3em', marginLeft: '5px'}}
                                            onClick={() => this.setState({ addUnitModalOpen: true })}
                                        > 
                                            + 
                                        </button>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                
                <ReactModal
                    id='cargo-unit-modal'
                    isOpen={this.state.addUnitModalOpen}
                    onRequestClose={this.closeUnitsModal}
                    className="modal-block dialog"
                    overlayClassName="modal-overlay gray"
                    style={{content: {width: '70%'}}}
                >
                    <LoadingOverlay
                        active={this.state.creatingCargoUnit}
                        spinner
                        text={<FormattedMessage id='common.loading'/>}
                    >
                        <div className="modal-dialog-centered" role="document">
                            <div className="modal-content bd-0">
                                    <div className="modal-header pd-x-20">
                                        <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                                            <FormattedMessage id="createCargoUnit" />
                                        </h6>
                                    </div>
                                    <Formik
                                        initialValues={{ name: '', shortName: '' }}
                                        validationSchema={Yup.object().shape({
                                            name: Yup.string()
                                                .min(1, <FormattedMessage id="validation.length.min" values={{ length: 1 }} />)
                                                .required(<FormattedMessage id="validation.required" />),
                                            shortName: Yup.string()
                                                .min(1, <FormattedMessage id="validation.length.min" values={{ length: 1 }} />)
                                                .required(<FormattedMessage id="validation.required" />),
                                            cargoUnitType: Yup.string()
                                                .min(1, <FormattedMessage id="validation.length.min" values={{ length: 1 }} />)
                                                .required(<FormattedMessage id="validation.required" />),
                                        })}
                                        onSubmit={this.handleSubmitUnit}
                                        render={({ isSubmitting, errors, submitForm, validateForm, setFieldValue }) => (
                                            <Form>
                                                <div className="modal-body">
                                                    <div className="row pd-r-20">
                                                        <div className="col-6">
                                                            <TextField id="shortName" title={formatMessage({ id: 'customUnitLabel' })} type='text' className="form-control" showIsRequired={true} showError={true} description={formatMessage({ id: "cargoUnitNameHelper" })} />
                                                        </div>
                                                        <div className="col-6">
                                                            <CreatableSelectField  
                                                                className={`form-control`}
                                                                id={'cargoUnitType'}
                                                                options={unitTypeOptions}
                                                                showIsRequired={true} 
                                                                showError={true}  
                                                                onCreateOption={this.createCargoUnitCategory}
                                                                value={this.state.selectedCargoUnitType}
                                                                onChange={(id, selectedOption) => {
                                                                    setFieldValue(id, selectedOption)
                                                                    return new Promise(resolve =>  this.setState({ selectedCargoUnitType: selectedOption }, resolve))
                                                                }}
                                                                isClearable={true}
                                                                showLabel={true}
                                                                description={formatMessage({ id: "cargoUnitTypeHelper" })}
                                                                type="select"
                                                                removePaddingRight={true}
                                                                seleniumName={'unittype'}
                                                            />
                                                        </div>
                                                        <div className="col-6">
                                                            <TextField id="name" title={formatMessage({ id: 'documentaryName' })} type='text' className="form-control" showIsRequired={true} showError={true} description={formatMessage({ id: "cargoUnitShortNameHelper" })} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer justify-content-center">
                                                        <button 
                                                            type="button" 
                                                            className="btn btn-primary btn-block col-3 pd-x-25" 
                                                            disabled={isSubmitting} 
                                                            onClick={async () => {
                                                                const result = await validateForm()
                                                                var errorNames = Object.keys(result);
                                                                if (errorNames.length === 0) {
                                                                    await submitForm();
                                                                } else {
                                                                    Notifications.error(<FormattedMessage id='common.requiredFieldsMissing' />);
                                                                }
                                                            }}
                                                        >
                                                            <FormattedMessage id="createCargoUnit" />
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn btn-secondary btn-block col-3 pd-x-25"
                                                            style={{marginTop: '0'}}
                                                            onClick={this.closeUnitsModal}
                                                        >
                                                            <FormattedMessage id="common.close" />
                                                        </button>
                                                </div>
                                            </Form>
                                        )}
                                    />
                            </div>
                        </div>
                    </LoadingOverlay>
                </ReactModal>
                </>
            )
        },
        isStaticUnit: (values, disabled = false) => {
            const staticUnitTooltip = (
                <Tooltip 
                    placement={'right'} 
                    trigger={['hover']} 
                    overlay={<FormattedMessage id='staticUnitDescription' />}
                >
                    <i className="material-icons-outlined" style={{ fontSize: '14px' }}>info</i>
                </Tooltip>
            )
            return (
                <div className="form-group col-12">
                    { (!values.default_unit) ?
                        <Tooltip placement={'right'} trigger={['hover']} overlay={<FormattedMessage id="chooseDefaultUnitFirst"/>}>
                            <div>
                                <Fields.Checkbox 
                                    name="is_static_unit" 
                                    checked={false} 
                                    disabled={true} 
                                    extras={{ post: staticUnitTooltip }} />
                            </div>
                        </Tooltip>
                        :
                        <Fields.Checkbox 
                            name="is_static_unit"
                            checked={values.is_static_unit === true}
                            disabled={disabled} 
                            extras={{ post: staticUnitTooltip }} />
                    }
                </div>
            )
        },
        quantityLabel: (disabled = false) => (
            <div className="form-group col-12 date-format-property">
                <Fields.Input
                    type="text"
                    name="quantityLabel"
                    disabled={disabled}
                />
            </div>
        ),
        dashFieldMap: (values, disabled = false) => {
            // Grab the current selected field passed down from the parent component (if the parent passes one)
            // For dash templates with mapped fields - particularly mapped dot fields - this object will be decorated
            // with important metadata not found grabbing the template field directly
            const { currentSelectedField } = this.props;

            let buttonLabel;
            let showHelpTooltip = false;
            let toolTipText;

            if (currentSelectedField && currentSelectedField.type === FieldTypes.FIELD_DATE && currentSelectedField.dateFormat === undefined) {
                // if a date format not selected then disable map button
                disabled = true;
                showHelpTooltip = true;
                buttonLabel = <FormattedMessage id="mapField" />;
                toolTipText = <FormattedMessage id="fieldMapDateFormatMissingTooltip"/>;
            }
            else if (this.props.fieldMappingMode) {
                buttonLabel = <FormattedMessage id="cancelMapping" />;
            } else if (currentSelectedField && currentSelectedField.map_dash_field) {
                buttonLabel = <FormattedMessage id="removeMapping" />
            } else {
                showHelpTooltip = true;
                buttonLabel = <FormattedMessage id="mapField" />
                toolTipText = <FormattedMessage id="fieldMapDescriptionTooltip"/>
            }
            const tooltipWrapper = (children) => {
                return showHelpTooltip ? 
                    <Tooltip placement={'right'} trigger={['hover']} mouseEnterDelay={0.4} overlay={toolTipText} children={children} />
                     :
                    <div children={children} />
            }

            const isSelectField = (values.type === FieldTypes.FIELD_MULTI_SELECT || values.type === FieldTypes.FIELD_SELECT);
            let toolTipEl = null;
            if (isSelectField) {
                toolTipEl = (
                    <div className='pd-y-10'>
                        <Tooltip 
                            placement={'bottom'}
                            trigger={['hover']}
                            overlay={
                                <>
                                    <p><FormattedMessage id="selectFieldMappingInfo1" /></p>
                                    <p><FormattedMessage id="selectFieldMappingInfo2" /></p>
                                </>
                        }>
                            <p><FormattedMessage id='selectFieldMappingTips' /> <i className="material-icons-outlined" style={{fontSize: '16px'}}>info</i></p>
                        </Tooltip>
                    </div>
                );
            }

            return (
                <div className="form-group col-12">
                    <Field
                        {...this.props}
                        render={({ form }) => {
                            return tooltipWrapper(
                                <div 
                                    className={'btn btn-outline-info col-12 se-map-field-btn ' + (disabled ? 'disabled' : '')}
                                    disabled={disabled}
                                    onClick={() => {
                                        if (disabled) return;
                                        const { onComplete = () => null, model } = this.props;
                                        if (currentSelectedField && currentSelectedField.map_dash_field) {
                                            let fieldMapValues;
                                            if (currentSelectedField.isDotField) {
                                                let owning_dash_field_id = currentSelectedField.dash_field_id;
                                                let owning_dot_field_id = String(currentSelectedField.id);
                                                let owning_special_dot_field_id = null;
                                                let map_dash_field_id = currentSelectedField.map_dash_field;
                                                let map_dot_field_id = currentSelectedField.map_dot_field;
                                                let map_special_dot_field = currentSelectedField.map_special_dot_field;
                                                if (owning_dot_field_id.includes('.l')) {
                                                    const idParts = owning_dot_field_id.split('.');
                                                    owning_special_dot_field_id = idParts[1].replace('l', '');
                                                    owning_dot_field_id = null;
                                                }
                                                fieldMapValues = {
                                                    dash_template_id: model.id,
                                                    owning_dash_field_id,
                                                    owning_dot_field_id,
                                                    owning_special_dot_field_id,
                                                    map_dash_field_id,
                                                    map_dot_field_id,
                                                    map_special_dot_field
                                                };
                                            } else {
                                                fieldMapValues = {
                                                    dash_template_id: model.id,
                                                    owning_dash_field_id: currentSelectedField.id,
                                                    map_dash_field_id: currentSelectedField.map_dash_field,
                                                    map_dot_field_id: currentSelectedField.map_dot_field,
                                                    map_special_dot_field: currentSelectedField.map_special_dot_field
                                                };
                                            }
                                            this.props.clearFieldMapping(fieldMapValues, () => {
                                                Notifications.success(<FormattedMessage id='successfullyClearedFieldMapping' />)
                                                onComplete();
                                            });
                                        } else {
                                            if (!this.props.fieldMappingMode) {
                                                this.props.onFieldMapModeSet(true);
                                            } else {
                                                this.props.onFieldMapModeSet(false);
                                            }
                                        }
                                    }}
                                >
                                    { buttonLabel }
                                </div>
                            );
                        }}
                    />
                    <br/>
                    {toolTipEl}
                </div>
            );
        },
    };

    confirmUpdateModelUsers = users => {
        const { fields } = this.props;
        const cargoFields = fields.filter(f => f.type === 'Cargo');
        if (cargoFields.length > 0 && users.length > 0) {
            this.setState({ showShareModal: false, confirmShareModalOpen: true, shareUserIds: users });
        } else {
            this.updateModelUsers(users);
        }
    }

    updateModelUsers = users => {
        const { setModelUsers, user, model } = this.props;
        const filteredUsers = users.filter(u => u !== user.id);
        const modelUserObjects = filteredUsers.map(u => ({ user_id: u.id, include_transactions: u.include_transactions }));
        const clearLoading = () => this.setState({ shareModalLoading: false, showShareModal: false, confirmShareModalOpen: false });
        this.setState({ shareModalLoading: true }, () => {
            setModelUsers({ model_id: model.id, model_users: modelUserObjects }, clearLoading, clearLoading);
        })
    }
    getModelProperties = values => {
        const {
            modelStatuses,
            model,
            user,
            fields,
            readOnly,
        } = this.props;
        const sharingWillCascade = fields.filter(f => f.type === 'Cargo').length > 0;
        const { status_name } = model;
        const modelNotEditable = status_name !== 'Draft' || readOnly;

        const title = this.typeSpecificProperties.title(modelNotEditable);
        const description = this.typeSpecificProperties.description(modelNotEditable);
        const modelWithFields = Object.assign({}, model);
        modelWithFields.model_field_list = fields;
        const status = this.typeSpecificProperties.status(modelStatuses.map(s => ({ value: s.id, label: s.status_name })), values, modelNotEditable, modelWithFields, readOnly);
        const shareButton = (
            <div className="form-group col-12" style={{width: '100%'}}>
                    <div className="field-button col-12 pd-b-15">
                        <Popover
                            // usePortal={false}
                            fill={true}
                            position="bottom"
                            content={
                                <Menu>
                                    <MenuItem
                                        text={
                                            <>
                                                <FormattedMessage id="myOrganization" />
                                                <Tooltip 
                                                    placement={'top'} 
                                                    trigger={['hover']} 
                                                    overlayStyle={{zIndex: 300}}
                                                    overlay={
                                                        <>
                                                            <p><FormattedMessage id="localSharingExplanation1" /></p>
                                                            <p><FormattedMessage id="localSharingExplanation2" /></p>
                                                            <p><FormattedMessage id="localSharingExplanation3" /></p>
                                                            <p><FormattedMessage id="localSharingExplanation4" /></p>
                                                            <p><FormattedMessage id="localSharingExplanation5" /></p>
                                                            <p><FormattedMessage id="localSharingExplanation6" /></p>
                                                        </>
                                                }>
                                                    <i className="material-icons-outlined" style={{fontSize: "14px", marginLeft: "0.5em"}}>info</i>
                                                </Tooltip>
                                            </>
                                        }
                                        onClick={() => this.setState({ showShareModal: true })}
                                    />
                                    <MenuItem
                                        text={
                                            <>
                                                <FormattedMessage id="externalUsers" />
                                                <Tooltip 
                                                    placement={'bottom'} 
                                                    trigger={['hover']} 
                                                    overlayStyle={{zIndex: 300}}
                                                    overlay={
                                                        <>
                                                            <p><FormattedMessage id="externalSharingExplanation1" /></p>
                                                            <p><FormattedMessage id="externalSharingExplanation2" /></p>
                                                            <p><FormattedMessage id="externalSharingExplanation3" /></p>
                                                            <p><FormattedMessage id="externalSharingExplanation4" /></p>
                                                            <p><FormattedMessage id="externalSharingExplanation5" /></p>
                                                            <p><FormattedMessage id="externalSharingExplanation6" /></p>
                                                        </>
                                                }>
                                                    <i className="material-icons-outlined" style={{fontSize: "14px", marginLeft: "0.5em"}}>info</i>
                                                </Tooltip>
                                            </>
                                        }
                                        onClick={() => this.setState({ showExternalShareModal: true })}
                                    />
                                </Menu>
                            }
                        >
                            <button className='btn btn-block ellipsis btn-outline-info col-12'>
                                <FormattedMessage id='share' />
                            </button>
                        </Popover>
                    </div>
                <InternalSharingModal
                    key={'subscriberModal-' + this.state.shareModalCount}
                    titleId={'templateUsers'}
                    showModal={this.state.showShareModal}
                    modelType={model.model_type_name}
                    externalOptions={{role_ids: [4]}}
                    setModalVisibility={(val) => this.setState({ showShareModal: val, shareModalCount: this.state.shareModalCount + 1 })}
                    subscribers={model.model_users} 
                    loading={this.state.shareModalLoading}
                    externalIds={[{id: user.id}]}
                    disabledIds={[{id: user.id}]}
                    onConfirm={this.confirmUpdateModelUsers}
                    
                />
                <ExternalSharingModal
                    key={'externalSharingModal-' + this.state.shareModalCount}
                    titleId={'templateUsersOtherOrganization'}
                    modelId={model.id}
                    modelType={model.model_type_name}
                    showModal={this.state.showExternalShareModal}
                    setModalVisibility={(val) => this.setState({ showExternalShareModal: val, shareModalCount: this.state.shareModalCount + 1 })}
                    sharingWillCascade={sharingWillCascade}
                    loading={this.state.shareModalLoading}
                />
            </div>
            
        );
        return [title, description, status, shareButton];
    };

    getEligibleIndexFields = values => {
        const { fields, indexFields, modelTypeId, currentSelectedField } = this.props;
        const { type, index_field_id } = values;

        const usedIndices = fields.map(f => f.index_field_id).filter(f => !!f && f !== index_field_id);
        return indexFields.filter(({ id, type_name, model_type, status_id }) => type_name === type && model_type === ((currentSelectedField && currentSelectedField.isDotField) ? 2 : modelTypeId) && status_id === 2 && !usedIndices.includes(id));
    };

    getTypeSpecificProperties = values => {
        const {
            fieldTypes,
            model: { status_name },
            modelTypeId,
            readOnly,
            intl: { formatMessage },
        } = this.props;

        const modelNotEditable = status_name !== 'Draft' || readOnly || values.isDotField;
        const options = this.typeSpecificProperties.options(modelNotEditable);
        const eligibleIndexTypes = this.getEligibleIndexFields(values);
        const dateFormatOptions = [
            { value: 'DD-MMM-YYYY', label: <FormattedMessage id='DD-MMM-YYYY' /> },
            { value: 'MM/DD/YYYY', label: <FormattedMessage id='MM/DD/YYYY' /> },
            { value: 'DD/MM/YYYY', label: <FormattedMessage id='DD/MM/YYYY' /> },
            { value: 'YYYY/MM/DD', label: <FormattedMessage id='YYYY/MM/DD' /> },
            { value: 'YYYY', label: <FormattedMessage id='YYYY' /> },
            { value: 'MM', label: <FormattedMessage id='MM' /> },
            { value: 'Q', label: <FormattedMessage id='Q' /> },
            { value: 'DDD', label: <FormattedMessage id='DDD' /> },
            { value: 'DD', label: <FormattedMessage id='DD' /> },
            { value: 'MM/YYYY', label: <FormattedMessage id='MM/YYYY' /> },
            { value: 'YYYY/MM', label: <FormattedMessage id='YYYY/MM' /> },
        ]

        const title = this.typeSpecificProperties.title(modelNotEditable);
        const description = this.typeSpecificProperties.description(modelNotEditable);
        const width = this.typeSpecificProperties.width(modelNotEditable);
        const dateFormat = this.typeSpecificProperties.dateFormat(dateFormatOptions, values, modelNotEditable);
        const step = this.typeSpecificProperties.step(modelNotEditable, values);
        const min = this.typeSpecificProperties.min(modelNotEditable, values);
        const max = this.typeSpecificProperties.max(modelNotEditable, values);
        const defaultValue = this.typeSpecificProperties.defaultValue(values, modelNotEditable);
        const visibilityCondition = this.typeSpecificProperties.visibilityCondition(values, modelNotEditable);
        const breakRow = this.typeSpecificProperties.breakRow(values, modelNotEditable);
        const isRequired = this.typeSpecificProperties.isRequired(values, modelNotEditable);
        const type = this.typeSpecificProperties.type(fieldTypes.map(({ type_name }) => ({ value: type_name, label: <FormattedMessage id={type_name} defaultMessage={(type_name === 'Cargo' ? 'DoT' : type_name)} /> })), values, modelNotEditable, modelTypeId);
        const indexed = this.typeSpecificProperties.indices(eligibleIndexTypes.map(({ id, title }) => ({ value: id, label: title })), values, modelNotEditable);
        const groupThousands = this.typeSpecificProperties.groupThousands(values, modelNotEditable);
        const numberFormat = this.typeSpecificProperties.numberFormat([{value: "DotSeparator", label: <FormattedMessage id='period' />}, {value: "CommaSeparator", label: <FormattedMessage id='comma' />}], values, modelNotEditable);

        const cargoCategories = this.state.cargoCategories.map(cc => {return {value: cc.id, label: cc.title + (cc.status_id === 1 ? (' (' + formatMessage({ id: 'Draft' }) + ')') : '')}});
        const cargoCategory = this.typeSpecificProperties.cargoCategories(cargoCategories, values, modelNotEditable);
        const cargoType = this.typeSpecificProperties.cargoType(values, modelNotEditable);
        const cargoReferenceOptions = [{value: 1, label: <FormattedMessage id='Input' />}, {value: 2, label: <FormattedMessage id='Output' />}, {value: 3, label: <FormattedMessage id='Transform' />}];
        const cargoReferenceType = this.typeSpecificProperties.cargoReferenceType(cargoReferenceOptions, values, modelNotEditable);
        const transformCargoType = (values.cargoReferenceType === 3) ? this.typeSpecificProperties.transformCargoType(values, modelNotEditable) : null;

        const isStaticUnit = this.typeSpecificProperties.isStaticUnit(values, modelNotEditable);
        const defaultUnit = this.typeSpecificProperties.defaultUnit(values, modelNotEditable);
        const quantityLabel = this.typeSpecificProperties.quantityLabel(modelNotEditable);
        const fieldMap = this.typeSpecificProperties.dashFieldMap(values, modelNotEditable);
        const dotFieldMap = this.typeSpecificProperties.dashFieldMap(values, status_name !== 'Draft' || readOnly);

        let typeSpecificFieldMapEl = values.isDotField ? dotFieldMap : fieldMap;
        if (modelTypeId !== 1) {
            typeSpecificFieldMapEl = null;
        }

        //
        switch (values.type) {
            case FieldTypes.FIELD_TEXT:                
                return [type, title, description, width, breakRow, defaultValue, isRequired, indexed, visibilityCondition, typeSpecificFieldMapEl];

            case FieldTypes.FIELD_NUMBER:
                return [type, title, description, width, breakRow, defaultValue, step, min, max, groupThousands, numberFormat, isRequired, indexed, visibilityCondition, typeSpecificFieldMapEl];

            case FieldTypes.FIELD_DATE:
                return [type, title, description, dateFormat, width, breakRow, defaultValue, isRequired, indexed, visibilityCondition, typeSpecificFieldMapEl];

            case FieldTypes.FIELD_YES_NO:
                return [type, title, description, width, breakRow, defaultValue, isRequired, indexed, visibilityCondition, typeSpecificFieldMapEl];

            case FieldTypes.FIELD_SELECT:
            case FieldTypes.FIELD_MULTI_SELECT:
                return [type, title, description, width, breakRow, options, defaultValue, isRequired, indexed, visibilityCondition, typeSpecificFieldMapEl];

            case FieldTypes.FIELD_CURRENCY:
                return [type, title, description, width, breakRow, defaultValue, isRequired, indexed, visibilityCondition, typeSpecificFieldMapEl];

            case FieldTypes.FIELD_MULTI_TEXT:
                return [type, title, description, width, breakRow, defaultValue, isRequired, indexed, visibilityCondition, typeSpecificFieldMapEl];

            case FieldTypes.FIELD_SECTION:
                return [type, title, description, visibilityCondition];

            case FieldTypes.FIELD_ATTACHMENT:
                return [type, title, description, width, breakRow, indexed, isRequired, visibilityCondition, typeSpecificFieldMapEl];

            case FieldTypes.FIELD_CARGO:
                return [type, title, description, cargoCategory, cargoReferenceType, cargoType, transformCargoType, isRequired, quantityLabel, defaultUnit, isStaticUnit, visibilityCondition];

            case FieldTypes.FIELD_DOT_QUANTITY:
            case FieldTypes.FIELD_DOT_UNIT:
                return [typeSpecificFieldMapEl];

            case FieldTypes.FIELD_NONE:
            default:
                return [];
        }
    };
    
    handleOnMouseClickOnProperties( event, onMouseClick ) {
        const domNode = ReactDOM.findDOMNode(this.contentRef.current);
        if (domNode && domNode.contains(event.target)) {
            onMouseClick(event);               
        }
    }

    componentDidMount() {
        const { fieldTypes, getFieldTypes, modelStatuses, getModelStatuses, indexFields, getIndices, getModels, getProfile, company, listCargoUnits, onMouseClick } = this.props;
        if (!company) {
            getProfile();
        }
        if (!fieldTypes) getFieldTypes();
        if (!modelStatuses) getModelStatuses();
        if (!indexFields) getIndices({ status_ids: [2] });
        
        listCargoUnits(null);
        
        getModels({ status_ids: [1, 2], model_type: 2 }, (cargoCategories) => {
            this.setState({cargoCategories: cargoCategories.data});
        })

        this.updateInterval = setInterval(() => {
            this.persistChanges();
        }, 5000);

        if (document.addEventListener) {            
            document.removeEventListener("click", this.handleOnMouseClickOnProperties);
            document.addEventListener("click", (e) => this.handleOnMouseClickOnProperties(e, onMouseClick));
        }
        else {
            document.detachEvent("onclick", this.handleOnMouseClickOnProperties);
            document.attachEvent('onclick', (e) => this.handleOnMouseClickOnProperties(e, onMouseClick));
        }
    }

    async componentWillUnmount() {    
        await this.persistChanges();
        clearInterval(this.updateInterval);

        if (document.addEventListener) {
            document.removeEventListener("click", this.handleOnMouseClickOnProperties);
        }
        else {
            document.detachEvent("onclick", this.handleOnMouseClickOnProperties);
        }
    }

    setFieldVisibilityConditions = (field, visibilityCondition) => {
        const { updateField } = this.props;
        updateField({ ...field, visibilityCondition });
    };

    persistChanges = async () => {
        const { model, fields, modelChanged, fieldsChanged, persistModelChanges, persistBatchFieldUpdates, getModels } = this.props;
        if (modelChanged) {
            await persistModelChanges(model, () => {
                if (this.state.isStatusChanging) {
                    this.setState({ statusModalLoading: false, statusModalOpen: false });
                    Notifications.success(<FormattedMessage id='updatedModel' />);
                } else {
                    this.setState({ statusModalLoading: false });
                }
            });
        }
        if (fieldsChanged) {
           await persistBatchFieldUpdates(model.id, fields);
        }
    };

    handleSubmit = values => {
        const { updateField, modelVisible, updateModel, fieldTypes, model, modelStatuses } = this.props;

        if (Number(values.min) > Number(values.max)) {
            this.setState({minMaxError: true});
        } else {
            this.setState({minMaxError: false});
            if (modelVisible) {
                // If the user is changing the model status, wait for the changes to propagate
                if (values.status_id !== 1) {
                    this.setState({ statusModalLoading: true });
                    updateModel({ ...values, status_name: (modelStatuses.find(s => s.id === values.status_id) || {}).status_name });
                } else {
                    updateModel({ ...values, status_name: (modelStatuses.find(s => s.id === values.status_id) || {}).status_name });
                }
            } else {
                
                if (model.status_name !== 'Draft') return;

                if (this.props.currentSelectedField){
                    updateField({ ...values, type_id: fieldTypes.find(f => f.type_name === values.type).id, visibilityCondition: this.props.currentSelectedField.visibilityCondition });
                }
            }
        }
    };

    getFieldInitialValues = field => {
        if (field.type === FieldTypes.FIELD_DATE) {
            return { ...field, dateFormat: !!field.dateFormat ? field.dateFormat : 'DD-MMM-YYYY' };
        }

        return field;
    };

    setModelUpdated = values => {
        if (Number(values.min) > Number(values.max)) {
            this.setState({minMaxError: true});
        }
        this.setState({values: values, modelUpdated: true, modelIsSaving: true});
    }

    render() {
        const { id, fieldTypes, field, model, modelVisible, selectField, setModelPropertiesVisibility, modelStatuses, indexFields, readOnly, cargoCategories } = this.props;
        if (!fieldTypes || !modelStatuses || !indexFields) return null;
        
        let selectedField = field;
        if (!selectedField) {
            if (id && id.endsWith('.l1')) {
                selectedField = new FieldDefinition({ id: id, special_id: 'l1', title: <FormattedMessage id='quantity'/>, type: FieldTypes.FIELD_DOT_QUANTITY,  });
                // selectedField
                selectedField.isDotField = true;
            } else if (id && id.endsWith('.l2')) {
                selectedField = new FieldDefinition({ id: id, special_id: 'l2', title: <FormattedMessage id='common.cargoUnitName'/>, type: FieldTypes.FIELD_DOT_UNIT,  });
                selectedField.isDotField = true;
            } else {
                const dotFieldCargoCategory = (cargoCategories || []).find(cc => cc.model_field_list.find(f => f.id === id));
                if (dotFieldCargoCategory) {
                    selectedField = dotFieldCargoCategory.model_field_list.find(f => f.id === id);
                    if (selectedField) {
                        selectedField = FieldDefinition.fromDB(selectedField)
                        selectedField.isDotField = true;
                    }
                }
            }
        }

        return (
            <div ref={this.contentRef} className="col-md-6 col-lg-3 col-xl-2 pd-x-5">
                <div className="fields-properties widget-2 mg-b-20">
                    <div className="card overflow-hidden">
                        <div className="card-header">
                            <h6 className="card-title">
                                <FormattedMessage id="common.properties" />
                            </h6>
                        </div>
                        <div className="card-body pd-0 bd-color-gray-lighter">
                            <div className="row no-gutters">
                                <div className="col-12">
                                    <SimpleBar>
                                        {!selectedField && !modelVisible ? (
                                            <div className="pd-20 tx-center tx-12">
                                                <FormattedMessage id="models.fields.noFieldSelected" />
                                            </div>
                                        ) : (
                                            <Formik
                                                key={selectedField ? `${selectedField.id}-${this.state.fieldPropsRenderCount}` : `model-${model.id}`}
                                                enableReinitialize={false}
                                                initialValues={{ ...(modelVisible ? model : this.getFieldInitialValues(selectedField)) }}
                                                onSubmit={debounce(this.handleSubmit, 500)}
                                                render={({ values, handleChange, submitForm }) => {
                                                    const properties = modelVisible ? this.getModelProperties(values) : this.getTypeSpecificProperties(values);
                                                    return (
                                                        <Form
                                                            noValidate
                                                            onChange={async e => {
                                                                if (readOnly) return;
                                                                await handleChange(e);
                                                                submitForm();
                                                            }}
                                                        >
                                                            <div className="properties-form">
                                                                {properties.map((property, i) => (
                                                                    <React.Fragment key={i}>{property}</React.Fragment>
                                                                ))}
                                                            </div>
                                                        </Form>
                                                    );
                                                }}
                                            />
                                        )}
                                    </SimpleBar>
                                    <div className="card-footer tx-12 mg-0 pd-0 bg-transparent">
                                        <div className="row mg-0">
                                            <span className={`col footer-link ${modelVisible ? '' : 'active'} ${this.props.fieldMappingMode ? 'disabled' : ''}`} onClick={() => !this.props.fieldMappingMode && selectField()}>
                                                <FormattedMessage id="model.properties.fields" />
                                            </span>
                                            <span
                                                className={`col footer-link ${!modelVisible ? '' : 'active'} ${this.props.fieldMappingMode ? 'disabled' : ''}`}
                                                onClick={() => !this.props.fieldMappingMode && setModelPropertiesVisibility(true)}
                                                style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}
                                            >
                                                {
                                                    this.props.modelTypeId === 1 ?
                                                    <FormattedMessage id="page.eventTemplates.properties.label" />
                                                    :
                                                    <FormattedMessage id="page.cargoCategories.properties.label" />
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderDialog()}
                <ConfirmModal
                    open={this.state.confirmShareModalOpen}
                    loading={this.state.shareModalLoading}
                    setOpen={(o) => this.setState({ confirmShareModalOpen: o, shareModalCount: this.state.shareModalCount + 1 })}
                    onConfirm={() => {
                        this.updateModelUsers(this.state.shareUserIds);
                    }}
                    stayOpenOnConfirm={true}
                    title={<FormattedMessage id='common.confirmVerb' values={{ verb: 'Share'}} />}
                    body={<FormattedMessage id='confirmTemplateShareCascade' />}
                />
                <VisibilityConditions field={field} onConditionsSet={conditions => this.setFieldVisibilityConditions(field, conditions)} disabled={model.status_id !== 1} />
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const field = state.fields.items ? state.fields.items.find(f => f.id === props.id) : undefined;
    return {
        modelChanged: state.fields.modelChanged,
        fieldsChanged: state.fields.fieldsChanged,
        fieldTypes: state.fields.types,
        field,
        fields: state.fields.items,
        model: state.fields.model,
        modelVisible: state.fields.modelVisible,
        modelStatuses: state.models.statuses,
        indexFields: state.indices.list,
        user: state.identity.user,
        company: state.identity.company,
        cargoUnits: state.cargoUnits.list,
    };
};
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getFieldTypes,
            updateField,
            updateModel,
            persistModelChanges,
            persistSingleFieldUpdate,
            persistBatchFieldUpdates,
            setModelPropertiesVisibility,
            createFieldValue,
            deleteFieldValue,
            getModelStatuses,
            getIndices,
            getModels,
            createTransaction,
            getTransaction,
            loadModel,
            getProfile,
            setModelUsers,
            listCargoUnits,
            createCargoUnit,
            setFieldMapping,
            clearFieldMapping,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(FieldProperties));
