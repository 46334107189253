import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Formik, Form } from 'formik';
import ReactModal from 'react-modal';
import { Fields } from 'components/fields';
import { getComparators, getIndexValues, getFieldValues } from 'reducers/fieldVisibility';
import { getStatuses } from 'reducers/transactions';
import { FormattedMessage } from 'react-intl';
import TagFilters from './tagFilters';
import { getFormattedFieldValue, FieldTypes } from 'models/field';
import FilterPreview from 'pages/events/components/queryBuilder/filterPreview';
import FieldFilters from './fieldFilters';
import SelectDashTemplateModal from 'pages/events/components/selectDashTemplateModal';
import { loadModel } from 'reducers/models';

class QueryBuilder extends React.Component {
    constructor(props) {
        super(props);
        this.state = props.initialState ? props.initialState : {
            fields: props.fields,
            filters: { status_ids: [1, 3, 7] },
            advancedFilters: props.externalFilters || [],
            fieldFilters: [],
            modelValues: [],
            modal: { open: false },
            includeDeletedTemplates: false,
            includeArchivedTemplates: false,
            selectedModels:  props.externalModels || [],
            popupSelectedModels: [],
            dotTemplatesFromSelectedDashes: []
        };
    }

    componentDidMount() {
        const { comparators, getComparators, statuses, getStatuses, externalFilters, setFilters } = this.props;
        if (!comparators) getComparators();
        if (!statuses) getStatuses();
    }

    componentDidUpdate() {
        const oldFields = this.state.fields;;
        const newFields = this.props.fields.map(f => f.id);
        const fieldsNotPreviouslyPassed = this.props.fields.filter(f => !oldFields.map(f => f.id).includes(f.id));
        if (fieldsNotPreviouslyPassed.length > 0) {
            this.setState({ fields: oldFields.concat(fieldsNotPreviouslyPassed) });
        }
    }

    removeAdvancedFilter = id => {
        this.setState(prevState => {
            return { advancedFilters: [...prevState.advancedFilters.filter(l => l.id !== id)] };
        }, () => {
            const { externalFilters } = this.props;
            const { advancedFilters } = this.state;
            const advancedFiltersToSet = (advancedFilters && advancedFilters.length > 0) ? advancedFilters : externalFilters;
            this.setFilters(undefined, advancedFiltersToSet, this.state.fieldFilters, undefined);
        });
    };

    removeFieldFilter = id => {
        const { fieldFilters } = this.state;

        this.setState({ fieldFilters: [...fieldFilters.filter(l => l.id !== id)] }, () => {
            this.setFilters(undefined, this.state.advancedFilters, this.state.fieldFilters, undefined);
        });
    }

    setFilters(filters, advancedFilters, fieldFilters, modalVisible = undefined) {
        const { onFiltersSet, externalFilters = [], stateSetter = () => null, models, modelType = ['event template', 'cargo category'], externalModels = [] } = this.props;
        const { selectedModels, fields, } = this.state;

        const dotTemplateIds = selectedModels
            .concat(externalModels.filter(m => !!m.model_field_list))
            .map(m => m.model_field_list)
            .flat()
            .filter(field => field.type_name === 'Cargo')
            .map(field => field.cargoCategory)

        const dotTemplates = models
            .filter(m => dotTemplateIds.includes(m.id));

        const modelFields = selectedModels
            .concat(externalModels.filter(m => !!m.model_field_list))
            .concat(dotTemplates)
            .map(m => m.model_field_list)
            .flat();

        let assignedAdvancedFilters = advancedFilters || this.state.advancedFilters;
        assignedAdvancedFilters = assignedAdvancedFilters.filter(f => f.hasOwnProperty('field') && f.hasOwnProperty('comparator'));
        if (!assignedAdvancedFilters || assignedAdvancedFilters.length === 0) {
            assignedAdvancedFilters = externalFilters;
        }
        let assignedFieldFilters = fieldFilters || this.state.fieldFilters;
        assignedFieldFilters = assignedFieldFilters.filter(f => f.hasOwnProperty('field') && f.hasOwnProperty('comparator'));
        
        this.setState({ 
                filters: filters || this.state.filters,
                advancedFilters: assignedAdvancedFilters,
                fieldFilters: assignedFieldFilters,
                showFieldFilterModal: modalVisible !== undefined ? !!modalVisible : this.state.showFieldFilterModal,
                modal: modalVisible !== undefined ? { open: !!modalVisible } : { ...this.state.modal },
            },
            () => {
                const transformedFilters = {
                    ...this.state.filters,
                    criteria: JSON.stringify(
                        this.state.advancedFilters.map(fd => {
                            let f = { ...fd };
                            if (f.comparator === 13) {
                                f.comparator = 9;
                                f.is_not = true;
                            }

                            if (f.comparator === 12) {
                                f.filterValue = null;
                            }

                            return {
                                ...f,
                                index_field_id: f.field,
                                comparator_id: f.comparator,
                                ...getFormattedFieldValue((fields.find(i => i.id === f.field) || {}).type_name, f.filterValue, !Array.isArray(f.filterValue)),
                                comparator: undefined,
                                id: undefined,
                                field: undefined,
                                filterValue: undefined
                            };
                        })
                    ),
                    field_criteria: JSON.stringify(
                        this.state.fieldFilters.map(fd => {
                            let f = { ...fd };
                            if (f.comparator === 13) {
                                f.comparator = 9;
                                f.is_not = true;
                            }

                            if (f.comparator === 12) {
                                f.filterValue = null;
                            }

                            return {
                                ...f,
                                field_id: f.field,
                                comparator_id: f.comparator,
                                ...getFormattedFieldValue((modelFields.find(i => i.id === f.field) || {}).type_name, f.filterValue, !Array.isArray(f.filterValue)),
                                comparator: undefined,
                                id: undefined,
                                field: undefined,
                                filterValue: undefined
                            };
                        })
                    )
                };
                stateSetter(this.state);
                onFiltersSet(transformedFilters);
            }
        );
    }

    setModalVisibility = open => {
        this.setState({ ...this.state, modal: { open } });
    };

    formatFilters = (values, setFieldValue) => {
        let updatedValues = { ...values };
        let momentFrom, momentTo;
        if (!!values.createdFrom && !!values.to) {
            momentFrom = moment(values.createdFrom, 'DD-MMM-YYYY', true);
            momentTo = moment(values.to, 'DD-MMM-YYYY', true);
            let bothValid = true;
            if (!momentFrom.isValid()) {
                updatedValues.createdFrom = null;
                bothValid = false;
            } 
            if (!momentTo.isValid()) {
                updatedValues.to = null;
                bothValid = false;
            } 
            if (bothValid && momentFrom >= momentTo) {
                let newTo = momentFrom.add(1, 'days').toDate();
                setFieldValue('to', newTo);
                updatedValues.to = newTo;
            }
        } else  {
            if (!!values.createdFrom) {
                momentFrom = moment(values.createdFrom, 'DD-MMM-YYYY', true);
                if (!momentFrom.isValid()) {
                    updatedValues.createdFrom = null;
                } 
            }
            if (!!values.to) {
                momentTo = moment(values.to, 'DD-MMM-YYYY', true);
                if (!momentTo.isValid()) {
                    updatedValues.to = null;
                } 
            }
        }
        if (updatedValues.createdFrom === '') {
            updatedValues.createdFrom = null;
        }
        if (updatedValues.to === '') {
            updatedValues.to = null;
        }
        const { models, status, createdFrom, to } = updatedValues;
        const filters = {
            status_ids: status,
            model_ids: models,
            created_from: createdFrom,
            created_to: to
        };

        return filters;
    };

    handleSubmit = (values, form) => {
        const { valuesSetter = () => null } = this.props;
        valuesSetter(values);
        this.setState({ modelValues: values.models || [] });
        const filters = this.formatFilters(values, form.setFieldValue);
        this.setFilters(filters);
    };

    renderForm = () => {
        const { filters, advancedFilters, modal, fieldFilters, selectedModels = [], fields, } = this.state;
        const { 
            models, 
            comparators, 
            statuses, 
            indexValues, 
            getIndexValues, 
            unavailableStatuses = [], 
            statusSelectorDisabled = false,
            externalFilters = [],
            externalModels = [],
            modelsPlaceholder = null,
            fieldValues,
            getFieldValues,
            modelType = ['event template', 'cargo category'],
            modelsLoading = false,
            onModelsSet = () => null,
            onDotTemplatesSet = () => null,
        } = this.props;

        // const allTagFilters = externalFilters.concat(advancedFilters);
        let allTagFilters = advancedFilters;
        if (!allTagFilters || allTagFilters.length === 0) {
            allTagFilters = externalFilters;
        }

        const targetStatuses = statuses.filter(s => !unavailableStatuses.includes(s.status_name) && s.id !== 8);

        const filteredModels = selectedModels
            .filter(m => 
                modelType.includes(m.model_type_name)
            );

        const dotTemplateIds = this.state.selectedModels
            .concat(externalModels.filter(m => !!m.model_field_list))
            .map(m => m.model_field_list)
            .flat()
            .filter(field => field.type_name === 'Cargo')
            .map(field => field.cargoCategory)

        const dotTemplates = models
            .filter(m => dotTemplateIds.includes(m.id));

        const allFields = this.state.selectedModels
            .concat(externalModels.filter(m => !!m.model_field_list))
            .concat(dotTemplates)
            .map(m => m.model_field_list)
            .flat();

        return (
            <React.Fragment>
                <Formik
                    enableReinitialize={true}
                    initialValues={this.props.initialValues || { models: filters.model_ids, status: filters.status_ids }}
                    onSubmit={(values, form) => this.handleSubmit(values, form)}
                    render={({ values, handleChange, submitForm, setFieldValue }) => {
                        
                        return (
                            <Form
                                noValidate
                                onChange={async e => {
                                    await handleChange(e);
                                    submitForm();
                                }}
                            >
                                <React.Fragment>
                                    <div className="row mg-b-15">
                                        <div className="col-10" onChange={e => { e.preventDefault(); e.stopPropagation(); }}>
                                            <Fields.Select
                                                name="models"
                                                placeholder={modelsLoading ? <FormattedMessage id='common.loading'/> : modelsPlaceholder}
                                                submitOnChange={true}
                                                extraOnChange={(_, valueObjs) => { 
                                                    const values = valueObjs.map(o => o.value);
                                                    this.setState(prevState => {
                                                        const previousPopupModels = prevState.popupSelectedModels || [];
                                                        const previousSelectedModels = prevState.selectedModels || [];
                                                        return { 
                                                            popupSelectedModels: previousPopupModels.filter(m => values.includes(m.id)), 
                                                            selectedModels: previousSelectedModels.filter(m => values.includes(m.id)) 
                                                        }
                                                    }, () => onModelsSet(this.state.selectedModels));
                                                }}
                                                value={externalModels.map(m => m.id).concat((values.models || []))}
                                                multi={true}
                                                options={externalModels.concat(filteredModels).map(m => ({ value: m.id, label: m.title }))}
                                                isClearable={true}
                                                hideDropDown={true}
                                            />
                                            <SelectDashTemplateModal 
                                                modalOpen={this.state.selectDashTemplateModalOpen}
                                                setModalOpen={state => this.setState({ selectDashTemplateModalOpen: state })}
                                                toggleDashTemplateSelected={template => {
                                                    this.setState(prevState => {
                                                        const popupSelectedModels = prevState.popupSelectedModels;
                                                        if (popupSelectedModels.find(m => m.id === template.id)) {
                                                            return { popupSelectedModels: popupSelectedModels.filter(m => m.id !== template.id) }
                                                        } else {
                                                            popupSelectedModels.push(template);
                                                            return { popupSelectedModels };
                                                        }
                                                    })
                                                }}
                                                confirmSelectedTemplates={async () => {
                                                    const { loadModel } = this.props;
                                                    await setFieldValue('models', this.state.popupSelectedModels.map(m => m.id));
                                                    onModelsSet(this.state.popupSelectedModels);
                                                    submitForm();
                                                    this.setState(prevState => ({ selectedModels: prevState.popupSelectedModels, selectDashTemplateModalOpen: false }));
                                                    const dotTemplatePromises = [];
                                                    const dotTypeFields = this.state.popupSelectedModels
                                                        .map(m => m.model_field_list)
                                                        .flat()
                                                        .filter(f => f.type_name === FieldTypes.FIELD_CARGO)
                                                    dotTypeFields.forEach(dotTypeField => {
                                                        if (dotTypeField.cargoCategory) {
                                                            dotTemplatePromises.push(new Promise(resolve => loadModel(dotTypeField.cargoCategory, resolve)));
                                                        }
                                                    })
                                                    Promise.all(dotTemplatePromises).then((values) => {
                                                        this.setState({dotTemplatesFromSelectedDashes: values});
                                                        onDotTemplatesSet(values);
                                                    });
                                                }}
                                                selectedDashTemplates={this.state.popupSelectedModels}
                                            />
                                        </div>
                                        <div className='col-2 pd-l-0'>
                                            <button
                                                className="btn btn-primary col icon-button field-inline se-template-filters-button"
                                                type="button"
                                                onClick={() => {
                                                    this.setState(prevState => ({
                                                        popupSelectedModels: [...prevState.selectedModels],
                                                        selectDashTemplateModalOpen: true
                                                    }));
                                                }}
                                            >
                                                <FormattedMessage id='chooseTemplates' />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <Fields.Select
                                                name="status"
                                                submitOnChange={true}
                                                multi={true}
                                                value={values.status}
                                                options={targetStatuses.map(s => ({ value: s.id, label: <FormattedMessage id={s.status_name} />}))}
                                                disabled={statusSelectorDisabled}
                                                useFormattedMessageFilter={true}
                                            />
                                        </div>
                                        <div className="col-2 pd-l-0">
                                            <Fields.Date name="createdFrom" submitOnChange={true} />
                                        </div>
                                        <div className="col-2 pd-l-0">
                                            <Fields.Date name="to" submitOnChange={true} />
                                        </div>
                                        <div className="col-2 pd-l-0">
                                            {
                                                externalModels.concat(values.models || []).length > 0 ?
                                                    <button
                                                        className="btn btn-primary col icon-button field-inline se-field-filters-button"
                                                        type="button"
                                                        onClick={() => this.setState({ showFieldFilterModal: true })}
                                                    >
                                                        <i className="material-icons-outlined">filter_list</i>
                                                        <span>
                                                            <FormattedMessage id="fieldFiltersWithCount" values={{ count: fieldFilters.length }} />
                                                        </span>
                                                    </button>
                                                    :
                                                    <button
                                                        className="btn btn-primary col icon-button field-inline se-tag-filters-button"
                                                        type="button"
                                                        onClick={() => this.setModalVisibility(true)}
                                                    >
                                                        <i className="material-icons-outlined">local_offer</i>
                                                        <span>
                                                            <FormattedMessage id="page.transactions.filters" values={{ count: allTagFilters.length }} />
                                                        </span>
                                                    </button>
                                            }
                                        </div>
                                    </div>
                                </React.Fragment>
                            </Form>
                        );
                    }}
                />
                <ReactModal
                    isOpen={modal.open}
                    onRequestClose={() => this.setModalVisibility(false)}
                    className="modal-block dialog"
                    overlayClassName="modal-overlay gray"
                >
                    <TagFilters
                        fields={fields}
                        filters={allTagFilters.filter(t => t.type_name !== 'Cargo')}
                        comparators={comparators}
                        indexValues={indexValues}
                        loadIndexValues={getIndexValues}
                        availableModels={filteredModels}
                        selectedModelIds={externalModels.map(m => m.id).concat(this.state.modelValues || [])}
                        onFiltersSet={f => this.setFilters(undefined, f, fieldFilters, false)}
                        onCancel={() => this.setModalVisibility(false)}
                    />
                </ReactModal>
                <ReactModal
                    isOpen={this.state.showFieldFilterModal}
                    onRequestClose={() => this.setState({ showFieldFilterModal: false })}
                    className="modal-block dialog"
                    overlayClassName="modal-overlay gray"
                >
                    <FieldFilters
                        fields={allFields}
                        filters={fieldFilters}
                        comparators={comparators}
                        indexValues={fieldValues}
                        loadIndexValues={getFieldValues}
                        availableModels={filteredModels.concat(externalModels)}
                        allModels={models}
                        selectedModelIds={externalModels.map(m => m.id).concat(this.state.modelValues || [])}
                        onFiltersSet={f => this.setFilters(undefined, allTagFilters, f, false)}
                        onCancel={() => this.setState({ showFieldFilterModal: false })}
                    />
                </ReactModal>
            </React.Fragment>
        );
    };

    render() {
        const { comparators, statuses, externalFilters = [], models, externalModels = [] } = this.props;
        const { advancedFilters, fieldFilters, selectedModels, fields, } = this.state;
        // const allFilters = externalFilters.concat(advancedFilters);
        let allFilters = advancedFilters;
        if (!advancedFilters || advancedFilters.length === 0) {
            allFilters = externalFilters;
        }
        if (!comparators || !statuses) return null;

        const dotTemplateIds = selectedModels
            .concat(externalModels.filter(m => !!m.model_field_list))
            .map(m => m.model_field_list)
            .flat()
            .filter(field => field.type_name === 'Cargo')
            .map(field => field.cargoCategory)

        // 'dotTemplatesFromSelectedDashes' is used to hold the dot templates based on selected dashes when user navigates away and then returns to Dashes page.
        const { dotTemplatesFromSelectedDashes } = this.state;
        const dotTemplates = models.length > 0 
            ?
            models.filter(m => dotTemplateIds.includes(m.id))
            :
            (dotTemplatesFromSelectedDashes || []).filter(m => dotTemplateIds.includes(m.id))
        
        const modelFields = selectedModels
            .concat(externalModels.filter(m => !!m.model_field_list))
            .concat(dotTemplates)
            .map(m => m.model_field_list)
            .flat();

        return (
            <div className="row">
                <div className="col-12 mg-b-15">{this.renderForm()}</div>
                <FilterPreview filters={allFilters} fieldFilters={fieldFilters} comparators={comparators} fields={fields} modelFields={modelFields} onRemove={this.removeAdvancedFilter} onRemoveFieldFilter={this.removeFieldFilter} />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    comparators: state.fieldVisibility.comparators,
    statuses: state.transactions.statuses,
    indexValues: state.fieldVisibility.indexValues,
    fieldValues: state.fieldVisibility.fieldValues,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getComparators,
            getStatuses,
            getIndexValues,
            getFieldValues,
            loadModel,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(QueryBuilder);
