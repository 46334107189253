import React from 'react';
import { FieldTypes, default as FieldDefinition } from 'models/field';
import {
    DateField,
    MultiTextField,
    NumberField,
    SectionField,
    SelectField,
    TextField,
    AttachmentField,
    YesNoNullField,
} from 'pages/models/fields/definitions';

import 'pages/models/models.scss';
import { getCurrencyOptions } from 'utilities/constants';
import CargoQuantityField from 'pages/events/components/cargoQuantityField';
import CargoUnitField from 'pages/events/components/cargoUnitForm';
import { tooltipWrapper } from 'utilities/common'
import { FormattedMessage } from 'react-intl';


class PickerFieldPreview extends React.Component {
    elSelector = (field) => {
        const { id } = field;
        return (
            <input
                key={id + '-picker'}
                type="radio"
                name="field-reference-id"
                value={id}
                checked={this.props.fieldReferenceId === id}
                className="field-reference-picker"
                onChange={() => this.props.setFieldReferenceId(id)}
            />
        )
    }

    elSelectorMultitext = (field) => {
        const { id } = field;
        return (
            <input
                key={id + '-picker'}
                type="radio"
                name="field-reference-id"
                value={id}
                checked={this.props.fieldReferenceId === id}
                className="field-reference-picker-multitext"
                onChange={() => this.props.setFieldReferenceId(id)}
            />
        )
    }

    eventLotFieldSelectors = (field) => {
        const { id } = field;
        return (
            <div className='row'>
                <div className={`pd-b-15 col-4 offset-2`}>
                    { this.elSelector({ id: id + '.l1' }) }
                    <CargoQuantityField
                        disabled={true}
                        initialTargetQuantity={0}
                    />
                </div>
                <CargoUnitField 
                    prependEls={[this.elSelector({ id: id + '.l2' })]}
                    disabled={true}
                    initialTargetCargoUnit={null}
                    width={4}
                />
            </div>
        )
    }

    getPreviewComponent = (field, formProps, fields, comparators, parentId) => {
        const typeName = field.type ? field.type : field.type_name;
        
        switch (typeName) {
            case FieldTypes.FIELD_SECTION:
                return (
                    <SectionField
                        key={field.id}
                        {...field}
                        renderChild={(id) => this.getPreviewComponent(fields.find(f => f.id === id), formProps, fields, comparators, parentId)}
                    />
                );
            case FieldTypes.FIELD_TEXT:
                return <TextField key={field.id} {...field} disabled={true} prependEls={[ this.elSelector(field) ]} />;
            case FieldTypes.FIELD_NUMBER:
                return <NumberField key={field.id} {...field} disabled={true} isPreview={true} prependEls={[ this.elSelector(field) ]} />;
            case FieldTypes.FIELD_DATE:
                return <TextField key={field.id} {...field} disabled={true} prependEls={[ this.elSelector(field) ]} />;
            case FieldTypes.FIELD_YES_NO:
                return <YesNoNullField key={field.id} {...field} disabled={true} prependEls={[ this.elSelector(field) ]} />;
            case FieldTypes.FIELD_SELECT:
                return (
                    <SelectField
                        key={field.id}
                        {...field}
                        isClearable={true}
                        prependEls={[ this.elSelector(field) ]}
                        disabled={true}
                    />
                );
            case FieldTypes.FIELD_MULTI_SELECT:
                return (
                    <SelectField
                        key={field.id}
                        {...field}
                        multi={true}
                        isClearable={true}
                        prependEls={[ this.elSelector(field) ]}
                        disabled={true}
                    />
                );
            case FieldTypes.FIELD_CURRENCY:
                return (
                    <SelectField
                        key={field.id}
                        {...field}
                        fieldValues={getCurrencyOptions()}
                        isClearable={true}
                        prependEls={[ this.elSelector(field) ]}
                        disabled={true}
                    />
                );
            case FieldTypes.FIELD_MULTI_TEXT:
                return <MultiTextField key={field.id} multi={true} {...field} disabled={true} prependEls={[ this.elSelectorMultitext(field) ]} marginLeft='-5px' />;

            case FieldTypes.FIELD_ATTACHMENT:
                return <AttachmentField key={field.id} {...field} disabled={true} prependEls={[ this.elSelector(field) ]}/>;

            case FieldTypes.FIELD_CARGO:
                return (
                    <SectionField
                        key={field.id}
                        {...field}
                        isCargo={true}
                        renderChild={id => this.getPreviewComponent(fields.find(f => f.id === id), formProps, fields, comparators, parentId)}
                        disabled={true}
                        extra={this.eventLotFieldSelectors(field)}
                    />
                );

            case FieldTypes.FIELD_NONE:
            default:
                return null;
        }
    };

    render() {
        const { field, formProps, fields, comparators, parentId = null } = this.props;
        return this.getPreviewComponent(field, formProps, fields, comparators, parentId);
    }
}

export default PickerFieldPreview;
