import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import Select from 'react-select';

import '../fields.scss';
import Tooltip from 'rc-tooltip';

class SelectControl extends React.Component {
    render() {
        const {
            intl: { formatMessage },
            multi,
            options,
            name,
            value,
            placeholder,
            disabled = false,
            onChange = () => null,
            isClearable = false,
            showIsRequired,
            width,
            backgroundColor,
        } = this.props;
        const customStyles = {  
            control: base => ({
                ...base,
                boxShadow: 'none',
                background: backgroundColor? backgroundColor:`` 
            }),
            multiValueLabel: (provided, state) => ({
                ...provided,
                maxWidth: '10em'
            }),
            menu: (provided, state) => ({
                ...provided,
                zIndex: '20'
            }),
            menuList: (provided, state) => ({
                ...provided,
                maxHeight: '200px'
            }),
        };
        const showLabel = !value && (this.props.showLabel !== false);
        return (
            <React.Fragment>
                <div className={`pd-b-15 col-${width ? width : 12}`} style={{ display: 'inline-block' }}>
                    <div className="field-select ">
                        <Select
                            className="select-input-field"
                            placeholder={formatMessage({ id: `${name}.placeholder` })}
                            name={name}
                            options={options}
                            isDisabled={disabled}
                            onChange={onChange}
                            value={value}
                            isMulti={multi}
                            isClearable={isClearable}
                            components={{ IndicatorSeparator: () => null }}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary25: '#eeeeee',
                                    primary: '#00336B'
                                }
                            })}
                            styles={customStyles}
                            menuPlacement={this.props.menuPlacement || 'auto'}
                        />
                        {!!showLabel && 
                            <Tooltip trigger={['hover']} overlay={<span>{placeholder || formatMessage({ id: `${name}.placeholder` })}</span>} mouseEnterDelay={2}>
                                <div className="select-input-placeholder">{placeholder || formatMessage({ id: `${name}.placeholder` })}</div>
                            </Tooltip>
                        }
                        {showIsRequired && !value && <div className="validation-message required-placeholder"><FormattedMessage id="validation.required" /></div>}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default injectIntl(SelectControl);
