

const isoCurrencies = [
    {
      Entity: "AFGHANISTAN",
      Currency: "Afghani",
      AlphabeticCode: "AFN",
      NumericCode: 971,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "ÅLAND ISLANDS",
      Currency: "Euro",
      AlphabeticCode: "EUR",
      NumericCode: 978,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "ALBANIA",
      Currency: "Lek",
      AlphabeticCode: "ALL",
      NumericCode: "008",
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "ALGERIA",
      Currency: "Algerian Dinar",
      AlphabeticCode: "DZD",
      NumericCode: "012",
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "AMERICAN SAMOA",
      Currency: "US Dollar",
      AlphabeticCode: "USD",
      NumericCode: 840,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "ANDORRA",
      Currency: "Euro",
      AlphabeticCode: "EUR",
      NumericCode: 978,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "ANGOLA",
      Currency: "Kwanza",
      AlphabeticCode: "AOA",
      NumericCode: 973,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "ANGUILLA",
      Currency: "East Caribbean Dollar",
      AlphabeticCode: "XCD",
      NumericCode: 951,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "ANTARCTICA",
      Currency: "No universal currency",
      AlphabeticCode: "",
      NumericCode: "",
      MinorUnit: "",
      Fund: ""
    },
    {
      Entity: "ANTIGUA AND BARBUDA",
      Currency: "East Caribbean Dollar",
      AlphabeticCode: "XCD",
      NumericCode: 951,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "ARGENTINA",
      Currency: "Argentine Peso",
      AlphabeticCode: "ARS",
      NumericCode: "032",
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "ARMENIA",
      Currency: "Armenian Dram",
      AlphabeticCode: "AMD",
      NumericCode: "051",
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "ARUBA",
      Currency: "Aruban Florin",
      AlphabeticCode: "AWG",
      NumericCode: 533,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "AUSTRALIA",
      Currency: "Australian Dollar",
      AlphabeticCode: "AUD",
      NumericCode: "036",
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "AUSTRIA",
      Currency: "Euro",
      AlphabeticCode: "EUR",
      NumericCode: 978,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "AZERBAIJAN",
      Currency: "Azerbaijan Manat",
      AlphabeticCode: "AZN",
      NumericCode: 944,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "BAHAMAS (THE)",
      Currency: "Bahamian Dollar",
      AlphabeticCode: "BSD",
      NumericCode: "044",
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "BAHRAIN",
      Currency: "Bahraini Dinar",
      AlphabeticCode: "BHD",
      NumericCode: "048",
      MinorUnit: 3,
      Fund: ""
    },
    {
      Entity: "BANGLADESH",
      Currency: "Taka",
      AlphabeticCode: "BDT",
      NumericCode: "050",
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "BARBADOS",
      Currency: "Barbados Dollar",
      AlphabeticCode: "BBD",
      NumericCode: "052",
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "BELARUS",
      Currency: "Belarusian Ruble",
      AlphabeticCode: "BYN",
      NumericCode: 933,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "BELGIUM",
      Currency: "Euro",
      AlphabeticCode: "EUR",
      NumericCode: 978,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "BELIZE",
      Currency: "Belize Dollar",
      AlphabeticCode: "BZD",
      NumericCode: "084",
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "BENIN",
      Currency: "CFA Franc BCEAO",
      AlphabeticCode: "XOF",
      NumericCode: 952,
      MinorUnit: 0,
      Fund: ""
    },
    {
      Entity: "BERMUDA",
      Currency: "Bermudian Dollar",
      AlphabeticCode: "BMD",
      NumericCode: "060",
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "BHUTAN",
      Currency: "Indian Rupee",
      AlphabeticCode: "INR",
      NumericCode: 356,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "BHUTAN",
      Currency: "Ngultrum",
      AlphabeticCode: "BTN",
      NumericCode: "064",
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "BOLIVIA (PLURINATIONAL STATE OF)",
      Currency: "Boliviano",
      AlphabeticCode: "BOB",
      NumericCode: "068",
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "BOLIVIA (PLURINATIONAL STATE OF)",
      Currency: "Mvdol",
      AlphabeticCode: "BOV",
      NumericCode: 984,
      MinorUnit: 2,
      Fund: "TRUE"
    },
    {
      Entity: "BONAIRE, SINT EUSTATIUS AND SABA",
      Currency: "US Dollar",
      AlphabeticCode: "USD",
      NumericCode: 840,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "BOSNIA AND HERZEGOVINA",
      Currency: "Convertible Mark",
      AlphabeticCode: "BAM",
      NumericCode: 977,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "BOTSWANA",
      Currency: "Pula",
      AlphabeticCode: "BWP",
      NumericCode: "072",
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "BOUVET ISLAND",
      Currency: "Norwegian Krone",
      AlphabeticCode: "NOK",
      NumericCode: 578,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "BRAZIL",
      Currency: "Brazilian Real",
      AlphabeticCode: "BRL",
      NumericCode: 986,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "BRITISH INDIAN OCEAN TERRITORY (THE)",
      Currency: "US Dollar",
      AlphabeticCode: "USD",
      NumericCode: 840,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "BRUNEI DARUSSALAM",
      Currency: "Brunei Dollar",
      AlphabeticCode: "BND",
      NumericCode: "096",
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "BULGARIA",
      Currency: "Bulgarian Lev",
      AlphabeticCode: "BGN",
      NumericCode: 975,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "BURKINA FASO",
      Currency: "CFA Franc BCEAO",
      AlphabeticCode: "XOF",
      NumericCode: 952,
      MinorUnit: 0,
      Fund: ""
    },
    {
      Entity: "BURUNDI",
      Currency: "Burundi Franc",
      AlphabeticCode: "BIF",
      NumericCode: 108,
      MinorUnit: 0,
      Fund: ""
    },
    {
      Entity: "CABO VERDE",
      Currency: "Cabo Verde Escudo",
      AlphabeticCode: "CVE",
      NumericCode: 132,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "CAMBODIA",
      Currency: "Riel",
      AlphabeticCode: "KHR",
      NumericCode: 116,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "CAMEROON",
      Currency: "CFA Franc BEAC",
      AlphabeticCode: "XAF",
      NumericCode: 950,
      MinorUnit: 0,
      Fund: ""
    },
    {
      Entity: "CANADA",
      Currency: "Canadian Dollar",
      AlphabeticCode: "CAD",
      NumericCode: 124,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "CAYMAN ISLANDS (THE)",
      Currency: "Cayman Islands Dollar",
      AlphabeticCode: "KYD",
      NumericCode: 136,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "CENTRAL AFRICAN REPUBLIC (THE)",
      Currency: "CFA Franc BEAC",
      AlphabeticCode: "XAF",
      NumericCode: 950,
      MinorUnit: 0,
      Fund: ""
    },
    {
      Entity: "CHAD",
      Currency: "CFA Franc BEAC",
      AlphabeticCode: "XAF",
      NumericCode: 950,
      MinorUnit: 0,
      Fund: ""
    },
    {
      Entity: "CHILE",
      Currency: "Chilean Peso",
      AlphabeticCode: "CLP",
      NumericCode: 152,
      MinorUnit: 0,
      Fund: ""
    },
    {
      Entity: "CHILE",
      Currency: "Unidad de Fomento",
      AlphabeticCode: "CLF",
      NumericCode: 990,
      MinorUnit: 4,
      Fund: "TRUE"
    },
    {
      Entity: "CHINA",
      Currency: "Yuan Renminbi",
      AlphabeticCode: "CNY",
      NumericCode: 156,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "CHRISTMAS ISLAND",
      Currency: "Australian Dollar",
      AlphabeticCode: "AUD",
      NumericCode: "036",
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "COCOS (KEELING) ISLANDS (THE)",
      Currency: "Australian Dollar",
      AlphabeticCode: "AUD",
      NumericCode: "036",
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "COLOMBIA",
      Currency: "Colombian Peso",
      AlphabeticCode: "COP",
      NumericCode: 170,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "COLOMBIA",
      Currency: "Unidad de Valor Real",
      AlphabeticCode: "COU",
      NumericCode: 970,
      MinorUnit: 2,
      Fund: "TRUE"
    },
    {
      Entity: "COMOROS (THE)",
      Currency: "Comorian Franc",
      AlphabeticCode: "KMF",
      NumericCode: 174,
      MinorUnit: 0,
      Fund: ""
    },
    {
      Entity: "CONGO (THE DEMOCRATIC REPUBLIC OF THE)",
      Currency: "Congolese Franc",
      AlphabeticCode: "CDF",
      NumericCode: 976,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "CONGO (THE)",
      Currency: "CFA Franc BEAC",
      AlphabeticCode: "XAF",
      NumericCode: 950,
      MinorUnit: 0,
      Fund: ""
    },
    {
      Entity: "COOK ISLANDS (THE)",
      Currency: "New Zealand Dollar",
      AlphabeticCode: "NZD",
      NumericCode: 554,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "COSTA RICA",
      Currency: "Costa Rican Colon",
      AlphabeticCode: "CRC",
      NumericCode: 188,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "CÔTE D'IVOIRE",
      Currency: "CFA Franc BCEAO",
      AlphabeticCode: "XOF",
      NumericCode: 952,
      MinorUnit: 0,
      Fund: ""
    },
    {
      Entity: "CROATIA",
      Currency: "Kuna",
      AlphabeticCode: "HRK",
      NumericCode: 191,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "CUBA",
      Currency: "Cuban Peso",
      AlphabeticCode: "CUP",
      NumericCode: 192,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "CUBA",
      Currency: "Peso Convertible",
      AlphabeticCode: "CUC",
      NumericCode: 931,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "CURAÇAO",
      Currency: "Netherlands Antillean Guilder",
      AlphabeticCode: "ANG",
      NumericCode: 532,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "CYPRUS",
      Currency: "Euro",
      AlphabeticCode: "EUR",
      NumericCode: 978,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "CZECHIA",
      Currency: "Czech Koruna",
      AlphabeticCode: "CZK",
      NumericCode: 203,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "DENMARK",
      Currency: "Danish Krone",
      AlphabeticCode: "DKK",
      NumericCode: 208,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "DJIBOUTI",
      Currency: "Djibouti Franc",
      AlphabeticCode: "DJF",
      NumericCode: 262,
      MinorUnit: 0,
      Fund: ""
    },
    {
      Entity: "DOMINICA",
      Currency: "East Caribbean Dollar",
      AlphabeticCode: "XCD",
      NumericCode: 951,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "DOMINICAN REPUBLIC (THE)",
      Currency: "Dominican Peso",
      AlphabeticCode: "DOP",
      NumericCode: 214,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "ECUADOR",
      Currency: "US Dollar",
      AlphabeticCode: "USD",
      NumericCode: 840,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "EGYPT",
      Currency: "Egyptian Pound",
      AlphabeticCode: "EGP",
      NumericCode: 818,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "EL SALVADOR",
      Currency: "El Salvador Colon",
      AlphabeticCode: "SVC",
      NumericCode: 222,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "EL SALVADOR",
      Currency: "US Dollar",
      AlphabeticCode: "USD",
      NumericCode: 840,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "EQUATORIAL GUINEA",
      Currency: "CFA Franc BEAC",
      AlphabeticCode: "XAF",
      NumericCode: 950,
      MinorUnit: 0,
      Fund: ""
    },
    {
      Entity: "ERITREA",
      Currency: "Nakfa",
      AlphabeticCode: "ERN",
      NumericCode: 232,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "ESTONIA",
      Currency: "Euro",
      AlphabeticCode: "EUR",
      NumericCode: 978,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "ETHIOPIA",
      Currency: "Ethiopian Birr",
      AlphabeticCode: "ETB",
      NumericCode: 230,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "EUROPEAN UNION",
      Currency: "Euro",
      AlphabeticCode: "EUR",
      NumericCode: 978,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "FALKLAND ISLANDS (THE) [MALVINAS]",
      Currency: "Falkland Islands Pound",
      AlphabeticCode: "FKP",
      NumericCode: 238,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "FAROE ISLANDS (THE)",
      Currency: "Danish Krone",
      AlphabeticCode: "DKK",
      NumericCode: 208,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "FIJI",
      Currency: "Fiji Dollar",
      AlphabeticCode: "FJD",
      NumericCode: 242,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "FINLAND",
      Currency: "Euro",
      AlphabeticCode: "EUR",
      NumericCode: 978,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "FRANCE",
      Currency: "Euro",
      AlphabeticCode: "EUR",
      NumericCode: 978,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "FRENCH GUIANA",
      Currency: "Euro",
      AlphabeticCode: "EUR",
      NumericCode: 978,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "FRENCH POLYNESIA",
      Currency: "CFP Franc",
      AlphabeticCode: "XPF",
      NumericCode: 953,
      MinorUnit: 0,
      Fund: ""
    },
    {
      Entity: "FRENCH SOUTHERN TERRITORIES (THE)",
      Currency: "Euro",
      AlphabeticCode: "EUR",
      NumericCode: 978,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "GABON",
      Currency: "CFA Franc BEAC",
      AlphabeticCode: "XAF",
      NumericCode: 950,
      MinorUnit: 0,
      Fund: ""
    },
    {
      Entity: "GAMBIA (THE)",
      Currency: "Dalasi",
      AlphabeticCode: "GMD",
      NumericCode: 270,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "GEORGIA",
      Currency: "Lari",
      AlphabeticCode: "GEL",
      NumericCode: 981,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "GERMANY",
      Currency: "Euro",
      AlphabeticCode: "EUR",
      NumericCode: 978,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "GHANA",
      Currency: "Ghana Cedi",
      AlphabeticCode: "GHS",
      NumericCode: 936,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "GIBRALTAR",
      Currency: "Gibraltar Pound",
      AlphabeticCode: "GIP",
      NumericCode: 292,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "GREECE",
      Currency: "Euro",
      AlphabeticCode: "EUR",
      NumericCode: 978,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "GREENLAND",
      Currency: "Danish Krone",
      AlphabeticCode: "DKK",
      NumericCode: 208,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "GRENADA",
      Currency: "East Caribbean Dollar",
      AlphabeticCode: "XCD",
      NumericCode: 951,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "GUADELOUPE",
      Currency: "Euro",
      AlphabeticCode: "EUR",
      NumericCode: 978,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "GUAM",
      Currency: "US Dollar",
      AlphabeticCode: "USD",
      NumericCode: 840,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "GUATEMALA",
      Currency: "Quetzal",
      AlphabeticCode: "GTQ",
      NumericCode: 320,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "GUERNSEY",
      Currency: "Pound Sterling",
      AlphabeticCode: "GBP",
      NumericCode: 826,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "GUINEA",
      Currency: "Guinean Franc",
      AlphabeticCode: "GNF",
      NumericCode: 324,
      MinorUnit: 0,
      Fund: ""
    },
    {
      Entity: "GUINEA-BISSAU",
      Currency: "CFA Franc BCEAO",
      AlphabeticCode: "XOF",
      NumericCode: 952,
      MinorUnit: 0,
      Fund: ""
    },
    {
      Entity: "GUYANA",
      Currency: "Guyana Dollar",
      AlphabeticCode: "GYD",
      NumericCode: 328,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "HAITI",
      Currency: "Gourde",
      AlphabeticCode: "HTG",
      NumericCode: 332,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "HAITI",
      Currency: "US Dollar",
      AlphabeticCode: "USD",
      NumericCode: 840,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "HEARD ISLAND AND McDONALD ISLANDS",
      Currency: "Australian Dollar",
      AlphabeticCode: "AUD",
      NumericCode: "036",
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "HOLY SEE (THE)",
      Currency: "Euro",
      AlphabeticCode: "EUR",
      NumericCode: 978,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "HONDURAS",
      Currency: "Lempira",
      AlphabeticCode: "HNL",
      NumericCode: 340,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "HONG KONG",
      Currency: "Hong Kong Dollar",
      AlphabeticCode: "HKD",
      NumericCode: 344,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "HUNGARY",
      Currency: "Forint",
      AlphabeticCode: "HUF",
      NumericCode: 348,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "ICELAND",
      Currency: "Iceland Krona",
      AlphabeticCode: "ISK",
      NumericCode: 352,
      MinorUnit: 0,
      Fund: ""
    },
    {
      Entity: "INDIA",
      Currency: "Indian Rupee",
      AlphabeticCode: "INR",
      NumericCode: 356,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "INDONESIA",
      Currency: "Rupiah",
      AlphabeticCode: "IDR",
      NumericCode: 360,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "INTERNATIONAL MONETARY FUND (IMF)",
      Currency: "SDR (Special Drawing Right)",
      AlphabeticCode: "XDR",
      NumericCode: 960,
      MinorUnit: "N.A.",
      Fund: ""
    },
    {
      Entity: "IRAN (ISLAMIC REPUBLIC OF)",
      Currency: "Iranian Rial",
      AlphabeticCode: "IRR",
      NumericCode: 364,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "IRAQ",
      Currency: "Iraqi Dinar",
      AlphabeticCode: "IQD",
      NumericCode: 368,
      MinorUnit: 3,
      Fund: ""
    },
    {
      Entity: "IRELAND",
      Currency: "Euro",
      AlphabeticCode: "EUR",
      NumericCode: 978,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "ISLE OF MAN",
      Currency: "Pound Sterling",
      AlphabeticCode: "GBP",
      NumericCode: 826,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "ISRAEL",
      Currency: "New Israeli Sheqel",
      AlphabeticCode: "ILS",
      NumericCode: 376,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "ITALY",
      Currency: "Euro",
      AlphabeticCode: "EUR",
      NumericCode: 978,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "JAMAICA",
      Currency: "Jamaican Dollar",
      AlphabeticCode: "JMD",
      NumericCode: 388,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "JAPAN",
      Currency: "Yen",
      AlphabeticCode: "JPY",
      NumericCode: 392,
      MinorUnit: 0,
      Fund: ""
    },
    {
      Entity: "JERSEY",
      Currency: "Pound Sterling",
      AlphabeticCode: "GBP",
      NumericCode: 826,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "JORDAN",
      Currency: "Jordanian Dinar",
      AlphabeticCode: "JOD",
      NumericCode: 400,
      MinorUnit: 3,
      Fund: ""
    },
    {
      Entity: "KAZAKHSTAN",
      Currency: "Tenge",
      AlphabeticCode: "KZT",
      NumericCode: 398,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "KENYA",
      Currency: "Kenyan Shilling",
      AlphabeticCode: "KES",
      NumericCode: 404,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "KIRIBATI",
      Currency: "Australian Dollar",
      AlphabeticCode: "AUD",
      NumericCode: "036",
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "KOREA (THE DEMOCRATIC PEOPLE’S REPUBLIC OF)",
      Currency: "North Korean Won",
      AlphabeticCode: "KPW",
      NumericCode: 408,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "KOREA (THE REPUBLIC OF)",
      Currency: "Won",
      AlphabeticCode: "KRW",
      NumericCode: 410,
      MinorUnit: 0,
      Fund: ""
    },
    {
      Entity: "KUWAIT",
      Currency: "Kuwaiti Dinar",
      AlphabeticCode: "KWD",
      NumericCode: 414,
      MinorUnit: 3,
      Fund: ""
    },
    {
      Entity: "KYRGYZSTAN",
      Currency: "Som",
      AlphabeticCode: "KGS",
      NumericCode: 417,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "LAO PEOPLE’S DEMOCRATIC REPUBLIC (THE)",
      Currency: "Lao Kip",
      AlphabeticCode: "LAK",
      NumericCode: 418,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "LATVIA",
      Currency: "Euro",
      AlphabeticCode: "EUR",
      NumericCode: 978,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "LEBANON",
      Currency: "Lebanese Pound",
      AlphabeticCode: "LBP",
      NumericCode: 422,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "LESOTHO",
      Currency: "Loti",
      AlphabeticCode: "LSL",
      NumericCode: 426,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "LESOTHO",
      Currency: "Rand",
      AlphabeticCode: "ZAR",
      NumericCode: 710,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "LIBERIA",
      Currency: "Liberian Dollar",
      AlphabeticCode: "LRD",
      NumericCode: 430,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "LIBYA",
      Currency: "Libyan Dinar",
      AlphabeticCode: "LYD",
      NumericCode: 434,
      MinorUnit: 3,
      Fund: ""
    },
    {
      Entity: "LIECHTENSTEIN",
      Currency: "Swiss Franc",
      AlphabeticCode: "CHF",
      NumericCode: 756,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "LITHUANIA",
      Currency: "Euro",
      AlphabeticCode: "EUR",
      NumericCode: 978,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "LUXEMBOURG",
      Currency: "Euro",
      AlphabeticCode: "EUR",
      NumericCode: 978,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "MACAO",
      Currency: "Pataca",
      AlphabeticCode: "MOP",
      NumericCode: 446,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "MACEDONIA (THE FORMER YUGOSLAV REPUBLIC OF)",
      Currency: "Denar",
      AlphabeticCode: "MKD",
      NumericCode: 807,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "MADAGASCAR",
      Currency: "Malagasy Ariary",
      AlphabeticCode: "MGA",
      NumericCode: 969,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "MALAWI",
      Currency: "Malawi Kwacha",
      AlphabeticCode: "MWK",
      NumericCode: 454,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "MALAYSIA",
      Currency: "Malaysian Ringgit",
      AlphabeticCode: "MYR",
      NumericCode: 458,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "MALDIVES",
      Currency: "Rufiyaa",
      AlphabeticCode: "MVR",
      NumericCode: 462,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "MALI",
      Currency: "CFA Franc BCEAO",
      AlphabeticCode: "XOF",
      NumericCode: 952,
      MinorUnit: 0,
      Fund: ""
    },
    {
      Entity: "MALTA",
      Currency: "Euro",
      AlphabeticCode: "EUR",
      NumericCode: 978,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "MARSHALL ISLANDS (THE)",
      Currency: "US Dollar",
      AlphabeticCode: "USD",
      NumericCode: 840,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "MARTINIQUE",
      Currency: "Euro",
      AlphabeticCode: "EUR",
      NumericCode: 978,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "MAURITANIA",
      Currency: "Ouguiya",
      AlphabeticCode: "MRU",
      NumericCode: 929,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "MAURITIUS",
      Currency: "Mauritius Rupee",
      AlphabeticCode: "MUR",
      NumericCode: 480,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "MAYOTTE",
      Currency: "Euro",
      AlphabeticCode: "EUR",
      NumericCode: 978,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "MEMBER COUNTRIES OF THE AFRICAN DEVELOPMENT BANK GROUP",
      Currency: "ADB Unit of Account",
      AlphabeticCode: "XUA",
      NumericCode: 965,
      MinorUnit: "N.A.",
      Fund: ""
    },
    {
      Entity: "MEXICO",
      Currency: "Mexican Peso",
      AlphabeticCode: "MXN",
      NumericCode: 484,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "MEXICO",
      Currency: "Mexican Unidad de Inversion (UDI)",
      AlphabeticCode: "MXV",
      NumericCode: 979,
      MinorUnit: 2,
      Fund: "TRUE"
    },
    {
      Entity: "MICRONESIA (FEDERATED STATES OF)",
      Currency: "US Dollar",
      AlphabeticCode: "USD",
      NumericCode: 840,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "MOLDOVA (THE REPUBLIC OF)",
      Currency: "Moldovan Leu",
      AlphabeticCode: "MDL",
      NumericCode: 498,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "MONACO",
      Currency: "Euro",
      AlphabeticCode: "EUR",
      NumericCode: 978,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "MONGOLIA",
      Currency: "Tugrik",
      AlphabeticCode: "MNT",
      NumericCode: 496,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "MONTENEGRO",
      Currency: "Euro",
      AlphabeticCode: "EUR",
      NumericCode: 978,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "MONTSERRAT",
      Currency: "East Caribbean Dollar",
      AlphabeticCode: "XCD",
      NumericCode: 951,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "MOROCCO",
      Currency: "Moroccan Dirham",
      AlphabeticCode: "MAD",
      NumericCode: 504,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "MOZAMBIQUE",
      Currency: "Mozambique Metical",
      AlphabeticCode: "MZN",
      NumericCode: 943,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "MYANMAR",
      Currency: "Kyat",
      AlphabeticCode: "MMK",
      NumericCode: 104,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "NAMIBIA",
      Currency: "Namibia Dollar",
      AlphabeticCode: "NAD",
      NumericCode: 516,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "NAMIBIA",
      Currency: "Rand",
      AlphabeticCode: "ZAR",
      NumericCode: 710,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "NAURU",
      Currency: "Australian Dollar",
      AlphabeticCode: "AUD",
      NumericCode: "036",
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "NEPAL",
      Currency: "Nepalese Rupee",
      AlphabeticCode: "NPR",
      NumericCode: 524,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "NETHERLANDS (THE)",
      Currency: "Euro",
      AlphabeticCode: "EUR",
      NumericCode: 978,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "NEW CALEDONIA",
      Currency: "CFP Franc",
      AlphabeticCode: "XPF",
      NumericCode: 953,
      MinorUnit: 0,
      Fund: ""
    },
    {
      Entity: "NEW ZEALAND",
      Currency: "New Zealand Dollar",
      AlphabeticCode: "NZD",
      NumericCode: 554,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "NICARAGUA",
      Currency: "Cordoba Oro",
      AlphabeticCode: "NIO",
      NumericCode: 558,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "NIGER (THE)",
      Currency: "CFA Franc BCEAO",
      AlphabeticCode: "XOF",
      NumericCode: 952,
      MinorUnit: 0,
      Fund: ""
    },
    {
      Entity: "NIGERIA",
      Currency: "Naira",
      AlphabeticCode: "NGN",
      NumericCode: 566,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "NIUE",
      Currency: "New Zealand Dollar",
      AlphabeticCode: "NZD",
      NumericCode: 554,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "NORFOLK ISLAND",
      Currency: "Australian Dollar",
      AlphabeticCode: "AUD",
      NumericCode: "036",
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "NORTHERN MARIANA ISLANDS (THE)",
      Currency: "US Dollar",
      AlphabeticCode: "USD",
      NumericCode: 840,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "NORWAY",
      Currency: "Norwegian Krone",
      AlphabeticCode: "NOK",
      NumericCode: 578,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "OMAN",
      Currency: "Rial Omani",
      AlphabeticCode: "OMR",
      NumericCode: 512,
      MinorUnit: 3,
      Fund: ""
    },
    {
      Entity: "PAKISTAN",
      Currency: "Pakistan Rupee",
      AlphabeticCode: "PKR",
      NumericCode: 586,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "PALAU",
      Currency: "US Dollar",
      AlphabeticCode: "USD",
      NumericCode: 840,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "PALESTINE, STATE OF",
      Currency: "No universal currency",
      AlphabeticCode: "",
      NumericCode: "",
      MinorUnit: "",
      Fund: ""
    },
    {
      Entity: "PANAMA",
      Currency: "Balboa",
      AlphabeticCode: "PAB",
      NumericCode: 590,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "PANAMA",
      Currency: "US Dollar",
      AlphabeticCode: "USD",
      NumericCode: 840,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "PAPUA NEW GUINEA",
      Currency: "Kina",
      AlphabeticCode: "PGK",
      NumericCode: 598,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "PARAGUAY",
      Currency: "Guarani",
      AlphabeticCode: "PYG",
      NumericCode: 600,
      MinorUnit: 0,
      Fund: ""
    },
    {
      Entity: "PERU",
      Currency: "Sol",
      AlphabeticCode: "PEN",
      NumericCode: 604,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "PHILIPPINES (THE)",
      Currency: "Philippine Peso",
      AlphabeticCode: "PHP",
      NumericCode: 608,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "PITCAIRN",
      Currency: "New Zealand Dollar",
      AlphabeticCode: "NZD",
      NumericCode: 554,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "POLAND",
      Currency: "Zloty",
      AlphabeticCode: "PLN",
      NumericCode: 985,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "PORTUGAL",
      Currency: "Euro",
      AlphabeticCode: "EUR",
      NumericCode: 978,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "PUERTO RICO",
      Currency: "US Dollar",
      AlphabeticCode: "USD",
      NumericCode: 840,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "QATAR",
      Currency: "Qatari Rial",
      AlphabeticCode: "QAR",
      NumericCode: 634,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "RÉUNION",
      Currency: "Euro",
      AlphabeticCode: "EUR",
      NumericCode: 978,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "ROMANIA",
      Currency: "Romanian Leu",
      AlphabeticCode: "RON",
      NumericCode: 946,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "RUSSIAN FEDERATION (THE)",
      Currency: "Russian Ruble",
      AlphabeticCode: "RUB",
      NumericCode: 643,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "RWANDA",
      Currency: "Rwanda Franc",
      AlphabeticCode: "RWF",
      NumericCode: 646,
      MinorUnit: 0,
      Fund: ""
    },
    {
      Entity: "SAINT BARTHÉLEMY",
      Currency: "Euro",
      AlphabeticCode: "EUR",
      NumericCode: 978,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA",
      Currency: "Saint Helena Pound",
      AlphabeticCode: "SHP",
      NumericCode: 654,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "SAINT KITTS AND NEVIS",
      Currency: "East Caribbean Dollar",
      AlphabeticCode: "XCD",
      NumericCode: 951,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "SAINT LUCIA",
      Currency: "East Caribbean Dollar",
      AlphabeticCode: "XCD",
      NumericCode: 951,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "SAINT MARTIN (FRENCH PART)",
      Currency: "Euro",
      AlphabeticCode: "EUR",
      NumericCode: 978,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "SAINT PIERRE AND MIQUELON",
      Currency: "Euro",
      AlphabeticCode: "EUR",
      NumericCode: 978,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "SAINT VINCENT AND THE GRENADINES",
      Currency: "East Caribbean Dollar",
      AlphabeticCode: "XCD",
      NumericCode: 951,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "SAMOA",
      Currency: "Tala",
      AlphabeticCode: "WST",
      NumericCode: 882,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "SAN MARINO",
      Currency: "Euro",
      AlphabeticCode: "EUR",
      NumericCode: 978,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "SAO TOME AND PRINCIPE",
      Currency: "Dobra",
      AlphabeticCode: "STN",
      NumericCode: 930,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "SAUDI ARABIA",
      Currency: "Saudi Riyal",
      AlphabeticCode: "SAR",
      NumericCode: 682,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "SENEGAL",
      Currency: "CFA Franc BCEAO",
      AlphabeticCode: "XOF",
      NumericCode: 952,
      MinorUnit: 0,
      Fund: ""
    },
    {
      Entity: "SERBIA",
      Currency: "Serbian Dinar",
      AlphabeticCode: "RSD",
      NumericCode: 941,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "SEYCHELLES",
      Currency: "Seychelles Rupee",
      AlphabeticCode: "SCR",
      NumericCode: 690,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "SIERRA LEONE",
      Currency: "Leone",
      AlphabeticCode: "SLL",
      NumericCode: 694,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "SINGAPORE",
      Currency: "Singapore Dollar",
      AlphabeticCode: "SGD",
      NumericCode: 702,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "SINT MAARTEN (DUTCH PART)",
      Currency: "Netherlands Antillean Guilder",
      AlphabeticCode: "ANG",
      NumericCode: 532,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "SISTEMA UNITARIO DE COMPENSACION REGIONAL DE PAGOS \"SUCRE",
      Currency: "Sucre",
      AlphabeticCode: "XSU",
      NumericCode: 994,
      MinorUnit: "N.A.",
      Fund: ""
    },
    {
      Entity: "SLOVAKIA",
      Currency: "Euro",
      AlphabeticCode: "EUR",
      NumericCode: 978,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "SLOVENIA",
      Currency: "Euro",
      AlphabeticCode: "EUR",
      NumericCode: 978,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "SOLOMON ISLANDS",
      Currency: "Solomon Islands Dollar",
      AlphabeticCode: "SBD",
      NumericCode: "090",
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "SOMALIA",
      Currency: "Somali Shilling",
      AlphabeticCode: "SOS",
      NumericCode: 706,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "SOUTH AFRICA",
      Currency: "Rand",
      AlphabeticCode: "ZAR",
      NumericCode: 710,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS",
      Currency: "No universal currency",
      AlphabeticCode: "",
      NumericCode: "",
      MinorUnit: "",
      Fund: ""
    },
    {
      Entity: "SOUTH SUDAN",
      Currency: "South Sudanese Pound",
      AlphabeticCode: "SSP",
      NumericCode: 728,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "SPAIN",
      Currency: "Euro",
      AlphabeticCode: "EUR",
      NumericCode: 978,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "SRI LANKA",
      Currency: "Sri Lanka Rupee",
      AlphabeticCode: "LKR",
      NumericCode: 144,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "SUDAN (THE)",
      Currency: "Sudanese Pound",
      AlphabeticCode: "SDG",
      NumericCode: 938,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "SURINAME",
      Currency: "Surinam Dollar",
      AlphabeticCode: "SRD",
      NumericCode: 968,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "SVALBARD AND JAN MAYEN",
      Currency: "Norwegian Krone",
      AlphabeticCode: "NOK",
      NumericCode: 578,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "ESWATINI",
      Currency: "Lilangeni",
      AlphabeticCode: "SZL",
      NumericCode: 748,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "SWEDEN",
      Currency: "Swedish Krona",
      AlphabeticCode: "SEK",
      NumericCode: 752,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "SWITZERLAND",
      Currency: "Swiss Franc",
      AlphabeticCode: "CHF",
      NumericCode: 756,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "SWITZERLAND",
      Currency: "WIR Euro",
      AlphabeticCode: "CHE",
      NumericCode: 947,
      MinorUnit: 2,
      Fund: "TRUE"
    },
    {
      Entity: "SWITZERLAND",
      Currency: "WIR Franc",
      AlphabeticCode: "CHW",
      NumericCode: 948,
      MinorUnit: 2,
      Fund: "TRUE"
    },
    {
      Entity: "SYRIAN ARAB REPUBLIC",
      Currency: "Syrian Pound",
      AlphabeticCode: "SYP",
      NumericCode: 760,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "TAIWAN (PROVINCE OF CHINA)",
      Currency: "New Taiwan Dollar",
      AlphabeticCode: "TWD",
      NumericCode: 901,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "TAJIKISTAN",
      Currency: "Somoni",
      AlphabeticCode: "TJS",
      NumericCode: 972,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "TANZANIA, UNITED REPUBLIC OF",
      Currency: "Tanzanian Shilling",
      AlphabeticCode: "TZS",
      NumericCode: 834,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "THAILAND",
      Currency: "Baht",
      AlphabeticCode: "THB",
      NumericCode: 764,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "TIMOR-LESTE",
      Currency: "US Dollar",
      AlphabeticCode: "USD",
      NumericCode: 840,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "TOGO",
      Currency: "CFA Franc BCEAO",
      AlphabeticCode: "XOF",
      NumericCode: 952,
      MinorUnit: 0,
      Fund: ""
    },
    {
      Entity: "TOKELAU",
      Currency: "New Zealand Dollar",
      AlphabeticCode: "NZD",
      NumericCode: 554,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "TONGA",
      Currency: "Pa’anga",
      AlphabeticCode: "TOP",
      NumericCode: 776,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "TRINIDAD AND TOBAGO",
      Currency: "Trinidad and Tobago Dollar",
      AlphabeticCode: "TTD",
      NumericCode: 780,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "TUNISIA",
      Currency: "Tunisian Dinar",
      AlphabeticCode: "TND",
      NumericCode: 788,
      MinorUnit: 3,
      Fund: ""
    },
    {
      Entity: "TURKEY",
      Currency: "Turkish Lira",
      AlphabeticCode: "TRY",
      NumericCode: 949,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "TURKMENISTAN",
      Currency: "Turkmenistan New Manat",
      AlphabeticCode: "TMT",
      NumericCode: 934,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "TURKS AND CAICOS ISLANDS (THE)",
      Currency: "US Dollar",
      AlphabeticCode: "USD",
      NumericCode: 840,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "TUVALU",
      Currency: "Australian Dollar",
      AlphabeticCode: "AUD",
      NumericCode: "036",
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "UGANDA",
      Currency: "Uganda Shilling",
      AlphabeticCode: "UGX",
      NumericCode: 800,
      MinorUnit: 0,
      Fund: ""
    },
    {
      Entity: "UKRAINE",
      Currency: "Hryvnia",
      AlphabeticCode: "UAH",
      NumericCode: 980,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "UNITED ARAB EMIRATES (THE)",
      Currency: "UAE Dirham",
      AlphabeticCode: "AED",
      NumericCode: 784,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND (THE)",
      Currency: "Pound Sterling",
      AlphabeticCode: "GBP",
      NumericCode: 826,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "UNITED STATES MINOR OUTLYING ISLANDS (THE)",
      Currency: "US Dollar",
      AlphabeticCode: "USD",
      NumericCode: 840,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "UNITED STATES OF AMERICA (THE)",
      Currency: "US Dollar",
      AlphabeticCode: "USD",
      NumericCode: 840,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "UNITED STATES OF AMERICA (THE)",
      Currency: "US Dollar (Next day)",
      AlphabeticCode: "USN",
      NumericCode: 997,
      MinorUnit: 2,
      Fund: "TRUE"
    },
    {
      Entity: "URUGUAY",
      Currency: "Peso Uruguayo",
      AlphabeticCode: "UYU",
      NumericCode: 858,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "URUGUAY",
      Currency: "Uruguay Peso en Unidades Indexadas (UI)",
      AlphabeticCode: "UYI",
      NumericCode: 940,
      MinorUnit: 0,
      Fund: "TRUE"
    },
    {
      Entity: "URUGUAY",
      Currency: "Unidad Previsional",
      AlphabeticCode: "UYW",
      NumericCode: 927,
      MinorUnit: 4,
      Fund: ""
    },
    {
      Entity: "UZBEKISTAN",
      Currency: "Uzbekistan Sum",
      AlphabeticCode: "UZS",
      NumericCode: 860,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "VANUATU",
      Currency: "Vatu",
      AlphabeticCode: "VUV",
      NumericCode: 548,
      MinorUnit: 0,
      Fund: ""
    },
    {
      Entity: "VENEZUELA (BOLIVARIAN REPUBLIC OF)",
      Currency: "Bolívar Soberano",
      AlphabeticCode: "VES",
      NumericCode: 928,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "VIET NAM",
      Currency: "Dong",
      AlphabeticCode: "VND",
      NumericCode: 704,
      MinorUnit: 0,
      Fund: ""
    },
    {
      Entity: "VIRGIN ISLANDS (BRITISH)",
      Currency: "US Dollar",
      AlphabeticCode: "USD",
      NumericCode: 840,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "VIRGIN ISLANDS (U.S.)",
      Currency: "US Dollar",
      AlphabeticCode: "USD",
      NumericCode: 840,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "WALLIS AND FUTUNA",
      Currency: "CFP Franc",
      AlphabeticCode: "XPF",
      NumericCode: 953,
      MinorUnit: 0,
      Fund: ""
    },
    {
      Entity: "WESTERN SAHARA",
      Currency: "Moroccan Dirham",
      AlphabeticCode: "MAD",
      NumericCode: 504,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "YEMEN",
      Currency: "Yemeni Rial",
      AlphabeticCode: "YER",
      NumericCode: 886,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "ZAMBIA",
      Currency: "Zambian Kwacha",
      AlphabeticCode: "ZMW",
      NumericCode: 967,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "ZIMBABWE",
      Currency: "Zimbabwe Dollar",
      AlphabeticCode: "ZWL",
      NumericCode: 932,
      MinorUnit: 2,
      Fund: ""
    },
    {
      Entity: "ZZ01_Bond Markets Unit European_EURCO",
      Currency: "Bond Markets Unit European Composite Unit (EURCO)",
      AlphabeticCode: "XBA",
      NumericCode: 955,
      MinorUnit: "N.A.",
      Fund: ""
    },
    {
      Entity: "ZZ02_Bond Markets Unit European_EMU-6",
      Currency: "Bond Markets Unit European Monetary Unit (E.M.U.-6)",
      AlphabeticCode: "XBB",
      NumericCode: 956,
      MinorUnit: "N.A.",
      Fund: ""
    },
    {
      Entity: "ZZ03_Bond Markets Unit European_EUA-9",
      Currency: "Bond Markets Unit European Unit of Account 9 (E.U.A.-9)",
      AlphabeticCode: "XBC",
      NumericCode: 957,
      MinorUnit: "N.A.",
      Fund: ""
    },
    {
      Entity: "ZZ04_Bond Markets Unit European_EUA-17",
      Currency: "Bond Markets Unit European Unit of Account 17 (E.U.A.-17)",
      AlphabeticCode: "XBD",
      NumericCode: 958,
      MinorUnit: "N.A.",
      Fund: ""
    },
    {
      Entity: "ZZ06_Testing_Code",
      Currency: "Codes specifically reserved for testing purposes",
      AlphabeticCode: "XTS",
      NumericCode: 963,
      MinorUnit: "N.A.",
      Fund: ""
    },
    {
      Entity: "ZZ07_No_Currency",
      Currency: "The codes assigned for transactions where no currency is involved",
      AlphabeticCode: "XXX",
      NumericCode: 999,
      MinorUnit: "N.A.",
      Fund: ""
    },
    {
      Entity: "ZZ08_Gold",
      Currency: "Gold",
      AlphabeticCode: "XAU",
      NumericCode: 959,
      MinorUnit: "N.A.",
      Fund: ""
    },
    {
      Entity: "ZZ09_Palladium",
      Currency: "Palladium",
      AlphabeticCode: "XPD",
      NumericCode: 964,
      MinorUnit: "N.A.",
      Fund: ""
    },
    {
      Entity: "ZZ10_Platinum",
      Currency: "Platinum",
      AlphabeticCode: "XPT",
      NumericCode: 962,
      MinorUnit: "N.A.",
      Fund: ""
    },
    {
      Entity: "ZZ11_Silver",
      Currency: "Silver",
      AlphabeticCode: "XAG",
      NumericCode: 961,
      MinorUnit: "N.A.",
      Fund: ""
    },
    {
      Entity: "",
      Currency: "",
      AlphabeticCode: "",
      NumericCode: "",
      MinorUnit: "",
      Fund: ""
    },
    {
      Entity: "",
      Currency: "",
      AlphabeticCode: "",
      NumericCode: "",
      MinorUnit: "",
      Fund: ""
    },
    {
      Entity: "",
      Currency: "",
      AlphabeticCode: "",
      NumericCode: "",
      MinorUnit: "",
      Fund: ""
    },
    {
      Entity: "",
      Currency: "",
      AlphabeticCode: "",
      NumericCode: "",
      MinorUnit: "",
      Fund: ""
    },
    {
      Entity: "",
      Currency: "",
      AlphabeticCode: "",
      NumericCode: "",
      MinorUnit: "",
      Fund: ""
    },
    {
      Entity: "",
      Currency: "",
      AlphabeticCode: "",
      NumericCode: "",
      MinorUnit: "",
      Fund: ""
    },
    {
      Entity: "",
      Currency: "",
      AlphabeticCode: "",
      NumericCode: "",
      MinorUnit: "",
      Fund: ""
    },
    {
      Entity: "",
      Currency: "Table A.2 (E) — Funds codes registered with the Maintenance Agency",
      AlphabeticCode: "",
      NumericCode: "",
      MinorUnit: "",
      Fund: ""
    },
    {
      Entity: "Code",
      Currency: "Definitions of the fund types",
      AlphabeticCode: "",
      NumericCode: "",
      MinorUnit: "",
      Fund: ""
    },
    {
      Entity: "BOV",
      Currency: "For indexation purposes and denomination of certain financial instruments (e.g. treasury bills). The Mvdol is set daily by the Central Bank of Bolivia based on the official USD/BOB rate.",
      AlphabeticCode: "",
      NumericCode: "",
      MinorUnit: "",
      Fund: ""
    },
    {
      Entity: "CHW",
      Currency: "WIR Franc – WIR Bank for use with the EFTPOS system with their own WIR-card and the Electronic Banking Services.",
      AlphabeticCode: "",
      NumericCode: "",
      MinorUnit: "",
      Fund: ""
    },
    {
      Entity: "CHE",
      Currency: "WIR Euro – WIR Bank for use with the EFTPOS system with their own WIR-card and the Electronic Banking Services",
      AlphabeticCode: "",
      NumericCode: "",
      MinorUnit: "",
      Fund: ""
    },
    {
      Entity: "CLF",
      Currency: "The CLF is a daily economically-financial unit calculated by the Central Bank of Chile according to inflation (as measured by the Chilean Consumer Price Index of the previous month). The value of the CLF is expressed in terms of Chilean Pesos per CLF. The use of CLF has been widely extended to all types of bank loans, financial investments (time deposits, mortgages and other public or private indexed instruments), contracts and fees in some cases.",
      AlphabeticCode: "",
      NumericCode: "",
      MinorUnit: "",
      Fund: ""
    },
    {
      Entity: "COU",
      Currency: "The UVR is a daily account unit set by the Central Bank of Colombia according to the variation in the Consumer Price Index of Colombia. The value of UVR is expressed in terms of Colombian Pesos per UVR. It is used to denominate and update mortgage loans and some public debt bonds.",
      AlphabeticCode: "",
      NumericCode: "",
      MinorUnit: "",
      Fund: ""
    },
    {
      Entity: "MXV",
      Currency: "The UDI is an inflation adjusted mechanism set by the Central Bank of Mexico according to the variation in the Mexican Consumer Price Index. The value of the UDI is expressed in terms of Mexican Pesos per UDI. It is used to denominate mortgage loans, some bank deposits with maturities of 3 month or more and Government bonds (UDIBONOS).",
      AlphabeticCode: "",
      NumericCode: "",
      MinorUnit: "",
      Fund: ""
    },
    {
      Entity: "USN",
      Currency: "”Next day“ funds are immediately available for transfer in like funds, and, subject to settlement, available the next business day for same day funds transfer or withdrawal in cash.",
      AlphabeticCode: "",
      NumericCode: "",
      MinorUnit: "",
      Fund: ""
    },
    {
      Entity: "UYI",
      Currency: "The UYI (URUIURUI) is used for issuance of debt instruments by the Uruguayan government in the international global bond market.  It is calculated based on an established methodology using underlying inflationary statistics in the Uruguayan market. (Introduced in 2002).",
      AlphabeticCode: "",
      NumericCode: "",
      MinorUnit: "",
      Fund: ""
    },
    {
      Entity: "UYW",
      Currency: "The Unidad Previsional  (UP) is a daily accounting unit that tracks changes to the nominal wage index. The value of UP is expressed in terms of Uruguayan Pesos per UP, with the initial value of one peso (UYU 1.00) on 04/30/2018. The institution responsible for the calculation and publication is the Instituto Nacional de Estadística (National Bureau of Statistics) according to Law 19,608.",
      AlphabeticCode: "",
      NumericCode: "",
      MinorUnit: "",
      Fund: ""
    }
  ].filter(c => c.AlphabeticCode.length > 0).sort((a, b) => {
        if (a.AlphabeticCode === b.AlphabeticCode) {
            return 0;
        } else if (a.AlphabeticCode === 'USD') {
            return -1;
        } else if (b.AlphabeticCode === 'USD') {
            return 1;
        } else if (a.AlphabeticCode === 'EUR' ) {
            return -1;
        } else if (b.AlphabeticCode === 'EUR' ) {
            return 1;
        } else if (a.AlphabeticCode === 'GBP') {
            return -1;
        } else if (b.AlphabeticCode === 'GBP') {
            return 1;
        } else if (b.AlphabeticCode === 'CHF') {
            return 1;
        } else if (a.AlphabeticCode === 'CHF') {
            return -1;
        } else if (a.AlphabeticCode === 'AUD') {
            return -1;
        } else if (b.AlphabeticCode === 'AUD') {
            return 1;
        } else if (a.AlphabeticCode === 'ZAR') {
            return -1;
        } else if (b.AlphabeticCode === 'ZAR') {
            return 1;
        }
        else return a.AlphabeticCode > b.AlphabeticCode ? 1 : -1;
    });

    const result = [];
    const map = new Map();
    for (const item of isoCurrencies) {
        if(!map.has(item.AlphabeticCode)){
            map.set(item.AlphabeticCode, true);    // set any value to Map
            result.push(item);
        }
    }

    export const CURRENCIES = result;

// export const CURRENCIES = [
//     { options: 'Common', name: 'USD', symbol: '$' },
//     { options: 'Common', name: 'EUR', symbol: '€' },
//     { options: 'Common', name: 'JPY', symbol: '¥' },
//     { options: 'Common', name: 'GBP', symbol: '£' },
//     { options: 'Common', name: 'AUD', symbol: 'A$' },
//     { options: 'Common', name: 'CAD', symbol: 'CA$' },
//     { options: 'Common', name: 'CHF', symbol: 'CHF' },
//     { options: 'Common', name: 'CNH', symbol: 'CNH' },
//     { options: 'Common', name: 'ZAR', symbol: 'ZAR' },
//     { options: 'separator', name: 'separator', symbol: '' },
//     { options: 'Other', name: 'AED', symbol: 'AED' },
//     { options: 'Other', name: 'AFN', symbol: 'AFN' },
//     { options: 'Other', name: 'ALL', symbol: 'ALL' },
//     { options: 'Other', name: 'AMD', symbol: 'AMD' },
//     { options: 'Other', name: 'ANG', symbol: 'ANG' },
//     { options: 'Other', name: 'AOA', symbol: 'AOA' },
//     { options: 'Other', name: 'ARS', symbol: 'ARS' },
//     { options: 'Other', name: 'AWG', symbol: 'AWG' },
//     { options: 'Other', name: 'AZN', symbol: 'AZN' },
//     { options: 'Other', name: 'BAM', symbol: 'BAM' },
//     { options: 'Other', name: 'BBD', symbol: 'BBD' },
//     { options: 'Other', name: 'BDT', symbol: 'BDT' },
//     { options: 'Other', name: 'BGN', symbol: 'BGN' },
//     { options: 'Other', name: 'BHD', symbol: 'BHD' },
//     { options: 'Other', name: 'BIF', symbol: 'BIF' },
//     { options: 'Other', name: 'BMD', symbol: 'BMD' },
//     { options: 'Other', name: 'BND', symbol: 'BND' },
//     { options: 'Other', name: 'BOB', symbol: 'BOB' },
//     { options: 'Other', name: 'BOV', symbol: 'BOV' },
//     { options: 'Other', name: 'BRL', symbol: 'R$' },
//     { options: 'Other', name: 'BSD', symbol: 'BSD' },
//     { options: 'Other', name: 'BTN', symbol: 'BTN' },
//     { options: 'Other', name: 'BWP', symbol: 'BWP' },
//     { options: 'Other', name: 'BYN', symbol: 'BYN' },
//     { options: 'Other', name: 'BZD', symbol: 'BZD' },
//     { options: 'Other', name: 'CDF', symbol: 'CDF' },
//     { options: 'Other', name: 'CHE', symbol: 'CHE' },
//     { options: 'Other', name: 'CHW', symbol: 'CHW' },
//     { options: 'Other', name: 'CLF', symbol: 'CLF' },
//     { options: 'Other', name: 'CLP', symbol: 'CLP' },
//     { options: 'Other', name: 'CNY', symbol: 'CN¥' },
//     { options: 'Other', name: 'COP', symbol: 'COP' },
//     { options: 'Other', name: 'COU', symbol: 'COU' },
//     { options: 'Other', name: 'CRC', symbol: 'CRC' },
//     { options: 'Other', name: 'CUC', symbol: 'CUC' },
//     { options: 'Other', name: 'CUP', symbol: 'CUP' },
//     { options: 'Other', name: 'CVE', symbol: 'CVE' },
//     { options: 'Other', name: 'CZK', symbol: 'CZK' },
//     { options: 'Other', name: 'DJF', symbol: 'DJF' },
//     { options: 'Other', name: 'DKK', symbol: 'DKK' },
//     { options: 'Other', name: 'DOP', symbol: 'DOP' },
//     { options: 'Other', name: 'DZD', symbol: 'DZD' },
//     { options: 'Other', name: 'EGP', symbol: 'EGP' },
//     { options: 'Other', name: 'ERN', symbol: 'ERN' },
//     { options: 'Other', name: 'ETB', symbol: 'ETB' },
//     { options: 'Other', name: 'FJD', symbol: 'FJD' },
//     { options: 'Other', name: 'FKP', symbol: 'FKP' },
//     { options: 'Other', name: 'GEL', symbol: 'GEL' },
//     { options: 'Other', name: 'GHS', symbol: 'GHS' },
//     { options: 'Other', name: 'GIP', symbol: 'GIP' },
//     { options: 'Other', name: 'GMD', symbol: 'GMD' },
//     { options: 'Other', name: 'GNF', symbol: 'GNF' },
//     { options: 'Other', name: 'GTQ', symbol: 'GTQ' },
//     { options: 'Other', name: 'GYD', symbol: 'GYD' },
//     { options: 'Other', name: 'HKD', symbol: 'HK$' },
//     { options: 'Other', name: 'HNL', symbol: 'HNL' },
//     { options: 'Other', name: 'HRK', symbol: 'HRK' },
//     { options: 'Other', name: 'HTG', symbol: 'HTG' },
//     { options: 'Other', name: 'HUF', symbol: 'HUF' },
//     { options: 'Other', name: 'IDR', symbol: 'IDR' },
//     { options: 'Other', name: 'ILS', symbol: '₪' },
//     { options: 'Other', name: 'INR', symbol: '₹' },
//     { options: 'Other', name: 'IQD', symbol: 'IQD' },
//     { options: 'Other', name: 'IRR', symbol: 'IRR' },
//     { options: 'Other', name: 'ISK', symbol: 'ISK' },
//     { options: 'Other', name: 'JMD', symbol: 'JMD' },
//     { options: 'Other', name: 'JOD', symbol: 'JOD' },
//     { options: 'Other', name: 'KES', symbol: 'KES' },
//     { options: 'Other', name: 'KGS', symbol: 'KGS' },
//     { options: 'Other', name: 'KHR', symbol: 'KHR' },
//     { options: 'Other', name: 'KMF', symbol: 'KMF' },
//     { options: 'Other', name: 'KPW', symbol: 'KPW' },
//     { options: 'Other', name: 'KRW', symbol: '₩' },
//     { options: 'Other', name: 'KWD', symbol: 'KWD' },
//     { options: 'Other', name: 'KYD', symbol: 'KYD' },
//     { options: 'Other', name: 'KZT', symbol: 'KZT' },
//     { options: 'Other', name: 'LAK', symbol: 'LAK' },
//     { options: 'Other', name: 'LBP', symbol: 'LBP' },
//     { options: 'Other', name: 'LKR', symbol: 'LKR' },
//     { options: 'Other', name: 'LRD', symbol: 'LRD' },
//     { options: 'Other', name: 'LSL', symbol: 'LSL' },
//     { options: 'Other', name: 'LYD', symbol: 'LYD' },
//     { options: 'Other', name: 'MAD', symbol: 'MAD' },
//     { options: 'Other', name: 'MDL', symbol: 'MDL' },
//     { options: 'Other', name: 'MGA', symbol: 'MGA' },
//     { options: 'Other', name: 'MKD', symbol: 'MKD' },
//     { options: 'Other', name: 'MMK', symbol: 'MMK' },
//     { options: 'Other', name: 'MNT', symbol: 'MNT' },
//     { options: 'Other', name: 'MOP', symbol: 'MOP' },
//     { options: 'Other', name: 'MRO', symbol: 'MRO' },
//     { options: 'Other', name: 'MUR', symbol: 'MUR' },
//     { options: 'Other', name: 'MWK', symbol: 'MWK' },
//     { options: 'Other', name: 'MXN', symbol: 'MX$' },
//     { options: 'Other', name: 'MXV', symbol: 'MXV' },
//     { options: 'Other', name: 'MYR', symbol: 'MYR' },
//     { options: 'Other', name: 'MZN', symbol: 'MZN' },
//     { options: 'Other', name: 'NAD', symbol: 'NAD' },
//     { options: 'Other', name: 'NGN', symbol: 'NGN' },
//     { options: 'Other', name: 'NIO', symbol: 'NIO' },
//     { options: 'Other', name: 'NOK', symbol: 'NOK' },
//     { options: 'Other', name: 'NPR', symbol: 'NPR' },
//     { options: 'Other', name: 'NZD', symbol: 'NZ$' },
//     { options: 'Other', name: 'OMR', symbol: 'OMR' },
//     { options: 'Other', name: 'PAB', symbol: 'PAB' },
//     { options: 'Other', name: 'PEN', symbol: 'PEN' },
//     { options: 'Other', name: 'PGK', symbol: 'PGK' },
//     { options: 'Other', name: 'PHP', symbol: 'PHP' },
//     { options: 'Other', name: 'PKR', symbol: 'PKR' },
//     { options: 'Other', name: 'PLN', symbol: 'PLN' },
//     { options: 'Other', name: 'PYG', symbol: 'PYG' },
//     { options: 'Other', name: 'QAR', symbol: 'QAR' },
//     { options: 'Other', name: 'RON', symbol: 'RON' },
//     { options: 'Other', name: 'RSD', symbol: 'RSD' },
//     { options: 'Other', name: 'RUB', symbol: 'RUB' },
//     { options: 'Other', name: 'RWF', symbol: 'RWF' },
//     { options: 'Other', name: 'SAR', symbol: 'SAR' },
//     { options: 'Other', name: 'SBD', symbol: 'SBD' },
//     { options: 'Other', name: 'SCR', symbol: 'SCR' },
//     { options: 'Other', name: 'SDG', symbol: 'SDG' },
//     { options: 'Other', name: 'SEK', symbol: 'SEK' },
//     { options: 'Other', name: 'SGD', symbol: 'SGD' },
//     { options: 'Other', name: 'SHP', symbol: 'SHP' },
//     { options: 'Other', name: 'SLL', symbol: 'SLL' },
//     { options: 'Other', name: 'SOS', symbol: 'SOS' },
//     { options: 'Other', name: 'SRD', symbol: 'SRD' },
//     { options: 'Other', name: 'SSP', symbol: 'SSP' },
//     { options: 'Other', name: 'STN', symbol: 'STN' },
//     { options: 'Other', name: 'SYP', symbol: 'SYP' },
//     { options: 'Other', name: 'SZL', symbol: 'SZL' },
//     { options: 'Other', name: 'THB', symbol: 'THB' },
//     { options: 'Other', name: 'TJS', symbol: 'TJS' },
//     { options: 'Other', name: 'TND', symbol: 'TND' },
//     { options: 'Other', name: 'TOP', symbol: 'TOP' },
//     { options: 'Other', name: 'TRY', symbol: 'TRY' },
//     { options: 'Other', name: 'TTD', symbol: 'TTD' },
//     { options: 'Other', name: 'TWD', symbol: 'NT$' },
//     { options: 'Other', name: 'TZS', symbol: 'TZS' },
//     { options: 'Other', name: 'UAH', symbol: 'UAH' },
//     { options: 'Other', name: 'UGX', symbol: 'UGX' },
//     { options: 'Other', name: 'USN', symbol: 'USN' },
//     { options: 'Other', name: 'UYI', symbol: 'UYI' },
//     { options: 'Other', name: 'UYU', symbol: 'UYU' },
//     { options: 'Other', name: 'UZS', symbol: 'UZS' },
//     { options: 'Other', name: 'VEF', symbol: 'VEF' },
//     { options: 'Other', name: 'VND', symbol: '₫' },
//     { options: 'Other', name: 'VUV', symbol: 'VUV' },
//     { options: 'Other', name: 'WST', symbol: 'WST' },
//     { options: 'Other', name: 'XAF', symbol: 'FCFA' },
//     { options: 'Other', name: 'XCD', symbol: 'EC$' },
//     { options: 'Other', name: 'XOF', symbol: 'CFA' },
//     { options: 'Other', name: 'XPF', symbol: 'CFPF' },
//     { options: 'Other', name: 'YER', symbol: 'YER' },
//     { options: 'Other', name: 'ZMW', symbol: 'ZMW' }
// ];
