export const FIELDS_CREATE = `
    mutation M(
        $model_id: Int!
        $order: Int!
        $type_id: Int!
        $status_id: Int
        $index_field_id: Long
        $input_id: String
        $visibility_check: String
        $title: String!
        $description: HtmlString
        $is_required: Boolean!
        $validation: String
        $width: Int!
        $force_break: Boolean
        $extra_info: JsonString
        $is_static_unit: Boolean!
        $default_unit: Int
    ) {
        model_field_create(
            input: {
                model_id: $model_id
                order: $order
                type_id: $type_id
                status_id: $status_id
                index_field_id: $index_field_id
                input_id: $input_id
                visibility_check: $visibility_check
                title: $title
                description: $description
                is_required: $is_required
                validation: $validation
                width: $width
                force_break: $force_break
                extra_info: $extra_info
                is_static_unit: $is_static_unit
                default_unit: $default_unit
            }
        ) {
            id
            model_id
            order
            type_id
            type_name
            status_id
            status_name
            index_field_id
            input_id
            visibility_check
            title
            description
            is_required
            validation
            width
            force_break
            extra_info
            company_id: model_company_id

            index_field {
                id
                order_by
                type_id
                type_name
                status_id
                status_name
                title
                description
                extra_info
            }
        }
    }
`;
