export const FIELD_VISIBILITY_INDEX_VALUES = `
    query(
        $index_field_id: Long!
        $model_ids: [Int!]
        $show_hidden: Boolean
        $search: String
        $order_by: [OrderBy]
        $offset: Int
        $limit: Int
    ) {
        model_field_value_list_by_index(
            input: { index_field_id: $index_field_id, model_ids: $model_ids, show_hidden: $show_hidden, search: $search }
            paging: { order_by: $order_by, offset: $offset, limit: $limit }
        ) {
            total
            data {
                id
                company_id
                field_id
                order_by
                is_hidden
                value
                field_model_id
                field_model_title
            }
        }
    }
`;
