import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logout, getProfile } from '../../../reducers/identity.actions';
import { getPages } from '../../../pages';

import './user.scss';
import UserMenuComponent from './userMenu';

class UserMenu extends React.Component {
    componentDidMount() {
        const { getProfile, user } = this.props;
        if (!user) {
            getProfile();
        }
    }

    render() {
        const { user, logout, permissions, entityPermissions, company } = this.props;
        if (!user || !company) return null;

        return <UserMenuComponent user={user} logout={logout} links={getPages('user', permissions, entityPermissions, company)} />;
    }
}

const mapStateToProps = state => ({
    user: state.identity.user,
    company: state.identity.company,
    permissions: state.identity.permissions,
    entityPermissions: state.identity.entityPermissions
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getProfile,
            logout
        },
        dispatch
    );
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserMenu);
