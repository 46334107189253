export const TRANSACTIONS_GET_SIDE_EFFECTS_DETAILS = `
    query _($transaction_field_id: Long!) {
        transaction_field_get_side_effects_details(input: {
            transaction_field_id: $transaction_field_id
        }) {
            side_effects {
                type
                affectedDot {
                    id
                    hash_id
                    created_on
                    modified_on
                    quantity
                    original_quantity
                    claimed_by_dash_id
                    created_by_dash_id
                    created_by_dash_company
                    created_by_dash_is_accessible
                    claimed_by_dash_company
                    claimed_by_dash_is_accessible
                    status_name
                    cargo_unit_id
                    cargo_unit {
                        name
                        display_name
                        description
                    }
                    cargo_unit_name
                    cargo_type_hash_id
                    cargo_type_category_title
                }
                affectedEntity {
                    id
                    title
                }
                dotField {
                    id
                    transaction_id
                    field_id
                    title
                }
            }

            dots_in_use {
                dot {
                    id
                    hash_id
                    created_on
                    modified_on
                    quantity
                    original_quantity
                    claimed_by_dash_id
                    created_by_dash_id
                    created_by_dash_company
                    created_by_dash_is_accessible
                    claimed_by_dash_company
                    claimed_by_dash_is_accessible
                    status_name
                    cargo_unit_id
                    cargo_unit {
                        name
                        display_name
                        description
                    }
                    cargo_unit_name
                    cargo_type_hash_id
                    cargo_type_category_title
                }

                dash_field {
                    id
                    transaction_id
                    field_id
                    title
                }

                dash_used_in {
                    id
                    title
                }
            }
        }
    }
`;
