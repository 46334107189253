export const MODELS_CREATE = `
    mutation M($status_id: Int!, $title: String!, $description: HtmlString, $split_conditions: String, $extra_info: JsonString, $model_type_name: String) {
        model_create(input: { status_id: $status_id, title: $title, description: $description, split_conditions: $split_conditions, extra_info: $extra_info, model_type_name: $model_type_name }) {
            id
            hash_id
            company_id
            status_id
            status_name
            created_on
            title
            description
            is_used
            split_conditions
            extra_info
            model_type
            model_type_name
            contains_inaccessible_dots
        }
    }
`;
