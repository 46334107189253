export const ALERTS_GET = `
    query _($alert_id: Int!) {
        alert(input: { id: $alert_id }) {
            id
            hash_id
            title
            description
            created_on
            created_by
            active
            conditions {
                id
                type_id
                comparator_id
                alert_id
                order
                break_or
                value
                template {
                    id
                    title
                }
                field {
                    id
                    title
                    type_id
                    type_name
                }
                object_type {
                    id
                    title
                }
                index_field {
                    id
                    title
                    type_id
                    type_name
                }
                dot_field
            }
            subscribers {
                id
                email
            }
        }
    }
`;
