import React from 'react';
import 'pages/models/models.scss';
import { FormattedMessage } from 'react-intl';
import FieldReferenceInfo from './fieldReferenceInfo'

class FieldReferenceGroupInfo extends React.Component {
    render() {
        const { group, refs } = this.props;
        const refsInGroup = refs.filter(r => r.group && r.group.name === group.name);
        let els = [];
        for (const fieldRef of refsInGroup) {
            const { literal, label, type, token, field, index } = fieldRef;

            let category;
            if (type === 'model') {
                category = <FormattedMessage id='dashTemplate' />;
            } else if (type === 'index') {
                category = <FormattedMessage id='index' />;
            } else {
                category = <FormattedMessage id='dot' />;
            }
            els.push((
                <React.Fragment key={literal}>
                    {/* <span>
                        <strong><FormattedMessage id='label' />: </strong> {label} ({category})
                    </span>
                    <br/> */}
                    <FieldReferenceInfo fieldRef={fieldRef} />
                    <hr/>
                </React.Fragment>
            ));
        }

        return (
            <React.Fragment key={group.name}>
                <div className="form-group col-12 pd-15">
                    <strong><FormattedMessage id='fieldReferenceGroup' /></strong>
                    <br/>
                    {els}
                </div>
            </React.Fragment>
        );
    }
}

export default FieldReferenceGroupInfo;
