export const FIELD_GET = `
    query _($field_id : Int!)  
    {
        model_field(field_id: $field_id)
        {
            id
            model_id
            order
            type_id
            type_name
            status_id
            status_name
            index_field_id
            input_id
            visibility_check
            title
            description
            is_required
            validation
            width
            force_break
            extra_info
            company_id: model_company_id
            is_static_unit
            default_unit

            map_dash_field
            map_dot_field
            map_special_dot_field
            map_compound_id
            
            values: model_field_value_list(show_hidden: false)
            {  
                id
                company_id
                field_id
                order_by
                is_hidden
                value
            }
            
            index_field
            {
                id
                order_by
                type_id
                type_name
                status_id
                status_name
                title
                description
                extra_info
            }
            
            visibility_condition_list
            {
                id
                order_by
                break_or
                is_not
                field_id
                compare_field_id
                comparator_id
                comparator_name
                comparands
                extra_info
            }

            model
            {
                id
                company_id
                status_id
                status_name
                created_on
                title
                description
                is_used
                split_conditions
                extra_info
                
                model_type_name
                contains_inaccessible_dots
            }
        }
    }
`;
