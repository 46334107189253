import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import SimpleBar from 'simplebar-react';
import { Formik, Form } from 'formik';
import { Fields } from '../../../../../components/fields';
import { DocumentItemTypes } from '../../../../../models/document';
import { capitalize } from '../../../../../utilities/common';
import { setItems, setTemplate } from '../../../../../reducers/document';
import { uploadMedia, getMedia } from '../../../../../reducers/media';
import FieldsReferencesMenu from './fieldsReferencesMenu';
import FieldsReferencesDialog from './fieldsReferences';
import Tooltip from 'rc-tooltip';
import { getPaperOrientations, getPaperSizes, getFonts, getFontSizes } from '../../../../../utilities/constants';

class DocumentConfiguratorProperties extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: 0
        };
    }

    setVisibleTab = tab => this.setState({ ...this.state, tab });

    getKey = () => {
        const { tab } = this.state;
        const { selected, template } = this.props;
        if (tab === 1) {
            return `template-${template.id}`;
        }

        if (tab === 0 && !!selected.section && !!selected.item) {
            return `item-${selected.section}-${selected.item}`;
        }
    };

    getInitialValues = () => {
        const { tab } = this.state;
        const { selected, template } = this.props;
        if (tab === 1) {
            return { title: template.title, description: template.description, page_orientation: template.page_orientation, paper_size: template.paper_size };
        }
        if (tab === 0 && !!selected.section && !!selected.item) {
            const values = template[selected.section].find(i => i.ref_id === selected.item);
            return { ...values };
        } else {
            return tab;
        }
    };

    onRichTextMacroSelect = (formProps) => {
        return (id) => {
            var richTextContent = JSON.parse(formProps.values.json);
            richTextContent.blocks[0].text += '{{' + id + '}}';
            formProps.values.json = JSON.stringify(richTextContent);
            formProps.submitForm();
            const { manualRichTextEditorChange = null } = this.props;
            if (!!manualRichTextEditorChange){
                manualRichTextEditorChange();
            }
        }
    } 

    typeSpecificProperties = {
        type: (typeOptions, values, disabled = false) => (
            <div className="form-group col-12">
                <Fields.Select name="type" options={typeOptions} submitOnChange={true} value={values.type} disabled={disabled} />
            </div>
        ),
        title: (disabled = false) => (
            <div className="form-group col-12">
                <Fields.Input type="text" name="title" disabled={disabled} />
            </div>
        ),
        paperSize: (typeOptions, values, disabled = false) => (
            <div className="form-group col-12">
                <Fields.Select name="paper_size" options={typeOptions} submitOnChange={true} value={values.paper_size} disabled={disabled} />
            </div>
        ),
        pageOrientation: (typeOptions, values, disabled = false) => (
            <div className="form-group col-12">
                <Fields.Select name="page_orientation" options={typeOptions} submitOnChange={true} value={values.page_orientation} disabled={disabled} />
            </div>
        ),
        description: (disabled = false) => (
            <div className="form-group col-12">
                <Fields.Input type="text" name="description" disabled={disabled} />
            </div>
        ),
        widthAndOffset: (disabled = false) => (
            <div className="form-group col-12">
                <Fields.Range
                    disabled={disabled}
                    min={0}
                    max={12}
                    step={1}
                    moreInfo={(
                        <Tooltip placement={'top'} trigger={['hover']} overlay={<FormattedMessage id="common.impersonate" />}>
                            <i className="material-icons-outlined">info</i>
                        </Tooltip>
                    )}
                    valueDisplay={value => {
                        let width = 0, offset = 0;
                        if (value && Array.isArray(value)){
                            offset = value[0];
                            width = value[1] - value[0];
                        }
                        return `${Math.round((width * 100) / 12)}% \n ${Math.round((offset * 100) / 12)}%`
                    }}
                    submitOnChange={true}
                    name="width_offset"
                />
            </div>
        ),
        width: (disabled = false) => (
            <div className="form-group col-12">
                <Fields.Slider
                    disabled={disabled}
                    min={0}
                    max={12}
                    step={null}
                    marks={{ 3: '25%', 4: '33%', 6: '50%', 8: '67%', 9: '75%', 12: '100%' }}
                    valueDisplay={value => `${Math.round((value * 100) / 12)}%`}
                    submitOnChange={true}
                    name="width"
                />
            </div>
        ),
        offset: (disabled = false) => (
            <div className="form-group col-12">
                <Fields.Slider
                    disabled={disabled}
                    min={0}
                    max={12}
                    step={null}
                    marks={{ 0: '0%', 3: '25%', 4: '33%', 6: '50%', 8: '67%', 9: '75%', 12: '100%' }}
                    valueDisplay={value => `${Math.round((value * 100) / 12)}%`}
                    submitOnChange={true}
                    name="offset"
                />
            </div>
        ),
        topPadding: (disabled = false) => (
            <div className="form-group col-12">
                <Fields.Slider
                    disabled={disabled}
                    min={0}
                    max={50}
                    step={1}
                    valueDisplay={value => value}
                    submitOnChange={true}
                    name="margin_top"
                />
            </div>
        ),
        bottomPadding: (disabled = false) => (
            <div className="form-group col-12">
                <Fields.Slider
                    disabled={disabled}
                    min={0}
                    max={50}
                    step={1}
                    valueDisplay={value => value}
                    submitOnChange={true}
                    name="margin_bottom"
                />
            </div>
        ),
        imageAlignment: (values, disabled = false) => (
            <div className="form-group col-12">
                <Fields.ImageAlignment disabled={disabled} width={values.width} />
            </div>
        ),
        url: (disabled = false, uploadMedia, getMedia) => (
            <div className="form-group col-12">
                <Fields.Upload
                    submitOnChange={true}
                    name="url"
                    accept="image/*"
                    // maxSize={10}
                    multiple={false}
                    disabled={disabled}
                    action={(file, onComplete) => uploadMedia(file, media => getMedia(media.id, onComplete))}
                />
                <small>
                    <FormattedMessage id="page.models.document.configuration.properties.maxFileSize" />
                </small>
            </div>
        ),
        json: (disabled = false, formProps, values, onFieldSelect) => (
            <div className="form-group col-12">
                <Fields.RichTextButton
                    submitOnChange={true}
                    name="json"
                    hiddenControls={['H1', 'H2', 'H3', 'H4', 'Blockquote', 'UL', 'OL', 'Code Block', 'Monospace']}
                    extraControls={<FieldsReferencesMenu formProps={formProps} name="content" currentValue={values} onFieldSelect={onFieldSelect}/>}
                    dialogTitle={<FormattedMessage id="page.models.document.configuration.properties.richtext" />}
                    disabled={disabled}
                />
            </div>
        ),
        content: (disabled = false, formProps, values) => (
            <div className="form-group col-12">
                <Fields.Input
                    type="text"
                    name="content"
                    disabled={disabled}
                    extras={{
                        post: <FieldsReferencesDialog formProps={formProps} name="content" currentValue={values.content} />
                    }}
                />
            </div>
        ),
        breakRow: (values, disabled = false) => (
            <div className="form-group col-12">
                <Fields.Checkbox name="break_row" checked={values.break_row === true} disabled={disabled} />
            </div>
        ),
        alignment: (typeOptions, values, disabled = false) => (
            <div className="form-group col-12">
                <Fields.Select name="text_alignment" options={typeOptions} submitOnChange={true} value={values.text_alignment} disabled={disabled} />
            </div>
        ),
        font: (typeOptions, values, disabled = false) => (
            <div className="form-group col-12">
                <Fields.Select name="font" options={typeOptions} submitOnChange={true} value={values.font || 'Inherit from previous'} disabled={disabled} />
            </div>
        ),
        fontSize: (typeOptions, values, disabled = false) => (
            <div className="form-group col-12">
                <Fields.Select name="font_size" options={typeOptions} submitOnChange={true} value={values.font_size} disabled={disabled} />
            </div>
        ),
        bold: (values, disabled = false) => (
            <div className="form-group col-12">
                <Fields.Checkbox name="bold" checked={values.bold === true} disabled={disabled} />
            </div>
        ),
        italic: (values, disabled = false) => (
            <div className="form-group col-12">
                <Fields.Checkbox name="italic" checked={values.italic === true} disabled={disabled} />
            </div>
        ),
        underline: (values, disabled = false) => (
            <div className="form-group col-12">
                <Fields.Checkbox name="underline" checked={values.underline === true} disabled={disabled} />
            </div>
        )
    };

    getProperties = (values, formProps) => {
        const { uploadMedia, getMedia } = this.props;

        const { tab, is_used = false } = this.state;
        const { selected, template } = this.props;
        const type = this.typeSpecificProperties.type(['richtext', 'image'].map(t => ({ value: t, label: t === 'richtext' ? 'Rich Text' : 'Image' })), values, is_used);
        const paperSize = this.typeSpecificProperties.paperSize(getPaperSizes(), values, is_used);
        const paperOrientation = this.typeSpecificProperties.pageOrientation(getPaperOrientations(), values, is_used);

        const title = this.typeSpecificProperties.title(is_used);
        const description = this.typeSpecificProperties.description(is_used);
        const widthAndOffset = this.typeSpecificProperties.widthAndOffset(is_used);
        const topPadding = this.typeSpecificProperties.topPadding(is_used);
        const bottomPadding = this.typeSpecificProperties.bottomPadding(is_used);
        const json = this.typeSpecificProperties.json(is_used, formProps, values, this.onRichTextMacroSelect(formProps));
        const url = this.typeSpecificProperties.url(is_used, uploadMedia, getMedia);
        const content = this.typeSpecificProperties.content(is_used, formProps, values);
        const breakRow = this.typeSpecificProperties.breakRow(values, is_used);
        const alignment = this.typeSpecificProperties.alignment(['left', 'center', 'right'].map(t => ({ value: t, label: capitalize(t) })), values, is_used);
        const imageAlignment = this.typeSpecificProperties.imageAlignment(values, is_used);
        const bold = this.typeSpecificProperties.bold(values, is_used);
        const italic = this.typeSpecificProperties.italic(values, is_used);
        const underline = this.typeSpecificProperties.underline(values, is_used);

        const font = this.typeSpecificProperties.font(getFonts(), values, is_used);
        const fontSize = this.typeSpecificProperties.fontSize(getFontSizes(), values, is_used);
        

        if (tab === 1) {
            return [title, description, paperSize, paperOrientation];
        }

        const item = template[selected.section].find(i => i.ref_id === selected.item);
        if (!item) return undefined;

        switch (item.type) {
            case DocumentItemTypes.IMAGE:
                return [type, title, url, breakRow, widthAndOffset, imageAlignment, topPadding, bottomPadding];

            case DocumentItemTypes.RICHTEXT:
                return [type, title, json, breakRow, alignment, font, fontSize, widthAndOffset, topPadding, bottomPadding]

            case DocumentItemTypes.TEXT:
            default:
                return [type, title, content, breakRow, alignment, font, fontSize, bold, italic, underline, widthAndOffset, topPadding, bottomPadding];
        }
    };

    handleSubmit = values => {
        const { tab } = this.state;
        const { selected, setItems, setTemplate, template } = this.props;

        if (tab === 1) {
            setTemplate(values.title, values.description, values.paper_size, values.page_orientation);
        } else {
            let theItem = { ...values };
            if (values.type === DocumentItemTypes.IMAGE) {
                delete theItem.upload_ignore;
                delete theItem.alignment_ignore;
            }
            const items = template[selected.section];
            setItems(
                selected.section,
                items.map((i, index) => ({ ...(i.ref_id === values.ref_id ? { ...i, ...theItem, order: index } : { ...i, order: index }) }))
            );
        }
    };

    render() {
        const { tab } = this.state;
        const { model, fields, selected } = this.props;
        if (!model || !fields) return null;

        const propertiesVisible = (!!selected.section && !!selected.item) || tab === 1;

        return (
            <div className="widget-2 mg-b-20 doc-t-properties">
                <div className="card overflow-hidden">
                    <div className="card-header">
                        <h6 className="card-title">
                            <FormattedMessage id="common.properties" />
                        </h6>
                    </div>
                    <div className="card-body pd-0 bd-color-gray-lighter">
                        <div className="row no-gutters">
                            <div className="col-12">
                                <SimpleBar>
                                    {!!propertiesVisible ? (
                                        <Formik
                                            key={this.getKey()}
                                            enableReinitialize={true}
                                            initialValues={{ ...this.getInitialValues() }}
                                            onSubmit={this.handleSubmit}
                                            render={formProps => {
                                                const { values, handleChange, submitForm } = formProps;
                                                const properties = this.getProperties(values, formProps);
                                                return (
                                                    <Form
                                                        noValidate
                                                        onChange={async e => {
                                                            await handleChange(e);
                                                            submitForm();
                                                        }}
                                                    >
                                                        <div className="properties-form">
                                                            {!!properties &&
                                                                properties.map((property, i) => <React.Fragment key={i}>{property}</React.Fragment>)}
                                                        </div>
                                                    </Form>
                                                );
                                            }}
                                        />
                                    ) : (
                                        <div className="pd-20 tx-center tx-12">
                                            <FormattedMessage id="page.models.document.configuration.noItemsSelected" />
                                        </div>
                                    )}
                                </SimpleBar>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer tx-12 mg-0 pd-0 bg-transparent">
                        <div className="row mg-0">
                            <span className={`col footer-link ${tab === 0 ? 'active' : ''}`} onClick={() => this.setVisibleTab(0)}>
                                <FormattedMessage id="common.items" />
                            </span>
                            <span className={`col footer-link ${tab === 1 ? 'active' : ''}`} onClick={() => this.setVisibleTab(1)}>
                                <FormattedMessage id="common.document" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    company: state.identity.company,
    model: state.fields.model,
    fields: state.fields.items,
    fieldTypes: state.fields.types,
    selected: state.document.selected,
    template: state.document.instance
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setTemplate,
            setItems,
            uploadMedia,
            getMedia
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DocumentConfiguratorProperties);
