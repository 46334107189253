export const BLACKLIST_DELETE = `
    mutation M($blacklist_id: Long!) {
        blacklist_entry_delete(blacklist_id: $blacklist_id) {
            id
            created_on
            exp_on
            user_id
            added_by_user_id
            company_id

            # Associations
            user {
                id
                email
            }

            user_added {
                id
                email
            }
        }
    }
`;
