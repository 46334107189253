import React from 'react';
import { FieldTypes, default as FieldDefinition } from '../../../../models/field';
import {
    DateField,
    MultiTextField,
    NumberField,
    SectionField,
    SelectField,
    TextField,
    AttachmentField,
    YesNoNullField,
} from '../definitionsControlled';
import { evaluateConditions } from './visibilityConditions/conditionParser';

import '../../models.scss';
import { getCurrencyOptions } from '../../../../utilities/constants';

import { RowBreak } from '../definitions/common'
import { FormattedMessage } from 'react-intl';
import LoadingOverlay from 'react-loading-overlay';
import ClipLoader from 'react-spinners/ClipLoader';
import Tooltip from 'rc-tooltip';

class FieldPreview extends React.Component {
    getPreviewComponent = (field, formProps, fields, comparators, parentId = null, required = false, prefilledFieldIdsPassed = [], showPrefilledFields) => {

        const { disabled = false, prefilledFieldIds = [], onChangeExt, loading, onUseMappedValueToggle = () => null, fieldMappingUsages, isTransformField, onUseUserTransformValueToggle = () => null, userTransformUsages, rerenderOnChange, extendedProps } = this.props;
        const allPrefilledFields = prefilledFieldIds.concat(prefilledFieldIdsPassed);
        const showIsRequired = required || this.props.showIsRequired
        if (!field) return null;
        if (!field.hasOwnProperty('visibilityCondition')) {
            try {
                const mappedField = FieldDefinition.fromDB(field);
                mappedField.isMappedField = field.isMappedField;
                mappedField.isDotField = field.isDotField;
                mappedField.onChangeExt = field.onChangeExt;
                mappedField.locked_from_revise = field.locked_from_revise;
                field = mappedField;
            } catch {
                console.error('field missing visibility condition and unable to parse from DB!', field);
            }
        }
        if (field.id === undefined) {
            return null;
        }
        const typeName = field.type ? field.type : field.type_name;
        const { values, initialValues, errors, touched, setFieldValue, submitForm, setFieldTouched } = formProps;
        let modelSpecificValues = [];
        let modelSpecificInitialValues = [];
        let fieldMappingId = field.id;
        let fieldMappingHash = null;
        // If we have a 'nested' field, make sure we split it from dot namespace notation to grab the correct value
        let valueRaw = undefined;
        let fieldValue = '';
        let initialValue = '';
        let seFieldId = field.id;
        if (!!field.dash_field_id) {
            seFieldId = field.dash_field_id + '.' + field.id;
        }
        if (String(field.id).indexOf('.') > -1) {
            const idParts = field.id.split('.');
            fieldMappingId = idParts[1];
            seFieldId = field.dash_field_id ? (field.dash_field_id + '.' + idParts[1]) : idParts[1];
        }
        if (!!parentId) {
            if (!!values[parentId]) {
                modelSpecificValues = values[parentId];
                modelSpecificInitialValues = initialValues[parentId];
            }
            fieldMappingHash = parentId;
            field.use_mapped_value = !(fieldMappingUsages && fieldMappingUsages[parentId] && fieldMappingUsages[parentId][fieldMappingId] === false);
            field.use_user_transform = !(userTransformUsages && userTransformUsages[parentId] && userTransformUsages[parentId][fieldMappingId] === false);
            
        } else {
            modelSpecificValues = values;
            modelSpecificInitialValues = initialValues;
            field.use_mapped_value = !(fieldMappingUsages && fieldMappingUsages[fieldMappingId] === false);
            field.use_user_transform = !(userTransformUsages && userTransformUsages[fieldMappingId] === false);
        }
        if (String(field.id).indexOf('.') > -1) {
            const idParts = field.id.split('.');
            if (values[idParts[0]]) {
                fieldValue = values[idParts[0]][idParts[1]];
                initialValue = initialValues[idParts[0]][idParts[1]];
                valueRaw = fieldValue;
            }
        } else {
            fieldValue = modelSpecificValues[field.id];
            if (modelSpecificInitialValues) {
                initialValue = modelSpecificInitialValues[field.id];
            }
            valueRaw = fieldValue;
        }
        const result = evaluateConditions(field, modelSpecificValues, fields, comparators);
        if (!result) {
            return field.breakRow ? <RowBreak width={field.width} /> : null;
        }

        const prefilledFieldsParsed = allPrefilledFields.filter(f => f.indexOf('.') > -1).map(f => f.split('.')[1]);
        const fieldIsPrefilled = allPrefilledFields.includes(field.id) || prefilledFieldsParsed.includes(field.id);
        const fieldIsDisabled = disabled || fieldIsPrefilled || (field.isMappedField && field.use_mapped_value) || field.locked_from_revise || (isTransformField && (!field.allow_user_transform || !field.use_user_transform));
        const showUserTransformToggleButton = (isTransformField && !!field.allow_user_transform);

        // Instead of not rendering anything, place a hidden field to maintain visibility conditions
        if (!showPrefilledFields && fieldIsPrefilled) {
            return (
                <div key={field.id} {...field} value={fieldValue} style={{ display: 'hidden' }} />
            )
        }
        if (!!parentId && field.id.indexOf('.') === -1) {
            // we have a cargo field within a section: fix naming convention
            field.id = parentId + '.' + field.id;
        }

        let dateFieldKey;
        if ((field.isMappedField &&  + field.use_mapped_value) || disabled) {
            dateFieldKey = field.id + '-' + valueRaw;
        } else if (valueRaw !== undefined && valueRaw !== null) {
            dateFieldKey = field.id + '-withValue';
        } else {
            dateFieldKey = field.id;
        }

        if (!field.onChangeExt && !!onChangeExt) {
            field.onChangeExt = onChangeExt;
        }
        let tooltipOverlay = null;
        if (field.locked_from_revise) {
            tooltipOverlay = (
                <>
                    <p><FormattedMessage id="fieldLockedFromRevise1" /></p>
                    <p><FormattedMessage id="fieldLockedFromRevise2" /></p>
                </>
            )
        } else if (!disabled && field.isMappedField && field.use_mapped_value) {
            tooltipOverlay = (
                <>
                    <p><FormattedMessage id="fieldIsBeingMappedMessage1" /></p>
                    <p><FormattedMessage id="fieldIsBeingMappedMessage2" /></p>
                </>
            )
        }

        const fieldMappingButton = (
            <button 
                type='button' 
                className={`btn btn-outline-info ${(loading || disabled) && 'disabled'}`}
                style={{ width: '2.8em', height: '3em', padding: 0 }}
                onClick={() => !(loading || disabled) && onUseMappedValueToggle(fieldMappingId, fieldMappingHash)}
            >
                <LoadingOverlay
                    active={this.props.loading}
                    spinner={<ClipLoader size={20} color={'white'}/>}
                    style={{
                        wrapper: {
                            height: '100%'
                        }
                    }}
                >
                    <div
                        style={{
                            height: '2.9em',
                            paddingTop: '.75em'
                        }}
                    >
                        <i 
                            className="material-icons-outlined"
                            style={{ fontSize: '1.5em', verticalAlign: 'middle' }}
                        >
                            { field.use_mapped_value ? 'layers_clear' : 'layers' }
                        </i>
                    </div>
                </LoadingOverlay>
            </button>
        );

        const fieldMappingEl = disabled ? 
            fieldMappingButton
            :
            <Tooltip 
                key={'fmb-' + field.id}
                placement={'top'} 
                trigger={['hover']} 
                overlay={<FormattedMessage id={field.use_mapped_value ? 'overrideMappingTooltip' : 'allowMappingTooltip'} />}
                mouseEnterDelay={0.2}
            >
                { fieldMappingButton }
            </Tooltip>

        

        const userTransformToggleButton = (
            <button 
                type='button' 
                className={`btn btn-outline-info ${(loading || disabled) ? 'disabled' : ''} se-tfm-toggle-btn-${seFieldId}`}
                style={{ width: '2.8em', height: '3em', padding: 0 }}
                onClick={() => !(loading || disabled) && onUseUserTransformValueToggle(fieldMappingId, fieldMappingHash)}
            >
                <LoadingOverlay
                    active={this.props.loading}
                    spinner={<ClipLoader size={20} color={'white'}/>}
                    style={{
                        wrapper: {
                            height: '100%'
                        }
                    }}
                >
                    <div
                        style={{
                            height: '2.9em',
                            paddingTop: '.75em'
                        }}
                    >
                        <i 
                            className="material-icons-outlined"
                            style={{ fontSize: '1.5em', verticalAlign: 'middle' }}
                        >
                            { field.use_user_transform ? 'layers' : 'layers_clear' }
                        </i>
                    </div>
                </LoadingOverlay>
            </button>
        );

        const userTransformToggleEl = disabled ? 
            userTransformToggleButton
            :
            <Tooltip 
                key={'futb-' + field.id}
                placement={'top'} 
                trigger={['hover']} 
                overlay={<FormattedMessage id={field.use_user_transform ? 'disableUserTransform' : 'enableUserTransform'} />}
                mouseEnterDelay={0.2}
            >
                { userTransformToggleButton }
            </Tooltip>

        let appendEl = field.isMappedField ? [fieldMappingEl] : undefined;
        if (showUserTransformToggleButton) {
            appendEl = [userTransformToggleEl];
        }
        delete field.fullField;

        let selectBackgroundColor='';
        if( extendedProps){
            const { selectFieldBackgroundColor } = extendedProps;
            selectBackgroundColor= selectFieldBackgroundColor;
        }

        switch (typeName) {
            case FieldTypes.FIELD_SECTION:
              
                return (                    
                    <SectionField
                        key={field.id}
                        {...field}
                        renderChild={(id) => {
                            let childField = fields.find(f => f.id === id);
                            if (!childField) {
                                const fieldParts = field.id.split('.');
                                if (fieldParts.length > 0) {
                                    childField=fields.find(f => f.id === fieldParts[0] + '.' + id);
                                }
                            }
                            if (childField && !childField.onChangeExt) {
                                childField.onChangeExt = onChangeExt;
                            }
                            return this.getPreviewComponent(childField, formProps, fields.map(f => { f.onChangeExt = onChangeExt; return f; }), comparators, parentId, childField ? childField.is_required : false, allPrefilledFields, showPrefilledFields)
                        }}
                        tooltipOverlay={tooltipOverlay}
                        seleniumId={seFieldId}
                        formProps={formProps}
                    />                    
                );
            case FieldTypes.FIELD_END_SECTION:
                return <div className={`field-text pd-b-15 col-12`}/>;
            case FieldTypes.FIELD_TEXT:
                return <TextField key={field.id + (field.use_mapped_value && '-mapped')} {...field} showIsRequired={showIsRequired} disabled={fieldIsDisabled} tooltipOverlay={tooltipOverlay} appendEls={appendEl} seleniumId={seFieldId} setFieldValue={setFieldValue} initialValue={initialValue} />;
            case FieldTypes.FIELD_NUMBER:
                return <NumberField key={field.id + (field.use_mapped_value && '-mapped')} {...field} showIsRequired={showIsRequired} submitOnChange={true} disabled={fieldIsDisabled} tooltipOverlay={tooltipOverlay} appendEls={appendEl} seleniumId={seFieldId} setFieldValue={setFieldValue} initialValue={initialValue} />;
            case FieldTypes.FIELD_DATE:
                return <DateField key={dateFieldKey + (field.use_mapped_value && '-mapped')} {...field} showIsRequired={showIsRequired} disabled={fieldIsDisabled} tooltipOverlay={tooltipOverlay} appendEls={appendEl} seleniumId={seFieldId} setFieldValue={setFieldValue} initialValue={initialValue} />;
            case FieldTypes.FIELD_YES_NO:
                return <YesNoNullField key={field.id + (field.use_mapped_value && '-mapped')} {...field} showIsRequired={showIsRequired} submitOnChange={true} disabled={fieldIsDisabled} tooltipOverlay={tooltipOverlay} appendEls={appendEl} seleniumId={seFieldId} setFieldValue={setFieldValue} initialValue={initialValue} rerenderOnChange={rerenderOnChange} />;
            case FieldTypes.FIELD_SELECT:
                return (
                    <SelectField
                        key={field.id + (field.use_mapped_value && '-mapped')}
                        {...field}
                        value={fieldValue}
                        onChange={async (id, data) => {
                            await setFieldValue(id, data);
                            submitForm();
                            if (field.onChangeExt) {
                                field.onChangeExt(field.id, data)
                            }
                        }}
                        onBlur={setFieldTouched}
                        showIsRequired={showIsRequired}
                        disabled={fieldIsDisabled}
                        isClearable={true}
                        tooltipOverlay={tooltipOverlay}
                        appendEls={appendEl}
                        seleniumId={seFieldId}
                        setFieldValue={setFieldValue} 
                        initialValue={initialValue}
                        backgroundColor={selectBackgroundColor}
                    />
                );
            case FieldTypes.FIELD_MULTI_SELECT:
                return (
                    <SelectField
                        key={field.id + (field.use_mapped_value && '-mapped')}
                        {...field}
                        multi={true}
                        value={fieldValue}
                        onChange={async (id, data) => {
                            await setFieldValue(id, data);
                            submitForm();
                            if (field.onChangeExt) {
                                field.onChangeExt(field.id, data)
                            }
                        }}
                        onBlur={setFieldTouched}
                        showIsRequired={showIsRequired}
                        disabled={fieldIsDisabled}
                        isClearable={true}
                        tooltipOverlay={tooltipOverlay}
                        appendEls={appendEl}
                        seleniumId={seFieldId}
                        setFieldValue={setFieldValue} 
                        initialValue={initialValue}
                        backgroundColor={selectBackgroundColor}
                    />
                );
            case FieldTypes.FIELD_CURRENCY:
                return (
                    <SelectField
                        key={field.id + (field.use_mapped_value && '-mapped')}
                        {...field}
                        value={fieldValue}
                        fieldValues={getCurrencyOptions()}
                        onChange={async (id, data) => {
                            await setFieldValue(id, data);
                            submitForm();
                            if (field.onChangeExt) {
                                field.onChangeExt(field.id, data)
                            }
                        }}
                        onBlur={setFieldTouched}
                        showIsRequired={showIsRequired}
                        disabled={fieldIsDisabled}
                        isClearable={true}
                        tooltipOverlay={tooltipOverlay}
                        appendEls={appendEl}
                        seleniumId={seFieldId}
                        setFieldValue={setFieldValue} 
                        initialValue={initialValue}
                        backgroundColor={selectBackgroundColor}
                    />
                );
            case FieldTypes.FIELD_MULTI_TEXT:
                return <MultiTextField 
                    key={field.id + (field.use_mapped_value && '-mapped')} 
                    multi={true} 
                    {...field} 
                    value={fieldValue} 
                    showIsRequired={showIsRequired} 
                    disabled={fieldIsDisabled} 
                    tooltipOverlay={tooltipOverlay}
                    appendEls={appendEl}
                    seleniumId={seFieldId}
                    setFieldValue={setFieldValue} 
                    initialValue={initialValue}
                />;

            case FieldTypes.FIELD_ATTACHMENT:
                return <AttachmentField 
                    key={field.id + (field.use_mapped_value && '-mapped')} 
                    {...field} value={fieldValue} 
                    showIsRequired={showIsRequired} 
                    disabled={fieldIsDisabled} 
                    tooltipOverlay={tooltipOverlay} 
                    appendEls={appendEl} 
                    seleniumId={seFieldId} 
                    setFieldValue={setFieldValue} 
                    initialValue={initialValue} 
                />;

            case FieldTypes.FIELD_CARGO:
                return (
                    <SectionField
                        key={field.id + (field.use_mapped_value && '-mapped')}
                        {...field}
                        isCargo={true}
                        renderChild={(id) => {
                            const field = fields.find(f => f.id === id);
                            field.onChangeExt = onChangeExt;
                            return this.getPreviewComponent(field, formProps, fields, comparators, parentId, fields.find(f => f.id === id).is_required, allPrefilledFields, showPrefilledFields)
                        }}
                        disabled={disabled}
                        seleniumId={seFieldId}
                        setFieldValue={setFieldValue} 
                        initialValue={initialValue}
                    />
                );

            case FieldTypes.FIELD_NONE:
            default:
                return null;
        }
    };

    render() {
        const { field, formProps, fields, comparators, parentId = null, showPrefilledFields } = this.props;
        return this.getPreviewComponent(field, formProps, fields, comparators, parentId, field.is_required, [], showPrefilledFields);
    }
}

export default FieldPreview;
