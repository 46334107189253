import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import moment from 'moment';
import { Button, Popover, Menu, MenuItem, MenuDivider, Icon } from '@blueprintjs/core';
import Dropzone from 'react-dropzone';
import { history } from '../../../configuration/history';
import Page from '../../../components/page';
import Table from '../../../components/table';
import { getModels, createModel, importModel, exportModel, cloneModel, deleteModel, persistModelChanges } from '../../../reducers/models';
import { getProfile } from '../../../reducers/identity';
import { createTransaction } from '../../../reducers/transaction';
import Model from '../../../models/model';
import QueryBuilder from './queryBuilder';
import { getIndices } from '../../../reducers/indices';
import { setCargoCategoriesTableOptions, setCargoCategoriesTableQueryValues, setCargoCategoriesTableQueryState } from 'reducers/sessionSettings';
import ConfirmModal from 'components/modals/confirm';
import '../models.scss';
import { forfeitSharedEntity } from 'reducers/sharing';
import { tooltipWrapper } from 'utilities/common'

class ModelList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deleteTargetMultiIds: [],
            tableRenderCount: 1,
        }
    }

    async componentDidMount() {
        const { company, getProfile, getIndices } = this.props;
        getIndices();
        if (!company) {
            getProfile();
        }
    }

    toggleDeleteTarget = (id) => {
        this.setState(prevState => {
            const targetIds = prevState.deleteTargetMultiIds;
            const index = targetIds.indexOf(id);
            if (index === -1) {
                targetIds.push(id);
            } else {
                targetIds.splice(index, 1);
            }
            return { deleteTargetMultiIds: targetIds };
        })
    }

    eventAllSelectorEl = () => {
        const { models, company } = this.props;
        const { deleteTargetMultiIds, isDeletingMulti } = this.state;
        const deletableModels = models.filter(m => m.company_id === company.id);
        const allEventsSelected = deleteTargetMultiIds.length === deletableModels.length;
        if (isDeletingMulti) {
            return ( 
                <div className="actions-wrapper" style={{height: '100%'}}>
                    <input 
                        type="checkbox" 
                        checked={allEventsSelected}  
                        onChange={() => this.setState(prevState => {
                            let allSelectedIds = prevState.deleteTargetMultiIds;
                            if (allSelectedIds.length === deletableModels.length) {
                                return { deleteTargetMultiIds: [] };
                            } else {
                                return { deleteTargetMultiIds: deletableModels.map(t => t.id) };
                            }
                        })}
                    />
                </div>
            );
        } else {
            return null;
        }
    }

    getColumns = () => {
        const { exportModel, cloneModel, persistModelChanges, getModels, company } = this.props;
        return [
            {
                headerClassName: 'd-sm-block col-2 d-none',
                Header: this.eventAllSelectorEl(),
                className: 'd-xs-block text-center col-2',
                sortable: false,
                resizable: false,
                width: 34,
                id: 'actions',
                accessor: d => {
                    return this.state.isDeletingMulti ?
                    (
                        <div className="actions-wrapper">
                            <input 
                                type="checkbox" 
                                checked={this.state.deleteTargetMultiIds && this.state.deleteTargetMultiIds.indexOf(d.id) > -1}  
                                onChange={() => this.toggleDeleteTarget(d.id)}
                                disabled={d.company_id !== company.id}
                            />
                        </div>
                    )
                    :
                    tooltipWrapper(
                        d.is_locked,
                        <FormattedMessage id='dotTemplateInUse' />,
                        <div className={'actions-wrapper' + (d.is_locked ? ' disabled' : '')}>
                            <Popover
                                disabled={d.is_locked}
                                content={
                                    <Menu>
                                        <MenuItem icon="share" text={<FormattedMessage id="common.open" />} onClick={() => history.push(`/objects/object-templates/${d.id}` + window.location.search)} />
                                        <MenuDivider />
                                        {/* <MenuItem
                                            icon="label"
                                            text={<FormattedMessage id="models.configure.documents" />}
                                            onClick={() => history.push(`/objects/object-templates/${d.id}/document-templates` + window.location.search)}
                                        />
                                        <MenuDivider /> */}
                                        <MenuItem icon="export" text={<FormattedMessage id="common.export" />} onClick={() => exportModel(d)} />
                                        <MenuItem
                                            icon="duplicate"
                                            text={<FormattedMessage id="common.clone" />}
                                            onClick={() => cloneModel(d, c => history.push(`/objects/object-templates/${c.id}` + window.location.search))}
                                        />
                                        { (d.status_id !== 4) && <MenuDivider /> }
                                        {
                                            d.company_id === company.id ?
                                                (d.status_id !== 4) && <>
                                                    <MenuItem
                                                        icon="compressed"
                                                        text={d.is_locked ? <FormattedMessage id='archiveInUse'/> : <FormattedMessage id="common.archive" />}
                                                        disabled={d.is_locked}
                                                        onClick={() =>
                                                            !d.is_locked && this.setState({ actionTargetId: d.id, actionTargetTitle: d.title || '[Untitled]', confirmModalOpen: true, targetStatus: 3 })
                                                        }
                                                    />
                                                    <MenuItem
                                                        icon="cross"
                                                        text={d.is_locked ? <FormattedMessage id="deleteInUse" /> : <FormattedMessage id='common.delete'/>}
                                                        disabled={d.is_locked}
                                                        onClick={() => {
                                                            !d.is_locked && this.setState({ actionTargetId: d.id, actionTargetTitle: d.title || '[Untitled]', confirmModalOpen: true, targetStatus: 4 })
                                                        }}
                                                    />
                                                </>
                                                :
                                                (d.status_id !== 4) && <MenuItem
                                                    icon="cross"
                                                    text={<FormattedMessage id="forfeitAccess" />}
                                                    onClick={() => {
                                                        this.setState({ actionTargetId: d.id, actionTargetTitle: d.title || '[Untitled]', confirmUnshareModalOpen: true })
                                                    }}
                                                />

                                        }
                                    </Menu>
                                }
                            >
                                <Button minimal={true} small={true} icon={<Icon iconSize={12} icon="menu" />} />
                            </Popover>
                        </div>
                    )
                }
            },
            {
                headerClassName: 'd-sm-block col d-none',
                className: 'd-sm-block col d-none tx-center id-column',
                Header: <FormattedMessage id="common.id" />,
                width: 100,
                id: 'id',
                accessor: d => <Link to={{ pathname: `/objects/object-templates/${d.id}`, search: window.location.search }}>{d.hash_id}</Link>
            },
            {
                headerClassName: 'd-sm-block d-none',
                className: 'd-xs-block',
                Header: <FormattedMessage id="common.title" />,
                id: 'title',
                accessor: d => <Link to={{ pathname: `/objects/object-templates/${d.id}`, search: window.location.search }}>{(d.title === null || d.title === '') ? '[No Title]' : d.title}</Link>
            },
            {
                headerClassName: 'd-block col-2',
                className: 'd-block col-2',
                Header: <FormattedMessage id="common.createdBy" />,
                id: 'created_by_name',
                accessor: d => d.created_by_name
            },
            {
                headerClassName: 'd-block col-2',
                className: 'd-block col-2',
                Header: <FormattedMessage id="common.createdOn" />,
                id: 'created_on',
                accessor: d => moment(d.created_on).format('DD-MM-YYYY')
            },
            {
                headerClassName: 'd-sm-block d-none col-1',
                className: 'd-xs-block col-1 tx-center',
                Header: <FormattedMessage id="common.status" />,
                id: 'status_name',
                accessor: d => <FormattedMessage id={d.status_name} defaultMessage={d.status_name} />
            },
            {
                headerClassName: 'd-sm-block d-none col-1',
                className: 'd-xs-block col-1 tx-center',
                Header: <FormattedMessage id="common.isInUse" />,
                sortable: false,
                id: 'is_used',
                accessor: d => (!!d.is_used ? <FormattedMessage id="common.yes" /> : <FormattedMessage id="common.no" />)
            }
        ];
    };
    
    deleteMultiButtonGroup = () => {
        if (this.state.isDeletingMulti) {
            const deleteButton = this.state.deleteTargetMultiIds && this.state.deleteTargetMultiIds.length > 0 ?
                (
                    <span 
                        className="btn btn-outline-info" 
                        onClick={() => {
                            this.setState({ confirmMultiModalOpen: true });
                        }
                    }>
                        <FormattedMessage id="deleteSelectedCargoCategories" values={{count: this.state.deleteTargetMultiIds.length}} />
                    </span>
                )
                :
                (
                    <span 
                        className="btn btn-outline-info disabled" 
                        disabled={true}
                        onClick={() => null
                    }>
                        <FormattedMessage id="noCargoCategoriesSelected" />
                    </span>
                )
            return (
                <React.Fragment>
                    { deleteButton }
                    <span 
                        className="btn btn-outline-info" 
                        onClick={() => {
                            this.setState({ isDeletingMulti: false, confirmMultiModalOpen: false, deleteTargetMultiIds: [] });
                        }
                    }>
                        <FormattedMessage id="cancel" />
                    </span>
                </React.Fragment>
            );
        } else {
            return (
                <span 
                    className="btn btn-outline-info" 
                    onClick={() => {
                        this.setState({ isDeletingMulti: true, deleteTargetMultiIds: [] });
                    }
                }>
                    <FormattedMessage id="deleteMultipleCargoCategories" />
                </span>
            );
        }
    }

    renderActions = () => {
        const { createModel, company, importModel, getModels } = this.props;
        return (
            <div className="btn-group pd-t-10">
                <span 
                    className="btn btn-outline-info" 
                    onClick={() => {
                        let model = Model.forCompany(company.id);
                        model.title = 'New DoT Template'
                        model.model_type_name = 'cargo category';
                        createModel(model, m => history.push(`/objects/object-templates/${m.id}` + window.location.search))
                    }
                }>
                    <FormattedMessage id="page.cargoCategories.button.new" />
                </span>
                <span className="btn btn-outline-info">
                    <Dropzone
                        accept={'.dorml'}
                        onDrop={files =>
                            importModel(null, files, m => {
                                getModels({ model_type: 2 });
                                if (m.model_type_name === 'event template') {
                                    history.push(`/events/event-templates/${m.id}` + window.location.search);
                                } else {
                                    history.push(`/objects/object-templates/${m.id}` + window.location.search);
                                }
                            })
                        }
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()} className="upload-wrapper">
                                <FormattedMessage id="common.import" />
                                <input {...getInputProps()} />
                            </div>
                        )}
                    </Dropzone>
                </span>
                <span className="btn btn-outline-info" onClick={() => history.push(`/objects/object-templates/indices` + window.location.search)}>
                    <FormattedMessage id="models.configure.indices" />
                </span>
                { this.deleteMultiButtonGroup() }
            </div>
        );
    };

    loadData = (options, onComplete) => {
        const { getModels, setCargoCategoriesTableOptions = () => null, cargoCategoriesTableOptions } = this.props;
        getModels({ status_ids: [1, 2], model_type: 2, ...options }, (results) => {
            if (results === undefined) {
                onComplete([]);
                return;
            }
            if (results.total > 0 && results.data.length === 0) {
                // navigate to previous page and force table to re-render
                const newOptions = Object.assign({}, cargoCategoriesTableOptions);
                newOptions.page = newOptions.page - 1;
                setCargoCategoriesTableOptions(newOptions).then(() => {
                    this.setState({ tableRenderCount: this.state.tableRenderCount + 1 });
                })
            } else {
                onComplete(results);
            }
        });
    };

    render() {
        const { models, total, company, persistModelChanges, forfeitSharedEntity, intl: { formatMessage }, } = this.props;
        if (!company) return null;

        const { deleteTargetMultiIds } = this.state;
        const targetedModels = models.filter(m => deleteTargetMultiIds && deleteTargetMultiIds.includes(m.id));
        let multiDeleteWillUnshare = false;
        if (targetedModels.some(m => m.company_id !== company.id)) {
            multiDeleteWillUnshare = true;
        }

        return (
            <Page {...ModelList.meta} actions={this.renderActions()} parents={this.props.parents}>
                <Table
                    key={'tb-' + this.state.tableRenderCount}
                    columns={this.getColumns()}
                    data={models}
                    total={total}
                    queryBuilder={setFilters => 
                        <QueryBuilder 
                            onFiltersSet={setFilters} 
                            stateSetter={this.props.setCargoCategoriesTableQueryState}
                            valuesSetter={this.props.setCargoCategoriesTableQueryValues}
                            initialState={this.props.cargoCategoriesTableQueryState}
                            initialValues={this.props.cargoCategoriesTableQueryValues}
                        />
                    }
                    dataLoader={(options, onComplete) => {
                        this.loadData(options, onComplete);
                        this.setState({ options: options });
                    }}
                    optionsSetter={this.props.setCargoCategoriesTableOptions}
                    initialOptions={this.props.cargoCategoriesTableOptions}
                />
                <ConfirmModal
                    open={this.state.confirmModalOpen}
                    setOpen={(o) => this.setState({ confirmModalOpen: o })}
                    onConfirm={() => {
                        const { options = { status_ids: [1, 2], model_type: 1 }, actionTargetId, targetStatus } = this.state;
                        const targetModel = models.find(m => m.id === actionTargetId);
                        persistModelChanges({ ...targetModel, title: targetModel.title ? targetModel.title : "", status_id: targetStatus }, () => this.loadData(options, () => null))
                    }}
                    title={<FormattedMessage id='common.confirmVerb' values={{ verb: this.state.targetStatus === 4 ? <FormattedMessage id='common.delete' /> : <FormattedMessage id='common.archive' />}} />}
                    body={<FormattedHTMLMessage id='confirmCargoCategoryAction' values={{ title: this.state.actionTargetTitle, action: this.state.targetStatus === 4 ? formatMessage({ id: 'permanentlyDelete' }) : formatMessage({ id: 'archive' }) }} tagName="span" />}
                />
                <ConfirmModal
                    open={this.state.confirmMultiModalOpen}
                    setOpen={(o) => this.setState({ confirmMultiModalOpen: o })}
                    onConfirm={() => {
                        const { options = { status_ids: [1, 2], model_type: 1 }, deleteTargetMultiIds } = this.state;
                        const promises = [];
                        for (const templateId of deleteTargetMultiIds) {
                            promises.push(new Promise(resolve => {
                                const targetModel = models.find(m => m.id === templateId);
                                if (targetModel.company_id === company.id) {
                                    persistModelChanges({ ...targetModel, title: targetModel.title ? targetModel.title : "", status_id: 4 }, resolve)
                                } else {
                                    forfeitSharedEntity({ entity_type: 'user_model', id: templateId }, resolve)
                                }
                            }));
                        }
                        Promise.all(promises).then(() => {
                            this.loadData(options, () => null);
                            this.setState({ isDeletingMulti: false });
                        })

                    }}
                    title={<FormattedMessage id='common.confirmVerb' values={{ verb: multiDeleteWillUnshare ? formatMessage({ id: 'deleteUnshareSelected' }) : formatMessage({ id: 'deleteSelected' })}} />}
                    body={multiDeleteWillUnshare ? <FormattedHTMLMessage tagName='div' id='confirmCargoCategoryDeleteAndUnshareMultiple' /> : <FormattedMessage id='confirmCargoCategoryDeleteMultiple' />}
                />
                <ConfirmModal
                    open={this.state.confirmUnshareModalOpen}
                    setOpen={(o) => this.setState({ confirmUnshareModalOpen: o })}
                    onConfirm={() => {
                        const { options = { status_ids: [1, 2], model_type: 1 }, actionTargetId } = this.state;
                        forfeitSharedEntity({ entity_type: 'user_model', id: actionTargetId }, () =>
                            this.loadData(options, () => null)
                        );
                    }}
                    title={<FormattedMessage id='common.confirmVerb' values={{ verb: formatMessage({ id: 'unshare' })}} />}
                    body={<FormattedHTMLMessage id='confirmUnshareDotTemplate' values={{ title: this.state.actionTargetTitle }} tagName="span" />}
                />
            </Page>
        );
    }
}

ModelList.meta = {
    title: <FormattedMessage id="page.cargoCategories.title" />,
    subtitle: <FormattedMessage id="page.cargoCategories.subtitle" />,
    routes: ['/objects/object-templates'],
    icon: 'ion-clipboard',
    menus: { main: true, user: false },
    order: 30
};

ModelList.defaultProps = {
    models: [],
    total: 0
};

const mapStateToProps = state => ({
    models: state.models.cargoCategoryList,
    company: state.identity.company,
    total: state.models.cargoCategoryTotal,
    cargoCategoriesTableOptions: state.sessionSettings.cargoCategoriesTableOptions,
    cargoCategoriesTableQueryValues:  state.sessionSettings.cargoCategoriesTableQueryValues,
    cargoCategoriesTableQueryState:  state.sessionSettings.cargoCategoriesTableQueryState,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getProfile,
            getModels,
            createModel,
            createTransaction,
            persistModelChanges,
            importModel,
            exportModel,
            cloneModel,
            deleteModel,
            getIndices,
            setCargoCategoriesTableOptions,
            setCargoCategoriesTableQueryState,
            setCargoCategoriesTableQueryValues,
            forfeitSharedEntity,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(ModelList));
