import { defineMessages } from 'react-intl';

export const messages = defineMessages({
    default_error: { id: 'error.default' },
    invalid_credentials: { id: 'error.invalidCredentials' }
});

export const findMessage = (prop, defaultMessage) => {
    return messages[prop] || defaultMessage;
};
