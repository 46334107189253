import React from 'react';
import { Field } from 'formik';
import YesNoNull from './uncontrolledComponents/yesNoNull'

class YesNoNullField extends React.Component {
    render() {
        const {
            name,
            placeholder,
            submitOnChange,
            disabled = false,
            onChangeExt = () => null,
            tooltipOverlay,
            seleniumId,
        } = this.props;

        return (
            <Field
                name={name}
                render={fieldProps => {
                    const {
                        field: { value },
                        form: { setFieldValue, submitForm }
                    } = fieldProps;
                    return (
                        <YesNoNull 
                            placeholder={placeholder} 
                            submitOnChange={submitOnChange} 
                            name={name} 
                            disabled={disabled} 
                            value={value}
                            onChange={async value => {
                                if (disabled) return;
                                await setFieldValue(name, value);
                                if (submitOnChange) {
                                    submitForm();
                                }
                                onChangeExt(name, value)
                            }}
                            tooltipOverlay={tooltipOverlay}
                            seleniumId={seleniumId}
                        />
                    );
                }}
            />
        );
    }
}

export default YesNoNullField;
