import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import ReactModal from 'react-modal';
import { store } from './configuration/store';
import { history } from './configuration/history';
import { initializeIdentity } from './utilities/identity';
import { StripeProvider } from 'react-stripe-elements';
import Routes from './routes';
import RootErrorBoundary from './RootErrorBoundary';

import 'simplebar/dist/simplebar.min.css';
import 'rc-tooltip/assets/bootstrap.css';
import './styles/root.scss';

initializeIdentity();
const Application = withRouter(Routes);

ReactModal.setAppElement('#root');



ReactDOM.render(
    <Provider store={store}>
        <RootErrorBoundary>
            <ConnectedRouter history={history}>
                <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY}>
                    <Application />
                </StripeProvider>
            </ConnectedRouter>
        </RootErrorBoundary>
    </Provider>,
    document.getElementById('root')
);
