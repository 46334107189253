import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getProfile } from 'reducers/identity';
import { getModels } from 'reducers/models';
import { setMenuVisibility } from 'reducers/menus';
import { getDocumentTemplate, updateDocumentTemplate, getDocumentSample } from 'reducers/document';
import 'pages/models/models.scss';
import 'components/fields/fields.scss';
import EdiText from 'react-editext'
import FieldPickerModal from 'pages/documentTemplates/components/fieldPickerModal';
import { DocumentEditorContainerComponent, Toolbar } from '@syncfusion/ej2-react-documenteditor';
import { Notifications } from 'components/notifications';
import { FormattedMessage } from 'react-intl';
import { debounce } from 'utilities/common';
import LoadingOverlay from 'react-loading-overlay';
import { addFieldReferenceToGroup, removeFieldReferenceFromGroup, setFieldGroupReferences, getDocumentReferenceData } from 'reducers/documents';
import { getWordFieldReferences, addMetadataToFieldReferences, parseStringForRefs } from 'utilities/common';
import FieldGroupPickerModal from './fieldGroupPickerModal';
import { Popover, Menu, MenuItem, MenuDivider } from '@blueprintjs/core';
import ConfirmModal from 'components/modals/confirm';
import FieldRefCopyPasteModal from 'components/modals/fieldReferenceCopyPasteModal';

DocumentEditorContainerComponent.Inject(Toolbar);

class WordDocumentTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            previewData: "",
            loadingPreview: false,
            contentChanged: false,
            fauxSaving: false,
            fieldRefGroups: [],
            fieldRefGroupRefs: [],
            fieldRefs: [],
            groupModalRenderCount: 0,
            doc_file_data: null,
            fieldRefModalRenderCount: 0,
            userSelection: '',
        };

        this.persistChanges = this.persistChanges.bind(this);
    }

    componentDidMount() {
        const { match, getDocumentTemplate, getModels, getDocumentReferenceData, template } = this.props;
        this.setState({templateTitle: template.title})
        getDocumentTemplate(match.params.id, (dt) => {
            this.setState({templateTitle: dt.title})
            let fieldReferences = [];
            if (this.documenteditor){
                // If we're opening an existing doc template, load it; otherwise save the new blank one
                if (dt.file_data) {
                    this.documenteditor.documentEditor.open(dt.file_data);
                    const parsedTemplate = JSON.parse(dt.file_data);
                    this.setState({ doc_file_data: dt.file_data });
                    fieldReferences = getWordFieldReferences(parsedTemplate);
                } else {
                    this.persistChanges();
                }
            }
            getDocumentReferenceData({ field_references: fieldReferences, document_template_id: match.params.id }, (withRefData) => {
                const fieldReferencesWithData = addMetadataToFieldReferences(fieldReferences, withRefData);
                this.setState({ fieldRefGroups: withRefData.ref_groups, fieldRefGroupRefs: withRefData.ref_group_references, fieldRefs: fieldReferencesWithData });
            }); 
        });
        
        window.addEventListener('beforeunload', (event) => {
            if (this.state.contentChanged ) {
                event.preventDefault();
                event.returnValue = 'You have unfinished changes!';
            }
        });

        // this.updateInterval = setInterval(async () => {
        //     await this.persistChanges();
        // }, 2000);
        getModels({model_type: 2}, (cargoCategories) => {
            this.setState({cargoCategories: cargoCategories.data});
        }, false);
    }

    fauxSave = () => {
        const { setRootLoadingState } = this.props;
        setRootLoadingState(true);
        this.setState({fauxSaving: true, contentChanged: true}, () => {
            this.persistChanges();
        })
    }

    async componentWillUnmount() {
        await this.persistChanges();
        window.onbeforeunload = null;
        clearInterval(this.updateInterval);
    }

    persistChanges = async () => {
        const { template, updateDocumentTemplate, getDocumentTemplate, setFieldGroupReferences } = this.props;
        const documentEditor = this.documenteditor.documentEditor;
        if (this.state.contentChanged) {
            try {
                await documentEditor.saveAsBlob('Sfdt').then(async (exportedDocument) => {
                    await documentEditor.saveAsBlob('Docx').then((exportedWordDocument) => {
                        const sfdtReader = new FileReader();
                        sfdtReader.addEventListener('loadend', () => {
                            const base64Text = ";base64,";
                            const base64Sfdt = sfdtReader.result.substring(sfdtReader.result.indexOf(base64Text) + base64Text.length);   
                            const sfdt = atob(base64Sfdt);
                            template.file_data = sfdt;
                            this.setState({ doc_file_data: sfdt });
                            template.title = this.state.templateTitle;
                            const wordReader = new FileReader();
                            wordReader.onloadend = () => {
                                const base64Text = ";base64,";
                                const base64data = wordReader.result.substring(wordReader.result.indexOf(base64Text) + base64Text.length);                
                                template.server_file_data = base64data;
                                updateDocumentTemplate(template, () => {
                                    if (this.state.fauxSaving) {
                                        Notifications.success(<FormattedMessage id="templatesavedsuccessfully" />);
                                    }
                                    getDocumentTemplate(template.id, () => {
                                        this.setState({contentChanged: false, fauxSaving: false});
                                        this.props.setRootLoadingState(false);
                                    });
                                    const parsedTemplate = JSON.parse(sfdt);
                                    const fieldReferences = getWordFieldReferences(parsedTemplate);
                                    // filter for any field references we can no longer find in the parsed document
                                    const potentiallyAlteredRefs = this.state.fieldRefGroupRefs.filter(sRef => {
                                        return !fieldReferences.find(fRef => fRef.literal === sRef.field_reference_literal);
                                    });
                                    const reloadRefs = () => {
                                        getDocumentReferenceData({ field_references: fieldReferences, document_template_id: template.id }, (dt) => {
                                            const fieldReferencesWithData = addMetadataToFieldReferences(fieldReferences, dt)
                                            this.setState({ fieldRefGroups: dt.ref_groups, fieldRefGroupRefs: dt.ref_group_references, fieldRefs: fieldReferencesWithData, fieldGroupModalVisible: false, fieldRefGroupsLoading: false });
                                        });     
                                    };
                                    if (potentiallyAlteredRefs.length > 0) {
                                        // initialize the array with all the group references that were not altered
                                        const group_references = this.state.fieldRefGroupRefs
                                            .filter(sRef => {
                                                return fieldReferences.find(fRef => fRef.literal === sRef.field_reference_literal);
                                            })
                                            .map(unalteredRef => ({
                                                name: unalteredRef.name,
                                                field_reference_literal: unalteredRef.field_reference_literal,
                                            }));

                                        // make sure any altered field references are unambiguously identifiable as the one previously in a group
                                        for (const alteredRef of potentiallyAlteredRefs) {
                                            const matchingRefs = fieldReferences.filter(fRef => alteredRef.field_reference_literal.indexOf(`${fRef.token}`) > -1);
                                            // If we find one and only one field ref with a matching token, we'll assume the user modified 
                                            // the label and move the updated field reference back into the group it belonged to
                                            if (matchingRefs.length === 1) {
                                                const updatedRef = matchingRefs[0];
                                                group_references.push({
                                                    name: alteredRef.name,
                                                    field_reference_literal: updatedRef.literal,
                                                });
                                            }
                                        }
                                        // update field reference groups and then refresh
                                        setFieldGroupReferences({ group_references, document_template_id: template.id }, reloadRefs, reloadRefs);
                                    } else {
                                        reloadRefs();
                                    }
                                }, () => {
                                    this.props.setRootLoadingState(false);
                                    this.setState({ fauxSaving: false });
                                });
                            }
                            wordReader.readAsDataURL(exportedWordDocument); 
                        });
                        sfdtReader.readAsDataURL(exportedDocument);
                    });
                });
            }
            catch (err) {
                console.error('Error saving template: ', err);
                window.onbeforeunload = null;
            }
        }
    };

    debouncedPersist = debounce(this.persistChanges, 500);

    onDocumentChanged = (e) => {
        if (this.props.readOnly) return;

        this.props.setRootLoadingState(true);
        this.setState({ contentChanged: true }, () => {
            this.debouncedPersist();
        })
    }

    onSaveTitle = (val) => {
        this.setState({ templateTitle: val, contentChanged: true }, () => {
            this.onDocumentChanged();
        });
    }

    onFieldSelect(field, ignoreDuplicates = false) {
        const { addFieldReferenceToGroup, template, getDocumentReferenceData } = this.props;
        
        let proxy = this;
        let lotTargetText = '';
        if (field.lotTarget) {
            if (field.lotTarget === 'Quantity') {
                lotTargetText = '.1';
            } else if (field.lotTarget === 'DoT Unit') {
                lotTargetText = '.2';
            } else {
                if (field.lotTarget.indexOf('l') > -1) {
                    lotTargetText = '.' + field.lotTarget;
                } else {
                    lotTargetText = '.f' + field.lotTarget;
                }
            }
        }
        const refLiteral = `{{${field.title} [$${field.type}.${field.id}${lotTargetText}]}}`;

        if (!ignoreDuplicates) {
            const { fieldRefs } = this.state;
            const exactFieldRefMatches = fieldRefs.filter(ref => ref.literal === refLiteral);
            if (exactFieldRefMatches.length > 0) {
                this.setState({ fieldReferenceToConfirm: field, confirmFieldRefModalOpen: true, confirmFieldRefModalMessage: <FormattedMessage id='duplicateFieldRefWarning' /> });
                return;
            }
            const fieldRefsWithMatchingLabels = fieldRefs.filter(ref => ref.label.trim() === field.title.trim());
            if (fieldRefsWithMatchingLabels.length > 0) {
                this.setState({ fieldReferenceToConfirm: field, confirmFieldRefModalOpen: true, confirmFieldRefModalMessage: <FormattedMessage id='duplicateFieldRefLabelWarning' /> });
                return;
            }
        }

        this.setState(prevState => ({ fieldModalVisible: false, fieldRefModalRenderCount: prevState.fieldRefModalRenderCount + 1 }));

        if (field.group) {
            this.setState(prevState => {
                const fieldRefGroupRefs = prevState.fieldRefGroupRefs;
                fieldRefGroupRefs.push({ document_template_id: template.id, name: field.group, field_reference_literal: refLiteral, compatible_field_ids: [], compatible_model_ids: [] })
                return { fieldRefGroupRefs };
            })
            addFieldReferenceToGroup({
                document_template_id: template.id,
                name: field.group,
                field_reference_literal: refLiteral,
                all_literals: this.state.fieldRefs.map(r => r.literal)
            });
        }
        proxy.documenteditor.documentEditor.editor.insertText(refLiteral);
    }

    render() {
        const { model, template, fields, readOnly } = this.props;
        if (!template) return null;
        const { fieldRefGroupsLoading } = this.state;

        return (
            <React.Fragment>
                <FieldPickerModal 
                    key={'field-ref-picker-' + this.state.fieldRefModalRenderCount}
                    showModal={this.state.fieldModalVisible}
                    setModalVisibility={(val) => this.setState({ fieldModalVisible: val})}
                    cargoCategories={this.state.cargoCategories} 
                    onFieldSelect={fieldInfo => this.onFieldSelect(fieldInfo)}
                    docTemplateId={template.id}
                    fieldRefGroups={this.state.fieldRefGroups}
                    fieldRefGroupRefs={this.state.fieldRefGroupRefs}
                    onGroupAdded={(newGroup) => this.setState(prevState => {
                        const groups = prevState.fieldRefGroups;
                        groups.push(newGroup);
                        return { fieldRefGroups: groups };
                    })}
                    stayOpenOnSelect={true}
                />
                <FieldGroupPickerModal 
                    key={JSON.stringify(this.state.fieldRefs) + this.state.groupModalRenderCount}
                    showModal={this.state.fieldGroupModalVisible}
                    setModalVisibility={(val) => this.setState({ fieldGroupModalVisible: val})}
                    template={template}
                    fieldRefs={this.state.fieldRefs}
                    fieldRefGroups={this.state.fieldRefGroups}
                    fieldRefGroupRefs={this.state.fieldRefGroupRefs}
                    onFieldRefGroupsSet={groups => {
                        const { template, setFieldGroupReferences, getDocumentReferenceData } = this.props;
                        const group_references = [];
                        const parsedTemplate = JSON.parse(this.state.doc_file_data);
                        const fieldReferences = getWordFieldReferences(parsedTemplate);
                        for (const groupData of groups) {
                            group_references.push({
                                name: groupData.group,
                                field_reference_literal: groupData.literal,
                            });
                        }
                        const reloadRefs = () => {
                            getDocumentReferenceData({ field_references: fieldReferences, document_template_id: template.id }, (dt) => {
                                const fieldReferencesWithData = addMetadataToFieldReferences(fieldReferences, dt)
                                this.setState({ fieldRefGroups: dt.ref_groups, fieldRefGroupRefs: dt.ref_group_references, fieldRefs: fieldReferencesWithData, fieldGroupModalVisible: false, fieldRefGroupsLoading: false });
                            });     
                        };
                        setFieldGroupReferences({ group_references, document_template_id: template.id }, reloadRefs, reloadRefs);
                    }}
                    onDeleteConfirm={(callback) => {
                        const { getDocumentReferenceData } = this.props;

                        const parsedTemplate = JSON.parse(this.state.doc_file_data);
                        const fieldReferences = getWordFieldReferences(parsedTemplate);
                        getDocumentReferenceData({ field_references: fieldReferences, document_template_id: template.id }, (dt) => {
                            this.setState({ fieldRefGroups: dt.ref_groups, fieldRefGroupRefs: dt.ref_group_references, groupModalRenderCount: this.state.groupModalRenderCount + 1 }, callback);
                        });  
                    }}
                    onGroupAdded={(newGroup) => this.setState(prevState => {
                        const groups = prevState.fieldRefGroups;
                        groups.push(newGroup);
                        return { fieldRefGroups: groups };
                    })}
                    onRefGroupSet={(newGroupRef) => this.setState(prevState => {
                        const groupRefs = prevState.fieldRefGroupRefs;
                        groupRefs.push(newGroupRef);
                        return { fieldRefGroupRefs: groupRefs };
                    })}
                    view={this.state.refGroupView}
                />
                <ConfirmModal
                    key={'field-ref-confirm-' + this.state.fieldRefModalRenderCount}
                    open={this.state.confirmFieldRefModalOpen}
                    setOpen={(o) => this.setState({ confirmFieldRefModalOpen: o })}
                    onConfirm={() => {
                        this.onFieldSelect(this.state.fieldReferenceToConfirm, true);
                        this.setState(prevState => ({ fieldModalVisible: false, fieldRefModalRenderCount: prevState.fieldRefModalRenderCount + 1 }));
                    }}
                    title={<FormattedMessage id='confirmFieldReference' />}
                    body={this.state.confirmFieldRefModalMessage}
                />
                <div className='row'>
                    <div className='col-xs-3 col-lg-4 col-xl-5'>
                        { readOnly ? 
                            <p>{this.state.templateTitle}</p>
                            :
                            <EdiText
                                type='text'
                                value={this.state.templateTitle}
                                onSave={this.onSaveTitle}
                                showButtonsOnHover={false}
                                disabled={readOnly}
                            />
                        }
                    </div>
                    <div className='col-xs-9 col-lg-8 col-xl-7'>
                        <div className='float-right'>
                            <button
                                type='button'
                                className='btn btn-outline-info tx-11 tx-uppercase pd-y-12 pd-x-25 mg-x-25 tx-mont tx-medium'
                                onClick={this.fauxSave}
                                disabled={this.state.fauxSaving || readOnly}
                            >
                                <FormattedMessage id='common.save' />
                            </button>
                            <button
                                type='button'
                                className='btn btn-outline-info tx-11 tx-uppercase pd-y-12 pd-x-25 mg-r-25 tx-mont tx-medium'
                                onClick={() => {
                                    this.setState({ insertFieldRefLoading: true }, () => {
                                        const { match, getDocumentReferenceData } = this.props;
                                        let fieldReferences = [];
                                        const parsedTemplate = JSON.parse(this.state.doc_file_data);
                                        if (parsedTemplate) {
                                            fieldReferences = getWordFieldReferences(parsedTemplate);
                                        }
                                        getDocumentReferenceData({ field_references: fieldReferences, document_template_id: match.params.id }, (dt) => {
                                            const fieldReferencesWithData = addMetadataToFieldReferences(fieldReferences, dt)
                                            this.setState({ fieldRefGroups: dt.ref_groups, fieldRefGroupRefs: dt.ref_group_references, fieldRefs: fieldReferencesWithData, fieldModalVisible: true, insertFieldRefLoading: false });
                                        });
                                    });
                                }}
                                disabled={readOnly}
                            >
                            { this.state.insertFieldRefLoading ?
                                <FormattedMessage id='common.loading' /> 
                                :
                                <FormattedMessage id='common.insertFieldReference' />
                            }
                            </button>
                            <Popover
                                content={
                                    <Menu>
                                        <MenuItem
                                            icon="edit"
                                            text={fieldRefGroupsLoading ? <FormattedMessage id='common.loading' /> : <FormattedMessage id="common.edit" />}
                                            disabled={fieldRefGroupsLoading}
                                            onClick={() =>
                                                !fieldRefGroupsLoading && this.setState({ fieldGroupModalVisible: true, refGroupView: 'edit' })
                                            }
                                        />
                                        <MenuDivider />
                                        <MenuItem
                                            icon="cross"
                                            text={fieldRefGroupsLoading ? <FormattedMessage id='common.loading' /> : <FormattedMessage id="common.delete" />}
                                            disabled={fieldRefGroupsLoading}
                                            onClick={() =>
                                                !fieldRefGroupsLoading && this.setState({ fieldGroupModalVisible: true, refGroupView: 'delete' })
                                            }
                                        />
                                    </Menu>
                                }
                            >
                                <button
                                    type='button'
                                    className='btn btn-outline-info tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium'
                                    onClick={() => {
                                        this.setState({ fieldRefGroupsLoading: true }, () => {
                                            const { match, getDocumentReferenceData } = this.props;
                                            let fieldReferences = [];
                                            const parsedTemplate = JSON.parse(this.state.doc_file_data);
                                            if (parsedTemplate) {
                                                fieldReferences = getWordFieldReferences(parsedTemplate);
                                            }
                                            getDocumentReferenceData({ field_references: fieldReferences, document_template_id: match.params.id }, (dt) => {
                                                const fieldReferencesWithData = addMetadataToFieldReferences(fieldReferences, dt)
                                                this.setState({ fieldRefGroups: dt.ref_groups, fieldRefGroupRefs: dt.ref_group_references, fieldRefs: fieldReferencesWithData, fieldRefGroupsLoading: false });
                                            });
                                        });
                                    }}
                                    disabled={readOnly || this.state.fieldRefGroupsLoading}
                                >
                                    <FormattedMessage id='fieldReferenceGroups' /> 
                                </button>
                            </Popover>
                        </div>
                    </div>
                </div>
                <LoadingOverlay
                    active={readOnly}
                >
                    <DocumentEditorContainerComponent 
                        id="container" 
                        style={{ 'height': this.props.manualHeight }}  
                        ref={(scope) => { this.documenteditor = scope; }} 
                        enableToolbar={true}
                        contentChange={this.onDocumentChanged}
                        isReadOnly={readOnly}
                        selectionChange={args => {
                            this.setState({ userSelection: args.source.documentEditor.selection.text });
                        }}
                        created={() => {
                            this.documenteditor.documentEditor.keyDown = (args) => {
                                const { hideFieldReferenceCopyPastedWarning } = this.props;
                                var keyCode = args.event.which || args.event.keyCode;
                                var isCtrlKey = (args.event.ctrlKey || args.event.metaKey) ? true : ((keyCode === 17) ? true : false);
                               
                                //67 is the character code for 'C'
                                if (isCtrlKey && keyCode === 67) {
                                    const refs = parseStringForRefs(this.state.userSelection);
                                    if (refs.length > 0 && !hideFieldReferenceCopyPastedWarning) {
                                        this.setState({ showFieldRefCopyPasteModal: true });
                                    }
                                }
                            }
                        }}
                    />
                </LoadingOverlay>
                <FieldRefCopyPasteModal
                    open={this.state.showFieldRefCopyPasteModal}
                    setOpen={(o) => this.setState({ showFieldRefCopyPasteModal: o })}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    company: state.identity.company,
    model: state.fields.model,
    fields: state.fields.items,
    fieldTypes: state.fields.types,
    template: state.document.instance,
    templateChanged: state.document.templateChanged,
    hideFieldReferenceCopyPastedWarning:  state.sessionSettings.excelHideFieldReferenceCopyPastedWarning,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getProfile,
            getDocumentTemplate,
            getDocumentSample,
            setMenuVisibility,
            updateDocumentTemplate,
            getModels,
            addFieldReferenceToGroup,
            removeFieldReferenceFromGroup,
            setFieldGroupReferences,
            getDocumentReferenceData,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WordDocumentTemplate);
