import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import ReactModal from 'react-modal';
import { Button, Popover, Menu, MenuItem, Icon } from '@blueprintjs/core';
import Table from 'components/table';
import Page from 'components/page';
import thumb from 'assets/user.svg';
import { history } from 'configuration/history';
import { cancelSubscription, getPlans, getProducts, subscribe } from 'reducers/payment';
import { getUserRoles, getUsers, deleteUser, reenableUser, updateUser } from 'reducers/users';
import { getProfile } from 'reducers/identity';
import UserInvite from '../components/forms/userInvite';
import Subscription from '../components/forms/subscription';
import { Notifications } from 'components/notifications';
import ConfirmModal from 'components/modals/confirm'
import { tooltipWrapper } from 'utilities/common'
import doraeLogo from 'assets/logo-green.svg';
import _ from 'lodash';

class CompanyBilling extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: { open: false },
            isSubmitting: false
        };
    }

    componentDidMount() {
        const { plans, getPlans, products, getProducts, company, roles, getUserRoles } = this.props;

        if (!plans) getPlans();
        if (!products) getProducts();
        if (!roles) getUserRoles({ company_id: company.id });
    }

    setModalVisibility = (open, type) => this.setState({ ...this.state, modal: { open, type: !!open ? type : undefined } });

    loadData = (options, onComplete) => {
        const { getUsers, company } = this.props;
        return getUsers({ company_ids: [company.id], status_ids: [1, 2, 3, 4, 5, 6], ...options }, onComplete);
    };

    getUserRole = (role_id) => {
        const { roles } = this.props;
        const role = roles.find(r => role_id === r.id);
        if (!role) return <FormattedMessage id='Unavailable' />;
        return <FormattedMessage id={role.title} />;
    }

    getColumns = () => {
        const { company, deleteUser, reenableUser, getUsers, getProfile, updateUser, roles } = this.props;
        return [
            {
                headerClassName: 'd-sm-block col-2 d-none',
                className: 'd-xs-block col-2',
                sortable: false,
                resizable: false,
                width: 34,
                id: 'actions',
                accessor: d => (
                    <div className="actions-wrapper">
                        <Popover
                            autoFocus={false}
                            content={d.status_name !== 'Deleted' ?
                                <Menu>
                                    <MenuItem
                                        icon="cross"
                                        text={<FormattedMessage id="common.delete" />}
                                        popoverProps={{ openOnTargetFocus: false }}
                                        onClick={() =>
                                            deleteUser(d.id, () => {
                                                getUsers({ company_ids: [company.id], status_ids: [1, 2, 3, 4, 5, 6] });
                                                getProfile();
                                            })
                                        }
                                    />
                                    <MenuItem
                                        icon="edit"
                                        text={<FormattedMessage id="common.changeRole"
                                        popoverProps={{ openOnTargetFocus: false }} />}
                                    >
                                        <MenuItem
                                            text={<FormattedMessage id="common.companyAdmin" />}
                                            popoverProps={{ openOnTargetFocus: false }}
                                            onClick={() =>
                                                updateUser({user_id: d.id, email: d.email, name: d.name, role_id: 2 }, () => {
                                                    Notifications.success(<FormattedMessage id="page.companies.billing.updateUserRole" />);
                                                    getUsers({ company_ids: [company.id], status_ids: [1, 2, 3, 4, 5, 6] });
                                                    getProfile();
                                                })
                                            }
                                        />
                                        <MenuItem
                                            text={<FormattedMessage id="common.companyUser" />}
                                            popoverProps={{ openOnTargetFocus: false }}
                                            onClick={() =>
                                                updateUser({user_id: d.id, email: d.email, name: d.name, role_id: 3 }, () => {
                                                    Notifications.success(<FormattedMessage id="page.companies.billing.updateUserRole" />);
                                                    getUsers({ company_ids: [company.id], status_ids: [1, 2, 3, 4, 5, 6] });
                                                    getProfile();
                                                })
                                            }
                                        />
                                        <MenuItem
                                            text={<FormattedMessage id="common.localizedUser" />}
                                            popoverProps={{ openOnTargetFocus: false }}
                                            onClick={() =>
                                                updateUser({user_id: d.id, email: d.email, name: d.name, role_id: 4 }, () => {
                                                    Notifications.success(<FormattedMessage id="page.companies.billing.updateUserRole" />);
                                                    getUsers({ company_ids: [company.id], status_ids: [1, 2, 3, 4, 5, 6] });
                                                    getProfile();
                                                })
                                            }
                                        />
                                    </MenuItem>
                                </Menu>
                                :
                                <Menu>
                                    <MenuItem
                                        icon="plus"
                                        text={<FormattedMessage id="common.undelete" />}
                                        onClick={() =>
                                            reenableUser(d.id, () => {
                                                getUsers({ company_ids: [company.id], status_ids: [1, 2, 3, 4, 5, 6] });
                                                getProfile();
                                            })
                                        }
                                    />
                                </Menu>
                            }
                        >
                            <Button minimal={true} small={true} icon={<Icon iconSize={12} icon="menu" />} />
                        </Popover>
                    </div>
                )
            },
            {
                headerClassName: 'd-sm-block d-none',
                Header: <FormattedMessage id="common.name" />,
                id: 'name',
                accessor: d => d.name,
                Cell: row => {
                    const { original, value } = row;
                    const avatar = original.avatar && original.avatar.thumb && original.avatar.thumb.url ? original.avatar.thumb.url : thumb;
                    return (
                        <div>
                            <span className="d-sm-block d-none">{value}</span>
                            <div className="d-sm-none d-xs-block mobile-cell">
                                <div className="row align-items-center">
                                    <div className="col-auto text-center pl-4 pr-0">
                                        <img src={avatar} className="wd-40 rounded-circle" alt={original.name} />
                                    </div>
                                    <div className="col-9">
                                        <div className="font-weight-bold ellipsis">{value}</div>
                                        <div className="ellipsis">{original.email}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }
            },
            {
                headerClassName: 'd-sm-block d-none',
                className: 'd-sm-block d-none',
                Header: <FormattedMessage id="common.email" />,
                id: 'email',
                accessor: d => d.email
            },
            {
                headerClassName: 'd-sm-block d-none',
                className: 'd-sm-block d-none',
                Header: <FormattedMessage id="role.placeholder" />,
                id: 'role_id',
                accessor: d => this.getUserRole(d.role_id)
            },
            {
                headerClassName: 'd-sm-block d-none',
                className: 'd-sm-block d-none',
                Header: <FormattedMessage id="common.status" />,
                id: 'status_id',
                accessor: d => <FormattedMessage id={d.status_name} defaultMessage={d.status_name} />
            }
        ];
    };

    render() {
        const { modal } = this.state;
        const { parents, company, cancelSubscription, subscribe, getProfile, plans, products, roles, users = [], total = 0 } = this.props;
        if (!company || !plans || !products) return null;

        const { plan_id, subscription_seats, payment_source } = company;

        const isFreeTrial = company.id !== 1 && (payment_source === null || payment_source === undefined || payment_source === '');

        const plan = plans.find(p => p.id === plan_id) || { price_cents: 0 };
        const totalPrice = isFreeTrial ? 0 : (Number.parseInt(subscription_seats) * plan.price_cents) / 100; //(plan.interval === 'year' ? 1200 : 100).toFixed(2);
        const type = plan.interval === 'year' ? 'annually' : 'monthly';
        const typeTotal = plan.interval === 'year' ? 'annual total' : 'monthly total';
        const currencySymbol = company.currency_name === 'USD' ? '$' : '€';

        return (
            <React.Fragment>
                <Page {...CompanyBilling.meta} parents={parents} panel={false}>
                    <div className="billing-box">
                        <div className="row">
                            <div className="col-8">
                                <div className="payment-plan-box pd-0">
                                    <div className="tx-light tx-11 pd-b-15">
                                        <span>
                                            <FormattedMessage id="page.companies.billing.activePlan" />
                                    </span>
                                    </div>
                                    <div className="payment-plan-box-inner pd-x-0-force">
                                        <h5 className="plan-title tx-century-gothic dorae_subscription-row">
                                            <img src={doraeLogo} alt="Dorae" className="dorae_plan-logo" /> <strong>Standard</strong>
                                        </h5>
                                        <div className="pricing-details" style={{ height: 'auto' }}>
                                            <p className="pricing-details-price">
                                                {
                                                    plan.interval === 'year' ?
                                                    <React.Fragment>
                                                        {`${currencySymbol}${isFreeTrial ? 0 : (plan.price_cents / 100)} `}
                                                        <span>
                                                            <FormattedMessage id="page.companies.payment.plan.box.annualDescription" />
                                                        </span>
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        {`${currencySymbol}${isFreeTrial ? 0 : (plan.price_cents / 100)} `}
                                                        <span>
                                                            <FormattedMessage id="page.companies.payment.plan.box.monthlyDescription" />
                                                        </span>
                                                    </React.Fragment>
                                                }
                                                
                                            </p>
                                        </div>
                                        <div>
                                            { tooltipWrapper(
                                                isFreeTrial,
                                                <FormattedMessage id='contactDoraeForSubscriptionChanges' />,
                                                <button
                                                    type="button"
                                                    className={'btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium' + (isFreeTrial ? ' disabled' : '')}
                                                    onClick={() => !isFreeTrial && history.push(`/settings/billing/plans` + window.location.search)}
                                                >
                                                    <FormattedMessage id="page.companies.billing.changePlan" />
                                                </button>
                                            ) }
                                            { tooltipWrapper(
                                                isFreeTrial,
                                                <FormattedMessage id='contactDoraeForSubscriptionChanges' />,
                                                <button
                                                    type="button"
                                                    className={'btn btn-danger tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium mg-l-5' + (isFreeTrial ? ' disabled' : '')}
                                                    onClick={() => !isFreeTrial && this.setState({ confirmModalOpen: true })}
                                                >
                                                    <FormattedMessage id="page.companies.billing.cancelPlan" />
                                                </button>
                                            ) }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="billing-statistics">
                                    <div className="billing-statistics-line">
                                        <div className="bsl-figure">{subscription_seats}</div>
                                        <span>total licenses</span>
                                        { tooltipWrapper(
                                            isFreeTrial,
                                            <FormattedMessage id='contactDoraeForSubscriptionChanges' />,
                                            <button
                                                type="button"
                                                className={'bsl-button btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium' + (isFreeTrial ? ' disabled' : '')}
                                                onClick={() => !isFreeTrial && this.setModalVisibility(true, 'licenses')}
                                            >
                                                <FormattedMessage id="page.companies.billing.licenses" />
                                            </button>,
                                            'left'
                                        ) }
                                    </div>
                                    <div className="bsl-line" />
                                    <div className="billing-statistics-line">
                                        <div className="bsl-figure">{`${currencySymbol}${totalPrice}`}</div>
                                        <span>{typeTotal}</span>
                                        { tooltipWrapper(
                                            isFreeTrial,
                                            <FormattedMessage id='contactDoraeForSubscriptionChanges' />,
                                            <button
                                                type="button"
                                                className={'bsl-button btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium'}
                                                onClick={() => !isFreeTrial && history.push(`/settings/billing/invoices` + window.location.search)}
                                            >
                                                <FormattedMessage id="page.companies.billing.invoices" />
                                            </button>,
                                            'left'
                                        ) }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="billing-box mg-t-30">
                        <div className="row users-title-bar">
                            <div className="col-10">
                                <span className="users-title">
                                    <FormattedMessage id="common.users" />
                                </span>
                                <span className="users-subtitle">
                                    <FormattedMessage
                                        id="page.companies.billing.usedLicenses"
                                        values={{ users: users.filter(u => u.status_id === 2 || u.status_id === 1).length, total: subscription_seats }}
                                    ></FormattedMessage>
                                </span>
                            </div>
                            <div className="col-2 tx-right">
                                <button
                                    type="button"
                                    className="bsl-button btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                    onClick={() => this.setModalVisibility(true, 'invite-user')}
                                >
                                    <FormattedMessage id="page.companies.details.actions.invite.user" />
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <Table
                                    columns={this.getColumns()}
                                    data={users}
                                    total={total}
                                    dataLoader={(options, onComplete) => this.loadData(options, onComplete)}
                                />
                            </div>
                        </div>
                    </div>
                </Page>
                <ReactModal
                    isOpen={modal.open}
                    onRequestClose={() => this.setModalVisibility(false)}
                    className="modal-block dialog"
                    overlayClassName="modal-overlay gray"
                >
                    {modal.type === 'invite-user' ? (
                        <UserInvite companyId={company.id} roles={roles} onCancel={() => this.setModalVisibility(false)} />
                    ) : (
                        <Subscription
                            initialSeats={subscription_seats}
                            plan={plan}
                            price={plan.price_cents}
                            type={type}
                            isSubscriptionChange={!!company.subscription_id ? true : false}
                            isSubmitting={this.state.isSubmitting}
                            onCancel={() => this.setModalVisibility(false)}
                            onSubmit={({ seats }) => {
                                this.setState({isSubmitting: true}, () => {
                                    subscribe(plan.id, seats, () => {
                                        getProfile(() => {
                                            Notifications.success(<FormattedMessage id="page.settings.setup.sub.done" />);
                                            this.setState({isSubmitting:false});
                                            this.setModalVisibility(false);
                                        });
                                    });
                                })
                            }}
                            currency={company.currency_name}
                        />
                    )}
                </ReactModal>
                <ConfirmModal
                    open={this.state.confirmModalOpen}
                    setOpen={(o) => this.setState({ confirmModalOpen: o })}
                    onConfirm={() => {
                        cancelSubscription(() => getProfile(() => history.push('/settings' + window.location.search)))
                    }}
                    title={<FormattedMessage id='common.confirmVerb' values={{ verb: 'Cancel'}} />}
                    body={<FormattedMessage id='confirmSubscriptionCancel' />}
                    cancelLabel={<FormattedMessage id="common.no" />}
                />
            </React.Fragment>
        );
    }
}

CompanyBilling.meta = {
    title: <FormattedMessage id="page.companies.billing.title" />,
    subtitle: <FormattedMessage id="page.companies.billing.subtitle" />,
    routes: ['/settings/billing'],
    icon: 'ion-ios-world-outline',
    menus: { main: false, user: false },
    order: 0
};

const mapStateToProps = state => {
    return {
        company: state.identity.company,
        roles: state.users.roles,
        users: state.users.list,
        total: state.users.total,
        permissions: state.identity.permissions,
        plans: state.payment.plans,
        products: state.payment.products
    };
};
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getProfile,
            cancelSubscription,
            getPlans,
            getProducts,
            getUserRoles,
            getUsers,
            updateUser,
            deleteUser,
            reenableUser,
            subscribe
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanyBilling);
