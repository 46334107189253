export const INDICES_LIST = `
    query($type_ids: [Int!], $status_ids: [Int!], $created_from: DateTime, $created_to: DateTime, $order_by: [OrderBy], $offset: Int, $limit: Int, $model_type: Int) {
        index_field_list(input: { type_ids: $type_ids, status_ids: $status_ids, created_from: $created_from, created_to: $created_to, model_type: $model_type }, paging: { order_by: $order_by, offset: $offset, limit: $limit }) {
            total
            data {
                id
                hash_id
                order_by
                type_id
                type_name
                status_id
                status_name
                title
                description
                extra_info
                is_used
                model_type
                model_type_name
                created_on
            }
        }
    }
`;
