export const DOCUMENT_TEMPLATE_REMOVE_FIELD_REF_FROM_GROUP = `
    mutation M(
        $document_template_id: Long!
        $field_reference_literal: String!
    ) {
        document_template_remove_ref_from_group(
            input: { 
                document_template_id: $document_template_id 
                field_reference_literal: $field_reference_literal
            }
        ) {
            document_template_id
            name
            field_reference_literal
        }
    }
`;
