import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import Page from 'components/page';
import { 
    getAlert, 
    updateAlert, 
    unsubscribeAlert, 
    modifyAlertSubscriber, 
    deleteAlert,
    CONDITION_TYPES,
    createCondition,
 } from 'reducers/alerts';
import { getModels } from 'reducers/models'
import { getProfile } from 'reducers/identity';
import { canInteract } from 'components/routes/permissions/permissionResolver';
import { history } from 'configuration/history';
import LoadingOverlay from 'react-loading-overlay';
import { Formik, Form } from 'formik';
import { Fields } from 'components/fields';
import { debounce } from 'utilities/common';
import ConfirmModal from 'components/modals/confirm';
import { tooltipWrapper } from 'utilities/common'
import SubscribersModal from 'components/modals/subscribersModal';
import { Popover, Menu, MenuItem } from '@blueprintjs/core';
import AlertCondition from './condition'

import 'pages/models/models.scss';

export class AlertNew extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cargoCategories: [],
            cargoTypes: [],
            model: {},
            loadingAlert: false,
            loaded: false,
            saving: false,
            activateChanging: false,
            subscribeChanging: false,
            subscribersModalCount: 0,
        };
    }

    validatePermissions = () => {
        const { entityPermissions } = this.props;
        if (!canInteract(entityPermissions, ['model', 'create', 'update'])) {
            history.replace('/');
        }
    };

    clearLoading = () => {
        const { setRootLoadingState } = this.props;
        this.setState({ saving: false, activateChanging: false, subscribeChanging: false }, () => setRootLoadingState(false));
    }


    debouncedUpdate = debounce((values) => {
        const { updateAlert, match } = this.props;
        values.id = match.params.id;
        this.setState({ saving: true }, () => {
            updateAlert({ ...values }, this.clearLoading, this.clearLoading);
        })
    }, 500);

    componentDidMount() {
        const { match, company, getProfile, getAlert, getModels } = this.props;
        if (!company) {
            getProfile(this.validatePermissions);
        } else {
            this.validatePermissions();
        }
        
        getModels({model_type: 2}, (cargoCategories) => {
            this.setState({cargoCategories: cargoCategories.data});
        }, false)

        if (match && match.params && match.params.id) {
            this.setState({ loadingAlert: true }, () => {
                getAlert({ alert_id: match.params.id }, () => {
                    this.setState({ loadingAlert: false, loaded: true });
                }, () => {
                    console.error('Error loading alert');
                    this.setState({ loadingAlert: false });
                })
            })
        }
    }

    isNotOwnerTooltipWrapper = (el) => {
        return tooltipWrapper(
            !this.isAlertOwner(),
            <FormattedMessage id='notAlertOwnerMessage'/>,
            el,
            'top'
        );
    }

    getActivateButton = (formProps) => {
        const { activateChanging } = this.state;
        const active = formProps.values['active'];

        return this.isNotOwnerTooltipWrapper(
            <button
                id="activate-button"
                type="button"
                className={'btn btn-primary btn-block pd-y-12' + ((activateChanging || !this.isAlertOwner()) ? ' disabled' : '')}
                onClick={() => {
                    if (activateChanging || !this.isAlertOwner()) return null;
                    this.setState({ activateChanging: true }, () => {
                        formProps.setFieldValue('active', !active, false);
                        formProps.submitForm();
                    })
                } }
            >
                {
                    active ?
                    <FormattedMessage id="deactivate" />
                    :
                    <FormattedMessage id="activate" />
                }
            </button>
        )
    }

    getManageUsersButton = () => {
        return this.isNotOwnerTooltipWrapper(
            <button
                id="manage-users-button"
                type="button"
                className={'btn btn-primary btn-block pd-y-12' + (!this.isAlertOwner() ? ' disabled' : '')}
                onClick={() => {
                    if (!this.isAlertOwner()) return;
                    this.setState({ subscribersModalOpen: true })
                } }
            >
                <FormattedMessage id="manageSubscribers" />
            </button>
        )
    }

    isCurrentlySubscribed = () => {
        const { alert, user } = this.props;
        let subscribers = [];
        if (alert && alert.subscribers) {
            subscribers = alert.subscribers;
        }
        const me = subscribers.find(s => (s && s.id) ? s.id === user.id : false);
        return me !== undefined;
    }

    isAlertOwner = () => {
        const { alert, user } = this.props;
        if (alert && alert.created_by && user && user.id) {
            return alert.created_by === user.id;
        }
        return false;
    }

    getSubscribeButton = () => {
        const { user, unsubscribeAlert, match, modifyAlertSubscriber, setRootLoadingState, getAlert } = this.props;
        const { subscribeChanging } = this.state;

        return (
            <button
                type="button"
                className="btn btn-primary btn-block pd-y-12"
                onClick={() => {
                    setRootLoadingState(true);
                    this.setState({ subscribeChanging: true }, () => {
                        const subscribed = this.isCurrentlySubscribed();
                        const onComplete = () => {
                            const afterFetch = () => this.setState({ subscribersModalCount: this.state.subscribersModalCount + 1 }, this.clearLoading);
                            getAlert({ alert_id: match.params.id }, afterFetch, afterFetch);
                        }
                        if (subscribed) {
                            unsubscribeAlert(
                                { alert_id: match.params.id }, 
                                () => {
                                    if (this.isAlertOwner()) {
                                        onComplete();
                                    } else {
                                        this.clearLoading();
                                        history.push('/intelligence/alerts' + window.location.search);
                                    }
                                }, 
                                onComplete
                            );
                        } else {
                            modifyAlertSubscriber({ alert_id: match.params.id, user_id: user.id, add: true }, onComplete, onComplete);
                        }
                    })
                } }
                disabled={subscribeChanging}
            >
                {
                    this.isCurrentlySubscribed() ?
                    <FormattedMessage id="unsubscribe" />
                    :
                    <FormattedMessage id="subscribe" />
                }
            </button>
        )
    }

    updateSubscribers = (updatedSubscribers) => {
        const { alert, modifyAlertSubscriber, getAlert } = this.props;
        const subscribers = (alert && alert.subscribers) ? alert.subscribers.map(s => s.id) : [];
        this.setState({ subscribersLoading: true }, () => {
            const newlyAdded = updatedSubscribers.filter(s => !subscribers.includes(s));
            const removed = subscribers.filter(s => !updatedSubscribers.includes(s));
            const addPromises = newlyAdded.map(s => new Promise(resolve => {
                modifyAlertSubscriber({ alert_id: Number(alert.id), user_id: Number(s), add: true }, resolve, resolve);
            }));
            const removePromises = removed.map(s => new Promise(resolve => {
                modifyAlertSubscriber({ alert_id: Number(alert.id), user_id: Number(s), add: false }, resolve, resolve);
            }));
            Promise.all(addPromises.concat(removePromises)).then(() => {
                const onComplete = () => this.setState({ subscribersLoading: false, subscribersModalOpen: false });
                getAlert({ alert_id: alert.id }, onComplete, onComplete);
            });
        })
    }

    renderProperties = () => {
        const { alert, setRootLoadingState } = this.props;

        const initialValues = alert ? 
            {
                title: alert.title || '',
                description: alert.description || '',
                active: alert.active
            }
            :
            {
                title: '',
                description: '',
                active: false
            }

        return (
            <div className="row">
                <div className="col-12">
                    <div className="shadow-base">
                        <div className="card">
                            <div className='widget-1'>
                                <div className="card-header row">
                                    <h6 className="col-10 card-title"><FormattedMessage id='common.properties'/></h6>
                                </div>
                            </div>
                            <div className="card-body">
                                <Formik
                                    key={'properties-' + (this.state.loaded ? 'loaded' : 'preload')}
                                    enableReinitialize={false}
                                    initialValues={ initialValues }
                                    onSubmit={values => {
                                        setRootLoadingState(true);
                                        this.debouncedUpdate(values)
                                    }}
                                    render={( formProps ) => {
                                        return (
                                            <Form 
                                                noValidate
                                                onChange={async e => {
                                                    await formProps.handleChange(e);
                                                    formProps.submitForm();
                                                }}
                                            >
                                                <div className='row'>
                                                    <div className="form-group col-2">
                                                        {
                                                            this.isNotOwnerTooltipWrapper(<div><Fields.Input type="text" name="title" className="form-control" disabled={formProps.values['active'] || !this.isAlertOwner()} /></div>)
                                                        }
                                                    </div>
                                                    <div className="form-group col-3">
                                                        {
                                                            this.isNotOwnerTooltipWrapper(<div><Fields.Input type="text" name="description" className="form-control" disabled={formProps.values['active'] || !this.isAlertOwner()} /></div>)
                                                        }
                                                    </div>
                                                    <div className="form-group col-7">
                                                        <div className='row'>
                                                            <div className='col-3 justify-content-center'>
                                                                { this.getActivateButton(formProps) }
                                                            </div>
                                                            <div className='col-3 justify-content-center'>
                                                                { this.getSubscribeButton(formProps) }
                                                            </div>
                                                            <div className='col-3 justify-content-center'>
                                                                { this.getManageUsersButton() }
                                                            </div>
                                                            <div className='col-3'>
                                                                {
                                                                    this.isNotOwnerTooltipWrapper(
                                                                        <button
                                                                            type="button"
                                                                            className={'btn btn-secondary btn-block pd-y-12' + (!this.isAlertOwner() ? ' disabled' : '')}
                                                                            onClick={() => this.isAlertOwner() && this.setState({ deleteModalOpen: true })}
                                                                        >
                                                                            <FormattedMessage id="delete" />
                                                                        </button>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    addCondition = (variables) => {
        const { break_or, type } = variables;
        const { createCondition, alert, getAlert, match } = this.props;
        const refresh = () => {
            getAlert({ alert_id: match.params.id }, () => {
                this.setState({ loadingConditions: false });
            }, () => {
                console.error('Error loading alert');
                this.setState({ loadingConditions: false });
            })
        }
        this.setState({ loadingConditions: true }, () => {
            createCondition({type_id: type, alert_id: alert.id, order: alert.conditions.length, break_or: break_or}, refresh, refresh);
        })
    }

    addConditionButton = () => {
        const { alert } = this.props;
        let conditions = [];
        if (alert && alert.conditions) {
            conditions = alert.conditions;
        }
        const button = (break_or) => {
            const breakOrFixed = (break_or === undefined) ? true : break_or;
            return (
                <Popover
                    disabled={this.state.newConditionLoading || !this.isAlertOwner() || alert.active}
                    content={
                        <Menu>
                            {/* <MenuItem 
                                icon="plus" 
                                text={<FormattedMessage id="event" />} 
                                onClick={() => this.addCondition({ break_or: breakOrFixed, type: CONDITION_TYPES.event })}
                            /> */}
                            <MenuItem
                                icon="plus"
                                text={<FormattedMessage id="index" />}
                                onClick={() => this.addCondition({ break_or: breakOrFixed, type: CONDITION_TYPES.index })}
                            />
                            <MenuItem
                                icon="plus"
                                text={<FormattedMessage id="dot" />}
                                onClick={() => this.addCondition({ break_or: breakOrFixed, type: CONDITION_TYPES.dot })}
                            />
                        </Menu>
                    }
                >
                    <button className="btn btn-primary icon-button field-inline mg-r-15" type="button" disabled={this.state.newConditionLoading || !this.isAlertOwner() || alert.active}>
                        <i className="material-icons">add</i>
                        <span>
                            {
                                break_or === undefined && <FormattedMessage id="newCondition" values={{ type: <FormattedMessage id='AND' /> }} />
                            }
                            {
                                break_or === false && <FormattedMessage id="newConditionWithType" values={{ type: <FormattedMessage id='AND' /> }} />
                            }
                            {
                                break_or === true && <FormattedMessage id="newConditionWithType" values={{ type: <FormattedMessage id='OR' /> }} />
                            }
                        </span>
                    </button>
                </Popover>
            )
        }
        if (conditions.length > 0) {
            return (
                <React.Fragment>
                    { button(false) }
                    { button(true) }
                </React.Fragment>
            );
        } else {
            return button();
        }
    }

    renderConditions = () => {
        const { parents, alert, match, company, deleteAlert, setRootLoadingState } = this.props;
        let conditions = [];
        if (alert && alert.conditions) {
            conditions = alert.conditions;
        }
        return (
            <LoadingOverlay
                active={this.state.loadingConditions}
                spinner
                text={<FormattedMessage id='common.loading'/>}
            >
                <div className="advanced-filters-inner">
                    { conditions.length > 0 ?
                        alert.conditions.map((filterItem, index) => (
                            <React.Fragment key={filterItem.id}>
                                {!!filterItem.break_or && index != 0 && (
                                    <div className="condition-separator tx-center mg-y-25">
                                        <span>OR</span>
                                    </div>
                                )}
                                <AlertCondition 
                                    item={filterItem} 
                                    setRootLoadingState={setRootLoadingState} 
                                    cargoCategories={this.state.cargoCategories}
                                    disabled={alert.active || !this.isAlertOwner()}
                                />
                            </React.Fragment>
                        ))
                        :
                        <FormattedMessage id='noConditions'/>
                    }
                    <div className="row mg-t-15">
                        <div className="col">
                            { this.addConditionButton() }
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        )
    }

    render() {
        const { parents, alert, match, company, deleteAlert } = this.props;
        if (!match || !match.params || !match.params.id) return null;
        if (!company) return null;

        return (
            <Page {...{ ...AlertNew.meta, title: `${alert.title}` }} parents={parents} panel={false}>            
                <LoadingOverlay
                    active={this.state.loadingAlert}
                    spinner
                    text={<FormattedMessage id='common.loading'/>}
                >
                    { this.renderProperties() }
                    <br/>
                    <div className="shadow-base">
                        <div className="card">
                            <div className='widget-1'>
                                <div className="card-header row">
                                    <h6 className="col-10 card-title"><FormattedMessage id='alertConditions'/></h6>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        { this.renderConditions() }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </LoadingOverlay>
                <ConfirmModal
                    open={this.state.deleteModalOpen}
                    setOpen={(o) => this.setState({ deleteModalOpen: o })}
                    onConfirm={() => {
                        this.setState({ loadingAlert: true }, () => {
                            deleteAlert({ alert_id: match.params.id }, () => history.push('/intelligence/alerts' + window.location.search), () => this.setState({ loadingAlert: false }));
                        });
                    }}
                    title={<FormattedMessage id='common.confirmVerb' values={{ verb: 'Delete'}} />}
                    body={<FormattedMessage id='confirmAlertDelete' values={{ title: alert && alert.title ? alert.title : '[Untitled]' }} />}
                />
                {
                    this.state.loaded && (
                        <SubscribersModal
                            key={'subscriberModal-' + this.state.subscribersModalCount}
                            loading={this.state.subscribersLoading}
                            showModal={this.state.subscribersModalOpen}
                            setModalVisibility={(val) => this.setState({ subscribersModalOpen: val, subscribersModalCount: this.state.subscribersModalCount + 1 })}
                            subscribers={alert.subscribers} 
                            onConfirm={this.updateSubscribers}
                        />
                    )
                }
                
            </Page>
        );
    }
}

AlertNew.meta = {
    showTitleBlock: false,
    title: <FormattedMessage id="page.models.new.title" />,
    subtitle: <FormattedMessage id="page.models.new.subtitle" />,
    routes: ['/models/'],
    icon: 'ion-clipboard',
    menus: { main: false, user: false },
    order: 0,
    requires: ['model', 'read', 'update']
};

const mapStateToProps = state => ({
    entityPermissions: state.identity.entityPermissions,
    company: state.identity.company,
    alert: state.alerts.alert,
    fieldTypes: state.fields.types,
    user: state.identity.user,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getProfile,
            getAlert,
            updateAlert,
            unsubscribeAlert,
            modifyAlertSubscriber,
            deleteAlert,
            createCondition,
            getModels,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AlertNew);
