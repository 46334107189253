export const RETRY_PAYMENT = `
    mutation _
    {
        company_retry_payment
        {
            id              
            status_id       
            status_name     
            created_on      
            modified_on     
            code            
            company_name    
            lang_id         
            lang            
            country_id      
            country_code2   
            country_code3   
            custom_domain   
            description     
            website         
            email           
            signature       
            city            
            address         
            zip             
            phone1          
            phone2          
            fax           
            
            branding_config 
            default_prefs
            extra_info
            
            is_system
            
            stripe_customer_id
            is_paid
            payment_source
            subscription_id
            subscription_status
            subscription_type
        }
    }
`;
