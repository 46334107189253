import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Fields } from '../../../../components/fields';
import { Notifications } from '../../../../components/notifications';
import { createInviteLink, getInviteLinks } from '../../../../reducers/companies';

class InviteLinkCreate extends React.Component {
    handleSubmit = values => {
        const { onCancel, getInviteLinks, createInviteLink } = this.props;
        const { title, description } = values;

        createInviteLink(title, description, () => {
            Notifications.success(<FormattedMessage id="page.companies.invites.linkCreated" />);
            getInviteLinks();
            onCancel();
        });
    };

    render() {
        const { onCancel } = this.props;
        return (
            <div className="modal-dialog wd-350-force advanced-filters-modal" role="document">
                <div className="modal-content bd-0">
                    <div className="modal-header pd-x-20">
                        <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                            <FormattedMessage id="page.companies.invites.createInviteLink" />
                        </h6>
                    </div>
                    <Formik
                        initialValues={{
                            title: '',
                            description: 'Company invitation link.'
                            // sendEmail: false,
                            // email: ''
                        }}
                        validationSchema={Yup.object().shape({
                            title: Yup.string().required(<FormattedMessage id="validation.required" />)
                        })}
                        onSubmit={this.handleSubmit}
                        render={({ values }) => (
                            <Form>
                                <React.Fragment>
                                    <div className="modal-body pd-0 pd-t-10 widget-2">
                                        <div className="card mg-x-0 mg-b-20 mg-t-5">
                                            <div className="card-body pd-x-5 pd-b-0">
                                                <div className="form-group col-12">
                                                    <Fields.Input name="title" showError={true} />
                                                </div>
                                                <div className="form-group col-12">
                                                    <Fields.Input name="description" />
                                                </div>
                                                {/* <div className="form-group col-12 mg-b-0">
                                                    <Fields.Checkbox name="sendEmail" checked={values.sendEmail === true} />
                                                </div>
                                                {values.sendEmail === true && (
                                                    <div className="form-group col-12 mg-t-10">
                                                        <Fields.Input name="email" />
                                                    </div>
                                                )} */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer justify-content-center">
                                        <button type="submit" className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium">
                                            <FormattedMessage id="common.submit" />
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                            onClick={onCancel}
                                        >
                                            <FormattedMessage id="common.cancel" />
                                        </button>
                                    </div>
                                </React.Fragment>
                            </Form>
                        )}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            createInviteLink,
            getInviteLinks
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InviteLinkCreate);
