import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import Page from '../../../components/page';
import { getProfile } from '../../../reducers/identity';
import { getIndices, createIndex, getIndexTypes, updateIndex, deleteIndex, getIndexStatuses } from '../../../reducers/indices';
import ModelsIndicesTable from '../../models/components/indicesTable';

class EventTemplateIndices extends React.Component {

    // Dash Tags

    constructor(props) {
        super(props);
        this.state = { modal: { open: false, type: undefined, data: undefined },
                        deleteTargetMultiIds: [], 
                        isDeletingMulti: false, 
                        confirmMultiModalOpen: false  };
    }

    setTagIdsToDelete = (idsToDelete) =>{
        this.setState({deleteTargetMultiIds: idsToDelete})
    }

    setConfirmMultiModalOpen = (open) =>{
        this.setState({confirmMultiModalOpen: open})
    }

    setModalVisibility = (visible, data = undefined, type = undefined) => {
        this.setState({ ...this.state, modal: { ...this.state.modal, open: visible, data: visible ? data : undefined, type: visible ? type : undefined } });
    };

    deleteMultiButtonGroup = () => {
        if (this.state.isDeletingMulti) {

            const deleteButton = this.state.deleteTargetMultiIds && this.state.deleteTargetMultiIds.length > 0 ?
                (
                    <span 
                        className="btn btn-outline-info" 
                        onClick={() => {
                            this.setState({ confirmMultiModalOpen: true });
                        }
                    }>
                        <FormattedMessage id="deleteSelectedTags" values={{count: this.state.deleteTargetMultiIds.length}} />
                    </span>
                )
                :
                (
                    <span 
                        className="btn btn-outline-info disabled" 
                        disabled={true}
                        onClick={() => null
                    }>
                        <FormattedMessage id="noTagsSelected" />
                    </span>
                )

            return (
                <React.Fragment>
                    { deleteButton }
                    <span 
                        className="btn btn-outline-info" 
                        onClick={() => {
                            this.setState({ isDeletingMulti: false, confirmMultiModalOpen: false, deleteTargetMultiIds: [] });
                        }
                    }>
                        <FormattedMessage id="cancel" />
                    </span>
                </React.Fragment>
            );
        } else {
            return (
                <span 
                    className="btn btn-outline-info" 
                    onClick={() => {
                        this.setState({ isDeletingMulti: true, deleteTargetMultiIds: [] });
                    }
                }>
                    <FormattedMessage id="deleteMultipleTags" />
                </span>
            );
        }
    }

    renderActions = () => {
        return (
            <div className="btn-group pd-t-10">
                <span className="btn btn-outline-info" onClick={() => this.setModalVisibility(true, undefined, 'add')}>
                    <FormattedMessage id="models.configure.indices.new" />
                </span>
                {this.deleteMultiButtonGroup()}
            </div>
        );
    };

    render() {
        // Dash Tags
        const { parents } = this.props;
        return (
            <Page {...EventTemplateIndices.meta} parents={parents} actions={this.renderActions()}>
                <ModelsIndicesTable
                    modal={this.state.modal}
                    modelTypeId={1}
                    setModalVisibility={this.setModalVisibility}
                    isDeletingMulti={this.state.isDeletingMulti}
                    setTagIdsToDelete={this.setTagIdsToDelete}
                    confirmMultiModalOpen={this.state.confirmMultiModalOpen}
                    setConfirmMultiModalOpen={this.setConfirmMultiModalOpen}
                    {...this.props}
                />
            </Page>
        );
    }
}

EventTemplateIndices.meta = {
    title: <FormattedMessage id="page.eventTemplates.configure.indices.title" />,
    subtitle: <FormattedMessage id="page.models.configure.indices.subtitle" />,
    routes: ['/events/event-templates/indices'],
    icon: 'ion-ios-gear-outline',
    menus: { main: false, user: false },
    order: 30
};

const mapStateToProps = state => ({
    company: state.identity.company,
    indexTypes: state.indices.types,
    indices: state.indices.list,
    statuses: state.indices.statuses,
    total: state.indices.total
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getProfile,
            getIndexTypes,
            getIndexStatuses,
            getIndices,
            createIndex,
            updateIndex,
            deleteIndex
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EventTemplateIndices);
