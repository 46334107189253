import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { login, getProfile } from '../../../reducers/identity';
import { Fields } from '../../../components/fields';
import { history } from 'configuration/history';

class Login extends React.Component {
    handleSubmit = async (values, { setFieldError, setSubmitting }) => {
        const { login, getProfile } = this.props;
        this.setState({ ...this.state, error: undefined }, () =>
            login(
                values,
                response => {
                    if (window.fcWidget) {
                        window.fcWidget.setTags(["logged"]); 
                    } else {
                        setTimeout(() => window.fcWidget.setTags(["logged"]), 5000);
                    }
                    window.gtag('config', 'G-K1SHP5P637', {
                        'user_id': response.user.id
                    });
                    if (response.requires_mfa) {
                        history.push('/mfa' + window.location.search);
                    } else {
                        getProfile();
                    }
                    setSubmitting(false);
                },
                errorResponse => {
                    const message = errorResponse && errorResponse.error ? errorResponse.error : 'error.default';
                    setFieldError('login', message);
                    setSubmitting(false);
                }
            )
        );
    };

    render() {
        return (
            <Formik
                initialValues={{ email: '', password: '' }}
                validationSchema={Yup.object().shape({
                    email: Yup.string()
                        .email(<FormattedMessage id="validation.invalid" />)
                        .required(<FormattedMessage id="validation.required" />),
                    password: Yup.string()
                        .matches(/^[^\r\n<>@#$%&\^\*{{}}/\+=~\\`]*$/, <FormattedMessage id="invalidCharactersPassword" />)
                        .min(8, <FormattedMessage id="validation.length.min" values={{ length: 8 }} />)
                        .required(<FormattedMessage id="validation.required" />)
                })}
                onSubmit={this.handleSubmit}
                render={({ isSubmitting, errors }) => (
                    <Form noValidate className="login">
                        <div className="form-group">
                            <Fields.Input type="email" name="email" className="form-control" showError={true} />
                        </div>
                        <div className="form-group">
                            <Fields.Input type="password" name="password" className="form-control" showError={true} />
                            <Link to={{ pathname: '/forgot', search: window.location.search }} className="tx-12 d-block mg-t-10">
                                <FormattedMessage id="page.account.forgot.link" />
                            </Link>
                        </div>
                        {errors && errors.login && (
                            <div className="form-group account-error">
                                <FormattedMessage id={errors.login} />
                            </div>
                        )}
                        <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting}>
                            <FormattedMessage id="login" />
                        </button>
                    </Form>
                )}
            />
        );
    }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            login,
            getProfile
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);
