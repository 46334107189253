export const TRANSACTIONS_SEARCH = `
    query(
        $status_ids: [Int!]
        $model_ids: [Int!]
        $created_from: DateTime
        $created_to: DateTime
        $result_index_field_ids: [Long!]
        $criteria: JsonString
        $field_criteria: JsonString
        $order_by: [OrderBy]
        $offset: Int
        $limit: Int
        $transaction_type: Int
        $include_last_visualized: Boolean
    ) {
        transaction_search(
            input: {
                status_ids: $status_ids
                model_ids: $model_ids
                created_from: $created_from
                created_to: $created_to
                result_index_field_ids: $result_index_field_ids
                criteria: $criteria 
                field_criteria: $field_criteria 
                transaction_type: $transaction_type
                include_last_visualized: $include_last_visualized
            }
            paging: { order_by: $order_by, offset: $offset, limit: $limit }
        ) {
            total
            data {
                id
                title
                description
                status_id
                status_name

                model_id
                model_title

                match_strength
                
                created_on
                modified_on
                user_id
                user_name
                user_email
                
                modified_on
                modified_by_user_id
                modified_by_user_name
                modified_by_user_email
    		
                transaction_type
                transaction_type_name

                index_fields_data
                
                history {
                    id
                    created_on
                }

                model {
                    id
                    title
                    contains_inaccessible_dots
                }
            }
        }
    }
`;
