export const TRANSACTIONS_UPDATE = `
    mutation M(
        $transaction_id: Id!
        $status_id: Int!
        $user_id: Long
        $source_cargo_id: Long
        $result_cargo_id: Long
        $title: String
        $description: String
        $notes: String
        $bc_transaction_id: String
        $extra_info: JsonString
        $fields: JsonString
    ) {
        transaction_update(
            transaction_id: $transaction_id
            input: {
                status_id: $status_id
                user_id: $user_id
                source_cargo_id: $source_cargo_id
                result_cargo_id: $result_cargo_id
                title: $title
                description: $description
                notes: $notes
                bc_transaction_id: $bc_transaction_id
                extra_info: $extra_info
                fields: $fields
            }
        ) {
            id
            status_id
            status_name
            model_id
            company_id
            user_id
            created_on
            source_cargo_id
            result_cargo_id
            title
            description
            notes
            bc_transaction_id
            extra_info
    		
    		transaction_type
    		transaction_type_name

            model {
                id
                title
            }

            source_cargo {
                code
                extra_info
            }

            result_cargo {
                code
                extra_info
            }

            history {
                id
                created_on
                accessed_on
                processed_on
                locked_on
                locked_by
                company_id
                user_id
                status_id
                status_name
                type_id
                type_name
                operation_id
                operation_name
                object_id
                bc_batch_id
                bc_block_id
                bc_transaction_id
            }

            transaction_field_list {
                id
                transaction_id
                field_id
                value
                company_id: model_company_id
                order_by: field_order_by
                lot_id
                mapped_lot_id
                quantity
                cargo_unit_id
                in_revision
                can_unwind
            }
        }
    }
`;
