export const ENTITY_GROUP_LIST = `
    query M ( 
        $locale: String
        $list_public: Boolean
    )  {
        entity_group_list (
            input: {
                locale: $locale
                list_public: $list_public
            }
        ) {
            id
            label
            description
            detailed_description
            icon
            metadata
            status_id
            locale
            dot_template_count
            dash_template_count
            document_template_count
        }
    }
`;