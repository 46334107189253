import React, { useState } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Field from '../../../models/field';
import FieldPreview from '../../models/fields/components/fieldPreview';

import './../../models/models.scss';
import { arrayEquals } from '../../../utilities/common';
import CargoQuantityField from './cargoQuantityField'
import CargoUnitField from './cargoUnitForm'
import { RowBreak } from '../../models/fields/definitions/common'
import { evaluateConditions } from '../../models/fields/components/visibilityConditions/conditionParser';
import Tooltip from 'rc-tooltip';
import SelectControl from '../../../components/fields/uncontrolledComponents/selectControl';
import { getGroupedSections, inheritMappedFieldProperties } from 'pages/events/eventUtils'
import CargoTypeModal from 'pages/lots/components/cargoTypeModal';
import { tooltipWrapper } from 'utilities/common'
import transaction from 'reducers/transaction';
import LoadingOverlay from 'react-loading-overlay';
import ClipLoader from 'react-spinners/ClipLoader';

function InputDotsSelectedPreview(props) {
    const { dotsList, inputDots, createdInputDot, dotTypes, message, readOnly = false, event, referenceType, onInputDotTypeMapped = () => null, dashFieldId, isTransform, transactionField, showSelectedQuantity, fieldValues } = props;
    const [ cargoTypeModalOpen, setCargoTypeModalOpen ] = useState(false);
    const [ selectedCargoType, setSelectedCargoType ] = useState(null);
    const [ selectedDot, setSelectedDot ] = useState(null);
    const [ selectedDotId, setSelectedDotId ] = useState(transactionField.mapped_lot_id);

    let selectedInputDots = dotsList.filter(d => inputDots.some(i => i.lot_id === d.id));

    if (createdInputDot && selectedInputDots.length === 0) {
        const inputDot = dotsList.find(d => d.id === createdInputDot);
        selectedInputDots = [ inputDot ];
    }

    const onIdSelected = id => {  
        const targetDot = dotsList.find(d => d.id === id);
        // When previewing a DoT type for an input type field, show the quantity
        // that will be consumed instead of the actual quantity of the full DoT
        if (showSelectedQuantity) {
            const targetInputDot = inputDots.find(sd => sd.lot_id === id);
            targetDot.quantity = targetInputDot.quantity;
            targetDot.original_quantity = targetInputDot.quantity;
        }
        const targetDotType = dotTypes.find(dt => dt.id === targetDot.cargo_type.id);
        if (targetDotType) {
            setSelectedCargoType(targetDotType);
            setSelectedDot(targetDot);
            setCargoTypeModalOpen(true);
        }
    }

    const onMapFieldsClicked = () => {
        const selectedDotType = dotTypes.find(dt => dt.id === selectedDot.cargo_type_hash_id);
        if (selectedDotType) {
            onInputDotTypeMapped(dashFieldId, selectedDotType, selectedDot.id);
            setCargoTypeModalOpen(false);
            setSelectedDotId(selectedDot.id);
        }
    }

    let fieldDotTypeMatchesCurrentType = true;
    if (transactionField && fieldValues && fieldValues[transactionField.field_id] && selectedCargoType){
        const selectedDotTypeId = fieldValues[transactionField.field_id];
        const selectedValues = fieldValues[selectedDotTypeId];
        for (const dotTypeField of selectedCargoType.transaction_field_list) {
            const matchingField = selectedValues[dotTypeField.field_id];
            const valueObject = JSON.parse(dotTypeField.value);
            const valueProperty = valueObject[Object.keys(valueObject)[0]];
            const isEqual = (i1, i2) => {
                if (Array.isArray(i1) && Array.isArray(i2)) {
                    return i1.length === i2.length && i1.every((value, index) => value === i2[index])
                } else {
                    return i1 === i2;
                }
            }
            if (matchingField === undefined || !isEqual(matchingField, valueProperty)) {
                fieldDotTypeMatchesCurrentType = false;
                break;
            }
        }
    }

    const isDotSelected = id => {
        const targetDot = dotsList.find(d => d.id === id);
        const targetDotType = dotTypes.find(dt => dt.id === targetDot.cargo_type.id);

        let fieldDotTypeMatchesCurrentType = true;
        if (transactionField && fieldValues && fieldValues[transactionField.field_id] && targetDotType){
            const selectedDotTypeId = fieldValues[transactionField.field_id];
            const selectedValues = fieldValues[selectedDotTypeId];
            for (const dotTypeField of targetDotType.transaction_field_list) {
                const matchingField = selectedValues[dotTypeField.field_id];
                const valueObject = JSON.parse(dotTypeField.value);
                const valueProperty = valueObject[Object.keys(valueObject)[0]];
                const isEqual = (i1, i2) => {
                    if (Array.isArray(i1) && Array.isArray(i2)) {
                        return i1.length === i2.length && i1.every((value, index) => value === i2[index])
                    } else {
                        return i1 === i2;
                    }
                }
                if (matchingField === undefined || !isEqual(matchingField, valueProperty)) {
                    fieldDotTypeMatchesCurrentType = false;
                    break;
                }
            }
        }
        return fieldDotTypeMatchesCurrentType;
    }

    return  <React.Fragment>
        <CargoTypeModal
            modalOpen={cargoTypeModalOpen}
            setModalOpen={(open) => setCargoTypeModalOpen(open)}
            cargoType={selectedCargoType}
            showQuantityInfo={true}
            dot={selectedDot}
            readOnly={readOnly}
            primaryButton={
                (referenceType === 1 || !referenceType) ?
                    <div className="col-3" id='save-final-btn'>
                        <button 
                            type="button" 
                            className="btn btn-primary btn-block" 
                            disabled={(event.status_id !== 1 && event.status_id !== 7) || readOnly || fieldDotTypeMatchesCurrentType} 
                            onClick={() => onMapFieldsClicked()}
                        >
                            { 
                                fieldDotTypeMatchesCurrentType ? 
                                    <FormattedMessage id="selectedForMapping" /> 
                                    : 
                                    <span>
                                        <FormattedMessage id="useDotForMapping" />
                                        
                                    <Tooltip 
                                        placement={'top'}
                                        trigger={['hover']}
                                        mouseEnterDelay={0.5}
                                        overlay={
                                            <React.Fragment>
                                                <p><FormattedMessage id="useDotForFieldMappingExplanation" /></p>
                                                <p><FormattedMessage id="useDotForFieldMappingExplanation2" /></p>
                                            </React.Fragment>
                                        }
                                    >
                                        <i className="material-icons-outlined" style={{fontSize: "14px", marginLeft: "0.5em"}}>info</i>
                                    </Tooltip>
                                    </span>
                            }
                        </button>
                    </div>
                    :
                    null
            }
        />
        <p className={'se-affected-dots-' + dashFieldId} style={{marginBottom: '0.5rem'}}>
            {message}
            {
                selectedInputDots.length === 0 && <FormattedMessage id="common.loading" />
            }
            {
                selectedInputDots.map((d, index) => (
                    <span key={d.id}>
                        <a className={ isDotSelected(d.id) ? 'dorae_selectedDot ' : null } onClick={e => { e.stopPropagation(); e.preventDefault(); onIdSelected(d.id);}} href='#'>
                            { d.hash_id }
                        </a>
                        {index === selectedInputDots.length - 1 ? '' : <span>, </span>}
                    </span>
                ))
            }
        </p>
        {
            (event.status_id === 1 || (event.status_id === 7 && transactionField.in_revision)) &&
            <p style={{margin: '0'}}>{ isTransform ? <FormattedMessage id="clickChooseDotToTransformToClear" /> : <FormattedMessage id="clickChooseInputDotToClear" /> }</p>
        }
    </React.Fragment>
}  

export default function DotFieldPreview(props) {
    const { 
        templateField, 
        event, 
        dotTypes, 
        dotTemplates, 
        formProps, 
        comparators, 
        fields, 
        eventDotTemplates, 
        cargoCategoryModalTargetFields, 
        loading, 
        initializeInputLotState, 
        setTargetDotTemplate,
        onDotFieldTemplateSelect,
        fieldDots,
        transactionIsUpdating,
        dotsList,
        onDotFieldQuantitySelected,
        onDotFieldUnitSelected,
        readOnly,
        onReviseClicked = () => null,
        fieldMappings = [],
        onChangeExt,
        onInputDotTypeMapped,
        newDotQuantity,
        fieldMappingUsages,
        onUseMappedValueToggle,
        fieldsMappingToLockedDots,
        targetDotUnit,
        onUseUserTransformValueToggle,
        userTransformUsages,
        extendedProps,
    } = props;
    const { values } = formProps;
    const [ prefilledFieldsVisibility, setPrefilledFieldsVisibility ] = useState(true);
    const [ isExpanded, setIsExpanded ] = useState(templateField.cargoReferenceType === 2);

    // Check if the field should be hidden
    const result = evaluateConditions(templateField, values, fields, comparators);
    if (!result) {
        return <RowBreak key={'br-' + templateField.id} width={12} />;
    }

    const transactionField = event.transaction_field_list.find(tf => tf.field_id === templateField.id);
    let transformType = null;
    if (templateField.transformCargoType) {
        transformType = dotTypes.find(dt => dt.id === templateField.transformCargoType);
    }
    let cargoUnspecified = false;
    let txCargoTypeId;
    const transactionFieldPopulated = transactionField && (transactionField.value && transactionField.value.startsWith('{'))
    if (transactionFieldPopulated) {
        // Fetch the referenced cargo type for this tx field so we can properly namespace the values
        const value = JSON.parse(transactionField.value);
        txCargoTypeId = value[Object.keys(value)[0]];
        if ((!txCargoTypeId || txCargoTypeId === '') && !templateField.cargoCategory) {
            cargoUnspecified = true;
        }
    } else if (!templateField.cargoCategory) {
        cargoUnspecified = true;
    }

    let unitBackgroundColor='';
    if ( extendedProps ){
        if( readOnly ){
            const { unitFieldBackgroundColor } = extendedProps;
            unitBackgroundColor= unitFieldBackgroundColor;
        }
    }

    let selectBackgroundColor='';
    if ( extendedProps ){
        if( readOnly ){
            const { selectFieldBackgroundColor } = extendedProps;
            selectBackgroundColor= selectFieldBackgroundColor;
        }
    }
    
    if (cargoUnspecified) {
        // If there is no cargo category specified, allow the user to choose
        const ccOptions = dotTemplates ? dotTemplates.filter(c => c.status_id == 2).map(c => ({value: c.id, label: c.title})) : [];
        return (
            <div key={templateField.id + '-cargoCategory'} className={`col-12 pd-b-15`}>
                <div className={'field-section  expanded'}>
                    <div className="card primary row">
                        <div className="card-header bg-transparent no-select">
                            <div className="title-wrapper">
                                <h6 className="card-title tx-uppercase tx-12 mg-b-0 ellipsis"><FormattedMessage id='common.unassignedCargo'/></h6>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="section-body pd-x-12">
                                <div className="widget-2 row">
                                    <SelectControl
                                        name='transactionCargoCategory'
                                        options={ccOptions}
                                        submitOnChange={true}
                                        onChange={(valueObj) => {
                                            setTargetDotTemplate({ fieldId: templateField.id, selectedValue: valueObj });
                                        }}
                                        disabled={(event.status_id !== 1 && event.status_id !== 7) || readOnly}
                                        isClearable={true}
                                        showLabel={false}
                                        showIsRequired={true}
                                        width={12}
                                        backgroundColor={selectBackgroundColor}
                                    />
                                    {
                                        (cargoCategoryModalTargetFields && cargoCategoryModalTargetFields[templateField.id]) 
                                        &&
                                        <div className="col-12 mg-b-20">
                                            <div className='row d-flex justify-content-center align-items-center pd-t-15'>
                                                <div className={"col-sm-6 col-md-3"}>
                                                    <button type="button" className={'btn btn-outline-primary btn-block'} onClick={() => {
                                                        onDotFieldTemplateSelect({ fieldId: templateField.id })
                                                    }}>
                                                        <FormattedMessage id="common.confirmCargoCategory" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else if (transactionFieldPopulated) {
        // Fetch the event template cargo type so we know which fields to hide
        const templateCargoType = dotTypes.find(ct => ct.id === templateField.cargoType);

        // fetch the list of all fields from the cargo category
        const thisCargoType = dotTypes.find(ct => ct.id === txCargoTypeId);
        const cargoCategory = eventDotTemplates.find(cc => cc.id === (thisCargoType ? thisCargoType.model_id : null));
        const cargoCategoryFields = cargoCategory === undefined ? [] : cargoCategory.model_field_list;
        const cargoTypeFields = [];
        
        
        let incompletedCargoFields = [];
        let completedCargoFields = [];
        if (templateCargoType) {
            templateCargoType.transaction_field_list.forEach(field => {
                cargoTypeFields.push(field);
            });
            // We hide any fields that had values hard coded within the event template,
            // Or show them (disabled) at the user's request for convenience
            incompletedCargoFields = cargoCategoryFields.filter(unformattedFieldToShow => {
                const fieldToShow = Field.fromDB(unformattedFieldToShow);
                const fieldInCargoType = cargoTypeFields.find(f => f.field_id === fieldToShow.id);
                if (fieldInCargoType === undefined) {
                    return true;
                }
                const fieldValueObj = JSON.parse(fieldInCargoType.value);
                const valuePropertyName = fieldToShow.type.toLowerCase() + '_value';
                if (fieldValueObj.hasOwnProperty(valuePropertyName)) {
                    const fieldValue = fieldValueObj[valuePropertyName];
                    if (fieldValue === null || fieldValue === undefined || fieldValue === "" || arrayEquals(fieldValue, [""]) || arrayEquals(fieldValue, [])) {
                        return true;
                    } else {
                        return false;
                    }
                }
                return true;
            });
            completedCargoFields = cargoCategoryFields
                .filter( f => !(incompletedCargoFields.map(incf => incf.id).includes(f.id)) );
        } else {
            // No fields were filled in the Event Template: show all fields
            incompletedCargoFields = cargoCategoryFields;
        }
        incompletedCargoFields = incompletedCargoFields
            .concat(completedCargoFields)
            .map(f => {
                const correspondingMapping = fieldMappings.find(fm => fm.map_dash_field_id === templateField.id && fm.map_dot_field_id === f.id);
                f.isMappedField = !!correspondingMapping;
                if (transformType) {
                    const transformField = transformType.transaction_field_list.find(tf => tf.field_id === f.id);
                    if (transformField) {
                        f.allow_user_transform = transformField.allow_user_transform;
                        f.use_user_transform = transformField.use_user_transform;
                    }
                }
                return f;
            });
        // Group them in case the cargo category contains any sections
        const groupedCargoTypeFields = getGroupedSections(
            incompletedCargoFields.map(f => {
                f.isDotField = true;
                f.dash_field_id = templateField.id;
                return f;
            }),
            fieldMappings,
            fields, 
            eventDotTemplates,
            fieldsMappingToLockedDots,
        ).map(ctf => { 
            const ungroupedField = incompletedCargoFields.find(f => f.id === ctf.id);
            if (ungroupedField) {
                ctf.isMappedField = ungroupedField.isMappedField;
            }
            ctf.id = txCargoTypeId + '.' + ctf.id; 
            return ctf; 
        });

        const transactionNotDraftOrRevision = (event.status_id !== 1 && !(event.status_id === 7 && transactionField.in_revision));
        const inputCargoHasLotAssigned = (templateField.cargoReferenceType === 1 || templateField.cargoReferenceType === 3 || !templateField.cargoReferenceType) 
            && ( 
                (!!fieldDots && !!fieldDots['lot']) 
                || !!transactionField.lot_id 
                || (transactionField.input_lots && transactionField.input_lots.length > 0)
            );
        let initialTargetQuantity = transactionField ? transactionField.quantity : 0;
        if (fieldDots && fieldDots['quantity']) {
            initialTargetQuantity = fieldDots['quantity'];
        }
        let initialTargetCargoUnit = transactionField ? transactionField.cargo_unit_id : null;
        if (!!targetDotUnit) {
            initialTargetCargoUnit = targetDotUnit;
        }
        if (initialTargetCargoUnit === null || (templateField.is_static_unit && templateField.default_unit)) {
            initialTargetCargoUnit = templateField.default_unit;
        }
        if (templateField.cargoReferenceType !== 2 && dotsList && dotsList.length === 1) {
            const inputDots = transactionField.input_lots;
            if (inputDots && inputDots.length > 0) {
                const first = inputDots[0];
                initialTargetQuantity = first.quantity;
            }
            initialTargetCargoUnit = dotsList[0].cargo_unit_id;
        }
        const prefilledFieldIds = completedCargoFields.map(f => txCargoTypeId + '.' + f.id);
        const fieldIsPrefilled = (ctf) => prefilledFieldIds.includes(ctf.id);
        let referencedLotId = null;
        const referencedLot = dotsList.find(l => l.id === transactionField.lot_id);
        if (referencedLot && referencedLot.status_name !== 'voided') {
            referencedLotId = referencedLot.hash_id;
            if (transactionNotDraftOrRevision && templateField.cargoReferenceType === 2) {                
                initialTargetQuantity = referencedLot.original_quantity;
                initialTargetCargoUnit = referencedLot.cargo_unit_id;
            } else {                
                if (event.status_id === 7 && transactionField.in_revision && transactionField.created_dot_all_consumed) {
                    initialTargetQuantity = transactionField.revised_remainder_quantity;
                }
                initialTargetCargoUnit = referencedLot.cargo_unit_id;
            }
        }
        let quantityTooltip;
        if (
            templateField.cargoReferenceType === 2 && event.status_id === 7 
            && transactionField.in_revision && referencedLot
        ) {
            if (transactionField.created_dot_all_consumed) {
                quantityTooltip = (
                    <>
                        <p><FormattedMessage id="addRemainderToConsumedDotMessage1" /></p>
                        <p><FormattedMessage id="addRemainderToConsumedDotMessage2" /></p>
                        <p><FormattedMessage id="addRemainderToConsumedDotMessage3" /></p>
                    </>
                );
            } else if (referencedLot.quantity !== referencedLot.original_quantity) {
                quantityTooltip = (
                    <>
                        <p><FormattedMessage id="revisionQuantityMessage1" values={{ originalQuantity: referencedLot.original_quantity, claimedQuantity: (referencedLot.original_quantity - referencedLot.quantity)}} /></p>
                        <p><FormattedMessage id="revisionQuantityMessage2" /></p>
                    </>
                );
            }
        }
        if (transactionField.input_lots && transactionField.input_lots.length > 0) {
            const lotIds = transactionField.input_lots.map(l => l.lot_id);
            const referencedLots = dotsList.filter(l => lotIds.indexOf(l.id) > -1).map(l => l.hash_id);
            if (referencedLots.length > 0) {
                referencedLotId = referencedLots.join(', ');
            }
        }

        let affectedDotsMessage = (
            <InputDotsSelectedPreview 
                event={event}
                message={<FormattedMessage id='eventConsumedLot' />}
                dotsList={dotsList} 
                inputDots={transactionField.input_lots} 
                dotTypes={dotTypes}
                readOnly={readOnly}
                createdInputDot={transactionField.lot_id}
                referenceType={transactionField.cargo_reference_type}
                onInputDotTypeMapped={onInputDotTypeMapped}
                dashFieldId={templateField.id}
                transactionField={transactionField}
                showSelectedQuantity={true}
                fieldValues={formProps.values}
            />
        );
        let isTransform = false;
    
        if (templateField.cargoReferenceType === 2) {
            affectedDotsMessage = <FormattedHTMLMessage id='eventProducedLot' tagName="span" values={{id: referencedLotId}} />
        } else if (templateField.cargoReferenceType === 3) {
            isTransform = true;
            affectedDotsMessage = (
                <InputDotsSelectedPreview 
                    event={event}
                    message={<FormattedMessage id='eventTransformedLot' />}
                    dotsList={dotsList} 
                    inputDots={transactionField.input_lots} 
                    dotTypes={dotTypes}
                    readOnly={readOnly}
                    referenceType={transactionField.cargo_reference_type}
                    onInputDotTypeMapped={onInputDotTypeMapped}
                    dashFieldId={templateField.id}
                    transactionField={transactionField}
                    showSelectedQuantity={true}
                    fieldValues={formProps.values}
                />
            );
        }

        const initializeInputLotStateFunc = initializeInputLotState(templateField.id, txCargoTypeId);

        const showInputDotsPreview = ((event.status_id === 1 || (event.status_id === 7 && transactionField.in_revision)) && groupedCargoTypeFields.length !== 0 && inputCargoHasLotAssigned && !newDotQuantity);
        const showAffectedDots = ( transactionNotDraftOrRevision && transactionField.lot_id && (templateField.cargoReferenceType !== 2) && referencedLotId )

        let quantityDisabled = transactionNotDraftOrRevision || readOnly;
        let unitDisabled = transactionNotDraftOrRevision || readOnly;

        let useMappedQuantity = false;
        let quantityIsMappedTo = false;
        const parentQuantityMapping = fieldMappings.find(fm => fm.map_dash_field_id === templateField.id && fm.owning_special_dot_field_id === 1);
        if (parentQuantityMapping) {
            quantityIsMappedTo = true;
            useMappedQuantity = fieldMappingUsages[parentQuantityMapping.map_dash_field_id + '.l1'];
            if (useMappedQuantity === undefined) {
                useMappedQuantity = true;
            }
        }
        if (useMappedQuantity && !quantityDisabled) {
            quantityTooltip = (
                <>
                    <p><FormattedMessage id="fieldIsBeingMappedMessage1" /></p>
                    <p><FormattedMessage id="fieldIsBeingMappedMessage2" /></p>
                </>
            );
        }

        let useMappedUnit = false;
        let unitIsMappedTo = false;
        let unitTooltip = null;
        const parentUnitMapping = fieldMappings.find(fm => fm.map_dash_field_id === templateField.id && fm.owning_special_dot_field_id === 2);
        if (parentUnitMapping) {
            unitIsMappedTo = true;
            useMappedUnit = fieldMappingUsages[parentUnitMapping.map_dash_field_id + '.l2'];
            if (useMappedUnit === undefined) {
                useMappedUnit = true;
            }
        }
        if (useMappedUnit && !unitDisabled) {
            unitTooltip = (
                <>
                    <p><FormattedMessage id="fieldIsBeingMappedMessage1" /></p>
                    <p><FormattedMessage id="fieldIsBeingMappedMessage2" /></p>
                </>
            );
        }

        const field = transactionField;

        const quantityMappingButton = (
            <button 
                type='button' 
                className={`btn btn-outline-info ${(loading || quantityDisabled) && 'disabled'}`}
                style={{ width: '2.8em', height: '3em', padding: 0 }}
                onClick={() => !(loading || quantityDisabled) && onUseMappedValueToggle(templateField.id + '.l1')}
            >
                <LoadingOverlay
                    active={loading}
                    spinner={<ClipLoader size={20} color={'white'}/>}
                    style={{
                        wrapper: {
                            height: '100%'
                        }
                    }}
                >
                    <div
                        style={{
                            height: '2.9em',
                            paddingTop: '.75em'
                        }}
                    >
                        <i 
                            className="material-icons-outlined"
                            style={{ fontSize: '1.5em', verticalAlign: 'middle' }}
                        >
                            { useMappedQuantity ? 'layers_clear' : 'layers' }
                        </i>
                    </div>
                </LoadingOverlay>
            </button>
        );
        const unitMappingButton = (
            <button 
                type='button' 
                className={`btn btn-outline-info ${(loading || unitDisabled) && 'disabled'}`}
                style={{ width: '2.8em', height: '3em', padding: 0 }}
                onClick={() => !(loading || unitDisabled) && onUseMappedValueToggle(templateField.id + '.l2')}
            >
                <LoadingOverlay
                    active={loading}
                    spinner={<ClipLoader size={20} color={'white'}/>}
                    style={{
                        wrapper: {
                            height: '100%'
                        }
                    }}
                >
                    <div
                        style={{
                            height: '2.9em',
                            paddingTop: '.75em'
                        }}
                    >
                        <i 
                            className="material-icons-outlined"
                            style={{ fontSize: '1.5em', verticalAlign: 'middle' }}
                        >
                            { useMappedUnit ? 'layers_clear' : 'layers' }
                        </i>
                    </div>
                </LoadingOverlay>
            </button>
        );

        const quantityMappingEl = quantityDisabled ? 
            quantityMappingButton
            :
            <Tooltip 
                key={'fmb-' + templateField.id + '-l1'}
                placement={'top'} 
                trigger={['hover']} 
                overlay={<FormattedMessage id={field.use_mapped_quantity_value ? 'overrideMappingTooltip' : 'allowMappingTooltip'} />}
                mouseEnterDelay={0.2}
            >
                { quantityMappingButton }
            </Tooltip>

        const unitMappingEl = unitDisabled ? 
            unitMappingButton
            :
            <Tooltip 
                key={'fmb-' + templateField.id + '-l1'}
                placement={'top'} 
                trigger={['hover']} 
                overlay={<FormattedMessage id={field.use_mapped_unit_value ? 'overrideMappingTooltip' : 'allowMappingTooltip'} />}
                mouseEnterDelay={0.2}
            >
                { unitMappingButton }
            </Tooltip>

        const quantityAppendEl = quantityIsMappedTo ? [quantityMappingEl] : undefined;

        const unitAppendEl = unitIsMappedTo ? [unitMappingEl] : undefined;


        // Render the cargo type identically to a section field, containing all of the cargo type fields
        // These fields are namespaced using the cargo type ID so formik will track them separately (hence: txCargoTypeId + '.' + ctf.id)
        return <div key={txCargoTypeId + '.' + templateField.id} className={`col-12 pd-b-15 pd-x-30`}>
                <div className={`field-section  ${isExpanded ? 'expanded' : 'collapsed'}`}>
                    <div className="card primary row" style={{paddingTop: 0, paddingBottom: 0}}>
                        <div className={"card-header bg-transparent no-select se-field-expand-" + templateField.id} onClick={() => setIsExpanded(!isExpanded)}>
                            <div className='row title-wrapper' style={{width: '100%', lineHeight: '24px'}}>
                                <div className={((showInputDotsPreview || showAffectedDots) && (prefilledFieldIds.length > 0)) ? 'col-2 text-left' : 'col-3 text-left'}>
                                    <Tooltip placement={'top'} trigger={['hover']} overlay={templateField.title + (cargoCategory ? ` (${cargoCategory.title})` : '')} mouseEnterDelay={2}>
                                        <h6 className="card-title tx-uppercase tx-12 mg-b-0 ellipsis" style={{}}>{templateField.title + (cargoCategory ? ` (${cargoCategory.title})` : '')}</h6>
                                    </Tooltip>
                                </div>
                                <div className="col-2 text-left">
                                    {
                                        (event.status_id === 7 && !transactionField.in_revision)
                                        &&
                                        (
                                            transactionField.can_unwind ?
                                            <button 
                                                type="button" 
                                                className="btn btn-outline-info dorae_btn_adjustable"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    onReviseClicked(transactionField.id);
                                                }}
                                            >
                                                <FormattedMessage id="reviseDotField" />
                                            </button>
                                            :
                                            tooltipWrapper(
                                                true,
                                                <FormattedMessage id='dotFieldCanNotBeRevised' />,
                                                <button 
                                                    type="button" 
                                                    className="btn btn-primary dorae_btn_adjustable" 
                                                    disabled={true} 
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                    }}
                                                >
                                                    <FormattedMessage id="reviseDotField" />
                                                </button>,
                                                'top'
                                            )
                                        )
                                    }
                                    {   // Set up component/state for selecting input Lot
                                        (!(event.status_id === 7 && !transactionField.in_revision) && ( templateField.cargoReferenceType === 1 || !templateField.cargoReferenceType || templateField.cargoReferenceType === 3 )) &&
                                            <button type="button" 
                                                className={`btn btn-outline-primary pd-x-15 se-dot-input-button-${templateField.id} ${(transactionNotDraftOrRevision || readOnly) && 'disabled'} dorae_btn_adjustable`} 
                                                disabled={transactionNotDraftOrRevision || transactionIsUpdating || readOnly} 
                                                onClick={e => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    initializeInputLotStateFunc();
                                                }}
                                            >
                                                { (templateField.cargoReferenceType === 3) ? <FormattedMessage id="chooseDotToTransform" /> : <FormattedMessage id="page.transactions.lot.chooseInput" /> }
                                            </button>
                                    }
                                </div>
                                <div className={(showInputDotsPreview || showAffectedDots) ? 'col-5 text-left' : ((prefilledFieldIds.length > 0) ? 'col-4' : 'col-5')}>
                                    {
                                        showInputDotsPreview &&
                                        <div className={`h6 pd-t-0 mg-b-0 text-left`}>
                                            <InputDotsSelectedPreview 
                                                event={event}
                                                message={<FormattedMessage id="selectedInputDots:" />}
                                                dotsList={dotsList} 
                                                inputDots={transactionField.input_lots} 
                                                dotTypes={dotTypes}
                                                referenceType={transactionField.cargo_reference_type}
                                                onInputDotTypeMapped={onInputDotTypeMapped}
                                                dashFieldId={templateField.id}
                                                isTransform={isTransform}
                                                transactionField={transactionField}
                                                showSelectedQuantity={true}
                                                fieldValues={formProps.values}
                                            />
                                        </div>
                                    }
                                    {
                                        showAffectedDots &&
                                        <div className={`h6 pd-t-0 mg-b-0 text-left`}>
                                            { affectedDotsMessage }
                                        </div>
                                    }
                                </div>
                                <div className={(prefilledFieldIds.length > 0) ? 'col-3' : 'col-2'}>
                                    {
                                        (prefilledFieldIds.length > 0) &&
                                        <div className={`checkbox-field withPadding pd-b-5 float-right`} onClick={(e) => e.stopPropagation()}>
                                            <label className="ckbox float-right">
                                                <input type="checkbox" checked={prefilledFieldsVisibility} onClick={(e) => e.stopPropagation()} onChange={(e) => { e.stopPropagation(); setPrefilledFieldsVisibility(!prefilledFieldsVisibility)}}/>
                                                <span className={`ckbox-label-text${prefilledFieldsVisibility ? ' checked' : ''}`} onClick={(e) => e.stopPropagation()}><FormattedMessage id="common.showPrefilledFields" /></span>
                                            </label>
                                        </div>
                                    }
                                    <Tooltip
                                        placement={'top'}
                                        trigger={['hover']}
                                        overlay={<span>{isExpanded ? <FormattedMessage id="common.collapse" /> : <FormattedMessage id="common.expand" />}</span>}
                                    >
                                        <i className={'material-icons no-select ' + (prefilledFieldIds.length === 0 ? 'float-right' : '')} >{isExpanded ? 'arrow_drop_up' : 'arrow_drop_down'}</i>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="section-body pd-x-12">
                                <div className="widget-2">
                                    <div className="row">
                                        <div className="col-12 pd-0">
                                            {
                                                (groupedCargoTypeFields.length === 0) ?
                                                    <div className={`field-text pd-b-15 col-12`}>
                                                        <FormattedMessage id="common.noDotFieldSelected" />
                                                    </div>
                                                    :
                                                    groupedCargoTypeFields.map(ctf => {
                                                        ctf.onChangeExt = onChangeExt;
                                                        return (
                                                            <FieldPreview 
                                                                key={ctf.id + '-' + !loading} 
                                                                field={ctf} 
                                                                parentId={txCargoTypeId}
                                                                fields={incompletedCargoFields.map(f => {
                                                                    const mappedField = Field.fromDB(f);
                                                                    if (f.hiddenField) mappedField.hiddenField = true;
                                                                    mappedField.isDotField = f.isDotField;
                                                                    mappedField.dash_field_id = f.dash_field_id;
                                                                    return inheritMappedFieldProperties(mappedField, fieldMappings, fields, eventDotTemplates);
                                                                })} 
                                                                formProps={formProps} 
                                                                comparators={comparators} 
                                                                modelTypeId={2} 
                                                                showIsRequired={ctf.is_required}
                                                                prefilledFieldIds={prefilledFieldIds}
                                                                disabled={
                                                                    transactionNotDraftOrRevision 
                                                                    || fieldIsPrefilled(ctf) 
                                                                    || readOnly 
                                                                    || (
                                                                        templateField.cargoReferenceType !== 2 
                                                                        && templateField.cargoReferenceType !== 3
                                                                        && !newDotQuantity
                                                                    )
                                                                }
                                                                hiddenField={ctf.hiddenField}
                                                                showPrefilledFields={prefilledFieldsVisibility}
                                                                onChangeExt={onChangeExt}
                                                                fieldMappingUsages={fieldMappingUsages}
                                                                onUseMappedValueToggle={onUseMappedValueToggle}
                                                                isTransformField={templateField.cargoReferenceType === 3}
                                                                onUseUserTransformValueToggle={onUseUserTransformValueToggle}
                                                                userTransformUsages={userTransformUsages}
                                                                extendedProps={extendedProps}
                                                            />
                                                        )
                                                    })
                                            }
                                        </div>
                                        {   
                                            // Set up component/state for selecting output DoT quantity/DoT unit
                                            ( templateField.cargoReferenceType === 2 || (dotsList && dotsList.length === 1) ) 
                                            &&
                                            <React.Fragment>
                                                <div className={`pd-b-15 col-4 offset-2`}>
                                                    <CargoQuantityField
                                                        customLabel={templateField.quantityLabel}                                                        
                                                        disabled={transactionNotDraftOrRevision || readOnly || useMappedQuantity || templateField.cargoReferenceType !== 2 }
                                                        onQuantitySelected={(quantity) => {
                                                            onDotFieldQuantitySelected({ fieldId: templateField.id, quantity: quantity }, () => {
                                                                let quantityMappingUsage = false;
                                                                const quantityMapping = fieldMappings.find(fm => fm.owning_dash_field_id === templateField.id && fm.owning_special_dot_field_id === 1);
                                                                if (quantityMapping) {
                                                                    quantityMappingUsage = fieldMappingUsages[quantityMapping.map_dash_field_id + '.l1'];
                                                                    if (quantityMappingUsage === undefined) {
                                                                        quantityMappingUsage = true;
                                                                    }
                                                                }
                                                                if (quantityMappingUsage) {
                                                                    onDotFieldQuantitySelected({ fieldId: quantityMapping.map_dash_field_id, quantity: quantity });
                                                                }
                                                            });
                                                        }}
                                                        initialTargetQuantity={initialTargetQuantity}
                                                        tooltipOverlay={quantityTooltip}
                                                        seleniumId={templateField.id}
                                                        appendEl={quantityAppendEl}
                                                    />
                                                </div>
                                                <CargoUnitField                                                     
                                                    disabled={transactionNotDraftOrRevision || readOnly || (templateField.is_static_unit && templateField.default_unit) || useMappedUnit || templateField.cargoReferenceType !== 2  }
                                                    onCargoUnitSelected={(value) => {
                                                        onDotFieldUnitSelected({ fieldId: templateField.id, value: value }, () => {
                                                            let unitMappingUsage = false;
                                                            const unitMapping = fieldMappings.find(fm => fm.owning_dash_field_id === templateField.id && fm.owning_special_dot_field_id === 2);
                                                            if (unitMapping) {
                                                                unitMappingUsage = fieldMappingUsages[unitMapping.map_dash_field_id + '.l2'];
                                                                if (unitMappingUsage === undefined) {
                                                                    unitMappingUsage = true;
                                                                }
                                                            }
                                                            if (unitMappingUsage) {
                                                                onDotFieldUnitSelected({ fieldId: unitMapping.map_dash_field_id, value: value });
                                                            }
                                                        });
                                                    }}
                                                    initialTargetCargoUnit={initialTargetCargoUnit}
                                                    width={4}
                                                    seleniumId={templateField.id}
                                                    appendEl={unitAppendEl}
                                                    tooltipOverlay={unitTooltip}
                                                    backgroundColor={unitBackgroundColor}
                                                />
                                            </React.Fragment>
                                        }
                                        {
                                            ( transactionNotDraftOrRevision && transactionField.lot_id && (templateField.cargoReferenceType === 2) && referencedLotId )
                                            &&
                                            <div className={`pd-b-15 col-4 offset-4 tx-center`}>
                                                <h6 className={"tx-14 mg-b-0 tx-inverse se-output-dots-" + templateField.id}>
                                                    { affectedDotsMessage }
                                                </h6>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    }

    return <div key={templateField.id}/>;
}
