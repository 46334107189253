import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSupportedLocales, setLocale } from '../../../reducers/localization';

import './language.scss';

class LanguageMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { locale, setLocale } = this.props;
        const locales = getSupportedLocales();
        return (
            <nav>
                <div className="dropdown">
                    <span className="nav-link nav-link-profile" data-toggle="dropdown">
                        <span className="nav-link language-name pd-x-7 pos-relative">{locales.find(l => l.locale === locale).locale.toUpperCase()}</span>
                    </span>
                    <div className="dropdown-menu dropdown-menu-header wd-200">
                        <ul className="list-unstyled user-profile-nav">
                            {locales.map(({ locale, name }) => (
                                <li key={locale} className="locale-link" onClick={() => setLocale(locale, true)}>
                                    <span>{name}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
}

const mapStateToProps = state => ({
    locale: state.localization.locale
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setLocale
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LanguageMenu);
