export const LINKS_USE = `
    mutation M($link_name: String!, $link_token: String!) {
        link_use(link_name: $link_name, link_token: $link_token) {
            id
            type_id
            type_name
            name
            created_on
            exp_on
            data
            clicks
            uses
            max_uses
            is_valid
        }
    }
`;
