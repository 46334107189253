export const ENTITY_GROUP_UPDATE = `

    type EntityData {
        entity_type	: String!
        entity_hash_id	: Id
        entity_id      : Long
    }

    mutation M (
        $id: Long
        $label: String
        $description: String
        $detailed_description: String
        $icon: String
        $metadata: String
        $entities: [EntityData!]
        $status: Int
        $locale: String!
    )  
    {
        entity_group_update(
            input: {
                id: $id
                label: $label
                description: $description
                detailed_description: $detailed_description
                icon: $icon
                metadata: $metadata
                entities: $entities
                status_id: $status
                locale: $locale
            }
        )
        {
            id
            label
            description
            detailed_description
            icon
            metadata
            entities
            status_id
            locale

            dashes {
                id
            }
            dots {
                id
            }
            templates {
                id
                hash_id
                company_id
                status_id
                status_name
                created_on
                title
                description
                is_used
                split_conditions
                extra_info
                created_by_name
                model_type
                model_type_name
                is_locked

                model_field_list {
                    id
                    model_id
                    order
                    type_id
                    type_name
                    status_id
                    status_name
                    index_field_id
                    input_id
                    visibility_check
                    title
                    description
                    is_required
                    validation
                    width
                    force_break
                    extra_info
                    company_id: model_company_id
                    cargoCategory: cargo_category
                    cargo_type
                    transform_cargo_type
                    cargo_reference_type
                    is_static_unit
                    default_unit

                    model_field_value_list {
                        id
                        company_id
                        field_id
                        order_by
                        is_hidden
                        value
                    }
                }
            }
            document_templates {
                id
                hash_id
                model_id
                title
                description
                version
                document_type
                created_on
                updated_at
                file_data
                metadata
            }
            documents {
                id
            }
            tags {
                id
                hash_id
                order_by
                type_id
                type_name
                status_id
                status_name
                title
                description
                extra_info
                is_used
                model_type
                model_type_name
                created_on
            }
        }
    }
`;