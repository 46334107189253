import React from 'react';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import EventTemplatesTable from 'pages/event-templates/components/table';
import LoadingOverlay from 'react-loading-overlay';
import { createTransaction } from 'reducers/transaction';
import { history } from 'configuration/history';

class CreateEventModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            eventSaving: false,
            modalCloseRequested: false,
            selectedEventTemplate: null,
        }
    }

    chooseEventModel = id => {
        this.setState({ selectedEventTemplate: id });
        const container = document.querySelector('.ReactModal__Content');
        container.scrollTo({ top: container.scrollHeight, behavior: "smooth" });
    }

    overrideMenuEl = () => {
        return (d) => (
            <div className="actions-wrapper">
                <div className="form-check">
                    <input
                        type="radio"
                        name="selectedEventTemplate"
                        value={d.id}
                        checked={this.state.selectedEventTemplate === d.id}
                        className="form-check-input"
                        onChange={() => this.chooseEventModel(d.id)}
                    />
                </div>
            </div>
        )
    }

    render() {
        const { modalOpen = false, setModalOpen = () => null, cargoType = null, createTransaction } = this.props;

        return (
            <ReactModal
                isOpen={modalOpen}
                onRequestClose={() => setModalOpen(false)}
                className="modal-block dialog"
                overlayClassName="modal-overlay gray"
                style={{content: {width: '70%', maxHeight: '70%', overflowY: 'auto', overflowX: 'hidden'}}}
            >
                <div className="modal-dialog-centered" role="document">
                    <div className="modal-content bd-0">
                        <div className="modal-header pd-x-20">
                            {
                                this.state.eventSaving ?
                                    <div style={{ position: 'relative' }}>
                                        <div className="loader" />
                                        <span className="mg-l-5">
                                            <FormattedMessage id="common.saving" />
                                        </span>
                                    </div>
                                    :
                                    <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                                        <FormattedMessage id="common.chooseEventTemplate" />
                                    </h6>
                            }
                        </div>
                        <LoadingOverlay
                            active={this.state.isLoading}
                            spinner
                            text={<FormattedMessage id='common.loading'/>}
                        >
                            <div className="modal-body br-section-wrapper">
                                <EventTemplatesTable
                                    overrideMenuEl={this.overrideMenuEl()}
                                    overrideIdOnClick={d => this.chooseEventModel(d.id)}
                                    overrideTitleOnClick={d => this.chooseEventModel(d.id)}
                                    externalOptions={{ status_ids: [2] }}
                                    externalStatuses={[{ id: 2, status_name: 'Active' }]}
                                    statusSelectDisabled={true}
                                />
                            </div>
                        </LoadingOverlay>
                        <div className="modal-footer justify-content-center">
                            <button
                                type="button"
                                className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                disabled={!this.state.selectedEventTemplate || this.state.isLoading}
                                onClick={() =>
                                    this.setState({ isLoading: true }, () => {
                                        createTransaction(this.state.selectedEventTemplate, 'transaction', transaction => history.push(`/events/${transaction.id}` + window.location.search))
                                    })
                                }
                            >
                                <FormattedMessage id="createEvent" />
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                onClick={() => setModalOpen(false)}
                            >
                                <FormattedMessage id="common.close"  />
                            </button>
                        </div>
                    </div>
                </div>
            </ReactModal>
        );
        
    }
}

const mapStateToProps = state => ({
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            createTransaction,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateEventModal);