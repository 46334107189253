export const DELETE_SHARED_ENTITY = `
    mutation M (
        $id             : Long!
        $entity_type	: String!
    )  
    {
        external_sharing_delete(
            input: {
                id		        : $id
                entity_type     : $entity_type      
            }
        )
        {
            id              
            entity_type       
            entity_id     
            target_email      
            include_transactions
            accepted
            is_deleted
        }
    }
`;