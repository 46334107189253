import React from 'react';
import { toast, ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './notifications.scss';

class NotificationsContainer extends React.Component {
    render() {
        const urlParams = new URLSearchParams(window.location.search);
        const toastPersist = urlParams.get('toastPersist');
        const autoClose = toastPersist ? false : 5000;
        return (
            <ToastContainer
                position="top-right"
                closeButton={false}
                autoClose={autoClose}
                hideProgressBar
                newestOnTop
                closeOnClick
                draggable={false}
                rtl={false}
                pauseOnHover
                toastClassName="notification-box"
            />
        );
    }
}

const renderContent = (message, closeToast, hideClose, type = 'info') => {
    return (
        <div className={`alert alert-${type}`}>
            {!hideClose && (
                <button type="button" className="close" aria-hidden="true" onClick={closeToast}>
                    ×
                </button>
            )}
            {message}
        </div>
    );
};

let instances = [];

const launch = (message, options, hideClose = false, type = 'info') => {
    for (const inst of instances) {
        toast.dismiss(instance)
    }
    instances = [];
    let content = ({ closeToast }) => renderContent(message, closeToast, hideClose, type);
    let instance = toast.error(content, options);
    instances.push(instance);
};

class Notifications {
    static success = (message, options, hideClose = false) => launch(message, options, hideClose, 'success');
    static info = (message, options, hideClose = false) => launch(message, options, hideClose, 'info');
    static warning = (message, options, hideClose = false) => launch(message, options, hideClose, 'warning');
    static error = (message, options, hideClose = false) => launch(message, options, hideClose, 'danger');
}

export { Notifications, NotificationsContainer };
