import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { 
    getAlert, 
    updateAlert, 
    unsubscribeAlert, 
    modifyAlertSubscriber, 
    deleteAlert,
    CONDITION_TYPES,
    updateCondition,
    deleteCondition,
    setConditionReference,
 } from 'reducers/alerts';
import { Formik, Form } from 'formik';
import { Fields } from 'components/fields';
import { injectIntl, FormattedMessage } from 'react-intl';
import ConditionFieldPicker from './conditionFieldPickerModal';
import { debounce } from 'utilities/common';
import LoadingOverlay from 'react-loading-overlay';
import { getComparators, getIndexValues, getFieldValues } from 'reducers/fieldVisibility';
import { FieldTypes, FieldComparatorTypes, getComparatorSymbol } from 'models/field';
import { getCurrencyOptions } from 'utilities/constants';
import { listCargoUnits } from 'reducers/cargoUnits';
import { tooltipWrapper } from 'utilities/common'
import { getFormattedFieldValue } from 'models/field';
import Tooltip from 'rc-tooltip';

import 'pages/models/models.scss';

class AlertCondition extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            conditionModalVisible: false,
        };
    }

    componentDidMount() {
        const { comparators, getComparators, listCargoUnits } = this.props;
        if (!comparators) getComparators();
        listCargoUnits();
    }

    debouncedUpdate = debounce((values) => {
        const { setRootLoadingState, item, updateCondition } = this.props;
        const onComplete = () => setRootLoadingState(false);
        if (values.comparator === null || values.comparator === undefined) {
            onComplete();
            return;
        }
        const fieldType = this.getFieldTypeFromItem();
        const value = JSON.stringify(getFormattedFieldValue(fieldType, values.filterValue, !Array.isArray(values.filterValue)));
        updateCondition({ condition_id: item.id, comparator_id: values.comparator, value: value }, onComplete, onComplete);
    }, 500);

    removeCondition = (id) => {
        const { deleteCondition, item, getAlert } = this.props;
        const { alert_id } = item;
        const refresh = () => {
            getAlert({alert_id: alert_id});
        }
        deleteCondition({ condition_id: id }, refresh, refresh);
    }

    typeLabel = () => {
        const { item } = this.props;
        const { type_id, } = item;
        return (
            <span className='h6'>
                <FormattedMessage id='type'/>: &nbsp;
                {type_id === CONDITION_TYPES.event && <FormattedMessage id='event' />}
                {type_id === CONDITION_TYPES.index && <FormattedMessage id='index' />}
                {type_id === CONDITION_TYPES.dot && <FormattedMessage id='dot' />}
            </span>
        )
    }

    fieldLabel = () => {
        const { item } = this.props;
        const { type_id, } = item;
        if (this.isFieldSelected()) {
            let selectedField;
            switch(type_id) {
                case CONDITION_TYPES.event:
                    selectedField = (      
                        <p><FormattedMessage id='index'/> </p>
                    )
                    break;
                case CONDITION_TYPES.index:
                    selectedField = (      
                        <strong>{item.index_field.title}</strong>
                    )
                    break;
                case CONDITION_TYPES.dot:
                    selectedField = (      
                        <strong>{item.template.title} {item.dot_field}</strong>
                    )
                    break;
                default:
                    return null;
            }
            return (<h6>
                    {selectedField} &nbsp;
                    <button className='btn btn-icon btn-outline-secondary' onClick={() => this.clearSelectedField()} disabled={this.props.disabled}>
                        <i className="material-icons-outlined" style={{ color: 'gray'}}>delete_forever</i>
                    </button>
                </h6>);
        } else {
            switch(type_id) {
                case CONDITION_TYPES.event:
                    return (      
                        <button className='btn btn-primary' onClick={() => this.setState({ conditionModalVisible: true })} disabled={this.props.disabled}>
                            {type_id === CONDITION_TYPES.event && <FormattedMessage id='chooseEventField' />}
                        </button>
                    )
                case CONDITION_TYPES.index:
                    return (
                        <button className='btn btn-primary' onClick={() => this.setState({ conditionModalVisible: true })} disabled={this.props.disabled}>
                            {type_id === CONDITION_TYPES.index && <FormattedMessage id='chooseIndexField' />}
                        </button>
                    )
                case CONDITION_TYPES.dot:
                    return (
                        <button className='btn btn-primary' onClick={() => this.setState({ conditionModalVisible: true })} disabled={this.props.disabled}>
                            {type_id === CONDITION_TYPES.dot && <FormattedMessage id='chooseDotField' />}
                        </button>
                    )
                default:
                    return null;
            }
        }
    }

    isFieldSelected = () => {
        const { item } = this.props;
        const { type_id, } = item;
        switch(type_id) {
            case CONDITION_TYPES.event:
                return !!item.template;
            case CONDITION_TYPES.index:
                return !!item.index_field
            case CONDITION_TYPES.dot:
                return !!item.template
            default:
                return false;
        }
    }

    clearSelectedField = () => {
        const { item } = this.props;
        const { type_id, } = item;
        this.onFieldSelect({type: type_id, id: null });
    }

    onFieldSelect = (data) => {
        const { item, setRootLoadingState, setConditionReference, getAlert } = this.props;
        setRootLoadingState(true);
        const onComplete = () => {
            const setLoadState = () => {
                setRootLoadingState(false);
                this.setState({ updatingCondition: false });
            }
            getAlert({alert_id: item.alert_id}, setLoadState, setLoadState);
        }
        this.setState({ updatingCondition: true }, () => {
            setConditionReference({ condition_id: item.id, data: JSON.stringify(data) }, onComplete, onComplete);
        })
    }

    getFieldTypeFromItem = () => {
        const { item } = this.props;
        let type;
        switch(item.type_id) {
            case 1:
                if (item.field) {
                    type = item.field.type_name;
                }
            break;
            case 2:
                if (item.index_field) {
                    type = item.index_field.type_name;
                }
            break;
            case 3:
                if (item.dot_field === 'Quantity') {
                    type = FieldTypes.FIELD_NUMBER;
                } else {
                    type = FieldTypes.FIELD_SELECT;
                }
            break;
        }
        return type;
    }

    getFieldFromItem = () => {
        const { item, cargoUnits = [] } = this.props;
        let field;
        switch(item.type_id) {
            case CONDITION_TYPES.event:
                field = item.field;
            break;
            case CONDITION_TYPES.index:
                field = item.index_field;
            break;
            case CONDITION_TYPES.dot:
                if (item.dot_field === 'Quantity') {
                    field = {
                        type: FieldTypes.FIELD_NUMBER
                    };
                } else {
                    field = {
                        type: FieldTypes.FIELD_SELECT,
                        fieldValues: cargoUnits.map(c => ({ value: c.display_name, label: <FormattedMessage id={c.display_name} /> }))
                    };
                }
            break;
        }
        return field;
    }

    getLoadFieldValuesFuncFromItem = () => {
        const { getIndexValues, getFieldValues, item, cargoUnits = [] } = this.props;
        let loadIndexValues = () => null;
        switch(item.type_id) {
            case CONDITION_TYPES.event:
                if (item.field) {
                    loadIndexValues = getFieldValues;
                }
            break;
            case CONDITION_TYPES.index:
                if (item.index_field) {
                    loadIndexValues = getIndexValues;
                }

            break;
            case CONDITION_TYPES.dot:
                if (item.dot_field !== 'Quantity') {
                    loadIndexValues = () => cargoUnits.map(i => ({ value: i.display_name, label: <FormattedMessage id={i.display_name} /> }))
                }
            break;
        }
        return loadIndexValues;
    }

    getComparators = values => {
        const { comparators, item } = this.props;
        if (!comparators) return [];
        const { comparator } = values;
        const field = this.getFieldFromItem();
        const type = this.getFieldTypeFromItem();
        const loadIndexValues = this.getLoadFieldValuesFuncFromItem();
        if (!field) return [];

        const { NONE, EQUALS, GREATER, LESS, GREATER_EQUAL, LESS_EQUAL, NOT_EQUAL, BETWEEN, CONTAINS, DOES_NOT_CONTAIN, IN, LIKE, NOT_LIKE, IS_NULL } = FieldComparatorTypes;
        
        // const field = fields.filter(f => f.type !== FieldTypes.FIELD_SECTION && f.type !== FieldTypes.FIELD_CARGO).find(f => f.visibility_id === field) || {};
        let comparatorInputs = [];
        switch (type) {
            case FieldTypes.FIELD_TEXT:
                const textInput = (
                    <div className="col-12 pd-l-0" key={`${field}-${type}-${comparator}`}>
                        <Fields.Input name="filterValue" type="text" value={values.filterValue} disabled={this.props.disabled}/>
                    </div>
                );
                comparatorInputs = [
                    { comparator: NONE, input: null },
                    { comparator: IS_NULL, input: null },
                    { comparator: EQUALS, input: textInput },
                    { comparator: NOT_EQUAL, input: textInput },
                    { comparator: LIKE, input: textInput },
                    { comparator: NOT_LIKE, input: textInput }
                ];
                break;

            case FieldTypes.FIELD_NUMBER:
                const numberInput = (
                    <div className="col-12 pd-l-0" key={`${field}-${type}-${comparator}`}>
                        <Fields.Input name="filterValue" type="number" value={values.filterValue} disabled={this.props.disabled}/>
                    </div>
                );
                if (item.type_id === CONDITION_TYPES.dot) {
                    // reduce number of comparators for DoT Quantity field
                    comparatorInputs = [
                        { comparator: EQUALS, input: numberInput },
                        { comparator: NOT_EQUAL, input: numberInput },
                        { comparator: GREATER, input: numberInput },
                        { comparator: GREATER_EQUAL, input: numberInput },
                        { comparator: LESS_EQUAL, input: numberInput },
                        { comparator: LESS, input: numberInput }
                    ];
                } else {
                    comparatorInputs = [
                        { comparator: NONE, input: null },
                        { comparator: IS_NULL, input: null },
                        { comparator: EQUALS, input: numberInput },
                        { comparator: NOT_EQUAL, input: numberInput },
                        { comparator: GREATER, input: numberInput },
                        { comparator: GREATER_EQUAL, input: numberInput },
                        { comparator: LESS_EQUAL, input: numberInput },
                        { comparator: LESS, input: numberInput },
                        {
                            comparator: BETWEEN,
                            input: (
                                <div className="col-5 pd-l-0" key={`${field}-${type}-${comparator}`}>
                                    <div className="row">
                                        <div className="col-5 pd-r-0">
                                            <Fields.Input
                                                name="filterValue[0]"
                                                type="number"
                                                value={
                                                    values.filterValue && Array.isArray(values.filterValue) && values.filterValue.length > 0
                                                        ? values.filterValue[0]
                                                        : null
                                                }
                                                disabled={this.props.disabled}
                                            />
                                        </div>
                                        <div className="col-2 pd-x-0">
                                            <div className="field-inline-separator">
                                                <FormattedMessage id="common.and" />
                                            </div>
                                        </div>
                                        <div className="col-5 pd-l-0">
                                            <Fields.Input
                                                name="filterValue[1]"
                                                type="number"
                                                value={
                                                    values.filterValue && Array.isArray(values.filterValue) && values.filterValue.length > 1
                                                        ? values.filterValue[1]
                                                        : null
                                                }
                                                disabled={this.props.disabled}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    ];
                }
                break;
            case FieldTypes.FIELD_CURRENCY:
                const currencyInput = (
                    <div className="col-12 pd-l-0" key={`${field}-${type}-${comparator}`}>
                        <Fields.Select name="filterValue" options={getCurrencyOptions()} submitOnChange={true} value={values.filterValue} disabled={this.props.disabled} />
                    </div>
                );
                comparatorInputs = [
                    { comparator: EQUALS, input: currencyInput },
                    { comparator: NOT_EQUAL, input: currencyInput },
                ];
                break;

            case FieldTypes.FIELD_YES_NO:
                const booleanInput = (
                    <div className="col-12 pd-l-0" key={`${field}-${type}-${comparator}`}>
                        <Fields.Select
                            name="filterValue"
                            value={values.filterValue}
                            submitOnChange={true}
                            options={[
                                { value: 1, label: <FormattedMessage id="common.yes" /> }, 
                                { value: 0, label: <FormattedMessage id="common.no" /> },
                                { value: null, label: <FormattedMessage id="common.n/a" /> },
                            ]}
                            disabled={this.props.disabled}
                        />
                    </div>
                );

                comparatorInputs = [
                    { comparator: EQUALS, input: booleanInput },
                ];
                break;

            case FieldTypes.FIELD_DATE:
                const dateInput = (
                    <div className="col-12 pd-l-0" key={`${field}-${type}-${comparator}`}>
                        <Fields.Date submitOnChange={true} dateFormat={field.dateFormat} name="filterValue" value={values.filterValue} disabled={this.props.disabled}/>
                    </div>
                );

                comparatorInputs = [
                    { comparator: NONE, input: null },
                    { comparator: IS_NULL, input: null },
                    { comparator: EQUALS, input: dateInput },
                    { comparator: NOT_EQUAL, input: dateInput },
                    { comparator: GREATER, input: dateInput },
                    { comparator: GREATER_EQUAL, input: dateInput },
                    { comparator: LESS_EQUAL, input: dateInput },
                    { comparator: LESS, input: dateInput },
                    {
                        comparator: BETWEEN,
                        input: (
                            <div className="col-12 pd-l-0" key={`${field}-${type}-${comparator}`}>
                                <div className="row">
                                    <div className="col-5 pd-r-0">
                                        <Fields.Date
                                            submitOnChange={true}
                                            dateFormat={field.dateFormat}
                                            name="filterValue[0]"
                                            value={ (values.filterValue && Array.isArray(values.filterValue)) ? values.filterValue[0] : ''}
                                            disabled={this.props.disabled}
                                        />
                                    </div>
                                    <div className="col-2 pd-x-0">
                                        <div className="field-inline-separator">
                                            <FormattedMessage id="common.and" />
                                        </div>
                                    </div>
                                    <div className="col-5 pd-l-0">
                                        <Fields.Date
                                            submitOnChange={true}
                                            dateFormat={field.dateFormat}
                                            name="filterValue[1]"
                                            value={ (values.filterValue && Array.isArray(values.filterValue)) ? values.filterValue[1] : ''}
                                            disabled={this.props.disabled}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    }
                ];
                break;

            case FieldTypes.FIELD_SELECT:
                const selectInput = multi => (
                    <div className="col-12 pd-l-0" key={`${field}-${type}-${comparator}`}>
                        <Fields.AsyncSelect
                            name="filterValue"
                            value={
                                field && field.fieldValues
                                    ? field.fieldValues
                                        .map(i => ({ value: i.value, label: i.label ? i.label : i.value }))
                                        .find(i => i.value === values.filterValue)
                                    : undefined
                            }
                            submitOnChange={true}
                            multi={multi}
                            options={
                                field && field.fieldValues
                                    ? field.fieldValues.map(i => ({ value: i.value, label: !!i.label ? i.label : i.value }))
                                    : undefined
                            }
                            optionsLoader={s => loadIndexValues(field.id, s, () => null, r => r.map(i => ({ value: i.value, label: !!i.label ? i.label : i.value })))}
                            disabled={this.props.disabled}
                            useFormattedLabel={true}
                        />
                    </div>
                );
                comparatorInputs = [
                    { comparator: NONE, input: null },
                    { comparator: IS_NULL, input: null },
                    { comparator: EQUALS, input: selectInput(false) },
                    { comparator: NOT_EQUAL, input: selectInput(false) },
                    { comparator: IN, input: selectInput(true) }
                ];
                break;

            case FieldTypes.FIELD_MULTI_SELECT:
                const multiSelectInput = multi => (
                    <div className="col-12 pd-l-0" key={`${field}-${type}-${comparator}`}>
                        <Fields.AsyncSelect
                            name="filterValue"
                            value={values.filterValue}
                            submitOnChange={true}
                            multi={multi}
                            options={
                                field && field.fieldValues
                                    ? field.fieldValues.map(i => ({ value: i.value, label: i.value }))
                                    : undefined
                            }
                            optionsLoader={s => loadIndexValues(field.id, s, () => null, r => r.map(i => ({ value: i.value, label: i.value })))}
                            disabled={this.props.disabled}
                        />
                    </div>
                );
                comparatorInputs = [
                    { comparator: NONE, input: null },
                    { comparator: IS_NULL, input: null },
                    { comparator: CONTAINS, input: multiSelectInput(false) },
                    { comparator: DOES_NOT_CONTAIN, input: multiSelectInput(false) }
                ];
                break;

            case FieldTypes.FIELD_MULTI_TEXT:
                comparatorInputs = [
                    { comparator: NONE, input: null },
                    { comparator: IS_NULL, input: null },
                    {
                        comparator: CONTAINS,
                        input: (
                            <div className="col-12 pd-l-0" key={`${field}-${type}-${comparator}`}>
                                <Fields.Input name="filterValue" type="text" value={values.filterValue} disabled={this.props.disabled}/>
                            </div>
                        )
                    },
                    {
                        comparator: DOES_NOT_CONTAIN,
                        input: (
                            <div className="col-12 pd-l-0" key={`${field}-${type}-${comparator}`}>
                                <Fields.Input name="filterValue" type="text" value={values.filterValue} disabled={this.props.disabled}/>
                            </div>
                        )
                    }
                ];
                break;

            case FieldTypes.FIELD_ATTACHMENT:
                comparatorInputs = [
                    { comparator: NONE, input: null },
                    { comparator: IS_NULL, input: null },
                ];
                break;
                
            default:
                break;
        }

        return comparatorInputs.map(ci => ({
            ...ci,
            comparator: { value: (comparators.find(c => c.comparator_name === ci.comparator) || {}).id, label: getComparatorSymbol(ci.comparator) }
        }));
    };

    getParsedItemValue = () => {
        const { item } = this.props;
        try {
            if (item.value) {
                const fieldValueObj = JSON.parse(item.value);
                const keys = Object.keys(fieldValueObj);
                if (keys.length > 0) {
                    return fieldValueObj[keys[0]];
                }
            }
        } catch(err) {
            console.error(err)
            return null;
        }
        return null;
    }

    render() {
        const { item, setRootLoadingState, intl: { formatMessage } } = this.props;
        if (!item) return null;
        const { id, type_id, comparator_id, value } = item;
        const initialValues = {
            comparator: comparator_id,
            filterValue: this.getParsedItemValue()
        };

        return (
            <LoadingOverlay
                active={this.state.updatingCondition}
                spinner
                text={<FormattedMessage id='common.loading'/>}
            >
                <div className='card shadow-base' style={{padding: 15, margin: 15}}>
                    <Formik
                        enableReinitialize={false}
                        initialValues={ initialValues }
                        onSubmit={values => {
                            setRootLoadingState(true);
                            this.debouncedUpdate(values)
                        }}
                        render={( formProps ) => {
                            const comparatorInputs = this.getComparators(formProps.values);
                            return (
                                <Form 
                                    noValidate
                                    onChange={async e => {
                                        await formProps.handleChange(e);
                                        formProps.submitForm();
                                    }}
                                >
                                    <div className='row'>
                                        <div className="form-group col-2 tx-center">
                                            { this.typeLabel() }
                                        </div>
                                        <div className="form-group col-2">
                                            { this.fieldLabel() }
                                        </div>
                                        {
                                            !this.isFieldSelected() ?
                                                tooltipWrapper(
                                                    !this.isFieldSelected(),
                                                    <FormattedMessage id='youMustChooseFieldFirst'/>,
                                                    <div className="form-group col-3">
                                                        <Fields.Select
                                                            key={id}
                                                            name="comparator"
                                                            submitOnChange={true}
                                                            value={formProps.values.comparator}
                                                            options={comparatorInputs.map(c => c.comparator)}
                                                            disabled={true}
                                                        />
                                                    </div>,
                                                    'top'
                                                )
                                                :
                                                <Tooltip trigger={['hover']} placement='top' overlay={<span>{ formatMessage({ id: `comparator.placeholder` })}</span>}>
                                                    <div className="form-group col-3">
                                                        <Fields.Select
                                                            key={id}
                                                            name="comparator"
                                                            submitOnChange={true}
                                                            value={formProps.values.comparator}
                                                            options={comparatorInputs.map(c => c.comparator)}
                                                            disabled={this.props.disabled}
                                                            extraOnChange={() => formProps.setFieldValue('filterValue', null)}
                                                        />
                                                    </div>
                                                </Tooltip>
                                        }
                                        <div className="form-group col-4">
                                            {
                                                this.isFieldSelected() && !!formProps.values.comparator && (comparatorInputs.find(c => c.comparator.value === formProps.values.comparator) || {}).input
                                            }
                                        </div>
                                        <div className="col-1 pd-l-0">
                                            <button
                                                className="btn btn-primary icon-button field-inline float-right"
                                                type="button"
                                                onClick={() => this.removeCondition(id)}
                                                disabled={this.props.disabled}
                                            >
                                                <i className="material-icons-outlined">remove</i>
                                            </button>
                                        </div>
                                    </div>
                                    <ConditionFieldPicker 
                                        condition={item}
                                        showModal={this.state.conditionModalVisible}
                                        setModalVisibility={(val) => this.setState({ conditionModalVisible: val})}
                                        cargoCategories={this.props.cargoCategories} 
                                        setRootLoadingState={setRootLoadingState}
                                        conditionType={type_id}
                                        onFieldSelect={this.onFieldSelect}
                                    />
                                </Form>
                            );
                        }}
                    />
                </div>
            </LoadingOverlay>
        );
    }
}

const mapStateToProps = state => ({
        fields: state.fields.items,
        model: state.fields.model,
        comparators: state.fieldVisibility.comparators,
        cargoUnits: state.cargoUnits.list,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            deleteCondition,
            getAlert,
            setConditionReference,
            getFieldValues,
            getComparators,
            listCargoUnits,
            getIndexValues,
            updateCondition,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(AlertCondition));