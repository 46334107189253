import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Transactions from 'pages/events/index';
import MainPage from './containers/mainPage';
import FAQ from 'pages/faq/index';
import Collection from './containers/libraryCollection';
import LibraryCreateDashboard from './containers/createDashboard';
import LibraryFetchWrapper from './containers/libraryFetchWrapper';

class LibraryPages extends React.Component {
    render() {
        return (
            <Switch>
                <Route exact path="/library" component={props => <MainPage {...props} />} />
                <Route exact path="/library/faq" component={props => <FAQ {...props} />} />
                <Route exact path="/library/create" component={props => <LibraryCreateDashboard {...props} parents={[MainPage.meta]} />} />
                <Route path="/library/edit/:id" component={props => <LibraryFetchWrapper {...props} parents={[MainPage.meta]} ><LibraryCreateDashboard /></LibraryFetchWrapper>} parents={[LibraryPages.meta]} />
                <Route path="/library/:id" component={props => <LibraryFetchWrapper {...props} parents={[MainPage.meta]} ><Collection {...props} /></LibraryFetchWrapper>} />
            </Switch>
        );
    }
}

LibraryPages.meta = {
    title: <FormattedMessage id="page.library.title" defaultMessage="Library" />,
    routes: ['/library', '/libraries'],
    icon: 'ion-ios-help-outline',
    menus: { main: true, user: false },
    hideBreadcrumbs: true,
    order: 40,
    onlySubscribed: true
};

export default LibraryPages;
