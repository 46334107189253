import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import { injectIntl, FormattedMessage } from 'react-intl';
import ModelPreview from 'pages/models/fields/components/preview';
import FieldDefinition from 'models/field';
// import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import DashViewModal from 'pages/events/components/dashViewModal';
import CargoTypeModal from 'pages/lots/components/cargoTypeModal';
import WordDocumentViewer from 'pages/documentTemplates/components/wordDocumentViewer';
import ExcelDocumentViewer from 'pages/documentTemplates/components/excelDocumentViewer';
import { pdfjs, Document, Page } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


export default injectIntl(function EntityModal(props) {
    const { 
        open,
        setOpen,
        entityType,
        entity,
        allEntities,
        intl: { formatMessage },
        onClose = () => null,
    } = props;


    const [documentBlob, setDocumentBlob] = useState(null);
    const [numPages, setNumPages] = useState(null);
    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        setNumPages(nextNumPages);
    }

    useEffect(() => {
        if (entity.blob) {
            fetch(entity.blob.url, {
                method: 'GET',
                mode: 'cors',
                headers: {
                  'Access-Control-Allow-Origin':'*'
                }
            })
            .then(response => response.blob())
            .then(blob => setDocumentBlob(blob))
            .catch(err => console.log('error fetching blob', err));
        }
    }, [])

    if (entityType === 'dash') {
        return (
            <DashViewModal
                modalOpen={open}
                setModalOpen={open => setOpen(open)}
                dashId={entity.id}
                modalTitle={entity.id}
                showLink={false}
                showPastVersions={true}
                onClose={onClose}
            />
        )
    } else if (entityType === 'dot') {
        return (
            <CargoTypeModal
                modalOpen={open}
                setModalOpen={open => setOpen(open)}
                cargoType={entity.cargo_type}
                readOnly={true}
                onClose={onClose}
            />
        )
    }

    let contentStyle ={width: '70%', maxHeight: '90%', overflowY: 'hidden', overflowX: 'hidden'};
    if (entityType === 'document') {
        contentStyle.width = null;
        contentStyle.overflowY = 'auto';
    } else if (entityType === 'document-template') {
        contentStyle.height = '95%';
        contentStyle.maxHeight = '95%';
    }

    return (
        <ReactModal
            isOpen={open}
            onRequestClose={() => {
                setOpen(false)
                onClose();
            }}
            className="modal-block dialog"
            overlayClassName="modal-overlay gray"
            style={{zIndex: '2000', content: { ...contentStyle, minWidth: '800px' }}}
        >
            <>
                {entityType === 'document' && documentBlob &&
                    <Document 
                        file={documentBlob} 
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                    {
                        Array.from(
                            new Array(numPages),
                            (el, index) => (
                            <Page
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                            />
                            ),
                        )
                    }
                    {/* <Page
                        pageNumber={1}
                    /> */}
                    </Document>
                }
                {entityType === 'document-template' && entity.document_type === 'excel' &&
                    <div style={{ height: '100%', backgroundColor: '#fff', padding: '5px' }}>
                        <div style={{ padding: '1em', display: 'flex', flexDirection: 'row' }}>
                            <div style={{width: '50%'}}>
                                <h6 className="card-title">
                                    {entity.title}
                                </h6>
                            </div>
                            <div style={{ marginLeft: 'auto', width: '3em' }}>
                                <button
                                    type="button"
                                    className="btn btn-outline-info font-weight-bold ml-2 close-preview-btn"
                                    onClick={() => setOpen(false)}
                                >
                                    {'X'}
                                </button>
                            </div>
                        </div>
                        <div style={{ height: 'calc(100% - 5em)' }}>
                            <ExcelDocumentViewer
                                templateFileData={entity.file_data}
                                templateMetadata={entity.metadata}
                            />
                        </div>
                    </div>
                }
                {entityType === 'document-template' && entity.document_type === 'word' &&
                    <div style={{ height: '100%', backgroundColor: '#fff', padding: '5px' }}>
                        <div style={{ padding: '1em', display: 'flex', flexDirection: 'row' }}>
                            <div style={{width: '50%'}}>
                                <h6 className="card-title">
                                    {entity.title}
                                </h6>
                            </div>
                            <div style={{ marginLeft: 'auto', width: '3em' }}>
                                <button
                                    type="button"
                                    className="btn btn-outline-info font-weight-bold ml-2 close-preview-btn"
                                    onClick={() => setOpen(false)}
                                >
                                    {'X'}
                                </button>
                            </div>
                        </div>
                        <div style={{ height: 'calc(100% - 5em)' }}>
                            <WordDocumentViewer
                                templateFileData={entity.file_data}
                            />
                        </div>
                    </div>
                }
                {(entityType === 'dash-template' || entityType === 'dot-template') &&
                    <div style={{ height: '100%', backgroundColor: '#fff', padding: '1em 1em 1em 1em' }}>
                        <div style={{ marginBottom: '2em', display: 'flex', flexDirection: 'row' }}>
                            <div style={{width: '50%'}}>
                                <h6 className="card-title">
                                    {entity.title}
                                </h6>
                            </div>
                            <div style={{ marginLeft: 'auto', width: '3em' }}>
                                <button
                                    type="button"
                                    className="btn btn-outline-info font-weight-bold ml-2 close-preview-btn"
                                    onClick={() => setOpen(false)}
                                >
                                    {'X'}
                                </button>
                            </div>
                        </div>
                        <ModelPreview 
                            key={entity.id}
                            model={entity}
                            modelId={entity.id} 
                            modelTypeId={entityType === 'dash-template' ? 1 : 2} 
                            cargoCategories={allEntities.selectedDots} 
                            cargoTypes={[]} 
                            onShowFieldMaps={val => {
                                this.setState({ showFieldMaps: val });
                            }}
                            manualFields={entity.model_field_list.map(f => FieldDefinition.fromDB(f))}
                            hideMapFieldToggle={true}
                            hideHeader={true}
                        />
                    </div>
                }
            </>
        </ReactModal>
    )
});