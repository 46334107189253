export const RELEASE_ENTITY_LOCK = `
    mutation M (
        $entity_type	: String!
        $entity_hash_id	: Id
        $entity_id      : Long
    )  
    {
        entity_lock_release_lock(
            input: {
                entity_type             : $entity_type      
                entity_id		        : $entity_id           
                entity_hash_id          : $entity_hash_id   
            }
        )
        {
            user_id
            entity_type
            entity_id
            entity_hash_id   
            user_email
            user_name
        }
    }
`;