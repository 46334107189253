export const DELETE_DOCUMENT = `
    mutation($document_id: Long!) {
        document_delete(document_id: $document_id) {
            id
            name
            url
            s3_bucket
            s3_object
        }
    }
`;
