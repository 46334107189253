export const CONDITIONS_LIST = `
    query {
        alert_condition_list {
            total
            data {
                id: Id
                type_id: TypeId
                comparator_id: ComparatorId
                alert_id: AlertId
                order: Order
                break_or: BreakOr
                condition_event: ConditionEvent {
                    event_template: EventTemplate {
                        id
                        hash_id
                        company_id
                        status_id
                        status_name
                        created_on
                        title
                        description
                        is_used
                        split_conditions
                        extra_info
                        model_type
                        model_type_name
                        contains_inaccessible_dots
                        
                        model_field_list {
                            id
                            model_id
                            order
                            type_id
                            type_name
                            status_id
                            status_name
                            index_field_id
                            input_id
                            visibility_check
                            title
                            description
                            is_required
                            validation
                            width
                            force_break
                            extra_info
                            company_id: model_company_id
                            cargoCategory: cargo_category
                            cargo_type
                            cargo_reference_type

                            model_field_value_list {
                                id
                                company_id
                                field_id
                                order_by
                                is_hidden
                                value
                            }
                        }
                    }
                }
            }
        }
    }
`;
