export const COMPANIES_GET_INVITE_LINKS = `
    query($type_ids: [Int!], $show_valid: Boolean, $show_invalid: Boolean, $order_by: [OrderBy], $offset: Int, $limit: Int) {
        link_list(
            input: { type_ids: $type_ids, show_valid: $show_valid, show_invalid: $show_invalid }
            paging: { order_by: $order_by, offset: $offset, limit: $limit }
        ) {
            id
            type_id
            type_name
            name
            created_on
            title
            description
            exp_on
            data
            clicks
            uses
            max_uses
            is_valid
            link_token
        }
    }
`;
