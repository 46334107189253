import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Page from 'components/page';
import { getTransaction, createTransactionDocument, getTransactionDocuments, deleteTransactionDocument } from 'reducers/transaction';
import { loadModel, getModels } from 'reducers/models';
import EventContainer from './eventContainer';
import { Popover, Menu, MenuItem, } from '@blueprintjs/core';
import { findMedia } from 'reducers/media';
import { getDocumentTemplates } from 'reducers/documents';
import PrintContainer from 'components/print';
import DocumentsDialog from '../components/documentsDialog';
import LoadingOverlay from 'react-loading-overlay';
import { requestEntityLock, releaseEntityLock } from 'reducers/entityLock';
import CargoTypeModal from 'pages/lots/components/cargoTypeModal';
import moment from 'moment';

import './../transactions.scss';
import { getTransactions } from 'reducers/transactions';

class EventPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            invoices: [],
            print: false,
            documentsDialog: false,
            transactionLoading: false,
            cargoCategories: [],
            cargoTypes: [],
            transactionId: null,
            eventContainerRenderCnt: 0,
            pastVersions: [],
        };
        this.lockIntervalId = 0;
    }

    componentDidMount() {
        const { getTransaction, match, getModels, requestEntityLock, } = this.props;
        if (match && match.params && match.params.id) {
            this.setState({transactionLoading: true})
            const promises = [];
            promises.push(new Promise(resolve => {
                getTransaction(match.params.id, t => {
                    this.setState({ transactionId: t.id });
                    resolve();
                });
            }))
            promises.push(new Promise(resolve => {
                getModels({model_type: 2}, (cargoCategories) => {
                    this.setState({ cargoCategories: cargoCategories.data });
                    resolve();
                });
                requestEntityLock(
                    {
                        entity_type: 'dash',
                        entity_hash_id: match.params.id
                    },
                    (entityLock) => {
                        resolve();
                    }
                );
            }))
            Promise.all(promises).then(() => this.setState({ transactionLoading: false }));
        }
        this.lockIntervalId = setInterval(
            () => {
                const { lockedEntity } = this.props;
                if (this.props.user.id !== lockedEntity.user_id) {
                    getTransaction(match.params.id)
                }
                requestEntityLock({ entity_type: 'dash', entity_hash_id: match.params.id })
            },
            10 * 1000
        );
    }

    componentWillUnmount() {
        const { releaseEntityLock, match } = this.props;
        clearInterval(this.lockIntervalId);
        releaseEntityLock({
            entity_type: 'dash',
            entity_hash_id: match.params.id
        });
    }

    setPrint = print => {
        this.setState({ print });
    };

    renderActions = () => {

        return (
            <div className="btn-group pd-t-0">
                <button
                    className="btn btn-outline-info"
                    onClick={async e => {
                        e.preventDefault();
                        e.stopPropagation();
                        await this.setPrint(true);
                    }}
                >
                    <FormattedMessage id="common.print" />
                </button>
                {
                    (this.state.pastVersions.length > 0) &&
                    <button
                        type='button'
                        className="btn btn-outline-info" 
                        style={{padding: 0}}
                    >
                        <Popover
                            position="bottom-left"
                            captureDismiss
                            targetTagName="div"
                            wrapperTagName="div"
                            content={
                                <Menu>
                                    <div tabIndex={0} />
                                    {
                                        this.state.pastVersions
                                            .sort((first, second) => {
                                                const firstMom = moment(first.created_on);
                                                const secondMom = moment(second.created_on);
                                                if (firstMom.isBefore(secondMom)) {
                                                    return 1;
                                                } else if (firstMom.isAfter(secondMom)) {
                                                    return -1;
                                                } else {
                                                    return 0;
                                                }
                                            })
                                            .map((pv, index) => (
                                                <MenuItem 
                                                    key={pv.id}
                                                    text={<FormattedMessage id="revisedOn" values={{date: moment(pv.created_on).format('DD-MM-YYYY HH:MM'), version: (this.state.pastVersions.length - index)}} />}
                                                    onClick={() => this.setState({ selectedPastVersion: pv, selectedPastVersionDate: moment(pv.created_on).format('DD-MM-YYYY HH:MM'), pastVersionModalOpen: true })}
                                                />
                                            )
                                        )
                                    }
                                    
                                </Menu>
                            }
                        >
                            <div style={{ padding: '0.65rem 0.75rem', width: '100%', height: '100%' }}>
                                <FormattedMessage id="pastVersions" />
                            </div>
                        </Popover>
                    </button>
                }
            </div>
        );
    };

    eventPlaceholder = () => (
        <LoadingOverlay
            active={true}
            spinner
            text={<FormattedMessage id='common.loading'/>}
        >
            <div className="widget-2" style={{height: '20vw'}}>
                <div className="row">
                    <div className="col-12 pd-0">
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
        </LoadingOverlay>
    )

    render() {
        const { print, documentsDialog, invoices } = this.state;
        const { parents, transaction, match, company, findMedia, lockedEntity, user } = this.props;
        if (!match || !match.params || !match.params.id) return null;
        if (!company) return null;
        if (!transaction) return null;

        let eventReadLocked = false;
        let readLockedMessage = () => null;
        if (lockedEntity.user_id && lockedEntity.user_id !== user.id) {
            eventReadLocked = true;
            readLockedMessage = () => (
                <div style={{
                    backgroundColor: '#fff',
                    padding: '15px 20px',
                    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.21)',
                    margin: '10px'
                }}>
                    <h6 className="mg-b-0 tx-inverse tx-bold">
                        <FormattedHTMLMessage id='lockedEntityMessage' tagName="span" values={{ entity: 'Dash', name: lockedEntity.user_name, email: lockedEntity.user_email }} />
                    </h6>
                </div>
            )
        }

        return (
            <React.Fragment>
                <Page
                    {...{ ...EventPage.meta, title: `${transaction.model.title} (${transaction.id})` }}
                    parents={parents}
                    actions={this.renderActions()}
                    extraHeaderEls={readLockedMessage()}
                >
                    {
                        this.state.transactionLoading ?
                        this.eventPlaceholder()
                        :
                        <EventContainer
                            key={'event-container-' + this.state.eventContainerRenderCnt}
                            transaction={transaction} 
                            company={company} 
                            isLoading={this.state.transactionLoading} 
                            cargoCategories={this.state.cargoCategories} 
                            cargoTypes={this.state.cargoTypes} 
                            showSavingStatus={this.props.showSavingStatus}
                            showSaveButton={true}
                            readOnly={eventReadLocked}
                            reload={() => this.setState(prevState => ({ eventContainerRenderCnt: prevState.eventContainerRenderCnt + 1 }))}
                            setPastVersions={versions => this.setState({ pastVersions: versions })}
                            extendedProps={{ selectFieldBackgroundColor: '#e9ecef', unitFieldBackgroundColor:'#e9ecef'}}
                        />
                    }
                    <CargoTypeModal
                        modalOpen={this.state.pastVersionModalOpen}
                        setModalOpen={(open) => this.setState({ pastVersionModalOpen: open })}
                        cargoType={this.state.selectedPastVersion}
                        showQuantityInfo={false}
                        dot={null}
                        hideTitleAndDescription={false}
                        readOnly={true}
                        modalTitle={<FormattedMessage id='pastVersionRevisedOn' values={{ date: this.state.selectedPastVersionDate }}/>}
                    />
                </Page>
                <PrintContainer open={print} onClose={() => this.setPrint(false)}>
                    <div className="pd-x-30 pd-t-60">
                        <EventContainer 
                            transaction={transaction} 
                            company={company} 
                            isLoading={false} 
                            cargoCategories={this.state.cargoCategories} 
                            cargoTypes={this.state.cargoTypes} 
                            showSavingStatus={this.props.showSavingStatus}
                            showSaveButton={true}
                            readOnly={eventReadLocked}
                            showSaveButton={true}
                            onLoadComplete={async () => {
                                await window.print();
                                this.setPrint(false);
                            }}
                        />
                    </div>
                </PrintContainer>
                <DocumentsDialog
                    open={documentsDialog}
                    onClose={() => this.setDocumentsDialogVisibility(false)}
                    documents={invoices}
                    deleteDocument={id => this.deleteDocument(id)}
                    onItemClick={({ s3_object, s3_bucket }) => findMedia(s3_object, s3_bucket, ({ blob: { url } }) => window.open(url, '_blank'))}
                />
            </React.Fragment>
        );
    }
}

EventPage.meta = {
    showTitle: false,
    showSubtitle: false,
    title: <FormattedMessage id="page.transactions.single.title" />,
    subtitle: <FormattedMessage id="page.transactions.single.subtitle" />,
    routes: ['/events/'],
    icon: 'ion-arrow-swap',
    menus: { main: false, user: false },
    order: 0
};

const mapStateToProps = state => {
    return {
        company: state.identity.company,
        transaction: state.transaction.details,
        templates: state.documents.list,
        lockedEntity: state.entityLock.lockedEntity,
        user: state.identity.user,
    };
};
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getTransaction,
            getDocumentTemplates,
            findMedia,
            createTransactionDocument,
            getTransactionDocuments,
            deleteTransactionDocument,
            loadModel,
            getModels,
            getTransactions,
            requestEntityLock,
            releaseEntityLock,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EventPage);
