export const LIST_DOCUMENT_SELECTION = `
    mutation M($document_template_id: Long, $document_template_ids: [Long!]) {
        document_selection_list(input: { document_template_id: $document_template_id, document_template_ids: $document_template_ids }) {
            id
            name
            document_template_id
            created_by
            created_on
            field_reference_events
        }
    }
`;
