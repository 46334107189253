import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import Page from '../../components/page';
import IntroductionDashboard from './components/introduction';
import StaticDashboardContent from './components/static';
import { DefinedPermissions, canAccessPage } from '../../components/routes/permissions/permissionResolver';

import './dashboard.scss';

class Dashboard extends React.Component {
    render() {
        const { user, company, permissions } = this.props;
        if (!user || !company || !permissions) return null;
        const isCompanyAdmin = canAccessPage(permissions, { access: { score: DefinedPermissions.CompanyRoot.score } });

        return (
            company.is_paid ?
                <Page
                    {...Dashboard.meta}
                    subtitle={<FormattedMessage id="page.dashboard.subtitle" values={{ user: user.name }} />}
                    showTitleBlock={!!company.is_system}
                    panel={false}
                >
                    {!!company.is_system ? <StaticDashboardContent /> : <IntroductionDashboard user={user} company={company} isCompanyAdmin={isCompanyAdmin} />}
                </Page>
                :
                <Redirect to={{ pathname: '/settings', search: window.location.search }} />
        );
    }
}

Dashboard.meta = {
    title: <FormattedMessage id="page.dashboard.title" defaultMessage="Dashboard" />,
    routes: ['/dashboard'],
    icon: 'ion-ios-home-outline',
    menus: { main: true, user: false },
    hideBreadcrumbs: true,
    order: 0,
    onlySubscribed: true
};

const mapStateToProps = state => ({
    user: state.identity.user,
    company: state.identity.company,
    permissions: state.identity.permissions
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard);
