import React from 'react';
import { Field, FieldArray } from 'formik';
import { RowBreak, FieldDescription } from './common';
import { injectIntl, FormattedMessage } from 'react-intl';
import Tooltip from 'rc-tooltip';
import { getTextMaxLength } from '../../../../utilities/constants';
import TextField from './text';

class MultiTextField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.initialValue
        };
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.initialValue !== prevProps.initialValue) {
          this.setState({ value: this.props.initialValue });
        }
    }

    setCurrentTextField = (fieldId, value) => {
        const { id, setFieldValue } = this.props;
        const idParts = fieldId.split('.');
        if (idParts.length < 2) return;
        const index = idParts[idParts.length - 1];
        this.setState(prevState => {
            let fieldValues = prevState.value;
            if (fieldValues) {
                if (Array.isArray(fieldValues)) {
                    if (fieldValues.length === 0) {
                        return { value: [value] };
                    } else {
                        fieldValues.splice(index, 1, value)
                        return { value: fieldValues }
                    }
                } else if (typeof fieldValues === 'object') {
                    fieldValues = Object.values(fieldValues);
                    if (fieldValues.length === 0) {
                        return { value: [value] };
                    } else {
                        fieldValues.splice(index, 1, value)
                        return { value: fieldValues }
                    }
                } else {
                    return { value: [value] };
                }
            } else {
                return { value: [value] };
            }
        }, () => {
            setFieldValue(id, this.state.value);
        })
    }

    render() {
        const {
            id,
            width,
            breakRow,
            title,
            intl: { formatMessage },
            disabled,
            showIsRequired,
            prependEls = [],
            onChangeExt = () => null,
            tooltipOverlay,
            appendEls = [],
            seleniumId,
            setFieldValue,
        } = this.props;

        const { value } = this.state;
        let fieldValues = [''];
        
        if (value) {
            if (Array.isArray(value)) {
                if (value.length === 0) {
                    fieldValues = [''];
                } else {
                    fieldValues = value;
                }
            } else if (typeof value  === 'object') {
                fieldValues = Object.values(value);
            } else {
                fieldValues = [''];
            }
        }
        const noValuesYet = fieldValues.length === 1 && fieldValues[0] === '';
        const fieldEl = (
            <div style={{width: '100%', height: '100%'}}>
                {fieldValues.map((v, index) => (
                    <div key={index} className="d-flex justify-content-between align-items-center">
                        <TextField
                            id={`${id}.${index}`}
                            key={`${id}.${index}`}
                            title={!v ? `${formatMessage({ id: 'common.enter' })} ${title}` : ' '}
                            autoComplete="off"
                            className={`form-control mg-r-5 se-id-${seleniumId}`}
                            disabled={disabled}
                            setFieldValue={this.setCurrentTextField}
                            initialValue={v}
                            seleniumId={seleniumId}
                            wrapperClassName={' '}
                            wrapperStyle={{ width: '100%' }}
                            appendEls={
                                fieldValues.length - 1 === index ? 
                                ([
                                    <div className='pd-b-15' key='add-btn'>
                                        <button 
                                            type="button" 
                                            className={`btn btn-primary se-id-${seleniumId}-add-button`}
                                            onClick={async () => { 
                                                fieldValues.push('');
                                                onChangeExt(id, fieldValues);
                                                this.setState({ value: fieldValues });
                                                setFieldValue(id, fieldValues);
                                            }}
                                            disabled={disabled}
                                        >
                                            <i className="material-icons">add</i>
                                        </button>
                                        {[...appendEls]}
                                    </div>
                                ]) : ([
                                    <div className='pd-b-15' key='remove-btn'>
                                        <button 
                                            type="button" 
                                            className={`btn btn-warning se-id-${seleniumId}-remove-button`}
                                            onClick={async () => { 
                                                fieldValues.splice(index, 1);
                                                onChangeExt(id, fieldValues);
                                                this.setState({ value: fieldValues });
                                                setFieldValue(id, fieldValues);
                                            }} 
                                            disabled={disabled}
                                        >
                                            <i className="material-icons">remove</i>
                                        </button>
                                    </div>
                                ])
                            }
                        />
                        
                        <FieldDescription 
                            {...this.props} 
                            seleniumId={seleniumId}
                        />
                    </div>
                ))}
            </div>
        );

        const widthClass = width ? `-${width}` : '';
        return (
            <React.Fragment>
                <div className={`field-multiText col${widthClass}`}>
                    {[...prependEls]}
                    <div>
                        {
                            tooltipOverlay ?
                            <Tooltip
                                placement={'top'}
                                trigger={['hover']}
                                overlay={tooltipOverlay}
                                children={ fieldEl }
                            />
                            :
                            fieldEl
                        }
                        {(noValuesYet && showIsRequired) ? <div className="validation-message required-placeholder-multitext"><FormattedMessage id="validation.required" /></div> : null}
                        {(!noValuesYet) ?                           
                            <Tooltip trigger={['hover']} overlay={<span>{title}</span>} mouseEnterDelay={2}>
                                <div className="input-placeholder-multitext">{title}</div>
                            </Tooltip>: null                            
                        }
                    </div>
                </div>
                {breakRow && <RowBreak width={width} />}
            </React.Fragment>
        );
    }
}

export default injectIntl(MultiTextField);
