import React from 'react';
import { createErrorReport } from 'reducers/errorReport';
import { DataStorage } from 'utilities/storage';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class RootErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        console.log(error, info)
        debugger;
        const { createErrorReport } = this.props;
        this.setState({ hasError: true });
        const onComplete = (error, info) => {
            debugger;
            DataStorage.clearAll()
            window.location.reload();
        }
        createErrorReport(error + "\n" + JSON.stringify(info), () => onComplete(error, info), () => onComplete(error, info));
    }

    render() {
        if (this.state.hasError) {
          return null;
        }
        return this.props.children;
    }
}

const mapStateToProps = state => ({ });
const mapDispatchToProps = dispatch => bindActionCreators({ createErrorReport}, dispatch);
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RootErrorBoundary);