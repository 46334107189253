import React from 'react';
import { Editor, EditorState, RichUtils, convertToRaw, convertFromRaw } from 'draft-js';
import { getStyleButtons } from './styleButtons';
import { getRichTextContent } from '../../../utilities/common';

import 'draft-js/dist/Draft.css';
import './richText.scss';

class RichTextEditor extends React.Component {
    constructor(props) {
        super(props);
        const {
            fieldProps: { field }
        } = props;
        const value = getRichTextContent(field.value);

        this.state = { editorState: value ? EditorState.createWithContent(convertFromRaw(value)) : EditorState.createEmpty() };
        this.focus = () => (this.refs.editor ? this.refs.editor.focus() : null);

        this.onChange = this.onChange.bind(this);
        this.handleKeyCommand = this.handleKeyCommand.bind(this);
        this.toggleBlockType = this.toggleBlockType.bind(this);
        this.toggleInlineStyle = this.toggleInlineStyle.bind(this);
    }

    componentDidUpdate(prevProps) {
        const oldName = prevProps.name;
        const newName = this.props.name;
        if (oldName !== undefined && oldName !== newName) {
            this.setState({ ...this.state, editorState: EditorState.createEmpty() });
        }
    }

    handleKeyCommand(command, editorState, form) {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            this.onChange(newState, form);
            return 'handled';
        }
        return 'not-handled';
    }

    toggleBlockType(blockType, form) {
        this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType), form);
    }

    toggleInlineStyle(inlineStyle, form) {
        this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle), form);
    }

    onChange = async (editorState, form) => {
        const { name, submitOnChange } = this.props;
        this.setState({ ...this.state, editorState });
        var json = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
        await form.setFieldValue(name, json);
        if (!!submitOnChange) {
            form.submitForm();
        }
    };

    render() {
        const {
            placeholder,
            fieldProps: { field, form },
            hiddenControls = [],
            extraControls = null
        } = this.props;
        
        const value = getRichTextContent(field.value);
        
        let upstreamState = value ? EditorState.createWithContent(convertFromRaw(value)) : EditorState.createEmpty();
        let upstreamContent = upstreamState.getCurrentContent();
        let upstreamText = upstreamContent.getBlockMap().first().getText();
        let { editorState } = this.state;
        let editorContent = editorState.getCurrentContent();
        let editorText = editorContent.getBlockMap().first().getText();

        if (!(upstreamText === editorText)){
            editorState = upstreamState;
            this.state = { editorState: editorState };
        }


        let className = 'RichEditor-editor';
        var contentState = editorState.getCurrentContent();
        if (!contentState.hasText()) {
            if (
                contentState
                    .getBlockMap()
                    .first()
                    .getType() !== 'unstyled'
            ) {
                className += ' RichEditor-hidePlaceholder';
            }
        }

        return (
            <div className="form-control RichEditor-root">
                {getStyleButtons(editorState, this.toggleBlockType, this.toggleInlineStyle, form, hiddenControls, extraControls)}
                <div className="RichEditor-separator" />
                <div className={className} onClick={this.focus}>
                    <Editor
                        placeholder={placeholder}
                        editorState={editorState}
                        handleKeyCommand={(c, s) => this.handleKeyCommand(c, s, form)}
                        onChange={es => this.onChange(es, form)}
                        ref="editor"
                        spellCheck={true}
                    />
                </div>
            </div>
        );
    }
}

export default RichTextEditor;
