export const FIELD_VALUE_CREATE = `
    mutation M($field_id: Long!, $order_by: Int, $is_hidden: Boolean, $value: String) {
        model_field_value_create(input: { field_id: $field_id, order_by: $order_by, is_hidden: $is_hidden, value: $value }) {
            id
            company_id
            field_id
            order_by
            is_hidden
            value
        }
    }
`;
