import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ReactModal from 'react-modal';
import countries from '../../../../assets/countries.json';
import { Fields } from '../../../../components/fields';
import Terms from './terms';
import { history } from '../../../../configuration/history.js';
import { useLink } from '../../../../reducers/links.jsx';
import { storeInvitationResponse } from '../../../../reducers/companies.jsx';
import SimpleBar from 'simplebar-react';

class Presubmission extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitted: false,
            submitting: false,
            modal: { open: false }
        };
    }

    setSubmitted = submitted => this.setState({ ...this.state, submitted });

    validateLinkData = () => {
        const { location } = this.props;
        const { linkData } = location.state || {};
        if (!linkData) {
            return false;
        }
        const { link_token, name } = linkData || {};
        if (!link_token || !name) {
            return false;
        }

        return true;
    };

    componentDidMount() {
        const valid = this.validateLinkData();
        if (!valid) {
            history.push('/' + window.location.search);
        }
    }

    handleSubmit = values => {
        const { useLink, storeInvitationResponse } = this.props;
        const valid = this.validateLinkData();
        if (!valid) {
            history.push('/' + window.location.search);
            return;
        }

        const { location } = this.props;
        const { linkData } = location.state || {};
        const data = { ...values, status_id: 1 };
        this.setState({ submitting: true });
        storeInvitationResponse(
            data,
            () => useLink(
                linkData.name,
                linkData.link_token,
                () => {
                    this.setSubmitted(true);
                    this.setState({ submitting: false })
                },
                () => this.setState({ submitting: false })
            ),
            () => this.setState({ submitting: false })
        );
    };

    setModalVisibility = open => {
        this.setState({ ...this.state, modal: { open } });
    };

    render() {
        const { modal, submitted, submitting } = this.state;

        return (
            <React.Fragment>
                <div className="modal-dialog wd-100p-force advanced-filter-modal" role="document">
                    <div className="modal-content bd-0">
                        <div className="modal-header pd-x-20">
                            <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                                <FormattedMessage id="page.account.forms.presubmission.subscribe" />
                            </h6>
                        </div>
                        {!!submitted ? (
                            <React.Fragment>
                                <div className="modal-body pd-20 widget-2 company-invite-form">
                                    <FormattedMessage id="page.account.forms.presubmission.subscribe.submitted" />
                                </div>
                                <div className="modal-footer justify-content-center">
                                    <button
                                        type="button"
                                        onClick={() => history.push('/' + window.location.search)}
                                        className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                    >
                                        <FormattedMessage id="common.ok" />
                                    </button>
                                </div>
                            </React.Fragment>
                        ) : (
                                <Formik
                                    initialValues={{
                                        company_name: '',
                                        description: '',
                                        address_1: '',
                                        address_2: '',
                                        lang_id: 1,
                                        website: '',
                                        email: '',
                                        country_id: '',
                                        city: '',
                                        state: '',
                                        zip: '',
                                        new_user_first_name: '',
                                        new_user_last_name: '',
                                        new_user_email: '',
                                        new_user_phone: ''
                                    }}
                                    validationSchema={Yup.object().shape({
                                        company_name: Yup.string().required(<FormattedMessage id="validation.required" />),
                                        new_user_first_name: Yup.string().required(<FormattedMessage id="validation.required" />),
                                        new_user_last_name: Yup.string().required(<FormattedMessage id="validation.required" />),
                                        new_user_email: Yup.string().email(<FormattedMessage id="validation.invalid" />).required(<FormattedMessage id="validation.required" />),
                                        new_user_phone: Yup.string().required(<FormattedMessage id="validation.required" />),
                                        country_id: Yup.string().required(<FormattedMessage id="validation.required" />)
                                    })}
                                    onSubmit={this.handleSubmit}
                                    render={({ values }) => (
                                        <Form>
                                            <React.Fragment>
                                                <div className="modal-body pd-0 widget-2 company-invite-form">
                                                    <SimpleBar>
                                                        <div className="pd-x-10 pd-y-5">
                                                            
                                                            <div className="form-group col-12 pd-t-15">
                                                                <h6 className="tx-10 mg-b-0 tx-uppercase">
                                                                    <FormattedMessage id="page.account.forms.presubmission.subscribe.user" />
                                                                </h6>
                                                            </div>

                                                            <div className="form-group col-12">
                                                                <div className="row">
                                                                    <div className="col-6 pd-r-0">
                                                                        <Fields.Input name="new_user_first_name" showError={true} />
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <Fields.Input name="new_user_last_name" showError={true} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group col-12">
                                                                <Fields.Input name="new_user_email" showError={true} />
                                                            </div>
                                                            <div className="form-group col-12">
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <Fields.Phone name="new_user_phone" showError={true} />
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className="form-group col-12 pd-t-15">
                                                                <h6 className="tx-10 mg-b-0 tx-uppercase">
                                                                    <FormattedMessage id="page.account.forms.presubmission.subscribe.company" />
                                                                </h6>
                                                            </div>
                                                            <div className="form-group col-12">
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <Fields.Input name="company_name" showError={true} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group col-12">
                                                                <div className="row">
                                                                    <div className="col-6 pd-r-0">
                                                                        <Fields.Input name="website" />
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <Fields.Select
                                                                            name="country_id"
                                                                            value={values.country_id || ''}
                                                                            options={countries.map(c => ({ value: c.id, label: ` (${c.code}) ${c.title}` }))}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group col-12">
                                                                <div className="row">
                                                                    <div className="col-6 pd-r-0">
                                                                        <Fields.Input name="address_1" />
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <Fields.Input name="address_2" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group col-12">
                                                                <div className="row">
                                                                    <div className="col-4 pd-r-0">
                                                                        <Fields.Input name="city" />
                                                                    </div>
                                                                    <div className="col-4 pd-r-0">
                                                                        <Fields.Input name="state" />
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <Fields.Input name="zip" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group col-12">
                                                                <div className="row">
                                                                    <div className="col-12 pd-t-30 pd-t-15">
                                                                        <span onClick={() => this.setModalVisibility(true)} className="clickable">
                                                                            <FormattedHTMLMessage
                                                                                id="page.account.forms.presubmission.subscribe.terms"
                                                                                tagName="span"
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </SimpleBar>
                                                </div>
                                                <div className="modal-footer justify-content-center">
                                                    <button type="submit" disabled={submitting} className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium">
                                                        <FormattedMessage id="common.submit" />
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                                        onClick={() => history.push('/' + window.location.search)}
                                                    >
                                                        <FormattedMessage id="common.cancel" />
                                                    </button>
                                                </div>
                                            </React.Fragment>
                                        </Form>
                                    )}
                                />
                            )}
                    </div>
                </div>
                <ReactModal
                    isOpen={modal.open}
                    onRequestClose={() => this.setModalVisibility(false)}
                    className="modal-block dialog"
                    overlayClassName="modal-overlay gray modal-overlay-account"
                >
                    <Terms onCancel={() => this.setModalVisibility(false)} />
                </ReactModal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            useLink,
            storeInvitationResponse
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Presubmission);
