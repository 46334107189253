import React from 'react';
import axios from 'axios';
import { parseErrors } from '../utilities/request';
import { FormattedMessage } from 'react-intl';
import { DataStorage } from '../utilities/storage';
import { Notifications } from '../components/notifications';
import { logout } from '../utilities/identity';
import { store } from './store';
import { authenticate } from '../reducers/identity';
import Queries from '../reducers/queries';

const uri = process.env.REACT_APP_API_MANUAL_URL ? process.env.REACT_APP_API_MANUAL_URL : process.env.REACT_APP_API_URI;

const client = axios.create({ baseURL: uri });
const invoke = ({ query, variables }, config) => client.post('', { query, variables }, config);

client.interceptors.response.use(
    response => response,
    error => {
        if (!error.response) {
            return Promise.reject(new Error('Network error, please check your connection and try again'));
        } else if (401 === error.response.status && !isLogin(error)) {
            logout();
        } else if ([502, 503, 504].includes(error.response.status)) {
            const maintenanceError = new Error('Server is undergoing maintenance, please try again in a few minutes');
            maintenanceError.name = 'dorae-maintenance';
            return Promise.reject(maintenanceError);
        } else {
            return Promise.reject(error);
        }
    }
);
client.interceptors.request.use(
    config => {
        if (config.data.query === Queries.Identity.Login) {
            return config;
        }

        const { token } = DataStorage.getToken() || {};
        if (token != null) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    function(err) {
        return Promise.reject(err);
    }
);

export const call = async ({ query, variables }, checkToken = true, config = undefined) => {
    if (checkToken) {
        await authenticate()(store.dispatch);
    }

    return invoke({ query, variables }, config)
        .then(response => {
            if (!response || !response.data) {
                return undefined;
            }

            return response.data;
        })
        .catch(error => {
            if (error && error.name && error.name === 'dorae-maintenance') {
                window.location.reload();
            }
            return { error: parseErrors(error), raw: error };
        });
};

export const hasErrors = (response, errorIfEmpty = true, dispatchToast = true) => {
    if ((!response && errorIfEmpty) || response.error) {
        const error = response && response.error ? response.error : <FormattedMessage id="error.default" />;
        if (!!dispatchToast) {
            Notifications.error(error);
        }
        return true;
    }

    return false;
};

export const upload = async (formData, checkToken = true) => {
    if (checkToken) {
        await authenticate()(store.dispatch);
    }

    return client
        .post('', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => {
            if (!response || !response.data) {
                return undefined;
            }

            return response.data;
        })
        .catch(error => {
            return { error: parseErrors(error) };
        });
};

const isLogin = error => {
    try {
        const login = Queries.Identity.Login;
        const query = (JSON.parse(error.response.config.data) || {}).query;
        return query === login;
    } catch (error) {
        console.error('isLogin error', error);
        return false;
    }
};
