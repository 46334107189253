export const MODELS_LIST_LIGHT = `
    query($status_ids: [Int!], $created_from: DateTime, $created_to: DateTime, $search: String, $order_by: [OrderBy], $offset: Int, $limit: Int, $model_type: Int) {
        model_list(
            input: { status_ids: $status_ids, created_from: $created_from, created_to: $created_to, search: $search, model_type: $model_type }
            paging: { order_by: $order_by, offset: $offset, limit: $limit }
        ) {
            total
            data {
                id
                hash_id
                status_id
                status_name
                title
                created_by_name
                model_type
                model_type_name
                is_locked
                contains_inaccessible_dots

                model_field_list {
                    id
                    title
                    index_field_id
                    type_id
                    type_name
                    cargoCategory: cargo_category
                    cargo_type
                }
            }
        }
    }
`;
