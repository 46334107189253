import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addLocaleData, IntlProvider } from 'react-intl';
import en from 'react-intl/locale-data/en';
import fr from 'react-intl/locale-data/fr';
import pt from 'react-intl/locale-data/pt';
import queryString from 'query-string';
import { setLocale, loadLocale, getSupportedLocales } from '../../reducers/localization';
import { DataStorage } from '../../utilities/storage';

class Localized extends React.Component {
    parseLocale = userLanguage => {
        if (userLanguage.length > 2) {
            return userLanguage.substr(0, 2).toLowerCase();
        } else if (userLanguage.length === 2) {
            return userLanguage.toLowerCase();
        } else {
            return 'en';
        }
    };

    getDefaultLocale = () => {
        const { supportedLocales, location } = this.props;
        const queryStringLocale = queryString.parse(location.search);
        if (queryStringLocale && queryStringLocale.lang && supportedLocales.includes(queryStringLocale.lang.toLowerCase())) {
            return queryStringLocale.lang.toLowerCase();
        }

        const storedLocale = DataStorage.local.getItem('l');
        if (storedLocale) {
            return storedLocale;
        }

        const browserLocale = this.parseLocale(navigator.language || navigator.userLanguage);
        const locale = supportedLocales.includes(browserLocale) ? browserLocale : 'en';
        DataStorage.setLocale(locale);

        return locale;
    };

    componentDidMount() {
        const { supportedLocales, setLocale, loadLocale } = this.props;
        addLocaleData([...en, ...fr, ...pt]);
        setLocale(this.getDefaultLocale());
        supportedLocales.forEach(locale => loadLocale(locale));

        try {
            const fullySupportedLocales = ['en', 'pt'];
            const localeFetchPromises = [];
            fullySupportedLocales.forEach(locale => {
                localeFetchPromises.push(fetch(`${process.env.PUBLIC_URL}/messages-${locale}.json`, { method: 'GET' }).then(async data => {
                    return await data.json();
                }));
            });
            Promise.all(localeFetchPromises).then(results => {
                let keys = [];
                results.forEach(locale => {
                    const localeKeys = Object.keys(locale);
                    keys = keys.concat(localeKeys.filter(k => !keys.find(existing => existing === k)));
                });
                results.forEach(locale => {
                    const localeKeys = Object.keys(locale);
                    const missingKeys = keys.filter(key => !localeKeys.find(localeKey => localeKey === key));
                    if (missingKeys.length > 0) {
                        console.error('Missing locale keys found in ' + locale['language.current'], missingKeys);
                    } else {
                        console.info('Successfully validated Locale ' + locale['language.current'] + ' (' + localeKeys.length + ' Keys)');
                    }
                });
            })
        } catch (err) {
            console.warn("Failed to check Locales for missing keys", err);
        }
    }

    render() {
        const { children, locale, translations } = this.props;
        if (!locale || !translations) return null;

        return (
            <IntlProvider locale={locale} key={locale} messages={translations} textComponent={React.Fragment}>
                {children}
            </IntlProvider>
        );
    }
}

Localized.defaultProps = {
    supportedLocales: getSupportedLocales().map(l => l.locale)
};
Localized.propTypes = {
    supportedLocales: PropTypes.arrayOf(PropTypes.string)
};

const mapStateToProps = state => {
    const locale = state.localization.locale;

    return {
        locale,
        translations: state.localization[locale],
        location: state.router.location
    };
};
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setLocale,
            loadLocale
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Localized);
