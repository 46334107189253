import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from './breadcrumbs';
class Page extends React.Component {
    render() {
        const { showTitleBlock, title, showTitle, subtitle, showSubtitle, actions, breadcrumbActions, panel, children, noPadding = false, extraHeaderEls = null } = this.props;
        const styleClass = noPadding ? {padding: 5, margin: 5} : {};
        return (
            <React.Fragment>
                { !noPadding && <Breadcrumbs {...this.props} actions={breadcrumbActions} /> }
                {showTitleBlock && (
                    <div className="pd-x-20 pd-t-10">
                        {title && showTitle && <h4 className="tx-gray-800 mg-b-5">{title}</h4>}
                        {subtitle && showSubtitle && <p className="mg-b-0">{subtitle}</p>}
                        {actions}
                        {extraHeaderEls}
                    </div>
                )}
                {!showTitleBlock && !!extraHeaderEls && (
                    <div className="pd-x-20 pd-t-5">
                        {extraHeaderEls}
                    </div>
                )}
                <div className={'br-pagebody' + (noPadding ? '' : ' pd-x-20')} style={styleClass}>{panel ? <div className="br-section-wrapper">{children}</div> : children}</div>
            </React.Fragment>
        );
    }
}

Page.defaultProps = {
    showTitleBlock: true,
    title: undefined,
    showTitle: true,
    subtitle: undefined,
    showSubtitle: true,
    panel: true,
    actions: null,
    breadcrumbActions: null
};
Page.propTypes = {
    showTitleBlock: PropTypes.bool,
    title: PropTypes.any,
    showTitle: PropTypes.bool,
    subtitle: PropTypes.any,
    showSubtitle: PropTypes.bool,
    panel: PropTypes.bool,
    actions: PropTypes.any,
    breadcrumbActions: PropTypes.any
};

export default Page;
