import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import ReactModal from 'react-modal';
import Tooltip from 'rc-tooltip';
import { Fields } from '../../../../../components/fields';
import FieldPicker from './fieldPicker';

class FieldsReferencesDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: { open: false }
        };
    }

    setModalVisibility = open => {
        this.setState({ ...this.state, modal: { open } });
    };

    render() {
        const { modal } = this.state;
        const { fields = [], formProps, name, currentValue } = this.props;

        return (
            <React.Fragment>
                <Tooltip
                    placement={'right'}
                    trigger={['hover']}
                    overlay={<FormattedMessage id="page.models.document.configuration.properties.addFieldMacro" />}
                >
                    <i className="material-icons-outlined clickable no-select" onClick={() => this.setModalVisibility(true)}>
                        playlist_add
                    </i>
                </Tooltip>
                <ReactModal
                    isOpen={modal.open}
                    onRequestClose={() => this.setModalVisibility(false)}
                    className="modal-block dialog"
                    overlayClassName="modal-overlay gray"
                >
                    <div className="modal-dialog wd-300" role="document">
                        <div className="modal-content bd-0">
                            <div className="modal-header pd-x-20">
                                <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                                    <FormattedMessage id="models.configure.indices.new" />
                                </h6>
                            </div>
                            <div className="modal-body pd-20">
                                <div className="mg-b-20">
                                    <FieldPicker fields={fields} formProps={formProps} name={name} currentValue={currentValue} />
                                </div>
                                <Fields.Input type="text" name="content" />
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button
                                    type="button"
                                    className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                    onClick={() => this.setModalVisibility(false)}
                                >
                                    <FormattedMessage id="common.ok" />
                                </button>
                            </div>
                        </div>
                    </div>
                </ReactModal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    company: state.identity.company,
    model: state.fields.model,
    fields: state.fields.items,
    fieldTypes: state.fields.types,
    template: state.document.instance
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FieldsReferencesDialog);
