import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Page from '../../../components/page';
import { FormattedHTMLMessage } from 'react-intl';

class ImpersonateLandingPage extends React.Component {
    render() {
        const { user } = this.props;
        if (!user) return null;

        return (
            <Page panel={false} hideBreadcrumbs={true}>
                <div className="impersonate-page">
                    <i className="material-icons-outlined no-select">account_circle</i>
                    <p>
                        <FormattedHTMLMessage id="page.account.impersonate.message" tagName="span" values={{ user: user.name }} />
                    </p>
                </div>
            </Page>
        );
    }
}

const mapStateToProps = state => ({
    user: state.identity.user
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ImpersonateLandingPage);
