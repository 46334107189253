export const CREATE_SHARED_ENTITY = `
    mutation M (
        $entity_type	: String!
        $entity_id      : Long!
        $target_email   : String!
        $include_transactions     : Boolean
    )  
    {
        external_sharing_create(
            input: {
                entity_type             : $entity_type      
                entity_id		        : $entity_id           
                target_email            : $target_email   
                include_transactions    : $include_transactions
            }
        )
        {
            id              
            entity_type       
            entity_id     
            target_email      
            include_transactions
        }
    }
`;