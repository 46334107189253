export const TRANSACTIONS_GET_DELETE_SIDE_EFFECTS = `
    query _($transaction_id: Id!) {
        transaction_get_delete_side_effects(input: {
            transaction_id: $transaction_id
        }) {
            has_effects_to_reverse
            can_reverse_effects
        }
    }
`;
