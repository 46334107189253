import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

export default class TablePagination extends Component {
    static defaultProps = {
        renderPageJump: ({ onChange, value, onBlur, onKeyPress, inputType, pageJumpText }) => (
            <div className="-pageJump">
                <input aria-label={pageJumpText} type={inputType} onChange={onChange} value={value} onBlur={onBlur} onKeyPress={onKeyPress} />
            </div>
        ),
        renderCurrentPage: page => <span className="-currentPage">{page + 1}</span>,
        renderTotalPagesCount: pages => <span className="-totalPages">{pages || 1}</span>,
        renderPageSizeOptions: ({ pageSize, pageSizeOptions, rowsSelectorText, onPageSizeChange, rowsText }) => (
            <React.Fragment>
                <select className={'se-select-pageSize'} aria-label={rowsSelectorText} onChange={e => onPageSizeChange(Number(e.target.value))} value={pageSize}>
                    {pageSizeOptions.map((option, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <option key={i} value={option} className={'se-select-option-pageSize-' + option}>
                            {option}
                        </option>
                    ))}
                </select>
                <FormattedMessage id="pageSize.placeholder" tagName="span" />
            </React.Fragment>
        )
    };

    constructor(props) {
        super(props);

        this.getSafePage = this.getSafePage.bind(this);
        this.changePage = this.changePage.bind(this);
        this.applyPage = this.applyPage.bind(this);
        this.getVisiblePages = this.getVisiblePages.bind(this);
        this.filterPages = this.filterPages.bind(this);

        this.state = {
            page: props.page,
            visiblePages: this.getVisiblePages(null, props.pages)
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.page !== nextProps.page) {
            this.setState({ page: nextProps.page, visiblePages: this.getVisiblePages(null, nextProps.pages) });
        }
    }

    getSafePage(page) {
        if (Number.isNaN(page)) {
            page = this.props.page;
        }
        return Math.min(Math.max(page, 0), this.props.pages - 1);
    }

    changePage(page) {
        page = this.getSafePage(page);

        const visiblePages = this.getVisiblePages(page, this.props.pages);
        this.setState({ page, visiblePages: this.filterPages(visiblePages, this.props.pages) });
        if (this.props.page !== page) {
            this.props.onPageChange(page);
        }
    }

    applyPage(e) {
        if (e) {
            e.preventDefault();
        }
        const page = this.state.page;
        this.changePage(page === '' ? this.props.page : page);
    }

    getPageJumpProperties() {
        return {
            onKeyPress: e => {
                if (e.which === 13 || e.keyCode === 13) {
                    this.applyPage();
                }
            },
            onBlur: this.applyPage,
            value: this.state.page === '' ? '' : this.state.page + 1,
            onChange: e => {
                const val = e.target.value;
                const page = val - 1;
                if (val === '') {
                    return this.setState({ page: val });
                }
                this.setState({ page: this.getSafePage(page) });
            },
            inputType: this.state.page === '' ? 'text' : 'number',
            pageJumpText: this.props.pageJumpText
        };
    }

    filterPages(visiblePages, totalPages) {
        return visiblePages.filter(page => page <= totalPages);
    }
    getVisiblePages(page, total) {
        if (total < 7) {
            return this.filterPages([1, 2, 3, 4, 5, 6], total);
        } else {
            if (page % 5 >= 0 && page > 4 && page + 2 < total) {
                return [1, page - 1, page, page + 1, total];
            } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
                return [1, total - 3, total - 2, total - 1, total];
            } else {
                return [1, 2, 3, 4, 5, total];
            }
        }
    }

    render() {
        const {
            // Computed
            pages,
            // Props
            page,
            showPageSizeOptions,
            pageSizeOptions,
            pageSize,
            canPrevious,
            canNext,
            onPageSizeChange,
            renderPageSizeOptions,
            totalRecords,
            reducedPagination = false
        } = this.props;
        const visiblePages = this.getVisiblePages(page, pages);

        return (
            <div className="row table-pagination-strip" style={this.props.style}>
                <div className={reducedPagination ? 'col-3' : 'col-sm col-xs-12'}>
                    <div className="row">
                        {
                            !reducedPagination && (
                                <div className="col-auto d-sm-block d-none">
                                    <span className="select2 select2-container select2-container--default">
                                        {showPageSizeOptions &&
                                            renderPageSizeOptions({
                                                pageSize,
                                                rowsSelectorText: this.props.rowsSelectorText,
                                                pageSizeOptions,
                                                onPageSizeChange,
                                                rowsText: this.props.rowsText
                                            })}
                                    </span>
                                </div>
                            )
                        }
                        <div className="col-sm-auto col-xs-12">
                            {totalRecords > 0 && (
                                <div className="dataTables_info entry-count">
                                    <FormattedMessage
                                        id={reducedPagination ? "table.data.pagination.message.reduced" : "table.data.pagination.message"}
                                        values={{
                                            floor: page * pageSize + 1,
                                            ceiling: totalRecords >= page * pageSize + pageSize ? page * pageSize + pageSize : totalRecords,
                                            total: totalRecords
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-sm col-xs-12">
                    <div className="dataTables_wrapper">
                        {totalRecords > 0 && (
                            <div className="dataTables_paginate paging_simple_numbers">
                                <span
                                    className={`paginate_button previous${!canPrevious ? ' disabled' : ''}`}
                                    onClick={() => {
                                        if (!canPrevious) return;
                                        this.changePage(page - 1);
                                    }}
                                    disabled={!canPrevious}
                                >
                                    <FormattedMessage id='previous'/>
                                </span>
                                {visiblePages.map((p, index, array) => {
                                    return (
                                        <span
                                            key={index}
                                            className={`paginate_button${p - 1 === page ? ' current' : ''}`}
                                            onClick={this.changePage.bind(null, p - 1)}
                                        >
                                            {array[index - 1] + 2 < p ? `...${p}` : p}
                                        </span>
                                    );
                                })}
                                <span
                                    className={`paginate_button next${!canNext ? ' disabled' : ''}`}
                                    onClick={() => {
                                        if (!canNext) return;
                                        this.changePage(page + 1);
                                    }}
                                    disabled={!canNext}
                                >
                                    <FormattedMessage id='next'/>
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
