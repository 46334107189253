export const CARGO_UNITS_CREATE = `
	mutation M(
		$name: String!
		$shortName: String!
		$cargoUnitType: String!
		$default: Boolean
		$cargoCategory: Long
	) {
		cargo_unit_create (
			input: {
				name: $name
				display_name: $shortName
				unit_type: $cargoUnitType
				default: $default
				cargo_category: $cargoCategory
			}
		) {
			id
			short_name
			name
			display_name
			description
			unit_type
			is_public
			is_default
		}
  	}`