export const USERS_CREATE_ROLE = `
    mutation M($company_id: Int, $title: String!) {
        role_create(input: { company_id: $company_id, title: $title }) {
            id
            company_id
            title
            is_builtin
            is_restricted

            aux {
                name
                value
            }
        }
    }
`;
