export const LOTS_LIST_LIGHT = `
    query(
		$cargo_categories : [Long]
        $order_by: [OrderBy]
        $offset: Int
        $limit: Int
        $status_ids: [Int!]
        $model_ids: [Int!]
        $created_from: DateTime
        $created_to: DateTime
	) {
		lot_list (
			input: {
				cargo_categories: $cargo_categories
                status_ids: $status_ids
                created_from: $created_from
                created_to: $created_to
            }
            paging: { order_by: $order_by, offset: $offset, limit: $limit }
		) {
			total
			data {		
				id
				hash_id
				created_on
				modified_on
				quantity
				original_quantity
				claimed_by_dash_id
				created_by_dash_id
				created_by_dash_company
				created_by_dash_is_accessible
				claimed_by_dash_company
				claimed_by_dash_is_accessible
				status_name
				cargo_unit_id
				cargo_unit {
					name
					display_name
					description
				}
				cargo_unit_name
				cargo_type_hash_id
				cargo_type_category_title
				cargo_type {
					id
					status_id
					status_name
					model_id
					company_id
					user_id
					created_on
					source_cargo_id
					result_cargo_id
					title
					description
					notes
					bc_transaction_id
					extra_info
					
					transaction_type
					transaction_type_name
				}
			}
        }
    }
`;
