export const LIST_SOURCES = `
    query _($company_id : Int!)  
    {
        company_get_payment_sources(company_id: $company_id)
        {
            id
            customer
            card_owner
            brand
            last4
            is_default
        }
    }
`;
