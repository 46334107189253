export const CREATE_DOCUMENT_SELECTION = `
    mutation M($document_template_id: Long!, $name: String!, $field_reference_events: JsonString!) {
        document_selection_create(input: { document_template_id: $document_template_id, name: $name, field_reference_events: $field_reference_events }) {
            id
            name
            document_template_id
            created_by
            created_on
            field_reference_events
        }
    }
`;
