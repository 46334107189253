import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import ReactModal from 'react-modal';
import Page from '../../../components/page';
import { history } from '../../../configuration/history';
import { cancelSubscription, getPlans, getProducts, subscribe, previewPayment, retryPayment } from '../../../reducers/payment';
import { getUserRoles, getUsers, deleteUser, reenableUser, updateUser } from '../../../reducers/users';
import { getProfile } from '../../../reducers/identity';
import { Notifications } from '../../../components/notifications';
import BillingInfo from '../components/forms/billingInfo';

class CompanyBillingOverdue extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: { open: false },
            isSubmitting: false,
            overduePreview: {
                amount_due: 0,
                line_items: []
            }
        };
    }

    componentDidMount() {
        const { plans, getPlans, products, getProducts, company, previewPayment } = this.props;

        if (!plans) getPlans();
        if (!products) getProducts();
        previewPayment(company.plan_id, company.subscription_seats, (preview) => {
            this.setState({overduePreview: preview});
        })
    }

    setModalVisibility = (open, type) => this.setState({ ...this.state, modal: { open, type: !!open ? type : undefined } });

    loadData = (options, onComplete) => {
        const { getUsers, company } = this.props;
        return getUsers({ company_ids: [company.id], status_ids: [1, 2, 3, 4, 5, 6], ...options }, onComplete);
    };

    render() {
        const { modal } = this.state;
        const { parents, company, getProfile, plans, products, retryPayment } = this.props;
        if (!company || !plans || !products) return null;

        const { plan_id, subscription_seats } = company;

        const plan = plans.find(p => p.id === plan_id) || { price_cents: 0 };
        const totalPrice = (Number.parseInt(subscription_seats) * plan.price_cents) / 100;

        return (
            <React.Fragment>
                <Page {...CompanyBillingOverdue.meta} parents={parents} panel={false}>
                    <div className="billing-box">
                        <div className="row">
                            <div className="col-8">
                                <div className="payment-plan-box pd-0">
                                    <span>
                                        <FormattedMessage id="page.companies.billingOverdue.overview" />
                                    </span>
                                    <br/>
                                    <br/>
                                    <div className="tx-light tx-11 pd-b-15">
                                        <span>
                                            <FormattedMessage id="page.companies.billing.activePlan" />
                                        </span>
                                    </div>
                                    <div className="payment-plan-box-inner pd-x-0-force">
                                        <h5 className="plan-title tx-century-gothic">
                                            DORÆ <strong>Standard</strong>
                                        </h5>
                                        <div className="pricing-details" style={{ height: 'auto' }}>
                                            <p className="pricing-details-price" style={{color: 'red'}}>
                                                {`$${Number(this.state.overduePreview.amount_due / 100).toFixed(2)} `}
                                                <span style={{color: 'black'}}>
                                                    <FormattedMessage id="page.companies.billingOverdue.description" />
                                                </span>
                                            </p>
                                        </div>
                                        <div>
                                            <button
                                                type="button"
                                                className="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                                onClick={() => this.setModalVisibility(true)}
                                            >
                                                <FormattedMessage id="page.companies.billingOverdue.changeCard" />
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-danger tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium mg-l-5"
                                                onClick={
                                                    () => retryPayment(
                                                        () => getProfile(() => history.push('/settings' + window.location.search)), 
                                                    )}
                                            >
                                                <FormattedMessage id="page.companies.billingOverdue.retry" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="billing-statistics">
                                    <div className="billing-statistics-line">
                                        <div className="bsl-figure">{subscription_seats}</div>
                                        <span>total licenses</span>
                                        <button
                                            type="button"
                                            className="bsl-button btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                            onClick={() => this.setModalVisibility(true, 'licenses')}
                                        >
                                            <FormattedMessage id="page.companies.billing.licenses" />
                                        </button>
                                    </div>
                                    <div className="bsl-line" />
                                    <div className="billing-statistics-line">
                                        <div className="bsl-figure">{`$${totalPrice}`}</div>
                                        <span>monthly total</span>
                                        <button
                                            type="button"
                                            className="bsl-button btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                                            onClick={() => history.push(`/settings/billing/invoices` + window.location.search)}
                                        >
                                            <FormattedMessage id="page.companies.billing.invoices" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Page>
                <ReactModal
                    isOpen={modal.open}
                    onRequestClose={() => this.setModalVisibility(false)}
                    className="modal-block dialog"
                    overlayClassName="modal-overlay gray"
                >
                    <BillingInfo 
                        onCancel={() => this.setModalVisibility(false)} 
                        company={company} 
                        onSuccess={() => retryPayment(
                            () => {
                                getProfile(() => history.push('/settings' + window.location.search));
                                Notifications.success(<FormattedMessage id='page.companies.billingOverdue.retrySuccess'/>);
                            },
                            () => Notifications.error(<FormattedMessage id='page.companies.billingOverdue.retryError'/>)
                        )} />
                </ReactModal>
            </React.Fragment>
        );
    }
}

CompanyBillingOverdue.meta = {
    title: <FormattedMessage id="page.companies.billingOverdue.title" />,
    subtitle: <FormattedMessage id="page.companies.billingOverdue.subtitle" />,
    routes: ['/settings/billing-overdue'],
    icon: 'ion-ios-world-outline',
    menus: { main: false, user: false },
    order: 0
};

const mapStateToProps = state => {
    return {
        company: state.identity.company,
        roles: state.users.roles,
        users: state.users.list,
        total: state.users.total,
        permissions: state.identity.permissions,
        plans: state.payment.plans,
        products: state.payment.products
    };
};
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getProfile,
            cancelSubscription,
            getPlans,
            getProducts,
            getUserRoles,
            getUsers,
            updateUser,
            deleteUser,
            reenableUser,
            subscribe,
            previewPayment,
            retryPayment
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanyBillingOverdue);
