import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import Page from '../../../components/page';
import LotsTable from '../components/lotsTable';
import { history } from 'configuration/history';
import { listLots } from 'reducers/lots'


class LotsList extends React.Component {

    renderActions = () => {
        const objectTemplatesButton = (
            <span 
                className="btn btn-outline-info" 
                onClick={() => {
                    history.push('/objects/object-templates/' + window.location.search)
                }
            }>
                <FormattedMessage id="objectTemplates" />
            </span>
        )
        return (
            <div className="btn-group pd-t-10">
                { objectTemplatesButton }
            </div>
        );
        
    };

    render() {

        return (
            <Page {...LotsList.meta  } parents={this.props.parents} actions={this.renderActions()}>
                <LotsTable key='root-lots-table' lockModal={true} showMenu={true}/>
            </Page>
        );
        
    }
}

LotsList.meta = {
    title: <FormattedMessage id="dots" />,
    subtitle: <FormattedMessage id="page.lots.subtitle" />,
    routes: ['/objects'],
    icon: 'ion-ios-world-outline',
    menus: { main: true, user: false },
    order: 10
};

export default LotsList;